import createCheckoutSession from 'api/v1/subscriptions/createCheckoutSession';
import { stripe as stripeConfig } from 'config';
import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';
import type { IUser } from 'types/users.types';

const useRedirectToCheckoutSession = (
  user: IUser | undefined,
): UseMutationResult<unknown, unknown, Record<string, any>> => {
  return useMutation(
    async ({ planId, promocode }) => {
      if (!user) throw new Error('User data not loaded');
      const customerId = user._id;

      return createCheckoutSession(customerId, planId, promocode);
    },
    {
      onSuccess: ({ id }) => {
        if (!user || !window.Stripe) throw new Error('Data not loaded');
        const country = user.country;
        const stripe = window.Stripe(stripeConfig.key[country]);
        stripe.redirectToCheckout({
          sessionId: id,
        });
      },
    },
  );
};

export default useRedirectToCheckoutSession;
