import axiosInstance from 'api/axiosInstance';
import type { AxiosRequestConfig } from 'axios';
import FileSaver from 'file-saver';
import { ResponseType } from 'types/response.types';

export const downloadAttachment = async (
  url: string,
  filename: string,
): Promise<any> => {
  return axiosInstance
    .get(url, {
      responseType: ResponseType.blob,
      noHeaders: true,
    } as AxiosRequestConfig<any>)
    .then((data) => FileSaver.saveAs(data as unknown as Blob, filename));
};
