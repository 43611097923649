import { AvailableCountries } from 'types/users.types';

const adaptOldCategorySlug = (
  country: AvailableCountries,
  slug: string,
  key: 'title' | 'description' | 'question' | 'displayName',
) =>
  `${country}.expense.category.${(slug || '').replace('categories.', '')}${
    country === AvailableCountries.germany ? '' : `.${key}`
  }`;

export default adaptOldCategorySlug;
