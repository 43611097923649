import tryCatch from './tryCatch';

const dismissedAlerts = 'dismissedAlerts';

const dismissValue = '1';

const getOrCreateAlertsStore = (): Record<string, string> => {
  const store = localStorage.getItem(dismissedAlerts);
  if (!store) return {};
  const parsed = tryCatch(() => JSON.parse(store));
  if ({}.toString.call(parsed) !== '[object Object]') return {};
  return parsed;
};

export const isAlertDismissed = (dismissId: string) => {
  const store = getOrCreateAlertsStore();
  return store[dismissId] === dismissValue;
};

export const markAlertAsDismissed = (dismissId: string) => {
  const store = getOrCreateAlertsStore();
  store[dismissId] = dismissValue;
  localStorage.setItem(dismissedAlerts, JSON.stringify(store));
};

// is it worth it to make this into a reactive zustand store?
