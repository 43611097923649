import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import { renderTooltip } from 'components/atoms/Tooltip/Tooltip.component';
import { useHighlight } from 'hooks/useHighlight';
import type { FC, ReactNode } from 'react';
import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { ExclamationCircleIcon, EyeIcon, TrashIcon } from 'utils/icons';

type Dup = {
  _id: string;
  duplicateReason?: string;
  [key: string]: any;
};

type DocumentDuplicateFinderProps = {
  onSelect: (_id: string) => void;
  onDelete: (dup: Dup) => Promise<boolean>;
  onFetch: () => Promise<Dup[]>;
  cacheKey?: string;
  renderLabel: (dup: Dup) => ReactNode;
  className?: string;
  viewText?: string;
  deleteText?: string;
  getShouldShowDeleteButton?: (dup: Dup) => boolean;
  highlightID?: string;
};

const DocumentDuplicateFinder: FC<DocumentDuplicateFinderProps> = (props) => {
  const { getShouldShowDeleteButton = () => true } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const autoCacheKey = useId();

  const [showMore, setShowMore] = useState(false);

  const cacheKey = props.cacheKey || autoCacheKey;
  const { isError, isLoading, data } = useQuery(cacheKey, props.onFetch, {
    cacheTime: 0,
  });

  const highlightAPI = useHighlight();

  const className = classNames(
    props.className,
    highlightAPI.shouldHighlight('duplicate-warning') && highlightAPI.className,
  );

  // TODO: maybe handle error and loading
  if (isError || isLoading || !data?.length) return null;

  const deleteDup = (deletedDup: Dup) => {
    queryClient.setQueryData(cacheKey, (dups: any) =>
      dups.filter((dup: Dup) => dup._id !== deletedDup._id),
    );
  };

  return (
    <div className={classNames('rounded-lg bg-yellow-50 p-4', className)}>
      <div className="flex">
        <ExclamationCircleIcon className="h-5 w-5 mr-2 text-yellow-400 relative top-[3px]" />
        <p className="font-semibold text-yellow-800">
          {t('duplicate_finder.found_title')}
        </p>
      </div>
      <ol className="flex flex-col mt-2 ml-7">
        {(showMore ? data : data.slice(0, 2)).map((dup, index, array) => {
          const isLast = array.length - 1 === index;
          return (
            <li
              key={index}
              className={classNames('py-2 flex flex-col', {
                'border-b border-primary-100': !isLast,
              })}
            >
              <div className="flex justify-between items-center gap-4">
                <span className="text-sm truncate text-yellow-700 leading-tight">
                  {props.renderLabel(dup)}
                </span>
                <div className="flex gap-4 relative">
                  <Button
                    asDefault
                    id={'view_dup_btn_' + index}
                    className="text-yellow-700 hover:text-yellow-600 focus:text-yellow-600"
                    // only expose the id on select, since the normal findOne data/logic can different than the duplicate search data/logic,
                    // and we dont wont to use the duplicate document as a full/valid expense/invoice document
                    onClick={() => props.onSelect(dup._id)}
                  >
                    <EyeIcon className="h-5 w-5" />
                  </Button>
                  {!!props.viewText &&
                    renderTooltip({
                      anchorId: 'view_dup_btn_' + index,
                      content: props.viewText,
                    })}
                  {getShouldShowDeleteButton(dup) && (
                    <Button
                      asDefault
                      id={'delete_dup_btn_' + index}
                      className="text-yellow-700 hover:text-yellow-600 focus:text-yellow-600"
                      onClick={() =>
                        props.onDelete(dup).then((isDeleted) => {
                          if (isDeleted) deleteDup(dup);
                        })
                      }
                    >
                      <TrashIcon className="h-5 w-5" />
                    </Button>
                  )}
                  {!!props.deleteText &&
                    renderTooltip({
                      anchorId: 'delete_dup_btn_' + index,
                      content: props.deleteText,
                    })}
                </div>
              </div>
              {dup.duplicateReason ? (
                <span className="font-semibold leading-tight text-sm text-yellow-700 truncate">
                  {t('duplicate_finder.reason.' + dup.duplicateReason)}
                </span>
              ) : null}
            </li>
          );
        })}
      </ol>
      {data.length > 2 && (
        <button
          type="button"
          onClick={() => setShowMore((bool) => !bool)}
          className={classNames(
            'text-yellow-700 hover:text-yellow-600 focus:text-yellow-600 ml-7 mt-3 font-semibold text-xs',
          )}
        >
          {showMore
            ? t('duplicate_finder.show_less')
            : t('duplicate_finder.show_more')}
        </button>
      )}
    </div>
  );
};

export default DocumentDuplicateFinder;
