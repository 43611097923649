const ESTPayment = lazy(
  () => import('components/pages/Taxes/Tax/Germany/ESTPayment'),
);
const ESTPrepayment = lazy(
  () => import('components/pages/Taxes/Tax/Germany/ESTPrepayment'),
);
const IncomeTaxPayment = lazy(
  () => import('components/pages/Taxes/Tax/Belgium/IncomeTaxPayment'),
);

const CompanyTaxPayment = lazy(
  () => import('components/pages/Taxes/Tax/Belgium/CompanyTaxPayment'),
);

const CompanyTaxPrepayment = lazy(
  () => import('components/pages/Taxes/Tax/Belgium/CompanyTaxPrepayment'),
);

const IncomeTaxPrepayment = lazy(
  () => import('components/pages/Taxes/Tax/Belgium/IncomeTaxPrepayment'),
);

const USTESubmission = lazy(
  () => import('components/pages/Taxes/Tax/Germany/USTE'),
);

const VATFranchisee = lazy(
  () => import('components/pages/Taxes/Tax/Belgium/VATFranchisee'),
);
const SCExonerated = lazy(
  () =>
    import(
      'components/pages/Taxes/Tax/Belgium/SocialContribution/SCExonerated'
    ),
);
import _get from 'lodash/get';
import type { FC } from 'react';
import { lazy } from 'react';
import type { ITax } from 'types/taxes.types';

const ClientListing = lazy(
  () => import('components/pages/Taxes/Tax/Belgium/ClientListing'),
);
const IntracomListing = lazy(
  () => import('components/pages/Taxes/Tax/Belgium/IntracomListing'),
);
const VATSubmissionBE = lazy(
  () => import('components/pages/Taxes/Tax/Belgium/VATSubmission'),
);
const SCSubject = lazy(
  () =>
    import('components/pages/Taxes/Tax/Belgium/SocialContribution/SCSubject'),
);

const SpecialVAT = lazy(
  () => import('components/pages/Taxes/Tax/Belgium/SpecialVAT'),
);
const EUR = lazy(() => import('components/pages/Taxes/Tax/Germany/EUR'));
const Gewst = lazy(() => import('components/pages/Taxes/Tax/Germany/GEWST'));
const Ust = lazy(() => import('components/pages/Taxes/Tax/Germany/UST'));
const Zm = lazy(() => import('components/pages/Taxes/Tax/Germany/Zm'));

const IncomeSubmission = lazy(
  () => import('components/pages/Taxes/Tax/Belgium/IncomeSubmission'),
);

const Est = lazy(() => import('components/pages/Taxes/Tax/Germany/EST'));

export const taxItems = {
  // Belgian items
  'be/social-contribution-subject': SCSubject,
  'be/social-contribution-exonerated': SCExonerated,
  'be/income-tax-prepayment': IncomeTaxPrepayment,
  'be/income-tax-payment': IncomeTaxPayment,
  'be/client-listing': ClientListing,
  'be/vat': VATSubmissionBE,
  'be/vat/submission': VATSubmissionBE,
  'be/vat/payment': VATSubmissionBE,
  'be/special-vat': SpecialVAT,
  'be/intracom-listing': IntracomListing,
  'be/income-tax-submission': IncomeSubmission,
  'be/company-tax-prepayment': CompanyTaxPrepayment,
  'be/company-tax-payment': CompanyTaxPayment,
  'be/vat-franchisee': VATFranchisee,
  // German items
  'de/est-payment': ESTPayment,
  'de/est-prepayment': ESTPrepayment,
  'de/uste-submission': USTESubmission,
  'de/est-submission': Est,
  'de/vat-franchisee': VATFranchisee,
  'de/ustva/payment': Ust,
  'de/ustva/submission': Ust,
  'de/ustva': Ust,
  'de/zm': Zm,
  'de/eur': EUR,
  'de/gewst-submission': Gewst,
  // not found
  default: () => null,
};

const useTaxItemComponent = (tax: ITax): FC<{ tax: ITax }> => {
  return _get(taxItems, tax?.readableType, taxItems.default) as FC<{
    tax: ITax;
  }>;
};

export default useTaxItemComponent;
