import { ArrowDownIcon, CheckIcon } from '@heroicons/react/outline';
import Downgrade from 'assets/svg/icons/Downgrade';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import { Modal } from 'components/atoms/Modal';
import { Title } from 'components/atoms/Title';
import { SizesEnum } from 'components/atoms/Title/Title.enum';
import layoutContext from 'components/organisms/Layout/layoutContext';
import {
  AppBannersContext,
  BANNERS_STORAGE_KEYS,
} from 'contexts/AppBannersProvider';
import dayjs from 'dayjs';
import {
  useRedirectToCustomerBillingPortal,
  useSubscriptionPlan,
} from 'hooks/Subscriptions';
import useCustomNavigate from 'hooks/useCustomeNavigate';
import useFeatures from 'hooks/useFeatures';
import { last } from 'lodash';
import { useContext, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { SubscriptionPlanNameEnum } from 'types/users.types';

const LostBenefitItem: FC<{ benefitName: string }> = ({ benefitName }) => {
  return (
    <li className="flex gap-2 mb-2 items-center">
      <div className="flex justify-center items-center rounded-full p-2 bg-taxes-100">
        <CheckIcon className="w-4 h-4 text-taxes" />
      </div>
      <span className="text-taxes font-semibold text-base">{benefitName}</span>
    </li>
  );
};

const DowngradeBanner: FC = () => {
  const layout = useContext(layoutContext);
  const { isLoading: isRedirectingToCustomerPortal } =
    useRedirectToCustomerBillingPortal();
  const plan = useSubscriptionPlan();
  const planNickName = last((plan?.planInternalName || '')?.split('.'));
  const isSmall = plan?.planInternalName === SubscriptionPlanNameEnum.small1;
  const monthlySmallUpgradeCopy = useFeatures('taxes.monthlySmallUpgradeCopy');
  const { t } = useTranslation();
  const { dismissBanner } = useContext(AppBannersContext);
  const navigate = useCustomNavigate();
  return (
    <div
      className={classNames('fixed z-50 right-4 top-4 ')}
      style={{ left: layout.sidebarArea }}
    >
      <div className="relative overflow-hidden masked bg-taxes flex justify-between py-5 items-center transition-colors shadow-filters rounded-xl">
        <Downgrade
          className="absolute scale-90 -top-1.5 -left-1.5"
          width="70"
          height="81"
        />
        <div className="flex items-center justify-between pl-24 flex-1">
          <span className="text-white font-semibold text-lg">
            {t('downgrade_banner.warning', {
              date: dayjs(plan?.end).format('DD/MM/YYYY'),
            })}
          </span>
          <div className="flex gap-4 items-center mx-8">
            <Modal
              width="2xl"
              name="downgrade-banner"
              color="taxes"
              trigger={(api) => (
                <Button
                  loading={isRedirectingToCustomerPortal}
                  onClick={() => api?.openModal()}
                  className="!bg-white !text-base hover:!bg-white !text-taxes-900"
                >
                  {t('downgrade_banner.see_what_you_lose')}
                </Button>
              )}
              render={(api) => {
                return (
                  <div className="px-10 py-2">
                    <div className="flex justify-center items-center mb-4">
                      <div className="bg-taxes-100 rounded-full p-4">
                        <ArrowDownIcon className="w-6 h-6 text-main" />
                      </div>
                    </div>
                    <Title
                      type={SizesEnum.h1}
                      className="text-main text-center text-2xl mb-2"
                    >
                      {t('downgrade_banner.you_lose')}
                    </Title>
                    <p className="mb-4 text-main !text-base">
                      {t('downgrade_banner.lost_benefits', {
                        plan: t(
                          `subscriptions_screen.plans.${planNickName?.replace(
                            /[0-9]/g,
                            '',
                          )}`,
                        ),
                        date: dayjs(plan?.end).format('DD/MM/YYYY'),
                      })}
                    </p>
                    <ul className="list-disc list-inside mb-4">
                      <ReactMarkdown
                        components={{
                          li: ({ children }) => (
                            <LostBenefitItem
                              key={children as string}
                              benefitName={children as string}
                            />
                          ),
                        }}
                      >
                        {t('downgrade_banner.lost_benefits_list')}
                      </ReactMarkdown>
                    </ul>
                    <p className="mb-12 text-main !text-base">
                      <ReactMarkdown
                        components={{
                          a: ({ children }) => (
                            <Button
                              color="taxes"
                              structure="text"
                              className="underline"
                              onClick={() => navigate('/subscriptions')}
                            >
                              {children}
                            </Button>
                          ),
                        }}
                      >
                        {t('downgrade_banner.renew_subscription')}
                      </ReactMarkdown>
                    </p>
                    <div className="flex gap-4">
                      <Button
                        color="taxes"
                        className="flex-1"
                        onClick={() => api.closeModal()}
                        structure="secondary"
                      >
                        {t('beta_opt_in.no_thanks')}
                      </Button>
                      <Button
                        color="taxes"
                        className="flex-1"
                        onClick={() => navigate('/subscriptions')}
                      >
                        {t('keywords.keyword_renew')}
                      </Button>
                    </div>
                  </div>
                );
              }}
            />
            <Button
              structure="secondary"
              className="!border-white !text-base !bg-transparent !text-white"
              onClick={() =>
                dismissBanner(BANNERS_STORAGE_KEYS.DOWNGRADE_BANNER_DISMISS_KEY)
              }
            >
              {t('close')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DowngradeBanner;
