import type { FC } from 'react';
import { Navigate } from 'react-router-dom';

const OnlyBeforeAuthRoute: FC<{ isAuthenticated: boolean; children: any }> = ({
  isAuthenticated,
  children,
}) => {
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export default OnlyBeforeAuthRoute;
