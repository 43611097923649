import { CellsModifiers, CustomerCells } from 'types/CellsModifiers.types';

const customers = {
  phoneNumberPlaceholder: '+49',
  list: {
    cells: {
      _modifiers: [{ name: CellsModifiers.customers }],
      default_sort: {
        web: 'customersName_asc',
        experts: 'customersName_asc',
      },
      web: [],
      experts: [
        CustomerCells.customer,
        CustomerCells.type,
        CustomerCells.companyName,
        CustomerCells.access,
      ],
    },
  },
};

export default customers;
