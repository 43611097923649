export enum CellsModifiers {
  expenses = 'EXPENSES_CELLS_MODIFIER',
  invoices = 'INVOICES_CELLS_MODIFIER',
  clients = 'CLIENTS_CELLS_MODIFIER',
  transactions = 'TRANSACTIONS_CELLS_MODIFIER',
  taxes = 'TAXES_CELLS_MODIFIER',
  customers = 'CUSTOMERS_CELLS_MODIFIER',
}

export enum CustomerCells {
  customer = 'CUSTOMER',
  type = 'TYPE',
  companyName = 'COMPANY_NAME',
  access = 'ACCESS',
}
