import api from 'api/axiosInstance';
import type { Paging } from 'types/pagination.types';
import type { Transaction } from 'types/transactions.types';

const hasTransactions = (): Promise<boolean> =>
  api
    .get<
      any,
      {
        data: Transaction[];
        paging: Paging;
      }
    >('/v1/transactions?fields=_id&page=1&perPage=1')
    .then(({ paging }) => paging.totalCount >= 1);

export default hasTransactions;
