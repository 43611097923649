import SVGIconRenderer from 'assets/svg/icons/revenues/SVGIconRenderer';
import { Select } from 'components/atoms/Form/Select';
import WarningModalContent from 'components/organisms/WarningModalContent';
import type { FieldProps } from 'components/pages/Expenses/Form/Fields/commonFieldProps.types';
import useFeatures from 'hooks/useFeatures';
import useModal from 'hooks/useModal';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientTypeEnum } from 'types/clients.types';
import { getTracingEvents } from 'utils/amplitude';

const UserType: FC<FieldProps> = ({
  value,
  name,
  onChange,
  tracing,
  error,
}) => {
  const { t } = useTranslation();
  const clientTypes = useFeatures('clients.client_types');

  const [modalContent, modalApi] = useModal({
    dialogPanalClassName: '!p-0',
    width: '3xl',
    name: 'Client type',
  });

  // const customerCountry = useCustomerCountry();

  const options = useMemo(
    () =>
      [
        {
          title: t('client.field.type.modal.options.business.title'),
          description: t(
            'client.field.type.modal.options.business.description',
          ),
          value: ClientTypeEnum.business,
          icon: <SVGIconRenderer icon="cashRegistry" className="w-10 mt-2" />,
          isSelected: value === ClientTypeEnum.business,
        },
        {
          title: t('client.field.type.modal.options.private_individual.title'),
          description: t(
            'client.field.type.modal.options.private_individual.description',
          ),
          value: ClientTypeEnum.private,
          icon: <SVGIconRenderer icon="face2" className="w-10 mt-2" />,
          isSelected: value === ClientTypeEnum.private,
        },
        {
          title: t('client.field.type.modal.options.diplomatic.title'),
          description: t(
            'client.field.type.modal.options.diplomatic.description',
          ),
          value: ClientTypeEnum.diplomatic,
          icon: <SVGIconRenderer icon="embassy" className="w-10 mt-2" />,
          isSelected: value === ClientTypeEnum.diplomatic,
        },
      ].filter((option) => clientTypes.includes(option.value)),
    [clientTypes, value],
  );

  const selected =
    options.find((option) => option.value === value)?.title || null;

  const tracingEvents = getTracingEvents(tracing, { onChange });

  return (
    <>
      {modalContent}
      <Select
        error={error}
        name={name}
        value={selected}
        openMenuOnClick
        isSearchable={false}
        noOptionsMessage={() => null}
        placeholder=""
        onMenuOpen={() => {
          tracingEvents.onMenuOpen();
          modalApi.open(() => (
            <WarningModalContent
              title={t('client.field.type.modal.title')}
              options={options}
              hideBGImage
              onChoose={(value) => {
                tracingEvents.onChange(value);
                modalApi.close();
              }}
            />
          ));
        }}
      />
    </>
  );
};

export default UserType;
