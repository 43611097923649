import Dropzone_onBoardingAnimated from 'assets/images/Dropzone_Preview.gif';
import Dropzone_onBoardingStatic from 'assets/images/Dropzone_Static_2x.png';
import dropzoneSuccessImg from 'assets/images/svgs/dropzone-success.svg';
import dropzoneImg from 'assets/images/svgs/dropzone.svg';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import type { dropzoneMeta } from 'components/molecules/Dropzone/Dropzone.types';
import type { fileUploaderMeta } from 'components/organisms/FileUploader/FileUploader.types';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import { get } from 'lodash';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import type { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import { maxFileSize } from 'utils/constants';

const getDropzoneMsg = (
  dropzoneMeta: dropzoneMeta,
  fileUploaderMeta: fileUploaderMeta,
) => {
  if (fileUploaderMeta.done) return 'expenses.dropzone.title.success';
  if (dropzoneMeta.isDragActive) return 'expenses.dropzone.cta';
  return 'expenses.dropzone.title.not_started';
};

const getFakeInvoiceLink = (fileName: string) => window.origin + '/' + fileName;

export const DropzoneContent: FC<{
  dropzoneMeta: dropzoneMeta;
  fileUploaderMeta: fileUploaderMeta;
}> = (props) => {
  const { dropzoneMeta, fileUploaderMeta } = props;
  const { t } = useTranslation();
  const showFileSizeWarning = get(dropzoneMeta, 'fileRejections')?.some(
    (rejection: any) => rejection.file.size > maxFileSize,
  );

  return (
    <>
      <img
        alt=""
        className="h-20"
        src={fileUploaderMeta.done ? dropzoneSuccessImg : dropzoneImg}
      />
      <p className="mt-6 mb-1 text-xl lg:text-2xl text-primary-700 font-avenir font-semibold text-center w-3/4">
        {t(getDropzoneMsg(dropzoneMeta, fileUploaderMeta))}
      </p>
      <span className="text-expenses hover:text-expenses-800 font-semibold text-center">
        {t('expenses.dropzone.suggestion')}
      </span>
      {showFileSizeWarning && (
        <span className="bg-yellow-100 text-sm mt-2 px-1">
          {t('warning_file_to_big')}
        </span>
      )}
    </>
  );
};

const getDropzoneOnBoardMsg = (
  dropzoneMeta: dropzoneMeta,
  fileUploaderMeta: fileUploaderMeta,
) => {
  if (dropzoneMeta.isDragActive) return 'expenses.dropzone.cta';
  if (!fileUploaderMeta?.isEmpty) return 'expenses.dropzone.cta2';
  return 'onboarding_expenses.dropzone.title.not_started';
};

const markdownComponentsContent1 = {
  p: ({ children }: ReactMarkdownProps) => <>{children}</>,
  em: ({ children }: ReactMarkdownProps) => (
    <span className="text-primary-700 font-avenir tracking-tight pr-1">
      {children}
    </span>
  ),
};

export const DropzoneOnBoardingContent: FC<{
  dropzoneMeta: dropzoneMeta;
  fileUploaderMeta: fileUploaderMeta;
  processFile?: any;
  hideFakeReceipt?: boolean;
  imgProps?: Record<string, any>;
  titleClassNames?: string;
}> = (props) => {
  const {
    dropzoneMeta,
    fileUploaderMeta,
    hideFakeReceipt,
    imgProps = {},
    titleClassNames,
  } = props;
  const { data: user } = useGetCurrentCustomerData();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const showFileSizeWarning = get(dropzoneMeta, 'fileRejections')?.some(
    (rejection: any) => rejection.file.size > maxFileSize,
  );

  const invoiceFile = `${user?.country}_${language}.pdf`;

  const createFakeInvoice = async () => {
    const file = await fetch(getFakeInvoiceLink(invoiceFile))
      .then((res) => res.blob())
      .then(
        (blob) =>
          new File([blob], invoiceFile, {
            type: 'application/pdf',
          }),
      );

    props.processFile?.(file, `${invoiceFile}${new Date().toISOString()}`, {
      isFake: true,
    });
  };

  return (
    <>
      <img
        alt=""
        width="288px"
        height="288px"
        src={
          dropzoneMeta.isHovered
            ? Dropzone_onBoardingAnimated
            : Dropzone_onBoardingStatic
        }
        {...imgProps}
        className={classNames('w-[18rem] h-[18rem]', imgProps.className)}
      />
      <p
        className={classNames(
          'mb-6 text-2xl lg:text-3xl text-primary-700 font-avenir font-semibold text-center w-3/4 tracking-tight',
          titleClassNames,
        )}
      >
        {t(getDropzoneOnBoardMsg(dropzoneMeta, fileUploaderMeta))}
      </p>
      {fileUploaderMeta.isEmpty && !hideFakeReceipt && (
        <p className="mb-6 block text-primary-700 font-avenir tracking-tight">
          <button
            type="button"
            className="text-expenses relative p-10 -m-10 flex item-center"
            onClick={(e) => {
              e.stopPropagation();
              createFakeInvoice();
            }}
          >
            <ReactMarkdown components={markdownComponentsContent1}>
              {t('onboarding_expenses.dropzone.suggest_fake_invoice')}
            </ReactMarkdown>
          </button>
        </p>
      )}

      {showFileSizeWarning && (
        <span className="bg-yellow-100 text-sm mt-2 px-1">
          {t('warning_file_to_big')}
        </span>
      )}
    </>
  );
};

const markdownComponentsContent2 = {
  em: ({ children }: ReactMarkdownProps) => {
    return <span className="text-expenses relative">{children}</span>;
  },
};

export const DropzoneOnBoardingContent2: FC<{
  dropzoneMeta: dropzoneMeta;
  fileUploaderMeta: fileUploaderMeta;
  message?: ReactNode;
}> = (props) => {
  const { dropzoneMeta, message, fileUploaderMeta } = props;
  const { t } = useTranslation();
  const showFileSizeWarning = get(dropzoneMeta, 'fileRejections')?.some(
    (rejection: any) => rejection.file.size > maxFileSize,
  );

  return (
    <div
      className={classNames('w-full flex items-center', {
        'py-6 px-8 lg:py-10 lg:px-14 flex-row gap-2': fileUploaderMeta.isEmpty,
        'pb-6 flex-col text-center': !fileUploaderMeta.isEmpty,
      })}
    >
      <img
        alt=""
        className="hidden md:block w-[250px] h-[250px] lg:w-[350px] lg:h-[350px]"
        src={
          dropzoneMeta.isHovered
            ? Dropzone_onBoardingAnimated
            : Dropzone_onBoardingStatic
        }
      />
      <div
        className={classNames({
          'w-3/4': !fileUploaderMeta.isEmpty,
        })}
      >
        {message}
        <p className="text-2xl lg:text-3xl mb-2 text-primary-700 font-avenir font-semibold tracking-tight">
          {t(getDropzoneOnBoardMsg(dropzoneMeta, fileUploaderMeta))}
        </p>
        <span className="block text-primary-700 font-avenir tracking-tight">
          <ReactMarkdown components={markdownComponentsContent2}>
            {t('onboarding_expenses.dropzone.suggestion_new')}
          </ReactMarkdown>
        </span>
        {showFileSizeWarning && (
          <span className="block bg-yellow-100 text-sm mt-2 px-1">
            {t('warning_file_to_big')}
          </span>
        )}
      </div>
    </div>
  );
};

export const FilesListHeader: FC<{ fileUploaderMeta: fileUploaderMeta }> = (
  props,
) => {
  const { fileUploaderMeta } = props;
  const { t } = useTranslation();
  if (!fileUploaderMeta.done) return null;
  return (
    <div className="pb-5">
      <p className="text-primary-900 text-base font-assistant">
        {t('expenses.dropzone.import_success')}
      </p>
      <p className="text-primary-900 text-base font-assistant">
        {t('expenses.dropzone.further_instructions')}
      </p>
    </div>
  );
};

export const FilesListFooter: FC<{
  fileUploaderMeta: fileUploaderMeta;
  disableReviewLaterUntilDone?: boolean;
  onReviewLater?: (fileUploaderMeta: fileUploaderMeta) => void;
  onReviewNow?: (fileUploaderMeta: fileUploaderMeta) => void;
}> = (props) => {
  const {
    fileUploaderMeta,
    onReviewLater,
    onReviewNow,
    disableReviewLaterUntilDone = false,
  } = props;
  const { done } = fileUploaderMeta;
  const { t } = useTranslation();
  const isDisabled = !done;
  const isReviewLaterDisabled = disableReviewLaterUntilDone && !done;
  return (
    <div className={'flex gap-3 pt-5 pb-1.5 justify-end'}>
      <Button
        disabled={isDisabled}
        onClick={() => onReviewNow?.(fileUploaderMeta)}
      >
        {t('expenses.dropzone.review_now')}
      </Button>
      <Button
        structure="text"
        disabled={isReviewLaterDisabled}
        onClick={() => onReviewLater?.(fileUploaderMeta)}
      >
        {t('expenses.dropzone.review_later')}
      </Button>
    </div>
  );
};
