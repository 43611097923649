const calcEndOfAmortizationPeriod = (
  date: string,
  amortizationPeriod: number | undefined,
) => {
  if (typeof amortizationPeriod !== 'number' || amortizationPeriod === 0) {
    return 0;
  }

  return new Date(date).getFullYear() + amortizationPeriod / 12;
};

export default calcEndOfAmortizationPeriod;
