import type { StoreApi, UseBoundStore } from 'zustand';
import create from 'zustand';
import { stateCreator } from './helpers';
import type { TableState, TableStateFactory } from './table.state.types';

/**
 *
 * this store is responsible for managing table state that is related to user inputs
 * like pagination change, selecting a row, and sorting, extra?..
 */

const tableState = ({
  filtersData,
  tableSorter,
}: TableStateFactory): UseBoundStore<StoreApi<TableState>> => {
  return create(
    stateCreator({ filtersData, tableSorter, registerCleanup: true }),
  );
};

export default tableState;
