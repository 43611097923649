import type {
  Transaction,
  TransactionAsOption,
} from 'types/transactions.types';

const transactionToOption = (
  transaction: Transaction,
): TransactionAsOption => ({
  value: transaction._id,
  transaction,
});

export default transactionToOption;
