import { useCallback, useEffect, useMemo, useState } from 'react';
import { dateToDateNumber } from './Calendar';

export type BaseDateRangeProps = {
  onChange: (range: [number, number]) => unknown;
  value?: [number, number];
  getInitialValue?: () => [Date, Date] | null;
};

const useDateRange = (props: BaseDateRangeProps) => {
  const { onChange, value, getInitialValue } = props;

  const [range, setRange] = useState<[Date, Date] | null>(() => {
    if (Array.isArray(value)) return [new Date(value[0]), new Date(value[1])];

    if (getInitialValue) return getInitialValue();

    return null;
  });

  const [selectedRange, setSelectedRange] = useState<[Date, Date] | null>(
    range,
  );

  useEffect(() => {
    if (selectedRange) {
      onChange([
        dateToDateNumber(selectedRange[0]),
        dateToDateNumber(selectedRange[1]),
        // why do we apply 36 hours to the end date? ask @Mokhtar
        // dateToDateNumber(new Date(new Date(selectedRange[1]).setHours(36))),
      ]);
    }
  }, [selectedRange]);

  const [month, setMonth] = useState(
    () => (range?.[0] ?? new Date()).getMonth() + 1,
  );

  const [year, setYear] = useState(() =>
    (range?.[0] ?? new Date()).getFullYear(),
  );

  const previousYear = useCallback(() => setYear((year) => year - 1), []);
  const nextYear = useCallback(() => setYear((year) => year + 1), []);

  const previousMonth = useCallback(() => {
    setMonth((month) => {
      if (month == 1) {
        previousYear();
        return 12;
      } else {
        return month - 1;
      }
    });
  }, [previousYear]);

  const nextMonth = useCallback(() => {
    setMonth((month) => {
      if (month == 12) {
        nextYear();
        return 1;
      } else {
        return month + 1;
      }
    });
  }, [nextYear]);

  const setRangeFromText = useCallback((range: [Date, Date]): void => {
    setRange(range);
    setSelectedRange(range);

    setMonth(range[0].getMonth() + 1);
    setYear(range[0].getFullYear());
  }, []);

  const now = useMemo(() => new Date(), []);

  const thisYear = useMemo(() => {
    const start = new Date(now.getFullYear(), 0, 1, 12);
    const end = new Date(now.getFullYear(), 11, 31, 12);

    return [start, end];
  }, [now]);

  const lastYear = useMemo(() => {
    const start = new Date(now.getFullYear() - 1, 0, 1, 12);
    const end = new Date(now.getFullYear() - 1, 11, 31, 12);

    return [start, end];
  }, [now]);

  const lastQuarter = useMemo(() => {
    const quarter = Math.floor(now.getMonth() / 3) - 1;

    const start = new Date(now.getFullYear(), quarter * 3, 1, 12);
    const end = new Date(now.getFullYear(), quarter * 3 + 3, 1, 12);
    end.setDate(end.getDate() - 1);

    return [start, end];
  }, [now]);

  const isThisYear = useMemo(() => {
    if (!range) return false;

    return (
      range[0].getTime() == thisYear[0].getTime() &&
      range[1].getTime() == thisYear[1].getTime()
    );
  }, [range, thisYear]);

  const isLastYear = useMemo(() => {
    if (!range) return false;

    return (
      range[0].getTime() == lastYear[0].getTime() &&
      range[1].getTime() == lastYear[1].getTime()
    );
  }, [range, lastYear]);

  const isLastQuarter = useMemo(() => {
    if (!range) return false;

    return (
      range[0].getTime() == lastQuarter[0].getTime() &&
      range[1].getTime() == lastQuarter[1].getTime()
    );
  }, [range, lastQuarter]);

  return {
    range,
    setRange,
    selectedRange,
    setSelectedRange,
    month,
    year,
    previousYear,
    nextYear,
    previousMonth,
    nextMonth,
    setRangeFromText,
    setMonth,
    setYear,
    thisYear,
    lastYear,
    lastQuarter,
    isThisYear,
    isLastYear,
    isLastQuarter,
  };
};

export default useDateRange;
