import type { Dayjs, ManipulateType } from 'dayjs';
import dayjs from 'dayjs';
import type {
  Month,
  Period,
  PeriodTimeFrameType,
  Quarter,
} from 'types/global.types';
import type { VATReturnFrequency } from 'types/users.types';

type RangeType = { start: () => Dayjs; end: () => Dayjs };

const getPreviousMonth = (currentMonth: number): number =>
  currentMonth === 1 ? 12 : currentMonth - 1;

const getPreviousMonthYear = (previousMonth: number, year: number): number =>
  previousMonth === 12 ? year - 1 : year;

const getPreviousYear = (year: number): number => year - 1;

const getPreviousQuarter = (currentQuarter: number): number =>
  currentQuarter === 1 ? 4 : currentQuarter - 1;

const getPreviousQuarterYear = (
  previousQuarter: number,
  year: number,
): number => (previousQuarter === 4 ? year - 1 : year);

const startOfQuarter = ({ value, year }: PeriodTimeFrameType): Dayjs =>
  dayjs().quarter(value).year(year).startOf('quarter');
const endOfQuarter = ({ value, year }: PeriodTimeFrameType): Dayjs =>
  dayjs().quarter(value).year(year).endOf('quarter').add(1, 'day');
const startOfYear = ({ year }: PeriodTimeFrameType): Dayjs =>
  dayjs().year(year).startOf('year');
const endOfYear = ({ year }: PeriodTimeFrameType): Dayjs =>
  dayjs().year(year).endOf('year');
const startOfMonth = ({ value, year }: PeriodTimeFrameType): Dayjs =>
  dayjs()
    .year(year)
    .month(value - 1)
    .startOf('month');
const endOfMonth = ({ value, year }: PeriodTimeFrameType): Dayjs =>
  dayjs()
    .year(year)
    .month(value - 1)
    .endOf('month')
    .subtract(1, 'day');

const periodRange = (period: PeriodTimeFrameType): RangeType => {
  const frequencyMap = {
    monthly: {
      start: () => startOfMonth(period),
      end: () => endOfMonth(period),
    },
    quarterly: {
      start: () => startOfQuarter(period),
      end: () => endOfQuarter(period),
    },
    yearly: {
      start: () => startOfYear(period),
      end: () => endOfYear(period),
    },
  };

  return frequencyMap[period.frequency as keyof typeof frequencyMap];
};

const getPeriodFromDate = (
  returnFrequency: VATReturnFrequency,
  date?: Date | Dayjs | string,
): Period => {
  const year = dayjs(date).year();
  const month = (dayjs(date).month() + 1) as Month;
  const quarter = dayjs(date).quarter() as Quarter;

  const frequencyMap = {
    monthly: {
      month,
      year,
    },
    quarterly: {
      quarter,
      year,
    },
    yearly: {
      year,
    },
  };

  return (
    frequencyMap[returnFrequency as keyof typeof frequencyMap] ||
    frequencyMap.quarterly
  );
};

const getPreviousPeriod = (
  currentPeriod: Period,
  returnFrequency: VATReturnFrequency,
): Period => {
  // TODO: USE DAYJS TO HANDLE IT
  const previousMonth = getPreviousMonth(
    currentPeriod.month as number,
  ) as Month;
  const previodMonthsYear = getPreviousMonthYear(
    previousMonth,
    currentPeriod.year,
  );
  const previousYear = getPreviousYear(currentPeriod.year);
  const previousQuarter = getPreviousQuarter(
    currentPeriod.quarter as number,
  ) as Quarter;
  const previousQuartersYear = getPreviousQuarterYear(
    previousQuarter,
    currentPeriod.year,
  ) as Quarter;

  const frequencyMap = {
    monthly: {
      month: previousMonth,
      year: previodMonthsYear,
    },
    quarterly: {
      quarter: previousQuarter,
      year: previousQuartersYear,
    },
    yearly: {
      year: previousYear,
    },
  };

  return (
    frequencyMap[returnFrequency as keyof typeof frequencyMap] ||
    frequencyMap.quarterly
  );
};

// https://github.com/iamkun/dayjs/issues/1162#issuecomment-1694654608
const dayjsRange = (
  start: Dayjs,
  end: Dayjs,
  step: [number, ManipulateType],
) => {
  const range = [];
  let current = start;
  while (!current.isAfter(end)) {
    range.push(current);
    current = current.add(step[0], step[1]);
  }
  return range;
};

export {
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  periodRange,
  getPeriodFromDate,
  getPreviousMonth,
  getPreviousMonthYear,
  getPreviousYear,
  getPreviousQuarter,
  getPreviousQuarterYear,
  getPreviousPeriod,
  dayjsRange,
};
