import { useEffect, useState } from 'react';

const useDebounce = (timer: number, state: any) => {
  const [lastState, setLastState] = useState(state);

  useEffect(() => {
    const id = setTimeout(() => {
      setLastState(state);
    }, timer);
    return () => clearTimeout(id);
  }, [state]);

  return lastState;
};

export default useDebounce;
