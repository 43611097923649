import api from 'api/axiosInstance';
import { getLocationOrigin } from 'utils/helpers';

const createCheckoutSession = (
  customerId: string,
  planId: string,
  promocode: string,
): Promise<{ id: string }> =>
  api.post('/v1/subscriptions/stripe/subscribe', {
    priceId: planId,
    success_url: `${getLocationOrigin(true)}/expenses?ps=true`,
    cancel_url: `${getLocationOrigin(true)}/paymentFailed`,
    promocode,
  });
export default createCheckoutSession;
