import i18n from 'i18n';
import type { ITax } from 'types/taxes.types';
import type { UserCountry } from 'types/users.types';

const getTitleIntracomItem = (
  tax: Partial<ITax>,
  country: UserCountry,
): string =>
  i18n.t(`taxes.intracom.vat_intraeu.${country}`, {
    quarter: tax.period && tax.period.quarter,
    year: tax.period && tax.period.year,
  });

export default getTitleIntracomItem;
