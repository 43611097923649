enum ClientTypeEnum {
  business = 'business',
  private = 'private',
  diplomatic = 'diplomatic',
}

enum ClientVATStatusEnum {
  subjectToVAT = 'subjectToVAT',
  franchisee = 'franchisee',
  exempt = 'exempt',
}

enum ClientLocationEnum {
  local = 'local',
  intra_eu = 'intra-eu',
  extra_eu = 'extra-eu',
}
export type OtherRevenueClient = {
  _id?: string;
  id?: string;
  name: string;
  location: ClientLocationEnum;
  type: undefined;
};
interface IClient {
  name: string;
  email?: string;
  type: ClientTypeEnum;
  steuernummer?: string;
  VATNumber: string;
  address?: {
    street: string;
    city: string;
    zip: string;
    country: string;
  };
  location: ClientLocationEnum;
  userId: string;
  _id: string;
  contactName?: string;
}

type ClientVATStatus = 'subjectToVAT' | 'franchisee' | 'exempt' | null;

type ClientLocation = 'local' | 'intra-eu' | 'extra-eu';

type RevenueClient = IClient;

export {
  ClientTypeEnum,
  ClientVATStatusEnum,
  ClientLocationEnum,
  IClient,
  ClientVATStatus,
  ClientLocation,
  RevenueClient,
};
