import type { CancelToken } from 'axios';
import axios from 'axios';
import type {
  QueryFunctionContext,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useQuery } from 'react-query';

type Options<Return> = Omit<UseQueryOptions, 'queryFn'> & {
  queryFn: (
    context: QueryFunctionContext<string | readonly unknown[], any>,
    cancelToken: CancelToken,
  ) => Promise<Return> | Return;
  debounceTime: number;
};

function useDebounceQuery<Return = any>(options: Options<Return>) {
  return useQuery({
    ...options,
    queryFn: (context) => {
      let cancelId: NodeJS.Timeout | undefined;
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = new Promise((resolve, reject) => {
        cancelId = setTimeout(() => {
          Promise.resolve(options.queryFn(context, source.token))
            .then(resolve)
            .catch(reject);
        }, options.debounceTime);
      });
      (promise as any).cancel = () => {
        clearTimeout(cancelId);
        source.cancel();
      };
      return promise;
    },
  }) as UseQueryResult<Return>;
}

export default useDebounceQuery;
