import { getExpenseTaxImpact } from 'api/v2/expenses';
import { getRevenueTaxImpact } from 'api/v2/revenues';
import { cleanExpenseDataForTaxImpact } from 'components/pages/Expenses/Form/utils';
import { cleanInvoiceForTaxImpact } from 'components/pages/Revenues/OtherRevenueForm/utils';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { TaxesCacheKeys } from 'types/cacheKeys.types';
import type { IExpense } from 'types/expenses.types';
import type { IRevenue } from 'types/revenues.types';
import { TaxImpactDocumentType } from 'types/taxes.types';
import { ONE_MINUTE } from 'utils/time';

const useTaxImpact = ({
  documentData,
  documentType,
}: {
  documentData: IRevenue | IExpense;
  documentType: TaxImpactDocumentType;
}) => {
  const apiCallback = useMemo(() => {
    if (documentType === TaxImpactDocumentType.EXPENSE) {
      return getExpenseTaxImpact;
    }
    return getRevenueTaxImpact;
  }, [documentType]);

  const modifiedDocData = useMemo(() => {
    if (documentType === TaxImpactDocumentType.EXPENSE) {
      return cleanExpenseDataForTaxImpact(documentData as any);
    }

    return cleanInvoiceForTaxImpact(documentData as any);
  }, [documentData, documentType]);

  return useQuery(
    [TaxesCacheKeys.TaxImpact, documentType, modifiedDocData],
    () => apiCallback(modifiedDocData as any),
    {
      cacheTime: ONE_MINUTE * 5,
    },
  );
};

export default useTaxImpact;
