import { createExpertRouteURL } from 'applications/expert/routing/navigationGenerator';
import { isWeb } from 'utils/constants';
import useGetCurrentCustomerData from './Authentication/useGetCurrentCustomerData';

const useGetCustomNavigationLink = () => {
  const { data: customer } = useGetCurrentCustomerData();

  return (url: string) => {
    return isWeb ? url : createExpertRouteURL(customer?._id as string, url);
  };
};

export default useGetCustomNavigationLink;
