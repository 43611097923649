enum SettingsViewsEnum {
  personalDetails = 'personalDetails',
  changePassword = 'changePassword',
  bankSettings = 'bank',
  dataAccess = 'data-access',
  invoicesRecurringJobs = 'invoices/recurring-jobs',
  invoicesReminders = 'invoices/reminders',
  billing = 'subscriptions',
  taxesSettings = 'taxesSettings',
  mfa = 'mfa',
  cars = 'cars',
  amortization = 'amortization',
  loginMethods = 'loginMethods',
  peppol = 'peppol',
  integration = 'integrations',
  members = 'members',
}

enum AccountSettingsFieldsNamesEnum {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
  // Profession
  occupation = 'occupation',
  APC1 = 'APC1',
  APC2 = 'APC2',
  //
  nationalRegistryNumber = 'nationalRegistryNumber',
  companyName = 'companyName',
  startDate = 'startDate',
  VATType = 'VATType',
  VATNumber = 'VATNumber',
  accountType = 'accountType',
  activity = 'activity',
  complementaryDays = 'complementaryDays',
  steuernummer = 'steuernummer',
  finanzamt = 'finanzamt',
  IBAN = 'IBAN',
  SWIFT = 'SWIFT',
  address = 'address',
  street = 'address.street',
  city = 'address.city',
  zip = 'address.zip',
  municipality = 'address.municipality',
  _country = 'address.country',
  state = 'address.state',
  country = 'country',
  settings = 'settings',
  emailAliases = 'settings.emailAliases',
  cashAvailable = 'settings.cashAvailable',
  datev = 'settings.datev',
  invoices = 'settings.invoices',
  taxes = 'settings.taxes',
  photoPath = 'photoPath',
  deleteAccountReason = 'deleteAccountReason',
  deleteAccountOtherReason = 'deleteAccountOtherReason',
  language = 'language',
  firstTaxComputationYear = 'firstTaxComputationYear',
  professionType = 'professionType',
}

enum ChangePasswordFieldsNamesEnum {
  oldPassword = 'oldPassword',
  newPassword = 'newPassword',
  confirmPassword = 'confirmPassword',
}

enum SetPasswordFieldsNamesEnum {
  password = 'password',
  confirmPassword = 'confirmPassword',
}

export {
  SettingsViewsEnum,
  AccountSettingsFieldsNamesEnum,
  ChangePasswordFieldsNamesEnum,
  SetPasswordFieldsNamesEnum,
};
