import axiosInstance from 'api/axiosInstance';
import dayjs from 'dayjs';
import { stringify } from 'query-string';
import type {
  IExpense,
  Supplier,
  SupplierAsOption,
} from 'types/expenses.types';
import type { CategoryAPI } from 'types/expensesCategory.types';
import type { Period } from 'types/global.types';
import type { UserCountry, UserLanguage } from 'types/users.types';
import { onCreateExpenseGTM } from 'utils/GTM';
import { FIVE_MINUTES, TWO_MINUTES } from 'utils/time';

const expensesService = '/v1/expenses';
const calcService = '/v1/calculations/expenses';

export const createExpense = (filename: string, isFake?: boolean) => {
  return axiosInstance
    .post<any, Promise<IExpense>>(
      `${expensesService}/dropzone-import?cache=${Date.now()}`,
      { filename, isFake },
      {
        timeout: FIVE_MINUTES,
      },
    )
    .then((data) => {
      onCreateExpenseGTM();
      return data;
    });
};

export const getCurrentYearDeductibleAmount = (): Promise<{
  amount: number;
}> => {
  const query = {
    year: dayjs().year(),
    endDate: dayjs().format('YYYY-MM-DD'),
  };
  return axiosInstance.get(`${calcService}/deductible?${stringify(query)}`);
};

export const getCurrentPeriodDeductibleAmount = (
  params: Period & { endDate: string },
): Promise<{
  amount: number;
}> => {
  return axiosInstance.get(`${calcService}/deductible?${stringify(params)}`);
};

export const getSuppliers = (name: string): Promise<Supplier[]> => {
  return axiosInstance.get(
    `${expensesService}/guessMerchantData?name=${encodeURIComponent(name)}`,
  );
};

export const getSuppliersOptions = (
  name: string,
): Promise<SupplierAsOption[]> => {
  return getSuppliers(name).then((suppliers) =>
    suppliers.map((supplier) => ({
      label: supplier.name,
      value: supplier.name,
      supplier,
    })),
  );
};

export const getCategories = (
  country: UserCountry,
  lang: UserLanguage,
  params?: Record<string, any>,
): Promise<CategoryAPI[]> => {
  return axiosInstance
    .get(
      `${expensesService}/categories/${country}?${stringify({
        lang,
        raw: true,
      })}`,
      params,
    )
    .then(({ data }) => data);
};

export const getExpenseDuplicates = (
  expenseId: string,
): Promise<(IExpense & { duplicateReason?: string })[]> => {
  return axiosInstance
    .get<
      any,
      {
        duplicates: IExpense[];
        types: { expenseId: string; type: string }[];
      }
    >(
      `${expensesService}/${expenseId}/duplicates?${stringify({
        perPage: 10,
        page: 1,
        expand: ['taxLock'],
      })}`,
      {
        timeout: TWO_MINUTES,
      },
    )
    .then((data) => {
      const reasons = {} as Record<string, string>;
      data.types.forEach((type) => {
        reasons[type.expenseId] = type.type;
      });
      return data.duplicates.map((dup) => ({
        ...dup,
        duplicateReason: reasons[dup._id],
      }));
    });
};

export const getExpenseOCR = (filename: string): Promise<IExpense> =>
  axiosInstance.get(`${expensesService}/expenseOCR/${filename}`);
