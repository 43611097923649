import { IClient } from 'types/clients.types';
import { GeneratedInvoiceFormValues, Invoice } from 'types/invoice.types';

export const prepareRecurringInvoiceFromSavedInvoice = (
  values: Partial<Invoice<IClient>>,
) => {
  return {
    revenueId: values._id,
    revenueData: {
      client: values.client,
      clientId: values.clientId,
      currency: values.currency,
      din5008: values.din5008,
      comments: values.comments,
      items: values.items,
      language: values.userSnapshot?.settings?.invoices.language,
      settings: values.userSnapshot?.settings?.invoices,
      userSnapshot: values.userSnapshot,
      usePaymentQrCode:
        values.userSnapshot?.settings?.invoices.usePaymentQrCode,
      useCommunication:
        values.userSnapshot?.settings?.invoices.useCommunication,
    },
  };
};

export const prepareRecurringInvoiceFromFormValues = (
  values: GeneratedInvoiceFormValues,
) => {
  return {
    revenueId: values._id,
    revenueData: {
      client: values.client,
      clientId: values.clientId,
      currency: values.currency,
      din5008: values.din5008,
      comments: values.comments,
      items: values.items,
      language: values.settings.language,
      settings: values.settings,
      userSnapshot: values.user,
      usePaymentQrCode: values.settings.usePaymentQrCode,
      useCommunication: values.settings.useCommunication,
    },
  };
};
