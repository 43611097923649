import IPhone from 'components/atoms/SVG/IPhone.component';
import Bankicon from 'components/atoms/SVG/sidebarIcons/BankIcon.component';
import ChristmasIcon from 'components/atoms/SVG/sidebarIcons/ChristmasIcon.component';
import ExpensesIcon from 'components/atoms/SVG/sidebarIcons/ExpensesIcon.component';
import HomeIcon from 'components/atoms/SVG/sidebarIcons/HomeIcon.component';
import RevenuesIcon from 'components/atoms/SVG/sidebarIcons/RevenuesIcon.component';
import TaxesIcon from 'components/atoms/SVG/sidebarIcons/TaxesIcon.component';
import NewExpensesCount from 'components/organisms/Sidebar/CountBadges/NewExpensesCount.component';
import NewRevenuesCount from 'components/organisms/Sidebar/CountBadges/NewRevenuesCount.component';
import TaxesCount from 'components/organisms/Sidebar/CountBadges/TaxesCount.component';

import { SidebarNavigationItemTypesEnum } from 'components/organisms/Sidebar/types';
import AIAssistantSidebar from 'components/pages/AI/AIAssistantSidebar';
import { getReviewYears } from 'components/pages/YearlyReview/helpers';
import type { GrowthExperimentsWithABGroupsType } from 'store/ABExperiments.store';
import type { NavbarNavigationListType } from 'types/navbar.types';
import { type IUser } from 'types/users.types';
import { isEndOrBeginingOfAYear } from 'utils/helpers';
import appI18nInstance from '../pages/UserSignup/i18n';

const navigationGenerator = (
  _: string,
  experiments?: GrowthExperimentsWithABGroupsType,
  user?: IUser,
): NavbarNavigationListType => [
  {
    title: 'menu.home',
    to: '/',
    Icon: HomeIcon,
    type: SidebarNavigationItemTypesEnum.taxes,
  },
  {
    title: 'menu.bank',
    to: '/banks',
    Icon: Bankicon,
    type: SidebarNavigationItemTypesEnum.banks,
  },
  {
    title: 'menu.invoices',
    to: `/revenues`,
    children: [
      '/clients',
      '/revenues/create',
      '/quotes',
      '/revenues/:id',
      '/revenues/analytics',
      '/recurring-revenues',
      '/recurring-revenues/create',
      '/recurring-revenues/:id',
    ],
    Icon: RevenuesIcon,
    type: SidebarNavigationItemTypesEnum.revenues,
    BadgeComponent: NewRevenuesCount,
  },
  {
    title: 'menu.expenses',
    to: `/expenses`,
    children: ['/expenses/analytics'],
    Icon: ExpensesIcon,
    type: SidebarNavigationItemTypesEnum.expenses,
    BadgeComponent: NewExpensesCount,
  },
  {
    title: 'menu.taxes',
    to: `/taxes`,
    children: ['/taxes/analytics', '/taxes/:country/:taxSlug/:periodSlug'],
    Icon: TaxesIcon,
    type: SidebarNavigationItemTypesEnum.taxes,
    BadgeComponent: TaxesCount,
  },
  {
    title: 'yearly_review.title',
    renderTitle: () =>
      appI18nInstance.t('yearly_review.title', {
        year: getReviewYears().year,
      }),
    to: '/yearly-review',
    Icon: ChristmasIcon,
    type: SidebarNavigationItemTypesEnum.yearlyReview,
    condition: isEndOrBeginingOfAYear,
    placement: 'bottom',
  },
  {
    Component: <AIAssistantSidebar />,
    type: SidebarNavigationItemTypesEnum.AI,
    Icon: () => null,
    to: '/assistant',
    title: 'AI Assistant',
    placement: 'bottom',
  },
  {
    title: 'mobile_app.title',
    to: '/mobile',
    Icon: IPhone,
    type: SidebarNavigationItemTypesEnum.export,
    condition: !user?.hasLoggedInMobile,
    placement: 'bottom',
  },
];

export default navigationGenerator;
