import dayjs from 'dayjs';
import i18n from 'i18n';
import _capitalize from 'lodash/capitalize';
import type { Period } from 'types/global.types';

const formatPeriodLabel = (period: Period): string | null => {
  if (!period) {
    return null;
  }
  if (period?.month) {
    return (
      _capitalize(
        dayjs()
          .month(period.month - 1)
          .format('MMMM'),
      ) +
      ' ' +
      period.year
    );
  } else if (period?.quarter) {
    return i18n.t('taxes.filters.quarter_abr', {
      quarter: period.quarter,
      year: period.year,
    });
  } else {
    return String(period.year);
  }
};

export default formatPeriodLabel;
