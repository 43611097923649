// Run a function that returns a promise at most once at any given time, and
// reuse response for concurrent calls.
export function deparallelize<T>(fx: () => Promise<T>): () => Promise<T> {
  // Cached in-progress promise
  let promise: Promise<T> | null = null;

  return function () {
    // If no promise is in progress, create one, and make sure it is cleaned
    //  afterwards.
    if (promise == null) promise = fx().finally(() => (promise = null));

    // Return the currently in-progress promise
    return promise;
  };
}
