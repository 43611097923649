import type { CreateConnectorParams } from 'api/v2/connectors/createConnector';
import { createConnectorConnection } from 'api/v2/connectors/createConnector';
import useFeatures from 'hooks/useFeatures';
import { useMutation } from 'react-query';
import type { BankProviders } from 'types/banks.types';
import type { QP } from 'types/global.types';
import { appendQP } from 'utils/helpers';

const useCreateBankConnection = (qp: { numberOfConnectors: number } & QP) => {
  const provider = useFeatures('banks.provider') as BankProviders;
  return useMutation(
    (params?: Partial<CreateConnectorParams>) =>
      createConnectorConnection({
        provider,
        successRedirectUri: `${window.location.origin}${
          window.location.pathname
        }?${appendQP(window.location.search, qp)}`,
        errorRedirectUri: window.location.href,
        ...params,
      }),
    {
      onSuccess: ({ redirectUri }) => {
        window.location.href = redirectUri;
      },
    },
  );
};

export default useCreateBankConnection;
