import classNames from 'classnames';
import { FieldConnector } from 'components/atoms/Form/FieldConnector';
import FreeTextInput from 'components/atoms/Form/FreeTextInput/FreeTextInput.component';
import { Select } from 'components/atoms/Form/Select';
import NumberField from 'components/molecules/Fields/Number/Number';
import PaymentAmount from 'components/molecules/Fields/PaymentAmount/PaymentAmount.component';
import HelperComponent from 'components/molecules/Helper/Helper.component';
import type { FC } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { VehicleFuelTypeEnum } from 'types/vehicle.types';
import * as ExpensesEvents from 'utils/amplitude/events/expenses.amplitude';

type CarFormProps = {
  className?: string;
  formAPI: UseFormReturn;
};

const GermanCarsForm: FC<CarFormProps> = ({ className, formAPI }) => {
  const { t } = useTranslation();

  const fuelType = formAPI.watch('fuelType');

  return (
    <FormProvider {...formAPI}>
      <form className={classNames('flex flex-col gap-4', className)}>
        <FieldConnector name="name" label={t('german_car.form.name')}>
          {({ field }) => (
            <FreeTextInput
              tracing={{
                onFocus: ExpensesEvents.WEBAPP_VEHICLE_CLICKGERMANCARNAME,
              }}
              {...field}
            />
          )}
        </FieldConnector>
        <div className="flex gap-4">
          <FieldConnector
            className="flex-1 min-w-0"
            name="listPrice"
            label={t('german_car.form.list_price')}
            helper={
              <HelperComponent>
                <HelperComponent.Title>
                  {t('german_car.form.list_price.helper_q1')}
                </HelperComponent.Title>
                <HelperComponent.Paragraph>
                  <ReactMarkdown
                    components={{
                      a: ({ children, href }) => (
                        <a
                          href={href as string}
                          className="underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {children}
                        </a>
                      ),
                    }}
                  >
                    {t('german_car.form.list_price.helper_a1')}
                  </ReactMarkdown>
                </HelperComponent.Paragraph>
                <HelperComponent.Title>
                  {t('german_car.form.list_price.helper_q2')}
                </HelperComponent.Title>
                <HelperComponent.Paragraph>
                  {t('german_car.form.list_price.helper_a2')}
                </HelperComponent.Paragraph>
              </HelperComponent>
            }
          >
            {({ field }) => (
              <PaymentAmount
                tracing={{
                  onFocus:
                    ExpensesEvents.WEBAPP_VEHICLE_CLICKGERMANCARLISTPRICE,
                }}
                {...field}
              />
            )}
          </FieldConnector>
          <FieldConnector
            className="flex-1 min-w-0"
            name="fuelType"
            label={t('german_car.form.fuel_type')}
          >
            {({ field }) => (
              <Select
                tracing={{
                  onMenuOpen:
                    ExpensesEvents.WEBAPP_VEHICLE_CLICKGERMANCARFUELTYPE,
                  onChange:
                    ExpensesEvents.WEBAPP_VEHICLE_UPDATEGERMANCARFUELTYPE,
                }}
                options={[
                  {
                    value: VehicleFuelTypeEnum.diesel,
                    label: t('expense.diesel'),
                  },
                  { value: VehicleFuelTypeEnum.gas, label: t('expense.gas') },
                  { value: VehicleFuelTypeEnum.LPG, label: t('expense.LPG') },
                  {
                    value: VehicleFuelTypeEnum.electric,
                    label: t('expense.electric'),
                  },
                  {
                    value: VehicleFuelTypeEnum.hybrid,
                    label: t('expense.hybrid'),
                  },
                  { value: VehicleFuelTypeEnum.CNG, label: t('expense.CNG') },
                ]}
                isSearchable={false}
                {...field}
                onChange={(option: any) => {
                  field.onChange(option.value);
                }}
              />
            )}
          </FieldConnector>
        </div>
        <FieldConnector
          name="enginePower"
          showField={fuelType === 'electric'}
          label={t('german_car.form.engine_power')}
        >
          {({ field }) => (
            <NumberField
              tracing={{
                onFocus:
                  ExpensesEvents.WEBAPP_VEHICLE_CLICKGERMANCARENGINEPOWER,
              }}
              format={{ prefix: ' kW' }}
              {...field}
            />
          )}
        </FieldConnector>
        <FieldConnector
          name="electricRange"
          showField={fuelType === 'hybrid'}
          label={t('german_car.form.electric_range')}
        >
          {({ field }) => (
            <NumberField
              tracing={{
                onFocus:
                  ExpensesEvents.WEBAPP_VEHICLE_CLICKGERMANCARELECTRICRANGE,
              }}
              format={{ prefix: ' km' }}
              {...field}
            />
          )}
        </FieldConnector>
      </form>
    </FormProvider>
  );
};

export default GermanCarsForm;
