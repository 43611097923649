import { Pagination } from 'components/atoms';
import { Checkbox } from 'components/atoms/Form/Checkbox';
import { Category } from 'components/pages/Expenses/List/Cells/Category.component';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import { get } from 'lodash';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { IExpense } from 'types/expenses.types';
import type { ITaxSubmission } from 'types/tax-submissions.types';
import {
  TaxTypeEnum,
  type CheckComponentProps,
  type ITax,
} from 'types/taxes.types';
import type { AvailableCountries } from 'types/users.types';
import { logAmplitude } from 'utils/amplitude';
import { WEBAPP_TAXREVIEW_OPENCHECKEXPENSEORREVENUE } from 'utils/amplitude/events/taxes.amplitude';
import formatMoney from 'utils/formatMoney';
import formatPeriodLabel from 'utils/formatPeriodLabel';
import getTaxName from 'utils/getTaxName';
import ZebraTable from '../ZebraTable';

const CheckExpensesList: FC<
  {
    onItemClick?: (item: IExpense) => void;
    extraCols?: { head: string; key: string }[];
    renderExtra?: (item: IExpense) => JSX.Element;
    list: IExpense[];
    selectedExpenses?: string[];
    setSelectedExpenses?: (ids?: string[]) => void;
  } & CheckComponentProps
> = ({
  list,
  extraCols = [],
  selectedExpenses,
  setSelectedExpenses,
  expenseFormRef,
  onItemClick,
  checkName,
  checkData,
  setPage,
  renderExtra = () => null,
}) => {
  const { data, paging } = checkData;
  const { t } = useTranslation();
  const userCountry = useCustomerCountry();
  return (
    <div>
      <ZebraTable
        cells={[
          { head: t('merchant'), key: 'supplierName' },
          { head: t('date'), key: 'expenseDate' },
          ...(list.some((exp) => exp.items?.length)
            ? [{ head: t('category'), key: 'categoryCol' }]
            : []),
          {
            head: t('taxes.vat_submission.table.header.amount'),
            key: 'amountFormated',
          },
        ].concat(extraCols)}
        onClick={(_, item) => {
          logAmplitude(WEBAPP_TAXREVIEW_OPENCHECKEXPENSEORREVENUE, {
            checkName,
            expenseId: get(item, '_id'),
          });
          onItemClick?.(item as unknown as IExpense);
          setTimeout(() => expenseFormRef.current.open(get(item, '_id')), 0);
        }}
        cellClass="first:!flex-grow"
        hideChevron
        withEditIcon
        items={list?.map((expense: IExpense) => ({
          ...expense,
          taxItems: (
            <span className="text-xs">
              {expense?.submissions
                ?.map(
                  (sub) =>
                    `${getTaxName(
                      get(sub, 'type') === TaxTypeEnum.vatSubmission
                        ? {
                            ...(sub as unknown as ITaxSubmission),
                            type: TaxTypeEnum.vat,
                          }
                        : (sub as unknown as ITax),
                      userCountry as AvailableCountries,
                    )} ${formatPeriodLabel(get(sub, 'period'))}`,
                )
                .join(', ')}
            </span>
          ),
          amountFormated: (
            <span className="font-semibold text-primary">
              {formatMoney(expense.amount)}
            </span>
          ),
          categoryCol: expense.items ? (
            <Category
              items={expense.items}
              className="justify-center"
              labelClassName="!w-auto"
            />
          ) : (
            <span className="!font-normal opacity-50">{t('data.missing')}</span>
          ),
          key: expense?._id,
          supplierName: (
            <div className="flex items-center gap-2">
              {setSelectedExpenses ? (
                <Checkbox
                  onChange={() => {
                    const exp = expense;
                    setSelectedExpenses?.(
                      selectedExpenses?.includes(exp?._id)
                        ? selectedExpenses?.filter((id) => id !== exp._id)
                        : selectedExpenses?.concat([exp._id]),
                    );
                  }}
                  checked={!!selectedExpenses?.includes(expense?._id)}
                />
              ) : null}
              <span className="font-semibold text-primary">
                {expense.supplier.name || (
                  <span className="opacity-50">
                    {t('expenses.cells.unknown_supplier')}
                  </span>
                )}
              </span>
              {renderExtra(expense)}
            </div>
          ),
        }))}
      />
      {paging?.pageCount > 1 ? (
        <Pagination
          className="mt-2"
          pagesCount={paging?.pageCount || 0}
          perPage={25}
          totalCount={paging?.totalCount || 0}
          page={paging?.page || 0}
          currentPageCount={data?.length || 0}
          onPageChange={(pageNumber) => {
            setPage(pageNumber);
          }}
        />
      ) : null}
    </div>
  );
};

export default CheckExpensesList;
