import { isEmpty, omit } from 'lodash';
import type { FilterV2 } from 'types/global.types';
import type { FiltersConfigV2, UseFiltersStore } from 'types/shared/filters';
import zustandCleanup from 'utils/zustandCleanup';
import create from 'zustand';

const filtersStoreCreatorV2 = (): UseFiltersStore => {
  const initData = { text: '' };
  return create((set, get) => {
    zustandCleanup.registerCleanup(() =>
      set(() => ({ filtersData: initData })),
    );

    return {
      filtersData: initData,
      filters: [],
      setFilters: (filters: FilterV2[]) => set({ filters }),
      getFilters: () => get().filters,
      onSearch: (text: string) =>
        set(({ filtersData }: FiltersConfigV2) => ({
          filtersData: { ...filtersData, text },
        })),
      onChange: (key: string, value: number | string | null | undefined) => {
        set(({ filtersData }: FiltersConfigV2) => ({
          filtersData:
            value == null || (Array.isArray(value) && isEmpty(value))
              ? omit(filtersData, [key])
              : { ...filtersData, [key]: value },
        }));
      },
      clearFilters: (keys?: string[]) =>
        set(({ filtersData }: FiltersConfigV2) => ({
          filtersData: isEmpty(keys)
            ? initData
            : omit(filtersData, keys as string[]),
        })),
    };
  });
};

export default filtersStoreCreatorV2;
