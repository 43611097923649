import type { FC } from 'react';

const CheckBadge: FC<{ className?: string }> = (props) => (
  <svg
    width="48"
    height="49"
    className={props.className}
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.3675 30.8655C41.647 29.657 43.1998 27.2598 43.1998 24.5C43.1998 21.7402 41.647 19.343 39.3675 18.1345C40.1248 15.668 39.5278 12.875 37.5763 10.9235C35.6248 8.97198 32.8317 8.37491 30.3653 9.13228C29.1568 6.85274 26.7596 5.29999 23.9998 5.29999C21.24 5.29999 18.8428 6.85277 17.6343 9.13234C15.1679 8.375 12.3748 8.97207 10.4234 10.9236C8.47187 12.8751 7.87479 15.6681 8.63213 18.1345C6.35257 19.343 4.7998 21.7402 4.7998 24.5C4.7998 27.2598 6.35258 29.657 8.63214 30.8655C7.87477 33.3319 8.47184 36.125 10.4233 38.0765C12.3748 40.028 15.1679 40.625 17.6343 39.8677C18.8428 42.1472 21.24 43.7 23.9998 43.7C26.7596 43.7 29.1568 42.1472 30.3653 39.8676C32.8317 40.625 35.6248 40.0279 37.5763 38.0764C39.5277 36.1249 40.1248 33.3319 39.3675 30.8655ZM33.2555 20.1587C33.8402 19.3547 33.6625 18.229 32.8585 17.6443C32.0545 17.0596 30.9288 17.2373 30.3441 18.0413L21.9831 29.5377L17.4726 25.0272C16.7697 24.3243 15.63 24.3243 14.927 25.0272C14.2241 25.7301 14.2241 26.8698 14.927 27.5728L20.927 33.5728C21.2993 33.945 21.8158 34.1357 22.3406 34.0945C22.8654 34.0533 23.3459 33.7844 23.6555 33.3587L33.2555 20.1587Z"
      fill="#8059C8"
    />
  </svg>
);

export default CheckBadge;
