import type { FC } from 'react';
import { useState } from 'react';
import type { SelectConfig } from '../select.types';

const withRenderedText = (SelectComponent: FC<SelectConfig>) =>
  function WithAsyncSearch(props: SelectConfig) {
    const {
      onFocus,
      onBlur,
      isSearchable,
      inputValue,
      controlShouldRenderValue,
    } = props;

    const [isFocused, setIsFocused] = useState(false);

    const getInputValue = () => {
      if (!isSearchable) return inputValue;
      if (!isFocused || !inputValue) return '';
      return inputValue;
    };

    const getShouldRenderValue = () => {
      if (controlShouldRenderValue != null || !isSearchable) {
        return controlShouldRenderValue;
      }
      return !isFocused;
    };

    const newProps: Partial<SelectConfig> = {
      inputValue: getInputValue(),
      originalInputValue: inputValue,
      controlShouldRenderValue: getShouldRenderValue(),
      onFocus: (e) => {
        setIsFocused(true);
        onFocus?.(e);
      },
      onBlur: (e) => {
        setIsFocused(false);
        onBlur?.(e);
      },
    };

    return <SelectComponent {...props} {...newProps} />;
  };

export default withRenderedText;
