import classNames from 'classnames';
import type { ModalApi } from 'components/atoms/Modal';
import { Modal } from 'components/atoms/Modal';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useFeatures from 'hooks/useFeatures';
import { stringify } from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IUser } from 'types/users.types';
import { getTracingEvents } from 'utils/amplitude';
import {
  WEBAPP_GLOBAL_TRUSTPILOTASKFORREVIEW,
  WEBAPP_GLOBAL_TRUSTPILOTCLICKEDSTAR,
} from 'utils/amplitude/events/global.amplitude';
import { TrustPilotStar } from 'utils/icons';
import { saveTrustpilotReview, trustpilotReviewController } from './helpers';

const events = getTracingEvents({
  openModal: WEBAPP_GLOBAL_TRUSTPILOTASKFORREVIEW,
  onStarClick: {
    name: WEBAPP_GLOBAL_TRUSTPILOTCLICKEDSTAR,
    data: (star: number) => ({ star }),
  },
});

const getStarClass = (selectedIndex: number, currentIndex: number) => {
  if (currentIndex <= selectedIndex) {
    if (selectedIndex === 0) return 'text-[#ff3722]';
    if (selectedIndex === 1) return 'text-[#ff8621]';
    if (selectedIndex === 2) return 'text-[#ffce00]';
    if (selectedIndex === 3) return 'text-[#72cf10]';
    if (selectedIndex === 4) return 'text-[#00b67a]';
    return 'text-[#dcdce6]';
  }
  return 'text-[#dcdce6]';
};

const TrustpilotReview = () => {
  const { t, i18n } = useTranslation();
  const user = useGetCurrentCustomerData().data as IUser;

  const [activeStar, setActiveStar] = useState(-1);
  const selectedStarRef = useRef(-1);
  const ModalRef = useRef<ModalApi>();

  useEffect(() => {
    return trustpilotReviewController.subscribe(({ isOpen }) => {
      if (isOpen) {
        events.openModal();
        setActiveStar(-1);
        selectedStarRef.current = -1;
        return ModalRef.current?.openModal();
      }
      ModalRef.current?.closeModal();
    });
  }, []);

  const typeformLink = useFeatures('getTypeformLink')(i18n.language);
  const trustpilotLink = useFeatures('getTrustpilotLink')(i18n.language);

  const queryString = stringify({
    email: user.email,
    first_name: user.firstName,
  });

  return (
    <Modal
      apiRef={ModalRef}
      name="trustpilot review modal"
      width="md"
      onClose={() => {
        saveTrustpilotReview(selectedStarRef.current);
      }}
      render={(modalAPI) => {
        return (
          <div className="text-center">
            <p className="text-primary-700 font-bold">
              {t('trustpliot.review.title')}
            </p>
            <p className="text-primary-700 max-w-xs mx-auto leading-tight mt-1">
              {t('trustpliot.review.description')}
            </p>
            <div className="flex justify-center mt-5">
              {Array.from({ length: 5 }).map((_, i) => {
                return (
                  <a
                    href={
                      i < 3 ? `${typeformLink}?${queryString}` : trustpilotLink
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    key={i}
                    className={classNames(
                      getStarClass(activeStar, i),
                      'w-10 h-10 px-0.5 box-content',
                    )}
                    onMouseEnter={() => setActiveStar(i)}
                    onFocus={() => setActiveStar(i)}
                    onClick={() => {
                      selectedStarRef.current = i;
                      events.onStarClick(i + 1);
                      modalAPI.closeModal();
                    }}
                  >
                    <TrustPilotStar />
                  </a>
                );
              })}
            </div>
          </div>
        );
      }}
    ></Modal>
  );
};

export default function Render() {
  const user = useGetCurrentCustomerData().data;
  if (!user) return null;
  return <TrustpilotReview />;
}
