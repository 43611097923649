import classNames from 'classnames';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { Currency } from 'types/global.types';
import { getFormatConfig } from 'utils/formatMoney';
import { divideBy1000 } from 'utils/math';

export type AmountProps = {
  amount: number;
  currency?: Currency;
  className?: string;
  styled?: boolean;
  shouldDivideBy1000?: boolean;
  shouldShowSign?: boolean;
  // hidePositiveSign?: boolean;
};

const Amount: FC<AmountProps> = ({
  amount: _amount,
  currency,
  className,
  styled,
  shouldDivideBy1000,
  shouldShowSign = true,
  // hidePositiveSign = true,
}) => {
  const amount = shouldDivideBy1000 ? divideBy1000(_amount) : _amount;

  const config = useMemo(() => {
    return getFormatConfig({ amount, currency });
  }, [currency, amount]);

  const [beforeSep, afterSep] = config.amountFormatted.split(config.separator);

  const isPositiveAmount = amount > 0;

  return (
    <div
      className={classNames(
        'font-avenir',
        { '!text-green-600': isPositiveAmount && styled },
        className,
      )}
    >
      <span className="font-semibold">
        {config.isSymbolAtTheStart && `${config.symbol} `}
        {shouldShowSign && config.isNegative ? '-' : ''}
        {beforeSep}
      </span>
      <span className="font-normal">
        {config.separator}
        {afterSep}
      </span>
      {!config.isSymbolAtTheStart && (
        <span className="font-semibold">{` ${config.symbol}`}</span>
      )}
    </div>
  );
};

export default Amount;
