import { Select } from 'components/atoms/Form/Select';
import { SelectProps } from 'components/atoms/Form/Select/select.types';
import {
  getBlurNumber,
  getFocusStr,
  numberOrNull,
} from 'components/molecules/Fields/Number/useNumberField';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { AvailableLanguages } from 'types/users.types';
import formatMoney, {
  fixCommaDotInUserInput,
  hasNotNumChars,
} from 'utils/formatMoney';
import { round } from 'utils/math';
import type { FieldProps } from './commonFieldProps.types';

type VATAmountProps = FieldProps &
  SelectProps & {
    expenseDate?: string;
    amount: number;
    VATAmount: number;
    isExclVAT?: boolean;
    allowSetVatAmountManually?: boolean;
    isCreditNote?: boolean;
  };

const VATAmount: FC<VATAmountProps> = ({
  onChange,
  expenseDate,
  amount,
  VATAmount,
  isExclVAT,
  allowSetVatAmountManually,
  isCreditNote,
  ...props
}) => {
  const { t } = useTranslation();

  const language = useTranslation().i18n.language as AvailableLanguages;

  return (
    <Select
      {...props}
      isDisabled={props.disabled}
      creatable={allowSetVatAmountManually}
      placeholder={t('expense.vat_field.placeholder')}
      themeOptions={{ shouldOptionsShowSelectedIndicator: !!amount }}
      formatOnInputChange={(value: string) => {
        if (hasNotNumChars(value)) return null;
        return fixCommaDotInUserInput(value, language);
      }}
      formatOnInputFocus={(value: string) => {
        const text = getFocusStr(value, language);
        if (!text) return text;
        return isCreditNote ? `-${text}` : text;
      }}
      onChange={(option: any) => {
        let value = option?.value;
        if (option?.__isCreated) {
          value = numberOrNull(getBlurNumber(option.value));
        }
        if (value != null) onChange(Math.abs(round(value)));
      }}
      formatOptionLabel={(option: any, actionMeta) => {
        if (actionMeta.context === 'value')
          return formatMoney(option.value * (isCreditNote ? -1 : 1));
        return (
          <div className="flex justify-between w-full">
            {formatMoney(option.value * (isCreditNote ? -1 : 1))}
            {/* created options doesnt have tags */}
            {!!option.tag && (
              <span className="bg-expenses-200 rounded text-xs font-bold text-expenses w-8 flex items-center justify-center">
                {option.tag}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

export default VATAmount;
