import EuroCoins from 'assets/svg/icons/EuroCoins';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import layoutContext from 'components/organisms/Layout/layoutContext';
import {
  AppBannersContext,
  BANNERS_STORAGE_KEYS,
} from 'contexts/AppBannersProvider';
import {
  useGroupSubscriptionPlans,
  useRedirectToCustomerBillingPortal,
  useSubscriptionPlan,
} from 'hooks/Subscriptions';
import { useContext, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { SubscriptionPlan } from 'types/subscriptions.types';
import { SubscriptionPlanNameEnum } from 'types/users.types';
import formatMoney from 'utils/formatMoney';

const calculateAnnualPlanPriceDifference = (
  monthlyPlan: SubscriptionPlan,
  annualPlan: SubscriptionPlan,
) => {
  if (!monthlyPlan || !annualPlan) return 0;

  const monthlyPlanYearlyAmount = (monthlyPlan.amount / 100) * 12;

  const annualPlanAmount = annualPlan.amount / 100;

  return monthlyPlanYearlyAmount - annualPlanAmount;
};

const UpgradeYearlyBanner: FC = () => {
  const layout = useContext(layoutContext);

  const { mutate, isLoading: isRedirectingToCustomerPortal } =
    useRedirectToCustomerBillingPortal();

  const plan = useSubscriptionPlan();

  const {
    data: subscriptionPlans = [],
    isLoading,
    isError,
  } = useGroupSubscriptionPlans(plan?.planInternalName);

  const isSmall = plan?.planInternalName === SubscriptionPlanNameEnum.small1;

  const { t } = useTranslation();

  const { dismissBanner } = useContext(AppBannersContext);

  if (isLoading || isError) return null;

  const pro1 = subscriptionPlans?.find(
    (plan: SubscriptionPlan) => plan.nickname === SubscriptionPlanNameEnum.pro1,
  );

  const pro12 = subscriptionPlans?.find(
    (plan: SubscriptionPlan) =>
      plan.nickname === SubscriptionPlanNameEnum.pro12,
  );

  const small1 = subscriptionPlans?.find(
    (plan: SubscriptionPlan) =>
      plan.nickname === SubscriptionPlanNameEnum.small1,
  );

  const small12 = subscriptionPlans?.find(
    (plan: SubscriptionPlan) =>
      plan.nickname === SubscriptionPlanNameEnum.small12,
  );

  const proAnnualPlanDifference = calculateAnnualPlanPriceDifference(
    pro1 as SubscriptionPlan,
    pro12 as SubscriptionPlan,
  );

  const smallAnnualPlanDifference = calculateAnnualPlanPriceDifference(
    small1 as SubscriptionPlan,
    small12 as SubscriptionPlan,
  );

  return (
    <div
      className={classNames('fixed z-50 right-4 top-4 ')}
      style={{ left: layout.sidebarArea }}
    >
      <div className="relative bg-taxes flex justify-between items-center transition-colors shadow-filters rounded-xl">
        <EuroCoins className="left-0 -top-0.5 bottom-0" />

        <div className="flex items-center justify-between flex-1">
          <span className="text-white font-semibold text-lg">
            {isSmall
              ? t('upgrade_yearly_banner.small', {
                  priceDifference: formatMoney(
                    smallAnnualPlanDifference,
                    undefined,
                    undefined,
                    undefined,
                    true,
                  ),
                })
              : t('upgrade_yearly_banner.pro', {
                  priceDifference: formatMoney(
                    proAnnualPlanDifference,
                    undefined,
                    undefined,
                    undefined,
                    true,
                  ),
                })}
          </span>

          <div className="flex gap-4 items-center mx-8">
            <Button
              loading={isRedirectingToCustomerPortal}
              onClick={() => mutate()}
              className="!bg-white hover:!bg-white !text-taxes"
            >
              {t('monthly_plan.upgrade_to_yearly')}
            </Button>
            <Button
              structure="secondary"
              className="!border-white !bg-transparent !text-white"
              onClick={() =>
                dismissBanner(BANNERS_STORAGE_KEYS.UPGRADE_YEARLY_DISMISS_KEY)
              }
            >
              {t('close')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeYearlyBanner;
