import {
  ExclamationCircleIcon,
  EyeIcon,
  KeyIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { Loader } from 'components/atoms/Loader';
import { Tooltip } from 'components/atoms/Tooltip';
import { useHighlight } from 'hooks/useHighlight';
import type { PropertyPath } from 'lodash';
import _get from 'lodash/get';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { getTracingEvents } from 'utils/amplitude';
import { captureLeftRightArrowEvents } from 'utils/captureLeftRightArrowEvents';
import type FreeTextInputProps from './FreeTextInput.types';
import type {
  FormConnectProps,
  MaskedTextInputProp,
  TextAreaProps,
  TextInputProps,
} from './FreeTextInput.types';

const generateInputClasses = (
  error?: string,
  showErrorIcon?: boolean,
  hasIcon?: boolean,
  className?: string,
  type?: string,
  hasInfoComponent?: boolean,
  disabled?: boolean,
): string =>
  classNames(
    'bg-white placeholder-primary-400 font-semibold h-12 outline-none allow-focus-shadow',
    'w-full rounded-lg text-base inline-block text-main',
    'focus:ring-2 focus:ring-opacity-25 border',
    {
      'border-primary-100 focus:ring-primary-900 focus:border-primary-500':
        !error,
      '!border-red-500 placeholder-red-300 focus:ring-red-700': error,
      'pl-12': hasIcon,
      'p-4': type !== 'textarea',
      'pr-12': hasInfoComponent || (error && showErrorIcon),
      'cursor-not-allowed bg-primary-25 !text-primary-400': disabled,
    },
    className,
  );

const PasswordInput: FC<TextInputProps> = ({
  connectField,
  inputRef,
  ...props
}) => {
  const [viewPassword, setViewPassword] = useState(false);

  return (
    <div>
      <button
        onClick={() => setViewPassword((state) => !state)}
        type="button"
        className={classNames('absolute top-3.5 right-5 outline-none')}
      >
        <EyeIcon
          className={classNames('text-primary-500 w-5 h-5', {
            hidden: props.error,
          })}
        />
      </button>

      <input
        {...props}
        {...connectField}
        type={viewPassword ? 'text' : props.type}
      />
    </div>
  );
};

const InputTypeMap = {
  textarea: ({ connectField, inputRef, ...props }: TextAreaProps) => (
    <textarea {...props} {...connectField} />
  ),
  password: PasswordInput,
  maskedInput: ({
    connectField,
    maskProps = {
      mask: '',
    },
    inputRef,
    ...props
  }: {
    inputRef?: any;
    connectField: FormConnectProps;
    maskProps?: MaskedTextInputProp;
  }) => (
    // overriding type="text" because maskedInput is not a standard type and it misses with styling
    <InputMask {...props} {...connectField} {...maskProps} type="text" />
  ),
  default: ({ connectField, inputRef, ...props }: TextInputProps) => (
    <input {...props} {...connectField} ref={inputRef} />
  ),
};

const FreeTextInput: FC<FreeTextInputProps> = ({
  errors,
  className: _className,
  wrapperClassName,
  type = 'text',
  Icon,
  loading = false,
  disabled = false,
  InfoComponent,
  connectField = {},
  tracing,
  onFocus,
  highlightID,
  showErrorIcon = true,
  renderInput,
  extra,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const InputComponent =
    InputTypeMap[type as keyof typeof InputTypeMap] || InputTypeMap.default;
  const IconComponent = Icon || (type === 'password' && KeyIcon);

  const { message: error }: { message?: string } = _get(
    errors,
    (connectField.name || props.name) as PropertyPath,
    {},
  );
  const _error = props.error || error;

  const tracingEvents = getTracingEvents(tracing, { onFocus });

  const highlightAPI = useHighlight();

  const className = classNames(
    _className,
    highlightAPI.shouldHighlight(highlightID) && highlightAPI.className,
  );

  const ComponentProps = {
    connectField: connectField,
    className: generateInputClasses(
      _error,
      showErrorIcon,
      Boolean(IconComponent),
      className,
      type,
      Boolean(InfoComponent),
      disabled,
    ),
    disabled,
    type,
    extra,
    ...tracingEvents,
    ...props,
    error: _error,
    onKeyDown: (e: any) => {
      props.onKeyDown?.(e as any);
      captureLeftRightArrowEvents(e);
    },
  };

  return (
    <div
      className={classNames('relative w-full', wrapperClassName)}
      data-highlight-id={highlightID}
    >
      {IconComponent ? (
        <div className="absolute top-4 left-5 w-5 text-center flex items-center justify-center">
          <IconComponent
            className={classNames('text-primary-500', {
              'text-red-500': _error,
            })}
          />
        </div>
      ) : null}

      {renderInput ? (
        renderInput(ComponentProps)
      ) : (
        <InputComponent {...ComponentProps} />
      )}

      <div className="absolute top-1/2 -translate-y-1/2 right-5 flex items-center gap-2 leading-tight">
        {loading ? (
          <div
            className={classNames(
              'text-center flex items-center justify-center bg-white rounded-full p-1 shadow-sm',
              { 'right-14': _error || InfoComponent },
            )}
          >
            <Loader />
          </div>
        ) : null}

        {extra}

        {InfoComponent ? <InfoComponent /> : null}

        {_error && showErrorIcon && (
          <Tooltip content={i18n.exists(_error) ? t(_error) : _error}>
            <ExclamationCircleIcon
              className={classNames('w-5 h-5 text-red-500', {
                'opacity-40': disabled,
              })}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default FreeTextInput;
