import EuroCoins from 'assets/svg/icons/EuroCoins';
import { Button } from 'components/atoms/Button';
import layoutContext from 'components/organisms/Layout/layoutContext';
import {
  AppBannersContext,
  BANNERS_STORAGE_KEYS,
} from 'contexts/AppBannersProvider';
import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import { useSubscriptionPlan } from 'hooks/Subscriptions';
import useCustomNavigate from 'hooks/useCustomeNavigate';
import { useContext, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { VATTypeEnum } from 'types/users.types';

const TrialBanner: FC = () => {
  const layout = useContext(layoutContext);

  const navigate = useCustomNavigate();

  const { t } = useTranslation();

  const { data: user } = useGetCurrentCustomerData();

  const { dismissBanner } = useContext(AppBannersContext);

  const subscriptionPlan = useSubscriptionPlan();

  const trialExpirationDate = dayjs(subscriptionPlan?.end);

  const trialExpiresAfterNumOfDays = trialExpirationDate.diff(dayjs(), 'd');

  const isFranchisee = user?.VATType === VATTypeEnum.franchisee;

  return (
    <div
      className="fixed z-[100] right-4 top-4"
      style={{ left: layout.sidebarArea }}
    >
      <div className="relative bg-taxes flex justify-between items-center transition-colors shadow-filters rounded-xl">
        <EuroCoins className=" left-0 -top-0.5 bottom-0" />
        <div className="flex items-center justify-between flex-1">
          <span className="text-white font-semibold text-lg">
            {t(
              trialExpiresAfterNumOfDays === 1 ||
                trialExpiresAfterNumOfDays === 0
                ? 'subscriptions.banner.trial.title_singular_temp'
                : 'subscriptions.banner.trial.title_temp',
              {
                planName: t(
                  isFranchisee
                    ? 'subscriptions_screen.plans.small'
                    : 'subscriptions_screen.plans.pro',
                ),
                days: trialExpiresAfterNumOfDays,
              },
            )}
          </span>
          <div className="flex gap-4 items-center mx-8">
            <Button
              onClick={() => navigate('/subscriptions')}
              className="!bg-white hover:!bg-white !text-taxes"
            >
              {t('subscriptions.banner.trial.cta')}
            </Button>
            <Button
              onClick={() =>
                dismissBanner(BANNERS_STORAGE_KEYS.TRIAL_BANNER_DISMISS_KEY)
              }
              structure="secondary"
              className="!border-white !bg-transparent !text-white"
            >
              {t('close')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialBanner;
