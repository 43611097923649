import type { MotionProps } from 'framer-motion';
import type { ComponentProps, PropsWithChildren, PropsWithRef } from 'react';

export enum Sort {
  desc = 'desc',
  asc = 'asc',
}

export type TableHeadCellProps = PropsWithChildren<ComponentProps<'th'>> & {
  sortable?: boolean;
  active?: boolean;
  sort?: Sort;
  defaultSort?: Sort;
  onSortToggle?: () => void;
  sortableClassName?: string;
  contentClassName?: string;
  DescIcon?: any;
  AscIcon?: any;
};
export type TableRowProps = PropsWithRef<
  PropsWithChildren<ComponentProps<'tr'>>
>;
export type TableAnimatableRowProps = TableRowProps & MotionProps;

export type TableFooterProps = PropsWithChildren<ComponentProps<'tfoot'>> & {
  tdClassName?: string;
};

export type TableHeadProps = PropsWithChildren<ComponentProps<'thead'>>;

export type TableCellProps = PropsWithChildren<ComponentProps<'td'>>;

export type TableBodyProps = PropsWithChildren<ComponentProps<'tbody'>>;

export type TableProps = PropsWithChildren<ComponentProps<'table'>> & {
  tableClassName?: string;
  innerWrapperClassName?: string;
};

export type sorter = { cellId: string; direction: Sort };

export type onSort = (cellId: string) => void;

export type Sorter = { cellId: string; direction: Sort };

export type OnSortFunc = (cellId: string) => void;

export type SortProps = {
  sortable?: boolean;
  active?: boolean;
  sort?: Sort;
  onSortToggle?: () => void;
  defaultSort?: Sort;
};
