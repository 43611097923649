import { IdentificationIcon } from '@heroicons/react/outline';
import { joiResolver } from '@hookform/resolvers/joi';
import darkCirclesCornerArtImage from 'assets/svg/dark_circles_corner_art.svg';
import { Button } from 'components/atoms/Button';
import {
  FieldConnector,
  FieldTooltip,
} from 'components/atoms/Form/FieldConnector';
import FreeTextInput from 'components/atoms/Form/FreeTextInput';
import { Select } from 'components/atoms/Form/Select';
import { Title } from 'components/atoms/Title';
import { SizesEnum } from 'components/atoms/Title/Title.enum';
import SteuernummerField from 'components/pages/Taxes/Tax/SteuernummerField';

import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useFeatures from 'hooks/useFeatures';
import useSettingsSelectOptions from 'hooks/useSettingsSelectOptions';
import useUpdateAccountDetails from 'hooks/useUpdateAccountDetails';
import { useMemo, type FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountSettingsFieldsNamesEnum } from 'types/settings.types';
import { AvailableCountries, type IUser } from 'types/users.types';
import { getSelectOptionValue } from 'utils/helpers';
import {
  constructAccountDetailsFormDefaultValues,
  normalizeMaskedInputs,
} from 'utils/settings/settingsHelpers';
import {
  belgianVATNumberValidationSchema,
  germanTaxNumberValidationSchema,
} from './validation';

const GermanTaxNumberForm: FC<{ onSuccess?: () => void }> = ({ onSuccess }) => {
  const { data: user } = useGetCurrentCustomerData();

  const defaultValues = useMemo(
    () => constructAccountDetailsFormDefaultValues(user as IUser),
    [user],
  );

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: joiResolver(germanTaxNumberValidationSchema, {
      allowUnknown: true,
      abortEarly: false,
    }),
  });

  const { t } = useTranslation();

  const settingsOptions = useSettingsSelectOptions(
    user?.country as AvailableCountries,
  );

  const { update, isUpdating } = useUpdateAccountDetails(
    methods.handleSubmit,
    onSuccess,
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={update}>
        <div className="flex flex-col items-center my-5">
          <div className="w-12 h-12 rounded-full bg-taxes-100 flex items-center justify-center mb-3">
            <IdentificationIcon className="w-6 text-taxes" />
          </div>

          <Title type={SizesEnum.h6}>
            {t('home.tax_number_form.title.de')}
          </Title>
        </div>

        <div className="max-w-[70%] mx-auto">
          <FieldConnector
            className="my-3"
            name={AccountSettingsFieldsNamesEnum.state}
            label={t('settings.companyDetails.state')}
          >
            {({ name, field }) => (
              <Select
                {...field}
                name={name}
                placeholder={t('settings.companyDetails.state')}
                options={settingsOptions.germanStatesOptions}
                value={
                  getSelectOptionValue(
                    settingsOptions.germanStatesOptions,
                    field.value,
                  )?.value
                }
                onChange={({ value }: any) => {
                  field.onChange(value);
                  methods.clearErrors(name as any);
                }}
              />
            )}
          </FieldConnector>

          <FieldConnector
            className="flex-1"
            name={AccountSettingsFieldsNamesEnum.steuernummer}
            label={t('settings.companyDetails.steuernummer')}
            forceHideError
          >
            {({ field }) => (
              <SteuernummerField
                {...field}
                stateFieldName={AccountSettingsFieldsNamesEnum.state}
              />
            )}
          </FieldConnector>
        </div>

        <div className="text-center">
          <Button
            type="submit"
            size="xl"
            color="taxes"
            className="mt-16 w-[70%] !mx-auto mb-8"
            loading={isUpdating}
          >
            {t('settings.controllers.save')}
          </Button>
        </div>
      </form>

      <img
        src={darkCirclesCornerArtImage}
        className="absolute left-0 bottom-0 -rotate-180"
      />
    </FormProvider>
  );
};

const BelgianVATNumberForm: FC<{ onSuccess?: () => void }> = ({
  onSuccess,
}) => {
  const { data: user } = useGetCurrentCustomerData();

  const defaultValues = useMemo(
    () => constructAccountDetailsFormDefaultValues(user as IUser),
    [user],
  );

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: joiResolver(belgianVATNumberValidationSchema, {
      allowUnknown: true,
      abortEarly: false,
    }),
  });

  const { t } = useTranslation();

  const vatNumberPlaceholder = useFeatures('settings.vatNumberPlaceholder');

  const settingsFeatures = useFeatures('settings');
  const { VATNumberMask, VATNumberMaskNormalizer } = settingsFeatures;

  const { update, isUpdating } = useUpdateAccountDetails(
    methods.handleSubmit,
    onSuccess,
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={update}>
        <div className="flex flex-col items-center my-5">
          <div className="w-12 h-12 rounded-full bg-taxes-100 flex items-center justify-center mb-3">
            <IdentificationIcon className="w-6 text-taxes" />
          </div>

          <Title type={SizesEnum.h6}>
            {t('home.tax_number_form.title.be')}
          </Title>
        </div>

        <div className="max-w-[70%] mx-auto">
          <FieldConnector
            className="mt-3"
            name={AccountSettingsFieldsNamesEnum.VATNumber}
            label={t('settings.companyDetails.vatNumber')}
            helper={
              <FieldTooltip
                content={t(`settings.companyDetails.vat_number_tooltip.be`)}
              />
            }
          >
            {({ field, name }) => (
              <FreeTextInput
                placeholder={vatNumberPlaceholder}
                name={name}
                type="maskedInput"
                maskProps={{
                  maskChar: null,
                  mask: VATNumberMask,
                  normalizer: VATNumberMaskNormalizer,
                }}
                value={field.value}
                onChange={({ target: { value: inputValue } }) =>
                  field.onChange(normalizeMaskedInputs(inputValue))
                }
              />
            )}
          </FieldConnector>
        </div>

        <div className="text-center">
          <Button
            type="submit"
            size="xl"
            color="taxes"
            className="mt-16 w-[70%] !mx-auto mb-8"
            loading={isUpdating}
          >
            {t('settings.controllers.save')}
          </Button>
        </div>
      </form>

      <img
        src={darkCirclesCornerArtImage}
        className="absolute left-0 bottom-0 -rotate-180"
      />
    </FormProvider>
  );
};

const VATNumberForm: FC<{ onSuccess?: () => void }> = ({ onSuccess }) => {
  const { data: user } = useGetCurrentCustomerData();

  return user?.country === AvailableCountries.germany ? (
    <GermanTaxNumberForm onSuccess={onSuccess} />
  ) : (
    <BelgianVATNumberForm onSuccess={onSuccess} />
  );
};

export default VATNumberForm;
