type Fn = () => void;

const cleanupFns = new Set<Fn>();

const zustandCleanup = {
  registerCleanup: (fn: Fn) => cleanupFns.add(fn),
  cleanup: () => {
    cleanupFns.forEach((fn) => fn());
    cleanupFns.clear();
  },
};

export default zustandCleanup;
