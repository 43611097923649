import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import {
  BankTransactionsCacheKeys,
  ExpensesCacheKeys,
  RevenuesCacheKeys,
  SelectOptionsCacheKeys,
  TaxesCacheKeys,
} from 'types/cacheKeys.types';
import { SubWorld, World } from 'types/shared/worlds';

const expensesPageKeys = [
  ExpensesCacheKeys.ExpensesToReview,
  ExpensesCacheKeys.AllExpenses,
  ExpensesCacheKeys.CurrentPeriodDeductibleAmount,
  ExpensesCacheKeys.CurrentYearDeductibleAmount,
  ExpensesCacheKeys.HasExpenses,
  ExpensesCacheKeys.ExpensesFilters,
];

const revenuesPageKeys = [
  RevenuesCacheKeys.RevenuesToReview,
  RevenuesCacheKeys.AllRevenues,
  RevenuesCacheKeys.CurrentYearIncomeAmount,
  RevenuesCacheKeys.CurrentPeriodIncomeAmount,
  RevenuesCacheKeys.HasRevenues,
  RevenuesCacheKeys.RevenuesFilters,
  RevenuesCacheKeys.LatestRevenues,
];

const bankTransactionsPageKeys = [
  BankTransactionsCacheKeys.TransactionsList,
  BankTransactionsCacheKeys.BankTransactionsFilters,
  BankTransactionsCacheKeys.CashAvailableFactors,
];

const revenuesTransactionsTaxes = [
  ...revenuesPageKeys,
  ...bankTransactionsPageKeys,
  TaxesCacheKeys.Taxes,
];

// Assign affected query keys that need to be invalidated to each world
const mapper = {
  [World.BANK]: [
    ...expensesPageKeys,
    ...revenuesPageKeys,
    TaxesCacheKeys.Taxes,
  ],
  [World.EXPENSES]: [...bankTransactionsPageKeys, TaxesCacheKeys.Taxes],
  [World.REVENUES]: [
    ...revenuesPageKeys,
    ...bankTransactionsPageKeys,
    TaxesCacheKeys.Taxes,
  ],
  [SubWorld.CLIENTS]: [
    [SelectOptionsCacheKeys.Async, RevenuesCacheKeys.RevenueClientFieldOptions],
    RevenuesCacheKeys.RevenuesFilters,
    RevenuesCacheKeys.RecurringRevenues,
  ],
  [World.TAXES]: [...expensesPageKeys, ...revenuesPageKeys],
  [SubWorld.QUOTES]: [
    RevenuesCacheKeys.QuotesFilters,
    RevenuesCacheKeys.RevenuesQuotes,
  ],
  assetExpenses: [
    ...expensesPageKeys,
    ...bankTransactionsPageKeys,
    TaxesCacheKeys.Taxes,
  ],
  taxChecks: [...expensesPageKeys, ...revenuesPageKeys],
  assetRevenues: revenuesTransactionsTaxes,
  revenueUpdateStatusEvent: revenuesTransactionsTaxes,
  germanCarsAddRevenue: revenuesTransactionsTaxes,
  recurringInvoices: [
    RevenuesCacheKeys.RecurringRevenues,
    ...revenuesTransactionsTaxes,
  ],
  expensesIntro: expensesPageKeys,
  globalExpense: [
    ...expensesPageKeys,
    ...bankTransactionsPageKeys,
    TaxesCacheKeys.Taxes,
  ],
};

type ResourceKeyType = keyof typeof mapper;

const useInvalidateResources = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (resource: ResourceKeyType) => {
      const _resource = mapper[resource];

      _resource.forEach((key) => {
        if (Array.isArray(key)) {
          return queryClient.invalidateQueries(key);
        }
        queryClient.invalidateQueries([key]);
      });
    },
    [queryClient],
  );
};

export default useInvalidateResources;
