import useFeatures from 'hooks/useFeatures';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import isReducedVATRateApplicable from 'utils/isReducedVATRateApplicable';

type InvoiceDateType = string | undefined | null;
// type ItemVATRateType = number | undefined | null;

const useGetRevenuesVATRatesAPI = (invoiceDate: InvoiceDateType) => {
  const normalRates = useFeatures('revenue.vat_rates') as number[];
  const reducedRates = useFeatures('revenue.reduced_vat_rates', []) as number[];
  // const defaultRate = useFeatures('revenue.default_vat_rate') as number;

  const rates = useMemo(() => {
    if (!invoiceDate) return normalRates;

    if (isReducedVATRateApplicable(invoiceDate)) {
      return uniq(normalRates.concat(reducedRates).sort());
    }

    return normalRates;
  }, [invoiceDate]);

  // const isReducedVATRate = (rate: ItemVATRateType) => {
  //   if (!rate) return false;
  //   return reducedRates.includes(divideBy1000(rate));
  // };

  // const shouldRevertToDefaultRate = (
  //   invoiceDate: InvoiceDateType,
  //   itemVATRate: ItemVATRateType,
  // ) => {
  //   const isVATReduced = isReducedVATRate(itemVATRate);
  //   const isDateReduced = isReducedVATRateApplicable(invoiceDate);

  //   return !isDateReduced && isVATReduced;
  // };

  // const getItemsToRevertByIndex = (
  //   invoiceDate: InvoiceDateType,
  //   items: Partial<InvoiceItem>[] | undefined,
  // ) => {
  //   if (!items || items.length === 0) return null;
  //   const result: number[] = [];

  //   items.forEach((item, i) => {
  //     if (shouldRevertToDefaultRate(invoiceDate, item.VATRate)) {
  //       result.push(i);
  //     }
  //   });

  //   if (result.length === 0) return null;
  //   return result;
  // };

  return {
    rates,
    // defaultRate,
    // isReducedVATRate,
    // shouldRevertToDefaultRate,
    // getItemsToRevertByIndex,
  };
};

export default useGetRevenuesVATRatesAPI;
