import classNames from 'classnames';
import useWorldTheme from 'hooks/shared/useWorldTheme';
import type { ComponentProps, FC } from 'react';
import type { color } from 'types/shared/worlds';

const styles = {
  bank: 'text-bank hover:text-bank-800 focus:text-bank-800',
  expenses: 'text-expenses hover:text-expenses-800 focus:text-expenses-800',
  primary: 'text-primary hover:text-primary-800 focus:text-primary-800',
  taxes: 'text-taxes hover:text-taxes-800 focus:text-taxes-800',
  revenues: 'text-revenues hover:text-revenues-800 focus:text-revenues-800',
};

type AnchorProps = ComponentProps<'a'> & {
  color?: color;
};

const Anchor: FC<AnchorProps> = (props) => {
  const color = useWorldTheme();
  return (
    <a
      {...props}
      className={classNames(styles[props.color || color], props.className)}
    />
  );
};

export default Anchor;
