import useModal from 'hooks/useModal';
import VATNumberForm from './VATNumberForm.component';

const useVATNumberFormModal = () => {
  const [VATNumberModalContent, VATNumberModalAPI] = useModal({
    width: '2xl',
    name: 'vat number modal',
    color: 'taxes',
  });

  const openVATNumberForm = (onVATNumberUpdated?: () => void) => {
    VATNumberModalAPI.open(() => (
      <VATNumberForm
        onSuccess={() => {
          onVATNumberUpdated?.();
          VATNumberModalAPI.close();
        }}
      />
    ));
  };

  return { VATNumberModalContent, openVATNumberForm };
};

export default useVATNumberFormModal;
