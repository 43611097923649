import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { getTracingEvents } from 'utils/amplitude';
import { WEBAPP_GLOBAL_UTMS } from 'utils/amplitude/events/global.amplitude';
import getUTMs from 'utils/getUTMs';

const events = getTracingEvents({
  onPageView: {
    name: WEBAPP_GLOBAL_UTMS,
    data: (utms: Record<string, string>) => utms,
  },
});

const ReportUTMs = () => {
  const { search } = useLocation();
  const UTMs = useMemo(() => getUTMs(search), [search]);

  useEffect(() => {
    events.onPageView(UTMs);
  }, []);
  return null;
};

export default ReportUTMs;
