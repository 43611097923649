import type React from 'react';
import parse from './parse';

const parseColorInside = (str: string): React.ReactNode => {
  const [color, content] = str.split(/\]/gi);
  return <span style={{ color: color }}>{content}</span>;
};

const parseColor = (str: string): React.ReactNode =>
  str
    .split(/\[color=(.*?\].*?)\[\/color\]/gi)
    .map((part, index) => (index % 2 === 0 ? part : parseColorInside(part)));

const colorFormatter = (element: React.ReactNode): React.ReactNode | string =>
  parse(element, parseColor);

export default colorFormatter;
