import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { getReviewYears } from './helpers';

const WelcomeText: FC = () => {
  const { data: user } = useGetCurrentCustomerData();
  const { t } = useTranslation();
  const { year, newYear } = getReviewYears();

  return (
    <div className="text-center px-24 py-10 font-semibold text-primary-700">
      <ReactMarkdown
        components={{
          em: ({ children }) => <p className="not-italic mb-3">{children}</p>,
        }}
      >
        {t('yearly_review.welcome_text', {
          firstName: user?.firstName,
          year,
          newYear,
        })}
      </ReactMarkdown>
    </div>
  );
};

export default WelcomeText;
