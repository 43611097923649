import { updateEmailAliases, updateUserData } from 'api/v2/users/settings';
import _get from 'lodash/get';
import type { FormEventHandler, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { AccountSettingsFieldsNamesEnum } from 'types/settings.types';
import type { AccountType, IUser } from 'types/users.types';
import { AccountTypeEnum, ActivityTypesEnum } from 'types/users.types';
import { getTracingEvents } from 'utils/amplitude';
import { WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDSAVE } from 'utils/amplitude/events/settings.amplitude';
import { isSubscriptionFreePlan } from 'utils/subscriptions.utils';
import showToast from 'utils/toast';
import useGetCurrentCustomerData from './Authentication/useGetCurrentCustomerData';

const tracingEvents = getTracingEvents({
  onSubmit: WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDSAVE,
});

type SubmitFunctionType = (x: (values: IUser) => void) => () => void;
type HookResultType = {
  update: FormEventHandler<HTMLFormElement>;
  isUpdating: boolean;
};

const constructAccountTypeString = (values: IUser): AccountType => {
  // one_person_company account type is not editable
  if (values.accountType === AccountTypeEnum.one_person_company)
    return AccountTypeEnum.one_person_company;

  const vatStatus = values.VATType === 'subjectToVAT' ? 'vat' : 'no_vat';
  const ignoreVATStatus =
    values.activity === ActivityTypesEnum.complementary ||
    values.accountType === (AccountTypeEnum.gewerbetreibender as AccountType);

  const encodedAccountType = ignoreVATStatus
    ? `${values.accountType}_${values.activity}`
    : `${values.accountType}_${values.activity}_${vatStatus}`;

  return encodedAccountType as AccountType;
};

// Used to encapsulate the logic for updating the user/customer's profile information
const useUpdateAccountDetails = (
  handleSubmit: SubmitFunctionType,
  onSuccess?: () => void,
): HookResultType => {
  // The update happens sequentially: first update the email aliases, then, the actual profile gets updated
  const {
    mutateAsync: updateEmailAliasesAction,
    isLoading: isUpdatingEmailAliases,
  } = useMutation(updateEmailAliases);
  const { t } = useTranslation();
  const { mutateAsync: updateUserDataAction, isLoading: isUpdatingUserData } =
    useMutation(updateUserData);
  const { refetch, data: currentUserData } = useGetCurrentCustomerData();

  const update = (e?: SyntheticEvent): void => {
    if (e) e.preventDefault();
    handleSubmit(async (values: IUser) => {
      tracingEvents.onSubmit();
      const planInternalName = _get(
        values,
        'subscriptionPlan.planInternalName',
      );
      const emailAliases = _get(
        values,
        AccountSettingsFieldsNamesEnum.emailAliases,
        [],
      ).map(({ email }: { email: string }) => email.trim()) as string[];

      const oldEmailAliases = currentUserData?.settings.emailAliases || [];
      const newEmailAliases = emailAliases.filter(
        (email) => !oldEmailAliases.includes(email),
      );

      const hasEmailAliasesChanged =
        newEmailAliases.length > 0 ||
        emailAliases.length !== oldEmailAliases.length;

      // Update the email aliases first, then update the profile next
      if (!isSubscriptionFreePlan(planInternalName) && hasEmailAliasesChanged) {
        await updateEmailAliasesAction(emailAliases, {
          onSuccess: () => {
            if (!newEmailAliases.length) return;
            showToast(
              {
                title: t(
                  'settings.personalDetails.new_email_alias_confirmation',
                  {
                    emails: newEmailAliases.join(', '),
                  },
                ),
              },
              { type: 'success' },
            );
          },
        });
      }

      await updateUserDataAction(
        { ...values, accountType: constructAccountTypeString(values) },
        {
          onSuccess: () => {
            // Refetch the up-to-date profile to reflect in the whole system
            refetch();
            onSuccess?.();
            showToast(
              { title: t('successfully_saved_changes') },
              { type: 'success' },
            );
          },
        },
      );
    })();
  };

  return { update, isUpdating: isUpdatingEmailAliases || isUpdatingUserData };
};

export default useUpdateAccountDetails;
