import classNames from 'classnames';
import type { FC } from 'react';
import type { TableFooterProps } from './table.types';

export const TableFooter: FC<TableFooterProps> = ({
  children,
  tdClassName,
  className,
  ...props
}) => {
  return (
    <tfoot className={classNames('border-t', className)} {...props}>
      <tr className="bg-white">
        <td className={classNames('py-4 px-3', tdClassName)} colSpan={100}>
          {children}
        </td>
      </tr>
    </tfoot>
  );
};
