import type { FC } from 'react';
import { useMemo, useRef } from 'react';
import type { SelectConfig } from '../select.types';

// const formatInput = (value: any, options: any) => {
//   if (options && value != null) {
//     const result = convertUserInputMoneyToNumber(value + '');
//     if (Number.isNaN(result)) return null;
//     return result;
//   }
//   return value;
// };

const withCreate = (SelectComponent: FC<SelectConfig>) =>
  function WithCreate(props: SelectConfig) {
    const {
      // parseInputAsInt,
      // formatInputAsNumberOnFocus,
      inputValue,
      onInputChange,
      onChange,
    } = props;

    const createdOption = useMemo(() => {
      const value = inputValue?.trim();
      if (!value) return null;
      return {
        label: value,
        value: value,
        __isCreated: true,
      };
    }, [inputValue]);

    const useIsLastActionSetValue = useRef(false);
    const useHasChanged = useRef(false);

    const newProps: Partial<SelectConfig> = {
      isSearchable: true,
      noOptionsMessage: () => null,
      // formatInputAsNumberOnFocus: undefined,
      createdOption,
      onInputChange(inputValue, actionMeta, selected) {
        if (actionMeta.action === 'input-change') {
          useHasChanged.current = true;
        }
        const isInputBlur = actionMeta.action === 'input-blur';
        const isSetValue = actionMeta.action === 'set-value';
        if (isInputBlur && useIsLastActionSetValue.current) {
          useIsLastActionSetValue.current = false;
          useHasChanged.current = false;
          return onInputChange?.('', actionMeta, selected);
        }
        if (isInputBlur || isSetValue) {
          useIsLastActionSetValue.current = isSetValue;
        }
        if (!isInputBlur || !useHasChanged.current) {
          return onInputChange?.(inputValue, actionMeta, selected);
        }
        useHasChanged.current = false;
        onInputChange?.('', actionMeta, selected);
        onChange?.(createdOption, {
          action: 'create-option',
          option: createdOption,
        });
      },
    };

    return <SelectComponent {...props} {...newProps} />;
  };

export default withCreate;
