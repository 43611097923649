import { round } from 'utils/math';

export const calculateVATRate = (
  VATAmount: number,
  amountInclVAT: number,
): number =>
  !amountInclVAT ? 0 : round(VATAmount / (amountInclVAT - VATAmount));

export const calculateVATAmount = (
  newAmountInclVAT: number,
  VATRate: number,
): number =>
  !newAmountInclVAT
    ? 0
    : round(newAmountInclVAT - newAmountInclVAT / (1 + VATRate));

export const calculateAmount = (
  isExclVAT: boolean,
  amount: number,
  VATAmount: number,
) => null;

export const calcEndOfAmortizationPeriod = (
  date: string,
  amortizationPeriod: number | undefined,
) => {
  if (typeof amortizationPeriod !== 'number' || amortizationPeriod === 0) {
    return 0;
  }

  return new Date(date).getFullYear() + amortizationPeriod / 12;
};
