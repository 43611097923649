import create from 'zustand';

export type CountsMapType = Record<string, number>;

const persistAmounts = () =>
  create<{
    amountsCache: CountsMapType;
    setAmountsCache: (next: CountsMapType) => void;
  }>((set) => ({
    amountsCache: {} as CountsMapType,
    setAmountsCache: (next: CountsMapType) =>
      set((state) => Object.assign(state.amountsCache, next)),
  }));

export default persistAmounts;
