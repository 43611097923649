import CustomLoader from 'components/molecules/CustomLoader.component';
import type { InvoiceInput } from 'components/pages/Revenues/OtherRevenueForm';
import RevenueForm from 'components/pages/Revenues/OtherRevenueForm';

import NotFoundPage from 'components/pages/404.component';
import ExpenseForm from 'components/pages/Expenses/Form';
import type { ExpenseInput } from 'components/pages/Expenses/Form/FormSlideOver';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useExpenseRevenueFormsRefs from 'hooks/Taxes/useExpenseRevenueFormsRefs';
import useGetSelectedYearTaxes from 'hooks/Taxes/useGetSelectedYearTaxes';
import useGetTaxBySlug from 'hooks/Taxes/useGetTaxBySlug';
import useTaxItemComponent, { taxItems } from 'hooks/Taxes/useTaxItemComponent';
import useCustomNavigate from 'hooks/useCustomeNavigate';
import type { FormSlideOverManagerApi } from 'hooks/useFormSlideOverManager';
import type { FC, SetStateAction } from 'react';
import { createContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type UseQueryResult } from 'react-query';
import { useParams } from 'react-router';
import type { Period } from 'types/global.types';
import type { ITax, TaxCheckEnum } from 'types/taxes.types';
import { TaxTypeEnum } from 'types/taxes.types';
import { logAmplitude } from 'utils/amplitude';
import formatPeriodLabel from 'utils/formatPeriodLabel';
import { useReviewSkipStatus } from './TaxReview/hooks';

export const CurrentTaxContext = createContext<
  | (UseQueryResult<ITax, unknown> & {
      currentCheck: TaxCheckEnum;
      setCurrentCheck: SetStateAction<string>;
    })
  | any
>({});

const Tax: FC = () => {
  const [currentCheck, setCurrentCheck] = useState<null | TaxCheckEnum>(null);
  const { country, taxSlug, periodSlug } = useParams();
  const queryResult = useGetTaxBySlug(
    `${country}/${taxSlug}/${periodSlug}`,
    `${country}/${taxSlug}` in taxItems,
  );
  const { data: tax, error, refetch: refetchTax } = queryResult;
  const TaxItem = useTaxItemComponent(tax as ITax);
  const customerCountry = useCustomerCountry();
  const { refetch } = useGetSelectedYearTaxes();
  const { t } = useTranslation();
  const expRef = useRef<FormSlideOverManagerApi<ExpenseInput>>();
  const revRef = useRef<FormSlideOverManagerApi<InvoiceInput>>();
  const { setFormRefs, expenseFormRef, revenueFormRef } =
    useExpenseRevenueFormsRefs();
  useEffect(() => {
    setFormRefs(expRef, revRef);
  }, []);
  const taxRoute = `${country}/${taxSlug}`;
  useEffect(() => {
    if (!queryResult.isLoading && tax && tax.type !== TaxTypeEnum.vat) {
      logAmplitude(
        `WEBAPP_${
          tax.type === TaxTypeEnum.incomeTaxSubmission
            ? `INCOMETAXSUBMISSION${customerCountry?.toUpperCase()}`
            : tax.type?.replace(/_/g, '').toUpperCase()
        }_VIEWTAXSCREEN`,
        { period: formatPeriodLabel(tax?.period as Period) as string },
      );
    }
  }, [tax]);
  const { setSkipped } = useReviewSkipStatus();

  useEffect(() => {
    setSkipped(false);
  }, [taxRoute]);

  const navigate = useCustomNavigate();

  useEffect(() => {
    if (!(taxRoute in taxItems)) {
      navigate('/taxes');
    }
    window.scrollTo(0, 0);
  }, [taxRoute]);

  if ((queryResult.isLoading || queryResult.isFetching) && !tax) {
    return (
      <div className="h-screen flex items-center justify-center">
        <CustomLoader />
      </div>
    );
  }

  if (!(taxRoute in taxItems)) {
    return null;
  }
  if (error)
    return (
      <div className="!h-screen flex items-center justify-center">
        <NotFoundPage goBack={() => navigate('/taxes')} />;
      </div>
    );
  return (
    <>
      <CurrentTaxContext.Provider
        value={{ ...queryResult, currentCheck, setCurrentCheck }}
      >
        <TaxItem tax={tax as ITax} />
      </CurrentTaxContext.Provider>
      <ExpenseForm
        apiRef={expenseFormRef}
        afterSubmit={() => {
          refetch();
          refetchTax();
        }}
      />
      <RevenueForm
        apiRef={revenueFormRef}
        afterSubmit={() => {
          refetch();
          refetchTax();
        }}
      />
    </>
  );
};

export default Tax;
