import * as Sentry from '@sentry/react';
import AccountableLogo from 'components/atoms/AccountableLogo';
import GrowthExperiments from 'components/organisms/GrowthExperiments/GrowthExperiments.component';
import { environment } from 'config';
import useAuthContinuation from 'hooks/Authentication/useAuthContinuation';
import useAuthStatus from 'hooks/Authentication/useAuthStatus';
import { useInitializeIntercom } from 'hooks/intercom.hook';
import useAssignGrowthABGroups from 'hooks/useAssignGrowthABGroups';
import useLogAmplitudeOnPageVisits from 'hooks/useLogAmplitudeOnPageVisits';
import { type FC, type ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import OnlyBeforeAuthRoute from 'routes/OnlyBeforeAuthRoute';
import PrivateRoute from 'routes/PrivateRoute';
import { GROWTH_ALLOWED_EMAILS } from 'utils/constants';
import type { RouteType } from '../../types/routing.types';
import routes from './routing/routes';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App: FC = () => {
  const { isAuthenticated, isLoadingAuthData, user } = useAuthStatus();

  const { isReady } = useAssignGrowthABGroups(user);

  useLogAmplitudeOnPageVisits();

  useInitializeIntercom();

  const { isLoading } = useAuthContinuation();

  if (isLoadingAuthData || isLoading || !isReady)
    return (
      <div className="flex flex-1 h-screen items-center justify-center bg-white">
        <AccountableLogo className="w-52" />
      </div>
    );

  return (
    <>
      <SentryRoutes>
        {routes.map(
          ({
            path,
            Component,
            private: isPrivate,
            onlyBeforeAuth,
          }: RouteType): ReactNode => {
            if (isPrivate) {
              return (
                <Route
                  path={path}
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      {Component}
                    </PrivateRoute>
                  }
                  key={path}
                />
              );
            }
            if (onlyBeforeAuth) {
              return (
                <Route
                  path={path}
                  element={
                    <OnlyBeforeAuthRoute isAuthenticated={isAuthenticated}>
                      {Component}
                    </OnlyBeforeAuthRoute>
                  }
                  key={path}
                />
              );
            }
            return <Route path={path} element={Component} key={path} />;
          },
        )}
      </SentryRoutes>

      {environment !== 'production' ||
      GROWTH_ALLOWED_EMAILS.includes(user?.email as string) ||
      user?.email?.includes('@accountable.eu') ? (
        <GrowthExperiments />
      ) : null}
    </>
  );
};

export default App;
//
