import type { ComponentProps, FC, PropsWithChildren } from 'react';

const ContainerWithShadow: FC<PropsWithChildren<ComponentProps<'div'>>> = ({
  children,
  className,
}) => (
  <div className={`rounded-lg shadow-lg w-full bg-white ${className}`}>
    {children}
  </div>
);

export default ContainerWithShadow;
