import type { ExpenseInput } from 'components/pages/Expenses/Form/FormSlideOver';
import type { InvoiceInput } from 'components/pages/Revenues/OtherRevenueForm';
import type { FormSlideOverManagerApi } from 'hooks/useFormSlideOverManager';
import type { MutableRefObject } from 'react';
import create from 'zustand';

type E = MutableRefObject<FormSlideOverManagerApi<ExpenseInput> | undefined>;

type R = MutableRefObject<FormSlideOverManagerApi<InvoiceInput> | undefined>;

type ExpenseRevenueFormsRefs = {
  expenseFormRef: E | null;
  revenueFormRef: R | null;
  setFormRefs: (e: E, r: R) => void;
};

const useExpenseRevenueFormsRefs = create<ExpenseRevenueFormsRefs>((set) => ({
  expenseFormRef: null,
  revenueFormRef: null,
  setFormRefs: (expenseFormRef, revenueFormRef) =>
    set({ expenseFormRef, revenueFormRef }),
}));

export default useExpenseRevenueFormsRefs;
