import classNames from 'classnames';
import CountAnimatedBadge from 'components/atoms/CountAnimatedBadge/CountAnimatedBadge.component';
import UseEffect from 'components/atoms/UseEffect';
import useUpdateSidebarDocCounts, {
  useSidebarState,
} from 'hooks/useUpdateSidebarDocCounts';
import type { FC } from 'react';
import { SidebarDocCountsKeysEnum } from 'store/sidebarState';

const NewExpensesCount: FC<{ isActive?: boolean }> = ({ isActive }) => {
  const { state: sidebarState } = useSidebarState();
  const newlyAddedExpensesCount =
    sidebarState?.[SidebarDocCountsKeysEnum.NewExpensesCount];
  const updateSidebarCounts = useUpdateSidebarDocCounts();

  if (!newlyAddedExpensesCount) return null;

  return (
    <UseEffect
      effect={() => {
        const id = setTimeout(
          () =>
            updateSidebarCounts(SidebarDocCountsKeysEnum.NewExpensesCount, 0),
          3000,
        );

        return () => clearTimeout(id);
      }}
      key={String(newlyAddedExpensesCount)}
      deps={[newlyAddedExpensesCount]}
      render={() => (
        <CountAnimatedBadge
          count={`+${newlyAddedExpensesCount}`}
          className={classNames(
            '!text-expenses-900 border-[1px] border-expenses-900',
            {
              '!bg-white': isActive,
              '!bg-expenses-100': !isActive,
            },
          )}
        />
      )}
    />
  );
};

export default NewExpensesCount;
