import {
  generatePeriods,
  periodExists,
} from 'components/molecules/Fields/Period/utils';
import useCategoriesPicker, {
  UseCategoriesPickerProps,
} from 'components/organisms/CategoriesPicker/useCategoriesPicker';
import { categoryIdWithTaxPeriodToValue } from 'components/organisms/CategoriesPicker/utils';
import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useVATReturnFrequency from 'hooks/shared/useVATReturnFrequency';
import useExpensesCategories from 'hooks/useExpensesCategories';
import useFeatures from 'hooks/useFeatures';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { IUser, VATReturnFrequency } from 'types/users.types';
import { BEVehicle, DEVehicle } from 'types/vehicle.types';
import { getTracingEvents } from 'utils/amplitude';
import { TracingConfig } from 'utils/amplitude/amplitude.types';
import formatPeriodLabel from 'utils/formatPeriodLabel';
import { adaptCategories } from './helpers';

export type CategoryChangeParams = {
  taxPeriod?: any;
  guessedCategoryFrom?: string;
  vehicle?: BEVehicle | DEVehicle;
};

export type UseExpensesCategoriesPickerProps = Omit<
  UseCategoriesPickerProps,
  'categories' | 'modalWidth'
> & {
  defaultTaxPeriod?: any;
  taxPeriod?: any;
  tracing?: TracingConfig;
};

type OpenFnProps = {
  value?: string;
  onChange: (category: any, params?: CategoryChangeParams) => void;
};

const useExpensesCategoriesPicker = (
  props: UseExpensesCategoriesPickerProps,
) => {
  const { defaultTaxPeriod, taxPeriod, tracing, ...categoriesPickerProps } =
    props;

  const { t } = useTranslation();
  const user = useGetCurrentCustomerData().data as IUser;

  const country = user.country;

  const deductibleTaxCategories = useFeatures(
    'expenses.deductible_tax_categories',
  );
  const vatReturnFrequencies = useFeatures('taxes.vat_return_frequency');
  const userVatReturnFrequency = useVATReturnFrequency();

  const deductibleTaxCategoriesMap = useMemo(() => {
    return deductibleTaxCategories.reduce((acc: any, cateId: string) => {
      return {
        ...acc,
        [cateId]: {
          id: cateId,
          tabs: vatReturnFrequencies.map((tab: string) => ({
            label: t(tab),
            value: tab,
          })),
          defaultTab: userVatReturnFrequency,
          question: t(`expense.vat_expense_title.${country}`),
          questionDescription: t('expense.vat_frequency_select'),
          list: vatReturnFrequencies.flatMap(
            (frequency: VATReturnFrequency) => {
              let periods = generatePeriods(frequency, dayjs().endOf('year'));
              if (
                defaultTaxPeriod &&
                defaultTaxPeriod.frequency === frequency
              ) {
                const doesDefaultPeriodExist = periodExists(
                  defaultTaxPeriod,
                  periods,
                  defaultTaxPeriod.frequency,
                );
                if (!doesDefaultPeriodExist) {
                  periods = periods.concat(defaultTaxPeriod);
                }
              }
              return periods.map((period) => ({
                title: formatPeriodLabel(period),
                id: categoryIdWithTaxPeriodToValue(cateId, frequency, period),
                parentCatgoryId: cateId,
                taxPeriod: { ...period, frequency },
                frequency,
                tab: frequency,
              }));
            },
          ),
        },
      };
    }, {});
  }, [
    deductibleTaxCategories,
    userVatReturnFrequency,
    country,
    defaultTaxPeriod,
    t,
  ]);

  const result = useExpensesCategories();

  const data = useMemo(() => {
    if (!result.data) return [];
    return adaptCategories(
      result.data.list,
      deductibleTaxCategoriesMap,
      user?.accountType as string,
    );
  }, [result.data]);

  const api = useCategoriesPicker({
    ...categoriesPickerProps,
    categories: data,
  });

  const events = getTracingEvents(tracing);

  const open = ({ value, onChange }: OpenFnProps) => {
    const finalValue =
      deductibleTaxCategories.includes(value) && taxPeriod
        ? categoryIdWithTaxPeriodToValue(value!, taxPeriod.frequency, taxPeriod)
        : value;

    events.onOpen?.();

    api.open({
      value: finalValue,
      onChange: (category, categoriesDict) => {
        events.onChange?.();

        if (category.taxPeriod) {
          return onChange(categoriesDict[category.parentCatgoryId!], {
            taxPeriod: category.taxPeriod,
          });
        }
        onChange(category);
      },
    });
  };

  return {
    api: {
      ...api,
      open,
    },
    query: result,
  };
};

export default useExpensesCategoriesPicker;
