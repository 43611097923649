import omit from 'lodash/omit';
import type { FC, PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ONE_SECOND } from 'utils/time';

const highlightContext = createContext({
  shouldHighlight: (id?: string): boolean => false,
  highlight: (id: string) => {},
  unHighlight: (id: string) => {},
  className: '',
});

export const useHighlight = () => {
  return useContext(highlightContext);
};

export const HighlightProvider: FC<
  PropsWithChildren<{
    className: string;
    unHighlightTimer?: number;
  }>
> = ({ className, unHighlightTimer = ONE_SECOND, children }) => {
  const [state, setState] = useState<Record<string, boolean | undefined>>({});

  const timeouts = useRef<Record<string, NodeJS.Timeout>>({});

  const shouldHighlight = useCallback(
    (id?: string) => !!id && !!state[id],
    [state],
  );

  const unHighlight = useCallback((id: string) => {
    setState((_state) => {
      if (!_state[id]) return _state;
      return omit(_state, id);
    });

    if (timeouts.current[id] != null) {
      clearTimeout(timeouts.current[id]);
      delete timeouts.current[id];
    }
  }, []);

  const highlight = useCallback(
    (id: string, _unHighlightTimer = unHighlightTimer) => {
      unHighlight(id);

      setState((_state) => {
        if (_state[id]) return _state;
        return { ..._state, [id]: true };
      });

      timeouts.current[id] = setTimeout(
        () => unHighlight(id),
        _unHighlightTimer,
      );
    },
    [unHighlight, unHighlightTimer],
  );

  useEffect(() => {
    return () => {
      Object.values(timeouts.current).forEach((TO) => clearTimeout(TO));
    };
  }, []);

  return (
    <highlightContext.Provider
      value={useMemo(
        () => ({
          shouldHighlight,
          highlight,
          unHighlight,
          className,
        }),
        [state, shouldHighlight, highlight, unHighlight, className],
      )}
    >
      {children}
    </highlightContext.Provider>
  );
};
