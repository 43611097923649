import firstClientUrlBE from 'assets/images/Hassan_Ayed.jpeg';
import secondClientUrlBE from 'assets/images/Kevin_Verbesselt.png';
import appstoreQRCode from 'assets/images/QR_apple_BE.png';
import googlePlayQRCode from 'assets/images/QR_google_BE.png';
import { HOME_PAGE_STATE } from 'contexts/HomeProvider';
import dayjs from 'dayjs';
import appI18nInstance from 'i18n';
import type { HomeUtils, StarterTodoItemsList } from 'types/home.types';
import { AvailableLanguages, VATTypeEnum, type IUser } from 'types/users.types';
import { openInNewTab } from 'utils/helpers';
import banks from './banks';
import clients from './clients';
import customers from './customers';
import expenses from './expenses';
import growthExperiments from './growthExperiments';
import paywall from './paywall';
import period from './period';
import revenue from './revenue';
import revenues from './revenues';
import settings from './settings';
import taxes from './taxes';
import transactions from './transactions';

const REVENUE_SIMULATOR_LINK = 'https://simulator.accountable.eu/?revenue=';

export default {
  clients,
  customers,
  expenses,
  revenue,
  revenues,
  period,
  settings,
  transactions,
  growthExperiments,
  taxes,
  banks,
  home: {
    blocked: {
      web: true,
      experts: true,
    },
  },
  testimony_img_urls: {
    firstClientUrl: firstClientUrlBE,
    secondClientUrl: secondClientUrlBE,
  },
  formats: {
    calc_phone_prefix: '+32',
    date: 'DD/MM/YYYY',
    datePicker: 'dd/MM/yyyy',
  },
  paywall,
  mobileAppData: {
    appStoreURL:
      'https://apps.apple.com/eg/app/accountable-self-employed/id1384729810',
    googlePlayURL:
      'https://play.google.com/store/apps/details?id=com.hivearts.accountable&gl=BE',
    appstoreQRCode,
    googlePlayQRCode,
  },
  canAccessPeppol: true,
  showProfitAndLossInMoreMenu: true,
  getTypeformLink: (lang: AvailableLanguages) => {
    if (lang === AvailableLanguages.nl) {
      return 'https://getaccountable.typeform.com/to/IJeCD5f6';
    }
    if (lang === AvailableLanguages.fr) {
      return 'https://getaccountable.typeform.com/to/x2Ici170';
    }
    return 'https://getaccountable.typeform.com/to/M247sQUp';
  },
  getTrustpilotLink: (lang: AvailableLanguages) => {
    if (lang === AvailableLanguages.nl) {
      return 'https://nl.trustpilot.com/evaluate/getaccountable.eu';
    }
    if (lang === AvailableLanguages.fr) {
      return 'https://fr.trustpilot.com/evaluate/getaccountable.eu';
    }
    return 'https://www.trustpilot.com/evaluate/getaccountable.eu';
  },
  getStarterTodoList: (
    state: Record<string, boolean>,
    utils: HomeUtils & { isRegisteredThroughRASO: boolean },
    user?: IUser,
  ): StarterTodoItemsList => [
    {
      key: HOME_PAGE_STATE.CLAIM_STARTER_PACK_KEY,
      title: appI18nInstance.t(
        'home.starter_todo_list.claim_starter_pack.title.be',
      ),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.claim_starter_pack.helper_content.be',
        {
          starterPackLink: appI18nInstance.t('starter_pack_link'),
        },
      ),
      isDone: !!state[HOME_PAGE_STATE.CLAIM_STARTER_PACK_KEY],
      isNotEligible: !!utils.isRegisteredThroughRASO,
    },
    {
      key: HOME_PAGE_STATE.CLAIM_STARTER_DISCOUNT,
      title: appI18nInstance.t(
        'home.starter_todo_list.claim_discount.title.be',
      ),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.claim_discount.helper_content.be',
        {
          starterPackLink: appI18nInstance.t('starter_pack_link'),
          upgradeURL:
            user?.VATNumber === VATTypeEnum.franchisee
              ? `https://app.accountable.eu/api/v1/subscriptions/stripe/checkout-session?userId=${user?._id}&priceId=price_1OizUqD9RdWv1Cv5PUFR93nF&promocode=THaZb86k&redirectUrl=https://web.accountable.eu`
              : `https://app.accountable.eu/api/v1/subscriptions/stripe/checkout-session?userId=${user?._id}&priceId=price_1OizSPD9RdWv1Cv5e8kRMOq1&promocode=THaZb86k&redirectUrl=https://web.accountable.eu`,
        },
      ),
      isDone: !!state[HOME_PAGE_STATE.CLAIM_STARTER_DISCOUNT],
      isNotEligible: !utils.isRegisteredThroughRASO,
    },
    {
      key: HOME_PAGE_STATE.SIMULATE_REVENUE_KEY,
      title: appI18nInstance.t(
        'home.starter_todo_list.simulate_revenue.title.be',
      ),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.simulate_revenue.helper_text.be',
      ),
      onClick: () => openInNewTab(`${REVENUE_SIMULATOR_LINK}${50000}`),
      isDone:
        !!state[HOME_PAGE_STATE.SIMULATE_REVENUE_KEY] ||
        !!utils.isRegisteredThroughRASO,
      isHidden: !!utils.isRegisteredThroughRASO,
    },
    {
      key: HOME_PAGE_STATE.CROSSBANK_KEY,
      title: appI18nInstance.t(
        'home.starter_todo_list.crossroads_bank.title.be',
      ),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.crossroads_bank.helper_text.be',
      ),
      isDone:
        !!state[HOME_PAGE_STATE.CROSSBANK_KEY] ||
        !!utils.isRegisteredThroughRASO,
    },
    {
      key: HOME_PAGE_STATE.SOCIAL_SECURITY_KEY,
      title: appI18nInstance.t('home.starter_todo_list.security_fund.title.be'),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.security_fund.helper_text.be',
      ),
      isDone: !!state[HOME_PAGE_STATE.SOCIAL_SECURITY_KEY],
    },
    {
      key: HOME_PAGE_STATE.PAST_90_DAYS_EXPENSES_KEY,
      title: appI18nInstance.t(
        'home.starter_todo_list.past_ninety_days_expenses.title.be',
      ),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.past_ninety_days_expenses.helper_text.be',
      ),
      isDone: !!state[HOME_PAGE_STATE.PAST_90_DAYS_EXPENSES_KEY],
      onClick: () =>
        utils.openExpenseUploader({
          onUpload: () => null,
          onClickReviewNow: (result) =>
            utils.openExpenseDetails(result?.done?.[0].result),
        }),
    },
    {
      key: HOME_PAGE_STATE.BUSINESS_COUNTER_BILL_KEY,
      title: appI18nInstance.t(
        'home.starter_todo_list.business_counter_bill.title.be',
      ),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.business_counter_bill.helper_text.be',
      ),
      isDone: !!state[HOME_PAGE_STATE.BUSINESS_COUNTER_BILL_KEY],
      onClick: () =>
        utils.openExpenseUploader({
          onUpload: () => null,
          onClickReviewNow: (result) =>
            utils.openExpenseDetails(result?.done?.[0].result),
        }),
    },
    {
      key: HOME_PAGE_STATE.ENTER_VAT_NUMBER_KEY,
      title: appI18nInstance.t('home.starter_todo_list.vat_number.title.be'),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.vat_number.helper_text.be',
      ),
      isDone:
        !!state[HOME_PAGE_STATE.ENTER_VAT_NUMBER_KEY] || !!user?.VATNumber,
      isHidden:
        !utils.isRegisteredThroughRASO &&
        !state[HOME_PAGE_STATE.CROSSBANK_KEY] &&
        !user?.VATNumber,
      shouldHighlight: true,
      hideCheckbox: true,
      onClick: () =>
        utils.openVATNumberForm(() =>
          utils.toggleStateByKey(HOME_PAGE_STATE.ENTER_VAT_NUMBER_KEY),
        ),
    },
  ],
  getRASORequestTimeline: (user?: IUser) => [
    {
      title: appI18nInstance.t('raso_request_status.timeline.event1.title.be'),
      description: appI18nInstance.t(
        'raso_request_status.timeline.event1.description.be',
        {
          requestDate: dayjs(user?.created).format('MMM DD YYYY h:mm a'),
        },
      ),
      status: 'done',
    },
    {
      title: appI18nInstance.t('raso_request_status.timeline.event2.title.be'),
      description: appI18nInstance.t(
        'raso_request_status.timeline.event2.description.be',
      ),
      status: 'pending',
    },
    {
      title: appI18nInstance.t('raso_request_status.timeline.event3.title.be'),
      description: appI18nInstance.t(
        'raso_request_status.timeline.event3.description.be',
        {
          email: user?.email,
        },
      ),
      status: 'not-reached',
    },
  ],
  homeMustReadLinks: () => [
    {
      title: appI18nInstance.t('home.must_read.account_types.title.be'),
      description: appI18nInstance.t(
        'home.must_read.account_types.description.be',
      ),
      link: appI18nInstance.t('home.must_read.account_types.link.be'),
    },
    {
      title: appI18nInstance.t('home.must_read.deductible_expenses.title.be'),
      description: appI18nInstance.t(
        'home.must_read.deductible_expenses.description.be',
      ),
      link: appI18nInstance.t('home.must_read.deductible_expenses.link.be'),
    },
    {
      title: appI18nInstance.t('home.must_read.social_counter.title.be'),
      description: appI18nInstance.t(
        'home.must_read.social_counter.description.be',
      ),
      link: appI18nInstance.t('home.must_read.social_counter.link.be'),
    },
    {
      title: appI18nInstance.t(
        'home.must_read.register_as_selfemployed.title.be',
      ),
      description: appI18nInstance.t(
        'home.must_read.register_as_selfemployed.description.be',
      ),
      link: appI18nInstance.t(
        'home.must_read.register_as_selfemployed.link.be',
      ),
    },
  ],
  revenueSimulationData: [
    {
      monthlyRevenue: 1600,
      yearlyIncome: 10188,
      accountableRate: 16,
      rate: 18,
    },
    {
      monthlyRevenue: 1650,
      yearlyIncome: 10627,
      accountableRate: 15,
      rate: 18,
    },
    {
      monthlyRevenue: 1700,
      yearlyIncome: 11067,
      accountableRate: 16,
      rate: 19,
    },
    {
      monthlyRevenue: 1750,
      yearlyIncome: 11506,
      accountableRate: 15,
      rate: 19,
    },
    {
      monthlyRevenue: 1800,
      yearlyIncome: 11946,
      accountableRate: 16,
      rate: 19,
    },
    {
      monthlyRevenue: 1850,
      yearlyIncome: 12385,
      accountableRate: 16,
      rate: 19,
    },
    {
      monthlyRevenue: 1900,
      yearlyIncome: 12825,
      accountableRate: 16,
      rate: 20,
    },
    {
      monthlyRevenue: 1950,
      yearlyIncome: 13264,
      accountableRate: 17,
      rate: 20,
    },
    {
      monthlyRevenue: 2000,
      yearlyIncome: 13704,
      accountableRate: 16,
      rate: 20,
    },
    {
      monthlyRevenue: 2050,
      yearlyIncome: 14080,
      accountableRate: 17,
      rate: 20,
    },
    {
      monthlyRevenue: 2100,
      yearlyIncome: 14423,
      accountableRate: 17,
      rate: 21,
    },
    {
      monthlyRevenue: 2150,
      yearlyIncome: 14727,
      accountableRate: 17,
      rate: 22,
    },
    {
      monthlyRevenue: 2200,
      yearlyIncome: 15010,
      accountableRate: 18,
      rate: 22,
    },
    {
      monthlyRevenue: 2250,
      yearlyIncome: 15294,
      accountableRate: 20,
      rate: 23,
    },
    {
      monthlyRevenue: 2300,
      yearlyIncome: 15577,
      accountableRate: 21,
      rate: 24,
    },
    {
      monthlyRevenue: 2350,
      yearlyIncome: 15860,
      accountableRate: 21,
      rate: 24,
    },
    {
      monthlyRevenue: 2400,
      yearlyIncome: 16143,
      accountableRate: 21,
      rate: 25,
    },
    {
      monthlyRevenue: 2450,
      yearlyIncome: 16426,
      accountableRate: 22,
      rate: 25,
    },
    {
      monthlyRevenue: 2500,
      yearlyIncome: 16710,
      accountableRate: 22,
      rate: 26,
    },
    {
      monthlyRevenue: 2550,
      yearlyIncome: 16993,
      accountableRate: 23,
      rate: 26,
    },
    {
      monthlyRevenue: 2600,
      yearlyIncome: 17276,
      accountableRate: 23,
      rate: 27,
    },
    {
      monthlyRevenue: 2650,
      yearlyIncome: 17559,
      accountableRate: 23,
      rate: 27,
    },
    {
      monthlyRevenue: 2700,
      yearlyIncome: 17842,
      accountableRate: 24,
      rate: 28,
    },
    {
      monthlyRevenue: 2750,
      yearlyIncome: 18126,
      accountableRate: 23,
      rate: 28,
    },
    {
      monthlyRevenue: 2800,
      yearlyIncome: 18409,
      accountableRate: 24,
      rate: 29,
    },
    {
      monthlyRevenue: 2850,
      yearlyIncome: 18692,
      accountableRate: 24,
      rate: 29,
    },
    {
      monthlyRevenue: 2900,
      yearlyIncome: 18975,
      accountableRate: 26,
      rate: 30,
    },
    {
      monthlyRevenue: 2950,
      yearlyIncome: 19258,
      accountableRate: 26,
      rate: 30,
    },
    {
      monthlyRevenue: 3000,
      yearlyIncome: 19542,
      accountableRate: 26,
      rate: 30,
    },
    {
      monthlyRevenue: 3050,
      yearlyIncome: 19825,
      accountableRate: 27,
      rate: 31,
    },
    {
      monthlyRevenue: 3100,
      yearlyIncome: 20108,
      accountableRate: 27,
      rate: 31,
    },
    {
      monthlyRevenue: 3150,
      yearlyIncome: 20391,
      accountableRate: 26,
      rate: 31,
    },
    {
      monthlyRevenue: 3200,
      yearlyIncome: 20659,
      accountableRate: 27,
      rate: 32,
    },
    {
      monthlyRevenue: 3250,
      yearlyIncome: 20915,
      accountableRate: 28,
      rate: 32,
    },
    {
      monthlyRevenue: 3300,
      yearlyIncome: 21172,
      accountableRate: 29,
      rate: 33,
    },
    {
      monthlyRevenue: 3350,
      yearlyIncome: 21429,
      accountableRate: 27,
      rate: 33,
    },
    {
      monthlyRevenue: 3400,
      yearlyIncome: 21686,
      accountableRate: 28,
      rate: 33,
    },
    {
      monthlyRevenue: 3450,
      yearlyIncome: 21942,
      accountableRate: 29,
      rate: 34,
    },
    {
      monthlyRevenue: 3500,
      yearlyIncome: 22199,
      accountableRate: 31,
      rate: 34,
    },
    {
      monthlyRevenue: 3550,
      yearlyIncome: 22456,
      accountableRate: 28,
      rate: 34,
    },
    {
      monthlyRevenue: 3600,
      yearlyIncome: 22712,
      accountableRate: 30,
      rate: 35,
    },
    {
      monthlyRevenue: 3650,
      yearlyIncome: 22969,
      accountableRate: 31,
      rate: 35,
    },
    {
      monthlyRevenue: 3700,
      yearlyIncome: 23226,
      accountableRate: 31,
      rate: 35,
    },
    {
      monthlyRevenue: 3750,
      yearlyIncome: 23483,
      accountableRate: 29,
      rate: 36,
    },
    {
      monthlyRevenue: 3800,
      yearlyIncome: 23739,
      accountableRate: 31,
      rate: 36,
    },
    {
      monthlyRevenue: 3850,
      yearlyIncome: 23996,
      accountableRate: 31,
      rate: 36,
    },
    {
      monthlyRevenue: 3900,
      yearlyIncome: 24253,
      accountableRate: 30,
      rate: 36,
    },
    {
      monthlyRevenue: 3950,
      yearlyIncome: 24509,
      accountableRate: 33,
      rate: 37,
    },
    {
      monthlyRevenue: 4000,
      yearlyIncome: 24766,
      accountableRate: 32,
      rate: 37,
    },
    {
      monthlyRevenue: 5000,
      yearlyIncome: 29901,
      accountableRate: 34,
      rate: 41,
    },
    {
      monthlyRevenue: 6000,
      yearlyIncome: 34586,
      accountableRate: 37,
      rate: 40,
    },
    {
      monthlyRevenue: 7000,
      yearlyIncome: 39191,
      accountableRate: 40,
      rate: 47,
    },
    {
      monthlyRevenue: 8000,
      yearlyIncome: 43897,
      accountableRate: 42,
      rate: 49,
    },
    {
      monthlyRevenue: 9000,
      yearlyIncome: 48765,
      accountableRate: 43,
      rate: 50,
    },
    {
      monthlyRevenue: 10000,
      yearlyIncome: 53634,
      accountableRate: 45,
      rate: 51,
    },
    {
      monthlyRevenue: 11000,
      yearlyIncome: 58627,
      accountableRate: 45,
      rate: 51,
    },
    {
      monthlyRevenue: 12000,
      yearlyIncome: 64210,
      accountableRate: 45,
      rate: 52,
    },
    {
      monthlyRevenue: 13000,
      yearlyIncome: 69792,
      accountableRate: 45,
      rate: 52,
    },
    {
      monthlyRevenue: 14000,
      yearlyIncome: 75374,
      accountableRate: 45,
      rate: 52,
    },
    {
      monthlyRevenue: 15000,
      yearlyIncome: 80956,
      accountableRate: 45,
      rate: 52,
    },
  ],
  getRevenueSimulatorLink: (amount: number): string =>
    `${REVENUE_SIMULATOR_LINK}${amount}`,
};
