import type { OpenRevenueModalHandler } from 'components/pages/Banks/Classification/useImportRevenueModal';
import type { ExpenseFormWrapperProps } from 'components/pages/Expenses/Form/FormSlideOver';
import type { InvoiceFormWrapperProps } from 'components/pages/Revenues/OtherRevenueForm';
import type { UseConfirmReturn } from 'hooks/useConfirm';
import type { IExpense, Supplier } from 'types/expenses.types';
import type { Invoice } from 'types/invoice.types';
import type { Currency, TaxPeriod } from './global.types';

export type IMatchedItem = {
  type: string;
  documentId: string;
  isCreditNote?: boolean;
  amount?: number;
  sufficientlyDocumented?: boolean;
  supplier?: Supplier;
  expenseDate?: string;
};

type TransactionType =
  | 'deposit'
  | 'debit_card'
  | 'credit_card'
  | 'bank_fees'
  | 'withdrawal'
  | 'check'
  | 'transfer'
  | 'other'
  | 'direct_debit'
  | 'ATM'
  | 'domiciliation';

// outdated, TODO use the enum
export type TransactionCategory =
  | 'professionalExpense'
  | 'creditNoteOnSales'
  | 'professionalIncome'
  | 'creditNoteOnPurchase'
  | 'personalPayment'
  | 'VAT'
  | 'incomeTax'
  | 'loanRepayment'
  | 'capitalMovement'
  | 'realEstateActivities'
  | 'financialIncome'
  | 'miscellaneousActivities'
  | 'other';

export type Transaction = {
  dontAutoCreate: boolean;
  _id: string;
  id?: string;
  userId: string;
  comment: string;
  originalNdx?: number;
  transactionDate: Date;
  communication: string;
  currency: Currency;
  date: Date;
  executionDate: Date;
  valueDate: Date | string;
  text: string;
  amount: number;
  accountName: string;
  accountNumber: string;
  counterPartyName: string;
  counterPartyNumber: number;
  reference: string;
  transactionType: TransactionType;
  matchedItems: IMatchedItem[];
  rawMatchedItems?: IMatchedItem[];
  matchedItemsDetails?: any[];
  created: Date;
  modified: Date;
  isMatched: boolean;
  silenceCTA: boolean;
  transactionCategory: TransactionCategory | null;
  taxPeriod?: TaxPeriod | null;
  baseCurrencyAmount: number;
  shouldUpdateTaxStatus?: boolean; // true on the backend by default
  _source?: string; // frontend helper
  provider: BankProvider;
  invoiceToBeMarkedAsPaid?: { invoiceId: string; invoiceNumber: string }[];
  taxStatusUpdated?: boolean;
};

export type TransactionAsOption = {
  value: string;
  transaction: Transaction;
};

export type AlternativeTransaction = {
  _id: string;
  label: string;
  preset: boolean;
  value: string;
  amount: any;
};

export enum RevenueAlternativeTransactionId {
  notPaidYet = 'notPaidYet',
  otherBank = 'otherBank',
  cash = 'cash',
}

type BankProvider =
  | 'ibanity'
  | 'finAPI'
  | 'ING-camt'
  | 'accountable'
  | 'klarna'
  | 'credit-card';

type BankAccountReference = {
  IBAN: string;
  SWIFT?: string;
  BIC?: string;
  financialInstitutionSlug?: string;
  name?: string;
  email?: string;
};

type ConnectorErrorMessage =
  | 'technicalFailure'
  | 'authorizationFailed'
  | 'unknownError';

type ConnectorStatus =
  | 'ready'
  | 'pending'
  | 'deprecated'
  | ConnectorErrorMessage;

type ConnectorError = {
  date: Date;
  error: ConnectorStatus;
};

type Balance = {
  amount: number;
  currency: 'EUR';
};

type ConnectorSynchronization = {
  id: number;
  createdDate: Date;
  synchronizatedDate: Date;
};

export type Connector = {
  id: string;
  _id: string;
  status: ConnectorStatus;
  bankAccountReference: BankAccountReference;
  lastSynchronizationDate: Date;
  expiresAt: string;
  lastSuccessfulSyncAt: Date;
  createdDate: number;
  latestSynchronizations: ConnectorSynchronization[];
  errors: ConnectorError[] | null;
  provider: BankProvider;
  syncing: boolean;
  balance: Balance;
  taxTipsShown?: boolean;
  automatch: { positive_amount: boolean; negative_amount: boolean };
  manualSyncRequired?: boolean;
  created?: string;
};

export type CashAvailableFactorType = {
  amount: number;
  active: boolean;
};

export type CashAvailableBalanceType = {
  IBAN: string;
  name: string;
  status: 'ready' | 'pending' | 'deleted';
  balance: {
    amount: number;
    currency: Currency;
  };
  baseCurrencyBalance: {
    amount: number;
    currency: Currency;
    currencyRate: number;
    currencyRateDate: string;
  };
  active: boolean | undefined;
};

export type CashAvailableFactorWithPeriodType = {
  currentPeriod: CashAvailableFactorType;
  pastPeriods: CashAvailableFactorType;
};

export type CashAvailableFactorsType = {
  provisionForIncomeTax?: CashAvailableFactorWithPeriodType;
  socialContributions?: CashAvailableFactorWithPeriodType;
  provisionForVAT?: CashAvailableFactorWithPeriodType;
  provisionForSpecialVAT?: CashAvailableFactorWithPeriodType;
  provisionForCompanyTax?: CashAvailableFactorWithPeriodType;
  balance: {
    totalBalance: {
      amount: number;
    };
    individualBalance: CashAvailableBalanceType[];
  };
  salesInvoiceToBePaid?: CashAvailableFactorType;
};

export type CreateConnectorConnectionResponse = {
  redirectUri: string;
};

export type UpdateConnectorConnectionResponse = {
  redirectUri: string;
};

export type CashAvailableSettingsType = {
  balances: {
    IBAN: string;
    balance: boolean;
  }[];
  vatCurrentPeriod: boolean;
  vatPastPeriods: boolean;
  salesInvoices: boolean;
  purchaseInvoices: boolean;
  socialContributionsCurrentPeriod?: boolean;
  socialContributionsPastPeriods?: boolean;
  incomeTaxCurrentPeriod: boolean;
  incomeTaxPastPeriods: boolean;
  specialVatPastPeriods: boolean;
  specialVatCurrentPeriod: boolean;
  companyTaxCurrentPeriod?: boolean;
  companyTaxPastPeriods?: boolean;
};
export type ClassificationSuccessHandler = (
  transaction: Transaction | Transaction[],
  options?: ClassificationEffects,
) => void;

export type LinkingClassificationSuccessHandler = (
  originalTransaction: Transaction | Transaction[],
  updatedTransaction?: Transaction | Transaction[],
  options?: ClassificationEffects,
) => void;

export enum TransactionCategoryWithNextStepsEnum {
  professionalIncome = 'professionalIncome',
  professionalExpense = 'professionalExpense',
  creditNoteOnSales = 'creditNoteOnSales',
  creditNoteOnPurchase = 'creditNoteOnPurchase',
}

export enum TransactionCategoryWithOutNextStepsEnum {
  VATReimbursement = 'VATReimbursement',
  incomeTaxReimbursement = 'incomeTaxReimbursement',
  withholdingTaxReimbursement = 'withholdingTaxReimbursement',
  incomingLoan = 'incomingLoan',
  incomingLoanReimbursement = 'incomingLoanReimbursement',
  incomingCapitalMovement = 'incomingCapitalMovement',
  otherProfessionalIncomingPayment = 'otherProfessionalIncomingPayment',
  realEstateActivities = 'realEstateActivities',
  financialIncome = 'financialIncome',
  miscellaneousActivities = 'miscellaneousActivities',
  otherNonProfessionalIncome = 'otherNonProfessionalIncome',
  personalIncomingPayment = 'personalIncomingPayment',
  VATPayment = 'VATPayment',
  withholdingTaxPayment = 'withholdingTaxPayment',
  incomeTaxPayment = 'incomeTaxPayment',
  incomeTaxPrePayment = 'incomeTaxPrePayment',
  outgoingLoan = 'outgoingLoan',
  outgoingLoanReimbursement = 'outgoingLoanReimbursement',
  outgoingCapitalMovement = 'outgoingCapitalMovement',
  otherProfessionalOutgoingPayment = 'otherProfessionalOutgoingPayment',
  personalOutgoingPayment = 'personalOutgoingPayment',
  socialContributions = 'socialContributions',
  // temporarely putting back the old categories to ensure backward compatibility
  personalPayment = 'personalPayment',
  VAT = 'VAT',
  incomeTax = 'incomeTax',
  loanRepayment = 'loanRepayment',
  capitalMovement = 'capitalMovement',
  other = 'other',
  creditCardReimbursement = 'creditCardReimbursement',
  companyTaxPayment = 'companyTaxPayment',
  companyTaxPrePayment = 'companyTaxPrePayment',
  companyTaxReimbursement = 'companyTaxReimbursement',
  tradeTaxPrePayment = 'tradeTaxPrePayment',
}

export enum TransactionCategoryEnum {
  professionalIncome = 'professionalIncome',
  professionalExpense = 'professionalExpense',
  creditNoteOnSales = 'creditNoteOnSales',
  creditNoteOnPurchase = 'creditNoteOnPurchase',
  ////
  VATReimbursement = 'VATReimbursement',
  incomeTaxReimbursement = 'incomeTaxReimbursement',
  withholdingTaxReimbursement = 'withholdingTaxReimbursement',
  incomingLoan = 'incomingLoan',
  incomingLoanReimbursement = 'incomingLoanReimbursement',
  incomingCapitalMovement = 'incomingCapitalMovement',
  otherProfessionalIncomingPayment = 'otherProfessionalIncomingPayment',
  realEstateActivities = 'realEstateActivities',
  financialIncome = 'financialIncome',
  miscellaneousActivities = 'miscellaneousActivities',
  otherNonProfessionalIncome = 'otherNonProfessionalIncome',
  personalIncomingPayment = 'personalIncomingPayment',
  VATPayment = 'VATPayment',
  withholdingTaxPayment = 'withholdingTaxPayment',
  incomeTaxPayment = 'incomeTaxPayment',
  incomeTaxPrePayment = 'incomeTaxPrePayment',
  outgoingLoan = 'outgoingLoan',
  outgoingLoanReimbursement = 'outgoingLoanReimbursement',
  outgoingCapitalMovement = 'outgoingCapitalMovement',
  otherProfessionalOutgoingPayment = 'otherProfessionalOutgoingPayment',
  personalOutgoingPayment = 'personalOutgoingPayment',
  socialContributions = 'socialContributions',
  // temporarely putting back the old categories to ensure backward compatibility
  personalPayment = 'personalPayment',
  VAT = 'VAT',
  incomeTax = 'incomeTax',
  loanRepayment = 'loanRepayment',
  capitalMovement = 'capitalMovement',
  other = 'other',
  creditCardReimbursement = 'creditCardReimbursement',
  companyTaxPayment = 'companyTaxPayment',
  companyTaxPrePayment = 'companyTaxPrePayment',
  tradeTaxPrePayment = 'tradeTaxPrePayment',
  companyTaxReimbursement = 'companyTaxReimbursement',
}

export enum ClassificationActionTypes {
  create = 'create',
  import = 'import',
  scan = 'scan',
  linkExisting = 'link_existing',
  classifyAs = 'classify_as',
}

export enum ClassificationDocumentTypes {
  expense = 'expense',
  invoice = 'invoice',
  otherRevenue = 'other_revenue',
  creditNoteOnSales = 'credit_note_on_sales',
  creditNoteOnPurchase = 'credit_note_on_purchase',
}

export type GuessedCategory = {
  title: string;
  isGuessed: true;
  action: {
    type: ClassificationActionTypes;
    documentOptions?: any;
  };
  id: TransactionCategoryEnum;
};

export type ClassificationEffects = {
  showSuccessAlert?: boolean;
  showConfetti?: boolean;
  transactionsIds?: string[];
  showStamp?: boolean;
  // keepSlideover?: boolean;
};

export type ClassificationApi = {
  openExpenseDetails: (
    doc: Partial<IExpense>,
    options?: Partial<ExpenseFormWrapperProps>,
  ) => void;
  openRevenueDetails: (
    doc: Partial<Invoice>,
    options?: Partial<InvoiceFormWrapperProps>,
  ) => void;
  openImportRevenueModal: OpenRevenueModalHandler;
  openConfirmationModal: UseConfirmReturn['1']['open'];
  onClassificationSuccess: (
    transactions: Transaction | Transaction[],
    options?: ClassificationEffects,
  ) => void;
  showSuccessAlert: () => void;
};

export type CreditCardStatementPeriod = {
  start: string | number;
  end: string | number;
};

export type CreditCardStatementStatus =
  | 'failed'
  | 'processing'
  | 'pending-review'
  | 'reviewed';

export type CreditCardStatementOCR = {
  connectorId: string;
  created: string;
  filePath: string;
  modified: string;
  status: CreditCardStatementStatus;
  userId: string;
  __v: string;
  _id: string;
  originalFilename: string;
  period?: CreditCardStatementPeriod;
  failureReason?: string;
};

export type CreditCardStatementOCRTransaction = {
  amount: number | null;
  baseCurrency: Currency;
  baseCurrencyAmount: number | null;
  connectorId: string;
  counterPartyName: string;
  created: string;
  currency: Currency;
  modified: string;
  statementId: string;
  statementPeriod: CreditCardStatementPeriod;
  userId: string;
  valueDate: string;
  __v: number | null;
  _id: string;
  transactionCategory?: string | null;
  matchedItems?: IMatchedItem[];
  id?: string;
  isNew?: boolean;
};
