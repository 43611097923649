import { GetExpenseCategoriesGuessedResponse } from 'api/v2/expenses';
import { Tooltip } from 'components/atoms/Tooltip';
import AIButtonConsentCheck from 'components/molecules/AIButtonConsentCheck';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { logAmplitude } from 'utils/amplitude';
import {
  WEB_AICATEGORYGUESSER_CATEGORYGUESSERTRIGGERED,
  WEB_AICATEGORYGUESSER_USERCHOSECATEGORYFROMCATEGORYGUESSER,
} from 'utils/amplitude/events/ai.amplitude';
import {
  CategoriesPickerControlled,
  CategoriesPickerProps,
} from './CategoriesPicker';
import { UseGuessExpenseCategoryWithAIReturn } from './useGuessExpenseCategoryWithAI';

const AnimationWrapper = ({ children, ...props }: HTMLMotionProps<any>) => (
  <motion.div
    initial={{ scale: 0.5, opacity: 0 }}
    animate={{ scale: 1, opacity: 1, transition: { duration: 0.3 } }}
    exit={{ scale: 0.5, opacity: 0, transition: { duration: 0.3 } }}
    className="mt-2"
    {...props}
  >
    {children}
  </motion.div>
);

const CategoryGuessed = ({
  onClick,
  mostLikely,
  title,
}: {
  onClick: () => void;
  mostLikely?: boolean;
  title: string;
}) => {
  const { t } = useTranslation();

  if (!title) return null;

  return (
    <button
      type="button"
      className="flex-1 relative px-5 py-3 color-taxes font-bold border border-taxes-100 rounded-lg cursor-pointer hover:bg-taxes hover:text-white transition-colors duration ease-in-out leading-4 text-xs justify-center items-center flex mt-3 text-taxes"
      onClick={onClick}
    >
      {mostLikely && (
        <span className="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-taxes-200 text-taxes p-1 rounded-full text-[10px] font-bold text-center w-[75px]">
          {t('expense.category.guess.most_likely')}
        </span>
      )}
      {title}
    </button>
  );
};

type CategoryPickerWithAIProps = CategoriesPickerProps &
  UseGuessExpenseCategoryWithAIReturn;

const CategoryPickerWithAI: FC<CategoryPickerWithAIProps> = ({
  query,
  enabled,
  showGuessedCategories,
  setShowGuessedCategories,
  ...props
}) => {
  const { t } = useTranslation();
  const { isLoading, isError, refetch, data } = query;

  const toggleShowCategories = useCallback(() => {
    if (!enabled) return;

    if (!showGuessedCategories) {
      logAmplitude(WEB_AICATEGORYGUESSER_CATEGORYGUESSERTRIGGERED);
    }
    setShowGuessedCategories(!showGuessedCategories);
  }, [showGuessedCategories]);

  const onSelectGuessedCategory = useCallback(
    (guessedCategory: GetExpenseCategoriesGuessedResponse) => {
      props.onChange(guessedCategory?.category, {
        guessedCategoryFrom: guessedCategory?.guessedCategoryFrom,
        vehicle: guessedCategory?.vehicle,
      });
      logAmplitude(WEB_AICATEGORYGUESSER_USERCHOSECATEGORYFROMCATEGORYGUESSER);
    },
    [props.onChange, data],
  );

  if (!enabled || props.value) {
    return <CategoriesPickerControlled {...props} />;
  }

  const { categoriesDict } = props.pickerController?.api?.data || {};

  return (
    <>
      <div className="relative">
        <CategoriesPickerControlled {...props} />

        <Tooltip content={t('ai_bot.tooltip.suggest_category')}>
          <AIButtonConsentCheck
            loading={isLoading || props.pickerController.query.isLoading}
            onClick={toggleShowCategories}
            requiresFullAccess
            className="absolute right-14 top-1/2 transform -translate-y-1/2 cursor-pointer"
          />
        </Tooltip>
      </div>

      {showGuessedCategories && (
        <AnimatePresence>
          {/* show error */}
          {isError && (
            <AnimationWrapper>
              {t('expense.category.guess.error')}{' '}
              <button type="button" onClick={() => refetch()}>
                {t('retry')}
              </button>
            </AnimationWrapper>
          )}

          {/* show no results */}
          {!isLoading && !isError && data?.length === 0 && (
            <AnimationWrapper>
              {t('expense.category.guess.no_results')}
            </AnimationWrapper>
          )}

          {/* show suggested categories */}
          {!isLoading && !isError && data?.length && (
            <AnimationWrapper className="flex flex-row space-x-3 mt-2">
              {data?.map((guess, i) => (
                <CategoryGuessed
                  onClick={() => onSelectGuessedCategory(guess)}
                  mostLikely={i === 0}
                  key={guess?.category?.id}
                  title={categoriesDict?.[guess?.category?.id]?.title}
                />
              ))}
            </AnimationWrapper>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default CategoryPickerWithAI;
