import classNames from 'classnames';
import useWorldTheme from 'hooks/shared/useWorldTheme';
import useCustomDropzone from 'hooks/useCustomDropzone';
import type { FC } from 'react';
import { useState } from 'react';
import type { DropzoneProps } from './Dropzone.types';

const theme: { [key: string]: { [key: string]: string } } = {
  expenses: {
    base: 'bg-expenses-100 dropzone-border-expenses',
    interactive: 'hover:bg-expenses-50',
  },
  taxes: {
    base: 'bg-taxes-100 dropzone-border-taxes',
    interactive: 'hover:bg-taxes-50',
  },
  revenues: {
    base: 'bg-revenues-100 dropzone-border-revenues',
    interactive: 'hover:bg-revenues-50',
  },
  bank: {
    base: 'bg-bank-100 dropzone-border-bank',
    interactive: 'hover:bg-bank-50',
  },
};

const Dropzone: FC<DropzoneProps> = ({
  dropOptions,
  render,
  className,
  color,
}): JSX.Element => {
  const worldColor = useWorldTheme();

  const { getRootProps, getInputProps, ...meta } =
    useCustomDropzone(dropOptions);

  const [isHovered, setIsHovered] = useState(false);

  const dropzoneMeta = {
    ...meta,
    isHovered,
  };

  return (
    <div
      {...getRootProps({
        onMouseEnter: () => setIsHovered(true),
        onMouseLeave: () => setIsHovered(false),
      })}
      className={classNames(
        className,
        theme[color || worldColor].base,
        'rounded-lg',
        {
          'cursor-not-allowed !opacity-50': dropOptions?.disabled,
          [`${theme[color || worldColor].interactive} cursor-pointer`]:
            !dropOptions?.disabled,
        },
      )}
    >
      <input {...getInputProps()} />

      <div className="h-full flex flex-col justify-center items-center py-6 px-3">
        {render(dropzoneMeta)}
      </div>
    </div>
  );
};

export default Dropzone;
