import dayjs from 'dayjs';
import TagManager from 'react-gtm-module';
import type { Period } from 'types/global.types';
import { isWeb, signupDateStorageKey } from 'utils/constants';

/// ------------------- Events enums ------------------- ///

enum ActiveEvents {
  // ActiveEvents events are events that will trigger an "activeEvent" in GTM for ONE time only when any of these events are triggered.
  createExpense = 'createExpense',
  createInvoice = 'createInvoice',
  createClient = 'createClient',
  createQuote = 'createQuote',
  submitTax = 'submitTax',
  updateTax = 'updateTax',
  saveBankTransaction = 'saveBankTransaction',
  generateExport = 'generateExport',
  paidCustomer = 'paidCustomer',
  connectBankAccount = 'connectBankAccount',
}

enum OtherEvents {
  createdAccount = 'createdAccount',
  submittedEmail = 'WSEmail',
}

/// ------------------- Types ------------------- ///

type DataLayer = {
  event: string;
  payload?: Record<string, any>;
};

/// ------------------- Setup ------------------- ///

const activeEventsArray = Object.values(ActiveEvents) as string[];

const isActiveFlag = 'is_user_active';
const activeFlagValue = '1';

const isUserActive = () => {
  return localStorage.getItem(isActiveFlag) === activeFlagValue;
};

const markUserAsActive = () => {
  localStorage.setItem(isActiveFlag, activeFlagValue);
};

const isSameDaySignup = () => {
  const signupDate = localStorage.getItem(signupDateStorageKey);

  if (!signupDate) return false;

  return Math.abs(dayjs(signupDate).diff(dayjs(), 'h')) <= 36;
};

const maybeMarkUserAsActive = (data: DataLayer) => {
  if (!isWeb) return;

  if (isUserActive()) return;

  if (!isSameDaySignup()) return;

  if (!activeEventsArray.includes(data.event)) return;

  TagManager.dataLayer({
    dataLayer: { event: 'activeEvent2', payload: data },
  });

  markUserAsActive();
};

const sendEvent = (data: DataLayer) => {
  TagManager.dataLayer({ dataLayer: data });

  maybeMarkUserAsActive(data);
};

/// ------------------- Active Events ------------------- ///

export const onCreateExpenseGTM = () => {
  sendEvent({ event: ActiveEvents.createExpense });
};

export const onCreateInvoiceGTM = () => {
  sendEvent({ event: ActiveEvents.createInvoice });
};

export const onCreateClientGTM = () => {
  sendEvent({ event: ActiveEvents.createClient });
};

export const onCreateQuoteGTM = () => {
  sendEvent({ event: ActiveEvents.createQuote });
};

export const onSubmitTaxGTM = (period: Period, type: string) => {
  sendEvent({ event: ActiveEvents.submitTax, payload: { period, type } });
};

export const onUpdateTaxGTM = (period: Period, type: string) => {
  sendEvent({ event: ActiveEvents.updateTax, payload: { period, type } });
};

export const onSaveBankTransactionGTM = () => {
  sendEvent({ event: ActiveEvents.saveBankTransaction });
};

export const onGenerateExportGTM = () => {
  sendEvent({ event: ActiveEvents.generateExport });
};

export const onPaidCustomerGTM = () => {
  sendEvent({ event: ActiveEvents.paidCustomer });
};

export const onConnectBankGTM = () => {
  sendEvent({ event: ActiveEvents.connectBankAccount });
};

/// ------------------- Other Events ------------------- ///

export const onCreatedAccount = (_id: string) => {
  sendEvent({
    event: OtherEvents.createdAccount,
    payload: { _id },
  });
};

export const onSubmittedEmail = () => {
  sendEvent({ event: OtherEvents.submittedEmail });
};
