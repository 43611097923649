import { isProduction } from 'config';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useApplySMALL25Discount from 'hooks/Growth/useApplySMALL25Discount';
import { useCallback } from 'react';
import type { SubscriptionPlan } from 'types/subscriptions.types';
import { AvailableCountries } from 'types/users.types';
import {
  SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT_CODE_DEV,
  SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT_CODE_PROD,
  SMALL_BUSINESS_PLAN_DISCOUNT_CODE_DEV,
  SMALL_BUSINESS_PLAN_DISCOUNT_CODE_PROD,
} from 'utils/constants';
import { isSubscriptionPaidPlan } from 'utils/subscriptions.utils';
import useGroupSubscriptionPlans from './useGroupSubscriptionPlans';
import useRedirectToCheckoutSession from './useRedirectToCheckoutSession';
import useRedirectToCustomerBillingPortal from './useRedirectToCustomerBillingPortal';
import useSubscriptionPlan from './useSubscriptionPlan';

const defaultSmallDiscount = isProduction
  ? SMALL_BUSINESS_PLAN_DISCOUNT_CODE_PROD
  : SMALL_BUSINESS_PLAN_DISCOUNT_CODE_DEV;

const ABSmallDiscount = isProduction
  ? SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT_CODE_PROD
  : SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT_CODE_DEV;

const useStripeSubscription = () => {
  const subscriptionPlan = useSubscriptionPlan();
  const { planInternalName, fromPlatform } = subscriptionPlan || {};
  const { data: user } = useGetCurrentCustomerData();
  const {
    mutate: redirectToBilingPortal,
    isLoading: isBillingPortalLoading,
    isError: isBillingPortalError,
  } = useRedirectToCustomerBillingPortal();
  const {
    mutate: redirectToCheckoutSession,
    isLoading: isCheckoutSessionLoading,
    isError: isCheckoutSessionError,
  } = useRedirectToCheckoutSession(user);
  const { data: subscriptionPlans = [] } = useGroupSubscriptionPlans(
    subscriptionPlan?.planInternalName,
  );

  const shouldShowSmall25Discount = useApplySMALL25Discount();

  const subscribe = useCallback(
    async (targetPlan: any) => {
      let plan = targetPlan;

      if (typeof targetPlan === 'string') {
        plan = subscriptionPlans?.find(
          (plan: SubscriptionPlan) => plan.nickname === targetPlan,
        );
      }

      if (
        (fromPlatform === 'stripe' || fromPlatform === 'stripe-otp') &&
        isSubscriptionPaidPlan(planInternalName)
      ) {
        return redirectToBilingPortal();
      }

      const isSMALLBusinessPlan = plan?.nickname?.includes('small');
      const isGermanUser = user?.country === AvailableCountries.germany;

      return redirectToCheckoutSession({
        planId: plan?.id as string,
        promocode: !isSMALLBusinessPlan
          ? undefined
          : shouldShowSmall25Discount
          ? ABSmallDiscount
          : isGermanUser
          ? defaultSmallDiscount
          : undefined,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscriptionPlans, fromPlatform, planInternalName],
  );

  return {
    subscribe,
    isLoading: isCheckoutSessionLoading || isBillingPortalLoading,
    isError: isBillingPortalError || isCheckoutSessionError,
  };
};

export default useStripeSubscription;
