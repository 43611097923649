import dayjs from 'dayjs';
import i18n from 'i18n';
import type { ITax } from 'types/taxes.types';
import type { UserCountry } from 'types/users.types';

const getTitleVATToPay = (tax: Partial<ITax>, country: UserCountry): string => {
  if (tax?.period?.month) {
    return i18n.t(`taxes.vat_payment_month_year.${country}`, {
      month: dayjs()
        .month((tax.period.month as number) - 1)
        .format('MMMM'),
      year: tax.period && tax.period.year,
    });
  } else if (tax?.period?.quarter) {
    return i18n.t(`taxes.vat_payment_quarter_year.${country}`, {
      quarter: tax.period && tax.period.quarter,
      year: tax.period && tax.period.year,
    });
  } else {
    return i18n.t(`taxes.vat_payment_year.${country}`, {
      year: tax.period && tax.period.year,
    });
  }
};

export default getTitleVATToPay;
