import type { InvoiceTemplatesEnum } from './invoice.types';
import type {
  ActivityEnum,
  ProfitProjectionEnum,
  TaxableIncomeCalcMethodTypes,
} from './taxes.types';

export type AccountType =
  | 'one_person_company'
  | 'natural_person_student_vat'
  | 'natural_person_student_no_vat'
  | 'natural_person_principal_vat'
  | 'natural_person_principal_no_vat'
  | 'natural_person_complementary'
  | 'freiberufler_principal_vat'
  | 'freiberufler_principal_no_vat'
  | 'freiberufler_complementary';
export enum AccountTypeEnum {
  one_person_company = 'one_person_company',
  natural_person = 'natural_person',
  freiberufler = 'freiberufler',
  gewerbetreibender = 'gewerbetreibender',
  natural_person_student_vat = 'natural_person_student_vat',
  natural_person_student_no_vat = 'natural_person_student_no_vat',
  natural_person_principal_vat = 'natural_person_principal_vat',
  natural_person_principal_no_vat = 'natural_person_principal_no_vat',
  natural_person_complementary = 'natural_person_complementary',
  freiberufler_principal_vat = 'freiberufler_principal_vat',
  freiberufler_principal_no_vat = 'freiberufler_principal_no_vat',
  freiberufler_complementary = 'freiberufler_complementary',
}
export type VATReturnFrequency = 'monthly' | 'quarterly' | 'yearly';

export enum VATReturnFrequencyEnum {
  monthly = 'monthly',
  quarterly = 'quarterly',
  yearly = 'yearly',
}

export enum VATTypeEnum {
  exempt = 'exempt',
  franchisee = 'franchisee',
  subjectToVAT = 'subjectToVAT',
  exonerated = 'exonerated', // DE
}

export type VATType = keyof typeof VATTypeEnum;

export type TaxesSettings = {
  VATReturnFrequency: VATReturnFrequency;
  taxableIncomeCalcMethod: {
    type: TaxableIncomeCalcMethodTypes;
  };
  years: {
    [key: number]: {
      taxableIncome: number;
      expectedSelfEmployedIncome: number;
      payments: any[]; // TODO: Modify this to be Tax[] once the taxes types are added
    };
  };
};

export type RGBColor = {
  r: number;
  g: number;
  b: number;
};

export type TemplateColor = RGBColor | string;

export type TemplateHeader = {
  color: TemplateColor;
};

export type TemplateFooter = {
  color: TemplateColor;
};

type TemplateLogo = {
  filename?: string;
  filePath?: string;
};

export type DefaultMessageTemplate = {
  default?: string;
};

export type TemplateMessage = {
  default: DefaultMessageTemplate;
};

export type Template = {
  header?: TemplateHeader;
  footer?: TemplateFooter;
  language?: AvailableLanguages;
  logo?: TemplateLogo;
  termsAndConditions?: string | DefaultMessageTemplate;
  messageTemplate?: TemplateMessage;
  usePaymentQrCode?: boolean;
  useCommunication?: boolean;
  useDownpayment?: boolean;
  templateType?: InvoiceTemplatesEnum;
  emailHtmlTemplate?: string;
  enableTracking?: boolean;
  fontFamily?: string;
  doesUnitPriceIncludeVAT?: boolean;
  includeCopyrightReminder?: boolean;
  showDeliveryPeriod?: boolean;
};

export type InvoiceTemplate = Template & {
  reminderHtmlTemplate?: string | null;
  reminderSubject?: string | null;
  automaticRemindersMarginDays?: number[] | null;
};

export type QuoteTemplate = Template;

export type CashAvailableSettings = {
  balance: boolean;
  currentPeriod: boolean;
  history: boolean;
  salesInvoices: boolean;
  purchaseInvoices: boolean;
  nextSocialContributions: boolean;
  provisionForIncomeTaxes: boolean;
};

export type UserSettings = {
  taxes: TaxesSettings;
  invoices: InvoiceTemplate;
  quotes?: QuoteTemplate;
  profit: {
    option: ProfitProjectionEnum;
    manualProfitBeforeTax?: number;
    companyTaxRate?: number;
  };
  VATNumber: string;
  cashAvailable: CashAvailableSettings;
  emailAliases: string[];
  datev?: any;
};
interface NACECodes {
  main: number[];
  secondary: number[];
}

interface IAddress {
  zip?: string;
  zipcode?: string;
  city?: string;
  street?: string;
  country: string;
  countryCode?: string;
  state?: string;
  municipality?: string;
}

type ComplementaryDays = 1 | 2 | 3 | 4 | 5 | null;

enum AvailableCountries {
  belgium = 'be',
  germany = 'de',
}

export enum ActivityTypesEnum {
  student = 'student',
  principal = 'principal',
  complementary = 'complementary',
}

type UserCountry = AvailableCountries;

interface IInstallation {
  token: string;
  expoVersion: string;
  deviceId: string;
  deviceName: string;
  deviceYearClass: number;
  platform: {
    OS?: string;
    platform?: string;
    systemVersion?: string;
    model?: string;
    userInterfaceIdiom?: string;
    buildNumber?: string;
  };
  provider: 'expo';
}

interface Referral {
  userId: string;
  source: string;
  created: Date;
  updated: Date;
  validated: boolean;
  processed: boolean;
}

interface IUsedPromotions {
  source: string;
  promo: {
    code: string;
    effect: {
      duration: number;
      unit: string;
    };
    expires: Date;
  };
}

interface IInvalidPromocode {
  code: string;
  reason: string;
}

export enum SubscriptionPlanNameEnum {
  free = 'eu.accountable.free',
  trial1 = 'eu.accountable.trial1',
  basic1 = 'eu.accountable.basic1',
  basic12 = 'eu.accountable.basic12',
  pro1 = 'eu.accountable.pro1',
  pro12 = 'eu.accountable.pro12',
  ambassador = 'eu.accountable.ambassador',
  small1 = 'eu.accountable.small1',
  small12 = 'eu.accountable.small12',
}

export interface ISubscriptionPlan {
  planId: string;
  planInternalName: SubscriptionPlanNameEnum;
  nextReset: Date | 'INFINITE';
  firstSubscriptionDate?: Date;
  lastSubscriptionDate?: Date;
  start: Date;
  end: Date | 'INFINITE';
  fromPlatform: string;
  status: {
    valid: true;
    autoRenew: boolean;
    didRefund: boolean;
    cancelationReason: null;
    retryPeriodEnd: Date | null;
    inRetryPeriod: boolean;
  };
  meta?: { subscription?: { start_date?: number } };
  subscriptionId: string;
  paymentId: string;

  features: {
    counters: {
      documents: {
        total: Date | 'INFINITE';
        units: Date | 'INFINITE';
      };
    };
    locks: {
      analytics: boolean;
      invoiceCustomization: boolean;
    };
  };
  created: Date;
  updated: Date;
}

export enum UserCountryEnum {
  be = 'be',
  de = 'de',
}

export enum UserAiUsageConsentEnum {
  allow = 'allow',
  limited = 'limited',
  deny = 'deny',
}

interface IUser {
  _id: string;
  showProjectedIncomeTax?: boolean;
  created?: string;
  chiftLinks?: any[];
  IBAN: string | null;
  SWIFT: string | null;
  steuernummer?: string;
  bufaNumber?: string;
  firstName: string;
  lastName: string;
  email: string;
  VATType: VATType;
  isVATSubject: boolean;
  photoSrc: string;
  photoPath: string;
  accountType: AccountType;
  VATReturnFrequency?: VATReturnFrequency;
  VATNumber?: string;
  leitwegId?: string;
  companyName?: string;
  address: IAddress;
  subscriptionPlan: ISubscriptionPlan;
  language: number;
  country: UserCountry;
  gratifications?: { [key: number]: any };
  settings: UserSettings;
  startDate?: string;
  phoneNumber?: string | null;
  occupation?: string;
  APC1?: string;
  APC2?: string;
  complementaryDays?: number | null;
  activity?: string;
  _raw: any;
  isSubjectToSocialContributions: boolean;
  nationalRegistryNumber?: string;
  firstTaxComputationYear?: number;
  featureFlags?: string[];
  unValidatedExpensesCount?: number;
  unValidatedRevenuesCount?: number;
  guessRevenueUsage?: 'invoice' | 'other-revenue';
  NACECodes?: NACECodes;
  hasLoggedInMobile?: boolean;
  hasLoggedInWeb?: boolean;
  authMethods?: string[];
  googleEmail?: string;
  appleEmail?: string;
  isNotYetSelfEmployed?: boolean;
  professionType?: ActivityEnum;
  raso?: {
    referenceId?: string | null;
    taxOffice?: string | null;
    businessAddress?: {
      street?: string | null;
      city?: string | null;
      postcode?: string | null;
    };
    homeAddress?: {
      street?: string | null;
      city?: string | null;
      postcode?: string | null;
    };
  };
  aiUsageConsent?: UserAiUsageConsentEnum;
}

interface IUserSnapShot extends Document {
  language: number;
  address: IAddress;
  companyName: string;
  firstName: string;
  lastName: string;
  VATNumber: string;
  SWIFT: string;
  IBAN: string;
  bufaNumber?: string;
  email: string;
  VATType: string; //VATTypeEnum;
  VATReturnFrequency: VATReturnFrequency; // VATReturnFrequencyEnum;
  accountType: string; //AccountTypeEnumDE;
  settings: any;
  complementaryDays: number;
  country: 'be' | 'de';
  steuernummer: string;
  subscriptionPlan: any;
  phoneNumber?: string;
}
export type Organization = {
  ITAANumber?: string;
  name?: string;
  email?: string;
  address?: string;
  language?: string;
  phoneNumber?: string;
  isNewOrg?: boolean;
  label?: string;
  value?: string;
  members?: { firstName: string; lastName: string; email: string }[];
};

enum AvailableLanguagesBE {
  en = 'en',
  fr = 'fr',
  nl = 'nl',
}

enum AvailableLanguagesDE {
  en = 'en',
  de = 'de',
}

enum AvailableLanguages {
  en = 'en',
  fr = 'fr',
  de = 'de',
  nl = 'nl',
}

type UserLanguage = AvailableLanguages;

enum Role {
  user = 'user',
  admin = 'admin',
  accountant = 'accountant',
}

export enum UserFeatures {
  AI_Assistant = 'AI-Assistant',
}

export {
  NACECodes,
  IAddress,
  ComplementaryDays,
  AvailableCountries,
  UserCountry,
  IInstallation,
  IUsedPromotions,
  IInvalidPromocode,
  IUser,
  IUserSnapShot,
  AvailableLanguages,
  UserLanguage,
  Role,
  AvailableLanguagesBE,
  AvailableLanguagesDE,
};
