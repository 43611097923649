import {
  getInvoiceItemExclVAT,
  getInvoiceItemsTotalExclVAT,
} from 'components/pages/Revenues/OtherRevenueForm/utils';
import i18n from 'i18n';
import { round } from 'lodash';
import type { IExpense } from 'types/expenses.types';
import type { IRevenue } from 'types/revenues.types';
import { RevenueType } from 'types/revenues.types';
import type {
  TaxImpactIncomeTax,
  TaxImpactItem,
  TaxImpactVAT,
} from 'types/taxes.types';
import { TaxImpactDocumentType } from 'types/taxes.types';
import type { AvailableCountries, UserCountry } from 'types/users.types';
import formatMoney from 'utils/formatMoney';

export const getTaxImpactHeaderTitle = (
  documentType: TaxImpactDocumentType,
  numberOfItems: number,
  itemNumber?: number,
): string => {
  if (!itemNumber && itemNumber !== 0) {
    return documentType === TaxImpactDocumentType.REVENUE ||
      documentType === TaxImpactDocumentType.OTHER_REVENUE
      ? i18n.t('tax_impact.action_sheet.header.title.total.invoice')
      : i18n.t('tax_impact.action_sheet.header.title.total.expense');
  } else {
    return documentType === TaxImpactDocumentType.REVENUE ||
      documentType === 'other-revenue'
      ? i18n.t('tax_impact.action_sheet.header.title.item.invoice', {
          itemNumber: itemNumber + 1,
          total: numberOfItems,
        })
      : i18n.t('tax_impact.action_sheet.header.title.item.expense', {
          itemNumber: itemNumber + 1,
          total: numberOfItems,
        });
  }
};
export const getVATHeaderValue = (
  documentType: TaxImpactDocumentType,
  vat: TaxImpactVAT,
  isCreditNote?: boolean,
): number => {
  if (documentType === TaxImpactDocumentType.EXPENSE) {
    return getExpenseVATHeaderValue(vat, isCreditNote);
  } else {
    return getRevenueVATHeaderValue(vat, isCreditNote);
  }
};

export const getExpenseVATHeaderValue = (
  vat: TaxImpactVAT,
  isCreditNote?: boolean,
): number => {
  if (vat?.reverseChargeAmountToPay && vat?.reverseChargeAmountToPay !== 0) {
    return isCreditNote
      ? -1 * (vat?.reverseChargeAmountToPay || 0)
      : vat?.reverseChargeAmountToPay || 0;
  } else {
    return isCreditNote
      ? -1 * (vat?.recoverableAmount || 0)
      : vat?.recoverableAmount || 0;
  }
};

export const getRevenueVATHeaderValue = (
  vat: TaxImpactVAT,
  isCreditNote?: boolean,
): number => (isCreditNote ? -vat.statement.xx : vat.statement.xx);

export const getIncomeTaxHeaderValue = (
  documentType: TaxImpactDocumentType,
  incomeTax: TaxImpactIncomeTax,
  isCreditNote?: boolean,
): number => {
  return documentType === TaxImpactDocumentType.EXPENSE
    ? getExpenseIncomeTaxHeaderValue(incomeTax)
    : getRevenueIncomeTaxHeaderValue(incomeTax, isCreditNote);
};

const getExpenseIncomeTaxHeaderValue = (
  incomeTax: TaxImpactIncomeTax,
): number => {
  return incomeTax.amount;
};
const getRevenueIncomeTaxHeaderValue = (
  incomeTax: TaxImpactIncomeTax,
  isCreditNote?: boolean,
): number => {
  return isCreditNote ? -incomeTax.amount : incomeTax.amount;
};

export type SectionItem = {
  title?: string;
  label: string;
  value: string;
  extra?: string;
  isDeductibility?: boolean;
};

export const getIncomeTaxHeaderTitle = (
  flatFeeUsed: boolean,
  documentType: TaxImpactDocumentType,
  taxRate: number,
): string =>
  documentType === TaxImpactDocumentType.EXPENSE
    ? i18n.t(
        flatFeeUsed
          ? 'tax_impact.action_sheet.section.income_tax.title.flat_fee'
          : 'tax_impact.action_sheet.section.income_tax.title.savings',
        {
          taxRate: round(taxRate || 0, 2),
        },
      )
    : i18n.t('tax_impact.action_sheet.income_tax.revenue.title_1', {
        taxImpact: 0,
        taxRate: round(taxRate || 0, 2),
      });

export const getItemsFromTaxImpactIncomeTax = (
  userCountry: UserCountry,
  incomeTax: TaxImpactIncomeTax,
  documentType: TaxImpactDocumentType,
  amortizationPeriod?: number,
): SectionItem[] =>
  documentType === TaxImpactDocumentType.EXPENSE
    ? getItemsFromTaxImpactIncomeTaxForExpense(incomeTax, amortizationPeriod)
    : getItemsFromTaxImpactIncomeTaxForRevenue(userCountry, incomeTax);

const getItemsFromTaxImpactIncomeTaxForExpense = (
  incomeTax: TaxImpactIncomeTax,
  amortizationPeriod?: number,
): SectionItem[] => [
  ...(amortizationPeriod
    ? [
        {
          label: i18n.t(
            'tax_impact.action_sheet.section.income_tax.title.amortization',
          ),
          value: Math.floor(amortizationPeriod / 12).toString(),
        },
      ]
    : []),
  {
    label: amortizationPeriod
      ? i18n.t(
          'tax_impact.action_sheet.section.income_tax.title.deductible.asset',
        )
      : i18n.t('tax_impact.action_sheet.section.income_tax.title.deductible'),
    value: formatMoney(incomeTax.deductibleAmount),
    extra: incomeTax.deductibilityPercentage
      ? `(${(incomeTax.deductibilityPercentage * 100).toFixed(0)}%)`
      : undefined,
    isDeductibility: true,
  },
  {
    label: amortizationPeriod
      ? i18n.t(
          'tax_impact.action_sheet.section.income_tax.title.non_deductible.asset',
        )
      : i18n.t(
          'tax_impact.action_sheet.section.income_tax.title.non_deductible',
        ),
    value: formatMoney(incomeTax.nonDeductibleAmount),
  },
];

const getItemsFromTaxImpactIncomeTaxForRevenue = (
  userCountry: UserCountry,
  incomeTax: TaxImpactIncomeTax,
): SectionItem[] =>
  Object.entries(incomeTax)
    .filter(
      ([key, value]) =>
        value && value !== 0 && !['taxRate', 'amount'].includes(key),
    )
    .map(([key, value]) => {
      // if (key === 'amount') return
      return {
        title: i18n.t(
          `${userCountry}.tax_impact.revenue.income_tax.title.${key}`,
        ),
        label:
          key === 'amount'
            ? i18n.t(
                'tax_impact.action_sheet.section.income_tax.title.deductible',
              )
            : i18n.t(`${userCountry}.tax_impact.revenue.income_tax.${key}`),
        value: formatMoney(value as number),
        isDeductibility: key === 'amount',
      };
    });

export const getItemsFromTaxImpactVAT = (
  vat: TaxImpactVAT,
  userCountry: AvailableCountries,
): SectionItem[] =>
  Object.entries(vat?.statement || {})
    .filter((cell) => cell[1] && cell[1] !== 0)
    .map((cell) => ({
      title: cell[0],
      label: i18n.t(
        `taxes.vat_submission.table.cells.${userCountry}.${cell[0]}`,
      ),
      value: formatMoney(cell[1]),
      // isDeductibility: false,
    }));

export const getIsCreditNote = (
  documentType: TaxImpactDocumentType,
  documentData: IRevenue | IExpense,
) => {
  if (documentType === TaxImpactDocumentType.EXPENSE) {
    const doc = documentData as IExpense;
    return doc.isCreditNote;
  }
  if (documentType === TaxImpactDocumentType.REVENUE) {
    const doc = documentData as IRevenue;
    return doc.type === RevenueType.credit_note;
  }

  return false;
};

export const getTotal = (
  documentType: TaxImpactDocumentType,
  taxItem: TaxImpactItem,
  documentData: IExpense | IRevenue,
  index?: number,
): number => {
  if (documentType === TaxImpactDocumentType.EXPENSE) {
    return taxItem.professionalAmount || 0;
  }

  if (index != null) {
    const item = ((documentData as IRevenue).items || [])[index];
    if (!item) return 0;
    return getInvoiceItemExclVAT(item);
  }

  return getInvoiceItemsTotalExclVAT((documentData as IRevenue).items);
};
