import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import type { CategoriesPickerSharedProps } from './categoriesPicker.types';
import List from './List.component';
import Menu from './Menu.component';

export type CategoriesPickerContentProps = CategoriesPickerSharedProps & {
  searchBarPlaceholder?: string;
  onChange: any;
  value?: string;
  valueCategory?: any;
  categoriesList: any[];
  categoriesDict: Record<string, any>;
  header?: ReactNode;
  renderGridHeader?: (searchTerm: string) => ReactNode;
  hideSearchBar?: boolean;
};

const CategoriesPickerContent: FC<CategoriesPickerContentProps> = ({
  searchBarPlaceholder,
  onChange,
  value,
  valueCategory,
  categoriesList,
  categoriesDict,
  header,
  renderGridHeader,
  hideSearchBar,
  tooltips,
  helpers,
  disabledCategories,
  renderHeader,
}) => {
  // const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');

  const allCategoriesPath = categoriesList[0]?.path || []; // for now this is fine as all categories tab is always the first and will always exist

  const [activePath, setActivePath] = useState(() =>
    valueCategory ? valueCategory.path : allCategoriesPath,
  );

  return (
    <div className="flex h-full rounded-2xl overflow-hidden">
      {categoriesList.length > 1 && (
        <div className="w-[30%] min-w-[300px] max-w-[400px] overflow-y-auto p-10 flex flex-col gap-6 bg-primary-50">
          <Menu
            menuItems={categoriesList}
            activePath={activePath}
            onPathChange={(path) => {
              setSearchValue('');
              setActivePath(path);
            }}
          />
        </div>
      )}
      <div className="flex-1 p-10 overflow-y-auto">
        <List
          searchBarPlaceholder={searchBarPlaceholder}
          searchValue={searchValue}
          hideSearchBar={hideSearchBar ?? categoriesList.length <= 1}
          onSearch={(term) => {
            setSearchValue(term);
            setActivePath(allCategoriesPath);
          }}
          categoriesDict={categoriesDict}
          activePath={activePath}
          header={header}
          renderGridHeader={renderGridHeader}
          value={value}
          onChange={onChange}
          onPathChange={(path: any) => {
            setSearchValue('');
            setActivePath(path);
          }}
          tooltips={tooltips}
          helpers={helpers}
          disabledCategories={disabledCategories}
          renderHeader={renderHeader}
        />
      </div>
    </div>
  );
};

export default CategoriesPickerContent;
