import { getUserHash } from 'api/v2/users/authentication';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useIntercomStore from 'store/intercom.store';
import useGetLoggedInUserData from './Authentication/useGetLoggedInUserData';

export type IUseIntercom = {
  boot: (settings: Intercom_.IntercomSettings) => void;
  update: (settings: Intercom_.IntercomSettings | undefined) => void;
  show: () => void;
  showIntercom: () => void;
  getVisitorId: () => string;
  shutdown: () => void;
  onUnreadCountChange: (cb: (unreadCount: number) => void) => void;
};

export const getIntercom = (): IUseIntercom => {
  const boot: IUseIntercom['boot'] = (settings) => Intercom('boot', settings);
  const update: IUseIntercom['update'] = (
    settings: Intercom_.IntercomSettings | undefined,
  ) => Intercom('update', settings);
  const show = () => Intercom('show');
  const getVisitorId = () => Intercom('getVisitorId');
  const shutdown = () => Intercom('shutdown');
  const onUnreadCountChange: IUseIntercom['onUnreadCountChange'] = (cb) =>
    Intercom('onUnreadCountChange', cb);

  return {
    boot,
    update,
    show,
    showIntercom: show,
    getVisitorId,
    shutdown,
    onUnreadCountChange,
  };
};

const onUnreadCountChange = (count: number) =>
  useIntercomStore.setState({ unreadCount: count });

export const useInitializeIntercom = () => {
  const { data: user } = useGetLoggedInUserData(); // get customer or expert
  const { data: userHash } = useQuery(
    ['useInitializeIntercom', user?._id],
    getUserHash,
    {
      enabled: !!user?._id,
    },
  );
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const intercom = getIntercom();
    if (userHash) {
      intercom.boot({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_hash: userHash as unknown as string,
        hide_default_launcher: true,
        email: user?.email,
        name: `${user?.firstName || ''} ${user?.lastName || ''}`,
        user_id: user?._id,
        language_override: language,
      });
    } else {
      intercom.boot({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        hide_default_launcher: true,
        language_override: language,
      });
    }
    intercom.onUnreadCountChange(onUnreadCountChange);
  }, [
    language,
    user?._id,
    user?.email,
    user?.firstName,
    user?.lastName,
    userHash,
  ]);

  useEffect(() => {
    if ((window as any).shouldOpenIntercomInitially) {
      (window as any).shouldOpenIntercomInitially = false;
      getIntercom().show();
    }
  }, []);
};

export default getIntercom;
