import questionMark from 'assets/icons/question-mark-outline.svg';
import CountryFlag from 'components/atoms/CountryFlag';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { SupplierCountriesEnum } from 'types/expense.types';
import type { AvailableCountries } from 'types/users.types';

export const getOptionsSets: any = (customerCountry: AvailableCountries) => ({
  entry: [
    {
      label: i18n.t(`expense.countryPicker.has_vat_number.${customerCountry}`),
      options: [
        { label: i18n.t('yes'), optionsSetKey: 'entry.yes' },
        { label: i18n.t('no'), optionsSetKey: 'entry.no' },
      ],
    },
  ],
  'entry.yes': [
    {
      label: i18n.t(
        `expense.countryPicker.which_vat_number.title.${customerCountry}`,
      ),
      options: [
        {
          label: customerCountry.toUpperCase(),
          value: customerCountry,
          subLabel: i18n.t(
            `expense.countryPicker.which_vat_number.label.local.${customerCountry}`,
          ),
        },
        {
          label: SupplierCountriesEnum.eu.toUpperCase(),
          optionsSetKey: 'entry.yes.eu',
        },
        {
          label: i18n.t(
            `expense.countryPicker.which_vat_number.two_letters_code.${customerCountry}`,
          ),
          value: SupplierCountriesEnum.eu,
          subLabel: i18n.t(
            `expense.countryPicker.which_vat_number.label.intra.${customerCountry}`,
          ),
        },
        {
          label: i18n.t(
            `expense.countryPicker.which_vat_number.other.${customerCountry}`,
          ),
          value: SupplierCountriesEnum.other,
          subLabel: i18n.t(
            `expense.countryPicker.which_vat_number.label.other.${customerCountry}`,
          ),
        },
      ],
    },
  ],
  'entry.yes.eu': [
    {
      label: i18n.t(`expense.countryPicker.is_vat_charged.${customerCountry}`),
      options: [
        {
          label: i18n.t('yes'),
          value: customerCountry,
          subLabel: i18n.t(
            `expense.countryPicker.which_vat_number.label.local.${customerCountry}`,
          ),
        },
        {
          label: i18n.t('no'),
          value: SupplierCountriesEnum.eu,
          subLabel: i18n.t(
            `expense.countryPicker.which_vat_number.label.intra.${customerCountry}`,
          ),
        },
      ],
    },
  ],
  'entry.no': [
    {
      label: i18n.t(
        `expense.countryPicker.which_address.title.${customerCountry}`,
      ),
      options: [
        {
          label: i18n.t(
            `expense.countryPicker.which_address.local.${customerCountry}`,
          ),
          value: customerCountry,
          subLabel: i18n.t(
            `expense.countryPicker.which_vat_number.label.local.${customerCountry}`,
          ),
        },
        {
          label: i18n.t(
            `expense.countryPicker.which_address.intra.${customerCountry}`,
          ),
          value: SupplierCountriesEnum.eu,
          subLabel: i18n.t(
            `expense.countryPicker.which_vat_number.label.intra.${customerCountry}`,
          ),
        },
        {
          label: i18n.t(
            `expense.countryPicker.which_address.other.${customerCountry}`,
          ),
          value: SupplierCountriesEnum.other,
          subLabel: i18n.t(
            `expense.countryPicker.which_vat_number.label.other.${customerCountry}`,
          ),
        },
      ],
    },
  ],
});

export const getSupplierCountries = (customerCountry: AvailableCountries) => {
  return [
    {
      label: i18n.t(`expense.supplier.country_option_label.${customerCountry}`),
      value: customerCountry,
      icon: (
        <CountryFlag country={customerCountry} width="21px" height="21px" />
      ),
    },
    {
      label: i18n.t(
        `expense.supplier.country_option_label.${SupplierCountriesEnum.eu}`,
      ),
      value: SupplierCountriesEnum.eu,
      icon: <CountryFlag country="intraeu" width="21px" height="21px" />,
    },
    {
      label: i18n.t(
        `expense.supplier.country_option_label.${SupplierCountriesEnum.other}`,
      ),
      value: SupplierCountriesEnum.other,
      icon: <CountryFlag country="extraeu" width="21px" height="21px" />,
    },
    {
      label: i18n.t(`expense.countryPicker.${customerCountry}.unknown`),
      value: '_', // nothing, not used just for typescript
      optionsSetKey: 'entry',
      icon: <img alt="" src={questionMark} width="21px" height="21px" />,
    },
  ];
};

export const SupplierCountriesListFooter = () => {
  const { t } = useTranslation();
  const country = useCustomerCountry();
  return (
    <div className="p-4 bg-primary-25 border-t flex flex-col gap-3">
      <div>
        <div className="font-assistant font-semibold text-xs text-primary mb-1">
          {t(`expense.supplier.country_helper.title_${2}`)}
        </div>
        <div className="font-assistant text-xs text-primary-700 whitespace-pre-line">
          {t(`expense.supplier.country_helper.description_${2}`, {
            userDemonym: t(`nationality.${country}`),
            userCountry: t(`country.${country}`),
          })}
        </div>
      </div>
      {/* {[1, 2].map((textIdx) => (
        <div key={textIdx}>
          <div className="font-assistant font-semibold text-xs text-primary mb-1">
            {t(`expense.supplier.country_helper.title_${textIdx}`)}
          </div>
          <div className="font-assistant text-xs text-primary-700 whitespace-pre-line">
            {t(`expense.supplier.country_helper.description_${textIdx}`, {
              userDemonym: t(`nationality.${country}`),
              userCountry: t(`country.${country}`),
            })}
          </div>
        </div>
      ))} */}
    </div>
  );
};
