import { getRevenuesCategoryById } from 'api/v2/revenues';
import { useQuery } from 'react-query';
import { RevenuesCacheKeys } from 'types/cacheKeys.types';
import { AvailableCountries } from 'types/users.types';
import { REVENUE_CAR_PRIVATE_USE } from 'utils/categories';

const useGermanPrivateUsageCategory = () => {
  return useQuery([RevenuesCacheKeys.GermanCarPrivateUseCategory], () => {
    return getRevenuesCategoryById(
      REVENUE_CAR_PRIVATE_USE,
      AvailableCountries.germany,
      { ignoreToast: true },
    );
  });
};

export default useGermanPrivateUsageCategory;
