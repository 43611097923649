import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppName } from 'utils/constants';

type PageTitleProps = {
  slug?: string;
  text?: string;
  children?: ReactNode;
  params?: Record<string, any>;
};

const PageTitle: FC<PageTitleProps> = ({ slug, text, children, params }) => {
  const { t } = useTranslation();

  const title = `${AppName}${
    slug ? ` - ${t(slug, params)}` : text ? ` - ${text}` : null
  }`;

  useEffect(() => {
    document.title = title;
    return () => {
      document.title = AppName;
    };
  }, [title]);

  return <>{children}</>;
};

export default PageTitle;
