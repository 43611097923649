import classNames from 'classnames';
import getIntercom from 'hooks/intercom.hook';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isAlertDismissed,
  markAlertAsDismissed,
} from 'utils/AlertsStoreManager';
import { getTracingEvents } from 'utils/amplitude';
import { WEBAPP_GLOBAL_WARNINGBOX } from 'utils/amplitude/events/global.amplitude';
import { XIconOutline } from 'utils/icons';
import { Button } from '../Button';
import type { AlertProps } from './Alert.types';
import { customAlertTypeMap } from './Alert.types';

const events = getTracingEvents({
  onWarningMount: {
    name: WEBAPP_GLOBAL_WARNINGBOX,
    data: (name: string) => ({ name }),
  },
});

const Alert: FC<AlertProps> = ({
  type,
  title,
  dismissable,
  description,
  errorMessages,
  renderButtons,
  className,
  contentClassName,
  showIntercomBtn,
  size = 'sm',
  hideIcon,
  onDismiss,
  name,
  style,
  id,
  dismissId,
  icon: _icon,
  iconClassName,
}) => {
  const {
    icon,
    backgroundClass,
    iconClass,
    titleClass,
    descriptionClass,
    supportBtn,
  } = customAlertTypeMap[type] || customAlertTypeMap.default;

  const CustomIconTag = _icon || icon;

  const fontSize = size === 'sm' ? 'text-sm' : 'text-base';
  const iconSize = size === 'sm' ? 'w-5 h-5' : 'w-6 h-6';
  const padding = size === 'sm' ? 'p-3' : 'p-4';
  const gap = size === 'sm' ? 'gap-2' : 'gap-4';

  const [dismissed, setDismissed] = useState(() => {
    if (dismissId) {
      return isAlertDismissed(dismissId);
    }
    return false;
  });
  const { t } = useTranslation();
  const { showIntercom } = getIntercom();

  useEffect(() => {
    if (name) events.onWarningMount(name);
  }, [name]);

  if (dismissed) return null;

  const dismiss = () => {
    if (dismissId) {
      markAlertAsDismissed(dismissId);
    }
    setDismissed(true);
    onDismiss?.();
  };

  const extraBtns = renderButtons?.(supportBtn);

  return (
    <div
      className={classNames(
        className,
        backgroundClass,
        padding,
        `rounded-md mb-2`,
      )}
      style={style}
      id={id}
    >
      <div className={classNames('flex w-full', gap)}>
        {!hideIcon && (
          <div className={`flex-shrink-0 leading-tight ${fontSize}`}>
            <CustomIconTag
              className={`${iconSize} inline ${iconClass} ${iconClassName}`}
              aria-hidden="true"
            />
          </div>
        )}
        <div className="flex-1">
          <div className="flex flex-col">
            {title && (
              <div className={`${fontSize} font-semibold ${titleClass}`}>
                {title}
              </div>
            )}

            {(!!description || !!errorMessages?.length) && (
              <div className={`${fontSize} ${descriptionClass}`}>
                {description && (
                  <p className={classNames({ 'pt-2': !!title })}>
                    {description}
                  </p>
                )}
                {errorMessages?.length === 1 && (
                  <p className="pt-2">{errorMessages}</p>
                )}
                {errorMessages && errorMessages.length > 1 && (
                  <ul role="list" className="pt-2 list-disc pl-5 space-y-1">
                    {errorMessages.map((errorMessage, index) => (
                      <li key={index}>{errorMessage}</li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            {showIntercomBtn && (
              <button
                type="button"
                onClick={showIntercom}
                className={classNames(
                  supportBtn,
                  'ml-auto mt-3 font-semibold text-xs',
                )}
              >
                {t('contact_support_short')}
              </button>
            )}
            {extraBtns && <div>{extraBtns}</div>}
          </div>
        </div>
        {dismissable ? (
          <div>
            <Button onClick={dismiss} structure="text">
              <XIconOutline className={classNames(iconSize, titleClass)} />
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Alert;
