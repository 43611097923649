import { getConnectors } from 'api/v1/transactions';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import { BankTransactionsCacheKeys } from 'types/cacheKeys.types';

const useGetConnectors = (options?: UseQueryOptions) => {
  return useQuery({
    queryKey: BankTransactionsCacheKeys.Connectors,
    queryFn: getConnectors,
    ...options,
  });
};

export default useGetConnectors;
