import classNames from 'classnames';
import CustomLoader from 'components/molecules/CustomLoader.component';
import AppBannersProvider, {
  AppBannersContext,
} from 'contexts/AppBannersProvider';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import {
  useGetWorldThemeFromPath,
  worldThemeContext,
} from 'hooks/shared/useWorldTheme';
import { useGrowthABExperimentsState } from 'hooks/useAssignGrowthABGroups';
import useExpenseFromEmail from 'hooks/useExpenseFromEmail';
import UseGratifications from 'hooks/useGratifications';
import useRevenuesFromEmail from 'hooks/useRevenuesFromEmail';
import type { FC, ReactNode } from 'react';
import { Suspense, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import type { GrowthExperimentsWithABGroupsType } from 'store/ABExperiments.store';
import type { NavbarNavigationListType } from 'types/navbar.types';
import type { RoutesListType, RouteType } from 'types/routing.types';
import type { IUser } from 'types/users.types';
import { isExpert } from 'utils/constants';

import UsePeppolInactiveEvent from 'hooks/UsePeppolInactiveEvent';
import UseRevenueStatusEvent from 'hooks/useRevenueStatusEvent';
import ResizeObserver from 'resizeObserver';
import DowngradeBanner from '../DowngradeBanner/DowngradeBanner';
import Sidebar from '../Sidebar';
import SmallBusinessPlanBanner from '../SmallBusinessPlanBanner';
import TopPanel from '../TopPanel';
import TrialBanner from '../TrialBanner';
import UpgradeYearlyBanner from '../UpgradeYearlyBanner/UpgradeYearlyBanner.component';
import layoutContext from './layoutContext';

type LayoutProps = {
  children: ReactNode;
  navigationListGenerator: (
    customerId: string,
    experiments?: GrowthExperimentsWithABGroupsType,
    user?: IUser,
  ) => NavbarNavigationListType;
  showBgImage?: boolean;
  className?: string;
};

export const assignLayoutToRoutes = (
  routes: RoutesListType,
  navigationGenerator: (
    customerId: string,
    experiments?: GrowthExperimentsWithABGroupsType,
  ) => NavbarNavigationListType,
): RoutesListType =>
  routes.map((route: RouteType) => ({
    ...route,
    Component: route.withLayout ? (
      <Layout
        navigationListGenerator={navigationGenerator}
        showBgImage={route?.showBgImage}
        className={route?.className}
      >
        {route.Component}
      </Layout>
    ) : (
      <Suspense fallback={<CustomLoader className="h-screen" />}>
        {route.Component}
      </Suspense>
    ),
  }));

const Banners: FC = () => {
  const { data: user } = useGetCurrentCustomerData();
  const {
    displaySMALLBanner,
    displayTRIALBanner,
    displayDowngradeBanner,
    displayUpgradeYearlyBanner,
  } = useContext(AppBannersContext);
  const { country } = user || {};
  if (displayDowngradeBanner) return <DowngradeBanner />;
  if (displayUpgradeYearlyBanner) return <UpgradeYearlyBanner />;
  if (displaySMALLBanner) return <SmallBusinessPlanBanner country={country} />;
  if (displayTRIALBanner) return <TrialBanner />;

  return null;
};

const Content: FC<Omit<LayoutProps, 'navigationListGenerator'>> = ({
  children,
  showBgImage = true,
  className = 'px-16',
}) => {
  const {
    displaySMALLBanner,
    displayTRIALBanner,
    displayDowngradeBanner,
    displayUpgradeYearlyBanner,
  } = useContext(AppBannersContext);
  const { layoutBGColor, sidebarArea, shouldShowBgImage } =
    useContext(layoutContext);

  return (
    <div
      className={`${layoutBGColor}`}
      style={{ marginLeft: sidebarArea - 16 }}
    >
      <div className="mx-auto max-w-[1750px]">
        <div
          id="LAYOUT_CONTENT"
          className={classNames(
            'py-14 bg-no-repeat bg-[60%_-120px] bg-[length:273px_auto]',
            className,
            layoutBGColor,
            {
              'pt-32':
                displayDowngradeBanner ||
                isExpert ||
                displayUpgradeYearlyBanner ||
                displaySMALLBanner ||
                displayTRIALBanner,
              "bg-[url('assets/images/Background_illustration.png')]":
                shouldShowBgImage && showBgImage,
            },
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

const Layout: FC<LayoutProps> = ({
  children,
  navigationListGenerator,
  showBgImage = true,
  className = 'px-16',
}) => {
  const [shouldShowBgImage, setShouldShowBgImage] = useState(showBgImage);
  const [sidebarArea, setSidebarArea] = useState(176);
  const [layoutBGColor, setLayoutBGColor] = useState('transparent');
  const { id: customerId } = useParams();
  const { experiments } = useGrowthABExperimentsState();

  const { data: user } = useGetCurrentCustomerData();
  const navigationList: NavbarNavigationListType = navigationListGenerator(
    customerId as string,
    experiments,
    user,
  );
  const theme = useGetWorldThemeFromPath();

  useExpenseFromEmail();
  useRevenuesFromEmail();

  const sidebarRef = useRef();

  useEffect(() => {
    if (!sidebarRef.current) return;
    const resizeObserver = new ResizeObserver((d) => {
      const { contentRect } = d[0] || {};

      setSidebarArea(contentRect?.width + contentRect?.left + 16);
    });

    resizeObserver.observe(sidebarRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <worldThemeContext.Provider value={theme}>
      <layoutContext.Provider
        value={{
          shouldShowBgImage,
          setShouldShowBgImage,
          setLayoutBGColor,
          layoutBGColor,
          sidebarArea,
        }}
      >
        <UseGratifications />
        <UseRevenueStatusEvent />
        <UsePeppolInactiveEvent />
        <main className="min-h-full main-bg">
          <Sidebar navigationList={navigationList} sidebarRef={sidebarRef} />

          <Suspense fallback={<CustomLoader className="h-screen" />}>
            <AppBannersProvider>
              {isExpert ? <TopPanel /> : null}

              <Banners />

              <Content className={className} showBgImage={showBgImage}>
                {children}
              </Content>
            </AppBannersProvider>
          </Suspense>
        </main>
      </layoutContext.Provider>
    </worldThemeContext.Provider>
  );
};

export default Layout;
