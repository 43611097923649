import { updateTax } from 'api/v1/taxes';
import { CurrentTaxContext } from 'components/pages/Taxes/Tax/Tax';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useInvalidateResources from 'hooks/useInvalidateResources';
import { useContext } from 'react';
import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';
import type { Period } from 'types/global.types';
import { World } from 'types/shared/worlds';
import type { ITax } from 'types/taxes.types';
import { TaxStatusEnum, TaxTypeEnum } from 'types/taxes.types';
import { logAmplitude } from 'utils/amplitude';
import formatPeriodLabel from 'utils/formatPeriodLabel';
import useGetSelectedYearTaxes from './useGetSelectedYearTaxes';

type UpdateResult = UseMutationResult<ITax, unknown, ITax, unknown>;

const useUpdateTaxMutation = (
  afterOnSuccess?: () => void,
  isUpdatingStatus?: boolean,
): UpdateResult & { isUpdateLoading: boolean } => {
  const customerCountry = useCustomerCountry();
  const { refetch, data: tax } = useContext(CurrentTaxContext);
  const invalidate = useInvalidateResources();
  const {
    refetch: refetchTaxes,
    isLoading,
    isFetching,
    isRefetching,
  } = useGetSelectedYearTaxes(tax?.period?.year, afterOnSuccess);

  const mutationResult = useMutation(updateTax, {
    onSuccess: async (data) => {
      invalidate(World.TAXES);
      await refetch?.();
      if (isUpdatingStatus) {
        const eventName = (() => {
          if (data.type === TaxTypeEnum.incomeTaxSubmission) {
            return `WEBAPP_INCOMETAXSUBMISSION${customerCountry}_${
              data.status === TaxStatusEnum.done
                ? 'MarkedAsDone'
                : 'MarkedAsNotDone'
            }`;
          }
          return `WEBAPP_${data.type?.replace?.(/_/g, '')?.toUpperCase?.()}_${
            data.status === TaxStatusEnum.done
              ? 'MARKEDASDONE'
              : 'MARKEDASNOTDONE'
          }`;
        })();
        logAmplitude(eventName, {
          period: formatPeriodLabel(data.period as Period) as string,
        });
      }

      return refetchTaxes();
    },
  });

  return {
    ...mutationResult,
    isLoading:
      mutationResult.isLoading || isLoading || isFetching || isRefetching,
    isUpdateLoading: mutationResult.isLoading,
  } as unknown as UpdateResult & { isUpdateLoading: boolean };
};

export default useUpdateTaxMutation;
