import {
  DropzoneOnBoardingContent,
  FilesListFooter,
  FilesListHeader,
} from 'components/pages/Expenses/fileUploader.content';
import { createExpenseDocument } from 'components/pages/Expenses/helpers';
import useUpdateSidebarDocCounts from 'hooks/useUpdateSidebarDocCounts';
import { type FC } from 'react';
import { SidebarDocCountsKeysEnum } from 'store/sidebarState';
import type { HomeExpenseUploaderProps } from 'types/expense.types';
import { getTracingEvents } from 'utils/amplitude';
import { WEBAPP_EXPENSESLIST_DROPZONEUPLOADING } from 'utils/amplitude/events/expenses.amplitude';
import { GlobalExpensesUploader } from '../FileUploader/FileUploader.component';

const tracingEvents = getTracingEvents({
  onUpload: { name: WEBAPP_EXPENSESLIST_DROPZONEUPLOADING },
});

const HomeExpenseUploader: FC<HomeExpenseUploaderProps> = ({
  onUpload,
  onClickReviewLater,
  onClickReviewNow,
  renderUploadedFileItem,
  hideDropzoneWhileUploading,
  renderFooter,
  renderHeader,
}) => {
  const updateSidebarCounts = useUpdateSidebarDocCounts();

  return (
    <GlobalExpensesUploader
      keepUploadingAfterDestroy
      color="taxes"
      className="flex-col lg:flex-row h-screen"
      onUpload={(...args) => {
        tracingEvents.onUpload();
        return createExpenseDocument(...args).then((createdeExpense) => {
          onUpload?.(createdeExpense);

          updateSidebarCounts(SidebarDocCountsKeysEnum.NewExpensesCount);

          return createdeExpense;
        });
      }}
      onAfterDropAccepted={(processFiles) => processFiles()}
      filesListContainerClassName="!px-0"
      dropzoneProps={{
        className: 'flex-none lg:flex-1',
        render: (dropzoneMeta, fileUploaderMeta) => (
          <DropzoneOnBoardingContent
            dropzoneMeta={dropzoneMeta}
            fileUploaderMeta={fileUploaderMeta}
            imgProps={{ width: '200px', height: '200px', className: 'mt-10' }}
            titleClassNames="!text-base lg:!text-lg"
            hideFakeReceipt
          />
        ),
      }}
      filesListProps={{
        renderFooter:
          renderFooter ||
          ((fileUploaderMeta) => (
            <FilesListFooter
              fileUploaderMeta={fileUploaderMeta}
              onReviewLater={onClickReviewLater}
              onReviewNow={onClickReviewNow}
            />
          )),
        renderHeader:
          renderHeader ||
          ((fileUploaderMeta) => (
            <FilesListHeader fileUploaderMeta={fileUploaderMeta} />
          )),
      }}
      renderUploadedFileItem={renderUploadedFileItem}
      hideDropzoneWhileUploading={hideDropzoneWhileUploading}
    />
  );
};

export default HomeExpenseUploader;
