const revenues = {
  header: {
    helper: {
      title: 'de.revenue_list.helper.title',
      description: [
        'de.revenue_list.helper.text_1',
        'de.revenue_list.helper.text_2',
        'de.revenue_list.helper.text_3',
        'de.revenue_list.helper.text_4',
        'de.revenue_list.helper.text_5',
      ],
      ctaDesc: 'question_on_topic',
      ctaText: 'talk_to_an_expert',
      onClick: (): void => {}, // To be replaced with Intercom call
    },
  },
};

export default revenues;
