import type { SVGProps } from 'react';

const AmortizationTableIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M3.95898 5.625C3.95898 4.70452 4.70518 3.95833 5.62565 3.95833H14.3757C15.2961 3.95833 16.0423 4.70452 16.0423 5.625V14.375C16.0423 15.2955 15.2961 16.0417 14.3757 16.0417H5.62565C4.70518 16.0417 3.95898 15.2955 3.95898 14.375V5.625Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16602 6.875H15.8327"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16602 11.4583H15.8327"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.70898 6.875L7.70898 16.0417"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.291 6.875L12.291 16.0417"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AmortizationTableIcon;
