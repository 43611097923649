import { Button } from 'components/atoms/Button';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Props } from 'react-select';
import { getOptionsDict, getSelectedOptionsFromDict } from '../helpers';
import type { ObjectWithValue, SelectConfig, value } from '../select.types';

const withDefault = (SelectComponent: FC<Props>) =>
  function WithDefault(
    props: SelectConfig & { isError?: boolean; retry?: () => void },
  ) {
    const {
      value,
      onInputChange,
      options,
      fixedOptions,
      mapOptions,
      isError,
      retry,
      menuIsOpen,
      createdOption,
      isLoading,
      ...rest
    } = props;

    const { t } = useTranslation();

    const { isMulti } = props;

    const { extraOptions, normalOptions } = useMemo(() => {
      const extraOptions = fixedOptions ? [].concat(fixedOptions) : [];
      const normalOptions = options || [];
      return { extraOptions, normalOptions };
    }, [fixedOptions, options]);

    const optionsDict = useMemo(
      () =>
        getOptionsDict(normalOptions.concat(extraOptions) as ObjectWithValue[]),
      [extraOptions, normalOptions],
    );

    const selected = useMemo(
      () =>
        getSelectedOptionsFromDict({
          value: value as value,
          optionsDict,
          isMulti,
        }),
      [value, optionsDict],
    );

    const maybeMappedOptions = useMemo(() => {
      if (mapOptions) {
        return mapOptions({
          fixedOptions: extraOptions,
          options: normalOptions,
        });
      }
      return normalOptions.concat(extraOptions);
    }, [extraOptions, normalOptions]);

    // const optionsWithCreatedOption = useMemo(() => {
    //   if (!createdOption || isLoading) return maybeMappedOptions;
    //   const hasSimilarOption = maybeMappedOptions.some(
    //     (option: any) => option.value === createdOption.value,
    //   );
    //   if (hasSimilarOption) return maybeMappedOptions;
    //   return [createdOption].concat(maybeMappedOptions);
    // }, [createdOption, maybeMappedOptions, isLoading]);

    return (
      <>
        <SelectComponent
          isLoading={isLoading}
          options={maybeMappedOptions}
          value={selected}
          menuIsOpen={isError ? false : menuIsOpen}
          onInputChange={
            onInputChange
              ? (newValue, actionMeta) => {
                  // if (isError) return; // don't reset user search input
                  return onInputChange(newValue, actionMeta, selected);
                }
              : undefined
          }
          {...rest}
        />
        {isError && (
          <div className="flex flex-wrap items-center gap-2">
            <span className="text-sm text-primary-400">
              {t('backend.error.something_went_wrong')}.
            </span>
            <Button structure="text" color="primary" onClick={() => retry?.()}>
              <span className="text-sm">{t('retry')}</span>
            </Button>
          </div>
        )}
      </>
    );
  };

export default withDefault;
