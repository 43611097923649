import { getClientById } from 'api/v2/clients';
import { calculateItems, getRevenueById } from 'api/v2/revenues';
import { Loader } from 'components/atoms/Loader';
import SimpleError from 'components/molecules/SimpleError.component';
import dayjs from 'dayjs';
import useQueryParams from 'hooks/shared/useQueryParams';
import useCustomNavigate from 'hooks/useCustomeNavigate';
import useCutRouterState from 'hooks/useCutRouterState';
import { lazy, useEffect, useId, useMemo, type FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { RevenuesCacheKeys } from 'types/cacheKeys.types';
import { CurrenciesEnum } from 'types/global.types';
import { Invoice, InvoiceTypeEnum } from 'types/invoice.types';
import { isDocumentLockedByTaxesOrAccountant } from 'utils/helpers';
import { cleanInvoiceItems } from '../SharedControls/ItemsForm/helpers';
import '../styles.scss';
const Form = lazy(() => import('./Form'));

const PrepareInvoice: FC<{ data: Partial<Invoice> }> = ({ data: invoice }) => {
  const clientQuery = useQuery(
    [RevenuesCacheKeys.ClientById, useId()],
    () => {
      const clientId = invoice.client?._id;
      if (!clientId) {
        return {
          client: undefined,
          clientId: undefined,
        };
      }
      return getClientById(clientId, { ignoreToast: true })
        .then(() => ({
          client: invoice!.client,
          clientId: clientId,
        }))
        .catch((e) => {
          if (e.response?.status === 404) {
            return {
              client: undefined,
              clientId: undefined,
            };
          }

          throw e;
        });
    },
    {
      cacheTime: 0,
    },
  );

  const calculationsQueryEnabled = !!clientQuery.data;
  const calculationsQuery = useQuery(
    [RevenuesCacheKeys.ItemsCalculations, useId(), calculationsQueryEnabled],
    () => {
      const client = clientQuery.data!;
      return calculateItems({
        items: cleanInvoiceItems(invoice.items || []),
        type: invoice.type || InvoiceTypeEnum.INVOICE,
        clientId: client.clientId,
        invoiceDate: invoice.invoiceDate || dayjs().format('YYYY-MM-DD'),
        options: {
          currency: invoice.currency || CurrenciesEnum.EUR,
        },
      });
    },
    { cacheTime: 0, enabled: calculationsQueryEnabled },
  );

  const document = useMemo(() => {
    return {
      ...invoice,
      ...clientQuery.data,
      itemsCalculations: calculationsQuery.data,
    };
  }, [invoice, clientQuery.data, calculationsQuery.data]);

  const isLoading = clientQuery.isLoading || calculationsQuery.isLoading;
  const isError = clientQuery.isError || calculationsQuery.isError;

  if (isLoading) return <Loader />;

  if (isError)
    return (
      <SimpleError
        onRetry={() => {
          if (clientQuery.isError) clientQuery.refetch();
          if (calculationsQuery.isError) calculationsQuery.refetch();
        }}
      />
    );

  return <Form defaultValues={document as any} />;
};

export const EditInvoice = () => {
  const navigate = useCustomNavigate();
  const revenueId = useParams().revenueId as string;

  const revenueQuery = useQuery(
    [RevenuesCacheKeys.RevenueById, revenueId],
    () => getRevenueById(revenueId),
    { cacheTime: 0 },
  );

  const isLocked =
    !!revenueQuery.data &&
    isDocumentLockedByTaxesOrAccountant(revenueQuery.data);

  useEffect(() => {
    if (isLocked) {
      navigate('/revenues?id=' + revenueId, { replace: true });
    }
  }, [isLocked, revenueId]);

  if (revenueQuery.isLoading || isLocked) return <Loader />;

  if (revenueQuery.isError) {
    return (
      <SimpleError
        onRetry={() => {
          revenueQuery.refetch();
        }}
      />
    );
  }

  if (!revenueQuery.data) return null;
  return <PrepareInvoice data={revenueQuery.data} />;
};

export const CreateInvoice: FC = () => {
  const isCreditNote = useQueryParams().type === InvoiceTypeEnum.CREDIT_NOTE;

  const guessedDocument = useCutRouterState(
    'guessedDocument',
  ) as Partial<Invoice> | null;

  if (!guessedDocument) {
    return (
      <Form
        defaultValues={{
          type: isCreditNote
            ? InvoiceTypeEnum.CREDIT_NOTE
            : InvoiceTypeEnum.INVOICE,
        }}
      />
    );
  }

  return <PrepareInvoice data={{ ...guessedDocument, _id: undefined }} />;
};
