import { ArrowRightIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import getIntercom from 'hooks/intercom.hook';
import useWorldTheme from 'hooks/shared/useWorldTheme';
import type { FC, ReactNode } from 'react';
import { Children, cloneElement, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';
import type { IColors } from 'theme/colors';
import { Modal } from '../../atoms/Modal';
import type { HelperProps } from './Helper.types';

const colors = {
  primary: 'text-primary-800',
  expenses: 'text-expenses',
  revenues: 'text-revenues',
  bank: 'text-bank',
  taxes: 'text-taxes',
};

const Title = ({
  children,
  color,
  className,
}: {
  children: any;
  color?: IColors;
  className?: string;
}) => {
  const _color = useWorldTheme();
  return (
    <div
      className={classNames(
        `font-bold text-xl font-avenir ${colors[color || _color]}`,
        className,
      )}
    >
      {children}
    </div>
  );
};

const SubTitle = ({
  children,
  color,
  className,
}: {
  children: ReactNode;
  color?: IColors;
  className?: string;
}) => {
  const _color = useWorldTheme();
  return (
    <div
      className={classNames(
        `font-bold text-lg font-avenir ${colors[color || _color]}`,
        className,
      )}
    >
      {children}
    </div>
  );
};

const Paragraph = ({
  children,
  className,
}: {
  children: any;
  className?: string;
}) => (
  <div
    className={classNames(
      'py-2 text-md font-assistant text-primary',
      className,
    )}
  >
    {children}
  </div>
);

const Contact = ({
  color,
  onTalkToExpert,
  footerQuestion,
}: {
  color?: IColors;
  footerQuestion?: string;
  onTalkToExpert: () => void;
}) => {
  const { t } = useTranslation();
  const _color = useWorldTheme();

  return (
    <>
      <div className={`font-semibold font-avenir text-primary-800`}>
        {footerQuestion || t('question_on_topic')}
      </div>
      <Button
        onClick={onTalkToExpert}
        structure="text"
        color={color || _color}
        icon={ArrowRightIcon}
        iconPlacement="right"
      >
        {t('talk_to_an_expert')}
      </Button>
    </>
  );
};

const Inline: FC<
  Pick<HelperProps, 'children' | 'color' | 'footerQuestion'> & {
    onAfterClickTalkToExpert?: () => void;
  }
> = ({ color, footerQuestion, children, onAfterClickTalkToExpert }) => {
  const { showIntercom } = getIntercom();
  const worldColor = useWorldTheme();
  color = color || worldColor;
  return (
    <div className="p-6">
      {Children.map(children, (child) =>
        isValidElement(child)
          ? cloneElement(child, { color: color } as any)
          : child,
      )}
      <div className="mt-3">
        <Contact
          color={color}
          footerQuestion={footerQuestion}
          onTalkToExpert={() => {
            showIntercom();
            onAfterClickTalkToExpert?.();
          }}
        />
      </div>
    </div>
  );
};

const HelperComponent: FC<HelperProps> = ({
  children,
  color,
  trigger,
  name,
  footerQuestion,
  iconClassName = '',
  className,
  tracingEvent,
}) => {
  const worldColor = useWorldTheme();
  color = color || worldColor;
  return (
    <Modal
      name={`tutorial helper: ${name || ''}`}
      trigger={(api) =>
        trigger ? (
          trigger(() => api.openModal())
        ) : (
          <Button
            asDefault
            className={classNames('flex', className)}
            tracingEvent={tracingEvent}
            onClick={(e) => {
              e.stopPropagation();
              api.openModal();
            }}
          >
            <QuestionMarkCircleIcon
              className={classNames(
                'h-5 w-5 inline-block cursor-pointer text-primary-300 hover:text-primary-400 focus:text-primary-400',
                iconClassName,
              )}
            />
          </Button>
        )
      }
      color={color}
      render={(api) => (
        <Inline
          color={color}
          footerQuestion={footerQuestion}
          onAfterClickTalkToExpert={api.closeModal}
        >
          {children}
        </Inline>
      )}
    />
  );
};

export default Object.assign(HelperComponent, {
  Title,
  SubTitle,
  Paragraph,
  Contact,
  Inline,
});
