import axiosInstance from 'api/axiosInstance';
import { getExpenses } from 'api/v2/expenses';
import { stringify } from 'query-string';
import type {
  IPaginationRequest,
  IPaginationResponse,
} from 'types/pagination.types';
import { AvailableCountries } from 'types/users.types';
import type { BEVehicle, VehiclesOption } from 'types/vehicle.types';
import { VEHICLE_AMORTIZATION_END_YEAR } from 'utils/constants';
import calcEndOfAmortizationPeriod from 'utils/expenses/calcEndOfAmortizationPeriod';

const base = '/v1/vehicles';

type VehiclesResponse<Vehicle = BEVehicle> = {
  data: Vehicle[];
} & IPaginationResponse;

export const getVehicles = <Vehicle = BEVehicle>(
  params: IPaginationRequest,
): Promise<VehiclesResponse<Vehicle>> => {
  return axiosInstance.get(`${base}?${stringify(params)}`);
};

export const getVehicle = <Vehicle = BEVehicle>(
  id: string,
): Promise<Vehicle> => {
  return axiosInstance.get(`${base}/${id}`);
};

// perPage: 1000, old code did this, we can paginate it if its important
export const getVehiclesOptions = <Vehicle = BEVehicle>(): Promise<
  VehiclesOption<Vehicle>[]
> =>
  getVehicles<Vehicle & { name: string; _id: string }>({
    perPage: 1000,
    page: 1,
  }).then(({ data }) => {
    return data.map((vehicle) => {
      return {
        label: vehicle.name,
        value: vehicle!._id,
        vehicle,
      };
    });
  });

export const createVehicle = <Vehicle = Partial<BEVehicle>>(
  vehicle: Vehicle,
): Promise<Vehicle> => {
  return axiosInstance.post(base, vehicle);
};

export const updateVehicle = <Vehicle = BEVehicle>(
  vehicleId: string,
  vehicle: Vehicle,
): Promise<Vehicle> => {
  return axiosInstance.put(`${base}/${vehicleId}`, vehicle);
};

export const canAssignVehicleToOtherExpenses = (
  vehicleCategoryIds: string[],
  expenseId?: string,
): Promise<boolean> => {
  return getExpenses({
    page: 1,
    perPage: 9999,
    fields:
      'expenseDate,items.categoryId,items.vehicleId,items.amortizationPeriod',
  }).then(({ data }) =>
    data.some(
      (expense) =>
        expense._id !== expenseId &&
        expense.items.some(
          (item) =>
            vehicleCategoryIds.includes(item.categoryId) &&
            calcEndOfAmortizationPeriod(
              expense.expenseDate,
              item.amortizationPeriod,
            ) > VEHICLE_AMORTIZATION_END_YEAR,
        ),
    ),
  );
};

export const assignVehicleToExistingExpenses = <Vehicle = BEVehicle>(
  vehicleId: string,
): Promise<Vehicle> => {
  return axiosInstance.put(`${base}/${vehicleId}/assign`);
};

export const deleteVehicle = (vehicleId: string): Promise<any> =>
  axiosInstance.delete(`${base}/${vehicleId}`);

export const getVehiclesWithoutPurchaseDate = <Vehicle = BEVehicle>(
  country: AvailableCountries,
): Promise<VehiclesResponse> => {
  if (country !== AvailableCountries.belgium)
    return Promise.resolve({
      data: [],
      paging: {
        page: 1,
        pageCount: 0,
        pagesCount: 0,
        perPage: 1,
        totalCount: 0,
      },
    });
  return axiosInstance.get(`${base}/missing-order-date`);
};

type Payload = {
  ops: {
    resourceId: string;
    body: {
      orderDate: number;
    };
  }[];
};

export const bulkPatchVehicles = (payload: Payload) =>
  axiosInstance.patch('/v1/vehicles/assign-order-dates', payload);
