import { CalendarIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { MenuPropsTriggerAPI } from 'components/atoms/Menu';
import { Menu } from 'components/atoms/Menu';
import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailableCountries } from 'types/users.types';
import { ChevronLeftIconOutline, ChevronRightIconOutline } from 'utils/icons';
import Calendar from './Calendar';
import type { BaseDateRangeProps } from './useDateRange';
import useDateRange from './useDateRange';

export const DatePickerCalendar: FC<{
  previousMonth: () => void;
  month: number;
  nextMonth: () => void;
  previousYear: () => void;
  year: number;
  nextYear: () => void;
  range: [Date, Date] | null;
  setRange: (range: [Date, Date] | null) => void;
  onDayClick?: (day: number) => void;
  rangeClassNames?: string;
  preselectedDate?: Date[];
}> = ({
  previousMonth,
  month,
  nextMonth,
  previousYear,
  year,
  nextYear,
  range,
  setRange,
  rangeClassNames,
  onDayClick,
  preselectedDate,
}) => (
  <div>
    <div className="flex flex-row justify-around text-primary-700 font-semibold py-3">
      <div className="flex flex-row items-center">
        <ChevronLeftIconOutline
          className="w-4 h-4 mx-4 cursor-pointer"
          onClick={previousMonth}
        />
        <div>{dayjs(new Date(1970, month - 1, 15)).format('MMM')}</div>
        <ChevronRightIconOutline
          className="w-4 h-4 mx-4 cursor-pointer"
          onClick={nextMonth}
        />
      </div>
      <div className="flex flex-row items-center">
        <ChevronLeftIconOutline
          className="w-4 h-4 mx-4 cursor-pointer"
          onClick={previousYear}
        />
        <div>{year}</div>
        <ChevronRightIconOutline
          className="w-4 h-4 mx-4 cursor-pointer"
          onClick={nextYear}
        />
      </div>
    </div>

    <Calendar
      month={month}
      year={year}
      range={range}
      setRange={setRange}
      rangeClassNames={rangeClassNames}
      onDayClick={onDayClick}
      preselectedDate={preselectedDate}
    />
  </div>
);

export const BasicDateRangePicker: FC<
  BaseDateRangeProps & {
    renderTrigger: (
      triggerAPI: MenuPropsTriggerAPI,
      dateRangeAPI: ReturnType<typeof useDateRange>,
    ) => ReactNode;
  }
> = ({ onChange, value, getInitialValue, renderTrigger }) => {
  const { t } = useTranslation();

  const dateRangeAPI = useDateRange({
    onChange,
    value,
    getInitialValue,
  });

  const {
    range,
    setRange,
    selectedRange,
    setSelectedRange,
    month,
    year,
    previousYear,
    nextYear,
    previousMonth,
    nextMonth,
  } = dateRangeAPI;

  const [menuOpen, setMenuOpen] = useState(false);
  const [canceled, setCanceled] = useState(false);

  return (
    <Menu
      trigger={(triggerAPI) => renderTrigger(triggerAPI, dateRangeAPI)}
      buttonProps={{ className: 'flex' }}
      position="right"
      contentWrapperClassName="ring-0 bg-taxes-100 w-72 rounded-[20px] overflow-hidden"
      onOpenAnimationStart={() => {
        setMenuOpen(true);
      }}
      onCloseAnimationEnd={() => {
        setMenuOpen(false);
        if (canceled) {
          setRange(selectedRange);
          setCanceled(false);
          return;
        }

        if (range) setSelectedRange(range);
        else setRange(selectedRange);
      }}
    >
      <div>
        <DatePickerCalendar
          previousMonth={previousMonth}
          nextMonth={nextMonth}
          month={month}
          previousYear={previousYear}
          nextYear={nextYear}
          year={year}
          range={range}
          setRange={setRange}
        />

        <div className="flex flex-row justify-end p-3 text-primary-700">
          <Menu.Button
            className="p-3 first-letter:capitalize"
            onClick={() => setCanceled(true)}
          >
            {t('cancel')}
          </Menu.Button>

          <Menu.Button className="p-3 first-letter:capitalize">
            {t('ok')}
          </Menu.Button>
        </div>
      </div>
    </Menu>
  );
};

const DateRangePicker: FC<
  BaseDateRangeProps & {
    wrapperClassNames?: string;
    placeholder?: string;
  }
> = ({ onChange, value, getInitialValue, wrapperClassNames, placeholder }) => {
  const dateRangeAPI = useDateRange({
    onChange,
    value,
    getInitialValue,
  });

  const {
    range,
    setRange,
    selectedRange,
    setSelectedRange,
    setMonth,
    setYear,
    month,
    setRangeFromText,
    year,
    previousYear,
    nextYear,
    previousMonth,
    nextMonth,
    thisYear,
    lastYear,
    lastQuarter,
    isThisYear,
    isLastYear,
    isLastQuarter,
  } = dateRangeAPI;

  const [menuOpen, setMenuOpen] = useState(false);
  const [canceled, setCanceled] = useState(false);

  const { t } = useTranslation();

  return (
    <div
      className={classNames('bg-primary-25 p-4 rounded-2xl', wrapperClassNames)}
    >
      <Menu
        Button={
          <DatePickerText
            range={selectedRange}
            setRange={setRangeFromText}
            isThisYear={isThisYear}
            isLastYear={isLastYear}
            isLastQuarter={isLastQuarter}
            placeholder={placeholder}
            menuOpen={menuOpen}
          />
        }
        position="left"
        contentWrapperClassName="ring-0 bg-taxes-100 w-72 rounded-[20px] overflow-hidden"
        onOpenAnimationStart={() => {
          setMenuOpen(true);
        }}
        onCloseAnimationEnd={() => {
          setMenuOpen(false);
          if (canceled) {
            setRange(selectedRange);
            setCanceled(false);
            return;
          }

          if (range) setSelectedRange(range);
          else setRange(selectedRange);
        }}
      >
        <div>
          <div className="p-3 pb-0 gap-2 flex justify-between text-sm">
            <Menu.Button
              className={classNames(
                'p-2 border border-taxes-300 hover:bg-taxes-50 rounded-lg first-letter:capitalize',
                { 'bg-taxes-300 hover:bg-taxes-200': isThisYear },
              )}
              onClick={() => {
                const start = new Date(thisYear[0]);
                const end = new Date(thisYear[1]);

                setRange([start, end]);
                setSelectedRange([start, end]);

                setMonth(start.getMonth() + 1);
                setYear(start.getFullYear());
              }}
            >
              {t('this_year')}
            </Menu.Button>

            <Menu.Button
              className={classNames(
                'p-2 border border-taxes-300 hover:bg-taxes-50 rounded-lg first-letter:capitalize',
                { 'bg-taxes-300 hover:bg-taxes-200': isLastYear },
              )}
              onClick={() => {
                const start = new Date(lastYear[0]);
                const end = new Date(lastYear[1]);

                setRange([start, end]);
                setSelectedRange([start, end]);

                setMonth(start.getMonth() + 1);
                setYear(start.getFullYear());
              }}
            >
              {t('last_year')}
            </Menu.Button>

            <Menu.Button
              className={classNames(
                'p-2 border border-taxes-300 hover:bg-taxes-50 rounded-lg first-letter:capitalize',
                { 'bg-taxes-300 hover:bg-taxes-200': isLastQuarter },
              )}
              onClick={() => {
                const start = new Date(lastQuarter[0]);
                const end = new Date(lastQuarter[1]);

                setRange([start, end]);
                setSelectedRange([start, end]);

                setMonth(start.getMonth() + 1);
                setYear(start.getFullYear());
              }}
            >
              {t('last_quarter')}
            </Menu.Button>
          </div>

          <DatePickerCalendar
            previousMonth={previousMonth}
            nextMonth={nextMonth}
            month={month}
            previousYear={previousYear}
            nextYear={nextYear}
            year={year}
            range={range}
            setRange={setRange}
          />

          <div className="flex flex-row justify-end p-3 text-primary-700">
            <Menu.Button
              className="p-3 first-letter:capitalize"
              onClick={() => setCanceled(true)}
            >
              {t('cancel')}
            </Menu.Button>

            <Menu.Button className="p-3 first-letter:capitalize">
              {t('ok')}
            </Menu.Button>
          </div>
        </div>
      </Menu>
    </div>
  );
};

export const DatePickerText: FC<{
  range: [Date, Date] | null;
  setRange: (range: [Date, Date]) => void;
  isThisYear: boolean;
  isLastYear: boolean;
  isLastQuarter: boolean;
  placeholder?: string;
  menuOpen: boolean;
}> = ({
  range,
  setRange,
  isThisYear,
  isLastYear,
  isLastQuarter,
  placeholder,
  menuOpen,
}) => {
  const { t } = useTranslation();
  const { data: user } = useGetCurrentCustomerData();

  const dateFormat = useMemo(
    () =>
      user?.country == AvailableCountries.germany ? 'DD.MM.YYYY' : 'DD/MM/YYYY',
    [user?.country],
  );

  const rangeText = useMemo(
    () =>
      range?.map((date) => dayjs(date).format(dateFormat)).join(' – ') ??
      placeholder,
    [dateFormat, placeholder, range],
  );

  const shortRangeText = useMemo(
    () =>
      isThisYear
        ? t('this_year')
        : isLastYear
        ? t('last_year')
        : isLastQuarter
        ? t('last_quarter')
        : rangeText,
    [isLastQuarter, isLastYear, isThisYear, rangeText, t],
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // If range ever changes, update the input text.
    if (rangeText && inputRef.current) inputRef.current.value = rangeText;
  }, [rangeText]);

  const onSubmit = useCallback(() => {
    const input = inputRef.current;
    if (!input) return;

    const range = textToDateRange(input.value);

    if (!range) {
      input.setSelectionRange(0, input.value.length);
      return;
    }

    setRange(range);
  }, [setRange]);

  return (
    <div className="bg-white border border-primary-100 rounded-md w-72 px-4 py-3 flex items-center">
      {!menuOpen ? (
        <div className="text-sm font-semibold flex-1 text-left first-letter:capitalize">
          {shortRangeText}
        </div>
      ) : (
        <input
          ref={inputRef}
          type="text"
          name="range"
          className="text-sm font-semibold flex-1 border-0 -ml-4 px-4 -my-3 py-3 bg-transparent"
          defaultValue={rangeText}
          autoFocus
          onClick={(event) => event.stopPropagation()}
          onKeyDown={(event) => {
            if (event.key == 'Enter') {
              event.preventDefault();
              onSubmit();
            } else if (event.key == ' ') {
              event.stopPropagation();
            }
          }}
        />
      )}

      <div className="w-[1px] self-stretch mr-3 bg-primary-100" />
      <CalendarIcon className="h-4 w-4 text-primary-400" />
    </div>
  );
};

const textToDate = (text: string): Date | null => {
  const separators = ['/', '.', '-'];

  // Try to parse the date with each separator.
  for (const separator of separators) {
    const parts = text.split(separator).map((chunk) => chunk.trim());

    if (parts.length != 3) continue;

    let day = +parts[0];
    const month = +parts[1];
    let year = +parts[2];

    // If day is nonsensical, assume it's the year (swap day and year).
    if (day > 31) [day, year] = [year, day];

    // If year is less than 100, assume it's the 21st century.
    if (year < 100) year += 2000;

    const date = new Date(year, month - 1, day);

    // Make sure it is a valid date.
    if (date.getFullYear() != year) continue;
    if (date.getMonth() != month - 1) continue;
    if (date.getDate() != day) continue;

    return date;
  }

  return null;
};

export const textToDateRange = (text: string): [Date, Date] | null => {
  const separators = ['-', '–', '—', /--+/];

  // Try to parse the date range with each separator.
  for (const separator of separators) {
    const parts = text.split(separator);

    if (parts.length != 2) continue;

    let start = textToDate(parts[0]);
    let end = textToDate(parts[1]);

    if (!start || !end) continue;
    if (start.getTime() > end.getTime()) [start, end] = [end, start];

    return [start, end];
  }

  // Try parsing it as a single date otherwise.
  const date = textToDate(text);
  if (date) return [date, date];

  return null;
};

export default DateRangePicker;
