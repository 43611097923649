import amplitude from 'amplitude-js';

import { amplitudeKey, environment } from 'config';
import type {
  EventConfig,
  TracingConfig,
  TracingConfigEvents,
  TracingConfigEventsHandlers,
} from './amplitude.types';

const amplitudeInstance = amplitude.getInstance();

export const initAmplitude = (): number =>
  requestAnimationFrame(() => amplitudeInstance.init(amplitudeKey || ''));

export const setAmplitudeUser = (userEmail: string): boolean | number =>
  environment !== 'development' &&
  requestAnimationFrame(() => amplitudeInstance.setUserId(userEmail));

export const setAmplitudeUserProperties = (
  properties: Record<string, any>,
): boolean | number =>
  environment !== 'development' &&
  requestAnimationFrame(() => amplitudeInstance.setUserProperties(properties));

let defaultProps = {};

// set some props that can be sent with every event
export const overrideAmplDefaultProps = (
  props: Record<string, string>,
  replace = false,
) => {
  if (replace) {
    defaultProps = props;
  } else {
    defaultProps = { ...defaultProps, ...props };
  }
};

export const logAmplitude = (
  eventName: string,
  properties?: Record<string, string>,
): boolean | number | undefined => {
  console.log({ eventName, properties });
  if (environment !== 'development') {
    return requestAnimationFrame(() => {
      return amplitudeInstance.logEvent(eventName, {
        ...defaultProps,
        ...properties,
      });
    });
  }
};

export const getTracingEvents = (
  tracingConfig: TracingConfig | undefined,
  inputEvents?: TracingConfigEventsHandlers,
): any => {
  if (!tracingConfig) return inputEvents || {};
  const result = Object.entries(tracingConfig).reduce(
    (acc, [eventName, config]) => {
      if (!config) return acc;
      acc[eventName as TracingConfigEvents] = (...eventData: any[]) => {
        // logAmplitude
        if (typeof config === 'string') {
          logAmplitude(config);
        } else {
          logAmplitude(
            config.name,
            typeof config.data === 'function'
              ? config.data(...eventData)
              : config.data,
          );
        }
        inputEvents?.[eventName as TracingConfigEvents]?.(...eventData);
      };
      return acc;
    },
    {} as TracingConfigEventsHandlers,
  );
  return inputEvents ? { ...inputEvents, ...result } : result;
};

const noop = () => {};
export const getTracingEvent = (eventConfig?: EventConfig) => {
  if (!eventConfig) return noop;
  return (...eventData: any[]) => {
    if (typeof eventConfig === 'string') {
      logAmplitude(eventConfig);
    } else {
      logAmplitude(
        eventConfig.name,
        typeof eventConfig.data === 'function'
          ? eventConfig.data(...eventData)
          : eventConfig.data,
      );
    }
  };
};
