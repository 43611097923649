import { useMemo } from 'react';
import type { OptionType } from 'types/global.types';
import {
  complementaryDaysOptions,
  firstTaxComputationYearOptions,
  germanStatesOptions,
  getVATTypeOptions,
} from 'utils/settings/options';
import useFeatures from './useFeatures';
import useTranslateSelectOptions from './useTranslateSelectOptions';

// Use this hook as a single point of truth for all the select options used in the
// settings page functionality and all of their manipulations
const useSettingsSelectOptions = (
  userCountry: string,
  firstTaxComputationYearValue?: number,
): { [x: string]: OptionType[] } => {
  const translateOptions = useTranslateSelectOptions();
  const accountTypesOptions = useFeatures('settings.accountTypeOptions', []);
  const activityTypesOptions = useFeatures('settings.activityTypeOptions', []);

  return useMemo(
    () => ({
      vatTypeOptions: translateOptions(getVATTypeOptions(userCountry)),
      accountTypesOptions: translateOptions(accountTypesOptions),
      activityTypesOptions: translateOptions(activityTypesOptions),
      complementaryDaysOptions: translateOptions(complementaryDaysOptions),
      countriesOptions: translateOptions([
        {
          value: userCountry,
          label: `country.${userCountry}`,
        },
      ]),
      germanStatesOptions,
      firstTaxComputationYearOptions: firstTaxComputationYearOptions(
        firstTaxComputationYearValue as number,
      ),
    }),
    [translateOptions, userCountry, firstTaxComputationYearValue],
  );
};

export default useSettingsSelectOptions;
