import pureSet from 'lodash/fp/set';
import get from 'lodash/get';
import type { FieldState } from './getFieldState';

export function viewSuggestedChanges(
  doc: Record<string, any>,
  suggestedChanges: FieldState['suggestedChanges'],
  callback: (name: string, value: any) => void,
  replaceUndefinedWithNull = false,
) {
  suggestedChanges.forEach((s) => {
    const oldValue = get(doc, s.name);
    let newValue = s.value;

    if (replaceUndefinedWithNull) {
      if (newValue === undefined) newValue = null;
    }

    const isComplex =
      typeof oldValue === 'object' && typeof newValue === 'object';

    if (isComplex) {
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        callback(s.name, newValue);
      }
    } else if (oldValue !== newValue) {
      callback(s.name, newValue);
    }
  });
}

export function applySuggestedChanges(
  doc: Record<string, any>,
  suggestedChanges: FieldState['suggestedChanges'],
  replaceUndefinedWithNull = false,
): any {
  let nextValues = doc;
  viewSuggestedChanges(
    doc,
    suggestedChanges,
    (name, value) => {
      nextValues = pureSet(name, value, nextValues);
    },
    replaceUndefinedWithNull,
  );
  return nextValues;
}

// type MetaResultType = { fields: Record<string, FieldState> };

// type MetaAPIType = {
//   getFormMeta: (values: any) => MetaResultType;
//   getItemMeta: (values: any, item: any, prefix: any) => MetaResultType;
// };

// function applyMetaSuggestedChanges(
//   doc: Record<string, any>,
//   metaAPI: MetaAPIType,
//   callback?: (name: string, value: any) => void,
// ): any {
//   let loops = 1;
//   const safeLoop = (values: any): any => {
//     let nextValues = values;
//     Object.values(metaAPI.getFormMeta(values).fields)
//       .flatMap((field) => field.suggestedChanges)
//       .concat(
//         (values.items || [])
//           .flatMap((item: any, i: number) =>
//             Object.values(
//               metaAPI.getItemMeta(values, item, `items.${i}.`).fields,
//             ),
//           )
//           .flatMap((field: any) => field.suggestedChanges),
//       )
//       .forEach((s) => {
//         if (get(values, s.name) !== s.value) {
//           nextValues = pureSet(s.name, s.value, nextValues);
//           callback?.(s.name, s.value);
//         }
//       });
//     if (nextValues === values) return nextValues;
//     if (loops >= 10) {
//       // just for safety, should never happen, usually the values are stable after the "first" run
//       console.log('-----docUpdateTTL-----');
//       return nextValues;
//     }
//     loops += 1;
//     return safeLoop(nextValues);
//   };
//   return safeLoop(doc);
// }

// export default applyMetaSuggestedChanges;
