import last from 'lodash/last';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getTracingEvents } from 'utils/amplitude';
import { WEBAPP_CATEGORYPICKER_FOCUSEDCATEGORYSEARCH } from 'utils/amplitude/events/global.amplitude';
import { SearchIconOutline } from 'utils/icons';
import BreadCrumbs from './BreadCrumbs.component';
import type { CategoriesPickerSharedProps } from './categoriesPicker.types';
import ListCards from './ListCards.component';
import ListQuestions from './ListQuestions.component';
import { includesPattern } from './utils';

type AllCategoriesListCardsProps = CategoriesPickerSharedProps & {
  categoriesDict: Record<string, any>;
  category: any;
  onClick: (cate: any) => void;
  value?: string;
  searchValue: string;
};

const AllCategoriesListCards: FC<AllCategoriesListCardsProps> = ({
  searchValue,
  category,
  categoriesDict,
  value,
  onClick,
  tooltips,
  helpers,
  disabledCategories,
}) => {
  const { t } = useTranslation();

  const maybeFilteredList = useMemo(() => {
    if (!searchValue.trim()) return category.list;
    return category.list
      .map((category: any) => {
        return {
          ...category,
          list: category.list.flatMap(function search(item: any) {
            const isMatch = includesPattern({
              title: item.title,
              displayName: item.displayName || '',
              description: item.description || '',
              keywords: item.keywords,
              searchValue: searchValue || '',
            });

            const isFinal = !!item.isFinal;

            if (isMatch && isFinal) {
              return { ...item, title: item.displayName || item.title };
            }

            if (item.list) {
              const result = item.list.flatMap(search);
              if (result.length) return result; //{ ...item, list: result };
              if (isMatch) return item;
            }

            return [];
          }),
        };
      })
      .filter((category: any) => category.list.length > 0);
  }, [category.list, searchValue]);

  const isFiltered = maybeFilteredList !== category.list;

  const isEmpty = useMemo(
    () =>
      maybeFilteredList.every((category: any) => category.list.length === 0),
    [maybeFilteredList],
  );

  if (isEmpty) return <div>{t('list_no_result')}</div>;

  return (
    <div className="flex flex-col gap-6">
      {maybeFilteredList.map((cate: any) => (
        <ListCards
          key={cate.id}
          category={cate}
          categoriesDict={categoriesDict}
          value={value}
          onClick={onClick}
          shouldGroupList={isFiltered}
          showCategoryTitle
          tooltips={tooltips}
          helpers={helpers}
          disabledCategories={disabledCategories}
        />
      ))}
    </div>
  );
};

type ListProps = CategoriesPickerSharedProps & {
  categoriesDict: Record<string, any>;
  activePath: string[];
  onPathChange: (path: string[]) => void;
  onChange: any;
  value?: string;
  searchValue: string;
  searchBarPlaceholder?: string;
  onSearch: (value: string) => void;
  header?: ReactNode;
  renderGridHeader?: (searchTerm: string) => ReactNode;
  hideSearchBar?: boolean;
};

const events = getTracingEvents({
  onSearchFocus: WEBAPP_CATEGORYPICKER_FOCUSEDCATEGORYSEARCH,
});

const List: FC<ListProps> = ({
  categoriesDict,
  activePath,
  onPathChange,
  onChange,
  value,
  searchValue,
  header,
  renderGridHeader,
  onSearch,
  searchBarPlaceholder,
  hideSearchBar,
  tooltips,
  helpers,
  disabledCategories,
  renderHeader,
}) => {
  const { t } = useTranslation();

  const dataLayers = useMemo(
    () => activePath.map((id: string) => categoriesDict[id]),
    [activePath],
  );

  // const isCurrentLayerNotTheFirst = activePath.length > 1;

  const currentLayer = last(dataLayers);

  if (!currentLayer) return null; // for safty if the value doesn't exist on our data for some reason

  const backBtn = (
    <BreadCrumbs
      titleClassName="text-sm"
      iconClassName="w-4"
      data={dataLayers.map((layer) => ({
        label: layer.title,
        value: layer.path,
      }))}
      onClick={(path: any) => {
        onPathChange(path);
      }}
    />
  );

  const onClick = (category: any) => {
    if (category.isFinal) return onChange(categoriesDict[category.id]);
    onPathChange(category.path);
  };

  const searchElm = (
    <div className="flex items-center relative">
      <SearchIconOutline className="text-primary-400 w-6 absolute left-4" />
      <input
        onChange={({ target: { value } }) => {
          onSearch(value);
        }}
        onFocus={() => events.onSearchFocus()}
        value={searchValue}
        type="text"
        placeholder={
          searchBarPlaceholder || t('invoice.category_picker.placeholder')
        }
        className="transition-all allow-focus-shadow pl-12 text-lg font-semibold leading-tight py-4 text-primary-700 w-full focus:shadow-[0px_0px_0px_0.25rem] focus:shadow-taxes-100 !ring-0 rounded-full border border-primary-100 focus:border-taxes-600 hover:border-taxes-600 placeholder:text-primary-200"
      />
    </div>
  );

  if (currentLayer.isAllCategories) {
    return (
      <>
        <div className="flex flex-col gap-4 mb-4">
          {backBtn}
          {header}
          {!hideSearchBar && searchElm}
          {renderGridHeader?.(searchValue.trim())}
          {renderHeader?.(currentLayer)}
        </div>
        <AllCategoriesListCards
          searchValue={searchValue.trim()}
          category={currentLayer}
          categoriesDict={categoriesDict}
          value={value}
          onClick={onClick}
          tooltips={tooltips}
          helpers={helpers}
          disabledCategories={disabledCategories}
        />
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4 mb-4">
        {backBtn}
        {header}
        {!hideSearchBar && searchElm}
        {renderGridHeader?.(searchValue.trim())}
        {renderHeader?.(currentLayer)}
      </div>
      {currentLayer.question ? (
        <ListQuestions
          category={currentLayer}
          value={value}
          onClick={onClick}
          tooltips={tooltips}
          helpers={helpers}
          disabledCategories={disabledCategories}
        />
      ) : (
        <ListCards
          category={currentLayer}
          categoriesDict={categoriesDict}
          value={value}
          onClick={onClick}
          tooltips={tooltips}
          helpers={helpers}
          disabledCategories={disabledCategories}
        />
      )}
    </>
  );
};

export default List;
