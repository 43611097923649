import type { ExpenseInput } from 'components/pages/Expenses/Form/FormSlideOver';
import type { InvoiceInput } from 'components/pages/Revenues/OtherRevenueForm';
import type { FormSlideOverManagerApi } from 'hooks/useFormSlideOverManager';
import type { MutableRefObject } from 'react';
import type { Supplier } from 'types/expenses.types';
import type { IClient } from './clients.types';
import type { Period } from './global.types';
import type PersonalDetailsBE from './personalDetailsBE.types';
import type { IAddress, VATTypeEnum } from './users.types';

export enum TaxStatusEnum {
  done = 'done',
  notDone = 'not_done',
  unknown = 'unknown',
}
export type UStESubmissionYearSpecificData = {
  netLines: string[];
};

export enum TaxableIncomeCalcMethodTypes {
  manualEstimate = 'manual_estimate',
  extrapolatedEstimate = 'extrapolated_estimate',
  previousYearData = 'previous_year_data',
}

export enum ActivityEnum {
  liberalProfession = 'liberalProfession',
  commercialEnterprise = 'commercialEnterprise',
}

export enum TaxTypeEnum {
  incomeTaxSubmission = 'income_tax_submission',
  tradeTaxSubmission = 'trade_tax_submission',
  intracomListing = 'intracom_listing',
  vatSubmission = 'vat_submission',
  clientListing = 'client_listing',
  scSubject = 'sc_subject',
  EUR = 'EUR',
  vat = 'vat',
  ustva = 'ustva',
  ustvaSubmission = 'ustva_submission',
  ustvaPayment = 'ustva_payment',
  specialVAT = 'special-vat',
  specialVATPayment = 'special_vat_payment',
  specialVATSubmission = 'special_vat_submission',
  vatPayment = 'vat_payment',
  usteSubmission = 'uste_submission',
  usteVatPayment = 'uste_vat_payment',
  usteVatSubmission = 'uste_vat_submission',

  vatFranchisee = 'vat_franchisee',
  incomeTaxPayment = 'income_tax_payment',
  incomeTaxPrepayment = 'income_tax_prepayment',
  companyTaxPayment = 'company_tax_payment',
  companyTaxPrepayment = 'company_tax_prepayment',
  tradeTaxPrepayment = 'trade_tax_prepayment',
  ustePayment = 'uste_payment',
  scExonerated = 'sc_exonerated',
  scSettings = 'sc_settings',
}

export enum ProfitProjectionEnum {
  extrapolated = 'extrapolated',
  persona = 'persona',
  manual = 'manual',
  previous_year = 'previous_year',
}

export enum CivilStatusEnum {
  single = 'single',
  married = 'married',
  divorced = 'divorced',
  civilPartnership = 'civilPartnership',
  widowed = 'widowed',
  separated = 'separated',
}

export enum PrepaymentMethodEnum {
  none = 'none',
  frontLoaded = 'front_loaded',
  gradual = 'gradual',
}

export enum DocumentFetchType {
  INCLUDED = 'included',
  EXCLUDED = 'excluded',
}

export enum TaxExpensesReasons {
  SUBMITTED = 'submitted',
  NOT_VALIDATED = 'not-validated',
  NOT_SUFFICIENTLY_DOCUMENTED = 'not-sufficiently-documented',
  NOT_PAID = 'not-paid',
  FAKE_DOCUMENT = 'fake-document',
}

export enum TaxCheckThemeEnum {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum TaxRevenuesReasons {
  SUBMITTED = 'submitted',
  NOT_VALIDATED = 'not-validated',
  NOT_SUFFICIENTLY_DOCUMENTED = 'not-sufficiently-documented',
  NOT_PAID = 'not-paid',
  BAD_STATUS = 'bad-status',
}

export enum UseCase {
  validate = 'validate',
  send = 'send',
}

export enum TradeTaxStatusEnum {
  subjected = 'subjected',
  notSubjected = 'not-subjected',
  pending = 'pending',
}

export type StatementMap = {
  [key: string]: Statement;
};

export type Statement = {
  amount: number;
  documents: Document[];
};

export type Document = {
  contribution: number;
  type: 'invoice' | 'expense';
  document: InvoiceDocument | ExpenseDocument;
};

export type InvoiceDocument = {
  id: string;
  revenueNumber: number;
  totalAmountInclVAT: number;
  invoiceDate: Date;
  paymentDate: Date;
  client: IClient;
};

export type ExpenseDocument = {
  id: string;
  amount: number;
  expenseDate: Date;
  supplier: Supplier;
};

export type Submission = {
  created: Date;
  statement?: Record<string, any>;
  personalDetails?: PersonalDetailsBE;
  filepath: string;
  issuer: { _id: string; role: 'user' | 'accountant'; email?: string };
  modified: Date;
  period: Period;
  taxId: string;
  isGuaranteed: boolean;
  isVirtual: boolean;
  submissionStatementBreackdownId: string;
  type: TaxTypeEnum;
  userId: string;
  __v: number;
  _id: string;
};

export interface ITaxSubmissionBody {
  skipGuarantee?: boolean;
  usecase: UseCase;
  name?: string;
  email?: string;
  address?: IAddress;
  steuernummer?: string;
  purposeOfBusiness?: string;
  companyName?: string;
  VATNumber?: string;
  activity?: string;
  firstName?: string;
  lastName?: string;
}
export interface ITax {
  _id: string;
  userId: string;
  readableType: string;
  filepath?: string;
  status: TaxStatusEnum;
  modified: Date;
  created: Date;
  type: TaxTypeEnum;
  period: Period;
  availability: 'ready' | 'intercom';
  dueDate: Date;
  statement: StatementMap;
  breakdown: StatementMap;
  submissions?: Submission[];
  indexKey: string;
  transactionId?: string | null;
  paidAmount?: number | null;
  // local props
  toClaim?: number;
  title?: string;
  availableForSubmission?: boolean;
  values: number[];
  threshold: number;
  currentTurnOver: number;
  projectedTurnOver: number;
  isSpecial?: boolean;
  submission?: ITax;
  payment?: ITax;
  hasGuarantee?: boolean;
  VATTypeSelection: {
    VATType: VATTypeEnum;
    validated: boolean;
  };
}

export interface ESTPaymentTax extends ITax {
  currentTaxableIncome: number;
  projectedTaxableIncome: number;
  employeeSalary: number;
  projectedIncomeTax: number;
  estimatedIncomeTaxOnSalary: number;
  netSalaryAfterTax: number;
  employeePrepayments: number;
  estimatedOtherTaxes: number;
  estimatedIncomeTax: number;
  selfEmployedPrepayments: number;
  incomeTaxPrepaymentTotal: number;
  isEmploymentSubmissionPaneValidated: boolean;
}
export interface ITradeTax extends ITax {
  type: TaxTypeEnum.tradeTaxSubmission;
  personalDetails: {
    validated: boolean;
    name: string;
    purposeOfBusiness: string;
    address: {
      street: string;
      city: string;
      zip: string;
      state: string;
    };
    hebesatz?: {
      value: number;
      isDefault: boolean;
    };
    state: string;
    finanzamt: string;
    bufaNumber: string;
    steuernummer: string;
  };
  calculations: {
    validated: boolean;
    revenueBasedOnEUR: number;
  };
  // tax amounts will be prefilled with DB prepayment items
  prepayments: {
    validated: boolean;
    exist: boolean;
    Q1: number;
    Q2: number;
    Q3: number;
    Q4: number;
  };
  settings: {
    subjectedToTradeTax: string;
    requestedPrepaymentByTaxOffice: number;
  };
  period: {
    year: number;
  };
  // result after validation of all steps
  taxEngineResults?: {
    estimatedPayment?: number;
    revenueBasedOnEUR?: number;
    totalTradeTaxAmount?: number;
    totalPrepaymentsAmount?: number;
  };
}

export type ClientListingItem = {
  name: string;
  totalSalesRevenueExcludingVAT: number;
  totalVAT: number;
  VATNumber: string;
};

export enum ReligionsEnum {
  not_subject_to_church_tax = 'not_subject_to_church_tax',
  altkatholische = 'altkatholische',
  evangelische = 'evangelische',
  römisch_katholisch = 'römisch_katholisch',
  evangelische_reformiert = 'evangelische_reformiert',
  evangelische_reformiert_kirche_bückeburg = 'evangelische_reformiert_kirche_bückeburg',
  evangelische_reformiert_kirche_stadthagen = 'evangelische_reformiert_kirche_stadthagen',
  französisch_reformiert = 'französisch_reformiert',
  freie_religionsgemeinschaft_alzey = 'freie_religionsgemeinschaft_alzey',
  freireligiöse_landesgemeinde_baden = 'freireligiöse_landesgemeinde_baden',
  freireligiöse_landesgemeinde_pfalz = 'freireligiöse_landesgemeinde_pfalz',
  freireligiöse_gemeinde_mainz = 'freireligiöse_gemeinde_mainz',
  freireligiöse_gemeinde_offenbach = 'freireligiöse_gemeinde_offenbach',
  israelitische_religionsgemeinschaft_baden = 'israelitische_religionsgemeinschaft_baden',
  jüdische_gemeinde_im_landesverband_hessen = 'jüdische_gemeinde_im_landesverband_hessen',
  landesverband_der_israelitischen_kultusgemeinden_in_bayern = 'landesverband_der_israelitischen_kultusgemeinden_in_bayern',
  jüdische_gemeinde_frankfurt_hessen = 'jüdische_gemeinde_frankfurt_hessen',
  jüdische_kultusgemeinde_bad_kreuznach_und_koblenz = 'jüdische_kultusgemeinde_bad_kreuznach_und_koblenz',
  israelitische_saarland = 'israelitische_saarland',
  israelitische_religionsgemeinschaft_württemberg = 'israelitische_religionsgemeinschaft_württemberg',
  nordrhein_westfalen_israelitisch_jüdisch = 'nordrhein_westfalen_israelitisch_jüdisch',
  jüdische_gemeinde_hamburg = 'jüdische_gemeinde_hamburg',
  sonstige = 'sonstige',
  freireligiöse = 'freireligiöse',
}

export type QuarterTaxes = { meta?: Record<string, number>; items: ITax[] };

export type TaxesByYearData = {
  q1: QuarterTaxes;
  q2: QuarterTaxes;
  q3: QuarterTaxes;
  q4: QuarterTaxes;
  annual: QuarterTaxes;
};

export type TaxesByYear = {
  data: TaxesByYearData;
  meta: {
    actionsRequired: Record<number, number>;
  };
};

export type VATScreenContent = {
  tax: ITax;
  currentSubmission: Submission | null;
  payment: ITax;
  submission: ITax;
  isSummaryStep?: boolean;
  nextHandler?: (i: string) => void;
  VATGroup?: ITax;
};

export type TaxImpactVAT = {
  recoverableAmount: number;
  recoverablePercentage?: number;
  reverseChargeAmountToPay: number;
  amount: number;
  flatFeeUsed: boolean;
  taxRate: number;
  statement: { [key: string]: number };
};

export type TaxImpactIncomeTax = {
  deductibilityPercentage: number;
  deductibleAmount: number;
  nonDeductibleAmount: number;
  amount: number;
  flatFeeUsed: boolean;
  taxRate: number;
  main: number;
  copyright: number;
};
export type TaxImpactItem = {
  isAsset?: boolean;
  amortizationPeriod?: number;
  vat: TaxImpactVAT;
  name: string;
  incomeTax: TaxImpactIncomeTax;
  professionalAmount?: number;
};
export type TaxImpact = {
  sufficientlyDocumented: boolean;
  items: TaxImpactItem[];
  total: {
    incomeTax: TaxImpactIncomeTax;
    vat: TaxImpactVAT;
  };
};

export enum TaxImpactDocumentType {
  REVENUE = 'revenues',
  EXPENSE = 'expenses',
  OTHER_REVENUE = 'other-revenue',
}

export enum TaxCheckEnum {
  VATRefundMoreThan1000Euro = 'submission.vat-refund-above-1000', // 1
  expenseVATClaimedAbove21 = 'expense.vat-claimed-above-21', // 2
  expenseVATClaimedAbove91 = 'expense.vat-claimed-above-19', // 2
  expenseReverseCharge = 'expense.reverse-charge', // 5
  expenseLinkedToVATPayment = 'expense.linked-to-vat-payment', // 7
  expensePerDiemNatural = 'expense.per-diem-natural-person', // 8
  expenseLunchVoucherNaturalPerson = 'expense.lunch-voucher-natural-person', // 9
  expenseVehicleACPCodeMismatch = 'expense.vehicle-acp-code-mismatch', // 10
  expenseVehicleMore35deductibility = 'expense.vehicle-more-35-deductibility', // 11
  expenseFakeReceipt = 'expense.fake-receipt', // 12
  expenseMismatchVATRateWithCategory = 'expense.mismatch-vat-rate-with-category', // 14
  expensePaymentMismatchWithDocument = 'expense.payment-mismatch-with-document', // 15
  revenuePaymentMismatchWithDocument = 'revenue.payment-mismatch-with-document', // 17
  expensePersonalSupplier = 'expense.personal-supplier', // 18
  expenseRestaurantPersonal = 'expense.restaurant-personal', // 19
  expenseVehiclePurchaseAbove500 = 'expense.vehicle-purchase-above-500', // 20
  expenseAbove1000Euro = 'expense.above-1000-eur', // 21
  expenseSufficientlyDocumentedWithoutDocument = 'expense.sufficiently-documented-without-document', // 22
  expenseAmortizedPeriodNotRecommended = 'expense.amortized-period-not-recommended', // 23
  expenseFranchiseeRegime = 'expense.franchisee-regime', // 24
  revenueFranchiseeRegime = 'revenue.franchisee-regime', // 24
  revenueIncludedInAnotherSubmission = 'revenue.included-in-another-submission', // 26
  expenseIncludedInAnotherSubmission = 'expense.included-in-another-submission', // 26
  submissionExpenseIncludedInAnotherSubmission = 'submission.expense-included-in-another-submission', // 26
  submissionRevenueIncludedInAnotherSubmission = 'submission.revenue-included-in-another-submission', // 26
  expenseMissingDocument = 'expense.missing-document', // 27
  expenseNotReviewed = 'expense.not-reviewed', // 28
  revenueNotReviewed = 'revenue.not-reviewed', // 29
  revenueNotSent = 'revenue.not-sent', // 30
  revenueNotPaid = 'revenue.not-paid', // 31
  expenseInvoiceWithoutVATNumber = 'expense.invoice-without-vat-number', // 32
  expenseTicketWithVATNumber = 'expense.ticket-with-vat-number', // 33
  expenseDuplicate = 'expense.duplicate', // 34
  missingVATPaymentQuarterly = 'submission.missing-vat-payment-quarterly', // 41
  missingVATPaymentMonthly = 'submission.missing-vat-payment-monthly', // 42
  onePercentRuleExpenseNoUsage = 'submission.1p-rule-expense-no-usage', // 43
  onePercentRuleExpenseNoUsageNewYear = 'submission.1p-rule-expense-no-usage-new-year', // 44
  commuterAllowanceDaysHigh = 'submission.commuter-allowance-days-high', // 46
  commuterAllowanceAmountHigh = 'submission.commuter-allowance-amount-high', // 47
  onePercentRuleExpenseMixedUsage = 'submission.1p-rule-expense-mixed-usage', // 51
  submissionRevenueFranchiseeAssessmentPeriodVAT = 'submission.revenue-franchisee-assessment-period-VAT',
  submissionRevenueSubjectToVATAssessmentPeriodVAT = 'submission.revenue-subject-to-VAT-assessment-period-VAT',
  submissionExpenseFranchiseeAssessmentPeriodVAT = 'submission.expense-franchisee-assessment-period-VAT',
  submissionExpenseSubjectToVATAssessmentPeriodVAT = 'submission.expense-subject-to-VAT-assessment-period-VAT',
  AllReviewed = 'AllReviewed',
}

export type CheckComponentProps = {
  checkData: any;
  setPage: (page: number) => void;
  taxId: string;
  checkName: TaxCheckEnum;
  refetchCheck: () => void;
  nextHandler?: () => void;
  expenseFormRef: MutableRefObject<FormSlideOverManagerApi<ExpenseInput>>;
  revenueFormRef: MutableRefObject<FormSlideOverManagerApi<InvoiceInput>>;
};

export enum TaxStepsEnum {
  DISCLAIMER = 'disclaimer',
  REVIEW = 'review',
  VATTYPE = 'VATType',
  SUBMISSION = 'submission',
  PAYMENT = 'payment',
}
