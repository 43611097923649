import api from 'api/axiosInstance';
import type { CreditCardStatementOCR } from 'types/transactions.types';

export type CreditCardStatementOCRBody = {
  filename: string;
  accountNumber: string;
  originalFilename: string;
};

const getCreditCardStatementsOCR = (
  connectorId: string,
  body: CreditCardStatementOCRBody,
): Promise<CreditCardStatementOCR> =>
  api.post(`/v2/connectors/${connectorId}/statements`, body);

export default getCreditCardStatementsOCR;
