import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { ONE_SECOND } from 'utils/time';
import tryCatch from 'utils/tryCatch';
import create from 'zustand';

type Get = () => { isOpen: boolean };
type API = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export const trustpilotReviewController = create<API>((set, get: Get) => {
  return {
    isOpen: false,
    open: () => set({ isOpen: true }),
    close: () => set({ isOpen: false }),
    toggle: () => set({ isOpen: !get().isOpen }),
  };
});

type SavedReview = {
  stars: number;
  date: string;
};

const storageName = 'TPReveiw';
const dateFormat = 'YYYY-MM-DD';

export const maybeAskForTrustpilotReview = () => {
  setTimeout(() => {
    const lastReview = tryCatch(() =>
      JSON.parse(localStorage.getItem(storageName) || '{}'),
    ) as SavedReview | undefined;

    if (!lastReview || isEmpty(lastReview)) {
      return trustpilotReviewController.getState().open();
    }

    const lastReviewDate = dayjs(lastReview.date, dateFormat);

    if (lastReviewDate.isValid() && dayjs().diff(lastReviewDate, 'day') < 30) {
      return;
    }

    const lastReviewStars =
      typeof lastReview.stars !== 'number' || isNaN(lastReview.stars)
        ? -1
        : lastReview.stars;

    if (lastReviewStars === -1) {
      // modal was closed without interacting with the stars
      return trustpilotReviewController.getState().open();
    }

    if (lastReview.stars < 3) {
      // left a bad review maybe we should ask him again now ?
      return trustpilotReviewController.getState().open();
    }
  }, ONE_SECOND * 1);
};

export const saveTrustpilotReview = (stars: number) => {
  localStorage.setItem(
    storageName,
    JSON.stringify({ date: dayjs().format(dateFormat), stars } as SavedReview),
  );
};
