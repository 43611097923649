import dayjs from 'dayjs';
import Joi from 'joi';
import { formatErrorMessage } from 'validation/commonValidators';

const currentYear = dayjs().year();
const SCSettingSchema = Joi.object({
  startDate: Joi.date().required(),
  provider: Joi.string().required(),
  quarterPayment: Joi.number()
    .required()
    .messages(formatErrorMessage('taxes.social_contributions', 'number')),
  isSubjectToSocialContributions: Joi.boolean(),
  expectedTaxableIncome: Joi.number()
    .required()
    .messages(formatErrorMessage('taxes.social_contributions', 'number')),
  taxableIncomeThreeYearsAgo: Joi.when('startDate', {
    is:
      currentYear - dayjs(Joi.ref('startDate') as unknown as string)?.year() >=
      3,
    then: Joi.number()
      .required()
      .messages(formatErrorMessage('taxes.social_contributions', 'number')),
    otherwise: Joi.any(),
  }),
});

export default SCSettingSchema;
