import dayjs from 'dayjs';
import type { Period } from 'types/global.types';

const getPeriodSlug = (tax?: { period: Period }): string =>
  `${tax?.period?.year || dayjs().year()}${
    tax?.period?.quarter || tax?.period?.month ? '-' : ''
  }${tax?.period?.quarter ? `4${tax?.period?.quarter}` : ''}${
    tax?.period?.month || ''
  }`;

export default getPeriodSlug;
