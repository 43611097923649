import whyZeroVATImage from 'assets/images/why-zero-vat.png';
import { Button } from 'components/atoms/Button';
import { Select } from 'components/atoms/Form/Select';
import { Helper } from 'components/molecules/Helper';
import WarningModalContent from 'components/organisms/WarningModalContent';
import type { FieldProps } from 'components/pages/Expenses/Form/Fields/commonFieldProps.types';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useModal from 'hooks/useModal';
import i18n from 'i18n';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { WhyZeroVATEnum } from 'types/invoice.types';
import { getTracingEvents } from 'utils/amplitude';
import type { TracingConfig } from 'utils/amplitude/amplitude.types';
import {
  getValidWhyZeroVATValues,
  GetValidWhyZeroVATValuesData,
} from '../utils';

export type WhyZeroVATProps = FieldProps &
  GetValidWhyZeroVATValuesData & {
    isNormalSelect?: boolean;
    tracing?: TracingConfig;
  };

export const getValueHelper = (value?: string) => {
  if (value === 'intra-eu-reverse-charge') {
    return (
      <Helper
        trigger={(open) => (
          <Button structure="text" onClick={open}>
            {i18n.t('learn_more_about_this_option') as string}
          </Button>
        )}
      >
        <Helper.Title>
          {i18n.t(
            'invoice.form.why_zero_vat.intra-eu-reverse-charge.helper.title',
          )}
        </Helper.Title>
        <Helper.Paragraph>
          {i18n.t(
            'invoice.form.why_zero_vat.intra-eu-reverse-charge.helper.text1',
          )}
        </Helper.Paragraph>
        <Helper.Paragraph>
          {i18n.t(
            'invoice.form.why_zero_vat.intra-eu-reverse-charge.helper.text2',
          )}
        </Helper.Paragraph>
        <Helper.Paragraph>
          {i18n.t(
            'invoice.form.why_zero_vat.intra-eu-reverse-charge.helper.text3',
          )}
        </Helper.Paragraph>
      </Helper>
    );
  }
  return null;
};

const WhyZeroVAT: FC<WhyZeroVATProps> = ({
  value,
  name,
  onChange,
  clientLocation,
  clientType,
  revenueType,
  categoryId,
  isNormalSelect,
  tracing,
  disabled,
  VATType,
}) => {
  const { t } = useTranslation();

  const [modalContent, modalApi] = useModal({
    dialogPanalClassName: '!p-0',
    width: '5xl',
    name: 'Why zero vat picker',
  });

  const customerCountry = useCustomerCountry();

  const valids = getValidWhyZeroVATValues({
    clientLocation,
    clientType,
    revenueType,
    categoryId,
    VATType,
  });

  const options = [
    {
      title: t('invoice.form.why_zero_vat.exempt-item'),
      description: t(
        `invoice.form.why_zero_vat.exempt-item.subtitle.${customerCountry}`,
      ),
      value: WhyZeroVATEnum.exempt_item,
      isSelected: value === WhyZeroVATEnum.exempt_item,
      isDisabled: !valids.includes(WhyZeroVATEnum.exempt_item),
    },
    {
      title: t('invoice.form.why_zero_vat.intra-eu-reverse-charge'),
      description: t(
        `invoice.form.why_zero_vat.intra-eu-reverse-charge.subtitle.${customerCountry}`,
      ),
      value: WhyZeroVATEnum.intra_eu_reverse_charge,
      isSelected: value === WhyZeroVATEnum.intra_eu_reverse_charge,
      tooltip: !valids.includes(WhyZeroVATEnum.intra_eu_reverse_charge)
        ? t('invoice.field.why_zero_vat.disclaimer')
        : undefined,
      isDisabled: !valids.includes(WhyZeroVATEnum.intra_eu_reverse_charge),
    },
    {
      title: t('invoice.form.why_zero_vat.diplomatic'),
      description: t('invoice.form.why_zero_vat.diplomatic.subtitle'),
      value: WhyZeroVATEnum.diplomatic,
      isSelected: value === WhyZeroVATEnum.diplomatic,
      isDisabled: !valids.includes(WhyZeroVATEnum.diplomatic),
    },
    {
      title: t(
        `invoice.form.why_zero_vat.local-reverse-charge.${customerCountry}`,
      ),
      description: t(
        `invoice.form.why_zero_vat.local-reverse-charge.${customerCountry}.subtitle`,
      ),
      value: WhyZeroVATEnum.local_reverse_charge,
      isSelected: value === WhyZeroVATEnum.local_reverse_charge,
      tooltip: !valids.includes(WhyZeroVATEnum.local_reverse_charge)
        ? t('invoice.field.why_zero_vat.disclaimer')
        : undefined,
      isDisabled: !valids.includes(WhyZeroVATEnum.local_reverse_charge),
    },
    {
      title: t('invoice.form.why_zero_vat.extra-eu-export'),
      description: t('invoice.form.why_zero_vat.extra-eu-export.subtitle'),
      value: WhyZeroVATEnum.extra_eu_export,
      isSelected: value === WhyZeroVATEnum.extra_eu_export,
      tooltip: !valids.includes(WhyZeroVATEnum.extra_eu_export)
        ? t('invoice.field.why_zero_vat.disclaimer')
        : undefined,
      isDisabled: !valids.includes(WhyZeroVATEnum.extra_eu_export),
    },
  ];

  const selected =
    options.find((option) => option.value === value)?.title || null;

  const tracingEvents = getTracingEvents(tracing, { onChange });

  const valueHelper = getValueHelper(value);

  return (
    <>
      {modalContent}
      <Select
        name={name}
        value={selected}
        openMenuOnClick
        isDisabled={disabled}
        isSearchable={false}
        noOptionsMessage={() => null}
        placeholder=""
        onMenuOpen={() => {
          tracingEvents.onMenuOpen?.();
          if (!isNormalSelect) {
            modalApi.open(() => (
              <WarningModalContent
                title={t('invoice.field.why_zero_vat.title')}
                options={options}
                img={whyZeroVATImage}
                onChoose={(value) => {
                  tracingEvents.onChange(value);
                  modalApi.close();
                }}
              />
            ));
          }
        }}
        formatOptionLabel={(option: any, meta: any) => {
          if (meta.context === 'value') return option.label;
          return (
            <div className="flex align-middle justify-between">
              <div className="ml-3">
                <p className="text-sm font-semibold">{option.title}</p>
                <p className="text-sm desc">
                  <ReactMarkdown
                    components={{
                      a: ({ children, href }) => (
                        <a
                          href={href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline text-blue-500 hover:text-blue-600"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {children}
                        </a>
                      ),
                    }}
                  >
                    {option.description}
                  </ReactMarkdown>
                </p>
              </div>
            </div>
          );
        }}
        {...(isNormalSelect && {
          options,
          onChange: tracingEvents.onChange,
          // value: options?.find((opt) => opt.value === value),
        })}
      />
      {valueHelper && (
        <div className="flex">
          <span className="ml-auto">{valueHelper}</span>
        </div>
      )}
    </>
  );
};

export default WhyZeroVAT;
