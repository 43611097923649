import api from 'api/axiosInstance';
import { getLocationOrigin } from 'utils/helpers';
const returnUrl = `${getLocationOrigin(true)}/expenses`;

const getCustomerBillingPortal = (): Promise<{
  url: string;
}> =>
  api.get(`/v1/subscriptions/stripe/customer-portal?return_url=${returnUrl}`);

export default getCustomerBillingPortal;
