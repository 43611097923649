import api from 'api/axiosInstance';

type GetUserFinanzamtResponse = {
  taxOffice: string;
  IBAN: string;
  address: {
    zip: string;
    city: string;
    street: string;
  };
  email: string;
  fax: string;
  tel: string;
  url: string;
};

export const getUserFinanzamt = async (
  steuernummer: string,
  state: string,
): Promise<GetUserFinanzamtResponse> => {
  return api.get('/v2/users/tax-office', {
    params: { steuernummer, state },
    ignoreToast: true,
  } as any);
};
