import AIIconOutline from 'assets/svg/icons/AIIcon';
import type { FC, ReactNode } from 'react';
import {
  CheckIconOutline,
  ExclamationIconOutline,
  InformationCircleIcon,
  InformationCircleIconOutline,
} from 'utils/icons';

export interface AlertProps {
  className?: string;
  contentClassName?: string;
  showIntercomBtn?: boolean;
  type:
    | 'default'
    | 'success'
    | 'info'
    | 'warning'
    // | 'warning2'
    | 'error'
    | 'ai';
  title?: ReactNode;
  description?: string | ReactNode;
  errorMessages?: string[];
  renderButtons?: (themeClassName: string) => ReactNode;
  dismissable?: boolean;
  size?: 'sm' | 'normal';
  hideIcon?: boolean;
  onDismiss?: () => void;
  name?: string; // for amplitude
  style?: Record<string, any>;
  id?: string;
  dismissId?: string;
  icon?: FC<React.ComponentProps<'svg'>>;
  iconClassName?: string;
}

type CustomAlertTypeMap = Record<
  AlertProps['type'],
  {
    icon: FC<React.ComponentProps<'svg'>>;
    backgroundClass: string;
    iconClass: string;
    titleClass: string;
    descriptionClass: string;
    supportBtn: string;
  }
>;

export const customAlertTypeMap: CustomAlertTypeMap = {
  default: {
    icon: InformationCircleIconOutline,
    backgroundClass: 'bg-taxes-100',
    iconClass: 'text-primary-400',
    titleClass: 'text-primary',
    descriptionClass: 'text-primary',
    supportBtn: 'text-primary hover:text-primary-700',
  },
  ai: {
    icon: AIIconOutline,
    backgroundClass: 'bg-neutral-100',
    iconClass: 'text-taxes bg-taxes-100 p-1 box-content rounded-full',
    titleClass: 'text-primary-700',
    descriptionClass: 'text-primary-700',
    supportBtn: 'text-primary-700 hover:text-primary',
  },
  success: {
    icon: CheckIconOutline,
    backgroundClass: 'bg-[#ECFDF5]',
    iconClass: 'text-[#065F46]',
    titleClass: 'text-[#065F46]',
    descriptionClass: 'text-[#065F46]',
    supportBtn: 'text-[#065F46] hover:text-green-700',
  },
  info: {
    icon: InformationCircleIcon,
    backgroundClass: 'bg-blue-50',
    iconClass: 'text-blue-400',
    titleClass: 'text-blue-800',
    descriptionClass: 'text-blue-800',
    supportBtn: 'text-blue-800 hover:text-blue-700',
  },
  warning: {
    icon: ExclamationIconOutline,
    backgroundClass: 'bg-[#FFFBEB]',
    iconClass: 'text-[#92400E]',
    titleClass: 'text-[#92400E]',
    descriptionClass: 'text-[#92400E]',
    supportBtn: 'text-[#92400E] hover:text-yellow-700',
  },
  // warning2: {
  //   icon: ExclamationIconOutline,
  //   backgroundClass: 'bg-[#D97706]',
  //   iconClass: 'text-yellow-800',
  //   titleClass: 'text-white',
  //   descriptionClass: 'text-white font-semibold',
  //   supportBtn: 'text-yellow-800 hover:text-yellow-700',
  // },
  error: {
    icon: ExclamationIconOutline,
    backgroundClass: 'bg-[#FEF2F2]',
    iconClass: 'text-[#7F1D1D]',
    titleClass: 'text-[#7F1D1D]',
    descriptionClass: 'text-[#991B1B]',
    supportBtn: 'text-[#7F1D1D] hover:text-red-700',
  },
};
