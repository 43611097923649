import {
  helperContent18,
  helperContent270,
  helperContent271,
  helperContent33,
  helperContent36,
  professionHelperContent,
} from './helperContent';
import type { Gratifications } from './types';
import { ContentType, GratificationTypes } from './types';

const getHelperContentSimpleText = (text: string) => [
  {
    type: ContentType.text,
    text,
  },
];

export const gratificationsData: Gratifications = {
  '2': {
    gratificationNumber: 2,
    text: 'gratification_message_2',
    icon: 'message_5',
    type: 'taxTip',
  },
  '5': {
    gratificationNumber: 5,
    text: 'gratification_message_5',
    icon: 'message_5',
    type: 'taxTip',
  },
  '6': {
    gratificationNumber: 6,
    text: 'gratification_message_6',
    icon: 'message_6',
    type: 'taxTip',
  },
  '7': {
    gratificationNumber: 7,
    text: 'gratification_message_7',
    icon: 'message_7',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText('gratification_message_7_text_1'),
  },
  '8': {
    gratificationNumber: 8,
    text: 'gratification_message_8',
    icon: 'message_8',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText('gratification_message_8_text_1'),
  },
  '9': {
    gratificationNumber: 9,
    text: 'gratification_message_9',
    icon: 'message_9',
    type: 'taxImpact',
    helperContent: helperContent36,
  },
  '10': {
    gratificationNumber: 10,
    text: 'gratification_message_10',
    icon: 'message_10',
    type: 'taxImpact',
    helperContent: helperContent36,
  },
  '11': {
    gratificationNumber: 11,
    text: 'gratification_message_11',
    icon: 'message_11',
    type: 'taxTip',
  },
  '12': {
    gratificationNumber: 12,
    text: 'gratification_message_12',
    icon: 'message_11',
    type: 'taxTip',
  },
  '13': {
    gratificationNumber: 13,
    text: 'gratification_message_13',
    icon: 'message_13',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_13_text_1',
    ),
  },
  '14': {
    gratificationNumber: 14,
    text: 'gratification_message_14',
    icon: 'message_14',
    type: 'taxTip',
  },
  '16': {
    gratificationNumber: 16,
    text: 'gratification_message_16',
    icon: 'message_16',
    type: 'taxTip',
  },
  '17': {
    gratificationNumber: 17,
    text: 'gratification_message_17',
    icon: 'message_17',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_17_text_1',
    ),
  },
  '18': {
    gratificationNumber: 18,
    text: 'gratification_message_18_new',
    icon: 'message_271',
    type: 'taxTip',
    helperContent: helperContent18,
  },
  '20': {
    gratificationNumber: 20,
    text: 'gratification_message_20',
    icon: 'message_20',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_20_text_1',
    ),
  },
  '21': {
    gratificationNumber: 21,
    text: 'gratification_message_21',
    icon: 'message_21',
    type: 'taxTip',
  },
  '22': {
    gratificationNumber: 22,
    text: 'gratification_message_22',
    icon: 'message_22',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_22_text_1',
    ),
  },
  '23': {
    gratificationNumber: 23,
    text: 'gratification_message_23',
    icon: 'message_23',
    type: 'appTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_23_text_1',
    ),
  },
  '24': {
    gratificationNumber: 24,
    text: 'gratification_message_24',
    icon: 'message_24',
    type: 'taxTip',
  },
  '25': {
    gratificationNumber: 25,
    text: 'gratification_message_25',
    icon: 'message_25',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_25_text_1',
    ),
  },
  '28': {
    gratificationNumber: 28,
    text: 'gratification_message_28',
    icon: 'message_28',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_28_text_1',
    ),
  },
  '29': {
    gratificationNumber: 29,
    text: 'gratification_message_29',
    icon: 'message_29',
    type: 'taxTip',
    helperContent: [
      { type: ContentType.text, text: 'gratification_message_29_text_1' },
      { type: ContentType.link, text: 'gratification_message_36_link_1' },
    ],
  },
  '32': {
    gratificationNumber: 32,
    text: 'gratification_message_32',
    icon: 'message_32',
    type: 'taxTip',
    helperContent: [
      { type: ContentType.title, text: 'gratification_message_32_title_1' },
      { type: ContentType.text, text: 'gratification_message_32_text_1' },
      { type: ContentType.text, text: 'gratification_message_32_text_2' },
      { type: ContentType.text, text: 'gratification_message_32_text_3' },
      { type: ContentType.text, text: 'gratification_message_32_text_4' },
    ],
  },
  '33': {
    gratificationNumber: 33,
    text: 'gratification_message_33',
    icon: 'message_33',
    type: 'appTip',
    helperContent: helperContent33,
  },
  '36': {
    gratificationNumber: 36,
    text: 'gratification_message_36',
    icon: 'message_36',
    type: 'taxImpact',
    helperContent: helperContent36,
  },
  '38': {
    gratificationNumber: 38,
    text: 'gratification_message_38',
    icon: 'message_38',
    type: 'taxImpact',
    helperContent: helperContent36,
  },
  '40': {
    gratificationNumber: 40,
    text: 'gratification_message_40',
    icon: 'message_40',
    type: 'taxImpact',
    helperContent: helperContent36,
  },
  '42': {
    gratificationNumber: 42,
    text: 'gratification_message_42',
    icon: 'message_42',
    type: 'taxImpact',
    helperContent: helperContent36,
  },
  '45': {
    gratificationNumber: 45,
    text: 'gratification_message_45',
    icon: 'message_45',
    type: 'appTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_45_text_1',
    ),
  },
  '48': {
    gratificationNumber: 48,
    text: 'gratification_message_48',
    icon: 'message_33',
    type: 'appTip',
    helperContent: helperContent33,
  },
  '49': {
    gratificationNumber: 49,
    text: 'gratification_message_49',
    icon: 'message_49',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_49_text_1',
    ),
  },
  '52': {
    gratificationNumber: 52,
    text: 'gratification_message_52',
    icon: 'message_52',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_52_text_1',
    ),
  },
  '53': {
    gratificationNumber: 53,
    text: 'gratification_message_53',
    icon: 'message_53',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_53_text_1',
    ),
  },
  '56': {
    gratificationNumber: 56,
    text: 'gratification_message_56',
    icon: 'message_56',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_56_text_1',
    ),
  },
  '58': {
    gratificationNumber: 58,
    text: 'gratification_message_58',
    icon: 'message_58',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_58_text_1',
    ),
  },
  '61': {
    gratificationNumber: 61,
    text: 'gratification_message_61',
    icon: 'message_61',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_61_text_1',
    ),
  },
  '63': {
    gratificationNumber: 63,
    text: 'gratification_message_63',
    icon: 'message_63',
    type: 'joke',
  },
  '65': {
    gratificationNumber: 65,
    text: 'gratification_message_65',
    icon: 'message_65',
    type: 'joke',
  },
  '67': {
    gratificationNumber: 67,
    text: 'gratification_message_67',
    icon: 'message_67',
    type: 'joke',
  },
  '69': {
    gratificationNumber: 69,
    text: 'gratification_message_69',
    icon: 'message_69',
    type: 'joke',
  },
  '71': {
    gratificationNumber: 71,
    text: 'gratification_message_71',
    icon: 'message_71',
    type: 'joke',
  },
  '73': {
    gratificationNumber: 73,
    text: 'gratification_message_73',
    icon: 'message_73',
    type: 'joke',
  },
  '75': {
    gratificationNumber: 75,
    text: 'gratification_message_75',
    icon: 'message_75',
    type: 'joke',
  },
  '77': {
    gratificationNumber: 77,
    text: 'gratification_message_77',
    icon: 'message_77',
    type: 'joke',
  },
  '79': {
    gratificationNumber: 79,
    text: 'gratification_message_79',
    icon: 'message_79',
    type: 'joke',
  },
  '81': {
    gratificationNumber: 81,
    text: 'gratification_message_81',
    icon: 'message_81',
    type: 'joke',
  },
  '82': {
    gratificationNumber: 82,
    text: 'gratification_message_82',
    icon: 'message_82',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_82_text_1',
    ),
  },
  '83': {
    gratificationNumber: 83,
    text: 'gratification_message_83',
    icon: 'message_83',
    type: 'joke',
  },
  '86': {
    gratificationNumber: 86,
    text: 'gratification_message_86',
    icon: 'message_86',
    type: 'joke',
  },
  '88': {
    gratificationNumber: 88,
    text: 'gratification_message_88',
    icon: 'message_88',
    type: 'joke',
  },
  '90': {
    gratificationNumber: 90,
    text: 'gratification_message_90',
    icon: 'message_90',
    type: 'joke',
  },
  '91': {
    gratificationNumber: 91,
    text: 'gratification_message_91',
    icon: 'message_91',
    type: 'taxTip',
  },
  '92': {
    gratificationNumber: 92,
    text: 'gratification_message_92',
    icon: 'message_92',
    type: 'joke',
  },
  '93': {
    gratificationNumber: 93,
    text: 'gratification_message_93',
    icon: 'message_93',
    type: 'taxTip',
  },
  '94': {
    gratificationNumber: 94,
    text: 'gratification_message_94',
    icon: 'message_94',
    type: 'joke',
  },
  '95': {
    gratificationNumber: 95,
    text: 'gratification_message_95',
    icon: 'message_95',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_95_text_1',
    ),
  },
  '96': {
    gratificationNumber: 96,
    text: 'gratification_message_96',
    icon: 'message_96',
    type: 'taxTip',
    helperContent: [
      { type: ContentType.text, text: 'gratification_message_96_text_1' },
      { type: ContentType.title, text: 'gratification_message_96_title_1' },
      { type: ContentType.text, text: 'gratification_message_96_text_2' },
      { type: ContentType.link, text: 'gratification_message_96_link_1' },
      { type: ContentType.text, text: 'gratification_message_96_text_3' },
      { type: ContentType.text, text: 'gratification_message_96_text_4' },
    ],
  },
  '97': {
    gratificationNumber: 97,
    text: 'gratifications.message_97',
    icon: 'message_97',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_97_text_1',
    ),
  },
  '99': {
    gratificationNumber: 99,
    text: 'gratifications.message_99',
    icon: 'message_99',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_99_text_1',
    ),
  },
  '101': {
    gratificationNumber: 101,
    text: 'gratifications.message_101',
    icon: 'message_101',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_101_text_1',
    ),
  },
  '102': {
    gratificationNumber: 102,
    text: 'gratifications.message_102',
    icon: 'message_102',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_102_text_1',
    ),
  },
  '103': {
    gratificationNumber: 103,
    text: 'gratifications.message_103',
    icon: 'message_103',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_103_text_1',
    ),
  },
  '104': {
    gratificationNumber: 104,
    text: 'gratifications.message_104',
    icon: 'message_104',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_104_text_1',
    ),
  },
  '105': {
    gratificationNumber: 105,
    text: 'gratifications.message_105',
    icon: 'message_105',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_105_text_1',
    ),
  },
  '106': {
    gratificationNumber: 106,
    text: 'gratifications.message_106',
    icon: 'message_106',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_106_text_1',
    ),
  },
  '107': {
    gratificationNumber: 107,
    text: 'gratifications.message_107',
    icon: 'message_107',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_107_text_1',
    ),
  },
  '108': {
    gratificationNumber: 108,
    text: 'gratifications.message_108',
    icon: 'message_108',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_108_text_1',
    ),
  },
  '109': {
    gratificationNumber: 109,
    text: 'gratifications.message_109',
    icon: 'message_109',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_109_text_1',
    ),
  },
  '111': {
    gratificationNumber: 111,
    text: 'gratifications.message_111',
    icon: 'message_111',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_111_text_1',
    ),
  },
  '112': {
    gratificationNumber: 112,
    text: 'gratifications.message_112',
    icon: 'message_112',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_112_text_1',
    ),
  },
  '113': {
    gratificationNumber: 113,
    text: 'gratifications.message_113',
    icon: 'message_113',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_113_text_1',
    ),
  },
  '114': {
    gratificationNumber: 114,
    text: 'gratifications.message_114',
    icon: 'message_114',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_114_text_1',
    ),
  },
  '133': {
    gratificationNumber: 133,
    text: 'gratifications.message_133',
    icon: 'message_133',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_133_text_1',
    ),
  },
  '140': {
    gratificationNumber: 140,
    text: 'gratifications.message_140',
    icon: 'message_140',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_140_text_1',
    ),
  },
  '141': {
    gratificationNumber: 141,
    text: 'gratifications.message_141',
    icon: 'message_141',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_141_text_1',
    ),
  },
  '145': {
    gratificationNumber: 145,
    text: 'gratifications.message_145',
    icon: 'message_145',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_145_text_1',
    ),
  },
  '146': {
    gratificationNumber: 146,
    text: 'gratifications.message_146',
    icon: 'message_146',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_146_text_1',
    ),
  },
  '148': {
    gratificationNumber: 148,
    text: 'gratifications.message_148',
    icon: 'message_148',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_148_text_1',
    ),
  },
  '150': {
    gratificationNumber: 150,
    text: 'gratifications.message_150',
    icon: 'message_150',
    type: 'appTip',
  },
  '152': {
    gratificationNumber: 152,
    text: 'gratifications.message_152',
    icon: 'message_152',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_152_text_1',
    ),
  },
  '153': {
    gratificationNumber: 153,
    text: 'gratifications.message_153',
    icon: 'message_153',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_153_text_1',
    ),
  },
  '155': {
    gratificationNumber: 155,
    text: 'gratifications.message_155',
    icon: 'message_155',
    type: 'appTip',
  },
  '156': {
    gratificationNumber: 156,
    text: 'gratifications.message_156',
    icon: 'message_156',
    type: 'appTip',
  },
  '157': {
    gratificationNumber: 157,
    text: 'gratifications.message_157',
    icon: 'message_157',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_157_text_1',
    ),
  },
  '158': {
    gratificationNumber: 158,
    text: 'gratifications.message_158',
    icon: 'message_158',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_158_text_1',
    ),
  },
  '160': {
    gratificationNumber: 160,
    text: 'gratifications.message_160',
    icon: 'message_160',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_160_text_1',
    ),
  },
  '161': {
    gratificationNumber: 161,
    text: 'gratifications.message_161',
    icon: 'message_161',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_161_text_1',
    ),
  },
  '162': {
    gratificationNumber: 162,
    text: 'gratifications.message_162',
    icon: 'message_162',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_162_text_1',
    ),
  },
  '163': {
    gratificationNumber: 163,
    text: 'gratifications.message_163',
    icon: 'message_163',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_163_text_1',
    ),
  },
  '164': {
    gratificationNumber: 164,
    text: 'gratifications.message_164',
    icon: 'message_164',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_164_text_1',
    ),
  },
  '165': {
    gratificationNumber: 165,
    text: 'gratifications.message_165',
    icon: 'message_165',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_165_text_1',
    ),
  },
  '166': {
    gratificationNumber: 166,
    text: 'gratifications.message_166',
    icon: 'message_166',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_166_text_1',
    ),
  },
  '167': {
    gratificationNumber: 167,
    text: 'gratifications.message_167',
    icon: 'message_167',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_167_text_1',
    ),
  },
  '169': {
    gratificationNumber: 169,
    text: 'gratifications.message_169',
    icon: 'message_169',
    type: 'appTip',
  },
  '170': {
    gratificationNumber: 170,
    text: 'gratifications.message_170',
    icon: 'message_170',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_170_text_1',
    ),
  },
  '171': {
    gratificationNumber: 171,
    text: 'gratifications.message_171',
    icon: 'message_171',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_171_text_1',
    ),
  },
  '173': {
    gratificationNumber: 173,
    text: 'gratifications.message_173',
    icon: 'message_173',
    type: 'taxTip',
  },
  '174': {
    gratificationNumber: 174,
    text: 'gratifications.message_174',
    icon: 'message_174',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_174_text_1',
    ),
  },
  '175': {
    gratificationNumber: 175,
    text: 'gratifications.message_175',
    icon: 'message_175',
    type: 'taxTip',
  },
  '176': {
    gratificationNumber: 176,
    text: 'gratifications.message_176',
    icon: 'message_176',
    type: 'taxTip',
  },
  '177': {
    gratificationNumber: 177,
    text: 'gratifications.message_177',
    icon: 'message_177',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_177_text_1',
    ),
  },
  '178': {
    gratificationNumber: 178,
    text: 'gratifications.message_178',
    icon: 'message_178',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_178_text_1',
    ),
  },
  '179': {
    gratificationNumber: 179,
    text: 'gratifications.message_179',
    icon: 'message_179',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_179_text_1',
    ),
  },
  '181': {
    gratificationNumber: 181,
    text: 'gratifications.message_181',
    icon: 'message_181',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_181_text_1',
    ),
  },
  '189': {
    gratificationNumber: 189,
    text: 'gratification_message_189',
    icon: 'message_189',
    type: 'analytics',
  },
  '190': {
    gratificationNumber: 190,
    text: 'gratification_message_190',
    icon: 'message_190',
    type: 'analytics',
  },
  '191': {
    gratificationNumber: 191,
    text: 'gratification_message_191',
    icon: 'message_191',
    type: 'analytics',
  },
  '192': {
    gratificationNumber: 192,
    text: 'gratification_message_192',
    icon: 'message_192',
    type: 'analytics',
  },
  '193': {
    gratificationNumber: 193,
    text: 'gratifications.message_193',
    icon: 'message_193',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_193_text_1',
    ),
  },
  '195': {
    gratificationNumber: 195,
    text: 'gratification_message_195',
    icon: 'message_195',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_195_text_1',
    ),
  },
  '196': {
    gratificationNumber: 196,
    text: 'gratification_message_196',
    icon: 'message_196',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_196_text_1',
    ),
  },
  '198': {
    gratificationNumber: 198,
    text: 'gratification_message_198',
    icon: 'message_198',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_198_text_1',
    ),
  },
  '199': {
    gratificationNumber: 199,
    text: 'gratification_message_199',
    icon: 'message_199',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_199_text_1',
    ),
  },
  '200': {
    gratificationNumber: 200,
    text: 'gratification_message_200',
    icon: 'message_200',
    type: 'appTip',
  },
  '201': {
    gratificationNumber: 201,
    text: 'gratification_message_201',
    icon: 'message_201',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_201_text_1',
    ),
  },
  '202': {
    gratificationNumber: 202,
    text: 'gratification_message_202',
    icon: 'message_202',
    type: 'appTip',
  },
  '204': {
    gratificationNumber: 204,
    text: 'gratifications.message_204',
    icon: 'message_204',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_204_text_1',
    ),
  },
  '205': {
    gratificationNumber: 205,
    text: 'gratifications.message_205',
    icon: 'message_205',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_205_text_1',
    ),
  },
  '206': {
    gratificationNumber: 206,
    text: 'gratifications.message_206',
    icon: 'message_206',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_206_text_1',
    ),
  },
  '207': {
    gratificationNumber: 207,
    text: 'gratifications.message_207',
    icon: 'message_207',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_207_text_1',
    ),
  },
  '208': {
    gratificationNumber: 208,
    text: 'gratifications.message_208',
    icon: 'message_208',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_208_text_1',
    ),
  },
  '210': {
    gratificationNumber: 210,
    text: 'gratifications.message_210',
    icon: 'message_210',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_210_text_1',
    ),
  },
  '211': {
    gratificationNumber: 211,
    text: 'gratifications.message_211',
    icon: 'message_211',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_211_text_1',
    ),
  },
  '212': {
    gratificationNumber: 212,
    text: 'gratifications.message_212',
    icon: 'message_212',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_212_text_1',
    ),
  },
  '213': {
    gratificationNumber: 213,
    text: 'gratifications.message_213',
    icon: 'message_213',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_213_text_1',
    ),
  },
  '214': {
    gratificationNumber: 214,
    text: 'gratifications.message_214',
    icon: 'message_214',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_214_text_1',
    ),
  },
  '215': {
    gratificationNumber: 215,
    text: 'gratifications.message_215',
    icon: 'message_215',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_215_text_1',
    ),
  },
  '216': {
    gratificationNumber: 216,
    text: 'gratifications.message_216',
    icon: 'message_216',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_216_text_1',
    ),
  },
  '217': {
    gratificationNumber: 217,
    text: 'gratifications.message_217',
    icon: 'message_217',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_217_text_1',
    ),
  },
  '218': {
    gratificationNumber: 218,
    text: 'gratifications.message_218',
    icon: 'message_218',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_218_text_1',
    ),
  },
  '219': {
    gratificationNumber: 219,
    text: 'gratifications.message_219',
    icon: 'message_219',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_219_text_1',
    ),
  },
  '220': {
    gratificationNumber: 220,
    text: 'gratifications.message_220',
    icon: 'message_220',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_220_text_1',
    ),
  },
  '221': {
    gratificationNumber: 221,
    text: 'gratifications.message_221',
    icon: 'message_221',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_221_text_1',
    ),
  },
  '222': {
    gratificationNumber: 222,
    text: 'gratifications.message_222',
    icon: 'message_222',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_222_text_1',
    ),
  },
  '223': {
    gratificationNumber: 223,
    text: 'gratifications.message_223',
    icon: 'message_223',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_223_text_1',
    ),
  },
  '224': {
    gratificationNumber: 224,
    text: 'gratifications.message_224',
    icon: 'message_224',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_224_text_1',
    ),
  },
  '225': {
    gratificationNumber: 225,
    text: 'gratifications.message_225',
    icon: 'message_225',
    type: 'pnlTip',
    helperContent: getHelperContentSimpleText(
      'gratifications.message_225_text_1',
    ),
  },
  '230': {
    gratificationNumber: 230,
    text: 'gratification_message_230',
    icon: 'message_230',
    type: 'taxTip',
    helperContent: [
      { type: ContentType.text, text: 'gratification_message_230_text_1' },
      { type: ContentType.title, text: 'gratification_message_230_title_1' },
      { type: ContentType.text, text: 'gratification_message_230_text_2' },
      { type: ContentType.link, text: 'gratification_message_230_link_1' },
      { type: ContentType.text, text: 'gratification_message_230_text_3' },
    ],
  },
  '231': {
    gratificationNumber: 231,
    text: 'gratification_message_231',
    icon: 'message_231',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_231_text_1',
    ),
  },
  '232': {
    gratificationNumber: 232,
    text: 'gratification_message_232',
    icon: 'message_232',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_232_text_1',
    ),
  },
  '233': {
    gratificationNumber: 233,
    text: 'gratification_message_233',
    icon: 'message_233',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_233_text_1',
    ),
  },
  '234': {
    gratificationNumber: 234,
    text: 'gratification_message_234',
    icon: 'message_234',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_234_text_1',
    ),
  },
  '235': {
    gratificationNumber: 235,
    text: 'gratification_message_235',
    icon: 'message_235',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_235_text_1',
    ),
  },
  '236': {
    gratificationNumber: 236,
    text: 'gratification_message_236',
    icon: 'message_236',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_236_text_1',
    ),
  },
  '237': {
    gratificationNumber: 237,
    text: 'gratification_message_237',
    icon: 'message_237',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_237_text_1',
    ),
  },
  '238': {
    gratificationNumber: 238,
    text: 'gratification_message_238',
    icon: 'message_238',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_238_text_1',
    ),
  },
  '239': {
    gratificationNumber: 239,
    text: 'gratification_message_239',
    icon: 'message_239',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_239_text_1',
    ),
  },
  '240': {
    gratificationNumber: 240,
    text: 'gratification_message_240',
    icon: 'message_240',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_240_text_1',
    ),
  },
  '241': {
    gratificationNumber: 241,
    text: 'gratification_message_234',
    icon: 'message_241',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_241_text_1',
    ),
  },
  '242': {
    gratificationNumber: 242,
    text: 'gratification_message_242',
    icon: 'message_242',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_242_text_1',
    ),
  },
  '243': {
    gratificationNumber: 243,
    text: 'gratification_message_243',
    icon: 'message_243',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_243_text_1',
    ),
  },
  '244': {
    gratificationNumber: 244,
    text: 'gratification_message_244',
    icon: 'message_244',
    type: 'taxTip',
  },
  '245': {
    gratificationNumber: 245,
    text: 'gratification_message_245',
    icon: 'message_245',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_245_text_1',
    ),
  },
  '246': {
    gratificationNumber: 246,
    text: 'gratification_message_246',
    icon: 'message_246',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_246_text_1',
    ),
  },
  '247': {
    gratificationNumber: 247,
    text: 'gratification_message_247',
    icon: 'message_247',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_247_text_1',
    ),
  },
  '248': {
    gratificationNumber: 248,
    text: 'gratification_message_248',
    icon: 'message_248',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_248_text_1',
    ),
  },
  '249': {
    gratificationNumber: 249,
    text: 'gratification_message_249',
    icon: 'message_249',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_249_text_1',
    ),
  },
  '250': {
    gratificationNumber: 250,
    text: 'gratification_message_250',
    icon: 'message_250',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_250_text_1',
    ),
  },
  '251': {
    gratificationNumber: 251,
    text: 'gratification_message_251',
    icon: 'message_251',
    type: 'taxTip',
  },
  '252': {
    gratificationNumber: 252,
    text: 'gratification_message_252',
    icon: 'message_252',
    type: 'taxTip',
  },
  '255': {
    gratificationNumber: 255,
    text: 'gratification_message_255',
    icon: 'message_255',
    type: 'appTip',
    helperContent: [
      {
        type: ContentType.text,
        text: 'gratification_message_255_text_1',
      },
      {
        type: ContentType.link,
        text: 'gratification_message_255_link_1',
      },
      {
        type: ContentType.text,
        text: 'gratification_message_255_text_2',
      },
    ],
  },
  '256': {
    gratificationNumber: 256,
    text: 'gratification_message_256',
    icon: 'message_256',
    type: 'appTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_256_text_1',
    ),
  },
  '257': {
    gratificationNumber: 257,
    text: 'gratification_message_257',
    icon: 'message_257',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_257_text_1',
    ),
  },
  '258': {
    gratificationNumber: 258,
    text: 'gratification_message_258',
    icon: 'message_258',
    type: 'appTip',
  },
  '259': {
    gratificationNumber: 259,
    text: 'gratification_message_259',
    icon: 'message_259',
    type: 'taxTip',
    helperContent: [
      { type: ContentType.text, text: 'gratification_message_259_text_1' },
      { type: ContentType.link, text: 'gratification_message_259_link_1' },
    ],
  },
  '264': {
    gratificationNumber: 264,
    text: 'gratification_message_264',
    icon: 'message_264',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_264_text_1',
    ),
  },
  '267': {
    gratificationNumber: 267,
    text: 'gratification_message_267',
    icon: 'message_267',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_267_text_1',
    ),
  },
  '268': {
    gratificationNumber: 268,
    text: 'gratification_message_268',
    icon: 'message_268',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_268_text_1',
    ),
  },
  '269': {
    gratificationNumber: 269,
    text: 'gratification_message_269',
    icon: 'message_269',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_269_text_1',
    ),
  },
  '270': {
    gratificationNumber: 270,
    text: 'gratification_message_270',
    helperTitle: 'gratification_message_270_helper_title_1',
    icon: 'message_271',
    type: 'taxTip',
    helperContent: helperContent270,
  },
  '271': {
    gratificationNumber: 271,
    text: 'gratification_message_271',
    helperTitle: 'gratification_message_271_title_1',
    icon: 'message_271',
    type: 'taxTip',
    helperContent: helperContent271,
  },
  '272': {
    gratificationNumber: 272,
    text: 'gratification_message_272',
    icon: 'message_272',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_272_text_1',
    ),
  },
  '273': {
    gratificationNumber: 273,
    text: 'gratification_message_273',
    icon: 'message_273',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_273_text_1',
    ),
  },
  '274': {
    gratificationNumber: 274,
    text: 'gratification_message_274',
    icon: 'message_274',
    type: 'appTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_274_text_1',
    ),
  },
  '275': {
    gratificationNumber: 275,
    text: 'gratification_message_275',
    icon: 'message_275',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_275_text_1',
    ),
  },
  '279': {
    gratificationNumber: 279,
    text: 'gratification_message_279',
    icon: 'message_279',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_279_text_1',
    ),
  },
  '280': {
    gratificationNumber: 280,
    text: 'gratification_message_280',
    icon: 'message_280',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_280_text_1',
    ),
  },
  '281': {
    gratificationNumber: 281,
    text: 'gratification_message_281',
    icon: 'message_281',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_281_text_1',
    ),
  },
  '282': {
    gratificationNumber: 282,
    text: 'gratification_message_282',
    icon: 'message_282',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_282_text_1',
    ),
  },
  '283': {
    gratificationNumber: 283,
    text: 'gratification_message_283',
    icon: 'message_283',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_283_text_1',
    ),
  },
  '284': {
    gratificationNumber: 284,
    text: 'gratification_message_284',
    icon: 'message_284',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_284_text_1',
    ),
  },
  '286': {
    gratificationNumber: 286,
    text: 'gratification_message_286',
    icon: 'message_286',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_286_text_1',
    ),
  },
  '287': {
    gratificationNumber: 287,
    text: 'gratification_message_287',
    icon: 'message_287',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_287_text_1',
    ),
  },
  '288': {
    gratificationNumber: 288,
    text: 'gratification_message_288',
    icon: 'message_288',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_288_text_1',
    ),
  },
  '290': {
    gratificationNumber: 290,
    text: 'gratification_message_290',
    icon: 'message_290',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_290_text_1',
    ),
  },
  '291': {
    gratificationNumber: 291,
    text: 'gratification_message_291',
    icon: 'message_291',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_291_text_1',
    ),
  },
  '293': {
    gratificationNumber: 293,
    text: 'gratification_message_293',
    icon: 'message_293',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_293_text_1',
    ),
  },
  '294': {
    gratificationNumber: 294,
    text: 'gratification_message_294',
    icon: 'message_294',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_294_text_1',
    ),
  },
  '295': {
    gratificationNumber: 295,
    text: 'gratification_message_295',
    icon: 'message_295',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_295_text_1',
    ),
  },
  '296': {
    gratificationNumber: 296,
    text: 'gratification_message_296',
    icon: 'message_296',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_296_text_1',
    ),
  },
  '297': {
    gratificationNumber: 297,
    text: 'gratification_message_297',
    icon: 'message_297',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_297_text_1',
    ),
  },
  '298': {
    gratificationNumber: 298,
    text: 'gratification_message_298',
    icon: 'message_298',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_298_text_1',
    ),
  },
  '299': {
    gratificationNumber: 299,
    text: 'gratification_message_299',
    icon: 'message_299',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_299_text_1',
    ),
  },
  '300': {
    gratificationNumber: 300,
    text: 'gratification_message_300',
    icon: 'message_300',
    type: 'taxImpact',
  },
  '301': {
    gratificationNumber: 301,
    text: 'gratification_message_301',
    icon: 'message_301',
    type: 'taxImpact',
  },
  '302': {
    gratificationNumber: 302,
    text: 'gratification_message_302',
    icon: 'message_302',
    type: 'taxImpact',
  },
  '303': {
    gratificationNumber: 303,
    text: 'gratification_message_303',
    icon: 'message_303',
    type: 'taxImpact',
  },
  '304': {
    gratificationNumber: 304,
    text: 'gratification_message_304',
    icon: 'message_304',
    type: 'appTip',
  },
  '305': {
    gratificationNumber: 305,
    text: 'gratification_message_305',
    icon: 'message_305',
    type: 'pnlTip',
  },
  '306': {
    gratificationNumber: 306,
    text: 'gratification_message_306',
    icon: 'message_306',
    type: 'appTip',
  },
  '308': {
    gratificationNumber: 308,
    text: 'gratification_message_308',
    icon: 'message_308',
    type: 'appTip',
  },
  '309': {
    gratificationNumber: 309,
    text: 'gratification_message_309',
    icon: 'message_309',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_309_text_1',
    ),
  },
  '310': {
    gratificationNumber: 310,
    text: 'gratification_message_310',
    icon: 'message_310',
    type: 'appTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_310_text_1',
    ),
  },
  '311': {
    gratificationNumber: 311,
    text: 'gratification_message_311',
    icon: 'message_311',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_311_text_1',
    ),
  },
  '312': {
    gratificationNumber: 312,
    text: 'gratification_message_312',
    icon: 'message_312',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_312_text_1',
    ),
  },
  '313': {
    gratificationNumber: 313,
    text: 'gratification_message_313',
    icon: 'message_313',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_313_text_1',
    ),
  },
  '314': {
    gratificationNumber: 314,
    text: 'gratification_message_314',
    icon: 'message_314',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_314_text_1',
    ),
  },
  '315': {
    gratificationNumber: 315,
    text: 'gratification_message_315',
    icon: 'message_315',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_315_text_1',
    ),
  },
  '316': {
    gratificationNumber: 316,
    text: 'gratification_message_316',
    icon: 'message_316',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_316_text_1',
    ),
  },
  '317': {
    gratificationNumber: 317,
    text: 'gratification_message_317',
    icon: 'message_317',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_317_text_1',
    ),
  },
  '318': {
    gratificationNumber: 318,
    text: 'gratification_message_318',
    icon: 'message_318',
    type: 'taxTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_318_text_1',
    ),
  },
  '319': {
    gratificationNumber: 319,
    text: 'gratification_message_319',
    icon: 'message_319',
    type: 'appTip',
    helperContent: getHelperContentSimpleText(
      'gratification_message_319_text_1',
    ),
  },
  '320': {
    gratificationNumber: 320,
    text: 'gratification_message_320',
    icon: 'message_320',
    type: 'taxImpact',
    helperContent: getHelperContentSimpleText(
      'gratification_message_320_text_1',
    ),
  },
  '321': {
    gratificationNumber: 321,
    text: 'gratification_message_321',
    icon: 'message_321',
    type: 'taxImpact',
    helperContent: getHelperContentSimpleText(
      'gratification_message_321_text_1',
    ),
  },
  '322': {
    gratificationNumber: 322,
    text: 'gratification_message_322',
    icon: 'message_322',
    type: 'taxImpact',
    helperContent: getHelperContentSimpleText(
      'gratification_message_322_text_1',
    ),
  },
  '323': {
    gratificationNumber: 323,
    text: 'gratification_message_323',
    icon: 'message_323',
    type: 'taxImpact',
    helperContent: getHelperContentSimpleText(
      'gratification_message_323_text_1',
    ),
  },
  '324': {
    gratificationNumber: 324,
    text: 'gratification_message_324',
    icon: 'message_324',
    type: 'taxImpact',
    helperContent: getHelperContentSimpleText(
      'gratification_message_324_text_1',
    ),
  },
  '325': {
    gratificationNumber: 325,
    text: 'gratification_message_325',
    icon: 'message_325',
    type: 'taxImpact',
    helperContent: getHelperContentSimpleText(
      'gratification_message_325_text_1',
    ),
  },
  '326': {
    gratificationNumber: 326,
    text: 'gratification_message_326',
    icon: 'message_326',
    type: 'taxImpact',
    helperContent: getHelperContentSimpleText(
      'gratification_message_326_text_1',
    ),
  },
  '327': {
    gratificationNumber: 327,
    text: 'gratification_message_327',
    icon: 'message_327',
    type: 'taxImpact',
    helperContent: getHelperContentSimpleText(
      'gratification_message_327_text_1',
    ),
  },
  '328': {
    gratificationNumber: 328,
    text: 'gratification_message_328',
    icon: 'message_328',
    type: 'taxImpact',
    helperContent: getHelperContentSimpleText(
      'gratification_message_328_text_1',
    ),
  },
  '329': {
    gratificationNumber: 329,
    text: 'gratifications.message_329',
    icon: 'message_329',
    type: GratificationTypes.comparison,
    helperContent: [
      ...getHelperContentSimpleText('gratifications.message_329_text_1'),
      professionHelperContent,
    ],
  },
  '330': {
    gratificationNumber: 330,
    text: 'gratifications.message_330',
    icon: 'message_330',
    type: GratificationTypes.comparison,
    helperContent: [
      ...getHelperContentSimpleText('gratifications.message_330_text_1'),
      professionHelperContent,
    ],
  },
  '331': {
    gratificationNumber: 331,
    text: 'gratifications.message_331',
    icon: 'message_331',
    type: GratificationTypes.comparison,
    helperContent: [
      { type: ContentType.text, text: 'gratifications.message_331_text_1' },
      professionHelperContent,
    ],
  },
  '332': {
    gratificationNumber: 332,
    text: 'gratifications.message_332',
    icon: 'message_332',
    type: GratificationTypes.comparison,
    helperContent: [
      ...getHelperContentSimpleText('gratifications.message_332_text_1'),
      professionHelperContent,
    ],
  },
  '333': {
    gratificationNumber: 333,
    text: 'gratifications.message_333',
    icon: 'message_333',
    type: GratificationTypes.comparison,
    helperContent: [
      ...getHelperContentSimpleText('gratifications.message_333_text_1'),
      professionHelperContent,
    ],
  },
  '334': {
    gratificationNumber: 334,
    text: 'gratifications.message_334',
    icon: 'message_334',
    type: GratificationTypes.comparison,
    helperContent: [
      ...getHelperContentSimpleText('gratifications.message_334_text_1'),
      professionHelperContent,
    ],
  },
  '335': {
    gratificationNumber: 335,
    text: 'gratifications.message_335',
    icon: 'message_335',
    type: GratificationTypes.comparison,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_335_text_1',
    ),
  },
  '336': {
    gratificationNumber: 336,
    text: 'gratifications.message_336',
    icon: 'message_336',
    type: GratificationTypes.comparison,
    helperContent: [
      ...getHelperContentSimpleText('gratifications.message_336_text_1'),
      professionHelperContent,
    ],
  },
  '337': {
    gratificationNumber: 337,
    text: 'gratifications.message_337',
    icon: 'message_337',
    type: GratificationTypes.appTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_337_text_1',
    ),
  },
  '338': {
    gratificationNumber: 338,
    text: 'gratifications.message_338',
    icon: 'message_338',
    type: GratificationTypes.comparison,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_338_text_1',
    ),
  },
  '342': {
    gratificationNumber: 342,
    text: 'gratifications.message_342',
    icon: 'message_342',
    type: GratificationTypes.comparison,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_342_text_1',
    ),
  },
  '343': {
    gratificationNumber: 343,
    text: 'gratifications.message_343',
    icon: 'message_343',
    type: GratificationTypes.comparison,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_343_text_1',
    ),
  },
  '344': {
    gratificationNumber: 344,
    text: 'gratifications.message_344',
    icon: 'message_344',
    type: GratificationTypes.comparison,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_344_text_1',
    ),
  },
  '345': {
    gratificationNumber: 345,
    text: 'gratifications.message_345',
    icon: 'message_345',
    type: GratificationTypes.comparison,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_345_text_1',
    ),
  },
  '346': {
    gratificationNumber: 346,
    text: 'gratifications.message_346',
    icon: 'message_346',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_346_text_1',
    ),
  },
  '347': {
    gratificationNumber: 347,
    text: 'gratifications.message_347',
    icon: 'message_347',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_347_text_1',
    ),
  },
  '348': {
    gratificationNumber: 348,
    text: 'gratifications.message_348',
    icon: 'message_348',
    type: GratificationTypes.appTip,
  },
  '351': {
    gratificationNumber: 351,
    text: 'gratifications.message_351',
    icon: 'message_348',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_351_text_1',
    ),
  },
  '354': {
    gratificationNumber: 354,
    text: 'gratifications.message_354',
    icon: 'message_351',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_354_text_1',
    ),
  },
  '355': {
    gratificationNumber: 355,
    text: 'gratifications.message_355',
    icon: 'message_355',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_354_text_1',
    ),
  },
  '356': {
    gratificationNumber: 356,
    text: 'gratifications.message_356',
    icon: 'message_355',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_356_text_1',
    ),
  },
  '357': {
    gratificationNumber: 357,
    text: 'gratifications.message_357',
    icon: 'message_357',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_357_text_1',
    ),
  },
  '358': {
    gratificationNumber: 358,
    text: 'gratifications.message_358',
    icon: 'message_357',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_358_text_1',
    ),
  },
  '359': {
    gratificationNumber: 359,
    text: 'gratifications.message_359',
    icon: 'message_359',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_359_text_1',
    ),
  },
  '360': {
    gratificationNumber: 360,
    text: 'gratifications.message_360',
    icon: 'message_359',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_360_text_1',
    ),
  },
  '361': {
    gratificationNumber: 361,
    text: 'gratifications.message_361',
    icon: 'message_361',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_361_text_1',
    ),
  },
  '362': {
    gratificationNumber: 362,
    text: 'gratifications.message_362',
    icon: 'message_362',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_362_text_1',
    ),
  },
  '363': {
    gratificationNumber: 363,
    text: 'gratifications.message_363',
    icon: 'message_361',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_363_text_1',
    ),
  },
  '364': {
    gratificationNumber: 364,
    text: 'gratifications.message_364',
    icon: 'message_361',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_364_text_1',
    ),
  },
  '365': {
    gratificationNumber: 365,
    text: 'gratifications.message_365',
    icon: 'message_365',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_365_text_1',
    ),
  },
  '366': {
    gratificationNumber: 366,
    text: 'gratifications.message_366',
    icon: 'message_365',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_366_text_1',
    ),
  },
  '367': {
    gratificationNumber: 367,
    text: 'gratifications.message_367',
    icon: 'message_367',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_367_text_1',
    ),
  },
  '368': {
    gratificationNumber: 368,
    text: 'gratifications.message_368',
    icon: 'message_367',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_368_text_1',
    ),
  },
  '369': {
    gratificationNumber: 369,
    text: 'gratifications.message_369',
    icon: 'message_369',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_370_text_1',
    ),
  },
  '371': {
    gratificationNumber: 371,
    text: 'gratifications.message_371',
    icon: 'message_355',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_370_text_1',
    ),
  },
  '370': {
    gratificationNumber: 370,
    text: 'gratifications.message_370',
    icon: 'message_370',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_370_text_1',
    ),
  },
  '376': {
    gratificationNumber: 376,
    text: 'gratifications.message_376',
    icon: 'message_370',
    type: GratificationTypes.taxTip,
    helperContent: getHelperContentSimpleText(
      'gratifications.message_376_text_1',
    ),
  },
};
