import React from 'react';

// export default function parse(
//   children: string,
//   parser: (str: any) => any,
//   key?: number,
// ): string;

export type parse = (
  children: React.ReactNode,
  parser: (str: any) => any,
  key?: number,
) => React.ReactNode;

const parse: parse = (children, parser, key = 0) => {
  if (typeof children === 'string') {
    return parser(children);
  } else if (React.isValidElement(children)) {
    return React.cloneElement(
      children,
      { key: key },
      parse((children.props as any)?.children, parser),
    );
  } else if (Array.isArray(children)) {
    return children.map((child, i) => parse(child, parser, i));
  }
  return children;
};

export default parse;
