import { Modal } from 'components/atoms/Modal';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ExclamationIcon } from '@heroicons/react/solid';
import paymentFailedImageSrc from 'assets/images/payment-failed.png';
import { Button } from 'components/atoms/Button';
import { Title } from 'components/atoms/Title';
import { SizesEnum } from 'components/atoms/Title/Title.enum';
import getIntercom from 'hooks/intercom.hook';
import { useNavigate } from 'react-router';

const PaymentFailure: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showIntercom } = getIntercom();

  return (
    <Modal
      name="payment failure"
      color="expenses"
      trigger={() => null}
      render={() => (
        <div className="flex overflow-hidden">
          <div className="w-8/12 p-32 rounded-l-2xl">
            <div className="flex items-end mb-7" style={{ color: '#FD9651' }}>
              <ExclamationIcon className="h-7 w-7"></ExclamationIcon>
              <p className="text-lg font-bold font-avenir ml-2 tracking-[-1.05px]">
                {t('subscriptions_screen.payment_failed.warning')}
              </p>
            </div>
            <Title type={SizesEnum.h5} className="mb-7">
              {t('subscriptions_screen.payment_failed.title')}
            </Title>
            <div className="w-[80%] text-base font-semibold font-avenir tracking-[0.04px]">
              <p className="mb-4">
                {t('subscriptions_screen.payment_failed.text_1')}
              </p>
              <p className="mb-4">
                {t('subscriptions_screen.payment_failed.text_2')}
              </p>
              <p className="mb-4">
                {t('subscriptions_screen.payment_failed.text_3')}
              </p>
            </div>
            <Button className="my-4" onClick={showIntercom} color="expenses">
              {t('subscriptions_screen.payment_failed.button_text')}
            </Button>
          </div>

          <div className="w-4/12 px-16 bg-expenses-100 rounded-r-2xl">
            <img
              alt="Payment Failure"
              className="absolute top-8 right-0 w-[518px] h-[454px] -ml-24 mt-10 mr-14 self-center"
              src={paymentFailedImageSrc}
            />
          </div>
        </div>
      )}
      defaultOpen
      onClose={() =>
        navigate('/subscriptions', { state: { from: 'paymentFailed' } })
      }
      onBlur={() => null}
      width="6xl"
      dialogPanalClassName="!p-0"
    />
  );
};

export default PaymentFailure;
