import type { FC } from 'react';

const Mixed: FC<any> = ({ fill, ...props }) => (
  <svg viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 9a1 1 0 011 1v4a1 1 0 01-1 1H1a1 1 0 01-1-1v-4a1 1 0 011-1h8zm12 0a1 1 0 011 1v4a1 1 0 01-1 1h-8a1 1 0 01-1-1v-4a1 1 0 011-1h8zM8 10H2a1 1 0 00-.993.883L1 11v2a1 1 0 00.883.993L2 14h6a1 1 0 00.993-.883L9 13v-2a1 1 0 00-1-1zm12 0h-6a1 1 0 00-.993.883L13 11v2a1 1 0 00.883.993L14 14h6a1 1 0 00.993-.883L21 13v-2a1 1 0 00-1-1zM9 0a1 1 0 011 1v4a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1h8zm12 0a1 1 0 011 1v4a1 1 0 01-1 1h-8a1 1 0 01-1-1V1a1 1 0 011-1h8zM8 1H2a1 1 0 00-.993.883L1 2v2a1 1 0 00.883.993L2 5h6a1 1 0 00.993-.883L9 4V2a1 1 0 00-1-1zm12 0h-6a1 1 0 00-.993.883L13 2v2a1 1 0 00.883.993L14 5h6a1 1 0 00.993-.883L21 4V2a1 1 0 00-1-1z"
      fill={fill}
    />
  </svg>
);

export default Mixed;
