import i18n from 'i18n';
import get from 'lodash/get';
import type { ITax } from 'types/taxes.types';

const getTitleSocialContributionsSubject = (tax: Partial<ITax>): string =>
  i18n.t('taxes.social_contributions_quarter_year', {
    quarter: get(tax, 'period.quarter'),
    year: get(tax, 'period.year'),
  });

export default getTitleSocialContributionsSubject;
