import type { NavigateOptions } from 'react-router';
import { useNavigate } from 'react-router';
import useGetCustomNavigationLink from './useGetCustomNavigationLink';

type Options = NavigateOptions & {
  asDefault?: boolean;
};

const useCustomNavigate = (): ((url: string, options?: Options) => void) => {
  const navigate = useNavigate();
  const getLink = useGetCustomNavigationLink();

  return (url: string, options?: Options) => {
    const { asDefault, ..._options } = options || {};
    return navigate(asDefault ? url : getLink(url), _options);
  };
};

export default useCustomNavigate;
