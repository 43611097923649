import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import VATCells from 'components/molecules/VATCells.component';
import type { UseInvoiceVATCellsOptions } from 'hooks/Taxes/useVATCells';
import { useInvoiceVATCells } from 'hooks/Taxes/useVATCells';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

const RevenueVATCells: FC<
  UseInvoiceVATCellsOptions & { className?: string }
> = (props) => {
  const { t } = useTranslation();
  const { data, isFetching, isError, refetch } = useInvoiceVATCells(props);

  return (
    <div className={props.className || ''}>
      <div className="flex items-center gap-2">
        <p className="first-letter:capitalize whitespace-pre-line text-primary-700 text-sm mb-1">
          {t('tax_impact.vat_cells')}
        </p>
        {isFetching && <Loader />}
      </div>
      {data ? <VATCells data={data} /> : null}
      {data && !data.length && props.enabled && (
        <p className="text-sm text-primary-400">{t('vatCells.no_impact')}</p>
      )}
      {!props.enabled ? (
        <p className="text-sm text-primary-400">
          {t('tax_impact.action_sheet.error', {
            documentType: t('invoice'),
          })}
        </p>
      ) : null}
      {isError && (
        <div className="flex flex-wrap items-center gap-2">
          <span className="text-sm text-primary-400">
            {t('backend.error.something_went_wrong')}.
          </span>
          <Button structure="text" color="primary" onClick={() => refetch()}>
            <span className="text-sm">{t('retry')}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default RevenueVATCells;
