import useAllRevenues from 'components/pages/Revenues/useAllRevenues';
import useRevnuesToReview from 'components/pages/Revenues/useRevnuesToReview';
import { useEffect } from 'react';
import { World } from 'types/shared/worlds';
import useInvalidateResources from './useInvalidateResources';
import usePusher from './usePusher';

const useRevenuesFromEmail = () => {
  const channel = usePusher();
  const toReview = useRevnuesToReview({ enabled: false });
  const allRevenues = useAllRevenues({ enabled: false });
  const invalidateResources = useInvalidateResources();

  useEffect(() => {
    if (!channel) return;

    const handler = () => {
      toReview.controllers.reset();
      allRevenues.controllers.reset();
      invalidateResources(World.REVENUES);
    };

    channel.bind('revenues.createFromFile', handler);
    return () => {
      channel.unbind('revenues.createFromFile', handler);
    };
  }, [channel, toReview.controllers.reset]);
};

export default useRevenuesFromEmail;
