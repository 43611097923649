import create from 'zustand';

interface TaxOnboardingState {
  completedTaxOnboarding: boolean;
  setCompletedTaxOnboarding: (state: boolean) => void;
}

const taxOnboardingStoreCreator = () =>
  create<TaxOnboardingState>((set) => ({
    completedTaxOnboarding:
      localStorage.getItem('completedTaxOnboarding') === 'true',
    setCompletedTaxOnboarding: (completedTaxOnboarding: boolean) => {
      localStorage.setItem(
        'completedTaxOnboarding',
        String(completedTaxOnboarding),
      );

      return set({ completedTaxOnboarding });
    },
  }));

export default taxOnboardingStoreCreator;
