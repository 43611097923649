import classNames from 'classnames';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router';
import type { NavbarItemType } from 'types/navbar.types';
import {
  SidebarNavigationItemBGStyles,
  SideBarNavigationItemIconStyles,
  SidebarNavigationItemSelectedBGStyles,
  SideBarNavigationItemSelectedIconStyles,
  SidebarNavigationItemSelectedTextStyles,
  SidebarNavigationItemTextStyles,
} from './types';

const NavigationItem: FC<{
  item: NavbarItemType;
  className?: string;
  active?: boolean;
  titleContainerClassName?: string;
}> = ({ item, active, titleContainerClassName = '', className = '' }) => {
  const { title, renderTitle, to, onClick, Icon, type, children } = item;

  const { t } = useTranslation();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const isActive =
    active ||
    to === pathname ||
    Boolean(children?.some((child) => matchPath(child, pathname)));

  return (
    <div className="my-2 w-full px-2 group">
      <button
        onClick={(e) => {
          onClick?.();

          if (!to) return;

          // dont re-navigate to the current route
          // makes things easier when dealing with forms with id as search param
          // as it will trigger the router to re-navigate which will trigger an alert if the navigation is blocked
          if (to === pathname) e.preventDefault();

          return navigate(to as string);
        }}
        type="button"
        className={classNames(
          'flex flex-1 items-center text-left relative px-4 py-2 w-full rounded-lg',
          isActive
            ? SidebarNavigationItemBGStyles[type]
            : (SidebarNavigationItemSelectedBGStyles as any)[type] ||
                'bg-transparent',
          className,
        )}
      >
        <div className="relative mr-2 flex items-center justify-center">
          <Icon
            className={classNames(
              'h-7',
              isActive
                ? SideBarNavigationItemIconStyles[type]
                : (SideBarNavigationItemSelectedIconStyles as any)[type] ||
                    'text-primary opacity-40 group-hover:opacity-30 transition-all duration-300',
            )}
          />

          {item.BadgeComponent ? (
            <div className="absolute -right-2 -bottom-2">
              <item.BadgeComponent isActive={isActive} />
            </div>
          ) : null}
        </div>

        <span
          className={classNames(
            'text-[0.9rem] text-left font-semibold',
            isActive
              ? SidebarNavigationItemTextStyles[type]
              : (SidebarNavigationItemSelectedTextStyles as any)[type] ||
                  'text-primary-500 group-hover:text-primary-300 transition-colors',
            titleContainerClassName,
          )}
        >
          {renderTitle?.() || t(title)}
        </span>
      </button>
    </div>
  );
};

export default NavigationItem;
