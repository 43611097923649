// unique partial or full cache keys

export enum ExpensesCacheKeys {
  HasExpenses = 'Expenses:HasExpenses',
  ExpensesFilters = 'Expenses:Filters',
  ExpensesToReview = 'Expenses:ToReview',
  ExpensesReviewed = 'Expenses:Reviewed',
  AllExpenses = 'Expenses:AllExpenses',
  CurrentYearDeductibleAmount = 'Expenses:CurrentYearDeductibleAmount',
  CurrentPeriodDeductibleAmount = 'Expenses:CurrentPeriodDeductibleAmount',
  CategoriesPicker = 'Expenses:CategoriesPicker',
  ExpenseDetails = 'Expenses:ExpenseDetails',
  ExpensesSuggestedTransaction = 'Expenses:SuggestedTransaction',
  ExpenseVATTable = 'Expenses:VATTable',
  ExpenseVATCells = 'Expenses:VATCells',
  ExpensesVehiclesWithoutPurchaseDate = 'Expenses:VehiclesWithoutPurchaseDate',
  ExpenseWorkingDaysAndDeductibleAmount = 'Expenses:WorkingDaysAndDeductibleAmount',
  HomeTodoReviewedExpenses = 'Expenses:HomeTodoReviewedExpenses',
  FirstExpense = 'Expenses:FirstExpense',
  TaxLock = 'Expenses:TaxLock',
  ExpensesCategoriesGuessed = 'Expenses:CategoriesGuessed',
}

export enum TaxesCacheKeys {
  TaxesFilters = 'Taxes:Filters',
  Taxes = 'Taxes',
  StatementBreakdown = 'StatementBreakdown',
  Submissions = 'Submissions',
  GetHebesatz = 'GetHebezats',
  Municipalities = 'municipalities',
  ClosedPeriods = 'Taxes:ClosedPeriods',
  VATPeriods = 'Taxes:VATPeriods',
  TaxImpact = 'Taxes:TaxImpact',
  InitialTaxTips = 'Taxes:InitialTaxTips',
  TaxTips = 'Taxes:TaxTips',
}

export enum TaxesValidationCacheKeys {
  ApplicableExpenses = 'TAXES:ApplicableExpesnses',
  UnvalidatedExpenses = 'TAXES:UnvalidatedExpenses',
  WithoutDocumentsExpenses = 'TAXES:WithoutDocumentsExpenses',
  AlreadySubmittedExpenses = 'TAXES:AlreadySubmittedExpenses',
  FakeExpenses = 'TAXES:FakeExpenses',
  ApplicableRevenues = 'TAXES:ApplicableRevenues',
  UnvalidatedRevenues = 'TAXES:UnvalidatedRevenues',
  UnapplicableRevenues = 'TAXES:UnapplicableRevenues',
  AlreadySubmittedRevenues = 'TAXES:AlreadySubmittedRevenues',
}

export enum SelectOptionsCacheKeys {
  Async = 'SelectOptions:Async',
  AsyncPaginated = 'SelectOptions:AsyncPaginated',
}

export enum FilesCacheKeys {
  FileURL = 'Files:FileURL',
  S3FileData = 'Files:S3FileData',
}

export enum UserCacheKeys {
  UserData = 'User:UserData',
  CustomersList = 'Customers:CustomersList',
  CustomerData = 'Customers:CustomerData',
  ValidateSteuernummer = 'User:ValidateSteuernummer',
  UserFinanzamt = 'User:UserFinanzamt',
  UserAssets = 'User:UserAssets',
  UserAccountants = 'User.Accountants',
  UserAccessRequests = 'User.AccessRequests',
  AuthenticationMethods = 'User:AuthenticationMethods',
}

export enum RevenuesCacheKeys {
  HasRevenues = 'Revenues:HasRevenues',
  RevenuesFilters = 'Revenues:Filters',
  RevenuesToReview = 'Revenues:ToReview',
  RevenuesReviewed = 'Revenues:Reviewed',
  AllRevenues = 'Revenues:AllRevenues',
  PrimaryRevenueTab = 'Revenues:PrimaryRevenueTab',
  RevenuesClients = 'Revenues:Clients',
  RecurringRevenues = 'Revenues:Recurring',
  RevenuesClientById = 'Revenues:ClientById',
  RevenuesQuotes = 'Revenues:Quotes',
  QuotesFilters = 'Revenues:QuotesFilters',
  HasQuotes = 'Revenues:HasQuotes',
  RevenuesClientsSearch = 'Revenues:ClientsSearch',
  CurrentYearIncomeAmount = 'Revenues:CurrentYearIncomeAmount',
  CurrentPeriodIncomeAmount = 'Revenues:CurrentPeriodIncomeAmount',
  RevenuesSuggestedTransaction = 'Revenues:SuggestedTransaction',
  AnnualNetIncome = 'Revenues:AnnualNetIncome',
  MonthlyNetIncome = 'Revenues:MonthlyNetIncome',
  RevenueById = 'Revenues:RevenueById',
  RecurringRevenueById = 'Revenues:RecurringRevenueById',
  CategoriesPicker = 'Revenues:CategoriesPicker',
  RevenuesVATTable = 'Revenues:VATTable',
  RevenuesVATCells = 'Revenues:VATCells',
  CurrencyRate = 'Revenues:CurrencyRate',
  SuggestedItems = 'Revenues:SuggestedItems',
  CustomUnits = 'Revenues:CustomUnits',
  CalculateItems = 'Revenues:CalculateItems',
  LatestRevenues = 'Revenues:LatestRevenues',
  RevenueClientFieldOptions = 'Revenues:RevenueClientFieldOptions',
  RevenueDetails = 'Revenues:RevenueDetails',
  QuoteDetails = 'Revenues:QuoteDetails',
  HomeTodoReviewedRevenues = 'Revenues:HomeTodoReviewedRevenues',
  FirstRevenue = 'Revenues:FirstRevenue',
  TaxLock = 'Revenues:TaxLock',
  GermanCarComputedItems = 'Revenues:GermanCarComputedItems',
  RecurringList = 'Revenues:RecurringList',
  RecurringJob = 'Revenues:RecurringJob',
  RevenueLogs = 'Revenues:RevenueLogs',
  Communication = 'Revenues:Communication',
  QRCode = 'Revenues:QRCode',
  RevenuesItemsDropdown = 'Revenues:RevenuesItemsDropdown',
  ClientById = 'Revenues:ClientById',
  ItemsCalculations = 'Revenues:ItemsCalculations',
  GermanCarPrivateUseCategory = 'Revenues:GermanCarPrivateUseCategory',
  RecurringClients = 'Revenues:RecurringClients',
}

export enum BankTransactionsCacheKeys {
  BankTransactionsFilters = 'BankTransactions:Filters',
  HasTransactions = 'BankTransactions:HasTransactions',
  Connectors = 'BankTransactions:Connectors',
  SettingsConnectors = 'BankTransactions:SettingsConnectors',
  LinkingExpenses = 'BankTransactions:LinkingExpenses',
  LinkingCreditNotesOnPurchase = 'BankTransactions:LinkingCreditNotesOnPurchase',
  LinkingRevenue = 'BankTransactions:LinkingRevenue',
  LinkingCreditNotesOnSale = 'BankTransactions:LinkingCreditNotesOnSale',
  CashAvailableFactors = 'BankTransactions:CashAvailableFactors',
  LinkedRevenues = 'BankTransactions:LinkedRevenues',
  LinkedExpenses = 'BankTransactions:LinkedExpenses',
  TransactionById = 'BankTransactions:TransactionById',
  TransactionsList = 'BankTransactions:TransactionsList',
  PaymentLinkedList = 'BankTransactions:PaymentLinkedList',
  SuggestedClassification = 'BankTransactions:SuggestedClassification',
  TransactionDetails = 'BankTransactions:TransactionDetails',
  HomeClassifiedTransactionsQuery = 'BankTransactions:HomeClassifiedTransactionsQuery',
  CreditCardStatementsList = 'BankTransactions:CreditCardStatementsList',
  CreditCardStatement = 'BankTransactions:CreditCardStatement',
  CreditCardStatementTransactions = 'BankTransactions:CreditCardStatementTransactions',
  CreditCardStatementCSVFileData = 'BankTransactions:CreditCardStatementCSVFileData',
}

export enum BanksCacheKeys {
  BEBanks = 'Banks:BEBanks',
  BANK_TAX_TIPS = 'Banks:BANK_TAX_TIPS',
}

export enum DataExportCacheKeys {
  HasTransactions = 'DataExport:HasTransactions',
  ExportedDataFile = 'DataExport:ExportedDataFile',
}

export enum QRCodeKeys {
  PaidList = 'QRCodeKeys:PaidList',
}

export enum FilesUrls {
  FileUrl = 'FilesUrls:FileUrl',
  FileBlob = 'FilesUrls:FileBlob',
}

export enum VehiclesCacheKeys {
  VehiclesList = 'Vehicles:VehiclesList',
  Vehicle = 'Vehicles:Vehicle',
}

export enum CountriesCacheKeys {
  CountriesList = 'Countries:CountriesList',
}

export enum AnalyticsCacheKeys {
  AnalyticsMetric = 'Analytics:Metric',
}

export enum MFACacheKey {
  getMFA = 'MFA:Get',
  newTOTP = 'MFA:NewTOTP',
  verifyTOTP = 'MFA:VerifyTOTP',
  disableTOTP = 'MFA:DisableTOTP',
}

export enum SignupCacheKeys {
  Enterprises = 'Signup:Enterprises',
  SuggestedOccupation = 'Signup:SuggestedOccupation',
}

export enum SubscriptionsCacheKeys {
  SubscriptionPlans = 'Subscriptions:SubscriptionPlans',
}

export enum FontsCacheKeys {
  Font = 'Fonts:Font',
}

export enum AmortizationCacheKeys {
  CategorisedAssets = 'Amortization:CategorisedAssets',
  Asset = 'Amortization:Asset',
  Periods = 'Amortization:Periods',
  Items = 'Amortization:Items',
}

export enum YearlyReviewCacheKeys {
  YearlyReviewData = 'YearlyReview:YearlyReviewData',
}

export enum IntegrationsCacheKeys {
  PeppolIntegration = 'Integrations:PeppolIntegration',
  PeppolIntegrationById = 'Integrations:PeppolIntegrationById',
  ClientPeppolIntegrationStatus = 'Integrations:ClientPeppolIntegrationStatus',
}

export enum OtherCacheKeys {
  ItemsCalculations = 'Other:ItemsCalculations',
}

export enum AICacheKeys {
  ConversationMessages = 'AI:ConversationMessages',
  ConversationUsage = 'AI:ConversationUsage',
  ConversationsList = 'AI:ConversationList',
}
