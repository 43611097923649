import { Switch } from '@headlessui/react';
import { RefreshIcon, XIcon } from '@heroicons/react/outline';
import GearIcon from 'assets/svg/icons/gearIcon';
import { Button } from 'components/atoms/Button';
import { Select } from 'components/atoms/Form/Select';
import { Title } from 'components/atoms/Title';
import { SizesEnum } from 'components/atoms/Title/Title.enum';
import { Tooltip } from 'components/atoms/Tooltip';
import { accountableAPI } from 'config';
import { motion } from 'framer-motion';
import { useGrowthABExperimentsState } from 'hooks/useAssignGrowthABGroups';
import type { FC } from 'react';
import { useState } from 'react';
import colors from 'theme/colors';
import { ABTest } from 'types/growthExperiments.types';
import { isWeb } from 'utils/constants';
import { ExclamationCircleIconOutline } from 'utils/icons';

const GrowthExperiments: FC = () => {
  const { experiments, setExperiments } = useGrowthABExperimentsState();
  const [open, setOpen] = useState(false);

  const [envName, setEnvName] = useState(
    accountableAPI.baseUrl?.split('https://')?.[1].split('.')[0],
  );

  const switchEnv = (env: string) => {
    const prefix = env === 'production' ? 'https://' : `https://${env}.`;

    localStorage.setItem(
      'customENV',
      JSON.stringify({
        baseUrl: `${prefix}app.accountable.eu/api`,
        filesUrl: `${prefix}files.accountable.eu`,
        redirectUrl: `${prefix}accountable.app.link/user_access`,
      }),
    );
    window.location.reload();
  };
  const isDifferentLink = envName && !window.location.href?.includes(envName);

  return (
    <div className="flex">
      <Tooltip content="Growth Experiments" placement="left">
        <button
          className="h-8 w-12 rounded-l-full z-[500] bg-white shadow-filters flex justify-center items-center fill-current group transition-colors fixed bottom-3 right-0"
          onClick={() => setOpen((prev) => !prev)}
          type="button"
        >
          {open ? (
            <XIcon className="text-revenues h-5 transition-colors" />
          ) : (
            <GearIcon stroke={colors.revenues.DEFAULT} />
          )}
          {isDifferentLink ? (
            <span className="text-xs bg-yellow-500 px-0.5 rounded-[4px] text-black capitalize font-bold">
              {envName?.slice(0, 2)}
            </span>
          ) : null}
        </button>
      </Tooltip>

      <motion.div
        className="w-5/12 min-h-[40vh] max-h-[70vh] z-[200] overflow-y-auto bg-white shadow-filters fixed right-12 bottom-4 rounded-10 p-10"
        initial={{ scale: 0 }}
        animate={{ scale: open ? 1 : 0 }}
      >
        <div className="mb-6">
          <div className="flex items-center mb-4 gap-6">
            <Title type={SizesEnum.h6}>Switch backend server</Title>
            <Button
              structure="text"
              className=""
              onClick={() => {
                localStorage.removeItem('customENV');
                window.location.reload();
              }}
            >
              <RefreshIcon className="w-4 h-4" />
              <span className="pl-2">Reset default</span>
            </Button>
          </div>
          <div className="w-[70%]">
            <Select
              name="env"
              onChange={(env) => {
                setEnvName(env?.id);
                switchEnv(env?.id);
              }}
              value={envName}
              options={[
                {
                  id: 'neon',
                  label: 'Neon',
                  checked: envName === 'neon',
                },
                {
                  id: 'staging',
                  label: 'Staging',
                  checked: envName === 'staging',
                },
                {
                  id: 'helium',
                  label: 'Helium',
                  checked: envName === 'helium',
                },
                {
                  id: 'hydrogen',
                  label: 'Hydrogen',
                  checked: envName === 'hydrogen',
                },
                {
                  id: 'carbon',
                  label: 'Carbon',
                  checked: envName === 'carbon',
                },
                {
                  id: 'production',
                  label: 'Production',
                  checked: envName === 'app',
                  InfoComponent: () => (
                    <ExclamationCircleIconOutline className="w-4 h-4 ml-1 text-red-500" />
                  ),
                },
              ]}
            />
          </div>
        </div>

        {isWeb ? (
          <>
            <Title type={SizesEnum.h6}>Growth Experiments</Title>
            {Object.entries(experiments)
              .filter(([key]) => !key.includes('INTERCOM_'))
              .map(([key, value]) => {
                const enabled = value === ABTest.B;
                const experimentName = key?.replace(/_/g, ' ')?.toLowerCase();

                return (
                  <div className="mt-6 p-6 bg-[#f8f8f8] rounded-10" key={key}>
                    <div className="flex justify-between">
                      <span className="capitalize">{experimentName}</span>

                      <div className="flex items-center">
                        <span className="text-main">{ABTest.A}</span>
                        <Switch
                          checked={enabled}
                          onChange={(checked: boolean) =>
                            setExperiments({
                              ...experiments,
                              [key]: checked ? ABTest.B : ABTest.A,
                            })
                          }
                          className={`${
                            enabled ? 'bg-bank-900' : 'bg-revenues-900'
                          } relative inline-flex h-6 w-11 items-center rounded-full mx-2`}
                        >
                          <span className="sr-only">{experimentName}</span>
                          <span
                            className={`${
                              enabled ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                          />
                        </Switch>
                        <span className="text-main">{ABTest.B}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        ) : null}
        {/* <Title type={SizesEnum.h6} className="mt-10">
          Helpers
        </Title>
        <TestGratif className="mt-6" /> */}
      </motion.div>
    </div>
  );
};

export default GrowthExperiments;
