import { joiResolver } from '@hookform/resolvers/joi';
import { updateAsset } from 'api/v1/Assets';
import { getRevenueById, getRevenues, updateRevenue } from 'api/v2/revenues';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import DatePicker from 'components/atoms/DatePicker/DatePicker.component';
import { FieldConnector } from 'components/atoms/Form/FieldConnector';
import { Select } from 'components/atoms/Form/Select';
import { Menu } from 'components/atoms/Menu';
import {
  cleanInvoiceValues,
  formatInvoiceValues,
} from 'components/pages/Revenues/OtherRevenueForm/utils';
import dayjs from 'dayjs';
import useConfirm from 'hooks/useConfirm';
import useCustomNavigate from 'hooks/useCustomeNavigate';
import useFeatures from 'hooks/useFeatures';
import useModal from 'hooks/useModal';
import Joi from 'joi';
import type { FC } from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { AssetStatus, type Asset } from 'types/assets';
import { AmortizationCacheKeys } from 'types/cacheKeys.types';
import type { Currency } from 'types/global.types';
import formatMoney from 'utils/formatMoney';
import {
  CheckIconOutline,
  DotsVerticalIcon,
  EyeIconOutline,
  PlusIcon,
  TrashIconOutline,
} from 'utils/icons';
import { divideBy1000 } from 'utils/math';
import type { AssetRowProps } from './AssetRow.component';
import AssetRow from './AssetRow.component';

type CommonRowPropsType = {
  onAssetUpdate: () => void;
  onViewExpense: (id: string) => void;
};

type SelectLostDateProps = {
  onSubmit: (date: string) => any;
};

type ItemData = {
  value: string;
  label: string;
  totalAmountInclVAT: number;
  invoiceDate: string;
  invoiceId: string;
  currency: Currency;
  revenueNumber: string;
};

type MarkAsSoldFormProps = {
  data: Asset;
  onSubmit: (date: ItemData) => void;
};

const SelectLostDate: FC<SelectLostDateProps> = (props) => {
  const { t } = useTranslation();
  const formAPI = useForm({
    resolver: joiResolver(
      Joi.object({
        date: Joi.date().required().messages({ '*': 'date.required' }),
      }),
    ),
  });

  const isLoading = formAPI.formState.isSubmitting;

  return (
    <FormProvider {...formAPI}>
      <p className="text-primary-700 leading-tight mb-8">
        {t('settings.amortization.mark_as_lost.text')}
      </p>
      <form
        onSubmit={formAPI.handleSubmit(({ date }) => {
          return props.onSubmit(
            dayjs(date).set('hour', 12).startOf('hour').toISOString(),
          );
        })}
      >
        <FieldConnector name="date">
          {({ field: { value, onChange, ...field } }) => (
            <DatePicker
              {...field}
              selected={value}
              onChange={(date) => {
                const value = date ? dayjs(date).format('YYYY-MM-DD') : null;
                onChange(value);
              }}
            />
          )}
        </FieldConnector>
        <Button type="submit" className="mt-5" loading={isLoading}>
          {t('confirm')}
        </Button>
      </form>
    </FormProvider>
  );
};

const ItemOption: FC<{
  data: ItemData;
  className?: string;
  isSelected?: boolean;
}> = ({ data, className, isSelected }) => {
  const dateFormat = useFeatures('formats.date');
  return (
    <div
      id={'_' + data.value}
      className={classNames('flex flex-col gap-1 p-4', className)}
    >
      <div className="flex gap-4 justify-between text-primary">
        <div className="flex gap-1 items-center">
          {isSelected && <CheckIconOutline className="w-5 h-5" />}
          <span className="text-sm font-semibold font-assistant truncate">
            {data.label}
          </span>
        </div>
        <span className="text-sm font-semibold flex-shrink-0">
          {formatMoney(divideBy1000(data.totalAmountInclVAT), data.currency)}
        </span>
      </div>
      <div className="flex justify-between">
        <span className="text-sm text-primary-400">{data.revenueNumber}</span>
        <span className="text-sm text-primary-400">
          {dayjs(data.invoiceDate).format(dateFormat)}
        </span>
      </div>
    </div>
  );
};

const MarkAsSoldForm: FC<MarkAsSoldFormProps> = (props) => {
  const { t } = useTranslation();

  const navigate = useCustomNavigate();

  const [selectedItem, setSelectedItem] = useState<ItemData>();

  const { data, isLoading, isError, refetch } = useQuery(
    AmortizationCacheKeys.Items,
    () =>
      getRevenues({
        isValidated: true,
        page: 1,
        perPage: 99999,
        fields: ['items', 'invoiceDate', '_id', 'currency', 'revenueNumber'],
      }).then(({ data }) => {
        return data.flatMap((invoice) =>
          invoice.items
            .filter((item) => !item.assetId)
            .map(
              (item) =>
                ({
                  value: item._id,
                  label: item.name,
                  totalAmountInclVAT: item.totalAmountInclVAT,
                  invoiceDate: invoice.invoiceDate,
                  invoiceId: invoice._id,
                  currency: invoice.currency,
                  revenueNumber: invoice.revenueNumber,
                } as ItemData),
            ),
        );
      }),
    { staleTime: 0 },
  );

  return (
    <div className="min-h-[500px] px-4">
      <div className="flex justify-between items-start gap-4 my-8">
        <div>
          <h1 className="text-main text-4xl font-avenir font-bold">
            {t('settings.amortization.mark_as_sold.title')}
          </h1>
          <p className="text-primary-700 leading-tight">
            {t('settings.amortization.mark_as_sold.description')}
          </p>
        </div>
        <Button
          structure="secondary"
          icon={PlusIcon}
          className="flex-shrink-0"
          onClick={() => {
            navigate('/revenues/create', {
              state: {
                guessedDocument: {
                  items: [
                    {
                      name: props.data.name,
                      assetId: props.data._id,
                      unitAmountExclVAT: 0,
                      quantity: 1000,
                      unit: 'items',
                    },
                  ],
                },
              },
            });
          }}
        >
          {t('settings.amortization.mark_as_sold.create_sales_invoice')}
        </Button>
      </div>

      {/* {selectedItem && (
        <ItemOption
          data={selectedItem}
          className="bg-primary-25 mb-4 rounded-lg"
        />
      )} */}

      <Select
        isLoading={isLoading}
        isError={isError}
        retry={() => refetch()}
        name="item"
        options={data}
        menuIsOpen
        isSearchable
        classNamePrefix="flat-dropdown"
        placeholder={t(
          'settings.amortization.mark_as_sold.search_items_by_name',
        )}
        controlShouldRenderValue={false}
        // isClearable
        // value={selectedItem?.value}
        // hideSelectedOptions
        onChange={(option: ItemData) => {
          setSelectedItem(option);
          props.onSubmit(option);
        }}
        themeOptions={{
          hideDropdownIndicator: true,
          hideClearButton: true,
        }}
        formatOptionLabel={(option, meta) => {
          if (meta.context === 'value') return option.label;
          return <ItemOption data={option} className="border-b" />;
        }}
      />
    </div>
  );
};

const AssetThreeDotsMenu: FC<{
  data: Asset;
  onViewExpense: (id: string) => void;
}> = ({ data, onViewExpense }) => {
  const { t } = useTranslation();

  const [content, api] = useConfirm();

  return (
    <>
      {content}
      <Menu
        position="topLeft"
        contentWrapperClassName="mb-10"
        trigger={({ open }) => (
          <Button
            asDefault
            className={classNames(
              'text-primary-700 hover:shadow focus:shadow p-2 rounded-lg pointer-curser',
              { shadow: open },
            )}
          >
            <DotsVerticalIcon className="w-5 h-5" />
          </Button>
        )}
      >
        <Menu.Group>
          <Menu.Button
            onClick={() => onViewExpense(data.expense._id)}
            extendClassNames="flex gap-2 items-center"
            baseTextColorClass="text-primary-700"
          >
            <EyeIconOutline className="w-5 h-5" />
            {t('settings.amortization.actions.view_expense')}
          </Menu.Button>
          <Menu.Button
            onClick={() => {
              api.open({
                okText: t('settings.amortization.actions.delete_asset.ok_text'),
                message: t('settings.amortization.actions.delete_asset.text'),
                onConfirm: () => onViewExpense(data.expense._id),
                className: 'text-center font-semibold',
                buttonsWrapperClassName: 'justify-center',
              });
            }}
            extendClassNames="flex gap-2 items-center"
            baseTextColorClass="text-red-500"
          >
            <TrashIconOutline className="w-5 h-5" />
            {t('settings.amortization.actions.delete_asset')}
          </Menu.Button>
        </Menu.Group>
      </Menu>
    </>
  );
};

export const AssetUnderAmortizationRow: FC<
  AssetRowProps & CommonRowPropsType
> = (props) => {
  const { data: asset } = props;
  const { t } = useTranslation();

  const [lostModal, lostModalAPI] = useModal({
    width: 'lg',
    name: 'lost / broken asset date',
  });

  const [soldModal, soldModalAPI] = useModal({
    width: '3xl',
    name: 'mark as sold',
  });

  const markAsLost = useMutation(
    (date: string) =>
      updateAsset({
        ...asset,
        invoice: null,
        status: AssetStatus.LOST,
        writeOffDate: date,
      }),
    {
      onSuccess: () => {
        props.onAssetUpdate();
      },
    },
  );

  const markAsSold = useMutation(
    (itemData: ItemData) =>
      getRevenueById(itemData.invoiceId).then((invoice) => {
        return updateRevenue(itemData.invoiceId, {
          ...formatInvoiceValues(
            cleanInvoiceValues({
              ...invoice,
              items: invoice.items.map((item) => {
                if (item._id === itemData.value) {
                  return { ...item, assetId: asset._id };
                }
                return item;
              }),
            }),
          ),
          user: invoice.userSnapshot,
          settings: invoice.userSnapshot.settings?.invoices,
        });
      }),
    {
      onSuccess: () => {
        props.onAssetUpdate();
      },
    },
  );

  return (
    <>
      {lostModal}
      {soldModal}
      <AssetRow
        data={asset}
        defaultExpanded={props.defaultExpanded}
        renderControllers={() =>
          props.hideDefaultControllers ? null : (
            <div className="flex items-center gap-4 justify-end">
              <Button
                structure="secondary"
                disabled={markAsSold.isLoading}
                loading={markAsLost.isLoading}
                onClick={() => {
                  lostModalAPI.open(() => {
                    return (
                      <SelectLostDate
                        onSubmit={(date) => {
                          return markAsLost.mutateAsync(date).then(() => {
                            lostModalAPI.close();
                          });
                        }}
                      />
                    );
                  });
                }}
              >
                {t('settings.amortization.actions.mark_as_lost')}
              </Button>
              <Button
                disabled={markAsLost.isLoading}
                loading={markAsSold.isLoading}
                structure="primary"
                onClick={() => {
                  soldModalAPI.open((api) => (
                    <MarkAsSoldForm
                      data={asset}
                      onSubmit={(itemData) => {
                        markAsSold.mutate(itemData);
                        api.closeModal();
                      }}
                    />
                  ));
                }}
              >
                {t('settings.amortization.actions.mark_as_sold')}
              </Button>
              <AssetThreeDotsMenu
                data={asset}
                onViewExpense={props.onViewExpense}
              />
            </div>
          )
        }
      />
    </>
  );
};

export const AssetLostRow: FC<AssetRowProps & CommonRowPropsType> = (props) => {
  const { data: asset } = props;
  const { t } = useTranslation();

  const unMark = useMutation(
    () =>
      updateAsset({
        ...asset,
        invoice: null,
        status: null,
        writeOffDate: null,
      }),
    {
      onSuccess: () => {
        props.onAssetUpdate();
      },
    },
  );

  return (
    <AssetRow
      data={asset}
      renderControllers={() => (
        <div className="flex items-center gap-4 justify-end">
          <Button
            onClick={() => {
              unMark.mutate();
            }}
            loading={unMark.isLoading}
          >
            {t('settings.amortization.actions.unmark_as_lost')}
          </Button>
          <AssetThreeDotsMenu
            data={asset}
            onViewExpense={props.onViewExpense}
          />
        </div>
      )}
    />
  );
};

export const AssetSoldRow: FC<
  AssetRowProps & CommonRowPropsType & { onViewInvoice: (id: string) => void }
> = (props) => {
  const { data: asset } = props;
  const { t } = useTranslation();
  const [confirmContent, confirmAPI] = useConfirm();

  return (
    <>
      {confirmContent}
      <AssetRow
        data={asset}
        shouldHideStillToAmortizeCol
        renderControllers={() => (
          <div className="flex items-center gap-4 justify-end">
            <Button
              onClick={() => {
                confirmAPI.open({
                  okText: t('settings.amortization.unmark_as_sold.ok_text'),
                  message: t('settings.amortization.unmark_as_sold.text'),
                  onConfirm: () =>
                    props.onViewInvoice(asset.invoice?._id as string),
                  className: 'text-center font-semibold',
                  buttonsWrapperClassName: 'justify-center',
                });
              }}
            >
              {t('settings.amortization.actions.unmark_as_sold')}
            </Button>
            <AssetThreeDotsMenu
              data={asset}
              onViewExpense={props.onViewExpense}
            />
          </div>
        )}
      />
    </>
  );
};
