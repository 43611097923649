import VariantSelection from 'components/molecules/Fields/VariantSelection';
// import useFeatures from 'hooks/useFeatures';
import type { FC } from 'react';
import { useMemo } from 'react';
import { round } from 'utils/math';
import { useVATRatesGetter } from '../utils';
import type { FieldProps } from './commonFieldProps.types';

type LocalVATRateProps = FieldProps & {
  expenseDate?: string;
};

const LocalVATRate: FC<LocalVATRateProps> = ({
  onChange,
  value,
  expenseDate,
  ...rest
}) => {
  // const defaultVATRate = useFeatures('expenses.default_vat_rate');
  const VATRates = useVATRatesGetter()({ expenseDate });

  const VATRatePickerOptions = useMemo(
    () =>
      VATRates.map((rate) => ({
        label: `${round(rate * 100)}%`,
        value: rate,
      })),
    [VATRates],
  );

  return (
    <div className="flex gap-4">
      <VariantSelection
        value={value}
        onChange={(value) => {
          // if (value > defaultVATRate) return onChange( parseFloat((value as number).toFixed(2)));
          onChange(value);
        }}
        options={VATRatePickerOptions}
        {...rest}
      />
    </div>
  );
};

export default LocalVATRate;
