import type { FC } from 'react';
import type { SelectConfig } from '../select.types';

const withAsyncSearch = (SelectComponent: FC<SelectConfig>) =>
  function WithAsyncSearch(props: SelectConfig) {
    const newProps: Partial<SelectConfig> = {
      isSearchable: true,
      filterOption: null,
      refetchOnSearchChange: true,
    };

    return <SelectComponent {...props} {...newProps} />;
  };

export default withAsyncSearch;
