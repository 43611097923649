import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import useWorldTheme from 'hooks/shared/useWorldTheme';
import type { FC, ReactNode } from 'react';
import type { IColors } from 'theme/colors';

type MenuProps = {
  menuItems: { id: string; path: string[]; title: string; icon?: ReactNode }[];
  activePath: string[];
  onPathChange: (path: string[]) => void;
};

const selectedClasses: Partial<Record<IColors, string>> = {
  expenses: 'bg-expenses-900 shadow-[0px_0px_0px_0.25rem] shadow-expenses-200',
  revenues: 'bg-revenues-900 shadow-[0px_0px_0px_0.25rem] shadow-revenues-200',
  primary: 'bg-primary-900 shadow-[0px_0px_0px_0.25rem] shadow-primary-200',
};

const focusClasses: Partial<Record<IColors, string>> = {
  expenses: 'hover:shadow-expenses-200 focus-visible:shadow-expenses-200',
  revenues: 'hover:shadow-revenues-200 focus-visible:shadow-revenues-200',
  primary: 'hover:shadow-primary-200 focus-visible:shadow-primary-200',
};

const Menu: FC<MenuProps> = ({ menuItems, activePath, onPathChange }) => {
  const color = useWorldTheme();
  return (
    <>
      {menuItems.map((item) => {
        const isInActivePath = activePath.includes(item.id);
        return (
          <Button
            asDefault
            key={item.id}
            onClick={() => onPathChange(item.path)}
            className={classNames(
              'transition-all',
              focusClasses[color] || focusClasses.primary,
              'hover:shadow-[0px_0px_0px_0.25rem] focus-visible:shadow-[0px_0px_0px_0.25rem]',
              'block w-full text-left bg-white rounded-full',
              {
                [selectedClasses[color] || selectedClasses.primary || '']:
                  isInActivePath,
              },
            )}
          >
            <div className={classNames('flex items-center justify-between')}>
              <div className="flex items-center gap-4 p-2.5">
                <span className="border border-primary-100 bg-white rounded-full w-12 h-12 relative flex-shrink-0">
                  <span className="abs-center">
                    {typeof item.icon === 'string' ? (
                      <img
                        alt=""
                        src={item.icon}
                        className="w-6 h-6 object-contain"
                      />
                    ) : (
                      item.icon
                    )}
                  </span>
                </span>
                <span
                  className={classNames(
                    isInActivePath ? 'text-white' : 'text-primary',
                    'font-semibold text-sm leading-tight',
                  )}
                >
                  {item.title}
                </span>
              </div>
            </div>
          </Button>
        );
      })}
    </>
  );
};

export default Menu;
