import { getVehicles, updateVehicle } from 'api/v1/vehicles';
import { updateExpenseById } from 'api/v2/expenses';
import CarOutline from 'assets/svg/icons/CarOutline';
import { Button } from 'components/atoms/Button';
import { VehicleForm } from 'components/pages/Expenses/Form/Fields/Vehicle/helpers';
import useModal from 'hooks/useModal';
import { get } from 'lodash';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import type { IExpense } from 'types/expenses.types';
import type { CheckComponentProps } from 'types/taxes.types';
import { ChevronRightIcon } from 'utils/icons';
import CheckExpensesList from '../CheckExpensesList';
import CheckUITemplate from '../CheckUITemplate';

const ExpenseVehicleACPCodeMismatch: FC<CheckComponentProps> = (props) => {
  const { checkData } = props;
  const { t } = useTranslation();
  const { data: vehicles } = useQuery(['vehicles'], () =>
    getVehicles({ perPage: 1000, page: 1 }),
  );
  const [vehicleFormModal, vehicleFormModalApi] = useModal({
    dialogClassName: '!p-0',
    color: 'expenses',
    name: '',
  });

  return (
    <CheckUITemplate
      {...props}
      renderContent={() => (
        <div className="my-4">
          <div className="text-primary-700 text-lg font-semibold">
            {t('taxes.checks.expense.vehicle_acp_code_mismatch.action_1')}
          </div>
          <div className="my-4">
            {vehicles?.data?.length
              ? vehicles?.data
                  // filtering vehicles that have expenses with issues
                  ?.filter((vehicle) =>
                    checkData?.data?.some((exp: IExpense) =>
                      exp.items?.some?.(
                        (item) => get(item, 'vehicle._id') === vehicle._id,
                      ),
                    ),
                  )
                  ?.map((vehicle) => {
                    return (
                      <Button
                        asDefault
                        key={vehicle._id}
                        onClick={(e) => {
                          e.stopPropagation();
                          vehicleFormModalApi.open(
                            (api) => (
                              <VehicleForm
                                defaultValues={vehicle}
                                onSubmit={(values: any) => {
                                  return updateVehicle(vehicle._id, values)
                                    .then(() =>
                                      Promise.all(
                                        checkData?.data
                                          // filtering expenses associated with that vehicle
                                          ?.filter?.((exp: IExpense) =>
                                            exp?.items?.some(
                                              (item) =>
                                                get(item, 'vehicle._id') ===
                                                vehicle._id,
                                            ),
                                          )
                                          ?.map(async (expense: IExpense) => {
                                            updateExpenseById(expense?._id, {
                                              ...expense,
                                              user: expense.userSnapshot,
                                            });
                                          }),
                                      ),
                                    )
                                    .then(() =>
                                      setTimeout(
                                        () => props.refetchCheck(),
                                        1000,
                                      ),
                                    )
                                    .then(api.closeModal);
                                }}
                              />
                            ),
                            { name: 'edit vehicle' },
                          );
                        }}
                        className="w-full flex justify-between items-center border border-primary-100 rounded-10 my-2 px-4 py-4"
                      >
                        <div className="flex items-center gap-4">
                          <span className="flex w-12 h-12 justify-center items-center bg-taxes-100 rounded-full">
                            <CarOutline />
                          </span>
                          <span className="flex flex-col">
                            <span>{vehicle?.name}</span>
                            <span>{vehicle?.registrationYear}</span>
                          </span>
                        </div>
                        <ChevronRightIcon className="w-8 h-8 text-primary-400" />
                      </Button>
                    );
                  })
              : null}
          </div>
          {vehicleFormModal}

          {/* <div className="text-primary-700 text-lg font-semibold">
            {t('taxes.checks.expense.vehicle_acp_code_mismatch.action_2')}
          </div> */}
          <div className="text-primary-700 text-lg font-semibold mb-4">
            {t('taxes.checks.expense.vehicle_acp_code_mismatch.subtitle')}
          </div>
          <CheckExpensesList {...{ list: get(checkData, 'data'), ...props }} />
        </div>
      )}
    />
  );
};

export default ExpenseVehicleACPCodeMismatch;
