import { components, Select } from 'components/atoms/Form/Select';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import colors from 'theme/colors';
import type { AvailableCountries } from 'types/users.types';
import { ChevronRightIconOutline } from 'utils/icons';
import type { FieldProps } from '../commonFieldProps.types';
import {
  getOptionsSets,
  getSupplierCountries,
  SupplierCountriesListFooter,
} from './helpers';

const Menu: FC<any> = ({ children, ...props }) => (
  <components.Menu {...props}>
    {children}
    <SupplierCountriesListFooter />
  </components.Menu>
);

const SupplierCountry: FC<FieldProps & { highlightID?: string }> = ({
  onChange,
  highlightID,
  ...props
}) => {
  const customerCountry = useCustomerCountry() as AvailableCountries;
  const supplierCountryOptions = useMemo(
    () => getSupplierCountries(customerCountry),
    [customerCountry],
  );

  const optionsSets = getOptionsSets(customerCountry);

  const selected = useMemo(() => {
    if (!props.value) return props.value;
    return supplierCountryOptions.find(
      (option) => option.value === props.value,
    );
  }, [supplierCountryOptions, props.value]);

  const [options, setOptions] = useState(supplierCountryOptions);

  const [menuIsOpen, setIsmenuIsOpen] = useState(false);

  const [isQAMenu, setIsQAMenu] = useState(false);

  return (
    <Select
      {...props}
      isSearchable={false}
      isDisabled={props.disabled}
      options={options}
      placeholder=""
      onMenuOpen={() => {
        setIsmenuIsOpen(true);
      }}
      onBlur={() => {
        setIsmenuIsOpen(false);
        props.onBlur();
      }}
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      menuIsOpen={menuIsOpen}
      highlightID={highlightID}
      themeOptions={{
        shouldOptionsShowSelectedIndicator: false,
      }}
      formatOptionLabel={(option: any, meta) => {
        if (meta.context === 'value') return option.icon || selected?.icon;
        return (
          <div className="flex gap-2 items-center justify-between">
            <div className="flex gap-3 items-center">
              {option.icon ? <span>{option.icon}</span> : null}
              <span>{option.label}</span>
            </div>
            <span className="flex-shrink-0">
              {option.optionsSetKey ? (
                <ChevronRightIconOutline className="w-4 text-primary-400" />
              ) : null}
              {option.subLabel ? (
                <span className="text-sm text-primary-400">
                  {option.subLabel}
                </span>
              ) : null}
            </span>
          </div>
        );
      }}
      onChange={(option: any) => {
        if (option.optionsSetKey) {
          setIsQAMenu(true);
          return setOptions(optionsSets[option.optionsSetKey]);
        }
        setIsQAMenu(false);
        setIsmenuIsOpen(false);
        setOptions(supplierCountryOptions);
        onChange(option.value);
      }}
      components={isQAMenu ? {} : { Menu }}
      styles={{
        container: (provided) => ({
          ...provided,
          position: 'static',
          pointerEvents: 'auto',
        }),
        group: (provided) => ({
          ...provided,
          padding: 0,
        }),
        groupHeading: (provided) => ({
          ...provided,
          color: colors.primary[700],
          fontWeight: 600,
          fontSize: '1rem',
          'line-height': '1.5rem',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
          backgroundColor: colors.primary[25],
          textTransform: undefined,
        }),
        menuList: (provided) => ({
          ...provided,
          padding: 0,
        }),
      }}
    />
  );
};

export default SupplierCountry;
