import { Select } from 'components/atoms/Form/Select';
import useModal from 'hooks/useModal';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { maximumAmortizationPeriod } from 'utils/constants';
import { isNumeric } from 'utils/math';
import AmortizationPeriodModalContent from '../../AmortizationPeriodModalContent';
import type { FieldProps } from '../commonFieldProps.types';
import {
  getAmortizationOptionLabel,
  getAmortizationOptions,
  getRecommendedAmortizationDurationInYears,
} from './helpers';

const AmortizationPeriod: FC<FieldProps & { category: any }> = ({
  onChange,
  value,
  category,
  ...props
}) => {
  const { t } = useTranslation();
  const [modalContent, modalApi] = useModal({
    width: '5xl',
    dialogPanalClassName: '!p-0',
    name: '',
  });

  const currentValueInYears = value ? value / 12 : 0;
  const RecommendedValueInYears =
    getRecommendedAmortizationDurationInYears(category);

  return (
    <>
      {modalContent}
      <Select
        {...props}
        isDisabled={props.disabled}
        options={getAmortizationOptions(category)}
        creatable
        formatOnInputChange={(value: string) => {
          const parsed = parseInt(value);
          if (isNaN(parsed)) return null;
          return parsed + '';
        }}
        // formatOnInputFocus={(value: string) => {
        //   return value;
        // }}
        placeholder=""
        formatOptionLabel={(option: any, actionMeta) => {
          const label = t(getAmortizationOptionLabel(+option.value), {
            years: Math.floor(option.value),
          });
          if (actionMeta.context === 'value') return label;
          return (
            <div className="flex items-center justify-between w-full">
              {label}
              {option.isRecommended && (
                <span className="text-expenses text-xs font-bold">
                  {t('suggested')}
                </span>
              )}
            </div>
          );
        }}
        value={currentValueInYears}
        onChange={(option: any) => {
          if (!isNumeric(option?.value)) return onChange(0);
          onChange(+option.value * 12);

          if (
            RecommendedValueInYears &&
            option.value > RecommendedValueInYears &&
            option.value < maximumAmortizationPeriod
          ) {
            modalApi.open(
              (api) => (
                <AmortizationPeriodModalContent
                  currentValue={option.value}
                  recomendedValue={RecommendedValueInYears}
                  onChange={(valueInYears: number) => {
                    onChange(valueInYears * 12);
                    api.closeModal();
                  }}
                />
              ),
              { name: 'amortization details' },
            );
          }
        }}
      />
    </>
  );
};

export default AmortizationPeriod;
