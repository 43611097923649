import { useDropzone } from 'react-dropzone';

const useCustomDropzone: typeof useDropzone = (options) => {
  return useDropzone({
    ...options,

    // very impotant to set useFsAccessApi to false
    // when chrome has a pending browser update, clicking on upload fields wont work and the file system API is bugged and causes lag
    // we can fix the lag by telling the library to use the classic way of simulating a click on input type=file,
    // instead of using the file system API
    useFsAccessApi: false,
  });
};
export default useCustomDropzone;
