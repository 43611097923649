import * as Sentry from '@sentry/react';
import type { Paging } from 'types/pagination.types';
import type { TransactionAsOption } from 'types/transactions.types';
import { createSentryCustomErrorMsg } from 'utils/helpers';
import transactionToOption from 'utils/transactionToOption';
import getTransactions from './getTransactions';

type Response = {
  data: TransactionAsOption[];
  paging: Paging;
};

const getTransactionsOptions = (value: any, page: any): Promise<Response> =>
  getTransactions({ page, text: value, perPage: 50 }).then(
    ({ data, paging }) => {
      return {
        data: data
          .filter((t) => {
            if (!t) {
              Sentry.captureException({
                message: createSentryCustomErrorMsg(
                  'null_transactions_in_payment_link_field',
                ),
              });
            }
            return !!t;
          })
          .map(transactionToOption),
        paging,
      };
    },
  );

export default getTransactionsOptions;
