import create from 'zustand';

export enum SidebarDocCountsKeysEnum {
  NewExpensesCount = 'NewExpensesCount',
  NewRevenuesCount = 'NewRevenuesCount',
}

const sidebarState = () =>
  create<{
    state: Record<string, any>;
    setState: (next: Record<string, any>) => void;
  }>((set) => ({
    state: {} as Record<string, any>,
    setState: (next: Record<string, any>) =>
      set(({ state }) => Object.assign(state, next)),
  }));

export default sidebarState;
