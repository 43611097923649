import axiosInstance from 'api/axiosInstance';

export type ClientData = {
  NACECodes: {
    main: number[];
    secondary: any[];
  };
  VATNumber: string;
  city: string;
  name: string;
  street: string;
  zipcode: string;
};

export const searchEntrepriseClientsByName = (
  name: string,
  country: string,
): Promise<ClientData[]> => {
  return axiosInstance.get('/v1/search-entreprises', {
    params: { name, country },
  });
};

export const searchEntrepriseClientsByVATNumber = (
  VATNumber: string,
  country: string,
): Promise<ClientData[]> => {
  return axiosInstance.get('/v1/search-entreprises', {
    params: { VATNumber, country },
  });
};
