import {
  assignVehicleToExistingExpenses,
  canAssignVehicleToOtherExpenses,
  createVehicle,
  deleteVehicle,
  getVehiclesOptions,
  updateVehicle,
} from 'api/v1/vehicles';
import { Button } from 'components/atoms/Button';
import { Select } from 'components/atoms/Form/Select';
import type { SelectProps } from 'components/atoms/Form/Select/select.types';
import { Tooltip } from 'components/atoms/Tooltip';
import useConfirm from 'hooks/useConfirm';
import useFeatures from 'hooks/useFeatures';
import useModal from 'hooks/useModal';
import { useId, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import type { BEVehicle, VehiclesOption } from 'types/vehicle.types';
import { PencilIcon, TrashIcon } from 'utils/icons';
import { VehicleForm } from './helpers';

interface VehicleFieldProps extends SelectProps {
  onAddVehicle?: (vehicle: VehiclesOption) => void;
  onEditVehicle?: (vehicle: VehiclesOption) => void;
  onDeleteVehicle?: (vehicle: VehiclesOption) => void;
  expenseId?: string;
}

const VehicleField: FC<VehicleFieldProps> = (props) => {
  const { t } = useTranslation();
  const vehicleCategoryIds = useFeatures('expenses.vehicleCategoryIds');
  const [vehicleFormModal, vehicleFormModalApi] = useModal({
    dialogPanalClassName: '!p-0',
    color: 'expenses',
    name: '',
  });

  const [confirmationContent, confirmationApi] = useConfirm();
  const queryClient = useQueryClient();
  const cacheKey = useId();
  const {
    isError,
    isLoading,
    data: options = [],
    refetch,
  } = useQuery(cacheKey, () => getVehiclesOptions(), {
    cacheTime: 0,
  });

  const addNewVehicle = (vehicle: BEVehicle) => {
    const newVehcileOption = {
      label: vehicle.name,
      value: vehicle._id,
      vehicle,
    };
    queryClient.setQueryData(cacheKey, [newVehcileOption].concat(options));
    props.onAddVehicle?.(newVehcileOption);
    return vehicle;
  };

  const editVehicle = (vehicle: BEVehicle) => {
    const updatedVehcileOption = {
      label: vehicle.name,
      value: vehicle._id,
      vehicle,
    };
    queryClient.setQueryData(
      cacheKey,
      options.map((option) =>
        option.value === updatedVehcileOption.value
          ? updatedVehcileOption
          : option,
      ),
    );
    props.onEditVehicle?.(updatedVehcileOption);
    return vehicle;
  };

  const deleteVehicleOption = (vehicle: BEVehicle) => {
    const deletedVehcileOption = {
      label: vehicle.name,
      value: vehicle._id,
      vehicle,
    };
    queryClient.setQueryData(
      cacheKey,
      options.filter((option) => option.value !== vehicle._id),
    );
    props.onDeleteVehicle?.(deletedVehcileOption);
  };

  const ADD_VEHICLE = '__ADD_VEHICLE__';

  if (isLoading || isError || !!options.length) {
    return (
      <>
        <Select
          {...props}
          options={[
            { label: t('expense.add_vehicle'), value: ADD_VEHICLE },
          ].concat(options)}
          onChange={(option: any, actionMeta) => {
            if (option.value === ADD_VEHICLE) {
              return vehicleFormModalApi.open(
                (modalApi) => (
                  <VehicleForm
                    onSubmit={(values: any) => {
                      return createVehicle(values)
                        .then((vehicle) => {
                          if (vehicle) return addNewVehicle(vehicle);
                          throw new Error(
                            'something went wrong - creating vehicle',
                          );
                        })
                        .then(modalApi.closeModal);
                    }}
                  />
                ),
                { name: 'add vehicle' },
              );
            }
            props.onChange?.(option, actionMeta);
          }}
          isLoading={isLoading}
          isDisabled={isLoading || props.isDisabled}
          isError={isError}
          retry={() => refetch()}
          placeholder={t('expense.vehicle_field.placeholder')}
          formatOptionLabel={(option: any, meta) => {
            if (meta.context === 'value') return option.label;
            if (option.value === ADD_VEHICLE)
              return (
                <Button
                  structure="text"
                  size="xl"
                  className="w-full"
                  color="expenses"
                >
                  {option.label}
                </Button>
              );
            return (
              <div className="flex flex-1 justify-between item-center">
                <span>{option.label}</span>
                <div className="flex gap-2">
                  <Tooltip content={t('vehicle.edit')}>
                    <Button
                      color="expenses"
                      structure="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        vehicleFormModalApi.open(
                          (api) => (
                            <VehicleForm
                              defaultValues={option.vehicle}
                              onSubmit={(values: any) => {
                                return updateVehicle(values._id, values)
                                  .then(editVehicle)
                                  .then(api.closeModal);
                              }}
                            />
                          ),
                          { name: 'edit vehicle' },
                        );
                      }}
                    >
                      <PencilIcon className="w-5" />
                    </Button>
                  </Tooltip>
                  <Tooltip content={t('vehicle.delete')} placement="topRight">
                    <Button
                      color="expenses"
                      structure="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        return confirmationApi.open({
                          message: (
                            <>
                              <p className="font-semibold">
                                {t('vehicle.delete_warning')}
                              </p>
                              <p className="text-primary-700 mb-6">
                                - {option.label}
                              </p>
                            </>
                          ),
                          onConfirm: () =>
                            deleteVehicle(option.value).then(() => {
                              deleteVehicleOption(option.vehicle);
                              confirmationApi.close();
                            }),
                        });
                      }}
                    >
                      <TrashIcon className="w-5" />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            );
          }}
        />
        {vehicleFormModal}
        {confirmationContent}
      </>
    );
  }

  return (
    <>
      <Button
        size="xl"
        structure="text"
        onClick={() =>
          vehicleFormModalApi.open(
            (modalApi) => (
              <VehicleForm
                onSubmit={(values: any) => {
                  return createVehicle(values)
                    .then(addNewVehicle)
                    .then((vehicle) => {
                      return canAssignVehicleToOtherExpenses(
                        vehicleCategoryIds,
                        props.expenseId,
                      ).then((canAssign) => {
                        if (!canAssign) return modalApi.closeModal();
                        return confirmationApi.open({
                          message: t('expense.first_vehicle_warning'),
                          onDecline: () => {
                            confirmationApi.close();
                            modalApi.closeModal();
                          },
                          onConfirm: () =>
                            assignVehicleToExistingExpenses(vehicle._id),
                        });
                      });
                    });
                }}
              />
            ),
            { name: 'edit vehicle' },
          )
        }
      >
        {t('expense.add_vehicle')}
      </Button>
      {vehicleFormModal}
      {confirmationContent}
    </>
  );
};

export default VehicleField;
