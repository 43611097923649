import type { DependencyList, EffectCallback, FC, ReactNode } from 'react';
import { useEffect } from 'react';

type UseEffectProps = {
  deps?: DependencyList;
  effect: EffectCallback;
  render: () => ReactNode;
};

const UseEffect: FC<UseEffectProps> = (props) => {
  useEffect(props.effect, props.deps);
  return <>{props.render()}</>;
};

export default UseEffect;
