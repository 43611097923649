import classNames from 'classnames';
import useWorldTheme from 'hooks/shared/useWorldTheme';
import { forwardRef } from 'react';
import { getTracingEvents } from 'utils/amplitude';
import type { IconButtonProps } from './IconButton.types';
import { customButtonBgColorTypeMap } from './IconButton.types';

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon: CustomIconTag,
      onClick,
      buttonType,
      style,
      className,
      heightClass,
      disabled,
      tracingEvent,
    },
    ref,
  ) => {
    const color = useWorldTheme();
    const tracingEvents = getTracingEvents(
      tracingEvent ? { onClick: tracingEvent } : {},
      { onClick },
    );
    const { backgroundColor } =
      customButtonBgColorTypeMap[buttonType || color || 'default'];

    return (
      <button
        type="button"
        onClick={disabled ? undefined : tracingEvents.onClick}
        disabled={disabled}
        style={style}
        className={classNames(
          'flex justify-center items-center',
          'rounded-full  w-10 h-10',
          'disabled:transition-none disabled:bg-neutral disabled:text-white',
          'disabled:text-opacity-50 disabled:cursor-not-allowed',
          `${backgroundColor}`,
          className,
          heightClass,
        )}
        ref={ref}
      >
        <CustomIconTag />
      </button>
    );
  },
);
IconButton.displayName = 'IconButton';

export default IconButton;
