interface Options {
  rateLimit: number;
  bucketSize: number;
}

type Job<T> = () => T;

// RateLimiter limits the number of actions per second regardless of much time
// does each action takes. It implements a leaky bucket algorithm that can
// handle spikes of requests without a latency, but throttles continued usage.
export default class RateLimiter<T> extends EventTarget {
  private jobsQueue: Job<T>[] = [];
  private bucketStorage = 0;
  private leakingTimerId: number | NodeJS.Timer | undefined;

  constructor(private options: Options) {
    super();
  }

  schedule(job: Job<T>): void {
    this.jobsQueue.push(job);
    this.processNextJob();
  }

  clearQueue(): void {
    this.jobsQueue = [];
  }

  private processNextJob() {
    // Start the timer if it is not running
    if (!this.leakingTimerId) this.startLeakingTimer();

    // Run job immediately if bucket storage still has room
    if (this.bucketStorage < this.options.bucketSize) {
      const willBeEmpty = this.jobsQueue.length == 1;

      const job = this.jobsQueue.shift();
      if (job) this.run(job);

      // Send an event that the queue is empty
      if (willBeEmpty) this.dispatchEvent(new Event('empty'));
    }
  }

  private async run(job: Job<T>) {
    this.bucketStorage += 1;
    job();
  }

  private startLeakingTimer() {
    const interval = 1000 / this.options.rateLimit;

    this.leakingTimerId = setInterval(() => this.tick(), interval);
  }

  private tick() {
    this.bucketStorage -= 1;
    if (this.jobsQueue.length) this.processNextJob();

    // Stop leaking timer for now when there are no more jobs to process.
    if (this.bucketStorage == 0 && this.leakingTimerId) {
      clearInterval(this.leakingTimerId as number);
      this.leakingTimerId = undefined;
    }
  }
}
