import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import getIntercom from 'hooks/intercom.hook';
import type { FC } from 'react';

const SimpleError: FC<{
  className?: string;
  messageClassName?: string;
  onRetry: () => void;
}> = ({ className, messageClassName, onRetry }) => {
  const { showIntercom } = getIntercom();
  return (
    <div
      className={classNames(
        className,
        'flex justify-center h-full flex-col items-center',
      )}
    >
      <p
        className={classNames(
          messageClassName,
          'font-avenir text-main font-bold first-letter:capitalize tracking-tight leading-normal text-2xl',
        )}
      >
        Oops, looks like something went wrong . . .
      </p>
      <div className="mt-4 w-full flex items-center justify-center gap-3 flex-wrap">
        <Button className="w-36" onClick={() => onRetry()}>
          {'Retry'}
        </Button>
        <Button className="w-36" color="primary" onClick={showIntercom}>
          {'Contact support'}
        </Button>
      </div>
    </div>
  );
};

export default SimpleError;
