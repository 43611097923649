import * as Sentry from '@sentry/react';
import { getUserData as getExpertUserData } from 'api/v2/experts/authentication';
import { getUserData as getWebUserData } from 'api/v2/users/authentication';
import { product } from 'config';
import i18n from 'i18n';
import isNil from 'lodash/isNil';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { UserCacheKeys } from 'types/cacheKeys.types';
import type { IUser } from 'types/users.types';
import { getStoredAuthData } from 'utils/auth';
import { isExpert } from 'utils/constants';
import { indexedLanguages } from 'utils/languages';

// This query hook is responsible for fetching the user data if the user is logged-in (if there's an access_token)
// Initially, this query will get filled with user data received on the login request for the first time the user
// logs-in. And on subsequent reloads of the app, the data will be fetched from the server.
const useGetLoggedInUserData = (
  afterFetch?: () => void,
): UseQueryResult<IUser, unknown> => {
  const getUserData = product === 'web' ? getWebUserData : getExpertUserData;

  return useQuery<IUser>([UserCacheKeys.UserData], getUserData, {
    enabled: !!getStoredAuthData('access_token'),
    onSuccess: (user) => {
      afterFetch?.();
      Sentry.setUser({ id: user?._id, email: user?.email });
      const currentLocale = i18n.language;
      const userLanguage = isExpert
        ? user?.language
        : indexedLanguages[user?.language];

      if (!isNil(user?.language) && currentLocale !== userLanguage) {
        i18n.changeLanguage(userLanguage as string);
      }
    },
  });
};

export default useGetLoggedInUserData;
