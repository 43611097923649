import { AvailableCountries } from 'types/users.types';

export type PlanFeatureType = {
  content: string;
};

export const getPlanFeatures = (
  userCountry?: AvailableCountries,
): Record<
  string,
  {
    features: (PlanFeatureType | null)[];
  }
> => ({
  basic: {
    features: [
      {
        content: 'subscriptions_screen.features.mobile_access',
      },
      {
        content: 'subscriptions_screen.features.unlimited_docs',
      },
      { content: 'subscriptions_screen.features.one_bank_account' },
      {
        content: 'subscriptions_screen.features.accountant_access',
      },
    ],
  },
  free: {
    features: [
      {
        content: 'subscriptions_screen.features.mobile_access',
      },
      {
        content: 'subscriptions_screen.features.five_expenses',
      },
      { content: 'subscriptions_screen.features.one_bank_account' },
      {
        content: 'subscriptions_screen.features.excel_export',
      },
    ],
  },
  pro: {
    features: [
      {
        content: 'subscriptions_screen.features.mobile_desktop_access',
      },
      userCountry === AvailableCountries.belgium
        ? {
            content: 'subscriptions_screen.features.taxes_guaranteed',
          }
        : null,
      {
        content: 'subscriptions_screen.features.unlimited_docs',
      },
      {
        content: 'subscriptions_screen.features.five_bank_accounts',
      },
      {
        content: 'subscriptions_screen.features.accountant_access',
      },
      {
        content: 'subscriptions_screen.features.customer_service',
      },
    ],
  },
  small: {
    features: [
      {
        content: 'subscriptions_screen.features.mobile_desktop_access',
      },
      userCountry === AvailableCountries.belgium
        ? {
            content: 'subscriptions_screen.features.taxes_guaranteed',
          }
        : null,
      {
        content: 'subscriptions_screen.features.unlimited_docs',
      },
      {
        content: 'subscriptions_screen.features.five_bank_accounts',
      },
      {
        content: 'subscriptions_screen.features.accountant_access',
      },
      {
        content: 'subscriptions_screen.features.customer_service',
      },
    ],
  },
});
