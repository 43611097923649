import { AccountTypeShortEnum } from 'applications/web/pages/UserSignup/Shared/helpers';
import dayjs from 'dayjs';
import { get, isNumber } from 'lodash';
import _get from 'lodash/get';
import type { ITax } from 'types/taxes.types';
import { TaxTypeEnum } from 'types/taxes.types';
import type { AccountType, IUser } from 'types/users.types';
import { VATTypeEnum } from 'types/users.types';
import { getShortAccountType } from 'utils/helpers';
import isTaxDone from 'utils/taxes/isTaxDone';
import AVEUR2023 from './AVEUR2023.json';
import EURStatementTableData from './EURStatementTableData.json';
import EURStatementTableData2023 from './EURStatementTableData2023.json';
import VATStatementTableData from './VATStatementTableData.json';

const today = dayjs();

const currentYear = today.year();

const taxes = {
  firstClosingDate: dayjs(`01/11/${currentYear}`),
  isRealSubmissionToAuthority: true,
  incomeTaxSlug: 'de/est-submission',
  required_payment_summary_field: 'settings.companyDetails.steuernummer',
  getStatementTableData: (tax: ITax) => {
    switch (tax.type) {
      case TaxTypeEnum.vatSubmission:
        return VATStatementTableData;
      case TaxTypeEnum.EUR: {
        if (tax.period.year < 2023) return EURStatementTableData;
        return [
          ...EURStatementTableData2023,
          ...(tax.statement.AVEUR ? AVEUR2023 : []),
        ];
      }
    }
  },
  vat_breakdown_get_path: 'de/ustva',
  eur_breakdown_get_path: 'de/eur',
  download_vat_submission_xml: false,
  hasProfitAndLossBreakdown: false,
  startDateForIncomeTaxSubmission: dayjs(`02-01-${currentYear}`),
  required_data_for_intraeu_export: ['VATNumber', 'steuernummer', 'address'],
  required_data_for_vat_submission: ['steuernummer', 'address', 'state'],
  submission_description_suffix: '',
  can_make_vat_as_expense: true,
  incomTaxStillToPay: (tax: ITax) => {
    const isCurrentYear = currentYear === tax?.period?.year;
    const ofWhichAlreadyPaid = (() => {
      return (
        (get(tax, 'selfEmployedPrepayments') || 0) +
        (get(tax, 'isEmploymentSubmissionPaneValidated')
          ? get(tax, 'employeePrepayments') || 0
          : get(tax, 'estimatedIncomeTaxOnSalary') || 0)
      );
    })();
    if (isCurrentYear && !isTaxDone(tax)) {
      return (
        (get(tax, 'estimatedOtherTaxes') || 0) +
        (get(tax, 'estimatedIncomeTaxOnSalary') || 0) +
        (get(tax, 'projectedIncomeTax') || 0) -
        ofWhichAlreadyPaid
      );
    }
    return (
      (get(tax, 'estimatedOtherTaxes') || 0) +
      (get(tax, 'estimatedIncomeTaxOnSalary') || 0) +
      (get(tax, 'estimatedIncomeTax') || 0) -
      ofWhichAlreadyPaid
    );
  },
  getTaxAvailablityMessage: (acountType: AccountType) =>
    'taxes.api.not_available_yet_from_authorities',
  // `taxes.api.not_available_yet.${acountType?.split('_')[0]}`,
  hasCustomUnknownAlertDesc: true,
  getIsReimbursement: (tax: ITax): boolean => {
    if (tax?.type === TaxTypeEnum.vatPayment) {
      return (
        isNumber(get(tax, 'statement.c83')) && get(tax, 'statement.c83') < 0
      );
    }
    if (tax?.type === TaxTypeEnum.incomeTaxPayment)
      return get(tax, 'suggestedPrepaymentAmount') < 0;
    if (tax?.type === TaxTypeEnum.usteSubmission) {
      return get(tax, ['statement', 'l', 'f820'], 0) < 0;
    }
    return false;
  },
  required_data_for_eur_submission: [
    'steuernummer',
    'address',
    'state',
    'activity',
  ],
  fullTermsLink:
    'https://accountable.notion.site/Bedingungen-der-Accountable-Garantie-f-r-Deutschland-7de25d95124d4be8be29964bfbb98750',
  vat_return_frequency: ['quarterly', 'monthly', 'yearly'],
  default_vat_return_frequency: 'monthly',
  no_negative_vat_cells: [],
  available_export_modules: [
    { key: 'excel', value: 'excel' },
    { key: 'original_documents', value: 'original_documents' },
    // in Germany we refer to p&l as eur therefore we use eur as key
    // to show the translations for eur export module
    { key: 'eur', value: 'pl', lock: 'report_PL' },
    { key: 'datev', value: 'datev', lock: 'datev' },
    { key: 'pdf', value: 'pdf' },
    { key: 'amortisation_table', value: 'amortisation_table' },
  ],
  datev_fields: [
    { label: 'accountantNumber', placeholder: '4862886' },
    { label: 'clientNumber', placeholder: '10487' },
    { label: 'defaultSalesAccount.0', placeholder: '8400' },
    { label: 'defaultSalesAccount.7', placeholder: '8300' },
    { label: 'defaultSalesAccount.19', placeholder: '8100' },
  ],
  has_download: false,
  blocked: {
    web: false,
    experts: false,
  },
  taxes_onboarding_modal_texts: {
    canUse: [
      'taxes.onboarding_modal.can_use_text_1.de',
      'taxes.onboarding_modal.can_use_text_2.de',
      'taxes.onboarding_modal.can_use_text_3.de',
      'taxes.onboarding_modal.can_use_text_4.de',
      'taxes.onboarding_modal.can_use_text_5.de',
    ],
    cannotUse: [
      'taxes.onboarding_modal.cannot_use_text_1.de',
      'taxes.onboarding_modal.cannot_use_text_2.de',
      'taxes.onboarding_modal.cannot_use_text_3.de',
      'taxes.onboarding_modal.cannot_use_text_4.de',
      'taxes.onboarding_modal.cannot_use_text_5.de',
      'taxes.onboarding_modal.cannot_use_text_6.de',
    ],
  },
  onboardingTaxObligations: (user: IUser) => {
    const _accountType = getShortAccountType(user?.accountType);

    const mapper = {
      [AccountTypeShortEnum.freiberufler]: {
        [VATTypeEnum.subjectToVAT]: [
          {
            title: 'taxes_onboarding.obligations.vat',
            frequency: user.VATReturnFrequency,
            helper: 'taxes_onboarding.obligations.vat.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.yearly_vat.de',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.yearly_vat.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.income_tax',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.income_tax.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.eur.de',
            frequency: 'quarterly',
            helper: 'taxes_onboarding.obligations.eur.helper.de',
          },
        ],
        [VATTypeEnum.franchisee]: [
          {
            title: 'taxes_onboarding.obligations.yearly_vat.de',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.yearly_vat.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.income_tax',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.income_tax.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.eur.de',
            frequency: 'quarterly',
            helper: 'taxes_onboarding.obligations.eur.helper.de',
          },
        ],
        [VATTypeEnum.exempt]: [
          {
            title: 'taxes_onboarding.obligations.yearly_vat.de',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.yearly_vat.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.income_tax',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.income_tax.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.eur.de',
            frequency: 'quarterly',
            helper: 'taxes_onboarding.obligations.eur.helper.de',
          },
        ],
      },
      [AccountTypeShortEnum.gewerbetreibender]: {
        [VATTypeEnum.subjectToVAT]: [
          {
            title: 'taxes_onboarding.obligations.vat',
            frequency: user.VATReturnFrequency,
            helper: 'taxes_onboarding.obligations.vat.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.yearly_vat.de',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.yearly_vat.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.income_tax',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.income_tax.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.gewerbesteuer.de',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.gewerbesteuer.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.eur.de',
            frequency: 'quarterly',
            helper: 'taxes_onboarding.obligations.eur.helper.de',
          },
        ],
        [VATTypeEnum.franchisee]: [
          {
            title: 'taxes_onboarding.obligations.yearly_vat.de',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.yearly_vat.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.income_tax',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.income_tax.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.gewerbesteuer.de',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.gewerbesteuer.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.eur.de',
            frequency: 'quarterly',
            helper: 'taxes_onboarding.obligations.eur.helper.de',
          },
        ],
        [VATTypeEnum.exempt]: [
          {
            title: 'taxes_onboarding.obligations.yearly_vat.de',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.yearly_vat.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.income_tax',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.income_tax.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.gewerbesteuer.de',
            frequency: 'yearly',
            helper: 'taxes_onboarding.obligations.gewerbesteuer.helper.de',
          },
          {
            title: 'taxes_onboarding.obligations.eur.de',
            frequency: 'quarterly',
            helper: 'taxes_onboarding.obligations.eur.helper.de',
          },
        ],
      },
      default: [
        {
          title: 'taxes_onboarding.obligations.yearly_vat.de',
          frequency: 'yearly',
          helper: 'taxes_onboarding.obligations.yearly_vat.helper.de',
        },
        {
          title: 'taxes_onboarding.obligations.income_tax',
          frequency: 'yearly',
          helper: 'taxes_onboarding.obligations.income_tax.helper.de',
        },
        {
          title: 'taxes_onboarding.obligations.eur.de',
          frequency: 'quarterly',
          helper: 'taxes_onboarding.obligations.eur.helper.de',
        },
      ],
    };

    return _get(mapper, [_accountType, user?.VATType], mapper.default);
  },
} as const;

export default taxes;
