import { Select } from 'components/atoms/Form/Select';
import { isEmpty } from 'lodash';
import type { FC } from 'react';
import formatPeriodLabel from 'utils/formatPeriodLabel';
import useRevenuesCategoriesPicker, {
  UseRevenuesCategoriesPickerProps,
} from './useRevenuesCategoriesPicker';

type CategoriesPickerProps = {
  value: string;
  onChange: (category: any, taxPeriod?: any) => void;
  name: string;
  error?: string;
  taxPeriod?: any;
  pickerController: ReturnType<typeof useRevenuesCategoriesPicker>;
  highlightID?: string;
  placeholder?: string;
  isDisabled?: boolean;
};

export const CategoriesPickerControlled: FC<CategoriesPickerProps> = ({
  value,
  onChange,
  taxPeriod,
  name,
  error,
  pickerController,
  placeholder,
  highlightID,
  isDisabled,
}) => {
  const { api, query } = pickerController;

  const { data, open } = api;
  const { isLoading, isError, refetch: retry } = query;

  const getLabel = () => {
    if (!value) return null;
    const category = data.categoriesDict[value];
    if (!category) return null;
    if (!taxPeriod) return category.displayName;
    return `${category.displayName} - ${formatPeriodLabel(taxPeriod)}`;
  };

  return (
    <Select
      name={name}
      error={error}
      value={query.isLoading ? null : getLabel()}
      isLoading={isLoading}
      isDisabled={isLoading || isDisabled}
      isError={isError}
      retry={retry}
      onMenuOpen={() => {
        if (isEmpty(data.categoriesList)) return;
        open({ value, onChange });
      }}
      highlightID={highlightID}
      openMenuOnClick
      isSearchable={false}
      noOptionsMessage={() => null}
      placeholder={placeholder}
    />
  );
};

const CategoriesPicker: FC<
  UseRevenuesCategoriesPickerProps &
    Omit<CategoriesPickerProps, 'pickerController'>
> = (props) => {
  const {
    defaultTaxPeriod,
    taxPeriod,
    tracing,
    tooltips,
    type,
    renderGridHeader,
    renderHeader,
    header,
    searchBarPlaceholder,
  } = props;

  const controller = useRevenuesCategoriesPicker({
    defaultTaxPeriod,
    taxPeriod,
    type,
    tracing,
    tooltips,
    renderGridHeader,
    renderHeader,
    header,
    searchBarPlaceholder,
  });

  const { name, value, onChange, error, highlightID, placeholder, isDisabled } =
    props;

  return (
    <>
      {controller.api.content}
      <CategoriesPickerControlled
        value={value}
        onChange={onChange}
        taxPeriod={taxPeriod}
        name={name}
        error={error}
        pickerController={controller}
        highlightID={highlightID}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default CategoriesPicker;
