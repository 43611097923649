import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useFeatures from 'hooks/useFeatures';
import type { InvoiceFormValues, InvoiceItem } from 'types/invoice.types';
import {
  InvoiceFileTypeEnum,
  InvoiceStatusEnum,
  InvoiceTypeEnum,
  WhyZeroVATEnum,
} from 'types/invoice.types';
import type { IUser } from 'types/users.types';
import {
  AvailableCountries,
  VATReturnFrequencyEnum,
  VATTypeEnum,
} from 'types/users.types';
import {
  germanBusinessCarCustomType,
  isWeb,
  zeroVATTypes,
} from 'utils/constants';
import getFieldState from 'utils/getFieldState';
import {
  suggestVATRateBasedOnClientLocation,
  suggestWhyZeroVATBasedOnClientLocation,
} from '../GeneratedRevenueForm/SharedControls/ItemsForm/helpers';
import { getValidWhyZeroVATValues, otherRevenueEmptyClient } from './utils';

const useFormMeta = () => {
  const user = useGetCurrentCustomerData().data as IUser;
  const customerCountry = useCustomerCountry() as AvailableCountries;

  const isCustomerDE = customerCountry === AvailableCountries.germany;

  const deductibleTaxCategories = useFeatures(
    'revenue.deductible_tax_categories',
  );

  const getFormMeta = (values: InvoiceFormValues) => {
    const isDeductible = !!values.items?.some((item: any) =>
      deductibleTaxCategories?.includes(item.categoryId),
    );

    const isGermanBusinessCar =
      values.customType === germanBusinessCarCustomType;

    const isItemsComputed = isGermanBusinessCar;

    const isPaid = values.status === InvoiceStatusEnum.paid;

    const isOtherRevenue = values.type === InvoiceTypeEnum.OTHER_REVENUE;
    const isInvoice = values.type === InvoiceTypeEnum.INVOICE;
    const isCreditNote = values.type === InvoiceTypeEnum.CREDIT_NOTE;

    const isFileGenerated = values.fileType === InvoiceFileTypeEnum.generated;

    const isGenerated =
      isFileGenerated && (isInvoice || isCreditNote) && !!values._id;

    const isCustomerTypeZeroVAT =
      !!values.user?.VATType && zeroVATTypes.includes(values.user.VATType);

    const isCustomerFranshisee =
      values.user?.VATType === VATTypeEnum.franchisee;

    const isCustomerExempt = values.user?.VATType === VATTypeEnum.exempt;

    const isCustomerSubjectToVAT =
      values.user?.VATType === VATTypeEnum.subjectToVAT;

    const isCustomerFreqMonthly =
      values.user?.VATReturnFrequency === VATReturnFrequencyEnum.monthly;

    const isCustomerFreqQuarterly =
      values.user?.VATReturnFrequency === VATReturnFrequencyEnum.quarterly;

    const invoiceClientField = getFieldState({
      name: 'client',
      isHidden: isOtherRevenue || isGenerated,
      value: () => values.client,
    });

    const otherRevenueClientField = getFieldState({
      name: 'client',
      isHidden: isInvoice || isCreditNote || isGenerated,
      value: () => values.client,
      isApplicable: !isGermanBusinessCar,
      suggestedChanges: ({ isApplicable, name }) => {
        if (!isApplicable) {
          return [{ name, value: otherRevenueEmptyClient }];
        }
      },
    });

    const paymentAmountField = getFieldState({
      name: 'paymentAmount',
      value: () => values.paymentAmount,
      isDisabled: isGenerated,
      isApplicable:
        (isInvoice || isCreditNote) &&
        values.currency !== 'EUR' &&
        !!values.items?.length &&
        !!values.invoiceDate,
    });

    const currencyField = getFieldState({
      name: 'currency',
      isHidden: isGenerated,
      value: () => values.currency,
      isApplicable: isInvoice || isCreditNote,
    });

    const invoiceDateField = getFieldState({
      name: 'invoiceDate',
      isHidden: isGenerated,
      value: () => values.invoiceDate,
    });

    const paymentDateField = getFieldState({
      name: 'paymentDate',
      isHidden: isOtherRevenue,
      value: () => values.paymentDate,
      isApplicable: isCustomerDE && isPaid,
      suggestedChanges: ({ isApplicable, name }) => {
        if (!isApplicable) return;
        if (isOtherRevenue) {
          return [{ name, value: invoiceDateField.value }];
        }
      },
    });

    const deliveryDateField = getFieldState({
      name: 'deliveryDate',
      isHidden: isGenerated,
      value: () => values.deliveryDate,
      isApplicable:
        !isOtherRevenue && isCustomerDE && !values.showDeliveryPeriod,
    });

    const deliveryPeriodField = getFieldState({
      name: 'deliveryPeriod',
      isHidden: isGenerated,
      value: () => values.deliveryPeriod,
      isApplicable:
        !isOtherRevenue && isCustomerDE && !!values.showDeliveryPeriod,
    });

    const dueDateField = getFieldState({
      name: 'dueDate',
      isHidden: isGenerated,
      value: () => values.dueDate,
      isApplicable: isInvoice || isCreditNote,
    });

    const periodField = getFieldState({
      name: 'period',
      value: () => values.period,
      isApplicable:
        isCustomerSubjectToVAT && (!isCustomerDE || !!paymentDateField.value),
    });

    const isClientExtraEU = values.client?.location === 'extra-eu';
    const isClientIntraEU = values.client?.location === 'intra-eu';
    const isClientTypeBusiness = (values.client as any)?.type === 'business';

    const isClientTypeDiplomatic =
      (values.client as any)?.type === 'diplomatic';

    const isClientLocal =
      (values.client as any)?.address?.country?.toLowerCase() ===
      customerCountry.toLowerCase();

    const revenueNumberField = getFieldState({
      name: 'revenueNumber',
      value: () => values.revenueNumber,
      isHidden: isGenerated,
    });

    const itemsField = getFieldState({
      name: 'items',
      value: () => values.items,
      isHidden: isGenerated,
      shouldValidate: !isItemsComputed,
    });

    const notesField = getFieldState({
      name: 'notes',
      value: () => values.notes,
      isHidden: isGenerated,
    });

    const accountantCommentsField = getFieldState({
      name: 'accountantReview.comments',
      value: () => values.accountantReview?.comments,
      isHidden: isWeb || isGenerated,
    });

    const VATTypeField = getFieldState({
      name: 'user.VATType',
      value: () => values.user?.VATType,
      isHidden: isGenerated,
    });

    const taxPeriodField = getFieldState({
      name: 'taxPeriod',
      value: () => values.taxPeriod,
      isApplicable: isDeductible,
    });

    const statusField = getFieldState({
      name: 'status',
      value: () => values.status,
      isHidden: isOtherRevenue,
      suggestedChanges: ({ name }) => {
        if (isOtherRevenue) return [{ name, value: 'paid' }];
      },
    });

    const transactionsField = getFieldState({
      name: 'transactions',
      value: () => values.transactions,
      isApplicable: !isGermanBusinessCar,
    });

    const customCategoryIdField = getFieldState({
      name: 'customData.customCategoryId',
      value: () => values.customData?.customCategoryId,
      isApplicable: isGermanBusinessCar,
      suggestedChanges: ({ isApplicable, name }) => {
        if (!isApplicable) {
          return [
            { name, value: undefined },
            { name: 'customData.customCategory', value: undefined },
          ];
        }
      },
    });

    const usageStartDateField = getFieldState({
      name: 'customData.usageStartDate',
      value: () => values.customData?.usageStartDate,
      isApplicable: isGermanBusinessCar,
    });

    const usageEndDateField = getFieldState({
      name: 'customData.usageEndDate',
      value: () => values.customData?.usageEndDate,
      isApplicable: isGermanBusinessCar,
    });

    const isVehicleUsedForOfficeCommuteField = getFieldState({
      name: 'customData.isVehicleUsedForOfficeCommute',
      value: () => values.customData?.isVehicleUsedForOfficeCommute,
      isApplicable: isGermanBusinessCar,
      suggestedChanges: (self) => {
        const { isApplicable, name, value } = self;
        if (isApplicable && value(self) == null) {
          return [{ name, value: false }];
        }
      },
    });

    const distanceBetweenHomeAndOfficeField = getFieldState({
      name: 'customData.distanceBetweenHomeAndOffice',
      value: () => values.customData?.distanceBetweenHomeAndOffice,
      isApplicable: !!isVehicleUsedForOfficeCommuteField.value,
    });

    const vehicleIdField = getFieldState({
      name: 'customData.vehicleId',
      value: () => values.customData?.vehicleId,
      isApplicable: isGermanBusinessCar,
      suggestedChanges: ({ isApplicable, name }) => {
        if (!isApplicable) {
          return [
            { name, value: undefined },
            { name: 'customData.vehicle', value: undefined },
          ];
        }
      },
    });

    const isCalcUIHidden = isGenerated;

    const shouldHideAddItemBtn = isGermanBusinessCar || itemsField.isHidden;

    const shouldShow1RuleInfo = isGermanBusinessCar;

    const shouldShowBusinessCarAmountHelper = isGermanBusinessCar;

    const shouldUnitPriceIncludeVATByDefault =
      isOtherRevenue && !!user.settings?.invoices?.doesUnitPriceIncludeVAT;

    const shouldShowInvoiceDateWarning =
      !!values.invoiceDate && dayjs(values.invoiceDate).isAfter(dayjs(), 'day');

    const usagePeriodInMonths =
      usageStartDateField.value != null && usageEndDateField.value != null
        ? dayjs(usageEndDateField.value).diff(
            dayjs(usageStartDateField.value),
            'month',
          ) + 1
        : null;

    const shouldShowUsagePeriodWarning =
      isCustomerSubjectToVAT &&
      usagePeriodInMonths != null &&
      ((isCustomerFreqMonthly && usagePeriodInMonths > 1) ||
        (isCustomerFreqQuarterly &&
          usagePeriodInMonths > 3 &&
          usagePeriodInMonths < 12));

    return {
      fields: {
        paymentAmount: paymentAmountField,
        currency: currencyField,
        paymentDate: paymentDateField,
        invoiceDate: invoiceDateField,
        deliveryDate: deliveryDateField,
        deliveryPeriod: deliveryPeriodField,
        dueDate: dueDateField,
        period: periodField,
        otherRevenueClient: otherRevenueClientField,
        invoiceClient: invoiceClientField,
        revenueNumber: revenueNumberField,
        items: itemsField,
        notes: notesField,
        accountantComments: accountantCommentsField,
        VATType: VATTypeField,
        taxPeriod: taxPeriodField,
        status: statusField,
        transactions: transactionsField,
        customCategoryId: customCategoryIdField,
        usageStartDate: usageStartDateField,
        usageEndDate: usageEndDateField,
        isVehicleUsedForOfficeCommute: isVehicleUsedForOfficeCommuteField,
        distanceBetweenHomeAndOffice: distanceBetweenHomeAndOfficeField,
        vehicleId: vehicleIdField,
      },
      helpers: {
        clientLocation: values.client?.location,
        isCalcUIHidden,
        isCustomerTypeZeroVAT,
        isCustomerFranshisee,
        isCustomerExempt,
        isCustomerSubjectToVAT,
        isDeductible,
        isPaid,
        isOtherRevenue,
        isInvoice,
        isCreditNote,
        isClientExtraEU,
        isClientIntraEU,
        isClientTypeBusiness,
        isClientTypeDiplomatic,
        isClientLocal,
        isGenerated,
        isGermanBusinessCar,
        shouldHideAddItemBtn,
        shouldShow1RuleInfo,
        shouldShowBusinessCarAmountHelper,
        shouldUnitPriceIncludeVATByDefault,
        shouldShowInvoiceDateWarning,
        isItemsComputed,
        shouldShowUsagePeriodWarning,
      },
    };
  };

  const getItemMeta = (
    values: InvoiceFormValues,
    item: Partial<InvoiceItem>,
    prefix = '',
  ) => {
    const {
      helpers: {
        isCustomerSubjectToVAT,
        isCustomerTypeZeroVAT,
        isCustomerExempt,
        isOtherRevenue,
        isClientIntraEU,
        isClientTypeBusiness,
        isGermanBusinessCar,
        isItemsComputed,
        clientLocation,
      },
    } = getFormMeta(values);

    const hasAdmitsVAT = !!item.category?.admitsVAT;

    const isDeductible = deductibleTaxCategories.includes(item.categoryId);

    const isSalesCategory =
      !!item.categoryId &&
      [
        'be.revenue.sales_goods',
        'be.revenue.sales_services',
        'de.revenue.sales_goods',
        'de.revenue.sales_services',
      ].includes(item.categoryId);

    const isCopyRightCategory = item?.categoryId === 'be.revenue.copyright';

    const intraBusiness = isClientIntraEU && isClientTypeBusiness;

    const VATRateField = getFieldState({
      name: prefix + 'VATRate',
      value: () => item.VATRate,
      isDisabled: isItemsComputed,
      isHidden: !isCustomerSubjectToVAT,
      isApplicable: hasAdmitsVAT,
      suggestedChanges: ({ isApplicable, name }) => {
        if (isItemsComputed) return [];
        if (!isApplicable) return [{ name, value: null }];
        if (!isCustomerSubjectToVAT) {
          return [{ name, value: 0 }];
        }
        const suggestedVATRate =
          suggestVATRateBasedOnClientLocation(clientLocation);
        if (item.VATRate == null && suggestedVATRate != null) {
          return [{ name, value: suggestedVATRate }];
        }
      },
    });

    const recommendedVATRate = intraBusiness ? 0 : null;

    const shouldShowVATRateNotZeroWarning =
      intraBusiness && item.VATRate != null && item.VATRate !== 0;

    const nameField = getFieldState({
      name: prefix + 'name',
      value: () => item.name,
      isDisabled: isItemsComputed,
      isHidden: isItemsComputed,
    });

    const categoryIdField = getFieldState({
      name: prefix + 'categoryId',
      value: () => item.categoryId,
      isDisabled: isItemsComputed,
    });

    const quantityField = getFieldState({
      name: prefix + 'quantity',
      value: () => item.quantity,
      isHidden: isOtherRevenue,
      isDisabled: isItemsComputed,
    });

    const doesUnitPriceIncludeVATField = getFieldState({
      name: prefix + 'doesUnitPriceIncludeVAT',
      value: () => item.doesUnitPriceIncludeVAT,
      isDisabled: isItemsComputed,
      isHidden: !VATRateField.value,
    });

    const whyZeroVATField = getFieldState({
      name: prefix + 'whyZeroVAT',
      value: () => item.whyZeroVAT,
      isHidden: !isCustomerSubjectToVAT,
      isDisabled: isItemsComputed,
      isApplicable: hasAdmitsVAT && item.VATRate === 0,
      suggestedChanges: (self) => {
        if (isItemsComputed) return [];
        const { isApplicable, name, value } = self;
        const _value = value(self);
        if (!isApplicable) return;
        if (isCustomerExempt) {
          return [{ name, value: WhyZeroVATEnum.exempt_item }];
        }
        if (!isCustomerSubjectToVAT) {
          return [{ name, value: WhyZeroVATEnum.user_franchisee }];
        }

        const valids = getValidWhyZeroVATValues({
          clientLocation: values.client?.location,
          clientType: values.client?.type,
          categoryId: item.categoryId,
          revenueType: values.type as InvoiceTypeEnum,
          VATType: values.user.VATType,
        });

        if (_value != null && !valids.includes(_value)) {
          return [{ name, value: null }];
        }

        const suggestedWhyZeroVAT = suggestWhyZeroVATBasedOnClientLocation(
          values.client?.location,
        );
        if (
          _value == null &&
          suggestedWhyZeroVAT != null &&
          valids.includes(suggestedWhyZeroVAT)
        ) {
          return [{ name, value: suggestedWhyZeroVAT }];
        }
      },
    });

    const unitAmountExclVATField = getFieldState({
      name: prefix + 'unitAmountExclVAT',
      value: () => item.unitAmountExclVAT,
      isDisabled: isItemsComputed,
      isApplicable: !doesUnitPriceIncludeVATField.value,
    });

    const unitAmountInclVATField = getFieldState({
      name: prefix + 'unitAmountInclVAT',
      value: () => item.unitAmountInclVAT,
      isDisabled: isItemsComputed,
      isApplicable: doesUnitPriceIncludeVATField.value,
    });

    return {
      fields: {
        VATRate: VATRateField,
        quantity: quantityField,
        doesUnitPriceIncludeVAT: doesUnitPriceIncludeVATField,
        whyZeroVAT: whyZeroVATField,
        unitAmountExclVAT: unitAmountExclVATField,
        name: nameField,
        categoryId: categoryIdField,
        unitAmountInclVAT: unitAmountInclVATField,
      },
      helpers: {
        isDeductible,
        isSalesCategory,
        isCustomerTypeZeroVAT,
        isGermanBusinessCar,
        isCopyRightCategory,
        recommendedVATRate,
        shouldShowVATRateNotZeroWarning,
      },
    };
  };

  const getSuggestedChanges = (formValues: InvoiceFormValues) => {
    const formMeta = getFormMeta(formValues);

    const topFieldsSuggestedChanges = Object.values(formMeta.fields).flatMap(
      (field) => field.suggestedChanges,
    );

    return topFieldsSuggestedChanges.concat(
      (formValues.items || []).flatMap((item, i) =>
        Object.values(
          getItemMeta(formValues, item, `items.${i}.`).fields,
        ).flatMap((field) => field.suggestedChanges),
      ),
    );
  };

  return { getFormMeta, getItemMeta, getSuggestedChanges };
};

export default useFormMeta;
