import type SVGType from 'types/svg.types';

const ProfileIcon: SVGType = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.3337 10.6673C20.3337 13.0606 18.3936 15.0007 16.0003 15.0007C13.6071 15.0007 11.667 13.0606 11.667 10.6673C11.667 8.27408 13.6071 6.33398 16.0003 6.33398C18.3936 6.33398 20.3337 8.27408 20.3337 10.6673Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
    />
    <path
      d="M9.12997 25.666H22.87C24.3925 25.666 25.5653 24.3569 24.8543 23.0105C23.8084 21.0301 21.4239 18.666 16 18.666C10.576 18.666 8.19152 21.0301 7.14561 23.0105C6.43459 24.3569 7.60741 25.666 9.12997 25.666Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
    />
  </svg>
);

export default ProfileIcon;
