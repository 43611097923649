import { Button } from 'components/atoms/Button';
import useDebounceQuery from 'hooks/useDebounceQuery';
import type { FC } from 'react';
import { useEffect, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Props } from 'react-select';
import { SelectOptionsCacheKeys } from 'types/cacheKeys.types';
import { FIVE_MINUTES } from 'utils/time';
import { getOptionsDict, getSelectedOptionsFromDict } from '../helpers';
import type { SelectConfig, value } from '../select.types';

const withAsyncOptions = (SelectComponent: FC<Props>) =>
  function WithAsyncOptions(props: SelectConfig) {
    const {
      onFetch,
      refetchOnSearchChange,
      fetchOnEmpty = true,
      value,
      onInputChange,
      optionsRef,
      fixedOptions,
      mapOptions,
      afterFetch,
      cacheKey = '',
      menuIsOpen,
      isError,
      retry,
      isLoading,
      onSuccess,
      enabled,
      originalInputValue,
      createdOption,
      ...rest
    } = props;
    const { t } = useTranslation();

    const { name, isMulti } = props;

    const queryConfig = refetchOnSearchChange
      ? {
          inputValue: (originalInputValue ?? '')?.trim().slice(0, 70),
        }
      : {};

    const query = useDebounceQuery({
      queryKey: [SelectOptionsCacheKeys.Async, cacheKey, name, queryConfig],
      queryFn: () => onFetch?.(queryConfig.inputValue),
      debounceTime: 250,
      cacheTime: FIVE_MINUTES,
      enabled:
        enabled === false ? false : fetchOnEmpty || !!queryConfig.inputValue,
    });

    const { isFetching, isSuccess, isError: isInnerError, refetch } = query;

    useEffect(() => {
      if (isSuccess) {
        onSuccess?.(afterFetch ? afterFetch(query.data) : query.data);
      }
    }, [query.data, isSuccess]);

    const data = useMemo(() => {
      if (isSuccess && afterFetch) return afterFetch(query.data);
      return query.data;
    }, [query.data, isSuccess, afterFetch]);

    const { extraOptions, normalOptions } = useMemo(() => {
      const extraOptions = fixedOptions ? [].concat(fixedOptions) : [];
      const normalOptions = data ? data : [];
      return { extraOptions, normalOptions };
    }, [fixedOptions, data]);

    const optionsDict = useMemo(
      () => getOptionsDict(normalOptions.concat(extraOptions)),
      [extraOptions, normalOptions],
    );

    const selected = useMemo(
      () =>
        getSelectedOptionsFromDict({
          value: value as value,
          optionsDict,
          isMulti,
        }),
      [value, optionsDict],
    );

    useImperativeHandle(optionsRef, () => data, [data]);

    const maybeMappedOptions = useMemo(() => {
      if (mapOptions) {
        return mapOptions({
          fixedOptions: extraOptions,
          options: normalOptions,
        });
      }
      return normalOptions.concat(extraOptions);
    }, [extraOptions, normalOptions]);

    // const optionsWithCreatedOption = useMemo(() => {
    //   if (!createdOption || isFetching || isLoading) return maybeMappedOptions;
    //   const hasSimilarOption = maybeMappedOptions.some(
    //     (option: any) => option.value === createdOption.value,
    //   );
    //   if (hasSimilarOption) return maybeMappedOptions;
    //   return [createdOption].concat(maybeMappedOptions);
    // }, [createdOption, maybeMappedOptions, isFetching, isLoading]);

    return (
      <>
        <SelectComponent
          isLoading={isFetching || isLoading}
          options={maybeMappedOptions}
          value={selected}
          menuIsOpen={isError || isInnerError ? false : menuIsOpen}
          onInputChange={
            onInputChange
              ? (newValue, actionMeta) => {
                  // if (isError || isInnerError) return; // don't reset user search input
                  return onInputChange(newValue, actionMeta, selected);
                }
              : undefined
          }
          {...rest}
        />
        {(isError || isInnerError) && (
          <div className="flex flex-wrap items-center gap-2">
            <span className="text-sm text-primary-400">
              {t('backend.error.something_went_wrong')}.
            </span>
            <Button
              structure="text"
              color="primary"
              onClick={() => (isInnerError ? refetch() : retry?.())}
            >
              <span className="text-sm">{t('retry')}</span>
            </Button>
          </div>
        )}
      </>
    );
  };

export default withAsyncOptions;
