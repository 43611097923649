import classNames from 'classnames';
import type { FC } from 'react';

const CountAnimatedBadge: FC<{
  count: number | string;
  className?: string;
}> = ({ count, className }) => (
  <div
    className={classNames(
      'flex animate-countBadgeAnim items-center justify-center transition-all',
      'border-white border-2 bg-red-500 rounded-full',
      'w-6 h-6 z-50',
      'text-[12px] !font-bold text-white',
      className,
    )}
  >
    {count}
  </div>
);

export default CountAnimatedBadge;
