import { useCallback, useMemo, useState } from 'react';

const useKeyValueState = (init: { [key: string]: any } = {}) => {
  const [state, setState] = useState(init);

  const set = useCallback((key: string, value: any) => {
    setState((prevState) => Object.assign({}, prevState, { [key]: value }));
  }, []);

  const update = useCallback((key: string, value: any) => {
    setState((prevState) => {
      const updated = Object.assign({}, prevState[key], value);
      return Object.assign({}, prevState, { [key]: updated });
    });
  }, []);

  const clear = useCallback(() => {
    setState(init);
  }, [init]);

  const values = useMemo(() => Object.values(state), [state]);

  return {
    set,
    update,
    values,
    clear,
  };
};

export default useKeyValueState;
