import type SVGType from 'types/svg.types';

const ExportIcon: SVGType = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 25.667H23"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
      stroke="currentColor"
    />
    <path
      d="M15 20.333V21.333H17V20.333H15ZM17 6.33301C17 5.78072 16.5523 5.33301 16 5.33301C15.4477 5.33301 15 5.78072 15 6.33301H17ZM17 20.333V6.33301H15V20.333H17Z"
      fill="currentColor"
    />
    <path
      d="M10.333 12.333L15.9997 6.33301L21.6663 12.333"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
      stroke="currentColor"
    />
  </svg>
);

export default ExportIcon;
