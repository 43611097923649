import useAllExpenses from 'components/pages/Expenses/useAllExpenses';
import useExpensesToReview from 'components/pages/Expenses/useExpensesToReview';
import { useEffect } from 'react';
import { World } from 'types/shared/worlds';
import useInvalidateResources from './useInvalidateResources';
import usePusher from './usePusher';

const useExpenseFromEmail = () => {
  const channel = usePusher();
  const toReview = useExpensesToReview({ enabled: false });
  const allExpenses = useAllExpenses({ enabled: false });
  const invalidateResources = useInvalidateResources();

  useEffect(() => {
    if (!channel) return;

    const handler = () => {
      toReview.controllers.reset();
      allExpenses.controllers.reset();
      invalidateResources(World.EXPENSES);
    };

    channel.bind('expenses.createFromFile', handler);
    return () => {
      channel.unbind('expenses.createFromFile', handler);
    };
  }, [channel, toReview.controllers.reset]);
};

export default useExpenseFromEmail;
