import { TrashIcon } from '@heroicons/react/outline';
import { joiResolver } from '@hookform/resolvers/joi';
import requestDeleteAccount from 'api/v2/users/deleteUserAccount';
import { Button } from 'components/atoms/Button';
import { FieldConnector } from 'components/atoms/Form/FieldConnector';
import FreeTextInput from 'components/atoms/Form/FreeTextInput';
import { Loader } from 'components/atoms/Loader';
import { Modal } from 'components/atoms/Modal';
import { RadioGroup } from 'components/atoms/RadioGroups';
import { Title } from 'components/atoms/Title';
import { SizesEnum } from 'components/atoms/Title/Title.enum';
import useGetAuthMethods from 'hooks/Authentication/useGetAuthMethods';
import logout from 'hooks/Authentication/useLogout';
import useTranslateSelectOptions from 'hooks/useTranslateSelectOptions';
import Joi from 'joi';
import _get from 'lodash/get';
import type { FC, SyntheticEvent } from 'react';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { AccountSettingsFieldsNamesEnum } from 'types/settings.types';
import type { IUser } from 'types/users.types';
import { getTracingEvents } from 'utils/amplitude';
import {
  WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDCLOSEDELETEACCOUNTMODAL,
  WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDDELETEACCOUNT,
  WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENDELETEACCOUNTMODAL,
} from 'utils/amplitude/events/settings.amplitude';
import { deletionReasonsOptions } from 'utils/settings/options';
import showToast from 'utils/toast';
import deleteAccountValidationSchema from 'validation/deleteAccountValidationSchema';

const tracingEvents = getTracingEvents({
  onCloseModal: WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDCLOSEDELETEACCOUNTMODAL,
  onDelete: {
    name: WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDDELETEACCOUNT,
    data: (reason: string) => ({ reason }),
  },
});

const { deleteAccountReason, deleteAccountOtherReason } =
  AccountSettingsFieldsNamesEnum;

const DeleteConfirmationModal: FC<{
  onConfirmation: (password: string) => () => void;
  isDeleting: boolean;
}> = ({ onConfirmation, isDeleting }) => {
  const { t } = useTranslation();

  const methods = useForm({
    resolver: joiResolver(Joi.object({ password: Joi.string().required() })),
  });
  const {
    formState: { errors },
  } = methods;

  const applyDeletion = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      methods.handleSubmit((values) => {
        onConfirmation(values.password)();
      })();
    },
    [methods, onConfirmation],
  );

  return (
    <main>
      <Title type={SizesEnum.h5} className="text-center my-4 px-5">
        {t('settings.delete_account_confirm_password')}
      </Title>

      <FormProvider {...methods}>
        <form className="px-3" onSubmit={applyDeletion}>
          <div className="my-4">
            <FieldConnector name="password">
              {({ field }) => (
                <FreeTextInput
                  {...field}
                  errors={errors}
                  type="password"
                  placeholder={t('login.passwordPlaceholder')}
                />
              )}
            </FieldConnector>
          </div>

          <Button
            type="submit"
            className="w-full"
            color="red"
            icon={TrashIcon}
            loading={isDeleting}
          >
            {t('delete')}
          </Button>
        </form>
      </FormProvider>
    </main>
  );
};

const AccountDeletion: FC<{
  user: IUser;
  Trigger?: FC<{ api: any; isLoading: boolean }>;
}> = ({ user: { email }, Trigger }) => {
  const translateOptions = useTranslateSelectOptions();

  const { t } = useTranslation();

  const methods = useForm({
    resolver: joiResolver(deleteAccountValidationSchema(t)),
  });

  const {
    formState: { errors },
    clearErrors,
    handleSubmit,
    setValue,
    register,
    watch,
  } = methods;

  const [deleteAccountReasonValue, otherReasonValue] = watch([
    deleteAccountReason,
    deleteAccountOtherReason,
  ]);

  const { mutate, isLoading } = useMutation(
    (data: { email: string; password?: string }) =>
      requestDeleteAccount(data, { ignoreToast: true }),
  );

  const deleteAccountHandler = useCallback(
    (password?: string) => () => {
      handleSubmit(() => {
        tracingEvents.onDelete(otherReasonValue || deleteAccountReasonValue);

        mutate(
          { email, password },
          {
            onSuccess: () => logout(),
            onError: (e: any) => {
              const errorCode = _get(e, 'response.data.errors.0.code');

              if (errorCode === 'invalid-password') {
                showToast(
                  { title: t('settings.deleteAccount.password_incorrect') },
                  { type: 'error' },
                );
              } else {
                showToast({ title: e?.message }, { type: 'error' });
              }
            },
          },
        );
      })();
    },
    [
      handleSubmit,
      otherReasonValue,
      deleteAccountReasonValue,
      mutate,
      email,
      t,
    ],
  );

  const methodsQuery = useGetAuthMethods();

  const hasPasswordMethod =
    methodsQuery.data?.authMethods?.includes('password');

  return (
    <Modal
      name="delete account"
      trigger={(api) =>
        Trigger ? (
          <Trigger api={api} isLoading={isLoading} />
        ) : (
          <Button
            size="xl"
            type="button"
            color="red"
            structure="text"
            onClick={() => api.openModal()}
            tracingEvent={
              WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENDELETEACCOUNTMODAL
            }
            loading={isLoading}
          >
            {t('settings.controllers.deleteAccount')}
          </Button>
        )
      }
      color="taxes"
      width="lg"
      dialogPanalClassName="pt-8 pb-6 px-10"
      onClose={() => tracingEvents.onCloseModal()}
      render={() => (
        <main>
          <Title type={SizesEnum.h5} className="text-center my-4 px-5">
            {t('settings.delete_account.title')}
          </Title>

          {isLoading || methodsQuery.isLoading ? (
            <div className="flex items-center justify-center h-full mt-10">
              <Loader className="text-primary-200 fill-primary-600 !w-10 !h-10" />
            </div>
          ) : (
            <FormProvider {...methods}>
              <form className="px-3" onSubmit={deleteAccountHandler()}>
                <div className="my-4">
                  <FieldConnector name={deleteAccountReason}>
                    {({ name }) => (
                      <RadioGroup
                        {...register(name)}
                        type="block"
                        labelClassNames="text-black text-sm"
                        onChange={(v: string) => {
                          clearErrors(name);
                          setValue(name, v);
                        }}
                        data={translateOptions(deletionReasonsOptions).map(
                          ({ label, value }) => ({
                            title: label,
                            id: t(value),
                            checked: deleteAccountReasonValue === label,
                          }),
                        )}
                      />
                    )}
                  </FieldConnector>

                  <div className="flex-1">
                    <FieldConnector
                      name={deleteAccountOtherReason}
                      showField={
                        deleteAccountReasonValue ===
                        t('settings.delete_account.option_other')
                      }
                    >
                      {({ field }) => (
                        <FreeTextInput
                          {...field}
                          errors={errors}
                          placeholder={t(
                            `settings.delete_account.other_reason_placeholder`,
                          )}
                        />
                      )}
                    </FieldConnector>
                  </div>
                </div>

                {!hasPasswordMethod ? (
                  <Button type="submit" className="w-full" color="taxes">
                    {t('settings.controllers.continue')}
                  </Button>
                ) : (
                  <Modal
                    name="delete account confirmation"
                    trigger={(api) => (
                      <Button
                        type="button"
                        onClick={() => {
                          methods.trigger().then((valid) => {
                            if (valid) {
                              return api.openModal();
                            }
                          });
                        }}
                        className="w-full"
                        color="taxes"
                      >
                        {t('settings.controllers.continue')}
                      </Button>
                    )}
                    color="taxes"
                    width="lg"
                    dialogPanalClassName="pt-8 pb-6 px-10"
                    render={() => (
                      <DeleteConfirmationModal
                        onConfirmation={deleteAccountHandler}
                        isDeleting={isLoading}
                      />
                    )}
                  />
                )}
              </form>
            </FormProvider>
          )}

          <p className="text-xs text-center text-red-500 leading-4 px-3 my-3 mt-4">
            {t('settings.delete_account.deletion_alert')}
          </p>
        </main>
      )}
    />
  );
};

export default AccountDeletion;
