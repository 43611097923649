import _isNumber from 'lodash/isNumber';
import sidebarState from 'store/sidebarState';

export const useSidebarState = sidebarState();

const useUpdateSidebarDocCounts = () => {
  const { state: sidebarState, setState: setSidebarState } = useSidebarState();

  return (countProp: string, value?: number) => {
    setTimeout(() => {
      setSidebarState({
        [countProp]:
          _isNumber(value) || value === null
            ? value
            : (sidebarState?.[countProp] || 0) + 1, // Just increment the latest stored value
      });
    }, 1000);
  };
};

export default useUpdateSidebarDocCounts;
