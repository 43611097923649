import { LightBulbIcon } from '@heroicons/react/outline';
import darkCirclesCornerArtImage from 'assets/svg/dark_circles_corner_art.svg';
import givingCoinsIcon from 'assets/svg/icons/giving-coins.svg';
import MoneyCounter from 'components/atoms/MoneyCounter';
import TitledWidget from 'components/molecules/TitledWidget';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { type YearlyReviewData } from 'types/global.types';
import { isNumeric } from 'utils/math';
import { getReviewYears, prependPositiveSign } from './helpers';

const EarningsSection: FC<{ data?: YearlyReviewData }> = ({ data }) => {
  const { t } = useTranslation();

  const lastYearTurnover = data?.lastYear?.turnover?.total || 0;

  const thisYearTurnover = data?.thisYear?.turnover?.total || 0;

  const thisYearNetEarnings = data?.thisYear?.netEarnings?.afterTax || 0;

  const lastYearNetEarnings = data?.lastYear?.netEarnings?.afterTax || 0;

  const gainRelevantToLastYearPercentage =
    ((thisYearTurnover - lastYearTurnover) / lastYearTurnover) * 100;

  const earningsGainOrLossPercentage =
    ((thisYearNetEarnings - lastYearNetEarnings) / lastYearNetEarnings) * 100;

  const { year, previousYear } = getReviewYears();

  return (
    <section className="flex items-stretch justify-evenly gap-4">
      <TitledWidget
        title={t('yearly_review.total_revenue_widget.title')}
        initial={{ opacity: 0, translateY: 100 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ ease: 'easeOut', duration: 0.6 }}
        innerWrapperClassName="!h-full"
        subtitle={`(${t('yearly_review.exc_vat')})`}
        containerClassName="w-6/12 !pr-0"
        Icon={() => <img src={givingCoinsIcon} />}
        color="revenues"
      >
        <div className="flex flex-col items-center flex-1 h-full pt-10 pb-0">
          <div className="w-full text-center">
            <div className="font-avenir text-4xl mb-3 font-semibold">
              <MoneyCounter
                amount={thisYearTurnover}
                currency="EUR"
                stateId="yearlyReviewRevenuesTotal"
                noFractions
              />
            </div>

            {isNumeric(gainRelevantToLastYearPercentage) ? (
              <span className="px-3 py-[3px] rounded-full border border-primary-100 font-semibold text-primary-700 text-sm">
                {prependPositiveSign(
                  Math.floor(gainRelevantToLastYearPercentage),
                )}
                % vs {previousYear}
              </span>
            ) : null}
          </div>

          <div className="w-full mt-5 flex flex-col flex-1 justify-center">
            <div className="flex items-center justify-between border-b border-primary-50 py-3">
              <span className="font-semibold text-lg text-primary-700">
                {t('yearly_review.total_revenue_widget.clients')}
              </span>

              <span className="font-semibold text-lg text-primary-900">
                {data?.thisYear?.clients?.count || '-'}
              </span>
            </div>

            <div className="flex items-center justify-between border-b border-primary-50 py-3">
              <span className="font-semibold text-lg text-primary-700">
                {t('yearly_review.total_revenue_widget.Invoices')}
              </span>

              <span className="font-semibold text-lg text-primary-900">
                {data?.thisYear?.invoices?.totalCount?.total || '-'}
              </span>
            </div>

            <div className="flex items-center justify-between pt-3">
              <span className="font-semibold text-lg text-primary-700">
                {t('yearly_review.total_revenue_widget.time_to_get_paid')}
              </span>

              <span className="font-semibold text-lg text-primary-900">
                {data?.thisYear?.paymentTerms?.mean
                  ? Math.floor(data?.thisYear?.paymentTerms?.mean)
                  : '-'}
              </span>
            </div>
          </div>
        </div>
      </TitledWidget>

      <TitledWidget
        title={
          thisYearNetEarnings < 0
            ? t('yearly_review.profit_widget.loss_title')
            : t('yearly_review.profit_widget.earnings_title')
        }
        innerWrapperClassName="!h-full"
        containerClassName="w-6/12 !pr-0"
        Icon={() => <img src={givingCoinsIcon} />}
        cornerArtSrc={darkCirclesCornerArtImage}
        initial={{ opacity: 0, translateY: 100 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ ease: 'easeOut', delay: 0.1, duration: 0.6 }}
      >
        <div className="flex flex-col items-center flex-1 h-full pt-10 pb-0">
          <div className="w-full text-center">
            <div className="font-avenir text-4xl mb-3 font-semibold">
              <MoneyCounter
                amount={thisYearNetEarnings}
                currency="EUR"
                stateId="yearlyReviewTotalEarnings"
                noFractions
              />
            </div>

            {isNumeric(earningsGainOrLossPercentage) ? (
              <span className="px-3 py-[3px] rounded-full border border-primary-100 font-semibold text-primary-700 text-sm">
                {prependPositiveSign(Math.floor(earningsGainOrLossPercentage))}%
                vs {previousYear}
              </span>
            ) : null}
          </div>

          <div className="w-full mt-5 flex flex-col flex-1 justify-center">
            <div className="flex items-start bg-primary-25 rounded-lg p-4">
              <LightBulbIcon className="w-16 mr-4 text-primary-700 mt-2" />

              <p className="text-primary-700">
                {t('yearly_review.profit_widget.alert_text', {
                  year,
                })}
              </p>
            </div>
          </div>
        </div>
      </TitledWidget>
    </section>
  );
};

export default EarningsSection;
