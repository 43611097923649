import classNames from 'classnames';
import type { ComponentProps, FC, PropsWithChildren } from 'react';

const ContainerWithBorder: FC<
  PropsWithChildren<
    ComponentProps<'div'> & {
      containerClassName?: string;
      innerWrapperClassName?: string;
    }
  >
> = ({ children, innerWrapperClassName, containerClassName }) => (
  <div
    className={classNames(
      'bg-primary-25 p-[6px] rounded-lg h-full',
      containerClassName,
    )}
  >
    <div
      className={classNames(
        'rounded-lg border border-gray-200 w-full bg-white h-full',
        innerWrapperClassName,
      )}
    >
      {children}
    </div>
  </div>
);

export default ContainerWithBorder;
