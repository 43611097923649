import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import { uniq } from 'lodash';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { IExpenseItem } from 'types/expenses.types';
import type { AvailableCountries } from 'types/users.types';
import adaptOldCategorySlug from 'utils/expenses/adaptOldCategorySlugs';
import { pickerIcons as icons } from 'utils/expenses/categories/icons';
import mixedIcon from 'utils/expenses/icons/mixed.svg';
import { XIconOutline } from 'utils/icons';

export type ReturnType = (items?: IExpenseItem[]) => {
  isMixed: boolean;
  isEmpty: boolean;
  text: string;
  icon: FC;
};

const useExpenseItemsCategoriesSummary = () => {
  const country = useCustomerCountry() as AvailableCountries;
  const { t, i18n } = useTranslation();

  return useCallback(
    (items: IExpenseItem[] = []) => {
      const categories = (items || [])
        .map((item) => item?.category)
        .filter(Boolean);
      const ids = uniq(categories.map((c) => c.id));

      const isMixed = ids.length > 1;
      const isEmpty = ids.length === 0;

      if (isMixed) {
        return {
          isMixed: true,
          isEmpty: false,
          text: t('mixed_categories'),
          icon: mixedIcon,
        };
      }

      if (isEmpty) {
        return {
          isMixed: false,
          isEmpty: true,
          text: t('missing'),
          icon: XIconOutline,
        };
      }

      return {
        isMixed: false,
        isEmpty: false,
        text: (() => {
          const { displayName, title } = categories[0];
          const titleSlug = adaptOldCategorySlug(country, title, 'title');
          if (!displayName) return t(titleSlug);
          const displayNameSlug = adaptOldCategorySlug(
            country,
            displayName,
            'displayName',
          );
          if (i18n.exists(displayNameSlug)) return t(displayNameSlug);
          return t(titleSlug);
        })(),
        icon: categories[0].icon ? icons[categories[0].icon] || null : null,
      };
    },
    [country, t, i18n],
  );
};

export default useExpenseItemsCategoriesSummary;
