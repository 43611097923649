import type {
  StripeSubscriptionPlan,
  SubscriptionAmbassadorPlan,
  SubscriptionBasicPlan,
  SubscriptionFreePlan,
  SubscriptionManualPlatform,
  SubscriptionMobilePlatform,
  SubscriptionNoWebAccessPlan,
  SubscriptionPaidPlan,
  SubscriptionPlatform,
  SubscriptionPromoPlatform,
  SubscriptionProPlan,
  SubscriptionSmallPlan,
  SubscriptionTrialPlan,
  SubscriptionWebPlatform,
} from 'components/pages/Subscriptions/Subscription.enum';
import {
  stripeSubscriptionPlans,
  subscriptionAmbassadorPlans,
  subscriptionBasicPlans,
  subscriptionFreePlans,
  subscriptionManualPlatforms,
  subscriptionMobilePlatforms,
  subscriptionNoWebAccessPlans,
  subscriptionPaidPlans,
  subscriptionPlatforms,
  subscriptionPromoPlatforms,
  subscriptionProPlans,
  subscriptionSmallPlans,
  subscriptionTrialPlans,
  subscriptionWebPlatforms,
} from 'components/pages/Subscriptions/Subscription.enum';
import dayjs from 'dayjs';
import type { ISubscriptionPlan } from 'types/users.types';
import { SubscriptionPlanNameEnum } from 'types/users.types';

export const isSubscriptionFreePlan = (planName: string | undefined): boolean =>
  subscriptionFreePlans.includes(planName as SubscriptionFreePlan);

export const isSubscriptionTrialPlan = (
  planName: string | undefined,
): boolean =>
  subscriptionTrialPlans.includes(planName as SubscriptionTrialPlan);

export const isSubscriptionBasicPlan = (
  planName: string | undefined,
): boolean =>
  subscriptionBasicPlans.includes(planName as SubscriptionBasicPlan);

export const isSubscriptionProPlan = (planName: string | undefined): boolean =>
  subscriptionProPlans.includes(planName as SubscriptionProPlan);
export const isSubscriptionSmallPlan = (
  planName: string | undefined,
): boolean =>
  subscriptionSmallPlans.includes(planName as SubscriptionSmallPlan);

export const isSubscriptionPaidPlan = (planName: string | undefined): boolean =>
  subscriptionPaidPlans.includes(planName as SubscriptionPaidPlan);

export const isSubscriptionAmbassadorPlan = (
  planName: string | undefined,
): boolean =>
  subscriptionAmbassadorPlans.includes(planName as SubscriptionAmbassadorPlan);

export const isSubscriptionNoWebAccessPlan = (
  planName: string | undefined,
): boolean =>
  subscriptionNoWebAccessPlans.includes(
    planName as SubscriptionNoWebAccessPlan,
  );

export const isSubscriptionPlan = (planName: string | undefined): boolean =>
  stripeSubscriptionPlans.includes(planName as StripeSubscriptionPlan);

export const isSubscriptionManualPlatform = (
  platform: string | undefined,
): boolean =>
  subscriptionManualPlatforms.includes(platform as SubscriptionManualPlatform);

export const isSubscriptionMobilePlatform = (
  platform: string | undefined,
): boolean =>
  subscriptionMobilePlatforms.includes(platform as SubscriptionMobilePlatform);

export const isSubscriptionWebPlatform = (
  platform: string | undefined,
): boolean =>
  subscriptionWebPlatforms.includes(platform as SubscriptionWebPlatform);

export const isSubscriptionPromoPlatform = (
  platform: string | undefined,
): boolean =>
  subscriptionPromoPlatforms.includes(platform as SubscriptionPromoPlatform);

export const isSubscriptionPlatform = (platform: string | undefined): boolean =>
  subscriptionPlatforms.includes(platform as SubscriptionPlatform);

export const isMonthlySubscription = (plan: ISubscriptionPlan): boolean => {
  return (
    [SubscriptionPlanNameEnum.pro1, SubscriptionPlanNameEnum.small1].includes(
      plan?.planInternalName,
    ) &&
    plan?.fromPlatform === 'stripe' &&
    dayjs().subtract(2, 'month').isAfter(dayjs(plan.start))
  );
};

export const isEligibleForOptInBeta = (plan: ISubscriptionPlan) => {
  return (
    subscriptionProPlans.includes(plan?.planInternalName as any) &&
    dayjs().subtract(15, 'month').isAfter(dayjs(plan.start))
  );
};
