import { updateUserAIUsageConsent } from 'api/v2/users/settings';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { UserCacheKeys } from 'types/cacheKeys.types';
import { IUser, UserAiUsageConsentEnum } from 'types/users.types';

type UseAIUsageConsentProps = {
  onUpdate?: (newConsent: UserAiUsageConsentEnum) => void;
};

type UseAIUsageConsentReturn = {
  consent: UserAiUsageConsentEnum;
  patchConsent: (consent: UserAiUsageConsentEnum) => void;
  isLoading?: boolean;
};

const useAIUsageConsent = ({
  onUpdate,
}: UseAIUsageConsentProps): UseAIUsageConsentReturn => {
  const queryClient = useQueryClient();
  const { data: user } = useGetCurrentCustomerData();

  const onConsentUpdate = useCallback(
    (user: IUser) => {
      queryClient.invalidateQueries(UserCacheKeys.UserData);
      onUpdate?.(user?.aiUsageConsent || UserAiUsageConsentEnum.deny);
    },
    [queryClient, onUpdate],
  );

  const { mutateAsync: patchConsent, isLoading } = useMutation(
    (consent: UserAiUsageConsentEnum) => updateUserAIUsageConsent(consent),
    { onSuccess: onConsentUpdate },
  );

  return {
    consent: user?.aiUsageConsent || UserAiUsageConsentEnum.deny,
    patchConsent,
    isLoading,
  };
};

export default useAIUsageConsent;
