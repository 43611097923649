import uniq from 'lodash/uniq';
import type {
  flatOption,
  ObjectWithValue,
  optionsDict,
  value,
} from './select.types';

const flatOptions = (options: ObjectWithValue[]): flatOption[] => {
  return options.flatMap((option: any) =>
    option.options ? flatOptions(option.options) : option,
  );
};

export const getOptionsDict = (options: ObjectWithValue[] | undefined) => {
  if (!options) return {};
  return flatOptions(options).reduce(
    (acc, option) => Object.assign(acc, { [option.value]: option }),
    {},
  );
};

export const getSelectedOptionsFromDict = ({
  value,
  optionsDict,
  isMulti,
}: {
  value: value;
  optionsDict: optionsDict;
  isMulti?: boolean;
}): flatOption | flatOption[] | null => {
  if (value == null) return null;
  if (isMulti) {
    return uniq(value as [])
      .filter((key) => key != null)
      .map(
        (key: string) =>
          optionsDict[key] || { label: key, value: key, __isCreated: true },
      );
  }
  return (
    optionsDict[value as string] ||
    ({ label: value, value, __isCreated: true } as flatOption)
  );
};
