import axiosInstance from 'api/axiosInstance';
import { getTaxBySlug } from 'api/v2/taxes';
import { maybeAskForTrustpilotReview } from 'components/organisms/TrustpilotReview';
import { stringify } from 'query-string';
import type { OptionType, Period } from 'types/global.types';
import type { ITaxSubmission } from 'types/tax-submissions.types';
import type {
  CivilStatusEnum,
  ITax,
  ITaxSubmissionBody,
} from 'types/taxes.types';
import { TransactionCategoryEnum } from 'types/transactions.types';
import { AvailableCountries } from 'types/users.types';
import {
  CATEGORY_SOCIAL_CONTRIBUTIONS,
  CATEGORY_TAXES_AND_INSURANCE_VAT_PAYMENT,
  CATEGORY_TAXES_AND_INSURANCE_VAT_REIMBURSEMENT,
} from 'utils/categories';
import { onSubmitTaxGTM, onUpdateTaxGTM } from 'utils/GTM';
import getPeriodSlug from 'utils/taxes/getPeriodSlug';
import isTaxDone from 'utils/taxes/isTaxDone';

type AmountQuery = {
  year?: number;
  endDate?: string;
  startDate?: string;
  month?: number;
  quarter?: number;
};

export const getPeriodIncome = (
  query: AmountQuery,
): Promise<{
  amount: number;
}> => {
  return axiosInstance.get(
    `/v1/calculations/invoices/income?${stringify(query)}`,
  );
};

export const getPeriodDeductible = (
  query: AmountQuery,
): Promise<{
  amount: number;
}> => {
  return axiosInstance.get(
    `/v1/calculations/expenses/deductible?${stringify(query)}`,
  );
};

export const getTaxes = (): Promise<ITax[]> => {
  return axiosInstance.get('/v1/taxes');
};

const keysTypesMap: Record<
  AvailableCountries,
  Record<string, { type: string; isPayable: (tax: any) => boolean }>
> = {
  [AvailableCountries.belgium]: {
    [TransactionCategoryEnum.incomeTaxPayment]: {
      type: 'be/income-tax-payment',
      isPayable: (tax) => tax?.incomeTaxToPay > 0,
    },
    [TransactionCategoryEnum.incomeTaxPrePayment]: {
      type: 'be/income-tax-prepayment',
      isPayable: (tax) => tax?.suggestedPrepaymentAmount > 0,
    },
    [TransactionCategoryEnum.incomeTaxReimbursement]: {
      type: 'be/income-tax-payment',
      isPayable: (tax) => tax?.incomeTaxToPay < 0,
    },
    [TransactionCategoryEnum.VATPayment]: {
      type: 'be/vat',
      isPayable: (tax) =>
        tax?.payment?.amount > 0 && tax?.payment?.toClaim === false,
    },
    [TransactionCategoryEnum.VATReimbursement]: {
      type: 'be/vat',
      isPayable: (tax) =>
        tax?.payment?.amount > 0 && tax?.payment?.toClaim === true,
    },
    [CATEGORY_SOCIAL_CONTRIBUTIONS]: {
      type: 'be/social-contribution-subject',
      isPayable: (tax) => tax?.settings?.quarterPayment > 0,
    },
  },
  [AvailableCountries.germany]: {
    [TransactionCategoryEnum.incomeTaxPayment]: {
      type: 'de/est-payment',
      isPayable: (tax) => tax?.incomeTaxToPay > 0,
    },
    [TransactionCategoryEnum.incomeTaxPrePayment]: {
      type: 'de/est-prepayment',
      isPayable: (tax) => tax?.totalTaxAmountRequested > 0,
    },
    [TransactionCategoryEnum.incomeTaxReimbursement]: {
      type: 'de/est-payment',
      isPayable: (tax) => tax?.incomeTaxToPay < 0,
    },
    [CATEGORY_TAXES_AND_INSURANCE_VAT_PAYMENT]: {
      type: 'de/ustva',
      isPayable: (tax) =>
        tax?.payment?.amount > 0 && tax?.payment?.toClaim === false,
    },
    [CATEGORY_TAXES_AND_INSURANCE_VAT_REIMBURSEMENT]: {
      type: 'de/ustva',
      isPayable: (tax) =>
        tax?.payment?.amount > 0 && tax?.payment?.toClaim === true,
    },
  },
};

const reimbursementKeys: string[] = [
  TransactionCategoryEnum.incomeTaxReimbursement,
  TransactionCategoryEnum.VATReimbursement,
  CATEGORY_TAXES_AND_INSURANCE_VAT_REIMBURSEMENT,
];

export const hasPayableTax = (
  key: string,
  period: Period,
  country: AvailableCountries,
): Promise<{ isPayable: boolean; isReimbursement: boolean; tax: ITax }> => {
  const typeData = keysTypesMap[country][key];
  const isReimbursement = reimbursementKeys.includes(key);
  if (!typeData)
    return Promise.resolve({
      isPayable: false,
      isReimbursement,
      tax: {} as ITax,
    });
  const periodSlug = getPeriodSlug({ period });
  const slug = `${typeData.type}/${periodSlug}`;
  return getTaxBySlug(slug)
    .then((tax) => {
      if (!tax || isTaxDone(tax))
        return { isPayable: false, isReimbursement, tax };
      return { isPayable: typeData.isPayable(tax), isReimbursement, tax };
    })
    .catch(() => ({ isPayable: false, isReimbursement, tax: {} as ITax }));
};

export const getTaxesByYear = (year: number): Promise<any> => {
  return axiosInstance.get(`/v2/taxes/${year}`);
};

export const checkIsIncomeTaxSubmitable = (
  year: number,
): Promise<{ filled: boolean }> => {
  return axiosInstance.get(`/v1/taxes/income-tax/details?year=${year}`);
};

export const getReligions = (): Promise<string[]> => {
  return axiosInstance.get('/v1/users/religions');
};

export const updateTax = async (tax: ITax): Promise<ITax> => {
  return axiosInstance
    .put<any, ITax>(`/v1/taxes/${tax._id}`, tax)
    .then((data) => {
      onUpdateTaxGTM(tax.period, tax.readableType);
      maybeAskForTrustpilotReview();
      return data;
    });
};

export const submitTax = ({
  tax,
  body,
}: {
  tax: ITax;
  body: ITaxSubmissionBody;
}): Promise<ITaxSubmission> => {
  return axiosInstance
    .post(`/v1/taxes/${tax?._id}/submit`, body)
    .then((res) => {
      onSubmitTaxGTM(tax.period, tax.readableType);

      maybeAskForTrustpilotReview();

      const data = { ...res, tax } as unknown as Promise<ITaxSubmission>;
      return data;
    });
};

export const getMunicipalities = (year: number): Promise<OptionType[]> => {
  return axiosInstance
    .get('/v1/users/municipalities', {
      params: { year },
    })
    .then((municipalities) =>
      (municipalities as unknown as string[]).map((municipality: string) => ({
        label: municipality,
        value: municipality,
      })),
    );
};
export type UpdateIncomeTaxSetupBodyDE = {
  year: number;
  finanzamt?: string;
  bufaNumber?: string;
  religion?: string;
  civilStatus?: string;
  taxableIncomeAsEmployee?: number;
  healthInsuranceAmountSelfEmployed?: number;
  healthInsurancePrivateBasicCoverageAmount?: number;
  healthInsurancePrivateOptionalServicesAmount?: number;
  nursingInsuranceAmountSelfEmployed?: number;
  sickBenefitsAmount?: number;
  birthDate?: string; // iso date
  domicileAddress?: {
    zip?: number;
    street?: string;
    city?: string;
    municipality?: string;
    state?: string;
  };
  taxableIncomeForLast4Years?: number;
  selfEmploymentType?: string;
};

export type UpdateIncomeTaxSetupBodyBE = {
  year: number;
  birthDate?: string; // iso date
  domicileAddress?: {
    zip?: number;
    street?: string;
    city?: string;
    municipality?: string;
    state?: string;
  };
  civilStatus?: CivilStatusEnum;
  civilStatusStartDate?: string;
  children?: string[]; // iso dates array
  taxableIncomeAsEmployee?: number | null;
  taxableIncomeForLast4Years?: number;
  selfEmploymentType?: string;
};

export const updateIncomeTaxSetup = (
  params: UpdateIncomeTaxSetupBodyDE | UpdateIncomeTaxSetupBodyBE,
): Promise<any> =>
  axiosInstance.post('/v1/taxes/income-tax/submission-user-details', params);

export type AmountByQuarter = {
  Q1: number | null;
  Q2: number | null;
  Q3: number | null;
  Q4: number | null;
};

export type UpdateYearlyPrepaymentsBody = {
  year: number;
  solidarityTax?: AmountByQuarter;
  churchTax?: AmountByQuarter;
  incomeTax?: AmountByQuarter;
  state?: string;
  steuernummer?: string;
};

export const updateQuarterlyPrepaymentSetup = (
  params: UpdateYearlyPrepaymentsBody,
) => axiosInstance.post('/v1/taxes/income-tax/yearly-prepayments', params);

export enum PrepaymentGainMethod {
  aggressive = 'aggressive',
}

type GetPrepaymentGainParams = {
  year: number;
  quarter?: number;
  method?: PrepaymentGainMethod;
};

type GetPrepaymentGainResponse = { amount: number };

export const getPrepaymentGain = (
  params: GetPrepaymentGainParams,
): Promise<GetPrepaymentGainResponse> =>
  axiosInstance.get('/v1/calculations/income-tax/prepayment-gain', { params });

type GetPrepaymentGainSetupParams = {
  year: number;
  type: string;
};

interface Options {
  none: {
    lostBenefit: number;
  };
  gradual: {
    totalBenefit: number;
    totalPrepayment: number;
  };
  frontLoaded: {
    totalBenefit: number;
    totalPrepayment: number;
  };
}

interface GetGainSetupResponse {
  isUserStarter: boolean;
  accountType: string;
  projectedProfit: number;
  options: Options;
}

export const getPrepaymentGainSetup = (
  params: GetPrepaymentGainSetupParams,
): Promise<GetGainSetupResponse> =>
  axiosInstance.get('/v2/calculations/prepayment-gain', { params });
