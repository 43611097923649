import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import dayjs from 'dayjs';
import type { FC, ReactNode } from 'react';
import type { color } from 'types/shared/worlds';
import type { Transaction } from 'types/transactions.types';
import formatMoney from 'utils/formatMoney';
import { CheckIconOutline, TrashIcon, XIconOutline } from 'utils/icons';

export const revenueAlternativeTransactionsOptions = [
  {
    value: 'otherBank',
    label: 'other_revenue.payment.other_bank',
    preset: true,
  },
  {
    value: 'cash',
    label: 'other_revenue.payment.cash',
    preset: true,
  },
];

export const TransactionOption: FC<{
  transaction: Transaction;
  className?: string;
}> = ({ transaction, className }) => {
  const { counterPartyName, amount, currency, communication, valueDate } =
    transaction;
  return (
    <div className={classNames(className, 'overflow-hidden')}>
      <div className="flex justify-between text-sm font-semibold">
        <span className="min-w-0 break-words">{counterPartyName}</span>
        <span>{formatMoney(amount, currency)}</span>
      </div>
      <div className="flex flex-wrap justify-between text-xs">
        <span className="min-w-0 break-words">{communication}</span>
        <span>{dayjs(valueDate).format('MMM D, YYYY')}</span>
      </div>
    </div>
  );
};

export const TransactionOptionPreset: FC<{
  label: string;
  className?: string;
}> = ({ label, className }) => {
  return (
    <span className={classNames('text-sm font-semibold', className)}>
      {label}
    </span>
  );
};

export const TransactionGroup: FC<{ title: string; subTitle: string }> = ({
  title,
  subTitle,
}) => {
  return (
    <div className="py-2">
      <p className="text-lg text-semibold text-primary">{title}</p>
      <p className="text-sm">{subTitle}</p>
    </div>
  );
};

type SelectedOptionContainerProps = {
  onDelete: () => void;
  selectedOptionUI: ReactNode;
  isDisabled?: boolean;
  color?: color;
  className?: string;
};

export const SelectedOptionContainer: FC<SelectedOptionContainerProps> = ({
  onDelete,
  selectedOptionUI,
  isDisabled,
  color,
  className,
}) => {
  return (
    <div
      className={classNames(
        'bg-primary-25 px-4 py-3.5 rounded-md text-main flex gap-6',
        className,
      )}
    >
      {selectedOptionUI}
      <Button
        structure="text"
        className="!p-2 self-start"
        color={color}
        onClick={onDelete}
        disabled={isDisabled}
      >
        <TrashIcon
          className={classNames('w-6', { 'text-gray-400': isDisabled })}
        />
      </Button>
    </div>
  );
};

export const SuggestedTransactionContainer: FC<{
  transaction: Transaction;
  className?: string;
  onAccept: () => void;
  onDecline: () => void;
}> = ({ transaction, className, onAccept, onDecline }) => {
  return (
    <div
      className={classNames(
        className,
        'bg-primary-25 px-4 py-3.5 rounded-md text-main flex gap-6',
      )}
    >
      <TransactionOption transaction={transaction} className="flex-1" />
      <div className="flex gap-3 self-start">
        <Button
          color="expenses"
          className="!p-1 flex-shring-0"
          onClick={onDecline}
        >
          <XIconOutline className="w-4" />
        </Button>
        <Button
          color="revenues"
          className="!p-1 flex-shring-0"
          onClick={onAccept}
        >
          <CheckIconOutline className="w-4" />
        </Button>
      </div>
    </div>
  );
};
