import type SVGType from 'types/svg.types';

const ChristmasIcon: SVGType = (props) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.7353 15H22.9997L15.9997 6.33334L8.99967 15H12.264L7.66634 20.3333H11.6663L6.33301 25.6667H25.6663L20.333 20.3333H24.333L19.7353 15Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        fill="none"
      />
    </svg>
  );
};

export default ChristmasIcon;
