import { createExpertRouteURL } from 'applications/expert/routing/navigationGenerator';
import AIIcon from 'components/atoms/SVG/sidebarIcons/AIIcon.component';
import NavigationItem from 'components/organisms/Sidebar/NavigationItem.component';

import { SidebarNavigationItemTypesEnum } from 'components/organisms/Sidebar/types';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useCustomNavigate from 'hooks/useCustomeNavigate';
import { useTranslation } from 'react-i18next';
import { logAmplitude } from 'utils/amplitude';
import { WEB_AIASSISTANT_USEROPENED } from 'utils/amplitude/events/ai.amplitude';
import { isWeb } from 'utils/constants';

const AIAssistantSidebar = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  // const openedAIAssistant = localStorage.getItem('opened-ai-assistant');
  const { data: user } = useGetCurrentCustomerData();
  return (
    <>
      <NavigationItem
        item={{
          title: 'ai_bot.title',
          renderTitle: () => (
            <span>
              <span className="block">{t('ai_bot.title')}</span>
              <span className="rounded-2xl self-center inline-block text-taxes !font-semibold bg-taxes-200 text-center !text-[10px] px-2.5 py-1">
                Beta
              </span>
            </span>
          ),
          Icon: AIIcon,
          type: SidebarNavigationItemTypesEnum.AI,
          to: isWeb
            ? '/assistant'
            : createExpertRouteURL(user?._id, '/assistant'),

          onClick: () => {
            logAmplitude(WEB_AIASSISTANT_USEROPENED);
            navigate('/assistant');
          },
        }}
      />
    </>
  );
};

export default AIAssistantSidebar;
