import { getExpenses } from 'api/v2/expenses';
import { Sort } from 'components/atoms/Table/table.types';
import { useExpensesFiltersStore } from 'hooks/Expenses/useExpensesFilters';
import type { ExtraUseTableOptions } from 'hooks/tableQuery/tableQuery.types';
import useTableQuery from 'hooks/tableQuery/useTableQuery';
import { useEffect } from 'react';
import tableState from 'store/tableState/tableState.store';
import { ExpensesCacheKeys } from 'types/cacheKeys.types';
import { isExpert } from 'utils/constants';

const { filtersData } = useExpensesFiltersStore.getState();
const useTableState = tableState({
  filtersData,
  tableSorter: { cellId: 'expenseDate', direction: Sort.desc },
});

const primaryFilterKey = isExpert ? 'reviewed' : 'isValidated';

const userTypeBasedDefaults = { [primaryFilterKey]: 'false' };

const emptyResult = {
  data: [],
  paging: {
    page: 1,
    perPage: 1,
    pagesCount: 0,
    totalCount: 0,
  },
};

const useExpensesToReview = (options?: ExtraUseTableOptions) => {
  const tableState = useTableState();
  useEffect(() => {
    return useExpensesFiltersStore.subscribe((state, prev) => {
      if (state.filtersData === prev.filtersData) return;
      tableState.controllers.setFiltersData(state.filtersData);
    });
  }, []);

  return useTableQuery({
    tableState,
    cacheKey: ExpensesCacheKeys.ExpensesToReview,
    onFetch: (params) => {
      if (params?.[primaryFilterKey] === 'true') {
        return Promise.resolve(emptyResult);
      }
      return getExpenses({
        ...userTypeBasedDefaults,
        ...params,
        page: 1,
        perPage: 1,
      });
    },
    ...options,
  });
};

export default useExpensesToReview;
