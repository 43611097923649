import type { AxiosError } from 'axios';
import _get from 'lodash/get';
import i18n from '../i18n';

type ServerErrorWrapperType = { errors: ServerErrorObjectType[]; type: string };
type ServerErrorObjectType = {
  message: string;
  code: string;
  collection?: string;
};

// List of error codes we need to silence/avoid showing an error toast for
const neglectedErrors = [
  'no-auth-token',
  'invalid-refresh-token',
  'backend.errors.unexpected_error',
  'invitation.errors.accountant_not_found',
  'no-account-connected',
];

const customTypes = ['forbidden_request_error'];

const translationPrefix = 'backend.errors';

const resolveServerErrors = (error: AxiosError): string[] => {
  const { errors = [], type } = _get(
    error,
    'response.data',
    {},
  ) as ServerErrorWrapperType;

  // Translate the 'type' property directly instead of looping over the errors
  // if the type is one of the `customTypes`
  if (customTypes.includes(type)) return [type];

  // Show a toast message for each error in the array unless it's marked to be neglected
  return errors
    .filter(
      ({ code }: ServerErrorObjectType) => !neglectedErrors.includes(code),
    )
    .map(({ code, message, collection }: ServerErrorObjectType) => {
      if (collection) {
        const key = `${translationPrefix}.${collection}.${code}`;
        if (i18n.exists(key)) return i18n.t(key);
      }
      const key = `${translationPrefix}.${code}`;
      if (i18n.exists(key)) return i18n.t(key);

      if (message) return message;
      return i18n.t(`${translationPrefix}.something_went_wrong`);
    });
};

export { resolveServerErrors };
