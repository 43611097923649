import { useQuery, useQueryClient } from 'react-query';
import { QRCodeKeys } from 'types/cacheKeys.types';

const usePayWithQRCode = () => {
  const queryClient = useQueryClient();
  const { data } = useQuery(QRCodeKeys.PaidList, () => [], {
    cacheTime: Infinity,
    staleTime: Infinity,
    initialData: [],
  });
  const api = {
    markAsPaid: (id: string | undefined) => {
      if (!id) return;
      queryClient.setQueryData(QRCodeKeys.PaidList, [...(data as any), id]);
    },
    isPaid: (id: string | undefined) => {
      if (!id) return false;
      return (data as any).includes(id);
    },
  };
  return api;
};

export default usePayWithQRCode;
