import axiosInstance from 'api/axiosInstance';
import { stringify } from 'query-string';
import type { LoginData } from 'types/login.types';
import type { IPaginationResponse } from 'types/pagination.types';

type Enterprise = {
  NACECodes?: {
    main: number[];
    secondary: number[];
  };
  VATNumber: string;
  city?: string;
  name: string;
  street?: string;
  zipcode?: string;
  startDate?: string;
};

type SearchResponse = {
  data: Enterprise[];
  paging: IPaginationResponse;
};

export const searchEntrprises = (
  name?: string,
  country?: string,
  VATNumber?: string,
): Promise<SearchResponse> => {
  return axiosInstance.get(
    'v2/users/search-enterprises?' +
      stringify({ name, country, VATNumber }, { arrayFormat: 'comma' }),
  );
};

type EmailAvailabilityResponse = { exists: boolean; isEmailAlias: boolean };

export const checkEmailAvailability = (body: {
  email?: string;
  googleIdToken?: string;
  appleIdToken?: string;
}): Promise<EmailAvailabilityResponse> =>
  axiosInstance.get<any, EmailAvailabilityResponse>('v2/users/email-check', {
    params: body,
  });

export const signupUser = (data: any): Promise<LoginData> => {
  return axiosInstance.post('v2/users', data);
};
