import { AvatarSize, AvatarStatus, AvatarType } from './Avatar.enum';

export interface AvatarProps {
  type: AvatarType;
  size: AvatarSize;
  status?: AvatarStatus;
  src: string;
  tooltipText?: string;
}

type AvatarSizeToClassMap = Record<
  AvatarSize,
  { AvatarSize: string; roundedSize: string }
>;

type AvatarStatusToClassMap = Record<AvatarStatus, { roundedColor: string }>;

type CustomAvatarTypeMap = Record<
  AvatarType,
  { roundedClass?: string; position?: string }
>;

export const avatarSizeToClassMap: AvatarSizeToClassMap = {
  [AvatarSize.xs]: { AvatarSize: 'h-6 w-6', roundedSize: 'h-1.5 w-1.5' },
  [AvatarSize.sm]: { AvatarSize: 'h-7 w-7', roundedSize: 'h-2 w-2' },
  [AvatarSize.md]: { AvatarSize: 'h-10 w-10', roundedSize: 'h-2.5 w-2.5' },
  [AvatarSize.lg]: { AvatarSize: 'h-12 w-12', roundedSize: 'h-3 w-3' },
  [AvatarSize.xl]: { AvatarSize: 'h-14 w-14', roundedSize: 'h-3.5 w-3.5' },
  [AvatarSize['2xl']]: { AvatarSize: 'h-16 w-16', roundedSize: 'h-4 w-4' },
};

export const avatarStatusToClassMap: AvatarStatusToClassMap = {
  [AvatarStatus.online]: { roundedColor: 'bg-green-400' },
  [AvatarStatus.offline]: { roundedColor: 'bg-gray-300' },
  [AvatarStatus.busy]: { roundedColor: 'bg-red-400' },
};

export const customAvatarTypeMap: CustomAvatarTypeMap = {
  [AvatarType.circular]: {
    roundedClass: 'rounded-full',
  },
  [AvatarType.rounded]: {
    roundedClass: 'rounded-md',
  },
  [AvatarType.circularWithTopNotification]: {
    roundedClass: 'rounded-full',
    position: 'top-0',
  },
  [AvatarType.roundedWithTopNotification]: {
    roundedClass: 'rounded-md',
    position: 'top-0 transform -translate-y-1/2 translate-x-1/2',
  },
  [AvatarType.circularWithBottomNotification]: {
    roundedClass: 'rounded-full',
    position: 'bottom-0',
  },
  [AvatarType.roundedWithBottomNotification]: {
    roundedClass: 'rounded-md',
    position: 'bottom-0 transform translate-y-1/2 translate-x-1/2 ',
  },
};
