import { FontOptionType } from 'api/google-fonts';
import { RecurringData } from 'components/organisms/RecurringField/helpers';
import type { AccountantReview } from 'types/accountantReview.types';
import type { OtherRevenueClient, RevenueClient } from 'types/clients.types';
import type {
  Currency,
  DocumentSubmission,
  LockStatusType,
  Period,
} from 'types/global.types';
import type { Transaction } from './transactions.types';
import type {
  AvailableLanguages,
  InvoiceTemplate,
  IUser,
  VATReturnFrequency,
  VATType,
} from './users.types';
import type { DEVehicle } from './vehicle.types';

export enum SVGIconInvoiceCategoriesEnum {
  percentage = 'percentage',
  copyright = 'copyright',
  royalties = 'royalties',
  indemnity = 'indemnity',
  subsidy = 'subsidy',
  donation = 'donation',
  goods = 'goods',
  services = 'services',
  rent = 'rent',
  insurance = 'insurance',
  bank = 'bank',
  commission = 'commission',
  gift = 'gift',
  otherIncome = 'otherIncome',
  refunds = 'refunds',
  covid = 'covid',
  sales = 'sales',
  mixed = 'mixed',
}

export type InvoiceCategoryIcon = keyof typeof SVGIconInvoiceCategoriesEnum;

export type InvoiceCategory = {
  id: string;
  accountingCode: number | any;
  separatelyTaxable: boolean;
  taxRate?: string | number;
  admitsVAT: boolean;
  icon: InvoiceCategoryIcon;
  revenueType: InvoiceType[];
  customType?: string;
};

export type InvoiceCategoryGroup = {
  label: string;
  icon: InvoiceCategoryIcon;
  children: InvoiceCategory[];
  revenueType: InvoiceType[];
};

export type UnparsedInvoiceCategory = InvoiceCategory;

export enum InvoiceTypeEnum {
  INVOICE = 'invoice',
  OTHER_REVENUE = 'other-revenue',
  CREDIT_NOTE = 'credit-note',
  QUOTE = 'quote',
}

export enum QuoteStatusEnum {
  sent = 'sent',
  notSent = 'not-sent',
  approved = 'approved',
  rejected = 'rejected',
}

export enum InvoiceTemplatesEnum {
  straight = 'straight',
  basic = 'basic',
  rounded = 'rounded',
  circle = 'circle',
}

export enum WhyZeroVATEnum {
  user_franchisee = 'user-franchisee',
  local_reverse_charge = 'local-reverse-charge',
  intra_eu_reverse_charge = 'intra-eu-reverse-charge',
  extra_eu_export = 'extra-eu-export',
  exempt_item = 'exempt-item',
  diplomatic = 'diplomatic',
}

export type InvoiceCreatedBy = 'user';
export type InvoiceType = 'invoice' | 'other-revenue' | 'credit-note' | 'quote';
export type InvoiceFileType = 'imported' | 'generated';
export type InvoiceStatusWithoutDraft = 'sent' | 'not-sent' | 'paid';
export type InvoiceStatus = InvoiceStatusWithoutDraft | 'draft';
export type QuoteStatus = 'sent' | 'not-sent' | 'approved' | 'rejected';
export type OtherRevenueStatus = 'sent' | 'paid';
export type InvoiceCreatedFrom =
  | 'email'
  | 'mobile'
  | 'web'
  | 'dropzone'
  | 'experts'
  | 'bank';
export type InvoicePaymentType = 'cash' | 'otherBank' | null;
export type InvoiceOriginalTransaction = {
  counterPartyName: string;
  communication: string;
};

export enum InvoiceStatusEnum {
  sent = 'sent',
  notSent = 'not-sent',
  paid = 'paid',
  draft = 'draft',
}

// TODO: rename to invoice once v1 has been removed
type InvoiceBase<ClientType> = {
  modifiableCustomerData: IUser;
  id: string;
  _id: string;
  userId: string;

  isSuggestedPeriod?: boolean;

  isValidated: boolean;
  paymentAmount: number;
  client: ClientType;
  clientId: string;
  totalVATAmount: number;
  createdFrom: InvoiceCreatedFrom;
  totalAmountExclVAT: number;
  totalAmountInclVAT: number;
  baseCurrencyTotalVATAmount: number;
  baseCurrencyTotalAmountExclVAT: number;
  baseCurrencyTotalAmountInclVAT: number;
  downPayment?: number;
  transactions: string[]; // array transaction ids
  _matchedTransactions: Transaction[];
  originalTransaction?: InvoiceOriginalTransaction;
  deleted: boolean;
  dueDate: string; // Date on which the client has to pay the user
  filePath: string; // path in s3 bucket
  fileType: InvoiceFileType;

  filePathUbl21?: string;
  filePathUblBe?: string;

  scriptVersion: string; // back-end script version used to generate the invoice

  created: string;
  modified: Date;
  userSnapshot: Partial<IUser>;
  // user?: Partial<IUser>;
  token: string; // Used to allow client to see the invoice with URL
  comments?: string;
  lastHash: string; // not used on mobile
  accountantReview: AccountantReview;
  paymentDate: string; // only for German users
  deliveryDate: string; // only for German users
  paymentType?: InvoicePaymentType;
  period: Period;
  submissions: DocumentSubmission[];
  currency: Currency;
  currencyRate: number;
  baseCurrency: Currency;
  items: InvoiceItem[];
  notes?: string;
  computedItems?: InvoiceItem[];
  taxPeriod?: any;
  guessedInvoiceNumber?: string;
  shouldUpdateTaxStatus?: boolean;
  emailSeenAt?: string;
  emailSentAt?: string;
  emailId?: string;
  communication?: string;
  paymentQrCode?: string;
  guessedCommunication?: string;
  taxLock?: LockStatusType;
  guessedDeliveryDate?: string;
  din5008?: boolean;
};

export type Invoice<ClientType = OtherRevenueClient | RevenueClient> =
  InvoiceBase<ClientType> & {
    type: InvoiceType;
    status: InvoiceStatus;
    revenueNumber: string;
    invoiceDate: string;
    recurringId?: string;
    quoteId?: string;
    showDeliveryPeriod?: boolean; // TODO remove this
    deliveryPeriod?: {
      start: string;
      end: string;
    };
    chiftExportIds?: string[];
    customType?: string;
    customData?: {
      usageStartDate?: string;
      usageEndDate?: string;
      vehicle?: DEVehicle;
      vehicleId?: string;
      isVehicleUsedForOfficeCommute?: boolean;
      distanceBetweenHomeAndOffice?: number;
      customCategoryId?: string;
      customCategory?: InvoiceCategory;
    };
  };

export type InvoiceFormValues<ClientType = OtherRevenueClient | RevenueClient> =
  Partial<Omit<Invoice<ClientType>, 'userSnapshot'>> & {
    clientId?: string;
    user: { VATType: VATType; VATReturnFrequency: VATReturnFrequency };
  };

export type GeneratedInvoiceFormValues = Partial<
  Omit<Invoice<RevenueClient>, 'userSnapshot' | 'items'>
> & {
  items: Partial<InvoiceItem>[];
  defaultRevenueNumber?: string;
  clientId?: string;
  itemsCalculations?: InvoiceItemsCalculations;
  user: Partial<
    Pick<
      IUser,
      | 'companyName'
      | 'VATNumber'
      | 'phoneNumber'
      | 'email'
      | 'steuernummer'
      | 'SWIFT'
      | 'IBAN'
      | 'firstName'
      | 'lastName'
    >
  > & {
    address: Pick<IUser['address'], 'city' | 'zip' | 'street' | 'state'>;
    VATType: VATType;
    VATReturnFrequency: VATReturnFrequency;
  };
  _saveSettings?: boolean;
  settings: Partial<IUser['settings']['invoices']> & {
    _fontFallback?: string;
    _fontOption?: FontOptionType;
    _defaultUseDownpayment: boolean;
  };
};

export type Quote = InvoiceBase<RevenueClient> & {
  status: QuoteStatus;
  quoteNumber: string;
  quoteDate: string;
  expiryDate: string;
  linkedRevenueNumber?: string;
  emailSeenAt?: string;
  actionAt?: string;
  approvedAt?: string;
  rejectedAt?: string;
};

export type GeneratedQuoteFormValues = Partial<
  Omit<Quote, 'userSnapshot' | 'items'>
> & {
  defaultQuoteNumber?: string;
  clientId?: string;
  items: Partial<InvoiceItem>[];
  itemsCalculations?: InvoiceItemsCalculations;
  user: Partial<
    Pick<
      IUser,
      | 'companyName'
      | 'VATNumber'
      | 'phoneNumber'
      | 'email'
      | 'steuernummer'
      | 'firstName'
      | 'lastName'
    >
  > & {
    address: Pick<IUser['address'], 'city' | 'zip' | 'street' | 'state'>;
    VATType: VATType;
    VATReturnFrequency: VATReturnFrequency;
  };
  _saveSettings?: boolean;
  settings: Partial<IUser['settings']['quotes']> & {
    _fontFallback?: string;
    _fontOption?: FontOptionType;
  };
};

export enum InvoiceFileTypeEnum {
  generated = 'generated',
  imported = 'imported',
}

export const isInvoice = (invoice: Invoice): invoice is Invoice =>
  'revenueNumber' in invoice;

export const isRevenueClient = (
  client: RevenueClient | OtherRevenueClient,
): client is RevenueClient => 'type' in client;

// static highlights values are kept in this enum
export enum RevenueHighlights {
  NewRevenue = 'new',
  NewOtherRevenue = 'NewOtherRevenue',
}

export type InvoiceItemWhyZeroVAT =
  | 'diplomatic'
  | 'local-reverse-charge'
  | 'intra-eu-reverse-charge'
  | 'extra-eu-export'
  | 'exempt-item'
  | 'user-franchisee';
export type InvoiceItemUnit = string;
export enum defaultInvoiceItemUnitEnum {
  items = 'items',
  hours = 'hours',
  days = 'days',
}

export type InvoiceItemVATStatus = 'normal' | 'other' | 'co-contractor';
export type InvoiceItemType = 'service' | 'good';

/**
 * !!!
 * All amounts have to be multiplied by 1000 before being sent to the back-end.
 * Decimals aren't allowed anymore.
 * The purpose is to avoid precision errors due to VAT rates.
 * !!!
 */
export type InvoiceItem = {
  id?: string;
  _id?: string;
  _localId?: string; // for drag and drop
  _deleting?: boolean; // for async deleting
  _duplicating?: boolean; // for async duplicating
  _duplicated?: boolean; // for highlighting
  unitAmountExclVAT: number;
  unitAmountInclVAT: number;
  totalAmountExclVAT: number;
  totalVATAmount: number;
  totalAmountInclVAT: number;
  baseCurrencyTotalAmountExclVAT: number;
  baseCurrencyTotalVATAmount: number;
  baseCurrencyUnitPriceExclVAT: number;
  baseCurrencyTotalAmountInclVAT: number;
  baseCurrencyUnitAmountInclVAT: number;
  baseCurrencyUnitAmountExclVAT: number;
  quantity: number;
  VATRate: number;
  categoryId: string;
  category: InvoiceCategory;
  name: string;
  assetId: string;
  unit: InvoiceItemUnit;
  whyZeroVAT: WhyZeroVATEnum;
  whyZeroVATParagraph: string; // legal mention for German users invoicing 0% VAT
  exemptParagraph?: string;
  discountPercentage?: number;
  discountAmount: number;
  baseCurrencyDiscountAmount: number;
  originalUnitAmountExclVAT?: number;
  doesUnitPriceIncludeVAT?: boolean;
  description?: string;
};

export type InvoiceItemsCalculations = {
  calculations: {
    items: {
      VATRate: number;
      discountPercentage: number;
      doesUnitPriceIncludeVAT: boolean;
      quantity: number;
      totalAmountExclVAT: number;
      totalAmountInclVAT: number;
      totalVATAmount: number;
      unitAmountExclVAT: number;
      unitAmountInclVAT?: number;
    }[];
    baseCurrencyTotalAmountInclVAT: number;
    currencyRate: number;
    totalAmountExclVAT: number;
    totalAmountInclVAT: number;
    totalVATAmount: number;
    totalVATAmount_5?: number;
    totalVATAmount_6?: number;
    totalVATAmount_7?: number;
    totalVATAmount_12?: number;
    totalVATAmount_16?: number;
    totalVATAmount_19?: number;
    totalVATAmount_21?: number;
  };
  copyRight?: {
    copyrightRevenueAmountExclTax: number;
    copyrightRevenueTaxAmount: number;
  };
  currencyRate: number;
  legalNotes: Record<AvailableLanguages, string> | null;
};

export type UpdateInvoiceStatusBody = {
  status: InvoiceStatusWithoutDraft;
  paymentDate?: string; // format yyyy-mm-dd (iso)
  period?: Period;
};

export type InvoiceEmailType = {
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  htmlTemplate: string;
  attachInvoicePdf?: boolean;
  attachInvoiceUbl21?: boolean;
  attachInvoiceUblBe?: boolean;
  attachQuotePdf?: boolean;
  enableTracking?: boolean;
  saveTemplate: boolean;
};

type RecurringInvoiceBase = {
  _id: string;
  revenueId?: string;
  _amount?: number; // frontend key
  nextRecurrenceDate: string;
  mostRecentRevenue?: any;
  pausedAt?: string | null;
  pauseReason?:
    | 'client-missing'
    | 'no-revenues'
    | 'end-date-passed'
    | 'manual-pause'
    | null;
  revenueData: {
    client?: RevenueClient | null;
    totalAmountInclVAT: number;
    clientId?: string;
    differenceBetweenInvoiceDateAndDueDate: number;
    currency: Currency;
    paymentAmount?: number;
    din5008?: boolean;
    comments?: string;
    items: InvoiceItem[];
    language: string;
    settings: InvoiceTemplate;
    userSnapshot: Partial<IUser>;
    usePaymentQrCode: boolean;
    useCommunication: boolean;
  };
};

export enum InvoiceLogSlugs {
  created = 'created',

  emailSent = 'email-sent',
  reminderSent = 'reminder-sent',

  emailOpened = 'email-opened',
  reminderOpened = 'reminder-opened',

  emailReceived = 'email-received',
  reminderReceived = 'reminder-received',

  emailBounced = 'email-bounced',
  reminderBounced = 'reminder-bounced',

  peppolSent = 'peppol-sent',
  peppolFailed = 'peppol-failed',
}

type InvoiceCreation = {
  _id: string;
  created: string;
  slug: InvoiceLogSlugs.created;
  data: {
    createdAt: string;
    source: string;
  };
};

type InvoiceEmailOrReminderSent = {
  _id: string;
  created: string;
  slug: InvoiceLogSlugs.emailSent | InvoiceLogSlugs.reminderSent;
  data: {
    to?: string[];
    MessageID: string;
    reminderType?: string;
  };
};

type InvoiceEmailOrReminderOpened = {
  _id: string;
  created: string;
  slug: InvoiceLogSlugs.emailOpened | InvoiceLogSlugs.reminderOpened;
  data: {
    notification?: boolean;
    reminderType?: string;
    Recipient?: string;
    MessageID: string;
    ReceivedAt: string;
  };
};

type InvoiceEmailOrReminderReceived = {
  _id: string;
  created: string;
  slug: InvoiceLogSlugs.emailReceived | InvoiceLogSlugs.reminderReceived;
  data: {
    notification?: boolean;
    reminderType?: string;
    Recipient: string;
    MessageID: string;
    DeliveredAt: string;
  };
};

type InvoiceEmailOrReminderBounced = {
  _id: string;
  created: string;
  slug: InvoiceLogSlugs.emailBounced | InvoiceLogSlugs.reminderBounced;
  data: {
    reminderType?: string;
    Type: string;
    MessageID: string;
    Email: string;
    BouncedAt: string;
  };
};

type PeppolSent = {
  _id: string;
  created: string;
  slug: InvoiceLogSlugs.peppolSent;
  data?: {
    actionAt: string;
  };
};

type PeppolFailed = {
  _id: string;
  created: string;
  slug: InvoiceLogSlugs.peppolFailed;
  data?: {
    actionAt: string;
  };
};

export type InvoiceLog =
  | InvoiceCreation
  | InvoiceEmailOrReminderSent
  | InvoiceEmailOrReminderOpened
  | InvoiceEmailOrReminderReceived
  | InvoiceEmailOrReminderBounced
  | PeppolSent
  | PeppolFailed;

export type RecurringInvoice = RecurringData & RecurringInvoiceBase;

export type RecurringInvoiceFormValues = RecurringData &
  Omit<
    Partial<RecurringInvoiceBase>,
    'revenueData' | 'pausedAt' | 'pauseReason'
  > & {
    revenueId?: string;
    isPaused: boolean;
    revenueData: Omit<
      RecurringInvoice['revenueData'],
      | 'userSnapshot'
      | 'settings'
      | 'usePaymentQrCode'
      | 'useCommunication'
      | 'totalAmountInclVAT'
      | 'language'
      | 'items'
    > & {
      invoiceDate: string;
      deliveryDate?: string;
      clientId?: string;
      items: Partial<InvoiceItem>[];
      itemsCalculations?: InvoiceItemsCalculations;
      user: Partial<
        Pick<
          IUser,
          | 'companyName'
          | 'VATNumber'
          | 'phoneNumber'
          | 'email'
          | 'steuernummer'
          | 'SWIFT'
          | 'IBAN'
          | 'firstName'
          | 'lastName'
        >
      > & {
        address: Pick<IUser['address'], 'city' | 'zip' | 'street' | 'state'>;
        VATType: VATType;
        VATReturnFrequency: VATReturnFrequency;
      };
      _saveSettings?: boolean;
      settings: Partial<IUser['settings']['invoices']> & {
        _fontFallback?: string;
        _fontOption?: FontOptionType;
      };
    };
  };
