import classNames from 'classnames';
import { useHighlight } from 'hooks/useHighlight';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { components } from 'react-select';
import { getTracingEvents } from 'utils/amplitude';
import { captureLeftRightArrowEvents } from 'utils/captureLeftRightArrowEvents';
import withAsyncOptions from './bases/withAsyncOptions';
import withAsyncOptionsPaginated from './bases/withAsyncOptionsPaginated';
import withDefault from './bases/withDefault';
import getCustomUI from './customUI';
import withAsyncSearch from './extensions/withAsyncSearch';
import withControlledInputValue from './extensions/withControlledInputValue';
import withCreate from './extensions/withCreate';
import withOptionsDict from './extensions/withOptionsDict';
import withRenderedText from './extensions/withRenderedText';
import type { SelectConfig, SelectProps, ThemeOptions } from './select.types';

const getBase = (mode: SelectProps['mode']) => {
  if (mode === 'asyncOptions') return withAsyncOptions(ReactSelect);
  if (mode === 'asyncOptionsPaginated')
    return withAsyncOptionsPaginated(ReactSelect);
  return withDefault(ReactSelect);
};

const addExtension = (
  Base: FC<SelectConfig>,
  creatable: SelectProps['creatable'],
  asyncSearch: SelectProps['asyncSearch'],
) => {
  let maybeWrappedBase = withRenderedText(Base);
  // withCreate must be first since it can manipulate onInputValue params
  if (creatable) maybeWrappedBase = withCreate(maybeWrappedBase);
  if (asyncSearch) maybeWrappedBase = withAsyncSearch(maybeWrappedBase);
  return withControlledInputValue(withOptionsDict(maybeWrappedBase));
};

const themeOptionsDefaultValue: ThemeOptions = {
  shouldOptionsShowSelectedIndicator: true,
};

const Select = (props: SelectProps) => {
  const {
    mode = 'normal',
    creatable,
    asyncSearch,
    components,
    styles,
    themeOptions,
    isSearchable,
    filterOption,
    blurInputOnSelect,
    tracing,
    onMenuOpen,
    onChange,
    error,
    highlightID,
    ...rest
  } = props;
  const Base = useMemo(
    () => addExtension(getBase(mode), creatable, asyncSearch),
    [mode, creatable, asyncSearch],
  );
  const { t } = useTranslation();

  const componentProps = themeOptions || themeOptionsDefaultValue;

  const highlightAPI = useHighlight();

  const highlightClassName = highlightAPI.shouldHighlight(highlightID)
    ? highlightAPI.className
    : undefined;

  const customUI = useMemo(
    () =>
      getCustomUI(
        {
          ...componentProps,
          controlClassName: classNames(
            highlightClassName,
            componentProps.controlClassName,
          ),
        },
        t(error as string),
      ),
    [error, t, JSON.stringify(componentProps), highlightClassName],
  );

  const tracingEvents = getTracingEvents(tracing, { onMenuOpen, onChange });

  return (
    <Base
      data-highlight-id={highlightID}
      components={{ ...customUI.components, ...components }}
      theme={customUI.theme}
      styles={{ ...customUI.styles, ...styles }}
      {...rest}
      className={classNames('min-w-0', rest.className)}
      isCreatable={creatable} // used as hint for on of the extenstions that comes before the withcreate extention
      isSearchable={!!isSearchable}
      filterOption={isSearchable ? filterOption : null}
      blurInputOnSelect={blurInputOnSelect ?? true}
      onKeyDown={captureLeftRightArrowEvents}
      tabSelectsValue={false}
      {...tracingEvents}
      menuPortalTarget={false}
    />
  );
};

export default Select;
export { components };
