import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import { Menu } from 'components/atoms/Menu';
import useExpenseRevenueFormsRefs from 'hooks/Taxes/useExpenseRevenueFormsRefs';
import { get } from 'lodash';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import type { CheckComponentProps } from 'types/taxes.types';
import CheckRevenuesList from '../CheckRevenuesList';
import CheckUITemplate from '../CheckUITemplate';

const btnClasses = classNames(
  'w-full text-init text-main py-2 px-4 rounded',
  'flex gap-4 items-center font-semibold',
  'hover:bg-primary-200 hover:bg-opacity-25',
);

const VATRefundMoreThan100Euro: FC<CheckComponentProps> = (props) => {
  const { checkData } = props;
  const { t } = useTranslation();
  const { revenueFormRef } = useExpenseRevenueFormsRefs();
  const navigate = useNavigate();
  return (
    <CheckUITemplate
      {...props}
      renderContent={() => (
        <div className="my-4">
          <CheckRevenuesList {...{ list: get(checkData, 'data'), ...props }} />
          <div className="flex justify-end mt-4">
            <Menu
              position="top"
              contentWrapperClassName="w-max bottom-8 right-10 min-w-[20rem]"
              Button={
                <Button structure="text" size="xl" className="text-lg">
                  {t('revenues.dropzone.add_document')}
                </Button>
              }
            >
              <Menu.Group className="!p-0">
                <Menu.Button
                  className={btnClasses}
                  onClick={(e) => navigate('/revenues/create')}
                >
                  {t('revenue.empty.create_invoice.title')}
                </Menu.Button>
                <Menu.Button
                  className={btnClasses}
                  onClick={(e) => {
                    e.preventDefault();
                    revenueFormRef?.current?.open(
                      { type: 'other-revenue' },
                      {
                        afterSubmit: () => {
                          console.log('called');
                        },
                      },
                    );
                  }}
                >
                  {t('revenue.empty.encode_revenues.title')}
                </Menu.Button>
              </Menu.Group>
            </Menu>
          </div>
        </div>
      )}
    />
  );
};

export default VATRefundMoreThan100Euro;
