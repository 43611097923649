import dayjs from 'dayjs';
import { CellsModifiers } from 'types/CellsModifiers.types';
import type { Currency } from 'types/global.types';
import { CurrenciesEnum } from 'types/global.types';
import type { Invoice, InvoiceItem } from 'types/invoice.types';
import { InvoiceStatusEnum, InvoiceTypeEnum } from 'types/invoice.types';

const revenue = {
  use_bank_reference: false,
  reversedHeader: false,
  dateFormat: 'dd MMM yyyy',
  displayDateFormat: 'DD MMM YYYY',
  defaultDueDate: dayjs().add(1, 'M').format('YYYY-MM-DD'),
  zero_vat_reasons: {
    be: ['coContractor', 'noVAT', 'intraEU', 'extraEU', 'other'],
    eu: ['intraEU', 'coContractor', 'noVAT', 'extraEU', 'other'],
    other: ['extraEU', 'coContractor', 'noVAT', 'intraEU', 'other'],
  },
  blocked: {
    web: false,
    experts: false,
  },
  vat_application_status: 'sent',
  vat_not_applicable_status: 'not-sent',
  required_customer_data_for_subscription: [
    'VATNumber',
    'address.street',
    'address.city',
    'address.zip',
  ],
  required_customer_data_for_invoice_creation: [
    'companyName',
    'VATNumber',
    'address.street',
    'address.city',
    'address.zip',
    'IBAN',
  ],
  show_period_field: (): boolean => true,
  show_payment_amount: (
    currency: Currency,
    type: string,
    items: InvoiceItem[] = [],
  ): boolean =>
    type !== InvoiceTypeEnum.OTHER_REVENUE &&
    currency !== CurrenciesEnum.EUR &&
    !!items.length,
  has_delivery_date: false,
  has_payment_date: false,
  has_streuernummer: false,
  due_date_days_after_invoice_date: 30,
  list: {
    vat_inclusion_key: 'excl_vat',
    group_invoices: {
      web: 'isValidated',
      experts: 'reviewed',
    },
    cells: {
      _modifiers: [{ name: CellsModifiers.invoices }],
      first_group: {
        web: [
          {
            cellName: 'CLIENT',
            headerName: 'invoices.cells.invoices_and_credit_notes',
          },
          'TYPE',
          'NUMBER',
          'DATE',
          'STATUS',
          'AMOUNT',
        ],
        experts: [
          {
            cellName: 'CLIENT',
            headerName: 'invoices.cells.invoices_and_credit_notes',
          },
          'TYPE',
          'NUMBER',
          'DATE',
          'STATUS',
          'AMOUNT',
        ],
      },
      second_group: {
        web: [
          {
            cellName: 'CLIENT',
            headerName: 'invoices.cells.invoices_to_review',
          },
          'TYPE',
          'IMPORTED_DATE',
          'DATE',
          'STATUS',
          'AMOUNT',
        ],
        experts: [
          {
            cellName: 'CLIENT',
            headerName: 'invoices.cells.invoices_to_review',
          },
          'TYPE',
          'NUMBER',
          'IMPORTED_DATE',
          'DATE',
          'STATUS',
          'AMOUNT',
        ],
      },
      default_sort: {
        web: 'invoiceDate_desc',
        experts: 'invoiceDate_desc',
      },
    },
  },
  vat_rates: [0.0, 0.06, 0.12, 0.21],
  default_vat_rate: 0.21,
  fields: [],
  item: {
    types: { good: 'good', service: 'service' },
    vatStatuses: { normal: 'normal', coContractor: 'co-contractor' },
    fields: ['description', 'unit_cost', 'quantity', 'vat_rate', 'type'],
  },
  is_applicable: (invoice: Invoice): boolean =>
    invoice.isValidated && ['paid', 'sent'].includes(invoice.status),
  applicable_date_key: 'invoiceDate',
  vat_type_label: (type: string): string => type,
  show_reverse_charge_warning: true,
  deductible_tax_categories: [],
  shouldShowWarningEditingGeneratedDocument: (status: InvoiceStatusEnum) => {
    return [InvoiceStatusEnum.paid, InvoiceStatusEnum.sent].includes(status);
  },
  showUBLOptions: true,
  getStatusDisabledOptions: (isLocked?: boolean) => {
    if (isLocked) {
      return {
        [InvoiceStatusEnum.notSent]: true,
      };
    }
    return {};
  },
};

export default revenue;
