import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import { Tooltip } from 'components/atoms/Tooltip';
import { groupBy } from 'lodash';
import type { FC, ReactNode } from 'react';
import BreadCrumbs from './BreadCrumbs.component';
import type { CategoriesPickerSharedProps } from './categoriesPicker.types';

type ListCardsProps = CategoriesPickerSharedProps & {
  category: any;
  categoriesDict: Record<string, any>;
  value?: string;
  onClick: (cate: any) => void;
  shouldGroupList?: boolean;
  showCategoryTitle?: boolean;
};

type CardProps = {
  item: { path: string[]; isFinal: boolean; [key: string]: any };
  categoriesDict: Record<string, any>;
  onClick: (cate: any) => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  helper?: ReactNode;
};

const Card: FC<CardProps> = ({
  item,
  categoriesDict,
  onClick,
  isSelected,
  isDisabled,
  tooltip,
  helper,
}) => {
  return (
    <Tooltip content={tooltip} hoverable={false}>
      <Button
        asDefault
        onClick={() => !isDisabled && onClick(item)}
        ariaDisabled={isDisabled}
        className={classNames(
          'transition-all h-full w-full flex flex-col border border-primary-100 rounded-2xl px-5 py-7 text-left',
          'hover:shadow-lg focus-visible:shadow-lg',
          {
            'border-taxes-600 shadow-lg': isSelected,
            'opacity-75 bg-gray-100 cursor-not-allowed': isDisabled, // dont make the button it self disabled, the tooltip wont work
          },
        )}
      >
        <div className="flex w-full gap-6">
          <div className="leading-normal w-full">
            <p className="text-primary text-lg font-semibold leading-tight first-letter:capitalize">
              {item.title}

              {!!helper && (
                <span className="align-bottom inline-block mx-1">{helper}</span>
              )}
            </p>
            {!!item.path.length && (
              <p className="text-primary-400 font-semibold text-sm mt-1 leading-tight">
                {
                  categoriesDict[
                    item.path[
                      item.isFinal ? item.path.length - 1 : item.path.length - 2
                    ]
                  ].title
                }
              </p>
            )}
          </div>
        </div>
        {item.description && (
          <div className="w-full flex-1 flex items-center border-t border-primary-50 mt-4 pt-6">
            <p className="text-primary-700 text-sm leading-tight">
              {item.description}
            </p>
          </div>
        )}
        {item.additionalData && (
          <div
            className={classNames(
              'w-full flex gap-8 border-t border-primary-50 pt-4 mt-6',
              { 'mt-4': !item.description },
            )}
          >
            {item.additionalData.map((data: any, index: number) => (
              <div key={index}>
                <p className="text-primary-700 font-semibold text-xs leading-tight">
                  {data.label}
                </p>
                <p className="text-primary-700 text-sm leading-tight">
                  {data.value}
                </p>
              </div>
            ))}
          </div>
        )}
      </Button>
    </Tooltip>
  );
};

const topLevelItems = 'topLevelItems';

const ListCards: FC<ListCardsProps> = ({
  category,
  categoriesDict,
  value,
  onClick,
  shouldGroupList,
  showCategoryTitle,
  tooltips,
  helpers,
  disabledCategories,
}) => {
  const list = (() => {
    if (shouldGroupList) {
      const groups = groupBy(category.list, (item) => {
        const parentPath = category.path.join('');
        const pathWithoutParent = item.isFinal
          ? item.path.join('')
          : item.path.slice(0, -1).join('');
        if (parentPath === pathWithoutParent) {
          // is direct child, no need to display a subtitle for
          return topLevelItems;
        }
        return item.path.join('');
      });
      return Object.entries(groups).map(([key, items], i, { length }) => {
        const itemsGroupPath = items[0].path.slice(category.path.length);
        const isLast = i === length - 1;
        return (
          <div key={key} className={classNames({ 'mb-6': !isLast })}>
            {key !== topLevelItems && (
              <BreadCrumbs
                className="mb-3"
                titleClassName="text-sm"
                iconClassName="w-4"
                disableLastBreadcrumb={false}
                hideRoot={false}
                data={(itemsGroupPath as string[]).map((id) => ({
                  label: categoriesDict[id].title,
                  value: id,
                }))}
                onClick={(id: string) => {
                  onClick(categoriesDict[id]);
                }}
              />
            )}
            <div className="grid grid-cols-2 gap-7">
              {items.map((item: any) => (
                <Card
                  key={item.id}
                  item={item}
                  onClick={onClick}
                  isSelected={item.id === value}
                  categoriesDict={categoriesDict}
                  isDisabled={item.disabled || disabledCategories?.[item.id]}
                  tooltip={item.tooltip || tooltips?.[item.id]}
                  helper={helpers?.[item.id]}
                />
              ))}
            </div>
          </div>
        );
      });
    }
    return (
      <div className="grid grid-cols-2 gap-7">
        {category.list.map((item: any) => (
          <Card
            key={item.id}
            item={item}
            onClick={onClick}
            isSelected={item.id === value}
            categoriesDict={categoriesDict}
            isDisabled={item.disabled || disabledCategories?.[item.id]}
            tooltip={item.tooltip || tooltips?.[item.id]}
            helper={helpers?.[item.id]}
          />
        ))}
      </div>
    );
  })();

  return (
    <div>
      {showCategoryTitle && (
        <div className="mb-3">
          <p className="text-primary-700 leading-tight font-bold text-lg">
            {category.title}
          </p>
        </div>
      )}
      {list}
    </div>
  );
};

export default ListCards;
