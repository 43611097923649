import argentBankLogo from 'assets/images/BEBanks/argentBankLogo.png';
import AXABankLogo from 'assets/images/BEBanks/AXABankLogo.png';
import belfiusBankLogo from 'assets/images/BEBanks/belfiusBankLogo.png';
import beoBankLogo from 'assets/images/BEBanks/beoBankLogo.png';
import BNPBankLogo from 'assets/images/BEBanks/BNPBankLogo.png';
import bpostBankLogo from 'assets/images/BEBanks/bpostBankLogo.png';
import CBCBankLogo from 'assets/images/BEBanks/CBCBankLogo.png';
import crelanBankLogo from 'assets/images/BEBanks/crelanBankLogo.png';
import helloBank from 'assets/images/BEBanks/helloBankLogo.png';
import ingBankLogo from 'assets/images/BEBanks/ingBankLogo.png';
import KBCBankLogo from 'assets/images/BEBanks/KBCBankLogo.png';
import N26 from 'assets/images/BEBanks/N26BankLogo.png';
import nagelmackersBank from 'assets/images/BEBanks/nagelmackersBank.svg';
import PayPal from 'assets/images/BEBanks/PayPal.png';
import vanBredaBankLogo from 'assets/images/BEBanks/vanBredaBankLogo.png';
import comdirektBankLogo from 'assets/images/DEBanks/comdirektBankLogo.png';
import commerzBankLogo from 'assets/images/DEBanks/commerzBankLogo.png';
import deutscheBankLogo from 'assets/images/DEBanks/deutscheBankLogo.png';
import kontistBankLogo from 'assets/images/DEBanks/kontistBankLogo.png';
import postBankLogo from 'assets/images/DEBanks/postBankLogo.png';
import qontoBankLogo from 'assets/images/DEBanks/qontoBankLogo.png';
import sparkasseBankLogo from 'assets/images/DEBanks/sparkasseBankLogo.png';
import { product } from 'config';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import dayjs from 'dayjs';
import Joi from 'joi';
import isEmpty from 'lodash/isEmpty';
import { BEBankSlugs, DEBankCodes } from 'types/global.types';
import { ResponseType } from 'types/response.types';
import SubscriptionPlans from 'types/subscriptionPlans.types';
import type { Transaction } from 'types/transactions.types';
import { TransactionCategoryEnum } from 'types/transactions.types';
import {
  AccountTypeEnum,
  AvailableCountries,
  AvailableLanguagesBE,
} from 'types/users.types';
import { ONE_MB } from './fileSizes';
import { caseInsensitiveEqual } from './helpers';

const isWeb = product === 'web';
const isExpert = product === 'expert';

const hostName = window.location.hostname;
const hostNameZone = hostName.split('.').pop();
const isDomainDE = caseInsensitiveEqual(hostNameZone, 'de'); // web.accountable.de
const isDomainBE = caseInsensitiveEqual(hostNameZone, 'be'); // web.accountable.be
const isDomainEU = !isDomainDE && !isDomainBE; // web.accountable.eu , localhost, ...

const domainCountry = isDomainBE
  ? AvailableCountries.belgium
  : isDomainDE
  ? AvailableCountries.germany
  : undefined;

const forbiddenSubscriptionPlans = {
  web: [
    // eventhough these are forbiden subscriptionPlans in testing environments
    // we allow opening the web app from them / we don't redirect to subscriptions
    // checkout  src/store/features/selectors/index.ts selectIsSubscriptionPlanAllowed function
    // if you want to enable paywall in testing environments
    SubscriptionPlans.FREE,
    SubscriptionPlans.BASIC1,
    SubscriptionPlans.BASIC12,
  ],
  experts: [],
};

const allowedCountries = {
  web: ['be', 'de'],
  experts: ['be', 'de'],
};
const zeroVATTypes = ['franchisee', 'exempt'];
const countriesWithSearchableClients = ['BE', 'DE'];
const CURRENT_QUARTER = dayjs().quarter();
const CURRENT_YEAR = dayjs().year();
const CURRENT_MONTH = dayjs().month();

const VEHICLE_AMORTIZATION_END_YEAR = 2020;
const VEHICLES_START_DATE = '2020-01-01';

const defaultAcceptTypes = [
  ResponseType.imageJpg,
  ResponseType.imagePng,
  ResponseType.imageGif,
  ResponseType.applicationPdf,
];

export const globalyAcceptedFileUploaderTypes = {
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'application/pdf': ['.pdf'],
};

export const creditCardAcceptedFileUploaderTypes = {
  ...globalyAcceptedFileUploaderTypes,
  'text/csv': ['.csv'],
};
const maxFileSize = ONE_MB * 10;

const defaultInvoiceColor = { r: 22, g: 113, b: 195 };
const AppName = 'Accountable';

const formResetOptions = {
  keepValues: true,
  keepErrors: true,
  keepDirty: true,
  keepTouched: true,
  keepIsValid: true,
  keepSubmitCount: true,
  keepIsSubmitted: true,
  keepDirtyValues: true,
  keepDefaultValues: false,
};

const popoverTransition = {
  enter: 'transition ease-out duration-200',
  enterFrom: 'opacity-0 translate-y-1',
  enterTo: 'opacity-100 translate-y-0',
  leave: 'transition ease-in duration-150',
  leaveFrom: 'opacity-100 translate-y-0',
  leaveTo: 'opacity-0 translate-y-1',
};

const bulkBtnsTransition = {
  enter: 'ease-out duration-200',
  enterFrom: 'opacity-50 scale-90',
  enterTo: 'opacity-100 scale-100',
  leave: 'ease-in duration-100',
  leaveFrom: 'opacity-100 scale-100',
  leaveTo: 'opacity-50 scale-90',
};

const disabledForTransactionsBulk: string[] = [
  TransactionCategoryEnum.VATPayment,
  TransactionCategoryEnum.incomeTaxPayment,
  TransactionCategoryEnum.incomeTaxPrePayment,
  TransactionCategoryEnum.VATReimbursement,
  TransactionCategoryEnum.incomeTaxReimbursement,
  TransactionCategoryEnum.socialContributions,
  TransactionCategoryEnum.companyTaxPrePayment,
  TransactionCategoryEnum.companyTaxPayment,
  TransactionCategoryEnum.companyTaxReimbursement,
];

const isDisabledTransactionCategoryBulk = (
  cateId: string | null | undefined,
) => {
  if (typeof cateId !== 'string') return false;
  return disabledForTransactionsBulk.includes(cateId);
};

const isDisabledTransactionBulk = (transaction: Transaction) => {
  if (!isEmpty(transaction.matchedItems)) return true;
  return isDisabledTransactionCategoryBulk(transaction.transactionCategory);
};

const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/;

const passwordPatternChecks = [
  {
    schema: Joi.string().min(8).max(50).required(),
    slug: 'settings.password.minMaxHint',
  },
  {
    schema: Joi.string().regex(/[A-Z]/).required(),
    slug: 'settings.password.uppercaseHint',
  },
  {
    schema: Joi.string().regex(/[a-z]/).required(),
    slug: 'settings.password.lowercaseHint',
  },
  {
    schema: Joi.string().regex(/[0-9]/).required(),
    slug: 'settings.password.numberHint',
  },
];

const dateLocales = { de, en, fr, nl };

const DEbanksList = [
  {
    name: 'Sparkasse Bank',
    img: sparkasseBankLogo,
  },
  {
    name: 'N26',
    img: N26,
    bankCode: DEBankCodes.n26,
  },
  {
    name: 'Commerzbank',
    img: commerzBankLogo,
    bankCode: DEBankCodes.commerzbank,
  },
  {
    name: 'Postbank',
    img: postBankLogo,
    bankCode: DEBankCodes.postbank,
  },
  {
    name: 'PayPal',
    img: PayPal,
  },
  {
    name: 'ING Bank',
    img: ingBankLogo,
    bankCode: DEBankCodes.ingbank,
  },
  {
    name: 'Deutsch Bank',
    img: deutscheBankLogo,
    bankCode: DEBankCodes.deutschbank,
  },
  {
    name: 'Kontist',
    img: kontistBankLogo,
  },
  {
    name: 'Comdirect Bank',
    img: comdirektBankLogo,
    bankCode: DEBankCodes.comdirectbank,
  },
  {
    name: 'Qonto',
    img: qontoBankLogo,
    bankCode: DEBankCodes.qonto,
  },
];

export type PriorityBEBankMeta = { slug: BEBankSlugs; img: string };

type BEBanksListType = {
  [key in AvailableLanguagesBE]: PriorityBEBankMeta[];
};

const BEBanksList: BEBanksListType = {
  [AvailableLanguagesBE.en]: [
    { slug: BEBankSlugs.kbc, img: KBCBankLogo },
    { slug: BEBankSlugs.ing, img: ingBankLogo },
    { slug: BEBankSlugs.belfius, img: belfiusBankLogo },
    { slug: BEBankSlugs.argenta, img: argentBankLogo },
    { slug: BEBankSlugs.bnp, img: BNPBankLogo },
    { slug: BEBankSlugs.beo, img: beoBankLogo },
    { slug: BEBankSlugs.cbc, img: CBCBankLogo },
    { slug: BEBankSlugs.paypal, img: PayPal },
    { slug: BEBankSlugs.axa, img: AXABankLogo },
    { slug: BEBankSlugs.n26, img: N26 },
  ],
  [AvailableLanguagesBE.nl]: [
    { slug: BEBankSlugs.kbc, img: KBCBankLogo },
    { slug: BEBankSlugs.belfius, img: belfiusBankLogo },
    { slug: BEBankSlugs.bnp, img: BNPBankLogo },
    { slug: BEBankSlugs.ing, img: ingBankLogo },
    { slug: BEBankSlugs.argenta, img: argentBankLogo },
    { slug: BEBankSlugs.hello, img: helloBank },
    { slug: BEBankSlugs.crelan, img: crelanBankLogo },
    { slug: BEBankSlugs.beo, img: beoBankLogo },
    { slug: BEBankSlugs.axa, img: AXABankLogo },
    { slug: BEBankSlugs.vanbreda, img: vanBredaBankLogo },
  ],
  [AvailableLanguagesBE.fr]: [
    { slug: BEBankSlugs.ing, img: ingBankLogo },
    { slug: BEBankSlugs.belfius, img: belfiusBankLogo },
    { slug: BEBankSlugs.bnp, img: BNPBankLogo },
    { slug: BEBankSlugs.cbc, img: CBCBankLogo },
    { slug: BEBankSlugs.axa, img: AXABankLogo },
    { slug: BEBankSlugs.kbc, img: KBCBankLogo },
    { slug: BEBankSlugs.hello, img: helloBank },
    { slug: BEBankSlugs.beo, img: beoBankLogo },
    { slug: BEBankSlugs.bpost, img: bpostBankLogo },
    { slug: BEBankSlugs.nagelmackers, img: nagelmackersBank },
  ],
};

const disabledInvoiceCategoriesMap: Record<string, string[]> = {
  [AccountTypeEnum.one_person_company]: ['be.revenue.copyright'],
};

const getDisabledInvoiceCategoriesForAccountType = (accountType: string) => {
  return disabledInvoiceCategoriesMap[accountType] || [];
};

const signupPromocode = 'TAXHCG';

const customerEmailAmplProp = '__customerId__';

const mobileAppLink = 'https://accountable.app.link/j3LdsYMK82';

const taxCoachEmail = 'taxcoach@accountable.eu';
const accountableTLD = 'accountable.eu';
const invoiceDefaultFont = 'Assistant';

const GROWTH_ALLOWED_EMAILS = [
  'nicolas.quarre@gmail.com',
  'eggermont@gmail.com',
  'alexis@accountable.eu',
  'debellefroidclement@gmail.com',
  'isabel@accountable.eu',
  'mohamed@accountable.eu',
  'demo+clem@accountable.eu',
  'zonexjuan@gmail.com',
  'eniola@accountable.eu',
  'valescawilms@live.be',
];

const beVehicleDeductibliltyDate = dayjs('2023-07-01', 'YYYY-MM-DD');

const germanBusinessCarCustomType = 'private-usage-business-car';
const globalModalClassNameSelector = 'GLOBAL_MODAL_CONTENT';

const signupDateStorageKey = 'signupDate';

const invoiceItemsEmptyCalculations = {
  calculations: {
    items: [],
    totalAmountInclVAT: 0,
    totalAmountExclVAT: 0,
    totalVATAmount: 0,
    totalVATAmount_5: 0,
    totalVATAmount_6: 0,
    totalVATAmount_7: 0,
    totalVATAmount_12: 0,
    totalVATAmount_16: 0,
    totalVATAmount_19: 0,
    totalVATAmount_21: 0,
    baseCurrencyTotalAmountInclVAT: 0,
    currencyRate: 1,
  },
  copyRight: {
    copyrightRevenueAmountExclTax: 0,
    copyrightRevenueTaxAmount: 0,
  },
  currencyRate: 1,
  legalNotes: null,
};
const maximumAmortizationPeriod = 50;

const SMALL_BUSINESS_PLAN_DISCOUNT = 0.5;
const SMALL_BUSINESS_PLAN_DISCOUNT_CODE_PROD = 'ICF2IOxy';
const SMALL_BUSINESS_PLAN_DISCOUNT_CODE_DEV = 'SMALL-D50';

const SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT = 0.75;
const SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT_CODE_PROD = '33AdtKdL';
const SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT_CODE_DEV = 'sx7fWeZL';

export {
  forbiddenSubscriptionPlans,
  allowedCountries,
  zeroVATTypes,
  countriesWithSearchableClients,
  isWeb,
  isExpert,
  VEHICLES_START_DATE,
  defaultAcceptTypes,
  CURRENT_QUARTER,
  maxFileSize,
  CURRENT_MONTH,
  VEHICLE_AMORTIZATION_END_YEAR,
  CURRENT_YEAR,
  defaultInvoiceColor,
  AppName,
  formResetOptions,
  popoverTransition,
  bulkBtnsTransition,
  isDisabledTransactionBulk,
  isDisabledTransactionCategoryBulk,
  passwordPattern,
  dateLocales,
  DEbanksList,
  BEBanksList,
  getDisabledInvoiceCategoriesForAccountType,
  signupPromocode,
  isDomainEU,
  isDomainBE,
  isDomainDE,
  domainCountry,
  passwordPatternChecks,
  customerEmailAmplProp,
  mobileAppLink,
  invoiceDefaultFont,
  taxCoachEmail,
  accountableTLD,
  GROWTH_ALLOWED_EMAILS,
  beVehicleDeductibliltyDate,
  germanBusinessCarCustomType,
  globalModalClassNameSelector,
  invoiceItemsEmptyCalculations,
  signupDateStorageKey,
  SMALL_BUSINESS_PLAN_DISCOUNT,
  SMALL_BUSINESS_PLAN_DISCOUNT_CODE_PROD,
  SMALL_BUSINESS_PLAN_DISCOUNT_CODE_DEV,
  SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT,
  SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT_CODE_PROD,
  SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT_CODE_DEV,
  maximumAmortizationPeriod,
};
//
