import axios from 'axios';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import { FilesUrls } from 'types/cacheKeys.types';

const getBlob = (url: string): Promise<Blob> =>
  axios.get(url, { responseType: 'arraybuffer' }).then((res) => {
    return new Blob([res.data], {
      type: 'application/pdf',
    });
  });

const useGetPDFBlob = (
  url: string,
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) => {
  return useQuery([FilesUrls.FileBlob, url], () => getBlob(url), {
    cacheTime: 0,
    ...(options as any),
  });
};

export default useGetPDFBlob;
