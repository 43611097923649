import axiosInstance from 'api/axiosInstance';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import get from 'lodash/get';
import type { IExpense } from 'types/expenses.types';
import type { Period, VATReturnFrequency } from 'types/global.types';
import type {
  DocumentFetchType,
  ITax,
  StatementMap,
  Submission,
  TaxCheckEnum,
  TaxExpensesReasons,
  TaxRevenuesReasons,
} from 'types/taxes.types';
import { AvailableCountries } from 'types/users.types';
import getPeriodVATParam from 'utils/taxes/getVATPeriodParam';

type GetTaxExpensesParams = {
  taxId: string;
  perPage?: number;
  page?: number;
  type?: DocumentFetchType;
  reason?: TaxExpensesReasons;
};

type GetTaxRevenuesParams = {
  taxId: string;
  perPage?: number;
  page?: number;
  type?: DocumentFetchType;
  reason?: TaxRevenuesReasons;
};

export const getTaxBySlug = (slug: string): Promise<ITax> => {
  return axiosInstance
    .get(`/v2/taxes/resource/${slug}`, {
      ignoreToast: true,
    } as AxiosRequestConfig)
    .then(({ data }) => data);
};

export const getTaxesByYear = (year: number): Promise<any> => {
  return axiosInstance.get(`/v2/taxes/${year}`);
};

export const getClosedVATPeriods = (
  VATFreq: VATReturnFrequency,
  country: AvailableCountries,
): Promise<Period[]> =>
  axiosInstance
    .get(
      `/v2/taxes?types=${
        country === AvailableCountries.germany && VATFreq === 'yearly'
          ? 'uste_submission'
          : 'vat_submission,uste_submission,vat_payment,intracom_listing'
      }&statuses=done&perPage=${500}`,
    )
    .then(({ data }) => data.map(({ period }: any) => period));

export const getTaxSubmissions = (taxId: string): Promise<Submission[]> => {
  return axiosInstance
    .get(`v2/taxes/${taxId}/submissions`, {
      ignoreToast: true,
    } as AxiosRequestConfig<Promise<Submission[]> & { ignoreToast: boolean }>)
    .then(({ data }) => data);
};

export async function getTaxExpenses({
  taxId,
  perPage = 500,
  page = 1,
  type,
  reason,
}: GetTaxExpensesParams): Promise<AxiosResponse<{ data: IExpense[] }>> {
  return axiosInstance.get<{ data: IExpense[] }>(
    `/v2/taxes/${taxId}/documents/expenses`,
    {
      params: {
        perPage,
        page,
        type,
        reason,
      },
    },
  );
}

export type TaxesHeaderCalcs = {
  currentTaxRate: number;
  currentCorporateTaxRate: number;
  currentYearDaysBetweenFirstLastRevenue: number;
  usedFlatFee: number;
  projectedTaxRate: number;
  projectedIncomeTax: number;
  projectedTaxableIncome: number;
  incomeTaxToDate: number;
  taxableIncome: number;
  revenues: number;
  deductibleCosts: number;
  currentYearPrepayments: number;
};

export const getTaxesHeaderCalcs = (
  year?: number,
): Promise<TaxesHeaderCalcs> => {
  return axiosInstance.get('/v2/calculations/taxes', {
    params: { year: year || dayjs().year() },
  });
};

type ProfitProjectionSettings = {
  currentYearDaysBetweenFirstLastRevenue: number;
  currentYearProjectedTaxableIncome: number;
  currentYearTaxableIncome: number;
  manualEstimation: number | null;
  personaTaxableIncome: number;
  previousYearTaxableIncome: number;
};

export const getProfitSettings = (): Promise<ProfitProjectionSettings> => {
  return axiosInstance.get('v2/users/settings/profit');
};

export async function getTaxRevenues({
  taxId,
  perPage = 500,
  page = 1,
  type,
  reason,
}: GetTaxRevenuesParams): Promise<any> {
  return axiosInstance.get<{ data: IExpense[] }>(
    `/v2/taxes/${taxId}/documents/revenues`,
    {
      params: {
        perPage,
        page,
        type,
        reason,
      },
    },
  );
}

type Params = {
  year: number;
  period: Period;
  path: string;
};

export const getVATStatementBreakdown = async ({
  year,
  period,
  path,
}: Params): Promise<{ statement: StatementMap }> => {
  const periodParam = getPeriodVATParam(period);
  return axiosInstance.get(`v2/taxes/${path}/${year}`, {
    params: {
      period: periodParam,
      enableBreakdown: true,
    },
  });
};

export const getEURStatementBreakdown = async ({
  year,
}: {
  year: number;
}): Promise<{ statement: StatementMap }> => {
  return axiosInstance
    .get(`v2/taxes/de/eur/${year}`, {
      params: { enableBreakdown: true },
    })
    .then((data) => ({
      statement: {
        ...get(data, 'statement.EUR'),
        ...get(data, 'statement.AVEUR'),
      },
    }));
};

export const getSubmissionDetails = (
  taxId: string,
  submissionId: string,
  showBreakdown: boolean,
): Promise<Submission> => {
  return axiosInstance
    .get(`v2/taxes/${taxId}/submissions/${submissionId}`, {
      params: { showBreakdown },
    })
    .then(({ data }) => data);
};

export const getHebesatz = (params: {
  city: string;
  state: string;
}): Promise<{ hebesatz: { value: number; isDefault: boolean } }> => {
  return axiosInstance.get('/v2/taxes/utils/hebesatz', {
    params,
    ignoreToast: true,
  } as AxiosRequestConfig<{
    ignoreToast: string;
    city: string;
    state: string;
  }>);
};

export const getTaxCheck = (
  checkId: TaxCheckEnum,
  tax: ITax,
  page?: number,
): any =>
  axiosInstance.get(`v2/taxes/${tax?._id}/checks/${checkId}`, {
    params: {
      page,
      perPage: 25,
      status: get(tax, 'guarantee.taxChecks.failed', []).includes(checkId)
        ? 'failed'
        : 'reviewed',
    },
  });

export const reviewTaxCheck = (checkId: TaxCheckEnum, taxId: string): any =>
  axiosInstance.post(`v2/taxes/${taxId}/checks/${checkId}/review`);

export const undoReviewTaxCheck = (checkId: TaxCheckEnum, taxId: string): any =>
  axiosInstance.post(`v2/taxes/${taxId}/checks/${checkId}/undo-review`);
