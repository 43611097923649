import AmortizationTableIcon from 'assets/svg/icons/amortizationTableIcon';
import BankIcon from 'components/atoms/SVG/sidebarIcons/BankIcon.component';
import ChristmasIcon from 'components/atoms/SVG/sidebarIcons/ChristmasIcon.component';
import ExpensesIcon from 'components/atoms/SVG/sidebarIcons/ExpensesIcon.component';
import RevenuesIcon from 'components/atoms/SVG/sidebarIcons/RevenuesIcon.component';
import TaxesIcon from 'components/atoms/SVG/sidebarIcons/TaxesIcon.component';
import NewExpensesCount from 'components/organisms/Sidebar/CountBadges/NewExpensesCount.component';
import NewRevenuesCount from 'components/organisms/Sidebar/CountBadges/NewRevenuesCount.component';
import TaxesCount from 'components/organisms/Sidebar/CountBadges/TaxesCount.component';
import { SidebarNavigationItemTypesEnum } from 'components/organisms/Sidebar/types';
import AIAssistantSidebar from 'components/pages/AI/AIAssistantSidebar';
import { getReviewYears } from 'components/pages/YearlyReview/helpers';
import appI18nInstance from 'i18n';

import type {
  NavbarItemType,
  NavbarNavigationListType,
} from 'types/navbar.types';
import type { IUser } from 'types/users.types';
import { isExpert } from 'utils/constants';
import { isEndOrBeginingOfAYear } from 'utils/helpers';

export const createExpertRouteURL = (
  customerId?: string,
  url?: string,
): string => `/customer/${customerId}${url}`;

const navigationGenerator = (
  customerId: string,
  experiments?: any,
  user?: IUser,
): NavbarNavigationListType => {
  const isExpertCS = isExpert && user?.email?.includes('@accountable.eu');
  return [
    {
      title: 'menu.bank',
      to: createExpertRouteURL(customerId, '/banks'),
      Icon: BankIcon,
      type: SidebarNavigationItemTypesEnum.banks,
    },
    {
      title: 'menu.expenses',
      to: createExpertRouteURL(customerId, '/expenses'),
      children: [createExpertRouteURL(customerId, '/expenses/analytics')],
      Icon: ExpensesIcon,
      type: SidebarNavigationItemTypesEnum.expenses,
      BadgeComponent: NewExpensesCount,
    },
    {
      title: 'menu.invoices',
      to: createExpertRouteURL(customerId, '/revenues'),
      children: [
        createExpertRouteURL(customerId, '/clients'),
        createExpertRouteURL(customerId, '/revenues/create'),
        createExpertRouteURL(customerId, '/revenues/:id'),
        createExpertRouteURL(customerId, '/revenues/analytics'),
        createExpertRouteURL(customerId, '/quotes'),
        createExpertRouteURL(customerId, '/recurring-revenues'),
        createExpertRouteURL(customerId, '/recurring-revenues/create'),
        createExpertRouteURL(customerId, '/recurring-revenues/:id'),
      ],
      Icon: RevenuesIcon,
      type: SidebarNavigationItemTypesEnum.revenues,
      BadgeComponent: NewRevenuesCount,
    },
    {
      title: 'menu.taxes',
      to: createExpertRouteURL(customerId, '/taxes'),
      children: [
        createExpertRouteURL(customerId, '/taxes/analytics'),
        createExpertRouteURL(
          customerId,
          '/taxes/:country/:taxSlug/:periodSlug',
        ),
      ],
      Icon: TaxesIcon,
      type: SidebarNavigationItemTypesEnum.taxes,
      BadgeComponent: TaxesCount,
    },
    {
      title: 'menu.amortization',
      to: createExpertRouteURL(customerId, '/amortization'),
      Icon: AmortizationTableIcon,
      type: SidebarNavigationItemTypesEnum.primary,
    },
    ...(isExpertCS
      ? [
          {
            title: 'yearly_review.title',
            renderTitle: () =>
              appI18nInstance.t('yearly_review.title', {
                year: getReviewYears().year,
              }),
            to: createExpertRouteURL(customerId, '/yearly-review'),
            Icon: ChristmasIcon,
            type: SidebarNavigationItemTypesEnum.yearlyReview,
            condition: isEndOrBeginingOfAYear,
            placement: 'bottom',
          } as NavbarItemType,
        ]
      : []),
    {
      Component: <AIAssistantSidebar />,
      type: SidebarNavigationItemTypesEnum.taxes,
      Icon: () => null,
      to: createExpertRouteURL(customerId, '/assistant'),
      title: 'AI Assistant',
      placement: 'bottom',
    },
  ];
};

export default navigationGenerator;
