import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import { Progress } from 'components/atoms/Progress';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ONE_HUNDRED_KB, ONE_KB, ONE_MB } from 'utils/fileSizes';
import { CheckIcon, RefreshIcon, UploadIcon, XIcon } from 'utils/icons';
import type {
  FileUploadedItemMap,
  FileUploadedItemProps,
} from './FileUploaded.types';
import { Status } from './FileUploaded.types';

export const fileUploadedItemMap: FileUploadedItemMap = {
  waiting: {
    Icon: () => null,
    progressColor: 'blue',
  },
  uploading: {
    Icon: () => <UploadIcon className="text-[#6435c9] w-4 h-4 " />,
    progressColor: 'blue',
  },
  done: {
    Icon: () => <CheckIcon className="text-green-400 w-4 h-4 " />,
    progressColor: 'green',
  },
  error: {
    Icon: () => <XIcon className="text-red-400 w-4 h-4 " />,
    progressColor: 'red',
  },
};

const FileUploadedItem: FC<FileUploadedItemProps> = ({
  name,
  size,
  status,
  progress,
  className,
  onRetry,
  color,
}): JSX.Element => {
  const { t } = useTranslation();
  const { Icon, progressColor } = fileUploadedItemMap[status];
  return (
    <div className={classNames(className)}>
      <h1 title={name} className="font-bold truncate">
        {name}
      </h1>
      <div className="flex justify-between items-center">
        <span className="text-primary-700 leading-none">
          {size < ONE_MB
            ? `${(size / ONE_KB).toFixed(2)} kb`
            : `${(size / ONE_MB).toFixed(2)} mb`}
        </span>
        <div className="flex items-center">
          <Icon />
          <span className="uppercase ml-1 text-sm text-primary-400 font-bold">
            {status}
          </span>
        </div>
      </div>
      {status !== Status.done && size > ONE_HUNDRED_KB && (
        <span className="bg-yellow-100 text-sm px-1">
          {t('big_documents_might_take_longer')}
        </span>
      )}
      {status === 'error' && (
        <div className="mt-2">
          <Button
            color={color}
            icon={RefreshIcon}
            iconPlacement="right"
            onClick={onRetry}
          >
            {t('retry')}
          </Button>
        </div>
      )}
      <div className="mt-2">
        <Progress progress={progress} color={progressColor} />
      </div>
    </div>
  );
};

export default FileUploadedItem;
