import axiosInstance from 'api/axiosInstance';
import dayjs from 'dayjs';
import { stringify } from 'query-string';
import type { Currency } from 'types/global.types';
import type { Transaction } from 'types/transactions.types';

// Docs https://app.asana.com/0/1170965699515281/1205240894774598

export type SuggestedTransactionParams = {
  amount?: number;
  date?: string;
  communication?: string | null;
  documentNumber?: string | number;
  name?: string;
  IBAN?: string | null;
  currency?: Currency;
};

const getSuggestedTransaction = (
  params: SuggestedTransactionParams,
): Promise<Transaction | null> => {
  if (!params.amount || !params.date) return Promise.resolve(null);
  return axiosInstance
    .get(
      `/v1/transactions/suggested-transaction?${stringify(
        {
          ...params,
          date: dayjs(params.date).format('YYYY-MM-DD'),
        },
        { skipNull: true, skipEmptyString: true },
      )}`,
    )
    .then(({ isFound, transaction }: any) => (isFound ? transaction : null));
};

export default getSuggestedTransaction;
