import navigationGenerator from 'applications/expert/routing/navigationGenerator';
import PageTitle from 'components/atoms/PageTitle.component';
import { assignLayoutToRoutes } from 'components/organisms/Layout/Layout.component';
import YearlyReview from 'components/pages/YearlyReview';
import { getReviewYears } from 'components/pages/YearlyReview/helpers';
import { lazy } from 'react';
import type { RoutesListType } from 'types/routing.types';
const Customers = lazy(() => import('applications/expert/pages/Customers'));
const ExpertLogin = lazy(() => import('applications/expert/pages/ExpertLogin'));
const ExpertSignup = lazy(
  () => import('applications/expert/pages/ExpertSignup'),
);
const MagicLinkHandler = lazy(
  () => import('applications/expert/pages/MagicLinkHandler'),
);
const Assistant = lazy(() => import('components/pages/AI'));
/// ---------------
import Amortization from 'components/organisms/SettingsViews/Amortization';
import {
  CreateInvoice,
  EditInvoice,
} from 'components/pages/Revenues/GeneratedRevenueForm/InvoiceForm';
import {
  CreateQuote,
  EditQuote,
} from 'components/pages/Revenues/GeneratedRevenueForm/QuoteForm';
import {
  CreateRecurringInvoice,
  EditRecurringInvoice,
} from 'components/pages/Revenues/GeneratedRevenueForm/RecurringInvoiceForm';
/// ---------------
const Settings = lazy(() => import('applications/expert/pages/Settings'));
const Banks = lazy(() => import('components/pages/Banks'));
const DataExport = lazy(() => import('components/pages/DataExport'));
const Expenses = lazy(() => import('components/pages/Expenses'));
const Revenues = lazy(() => import('components/pages/Revenues'));
const Taxes = lazy(() => import('components/pages/Taxes'));
const Tax = lazy(() => import('components/pages/Taxes/Tax/Tax'));
const Clients = lazy(() => import('components/pages/Clients'));
const Quotes = lazy(() => import('components/pages/Quotes'));
const ExpertSettings = lazy(
  () => import('applications/expert/pages/ExpertSettings'),
);
const ExpensesAnalytics = lazy(
  () => import('components/pages/Analytics/ExpensesAnalytics.component'),
);
const RevenuesAnalytics = lazy(
  () => import('components/pages/Analytics/RevenuesAnalytics.component'),
);
const ProfitAnalytics = lazy(
  () => import('components/pages/Analytics/ProfitAnalytics.component'),
);
const RecurringRevenues = lazy(
  () => import('components/pages/RecurringRevenues'),
);

const routes: RoutesListType = [
  {
    path: '/',
    Component: <Customers />,
    private: true,
    withLayout: false,
  },
  {
    path: '/customer/:id/banks',
    Component: (
      <PageTitle slug="transactions.title">
        <Banks />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/expenses',
    Component: (
      <PageTitle slug="expenses.title">
        <Expenses />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/revenues',
    Component: (
      <PageTitle slug="invoices.title">
        <Revenues />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/revenues/create',
    Component: <CreateInvoice />,
    private: true,
    showBgImage: false,
    withLayout: true,
    className: 'bg-primary-25',
  },
  {
    path: '/customer/:id/revenues/:revenueId',
    Component: <EditInvoice />,
    private: true,
    showBgImage: false,
    withLayout: true,
    className: 'bg-primary-25',
  },
  {
    path: '/customer/:id/recurring-revenues',
    Component: <RecurringRevenues />,
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/recurring-revenues/create',
    Component: <CreateRecurringInvoice />,
    private: true,
    showBgImage: false,
    withLayout: true,
    className: 'bg-primary-25',
  },
  {
    path: '/customer/:id/recurring-revenues/:revenueId',
    Component: <EditRecurringInvoice />,
    private: true,
    showBgImage: false,
    withLayout: true,
    className: 'bg-primary-25',
  },
  {
    path: '/customer/:id/quotes',
    Component: (
      <PageTitle slug="quotes.title">
        <Quotes />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/quotes/create',
    Component: <CreateQuote />,
    private: true,
    withLayout: true,
    showBgImage: false,
    className: 'bg-primary-25',
  },

  {
    path: '/customer/:id/quotes/:quoteId',
    Component: <EditQuote />,
    private: true,
    withLayout: true,
    showBgImage: false,
    className: 'bg-primary-25',
  },
  {
    path: '/customer/:id/clients',
    Component: (
      <PageTitle slug="clients.title">
        <Clients />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/taxes/:country/:taxSlug/:type/:periodSlug',
    Component: (
      <PageTitle slug="taxes.title">
        <Tax />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
    showBgImage: false,
  },
  {
    path: '/customer/:id/taxes/:country/:taxSlug/:periodSlug',
    Component: (
      <PageTitle slug="taxes.title">
        <Tax />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
    showBgImage: false,
  },
  {
    path: '/customer/:id/taxes',
    Component: (
      <PageTitle slug="taxes.title">
        <Taxes />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/yearly-review',
    Component: (
      <PageTitle
        slug="yearly_review.title"
        params={{
          year: getReviewYears().year,
        }}
      >
        <YearlyReview />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/assistant',
    Component: <Assistant />,
    private: true,
    withLayout: true,
  },
  {
    className: '!px-8 !py-0',
    path: '/customer/:id/export',
    Component: (
      <PageTitle slug="menu.export_data">
        <DataExport />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/settings/*',
    Component: (
      <PageTitle slug="settings.title">
        <Settings />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
    showBgImage: false,
  },
  {
    path: '/customer/:id/expenses/analytics',
    Component: (
      <PageTitle slug="expenses.analytics.title">
        <ExpensesAnalytics />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/revenues/analytics',
    Component: (
      <PageTitle slug="revenues.analytics.title">
        <RevenuesAnalytics />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/taxes/analytics',
    Component: (
      <PageTitle slug="profit.analytics.title">
        <ProfitAnalytics />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/customer/:id/revenues/amortization',
    Component: <Amortization />,
    private: true,
    withLayout: false,
  },
  {
    path: '/customer/:id/amortization',
    Component: <Amortization />,
    private: true,
    withLayout: true,
  },
  {
    path: '/login',
    Component: <ExpertLogin />,
    private: false,
    withLayout: false,
  },
  {
    path: `/expertlogin`,
    Component: <MagicLinkHandler />,
    private: false,
    withLayout: false,
  },
  {
    path: '/signup',
    Component: <ExpertSignup />,
    private: false,
    withLayout: false,
  },
  {
    path: '/settings/*',
    Component: (
      <PageTitle slug="settings.title">
        <ExpertSettings />
      </PageTitle>
    ),
    private: true,
    withLayout: false,
  },
  {
    path: '*',
    Component: <h1>Not Found</h1>,
    private: false,
    withLayout: false,
  },
];

export default assignLayoutToRoutes(routes, navigationGenerator);
