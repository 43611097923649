import CheckBadge from 'components/atoms/SVG/CheckBadge';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentTaxContext } from '../../Tax';

const AllReviewed: FC<{ nextHandler?: () => void }> = ({ nextHandler }) => {
  const { setCurrentCheck } = useContext(CurrentTaxContext);
  useEffect(() => {
    const nextTimer = setTimeout(() => {
      setCurrentCheck(null);
      nextHandler?.();
    }, 3000);
    return () => clearTimeout(nextTimer);
  }, []);
  const { t } = useTranslation();
  return (
    <div className="rounded-10 shadow-topShadow bg-white min-h-[50vh] mt-4 flex flex-col items-center justify-center">
      <AnimatePresence>
        <motion.div
          {...{
            initial: { scale: 0.4, opacity: 0 },
            animate: {
              scale: 1,
              opacity: 1,
              transition: { duration: 0.5 },
            },
          }}
          className="mb-6"
        >
          <div className="rounded-full h-24 w-24 bg-taxes-200 flex items-center justify-center">
            <CheckBadge />
          </div>
        </motion.div>
        <div className="h-[80px]">
          <motion.div
            className="overflow-hidden text-center"
            {...{
              initial: { paddingTop: 60, height: 0 },
              animate: {
                paddingTop: 0,
                height: 80,
                transition: { delay: 0.5, duration: 0.5 },
              },
            }}
          >
            <div className="min-h-[40px] w-[360px] text-xl font-semibold">
              {t('taxes.checks.all_passed')}
            </div>
          </motion.div>
        </div>
      </AnimatePresence>
    </div>
  );
};

export default AllReviewed;
