import * as Sentry from '@sentry/react';
import getSuggestedTransaction from 'api/v1/transactions/getSuggestedTransaction';
import { getLockStatus } from 'api/v2/expenses';
import classNames from 'classnames';
import { Alert } from 'components/atoms/Alert';
import Anchor from 'components/atoms/Anchor';
import { Button } from 'components/atoms/Button';
import DatePicker from 'components/atoms/DatePicker/DatePicker.component';
import {
  FakeField,
  FieldConnector,
  FieldErrorManager,
} from 'components/atoms/Form/FieldConnector';
import FreeTextInput from 'components/atoms/Form/FreeTextInput';
import { Select } from 'components/atoms/Form/Select';
import { getOptionsDict } from 'components/atoms/Form/Select/helpers';
import { Toggle } from 'components/atoms/Form/Toggle';
import UseState from 'components/atoms/UseState';
import Amount from 'components/molecules/CommonCells/Amount.component';
import NumberField from 'components/molecules/Fields/Number/Number';
import PaymentAmount from 'components/molecules/Fields/PaymentAmount/PaymentAmount.component';
import { PaymentLinkSelect } from 'components/molecules/Fields/PaymentLink';
import {
  SelectedOptionContainer,
  TransactionOption,
  TransactionOptionPreset,
} from 'components/molecules/Fields/PaymentLink/utils';
import PeriodHelper from 'components/molecules/Fields/Period/PeriodHelper.components';
import {
  createPeriodsOptions,
  dateToPeriod,
  periodToValue,
} from 'components/molecules/Fields/Period/utils';
import VariantSelection from 'components/molecules/Fields/VariantSelection';
import { Helper } from 'components/molecules/Helper';
import LinkedItemsProgress from 'components/molecules/LinkedItemsProgress';
import { ExpenseSubmitLockedByTaxes } from 'components/molecules/SubmitLockByTaxes.compopnent';
import TaxImpact from 'components/organisms/TaxImpact';
import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useHasTransactions from 'hooks/Bank/useHasTransactions';
import useDebounceQuery from 'hooks/useDebounceQuery';
import type { ExpenseCategoriesType } from 'hooks/useExpensesCategories';
import useFeatures from 'hooks/useFeatures';
import useGetWorkingDaysAndDeductibleAmount from 'hooks/useGetWorkingDaysAndDeductibleAmount';
import { useHighlight } from 'hooks/useHighlight';
import useHighlighTaxCheckField from 'hooks/useHighlightTaxCheckField';
import { get, isEmpty, last } from 'lodash';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import { useEffect, useId, useMemo } from 'react';
import { flushSync } from 'react-dom';
import type { UseFormReturn } from 'react-hook-form';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { AlertIdsEnum } from 'types/AlertIdsEnum';
import { ExpensesCacheKeys } from 'types/cacheKeys.types';
import { ReviewStatusEnum } from 'types/expense.types';
import {
  TripType,
  TripVehicleType,
  type IExpense,
  type IExpenseItem,
  type SupplierAsOption,
} from 'types/expenses.types';
import type {
  LockStatusTypeCatgoriesed,
  VATReturnFrequency,
} from 'types/global.types';
import { TaxImpactDocumentType } from 'types/taxes.types';
import type { TransactionAsOption } from 'types/transactions.types';
import type { IUser } from 'types/users.types';
import { AccountTypeEnum, VATTypeEnum } from 'types/users.types';
import type { VehiclesOption } from 'types/vehicle.types';
import type { IVehicle } from 'types/vehicles.types';
import * as ExpensesEvents from 'utils/amplitude/events/expenses.amplitude';
import { viewSuggestedChanges } from 'utils/applySuggestedChanges';
import {
  autoMobileBE,
  CATEGORY_SOCIAL_CONTRIBUTIONS,
  CATEGORY_TAXES_AND_INSURANCE_VAT_PAYMENT,
  SELF_EMPLOYED_CAR_PRIVATE_USE,
} from 'utils/categories';
import { beVehicleDeductibliltyDate, isExpert, isWeb } from 'utils/constants';
import { calculateVATAmount, calculateVATRate } from 'utils/expenses/helper';
import formatMoney from 'utils/formatMoney';
import { createSentryCustomErrorMsg } from 'utils/helpers';
import { MinusIcon, PlusIcon } from 'utils/icons';
import convertMarkdown from 'utils/markdown';
import { round } from 'utils/math';
import { ONE_SECOND } from 'utils/time';
import transactionToOption from 'utils/transactionToOption';
import ExpenseVATCells from './ExpenseVATCells.component';
import { AmortizationPeriod } from './Fields/AmortizationPeriod';
import { getRecommendedAmortizationDurationInYears } from './Fields/AmortizationPeriod/helpers';
import CategoryPickerWithAI from './Fields/CategoriesPicker/CategoryPickerWithAI';
import useExpensesCategoriesPicker, {
  CategoryChangeParams,
} from './Fields/CategoriesPicker/useExpensesCategoriesPicker';
import useGuessExpenseCategoryWithAI from './Fields/CategoriesPicker/useGuessExpenseCategoryWithAI';
import IsInvoice from './Fields/IsInvoice';
import LocalVATRate from './Fields/LocalVATRate';
import { PayWithQRCode } from './Fields/PayWithQRCode';
import { SupplierCountry } from './Fields/SupplierCountry';
import SupplierName from './Fields/SupplierName';
import VATAmount from './Fields/VatAmount';
import { Vehicle } from './Fields/Vehicle';
import useFormMeta from './useFormMeta';
import {
  expenseAlternativeTransactionsOptions,
  FieldsArray,
  getDefaultVATRate,
  getExpenseLinkedPaymentsAmount,
  getExpenseLinkedPaymentsOppositeDirectionTotal,
  getLockStatusBody,
  getUpdatedIncomeTaxDeductibility,
  getUpdatedMaxDeductibleVAT,
  getVehicleIncomeTaxDeductibilityPerYear,
  isAmbiguousSupplier,
  isScamySupplier,
  itemDefault,
  SupplierInformative,
  supplierNameToSearchValue,
  TaxSearchWarning,
  useVATRatesGetter,
} from './utils';

const markdownComponents = {
  a: ({ children, href }: any) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="underline break-word"
    >
      {children}
    </a>
  ),
  p: ({ children }: any) => <p className="whitespace-pre-wrap">{children}</p>,
  li: ({ children }: any) => (
    <li className="py-1 flex gap-2">
      <span className="text-2xl leading-tight">&#8226;</span>
      {children}
    </li>
  ),
};

type FormProps = {
  formAPI: UseFormReturn;
  expense: Partial<IExpense>;
  headerMeta?: ReactNode;
  footerMeta?: ReactNode;
  onSubmit: (values: Partial<IExpense>) => any;
  disabled?: boolean;
  disabledFields?: string[] | '*';
  notDisabledFields?: string[];
  onEditVehicles?: () => void;
  categories: ExpenseCategoriesType;
  isLocked?: boolean;
  allowDynamicTypeSwitching?: boolean;
};

type FormValues = Omit<Partial<IExpense>, 'items'> & {
  items: IExpenseItem[];
  transactionsSuggestedIsActive?: boolean;
};

const today = dayjs();

const Form: FC<FormProps> = ({
  formAPI,
  expense,
  headerMeta,
  footerMeta,
  onSubmit,
  disabled,
  disabledFields,
  notDisabledFields,
  onEditVehicles,
  categories,
  isLocked,
  allowDynamicTypeSwitching = true,
}) => {
  const { t, i18n } = useTranslation();
  const { data: customer } = useGetCurrentCustomerData();

  const {
    watch,
    getValues,
    setValue,
    formState: { isSubmitting, errors: formErrors, dirtyFields, isValid },
  } = formAPI;

  // please keep this error log, it can be useful for fast debugging
  console.log(formErrors);

  const hasErrors = !isValid;

  const hasDirtyFields = !isEmpty(dirtyFields);

  const formValues = watch() as FormValues;

  const highlightAPI = useHighlight();

  // should be called BEFORE changing the field value, since hookform can update its state with mutations (kind of)
  // and by the time we call getValues(name) it will be already changed and old value will equal the next one
  const highlightFieldIfNeeded = (name: string, nextValue: any) => {
    if (getValues(name) === nextValue) return;
    highlightAPI.highlight(name);
  };

  const itemsController = useFieldArray({
    name: 'items',
    control: formAPI.control,
  });

  const issueField = useHighlighTaxCheckField(expense);

  const { getFormMeta, getItemMeta, getSuggestedChanges } = useFormMeta();

  const formMeta = getFormMeta(formValues);

  useEffect(() => {
    viewSuggestedChanges(
      formValues,
      getSuggestedChanges(formValues),
      setValue,
      true,
    );
  }, [formValues]);

  const documentVATReturnFrequency = getValues(
    'user.VATReturnFrequency',
  ) as VATReturnFrequency;
  const { country: customerCountry } = customer as IUser;

  const defaultTaxPeriod = expense.taxPeriod;
  const deductibleTaxCategories: string[] = useFeatures(
    'expenses.deductible_tax_categories',
  );

  const defaultVATRate = useFeatures('expenses.default_vat_rate');
  const invoiceIBAN = expense.OCRIBAN || expense.guessedIBAN;
  const canPayWithQRCode =
    useFeatures('expenses.can_pay_with_qr_code') && !!invoiceIBAN;
  const reverseChargeHelper = useFeatures('expenses.reverse_charge_helper');

  const isDirty = (key: string) => {
    return !!get(dirtyFields, key);
  };

  const AllItemsAmountInclVAT = (formValues.items || []).reduce(
    (acc: number, item: any) => {
      const amount = acc + (item.amount || 0);
      return amount;
    },
    0,
  );

  const AllItemsVATAMounts =
    formMeta.fields.isThereVATOnInvoice.value != null
      ? 0
      : formValues.items.reduce(
          (acc: number, item: any) => acc + (item.VATAmount || 0),
          0,
        );

  const AllItemsAmountExclVAT = formValues.items.reduce(
    (acc: number, item: any) => {
      const itemAmount = item.amount || 0;
      const itemVATAmount = item.VATAmount || 0;
      if (formMeta.fields.isThereVATOnInvoice.value != null) {
        return acc + itemAmount;
      }
      return acc + (itemAmount - itemVATAmount);
    },
    0,
  );

  const presetsOptions = useMemo(
    () =>
      expenseAlternativeTransactionsOptions.map((option) => ({
        ...option,
        label: t(option.label),
      })),
    [t],
  );

  const periodOptions = useMemo(
    () =>
      createPeriodsOptions(formValues.expenseDate, documentVATReturnFrequency),
    [formValues.expenseDate, documentVATReturnFrequency],
  );

  const updatePeriodBasedOnDate = (date: string | null | undefined) => {
    if (!date) return setValue('period', null);
    const period = dateToPeriod(dayjs(date), documentVATReturnFrequency);
    return setValue('period', period);
  };

  const updateItemDeductability = (i: number) => {
    const values = formAPI.getValues();
    const item = formAPI.getValues(`items.${i}`);
    const isVehcileFieldApplicable = getItemMeta(values, item).fields.vehicleId
      .isApplicable;
    const maxKey = `items.${i}.maxDeductibleVAT`;
    const maxValue = getUpdatedMaxDeductibleVAT(item, isVehcileFieldApplicable);
    const incomeKey = `items.${i}.incomeTaxDeductibility`;
    const icomeValue = getUpdatedIncomeTaxDeductibility(
      values.expenseDate,
      item,
      isVehcileFieldApplicable,
    );
    highlightFieldIfNeeded(maxKey, maxValue);
    highlightFieldIfNeeded(incomeKey, icomeValue);
    setValue(maxKey, maxValue);
    setValue(incomeKey, icomeValue);
  };

  const maxVATRate = useFeatures('expenses.max_vat_rate');

  const suggestedTransactionError =
    formErrors.transactionsSuggestedIsActive?.message;

  const isReviewed = formValues.accountantReview?.reviewStatus === 'reviewed';

  const hasTransactions = !!useHasTransactions().data;

  const dateFormat = useFeatures('formats.date');

  const lockStatusParams = getLockStatusBody(formValues);
  const lockBytaxesStatusAPI = useDebounceQuery<LockStatusTypeCatgoriesed>({
    queryKey: [ExpensesCacheKeys.TaxLock, useId(), lockStatusParams],
    queryFn: () => getLockStatus(lockStatusParams),
    cacheTime: 0,
    staleTime: 0,
    debounceTime: ONE_SECOND * 0.5,
    enabled: !isLocked,
  });

  const isFieldDisabled = useMemo(() => {
    const disabled: Record<string, boolean> = {};
    const notDisabled: Record<string, boolean> = {};
    if (Array.isArray(disabledFields)) {
      disabledFields.forEach((key) => (disabled[key] = true));
    }
    notDisabledFields?.forEach((key) => (notDisabled[key] = true));

    return (key: string) => {
      if (notDisabled[key]) return false;
      if (disabled[key] || disabledFields === '*') return true;
      return false;
    };
  }, [disabledFields, notDisabledFields]);

  const expenseYear = formValues.expenseDate
    ? dayjs(formValues.expenseDate).year()
    : undefined;

  const workingDaysDeductibleAmount = useGetWorkingDaysAndDeductibleAmount(
    expenseYear,
    formValues._id,
    formMeta.helpers.isGermanPrivateCar,
  ).data;

  const hasMultipleItems = formValues.items?.length > 1;

  const pickerController = useExpensesCategoriesPicker({
    defaultTaxPeriod,
    taxPeriod: formValues.taxPeriod,
    tracing: {
      onOpen: ExpensesEvents.WEBAPP_EXPENSE_CLICKEDCATEGORY,
      onChange: ExpensesEvents.WEBAPP_EXPENSE_UPDATEDCATEGORY,
    },
    searchBarPlaceholder: t('expense.category_picker.placeholder'),
    disabledCategories: {
      [SELF_EMPLOYED_CAR_PRIVATE_USE]: hasMultipleItems,
      [CATEGORY_SOCIAL_CONTRIBUTIONS]: hasMultipleItems,
      [CATEGORY_TAXES_AND_INSURANCE_VAT_PAYMENT]: hasMultipleItems,
    },
    tooltips: {
      [SELF_EMPLOYED_CAR_PRIVATE_USE]: hasMultipleItems
        ? t('one_item_categories.tooltip')
        : null,
      [CATEGORY_SOCIAL_CONTRIBUTIONS]: hasMultipleItems
        ? t('one_item_categories.tooltip')
        : null,
      [CATEGORY_TAXES_AND_INSURANCE_VAT_PAYMENT]: hasMultipleItems
        ? t('one_item_categories.tooltip')
        : null,
    },
    renderGridHeader: (searchTerm) => (
      <TaxSearchWarning searchTerm={searchTerm} />
    ),
    renderHeader: (category: any) => {
      const isVehicleCategory = category?.list?.some(
        (c: any) => c.id === SELF_EMPLOYED_CAR_PRIVATE_USE,
      );
      if (!isVehicleCategory) return null;
      return (
        <Alert
          type="default"
          title={
            <div className="flex justify-between">
              <p className="text-primary-700">
                {t('expenses.info_bubble.car_business_or_private.title')}
              </p>
              <Helper
                trigger={(open) => (
                  <Button
                    asDefault
                    className="text-taxes ml-auto"
                    onClick={open}
                  >
                    {t('learn_more')}
                  </Button>
                )}
              >
                <Helper.Title>
                  {t('expenses.info_bubble.car_business_or_private.title')}
                </Helper.Title>
                <Helper.Paragraph className="whitespace-pre-line">
                  {t('expenses.info_bubble.car_business_or_private.text')}
                </Helper.Paragraph>
              </Helper>
            </div>
          }
        />
      );
    },
  });

  const guessCategoryWithAI = useGuessExpenseCategoryWithAI({
    filePath: expense.filePath,
    supplier: formValues.supplier,
    transactions: formValues.transactions,
  });
  const guessedCategories = guessCategoryWithAI?.query?.data;

  const itemsCategoryPickerOnChange = (
    index: number,
    category: any,
    params?: CategoryChangeParams,
  ) => {
    setValue('items.' + index + '.categoryId', category.id, {
      shouldDirty: true,
      shouldValidate: true,
    });

    setValue('items.' + index + '.category', category);

    const professionalPartValue = (() => {
      const recommended = category?.recommendedProfessionalPart;
      if (recommended != null) return recommended;
      if (
        formMeta.helpers.isCustomerNaturalPerson &&
        autoMobileBE.includes(category.id)
      )
        return null;
      const maxProfessional = category?.maxProfessionalPart;
      if (maxProfessional != null) return maxProfessional;
      return 1;
    })();

    setValue('items.' + index + '.professionalPart', professionalPartValue, {
      shouldValidate: !!professionalPartValue,
    });

    if (params?.taxPeriod) {
      setValue('taxPeriod', params?.taxPeriod);
    }

    // if the users uses the quick category picker
    if (params?.guessedCategoryFrom) {
      setValue('guessedCategoryFrom', params?.guessedCategoryFrom);
      setValue('guessedCategoryId', category.id);
    }
    // if he uses the regular picker, but there is a guess
    else if (guessedCategories?.length) {
      const bestGuess =
        guessedCategories.find((g) => g?.category?.id === category.id) ||
        guessedCategories[0];
      setValue('guessedCategoryFrom', bestGuess.guessedCategoryFrom);
      setValue('guessedCategoryId', bestGuess.category.id);
    }

    if (params?.vehicle) {
      setValue('items.' + index + '.vehicleId', params?.vehicle._id, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('items.' + index + '.vehicle', params?.vehicle);
    }

    updateItemDeductability(index);
  };
  const VATRates = useVATRatesGetter()({ expenseDate: formValues.expenseDate });

  return (
    <>
      <FormProvider {...formAPI}>
        {pickerController.api.content}
        <form className="relative pb-20">
          <div className="bg-[#FCFBFE] px-6 py-10 border-b border-primary-100">
            {headerMeta}
            <div className="flex flex-col gap-4">
              <FieldConnector
                name="isInvoice"
                disabled={isFieldDisabled}
                showUI={!formMeta.fields.isInvoice.isHidden}
                label={t('expense.is_invoice.label')}
                helper={
                  <Helper>
                    <Helper.Title>{t('helper_invoice_title')}</Helper.Title>
                    <Helper.Paragraph>
                      {t('helper_invoice_text1')}
                    </Helper.Paragraph>
                    <Helper.Paragraph>
                      {t('helper_invoice_text2')}
                    </Helper.Paragraph>
                    <Helper.Paragraph>
                      {t('helper_invoice_text3')}
                    </Helper.Paragraph>
                    <Helper.SubTitle>
                      {t('helper_invoice_subtitle')}
                    </Helper.SubTitle>
                    <Helper.Paragraph className="whitespace-pre-line">
                      {t('helper_invoice_text4')}
                    </Helper.Paragraph>
                    <Helper.Paragraph>
                      {t('helper_invoice_text5')}
                    </Helper.Paragraph>
                    <Helper.Paragraph>
                      {t('helper_invoice_text6')}
                    </Helper.Paragraph>
                  </Helper>
                }
              >
                {({ field }) => (
                  <IsInvoice
                    tracing={{
                      onChange: {
                        name: ExpensesEvents.WEBAPP_EXPENSE_UPDATEDISINVOICE,
                        data: (value: boolean) => ({ value }),
                      },
                    }}
                    {...field}
                    onChange={(isInvoice) => {
                      field.onChange(isInvoice);
                    }}
                  />
                )}
              </FieldConnector>

              {formMeta.helpers.shouldShowIsInvoiceWarning && (
                <Alert
                  className="mb-0"
                  type="warning"
                  showIntercomBtn
                  name="classified as invoice without VAT number on the document"
                  description={t(
                    'expenses.warning_bubble.invoice_without_vat_number.be',
                  )}
                />
              )}
              {formMeta.helpers.shouShowIsTicketWarning && (
                <Alert
                  className="mb-0"
                  type="warning"
                  showIntercomBtn
                  name="classified as ticket with VAT number on the document"
                  description={
                    <div className="flex gap-4">
                      <span>
                        {t('expenses.warning_bubble.ticket_with_vat_number.be')}
                      </span>
                      <span className="flex-shrink-0">
                        <Helper>
                          <Helper.Title>
                            {t(
                              'expenses.warning_bubble.ticket_with_vat_number.helper_title1.be',
                            )}
                          </Helper.Title>
                          <Helper.Paragraph className="whitespace-pre-wrap">
                            {t(
                              'expenses.warning_bubble.ticket_with_vat_number.helper_text1.be',
                            )}
                          </Helper.Paragraph>
                          <Helper.Title>
                            {t(
                              'expenses.warning_bubble.ticket_with_vat_number.helper_title2.be',
                            )}
                          </Helper.Title>
                          <Helper.Paragraph>
                            {t(
                              'expenses.warning_bubble.ticket_with_vat_number.helper_text2.be',
                            )}
                          </Helper.Paragraph>
                        </Helper>
                      </span>
                    </div>
                  }
                />
              )}

              <FieldConnector
                name="expenseDate"
                disabled={isFieldDisabled}
                label={
                  formMeta.helpers.isGermanPrivateCar
                    ? t('date')
                    : t('expense.expenseDate')
                }
                loading={lockBytaxesStatusAPI.isLoading}
                helper={
                  formMeta.helpers.shouldShowExpenseDateHelper && (
                    <Helper>
                      <Helper.Paragraph>
                        {t('expense.expenseDate.helper')}
                      </Helper.Paragraph>
                    </Helper>
                  )
                }
              >
                {({ field: { value, onChange, ...field } }) => (
                  <DatePicker
                    tracing={{
                      onCalendarOpen:
                        ExpensesEvents.WEBAPP_EXPENSE_CLICKEDEXPENSEDATE,
                      onChange:
                        ExpensesEvents.WEBAPP_EXPENSE_UPDATEDEXPENSEDATE,
                    }}
                    {...field}
                    highlightID={field.name}
                    selected={value}
                    onChange={(date) => {
                      const value = date
                        ? dayjs(date).format('YYYY-MM-DD')
                        : null;
                      onChange(value);
                      formValues.items?.forEach((_, index) =>
                        updateItemDeductability(index),
                      );
                      if (formMeta.fields.period.isApplicable) {
                        updatePeriodBasedOnDate(value);
                      }
                    }}
                  />
                )}
              </FieldConnector>

              {!!lockBytaxesStatusAPI.data?.date && (
                <ExpenseSubmitLockedByTaxes
                  isCreditNote={!!formValues.isCreditNote}
                  fieldSlug={'expense_date'}
                  taxLockReasons={lockBytaxesStatusAPI.data.date}
                />
              )}

              {formMeta.helpers.shouldShowExpenseDateWarning && (
                <Alert
                  type="info"
                  name="expense date in the future"
                  showIntercomBtn
                  description={t('warning_bubble.document_date_in_the_future')}
                />
              )}

              {formMeta.helpers.shouldShowTenDayRuleWarning && (
                <Alert
                  type="info"
                  name="10 days rule"
                  className="mb-0"
                  description={
                    <div className="flex gap-4">
                      <span>
                        {t('expenses.warning_bubble.ten_days_rule.de', {
                          date: dayjs(formValues.expenseDate, 'YYYY-MM-DD')
                            .subtract(1, 'year')
                            .endOf('year')
                            .format(dateFormat),
                        })}
                      </span>
                      <span className="flex-shrink-0">
                        <Helper>
                          <Helper.Paragraph>
                            {t(
                              'expenses.warning_bubble.ten_days_rule.helper.de',
                            )}
                          </Helper.Paragraph>
                        </Helper>
                      </span>
                    </div>
                  }
                />
              )}

              {!formMeta.fields.supplier.isHidden && (
                <div className="flex flex-col gap-1">
                  <div className="relative">
                    <FakeField
                      label={t('expense.supplier.name')}
                      disabled={
                        isFieldDisabled('supplier.country') &&
                        isFieldDisabled('supplier.name')
                      }
                    >
                      {() => (
                        <>
                          <FieldConnector
                            name="supplier.country"
                            labelClassName="invisible"
                            className="float-left w-1/4 pr-4"
                            forceHideError
                          >
                            {({ field }) => (
                              <SupplierCountry
                                tracing={{
                                  onMenuOpen:
                                    ExpensesEvents.WEBAPP_EXPENSE_CLICKEDSUPPLIERNAME,
                                  onChange:
                                    ExpensesEvents.WEBAPP_EXPENSE_UPDATEDSUPPLIERCOUNTRY,
                                }}
                                highlightID={field.name}
                                disabled={isFieldDisabled(field.name)}
                                {...field}
                              />
                            )}
                          </FieldConnector>

                          <FieldConnector
                            name="supplier.name"
                            labelClassName="invisible"
                            className="float-right w-3/4"
                            forceHideError
                          >
                            {({ field: { onChange, ...field } }) => (
                              <SupplierName
                                {...field}
                                placeholder={t(
                                  'expense.supplier_field.placeholder',
                                )}
                                disabled={isFieldDisabled(field.name)}
                                tracing={{
                                  onMenuOpen:
                                    ExpensesEvents.WEBAPP_EXPENSE_CLICKEDSUPPLIERNAME,
                                  onChange:
                                    ExpensesEvents.WEBAPP_EXPENSE_UPDATEDSUPPLIERNAME,
                                }}
                                onChange={(option, actionMeta) => {
                                  // TODO remove supplier type on create ?
                                  if (!option) return;
                                  onChange(option.value);
                                  if (option.__isCreated) return;
                                  const supplierOption =
                                    option as SupplierAsOption;
                                  const {
                                    country,
                                    supplierType,
                                    categoryId,
                                    professionalPart,
                                  } = supplierOption.supplier;
                                  highlightFieldIfNeeded(
                                    'supplier.country',
                                    country,
                                  );
                                  setValue('supplier.country', country, {
                                    shouldValidate: true,
                                  });
                                  setValue(
                                    'supplier.supplierType',
                                    supplierType,
                                  );

                                  // if (formValues.isValidated) return // already submitted

                                  formValues.items?.forEach((_, index) => {
                                    if (
                                      categoryId &&
                                      categories.dict[categoryId]
                                    ) {
                                      highlightFieldIfNeeded(
                                        `items.${index}.categoryId`,
                                        categoryId,
                                      );
                                      setValue(
                                        `items.${index}.categoryId`,
                                        categoryId,
                                        { shouldValidate: true },
                                      );
                                      setValue(
                                        `items.${index}.category`,
                                        categories.dict[categoryId],
                                      );
                                      updateItemDeductability(index);
                                    }
                                    if (professionalPart != null) {
                                      highlightFieldIfNeeded(
                                        `items.${index}.professionalPart`,
                                        professionalPart,
                                      );
                                      setValue(
                                        `items.${index}.professionalPart`,
                                        professionalPart,
                                        { shouldValidate: true },
                                      );
                                    }
                                  });
                                }}
                              />
                            )}
                          </FieldConnector>
                        </>
                      )}
                    </FakeField>
                  </div>

                  <div>
                    <FieldErrorManager name="supplier.country" />
                    <FieldErrorManager name="supplier.name" />
                  </div>
                </div>
              )}

              {isScamySupplier(formValues.supplier?.name) &&
                formMeta.helpers.isCustomerBE && (
                  <Alert
                    type="warning"
                    name="scamy supplier"
                    description={t('expenses.warning_bubble.scamy_supplier', {
                      name: formValues.supplier?.name,
                    })}
                    renderButtons={(className) => (
                      <a
                        href={t('expenses.warning_bubble.scamy_supplier.link')}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classNames(
                          className,
                          'font-semibold underline text-sm',
                        )}
                      >
                        {t('read_blog_post')}
                      </a>
                    )}
                  />
                )}

              {isAmbiguousSupplier(formValues.supplier?.name) && (
                <Alert
                  type="info"
                  showIntercomBtn
                  name="ambiguous supplier"
                  description={t('expenses.warning_bubble.ambiguous_supplier')}
                />
              )}

              <SupplierInformative
                expenseDate={formValues.expenseDate}
                guessedVATNumber={expense.guessedVATNumber}
                customerCountry={customerCountry}
                isSupplierEU={formMeta.helpers.isSupplierEU}
                isSupplierLocal={formMeta.helpers.isSupplierLocal}
                isSupplierOther={formMeta.helpers.isSupplierOther}
                VATRate={
                  formValues.items[0].whyZeroVAT
                    ? undefined
                    : formValues.items[0].vat
                }
              />
            </div>
          </div>

          <div className="px-6 pt-4">
            <div className="flex flex-col gap-4">
              <FieldConnector
                name="isThereVATOnInvoice"
                showUI={!formMeta.fields.isThereVATOnInvoice.isHidden}
                disabled={isFieldDisabled}
                {...(formMeta.helpers.useIsThereVATEU
                  ? {
                      mainLabelClassName: 'font-semibold',
                      label: t('expenses.is_this_reverse_charge.label'),
                      subLabel: t(
                        'expenses.is_this_reverse_charge.description',
                      ),
                    }
                  : {
                      label: t('expenses.local_vat_rate.question'),
                    })}
                helper={
                  <Helper>
                    <Helper.Title>
                      {t('expenses.is_this_reverse_charge.helper.title1')}
                    </Helper.Title>
                    <Helper.Paragraph>
                      {t('expenses.is_this_reverse_charge.helper.text1')}
                    </Helper.Paragraph>
                    <Helper.SubTitle>
                      {t('expenses.is_this_reverse_charge.helper.title2')}
                    </Helper.SubTitle>
                    <Helper.Paragraph>
                      {t('expenses.is_this_reverse_charge.helper.text2')}
                    </Helper.Paragraph>
                    <Helper.Paragraph>
                      <ReactMarkdown components={markdownComponents}>
                        {t(
                          `expenses.is_this_reverse_charge.helper.learn_more.${customerCountry}`,
                        )}
                      </ReactMarkdown>
                    </Helper.Paragraph>
                  </Helper>
                }
              >
                {({ field }) => (
                  <div className="flex gap-4">
                    <VariantSelection
                      value={!!formMeta.fields.isThereVATOnInvoice.value}
                      onChange={(value) => field.onChange(value)}
                      isDisabled={field.disabled}
                      options={[
                        {
                          label: t('yes'),
                          value: !formMeta.helpers.useIsThereVATEU,
                        },
                        {
                          label: t('no'),
                          value: formMeta.helpers.useIsThereVATEU,
                        },
                      ]}
                    />
                  </div>
                )}
              </FieldConnector>
              {formMeta.helpers
                .shouldShowReverseChargeSmallBusinessInformative && (
                <Alert
                  showIntercomBtn
                  type="warning"
                  title={t('attention')}
                  name="reverse charge small business"
                  description={t(
                    `expenses.info_bubble.reverse_charge_small_business.${customerCountry}`,
                    {
                      userCountry: t(`country.${customerCountry}`),
                    },
                  )}
                />
              )}
              {formMeta.helpers.shouldShowNoVATReturnInformative && (
                <Alert
                  showIntercomBtn
                  type="warning"
                  title={t('attention')}
                  name="no VAT back"
                  description={t(
                    `expenses.info_bubble.no_vat_back.${customerCountry}`,
                  )}
                />
              )}
              {formMeta.helpers.shouldShowSpecialVATDeclarationWarning && (
                <Alert
                  showIntercomBtn
                  type="warning"
                  title={t('attention')}
                  name="special VAT declaration"
                  description={t(
                    `expenses.info_bubble.special_vat_declaration.${customerCountry}`,
                  )}
                />
              )}
              {formMeta.helpers.shouldShowVATInformative && (
                <Alert
                  showIntercomBtn
                  type="info"
                  title={t('attention')}
                  description={t(
                    `expenses.info_bubble.paid_next_vat_declaration`,
                    {
                      country: t(`country.${customerCountry}`),
                      userDemonym: t(`nationality.${customerCountry}`),
                    },
                  )}
                />
              )}
            </div>
            <div className="flex flex-col gap-4">
              <FieldConnector name="items">
                {({ name: parentName }) => (
                  <FieldsArray
                    showItemsNumber={formValues.items?.length > 1}
                    isItemsRemovable={!isLocked && formValues.items?.length > 1}
                    controller={itemsController}
                    name={parentName}
                    className="mt-6"
                    render={(props) => {
                      const { field, index } = props;
                      const itemName = `${parentName}.${index}`;
                      const getName = (name: string) => `${itemName}.${name}`;
                      const item = getValues(itemName);
                      const itemMeta = getItemMeta(formValues, item);

                      const VATAmountOptions = VATRates.map((rate) => {
                        const value = item.isExclVAT
                          ? round(
                              ((item.amount || 0) - (item.VATAmount || 0)) *
                                rate,
                            )
                          : calculateVATAmount(item.amount || 0, rate);
                        return {
                          value,
                          tag: `${round(rate * 100)}%`,
                        };
                      });

                      const priceIncVATLabel = `${t('amountSign')} (${t(
                        'incl_vat',
                      )})`;
                      const priceExcVATLabel = `${t('amountSign')} (${t(
                        'excl_vat',
                      )})`;

                      const priceLabel = (() => {
                        if (formMeta.helpers.isDeductible)
                          return t('amountSign');
                        if (item.isExclVAT) return priceExcVATLabel;
                        if (!item.categoryId) return priceIncVATLabel;
                        if (
                          formMeta.helpers.isSupplierOther &&
                          itemMeta.helpers.isItemGood
                        ) {
                          if (formMeta.helpers.isCustomerSubjectToVAT)
                            return priceExcVATLabel;
                          return priceIncVATLabel;
                        }
                        if (
                          formMeta.helpers.isSupplierLocal ||
                          formMeta.fields.isThereVATOnInvoice.value
                        ) {
                          return priceIncVATLabel;
                        }
                        return priceExcVATLabel;
                      })();

                      const toggleVATExcl = (isExclVAT: boolean) => {
                        const rate = calculateVATRate(
                          item.VATAmount,
                          item.amount,
                        );
                        const amount = isExclVAT
                          ? round(item.amount + round(item.amount * rate))
                          : round(item.amount - item.VATAmount);

                        // console.log({ isExclVAT, amount });
                        const newVATAmount = calculateVATAmount(amount, rate);
                        setValue(getName('amount'), amount);
                        setValue(getName('VATAmount'), newVATAmount);
                        setValue(getName('isExclVAT'), isExclVAT);
                        // onChange(isExclVAT);
                      };

                      const onVATChange = (newVATAmount: number) => {
                        if (item.isExclVAT) {
                          const amountExcl =
                            (item.amount || 0) - (item.VATAmount || 0);
                          setValue(
                            getName('amount'),
                            round(amountExcl + newVATAmount),
                          );
                        }
                        setValue(getName('VATAmount'), newVATAmount);
                      };

                      return (
                        <div key={field.id} className="flex flex-col gap-4">
                          <FieldConnector
                            name={getName('categoryId')}
                            disabled={isFieldDisabled}
                            label={t('expense.category')}
                            highlightID="categoryId"
                          >
                            {({ field: { value, onChange, ...rest } }) => (
                              <>
                                <CategoryPickerWithAI
                                  {...rest}
                                  pickerController={pickerController}
                                  placeholder={t(
                                    'expense.category_field.placeholder',
                                  )}
                                  isDisabled={rest.disabled}
                                  highlightID={rest.name}
                                  value={value}
                                  taxPeriod={formValues.taxPeriod}
                                  onChange={(category, params) =>
                                    itemsCategoryPickerOnChange(
                                      index,
                                      category,
                                      params,
                                    )
                                  }
                                  {...guessCategoryWithAI}
                                />
                                {value && i18n.exists(`AYCD.${value}`) && (
                                  <div className="text-right">
                                    <Anchor
                                      href={`${t(
                                        `AYCD.${value}`,
                                      )}?utm_source=webapp&utm_medium=expenseform`}
                                      target="_blank"
                                      color="expenses"
                                      rel="noopener noreferrer"
                                      className="font-semibold"
                                    >
                                      {t('expense.category.can_i_deduct_it')}
                                    </Anchor>
                                  </div>
                                )}
                              </>
                            )}
                          </FieldConnector>

                          {itemMeta.helpers.shouldShowBusinessCarInfo && (
                            <Alert
                              type="info"
                              showIntercomBtn
                              className="mb-0"
                              description={t(
                                'expenses.info_bubble.business_car_usage',
                              )}
                            />
                          )}

                          {itemMeta.helpers.shouldShowUtilityVehicleWarning && (
                            <Alert
                              type="info"
                              showIntercomBtn
                              className="mb-0"
                              name="vehicle must be classified as a utility"
                              description={
                                <div className="flex gap-4">
                                  <span>
                                    {t(
                                      'expenses.warning_bubble.utility_vehicle.be',
                                    )}
                                  </span>
                                  <span className="flex-shrink-0">
                                    <Helper>
                                      <Helper.Paragraph>
                                        {t(
                                          'expenses.warning_bubble.utility_vehicle.helper.be',
                                        )}
                                      </Helper.Paragraph>
                                    </Helper>
                                  </span>
                                </div>
                              }
                            />
                          )}

                          {itemMeta.helpers.shouldShowHealthWarning && (
                            <Alert
                              type="info"
                              showIntercomBtn
                              className="mb-0"
                              name="health insurance warning"
                              description={
                                <div className="flex gap-4">
                                  <span>
                                    {t(
                                      'expenses.warning_bubble.health_insurance.de',
                                    )}
                                  </span>
                                  <span className="flex-shrink-0">
                                    <Helper>
                                      <Helper.Paragraph>
                                        {t(
                                          'expenses.warning_bubble.ten_days_rule.helper_text1.de',
                                        )}
                                      </Helper.Paragraph>
                                      <Helper.Paragraph>
                                        {t(
                                          'expenses.warning_bubble.ten_days_rule.helper_text2.de',
                                        )}
                                      </Helper.Paragraph>
                                    </Helper>
                                  </span>
                                </div>
                              }
                            />
                          )}

                          {itemMeta.helpers.shouldShowVehicleFineWarning && (
                            <Alert
                              type="info"
                              showIntercomBtn
                              className="mb-0"
                              name="a fixed fee for a parking violation can be classified as a parking expense"
                              description={
                                <div className="flex gap-4">
                                  <span>
                                    {t(
                                      'expenses.warning_bubble.vehicle_fine.be',
                                    )}
                                  </span>
                                  <span className="flex-shrink-0">
                                    <Helper>
                                      <Helper.Paragraph>
                                        {t(
                                          'expenses.warning_bubble.vehicle_fine.helper.be',
                                        )}
                                      </Helper.Paragraph>
                                    </Helper>
                                  </span>
                                </div>
                              }
                            />
                          )}

                          {itemMeta.helpers.shouldShowLunchWarning && (
                            <Alert
                              type="info"
                              showIntercomBtn
                              name="lunch voucher"
                              title={
                                <div className="flex gap-2">
                                  <span>{t('attention')}</span>
                                  <Helper>
                                    <Helper.Title>
                                      {t('expenses.helper.lunch_voucher.title')}
                                    </Helper.Title>
                                    <Helper.Paragraph>
                                      {t('expenses.helper.lunch_voucher.text')}
                                    </Helper.Paragraph>
                                  </Helper>
                                </div>
                              }
                              description={t('expenses.lunch_voucher_warning')}
                            />
                          )}

                          {itemMeta.helpers.shouldShowLumpsumWarning && (
                            <Alert
                              type="info"
                              showIntercomBtn
                              name="lumpsum warning"
                              description={t('expenses.lumpsum_warning')}
                            />
                          )}

                          {itemMeta.helpers
                            .shouldShowSoftwareInvoiceWarning && (
                            <Alert
                              type="info"
                              showIntercomBtn
                              dismissable
                              dismissId={AlertIdsEnum.expenseSoftwareInvoice}
                              name="software invoice warning"
                              description={t(
                                'expenses.software_invoice_warning',
                              )}
                            />
                          )}

                          {itemMeta.helpers
                            .shouldShowSelfEmployedCompensationWarning && (
                            <Alert
                              type="info"
                              showIntercomBtn
                              name="self-employed compensation"
                              description={
                                <div className="flex gap-4">
                                  <span>
                                    {t(
                                      'expenses.self_employed_compensation_warning',
                                    )}
                                  </span>
                                  <span className="flex-shrink-0">
                                    <Helper>
                                      <Helper.Paragraph>
                                        {t(
                                          'expenses.self_employed_compensation_warning.helper',
                                        )}
                                      </Helper.Paragraph>
                                    </Helper>
                                  </span>
                                </div>
                              }
                            />
                          )}

                          {!itemMeta.fields.tripType.isHidden && (
                            <FieldConnector
                              name={getName('tripType')}
                              disabled={isFieldDisabled}
                              label={t('expense.form.destination')}
                            >
                              {({ field: { onChange, ...rest } }) => (
                                <Select
                                  options={[
                                    {
                                      label: t(
                                        'expense.form.destination.business_trip',
                                      ),
                                      value: TripType.businessTrip,
                                    },
                                    {
                                      label: t(
                                        'expense.form.destination.drive_to_work',
                                      ),
                                      value: TripType.driveToWork,
                                    },
                                  ]}
                                  isSearchable={false}
                                  onChange={(option: any) => {
                                    onChange(option.value);
                                  }}
                                  {...rest}
                                  isDisabled={rest.disabled}
                                  tracing={{
                                    onMenuOpen:
                                      ExpensesEvents.WEBAPP_EXPENSE_CLICKEDTRIPTYPE,
                                    onChange:
                                      ExpensesEvents.WEBAPP_EXPENSE_UPDATEDTRIPTYPE,
                                  }}
                                />
                              )}
                            </FieldConnector>
                          )}

                          {!itemMeta.fields.tripVehicleType.isHidden && (
                            <FieldConnector
                              name={getName('tripVehicleType')}
                              label={t('expense.form.type_of_vehicle')}
                              disabled={isFieldDisabled}
                            >
                              {({ field: { onChange, ...rest } }) => (
                                <Select
                                  options={[
                                    {
                                      label: t(
                                        'expense.form.type_of_vehicle.car',
                                      ),
                                      value: TripVehicleType.car,
                                    },
                                    {
                                      label: t(
                                        'expense.form.type_of_vehicle.motorbike',
                                      ),
                                      value: TripVehicleType.motorbike,
                                    },
                                  ]}
                                  isSearchable={false}
                                  onChange={(option: any) => {
                                    onChange(option.value);
                                  }}
                                  {...rest}
                                  isDisabled={rest.disabled}
                                  tracing={{
                                    onMenuOpen:
                                      ExpensesEvents.WEBAPP_EXPENSE_CLICKEDTRIPVEHICLETYPE,
                                    onChange:
                                      ExpensesEvents.WEBAPP_EXPENSE_UPDATEDTRIPVEHICLETYPE,
                                  }}
                                />
                              )}
                            </FieldConnector>
                          )}

                          {!itemMeta.fields.tripTotalKm.isHidden && (
                            <FieldConnector
                              name={getName('tripTotalKm')}
                              label={
                                itemMeta.fields.tripType.value ===
                                TripType.businessTrip
                                  ? t('expense.form.kilometers')
                                  : t('expense.form.distance')
                              }
                              disabled={isFieldDisabled}
                            >
                              {({ field }) => (
                                <NumberField
                                  {...field}
                                  tracing={{
                                    onFocus:
                                      ExpensesEvents.WEBAPP_EXPENSE_CLICKEDTRIPTOTALKM,
                                  }}
                                />
                              )}
                            </FieldConnector>
                          )}

                          {!itemMeta.fields.tripWorkingDays.isHidden && (
                            <FieldConnector
                              name={getName('tripWorkingDays')}
                              label={t('expense.form.number_of_working_days')}
                              disabled={isFieldDisabled}
                            >
                              {({ field }) => (
                                <NumberField
                                  {...field}
                                  tracing={{
                                    onFocus:
                                      ExpensesEvents.WEBAPP_EXPENSE_CLICKEDTRIPTOTALDAYS,
                                  }}
                                />
                              )}
                            </FieldConnector>
                          )}

                          {(() => {
                            if (
                              itemMeta.fields.tripWorkingDays.value == null ||
                              workingDaysDeductibleAmount?.days == null
                            ) {
                              return null;
                            }
                            const totalWorkingDays =
                              workingDaysDeductibleAmount.days +
                              itemMeta.fields.tripWorkingDays.value;
                            const tProps = {
                              year: expenseYear,
                              totalDays: totalWorkingDays,
                            };
                            if (totalWorkingDays > 365) {
                              return (
                                <Alert
                                  type="warning"
                                  showIntercomBtn
                                  name="working days more than 365"
                                  description={t(
                                    'expenses.warning_bubble.working_days_more_than_365',
                                    tProps,
                                  )}
                                />
                              );
                            }
                            if (totalWorkingDays > 250) {
                              return (
                                <Alert
                                  type="warning"
                                  showIntercomBtn
                                  name="working days more than 250"
                                  description={t(
                                    'expenses.warning_bubble.working_days_more_than_250',
                                    tProps,
                                  )}
                                />
                              );
                            }
                            return null;
                          })()}

                          {!itemMeta.fields.deduction.isHidden && (
                            <FieldConnector
                              name={getName('deduction')}
                              label={t('expense.form.deduction')}
                              disabled
                              helper={
                                <Helper>
                                  {(itemMeta.helpers
                                    .shouldShowDeductionDestinationHelpers ||
                                    itemMeta.helpers
                                      .shouldShowDeductionBusinessTripHelper) && (
                                    <>
                                      <Helper.Title>
                                        {t(
                                          'expense.form.deduction.business_trip.helper_title',
                                        )}
                                      </Helper.Title>
                                      <Helper.Paragraph>
                                        {t(
                                          'expense.form.deduction.business_trip.helper_text',
                                          {
                                            carAmount: formatMoney(0.3),
                                            motoAmount: formatMoney(0.2),
                                          },
                                        )}
                                      </Helper.Paragraph>

                                      <Helper.Title>
                                        {t(
                                          'expense.form.deduction.business_trip.helper_title2',
                                        )}
                                      </Helper.Title>
                                      <Helper.Paragraph>
                                        {t(
                                          'expense.form.deduction.business_trip.helper_text2',
                                        )}
                                      </Helper.Paragraph>
                                    </>
                                  )}
                                  {(itemMeta.helpers
                                    .shouldShowDeductionDestinationHelpers ||
                                    itemMeta.helpers
                                      .shouldShowDeductionDriveToWorkHelper) && (
                                    <>
                                      <Helper.Title>
                                        {t(
                                          'expense.form.deduction.drive_to_office.helper_title',
                                        )}
                                      </Helper.Title>
                                      <Helper.Paragraph className="whitespace-pre-line">
                                        {t(
                                          'expense.form.deduction.drive_to_office.helper_text',
                                          {
                                            carAmount: formatMoney(0.3),
                                            carAmount21: formatMoney(0.38),
                                            maxDeductionAmount:
                                              formatMoney(4500),
                                          },
                                        )}
                                      </Helper.Paragraph>
                                      <Helper.Title>
                                        {t(
                                          'expense.form.deduction.drive_to_office.helper_title2',
                                        )}
                                      </Helper.Title>
                                      <Helper.Paragraph>
                                        {t(
                                          'expense.form.deduction.drive_to_office.helper_text2',
                                        )}
                                      </Helper.Paragraph>
                                    </>
                                  )}
                                </Helper>
                              }
                            >
                              {({ field }) => (
                                <PaymentAmount
                                  {...field}
                                  onChange={() => null}
                                />
                              )}
                            </FieldConnector>
                          )}

                          {(() => {
                            if (
                              itemMeta.fields.deduction.value == null ||
                              workingDaysDeductibleAmount?.amount == null ||
                              itemMeta.fields.tripType.value !==
                                TripType.driveToWork
                            ) {
                              return null;
                            }
                            const totalAmount =
                              itemMeta.fields.deduction.value +
                              workingDaysDeductibleAmount.amount;
                            if (totalAmount <= 4500) return null;
                            return (
                              <Alert
                                type="warning"
                                showIntercomBtn
                                name="deduction more than 4500"
                                description={t(
                                  'expenses.warning_bubble.deduction_more_than_4500',
                                  {
                                    year: expenseYear,
                                    limit: formatMoney(4500),
                                    totalAmount: formatMoney(totalAmount),
                                  },
                                )}
                              />
                            );
                          })()}

                          <FieldConnector
                            name={getName('vehicleId')}
                            disabled={isFieldDisabled}
                            highlightID="select-vehicle"
                            showUI={!itemMeta.fields.vehicleId.isHidden}
                            label={() => t('expense.vehicle')}
                            helper={
                              item.vehicle && formValues.expenseDate ? (
                                <Helper>
                                  <Helper.Title>
                                    {t(
                                      'expenses.helper.car_deductibility.title',
                                    )}
                                  </Helper.Title>
                                  <Helper.Paragraph>
                                    <ul className="list-disc list-inside">
                                      <li>
                                        {t('VAT')}
                                        {': '}
                                        <span className="text-primary-700">{`${
                                          item.vehicle?.VATDeductibility * 100
                                        }%`}</span>
                                      </li>
                                      <li>
                                        {t(
                                          customer?.accountType ===
                                            AccountTypeEnum.one_person_company
                                            ? 'expenses.helper.car_deductibility.corporate_tax'
                                            : 'expenses.helper.car_deductibility.Income_tax',
                                        )}
                                        {': '}
                                        {(() => {
                                          const vehicle =
                                            item.vehicle as IVehicle;
                                          if (
                                            !vehicle.orderDate ||
                                            dayjs(
                                              vehicle.orderDate + '',
                                              'YYYYMMDD',
                                            ).isBefore(
                                              beVehicleDeductibliltyDate,
                                            )
                                          ) {
                                            return (
                                              <span className="text-primary-700">{`${
                                                item.vehicle
                                                  .incomeTaxDeductibility * 100
                                              }%`}</span>
                                            );
                                          }
                                          const startYear = Number(
                                            (vehicle.orderDate + '').slice(
                                              0,
                                              4,
                                            ),
                                          );
                                          return (
                                            <ul className="list-[circle] list-inside mx-6">
                                              {Array.from({ length: 10 }).map(
                                                (_, i) => (
                                                  <li key={i}>
                                                    {startYear + i}
                                                    {': '}
                                                    <span className="text-primary-700">{`${
                                                      getVehicleIncomeTaxDeductibilityPerYear(
                                                        vehicle,
                                                        startYear + i,
                                                      ) * 100
                                                    }%`}</span>
                                                  </li>
                                                ),
                                              )}
                                            </ul>
                                          );
                                        })()}
                                      </li>
                                    </ul>
                                  </Helper.Paragraph>
                                  <Helper.Paragraph>
                                    <ReactMarkdown
                                      components={markdownComponents}
                                    >
                                      {t(
                                        'expenses.helper.car_deductibility.learn_more',
                                        {
                                          currentYear: today.year(),
                                          currentYearDeductibilityPercentage:
                                            getVehicleIncomeTaxDeductibilityPerYear(
                                              item.vehicle as IVehicle,
                                              today.year(),
                                            ) * 100,
                                        },
                                      )}
                                    </ReactMarkdown>
                                  </Helper.Paragraph>
                                </Helper>
                              ) : null
                            }
                          >
                            {({ field: { onChange, ...field } }) => {
                              const _onChange = (
                                option: VehiclesOption | null,
                              ) => {
                                onChange(option?.value);
                                setValue(getName('vehicle'), option?.vehicle);
                                updateItemDeductability(index);
                              };
                              return (
                                <Vehicle
                                  {...field}
                                  isDisabled={field.disabled}
                                  expenseId={formValues._id}
                                  onAddVehicle={(vehicle) => {
                                    onEditVehicles?.();
                                    _onChange(vehicle);
                                  }}
                                  onEditVehicle={(option) => {
                                    onEditVehicles?.();
                                    if (field.value === option.value) {
                                      _onChange(option);
                                    }
                                  }}
                                  onDeleteVehicle={(option) => {
                                    onEditVehicles?.();
                                    if (field.value === option.value) {
                                      _onChange(null);
                                    }
                                  }}
                                  onChange={(option) =>
                                    _onChange(option as VehiclesOption)
                                  }
                                />
                              );
                            }}
                          </FieldConnector>

                          {(!itemMeta.fields.amount.isHidden ||
                            !itemMeta.fields.VATAmount.isHidden) && (
                            <div
                              className={classNames(
                                'flex gap-4',
                                itemMeta.helpers.isVATPaidAtCustoms
                                  ? 'flex-col'
                                  : 'items-start',
                              )}
                            >
                              {!itemMeta.fields.amount.isHidden && (
                                <FieldConnector
                                  className="flex-1"
                                  name={getName('amount')}
                                  disabled={isFieldDisabled}
                                  label={priceLabel}
                                  highlightID={`amount.${item.amount}`}
                                >
                                  {({
                                    field: { onChange, value, ...field },
                                  }) => {
                                    const adaptedValue = (() => {
                                      if (value == null) return null;
                                      const sign = formValues.isCreditNote
                                        ? -1
                                        : 1;
                                      const signed =
                                        (item.isExclVAT
                                          ? value - item.VATAmount
                                          : value) * sign;
                                      return round(signed);
                                    })();
                                    return (
                                      <PaymentAmount
                                        tracing={{
                                          onFocus:
                                            ExpensesEvents.WEBAPP_EXPENSE_CLICKEDAMOUNT,
                                        }}
                                        {...field}
                                        placeholder={t(
                                          'expense.amount_field.placeholder',
                                        )}
                                        value={adaptedValue}
                                        onChange={(value) => {
                                          if (value == null || value === 0) {
                                            // if (item.VATAmount) {
                                            //   setValue(getName('VATAmount'), null);
                                            // }
                                            return onChange(null);
                                          }

                                          const amount = round(
                                            Math.abs(value as number),
                                          );
                                          const isCreditNote = value < 0;
                                          if (allowDynamicTypeSwitching) {
                                            setValue(
                                              'isCreditNote',
                                              isCreditNote,
                                            );
                                          }
                                          if (
                                            itemMeta.fields.VATAmount.isHidden
                                          ) {
                                            return onChange(amount);
                                          }
                                          const rate = Math.max(
                                            calculateVATRate(
                                              item.VATAmount,
                                              item.amount,
                                            ),
                                            0,
                                          );

                                          if (item.isExclVAT) {
                                            const VATAmount = round(
                                              amount * rate,
                                            );
                                            onChange(round(amount + VATAmount));
                                            return setValue(
                                              getName('VATAmount'),
                                              VATAmount,
                                            );
                                          }

                                          // if old amount is 0 or undefined or null
                                          // that means the old VATAmount was also 0 and VAT rate is 0
                                          // so here we try to get a default VAT rate,
                                          // that might be more than zero
                                          // we try to use it under some conditions
                                          const defaultVATRate =
                                            getDefaultVATRate({
                                              isSupplierLocal:
                                                formMeta.helpers
                                                  .isSupplierLocal,
                                              isCustomerBE:
                                                formMeta.helpers.isCustomerBE,
                                              expenseDate:
                                                formValues.expenseDate,
                                              categorySuggestedVATRate:
                                                item.category?.suggestedVATRate,
                                            });

                                          if (defaultVATRate == null) {
                                            return onChange(amount);
                                          }

                                          const shouldUseDefaultRate =
                                            !item.amount &&
                                            (item.VATAmount == null ||
                                              !isDirty(getName('VATAmount')));

                                          const VATAmount = calculateVATAmount(
                                            amount,
                                            shouldUseDefaultRate
                                              ? defaultVATRate
                                              : rate,
                                          );

                                          onChange(amount);
                                          setValue(
                                            getName('VATAmount'),
                                            VATAmount,
                                          );
                                        }}
                                      />
                                    );
                                  }}
                                </FieldConnector>
                              )}

                              {!itemMeta.fields.VATAmount.isHidden && (
                                <FieldConnector
                                  className="flex-1"
                                  name={getName('VATAmount')}
                                  disabled={isFieldDisabled}
                                  onShow={(name) => {
                                    const current = getValues(name);
                                    if (current != null || !item.amount) return;
                                    const VATRate = getDefaultVATRate({
                                      isSupplierLocal:
                                        formMeta.helpers.isSupplierLocal,
                                      isCustomerBE:
                                        formMeta.helpers.isCustomerBE,
                                      expenseDate:
                                        formAPI.getValues('expenseDate'),
                                      categorySuggestedVATRate:
                                        item.category?.suggestedVATRate,
                                    });

                                    if (VATRate == null) return;

                                    const value = item.isExclVAT
                                      ? round(item.amount * VATRate)
                                      : calculateVATAmount(
                                          item.amount,
                                          VATRate,
                                        );

                                    onVATChange(value);
                                  }}
                                  label={
                                    itemMeta.helpers.isVATPaidAtCustoms
                                      ? t('expenses.VAT_paid_at_customs')
                                      : t('expense.form.vat_amount')
                                  }
                                  ErrorContext={{ maxVATRate }}
                                >
                                  {({ field }) => (
                                    <VATAmount
                                      tracing={{
                                        onFocus:
                                          ExpensesEvents.WEBAPP_EXPENSE_CLICKEDVATAMOUNT,
                                      }}
                                      {...field}
                                      onChange={onVATChange}
                                      options={VATAmountOptions}
                                      expenseDate={formValues.expenseDate}
                                      amount={item.amount || 0}
                                      VATAmount={field.value || 0}
                                      isExclVAT={item.isExclVAT}
                                      isCreditNote={formValues.isCreditNote}
                                      allowSetVatAmountManually={
                                        itemMeta.helpers.isVATAmountCreatable
                                      }
                                    />
                                  )}
                                </FieldConnector>
                              )}
                            </div>
                          )}

                          {!itemMeta.fields.VATAmount.isHidden &&
                            item.VATAmount != null &&
                            !VATAmountOptions.map((o) => o.value).includes(
                              item.VATAmount,
                            ) && (
                              <Alert
                                showIntercomBtn
                                type="warning"
                                name="vat rate not standard"
                                className="whitespace-pre-line"
                                description={t(
                                  `expenses.warning_bubble.vat_rate_not_standard`,
                                )}
                              />
                            )}

                          <FieldConnector
                            showUI={!itemMeta.fields.isExclVAT.isHidden}
                            name={getName('isExclVAT')}
                            disabled={isFieldDisabled}
                            className="flex flex-col items-center gap-2"
                            label={t(
                              'expense.form.item_amount_toggle_excl_vat',
                            )}
                          >
                            {({ field: { value, disabled } }) => (
                              <Toggle
                                enabled={!!value}
                                onChange={toggleVATExcl}
                                disabled={disabled}
                              />
                            )}
                          </FieldConnector>

                          {itemMeta.helpers
                            .shouldShowCompanyMealVoucherWarning && (
                            <Alert
                              className="mb-0"
                              name="company meal voucher"
                              type="warning"
                              showIntercomBtn
                              description={t(
                                'expenses.warning_bubble.meal_voucher_wrong_amount.be',
                              )}
                            />
                          )}

                          <FieldConnector
                            name={getName('isReverseCharge')}
                            disabled={isFieldDisabled}
                            showUI={!itemMeta.fields.isReverseCharge.isHidden}
                            label={t(
                              `expenses.reverse_charge.question.${customerCountry}`,
                            )}
                            helper={
                              <Helper>
                                <Helper.Title>
                                  {t(reverseChargeHelper.title)}
                                </Helper.Title>
                                {reverseChargeHelper.description.map(
                                  (text: string) => (
                                    <Helper.Paragraph key={text}>
                                      {t(text)}
                                    </Helper.Paragraph>
                                  ),
                                )}
                              </Helper>
                            }
                          >
                            {({ field }) => (
                              <div className="flex gap-4">
                                <VariantSelection
                                  isDisabled={field.disabled}
                                  value={
                                    !!itemMeta.fields.isReverseCharge.value
                                  }
                                  onChange={(value) => field.onChange(value)}
                                  options={[
                                    { label: t('yes'), value: true },
                                    { label: t('no'), value: false },
                                  ]}
                                />
                              </div>
                            )}
                          </FieldConnector>

                          {itemMeta.helpers
                            .shouldShowGermanyReverseChargeWarning && (
                            <Alert
                              showIntercomBtn
                              type="warning"
                              title={t('attention')}
                              name="germany reverse charge"
                              className="whitespace-pre-line"
                              description={t(
                                `expenses.warning_bubble.reverse_charge_hint.de`,
                              )}
                            />
                          )}

                          {!itemMeta.fields.localVATRate.isHidden && (
                            <FieldConnector
                              name={getName('localVATRate')}
                              disabled={isFieldDisabled}
                              highlightID={`localVATRate.${item.amount}`}
                              onShow={() => {
                                if (item.localVATRate == null)
                                  setValue(
                                    getName('localVATRate'),
                                    defaultVATRate,
                                  );
                              }}
                              label={t(
                                'expenses.local_vat_rate_picker_question',
                                {
                                  userCountry: t(`country.${customerCountry}`),
                                },
                              )}
                              helper={
                                <Helper>
                                  <Helper.Title>
                                    {t('expenses.helper.local_VAT_rate.title')}
                                  </Helper.Title>
                                  <Helper.Paragraph>
                                    {t('expenses.helper.local_VAT_rate.text1', {
                                      period: t(
                                        `vat_period.${documentVATReturnFrequency}`,
                                      ),
                                    })}
                                  </Helper.Paragraph>
                                  <Helper.Paragraph>
                                    {convertMarkdown(
                                      t('expenses.helper.local_VAT_rate.text2'),
                                    )}
                                  </Helper.Paragraph>
                                  <Helper.Paragraph>
                                    {convertMarkdown(
                                      t(
                                        `expenses.helper.local_VAT_rate.text3.${customerCountry}`,
                                      ),
                                    )}
                                  </Helper.Paragraph>
                                </Helper>
                              }
                            >
                              {({ field }) => (
                                <LocalVATRate
                                  tracing={{
                                    onFocus:
                                      ExpensesEvents.WEBAPP_EXPENSE_UPDATEDVATRATE,
                                  }}
                                  {...field}
                                  expenseDate={formValues.expenseDate}
                                />
                              )}
                            </FieldConnector>
                          )}

                          {itemMeta.helpers
                            .shouldShowreverseChargeZeroRateWarning && (
                            <Alert
                              type="warning"
                              showIntercomBtn
                              name="reverse charge with zero local vat rate"
                              description={
                                <div className="flex gap-4">
                                  <span>
                                    {t(
                                      'expenses.helper.reverse_charge_zero_rate.text',
                                      {
                                        defaultVATRate:
                                          defaultVATRate * 100 + '%',
                                      },
                                    )}
                                  </span>
                                  <span className="flex-shrink-0">
                                    <Helper>
                                      <Helper.Paragraph>
                                        {t(
                                          'expenses.helper.reverse_charge_zero_rate.helper',
                                          {
                                            defaultVATRate:
                                              defaultVATRate * 100 + '%',
                                          },
                                        )}
                                      </Helper.Paragraph>
                                    </Helper>
                                  </span>
                                </div>
                              }
                            />
                          )}

                          <FieldConnector
                            showUI={!itemMeta.fields.professionalPart.isHidden}
                            name={getName('professionalPart')}
                            disabled={isFieldDisabled}
                            label={t('expense.professionalPart')}
                            helper={
                              <Helper>
                                <Helper.Title>
                                  {t('helper_professional_part_title')}
                                </Helper.Title>
                                <Helper.Paragraph>
                                  {autoMobileBE.includes(item.categoryId)
                                    ? t('helper_professional_part_text1.cars')
                                    : t('helper_professional_part_text1')}
                                </Helper.Paragraph>
                                <Helper.Title>
                                  {t('helper_professional_part_subtitle')}
                                </Helper.Title>
                                <Helper.Paragraph>
                                  {t(
                                    'helper_professional_part_text2.' +
                                      customerCountry,
                                  )}
                                </Helper.Paragraph>
                              </Helper>
                            }
                          >
                            {({
                              field: { value, onChange, error, name, disabled },
                            }) => {
                              return (
                                <NumberField
                                  immedialtyCommitOnChange
                                  highlightID={name}
                                  disabled={disabled}
                                  error={error}
                                  tracing={{
                                    onFocus:
                                      ExpensesEvents.WEBAPP_EXPENSE_CLICKEDPROFESSIONNALUSE,
                                  }}
                                  extra={
                                    value != null && (
                                      <span className="font-semibold text-primary-400">
                                        {formatMoney(
                                          (item.amount || 0) * value,
                                        )}
                                      </span>
                                    )
                                  }
                                  format={{ prefix: '%' }}
                                  value={
                                    value == null ? null : round(value * 100)
                                  }
                                  onChange={(value) => {
                                    onChange(
                                      value == null
                                        ? 0
                                        : +(value / 100).toFixed(4),
                                    );
                                  }}
                                />
                              );
                            }}
                          </FieldConnector>

                          {itemMeta.helpers
                            .shouldShowProfPartRentSecWorkplaceWarning && (
                            <Alert
                              type="info"
                              description={t(
                                'expenses.info_bubble.professional_part_rent_secondary',
                              )}
                              renderButtons={(className) => (
                                <a
                                  className={classNames(
                                    'text-sm font-semibold underline',
                                    className,
                                  )}
                                  href={t(
                                    'expenses.info_bubble.professional_part_rent_secondary.link',
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {t('learn_more')}
                                </a>
                              )}
                            />
                          )}

                          {itemMeta.helpers
                            .shouldShowCompanyProfessionalPartWarning && (
                            <Alert
                              type="info"
                              showIntercomBtn
                              dismissable
                              dismissId={
                                AlertIdsEnum.companyProfessionalPartWarning
                              }
                              description={t(
                                'expenses.info_bubble.company_professional_part',
                              )}
                            />
                          )}

                          {itemMeta.helpers
                            .shouldShowProfessionalDeductibilityWarning && (
                            <Alert
                              type="warning"
                              showIntercomBtn
                              name="deductibility same as professional part"
                              description={t(
                                'expenses.helper.deductibility_equals_professional_part.text',
                                {
                                  incomeTaxDeductibility: Math.round(
                                    item.incomeTaxDeductibility * 100,
                                  ),
                                  professionalPart: Math.round(
                                    item.professionalPart * 100,
                                  ),
                                  incomeTaxDeductibility_by_professionalPart:
                                    Math.round(
                                      item.incomeTaxDeductibility *
                                        item.professionalPart *
                                        100,
                                    ),
                                },
                              )}
                            />
                          )}

                          {itemMeta.helpers
                            .shouldShowProfessionalPartDifferenceWarning && (
                            <Alert
                              type="info"
                              showIntercomBtn
                              name="professional part is not the recommended by the category"
                              description={t(
                                'expenses.helper.professional_part_not_equal_to_recommened.text',
                                {
                                  recommendedPercentage: Math.round(
                                    item.category?.recommendedProfessionalPart *
                                      100,
                                  ),
                                  categoryDisplayName:
                                    categories.dict[item.categoryId]
                                      ?.displayName,
                                  enteredPercentage: Math.round(
                                    item.professionalPart * 100,
                                  ),
                                },
                              )}
                            />
                          )}
                          {itemMeta.helpers
                            .shouldShowWorkFromHomeProfessionalPartWarning && (
                            <Alert
                              type="info"
                              renderButtons={(className) => (
                                <a
                                  className={classNames(
                                    'text-sm font-semibold underline',
                                    className,
                                  )}
                                  href={t(
                                    'expenses.warning_bubble.professional_part_work_from_home_categories.learn_more.' +
                                      customerCountry,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {t('learn_more')}
                                </a>
                              )}
                              name="professional part warning for work from home categories"
                              description={t(
                                'expenses.warning_bubble.professional_part_work_from_home_categories.' +
                                  customerCountry,
                              )}
                            />
                          )}

                          {itemMeta.helpers
                            .shouldShowBankChargesProfessionalPartWarning && (
                            <Alert
                              type="info"
                              showIntercomBtn
                              name="category bank charges and professional part is not 100"
                              description={t(
                                'expenses.helper.professional_part_bank_charges_not_100',
                              )}
                            />
                          )}

                          <FieldConnector
                            showUI={isExpert}
                            name={getName('incomeTaxDeductibility')}
                            disabled={isFieldDisabled}
                            label={t(
                              `expense.item.${
                                formMeta.helpers.isCustomerOnePersonCompany
                                  ? 'corporate'
                                  : 'income'
                              }_tax_deductibility`,
                            )}
                          >
                            {({
                              field: { value, onChange, name, disabled },
                            }) => (
                              <NumberField
                                highlightID={name}
                                disabled={disabled}
                                format={{ prefix: '%' }}
                                value={
                                  value == null ? null : Math.round(value * 100)
                                }
                                onChange={(value) =>
                                  onChange(value == null ? 0 : value / 100)
                                }
                              />
                            )}
                          </FieldConnector>

                          <FieldConnector
                            showUI={!itemMeta.fields.maxDeductibleVAT.isHidden}
                            name={getName('maxDeductibleVAT')}
                            disabled={isFieldDisabled}
                            label={t('expense.item.max_deductible_vat')}
                          >
                            {({
                              field: { value, onChange, name, disabled },
                            }) => (
                              <NumberField
                                highlightID={name}
                                disabled={disabled}
                                format={{ prefix: '%' }}
                                value={
                                  value == null ? null : Math.round(value * 100)
                                }
                                onChange={(value) =>
                                  onChange(value == null ? 0 : value / 100)
                                }
                              />
                            )}
                          </FieldConnector>

                          {!itemMeta.fields.amortizationPeriod.isHidden && (
                            <>
                              <FieldConnector
                                name={getName('amortizationPeriod')}
                                disabled={isFieldDisabled}
                                highlightID={`amortizationPeriod.${item?.amount}`}
                                onShow={() => {
                                  const duration =
                                    getRecommendedAmortizationDurationInYears(
                                      item.category,
                                    );
                                  if (
                                    duration &&
                                    item.amortizationPeriod == null
                                  ) {
                                    setValue(
                                      getName('amortizationPeriod'),
                                      duration * 12,
                                    );
                                  }
                                }}
                                label={t(
                                  'expense.field.amortization.amortization_period',
                                )}
                                helper={
                                  <Helper>
                                    <Helper.Title>
                                      <ReactMarkdown
                                        components={markdownComponents}
                                      >
                                        {t(
                                          'expense.amortization_period.helper.title1',
                                        )}
                                      </ReactMarkdown>
                                    </Helper.Title>
                                    <Helper.Paragraph>
                                      <ReactMarkdown
                                        components={markdownComponents}
                                      >
                                        {t(
                                          'expense.amortization_period.helper.text1',
                                        )}
                                      </ReactMarkdown>
                                    </Helper.Paragraph>
                                    <Helper.Paragraph>
                                      <ReactMarkdown
                                        components={markdownComponents}
                                      >
                                        {t(
                                          `expense.amortization_period.helper.text2.${customerCountry}`,
                                        )}
                                      </ReactMarkdown>
                                    </Helper.Paragraph>
                                    <Helper.Title>
                                      <ReactMarkdown
                                        components={markdownComponents}
                                      >
                                        {t(
                                          'expense.amortization_period.helper.title2',
                                        )}
                                      </ReactMarkdown>
                                    </Helper.Title>
                                    <Helper.Paragraph>
                                      <ReactMarkdown
                                        components={markdownComponents}
                                      >
                                        {t(
                                          'expense.amortization_period.helper.text3',
                                        )}
                                      </ReactMarkdown>
                                    </Helper.Paragraph>
                                  </Helper>
                                }
                              >
                                {({ field }) => (
                                  <AmortizationPeriod
                                    {...field}
                                    category={item.category}
                                  />
                                )}
                              </FieldConnector>
                              {itemMeta.helpers
                                .shouldShowAmortizationStartDateWarning && (
                                <Alert
                                  type="warning"
                                  className="mb-0"
                                  description={
                                    <div className="flex gap-4">
                                      <span>
                                        {t(
                                          'expenses.warning_bubble.purchase_before_start_date.title',
                                          { startDate: customer?.startDate },
                                        )}
                                      </span>
                                      <Helper>
                                        <Helper.Paragraph>
                                          {t(
                                            'expenses.warning_bubble.purchase_before_start_date.text',
                                          )}
                                        </Helper.Paragraph>
                                        <Helper.Paragraph>
                                          {t(
                                            'expenses.warning_bubble.purchase_before_start_date.text2',
                                          )}
                                        </Helper.Paragraph>
                                      </Helper>
                                    </div>
                                  }
                                />
                              )}
                            </>
                          )}

                          <FieldConnector
                            showUI={!itemMeta.fields.name.isHidden}
                            name={getName('name')}
                            disabled={isFieldDisabled}
                            label={t('expense.field.name.label')}
                          >
                            {({ field: { onChange, ...field } }) => (
                              <FreeTextInput
                                tracing={{
                                  onFocus:
                                    ExpensesEvents.WEBAPP_EXPENSE_CLICKEDAMORTISATIONITEMNAME,
                                }}
                                {...field}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                }}
                              />
                            )}
                          </FieldConnector>
                        </div>
                      );
                    }}
                  />
                )}
              </FieldConnector>
            </div>
            {formMeta.helpers.shouldShowNewItemBtn && !isLocked && (
              <div>
                <Button
                  structure="text"
                  className="mt-7"
                  size="xl"
                  onClick={() =>
                    itemsController.append({
                      ...itemDefault,
                      VATAmount:
                        formMeta.fields.isThereVATOnInvoice.value != null
                          ? formValues.items[0].VATAmount
                          : undefined,
                      whyZeroVAT:
                        formMeta.fields.isThereVATOnInvoice.value != null
                          ? formValues.items[0].whyZeroVAT
                          : undefined,
                    })
                  }
                >
                  <PlusIcon className="w-5 mr-1" />
                  {formValues.items.length > 1
                    ? t('invoice.field.item.add_item')
                    : t('expense.split_expense_new')}
                </Button>
              </div>
            )}

            {formMeta.helpers.shouldShowCalcRows && (
              <div className="mt-7">
                <div className="bg-[#F9F7FC] flex items-center justify-between rounded-lg p-2">
                  <p className="text-primary-400">
                    {t('expense.price_total_excluding_VAT')}
                  </p>
                  <span className="font-semibold">
                    <Amount amount={AllItemsAmountExclVAT} />
                  </span>
                </div>

                <div className="bg-[#F9F7FC] flex items-center justify-between rounded-lg p-2 my-1">
                  <p className="text-primary-400">
                    {t('expense.price_total_VAT')}
                  </p>
                  <span className="font-semibold">
                    <Amount amount={AllItemsVATAMounts} />
                  </span>
                </div>

                <div className="bg-primary-400 flex items-center justify-between rounded-lg p-2">
                  <p className="text-white">
                    {t('expense.price_total_including_VAT')}
                  </p>
                  <span className="font-semibold">
                    <Amount
                      className="!text-white"
                      amount={AllItemsAmountInclVAT}
                    />
                  </span>
                </div>
              </div>
            )}

            <div className="flex flex-col items-end mt-10">
              <TaxImpact
                isValidated={
                  !!((expense?.isValidated as boolean) && expense?._id)
                }
                documentType={TaxImpactDocumentType.EXPENSE}
              />
            </div>

            <div className="border-t border-primary-100 mt-7 pt-7 flex flex-col gap-4">
              <p className="font-bold text-main-900 text-xl leading-none tracking-tight font-avenir">
                {t('expense.additional_information')}
              </p>
              {!formMeta.fields.transactions.isHidden && (
                <FieldConnector
                  name="transactions"
                  disabled={isFieldDisabled}
                  label={`
                  ${
                    hasTransactions
                      ? t('expense.link_payment.with_transactions')
                      : t('expense.link_payment.without_transactions')
                  } (${t('field.optional.tag')})`}
                >
                  {({ field: { onChange, ...field } }) => {
                    const fieldOnChange = (selected: any) => {
                      const options = selected as any[];
                      const latestOption = last(options);
                      if (latestOption?.preset) {
                        presetsOptions.forEach((option) => {
                          if (option.value === latestOption.value)
                            return setValue(option.value, true, {
                              shouldDirty: true,
                            });
                          setValue(option.value, false, { shouldDirty: true });
                        });
                        return onChange([]);
                      }
                      presetsOptions.forEach((option) =>
                        setValue(option.value, false, { shouldDirty: true }),
                      );
                      onChange(
                        isEmpty(options)
                          ? []
                          : options.map(({ value }) => value),
                      );
                    };

                    const suggestedTransactionParams = {
                      amount:
                        AllItemsAmountInclVAT *
                        (formValues.isCreditNote ? 1 : -1),
                      date: formValues.expenseDate as string,
                      communication: expense.guessedCommunication,
                      name: formValues.supplier?.name,
                      documentNumber: expense.guessedExpenseNumber,
                      IBAN: expense.guessedIBAN,
                    };

                    return (
                      <PaymentLinkSelect
                        tracing={{
                          onMenuOpen:
                            ExpensesEvents.WEBAPP_EXPENSE_CLICKEDLINKPAYMENT,
                          onChange:
                            ExpensesEvents.WEBAPP_EXPENSE_UPDATEDLINKPAYMENT,
                        }}
                        optionsDict={getOptionsDict(
                          expense?._matchedTransactions?.map(
                            transactionToOption,
                          ),
                        )}
                        onSuggestionActiveStatusChange={(isActive: boolean) => {
                          if (isActive) {
                            return formAPI.setValue(
                              'transactionsSuggestedIsActive',
                              true,
                            );
                          }
                          formAPI.setValue(
                            'transactionsSuggestedIsActive',
                            false,
                          );
                          formAPI.clearErrors('transactionsSuggestedIsActive');
                        }}
                        suggestedTransactionHint={suggestedTransactionError}
                        suggestedTransactionHintClassName="text-red-500 field-with-error"
                        inputValue={
                          hasTransactions
                            ? supplierNameToSearchValue(
                                formValues.supplier?.name,
                              )
                            : undefined
                        }
                        {...field}
                        cacheKey={expense._id || 'expense_payments_list'}
                        isDisabled={field.disabled}
                        onChange={fieldOnChange}
                        fixedOptions={presetsOptions}
                        suggestedTransactionOptions={{
                          queryFn: () =>
                            getSuggestedTransaction(suggestedTransactionParams),
                          queryKey: [
                            ExpensesCacheKeys.ExpensesSuggestedTransaction,
                            suggestedTransactionParams,
                          ],
                          enabled: !!formValues.expenseDate,
                        }}
                        renderLabelContent={(selected) => {
                          if (!hasTransactions) return null;
                          const selectedPreset = presetsOptions.find(
                            (option) => (formValues as any)[option.value],
                          );
                          if (!selected && !selectedPreset) return null;

                          return (
                            <div className="mt-3">
                              {selectedPreset?.value === 'matchCard' ||
                              selectedPreset?.value === 'cashRemainder' ? (
                                <LinkedItemsProgress
                                  type="expenses"
                                  total={100}
                                  matchedAmount={100}
                                />
                              ) : (
                                <LinkedItemsProgress
                                  type="expenses"
                                  total={
                                    AllItemsAmountInclVAT +
                                    getExpenseLinkedPaymentsOppositeDirectionTotal(
                                      selected,
                                      formValues.isCreditNote,
                                    )
                                  }
                                  matchedAmount={getExpenseLinkedPaymentsAmount(
                                    selected,
                                    formValues.isCreditNote,
                                  )}
                                />
                              )}
                            </div>
                          );
                        }}
                        renderSelected={(selected) => {
                          const selectedPreset = presetsOptions.find(
                            (option) => (formValues as any)[option.value],
                          );
                          if (!selected && !selectedPreset) return null;

                          return (
                            <div className="flex flex-col gap-2 mt-2">
                              {selectedPreset && (
                                <SelectedOptionContainer
                                  isDisabled={field.disabled}
                                  onDelete={() => {
                                    setValue(selectedPreset.value, false, {
                                      shouldDirty: true,
                                    });
                                  }}
                                  selectedOptionUI={
                                    <TransactionOptionPreset
                                      className="flex-1"
                                      label={selectedPreset.label}
                                    />
                                  }
                                />
                              )}

                              {selected?.map((option: TransactionAsOption) => {
                                return (
                                  <SelectedOptionContainer
                                    key={option.value}
                                    isDisabled={field.disabled}
                                    selectedOptionUI={
                                      <TransactionOption
                                        className="flex-1"
                                        transaction={option.transaction}
                                      />
                                    }
                                    onDelete={() =>
                                      fieldOnChange(
                                        selected.filter(
                                          (selectedOption: any) =>
                                            selectedOption.value !==
                                            option.value,
                                        ),
                                      )
                                    }
                                  />
                                );
                              })}
                            </div>
                          );
                        }}
                        render={(selected: TransactionAsOption[] | null) => {
                          if (!canPayWithQRCode) return null;
                          if (Array.isArray(selected)) {
                            const sum = selected
                              .filter((option) => {
                                if (option?.transaction?.currency !== 'EUR')
                                  return false;
                                if (formValues?.isCreditNote)
                                  return option?.transaction?.amount > 0;
                                return option?.transaction?.amount < 0;
                              })
                              .reduce(
                                (acc, option) =>
                                  acc + Math.abs(option?.transaction?.amount),
                                0,
                              );
                            const isFullyMatchingTheAmount =
                              sum >= AllItemsAmountInclVAT;
                            if (isFullyMatchingTheAmount) return null;
                          }
                          return (
                            <div className="mt-2">
                              <PayWithQRCode
                                expenseId={expense._id}
                                data={{
                                  supplier: formValues.supplier?.name || '',
                                  iban: invoiceIBAN || '',
                                  amount: AllItemsAmountInclVAT,
                                  communication:
                                    expense.guessedCommunication ||
                                    expense.OCRExpenseNumber?.toString() ||
                                    '',
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    );
                  }}
                </FieldConnector>
              )}

              <FieldConnector
                name={'notes'}
                label={`${t('expense.notes')} (${t('field.optional.tag')})`}
                disabled={isFieldDisabled}
              >
                {({ field: { onChange, ...field } }) => (
                  <FreeTextInput
                    tracing={{
                      onFocus: ExpensesEvents.WEBAPP_EXPENSE_CLICKEDNOTES,
                    }}
                    type="textarea"
                    rows={1}
                    placeholder={t('expense.notes_field.placeholder')}
                    {...field}
                    onChange={({ target: { value } }) => {
                      onChange(value || null);
                    }}
                  />
                )}
              </FieldConnector>

              {!formMeta.fields.period.isHidden && (
                <FieldConnector
                  name="period"
                  disabled={isFieldDisabled}
                  onShow={() => {
                    if (formAPI.getValues().period != null) return;
                    updatePeriodBasedOnDate(formAPI.getValues().expenseDate);
                  }}
                  errorKeys={[
                    'period',
                    'period.year',
                    'period.month',
                    'period.quarter',
                  ]}
                  label={t('fields.vat_declaration.label')}
                  helper={<PeriodHelper />}
                  loading={lockBytaxesStatusAPI.isLoading}
                >
                  {({ field }) => (
                    <Select
                      tracing={{
                        onMenuOpen: ExpensesEvents.WEBAPP_EXPENSE_CLICKEDPERIOD,
                        onChange: ExpensesEvents.WEBAPP_EXPENSE_UPDATEDPERIOD,
                      }}
                      menuPlacement="top"
                      options={periodOptions}
                      placeholder={t('expense.period_field.placeholder')}
                      {...field}
                      isDisabled={field.disabled}
                      value={
                        field.value
                          ? periodToValue(
                              field.value,
                              documentVATReturnFrequency,
                            )
                          : null
                      }
                      onChange={(option: any) => field.onChange(option.period)}
                    />
                  )}
                </FieldConnector>
              )}

              {!!lockBytaxesStatusAPI.data?.period && (
                <ExpenseSubmitLockedByTaxes
                  isCreditNote={!!formValues.isCreditNote}
                  fieldSlug={'period'}
                  taxLockReasons={lockBytaxesStatusAPI.data.period}
                />
              )}

              <UseState
                init={issueField === 'user.VATType'}
                render={(isOpen, setIsOpen) => {
                  return (
                    <div className="mt-4 mb-2">
                      <Button
                        structure="text"
                        size="xl"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        {isOpen ? (
                          <MinusIcon className="w-5 mr-1" />
                        ) : (
                          <PlusIcon className="w-5 mr-1" />
                        )}
                        {t('invoice.item_form.advanced_settings')}
                      </Button>
                      <FieldConnector
                        className="mt-2"
                        name={'user.VATType'}
                        disabled={isFieldDisabled}
                        showUI={isOpen}
                        label={t('customer.field.vattype.doctype', {
                          docType: t('expense'),
                        })}
                      >
                        {({ field: { onChange, ...rest } }) => (
                          <Select
                            tracing={{
                              onMenuOpen:
                                ExpensesEvents.WEBAPP_EXPENSE_CLICKEDVATTYPE,
                              onChange:
                                ExpensesEvents.WEBAPP_EXPENSE_UPDATEDVATTYPE,
                            }}
                            menuPlacement="top"
                            options={[
                              {
                                label: t('settings.generalTaxes.subjectToVAT'),
                                value: VATTypeEnum.subjectToVAT,
                              },
                              {
                                label: t(
                                  `settings.generalTaxes.franchisee.${customerCountry}`,
                                ),
                                value: VATTypeEnum.franchisee,
                              },
                              {
                                label: t('settings.generalTaxes.exempt'),
                                value: VATTypeEnum.exempt,
                              },
                            ]}
                            isDisabled={rest.disabled}
                            isSearchable={false}
                            onChange={(option: any) => {
                              onChange(option.value);
                            }}
                            {...rest}
                          />
                        )}
                      </FieldConnector>
                    </div>
                  );
                }}
              />
            </div>

            {isExpert && (
              <div className="border-t border-primary-100 mt-5 pt-7 flex flex-col gap-4">
                <p className="font-bold text-main-900 text-xl leading-none tracking-tight font-avenir">
                  {t('expense.accountant_section')}
                </p>
                <FieldConnector
                  name={'accountantReview.comments'}
                  disabled={isFieldDisabled}
                  label={t('accountantReview.comments')}
                >
                  {({ field: { onChange, ...field } }) => (
                    <FreeTextInput
                      type="textarea"
                      {...field}
                      onChange={({ target: { value } }) => {
                        onChange(value || null);
                      }}
                    />
                  )}
                </FieldConnector>

                <ExpenseVATCells expense={formValues} enabled={!hasErrors} />
              </div>
            )}

            {footerMeta}

            {!disabled &&
              (!isLocked || (isLocked && hasDirtyFields)) &&
              (() => {
                const runValidationAndSubmit: MouseEventHandler<
                  HTMLButtonElement
                > = (e) => {
                  if (lockBytaxesStatusAPI.data) {
                    const lockUI = document.querySelector('.TAX_LOCK_REASON');
                    if (lockUI) {
                      lockUI.scrollIntoView({ behavior: 'smooth' });
                      return;
                    }
                  }
                  // temporary
                  if (formMeta.helpers.isDeductible && !formValues.taxPeriod) {
                    const firstDeductibleItemIndex = formValues.items.findIndex(
                      (item) =>
                        deductibleTaxCategories.includes(item.categoryId),
                    );

                    return pickerController.api.open({
                      value:
                        formValues.items[firstDeductibleItemIndex].categoryId,
                      onChange: (category, taxPeriod) => {
                        itemsCategoryPickerOnChange(
                          firstDeductibleItemIndex,
                          category,
                          taxPeriod,
                        );
                      },
                    });
                  }

                  formAPI.trigger().then((valid) => {
                    if (formValues.transactionsSuggestedIsActive) {
                      flushSync(() => {
                        formAPI.setError('transactionsSuggestedIsActive', {
                          type: 'custom',
                          message: t('expense.form.error.suggestedTransaction'),
                        });
                      });
                    }
                    if (valid && !formValues.transactionsSuggestedIsActive) {
                      return formAPI.handleSubmit(onSubmit)(e);
                    }
                    const fieldWithError =
                      document.querySelector('.field-with-error');
                    if (fieldWithError) {
                      fieldWithError.scrollIntoView({ behavior: 'smooth' });
                    } else {
                      // we have erros for hidden fields
                      // it means some important data is missing
                      Sentry.captureException(
                        {
                          message: createSentryCustomErrorMsg(
                            'expense_errors_on_hidden_fields',
                          ),
                        },
                        {
                          extra: {
                            formErrors: JSON.stringify(
                              formAPI.formState.errors,
                            ),
                            formValues: JSON.stringify(formValues),
                          },
                        },
                      );
                    }
                  });
                };

                return (
                  <div className="sticky bottom-20 translate-y-[20px] w-64 mx-auto flex flex-col gap-2 justify-center items-center">
                    {isWeb && (
                      <Button
                        loading={isSubmitting}
                        size="xl"
                        tracingEvent={ExpensesEvents.WEBAPP_EXPENSE_CLICKEDSAVE}
                        onClick={runValidationAndSubmit}
                        highlightID="submit-expense"
                      >
                        {t('save')}
                      </Button>
                    )}
                    {isExpert && (
                      <>
                        <Button
                          loading={isSubmitting && isReviewed}
                          disabled={isSubmitting}
                          size="xl"
                          className="w-full"
                          tracingEvent={
                            ExpensesEvents.WEBAPP_EXPENSE_CLICKEDSAVE
                          }
                          onClick={(e) => {
                            formAPI.setValue(
                              'accountantReview.reviewStatus',
                              ReviewStatusEnum.reviewed,
                            );
                            runValidationAndSubmit(e);
                          }}
                        >
                          {t('save_and_validate')}
                        </Button>
                        <Button
                          structure="secondary"
                          loading={isSubmitting && !isReviewed}
                          disabled={isSubmitting}
                          size="xl"
                          className="w-full"
                          tracingEvent={
                            ExpensesEvents.WEBAPP_EXPENSE_CLICKEDSAVE
                          }
                          onClick={(e) => {
                            formAPI.setValue(
                              'accountantReview.reviewStatus',
                              ReviewStatusEnum.notReviewed,
                            );
                            runValidationAndSubmit(e);
                          }}
                        >
                          {t('save_and_dont_validate')}
                        </Button>
                      </>
                    )}
                  </div>
                );
              })()}
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default Form;
