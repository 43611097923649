import classNames from 'classnames';
import ContainerWithBorder from 'components/atoms/ContainerWithBorder';
import { motion } from 'framer-motion';
import type { FC, PropsWithChildren, ReactNode } from 'react';

type WidgetProps = {
  containerClassName?: string;
  innerWrapperClassName?: string;
  contentWrapperClassName?: string;
  title: string;
  Icon: any;
  cornerArtSrc?: string;
  initial?: Record<string, any>;
  animate?: Record<string, any>;
  transition?: Record<string, any>;
  widgetControls?: ReactNode;
  widgetControlsClasses?: string;
  color?: 'revenues' | 'taxes' | 'expenses' | 'bank' | 'export' | 'primary';
  subtitle?: string;
};

enum IconColorsEnum {
  revenues = 'bg-revenues',
  clients = 'bg-revenues',
  taxes = 'bg-taxes',
  expenses = 'bg-expenses',
  bank = 'bg-bank',
  export = 'bg-black',
  primary = 'bg-primary-800',
}

const TitledWidget: FC<PropsWithChildren<WidgetProps>> = ({
  containerClassName,
  title,
  Icon,
  cornerArtSrc,
  children,
  animate,
  initial,
  transition,
  widgetControls,
  widgetControlsClasses = '',
  color,
  subtitle,
  innerWrapperClassName,
  contentWrapperClassName,
}) => (
  <motion.div
    animate={{ ...(animate || null) }}
    initial={{ ...(initial || null) }}
    transition={{ ...(transition || null) }}
    className={classNames('py-3 pr-6 2xl:py-4 2xl:pr-8', containerClassName)}
  >
    <ContainerWithBorder
      innerWrapperClassName={classNames(
        'group/widget h-[50vh] py-6 relative overflow-hidden',
        innerWrapperClassName,
      )}
    >
      <div className="flex items-center justify-between group px-6">
        <div className="flex items-center space-x-2">
          <div
            className={classNames(
              'bg-taxes w-10 h-10 rounded-lg p-1 border-[3px] border-taxes-100 flex items-center justify-center',
              IconColorsEnum[color || 'taxes'],
            )}
          >
            <Icon className="text-white" />
          </div>

          <div className="flex items-center">
            <h1 className="font-bold font-gotham text-xl text-primary-700 mr-3">
              {title}
            </h1>

            {subtitle ? (
              <p className="text-xl text-primary-700">{subtitle}</p>
            ) : null}
          </div>
        </div>

        <div
          className={classNames(
            'group-hover/widget:opacity-100 opacity-0 transition-opacity relative z-20',
            widgetControlsClasses,
          )}
        >
          {widgetControls}
        </div>
      </div>

      <motion.div
        layoutScroll
        className={classNames(
          'h-[calc(100%-1.5rem)] overflow-scroll relative scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent px-6',
          contentWrapperClassName,
        )}
      >
        {children}
      </motion.div>

      {cornerArtSrc ? (
        <img src={cornerArtSrc} className="absolute top-0 right-0 z-10" />
      ) : null}
    </ContainerWithBorder>
  </motion.div>
);

export default TitledWidget;
