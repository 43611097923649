import type SVGType from 'types/svg.types';

const IPhone: SVGType = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="currentColor"
      d="M14.8239 9.36642H17.1761M10.9345 25.6439H21.0655C22.1048 25.6439 22.9473 24.8014 22.9473 23.7621V8.23726C22.9473 7.19798 22.1048 6.35547 21.0655 6.35547H10.9345C9.89524 6.35547 9.05273 7.19798 9.05273 8.23726V23.7621C9.05273 24.8014 9.89524 25.6439 10.9345 25.6439Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IPhone;
