import api from 'api/axiosInstance';
import type { ProfitProjectionEnum } from 'types/taxes.types';
import { setWebAmplitudeUser } from './authentication';

const service = '/v2/users';

type IAPIGetTaxOfficeResponse = {
  taxOffice: string;
  IBAN: string;
  email: string;
  url: string;
  tel: string;
  fax: string;
  address: {
    zip: string;
    city: string;
    street: string;
  };
};

export const deleteUserRequest = (): Promise<void> =>
  api.post(`${service}/delete-user-request`);

export const deleteUser = (token: string): Promise<string> =>
  api
    .post(`${service}/delete-user`, { delete_token: token })
    .then(({ userHash }: any) => userHash);

export const getFileUrl = (filepath: string): Promise<{ url: string }> =>
  api.get(`${service}/file-url?filePath=${filepath}`);

export const signinAuthContinuation = (
  authContinuationToken: string,
): Promise<any> => {
  return api
    .post('/v2/users/auth-continuation', {
      auth_continuation_token: authContinuationToken,
      clientId: localStorage.getItem('clientId') || undefined,
    })
    .then((data: any) => {
      setWebAmplitudeUser(data?.data?.email);
      return data;
    });
};

export type UpdateProfitProjection = {
  option: ProfitProjectionEnum;
  manualProfitBeforTax?: number;
  companyTaxRate?: number;
};

export const updateProfitProjection = (
  body: UpdateProfitProjection,
): Promise<any> => {
  return api.put(`${service}/settings/profit`, body);
};

export type UpdateTaxesGradualPayment = {
  prepaymentSetup: string;
};

export const updateTaxesGradualPayment = (
  body: UpdateTaxesGradualPayment,
): Promise<any> => {
  return api.patch(`${service}/settings/taxes`, body);
};
