import type { IUser } from 'types/users.types';
import useGetLoggedInUserData from './useGetLoggedInUserData';

type AuthStatusType = {
  isAuthenticated: boolean;
  isLoadingAuthData: boolean;
  user: IUser | undefined;
};

// useAuthStatus is a wrapper hook that could be used to check the current authentication status
// whether the user is logged-in or not
const useAuthStatus = (): AuthStatusType => {
  const { isLoading, data } = useGetLoggedInUserData();

  return {
    isAuthenticated: !!data,
    isLoadingAuthData: isLoading,
    user: data,
  };
};

export default useAuthStatus;
