import { logout as logoutExpertUser } from 'api/v2/experts/authentication';
import { logout as logoutWebUser } from 'api/v2/users/authentication';
import { product } from 'config';
import { getIntercom } from 'hooks/intercom.hook';
import { overrideAmplDefaultProps, setAmplitudeUser } from 'utils/amplitude';

const removeIntercomData = () => {
  getIntercom().shutdown();
};

// Returns any because it always reloads the page, so in effect, it never returns
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logout = (skipCallback = false): any =>
  new Promise(() => {
    const clientId = localStorage.getItem('clientId') || undefined;
    const logoutAPI = product === 'web' ? logoutWebUser : logoutExpertUser;

    (skipCallback ? Promise.resolve() : logoutAPI(clientId)).finally(() => {
      setAmplitudeUser(null!);
      overrideAmplDefaultProps({}, true);
      // Invalidate and remove the user data from the query state on logout
      localStorage.removeItem('auth');
      removeIntercomData();
      window.location.href = '/';
      // appQueryClient.removeQueries(); // test
      // zustandCleanup.cleanup(); // test
    });
  });

export default logout;
