// from old code
const mod97 = (str: string) =>
  Array.from(str)
    .map((c) => parseInt(c))
    .reduce((remainder, value) => (remainder * 10 + value) % 97, 0)
    .toString();
// from old code
const validateStructuredCommunication = (str: string) =>
  mod97(str.replace(/\D/g, '').substring(0, 10)) ===
  str.replace(/\D/g, '').substring(10, 12);
// from old code
type IQRCodePayload = {
  name: string;
  bic?: string;
  iban: string;
  amount: number;
  reference?: string;
  information?: string;
};
// from old code
export const generateQRCodePayload = ({
  name,
  bic,
  iban,
  amount,
  reference,
  information,
}: IQRCodePayload): string =>
  [
    'BCD', // Service tag
    '002', // Version
    1, // Char set
    'SCT', // Identification code
    bic,
    name,
    iban,
    'EUR' + (amount || 0).toFixed(2),
    '', // purpose code
    reference && validateStructuredCommunication(reference) ? reference : '',
    reference && !validateStructuredCommunication(reference) ? reference : '',
    information || '',
  ].join('\n');

export const banksAvailable = [
  { value: 'Belfius', label: 'Belfius' },
  { value: 'ING', label: 'ING' },
  { value: 'KBC', label: 'KBC' },
];

export const bankSteps = {
  Belfius: [
    'expense.field.link_payment.pay_with_qr_code.steps.belfius.step1',
    'expense.field.link_payment.pay_with_qr_code.steps.belfius.step2',
    'expense.field.link_payment.pay_with_qr_code.steps.scan_the_qr_code',
  ],
  ING: [
    'expense.field.link_payment.pay_with_qr_code.steps.ing.step1',
    'expense.field.link_payment.pay_with_qr_code.steps.ing.step2',
    'expense.field.link_payment.pay_with_qr_code.steps.scan_the_qr_code',
  ],
  KBC: [
    'expense.field.link_payment.pay_with_qr_code.steps.kbc.step1',
    'expense.field.link_payment.pay_with_qr_code.steps.kbc.step2',
    'expense.field.link_payment.pay_with_qr_code.steps.kbc.step3',
    'expense.field.link_payment.pay_with_qr_code.steps.scan_the_qr_code',
  ],
};
