export const multiplyBy1000 = (amount: number) =>
  isNaN(amount) ? 0 : Math.round(amount * 1000 * 100) / 100;

export const divideBy1000 = (amount: number) =>
  isNaN(amount / 1000) ? 0 : Math.round((amount * 100) / 1000) / 100;

export const divideBy100 = (amount: number) =>
  isNaN(amount / 100) ? 0 : Math.round((amount * 100) / 100) / 100;

export const evaluateExchangeRateFromAmounts = (total = 0, euroAmount = 0) =>
  (total / euroAmount).toFixed(3);

export const round = (amount: number, multiplier?: number): number => {
  const _multiplier = multiplier || 100;

  return isNaN(amount) ? 0 : Math.round(amount * _multiplier) / _multiplier;
};

export const isNumeric = (n: any) => !isNaN(parseFloat(n)) && isFinite(n);

export const isMultipleOf2 = (n: number) => n % 2 === 0;
