import imageSrc from 'assets/images/period-assignment@2x.png';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import getIntercom from 'hooks/intercom.hook';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { renderLoader } from './utils';

type Option = {
  title: string;
  description: string;
  value: any;
  isLoading?: boolean;
};

type WarningModalContentProps = {
  options?: Option[];
  render?: () => ReactNode;
  title?: string;
  question?: string;
  description: string;
  onChoose?: (value: any) => Promise<any> | void;
  hideBGImage?: boolean;
  hideHelpBtn?: boolean;
  questionClassName?: string;
};

const WarningModalContent: FC<WarningModalContentProps> = ({
  title,
  description,
  question,
  options,
  render,
  onChoose,
  hideBGImage,
  hideHelpBtn,
  questionClassName,
}) => {
  const { t } = useTranslation();
  const { showIntercom } = getIntercom();

  return (
    <>
      <div className="bg-[#f6f6fd] px-12 py-10 rounded-t-2xl">
        <h1 className="font-avenir text-primary-400 text-xl uppercase font-semibold">
          {title || t('attention')}
        </h1>
        <p className="text-primary-900 text-lg leading-tight whitespace-pre-line">
          {description}
        </p>
      </div>
      <div className="px-12 py-10 flex gap-10">
        <div className="flex flex-col flex-1">
          <p
            className={classNames(
              'font-bold text-primary text-2xl mb-8',
              questionClassName,
            )}
          >
            {question || t('invoice.field.period_assignment.question')}
          </p>

          {render?.()}

          {options?.map((option, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  if (!option.isLoading) onChoose?.(option.value);
                }}
                disabled={option.isLoading}
                type="button"
                className="text-left px-6 py-3 mb-2 rounded-lg hover:bg-expenses-100 focus:bg-expenses-100"
              >
                <div className="flex items-center">
                  {option.isLoading && renderLoader('w-5 mr-2')}
                  <p className="font-bold text-main text-xl">{option.title}</p>
                </div>
                <p className="text-main text-lg leading-tight">
                  {option.description}
                </p>
              </button>
            );
          })}

          {!hideHelpBtn && (
            <div className="px-6 mt-8">
              <p className="font-bold text-main text-xl">{t('need_help')}</p>
              <Button
                structure="text"
                color="taxes"
                className="underline"
                onClick={() => showIntercom()}
              >
                {t('get_in_touch_with_our_team')}
              </Button>
            </div>
          )}
        </div>
        {!hideBGImage && (
          <div className="max-w-xs hidden lg:block">
            <img src={imageSrc} className="w-full" alt="" />
          </div>
        )}
      </div>
    </>
  );
};

export default WarningModalContent;
