export const WEBAPP_INTERCOMLISTING_CLICKEDSUBMIT =
  'WEBAPP_INTERCOMLISTING_CLICKEDSUBMIT';
export const WEBAPP_INTERCOMLISTING_CLICKEDSUBMISSIONHISTORY =
  'WEBAPP_INTERCOMLISTING_CLICKEDSUBMISSIONHISTORY';
export const WEBAPP_INTERCOMLISTING_DOWNLOADEDHISTORYSUBMISSION =
  'WEBAPP_INTERCOMLISTING_DOWNLOADEDHISTORYSUBMISSION';
export const WEBAPP_INTERCOMLISTING_VIEWTAXCHECKS =
  'WEBAPP_INTERCOMLISTING_VIEWTAXCHECKS';
export const WEBAPP_INTERCOMLISTING_MARKEDASDONE =
  'WEBAPP_INTERCOMLISTING_MARKEDASDONE';
export const WEBAPP_INTERCOMLISTING_MARKEDASNOTDONE =
  'WEBAPP_INTERCOMLISTING_MARKEDASNOTDONE';
export const WEBAPP_INTERCOMLISTING_VIEWTAXSCREEN =
  'WEBAPP_INTERCOMLISTING_VIEWTAXSCREEN';
export const WEBAPP_VATSUBMISSION_CLICKEDSUBMIT =
  'WEBAPP_VATSUBMISSION_CLICKEDSUBMIT';
export const WEBAPP_VATSUBMISSION_CLICKEDSUBMISSIONHISTORY =
  'WEBAPP_VATSUBMISSION_CLICKEDSUBMISSIONHISTORY';
export const WEBAPP_VATSUBMISSION_VIEWTAXCHECKS =
  'WEBAPP_VATSUBMISSION_VIEWTAXCHECKS';
export const WEBAPP_VATSUBMISSION_MARKEDASDONE =
  'WEBAPP_VATSUBMISSION_MARKEDASDONE';
export const WEBAPP_VATSUBMISSION_MARKEDASNOTDONE =
  'WEBAPP_VATSUBMISSION_MARKEDASNOTDONE';
export const WEBAPP_VATSUBMISSION_DOWNLOADEDHISTORYSUBMISSION =
  'WEBAPP_VATSUBMISSION_DOWNLOADEDHISTORYSUBMISSION';
export const WEBAPP_VATSUBMISSION_VIEWTAXSCREEN =
  'WEBAPP_VATSUBMISSION_VIEWTAXSCREEN';
export const WEBAPP_INCOMETAXSUBMISSIONDE_DOWNLOADOFFICIALREPORT =
  'WEBAPP_INCOMETAXSUBMISSIONDE_DOWNLOADOFFICIALREPORT';
export const WEBAPP_VATSUBMISSION_VIEWBREACKDOWNMODAL =
  'WEBAPP_VATSUBMISSION_VIEWBREACKDOWNMODAL';
export const WEBAPP_VATPAYMENT_CLICKEDSUBMIT =
  'WEBAPP_VATPAYMENT_CLICKEDSUBMIT';
export const WEBAPP_VATPAYMENT_MARKEDASDONE = 'WEBAPP_VATPAYMENT_MARKEDASDONE';
export const WEBAPP_VATPAYMENT_MARKEDASNOTDONE =
  'WEBAPP_VATPAYMENT_MARKEDASNOTDONE';
export const WEBAPP_VATPAYMENT_VIEWTAXSCREEN =
  'WEBAPP_VATPAYMENT_VIEWTAXSCREEN';
export const WEBAPP_CLIENTLISTING_VIEWTAXSCREEN =
  'WEBAPP_CLIENTLISTING_VIEWTAXSCREEN';
export const WEBAPP_CLIENTLISTING_DOWNLOADEDHISTORYSUBMISSION =
  'WEBAPP_CLIENTLISTING_DOWNLOADEDHISTORYSUBMISSION';
export const WEBAPP_CLIENTLISTING_MARKEDASNOTDONE =
  'WEBAPP_CLIENTLISTING_MARKEDASNOTDONE';
export const WEBAPP_CLIENTLISTING_MARKEDASDONE =
  'WEBAPP_CLIENTLISTING_MARKEDASDONE';
export const WEBAPP_CLIENTLISTING_CLICKEDSUBMISSIONHISTORY =
  'WEBAPP_CLIENTLISTING_CLICKEDSUBMISSIONHISTORY';
export const WEBAPP_CLIENTLISTING_CLICKEDSUBMIT =
  'WEBAPP_CLIENTLISTING_CLICKEDSUBMIT';
export const WEBAPP_CLIENTLISTING_VIEWTAXCHECKS =
  'WEBAPP_CLIENTLISTING_VIEWTAXCHECKS';
export const WEBAPP_EUR_DOWNLOADEDHISTORYSUBMISSION =
  'WEBAPP_EUR_DOWNLOADEDHISTORYSUBMISSION';
export const WEBAPP_EUR_VIEWBREACKDOWNMODAL = 'WEBAPP_EUR_VIEWBREACKDOWNMODAL';
export const WEBAPP_EUR_VIEWTAXSCREEN = 'WEBAPP_EUR_VIEWTAXSCREEN';
export const WEBAPP_EUR_MARKEDASNOTDONE = 'WEBAPP_EUR_MARKEDASNOTDONE';
export const WEBAPP_EUR_MARKEDASDONE = 'WEBAPP_EUR_MARKEDASDONE';
export const WEBAPP_EUR_VIEWTAXCHECKS = 'WEBAPP_EUR_VIEWTAXCHECKS';
export const WEBAPP_EUR_CLICKEDSUBMIT = 'WEBAPP_EUR_CLICKEDSUBMIT';
export const WEBAPP_EUR_CLICKEDSUBMISSIONHISTORY =
  'WEBAPP_EUR_CLICKEDSUBMISSIONHISTORY';
export const WEBAPP_TRADETAXSUBMISSION_DOWNLOADOFFICIALREPORT =
  'WEBAPP_TRADETAXSUBMISSION_DOWNLOADOFFICIALREPORT';
export const WEBAPP_TRADETAXSUBMISSION_VIEWTAXSCREEN =
  'WEBAPP_TRADETAXSUBMISSION_VIEWTAXSCREEN';
export const WEBAPP_TRADETAXSUBMISSION_MARKEDASNOTDONE =
  'WEBAPP_TRADETAXSUBMISSION_MARKEDASNOTDONE';
export const WEBAPP_TRADETAXSUBMISSION_CLICKEDSUBMIT =
  'WEBAPP_TRADETAXSUBMISSION_CLICKEDSUBMIT';
export const WEBAPP_TRADETAXSUBMISSION_VALIDATEDPERSONALDETAILS =
  'WEBAPP_TRADETAXSUBMISSION_VALIDATEDPERSONALDETAILS';
export const WEBAPP_TRADETAXSUBMISSION_VALIDATEDCALCULATIONS =
  'WEBAPP_TRADETAXSUBMISSION_VALIDATEDCALCULATIONS';
export const WEBAPP_TRADETAXSUBMISSION_VALIDATEDPREPAYMENTS =
  'WEBAPP_TRADETAXSUBMISSION_VALIDATEDPREPAYMENTS';
export const WEBAPP_TRADETAXSUBMISSION_MARKEDASDONE =
  'WEBAPP_TRADETAXSUBMISSION_MARKEDASDONE';
export const WEBAPP_INCOMETAXSUBMISSIONDE_DELETEDPAYSLIP =
  'WEBAPP_INCOMETAXSUBMISSIONDE_DELETEDPAYSLIP';
export const WEBAPP_INCOMETAXSUBMISSIONDE_EDITEDPAYSLIP =
  'WEBAPP_INCOMETAXSUBMISSIONDE_EDITEDPAYSLIP';
export const WEBAPP_INCOMETAXSUBMISSIONDE_ADDEDPAYSLIP =
  'WEBAPP_INCOMETAXSUBMISSIONDE_ADDEDPAYSLIP';
export const WEBAPP_INCOMETAXSUBMISSIONDE_CLICKEDPREVIEW =
  'WEBAPP_INCOMETAXSUBMISSIONDE_CLICKEDPREVIEW';
export const WEBAPP_INCOMETAXSUBMISSIONDE_DOWNLOADDOCUMENT =
  'WEBAPP_INCOMETAXSUBMISSIONDE_DOWNLOADDOCUMENT';
export const WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDPREPAYMENTS =
  'WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDPREPAYMENTS';
export const WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDSPECIALDEDUCTIONS =
  'WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDSPECIALDEDUCTIONS';
export const WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDOTHERINSURANCES =
  'WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDOTHERINSURANCES';
export const WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDHEALTHINSURANCES =
  'WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDHEALTHINSURANCES';
export const WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDINCOMEDETAILS =
  'WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDINCOMEDETAILS';
export const WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDPERSONALDETAILS =
  'WEBAPP_INCOMETAXSUBMISSIONDE_VALIDATEDPERSONALDETAILS';
export const WEBAPP_INCOMETAXSUBMISSIONDE_VIEWTAXSCREEN =
  'WEBAPP_INCOMETAXSUBMISSIONDE_VIEWTAXSCREEN';
export const WEBAPP_INCOMETAXSUBMISSIONDE_MARKEDASNOTDONE =
  'WEBAPP_INCOMETAXSUBMISSIONDE_MARKEDASNOTDONE';
export const WEBAPP_INCOMETAXSUBMISSIONDE_MARKEDASDONE =
  'WEBAPP_INCOMETAXSUBMISSIONDE_MARKEDASDONE';
export const WEBAPP_INCOMETAXSUBMISSIONDE_CLICKEDSUBMIT =
  'WEBAPP_INCOMETAXSUBMISSIONDE_CLICKEDSUBMIT';
export const WEBAPP_INCOMETAXSUBMISSIONBE_CLICKEDPREVIEW =
  'WEBAPP_INCOMETAXSUBMISSIONBE_CLICKEDPREVIEW';
export const WEBAPP_INCOMETAXSUBMISSIONBE_PREPAYMENTS =
  'WEBAPP_INCOMETAXSUBMISSIONBE_PREPAYMENTS';
export const WEBAPP_INCOMETAXSUBMISSIONBE_PROFITSANDLOSSES =
  'WEBAPP_INCOMETAXSUBMISSIONBE_PROFITSANDLOSSES';
export const WEBAPP_INCOMETAXSUBMISSIONBE_SUBSIDIES =
  'WEBAPP_INCOMETAXSUBMISSIONBE_SUBSIDIES';
export const WEBAPP_INCOMETAXSUBMISSIONBE_CAPITALGAINS =
  'WEBAPP_INCOMETAXSUBMISSIONBE_CAPITALGAINS';
export const WEBAPP_INCOMETAXSUBMISSIONBE_VALIDATEDPERSONALDETAILS =
  'WEBAPP_INCOMETAXSUBMISSIONBE_VALIDATEDPERSONALDETAILS';
export const WEBAPP_INCOMETAXSUBMISSIONBE_VIEWTAXSCREEN =
  'WEBAPP_INCOMETAXSUBMISSIONBE_VIEWTAXSCREEN';
export const WEBAPP_INCOMETAXSUBMISSIONBE_MARKEDASNOTDONE =
  'WEBAPP_INCOMETAXSUBMISSIONBE_MARKEDASNOTDONE';
export const WEBAPP_INCOMETAXSUBMISSIONBE_MARKEDASDONE =
  'WEBAPP_INCOMETAXSUBMISSIONBE_MARKEDASDONE';
export const WEBAPP_INCOMETAXSUBMISSIONBE_CLICKEDSUBMIT =
  'WEBAPP_INCOMETAXSUBMISSIONBE_CLICKEDSUBMIT';

export const WEBAPP_INCOMETAXPAYMENT_VIEWTAXSCREEN =
  'WEBAPP_INCOMETAXPAYMENT_VIEWTAXSCREEN';
export const WEBAPP_INCOMETAXPAYMENT_CLICKEDSUBMIT =
  'WEBAPP_INCOMETAXPAYMENT_CLICKEDSUBMIT';
export const WEBAPP_INCOMETAXPAYMENT_SUBMITTEDSHEETSTEP =
  'WEBAPP_INCOMETAXPAYMENT_SUBMITTEDSHEETSTEP';
export const WEBAPP_INCOMETAXPAYMENT_MARKEDASDONE =
  'WEBAPP_INCOMETAXPAYMENT_MARKEDASDONE';
export const WEBAPP_INCOMETAXPAYMENT_MARKEDASNOTDONE =
  'WEBAPP_INCOMETAXPAYMENT_MARKEDASNOTDONE';
export const WEBAPP_INCOMETAXPAYMENT_PAIDAMOUNTMODAL =
  'WEBAPP_INCOMETAXPAYMENT_PAIDAMOUNTMODAL';
export const WEBAPP_INCOMETAXPAYMENT_ENTEREDMANUALAMOUNT =
  'WEBAPP_INCOMETAXPAYMENT_ENTEREDMANUALAMOUNT';
export const WEBAPP_INCOMETAXPAYMENT_LINKEDEXISTINGTRANSACTION =
  'WEBAPP_INCOMETAXPAYMENT_LINKEDEXISTINGTRANSACTION';

export const WEBAPP_INCOMETAXPREPAYMENT_VIEWTAXSCREEN =
  'WEBAPP_INCOMETAXPREPAYMENT_VIEWTAXSCREEN';
export const WEBAPP_INCOMETAXPREPAYMENT_CLICKEDSUBMIT =
  'WEBAPP_INCOMETAXPREPAYMENT_CLICKEDSUBMIT';
export const WEBAPP_INCOMETAXPREPAYMENT_SUBMITTEDSHEETSTEP =
  'WEBAPP_INCOMETAXPREPAYMENT_SUBMITTEDSHEETSTEP';
export const WEBAPP_INCOMETAXPREPAYMENT_PAIDAMOUNTMODAL =
  'WEBAPP_INCOMETAXPREPAYMENT_PAIDAMOUNTMODAL';
export const WEBAPP_INCOMETAXPREPAYMENT_ENTEREDMANUALAMOUNT =
  'WEBAPP_INCOMETAXPREPAYMENT_ENTEREDMANUALAMOUNT';
export const WEBAPP_INCOMETAXPREPAYMENT_LINKEDEXISTINGTRANSACTION =
  'WEBAPP_INCOMETAXPREPAYMENT_LINKEDEXISTINGTRANSACTION';
export const WEBAPP_INCOMETAXPREPAYMENT_MARKEDASDONE =
  'WEBAPP_INCOMETAXPREPAYMENT_MARKEDASDONE';
export const WEBAPP_INCOMETAXPREPAYMENT_MARKEDASNOTDONE =
  'WEBAPP_INCOMETAXPREPAYMENT_MARKEDASNOTDONE';

export const WEBAPP_SPECIALVATPAYMENT_MARKASDONE =
  'WEBAPP_SPECIALVATPAYMENT_MARKASDONE';
export const WEBAPP_SPECIALVATPAYMENT_MARKASNOTDONE =
  'WEBAPP_SPECIALVATPAYMENT_MARKASNOTDONE';
export const WEBAPP_SPECIALVATPAYMENT_VIEWTAXSCREEN =
  'WEBAPP_SPECIALVATPAYMENT_VIEWTAXSCREEN';
export const WEBAPP_SPECIALVATPAYMENT_CLICKEDSUBMIT =
  'WEBAPP_SPECIALVATPAYMENT_CLICKEDSUBMIT';
export const WEBAPP_SPECIALVATPAYMENT_VIEWBREAKDOWNMODAL =
  'WEBAPP_SPECIALVATPAYMENT_VIEWBREAKDOWNMODAL';
export const WEBAPP_SPECIALVATPAYMENT_CLICKEDSUBMISSIONHISTORY =
  'WEBAPP_SPECIALVATPAYMENT_CLICKEDSUBMISSIONHISTORY';
export const WEBAPP_SPECIALVATPAYMENT_DOWNLOADEDHISTORYSUBMISSION =
  'WEBAPP_SPECIALVATPAYMENT_DOWNLOADEDHISTORYSUBMISSION';
export const WEBAPP_SPECIALVATSUBMISSION_MARKASDONE =
  'WEBAPP_SPECIALVATSUBMISSION_MARKASDONE';
export const WEBAPP_SPECIALVATSUBMISSION_MARKASNOTDONE =
  'WEBAPP_SPECIALVATSUBMISSION_MARKASNOTDONE';
export const WEBAPP_SPECIALVATSUBMISSION_VIEWTAXSCREEN =
  'WEBAPP_SPECIALVATSUBMISSION_VIEWTAXSCREEN';
export const WEBAPP_SPECIALVATSUBMISSION_CLICKEDSUBMIT =
  'WEBAPP_SPECIALVATSUBMISSION_CLICKEDSUBMIT';
export const WEBAPP_SPECIALVATSUBMISSION_VIEWBREAKDOWNMODAL =
  'WEBAPP_SPECIALVATSUBMISSION_VIEWBREAKDOWNMODAL';
export const WEBAPP_SPECIALVATSUBMISSION_CLICKEDSUBMISSIONHISTORY =
  'WEBAPP_SPECIALVATSUBMISSION_CLICKEDSUBMISSIONHISTORY';
export const WEBAPP_SPECIALVATSUBMISSION_DOWNLOADEDHISTORYSUBMISSION =
  'WEBAPP_SPECIALVATSUBMISSION_DOWNLOADEDHISTORYSUBMISSION';
export const WEBAPP_TAXESONBOARDING_CLICKEDONBOARDINGVIDEO =
  'WEBAPP_TAXESONBOARDING_CLICKEDONBOARDINGVIDEO';

export const WEBAPP_CORPORATETAXPAYMENT_VIEWTAXSCREEN =
  'WEBAPP_CORPORATETAXPAYMENT_VIEWTAXSCREEN';
export const WEBAPP_CORPORATETAXPAYMENT_CLICKEDSUBMIT =
  'WEBAPP_CORPORATETAXPAYMENT_CLICKEDSUBMIT';
export const WEBAPP_CORPORATETAXPAYMENT_SUBMITTEDSHEETSTEP =
  'WEBAPP_CORPORATETAXPAYMENT_SUBMITTEDSHEETSTEP';
export const WEBAPP_CORPORATETAXPAYMENT_MARKASDONE =
  'WEBAPP_CORPORATETAXPAYMENT_MARKASDONE';
export const WEBAPP_CORPORATETAXPAYMENT_MARKASNOTDONE =
  'WEBAPP_CORPORATETAXPAYMENT_MARKASNOTDONE';
export const WEBAPP_CORPORATETAXPAYMENT_ENTEREDMANUALAMOUNT =
  'WEBAPP_CORPORATETAXPAYMENT_ENTEREDMANUALAMOUNT';
export const WEBAPP_CORPORATETAXPAYMENT_LINKEDEXISTINGTRANSACTION =
  'WEBAPP_CORPORATETAXPAYMENT_LINKEDEXISTINGTRANSACTION';

export const WEBAPP_CORPORATETAXPREPAYMENT_VIEWTAXSCREEN =
  'WEBAPP_CORPORATETAXPREPAYMENT_VIEWTAXSCREEN';
export const WEBAPP_CORPORATETAXPREPAYMENT_CLICKSUBMIT =
  'WEBAPP_CORPORATETAXPREPAYMENT_CLICKSUBMIT';
export const WEBAPP_CORPORATETAXPREPAYMENT_SUBMITTEDSHEETSTEP =
  'WEBAPP_CORPORATETAXPREPAYMENT_SUBMITTEDSHEETSTEP';
export const WEBAPP_CORPORATETAXPREPAYMENT_ENTEREDMANUALAMOUNT =
  'WEBAPP_CORPORATETAXPREPAYMENT_ENTEREDMANUALAMOUNT';
export const WEBAPP_CORPORATETAXPREPAYMENT_LINKEDEXISTINGTRANSACTION =
  'WEBAPP_CORPORATETAXPREPAYMENT_LINKEDEXISTINGTRANSACTION';
export const WEBAPP_CORPORATETAXPREPAYMENT_MARKASDONE =
  'WEBAPP_CORPORATETAXPREPAYMENT_MARKASDONE';
export const WEBAPP_CORPORATETAXPREPAYMENT_MARKEDASNOTDONE =
  'WEBAPP_CORPORATETAXPREPAYMENT_MARKEDASNOTDONE';

export const WEBAPP_TAXREVIEW_VIEWTAXREVIEW = 'WEBAPP_TAXREVIEW_VIEWTAXREVIEW';
export const WEBAPP_TAXREVIEW_OPENPENDINGCHECK =
  'WEBAPP_TAXREVIEW_OPENPENDINGCHECK';
export const WEBAPP_TAXREVIEW_MARKCHECKASREVIEWED =
  'WEBAPP_TAXREVIEW_MARKCHECKASREVIEWED';
export const WEBAPP_TAXREVIEW_MARKCHECKASNOTREVIEWED =
  'WEBAPP_TAXREVIEW_MARKCHECKASNOTREVIEWED';
export const WEBAPP_TAXREVIEW_OPENCHECKEXPENSEORREVENUE =
  'WEBAPP_TAXREVIEW_OPENCHECKEXPENSEORREVENUE';
export const WEBAPP_TAXREVIEW_SKIPPEDREVIEWANDSUBMITTEDTAX =
  'WEBAPP_TAXREVIEW_SKIPPEDREVIEWANDSUBMITTEDTAX';
export const WEBAPP_TAXESONBOARDING_VISITEDWELCOMESCREEN =
  'WEBAPP_TAXESONBOARDING_VISITEDWELCOMESCREEN';
export const WEBAPP_TAXESONBOARDING_VISITEDOBLIGATIONSSCREEN =
  'WEBAPP_TAXESONBOARDING_VISITEDOBLIGATIONSSCREEN';
export const WEBAPP_TAXESONBOARDING_VISITEDTAXESLISTSTRUCTURESCREEN =
  'WEBAPP_TAXESONBOARDING_VISITEDTAXESLISTSTRUCTURESCREEN';
export const WEBAPP_TAXESONBOARDING_WELCOMESCREEN_CLICKEDDISCOVERTAXESBUTTON =
  'WEBAPP_TAXESONBOARDING_WELCOMESCREEN_CLICKEDDISCOVERTAXESBUTTON';
export const WEBAPP_TAXESONBOARDING_WELCOMESCREEN_CLICKEDSKIPBUTTON =
  'WEBAPP_TAXESONBOARDING_WELCOMESCREEN_CLICKEDSKIPBUTTON';
export const WEBAPP_TAXESONBOARDING_OBLIGATIONSSCREEN_CLICKEDCONTINUE =
  'WEBAPP_TAXESONBOARDING_OBLIGATIONSSCREEN_CLICKEDCONTINUE';
export const WEBAPP_TAXESONBOARDING_OBLIGATIONSSCREEN_CLICKEDOPENOBLIGATION =
  'WEBAPP_TAXESONBOARDING_OBLIGATIONSSCREEN_CLICKEDOPENOBLIGATION';
export const WEBAPP_TAXESONBOARDING_TAXESLISTSTRUCTURESCREEN_CLICKEDSEEFUTURETAXES =
  'WEBAPP_TAXESONBOARDING_TAXESLISTSTRUCTURESCREEN_CLICKEDSEEFUTURETAXES';

export const WEB_INCOMETAXSUBMISSION_CLICKEDWHOISTHISFOR =
  'WEB_INCOMETAXSUBMISSION_CLICKEDWHOISTHISFOR';
export const WEB_VATSUBMISSION_CLICKEDWHOISTHISFOR =
  'WEB_VATSUBMISSION_CLICKEDWHOISTHISFOR';
export const WEB_INTRACOMLISTING_ZM_CLICKEDWHOISTHISFOR =
  'WEB_INTRACOMLISTING_ZM_CLICKEDWHOISTHISFOR';
export const WEB_EURSUBMISSION_CLICKEDWHOISTHISFOR =
  'WEB_EURSUBMISSION_CLICKEDWHOISTHISFOR';
export const WEB_TRADETAXSUBMISSION_CLICKEDWHOISTHISFOR =
  'WEB_TRADETAXSUBMISSION_CLICKEDWHOISTHISFOR';

export const WEB_INCOME_TAX_SCREEN_FINISHEDIMPROVETAXESTIMATION =
  'WEB_INCOME_TAX_SCREEN_FINISHEDIMPROVETAXESTIMATION';

export const WEB_TAXES_ADDPREVIOUSYEARS = 'WEB_TAXES_ADDPREVIOUSYEARS';
