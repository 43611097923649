import { XCircleIcon } from 'utils/icons';
import showToast from 'utils/toast';

const showErrorToast = (title: string): void => {
  showToast({
    title,
    icon: <XCircleIcon className="w-6 h-6 text-red-500" />,
  });
};

export default showErrorToast;
