import classNames from 'classnames';
import type { FC, ReactNode } from 'react';
import type { TitleProps } from './Title.props';

const size = new Map<ReactNode, string>();
size.set('h1', 'text-7xl');
size.set('h2', 'text-6xl');
size.set('h3', 'text-5xl');
size.set('h4', 'text-4xl');
size.set('h5', 'text-3xl');
size.set('h6', 'text-2xl');

const Title: FC<TitleProps> = ({
  children,
  type: Type,
  className,
}): JSX.Element => {
  return (
    <Type
      className={classNames(
        'font-avenir text-primary-700 font-bold tracking-[-.045em]',
        size.get(Type),
        className,
      )}
    >
      {children}
    </Type>
  );
};

export default Title;
