import type { ModalApi, ModalProps } from 'components/atoms/Modal';
import { Modal } from 'components/atoms/Modal';
import type { ReactNode } from 'react';
import { useRef, useState } from 'react';

export type UseModalAPI = {
  open: (renderFn: ModalProps['render'], options?: ModalOptions) => void;
  close: () => void;
};

type ReturnType = [ReactNode, UseModalAPI];

export type ModalOptions = Omit<ModalProps, 'render' | 'apiRef'>;
const useModal = (modalProps?: ModalOptions): ReturnType => {
  const [modal, setModal] = useState<{
    render: ModalProps['render'];
    options?: ModalOptions;
  }>({ render: undefined, options: { name: '' } });

  const modalRef = useRef<ModalApi>();

  const api = {
    open: (renderFn: ModalProps['render'], options?: ModalOptions) => {
      setModal({ render: renderFn, options });
      modalRef.current?.openModal();
    },
    close: () => modalRef.current?.closeModal(),
  };
  const content = (
    <Modal
      {...modalProps}
      {...modal.options}
      name={modal?.options?.name || ''}
      apiRef={modalRef}
      render={modal.render}
    />
  );

  return [content, api];
};

export default useModal;
