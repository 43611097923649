import { joiResolver } from '@hookform/resolvers/joi';
import {
  createClient,
  deleteClient,
  getClientById,
  updateClient,
} from 'api/v2/clients';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import CustomLoader from 'components/molecules/CustomLoader.component';
import SimpleError from 'components/molecules/SimpleError.component';
import type { SlideOver2Props } from 'components/molecules/SlideOver2.component';
import SlideOver2 from 'components/molecules/SlideOver2.component';
import { AnimatePresence } from 'framer-motion';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useGetCountries from 'hooks/shared/useGetCountries';
import { worldThemeContext } from 'hooks/shared/useWorldTheme';
import useConfirm from 'hooks/useConfirm';
import type {
  CloseMeta,
  FormSlideOverManagerApi,
} from 'hooks/useFormSlideOverManager';
import {
  forceClose,
  naturalClose,
  useFormSlideOverManager,
} from 'hooks/useFormSlideOverManager';
import { isEmpty } from 'lodash';
import type { FC, MutableRefObject } from 'react';
import { useId, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RevenuesCacheKeys } from 'types/cacheKeys.types';
import type { IClient } from 'types/clients.types';
import * as ClientsEvents from 'utils/amplitude/events/clients.amplitude';
import { cleanEmptyValues } from 'utils/helpers';
import { TrashIcon } from 'utils/icons';
import Form from './Form';
import useFormMeta from './useFormMeta';
import { getDefaultValues } from './utils';
import validationSchema from './validationSchema';

export type ClientInput = string | Partial<IClient>;

type ExtraFormWrapperProps = {
  afterSubmit?: (result: any) => void;
  afterDelete?: () => void;
  onClose?: (meta: CloseMeta) => void;
};

type FormWrapperProps = {
  client: ClientInput;
  isFormDirtyRef: any;
} & ExtraFormWrapperProps &
  Partial<Omit<SlideOver2Props, 'onClose'>>;

const unSavedChangesDefaultSettings = {
  className: 'text-center font-semibold',
  buttonsWrapperClassName: 'justify-center',
  buttonClassName: 'min-w-[90px]',
};

const FormWrapper: FC<FormWrapperProps> = ({
  client,
  onClose,
  isFormDirtyRef,
  afterSubmit,
  afterDelete,
}) => {
  const { t } = useTranslation();

  const countries = useGetCountries();
  const { getFormMeta } = useFormMeta(countries.data);

  const [confirmContent, confirmApi] = useConfirm();
  const customerCountry = useCustomerCountry() as string;

  const formAPI = useForm({
    mode: 'onChange',
    resolver: (values, context, ...rest) => {
      return joiResolver(validationSchema, {
        abortEarly: false,
        allowUnknown: true,
      })(values, { ...context, ...getFormMeta(values) }, ...rest);
    },
  });

  const clientAPI = useQuery(
    [RevenuesCacheKeys.RevenuesClientById, useId()],
    () => {
      if (typeof client === 'string') return getClientById(client);
      return Promise.resolve(client);
    },
    {
      cacheTime: 0,
      onSuccess: (data) => {
        formAPI.reset(getDefaultValues(data, customerCountry));
      },
    },
  );

  const isLoading = clientAPI.isLoading || countries.isLoading;
  const isError = clientAPI.isError || countries.isError;
  const retry = () => {
    if (clientAPI.isError) clientAPI.refetch();
    if (countries.isError) countries.refetch();
  };

  const isEdit = !!clientAPI.data?._id;

  const isDirty = !isEmpty(formAPI.formState.dirtyFields);
  useImperativeHandle(isFormDirtyRef, () => isDirty, [isDirty]);

  const onSubmit = async () => {
    const values = formAPI.getValues();

    const submitValues = {
      ...cleanEmptyValues(values),
      steuernummer: values.steuernummer?.trim?.() || null,
      VATNumber: values.VATNumber?.trim?.() || null,
      VATStatus: values.VATStatus || null,
      email: values.email?.trim?.() || undefined,
      contactName: values.contactName?.trim?.() || null,
      notes: values.notes?.trim?.() || null,
      phoneNumber: values.phoneNumber?.trim?.() || null,
    };

    const result = isEdit
      ? await updateClient(values._id, submitValues)
      : await createClient(submitValues);

    onClose?.(forceClose);
    afterSubmit?.(result);
  };

  const onDelete = (_id: string): Promise<boolean> => {
    return new Promise((resolve) => {
      confirmApi.open({
        ...unSavedChangesDefaultSettings,
        message: t('clients.confirm_delete'),
        onConfirm: () => deleteClient(_id).then(() => resolve(true)),
        onDecline: () => {
          confirmApi.close();
          resolve(false);
        },
      });
    });
  };

  return (
    <>
      {confirmContent}
      <SlideOver2
        showLeftPanelUI={false}
        onClose={() => onClose?.(naturalClose)}
        renderRightPanel={() => {
          if (isLoading) {
            return <CustomLoader className="h-full" />;
          }
          if (isError) {
            return <SimpleError messageClassName="!text-xl" onRetry={retry} />;
          }
          if (!clientAPI.data || !countries.data) return null;
          return (
            <Form
              formAPI={formAPI}
              client={clientAPI.data}
              onSubmit={onSubmit}
              headerMeta={
                <>
                  <div className="relative z-10 pointer-events-auto mt-5">
                    {isEdit && (
                      <Alert
                        type="warning"
                        description={t('clients.modifications_warning')}
                      />
                    )}
                    <h1 className="font-avenir text-5xl text-primary-700 font-bold first-letter:capitalize tracking-tight leading-tight truncate mt-5 mb-5">
                      {t(isEdit ? 'invoice.edit_client' : 'invoice.new_client')}
                    </h1>
                  </div>
                  <div className="border-b border-primary-100" />
                </>
              }
              footerMeta={
                isEdit && (
                  <div className="mt-4">
                    <Button
                      tracingEvent={
                        ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDDELETE
                      }
                      structure="text"
                      onClick={() =>
                        onDelete(clientAPI.data._id as string).then(
                          (isDeleted) => {
                            if (isDeleted) {
                              onClose?.(naturalClose);
                              afterDelete?.();
                            }
                          },
                        )
                      }
                    >
                      <TrashIcon className="w-5 mr-2" />
                      <span className="font-semibold">{t('delete')}</span>
                    </Button>
                  </div>
                )
              }
            />
          );
        }}
      />
    </>
  );
};

type ClientFormSlideOverProps = {
  apiRef: MutableRefObject<FormSlideOverManagerApi<ClientInput> | undefined>;
  onOpen?: (doc: ClientInput) => void;
} & ExtraFormWrapperProps;

const ClientFormSlideOver: FC<ClientFormSlideOverProps> = ({
  apiRef,
  afterSubmit,
  afterDelete,
  onClose,
  onOpen,
}) => {
  const { doc, api, isFormDirtyRef, confirmContent, formKey } =
    useFormSlideOverManager<ClientInput>({
      apiRef,
      onClose,
      onOpen,
    });

  return (
    <worldThemeContext.Provider value="revenues">
      {confirmContent}
      <AnimatePresence mode="wait">
        {!!doc && (
          <FormWrapper
            key={formKey}
            client={doc}
            onClose={api.close}
            isFormDirtyRef={isFormDirtyRef}
            afterSubmit={afterSubmit}
            afterDelete={afterDelete}
          />
        )}
      </AnimatePresence>
    </worldThemeContext.Provider>
  );
};

export default ClientFormSlideOver;
