/// list //////
export const WEBAPP_EXPENSESLIST_CLICKEDADDEXPENSE =
  'WEBAPP_EXPENSESLIST_CLICKEDADDEXPENSE';
export const WEBAPP_EXPENSESLIST_SORTEDLIST = 'WEBAPP_EXPENSESLIST_SORTEDLIST';
export const WEB_EXPENSELIST_FILTEREDLIST = 'WEB_EXPENSELIST_FILTEREDLIST';
export const WEBAPP_EXPENSESLIST_CLICKEDEXPENSE =
  'WEBAPP_EXPENSESLIST_CLICKEDEXPENSE';
export const WEBAPP_EXPENSESLIST_BULKSELECT = 'WEBAPP_EXPENSESLIST_BULKSELECT';
export const WEBAPP_EXPENSESLIST_CHANGETABLEPAGE =
  'WEBAPP_EXPENSESLIST_CHANGETABLEPAGE';
export const WEBAPP_EXPENSESLIST_CLICKEDFILTERS =
  'WEBAPP_EXPENSESLIST_CLICKEDFILTERS';
export const WEBAPP_EXPENSESLIST_DROPZONEUPLOADING =
  'WEBAPP_EXPENSESLIST_DROPZONEUPLOADING';
export const WEBAPP_EXPENSESLIST_BULKDELETE = 'WEBAPP_EXPENSESLIST_BULKDELETE';

export const WEBAPP_EMPTYEXPENSELIST_STARTEDUPLOADING =
  'WEBAPP_EMPTYEXPENSELIST_STARTEDUPLOADING';

///// form ////
export const WEBAPP_EXPENSE_CLICKEDEXPENSEDATE =
  'WEBAPP_EXPENSE_CLICKEDEXPENSEDATE';
export const WEBAPP_EXPENSE_UPDATEDEXPENSEDATE =
  'WEBAPP_EXPENSE_UPDATEDEXPENSEDATE';

export const WEBAPP_EXPENSE_UPDATEDISINVOICE =
  'WEBAPP_EXPENSE_UPDATEDISINVOICE';

export const WEBAPP_EXPENSE_CLICKEDSUPPLIERNAME =
  'WEBAPP_EXPENSE_CLICKEDSUPPLIERNAME';
export const WEBAPP_EXPENSE_UPDATEDSUPPLIERNAME =
  'WEBAPP_EXPENSE_UPDATEDSUPPLIERNAME';

export const WEBAPP_EXPENSE_CLICKEDTRIPTYPE = 'WEBAPP_EXPENSE_CLICKEDTRIPTYPE';
export const WEBAPP_EXPENSE_UPDATEDTRIPTYPE = 'WEBAPP_EXPENSE_UPDATEDTRIPTYPE';

export const WEBAPP_EXPENSE_CLICKEDTRIPVEHICLETYPE =
  'WEBAPP_EXPENSE_CLICKEDTRIPVEHICLETYPE';
export const WEBAPP_EXPENSE_UPDATEDTRIPVEHICLETYPE =
  'WEBAPP_EXPENSE_UPDATEDTRIPVEHICLETYPE';

export const WEBAPP_EXPENSE_UPDATEDSUPPLIERCOUNTRY =
  'WEBAPP_EXPENSE_UPDATEDSUPPLIERCOUNTRY';
export const WEBAPP_EXPENSE_CLICKEDSUPPLIERCOUNTRY =
  'WEBAPP_EXPENSE_CLICKEDSUPPLIERCOUNTRY';

export const WEBAPP_EXPENSE_CLICKEDCATEGORY = 'WEBAPP_EXPENSE_CLICKEDCATEGORY';
export const WEBAPP_EXPENSE_UPDATEDCATEGORY = 'WEBAPP_EXPENSE_UPDATEDCATEGORY';

export const WEBAPP_EXPENSE_CLICKEDTRIPTOTALKM =
  'WEBAPP_EXPENSE_CLICKEDTRIPTOTALKM';

export const WEBAPP_EXPENSE_CLICKEDTRIPTOTALDAYS =
  'WEBAPP_EXPENSE_CLICKEDTRIPTOTALDAYS';

export const WEBAPP_EXPENSE_CLICKEDAMOUNT = 'WEBAPP_EXPENSE_CLICKEDAMOUNT';
export const WEBAPP_EXPENSE_CLICKEDVATAMOUNT =
  'WEBAPP_EXPENSE_CLICKEDVATAMOUNT';

export const WEBAPP_EXPENSE_UPDATEDVATRATE = 'WEBAPP_EXPENSE_UPDATEDVATRATE';

export const WEBAPP_EXPENSE_CLICKEDPROFESSIONNALUSE =
  'WEBAPP_EXPENSE_CLICKEDPROFESSIONNALUSE';

export const WEBAPP_EXPENSE_CLICKEDAMORTISATIONITEMNAME =
  'WEBAPP_EXPENSE_CLICKEDAMORTISATIONITEMNAME';

export const WEBAPP_EXPENSE_UPDATEDLINKPAYMENT =
  'WEBAPP_EXPENSE_UPDATEDLINKPAYMENT';
export const WEBAPP_EXPENSE_CLICKEDLINKPAYMENT =
  'WEBAPP_EXPENSE_CLICKEDLINKPAYMENT';

export const WEBAPP_EXPENSE_CLICKEDNOTES = 'WEBAPP_EXPENSE_CLICKEDNOTES';

export const WEBAPP_EXPENSE_UPDATEDPERIOD = 'WEBAPP_EXPENSE_UPDATEDPERIOD';
export const WEBAPP_EXPENSE_CLICKEDPERIOD = 'WEBAPP_EXPENSE_CLICKEDPERIOD';

export const WEBAPP_EXPENSE_CLICKEDVATTYPE = 'WEBAPP_EXPENSE_CLICKEDVATTYPE';
export const WEBAPP_EXPENSE_UPDATEDVATTYPE = 'WEBAPP_EXPENSE_UPDATEDVATTYPE';

export const WEBAPP_EXPENSE_CLICKEDSAVE = 'WEBAPP_EXPENSE_CLICKEDSAVE';
export const WEBAPP_EXPENSE_ERRORSAVING = 'WEBAPP_EXPENSE_ERRORSAVING';

export const WEBAPP_EXPENSE_CLICKEDDOWNLOAD = 'WEBAPP_EXPENSE_CLICKEDDOWNLOAD';
export const WEBAPP_EXPENSE_CLICKEDREPLACE = 'WEBAPP_EXPENSE_CLICKEDREPLACE';
export const WEBAPP_EXPENSE_CLICKEDDELETEATTACHMENT =
  'WEBAPP_EXPENSE_CLICKEDDELETEATTACHMENT';

export const WEBAPP_EXPENSE_CLICKEDDELETE = 'WEBAPP_EXPENSE_CLICKEDDELETE';
export const WEBAPP_EXPENSE_CLICKEDPAYWITHQRCODE =
  'WEBAPP_EXPENSE_CLICKEDPAYWITHQRCODE';
export const WEBAPP_EXPENSE_CLICKEDMARKASPAIDINQRCODEFORM =
  'WEBAPP_EXPENSE_CLICKEDMARKASPAIDINQRCODEFORM';
export const WEBAPP_EXPENSE_ONBOARDING_CLICKEDIMPORTFROMANOTHERSOFTWARE =
  'WEBAPP_EXPENSE_ONBOARDING_CLICKEDIMPORTFROMANOTHERSOFTWARE';

// german car form

export const WEBAPP_VEHICLE_CLICKGERMANCARFUELTYPE =
  'WEBAPP_VEHICLE_CLICKGERMANCARFUELTYPE';
export const WEBAPP_VEHICLE_UPDATEGERMANCARFUELTYPE =
  'WEBAPP_VEHICLE_UPDATEGERMANCARFUELTYPE';

export const WEBAPP_VEHICLE_CLICKGERMANCARENGINEPOWER =
  'WEBAPP_VEHICLE_CLICKGERMANCARENGINEPOWER';

export const WEBAPP_VEHICLE_CLICKGERMANCARELECTRICRANGE =
  'WEBAPP_VEHICLE_CLICKGERMANCARELECTRICRANGE';

export const WEBAPP_VEHICLE_CLICKGERMANCARLISTPRICE =
  'WEBAPP_VEHICLE_CLICKGERMANCARLISTPRICE';

export const WEBAPP_VEHICLE_CLICKGERMANCARNAME =
  'WEBAPP_VEHICLE_CLICKGERMANCARNAME';

export const WEBAPP_VEHICLE_CLICKGERMANCARDELETE =
  'WEBAPP_VEHICLE_CLICKGERMANCARDELETE';

export const WEBAPP_VEHICLE_CLICKGERMANCARSAVE =
  'WEBAPP_VEHICLE_CLICKGERMANCARSAVE';

export const WEBAPP_VEHICLE_ADDEDGERMANCAR = 'WEBAPP_VEHICLE_ADDEDGERMANCAR';

export const WEBAPP_VEHICLE_EDITEDGERMANCAR = 'WEBAPP_VEHICLE_EDITEDGERMANCAR';

export const WEBAPP_VEHICLE_DELETEDGERMANCAR =
  'WEBAPP_VEHICLE_DELETEDGERMANCAR';
