import ChristmasIcon from 'components/atoms/SVG/sidebarIcons/ChristmasIcon.component';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import Snowfall from 'react-snowfall';
import { AccountTypeEnum } from 'types/users.types';
import { getReviewYears } from './helpers';

const WelcomeBanner: FC = () => {
  const { t } = useTranslation();

  const { year } = getReviewYears();

  const { data: user } = useGetCurrentCustomerData();

  return (
    <div className="bg-primary py-9 text-center rounded-lg relative overflow-hidden">
      <h2 className="text-white text-3xl font-bold">
        {t(
          user?.accountType === AccountTypeEnum.one_person_company
            ? 'yearly_review.welcome_banner_text_corporate'
            : 'yearly_review.welcome_banner_text',
          { year },
        )}
      </h2>

      <Snowfall
        snowflakeCount={120}
        radius={[0.7, 0.8]}
        speed={[0, 0.3]}
        wind={[0, 0.3]}
      />

      <ChristmasIcon className="w-24 h-24 absolute -bottom-5 right-5 stroke-white fill-white" />
    </div>
  );
};

export default WelcomeBanner;
