import { get } from 'lodash';
import { type FC } from 'react';
import type { CheckComponentProps } from 'types/taxes.types';
import CheckRevenuesList from '../CheckRevenuesList';
import CheckUITemplate from '../CheckUITemplate';

const RevenueFranchiseeRegime: FC<CheckComponentProps> = (props) => {
  const { checkData } = props;
  return (
    <CheckUITemplate
      {...props}
      renderContent={() => (
        <div className="my-4">
          <CheckRevenuesList
            {...{
              list: get(checkData, 'data'),
              // extraCols: [{ head: 'VAT Rate', key: 'VATRate' }],
              ...props,
            }}
          />
        </div>
      )}
    />
  );
};

export default RevenueFranchiseeRegime;
