import {
  checkIfRevenueNumberExists,
  getNextRevenueNumber,
} from 'api/v2/revenues';
import { Button } from 'components/atoms/Button';
import FreeTextInput from 'components/atoms/Form/FreeTextInput';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useId, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

const today = dayjs().format('YYYY-MM-DD');

const RevenueNumber: FC<any> = ({
  value,
  onChange,
  onValidNumber,
  onInvalidNumber,
  type,
  date,
  onNextNumber,
  render,
  tracing,
  disabled,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const initValue = useRef(value);
  const modifiedByUser = useRef(false);

  const docDate = date ? dayjs(date).format('YYYY-MM-DD') : today;

  const nextNumberQuery = useQuery(
    [id, dayjs(docDate).year()],
    () => getNextRevenueNumber({ type, date: docDate }),
    {
      cacheTime: 0,
      enabled: initValue.current == null && !modifiedByUser.current && !!type,
      onSuccess: (value) => {
        if (!value) return;
        if (onNextNumber) onNextNumber(value);
        else onChange(value);
      },
    },
  );

  const validateNumber = useMutation(
    (revenueNumber: string) =>
      checkIfRevenueNumberExists({ type, revenueNumber }).catch(() => false),
    {
      onSuccess: (exists) => {
        if (exists) {
          onInvalidNumber();
        } else {
          onValidNumber();
        }
      },
    },
  );

  const validate = useCallback(
    debounce((value: string) => validateNumber.mutate(value), 500),
    [],
  );
  const handleChange = (
    e: ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const val = e.target.value;
    onChange(val);
    modifiedByUser.current = true;
    if (val && val !== nextNumberQuery.data) validate(val);
  };

  const errorContent = nextNumberQuery.isError && !value && (
    <div className="flex flex-wrap items-center gap-2">
      <span className="text-sm text-primary-400">
        {t('backend.error.something_went_wrong')}.
      </span>
      <Button
        structure="text"
        color="primary"
        onClick={() => nextNumberQuery.refetch()}
      >
        <span className="text-sm">{t('retry')}</span>
      </Button>
    </div>
  );

  if (render) {
    return (
      <div>
        {render({
          value,
          validate,
          onChange: handleChange,
          loading: nextNumberQuery.isLoading || validateNumber.isLoading,
          disabled,
        })}
        {/* how should we deal with errors in render case ? ask [Yasser] */}
      </div>
    );
  }
  return (
    <div>
      <FreeTextInput
        value={value}
        loading={nextNumberQuery.isLoading || validateNumber.isLoading}
        onChange={handleChange}
        tracing={tracing}
        disabled={disabled}
      />
      {errorContent}
    </div>
  );
};

export default RevenueNumber;
