import useExpenseUploader from 'components/organisms/HomeExpenseUploader/useExpenseUploader';
import useVATNumberFormModal from 'components/organisms/VATNumberForm/useVATNumberFormModal';
import ExpenseFormSlideOver, {
  type ExpenseInput,
} from 'components/pages/Expenses/Form';
import OtherRevenueForm, {
  type InvoiceInput,
} from 'components/pages/Revenues/OtherRevenueForm';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useCreateBankConnection from 'hooks/Bank/useCreateBankConnection';
import useGetConnectors from 'hooks/Bank/useGetConnectors';
import useShouldSeeStarterHomePage from 'hooks/Growth/useShouldSeeStarterHomePage';
import useFeatures from 'hooks/useFeatures';
import type { FormSlideOverManagerApi } from 'hooks/useFormSlideOverManager';
import useInvalidateResources from 'hooks/useInvalidateResources';
import _get from 'lodash/get';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  type FC,
  type ReactNode,
} from 'react';
import { useLocation } from 'react-router';
import type {
  HomeUtils,
  StarterTodoItemData,
  StarterTodoItemsList,
} from 'types/home.types';
import type { Connector } from 'types/transactions.types';
import { AvailableCountries } from 'types/users.types';
import { getTracingEvents } from 'utils/amplitude';
import { WEBAPP_GLOBAL_VISITEDPAGE } from 'utils/amplitude/events/global.amplitude';
import {
  WEBAPP_HOMEPAGE_TODO_MARKEDITEMASDONE,
  WEBAPP_HOMEPAGE_TODO_MARKEDITEMASUNDONE,
} from 'utils/amplitude/events/home.amplitude';

const tracingEvents = getTracingEvents({
  onRouteChange: {
    name: WEBAPP_GLOBAL_VISITEDPAGE,
    data: (data: { url: string; version?: string }) => data,
  },
  onMarkTodoItemAsDone: {
    name: WEBAPP_HOMEPAGE_TODO_MARKEDITEMASDONE,
    data: (data: { name: string }) => data,
  },
  onMarkTodoItemAsUndone: {
    name: WEBAPP_HOMEPAGE_TODO_MARKEDITEMASUNDONE,
    data: (data: { name: string }) => data,
  },
});

export enum HOME_PAGE_STATE {
  SIMULATE_REVENUE_KEY = 'simulatedRevenue',
  CONNECT_BANK_ACCOUNT_KEY = 'connectedBankAccount',
  CROSSBANK_KEY = 'registeredWithCrossBank',
  SOCIAL_SECURITY_KEY = 'joinedSocialSecurityFund',
  PAST_90_DAYS_EXPENSES_KEY = 'uploadedPast90DaysExpenses',
  BUSINESS_COUNTER_BILL_KEY = 'uploadedBusinessCounterBill',
  GEWRBEAMT_KEY = 'registeredWithGewerbeamt',
  FRAGEBOGEN_KEY = 'registeredAsFragebogen',
  ALL_BUSINESS_EXPENSES_KEY = 'uploadedAllBusinessExpenses',
  ENTER_VAT_NUMBER_KEY = 'enteredVATNumber',
  DISMISSED_GET_HELP_WIDGET = 'dismissedGetHelpWidget',
  DISMISSED_MUST_READ_WIDGET = 'dismissedMustReadWidget',
  CLAIM_STARTER_PACK_KEY = 'claimedStarterPack',
  CLAIM_STARTER_DISCOUNT = 'claimedStarterDiscount',
}

export const HomePageContext = createContext<
  HomeUtils & {
    state: Record<string, any>;
    starterTodoList: StarterTodoItemsList;
    isTodoDone: (key: string) => boolean;
  }
>({} as any);

const order = (_todos: StarterTodoItemsList) =>
  _todos
    .filter((todo) => !todo.isHidden)
    .filter((todo) => !todo.isDone)
    .concat(_todos.filter((todo) => todo.isHidden))
    .concat(_todos.filter((todo) => todo.isDone));

const HomeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const shouldSeeStarterHomeWidgets = useShouldSeeStarterHomePage();

  const [state, dispatch] = useReducer(
    (prev: Record<string, any>, next: Record<string, any>) => ({
      ...prev,
      ...next,
    }),
    Object.keys(HOME_PAGE_STATE).reduce((all, key) => {
      const keyName = _get(HOME_PAGE_STATE, [key]);

      return {
        ...all,
        [keyName]: localStorage.getItem(keyName) === 'true',
      };
    }, {}),
  );

  const { VATNumberModalContent, openVATNumberForm } = useVATNumberFormModal();

  const { expenseUploaderModalContent, openExpenseUploader } =
    useExpenseUploader();

  const { data: user } = useGetCurrentCustomerData();

  const getStarterTodoList = useFeatures('getStarterTodoList');

  const { mutate: createBankConnection } = useCreateBankConnection({
    numberOfConnectors: 0,
  });

  const connectorsQuery = useGetConnectors();

  const connectors = connectorsQuery.data as Connector[];

  const toggleStateByKey = useCallback(
    (key: string) => {
      // If the current state is already marked as "done", we log an Amplitude event to indicate that
      // the todo item is about to be marked "undone", and vice-versa
      if (state[key]) {
        tracingEvents.onMarkTodoItemAsUndone({
          name: key,
        });
      } else {
        tracingEvents.onMarkTodoItemAsDone({
          name: key,
        });
      }

      dispatch({ [key]: !state[key] });

      localStorage.setItem(key, String(!state[key]));
    },
    [state],
  );

  const isRegisteredThroughRASO =
    localStorage.getItem('startedAsRASORegister') === 'true';

  const invalidateResources = useInvalidateResources();

  const otherRevenueFormSlideOverRef =
    useRef<FormSlideOverManagerApi<InvoiceInput>>();

  const expenseFormSlideOverRef =
    useRef<FormSlideOverManagerApi<ExpenseInput>>();

  const openExpenseDetails = (expense: ExpenseInput) =>
    expenseFormSlideOverRef.current?.open(expense);

  const openRevenueDetails = (revenue: InvoiceInput) =>
    otherRevenueFormSlideOverRef.current?.open(revenue);

  const utils = useMemo(
    () => ({
      openVATNumberForm,
      toggleStateByKey,
      openExpenseUploader,
      openExpenseDetails,
      openRevenueDetails,
      createBankConnection,
      connectors,
    }),
    [
      openExpenseUploader,
      openVATNumberForm,
      toggleStateByKey,
      createBankConnection,
      connectors,
    ],
  );

  const starterTodoList = useMemo(
    () =>
      order(
        getStarterTodoList(
          state,
          {
            ...utils,
            isRegisteredThroughRASO,
          },
          user,
        ).filter((todo: StarterTodoItemData) => !todo.isNotEligible),
      ),
    [getStarterTodoList, state, user, utils, isRegisteredThroughRASO],
  );

  const isTodoDone = (todoKey: string): boolean =>
    !!starterTodoList?.find((todo) => todo?.key === todoKey)?.isDone;

  const { pathname, search } = useLocation();

  const isMainHomeDisplay =
    !shouldSeeStarterHomeWidgets ||
    ((user?.country === AvailableCountries.germany ||
      isTodoDone(HOME_PAGE_STATE.SIMULATE_REVENUE_KEY)) &&
      isTodoDone(HOME_PAGE_STATE.ENTER_VAT_NUMBER_KEY) &&
      state[HOME_PAGE_STATE.DISMISSED_GET_HELP_WIDGET] &&
      state[HOME_PAGE_STATE.DISMISSED_MUST_READ_WIDGET]);

  useEffect(() => {
    return tracingEvents.onRouteChange({
      url: pathname + search,
      version: isMainHomeDisplay ? 'main' : 'starter',
    });
  }, [isMainHomeDisplay, pathname, search]);

  return (
    <HomePageContext.Provider
      value={{
        state,
        starterTodoList,
        isTodoDone,
        ...utils,
      }}
    >
      <OtherRevenueForm
        apiRef={otherRevenueFormSlideOverRef}
        afterSubmit={(_, revenueBeforeSubmit) =>
          console.log(revenueBeforeSubmit._matchedTransactions)
        }
        disabledFields={['transactions']}
        enableNavigationBlockingIfDirty={false}
      />

      <ExpenseFormSlideOver
        apiRef={expenseFormSlideOverRef}
        afterSubmit={() => invalidateResources('globalExpense')}
        disabledFields={['transactions']}
        enableNavigationBlockingIfDirty={false}
      />

      {VATNumberModalContent}

      {expenseUploaderModalContent}

      {children}
    </HomePageContext.Provider>
  );
};

export default HomeProvider;
