import classNames from 'classnames';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Calendar: FC<{
  month: number;
  year: number;
  range: [Date, Date] | null;
  setRange: (range: [Date, Date] | null) => void;
  onDayClick?: (day: any) => void;
  rangeClassNames?: string;
  preselectedDate?: Date[];
}> = ({
  month,
  year,
  range,
  setRange,
  rangeClassNames,
  onDayClick,
  preselectedDate,
}) => {
  const _preselectedDate = preselectedDate?.map((d) =>
    dayjs(d).format('YYYY-MM-DD'),
  );

  const today = useMemo(() => {
    const today = new Date();
    today.setHours(12, 0, 0, 0);

    return today;
  }, []);

  const dates = useMemo(() => {
    const firstDate = new Date(year, month - 1, 1, 12);
    firstDate.setDate(1 - firstDate.getDay());

    const dates = new Array(7 * 6).fill(null).map((_, index) => {
      const date = new Date(firstDate);
      date.setDate(date.getDate() + index);

      return date;
    });

    return dates;
  }, [month, year]);

  const [hoveringIndex, setHoveringIndex] = useState<number | null>(null);
  const [selected, setSelected] = useState<Date | null>(null);

  const uiState = useMemo(() => {
    const selectedTimes = selected
      ? [dateToDateNumber(selected)]
      : range?.map((date) => dateToDateNumber(date)) ?? [];

    const [trailStart, trailEnd] = selected
      ? hoveringIndex != null
        ? [
            dateToDateNumber(selected),
            dateToDateNumber(dates[hoveringIndex]),
          ].sort()
        : [0, 0]
      : range
      ? [dateToDateNumber(range[0]), dateToDateNumber(range[1])]
      : [0, 0];

    return dates.map((date, index) => {
      const time = dateToDateNumber(date);

      return {
        hovering: hoveringIndex == index,
        selected: selectedTimes.includes(time),
        startTrail: time > trailStart && time <= trailEnd,
        endTrail: time >= trailStart && time < trailEnd,
      };
    });
  }, [dates, hoveringIndex, range, selected]);

  const onClick = useCallback(
    (date: Date) => {
      setSelected((selected) => {
        if (selected) {
          setRange(
            dateToDateNumber(selected) < dateToDateNumber(date)
              ? [selected, date]
              : [date, selected],
          );

          return null;
        }

        setRange(null);

        return date;
      });
    },
    [setRange],
  );

  const clearHovering = useCallback(() => setHoveringIndex(null), []);

  return (
    <div className="grid grid-cols-7 px-2.5">
      <Weekday index={0} />
      <Weekday index={1} />
      <Weekday index={2} />
      <Weekday index={3} />
      <Weekday index={4} />
      <Weekday index={5} />
      <Weekday index={6} />

      {dates.map((date, index) => (
        <MonthDay
          key={index}
          index={index}
          date={date}
          rangeClassNames={rangeClassNames}
          today={today}
          month={month}
          year={year}
          onClick={onDayClick ? onDayClick : onClick}
          onMouseOver={() => setHoveringIndex(index)}
          onMouseLeave={clearHovering}
          select={_preselectedDate}
          {...uiState[index]}
        />
      ))}
    </div>
  );
};

const Weekday: FC<{ index: number }> = ({ index }) => {
  const { i18n } = useTranslation();

  const name = useMemo(() => {
    const date = new Date(1970, 1, index + 1);
    const f = new Intl.DateTimeFormat(i18n.language, { weekday: 'narrow' });

    return f.format(date);
  }, [i18n.language, index]);

  return (
    <div className="text-xs text-primary-400 font-semibold h-12 py-4 ml-auto mr-auto">
      {name}
    </div>
  );
};

const MonthDay: FC<{
  index: number;
  date: Date;
  today: Date;
  month: number;
  year: number;
  onClick: (date: Date) => void;
  onMouseOver: (date: Date) => void;
  onMouseLeave: () => void;
  hovering: boolean;
  selected: boolean;
  startTrail: boolean;
  endTrail: boolean;
  rangeClassNames?: string;
  select?: string[];
}> = memo(function MonthDay({
  index,
  date,
  today,
  month,
  onClick,
  onMouseOver,
  onMouseLeave,
  hovering,
  selected,
  startTrail,
  endTrail,
  rangeClassNames,
  select,
}) {
  const isPreselectedDate = select?.includes(dayjs(date).format('YYYY-MM-DD'));

  return (
    <div
      className="aspect-square cursor-pointer relative"
      onClick={() => onClick(date)}
      onMouseOver={() => onMouseOver(date)}
      onMouseLeave={onMouseLeave}
    >
      {(startTrail || endTrail) && (
        <div
          className={classNames(
            'absolute h-full bg-taxes-500 z-10',
            startTrail ? (index % 7 == 0 ? '-left-2.5' : 'left-0') : 'left-1/2',
            endTrail
              ? index % 7 == 6
                ? '-right-2.5'
                : 'right-0'
              : 'right-1/2',
            rangeClassNames,
          )}
        />
      )}
      <div
        className={classNames(
          'w-full h-full rounded-full flex items-center justify-center text-sm absolute z-20 text-primary',
          {
            'bg-taxes-300': hovering && !selected,
            'bg-taxes !text-white font-semibold': selected,
            'bg-taxes-900': hovering && selected,

            '!text-primary-300': date.getMonth() != month - 1,
            'border border-taxes !text-taxes font-semibold':
              !selected && dateToDateNumber(date) == dateToDateNumber(today),
            'rounded-full !bg-taxes !left-0 !right-0 !text-white':
              isPreselectedDate,
          },
        )}
      >
        <div>{date.getDate()}</div>
      </div>
    </div>
  );
});

export const dateToDateNumber = (date: Date): number => {
  let dateNumber = 0;
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  dateNumber = day;
  dateNumber += month * 100;
  dateNumber += year * 10000;

  return dateNumber;
};

export default Calendar;
