import type { FC } from 'react';
import useIntercomStore from 'store/intercom.store';

const IntercomUnreadCount: FC = () => {
  const unreadCount = useIntercomStore(({ unreadCount }) => unreadCount);

  if (!unreadCount || unreadCount < 0) return null;
  return (
    <div className="h-5 w-5 bg-red-500 ring-2 ring-white text-white rounded-md text-xs flex justify-center items-center font-bold">
      {unreadCount >= 100 ? '99+' : unreadCount}
    </div>
  );
};

export default IntercomUnreadCount;
