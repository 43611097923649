/// list ////
export const WEBAPP_REVENUESLIST_CLICKEDCREATEINVOICE =
  'WEBAPP_REVENUESLIST_CLICKEDCREATEINVOICE';
export const WEBAPP_REVENUESLIST_CLICKEDCREATECREDITNOTE =
  'WEBAPP_REVENUESLIST_CLICKEDCREATECREDITNOTE';
export const WEBAPP_REVENUESLIST_CLICKEDIMPORTINVOICE =
  'WEBAPP_REVENUESLIST_CLICKEDIMPORTINVOICE';
export const WEBAPP_REVENUESLIST_CLICKEDIMPORTCREDITNOTE =
  'WEBAPP_REVENUESLIST_CLICKEDIMPORTCREDITNOTE';
export const WEBAPP_REVENUESLIST_CLICKEDADDANOTHERREVENUE =
  'WEBAPP_REVENUESLIST_CLICKEDADDANOTHERREVENUE';
export const WEBAPP_REVENUESLIST_CLICKEDCREATEQUOTE =
  'WEBAPP_REVENUESLIST_CLICKEDCREATEQUOTE';

export const WEBAPP_EMPTYREVENUELIST_CLICKEDCREATEINVOICE =
  'WEBAPP_EMPTYREVENUELIST_CLICKEDCREATEINVOICE';
export const WEBAPP_EMPTYREVENUELIST_CLICKEDCREATECREDITNOTE =
  'WEBAPP_EMPTYREVENUELIST_CLICKEDCREATECREDITNOTE';
export const WEBAPP_EMPTYREVENUELIST_CLICKEDCREATEQUOTE =
  'WEBAPP_EMPTYREVENUELIST_CLICKEDCREATEQUOTE';
export const WEBAPP_EMPTYREVENUELIST_CLICKEDCREATEOTHERREVENUE =
  'WEBAPP_EMPTYREVENUELIST_CLICKEDCREATEOTHERREVENUE';
export const WEBAPP_EMPTYREVENUELIST_CLICKEDIMPORTINVOICE =
  'WEBAPP_EMPTYREVENUELIST_CLICKEDIMPORTINVOICE';
export const WEBAPP_EMPTYREVENUELIST_CLICKEDIMPORTCREDITNOTE =
  'WEBAPP_EMPTYREVENUELIST_CLICKEDIMPORTCREDITNOTE';

export const WEBAPP_REVENUESLIST_SORTEDLIST = 'WEBAPP_REVENUESLIST_SORTEDLIST';
export const WEB_REVENUESLIST_FILTEREDLIST = 'WEB_REVENUESLIST_FILTEREDLIST';
export const WEBAPP_REVENUESLIST_CLICKEDREVENUE =
  'WEBAPP_REVENUESLIST_CLICKEDREVENUE';
export const WEBAPP_REVENUESLIST_BULKSELECT = 'WEBAPP_REVENUESLIST_BULKSELECT';
export const WEBAPP_REVENUESLIST_CHANGETABLEPAGE =
  'WEBAPP_REVENUESLIST_CHANGETABLEPAGE';
export const WEBAPP_REVENUESLIST_CLICKEDFILTERS =
  'WEBAPP_REVENUESLIST_CLICKEDFILTERS';
export const WEBAPP_REVENUESLIST_DROPZONEUPLOADING =
  'WEBAPP_REVENUESLIST_DROPZONEUPLOADING';
export const WEBAPP_REVENUESLIST_BULKDELETE = 'WEBAPP_REVENUESLIST_BULKDELETE';

export const WEBAPP_REVENUESLIST_CLICKEDQUICKACTIONMARKEDASSENT =
  'WEBAPP_REVENUESLIST_CLICKEDQUICKACTIONMARKEDASSENT';

export const WEBAPP_REVENUESLIST_CLICKEDQUICKACTIONSENDINVOICE =
  'WEBAPP_REVENUESLIST_CLICKEDQUICKACTIONSENDINVOICE';

export const WEBAPP_REVENUESLIST_CLICKEDQUICKACTIONSENDREMINDER =
  'WEBAPP_REVENUESLIST_CLICKEDQUICKACTIONSENDREMINDER';

export const WEBAPP_REVENUESLIST_CLICKEDQUICKACTIONPAID =
  'WEBAPP_REVENUESLIST_CLICKEDQUICKACTIONPAID';
export const WEBAPP_REVENUESLIST_CLICKEDQUICKACTIONNOTSENT =
  'WEBAPP_REVENUESLIST_CLICKEDQUICKACTIONNOTSENT';

//// form ////

export const WEBAPP_REVENUE_UPDATEDSTATUS = 'WEBAPP_REVENUE_UPDATEDSTATUS';
export const WEBAPP_REVENUE_CLICKEDCURRENCY = 'WEBAPP_REVENUE_CLICKEDCURRENCY';
export const WEBAPP_REVENUE_UPDATEDCURRENCY = 'WEBAPP_REVENUE_UPDATEDCURRENCY';

export const WEBAPP_REVENUE_CLICKEDVEHICLE = 'WEBAPP_REVENUE_CLICKEDVEHICLE';
export const WEBAPP_REVENUE_UPDATEDVEHICLE = 'WEBAPP_REVENUE_UPDATEDVEHICLE';

export const WEBAPP_REVENUE_UPDATEDCARUSEDFOROFFICETOGGLE =
  'WEBAPP_REVENUE_UPDATEDCARUSEDFOROFFICETOGGLE';

export const WEBAPP_REVENUE_CLICKEDDISTANCEHOMETOOFFICE =
  'WEBAPP_REVENUE_CLICKEDDISTANCEHOMEOFFICE';

export const WEBAPP_REVENUE_CLICKEDPAYMENTAMOUNT =
  'WEBAPP_REVENUE_CLICKEDPAYMENTAMOUNT';

//  pdf
export const WEBAPP_REVENUE_CLICKEDREVENUENUMBER =
  'WEBAPP_REVENUE_CLICKEDREVENUENUMBER';

export const WEBAPP_REVENUE_CLICKEDINVOICEDATE =
  'WEBAPP_REVENUE_CLICKEDINVOICEDATE';
export const WEBAPP_REVENUE_UPDATEDINVOICEDATE =
  'WEBAPP_REVENUE_UPDATEDINVOICEDATE';

export const WEBAPP_REVENUE_CLICKEDUSAGESTART =
  'WEBAPP_REVENUE_CLICKEDUSAGESTART';
export const WEBAPP_REVENUE_UPDATEDUSAGESTART =
  'WEBAPP_REVENUE_UPDATEDUSAGESTART';

export const WEBAPP_REVENUE_CLICKEDUSAGEEND = 'WEBAPP_REVENUE_CLICKEDUSAGEEND';
export const WEBAPP_REVENUE_UPDATEDUSAGEEND = 'WEBAPP_REVENUE_UPDATEDUSAGEEND';

export const WEBAPP_REVENUE_UPDATEDDUEDATE = 'WEBAPP_REVENUE_UPDATEDDUEDATE';
export const WEBAPP_REVENUE_CLICKEDDUEDATE = 'WEBAPP_REVENUE_CLICKEDDUEDATE';

export const WEBAPP_REVENUE_CLICKEDDELIVERYDATE =
  'WEBAPP_REVENUE_CLICKEDDELIVERYDATE';
export const WEBAPP_REVENUE_UPDATEDDELIVERYDATE =
  'WEBAPP_REVENUE_UPDATEDDELIVERYDATE';

export const WEBAPP_REVENUE_UPDATEDPAYMENTDATE =
  'WEBAPP_REVENUE_UPDATEDPAYMENTDATE';
export const WEBAPP_REVENUE_CLICKEDPAYMENTDATE =
  'WEBAPP_REVENUE_CLICKEDPAYMENTDATE';

export const WEBAPP_REVENUE_CLICKEDCLIENT = 'WEBAPP_REVENUE_CLICKEDCLIENT';
export const WEBAPP_REVENUE_UPDATEDCLIENT = 'WEBAPP_REVENUE_UPDATEDCLIENT';

export const WEBAPP_REVENUE_ADDCLIENT = 'WEBAPP_REVENUE_ADDCLIENT';

// ---

// item
export const WEBAPP_REVENUE_CLICKEDITEMNAME = 'WEBAPP_REVENUE_CLICKEDITEMNAME';

export const WEBAPP_REVENUE_CLICKEDQUANTITY = 'WEBAPP_REVENUE_CLICKEDQUANTITY';

export const WEBAPP_REVENUE_CLICKEDCATEGORY = 'WEBAPP_REVENUE_CLICKEDCATEGORY';
export const WEBAPP_REVENUE_UPDATEDCATEGORY = 'WEBAPP_REVENUE_UPDATEDCATEGORY';

export const WEBAPP_REVENUE_CLICKEDAMOUNT = 'WEBAPP_REVENUE_CLICKEDAMOUNT';

export const WEBAPP_REVENUE_CLICKEDVATRATE = 'WEBAPP_REVENUE_CLICKEDVATRATE';
export const WEBAPP_REVENUE_UPDATEDVATRATE = 'WEBAPP_REVENUE_UPDATEDVATRATE';

export const WEBAPP_REVENUE_CLICKEDWHYZEROVAT =
  'WEBAPP_REVENUE_CLICKEDWHYZEROVAT';
export const WEBAPP_REVENUE_UPDATEDWHYZEROVAT =
  'WEBAPP_REVENUE_UPDATEDWHYZEROVAT';

// -----

export const WEBAPP_REVENUE_UPDATEDLINKPAYMENT =
  'WEBAPP_REVENUE_UPDATEDLINKPAYMENT';
export const WEBAPP_REVENUE_CLICKEDLINKPAYMENT =
  'WEBAPP_REVENUE_CLICKEDLINKPAYMENT';

export const WEBAPP_REVENUE_CLICKEDPERIOD = 'WEBAPP_REVENUE_CLICKEDPERIOD';
export const WEBAPP_REVENUE_UPDATEDPERIOD = 'WEBAPP_REVENUE_UPDATEDPERIOD';

export const WEBAPP_REVENUE_CLICKEDVATTYPE = 'WEBAPP_REVENUE_CLICKEDVATTYPE';
export const WEBAPP_REVENUE_UPDATEDVATTYPE = 'WEBAPP_REVENUE_UPDATEDVATTYPE';

export const WEBAPP_REVENUE_CLICKEDSAVE = 'WEBAPP_REVENUE_CLICKEDSAVE';

export const WEBAPP_REVENUE_CLICKEDDOWNLOAD = 'WEBAPP_REVENUE_CLICKEDDOWNLOAD';
export const WEBAPP_REVENUE_CLICKEDREPLACE = 'WEBAPP_REVENUE_CLICKEDREPLACE';
export const WEBAPP_REVENUE_CLICKEDDELETEATTACHMENT =
  'WEBAPP_REVENUE_CLICKEDDELETEATTACHMENT';
export const WEBAPP_REVENUE_CLICKEDDELETE = 'WEBAPP_REVENUE_CLICKEDDELETE';

export const WEBAPP_REVENUE_CLICKEDEDIT = 'WEBAPP_REVENUE_CLICKEDEDIT';
export const WEBAPP_REVENUE_CLICKEDDUPLICATE =
  'WEBAPP_REVENUE_CLICKEDDUPLICATE';

export const WEBAPP_REVENUE_CLICKEDVIEWLOGS = 'WEBAPP_REVENUE_CLICKEDVIEWLOGS';
export const WEBAPP_REVENUE_MAKERECURRINGQUICKACTION =
  'WEBAPP_REVENUE_MAKERECURRINGQUICKACTION';

export const WEBAPP_REVENUE_MAKERECURRINGFORMPANEL =
  'WEBAPP_REVENUE_MAKERECURRINGRIGHTPANEL';

export const WEBAPP_REVENUE_SUGGESTRECURRINGPANNER =
  'WEBAPP_REVENUE_SUGGESTRECURRINGPANNER';

export const WEBAPP_REVENUE_MAKERECURRINGFROMPANNER =
  'WEBAPP_REVENUE_MAKERECURRINGFROMPANNER';
