import { Helper } from 'components/molecules/Helper';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ClientTypeEnum, ClientVATStatusEnum } from 'types/clients.types';
import { UserCountryEnum } from 'types/users.types';
import { cleanEmptyValues } from 'utils/helpers';

const VATParagraphs = [
  'client.field.vat_number.help_article.desc_1',
  'client.field.vat_number.help_article.desc_2',
  'client.field.vat_number.help_article.desc_3',
  'client.field.vat_number.help_article.desc_4',
];

export const VATNumberHelper = () => {
  const { t } = useTranslation();
  const customerCountry = useCustomerCountry();
  return (
    <Helper>
      <Helper.Title>
        {t(
          `client.field.vat_number.help_article.title.${
            customerCountry === 'be' ? customerCountry : 'eu'
          }`,
        )}
      </Helper.Title>
      {VATParagraphs.map((text, i) => (
        <Helper.Paragraph key={i}>{t(text)}</Helper.Paragraph>
      ))}
    </Helper>
  );
};

const ReverseChargeParagraphs = [
  'client.field.reverse_charge.help_article.description_1',
  'client.field.reverse_charge.help_article.description_2',
  'client.field.reverse_charge.help_article.description_3',
];

export const IsReverseChargeHelper = () => {
  const { t } = useTranslation();
  return (
    <Helper>
      <Helper.Title>
        {t('client.field.reverse_charge.help_article.explanation')}
      </Helper.Title>
      {ReverseChargeParagraphs.map((text, i) => (
        <Helper.Paragraph key={i}>{t(text)}</Helper.Paragraph>
      ))}
    </Helper>
  );
};

const defaultValueKeys = [
  'VATNumber',
  'VATStatus',
  'address',
  'name',
  'type',
  'email',
  '_id',
  'steuernummer',
  'phoneNumber',
  'notes',
  'contactName',
];

const addressKeys = ['country', 'city', 'street', 'zip'];

export const getDefaultValues = (values: any, customerCountry: string) => {
  const isEdit = !!values?._id;

  return cleanEmptyValues({
    ...pick(values, defaultValueKeys),

    ...(!isEdit && customerCountry === UserCountryEnum.de
      ? {
          type: ClientTypeEnum.business,
          VATStatus: ClientVATStatusEnum.subjectToVAT,
        }
      : null),

    address: {
      ...pick(values?.address, addressKeys),
      country: values?.address?.country?.toLowerCase() || customerCountry,
    },
  });
};
