import { getExpenses } from 'api/v2/expenses';
import { Sort } from 'components/atoms/Table/table.types';
import { useExpensesFiltersStore } from 'hooks/Expenses/useExpensesFilters';
import type { ExtraUseTableOptions } from 'hooks/tableQuery/tableQuery.types';
import useTableQuery from 'hooks/tableQuery/useTableQuery';
import { useEffect } from 'react';
import tableState from 'store/tableState/tableState.store';
import { ExpensesCacheKeys } from 'types/cacheKeys.types';
import { isExpert } from 'utils/constants';

const { filtersData } = useExpensesFiltersStore.getState();
const useTableState = tableState({
  filtersData,
  tableSorter: {
    cellId: isExpert ? 'accountantReview' : 'isValidated',
    direction: Sort.asc,
  },
});

const useAllExpenses = (options?: ExtraUseTableOptions) => {
  const tableState = useTableState();
  useEffect(() => {
    return useExpensesFiltersStore.subscribe((state, prev) => {
      if (state.filtersData === prev.filtersData) return;
      tableState.controllers.setFiltersData(state.filtersData);
    });
  }, []);

  return useTableQuery({
    tableState,
    cacheKey: ExpensesCacheKeys.AllExpenses,
    onFetch: (params) =>
      getExpenses({
        ...params,
        expand: ['taxLock'],
        perPage: 25,
      }),
    ...options,
  });
};

export default useAllExpenses;
