import VariantSelection from 'components/molecules/Fields/VariantSelection';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { FieldProps } from './commonFieldProps.types';

const IsInvoice: FC<FieldProps> = ({ onChange, value, disabled, ...rest }) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2">
      <VariantSelection
        value={value}
        onChange={onChange}
        isDisabled={disabled}
        options={[
          { label: t('expense.receipt'), value: false },
          { label: t('expense.invoice'), value: true },
        ]}
        {...rest}
      />
    </div>
  );
};

export default IsInvoice;
