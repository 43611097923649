import { getCategorisedAssets } from 'api/v1/Assets';
import emptyStateDE from 'assets/svg/amortization_empty_DE.svg';
import emptyStateEN from 'assets/svg/amortization_empty_EN.svg';
import emptyStateFR from 'assets/svg/amortization_empty_FR.svg';
import emptyStateNL from 'assets/svg/amortization_empty_NL.svg';
import { Badge } from 'components/atoms/Badge';
import ContainerWithShadow from 'components/atoms/ContainerWithShadow';
import { Table } from 'components/atoms/Table';
import { Title } from 'components/atoms/Title';
import { SizesEnum } from 'components/atoms/Title/Title.enum';
import CustomLoader from 'components/molecules/CustomLoader.component';
import SimpleError from 'components/molecules/SimpleError.component';
import ExpenseFormSlideOver from 'components/pages/Expenses/Form';
import type { ExpenseInput } from 'components/pages/Expenses/Form/FormSlideOver';
import type { InvoiceInput } from 'components/pages/Revenues/OtherRevenueForm';
import OtherRevenueFormSlideOver from 'components/pages/Revenues/OtherRevenueForm';
import { worldThemeContext } from 'hooks/shared/useWorldTheme';
import type { FormSlideOverManagerApi } from 'hooks/useFormSlideOverManager';
import useInvalidateResources from 'hooks/useInvalidateResources';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AmortizationCacheKeys } from 'types/cacheKeys.types';
import type { AvailableLanguages } from 'types/users.types';
import { isExpert } from 'utils/constants';
import AssetCols from './AssetCols.component';
import { SumRow } from './AssetRow.component';
import {
  AssetLostRow,
  AssetSoldRow,
  AssetUnderAmortizationRow,
} from './helperComponents';

const emptyState: Record<AvailableLanguages, string> = {
  en: emptyStateEN,
  fr: emptyStateFR,
  nl: emptyStateNL,
  de: emptyStateDE,
};

const Amortization = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const invalidateCache = useInvalidateResources();

  const { data, isLoading, isError, refetch } = useQuery(
    AmortizationCacheKeys.CategorisedAssets,
    () => getCategorisedAssets(),
    {
      staleTime: 0,
    },
  );

  const expenseFormSlideOverRef =
    useRef<FormSlideOverManagerApi<ExpenseInput>>();
  const otherRevenueFormSlideOverRef =
    useRef<FormSlideOverManagerApi<InvoiceInput>>();

  const onViewExpense = (id: string) =>
    expenseFormSlideOverRef.current?.open(id);
  const onViewInvoice = (id: string) =>
    otherRevenueFormSlideOverRef.current?.open(id);

  const afterChangeExpense = () => {
    invalidateCache('assetExpenses');
    refetch();
  };
  const afterChangeInvoice = () => {
    invalidateCache('assetRevenues');
    refetch();
  };

  return (
    <worldThemeContext.Provider value="taxes">
      <ExpenseFormSlideOver
        apiRef={expenseFormSlideOverRef}
        enableNavigationBlockingIfDirty={false}
        afterSubmit={afterChangeExpense}
        afterDelete={afterChangeExpense}
      />

      <OtherRevenueFormSlideOver
        apiRef={otherRevenueFormSlideOverRef}
        enableNavigationBlockingIfDirty={false}
        afterSubmit={afterChangeInvoice}
        afterDelete={afterChangeInvoice}
      />
      <div className="flex-1 min-w-0">
        <ContainerWithShadow className="p-8 w-full relative">
          <Title type={SizesEnum.h5} className="mb-1 leading-tight">
            {t('settings.amortization.title')}
          </Title>
          <p className="text-primary-700 mb-8">
            {isExpert
              ? t('settings.amortization.description.expert')
              : t('settings.amortization.description')}
          </p>
          {(() => {
            if (isLoading) {
              return (
                <div className="flex items-center justify-center">
                  <CustomLoader className="py-12" />
                </div>
              );
            }
            if (isError) {
              return (
                <SimpleError
                  messageClassName="!text-xl"
                  onRetry={() => {
                    if (isError) refetch();
                  }}
                />
              );
            }
            if (!data || Object.values(data).flat().length === 0)
              return (
                <div className="flex flex-col items-center pt-14 pb-8">
                  <img
                    alt=""
                    height="115"
                    width="330"
                    src={emptyState[language as AvailableLanguages]}
                  />
                  <p className="text-primary-700 text-center max-w-md mt-6 leading-tight">
                    {t('settings.amortization.empty_state_text')}
                  </p>
                </div>
              );
            return (
              <>
                {data.underAmortization.length > 0 && (
                  <div className="mb-8">
                    <span className="flex mb-3">
                      <Badge size="lg" color="purple" className="inline-block">
                        {t('settings.amortization.asset_being_amortized.title')}
                      </Badge>
                    </span>
                    <Table>
                      <Table.Head>
                        <AssetCols />
                      </Table.Head>
                      <Table.Body>
                        {data.underAmortization.map((asset) => {
                          return (
                            <AssetUnderAmortizationRow
                              key={asset._id}
                              data={asset}
                              onViewExpense={onViewExpense}
                              onAssetUpdate={() => refetch()}
                            />
                          );
                        })}
                        <SumRow assets={data.underAmortization} />
                      </Table.Body>
                    </Table>
                  </div>
                )}
                {data.fullyAmortized.length > 0 && (
                  <div className="mb-8">
                    <span className="flex mb-3">
                      <Badge size="lg" color="success" className="inline-block">
                        {t('settings.amortization.asset_fully_amortized.title')}
                      </Badge>
                    </span>
                    <Table>
                      <Table.Head>
                        <AssetCols />
                      </Table.Head>
                      <Table.Body>
                        {data.fullyAmortized.map((asset) => {
                          return (
                            <AssetUnderAmortizationRow
                              key={asset._id}
                              data={asset}
                              onViewExpense={onViewExpense}
                              onAssetUpdate={() => refetch()}
                            />
                          );
                        })}
                        <SumRow assets={data.fullyAmortized} />
                      </Table.Body>
                    </Table>
                  </div>
                )}
                {data.sold.length > 0 && (
                  <div className="mb-8">
                    <span className="flex mb-3">
                      <Badge size="lg" color="gray" className="inline-block">
                        {t('settings.amortization.asset_sold.title')}
                      </Badge>
                    </span>
                    <Table>
                      <Table.Head>
                        <AssetCols shouldHideStillToAmortizeCol />
                      </Table.Head>
                      <Table.Body>
                        {data.sold.map((asset) => {
                          return (
                            <AssetSoldRow
                              key={asset._id}
                              data={asset}
                              onViewExpense={onViewExpense}
                              onViewInvoice={onViewInvoice}
                              onAssetUpdate={() => refetch()}
                            />
                          );
                        })}
                      </Table.Body>
                    </Table>
                  </div>
                )}
                {data.lost.length > 0 && (
                  <div className="mb-8">
                    <span className="flex mb-3">
                      <Badge size="lg" color="warning" className="inline-block">
                        {t('settings.amortization.asset_lost.title')}
                      </Badge>
                    </span>
                    <Table>
                      <Table.Head>
                        <AssetCols />
                      </Table.Head>
                      <Table.Body>
                        {data.lost.map((asset) => {
                          return (
                            <AssetLostRow
                              key={asset._id}
                              data={asset}
                              onViewExpense={onViewExpense}
                              onAssetUpdate={() => refetch()}
                            />
                          );
                        })}
                        <SumRow assets={data.lost} />
                      </Table.Body>
                    </Table>
                  </div>
                )}
              </>
            );
          })()}
        </ContainerWithShadow>
      </div>
    </worldThemeContext.Provider>
  );
};

export default Amortization;
