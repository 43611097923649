import beFlagImg from 'assets/images/flags/be-flag.png';
import deFlagImg from 'assets/images/flags/de-flag.png';
import intraEuFlagImg from 'assets/images/flags/intra-eu-flag.png';
import worldImg from 'assets/images/flags/world.png';
import type { ComponentProps, FC } from 'react';

const flagsMap = {
  de: deFlagImg,
  intraeu: intraEuFlagImg,
  extraeu: worldImg,
  be: beFlagImg,
  default: beFlagImg,
};

type CountryFlag = {
  country: 'be' | 'de' | 'intraeu' | 'extraeu';
} & ComponentProps<'img'>;

const CountryFlag: FC<CountryFlag> = ({ country, ...rest }) => (
  <img src={flagsMap[country] || flagsMap.default} height="21px" {...rest} />
);

export default CountryFlag;
