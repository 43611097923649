import type { Dispatch, FC, ReactNode } from 'react';
import { useState } from 'react';

type UseStateProps = {
  init?: any;
  render: (state: any, setState: Dispatch<any>) => ReactNode;
};

const UseState: FC<UseStateProps> = (props) => {
  const [state, setState] = useState(props.init);
  return <>{props.render(state, setState)}</>;
};

export default UseState;
