import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { IExpense } from 'types/expenses.types';
import type { IRevenue } from 'types/revenues.types';
import { useHighlight } from './useHighlight';

// should be used inside highlight provider child
const useHighlighTaxCheckField = (
  doc?: Partial<IExpense> | Partial<IRevenue>,
) => {
  const [searchParams] = useSearchParams();
  const highlightAPI = useHighlight();
  const issueField = searchParams.get('issue-field');
  useEffect(() => {
    if (issueField && doc) {
      const timeoutId = setTimeout(() => {
        const highlightedElement = document.querySelector(
          `[data-highlight-id="${issueField}"]`,
        );
        const viewportOffset = highlightedElement?.getBoundingClientRect();
        const top = viewportOffset?.top;
        if (top && top > 500) {
          document.getElementById('expense-right-panel')?.scrollTo({
            left: 0,
            top: (top || 200) - 200,
            behavior: 'smooth',
          });
        }
        highlightAPI.highlight(issueField);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, []);
  return issueField;
};

export default useHighlighTaxCheckField;
