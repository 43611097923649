import { useEffect, useMemo, useRef } from 'react';
import type { RefType } from 'types/global.types';

type HookParamsType = {
  execute: () => void;
};
type ReturnType = { elementRef: RefType };

// Uses the IntersectionObserver API to detect when an element in within the viewport
// which is useful for functionalities like pagination/infinite scrolling
const useExecuteWhenElementInView = ({
  execute,
}: HookParamsType): ReturnType => {
  const elementRef = useRef(null);
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => entry.isIntersecting && execute()),
    [execute],
  );

  useEffect(() => {
    observer.observe(elementRef.current as unknown as HTMLElement);

    return () => observer.disconnect();
  }, [elementRef, observer]);

  return { elementRef };
};

export default useExecuteWhenElementInView;
