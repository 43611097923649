import { createExpense } from 'api/v1/expenses';
import { getExpenses } from 'api/v2/expenses';
import { uploadFile } from 'api/v2/upload';
import dayjs from 'dayjs';

export const createExpenseDocument = (
  file: File,
  onProgress: (n: number) => void,
  options?: Record<string, any>,
) => {
  return uploadFile({ file, onProgress }).then(({ key }: any) =>
    createExpense(key.split('/').pop(), options?.isFake),
  );
};

export const hasExpenses = () =>
  getExpenses({ page: 1, perPage: 1, fields: '_id' }).then(
    ({ paging }) => paging.totalCount > 0,
  );
export const firstExpense = () =>
  getExpenses({
    page: 1,
    perPage: 1,
    sort: 'created_asc',
  });

const vehiclesPurchaseYearKey = 'vehicles-purchase-year';
export const shouldShowVehiclePurchaseYearsModal = () => {
  const lastDate = localStorage.getItem(vehiclesPurchaseYearKey);
  return (
    !lastDate ||
    !dayjs(lastDate).isValid() ||
    dayjs(lastDate).add(1, 'week').isBefore(dayjs())
  );
};
export const updateVehiclesPurchaseYearModalDate = () => {
  localStorage.setItem(vehiclesPurchaseYearKey, dayjs().toString());
};

export const clearVehiclesPurchaseYearModalDate = () => {
  localStorage.removeItem(vehiclesPurchaseYearKey);
};
