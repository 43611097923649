import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Avatar } from 'components/atoms/Avatar';
import { Button } from 'components/atoms/Button';
import type { FC } from 'react';
import type { ToastButton, ToastProps } from './Toast.types';

const SplitButtons: FC<{ splitButtons: ToastButton[] }> = ({
  splitButtons,
}) => (
  <div
    className={classNames(
      'w-20 h-full',
      'flex flex-col',
      'border-l border-l-grey-400',
      'text-primary',
    )}
  >
    {splitButtons.map(({ title, ...props }, ndx) => (
      <Button
        size="xs"
        key={ndx}
        className={classNames('flex-1', {
          'border-t border-t-grey-400': !!ndx,
        })}
        {...props}
      >
        {title}
      </Button>
    ))}
  </div>
);

const ActionButtons: FC<{ actionButtons: ToastButton[] }> = ({
  actionButtons,
}) => (
  <div className="flex flex-shrink-0 gap-2">
    {actionButtons.map(({ title, ...props }, index) => (
      <Button key={index} size="xs" {...props}>
        {title}
      </Button>
    ))}
  </div>
);

const Toast: FC<ToastProps> = ({
  hasCloseIcon,
  title,
  desc,
  icon,
  avatar,
  splitButtons,
  actionButtons,
  containerClassNames,
  iconWrapperClassNames,
  titleClassNames,
  closeIconWrapperClassNames,
  closeIconClassNames,
  actionButtonsContainerClassNames,
  onClick,
}) => {
  return (
    <div
      className={classNames(
        'text-sm',
        'flex gap-4',
        'px-4',
        'bg-white shadow-colored rounded-lg items-center',
        containerClassNames,
      )}
    >
      <div className="flex-1 flex gap-4 items-center" onClick={onClick}>
        {(avatar || icon) && (
          <div className="flex-shrink-0">
            {avatar ? (
              <div className="py-4">
                <Avatar {...avatar} />
              </div>
            ) : null}
            {icon ? (
              <div className={classNames('py-4', iconWrapperClassNames)}>
                {icon}
              </div>
            ) : null}
          </div>
        )}

        <div className="py-4 flex flex-1 flex-col">
          <span
            className={classNames(
              'font-bold text-primary leading-tight',
              titleClassNames,
            )}
          >
            {title}
          </span>
          {desc?.map?.((descItem) => (
            <span key={descItem} className="text-primary-700 leading-tight">
              {descItem}
            </span>
          ))}
        </div>
      </div>

      <div className={actionButtonsContainerClassNames}>
        {actionButtons ? <ActionButtons actionButtons={actionButtons} /> : null}
      </div>

      {(hasCloseIcon || splitButtons) && (
        <div className="flex flex-col items-end">
          {hasCloseIcon ? (
            <div className={classNames('p-2', closeIconWrapperClassNames)}>
              <XIcon
                className={classNames(
                  'w-4 h-4 text-gray-500',
                  closeIconClassNames,
                )}
              />
            </div>
          ) : null}
          {splitButtons ? <SplitButtons splitButtons={splitButtons} /> : null}
        </div>
      )}
    </div>
  );
};

export default Toast;
