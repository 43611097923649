export const WEBAPP_GLOBAL_VISITEDPAGE = 'WEBAPP_GLOBAL_VISITEDPAGE';
export const WEBAPP_GLOBAL_OPENMODAL = 'WEBAPP_GLOBAL_OPENMODAL';
export const WEBAPP_GLOBAL_WARNINGBOX = 'WEBAPP_GLOBAL_WARNINGBOX';
export const WEBAPP_GLOBAL_UTMS = 'WEBAPP_GLOBAL_UTMS';
export const WEBAPP_CATEGORYPICKER_FOCUSEDCATEGORYSEARCH =
  'WEBAPP_CATEGORYPICKER_FOCUSEDCATEGORYSEARCH';
export const WEBAPP_GLOBAL_TRUSTPILOTCLICKEDSTAR =
  'WEBAPP_GLOBAL_TRUSTPILOTCLICKEDSTAR';
export const WEBAPP_GLOBAL_TRUSTPILOTASKFORREVIEW =
  'WEBAPP_GLOBAL_TRUSTPILOTASKFORREVIEW';
export const WEBAPP_PAYWALL_CLICKEDOPENDELETEACCOUNTMODAL =
  'WEBAPP_PAYWALL_CLICKEDOPENDELETEACCOUNTMODAL';
export const WEBAPP_REVENUESIMULATOR_SELECTEDMONTHLYINVOICEVALUE =
  'WEBAPP_REVENUESIMULATOR_SELECTEDMONTHLYINVOICEVALUE';
