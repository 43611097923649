import { bulkDeleteExpenses } from 'api/v2/expenses';
import { TableDeleteBulkAction } from 'components/molecules/TableDeleteBulkAction.component';
import { get } from 'lodash';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import type { CheckComponentProps } from 'types/taxes.types';
import CheckExpensesList from '../CheckExpensesList';
import CheckUITemplate from '../CheckUITemplate';

const ExpenseRestaurantPersonal: FC<CheckComponentProps> = (props) => {
  const { checkData, refetchCheck } = props;
  const { t } = useTranslation();
  const [selectedExpenses, setSelectedExpenses] = useState<string[]>([]);
  const { mutateAsync: deleteExpenses } = useMutation((ids: string[]) =>
    bulkDeleteExpenses(ids),
  );
  return (
    <CheckUITemplate
      {...props}
      renderContent={() => (
        <div className="border mt-3 rounded-10 p-2">
          <CheckExpensesList
            {...{
              list: get(checkData, 'data', []),
              selectedExpenses,
              setSelectedExpenses: setSelectedExpenses as any,
              ...props,
            }}
          />
        </div>
      )}
      renderButtons={() => (
        <TableDeleteBulkAction
          className="ml-auto self-center"
          structure="secondary"
          selected={selectedExpenses}
          onDelete={() => {
            return deleteExpenses(selectedExpenses);
          }}
          onAfterDelete={() => refetchCheck()}
          title={t('expenses.confirm_deletes.title')}
          description={
            selectedExpenses.length > 1
              ? t('expenses.confirm_deletes', {
                  amount: selectedExpenses.length,
                })
              : t('expenses.confirm_delete')
          }
        />
      )}
    />
  );
};

export default ExpenseRestaurantPersonal;
