import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import { useMemo } from 'react';
import * as pusher from 'utils/pusher';

const usePusher = () => {
  const { data } = useGetCurrentCustomerData();
  const userId = data?._id;

  return useMemo(() => {
    if (!userId) {
      return pusher.disconnectUserChannel();
    }
    return pusher.getOrCreateChannel(userId);
  }, [userId]);
};

export default usePusher;
