enum GrowthExperimentsEnum {
  NEW_INVOICE_CREATION_SAVE_BUTTONS = 'NEW_INVOICE_CREATION_SAVE_BUTTONS',
  NEW_DOC_TO_REVIEW_EXPERIENCE = 'NEW_DOC_TO_REVIEW_EXPERIENCE',
  CONSENT_OPENAI = 'CONSENT_OPENAI',
  STARTER_HOME_PAGE = 'STARTER_HOME_PAGE',
  UPLOAD_EXPENSE_HOME_WIDGET = 'UPLOAD_EXPENSE_HOME_WIDGET',
  SMALL_BUSINESS_PLAN_25_DISCOUNT_GERMAN = 'SMALL_BUSINESS_PLAN_25DISCOUNT_GERMAN',
  AI_CATEGORY_SUGGESTION = 'AI_CATEGORY_SUGGESTION',
  TAX_CALCULATOR_IN_HOME_SCREEN = 'TAX_CALCULATOR_IN_HOME_SCREEN',

  // INTERCOM EXPERIMENTS
  INTERCOM_1 = 'INTERCOM_1',
  INTERCOM_2 = 'INTERCOM_2',
  INTERCOM_3 = 'INTERCOM_3',
  INTERCOM_4 = 'INTERCOM_4',
  INTERCOM_5 = 'INTERCOM_5',
  INTERCOM_6 = 'INTERCOM_6',
  INTERCOM_7 = 'INTERCOM_7',
  INTERCOM_8 = 'INTERCOM_8',
  INTERCOM_9 = 'INTERCOM_9',
  INTERCOM_10 = 'INTERCOM_10',
  INTERCOM_11 = 'INTERCOM_11',
  INTERCOM_12 = 'INTERCOM_12',
  INTERCOM_13 = 'INTERCOM_13',
  INTERCOM_14 = 'INTERCOM_14',
  INTERCOM_15 = 'INTERCOM_15',
  INTERCOM_16 = 'INTERCOM_16',
  INTERCOM_17 = 'INTERCOM_17',
  INTERCOM_18 = 'INTERCOM_18',
  INTERCOM_19 = 'INTERCOM_19',
  INTERCOM_20 = 'INTERCOM_20',
  INTERCOM_21 = 'INTERCOM_21',
  INTERCOM_22 = 'INTERCOM_22',
  INTERCOM_23 = 'INTERCOM_23',
  INTERCOM_24 = 'INTERCOM_24',
}

enum GrowthExperimentType {
  IN_APP = 'in-app',
  SIGNUP = 'signup',
}

enum ABTest {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

type GrowthExperimentDefinition = {
  name: GrowthExperimentsEnum;
  type: GrowthExperimentType;
  id: string;
  numOfGroups?: number;
};

export {
  GrowthExperimentsEnum,
  GrowthExperimentType,
  ABTest,
  GrowthExperimentDefinition,
};
