import type { FC } from 'react';
import { Tooltip } from '../Tooltip';
import { AvatarType } from './Avatar.enum';
import type { AvatarProps } from './Avatar.types';
import {
  avatarSizeToClassMap,
  avatarStatusToClassMap,
  customAvatarTypeMap,
} from './Avatar.types';

const Avatar: FC<AvatarProps> = ({ type, size, status, src, tooltipText }) => {
  const { roundedClass, position } = customAvatarTypeMap[type];
  const { AvatarSize, roundedSize } = avatarSizeToClassMap[size];

  return (
    <Tooltip content={tooltipText} placement="right">
      <span className="inline-block relative">
        {src ? (
          <img
            className={`${AvatarSize} ${roundedClass} object-cover`}
            src={src}
            alt="Avatar"
          />
        ) : (
          <div
            className={`${AvatarSize} ${roundedClass} bg-white border border-primary-200`}
          />
        )}

        {status &&
          ![AvatarType.circular, AvatarType.rounded].includes(type) && (
            <span
              className={`absolute ${position} right-0 block ${roundedSize} rounded-full ring-2 ring-white ${avatarStatusToClassMap[status]?.roundedColor}`}
            />
          )}
      </span>
    </Tooltip>
  );
};

export default Avatar;
