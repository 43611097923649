import api from 'api/axiosInstance';
import type { AxiosResponse } from 'axios';
import type { AuthenticationDataType, IAuthResponse } from 'types/auth.types';
import type { IExpert } from 'types/experts.types';
import type { IPaginationResponse } from 'types/pagination.types';
import type { IUser } from 'types/users.types';
import { Role } from 'types/users.types';
import { setAmplitudeUser, setAmplitudeUserProperties } from 'utils/amplitude';
import { isExpert } from 'utils/constants';

const service = '/v2/experts';

type IAPILoginResponse = IAuthResponse & {
  data: IExpert;
};

type IAPIGetFiduciariesResponse = IPaginationResponse & {
  data: {
    name: string;
    ITAANumber: string;
    address: string;
    email: string;
    language: string;
  }[];
};

export const setExpertAmplitudeUser = (email: string): void => {
  setAmplitudeUser(email);
  setAmplitudeUserProperties({
    role: Role.accountant,
  });
};

export const postMagicLoginUrl = async ({
  email,
}: {
  email: string;
}): Promise<AxiosResponse<string>> => {
  const data = await api.post(`${service}/magic-link`, {
    email,
    // use when testing locally
    // redirectUri: 'https://next.experts.accountable.eu/expertlogin',
    redirectUri: `${window.location.origin}/expertLogin`,
  });

  return data;
};

export const login = async (params?: {
  [x: string | number]: unknown;
}): Promise<AxiosResponse<AuthenticationDataType>> => {
  const res = await api.post(`${service}/login`, { ...params });
  if (isExpert) {
    setExpertAmplitudeUser(res.data.email);
  }
  return res;
};

export const createExpert = (body: IExpert): Promise<IAPILoginResponse> =>
  api.post(`${service}`, body);

export const updateExpert = (body: Partial<IExpert>): Promise<{ ok: true }> =>
  api.patch(`${service}`, body);

export const getFiduciaries = (): Promise<IAPIGetFiduciariesResponse> =>
  api.get(`${service}/public-list-fiduciaries`);

export const getUserData = (): Promise<IUser> =>
  api.get(service).then((res) => {
    if (isExpert) {
      setExpertAmplitudeUser(res.data.email);
    }
    return res.data;
  });

export const logout = (): Promise<void> => api.post(`${service}/logout`);
