import { useAmountsCache } from 'index';
import type { FC } from 'react';
import { useCallback } from 'react';
import CountUp from 'react-countup';
import type { Currency } from 'types/global.types';
import formatMoney from 'utils/formatMoney';

const MoneyCounter: FC<{
  amount: number;
  currency?: Currency;
  stateId: string;
  showPositiveSign?: boolean;
  noFractions?: boolean;
}> = ({ amount, currency, stateId, showPositiveSign, noFractions }) => {
  const { amountsCache, setAmountsCache } = useAmountsCache();
  const amountInCache = amountsCache[stateId];
  const cacheLatestAmount = useCallback(
    (_amount: number) => setAmountsCache({ [stateId]: _amount }),
    [setAmountsCache, stateId],
  );
  const formatter = useCallback(
    (value: number) =>
      formatMoney(value, currency, undefined, showPositiveSign, noFractions),
    [currency, noFractions, showPositiveSign],
  );

  return amountInCache === amount || !amount ? (
    <>{formatter(amount)}</>
  ) : (
    <CountUp
      end={amount}
      formattingFn={formatter}
      preserveValue
      onEnd={() => cacheLatestAmount(amount)}
    />
  );
};

export default MoneyCounter;
