import { plans_order } from 'components/pages/Subscriptions/Subscription.enum';
import { useCallback } from 'react';
import { type UseQueryResult } from 'react-query';
import type { SubscriptionPlan } from 'types/subscriptions.types';
import {
  SubscriptionPlanNameEnum,
  type ISubscriptionPlan,
} from 'types/users.types';
import { excludeNumbers } from 'utils/helpers';
import useGroupSubscriptionPlans from './useGroupSubscriptionPlans';
import useSubscriptionPlan from './useSubscriptionPlan';

export const freePlanStaticData: Partial<SubscriptionPlan> = {
  currency: 'EUR',
  id: 'free',
  interval: 'month',
  interval_count: 1,
  amount: 0,
  nickname: SubscriptionPlanNameEnum.free,
  object: 'plan',
  product: 'prod_ItGdzTLtwBvOBI',
  shortNickname: 'free1',
};

const useGetAccessibleSubscriptionPlans = (): UseQueryResult<
  SubscriptionPlan[]
> & {
  getAccessiblePlans: (installment: number) => Partial<SubscriptionPlan>[];
  currentPlan: ISubscriptionPlan | void;
} => {
  const currentPlan = useSubscriptionPlan();
  const { planInternalName } = currentPlan || {};
  const subscriptionPlansQuery = useGroupSubscriptionPlans(planInternalName);
  const subscriptionPlans = subscriptionPlansQuery?.data;

  const getAccessiblePlans = useCallback(
    (installment: number) => {
      const _plans = subscriptionPlans?.filter(
        ({ nickname }: SubscriptionPlan) =>
          nickname === excludeNumbers(nickname).concat(String(installment)),
      );

      return [
        freePlanStaticData,
        ...(_plans
          ?.slice()
          .sort(
            (a: SubscriptionPlan, b: SubscriptionPlan) =>
              plans_order[a.nickname] - plans_order[b.nickname],
          ) || []),
      ];
    },
    [subscriptionPlans],
  );

  return { ...subscriptionPlansQuery, getAccessiblePlans, currentPlan };
};

export default useGetAccessibleSubscriptionPlans;
