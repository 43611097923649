import type SVGType from 'types/svg.types';

const ExpensesIcon: SVGType = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.5999 6.13379H10.3999C9.22173 6.13379 8.2666 7.08891 8.2666 8.26712V25.8671L11.0222 23.4671L13.511 25.8671L15.9999 23.4671L18.4888 25.8671L20.9777 23.4671L23.7333 25.8671V8.26712C23.7333 7.08892 22.7781 6.13379 21.5999 6.13379Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
    />
  </svg>
);

export default ExpensesIcon;
