import Joi from 'joi';

const germanCarValidationSchema = Joi.object({
  name: Joi.string().trim().max(50).required().messages({
    'string.max': 'german_car.form.name.max',
    '*': 'german_car.form.name.required',
  }),
  listPrice: Joi.number().min(1).max(9999999).required().messages({
    'number.min': 'german_car.form.listPrice.min',
    'number.max': 'german_car.form.listPrice.max',
    '*': 'german_car.form.listPrice.required',
  }),
  fuelType: Joi.string().required().messages({
    '*': 'german_car.form.fuelType.required',
  }),
  enginePower: Joi.when('fuelType', {
    is: 'electric',
    then: Joi.number().min(1).max(1000).required().messages({
      'number.min': 'german_car.form.enginePower.min',
      'number.max': 'german_car.form.enginePower.max',
      '*': 'german_car.form.enginePower.required',
    }),
  }),
  electricRange: Joi.when('fuelType', {
    is: 'hybrid',
    then: Joi.number().min(1).max(1000).required().messages({
      'number.min': 'german_car.form.electricRange.min',
      'number.max': 'german_car.form.electricRange.max',
      '*': 'german_car.form.electricRange.required',
    }),
  }),
});

export default germanCarValidationSchema;
