import type { LockStatusType, Month, Quarter } from './global.types';
import type { Transaction } from './transactions.types';
import type { IUserSnapShot, VATType } from './users.types';
import type { IVehicle } from './vehicles.types';

type AccountantReview = {
  reviewStatus: 'reviewed' | 'not_reviewed';
  comments: string;
};

export enum SupplierCountryEnum {
  be = 'be',
  de = 'de',
  eu = 'eu',
  other = 'other',
}

export enum WhyZeroVATEnum {
  other = 'other',
  noVAT = 'noVAT',
  reverseCharge = 'reverseCharge',
}

export enum ReviewStatusEnum {
  reviewed = 'reviewed',
  notReviewd = 'not_reviewed',
}

export enum ECreatedFrom {
  web = 'web',
  dropzone = 'dropzone',
  email = 'email',
  experts = 'experts',
  unknown = 'unknown',
  mobile = 'mobile',
  peppol = 'peppol',
}

export type CreatedFrom = ECreatedFrom;

export enum SupplierCountryValues {
  eu = 'eu',
  other = 'other',
}
export type SupplierCountry = null | string | SupplierCountryEnum;

export enum SupplierTypes {
  subjectToVAT = 'subjectToVAT',
  intraEU = 'intra-EU',
  extraEU = 'extra-EU',
  noVAT = 'noVAT',
}
export type SupplierType = SupplierTypes;

export type Supplier = {
  name: string;
  country: SupplierCountryEnum;
  categoryId: string;
  professionalPart: number;
  supplierType: VATType; // not sure
};

export type SupplierAsOption = {
  label: string;
  value: string;
  supplier: Supplier;
};

export enum ItemVATStatusTypes {
  normal = 'normal',
  coContractor = 'co-contractor',
  other = 'other',
}
export type ItemVATStatus = ItemVATStatusTypes;

export type PeriodUserAction =
  | 'setToNextOpenQuarter'
  | 'addedToClosedQuarter'
  | null;
export interface IExpenseCategory {
  id: string;
  accountingCode: string;
  title: string;
  displayName: string;
  dnaPercentage: number;
  incomeTaxDeductibility: number;
  maxProfessionalPart?: number | null;
  maxDeductibleVAT?: number;
  reverseChargeVAT?: number;
  vatDeductibleWithoutInvoice?: number;
  recommendedProfessionalPart?: number;
  isCustom?: boolean;
  allowsVAT?: boolean;
  suggestedVATRate?: number | null;
  [key: string]: any;
}

export enum TripType {
  businessTrip = 'BUSINESS_TRIP',
  driveToWork = 'DRIVE_TO_WORK',
}

export enum TripVehicleType {
  car = 'CAR',
  motorbike = 'MOTORBIKE',
}

export const TripTypeIds = {
  [TripType.businessTrip]: TripType.businessTrip.toLowerCase(),
  [TripType.driveToWork]: TripType.driveToWork.toLowerCase(),
};

export interface IExpenseItem {
  _id: string;
  amountExclVAT?: number | null;
  VATAmount?: number | null;
  netPrice?: number | null;
  amount: number;
  qty: number;
  unitPrice: number;
  vat?: number | null;
  categoryId: string;
  VATStatus: ItemVATStatus;
  professionalPart: number;
  whyZeroVAT?: ExpenseWhyZeroVAT;
  localVATRate?: number;
  name?: string;
  isAsset?: boolean;
  amortizationPeriod?: number;
  professionalPartOverride?: number;
  deductibleVATOverride?: number;
  dnaPercentageOverride?: number;
  dnaPercentage?: number;
  category: IExpenseCategory;
  vehicle?: IVehicle;
  vehicleId?: string;
  professionalAmount: number;
  recoverableVAT: number;
  professionalAmountExclRecoverableVAT: number;
  deductibleAmount: number;
  maxDeductibleVAT: number;
  incomeTaxDeductibility: number;
  isExclVAT?: boolean; // only front end
  isReverseCharge?: boolean | null; // only front end
  tripType?: TripType;
  tripVehicleType?: TripVehicleType;
  tripTotalKm?: number;
  tripWorkingDays?: number;
  deduction?: number;
}

export enum ExpenseWhyZeroVAT {
  reverseCharge = 'reverseCharge',
  other = 'other',
  noVAT = 'noVAT',
}

export enum ExpenseVATStatusTypes {
  normal = 'normal',
  reverseCharge = 'reverseCharge',
}

export type ExpenseVATStatus = ExpenseVATStatusTypes;

// export type VATQuarter = 1 | 2 | 3 | 4;

export interface VATPeriod {
  year: number;
  quarter?: Quarter;
  month?: Month;
  frequency?: string; //VATReturnFrequencyEnum;
}

export interface ExpenseFile {
  path: string;
  hash?: string;
}

export interface OCRExpense {
  OCRExpenseNumber: number;
  OCRDate?: Date;
  OCRTotalAmount?: number;
  OCRVATAmount?: number;
  OCRMerchantName?: string;
  OCRVATNumber?: string;
  OCRDocumentType?: string;
  OCRIBAN?: string;
}
export interface GuessedExpense {
  guessedItems:
    | null
    | {
        name: string;
        qty: number;
        netPrice: number;
        VATAmount: number;
        vat: number;
      }[];
  guessedMerchantCountry: string;
  guessedWhyZeroVAT: string;
  guessedEmail: string;
  guessedURL: string;
  guessedIBAN: string;
  guessedExpenseNumber: number;
  guessedProfessionalPart: number;
  guessedMerchantName?: string;
  guessedCategoryId?: string;
  guessedCategoryFrom?: string;
  guessedVATNumber?: string;
  guessedDate?: Date;
  guessedVATAmount?: number;
  guessedTotalAmount?: number;
  guessedIsInvoice?: boolean;
  guessedCommunication: string | null;
  guessedFrom?: string;
  guessedIsVATPresent?: boolean;
  guessedIsFraudDetected?: boolean;
}

export interface IExpense extends OCRExpense, GuessedExpense {
  _id: string;
  userId: string;
  isCreditNote: boolean;
  supplier: Partial<Supplier>;
  notes: string | null;
  expenseDate: string;
  expenseDateNumber: number;
  cashRemainder: boolean;
  matchCard: boolean;
  isInvoice: boolean;
  VATStatus: ExpenseVATStatus;
  isFake?: boolean;
  files: ExpenseFile[];
  fileSource?: string;
  isSuggestedPeriod?: boolean;
  chiftExportIds?: string[];
  // TODO: remove `file*` after migration to `files` key complete
  filename?: string | null;
  filePath?: string | null;
  fileHash?: string | null;

  createdFrom: CreatedFrom | 'unknown';
  items: IExpenseItem[];
  VATAmount: number | null;
  amountExclVAT: number | null;
  amount: number;
  transactions: string[];
  _matchedTransactions: Transaction[];
  automatch_transaction_id: string;
  accountantReview?: AccountantReview;
  accountIndex: number;
  isValidated: boolean;
  reviewed: boolean;
  createdBy: 'user' | 'email' | 'bank';
  userSnapshot: IUserSnapShot;
  user?: Partial<IUserSnapShot>;
  correctedMerchantName?: string;
  legalMerchantName?: string;
  transactionMerchantName?: string;
  period: VATPeriod;
  imported: boolean;
  submissions?: string[];
  periodUserAction?: PeriodUserAction;
  professionalAmount: number;
  recoverableVAT: number;
  professionalAmountExclRecoverableVAT: number;
  deductibleAmount: number;
  sufficientlyDocumented: boolean;
  customFeesAmount?: number;
  originalTransaction: {
    counterPartyName?: string;
    counterPartyNumber?: string;
    communication?: string;
  };
  created: Date;
  modified: string;
  ocr: OCRExpense & GuessedExpense;
  gmailMessageId?: string;
  taxPeriod?: VATPeriod;
  shouldUpdateTaxStatus?: boolean; // defaults to true on the backend
  isThereVATOnInvoice?: boolean | null; // frontend value
  taxLock?: LockStatusType;
}
