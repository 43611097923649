import type { ReactNode } from 'react';
import parse from './parse';

const parseNewLine = (str: string): ReactNode =>
  /(\r\n|\r|\n)/g.test(str) ? (
    <span className="whitespace-pre-line">{str}</span>
  ) : (
    str
  );

const newLineFormatter = (element: ReactNode): ReactNode | string =>
  parse(element, parseNewLine);

export default newLineFormatter;
