import { Section } from 'types/global.types';
import type { InvoiceType } from 'types/invoice.types';

const period = {
  priority_date_key: ({
    section,
    type,
  }: {
    section: Section;
    type?: InvoiceType;
  }): 'paymentDate' | 'invoiceDate' | 'expenseDate' => {
    if (section === Section.expenses) return 'expenseDate';
    if (type === 'other-revenue') return 'invoiceDate';
    return 'paymentDate';
  },
  priority_date: ({
    documentDate,
    paymentDate,
    section,
    type,
  }: {
    documentDate: Date;
    paymentDate?: Date;
    section: Section;
    type?: InvoiceType;
  }): Date | undefined => {
    if (section === Section.expenses) return documentDate;

    // for revenues of type other-revenue the priority date is invoiceDate
    // since they don't have a payment date
    if (type === 'other-revenue') return documentDate;
    return paymentDate;
  },
  is_period_dropdown_disabled: ({
    paymentDate,
    section,
    type,
  }: {
    paymentDate?: Date;
    section: Section;
    type?: InvoiceType;
  }): boolean => {
    // expenses or revenues of other-revenue type won't be disabled
    if (section === Section.expenses || type === 'other-revenue') return false;
    return !paymentDate;
  },
  period_dropdown_placeholder: ({
    paymentDate,
    section,
    type,
  }: {
    paymentDate?: Date;
    section: Section;
    type: InvoiceType;
  }): string => {
    return section !== Section.expenses &&
      !paymentDate &&
      type !== 'other-revenue'
      ? 'period.field.period_assignment.disabled_placeholder'
      : 'period.field.select';
  },
};

export default period;
