import * as jsvat from '@accountable/jsvat';
import { checkVAT } from '@accountable/jsvat';
import Joi from 'joi';
import { AccountSettingsFieldsNamesEnum } from 'types/settings.types';
import { AvailableCountries } from 'types/users.types';
import { settingsErrorMessagesFormatter } from 'utils/settings/settingsHelpers';
import { groupValidation } from 'validation/commonValidators';

const germanTaxNumberValidationSchema = Joi.object({
  [AccountSettingsFieldsNamesEnum.steuernummer]: Joi.when(
    AccountSettingsFieldsNamesEnum.state,
    {
      is: Joi.string().exist(),
      then: Joi.required().when(Joi.ref('/country'), {
        is: AvailableCountries.germany,
        then: Joi.custom((value, helpers) => {
          const values = helpers.state.ancestors[0];

          if (values.steuernummerValid === false) {
            return helpers.error('any.invalid');
          }
          return value;
        }).messages(
          settingsErrorMessagesFormatter(
            AccountSettingsFieldsNamesEnum.steuernummer,
          ),
        ),
      }),
    },
  ),
  ...groupValidation(AccountSettingsFieldsNamesEnum.address, {
    [AccountSettingsFieldsNamesEnum.state]: Joi.string()
      .required()
      .messages(
        settingsErrorMessagesFormatter(AccountSettingsFieldsNamesEnum.state),
      ),
  }),
});

const belgianVATNumberValidationSchema = Joi.object({
  [AccountSettingsFieldsNamesEnum.VATNumber]: Joi.string()
    .required()
    .custom((value, helpers) => {
      const { isValid } = checkVAT(value, [jsvat.belgium, jsvat.germany]);

      if (!isValid) {
        return helpers.error('any.invalid');
      }
      return value;
    })
    .messages(
      settingsErrorMessagesFormatter(AccountSettingsFieldsNamesEnum.VATNumber),
    ),
});

export { germanTaxNumberValidationSchema, belgianVATNumberValidationSchema };
