import { Switch } from '@headlessui/react';
import classNames from 'classnames';

import type { FC } from 'react';
import { getTracingEvents } from 'utils/amplitude';
import type { toggleProps } from './Toggle.types';

const Toggle: FC<toggleProps> = ({
  label,
  enabled,
  onChange,
  disabled,
  tracing,
  id,
}) => {
  const tracingEvents = getTracingEvents(tracing, { onChange });

  return (
    <Switch
      id={id}
      checked={enabled}
      onChange={(v: any) => {
        if (disabled) return;
        tracingEvents.onChange(v);
      }}
      disabled={disabled}
      className={classNames(
        disabled ? 'opacity-60 cursor-not-allowed' : '',
        enabled ? 'bg-primary-600' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
      )}
    >
      {label && <span>{label}</span>}
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        )}
      />
    </Switch>
  );
};

export default Toggle;
