import { useEffect, useRef } from 'react';

const useClickAwayListener = (
  handler: () => void,
  excludedId?: string,
): React.RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickAway(event: MouseEvent): void {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        (event.target as Element).id !== excludedId
      ) {
        handler();
      }
    }
    document.addEventListener('mousedown', handleClickAway);
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, [ref, handler]);
  return ref;
};

export default useClickAwayListener;
