import api from 'api/axiosInstance';
import _omit from 'lodash/omit';
import type { IUser, UserAiUsageConsentEnum } from 'types/users.types';
import formatCustomer from 'utils/formatCustomer';
import { removeComplementaryDays } from 'utils/settings/settingsHelpers';

const service = '/v2/users/settings';

type IAPIUpdateRevenueSettingsBody = {
  header: {
    color: {
      g: number;
      b: number;
      r: number;
    };
  };
  footer: {
    color: {
      g: number;
      b: number;
      r: number;
    };
  };
  logo: {
    filename: string;
    filePath: string;
  };
  termsAndConditions: {
    default: string;
  };
  messageTemplate: {
    default: {
      default: string;
    };
  };
  language: string;
  templateType: string;
  currency: string;
  usePaymentQrCode: boolean;
  useCommunication: boolean;
};

type IAPIUpdateQuotesBody = {
  header: {
    color: {
      g: number;
      b: number;
      r: number;
    };
  };
  logo: {
    filename: string;
    filePath: string;
  };
  termsAndConditions: {
    default: string;
  };
  messageTemplate: {
    default: {
      default: string;
    };
  };
  language: string;
  templateType: string;
  currency: string;
};

type IAPIUpdateCashAvailableBody = {
  balance: boolean;
  currentPeriod: boolean;
  history: boolean;
  salesInvoices: boolean;
  purchaseInvoices: boolean;
  nextSocialContributions: boolean;
  provisionForIncomeTaxes: boolean;
};

type IAPIUpdateDatevBody = {
  clientNumber: string;
  accountantNumber: string;
  defaultSalesAccount: {
    '0': string;
    '7': string;
    '19': string;
  };
};

export enum UpdateUserTypes {
  languageUpdate = 'languageUpdate',
  customerDataUpdate = 'customerDataUpdate',
}

// TODO: return user type
export const updateEmailAliases = (emails: string[]): Promise<void> =>
  api.patch(`${service}/email-aliases`, { emailAliases: emails });

// TODO: return user type
export const updateInvoiceSettings = (
  settings: IAPIUpdateRevenueSettingsBody,
): Promise<void> => api.patch(`${service}/invoices`, settings);

// TODO: return revenue settings type
export const updateQuotesSettings = (
  settings: IAPIUpdateQuotesBody,
): Promise<void> => api.patch(`${service}/quotes`, settings);

// TODO: return user type
export const updateCashAvailableSettings = (
  settings: IAPIUpdateCashAvailableBody,
): Promise<void> => api.patch(`${service}/cash-available`, settings);

// TODO: return user type
export const updateDatevSettings = (
  settings: IAPIUpdateDatevBody,
): Promise<void> => api.patch(`${service}/datev`, settings);

export const updateUserLanguage = (language: number): Promise<void> =>
  api.patch('/v2/users', { language });

export const updateUserStartDate = (startDate: string): Promise<void> =>
  api.patch('/v2/users', { startDate });

export const updateUserAIUsageConsent = (
  aiUsageConsent: UserAiUsageConsentEnum,
): Promise<IUser> => api.patch('/v2/users', { aiUsageConsent });

export const patchUserDangerously = (body: Partial<IUser>): Promise<void> =>
  api.patch('/v2/users', body);

export const updateUserData = (body: Partial<IUser>): Promise<IUser> => {
  const keysToOmit = [
    'password',
    'installations',
    'xerius',
    'subscriptionPlan',
    'promocodes',
    'settings.invoices',
  ];

  const omittedBody = _omit(formatCustomer(body), keysToOmit);

  return api.patch('/v2/users', {
    ...omittedBody,
    complementaryDays: removeComplementaryDays(body)
      ? undefined // CHANGE THIS TO BE "NULL" IN ORDER TO BE ABLE TO RESET THE FIELD (ONCE THE BE IS READY)
      : omittedBody.complementaryDays,
  });
};
