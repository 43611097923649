import type { Dictionary } from 'lodash';
import type { MutableRefObject } from 'react';
import type { TaxTypeEnum } from './taxes.types';

export type DictionaryType = Record<string | number, any>;

export type Quarter = 1 | 2 | 3 | 4;

export type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type BackendError = {
  code: string;
  field: string;
  message: string;
};

export type VATReturnFrequency = 'monthly' | 'quarterly' | 'yearly';

export type Period = {
  year: number; // year > must be present, not a date in the future and one year old at the latest
  quarter?: Quarter;
  month?: Month;
  locked?: boolean;
};

export type PeriodTimeFrameType = {
  frequency: VATReturnFrequency;
  value: number;
  year: number;
};

type SubmissionPeriod = Period & { frequency: VATReturnFrequency };

export type DocumentSubmission = {
  type: 'vat_submission';
  period: SubmissionPeriod;
  snapshotId: string;
  created: string;
};

export enum CurrenciesEnum {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTC = 'BTC',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLF = 'CLF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'zł',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  STD = 'STD',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export type Currency = keyof typeof CurrenciesEnum;

export enum Section {
  expenses = 'expenses',
  revenues = 'revenues',
  clients = 'clients',
  customers = 'customers',
  export = 'export',
  taxes = 'taxes',
  transactions = 'transactions',
  settings = 'settings',
}
export type OptionType = {
  label: string;
  value?: any;
};

export type TaxPeriod = {
  year: number; // year > must be present, not a date in the future and one year old at the latest
  quarter?: Quarter;
  month?: Month;
  frequency: VATReturnFrequency;
};

export type Color = {
  r: 'string';
  g: 'string';
  b: 'string';
};
export type RefType = MutableRefObject<HTMLElement | undefined | null>;

export type QP = Dictionary<string | number | undefined>;

export type FilterTypes = 'checkbox' | 'radio' | 'date-range';

export type FilterResponse = {
  key: string;
  type: FilterTypes;
  values: any[];
};

export type FilterV2 = {
  key: string;
  label: string;
  options: any[];
  type: FilterTypes;
  values: any[];
  isV2: true;
};

export enum DEBankCodes {
  n26 = '10011001',
  commerzbank = '26589210',
  postbank = '59010044',
  ingbank = '50010517',
  deutschbank = '81070000',
  comdirectbank = '20041111',
  qonto = '10010123',
}

export enum BEBankSlugs {
  kbc = 'kbc',
  ing = 'ing-belgium',
  belfius = 'belfius',
  argenta = 'argenta',
  bnp = 'bnp-paribas-fortis-api',
  beo = 'beobank',
  cbc = 'cbc',
  paypal = 'paypal',
  axa = 'axa-belgium',
  n26 = 'n26',
  hello = 'hello-bank',
  crelan = 'crelan',
  vanbreda = 'bank-van-breda',
  bpost = 'bpost',
  nagelmackers = 'nagelmackers',
}

export type LockReasonFieldType = 'period' | 'date';

export type LockReasonType = {
  taxId: string;
  type: TaxTypeEnum;
  readableType: string;
  field: LockReasonFieldType;
  period?: any;
};

export type LockStatusType = LockReasonType[];

export type LockStatusTypeCatgoriesed = Record<
  LockReasonFieldType,
  LockReasonType[]
> | null;

export type YearlyReviewData = {
  thisYear: {
    deductibleExpenses: { total: number; perQuarter: number[] };
    turnover: { total: number; perQuarter: number[] };
    netEarnings: { beforeTax: number; afterTax: number };
    invoices: {
      totalCount: { total: number; perQuarter: number[] };
      unpaidCount: { total: number; perQuarter: number[] };
    };
    expenses: {
      highestAmount: {
        amount: number;
        supplier: string;
        perQuarter: { amount: number; supplier: string }[];
      };
    };
    clients: { count: number };
    paymentTerms: { mean: number | null };
    categories: {
      top5: Record<string, { amount: number; percentile: number }>;
    };
  };
  lastYear: {
    deductibleExpenses: { total: number; perQuarter: number[] };
    turnover: { total: number; perQuarter: number[] };
    netEarnings: { beforeTax: number; afterTax: number };
  };
  quotes: { quote1: string; quote2: string };
  tips: string[];
};

export type Nullable<T> = T | null | undefined;

export type ExportListType = {
  modules: Record<string, any>;
  sendEmail: boolean;
  integrationId: string;
  linkId?: string;
};
