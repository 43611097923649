import { VATNumberMaskNormalizer } from 'utils/settingsHelpers';

import type { UseFormReturn } from 'react-hook-form';
import { AccountSettingsFieldsNamesEnum } from 'types/settings.types';
import { AccountTypeEnum, ActivityTypesEnum } from 'types/users.types';

const { freiberufler, gewerbetreibender } = AccountTypeEnum;
const { principal, complementary } = ActivityTypesEnum;

const settings = {
  canChangeVATFrequency: true,
  vatNumberPlaceholder: 'DE999999999',
  IBANPlaceholder: 'DE99 9999 9999 9999 9999 99',
  countryBasedFormFields: [
    AccountSettingsFieldsNamesEnum.occupation,
    AccountSettingsFieldsNamesEnum.APC1,
    AccountSettingsFieldsNamesEnum.APC2,
    AccountSettingsFieldsNamesEnum.steuernummer,
    AccountSettingsFieldsNamesEnum.state,
    AccountSettingsFieldsNamesEnum.finanzamt,
    AccountSettingsFieldsNamesEnum.accountType,
    AccountSettingsFieldsNamesEnum.activity,
    // AccountSettingsFieldsNamesEnum.complementaryDays,
  ],
  accountTypeOptions: [
    {
      value: freiberufler,
      label: 'settings.companyDetails.accountType.freiberufler',
    },
    {
      value: gewerbetreibender,
      label: 'settings.companyDetails.accountType.gewerbetreibender',
      updateLinkedFields: (formAPI: UseFormReturn): void =>
        formAPI.setValue(AccountSettingsFieldsNamesEnum.activity, principal),
    },
  ],
  activityTypeOptions: [
    {
      value: principal,
      label: 'settings.companyDetails.activity.primary_activity',
    },
    {
      value: complementary,
      label: 'settings.companyDetails.activity.secondary_activity',
    },
  ],
  VATNumberMask: 'DE 9999999999',
  VATNumberMaskNormalizer: VATNumberMaskNormalizer('DE'),
  available_languages: [0, 3], // english, german
  hasTaxesSettings: true,
  dateFormat: 'DD.MM.YYYY',
  requiredProfileData: [
    { field: AccountSettingsFieldsNamesEnum.steuernummer, weight: 25 },
    { field: AccountSettingsFieldsNamesEnum.city, weight: 7 },
    { field: AccountSettingsFieldsNamesEnum.street, weight: 9 },
    { field: AccountSettingsFieldsNamesEnum.zip, weight: 9 },
    { field: AccountSettingsFieldsNamesEnum.IBAN, weight: 25 },
  ],
};

export default settings;
