import isEmpty from 'lodash/isEmpty';

const belgiumVATFormatter = (val: string) => {
  const withLeadingZero =
    val.replace(/\D+/g, '').length === 9
      ? 'BE0'.concat(val.replace('BE', ''))
      : val;
  return 'BE '.concat(
    withLeadingZero
      .replace(/\D+/g, '')
      .split('')
      .reverse()
      .map((c, i) => ((i + 1) % 3 || i + 1 >= 9 ? c : `.${c}`))
      .reverse()
      .join(''),
  );
};

export const VATFormatter = (val: string) => {
  if (isEmpty(val)) {
    return '';
  }
  if (val.substring(0, 2) === 'BE') return belgiumVATFormatter(val);

  return val;
};

export const dehyphenateString = (str: string): string =>
  str.replace(/\u00AD/g, '');
