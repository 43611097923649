import CashRegistry from './CashRegistry';
import Copyright from './Copyright';
import Covid from './Covid';
import Donation from './Donation';
import Embassy from './Embassy';
import ExemptedProfessional from './ExemptedProfessional';
import Face2 from './Face2';
import House from './House';
import Mixed from './Mixed';
import Mixed2 from './Mixed2';
import Percentage from './Percentage';
import Person from './Person';
import PostPackage from './PostPackage';
import Shop from './Shop';
import Subsidy from './Subsidy';
import Umbrella from './Umbrella';

export enum SVGIconInvoiceCategoriesEnum {
  percentage = 'percentage',
  // same svg
  copyright = 'copyright',
  royalties = 'royalties',

  indemnity = 'indemnity',
  subsidy = 'subsidy',
  donation = 'donation',
  goods = 'goods',
  services = 'services',
  rent = 'rent',
  insurance = 'insurance',
  bank = 'bank',
  commission = 'commission',
  gift = 'gift',
  otherIncome = 'otherIncome',
  refunds = 'refunds',
  covid = 'covid',
  sales = 'sales',
  cashRegistry = 'cashRegistry',
  mixed = 'mixed',
  vat_reimbursement = 'vat_reimbursement',
  face2 = 'face2',
  embassy = 'embassy',
  shop = 'shop',
  exemptedProfessional = 'exemptedProfessional',
}

type SVGIconRendererProps = {
  icon: keyof typeof SVGIconInvoiceCategoriesEnum;
  className?: string;
  fill?: string;
};

const SVGIconRenderer = ({ icon, ...rest }: SVGIconRendererProps) => {
  if (!icon) return null;

  const props = { fill: '#5CE4B6', ...rest };

  switch (icon) {
    case SVGIconInvoiceCategoriesEnum.cashRegistry:
    case SVGIconInvoiceCategoriesEnum.sales:
      return <CashRegistry {...props} />;
    case SVGIconInvoiceCategoriesEnum.goods:
    case SVGIconInvoiceCategoriesEnum.gift:
      return <PostPackage {...props} />;
    case SVGIconInvoiceCategoriesEnum.services:
      return <Person {...props} />;
    case SVGIconInvoiceCategoriesEnum.rent:
    case SVGIconInvoiceCategoriesEnum.bank:
      return <House {...props} />;
    case SVGIconInvoiceCategoriesEnum.percentage:
    case SVGIconInvoiceCategoriesEnum.commission:
      return <Percentage {...props} />;
    case SVGIconInvoiceCategoriesEnum.insurance:
      return <Umbrella {...props} />;
    case SVGIconInvoiceCategoriesEnum.copyright:
    case SVGIconInvoiceCategoriesEnum.royalties:
      return <Copyright {...props} />;
    case SVGIconInvoiceCategoriesEnum.subsidy:
    case SVGIconInvoiceCategoriesEnum.refunds:
    case SVGIconInvoiceCategoriesEnum.indemnity:
      return <Subsidy {...props} />;
    case SVGIconInvoiceCategoriesEnum.donation:
      return <Donation {...props} />;
    case SVGIconInvoiceCategoriesEnum.covid:
      return <Covid {...props} />;
    case SVGIconInvoiceCategoriesEnum.mixed:
      return <Mixed {...props} />;
    case SVGIconInvoiceCategoriesEnum.vat_reimbursement:
      return <Subsidy {...props} />;
    case SVGIconInvoiceCategoriesEnum.otherIncome:
      return <Mixed2 {...props} />;
    case SVGIconInvoiceCategoriesEnum.face2:
      return <Face2 {...props} />;
    case SVGIconInvoiceCategoriesEnum.embassy:
      return <Embassy {...props} />;
    case SVGIconInvoiceCategoriesEnum.shop:
      return <Shop {...props} />;
    case SVGIconInvoiceCategoriesEnum.exemptedProfessional:
      return <ExemptedProfessional {...props} />;
    default:
      return null;
  }
};

export default SVGIconRenderer;
