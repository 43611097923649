import api from 'api/axiosInstance';
import { stringify } from 'query-string';
import type { Currency } from 'types/global.types';
import type { IPaginationRequest, Paging } from 'types/pagination.types';
import type { Transaction } from 'types/transactions.types';

const service = '/v1/transactions';

type Params = IPaginationRequest & {
  accountNumbers?: string;
  toClassify?: 'yes' | 'no';
  text?: any;
  currency?: Currency;
};

type Response = {
  data: Transaction[];
  paging: Paging;
};

// TODO: This is a work around. Discuss it with the team: either modify the filter
// from the Backend, or restructure filters configuration into custom configuration for each world
const toClassifyMapper = {
  yes: 'true',
  no: 'false',
};

const getTransactions = (params: Params): Promise<Response> =>
  api.get(
    `${service}?${stringify(
      {
        ...params,
        toClassify:
          toClassifyMapper[params.toClassify as keyof typeof toClassifyMapper],
      },
      {
        skipEmptyString: true,
        arrayFormat: 'comma',
      },
    )}`,
  );

export default getTransactions;
