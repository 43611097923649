import { useCallback, useReducer, useRef } from 'react';
import shallowEqual from 'utils/shallowEqual';

/**
 *
 * its like useState but with array of deps,
 * whenever the deps changes; the hook will reset it self to its initial state without re-rendering
 */
const useResetableState = ({
  init,
  resetFn,
  deps,
}: {
  init: any;
  resetFn?: (lastState: any) => any;
  deps: any[];
}) => {
  const [, render] = useReducer((prevState) => prevState + 1, 0);
  const state = useRef({ value: init, deps });

  if (!shallowEqual(deps, state.current.deps)) {
    state.current.deps = deps;
    state.current.value = resetFn ? resetFn(state.current.value) : init;
  }

  const update = useCallback((updater: any) => {
    if (typeof updater === 'function')
      state.current.value = updater(state.current.value);
    else state.current.value = updater;

    render();
  }, []);

  return [state.current.value, update];
};

export default useResetableState;
