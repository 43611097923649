import { LinkIcon, PaperClipIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { bulkDeleteExpenses } from 'api/v2/expenses';
import { Tooltip } from 'components/atoms/Tooltip';
import { TableDeleteBulkAction } from 'components/molecules/TableDeleteBulkAction.component';
import { get } from 'lodash';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import type { IExpense } from 'types/expenses.types';
import type { CheckComponentProps } from 'types/taxes.types';
import CheckExpensesList from '../CheckExpensesList';
import CheckUITemplate from '../CheckUITemplate';

// TODO: handle translation

const reasonMap = {
  'amount-with-date': 'taxes.checks.duplicate_reason_1',
  'file-hash': 'taxes.checks.duplicate_reason_2',
};

const ExpenseDuplicates: FC<CheckComponentProps> = (props) => {
  const { checkData, refetchCheck } = props;
  const { t } = useTranslation();
  const [selectedExpenses, setSelectedExpenses] = useState<string[]>([]);
  const { mutateAsync: deleteExpenses } = useMutation((ids: string[]) =>
    bulkDeleteExpenses(ids),
  );

  return (
    <CheckUITemplate
      {...props}
      renderContent={() => (
        <>
          {get(checkData, 'data', []).map(
            (group: { list: any[]; reason: string }, index: number) => (
              <div
                key={get(group, 'list', [])[0]?._id}
                className="border mt-3 rounded-10 p-2"
              >
                <div className="my-2 flex items-center gap-2">
                  <span>
                    <ExclamationCircleIcon className="w-6 h-6 text-yellow-500" />
                  </span>
                  <span className="font-semibold">
                    {t(get(reasonMap, [get(group, 'reason')]))}
                  </span>
                </div>
                <CheckExpensesList
                  list={get(group, 'list', [])}
                  selectedExpenses={selectedExpenses}
                  renderExtra={(expense: IExpense) => {
                    return (
                      <span className="flex gap-2 items-center">
                        {expense.transactions?.length ? (
                          <Tooltip
                            content={t(
                              'taxes.tax_checks.has_transactions_linked',
                            )}
                          >
                            <LinkIcon className="w-4 h-4" />
                          </Tooltip>
                        ) : null}
                        {expense.fileHash ? (
                          <Tooltip
                            content={t(
                              'taxes.tax_checks.has_document_attached',
                            )}
                          >
                            <PaperClipIcon className="w-4 h-4" />
                          </Tooltip>
                        ) : null}
                      </span>
                    );
                  }}
                  setSelectedExpenses={setSelectedExpenses as any}
                  {...props}
                />
              </div>
            ),
          )}
        </>
      )}
      renderButtons={() => (
        <TableDeleteBulkAction
          className="ml-auto self-center"
          structure="secondary"
          selected={selectedExpenses}
          onDelete={() => {
            return deleteExpenses(selectedExpenses).then(() =>
              setSelectedExpenses([]),
            );
          }}
          onAfterDelete={() => refetchCheck()}
          title={t('expenses.confirm_deletes.title')}
          description={
            selectedExpenses.length > 1
              ? t('expenses.confirm_deletes', {
                  amount: selectedExpenses.length,
                })
              : t('expenses.confirm_delete')
          }
        />
      )}
    />
  );
};

export default ExpenseDuplicates;
