const AIIconOutline = (props: React.ComponentProps<'svg'>) => (
  <svg
    {...props}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="sparkles-2">
      <path
        d="M22.6667 6.3335C22.6667 7.8629 21.1962 9.3335 19.6667 9.3335C21.1962 9.3335 22.6667 10.8041 22.6667 12.3335C22.6667 10.8041 24.1373 9.3335 25.6667 9.3335C24.1373 9.3335 22.6667 7.8629 22.6667 6.3335Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6667 19.6665C22.6667 21.196 21.1962 22.6665 19.6667 22.6665C21.1962 22.6665 22.6667 24.137 22.6667 25.6665C22.6667 24.137 24.1373 22.6665 25.6667 22.6665C24.1373 22.6665 22.6667 21.196 22.6667 19.6665Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 10.3335C11.9999 13.2224 9.22213 16.0002 6.33325 16.0002C9.22213 16.0002 11.9999 18.7779 11.9999 21.6668C11.9999 18.7779 14.7777 16.0002 17.6666 16.0002C14.7777 16.0002 11.9999 13.2224 11.9999 10.3335Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default AIIconOutline;
