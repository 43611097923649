import { getRevenuesCategories } from 'api/v2/revenues';
import {
  generatePeriods,
  periodExists,
} from 'components/molecules/Fields/Period/utils';
import useCategoriesPicker, {
  UseCategoriesPickerProps,
} from 'components/organisms/CategoriesPicker/useCategoriesPicker';
import { categoryIdWithTaxPeriodToValue } from 'components/organisms/CategoriesPicker/utils';
import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useVATReturnFrequency from 'hooks/shared/useVATReturnFrequency';
import useFeatures from 'hooks/useFeatures';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RevenuesCacheKeys } from 'types/cacheKeys.types';
import type { IUser, VATReturnFrequency } from 'types/users.types';
import { getTracingEvents } from 'utils/amplitude';
import { TracingConfig } from 'utils/amplitude/amplitude.types';
import formatPeriodLabel from 'utils/formatPeriodLabel';
import { adaptCategories } from '../utils';

export type UseRevenuesCategoriesPickerProps = Omit<
  UseCategoriesPickerProps,
  'categories' | 'modalWidth'
> & {
  defaultTaxPeriod?: any;
  taxPeriod?: any;
  type: string;
  tracing?: TracingConfig;
};

type OpenFnProps = {
  value?: string;
  onChange: (category: any, taxPeriod?: any) => void;
};

const useRevenuesCategoriesPicker = (
  props: UseRevenuesCategoriesPickerProps,
) => {
  const {
    defaultTaxPeriod,
    taxPeriod,
    type,
    tracing,
    ...categoriesPickerProps
  } = props;

  const { t } = useTranslation();
  const deductibleTaxCategories = useFeatures(
    'revenue.deductible_tax_categories',
  );
  const vatReturnFrequencies = useFeatures('taxes.vat_return_frequency');
  const userVatReturnFrequency = useVATReturnFrequency();

  const user = useGetCurrentCustomerData().data as IUser;

  const country = user.country;

  const deductibleTaxCategoriesMap = useMemo(() => {
    return deductibleTaxCategories.reduce((acc: any, cateId: string) => {
      return {
        ...acc,
        [cateId]: {
          id: cateId,
          tabs: vatReturnFrequencies.map((tab: string) => ({
            label: t(tab),
            value: tab,
          })),
          defaultTab: userVatReturnFrequency,
          question: t('expense.vat_frequency_select'),
          questionDescription: t(
            'transaction.tax_period_title.VATReimbursement',
          ),
          list: vatReturnFrequencies.flatMap(
            (frequency: VATReturnFrequency) => {
              let periods = generatePeriods(frequency, dayjs().endOf('year'));
              if (
                defaultTaxPeriod &&
                defaultTaxPeriod.frequency === frequency
              ) {
                const doesDefaultPeriodExist = periodExists(
                  defaultTaxPeriod,
                  periods,
                  defaultTaxPeriod.frequency,
                );
                if (!doesDefaultPeriodExist) {
                  periods = periods.concat(defaultTaxPeriod);
                }
              }
              return periods.map((period) => ({
                title: formatPeriodLabel(period),
                id: categoryIdWithTaxPeriodToValue(cateId, frequency, period),
                parentCatgoryId: cateId,
                taxPeriod: { ...period, frequency },
                frequency,
                tab: frequency,
              }));
            },
          ),
        },
      };
    }, {});
  }, [
    deductibleTaxCategories,
    userVatReturnFrequency,
    country,
    defaultTaxPeriod,
    t,
  ]);

  const result = useQuery([RevenuesCacheKeys.CategoriesPicker], () =>
    getRevenuesCategories(),
  );

  const data = useMemo(() => {
    if (!result.data) return [];
    return adaptCategories(
      result.data,
      deductibleTaxCategoriesMap,
      type,
      user.accountType,
    );
  }, [result.data, deductibleTaxCategoriesMap, type]);

  const api = useCategoriesPicker({
    ...categoriesPickerProps,
    modalWidth: data.length > 1 ? '7xl' : '4xl',
    categories: data,
  });

  const events = getTracingEvents(tracing);

  const open = ({ value, onChange }: OpenFnProps) => {
    const finalValue =
      deductibleTaxCategories.includes(value) && taxPeriod
        ? categoryIdWithTaxPeriodToValue(value!, taxPeriod.frequency, taxPeriod)
        : value;

    events.onOpen?.();

    api.open({
      value: finalValue,
      onChange: (category, categoriesDict) => {
        events.onChange?.();

        if (category.taxPeriod) {
          return onChange(
            categoriesDict[category.parentCatgoryId!]._category,
            category.taxPeriod,
          );
        }
        onChange(category._category);
      },
    });
  };

  return {
    api: {
      ...api,
      open,
    },
    query: result,
  };
};

export default useRevenuesCategoriesPicker;
