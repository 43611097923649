import SVGIconRenderer from 'assets/svg/icons/revenues/SVGIconRenderer';
import { Select } from 'components/atoms/Form/Select';
import WarningModalContent from 'components/organisms/WarningModalContent';
import type { FieldProps } from 'components/pages/Expenses/Form/Fields/commonFieldProps.types';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useModal from 'hooks/useModal';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { VATTypeEnum } from 'types/users.types';
import { getTracingEvents } from 'utils/amplitude';

const VATType: FC<FieldProps> = ({ value, name, onChange, tracing }) => {
  const { t } = useTranslation();
  const country = useCustomerCountry();
  const [modalContent, modalApi] = useModal({
    dialogPanalClassName: '!p-0',
    width: '3xl',
    name: 'VAT type picker',
  });

  const options = [
    {
      title: t(
        'client.field.subject_to_vat.modal.options.subject_to_vat.title',
      ),
      description: t(
        'client.field.subject_to_vat.modal.options.subject_to_vat.description',
      ),
      value: VATTypeEnum.subjectToVAT,
      icon: <SVGIconRenderer icon="shop" className="w-10 mt-2" />,
      isSelected: value === VATTypeEnum.subjectToVAT,
    },
    {
      title: t('client.field.vat_franchisee.title.' + country),
      description: t('client.field.vat_franchisee.description.' + country),
      value: VATTypeEnum.franchisee,
      icon: <SVGIconRenderer icon="face2" className="w-10 mt-2" />,
      isSelected: value === VATTypeEnum.franchisee,
    },
    {
      title: t(
        'client.field.subject_to_vat.modal.options.exempted_profession.title',
      ),
      description: t(
        'client.field.subject_to_vat.modal.options.exempted_profession.description',
      ),
      value: VATTypeEnum.exempt,
      icon: (
        <SVGIconRenderer icon="exemptedProfessional" className="w-10 mt-2" />
      ),
      isSelected: value === VATTypeEnum.exempt,
    },
  ];

  const selected =
    options.find((option) => option.value === value)?.title || null;

  const tracingEvents = getTracingEvents(tracing, { onChange });

  return (
    <>
      {modalContent}
      <Select
        name={name}
        value={selected}
        openMenuOnClick
        isSearchable={false}
        noOptionsMessage={() => null}
        placeholder=""
        onMenuOpen={() => {
          tracingEvents.onMenuOpen();
          modalApi.open(() => (
            <WarningModalContent
              title={t('client.field.subject_to_vat.modal.title')}
              options={options}
              hideBGImage
              onChoose={(value) => {
                tracingEvents.onChange(value);
                modalApi.close();
              }}
            />
          ));
        }}
      />
    </>
  );
};

export default VATType;
