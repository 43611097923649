import { joiResolver } from '@hookform/resolvers/joi';
import { Button } from 'components/atoms/Button';
import DatePicker from 'components/atoms/DatePicker/DatePicker.component';
import { FieldConnector } from 'components/atoms/Form/FieldConnector';
import { Select } from 'components/atoms/Form/Select';
import { Toggle } from 'components/atoms/Form/Toggle';
import type { ModalApi } from 'components/atoms/Modal';
import PaymentAmount from 'components/molecules/Fields/PaymentAmount/PaymentAmount.component';
import { Helper } from 'components/molecules/Helper';
import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useTaxHeaderMeta from 'hooks/Taxes/useTaxHeaderMeta';
import useUpdateTaxMutation from 'hooks/Taxes/useUpdateTaxMutation';
import useCustomNavigate from 'hooks/useCustomeNavigate';
import { get } from 'lodash';
import type { FC, MutableRefObject } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { ITax } from 'types/taxes.types';
import { TaxTypeEnum } from 'types/taxes.types';
import { AccountTypeEnum } from 'types/users.types';
import getPeriodSlug from 'utils/taxes/getPeriodSlug';
import SCSettingSchema from './SCSetting.schema';

export const SECRETARIAT_PERCENTAGE = {
  acerta: 'Acerta',
  groupS: 'Group S',
  xerius: 'Xerius',
  securexIntegrity: 'Securex Integrity',
  partena: 'Partena',
  cnh: 'CNH',
  ucm: 'UCM',
  liantis: 'Liantis',
  entraide: "L'entraide",
  avixi: 'Avixi',
};

const currentYear = dayjs().year();

type SCSettingModalProps = {
  modalRef?: MutableRefObject<ModalApi>;
  defaultOpen?: boolean;
  trigger?: (api: ModalApi) => JSX.Element;
  tax: ITax;
  afterSubmit?: () => void;
};

enum Fields {
  expectedTaxableIncome = 'expectedTaxableIncome',
  taxableIncomeThreeYearsAgo = 'taxableIncomeThreeYearsAgo',
  quarterPayment = 'quarterPayment',
  provider = 'provider',
  startDate = 'startDate',
  isSubjectToSocialContributions = 'isSubjectToSocialContributions',
}

const SCSettingsModalRender: FC<
  SCSettingModalProps & { skipNavigation?: boolean }
> = ({ tax, afterSubmit, skipNavigation }) => {
  const { t } = useTranslation();
  const {
    expectedTaxableIncome,
    quarterPayment,
    provider,
    startDate,
    taxableIncomeThreeYearsAgo,
    isSubjectToSocialContributions,
  } = Fields;
  const { data: headerMeta } = useTaxHeaderMeta();

  const methods = useForm({
    defaultValues: {
      expectedTaxableIncome:
        get(tax, 'settings.expectedTaxableIncome') ||
        headerMeta?.projectedTaxableIncome,
      taxableIncomeThreeYearsAgo: get(
        tax,
        'settings.taxableIncomeThreeYearsAgo',
      ),
      quarterPayment: get(tax, 'settings.quarterPayment', 0),
      provider: get(tax, 'settings.provider'),
      startDate: dayjs(get(tax, 'settings.startDate'))?.format('YYYY-MM-DD'),
      isSubjectToSocialContributions: tax.type !== TaxTypeEnum.scExonerated,
    },
    resolver: joiResolver(SCSettingSchema, {
      allowUnknown: true,
      abortEarly: false,
    }),
  });

  const { data: user } = useGetCurrentCustomerData();
  const showIsExoneratedToggle = [
    AccountTypeEnum.natural_person_complementary,
    AccountTypeEnum.natural_person_student_no_vat,
    AccountTypeEnum.natural_person_student_vat,
  ].includes(user?.accountType as AccountTypeEnum);
  const navigate = useCustomNavigate();
  const { watch } = methods;
  const [isSubjectToSocialContributionsVal, startDateValue] = watch([
    isSubjectToSocialContributions,
    startDate,
  ]);
  const { mutateAsync: updateTax, isLoading } = useUpdateTaxMutation();

  return (
    <div className="items-center px-36 mt-6">
      <div className="text-center">
        <span className="font-bold text-primary-400 text-2xl">
          {t('category_social_contributions')}
        </span>
      </div>
      <div className="text-center">
        <span className="font-bold text-primary-700 text-3xl my-4">
          {t('taxes.social_contributions.setup.modal_title')}
        </span>
      </div>
      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-6 my-6"
          onSubmit={methods.handleSubmit((settings) =>
            updateTax({
              ...tax,
              settings,
            } as ITax)
              .then(() => {
                if (!skipNavigation) {
                  if (settings.isSubjectToSocialContributions) {
                    return navigate(
                      `/taxes/be/social-contribution-subject/${getPeriodSlug(
                        tax,
                      )}`,
                    );
                  } else {
                    return navigate(
                      `/taxes/be/social-contribution-exonerated/${currentYear}`,
                    );
                  }
                }
              })
              .then(() => afterSubmit?.()),
          )}
        >
          <FieldConnector
            name={isSubjectToSocialContributions}
            showUI={showIsExoneratedToggle}
          >
            {({ field }) => (
              <div className="flex items-center justify-between bg-primary-50 rounded-10 p-4">
                <div className="flex items-center gap-1">
                  <span className="self-start font-semibold text-lg text-primary-700">
                    {t('taxes.social_contributions.setup.exonerated')}
                  </span>
                  <div className="mt-0.5">
                    <Helper>
                      <Helper.Title>
                        {t(
                          'taxes.social_contributions.setup.exonerated_helper_title',
                        )}
                      </Helper.Title>
                      <Helper.Paragraph>
                        {t(
                          'taxes.social_contributions.setup.exonerated_helper_text',
                        )}
                      </Helper.Paragraph>
                    </Helper>
                  </div>
                </div>
                <Toggle
                  {...field}
                  onChange={(v) => field.onChange(!v)}
                  enabled={!field.value}
                />
              </div>
            )}
          </FieldConnector>
          <FieldConnector
            name={quarterPayment}
            showUI={isSubjectToSocialContributionsVal}
            label={t('taxes.social_contributions.setup.quarter_income')}
          >
            {({ field }) => (
              <PaymentAmount
                placeholder="€ 2000"
                {...field}
                onChange={(v) => field.onChange(v || 0)}
              />
            )}
          </FieldConnector>
          <FieldConnector
            name={provider}
            label={t('taxes.social_contributions.setup.provider')}
          >
            {({ field }) => (
              <Select
                {...field}
                options={Object.entries(SECRETARIAT_PERCENTAGE).map(
                  ([k, v]) => ({ label: v, value: k }),
                )}
                onChange={(opt) => field.onChange(opt.value)}
              />
            )}
          </FieldConnector>
          <FieldConnector
            name={startDate}
            showUI={isSubjectToSocialContributionsVal}
            label={t('taxes.social_contributions.setup.start_date')}
          >
            {({ field }) => (
              <DatePicker
                {...field}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                maxDate={dayjs().toDate()}
                selected={field.value ? new Date(field.value) : null}
                onChange={(value) =>
                  field.onChange(dayjs(value).format('YYYY-MM-DD'))
                }
                placeholder={`${dayjs().format('DD/MM/YYYY')}`}
              />
            )}
          </FieldConnector>
          <FieldConnector
            name={taxableIncomeThreeYearsAgo}
            showUI={
              !!(
                isSubjectToSocialContributionsVal &&
                startDateValue &&
                currentYear - dayjs(startDateValue).year() >= 3
              )
            }
            label={t('taxes.income_tax_payment.setup.taxable_income.title', {
              year: currentYear - 3,
            })}
          >
            {({ field }) => (
              <PaymentAmount
                placeholder="€35000"
                {...field}
                onChange={(v) => field.onChange(v || 0)}
              />
            )}
          </FieldConnector>
          <FieldConnector
            name={expectedTaxableIncome}
            showUI={isSubjectToSocialContributionsVal}
            label={t(
              'taxes.social_contributions.setup.taxable_income_computation',
            )}
          >
            {({ field }) => (
              <PaymentAmount
                placeholder="€35000"
                {...field}
                onChange={(v) => field.onChange(v || 0)}
              />
            )}
          </FieldConnector>
          <Button type="submit" loading={isLoading}>
            {t('taxes.done')}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default SCSettingsModalRender;
