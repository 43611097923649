import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { updateExpert } from 'api/v2/experts/authentication';
import profileIcon from 'assets/images/svgs/profile.svg';
import classNames from 'classnames';
import AccountableLogo from 'components/atoms/AccountableLogo';
import { Loader } from 'components/atoms/Loader';
import { Menu } from 'components/atoms/Menu';
import dayjs from 'dayjs';
import useGetLoggedInUserData from 'hooks/Authentication/useGetLoggedInUserData';
import logout from 'hooks/Authentication/useLogout';
import useFeatures from 'hooks/useFeatures';
import type { FC } from 'react';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFetching } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import type { IExpert } from 'types/experts.types';
import { getStoredAuthData } from 'utils/auth';
import { indexedLanguages, languagesIndexes } from 'utils/languages';
import layoutContext from '../Layout/layoutContext';

const LanguagesMenu = () => {
  const {
    t,
    i18n: { changeLanguage, resolvedLanguage, language },
  } = useTranslation();
  const availableLanguagesPerCountry = useFeatures(
    'settings.available_languages',
    languagesIndexes,
  ) as number[];
  const { pathname } = useLocation();

  const languages = (
    pathname === '/' ? languagesIndexes : availableLanguagesPerCountry
  ).map((langIndex: number) => indexedLanguages[langIndex]);

  useEffect(() => {
    const selectedLanguage = indexedLanguages.findIndex(
      (key: string) => key === language,
    );
    if (
      pathname !== '/' &&
      !availableLanguagesPerCountry.includes(selectedLanguage)
    ) {
      changeLanguage('en');
    }
  }, [language, availableLanguagesPerCountry, pathname]);

  return (
    <Menu
      Button={
        <div className="flex items-center">
          <span className="text-sm">{t(resolvedLanguage)}</span>
          <ChevronDownIcon width={17} className="ml-2" />
        </div>
      }
      position="right"
    >
      {languages.map((key: string) => (
        <Menu.Group key={key}>
          <Menu.Button
            onClick={() => {
              changeLanguage(key);
              updateExpert({ language: key as IExpert['language'] });
            }}
          >
            {t(key)}
          </Menu.Button>
        </Menu.Group>
      ))}
    </Menu>
  );
};

const AccountMenu = () => {
  const { data: expert } = useGetLoggedInUserData();
  const { t } = useTranslation();
  const tokenExpiry = getStoredAuthData('exp');

  return (
    <Menu
      Button={
        <div className="flex items-center pt-1">
          <img src={profileIcon} alt="Account Menu" className="w-4 mr-2" />
          <span className="text-sm">{expert?.email}</span>
          <ChevronDownIcon width={17} className="ml-2" />
        </div>
      }
      contentWrapperClassName="!w-72"
      position="right"
    >
      <Menu.Group>
        <Menu.Link to="/settings/members">{t('menu.settings')}</Menu.Link>
        <Menu.Button onClick={() => logout()}>
          {t('avatarmenu.logout')}
        </Menu.Button>
        <div>
          <p className="text-primary-800 text-sm">
            <p className="text-xs">
              {t('appheader.expires_in', {
                literalDelta: dayjs(
                  (tokenExpiry as unknown as number) * 1000,
                ).fromNow(),
              })}
            </p>
          </p>
        </div>
      </Menu.Group>
    </Menu>
  );
};

const TopPanel: FC<{ fullWidth?: boolean }> = ({ fullWidth }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const fetchingQueries = useIsFetching();
  const layout = useContext(layoutContext);

  return (
    <header
      className={classNames(
        'flex items-center justify-between fixed top-4 right-4 z-30 bg-white py-5 px-8 shadow-filters rounded-xl',
        {
          '!left-4': fullWidth,
        },
      )}
      style={{ left: layout.sidebarArea }}
    >
      {pathname !== '/' ? (
        <Link
          to="/"
          className="font-semibold text-sm flex flex-1 items-center hover:text-primary-800"
        >
          <ArrowNarrowLeftIcon className="w-4 mr-3" />
          {t('appheader.go_back_customers_list')}
        </Link>
      ) : (
        <AccountableLogo className="w-44 opacity-20" />
      )}

      <div className="flex items-center space-x-4">
        {fetchingQueries ? <Loader /> : null}

        <AccountMenu />

        <LanguagesMenu />
      </div>
    </header>
  );
};

export default TopPanel;
