import FreeTextInput from 'components/atoms/Form/FreeTextInput';
import type FreeTextInputProps from 'components/atoms/Form/FreeTextInput/FreeTextInput.types';
import type { FC } from 'react';
import useNumberField, { FormatOptions, Value } from './useNumberField';

export type NumberFieldProps = Omit<
  FreeTextInputProps,
  'onChange' | 'value'
> & {
  value: Value;
  onChange?: (value: number | null) => void; // make it required what will happen ?
  onBlur?: () => void;
  onFocus?: () => void;
  format?: FormatOptions;
  inputRef?: any;
  immedialtyCommitOnChange?: boolean;
};

const CustomNumberField: FC<NumberFieldProps> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  format,
  onKeyDown,
  immedialtyCommitOnChange,
  ...rest
}) => {
  const config = useNumberField({
    value,
    onChange,
    format,
    immedialtyCommitOnChange,
  });

  return (
    <FreeTextInput
      value={config.value}
      type="text"
      onFocus={(e) => {
        onFocus?.();
        config.onFocus();
      }}
      onKeyDown={(e) => {
        onKeyDown?.(e as any);
        config.onKeyDown(e);
      }}
      onChange={config.onChange}
      onBlur={() => {
        onBlur?.();
        config.onBlur();
      }}
      data-highlight-id={rest.highlightID}
      {...rest}
      // keep it off, the autocomplete on this field can be very confusing since we apply formatting based on the language and currency
      autoComplete="off"
    />
  );
};

const NumberField: FC<NumberFieldProps> = (props) => {
  return <CustomNumberField {...props} />;
};

export default NumberField;
