import { getTaxesHeaderCalcs } from 'api/v2/taxes';
import { useQuery } from 'react-query';

const useTaxHeaderMeta = (year?: number) => {
  return useQuery(
    ['TAX_HEADER_CALCULATIONS', year],
    () => getTaxesHeaderCalcs(year),
    {
      cacheTime: 0,
    },
  );
};

export default useTaxHeaderMeta;
