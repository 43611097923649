import type SVGType from 'types/svg.types';

const HomeIcon: SVGType = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.3101 5.68516L14.6631 4.92265L15.3101 5.68516ZM16.6903 5.68516L17.3373 4.92265L16.6903 5.68516ZM24.4236 12.2468L25.0706 11.4843L24.4236 12.2468ZM23.8002 13.0601V23.4996H25.8002V13.0601H23.8002ZM8.2002 23.4996V13.0601H6.2002V23.4996H8.2002ZM8.22373 13.0093L15.9571 6.44767L14.6631 4.92265L6.92978 11.4843L8.22373 13.0093ZM16.0433 6.44767L23.7767 13.0093L25.0706 11.4843L17.3373 4.92265L16.0433 6.44767ZM14.6669 19.6996H17.3335V17.6996H14.6669V19.6996ZM17.4002 19.7663V23.4996H19.4002V19.7663H17.4002ZM14.6002 23.4996V19.7663H12.6002V23.4996H14.6002ZM12.5335 23.5663H8.26686V25.5663H12.5335V23.5663ZM19.4669 25.5663H23.7335V23.5663H19.4669V25.5663ZM12.6002 23.4996C12.6002 23.5364 12.5703 23.5663 12.5335 23.5663V25.5663C13.6749 25.5663 14.6002 24.641 14.6002 23.4996H12.6002ZM17.4002 23.4996C17.4002 24.641 18.3255 25.5663 19.4669 25.5663V23.5663C19.43 23.5663 19.4002 23.5364 19.4002 23.4996H17.4002ZM17.3335 19.6996C17.3703 19.6996 17.4002 19.7295 17.4002 19.7663H19.4002C19.4002 18.6249 18.4749 17.6996 17.3335 17.6996V19.6996ZM14.6669 17.6996C13.5255 17.6996 12.6002 18.6249 12.6002 19.7663H14.6002C14.6002 19.7295 14.63 19.6996 14.6669 19.6996V17.6996ZM15.9571 6.44767C15.9819 6.42656 16.0184 6.42656 16.0433 6.44767L17.3373 4.92265C16.566 4.26823 15.4344 4.26823 14.6631 4.92265L15.9571 6.44767ZM6.2002 23.4996C6.2002 24.641 7.12548 25.5663 8.26686 25.5663V23.5663C8.23004 23.5663 8.2002 23.5364 8.2002 23.4996H6.2002ZM23.8002 23.4996C23.8002 23.5364 23.7703 23.5663 23.7335 23.5663V25.5663C24.8749 25.5663 25.8002 24.641 25.8002 23.4996H23.8002ZM25.8002 13.0601C25.8002 12.4532 25.5334 11.8769 25.0706 11.4843L23.7767 13.0093C23.7916 13.0219 23.8002 13.0405 23.8002 13.0601H25.8002ZM8.2002 13.0601C8.2002 13.0405 8.2088 13.0219 8.22373 13.0093L6.92978 11.4843C6.46698 11.8769 6.2002 12.4532 6.2002 13.0601H8.2002Z"
    />
  </svg>
);

export default HomeIcon;
