import {
  GrowthExperimentsEnum,
  GrowthExperimentType,
} from 'types/growthExperiments.types';

const growthExperiments = {
  experiments: [
    {
      name: GrowthExperimentsEnum.NEW_INVOICE_CREATION_SAVE_BUTTONS,
      type: GrowthExperimentType.IN_APP,
      id: 'G0071',
    },
    {
      name: GrowthExperimentsEnum.NEW_DOC_TO_REVIEW_EXPERIENCE,
      type: GrowthExperimentType.IN_APP,
      id: 'G0073',
    },
    {
      name: GrowthExperimentsEnum.CONSENT_OPENAI,
      type: GrowthExperimentType.IN_APP,
      id: 'CONSENT_OPENAI',
    },
    {
      name: GrowthExperimentsEnum.STARTER_HOME_PAGE,
      type: GrowthExperimentType.IN_APP,
      id: 'G0109',
    },
    {
      name: GrowthExperimentsEnum.UPLOAD_EXPENSE_HOME_WIDGET,
      type: GrowthExperimentType.IN_APP,
      id: 'P0003',
    },
    {
      name: GrowthExperimentsEnum.AI_CATEGORY_SUGGESTION,
      type: GrowthExperimentType.IN_APP,
      id: 'P0007',
    },
    {
      name: GrowthExperimentsEnum.TAX_CALCULATOR_IN_HOME_SCREEN,
      type: GrowthExperimentType.IN_APP,
      id: 'P0005',
    },

    // INTERCOM EXPERIMENTS
    {
      name: GrowthExperimentsEnum.INTERCOM_1,
      id: 'G1000',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_2,
      id: 'G1001',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_3,
      id: 'G1002',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_4,
      id: 'G1003',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_5,
      id: 'G1004',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_6,
      id: 'G1005',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_7,
      id: 'G1006',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_8,
      id: 'G1007',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_9,
      id: 'G1008',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_10,
      id: 'G1009',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_11,
      id: 'G1010',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_12,
      id: 'G1011',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_13,
      id: 'G1012',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_14,
      id: 'G1013',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_15,
      id: 'G1014',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_16,
      id: 'G1015',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_17,
      id: 'G1016',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_18,
      id: 'G1017',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_19,
      id: 'G1018',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_20,
      id: 'G1019',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_21,
      id: 'G1020',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_22,
      id: 'P0001',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_23,
      id: 'P0002',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.INTERCOM_24,
      id: 'G0078',
      type: GrowthExperimentType.IN_APP,
    },
    {
      name: GrowthExperimentsEnum.SMALL_BUSINESS_PLAN_25_DISCOUNT_GERMAN,
      id: 'P0008',
      type: GrowthExperimentType.IN_APP,
    },
  ],
};

export default growthExperiments;
