import { Title } from 'components/atoms/Title';
import { SizesEnum } from 'components/atoms/Title/Title.enum';
import type { FC } from 'react';
import type { FeedbackMessageProps } from './FeedbackMessage.types';

const FeedbackMessage: FC<FeedbackMessageProps> = ({
  title,
  message,
  feedbackMessageControllers,
}) => {
  return (
    <div className="flex justify-center h-full flex-col items-center text-center px-40">
      <Title type={SizesEnum.h3}>{title}</Title>
      <div className="mt-5">
        <Title type={SizesEnum.h5}>{message}</Title>
      </div>
      <div className="mt-5 flex space-x-5">{feedbackMessageControllers}</div>
    </div>
  );
};

export default FeedbackMessage;
