import { joiResolver } from '@hookform/resolvers/joi';
import { getExpenseDuplicates } from 'api/v1/expenses';
import { hasPayableTax } from 'api/v1/taxes';
import {
  addExpense,
  deleteExpenseById,
  getExpenseById,
  patchLockedExpense,
  updateExpenseById,
} from 'api/v2/expenses';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import Amount from 'components/molecules/CommonCells/Amount.component';
import CustomLoader from 'components/molecules/CustomLoader.component';
import { ExpenseLockedByAccountant } from 'components/molecules/DocumentLockByAccountant.compopnent';
import { ExpenseLockByTaxes } from 'components/molecules/DocumentLockByTaxes.compopnent';
import { Helper } from 'components/molecules/Helper';
import SimpleError from 'components/molecules/SimpleError.component';
import type { SlideOver2Props } from 'components/molecules/SlideOver2.component';
import SlideOver2 from 'components/molecules/SlideOver2.component';
import { DocumentDuplicateFinder } from 'components/organisms/DocumentDuplicateFinder';
import SCSettingsModalRender from 'components/pages/Taxes/Tax/Belgium/SocialContribution/SCSettingRender';
import dayjs from 'dayjs';
import { AnimatePresence } from 'framer-motion';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useCustomrtCountry from 'hooks/shared/useCustomerCountry';
import { worldThemeContext } from 'hooks/shared/useWorldTheme';
import useConfirm from 'hooks/useConfirm';
import useExpensesCategories from 'hooks/useExpensesCategories';
import useFeatures from 'hooks/useFeatures';
import type {
  CloseMeta,
  FormSlideOverManagerOptions,
} from 'hooks/useFormSlideOverManager';
import {
  forceClose,
  naturalClose,
  useFormSlideOverManager,
} from 'hooks/useFormSlideOverManager';
import { HighlightProvider } from 'hooks/useHighlight';
import useModal from 'hooks/useModal';
import useUpdateSidebarDocCounts from 'hooks/useUpdateSidebarDocCounts';
import appI18nInstance from 'i18n';
import { get, isEmpty } from 'lodash';
import type { FC } from 'react';
import { useId, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { SidebarDocCountsKeysEnum } from 'store/sidebarState';
import { ExpensesCacheKeys } from 'types/cacheKeys.types';
import {
  ECreatedFrom,
  type IExpense,
  type Supplier,
} from 'types/expenses.types';
import type { LockStatusType, Period } from 'types/global.types';
import type { AvailableCountries, IUser } from 'types/users.types';
import * as ExpensesEvents from 'utils/amplitude/events/expenses.amplitude';
import { CATEGORY_SOCIAL_CONTRIBUTIONS } from 'utils/categories';
import { isWeb } from 'utils/constants';
import formatMoney from 'utils/formatMoney';
import { isDocumentLockedByTaxesOrAccountant } from 'utils/helpers';
import { TrashIcon } from 'utils/icons';
import { ONE_SECOND } from 'utils/time';
import AmortizationDeleteModalContent from './AmortizationDeleteModalContent';
import AmortizationEditModalContent from './AmortizationEditModalContent';
import AttachmentController from './AttachmentController';
import FileViewer from './FlieViewer';
import Form from './Form';
import useFormMeta from './useFormMeta';
import {
  adaptExpenseWithMeta,
  cleanExpenseDataForSubmit,
  getDefaultValues,
  utilityVehicleWarning,
} from './utils';
import { getValidationSchema } from './validationSchema';

export type ExpenseInput = string | Partial<IExpense>;

type ExtraFormWrapperProps = {
  allowDynamicTypeSwitching?: boolean;
  afterSubmit?: (result: any, originalDoc: any) => any;
  afterDelete?: () => void;
  disabledFields?: string[] | '*';
  notDisabledFields?: string[];
  onEditVehicles?: () => void;
  onClose: (meta: CloseMeta) => void;
};

export type ExpenseFormWrapperProps = {
  expense: ExpenseInput;
  onChangeExpense: (_id: string) => void;
  isFormDirtyRef: any;
} & ExtraFormWrapperProps &
  Partial<Omit<SlideOver2Props, 'onClose'>>;

const unSavedChangesDefaultSettings = {
  className: 'text-center font-semibold',
  buttonsWrapperClassName: 'justify-center',
  buttonClassName: 'min-w-[90px]',
};

const formatTitle = (
  id: string | undefined,
  supplier: Supplier,
  isCreditNote: boolean,
  country?: AvailableCountries,
) => {
  if (!id) {
    if (!isCreditNote) return appI18nInstance.t('expense.create_an_expense');

    return appI18nInstance.t(`invoices.new.credit_note.${country}`);
  }

  return supplier?.name || appI18nInstance.t('expenses.cells.unknown_supplier');
};

const FormWrapper: FC<ExpenseFormWrapperProps> = ({
  expense,
  onClose,
  onChangeExpense,
  isFormDirtyRef,
  afterSubmit,
  afterDelete,
  disabledFields = [],
  onEditVehicles,
  allowDynamicTypeSwitching,
}) => {
  const { t } = useTranslation();
  const deductibleTaxCategoriesSlugs: string[] = useFeatures(
    'expenses.deductible_tax_categories',
  );
  const customer = useGetCurrentCustomerData().data as IUser;

  const metaAPI = useFormMeta();

  const { getItemMeta } = metaAPI;

  const formAPI = useForm({
    mode: 'onChange',
    defaultValues: {} as any, // we depend on it to be empty object on the start so we know its not ready yet
    resolver: joiResolver(getValidationSchema(metaAPI), {
      allowUnknown: true,
      abortEarly: false,
    }),
  });

  const [showAttachment, setShowAttachment] = useState(false);

  const updateSidebarCounts = useUpdateSidebarDocCounts();

  const expenseAPI = useQuery(
    [ExpensesCacheKeys.ExpenseDetails, useId()],
    () => {
      if (typeof expense !== 'string') return expense;
      return getExpenseById(expense);
    },
    {
      cacheTime: 0,
      onSuccess: (data) => {
        const _initValues = getDefaultValues(
          data,
          customer,
        ) as Partial<IExpense>;

        formAPI.reset(adaptExpenseWithMeta(_initValues, metaAPI));

        setShowAttachment(!!formAPI.getValues('filePath'));
      },
    },
  );

  const categoriesAPI = useExpensesCategories();

  const isLoading = expenseAPI.isLoading || categoriesAPI.isLoading;
  const isError = expenseAPI.isError || categoriesAPI.isError;
  const retry = () => {
    if (expenseAPI.isError) expenseAPI.refetch();
    if (categoriesAPI.isError) categoriesAPI.refetch();
  };

  const [modalContent, modalApi] = useModal({
    dialogPanalClassName: '!p-0',
    name: '',
  });
  const [confirmContent, confirmApi] = useConfirm();
  const [confirmContentSC, confirmApiSC] = useConfirm();
  const [scSettingContent, scSettingModalAPI] = useModal({
    name: 'social contribution settings modal',
    width: '4xl',
  });
  const isDirty = !isEmpty(formAPI.formState.dirtyFields);

  useImperativeHandle(isFormDirtyRef, () => isDirty, [isDirty]);

  const [filePath, items, isCreditNote, supplier, reviewStatus] = formAPI.watch(
    [
      'filePath',
      'items',
      'isCreditNote',
      'supplier',
      'accountantReview.reviewStatus',
    ],
  );

  const isLockedByAccountant = isWeb && reviewStatus === 'reviewed';
  const isLockedByTaxes = !!expenseAPI.data?.taxLock?.length;

  const isLocked = isLockedByAccountant || isLockedByTaxes;

  const country = useCustomrtCountry();

  const onSubmit = (values: IExpense) => {
    return new Promise((resolve) => {
      if (!values._id) {
        return resolve(
          addExpense(values).then((data) => {
            updateSidebarCounts(SidebarDocCountsKeysEnum.NewExpensesCount);
            return data;
          }),
        );
      }
      if (isLocked) {
        return resolve(patchLockedExpense(values._id, values));
      }
      return resolve(updateExpenseById(values._id, values));
    }).then((data) => {
      const result = data as IExpense;
      return Promise.resolve(afterSubmit?.(result, expenseAPI.data))
        .catch()
        .finally(() => {
          const shouldMarkUtilityVehicleWarningAsSeen = result.items.some(
            (item) =>
              getItemMeta(result, item).helpers
                .shouldMarkUtilityVehicleWarningAsSeen,
          );
          if (shouldMarkUtilityVehicleWarningAsSeen) {
            utilityVehicleWarning.markAsSeen();
          }
          onClose(forceClose);
        });
    });
  };

  const onSubmitWrapper = async () => {
    let values = cleanExpenseDataForSubmit(formAPI.getValues()) as IExpense;

    // if locked, skip all checks, since we are only updating simple fields like the notes or the transactions
    if (isLocked) return onSubmit(values);

    const withDeductibleTaxCategoryItem = values.items.find(
      (item) => !!deductibleTaxCategoriesSlugs.includes(item.categoryId),
    );
    if (withDeductibleTaxCategoryItem) {
      const { isPayable, tax } = await hasPayableTax(
        withDeductibleTaxCategoryItem.categoryId,
        values.taxPeriod as Period,
        customer.country,
      );
      if (isPayable) {
        const isSocialContributions =
          CATEGORY_SOCIAL_CONTRIBUTIONS ===
          withDeductibleTaxCategoryItem.categoryId;
        const shouldComplete = await new Promise((resolve) => {
          const isDifferentSCAmount = values.items.find(
            (item) =>
              isSocialContributions &&
              item?.amount !== get(tax, 'settings.quarterPayment'),
          );
          if (isDifferentSCAmount && isSocialContributions) {
            confirmApi.open({
              message: t('taxes.sc_settings.different_amount', {
                scExpenseAmount: formatMoney(isDifferentSCAmount?.amount),
                scQuarterAmount: formatMoney(
                  get(tax, 'settings.quarterPayment'),
                ),
              }),
              okText: t('yes'),
              cancelText: t('no'),
              buttonsWrapperClassName: 'justify-end',
              modalOptions: {
                width: 'xl',
                name: 'different social contributions amount',
                onClose: () => resolve(true),
              },
              onConfirm: () => {
                scSettingModalAPI.open(() => (
                  <SCSettingsModalRender
                    tax={tax}
                    defaultOpen={true}
                    skipNavigation
                    afterSubmit={() => {
                      scSettingModalAPI.close();
                      resolve(true);
                    }}
                  />
                ));
              },
              onDecline: () => {
                resolve(true);
              },
            });
          } else {
            resolve(true);
          }
        });
        if (!shouldComplete) return;
        const shouldContinue = await new Promise((resolve) => {
          confirmApiSC.open({
            message: t(
              isSocialContributions
                ? 'transactions.mark_tax_done.socialContributions.confirmation'
                : 'transactions.mark_tax_done.VATPayment.confirmation',
            ),
            okText: t('yes'),
            cancelText: t('no'),
            buttonsWrapperClassName: 'justify-end',
            modalOptions: {
              width: 'xl',
              name: 'update expense category should mark tax item as done',
              onClose: () => resolve(false),
            },
            onConfirm: () => {
              values = { ...values, shouldUpdateTaxStatus: true };
              resolve(true);
            },
            onDecline: () => {
              values = { ...values, shouldUpdateTaxStatus: false };
              resolve(true);
            },
          });
        });
        if (!shouldContinue) return;
      }
    }
    const hasAssets = expenseAPI.data?.items?.some((item) => item.isAsset);
    if (hasAssets) {
      return modalApi.open(
        () => (
          <AmortizationEditModalContent
            onConfirm={() => onSubmit(values).then(modalApi.close)}
            onCancel={modalApi.close}
          />
        ),
        { width: '5xl', name: 'edit amortization' },
      );
    }
    return onSubmit(values);
  };

  const onDelete = (
    expense: Partial<IExpense> & { _id: string },
  ): Promise<boolean> => {
    const expenseHasAssets = expense.items?.some((item) => item.isAsset);
    return new Promise((resolve) => {
      if (expenseHasAssets) {
        return modalApi.open(
          () => (
            <AmortizationDeleteModalContent
              onDelete={() =>
                deleteExpenseById(expense._id).then(() => {
                  modalApi.close();
                  resolve(true);
                })
              }
              onCancel={() => {
                modalApi.close();
                resolve(false);
              }}
            />
          ),
          { width: '5xl', name: 'delete amortization' },
        );
      }
      confirmApi.open({
        ...unSavedChangesDefaultSettings,
        message: t('expenses.confirm_delete'),
        onConfirm: () =>
          deleteExpenseById(expense._id).then(() => resolve(true)),
        onDecline: () => {
          confirmApi.close();
          resolve(false);
        },
      });
    });
  };

  const onAttachmentUploaded = ({ key }: any) => {
    formAPI.setValue('filePath', key);
    formAPI.setValue('files', [{ path: key }]);
    setShowAttachment(true);
  };

  return (
    <>
      {modalContent}
      {confirmContent}
      {confirmContentSC}
      {scSettingContent}
      <SlideOver2
        showLeftPanelUI={showAttachment}
        rightPanelCoverContent={
          <p className="absolute bottom-[20%] text-xl font-avenir font-bold text-center px-10">
            {t('expenses.already_validated_by_accountant')}
          </p>
        }
        onClose={() => onClose(naturalClose)}
        renderLeftPanel={() => {
          if (!filePath) return null;
          return (
            <FileViewer
              floatingItemsMargin="var(--rightPaneWidth)"
              tracing={{
                onDownload: ExpensesEvents.WEBAPP_EXPENSE_CLICKEDDOWNLOAD,
                onReplace: ExpensesEvents.WEBAPP_EXPENSE_CLICKEDREPLACE,
                onDelete: ExpensesEvents.WEBAPP_EXPENSE_CLICKEDDELETEATTACHMENT,
              }}
              filePath={filePath}
              onReplace={onAttachmentUploaded}
              disabled={isLocked}
              onDelete={() => {
                formAPI.setValue('filePath', null);
                formAPI.setValue('files', []);
                setShowAttachment(false);
              }}
            />
          );
        }}
        renderRightPanel={() => {
          if (isLoading) {
            return <CustomLoader className="h-full" />;
          }
          if (isError) {
            return <SimpleError messageClassName="!text-xl" onRetry={retry} />;
          }
          if (
            !expenseAPI.data ||
            !categoriesAPI.data ||
            isEmpty(formAPI.getValues())
          ) {
            return null;
          }

          return (
            <div>
              <Form
                formAPI={formAPI}
                expense={expenseAPI.data}
                categories={categoriesAPI.data}
                isLocked={isLocked}
                onSubmit={onSubmitWrapper}
                disabledFields={isLocked ? '*' : disabledFields}
                notDisabledFields={isLocked ? ['transactions', 'notes'] : []}
                onEditVehicles={onEditVehicles}
                allowDynamicTypeSwitching={allowDynamicTypeSwitching}
                headerMeta={
                  <div className="relative">
                    {expenseAPI.data.isFake && (
                      <Alert
                        type="warning"
                        description={t('expense.warning_bubble.fake_expense')}
                        showIntercomBtn
                      />
                    )}
                    {isLockedByTaxes && (
                      <ExpenseLockByTaxes
                        taxLock={expenseAPI.data.taxLock as LockStatusType}
                        isCreditNote={isCreditNote}
                      />
                    )}
                    {isLockedByAccountant && !isLockedByTaxes && (
                      <ExpenseLockedByAccountant isCreditNote={isCreditNote} />
                    )}
                    {expenseAPI.data.guessedIsFraudDetected && (
                      <Alert
                        type="warning"
                        description={t('expenses.warning_bubble.fraud')}
                        showIntercomBtn
                      />
                    )}
                    {!!expenseAPI.data._id &&
                      expenseAPI.data.userSnapshot?.VATType !==
                        customer.VATType && (
                        <Alert
                          type="warning"
                          name="diff vat type warning"
                          showIntercomBtn
                          description={
                            <div className="flex gap-4">
                              <span>
                                {t(
                                  'document.warning_bubble.diff_vattype.title',
                                  {
                                    snapshotVATType: t(
                                      'VATType.' +
                                        expenseAPI.data.userSnapshot?.VATType,
                                    ),
                                    currentVATType: t(
                                      'VATType.' + customer.VATType,
                                    ),
                                  },
                                )}
                              </span>
                              <Helper>
                                <Helper.Paragraph>
                                  {t(
                                    'document.warning_bubble.diff_vattype.helper',
                                  )}
                                </Helper.Paragraph>
                              </Helper>
                            </div>
                          }
                        />
                      )}
                    <h1 className="font-avenir text-5xl text-primary-700 font-bold first-letter:capitalize tracking-tight leading-tight truncate mb-4">
                      {formatTitle(
                        expenseAPI.data._id,
                        supplier,
                        isCreditNote,
                        country,
                      )}
                    </h1>
                    <div className="flex gap-2 border-b border-primary-100 pb-5 mb-5">
                      <AttachmentController
                        onHide={() => setShowAttachment(false)}
                        onShow={() => setShowAttachment(true)}
                        onUploaded={onAttachmentUploaded}
                        isShow={showAttachment}
                        hasFile={!!filePath}
                        disabled={isLocked}
                      />
                      <span className="text-primary-400">{'/'}</span>
                      <span className="flex gap-1 text-primary-700">
                        <Amount
                          amount={
                            (items || []).reduce((acc: number, item: any) => {
                              const amount = acc + (item.amount || 0);
                              return amount;
                            }, 0) * (isCreditNote ? -1 : 1)
                          }
                        />
                        {t('incl_vat')}
                      </span>
                    </div>
                    <DocumentDuplicateFinder
                      className="mb-4"
                      onFetch={async () => {
                        if (expenseAPI.data._id) {
                          return getExpenseDuplicates(expenseAPI.data._id);
                        }
                        return Promise.resolve([]);
                      }}
                      renderLabel={(duplicate) =>
                        `${
                          duplicate.supplier?.name ||
                          t('expenses.cells.unknown_supplier')
                        }: ${formatMoney(duplicate.amount || 0)}, ${
                          dayjs(duplicate.expenseDate).format('DD/MM/YYYY') ||
                          t('unknown')
                        }`
                      }
                      viewText={t('expense.view')}
                      deleteText={t('expense.delete')}
                      onSelect={(_id) => onChangeExpense(_id)}
                      getShouldShowDeleteButton={(dup) =>
                        !isDocumentLockedByTaxesOrAccountant(dup)
                      }
                      onDelete={(duplicate) =>
                        onDelete(duplicate as IExpense).then((isDeleted) => {
                          if (isDeleted) afterDelete?.();
                          return isDeleted;
                        })
                      }
                    />
                  </div>
                }
                footerMeta={
                  <div className="border-t border-primary-100 pt-5 mt-5">
                    <p className="text-right text-primary-400 text-sm leading-tight">
                      {t(
                        expenseAPI.data.createdFrom
                          ? 'creation_method_and_date'
                          : 'creation_date',
                        {
                          type: t(
                            expenseAPI.data.imported ? 'imported' : 'created',
                          ),
                          createdFrom:
                            expenseAPI.data.createdFrom === ECreatedFrom.peppol
                              ? t('peppol.title.' + country)
                              : t(expenseAPI.data.createdFrom as string),
                          date: dayjs(expenseAPI.data.created).format('ll'),
                        },
                      )}
                    </p>
                    {!isLocked && expenseAPI.data._id && (
                      <Button
                        structure="text"
                        tracingEvent={
                          ExpensesEvents.WEBAPP_EXPENSE_CLICKEDDELETE
                        }
                        onClick={() =>
                          onDelete(expenseAPI.data as any).then((isDeleted) => {
                            if (isDeleted) {
                              onClose(forceClose);
                              afterDelete?.();
                            }
                          })
                        }
                      >
                        <TrashIcon className="w-5 mr-2" />
                        <span className="font-semibold">{t('delete')}</span>
                      </Button>
                    )}
                  </div>
                }
              />
            </div>
          );
        }}
      />
    </>
  );
};

type ExpenseFormSlideOverProps = FormSlideOverManagerOptions<ExpenseInput> &
  Omit<ExtraFormWrapperProps, 'onClose'>;

const ExpenseFormSlideOver: FC<ExpenseFormSlideOverProps> = ({
  apiRef,
  onClose,
  onOpen,
  disabledFields,
  afterSubmit,
  afterDelete,
  onEditVehicles,
  enableNavigationBlockingIfDirty,
  allowDynamicTypeSwitching,
}) => {
  const { doc, api, isFormDirtyRef, confirmContent, options, formKey } =
    useFormSlideOverManager<ExpenseInput, Partial<ExpenseFormWrapperProps>>({
      enableNavigationBlockingIfDirty,
      apiRef,
      onClose,
      onOpen,
    });

  const _afterSubmit = options?.afterSubmit || afterSubmit;

  return (
    <worldThemeContext.Provider value="expenses">
      {confirmContent}
      <AnimatePresence mode="wait">
        {!!doc && (
          <HighlightProvider
            key={formKey}
            unHighlightTimer={2 * ONE_SECOND}
            className="animate-taxesPulse rounded-lg"
          >
            <FormWrapper
              expense={doc}
              onClose={api.close}
              onChangeExpense={api.open}
              isFormDirtyRef={isFormDirtyRef as any}
              afterDelete={afterDelete}
              disabledFields={disabledFields}
              onEditVehicles={onEditVehicles}
              allowDynamicTypeSwitching={allowDynamicTypeSwitching}
              {...options}
              afterSubmit={(...args) => {
                if (!_afterSubmit) return;
                api.pauseDirtyTracking();
                api.pauseNavigationBlocking();
                return Promise.resolve(_afterSubmit(...args)).finally(() => {
                  api.resumeDirtyTracking();
                  api.resumeNavigationBlocking();
                });
              }}
            />
          </HighlightProvider>
        )}
      </AnimatePresence>
    </worldThemeContext.Provider>
  );
};

export default ExpenseFormSlideOver;
