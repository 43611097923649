export enum World {
  EXPENSES = 'expenses',
  REVENUES = 'revenues',
  TAXES = 'taxes',
  BANK = 'bank',
  SETTINGS = 'settings',
}

export enum SubWorld {
  QUOTES = 'quotes',
  CLIENTS = 'clients',
}

export type color = 'expenses' | 'revenues' | 'taxes' | 'bank' | 'primary';
