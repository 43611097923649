import dayjs from 'dayjs';

const isReducedVATRateApplicable = (invoiceDate: any) =>
  !!invoiceDate &&
  dayjs(invoiceDate).isBetween(
    '2020-06-30T22:00:01.171Z',
    '2021-01-01T01:59:59.171Z',
  );

export default isReducedVATRateApplicable;
