// * Based on backend first page index starts from 1
export const INITIAL_PAGE = 1;
export const ITEMS_ON_PAGE = 50;

export const paginatedFirstItem = (
  pageNumber: number,
  numberOfItemsOnPage: number,
  perPage: number,
): number => perPage * (pageNumber - 1) + (numberOfItemsOnPage === 0 ? 0 : 1);

export const paginatedLastItem = (
  pageNumber: number,
  numberOfItemsOnPage: number,
  numberOfTotalItems: number,
  perPage: number,
): number =>
  Math.min(
    perPage * (pageNumber - 1) + numberOfItemsOnPage,
    numberOfTotalItems,
  );
