enum SubscriptionPlans {
  TRIAL = 'eu.accountable.trial1',
  PRO1 = 'eu.accountable.pro1',
  PRO12 = 'eu.accountable.pro12',
  BASIC1 = 'eu.accountable.basic1',
  BASIC12 = 'eu.accountable.basic12',
  FREE = 'eu.accountable.free',
  AMBASSADOR = 'eu.accountable.ambassador',
}

export default SubscriptionPlans;
