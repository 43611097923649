import 'globalEffects';
// keep global effects at top //
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import appQueryClient from 'api/appQueryClient';
import ReportUTMs from 'components/atoms/ReportUTMs';
import { ExpensesUploaderProvider } from 'components/organisms/FileUploader/FileUploader.component';
import { TrustpilotReview } from 'components/organisms/TrustpilotReview';
import ErrorPage from 'components/pages/Error.component';
import * as config from 'config';
import i18n from 'i18n';
import 'index.css';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import 'react-loading-skeleton/dist/skeleton.css';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import openAIStoreCreator from 'store/openAI.store';
import persistAmounts from 'store/persistAmounts';
import taxOnboardingStoreCreator from 'store/taxOnboarding.store';
import UIEffectsCache from 'store/UIEffectsCache';
import 'theme/fonts.scss';

export const useUIEffectsCache = UIEffectsCache();
export const useAmountsCache = persistAmounts();
export const useOpenAIConsent = openAIStoreCreator();
export const useTaxOnboardingStore = taxOnboardingStoreCreator();

let App = require(`./applications/${config.product}/App`).default; // eslint-disable-line @typescript-eslint/no-var-requires
App = Sentry.withProfiler(App);
App = Sentry.withErrorBoundary(App, {
  fallback: (props) => <ErrorPage {...props} />,
});

// Only if not inside an iframe, render the app.
if (window == window.parent) {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={appQueryClient}>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENTID as string}
          >
            <ExpensesUploaderProvider>
              <ReactQueryDevtools />
              <BrowserRouter>
                <ReportUTMs />
                <TrustpilotReview />
                <App />
              </BrowserRouter>
              <ToastContainer
                toastClassName={() => 'p-0'}
                className="p-0 bg-none"
              />
            </ExpensesUploaderProvider>
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </>,
  );
}
