import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { getOptionsDict } from '../helpers';
import type { SelectConfig } from '../select.types';

const withOptionsDict = (SelectComponent: FC<SelectConfig>) =>
  function WithOptionsState(props: SelectConfig) {
    const { onChange, optionsDict: parentOptionsDict } = props;

    const [innerOptionsDict, setOptionsDict] = useState<{
      [key: string]: any;
    }>();

    const optionsDict = useMemo(() => {
      return { ...innerOptionsDict, ...parentOptionsDict };
    }, [parentOptionsDict, innerOptionsDict]);

    const newProps: Partial<SelectConfig> = {
      optionsDict,
      onChange: (newValue, actionMeta) => {
        onChange?.(newValue, actionMeta);

        if (newValue) {
          setOptionsDict(getOptionsDict([].concat(newValue as any)));
        }
      },
    };

    return <SelectComponent {...props} {...newProps} />;
  };

export default withOptionsDict;
