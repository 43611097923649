// import getUserLocale from 'get-user-locale';
// import {
//   getUserSavedLocale,
//   parseMissingKeyHandler,
//   sharedConfig,
// } from 'i18n/config';
// import occupations from 'i18n/occupations';

import appI18nInstance from 'i18n';

// import i18next, { type InitOptions } from 'i18next';
// import { pick } from 'lodash';
// import { initReactI18next } from 'react-i18next';
// import { AvailableLanguages } from 'types/users.types';

// const ins = i18next.createInstance();
// ins.use(initReactI18next).init({
//   supportedLngs: Object.values(AvailableLanguages),
//   lng: getUserSavedLocale() || getUserLocale()?.split('-')[0],
//   fallbackLng: AvailableLanguages.en,
//   ...pick(sharedConfig, ['interpolation', 'keySeparator']),
//   parseMissingKeyHandler: parseMissingKeyHandler(ins),
//   resources: {
//     en: { translation: { ...en, ...occupations.en } },
//     de: { translation: { ...de, ...occupations.de } },
//     fr: { translation: { ...fr, ...occupations.fr } },
//     nl: { translation: { ...nl, ...occupations.nl } },
//   },
// } as InitOptions);

export default appI18nInstance;
