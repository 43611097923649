import { signinAuthContinuation } from 'api/v2/users';
import { getGrowthExperimentsWithGroups } from 'hooks/useAssignGrowthABGroups';
import useCustomNavigate from 'hooks/useCustomeNavigate';
import _get from 'lodash/get';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import { UserCacheKeys } from 'types/cacheKeys.types';
import getUserFeatures from 'utils/getUserFeatures';

const useAuthContinuation = (): { isLoading: boolean } => {
  const queryClient = useQueryClient();
  const authContinToken = new URLSearchParams(useLocation().search).get(
    'auth_continuation_token',
  );
  const navigate = useCustomNavigate();
  return useQuery(
    'AUTH-CONTINUATION',
    () => signinAuthContinuation(authContinToken as string),
    {
      enabled: !!authContinToken,
      onSuccess: ({ data: user, clientId, ...authData }) => {
        const currentClientId = localStorage.getItem('clientId');

        // DON'T REMOVE! VERY EXPECTED TO USE THIS CODE WITH NEW FUTURE GROWTH EXPERIMENTS
        const growthExperiments = _get(
          getUserFeatures(user),
          'growthExperiments.experiments',
        );
        const growthExperimentsWithGroups = getGrowthExperimentsWithGroups(
          growthExperiments,
          null,
          user?._id,
        );

        if (clientId && !currentClientId) {
          localStorage.setItem('clientId', clientId);
        }

        authData.exp = authData.exp - authData.iat + Date.now() / 1000;

        queryClient.setQueryData([UserCacheKeys.UserData], user);
        localStorage.setItem('auth', JSON.stringify(authData));

        navigate('/');
      },
    },
  );
};

export default useAuthContinuation;
