import { Section } from 'types/global.types';
import type { InvoiceType } from 'types/invoice.types';

const period = {
  priority_date_key: ({
    section,
  }: {
    section: Section;
    type?: InvoiceType;
  }): 'paymentDate' | 'invoiceDate' | 'expenseDate' =>
    section === Section.expenses ? 'expenseDate' : 'invoiceDate',
  priority_date: ({
    documentDate,
  }: {
    documentDate: Date;
    paymentDate?: Date;
    section: Section;
    type?: InvoiceType;
  }): Date => documentDate,
  is_period_dropdown_disabled: (): boolean => false,
  period_dropdown_placeholder: (): string => 'period.field.select',
};

export default period;
