import { AvailableCountries, AvailableLanguages } from 'types/users.types';

const languages = [
  {
    label: 'en',
    value: 'en',
  },
  {
    label: 'fr',
    value: 'fr',
  },
  {
    label: 'nl',
    value: 'nl',
  },
  {
    label: 'de',
    value: 'de',
  },
];

export const languagesPerCountry = {
  [AvailableCountries.belgium]: [
    {
      label: 'en',
      value: 'en',
    },
    {
      label: 'fr',
      value: 'fr',
    },
    {
      label: 'nl',
      value: 'nl',
    },
  ],
  [AvailableCountries.germany]: [
    {
      label: 'en',
      value: 'en',
    },
    {
      label: 'de',
      value: 'de',
    },
  ],
};

export const indexedLanguages = [
  AvailableLanguages.en,
  AvailableLanguages.fr,
  AvailableLanguages.nl,
  AvailableLanguages.de,
] as string[];

export const languagesIndexes = [0, 1, 2, 3];

export const getLangIndex = (lang: AvailableLanguages) => {
  return indexedLanguages.indexOf(lang);
};

export default languages;
