import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import WarningModalContent from './WarningModalContent';

type AmortizationDeleteModalContentProps = {
  onDelete: () => Promise<any>;
  onCancel: () => void;
};

const AmortizationDeleteModalContent: FC<
  AmortizationDeleteModalContentProps
> = ({ onDelete, onCancel }) => {
  const { t } = useTranslation();
  const { isLoading, mutate } = useMutation(() => onDelete());

  return (
    <WarningModalContent
      onChoose={(shouldDelete) => (shouldDelete ? mutate() : onCancel())}
      description={t('expense.amortization.delete_warning')}
      options={[
        {
          value: true,
          isLoading,
          title: t('expense.amortization.delete.title'),
          description: t('expense.amortization.delete.subtitle'),
        },
        {
          value: false,
          title: t('expense.amortization.leave_as_is.title'),
          description: t('expense.amortization.leave_as_is.subtitle.delete'),
        },
      ]}
    />
  );
};

export default AmortizationDeleteModalContent;
