import { VATTypeEnum } from 'types/users.types';
import { germanStatesArr } from 'utils/defaults';

const { subjectToVAT, franchisee, exempt } = VATTypeEnum;

const getVATTypeOptions = (country: string) => [
  {
    value: subjectToVAT,
    label: 'settings.companyDetails.subjectToVAT',
  },
  {
    value: franchisee,
    label: `settings.companyDetails.${country}.franchisee`,
  },
  {
    value: exempt,
    label: 'settings.companyDetails.i_am_exempt_profession',
  },
];

const complementaryDaysOptions = [
  {
    value: 1,
    label: 'settings.companyDetails.activity.one_day_a_week',
  },
  {
    value: 2,
    label: 'settings.companyDetails.activity.two_days_a_week',
  },
  {
    value: 3,
    label: 'settings.companyDetails.activity.tree_day_a_week',
  },
  {
    value: 4,
    label: 'settings.companyDetails.activity.four_day_a_week',
  },
  {
    value: 5,
    label: 'settings.companyDetails.activity.five_day_a_week',
  },
];

const deletionReasonsOptions = [
  {
    label: 'settings.delete_account.option_expensive',
    value: 'settings.delete_account.option_expensive',
  },
  {
    label: 'settings.delete_account.option_not_self_employed',
    value: 'settings.delete_account.option_not_self_employed',
  },
  {
    label: 'settings.delete_account.option_missing_features',
    value: 'settings.delete_account.option_missing_features',
  },
  {
    label: 'settings.delete_account.option_buggy',
    value: 'settings.delete_account.option_buggy',
  },
  {
    label: 'settings.delete_account.option_other',
    value: 'settings.delete_account.option_other',
  },
];

const germanStatesOptions = germanStatesArr.map(
  ({ label }: { label: string }) => ({
    label,
    value: label,
  }),
);

const firstTaxComputationYearOptions = (
  firstTaxComputationYear: number,
): any[] => {
  const currentYear = new Date().getFullYear();
  const baseYear = 2020;

  const years = [];

  for (let i = baseYear; i <= currentYear; i++) {
    years.push(i);
  }

  if (firstTaxComputationYear < baseYear) {
    years.unshift(firstTaxComputationYear);
  }

  return years.map((year) => ({
    label: year,
    value: year,
  }));
};

export {
  getVATTypeOptions,
  complementaryDaysOptions,
  deletionReasonsOptions,
  germanStatesOptions,
  firstTaxComputationYearOptions,
};
