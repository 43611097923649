import classNames from 'classnames';
import useWorldTheme from 'hooks/shared/useWorldTheme';

import type { FC } from 'react';
import { getTracingEvents } from 'utils/amplitude';
import type { CheckboxProps } from './Checkbox.types';

const themes = {
  bank: 'focus:ring-bank text-bank',
  expenses: 'focus:ring-expenses text-expenses',
  revenues: 'focus:ring-revenues text-revenues',
  taxes: 'focus:ring-taxes text-taxes',
  primary: 'focus:ring-primary text-primary',
};

const Checkbox: FC<CheckboxProps> = ({
  className,
  color,
  disabled,
  onChange,
  tracingEvent,
  ...props
}) => {
  const tracingEvents = getTracingEvents(
    tracingEvent ? { onChange: tracingEvent } : {},
    { onChange },
  );
  const theme = useWorldTheme();
  const themeColor = color || themes[theme];
  return (
    <input
      {...props}
      type="checkbox"
      className={classNames(
        ' h-3.5 w-3.5 border-primary-400 rounded cursor-pointer',
        themeColor,
        className,
        { 'cursor-not-allowed !border-gray-200': disabled },
      )}
      disabled={disabled}
      onChange={disabled ? undefined : tracingEvents.onChange}
    />
  );
};

export default Checkbox;
