import axiosInstance from 'api/axiosInstance';
import type { Gratification } from 'utils/gratifications/types';

const service = '/v1/gratifications';

export type UpdateGratificationParams = {
  useful?: boolean;
  seen?: string; // iso format
  opened?: boolean;
  dismissed?: boolean;
};

export const updateGratification = async (
  id: string,
  params: UpdateGratificationParams,
): Promise<Gratification> => {
  return axiosInstance.put(`${service}/${id}`, params);
};

export const getGratificationById = (id: string): Promise<Gratification> => {
  return axiosInstance.get(`/v1/gratifications/${id}`);
};

export const triggerGratificationByNumber = (gratificationNumber: number) => {
  return axiosInstance.post(`/v1/gratifications/trigger`, {
    gratificationNumber,
    platform: 'accountable-web',
  });
};
