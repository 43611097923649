import api from 'api/axiosInstance';

export const validateSteuernummer = async (
  state: string,
  steuernummer: string,
): Promise<{ valid: boolean }> => {
  const data = await api.post(
    `v1/users/check/tax-number`,
    {
      state,
      steuernummer,
    },
    { ignoreToast: true } as any,
  );

  return data as any;
};
