import { Helper } from 'components/molecules/Helper';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Invoice } from 'types/invoice.types';
import showToast from 'utils/toast';
import getIntercom from './intercom.hook';
import { worldThemeContext } from './shared/useWorldTheme';
import useInvalidateResources from './useInvalidateResources';
import useModal from './useModal';
import usePusher from './usePusher';

type Reason =
  | 'NOT_INVOICE'
  | 'NOT_SENT'
  | 'NOT_LOCAL_CURRENCY'
  | 'NOT_EQUAL_TRANSACTION_AMOUNT'
  | 'TAX_LOCKED'
  | 'UNKNOWN';

type EventObject = {
  markedAsPaid: false;
  reason?: Reason;
  alreadyPaid?: boolean;
  revenue: Invoice;
};

type EventData = null | EventObject[];

const useRevenueStatusEvent = () => {
  const { t } = useTranslation();
  const { showIntercom } = getIntercom();
  const channel = usePusher();
  const invalidateResources = useInvalidateResources();
  const [content, api] = useModal({
    name: 'revenues status update event modal',
  });

  const triggerAlert: typeof showToast = (props, config) => {
    return showToast(props, {
      ...config,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  useEffect(() => {
    if (!channel) return;

    const handler = (maybeUnfilteredData: EventData) => {
      if (!Array.isArray(maybeUnfilteredData)) return;

      const data = maybeUnfilteredData.filter((item) => !item.alreadyPaid);
      if (!data.length) return;

      const succeded = data.filter((item) => item.markedAsPaid);
      if (succeded.length) {
        invalidateResources('revenueUpdateStatusEvent');
      }
      if (data.length === succeded.length) {
        if (succeded.length === 1) {
          return triggerAlert(
            {
              title: t('revenue_status_update.marked_as_paid.success.single', {
                revenueNumber: succeded[0].revenue.revenueNumber,
              }),
            },
            {
              type: 'success',
            },
          );
        }
        return triggerAlert(
          {
            title: t('revenue_status_update.marked_as_paid.success.multi'),
          },
          {
            type: 'success',
            onClick: () =>
              api.open(() => (
                <div className="p-6">
                  <ul className="list-disc">
                    {succeded.map((item, i) => (
                      <li
                        key={item.revenue.revenueNumber}
                        className={`text-md text-primary ${
                          i === 0 ? 'pb-2' : 'py-2'
                        }`}
                      >
                        <span className="font-semibold">
                          {t('revenue_status_update.revenue_title', {
                            revenueNumber: item.revenue.revenueNumber,
                          })}
                        </span>
                        <span className="block">
                          {t(
                            'revenue_status_update.marked_as_paid.success.single',
                            {
                              revenueNumber: item.revenue.revenueNumber,
                            },
                          )}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-3">
                    <Helper.Contact
                      onTalkToExpert={() => {
                        showIntercom();
                      }}
                    />
                  </div>
                </div>
              )),
          },
        );
      }

      const failed = data.filter((item) => !item.markedAsPaid);

      return triggerAlert(
        {
          title:
            failed.length > 1
              ? t('revenue_status_update.marked_as_paid.fail.multi')
              : t('revenue_status_update.marked_as_paid.fail.single', {
                  revenueNumber: failed[0].revenue.revenueNumber,
                }),
        },
        {
          type: 'error',
          autoClose: false,
          onClick: () =>
            api.open(() => (
              <div className="p-6">
                <ul className="list-disc">
                  {failed.map((item, i) => (
                    <li
                      key={item.revenue.revenueNumber}
                      className={`text-md text-primary ${
                        i === 0 ? 'pb-2' : 'py-2'
                      }`}
                    >
                      <span className="font-semibold">
                        {t('revenue_status_update.revenue_title', {
                          revenueNumber: item.revenue.revenueNumber,
                        })}
                      </span>
                      <span className="block">
                        {t(
                          `revenue_status_update.marked_as_paid.fail.reason.${item.reason!.toLowerCase()}`,
                        )}
                      </span>
                    </li>
                  ))}
                </ul>
                <div className="mt-3">
                  <Helper.Contact
                    onTalkToExpert={() => {
                      showIntercom();
                    }}
                  />
                </div>
              </div>
            )),
        },
      );
    };

    channel.bind('RevenuePaymentReconciliation.processed', handler);
    return () => {
      channel.unbind('RevenuePaymentReconciliation.processed', handler);
    };
  }, [channel, invalidateResources]);

  return content;
};

const UseRevenueStatusEvent = () => {
  return (
    <worldThemeContext.Provider value={'bank'}>
      {useRevenueStatusEvent()}
    </worldThemeContext.Provider>
  );
};

export default UseRevenueStatusEvent;
