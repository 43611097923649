import { QueryCache, QueryClient } from 'react-query';

const queryCache = new QueryCache();

const appQueryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 2,
      staleTime: Infinity,
      retry: false,
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default appQueryClient;
