import { product } from 'config';
import features from 'features';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import type { AvailableCountries } from 'types/users.types';
import useGetCurrentCustomerData from './Authentication/useGetCurrentCustomerData';

type SelectorType = string | ((product: string, lang: string) => string);

export const useFreeFeatures = (options: {
  selector: SelectorType;
  defaultValue?: unknown;
  country: AvailableCountries;
}): any => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();

  // Can either use the hook by passing a function (and have access to the `product`, or a simple string
  const _selector =
    typeof options.selector === 'function'
      ? options.selector(product, resolvedLanguage)
      : options.selector;

  return _get(
    features,
    `${options.country}.${_selector}`,
    options?.defaultValue,
  );
};

// useFeatures hook is responsible for accessing properties, functions, and features that
// are only available based on the user country (be, de ..etc) from the `features` object
const useFeatures = (selector: SelectorType, defaultValue?: unknown): any => {
  const country = useGetCurrentCustomerData().data
    ?.country as AvailableCountries;

  return useFreeFeatures({
    selector,
    country,
    defaultValue,
  });
};

export default useFeatures;
