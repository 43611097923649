import imageSrc from 'assets/images/period-assignment@2x.png';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import { FreeLoader } from 'components/atoms/Loader/Loader.component';
import { Tooltip } from 'components/atoms/Tooltip';
import getIntercom from 'hooks/intercom.hook';
import useWorldTheme from 'hooks/shared/useWorldTheme';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

const renderLoader = (className = '') => (
  <FreeLoader className={`text-primary-200 fill-primary-400 ${className}`} />
);

type Option = {
  title: ReactNode;
  description: string;
  value: any;
  isLoading?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  tooltip?: string;
  icon?: ReactNode;
};

type WarningModalContentProps = {
  options?: Option[];
  render?: () => ReactNode;
  title?: string;
  question?: string;
  description?: string;
  onChoose?: (value: any) => Promise<any> | void;
  hideBGImage?: boolean;
  hideHelpBtn?: boolean;
  questionClassName?: string;
  img?: string;
};

const optionsTheme: any = {
  interaction: {
    expenses: 'hover:bg-expenses-100 focus:bg-expenses-100',
    revenues: 'hover:bg-revenues-200 focus:bg-revenues-200',
    default: 'hover:bg-primary-100 focus:bg-primary-100',
  },
  selected: {
    expenses: 'bg-expenses-100',
    revenues: 'bg-revenues-200',
    default: 'bg-primary-100',
  },
};

const WarningModalContent: FC<WarningModalContentProps> = ({
  title,
  description,
  question,
  options,
  render,
  onChoose,
  hideBGImage,
  hideHelpBtn,
  questionClassName,
  img = imageSrc,
}) => {
  const { t } = useTranslation();
  const { showIntercom } = getIntercom();
  const color = useWorldTheme();

  const optionsInteractionClassName =
    optionsTheme.interaction[color] || optionsTheme.interaction.default;
  const optionsSelectedClassName =
    optionsTheme.selected[color] || optionsTheme.selected.default;

  return (
    <>
      <div className="bg-[#f6f6fd] px-12 py-10 rounded-t-2xl">
        <h1 className="font-avenir text-primary-400 text-xl font-semibold">
          {title || t('attention')}
        </h1>
        {description && (
          <p className="text-primary-900 text-lg leading-tight whitespace-pre-line">
            {description}
          </p>
        )}
      </div>
      <div className="px-12 py-10 flex gap-10">
        <div className="flex flex-col flex-1">
          {question && (
            <p
              className={classNames(
                'font-bold text-primary text-2xl mb-8',
                questionClassName,
              )}
            >
              {question}
            </p>
          )}

          {render?.()}

          {options?.map((option, index) => {
            return (
              <Tooltip key={index} content={option.tooltip}>
                <button
                  onClick={() => {
                    if (!option.isLoading && !option.isDisabled)
                      onChoose?.(option.value);
                  }}
                  type="button"
                  className={classNames('text-left px-6 py-3 mb-2 rounded-lg', {
                    [optionsInteractionClassName]: !option.isDisabled,
                    [optionsSelectedClassName]: option.isSelected,
                    'opacity-50': option.isDisabled,
                  })}
                >
                  <div className="flex items-start gap-4">
                    {!!option.icon && (
                      <div className="flex flex-shrink-0">{option.icon}</div>
                    )}
                    <div>
                      <div className="flex items-center">
                        {option.isLoading && renderLoader('w-5 mr-2')}
                        <p className="font-bold text-main text-xl">
                          {option.title}
                        </p>
                      </div>
                      <p className="text-main text-lg leading-tight">
                        <ReactMarkdown
                          components={{
                            a: ({ children, href }) => (
                              <a
                                href={href}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="underline text-blue-500 hover:text-blue-600"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {children}
                              </a>
                            ),
                          }}
                        >
                          {option.description}
                        </ReactMarkdown>
                      </p>
                    </div>
                  </div>
                </button>
              </Tooltip>
            );
          })}

          {!hideHelpBtn && (
            <div
              className={classNames('px-6 mt-8', {
                'text-center': hideBGImage,
              })}
            >
              <p className="font-bold text-main text-xl">{t('need_help')}</p>
              <Button
                structure="text"
                color="taxes"
                className="underline"
                onClick={() => showIntercom()}
              >
                {t('get_in_touch_with_our_team')}
              </Button>
            </div>
          )}
        </div>
        {!hideBGImage && (
          <div className="max-w-xs hidden lg:block">
            <img src={img} className="w-full" alt="" />
          </div>
        )}
      </div>
    </>
  );
};

export default WarningModalContent;
