import dayjs from 'dayjs';
import i18n from 'i18n';
import type { Period } from 'types/global.types';
import type { ITax } from 'types/taxes.types';
import { TaxTypeEnum } from 'types/taxes.types';
import type { UserCountry, VATReturnFrequency } from 'types/users.types';
import formatPeriodLabel from 'utils/formatPeriodLabel';
import getTitleIntracomItem from './getTitleIntracomItem';
import getTitleSocialContributionsSubject from './getTitleSocialContributionsSubject';
import getTitleVATSubmission from './getTitleVATSubmission';
import getTitleVATToPay from './getTitleVATToPay';

const getTaxTitle = (
  tax: Partial<ITax>,
  vatReturnFrequency: VATReturnFrequency,
  country: UserCountry,
): string | undefined => {
  switch (tax.type) {
    case TaxTypeEnum.vat: {
      if (tax.isSpecial)
        return `${i18n.t(
          'taxes.filters.types.special-vat',
        )} ${formatPeriodLabel(tax?.period as Period)}`;
      return `${i18n.t(
        // submission_payment
        `taxes.filters.types.vat.${country}`,
      )} ${formatPeriodLabel(tax?.period as Period)}`;
    }
    case TaxTypeEnum.specialVATPayment:
      return `${i18n.t(
        'taxes.filters.types.special-vat-payment',
      )} ${formatPeriodLabel(tax?.period as Period)}`;
    case TaxTypeEnum.specialVATSubmission:
      return `${i18n.t(
        'taxes.filters.types.special-vat-submission',
      )} ${formatPeriodLabel(tax?.period as Period)}`;
    case TaxTypeEnum.vatSubmission:
      return getTitleVATSubmission(tax, vatReturnFrequency, country);
    case TaxTypeEnum.vatPayment:
      return getTitleVATToPay(tax, country);
    case TaxTypeEnum.vatFranchisee:
      return i18n.t('taxes.vat_year');
    case TaxTypeEnum.scSettings:
      return i18n.t('taxes.social_contributions');
    case TaxTypeEnum.scSubject:
      return getTitleSocialContributionsSubject(tax);
    case TaxTypeEnum.scExonerated:
      return i18n.t('taxes.social_contributions');
    case TaxTypeEnum.intracomListing:
      return getTitleIntracomItem(tax, country);
    case TaxTypeEnum.clientListing:
      return i18n.t('taxes.client_listing_year', {
        year: tax?.period?.year ?? dayjs().year(),
      });
    case TaxTypeEnum.incomeTaxPayment:
      return i18n.t('taxes.filters.types.income_tax_payment', {
        year: tax?.period?.year ?? dayjs().year(),
      });
    case TaxTypeEnum.incomeTaxPrepayment:
      return i18n.t('taxes.income_tax_prepayment.tax_item_title', {
        year: tax?.period?.year ?? dayjs().year(),
        quarter: tax?.period?.quarter,
      });
    case TaxTypeEnum.companyTaxPayment:
      return i18n.t('taxes.filters.types.company_tax_payment', {
        year: tax?.period?.year ?? dayjs().year(),
      });
    case TaxTypeEnum.companyTaxPrepayment:
      return i18n.t('taxes.filters.types.company_tax_prepayment', {
        year: tax?.period?.year ?? dayjs().year(),
        quarter: tax?.period?.quarter,
      });
    case TaxTypeEnum.incomeTaxSubmission:
      return i18n.t('taxes.income_submission_tax.tax_item_title', {
        year: tax?.period?.year ?? dayjs().year(),
      });
    case TaxTypeEnum.tradeTaxSubmission:
      return i18n.t('taxes.trade_tax_submission.tax_item_title', {
        year: tax?.period?.year ?? dayjs().year(),
      });
    case TaxTypeEnum.tradeTaxPrepayment:
      return i18n.t('taxes.trade_tax_prepayment_quarter_year', {
        year: tax?.period?.year ?? dayjs().year(),
        quarter: tax?.period?.quarter ?? dayjs().quarter(),
      });
    case TaxTypeEnum.usteVatPayment:
      return i18n.t('taxes.uste_payment_year', {
        year: tax?.period?.year ?? dayjs().year(),
      });
    case TaxTypeEnum.usteVatSubmission:
      return i18n.t('taxes.uste_submission_year', {
        year: tax?.period?.year ?? dayjs().year(),
      });
    case TaxTypeEnum.EUR:
      return i18n.t('taxes.eur.eur', { year: tax?.period?.year });
    case TaxTypeEnum.usteSubmission:
      return i18n.t('taxes.uste_submission_year', {
        year: tax?.period?.year ?? dayjs().year(),
      });
    default:
      '';
  }
};

export default getTaxTitle;
