import { get } from 'lodash';
import type { FC } from 'react';
import type { CheckComponentProps } from 'types/taxes.types';
import CheckUITemplate from '../CheckUITemplate';
import CommutersList from '../CommutersList';

const CommuterAllowanceAmountHigh: FC<CheckComponentProps> = (props) => {
  return (
    <CheckUITemplate
      {...props}
      renderContent={() => (
        <div className="my-4">
          <CommutersList list={get(props.checkData, 'data')} {...props} />
        </div>
      )}
    />
  );
};

export default CommuterAllowanceAmountHigh;
