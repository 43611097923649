import WordLogoSrc from 'assets/images/logo-large@2x.png';
import LetterLogoSrc from 'assets/images/logo.png';
import type { FC } from 'react';
import type AccountableLogoProps from './AccountableLogo.types';

const AccountableLogo: FC<AccountableLogoProps> = ({
  className,
  type = 'word',
  ...props
}) => (
  <img
    src={type === 'word' ? WordLogoSrc : LetterLogoSrc}
    alt="Accountable Logo"
    className={className}
    {...props}
  />
);

export default AccountableLogo;
