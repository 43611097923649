import { getTaxBySlug } from 'api/v2/taxes';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useVATReturnFrequency from 'hooks/shared/useVATReturnFrequency';
import { get } from 'lodash';
import set from 'lodash/set';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import type { ITax } from 'types/taxes.types';
import { TaxTypeEnum } from 'types/taxes.types';
import { SubscriptionPlanNameEnum, type UserCountry } from 'types/users.types';
import getTaxTitle from 'utils/taxes/getTaxTitle';

const useGetTaxBySlug = (
  slug: string,
  enabled: boolean,
): UseQueryResult<ITax> => {
  const customerCountry = useCustomerCountry();
  const VATReturnFrequency = useVATReturnFrequency();
  const { data: user } = useGetCurrentCustomerData();
  const isUserPlanEligibleForGuarantee = [
    SubscriptionPlanNameEnum.pro1,
    SubscriptionPlanNameEnum.pro12,
    SubscriptionPlanNameEnum.trial1,
    SubscriptionPlanNameEnum.small1,
    SubscriptionPlanNameEnum.small12,
    SubscriptionPlanNameEnum.ambassador,
  ].includes(get(user, 'subscriptionPlan.planInternalName'));
  return useQuery(
    [slug],
    () => {
      if (!enabled) return Promise.resolve() as any;
      return getTaxBySlug(slug);
    },
    {
      enabled: !!(enabled && customerCountry && VATReturnFrequency),
      cacheTime: 0,
      select: (tax) => {
        if (!tax) return;
        if (tax.type === TaxTypeEnum.vat) {
          set(tax, '_id', tax?.submission?._id);
        }
        if (tax.type === TaxTypeEnum.specialVAT) {
          tax.isSpecial = true;
          if (tax?.submission) {
            set(tax, 'submission.type', TaxTypeEnum.vatSubmission);
            set(tax, 'submission.isSpecial', true);
          }
          if (tax?.payment) {
            set(tax, 'payment.type', TaxTypeEnum.vatPayment);
            set(tax, 'payment.isSpecial', true);
          }
        }
        tax.type = [TaxTypeEnum.specialVAT, TaxTypeEnum.ustva].includes(
          tax.type,
        )
          ? TaxTypeEnum.vat
          : tax.type;
        const submissions = get(
          tax,
          tax.type === TaxTypeEnum.vat
            ? 'submission.submissions'
            : 'submissions',
        );
        const taxChecks =
          submissions?.[submissions?.length - 1]?.taxChecks || {};
        return {
          ...tax,
          guarantee: {
            taxChecks,
            stats: {
              failed: taxChecks?.failed?.length,
              passed: taxChecks?.passed?.length,
              reviewed: taxChecks?.reviewed?.length,
            },
            ...get(tax, 'guarantee', {}),
          },
          isGuaranteed:
            get(tax, 'isGuaranteed') || get(tax, 'submission.isGuaranteed'),
          hasGuarantee:
            isUserPlanEligibleForGuarantee &&
            (get(tax, 'guarantee.stats.passed') ||
              submissions?.[submissions?.length - 1]?.taxChecks),
          title: getTaxTitle(
            tax,
            VATReturnFrequency,
            customerCountry as UserCountry,
          ),
        };
      },
    },
  );
};

export default useGetTaxBySlug;
