import { getClientById } from 'api/v2/clients';
import { getRecurringRevenue } from 'api/v2/recurring';
import { calculateItems } from 'api/v2/revenues';
import { Loader } from 'components/atoms/Loader';
import SimpleError from 'components/molecules/SimpleError.component';
import dayjs from 'dayjs';
import useCustomNavigate from 'hooks/useCustomeNavigate';
import useCutRouterState from 'hooks/useCutRouterState';
import { FC, lazy, useEffect, useId, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { RevenuesCacheKeys } from 'types/cacheKeys.types';
import { CurrenciesEnum } from 'types/global.types';
import { RecurringInvoice } from 'types/invoice.types';
import { cleanInvoiceItems } from '../SharedControls/ItemsForm/helpers';
import '../styles.scss';
const Form = lazy(() => import('./Form'));

const today = dayjs().format('YYYY-MM-DD');

const PrepareInvoice: FC<{ data: Partial<RecurringInvoice> }> = ({ data }) => {
  const clientQuery = useQuery(
    [RevenuesCacheKeys.ClientById, useId()],
    () => {
      const client = data.revenueData?.client;
      const clientId = data.revenueData?.clientId;
      if (!clientId) {
        return {
          client: undefined,
          clientId: undefined,
        };
      }
      return getClientById(clientId, { ignoreToast: true })
        .then((result) => ({
          client: { ...result, ...client },
          clientId: clientId,
        }))
        .catch((e) => {
          if (e.response?.status === 404) {
            return {
              client: undefined,
              clientId: undefined,
            };
          }

          throw e;
        });
    },
    {
      cacheTime: 0,
    },
  );

  const calculationsQueryEnabled = !!clientQuery.data;

  const calculationsQuery = useQuery(
    [RevenuesCacheKeys.ItemsCalculations, useId(), calculationsQueryEnabled],
    () => {
      const revenue = data.revenueData;
      const client = clientQuery.data!;
      return calculateItems({
        items: cleanInvoiceItems(revenue?.items || []),
        clientId: client.clientId,
        invoiceDate: today,
        options: {
          currency: revenue?.currency || CurrenciesEnum.EUR,
        },
      });
    },
    { cacheTime: 0, enabled: calculationsQueryEnabled },
  );

  const document = useMemo(() => {
    return {
      ...data,
      revenueData: {
        ...data?.revenueData,
        ...clientQuery.data,
        itemsCalculations: calculationsQuery.data,
      },
    };
  }, [data, clientQuery.data, calculationsQuery.data]);

  const isLoading = clientQuery.isLoading || calculationsQuery.isLoading;
  const isError = clientQuery.isError || calculationsQuery.isError;

  if (isLoading) return <Loader />;

  if (isError)
    return (
      <SimpleError
        onRetry={() => {
          if (clientQuery.isError) clientQuery.refetch();
          if (calculationsQuery.isError) calculationsQuery.refetch();
        }}
      />
    );

  return <Form defaultValues={document as any} />;
};

export const CreateRecurringInvoice = () => {
  const guessedDocument = useCutRouterState(
    'guessedDocument',
  ) as Partial<RecurringInvoice> | null;

  if (!guessedDocument) {
    return <Form />;
  }

  return <PrepareInvoice data={guessedDocument} />;
};

export const EditRecurringInvoice = () => {
  const navigate = useCustomNavigate();
  const revenueId = useParams().revenueId as string;

  const revenueQuery = useQuery(
    [RevenuesCacheKeys.RevenueById, revenueId],
    () => getRecurringRevenue(revenueId),
    { cacheTime: 0 },
  );

  const isFaulty =
    !!revenueQuery.data &&
    !!revenueQuery.data.pauseReason &&
    revenueQuery.data.pauseReason !== 'manual-pause';

  const isLoading = revenueQuery.isLoading;

  const isError = revenueQuery.isError;

  useEffect(() => {
    if (isFaulty) {
      navigate('/recurring-revenues');
    }
  }, [isFaulty]);

  if (isLoading || isFaulty) return <Loader />;

  if (isError)
    return (
      <SimpleError
        onRetry={() => {
          if (revenueQuery.isError) revenueQuery.refetch();
        }}
      />
    );

  if (!revenueQuery.data) return null;

  return <PrepareInvoice data={revenueQuery.data} />;
};
