import { get } from 'lodash';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { CheckComponentProps } from 'types/taxes.types';
import CheckExpensesList from '../CheckExpensesList';
import CheckUITemplate from '../CheckUITemplate';

const ExpenseVATClaimedAbove19: FC<CheckComponentProps> = (props) => {
  const { checkData } = props;
  const { t } = useTranslation();

  return (
    <CheckUITemplate
      {...props}
      renderContent={() => (
        <div className="my-4">
          <CheckExpensesList {...{ list: get(checkData, 'data'), ...props }} />
        </div>
      )}
    />
  );
};

export default ExpenseVATClaimedAbove19;
