import type { Category } from 'components/organisms/CategoriesPicker/categoriesPicker.types';
import { createPeriodCategory } from 'components/pages/Banks/Classification/useClassificationOptions';
import i18n from 'i18n';
import React from 'react';
import { CellsModifiers } from 'types/CellsModifiers.types';
import { TransactionCategoryEnum } from 'types/transactions.types';
import { CATEGORY_SOCIAL_CONTRIBUTIONS } from 'utils/categories';

import socialContributions from 'assets/svg/classification/socialContributions.svg';
import { categoriesIcons } from 'components/pages/Banks/Classification/helpers';
import {
  AccountTypeEnum,
  VATReturnFrequencyEnum,
  type IUser,
} from 'types/users.types';

const transactions = {
  iban_mask: 'BE99 9999 9999 9999',
  anotherProfessionalPaymentExpenseOptions: (user?: IUser): Category[] => [
    {
      icon: React.createElement('img', {
        src: categoriesIcons.incomeTaxPrePayment,
        alt: '',
      }),
      ...createPeriodCategory(
        TransactionCategoryEnum.companyTaxPayment,
        [VATReturnFrequencyEnum.yearly],
        i18n.t('payments.transaction_category_short.companyTaxPayment'),
        i18n.t('transaction.tax_period_title.companyTaxPayment'),
      ),
      hidden: user?.accountType?.includes(AccountTypeEnum.natural_person),
    },
    {
      icon: React.createElement('img', {
        src: categoriesIcons.incomeTaxPrePayment,
        alt: '',
      }),
      ...createPeriodCategory(
        TransactionCategoryEnum.companyTaxPrePayment,
        [VATReturnFrequencyEnum.quarterly],
        i18n.t('payments.transaction_category_short.companyTaxPrePayment'),
        i18n.t('transaction.tax_period_title.companyTaxPrePayment'),
      ),
      hidden: user?.accountType?.includes(AccountTypeEnum.natural_person),
    },
    {
      icon: React.createElement('img', { src: socialContributions, alt: '' }),
      ...createPeriodCategory(
        'socialContributions',
        ['quarterly'],
        i18n.t('payments.transaction_category_short.socialContributions'),
        i18n.t('transaction.tax_period_title.socialContributions'),
      ),
    },
  ],
  anotherProfessionalPaymentRevenueOptions: (user: IUser): Category[] => [
    {
      icon: React.createElement('img', {
        src: categoriesIcons.incomeTaxReimbursement,
        alt: '',
      }),
      ...createPeriodCategory(
        TransactionCategoryEnum.companyTaxReimbursement,
        [VATReturnFrequencyEnum.yearly],
        i18n.t('payments.transaction_category_short.companyTaxReimbursement'),
        i18n.t('transaction.tax_period_title.companyTaxReimbursement'),
      ),
      hidden: user?.accountType?.includes(AccountTypeEnum.natural_person),
    },
  ],
  transactionsTaxCategoriesMarkAsPaid: [
    TransactionCategoryEnum.VATPayment,
    TransactionCategoryEnum.VATReimbursement,
    TransactionCategoryEnum.incomeTaxPayment,
    TransactionCategoryEnum.incomeTaxPrePayment,
    TransactionCategoryEnum.incomeTaxReimbursement,
  ],
  paymentKeys: {
    incomingPayment:
      'payments.payment_classification.a_credit_note_on_purchase.be',
    outgoingPayment:
      'payments.payment_classification.a_credit_note_on_sales.be',
    linkToCreditNoteOnPurchase:
      'payments.payment_classification.link_to_credit_note_on_purchase.be',
    addToCreditNoteOnPurchase:
      'payments.payment_classification.create_a_new_credit_note_on_purchase.be',
    linkToCreditNoteOnSales:
      'payments.payment_classification.link_to_an_existing_credit_note_on_sales.be',
    addToCreditNoteOnSales:
      'payments.payment_classification.create_a_new_credit_note_on_sales.be',
  },
  transactionsTaxExpenseCategories: [
    TransactionCategoryEnum.socialContributions,
  ],
  transactionCategoryToCategoryIDMap: {
    [TransactionCategoryEnum.socialContributions]:
      CATEGORY_SOCIAL_CONTRIBUTIONS,
  },
  transactionsTaxReimbursementCategories: [],
  blocked: {
    web: false,
    experts: false,
  },
  list: {
    cells: {
      _modifiers: [{ name: CellsModifiers.transactions }],
      default_sort: {
        web: 'valueDate_desc',
        experts: 'valueDate_desc',
      },
      first_group: {
        web: [
          'COUNTERPARTY',
          'COMMUNICATION',
          'DATE',
          'CLASSIFICATION',
          'TOTAL_AMOUNT',
        ],
        experts: [
          'COUNTERPARTY',
          'COMMUNICATION',
          'DATE',
          'CLASSIFICATION',
          'TOTAL_AMOUNT',
        ],
      },
      second_group: {
        web: [
          'COUNTERPARTY',
          'COMMUNICATION',
          'DATE',
          'CLASSIFICATION',
          'TOTAL_AMOUNT',
        ],
        experts: [
          'COUNTERPARTY',
          'COMMUNICATION',
          'DATE',
          'CLASSIFICATION',
          'TOTAL_AMOUNT',
        ],
      },
    },
  },
};

export default transactions;
