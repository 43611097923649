import _groupBy from 'lodash/groupBy';
import _max from 'lodash/max';
import type {
  ShortNickName,
  SubscriptionPlan,
} from 'types/subscriptions.types';
import type { SubscriptionPlanNameEnum } from 'types/users.types';
import { plans_order } from './Subscription.enum';
import type { OptionsPlansType } from './Subscription.types';

type ReturnType = {
  title: string;
  subtitle: string;
  description: string;
  name: string;
  discountBadgeText?: string;
  subscriptionText: string;
  badge?: { Icon: any; text: string };
};

export const getMonthlyAmount = (interval: string, amount: number): number => {
  const intervals = { year: amount / 100 / 12, month: amount / 100 };
  return intervals[interval as keyof typeof intervals];
};

export const getMaxMonthlyAmount = (
  selectedPlanOptionsList: SubscriptionPlan[],
): number =>
  _max(
    selectedPlanOptionsList.map(({ amount, interval }) =>
      getMonthlyAmount(interval, amount),
    ),
  ) || 0;

export const getNextSelectedPlanGroup = (
  groupedSubscriptionPlans: Record<string, SubscriptionPlan[]>,
  selectedPlanGroup: string,
): {
  nextSelectedPlanGroup: string;
} => {
  const planNames = Object.keys(groupedSubscriptionPlans);
  // * if planNames.indexOf(selectedPlanGroup) is not the last element
  // * then (planNames.indexOf(selectedPlanGroup) + 1) % N == planNames.indexOf(selectedPlanGroup) + 1,
  // * hence the next index, however if (planNames.indexOf(selectedPlanGroup) + 1) == N,
  // * i.e. the current element is the last index, then (planNames.indexOf(selectedPlanGroup) + 1) % N == 0,
  // * therefore the next element will be the first element of the array.
  const nextPlanIdx =
    (planNames.indexOf(selectedPlanGroup) + 1) % planNames.length;
  return { nextSelectedPlanGroup: planNames[nextPlanIdx] };
};

export const getCustomerGroupSubscriptionPlan = (
  customerSubscriptionInternalName: SubscriptionPlanNameEnum,
): string =>
  (customerSubscriptionInternalName || '')
    .replace('eu.accountable.', '')
    .replace(/[0-9]/g, '');
export const checkForNextSubscriptionPlan = (
  groupedSubscriptionPlans: Record<string, SubscriptionPlan[]>,
  customerSubscriptionInternalName: SubscriptionPlanNameEnum,
): boolean => {
  const otherPlanNames = Object.keys(groupedSubscriptionPlans).filter(
    (groupName) =>
      groupName !==
      getCustomerGroupSubscriptionPlan(customerSubscriptionInternalName),
  );
  return otherPlanNames.length > 1;
};

export const isDowngradingSubscriptionPlan = (
  fromPlan: SubscriptionPlanNameEnum,
  toPlan: SubscriptionPlanNameEnum,
): boolean => plans_order[fromPlan] > plans_order[toPlan];

const calculateSavingMonths = (
  amountMonthly: number,
  maxMonthlyAmount: number,
): number => 12 - Math.round(12 * (amountMonthly / maxMonthlyAmount));

export const manipulateAvailablePlansOptions = ({
  customerPlanInternalName,
  selectedGroupedPlan,
}: {
  customerPlanInternalName: SubscriptionPlanNameEnum;
  selectedGroupedPlan: SubscriptionPlan[];
}): OptionsPlansType => {
  return (selectedGroupedPlan || []).map((subscriptionPlan) => {
    const amountMonthly = getMonthlyAmount(
      subscriptionPlan.interval,
      subscriptionPlan.amount,
    );

    const maxMonthlyAmount = getMaxMonthlyAmount(selectedGroupedPlan || []);

    return {
      ...subscriptionPlan,
      amountMonthly,
      // * calculate how many months your saving by choosing this plan
      // * compared to the max available payment per month
      savingMonths: calculateSavingMonths(amountMonthly, maxMonthlyAmount),
      disabled:
        // * disable current plan as a option
        customerPlanInternalName === subscriptionPlan.nickname ||
        // * disable downgrading plans to
        isDowngradingSubscriptionPlan(
          customerPlanInternalName,
          subscriptionPlan.nickname as any,
        ),
    };
  });
};

export const parseSubscriptionPlan = (
  plan: SubscriptionPlan,
): SubscriptionPlan => ({
  ...plan,
  shortNickname: plan.nickname.replace('eu.accountable.', '') as ShortNickName,
});

export const groupedSubscriptionPlans = (
  selectSubscriptionPlans: SubscriptionPlan[],
): {
  [key: string]: SubscriptionPlan[];
} =>
  _groupBy(selectSubscriptionPlans, ({ shortNickname }) =>
    // * remove numbers from plan to group them e.g group basic1 and basic12 in basic key
    shortNickname.replace(/[0-9]/g, ''),
  );
