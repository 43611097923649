import Toast from 'components/molecules/Toast';
import { type ToastProps } from 'components/molecules/Toast/Toast.types';
import { toast, type Id, type ToastOptions } from 'react-toastify';
const TEN_SECONDS_TIMEOUT = 10000;

const showToast = (toastProps: ToastProps, config?: ToastOptions): Id => {
  const { onClick, ...rest } = config || {};
  return toast(<Toast {...toastProps} onClick={onClick} />, {
    closeButton: false,
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: TEN_SECONDS_TIMEOUT,
    hideProgressBar: true,
    pauseOnHover: true,
    ...rest,
  });
};
export default showToast;
