import {
  freqsDict,
  generatePeriods,
} from 'components/molecules/Fields/Period/utils';
import type { Category } from 'components/organisms/CategoriesPicker/categoriesPicker.types';
import {
  attachData,
  categoryIdWithTaxPeriodToValue,
  getCategoriesById,
} from 'components/organisms/CategoriesPicker/utils';
import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useFeatures from 'hooks/useFeatures';
import i18n from 'i18n';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import type { VATReturnFrequency } from 'types/global.types';
import type { Transaction } from 'types/transactions.types';
import { TransactionCategoryEnum } from 'types/transactions.types';
import type { AvailableCountries } from 'types/users.types';
import { AccountTypeEnum, VATReturnFrequencyEnum } from 'types/users.types';
import { isDisabledTransactionCategoryBulk } from 'utils/constants';
import formatPeriodLabel from 'utils/formatPeriodLabel';
import { categoriesIcons, useTransactionsTypeState } from './helpers';

const freqToCategory = (
  frequency: VATReturnFrequency,
  question: string,
  categoryId: string,
): Category => {
  const periods = generatePeriods(frequency, dayjs().endOf('year'));
  return {
    id: frequency,
    title: i18n.t(`select_${freqsDict[frequency]}`),
    question,
    compact: true,
    list: periods.map((period) => ({
      title: formatPeriodLabel(period) as string,
      id: categoryIdWithTaxPeriodToValue(categoryId, frequency, period),
      parentCatgoryId: categoryId,
      taxPeriod: { ...period, frequency },
      frequency,
    })),
  };
};

export const createPeriodCategory = (
  categoryId: string,
  freqs: VATReturnFrequency[],
  title: string,
  question: string,
): Category => {
  const hasOnlyOneFreq = freqs.length === 1;
  return {
    id: categoryId,
    title,
    question,
    compact: hasOnlyOneFreq,
    list: hasOnlyOneFreq
      ? freqToCategory(freqs[0], question, categoryId).list
      : freqs.flatMap((freq) => freqToCategory(freq, question, categoryId)),
  };
};

const disableBulkIrrelevantCates = (cates: Category[]): Category[] => {
  return cates.map((cate) => {
    if (isDisabledTransactionCategoryBulk(cate.id)) {
      return Object.assign({}, cate, { isDisabled: true });
    }
    if (cate.list) {
      return Object.assign({}, cate, {
        list: disableBulkIrrelevantCates(cate.list),
      });
    }
    return cate;
  });
};

type ClassificationOption = {
  icon: ReactNode;
  title: string;
  helperTooltip?: string;
  id: string;
  hidden?: boolean;
  isDisabled?: boolean;
  list?: ClassificationOption[];
};

const useClassificationOptions = (transaction: Transaction | Transaction[]) => {
  const { t } = useTranslation();

  const { data: user } = useGetCurrentCustomerData();

  const country = useCustomerCountry() as AvailableCountries;

  const {
    isNegative,
    isPositive,
    isBulk,
    isPostiveMoreThanNegative,
    isForeign,
  } = useTransactionsTypeState(transaction);

  const anotherProfessionalPaymentExpenseOptions = useFeatures(
    'transactions.anotherProfessionalPaymentExpenseOptions',
    [],
  );
  const anotherProfessionalPaymentRevenueOptions = useFeatures(
    'transactions.anotherProfessionalPaymentRevenueOptions',
    [],
  );
  const paymentKeys = useFeatures('transactions.paymentKeys', {});
  const vatReturnFrequencies = useFeatures('taxes.vat_return_frequency');

  const expenseOptions: ClassificationOption[] = [
    {
      icon: <img src={categoriesIcons.professionalExpense} alt="" />,
      title: t('transactions.transaction_category_short.professionalExpense'),
      helperTooltip: t(
        `payments.payment_classification.a_professional_expense_subtitle.${country}`,
      ),
      id: TransactionCategoryEnum.professionalExpense,
    },
    {
      icon: <img src={categoriesIcons.anotherProfessionalPayment} alt="" />,
      title: t('payments.payment_classification.another_professional_payment'),
      helperTooltip: t(
        `payments.payment_classification.another_professional_payment_subtitle.${country}`,
      ),
      id: 'anotherProfessionalPayment',
      list: [
        {
          isDisabled: isForeign,
          icon: <img src={categoriesIcons.tax_payment} alt="" />,
          title: t('payments.payment_classification.tax_payment'),
          id: 'tax_payment',
          list: [
            {
              icon: <img src={categoriesIcons.VATPayment} alt="" />,
              ...createPeriodCategory(
                TransactionCategoryEnum.VATPayment,
                vatReturnFrequencies,
                t('payments.transaction_category_short.VATPayment'),
                t('transaction.tax_period_title.VATPayment'),
              ),
            },
            {
              icon: <img src={categoriesIcons.withholdingTaxPayment} alt="" />,
              title: t(
                'payments.transaction_category_short.withholdingTaxPayment',
              ),
              id: TransactionCategoryEnum.withholdingTaxPayment,
            },
            {
              icon: <img src={categoriesIcons.incomeTaxPayment} alt="" />,
              ...createPeriodCategory(
                TransactionCategoryEnum.incomeTaxPayment,
                [VATReturnFrequencyEnum.yearly],
                t('payments.transaction_category_short.incomeTaxPayment'),
                t('transaction.tax_period_title.incomeTaxPayment'),
              ),
              hidden: user?.accountType === AccountTypeEnum.one_person_company,
            },
            {
              icon: <img src={categoriesIcons.incomeTaxPrePayment} alt="" />,
              ...createPeriodCategory(
                TransactionCategoryEnum.incomeTaxPrePayment,
                [VATReturnFrequencyEnum.quarterly],
                t('payments.transaction_category_short.incomeTaxPrePayment'),
                t('transaction.tax_period_title.incomeTaxPrePayment'),
              ),
              hidden: user?.accountType === AccountTypeEnum.one_person_company,
            },
            ...anotherProfessionalPaymentExpenseOptions(user),
          ],
        },
        {
          icon: <img src={categoriesIcons.outgoingLoan} alt="" />,
          title: t('payments.transaction_category_short.outgoingLoan'),
          id: TransactionCategoryEnum.outgoingLoan,
        },
        {
          icon: <img src={categoriesIcons.outgoingLoanReimbursement} alt="" />,
          id: TransactionCategoryEnum.outgoingLoanReimbursement,
          title: t(
            'payments.transaction_category_short.outgoingLoanReimbursement',
          ),
        },
        {
          icon: <img src={categoriesIcons.outgoingCapitalMovement} alt="" />,
          id: TransactionCategoryEnum.outgoingCapitalMovement,
          title: t(
            'payments.transaction_category_short.outgoingCapitalMovement',
          ),
        },
        {
          icon: (
            <img
              src={categoriesIcons.otherProfessionalOutgoingPayment}
              alt=""
            />
          ),
          id: TransactionCategoryEnum.otherProfessionalOutgoingPayment,
          title: t(
            'payments.transaction_category_short.otherProfessionalOutgoingPayment',
          ),
        },
      ],
    },
    {
      icon: <img src={categoriesIcons.professionalIncome} alt="" />,
      title: t('payments.transaction_category_short.creditCardReimbursement'),
      id: TransactionCategoryEnum.creditCardReimbursement,
    },
    {
      icon: <img src={categoriesIcons.creditNoteOnSales} alt="" />,
      title: t(paymentKeys.outgoingPayment),
      helperTooltip: t(
        'payments.payment_classification.a_credit_note_on_sales_subtitle',
      ),
      id: TransactionCategoryEnum.creditNoteOnSales,
    },
    {
      icon: <img src={categoriesIcons.personalOutgoingPayment} alt="" />,
      title: t('payments.transaction_category_short.personalOutgoingPayment'),
      helperTooltip: t(
        'payments.payment_classification.a_personal_payment_expense_subtitle',
      ),
      id: TransactionCategoryEnum.personalOutgoingPayment,
    },
  ];

  const revenueOptions: ClassificationOption[] = [
    {
      icon: <img src={categoriesIcons.professionalIncome} alt="" />,
      title: t('payments.transaction_category_short.professionalIncome'),
      helperTooltip: t(
        'payments.payment_classification.a_professional_income_subtitle',
      ),
      id: TransactionCategoryEnum.professionalIncome,
    },
    {
      icon: <img src={categoriesIcons.anotherProfessionalPayment} alt="" />,
      title: t('payments.payment_classification.another_professional_payment'),
      helperTooltip: t(
        'payments.payment_classification.another_professional_payment_subtitle',
      ),
      id: 'anotherProfessionalPayment',
      list: [
        {
          isDisabled: isForeign,
          icon: <img src={categoriesIcons.tax_payment} alt="" />,
          title: t('payments.payment_classification.tax_payment'),
          id: 'tax_payment',
          list: [
            {
              icon: <img src={categoriesIcons.VATReimbursement} alt="" />,
              ...createPeriodCategory(
                TransactionCategoryEnum.VATReimbursement,
                vatReturnFrequencies,
                t('payments.transaction_category_short.VATReimbursement'),
                t('transaction.tax_period_title.VATReimbursement'),
              ),
            },
            {
              icon: <img src={categoriesIcons.incomeTaxReimbursement} alt="" />,
              ...createPeriodCategory(
                TransactionCategoryEnum.incomeTaxReimbursement,
                [VATReturnFrequencyEnum.yearly],
                t('payments.transaction_category_short.incomeTaxReimbursement'),
                t('transaction.tax_period_title.incomeTaxReimbursement'),
              ),
              hidden: user?.accountType === AccountTypeEnum.one_person_company,
            },
            ...anotherProfessionalPaymentRevenueOptions(user),
            {
              icon: (
                <img src={categoriesIcons.withholdingTaxReimbursement} alt="" />
              ),
              title: t(
                'payments.transaction_category_short.withholdingTaxReimbursement',
              ),
              id: TransactionCategoryEnum.withholdingTaxReimbursement,
            },
          ],
        },
        {
          icon: <img src={categoriesIcons.incomingLoan} alt="" />,
          title: t('payments.transaction_category_short.incomingLoan'),
          id: TransactionCategoryEnum.incomingLoan,
        },
        {
          icon: <img src={categoriesIcons.incomingLoanReimbursement} alt="" />,
          id: TransactionCategoryEnum.incomingLoanReimbursement,
          title: t(
            'payments.transaction_category_short.incomingLoanReimbursement',
          ),
        },
        {
          icon: <img src={categoriesIcons.incomingCapitalMovement} alt="" />,
          id: TransactionCategoryEnum.incomingCapitalMovement,
          title: t(
            'payments.transaction_category_short.incomingCapitalMovement',
          ),
        },
        {
          icon: (
            <img
              src={categoriesIcons.otherProfessionalIncomingPayment}
              alt=""
            />
          ),
          id: TransactionCategoryEnum.otherProfessionalIncomingPayment,
          title: t('payments.transaction_category_short.other'),
        },
      ],
    },
    {
      icon: <img src={categoriesIcons.a_non_professional_income} alt="" />,
      id: 'a_non_professional_income',
      title: t('payments.payment_classification.a_non_professional_income'),
      helperTooltip: t(
        'payments.payment_classification.a_non_professional_income_subtitle',
      ),
      list: [
        {
          icon: <img src={categoriesIcons.realEstateActivities} alt="" />,
          title: t('payments.transaction_category_short.realEstateActivities'),
          id: TransactionCategoryEnum.realEstateActivities,
        },
        {
          icon: <img src={categoriesIcons.financialIncome} alt="" />,
          title: t('payments.transaction_category_short.financialIncome'),
          id: TransactionCategoryEnum.financialIncome,
        },
        {
          icon: <img src={categoriesIcons.miscellaneousActivities} alt="" />,
          title: t(
            'payments.transaction_category_short.miscellaneousActivities',
          ),
          id: TransactionCategoryEnum.miscellaneousActivities,
        },
        {
          icon: <img src={categoriesIcons.otherNonProfessionalIncome} alt="" />,
          title: t('payments.transaction_category_short.other'),
          id: TransactionCategoryEnum.otherNonProfessionalIncome,
        },
      ],
    },
    {
      icon: <img src={categoriesIcons.professionalIncome} alt="" />,
      title: t('payments.transaction_category_short.creditCardReimbursement'),
      id: TransactionCategoryEnum.creditCardReimbursement,
    },
    {
      icon: <img src={categoriesIcons.creditNoteOnPurchase} alt="" />,
      title: t(paymentKeys.incomingPayment),
      helperTooltip: t(
        'payments.payment_classification.a_credit_note_on_purchase_subtitle',
      ),
      id: TransactionCategoryEnum.creditNoteOnPurchase,
    },
    {
      icon: <img src={categoriesIcons.personalIncomingPayment} alt="" />,
      title: t('payments.transaction_category_short.personalIncomingPayment'),
      helperTooltip: t(
        'payments.payment_classification.a_personal_payment_income_subtitle',
      ),
      id: TransactionCategoryEnum.personalIncomingPayment,
    },
  ];

  const result = (() => {
    if (isBulk) {
      if (isPositive) {
        return disableBulkIrrelevantCates(revenueOptions);
      }
      if (isNegative) {
        return disableBulkIrrelevantCates(expenseOptions);
      }
      // TODO: add tooltip to tell the user he is mixing negative and positive transactions
      // and if he should only select one type
      if (isPostiveMoreThanNegative) {
        return revenueOptions.map((option) => ({
          ...option,
          isDisabled: true,
          tooltip: t('bulk_transactions.type_mix_warning'),
        }));
      }
      return expenseOptions.map((option) => ({
        ...option,
        isDisabled: true,
        tooltip: t('bulk_transactions.type_mix_warning'),
      }));
    }

    return isPositive ? revenueOptions : expenseOptions;
  })();

  const categoriesList = attachData([
    {
      title: t('transactionCategory'),
      id: 'classification',
      topLevel: true,
      list: result,
    },
  ]);
  const categoriesDict = getCategoriesById(categoriesList);

  return {
    categoriesList,
    categoriesDict,
  };
};

export default useClassificationOptions;
