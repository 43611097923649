import CircularButton from 'components/atoms/CircularButton';
import type { CircularButtonProps } from 'components/atoms/CircularButton/CircularButton.types';
import { CircularButtonTypesEnum } from 'components/atoms/CircularButton/CircularButton.types';
import Help from 'components/atoms/SVG/Help.component';
import { Tooltip } from 'components/atoms/Tooltip';
import type { IUseIntercom } from 'hooks/intercom.hook';
import getIntercom from 'hooks/intercom.hook';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useIntercomStore from 'store/intercom.store';

export const IntercomController: FC<{
  type?: CircularButtonTypesEnum;
  active?: boolean;
  showIntercomLabel?: boolean;
  buttonProps?: Partial<CircularButtonProps>;
  intercomeIns?: IUseIntercom;
}> = ({
  active,
  type,
  buttonProps,
  intercomeIns,
  showIntercomLabel = true,
}) => {
  const defaultIntercom = getIntercom();
  const { showIntercom } = intercomeIns || defaultIntercom;
  const unreadCount = useIntercomStore(({ unreadCount }) => unreadCount);
  const { t } = useTranslation();

  return (
    <Tooltip
      content={
        showIntercomLabel
          ? ''
          : `${t('menu.help')} ${
              unreadCount > 0 ? `( ${unreadCount} unread messages )` : ''
            }`
      }
      placement="right"
    >
      <div className="flex flex-1 flex-col items-center">
        <div className="relative flex">
          <CircularButton
            active={!!active}
            Icon={Help}
            type={type || CircularButtonTypesEnum.export}
            onClick={showIntercom}
            {...buttonProps}
          />
          {unreadCount > 0 && (
            <div className="absolute bottom-0 right-0">
              <div className="h-5 w-5 bg-red-500 ring-2 ring-white text-white rounded-md text-xs flex justify-center items-center font-bold">
                {unreadCount >= 100 ? '99+' : unreadCount}
              </div>
            </div>
          )}
        </div>

        {showIntercomLabel ? (
          <p className="text-[0.84rem] text-center font-semibold text-[#777]">
            {t('menu.help')}
          </p>
        ) : null}
      </div>
    </Tooltip>
  );
};

export default IntercomController;
