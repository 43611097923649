import { CellsModifiers } from 'types/CellsModifiers.types';
import type { ExpenseItem } from 'types/expense.types';
import type { IUser } from 'types/users.types';
import { AvailableLanguages } from 'types/users.types';
import {
  CATEGORY_SOCIAL_CONTRIBUTIONS,
  catesThatRequiresVehicleBE,
} from 'utils/categories';

const expenses = {
  header: {
    helper: {
      title: 'be.expenses.deductible_costs',
      description: [
        'be.expenses.deductible_costs_helper_1',
        'be.expenses.deductible_costs_helper_2',
        'be.expenses.deductible_costs_helper_3',
        'be.expenses.deductible_costs_helper_4',
        'be.expenses.deductible_costs_helper_5',
        'be.expenses.deductible_costs_helper_6',
      ],
      ctaDesc: 'question_on_topic',
      ctaText: 'talk_to_an_expert',
      onClick: (): void => {},
    },
  },
  hasDeductibilityField: true,
  can_link_vehicle: true,
  vehicleCategoryIds: catesThatRequiresVehicleBE,
  filters: {
    is_invoice: true,
  },
  can_pay_with_qr_code: true,
  hasTranslatedCategories: true,
  is_customer_company: (customer: IUser): boolean =>
    customer.accountType === 'one_person_company',
  is_start_date_required: (isCompany: boolean): boolean => !isCompany,
  is_amortization: (item: ExpenseItem): boolean =>
    Boolean(item.amount >= 250 && item.category?.suggestedAmortizationPeriod),
  has_type: true,
  reverse_charge_helper: {
    title: 'helper_coContractor_title',
    description: [
      'helper_coContractor_text1',
      'helper_coContractor_text2',
      'helper_coContractor_text3',
    ],
  },
  vat_rates: [0.0, 0.06, 0.12, 0.21],
  default_vat_rate: 0.21,
  max_vat_rate: 0.21,
  list: {
    vat_inclusion_key: '',
    group_expenses: {
      web: 'isValidated',
      experts: 'reviewed',
    },
    cells: {
      _modifiers: [{ name: CellsModifiers.expenses }],
      // when a expense is highlighed we show only these columns
      cells_when_highlighted: ['SUPPLIER', 'CATEGORY', 'DATE', 'AMOUNT'],
      default_sort: {
        web: 'expenseDate_desc',
        experts: 'expenseDate_desc',
      },
      first_group: {
        web: [
          {
            cellName: 'SUPPLIER',
            headerName: 'expenses.cells.expenses_reviewed',
          },
          'DATE',
          'CATEGORY',
          'TYPE',
          'ATTACHMENT',
          'AMOUNT',
        ],
        experts: [
          {
            cellName: 'SUPPLIER',
            headerName: 'expenses.cells.expenses_validated',
          },
          'DATE',
          'CATEGORY',
          'TYPE',
          'ATTACHMENT',
          'AMOUNT',
          'REVIEWED',
        ],
      },
      second_group: {
        web: [
          {
            cellName: 'SUPPLIER',
            headerName: 'expenses.cells.expenses_to_review',
          },
          'DATE',
          {
            cellName: 'CATEGORY',
            headerName: 'expenses.cells.suggested_category',
          },
          'TYPE',
          'ATTACHMENT',
          'AMOUNT',
        ],
        experts: [
          {
            cellName: 'SUPPLIER',
            headerName: 'expenses.cells.expenses_to_validate',
          },
          'DATE',
          'CATEGORY',
          'TYPE',
          'ATTACHMENT',
          'AMOUNT',
        ],
      },
    },
  },
  deductible_tax_categories: [CATEGORY_SOCIAL_CONTRIBUTIONS],
  all_you_can_deduct_link_origin: (lang: AvailableLanguages) => {
    if (lang === AvailableLanguages.nl) return 'https://www.beroepskosten.be';
    if (lang === AvailableLanguages.fr) return 'https://www.fraispro.be';
    return 'https://www.deductibles.be';
  },
  includeVATAmountIfDeductible: false,
  irrelevantFilters: [],
};

export default expenses;
