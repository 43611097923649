import * as Sentry from '@sentry/react';
import { downloadAttachment } from 'api/v2/shared/downloadDocument';
import { uploadFile } from 'api/v2/upload';
import { getFileUrl } from 'api/v2/users';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import CustomLoader from 'components/molecules/CustomLoader.component';
import { pdfWorkerName } from 'config';
import useCustomDropzone from 'hooks/useCustomDropzone';
import useGetBlob from 'hooks/useGetPDFBlob';
import { get } from 'lodash';
import mime from 'mime/lite';
import type { FC, ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-photo-view/dist/react-photo-view.css';
import { useMutation, useQuery } from 'react-query';
import type { ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ResizeObserver from 'resizeObserver';
import type { IColors } from 'theme/colors';
import { FilesUrls } from 'types/cacheKeys.types';
import type { TracingConfig } from 'utils/amplitude/amplitude.types';
import { globalyAcceptedFileUploaderTypes, maxFileSize } from 'utils/constants';
import {
  CopyOutlineIcon,
  DownloadOutlineIcon,
  EditOutlineIcon,
  NewLinkOutlineIcon,
  RefreshIcon,
  Trash2OutlineIcon,
  Upload2OutlineIcon,
  ZoomInOutlineIcon,
  ZoomOutOutlineIcon,
} from 'utils/icons';
import CSVViewer from './CSVViewer';
import { renderLoader } from './utils';

// from the public folder
pdfjs.GlobalWorkerOptions.workerSrc = '/' + pdfWorkerName;

const zoomContext = createContext({
  zoomIn: () => {},
  zoomOut: () => {},
  registerZoomIn: (f: () => void) => () => {},
  registerZoomOut: (f: () => void) => () => {},
});

type ZoomSubs = {
  onZoomIn: Record<number, () => void>;
  onZoomOut: Record<number, () => void>;
};

const ZoomProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const subs = useRef<ZoomSubs>({ onZoomIn: {}, onZoomOut: {} });

  const uid = useRef(0);

  const registerZoomIn = useCallback((f: () => void) => {
    const id = uid.current++;
    subs.current.onZoomIn[id] = f;
    return () => {
      delete subs.current.onZoomIn[id];
    };
  }, []);

  const registerZoomOut = useCallback((f: () => void) => {
    const id = uid.current++;
    subs.current.onZoomOut[id] = f;
    return () => {
      delete subs.current.onZoomOut[id];
    };
  }, []);

  const zoomIn = useCallback(() => {
    Object.values(subs.current.onZoomIn).forEach((f) => f());
  }, []);

  const zoomOut = useCallback(() => {
    Object.values(subs.current.onZoomOut).forEach((f) => f());
  }, []);

  const api = useMemo(
    () => ({
      zoomIn,
      zoomOut,
      registerZoomIn,
      registerZoomOut,
    }),
    [],
  );

  return <zoomContext.Provider value={api}>{children}</zoomContext.Provider>;
};

type FileViewerFooterProps = {
  filePath: string;
  url: string;
  fileType: ReturnType<typeof getFileType>;
  onDelete?: () => void;
  onReplace?: (data: any) => void;
  onDownLoad?: () => void;
  onEdit?: (() => void) | null;
  onDuplicate?: (() => void) | null;
  disabled?: boolean;
  color?: IColors;
  tracing?: TracingConfig;
};

type FileViewerProps = Omit<FileViewerFooterProps, 'url' | 'fileType'> & {
  floatingItemsMargin: string;
  filePath: string;
  hideControllers?:
    | boolean
    | ((fileType: ReturnType<typeof getFileType>) => boolean);
};

const filePathToFileName = (filePath: string) => {
  const parts = filePath.split('/');
  return parts[parts.length - 1];
};

const FileViewerFooter: FC<FileViewerFooterProps> = ({
  filePath,
  onDelete,
  onReplace,
  onDownLoad,
  onEdit,
  onDuplicate,
  disabled,
  color,
  tracing,
  url,
  fileType,
}) => {
  const { t } = useTranslation();

  const { zoomIn, zoomOut } = useContext(zoomContext);

  const isPDF = fileType === 'pdf';
  const isImage = fileType === 'image';

  const PDFBlob = useGetBlob(url, {
    enabled: isPDF,
  });

  const fileName = filePathToFileName(filePath);

  const download = useMutation(
    () =>
      getFileUrl(filePath).then(({ url }) => downloadAttachment(url, fileName)),
    {
      onSuccess: () => {
        onDownLoad?.();
      },
    },
  );

  const upload = useMutation((file: File) => uploadFile({ file }), {
    onSuccess: (data) => {
      onReplace?.(data);
    },
  });

  const { getInputProps, open } = useCustomDropzone({
    onDropAccepted: ([file]) => upload.mutate(file),
    multiple: false,
    maxSize: maxFileSize,
    accept: globalyAcceptedFileUploaderTypes,
  });

  return (
    <div className="grid auto-cols-fr grid-flow-col gap-6 bg-primary bg-opacity-75 rounded-lg p-4">
      <Button
        onClick={() => download.mutate()}
        tracingEvent={tracing?.onDownload}
        disabled={download.isLoading}
        structure="text"
        color={color}
      >
        <div className="text-center flex flex-col gap-1.5">
          {download.isLoading ? (
            renderLoader('w-6 mx-auto')
          ) : (
            <DownloadOutlineIcon className="w-6 mx-auto" />
          )}
          <span className="text-white">{t('download')}</span>
        </div>
      </Button>
      {onEdit && (
        <Button
          onClick={() => onEdit()}
          tracingEvent={tracing?.onEdit}
          structure="text"
          color={color}
        >
          <div className="text-center flex flex-col gap-1.5">
            <EditOutlineIcon className="w-6 mx-auto" />
            <span className="text-white">{t('edit')}</span>
          </div>
        </Button>
      )}
      {onDuplicate && (
        <Button
          onClick={() => onDuplicate()}
          tracingEvent={tracing?.onDuplicate}
          structure="text"
          color={color}
        >
          <div className="text-center flex flex-col gap-1.5">
            <CopyOutlineIcon className="w-6 mx-auto" />
            <span className="text-white">{t('duplicate')}</span>
          </div>
        </Button>
      )}
      {isPDF && (
        <Button
          disabled={!PDFBlob.data}
          onClick={() => {
            if (!PDFBlob.data) return;
            const fileURL = URL.createObjectURL(PDFBlob.data);
            window.open(fileURL, '_blank', 'noopener noreferrer');
          }}
          tracingEvent={tracing?.onOpenPDFInNewWindow}
          structure="text"
          color={color}
        >
          <div className="text-center flex flex-col gap-1.5">
            <NewLinkOutlineIcon className="w-6 mx-auto" />
            <span className="text-white">{t('open')}</span>
          </div>
        </Button>
      )}
      {(isImage || isPDF) && (
        <>
          <Button
            onClick={() => {
              zoomIn();
            }}
            tracingEvent={tracing?.onZoomIn}
            structure="text"
            color={color}
          >
            <div className="text-center flex flex-col gap-1.5">
              <ZoomInOutlineIcon className="w-6 mx-auto" />
              <span className="text-white">{t('zoom_in')}</span>
            </div>
          </Button>
          <Button
            onClick={() => {
              zoomOut();
            }}
            tracingEvent={tracing?.onZoomIn}
            structure="text"
            color={color}
          >
            <div className="text-center flex flex-col gap-1.5">
              <ZoomOutOutlineIcon className="w-6 mx-auto" />
              <span className="text-white">{t('zoom_out')}</span>
            </div>
          </Button>
        </>
      )}
      <input {...getInputProps()} />
      {!disabled && (
        <>
          <Button
            onClick={() => open()}
            tracingEvent={tracing?.onReplace}
            disabled={upload.isLoading}
            structure="text"
            color={color}
          >
            <div className="text-center flex flex-col gap-1.5">
              {upload.isLoading ? (
                renderLoader('w-6 mx-auto')
              ) : (
                <Upload2OutlineIcon className="w-6 mx-auto" />
              )}
              <span className="text-white">{t('replace')}</span>
            </div>
          </Button>
          <Button
            onClick={onDelete}
            tracingEvent={tracing?.onDelete}
            structure="text"
            color={color}
          >
            <div className="text-center flex flex-col gap-1.5">
              <Trash2OutlineIcon className="w-6 mx-auto" />
              <span className="text-white">{t('delete')}</span>
            </div>
          </Button>
        </>
      )}
    </div>
  );
};

const PDFPage: FC<any> = (props) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <Page
      onRenderSuccess={() => setLoaded(true)}
      {...props}
      canvasRef={(elm) => elm?.getContext?.('2d', { alpha: false })}
      className={classNames(props.className, { 'opacity-0': !loaded })}
    />
  );
};

const PDFViewer: FC<{ url: string }> = ({ url }) => {
  const blob = useGetBlob(url);

  const { registerZoomIn, registerZoomOut } = useContext(zoomContext);
  const docRef = useRef<HTMLDivElement>(null);
  const [pageWidth, setPageWidth] = useState<number>();
  const [scale, setScale] = useState(1);
  const [pages, setPages] = useState(1);

  const loaded = !!blob.data;

  const isError = blob.isError;

  useEffect(() => {
    const baseZoom = 1;
    const zoomToMax = baseZoom / 0.8 - baseZoom;
    const zoomMax = baseZoom + zoomToMax;

    const zoomStep = zoomToMax / 2;

    const zoomInUnsub = registerZoomIn(() => {
      setScale((prevScale) => Math.min(prevScale + zoomStep, zoomMax));
    });

    const zoomOutUnsub = registerZoomOut(() => {
      setScale((prevScale) => Math.max(prevScale - zoomStep, baseZoom));
    });

    return () => {
      zoomInUnsub();
      zoomOutUnsub();
    };
  }, [registerZoomIn, registerZoomOut]);

  useLayoutEffect(() => {
    if (!loaded || !docRef.current) return;
    const resizeObservable = new ResizeObserver((entries) => {
      setPageWidth(entries[0].contentRect.width * 0.75);
    });
    resizeObservable.observe(docRef.current);
    return () => {
      resizeObservable.disconnect();
    };
  }, [loaded]);

  const retry = () => {
    blob.refetch();
  };

  return (
    <>
      {(!loaded || isError) && (
        <div className="h-full flex items-center justify-center">
          {isError ? <ErrorBtn onClick={retry} /> : <CustomLoader />}
        </div>
      )}
      {loaded && (
        <Document
          className={classNames('flex flex-col items-center', {
            'opacity-0': !loaded,
          })}
          inputRef={docRef}
          file={blob.data}
          onLoadSuccess={({ numPages }) => {
            setPages(numPages);
          }}
        >
          {Array.from({ length: pages }).map((_, i) => (
            <PDFPage
              key={i}
              pageIndex={i}
              width={pageWidth}
              scale={scale}
              className="my-4 overflow-hidden"
            />
          ))}
        </Document>
      )}
    </>
  );
};

const ImageViewer: FC<{ url: string }> = ({ url }) => {
  const { registerZoomIn, registerZoomOut } = useContext(zoomContext);

  const { isError, isLoading, data, refetch } = useQuery(
    url,
    (): Promise<{ src: string; width: number; height: number }> =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.onerror = () => reject();
        img.onload = () =>
          resolve({
            src: img.src,
            height: img.naturalHeight,
            width: img.naturalWidth,
          });
        img.src = url;
      }),
    {
      cacheTime: 0,
      retry: 1,
      onError: (error) => {
        Sentry.captureException(error);
      },
    },
  );

  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  const [ref, setRef] = useState<ReactZoomPanPinchContentRef | null>(null);

  const imageScale = useMemo(() => {
    if (!containerWidth || !containerHeight || !data?.width || !data?.height) {
      return 0;
    }

    // the image will be renderd with the width of the container anyway
    // so we try to get the height of the image if it was rendered with the width of the container

    const imageHeightToWidthRatio = data.height / data.width;

    const imageNewHeight = containerWidth * imageHeightToWidthRatio;

    const scale = Math.min(1, containerHeight / imageNewHeight);

    return scale;
  }, [containerWidth, containerHeight, data?.width, data?.height]);

  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect();
      setContainerWidth(rect.width);
      setContainerHeight(rect.height);
    } else {
      setContainerWidth(0);
      setContainerHeight(0);
    }
  }, [container]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (!ref) return;
    const zoomInUnsub = registerZoomIn(() => {
      ref.zoomIn();
    });

    const zoomOutUnsub = registerZoomOut(() => {
      ref.zoomOut();
    });

    return () => {
      zoomInUnsub();
      zoomOutUnsub();
    };
  }, [registerZoomIn, registerZoomOut, ref]);

  if (isError) {
    return (
      <div className="h-full flex items-center justify-center">
        <ErrorBtn onClick={() => refetch()} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <CustomLoader />
      </div>
    );
  }

  return (
    <div
      className="flex h-full m-auto w-4/5 relative"
      ref={(el: HTMLDivElement | null) => setContainer(el)}
    >
      {imageScale > 0 && (
        <TransformWrapper
          key={`${containerWidth}x${containerHeight}`}
          ref={setRef}
          initialScale={imageScale}
          minScale={imageScale}
          centerOnInit
        >
          <TransformComponent
            wrapperStyle={{
              width: '100%',
              height: '100%',
            }}
            contentClass="cursor-grab"
          >
            <img src={url} alt="" />
          </TransformComponent>
        </TransformWrapper>
      )}
    </div>
  );
};

const getFileType = (url: string) => {
  const fileType = (mime as any).getType(url.split('?')[0]);

  if (!fileType) return null;
  if (fileType.includes('image/')) return 'image';
  if (fileType === 'application/pdf') return 'pdf';
  if (fileType.includes('text/csv')) return 'csv';
  return null;
};

function renderFile(url: string): ReactNode {
  const fileType = getFileType(url);
  if (!fileType) return null;
  if (fileType === 'image') return <ImageViewer url={url} />;
  if (fileType === 'pdf') return <PDFViewer url={url} />;
  if (fileType === 'csv')
    return (
      <div className="overflow-x-auto px-10">
        <CSVViewer url={url} />
      </div>
    );
  return null;
}

type ErrorBtnProps = {
  onClick: () => void;
};
const ErrorBtn: FC<ErrorBtnProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button structure="text" {...props}>
      <div className="text-white text-center">
        <RefreshIcon className="w-20 mx-auto opacity-75" />
        <span className="text-lg font-semibold">
          {t('backend.error.something_went_wrong')}
          {'. '}
          <span className="border px-2 py-1 rounded">{t('retry')}</span>
        </span>
      </div>
    </Button>
  );
};

const FileViewer: FC<FileViewerProps> = ({
  filePath,
  hideControllers,
  floatingItemsMargin,
  ...restFooterProps
}) => {
  const { isLoading, isError, data, refetch } = useQuery(
    [FilesUrls.FileUrl, filePath],
    () => getFileUrl(filePath),
  );

  const content = useMemo(() => {
    if (!data?.url) return null;
    return renderFile(get(data, 'url', '') as string);
  }, [get(data, 'url')]);

  if (isLoading)
    return (
      <div className="h-full flex items-center justify-center">
        <CustomLoader />
      </div>
    );

  if (isError)
    return (
      <div className="h-full flex items-center justify-center">
        <ErrorBtn onClick={() => refetch()} />
      </div>
    );

  if (!data) return null;

  const fileType = getFileType(data.url);

  const shouldHideControllers =
    !content ||
    (typeof hideControllers === 'function'
      ? hideControllers(fileType)
      : !!hideControllers);

  return (
    <>
      <ZoomProvider>
        {content}
        {!shouldHideControllers && (
          <div
            style={{
              left: floatingItemsMargin
                ? `calc(50% - ${floatingItemsMargin} / 2)`
                : '50%',
            }}
            className={classNames('fixed z-10 bottom-0 -translate-x-1/2 mb-8')}
          >
            <FileViewerFooter
              filePath={filePath}
              url={data.url}
              fileType={getFileType(data.url)}
              {...restFooterProps}
            />
          </div>
        )}
      </ZoomProvider>
    </>
  );
};

export default FileViewer;
