import { CellsModifiers } from 'types/CellsModifiers.types';
import { ClientTypeEnum } from 'types/clients.types';
import { AvailableCountries } from 'types/users.types';
import { caseInsensitiveEqual } from 'utils/helpers';

const clients = {
  client_types: [ClientTypeEnum.business, ClientTypeEnum.private],
  has_VAT_field_for_same_country: false,
  shouldPrefillVATPrefix: (clientCountryCode: string): boolean =>
    !caseInsensitiveEqual(clientCountryCode, AvailableCountries.germany),
  vat_label: {
    same_country: null,
    other_country: 'client.field.vat_number.title.de',
  },
  getIsSearchableCountry: (countryCode: string): boolean => {
    return countryCode === AvailableCountries.germany;
  },
  list: {
    cells: {
      _modifiers: [{ name: CellsModifiers.clients }],
      default_sort: {
        web: 'clientName_asc',
        experts: 'clientName_asc',
      },
      first_group: {
        web: ['NAME', 'CITY', 'VATNUMBER'],
        experts: ['NAME', 'CITY', 'VATNUMBER'],
      },
      second_group: {
        web: ['NAME', 'CITY', 'VATNUMBER'],
        experts: ['NAME', 'CITY', 'VATNUMBER'],
      },
    },
  },
};

export default clients;
