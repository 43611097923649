import { getExpenses } from 'api/v2/expenses';
import { getRevenues } from 'api/v2/revenues';
import { getTaxCheck, reviewTaxCheck, undoReviewTaxCheck } from 'api/v2/taxes';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useFeatures from 'hooks/useFeatures';
import { get } from 'lodash';
import type { FC } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import type { ITax } from 'types/taxes.types';
import { TaxCheckEnum } from 'types/taxes.types';
import { logAmplitude } from 'utils/amplitude';
import { WEBAPP_TAXREVIEW_MARKCHECKASREVIEWED } from 'utils/amplitude/events/taxes.amplitude';
import formatPeriodLabel from 'utils/formatPeriodLabel';
import create from 'zustand';
import { CurrentTaxContext } from '../Tax';
import AllReviewed from './ChecksComponents/AllReviewed';
import CommuterAllowanceAmountHigh from './ChecksComponents/CommuterAllowanceAmountHigh';
import CommuterAllowanceDaysHigh from './ChecksComponents/CommuterAllowanceDaysHigh';
import ExpenseAbove1000Euro from './ChecksComponents/ExpenseAbove1000Euro';
import ExpenseAmortizedPeriodNotRecommended from './ChecksComponents/ExpenseAmortizedPeriodNotRecommended';
import ExpenseDuplicates from './ChecksComponents/ExpenseDuplicates';
import ExpenseFakeReceipt from './ChecksComponents/ExpenseFakeReceipts';
import ExpenseFranchiseeAssessmentPeriodVAT from './ChecksComponents/ExpenseFranchiseeAssessmentPeriodVAT';
import ExpenseFranchiseeRegime from './ChecksComponents/ExpenseFranchiseeRegime';
import ExpenseInvoiceWithoutVATNumber from './ChecksComponents/ExpenseInvoiceWithoutVATNumber';
import ExpenseLinkedToVATPayment from './ChecksComponents/ExpenseLinkedToVATPayment';
import ExpenseLunchVoucherNaturalPerson from './ChecksComponents/ExpenseLunchVoucherNaturalPerson';
import ExpenseMismatchVATRateWithCategory from './ChecksComponents/ExpenseMismatchVATRateWithCategory';
import ExpenseMissingDocument from './ChecksComponents/ExpenseMissingDocument';
import ExpenseNotReviewed from './ChecksComponents/ExpenseNotReviewed';
import ExpensePaymentMismatchWithDocument from './ChecksComponents/ExpensePaymentMismatchWithDocument';
import ExpensePerDiemNatural from './ChecksComponents/ExpensePerDiemNatural';
import ExpensePersonalSupplier from './ChecksComponents/ExpensePersonalSupplier';
import ExpenseRestaurantPersonal from './ChecksComponents/ExpenseRestaurantPersonal';
import ExpenseReverseCharge from './ChecksComponents/ExpenseReverseCharge';
import ExpenseSubjectToVATAssessmentPeriodVAT from './ChecksComponents/ExpenseSubjectToVATAssessmentPeriodVAT';
import ExpenseSufficientlyDocumentedWithoutDocument from './ChecksComponents/ExpenseSufficientlyDocumentedWithoutDocument';
import ExpenseTicketWithVATNumber from './ChecksComponents/ExpenseTicketWithVATNumber';
import ExpenseVATClaimedAbove19 from './ChecksComponents/ExpenseVATClaimedAbove19';
import ExpenseVATClaimedAbove21 from './ChecksComponents/ExpenseVATClaimedAbove21';
import ExpenseVehicleACPCodeMismatch from './ChecksComponents/ExpenseVehicleACPCodeMismatch';
import ExpenseVehicleMore35Deductibility from './ChecksComponents/ExpenseVehicleMore35Deductibility';
import ExpenseVehiclePurchaseAbove500 from './ChecksComponents/ExpenseVehiclePurchaseAbove500';
import MissingVATPayments from './ChecksComponents/MissingVATPayments';
import OnePercentRuleExpenseNoUsage from './ChecksComponents/OnePercentRuleExpenseNoUsage';
import RevenueFranchiseeAssessmentPeriodVAT from './ChecksComponents/RevenueFranchiseeAssessmentPeriodVAT';
import RevenueFranchiseeRegime from './ChecksComponents/RevenueFranchiseeRegime';
import RevenueNotPaid from './ChecksComponents/RevenueNoPaid';
import RevenueNotReviewed from './ChecksComponents/RevenueNotReviewed';
import RevenueNotSent from './ChecksComponents/RevenueNotSent';
import RevenuePaymentMismatchWithDocument from './ChecksComponents/RevenuePaymentMismatchWithDocument';
import RevenueSubjectToVATAssessmentPeriodVAT from './ChecksComponents/RevenueSubjectToVATAssessmentPeriodVAT';
import SubmissionExpenseIncludedInAnotherSubmission from './ChecksComponents/SubmissionExpenseIncludedInAnotherSubmission';
import SubmissionRevenueIncludedInAnotherSubmission from './ChecksComponents/SubmissionRevenueIncludedInAnotherSubmission';
import VATRefundMoreThan1000Euro from './ChecksComponents/VATRefundMoreThan1000Euro';
import CheckUITemplate from './CheckUITemplate';

export const useShouldAutoReview = (
  checkName: TaxCheckEnum,
): ((checkData: any) => boolean) => {
  switch (checkName) {
    case TaxCheckEnum.onePercentRuleExpenseNoUsageNewYear:
    case TaxCheckEnum.onePercentRuleExpenseMixedUsage:
    case TaxCheckEnum.onePercentRuleExpenseNoUsage:
    case TaxCheckEnum.missingVATPaymentQuarterly:
    case TaxCheckEnum.VATRefundMoreThan1000Euro:
    case TaxCheckEnum.missingVATPaymentMonthly:
      return () => false;
    default:
      return (checkData: any) => checkData?.data && !checkData?.data?.length;
  }
};

export const useGetTaxCheck = (
  checkName: TaxCheckEnum,
  tax: ITax,
  page: number,
): any => {
  const shouldAutoReview = useShouldAutoReview(checkName);
  const { mutate: markAsReviewed } = useReviewTaxCheck(checkName, tax, 1500);
  return useQuery(
    [checkName, page],
    () =>
      getTaxCheck(checkName, tax, page).then((res: any) => ({
        ...res,
        queryCheckName: checkName,
      })),
    {
      enabled: !!(checkName && !!tax?._id),
      cacheTime: 0,
      keepPreviousData: true,
      onSuccess: (res) => {
        if (shouldAutoReview(res)) {
          markAsReviewed();
        }
      },
    },
  );
};

export const useReviewTaxCheck = (
  id: TaxCheckEnum,
  tax: ITax,
  timeout?: number,
): any => {
  const { setCurrentCheck, refetch: refetchTax } = useContext(
    CurrentTaxContext,
  ) as any;
  return useMutation(() => reviewTaxCheck(id, tax?._id), {
    onMutate: () => {
      logAmplitude(WEBAPP_TAXREVIEW_MARKCHECKASREVIEWED, { checkId: id });
    },
    onSuccess: () => {
      const isLastCheck = get(tax, 'guarantee.stats.failed') === 1;
      setTimeout(() => {
        if (!isLastCheck) {
          // set current check with null and go back to checks list
          setCurrentCheck(null);
        }
      }, timeout || 0);
      setTimeout(() => refetchTax(), (timeout || 0) + 100);
    },
  });
};

export const useUndoReviewTaxCheck = (tax: ITax): any => {
  const { refetch: refetchTax } = useContext(CurrentTaxContext);
  return useMutation(
    (checkName: TaxCheckEnum) => undoReviewTaxCheck(checkName, tax?._id),
    {
      onSuccess: () => {
        refetchTax?.();
      },
    },
  );
};

const useTaxCheckUIComp = ({
  checkName,
}: {
  checkName: TaxCheckEnum;
}): FC<any> => {
  if (checkName?.startsWith(TaxCheckEnum.revenueIncludedInAnotherSubmission))
    return SubmissionRevenueIncludedInAnotherSubmission;
  if (checkName?.startsWith(TaxCheckEnum.expenseIncludedInAnotherSubmission))
    return SubmissionExpenseIncludedInAnotherSubmission;
  const compMap: Record<TaxCheckEnum, any> = {
    [TaxCheckEnum.expenseDuplicate]: ExpenseDuplicates,
    [TaxCheckEnum.expenseMissingDocument]: ExpenseMissingDocument,
    [TaxCheckEnum.expenseSufficientlyDocumentedWithoutDocument]:
      ExpenseSufficientlyDocumentedWithoutDocument,
    [TaxCheckEnum.expenseNotReviewed]: ExpenseNotReviewed,
    [TaxCheckEnum.expenseAbove1000Euro]: ExpenseAbove1000Euro,
    [TaxCheckEnum.expenseAmortizedPeriodNotRecommended]:
      ExpenseAmortizedPeriodNotRecommended,
    [TaxCheckEnum.revenueNotSent]: RevenueNotSent,
    [TaxCheckEnum.revenueNotReviewed]: RevenueNotReviewed,
    [TaxCheckEnum.VATRefundMoreThan1000Euro]: VATRefundMoreThan1000Euro,
    [TaxCheckEnum.expenseReverseCharge]: ExpenseReverseCharge,
    [TaxCheckEnum.expenseVATClaimedAbove21]: ExpenseVATClaimedAbove21,
    [TaxCheckEnum.expenseVATClaimedAbove91]: ExpenseVATClaimedAbove19,
    [TaxCheckEnum.expenseLinkedToVATPayment]: ExpenseLinkedToVATPayment,
    [TaxCheckEnum.expensePerDiemNatural]: ExpensePerDiemNatural,
    [TaxCheckEnum.expenseFakeReceipt]: ExpenseFakeReceipt,
    [TaxCheckEnum.expenseLunchVoucherNaturalPerson]:
      ExpenseLunchVoucherNaturalPerson,
    [TaxCheckEnum.expenseVehicleMore35deductibility]:
      ExpenseVehicleMore35Deductibility,
    [TaxCheckEnum.expenseMismatchVATRateWithCategory]:
      ExpenseMismatchVATRateWithCategory,
    [TaxCheckEnum.expensePaymentMismatchWithDocument]:
      ExpensePaymentMismatchWithDocument,
    [TaxCheckEnum.revenuePaymentMismatchWithDocument]:
      RevenuePaymentMismatchWithDocument,
    [TaxCheckEnum.expensePersonalSupplier]: ExpensePersonalSupplier,
    [TaxCheckEnum.AllReviewed]: AllReviewed,
    [TaxCheckEnum.expenseRestaurantPersonal]: ExpenseRestaurantPersonal,
    [TaxCheckEnum.expenseVehiclePurchaseAbove500]:
      ExpenseVehiclePurchaseAbove500,
    [TaxCheckEnum.submissionRevenueIncludedInAnotherSubmission]:
      SubmissionRevenueIncludedInAnotherSubmission,
    [TaxCheckEnum.submissionExpenseIncludedInAnotherSubmission]:
      SubmissionExpenseIncludedInAnotherSubmission,
    [TaxCheckEnum.revenueNotPaid]: RevenueNotPaid,
    [TaxCheckEnum.expenseInvoiceWithoutVATNumber]:
      ExpenseInvoiceWithoutVATNumber,
    [TaxCheckEnum.expenseFranchiseeRegime]: ExpenseFranchiseeRegime,
    [TaxCheckEnum.revenueFranchiseeRegime]: RevenueFranchiseeRegime,
    [TaxCheckEnum.expenseVehicleACPCodeMismatch]: ExpenseVehicleACPCodeMismatch,
    [TaxCheckEnum.expenseTicketWithVATNumber]: ExpenseTicketWithVATNumber,
    [TaxCheckEnum.missingVATPaymentMonthly]: MissingVATPayments,
    [TaxCheckEnum.missingVATPaymentQuarterly]: MissingVATPayments,

    [TaxCheckEnum.onePercentRuleExpenseNoUsage]: OnePercentRuleExpenseNoUsage,
    [TaxCheckEnum.onePercentRuleExpenseNoUsageNewYear]:
      OnePercentRuleExpenseNoUsage,
    [TaxCheckEnum.onePercentRuleExpenseMixedUsage]: CheckUITemplate,
    [TaxCheckEnum.commuterAllowanceDaysHigh]: CommuterAllowanceDaysHigh,
    [TaxCheckEnum.commuterAllowanceAmountHigh]: CommuterAllowanceAmountHigh,
    [TaxCheckEnum.submissionExpenseFranchiseeAssessmentPeriodVAT]:
      ExpenseFranchiseeAssessmentPeriodVAT,
    [TaxCheckEnum.submissionExpenseSubjectToVATAssessmentPeriodVAT]:
      ExpenseSubjectToVATAssessmentPeriodVAT,
    [TaxCheckEnum.submissionRevenueFranchiseeAssessmentPeriodVAT]:
      RevenueFranchiseeAssessmentPeriodVAT,
    [TaxCheckEnum.submissionRevenueSubjectToVATAssessmentPeriodVAT]:
      RevenueSubjectToVATAssessmentPeriodVAT,
  } as any;
  return compMap[checkName] || (() => null);
};

export const useTaxPaymentExpensesCount = ({
  categoryIds, // formatted as the following : 'cat1,cat2,cat3'
  enabled = true,
}: {
  categoryIds: string[];
  enabled?: boolean;
}) => {
  const { data: tax } = useContext(CurrentTaxContext);
  const periodYear = tax?.period?.year;
  return useQuery(
    ['VAT_PAYMENT_EXPENSES'],
    () =>
      getExpenses({
        page: 1,
        perPage: 1,
        categoryIds,
        dateRange: [`${periodYear}-01-01`, `${periodYear}-12-31`],
      })
        .then((data) => data.paging.totalCount || 0)
        .catch(() => 0),
    {
      enabled,
    },
  );
};

export const useTaxRefundsRevenuesCount = ({
  categoryIds, // formatted as the following : 'cat1,cat2,cat3'
  enabled = true,
}: {
  categoryIds: string[];
  enabled?: boolean;
}): any => {
  const { data: tax } = useContext(CurrentTaxContext);
  const periodYear = tax?.period?.year;
  return useQuery(
    ['VAT_PAYMENT_REFUNDS'],
    () =>
      getRevenues({
        page: 1,
        perPage: 1,
        categoryIds,
        dateRange: [`${periodYear}-01-01`, `${periodYear}-12-31`],
      })
        .then((data) => data.paging.totalCount || 0)
        .catch(() => 0),
    {
      enabled,
    },
  );
};

const useChecksTranslationParams = () => {
  const { t } = useTranslation();
  const { data: user } = useGetCurrentCustomerData();
  const userCountry = useCustomerCountry();
  const normalVatRate = useFeatures('expenses.default_vat_rate');
  const { data: tax } = useContext(CurrentTaxContext);

  return {
    userCountry: t(`country.${userCountry}`),
    normalRate: `${normalVatRate * 100}%`,
    occupation: user?.occupation || user?.APC2,
    year: tax?.period?.year,
    period: formatPeriodLabel(tax?.period),
    // tripWorkingDays
  };
};

export const useGetTaxCheckTranslation = (
  prefix: string,
  postfix: string,
  translationParams?: Record<string, string | number>,
): ((checkName: TaxCheckEnum) => string) => {
  const params = useChecksTranslationParams();
  const userCountry = useCustomerCountry();
  const { t, i18n } = useTranslation();
  const i18nParams = { ...params, ...(translationParams || {}) };
  return (checkName: string) => {
    const formatedCheckName = checkName?.replace(/-/g, '_');
    return i18n.exists(
      `${prefix}.${formatedCheckName}.${postfix}.${userCountry}`,
    )
      ? t(
          `${prefix}.${formatedCheckName}.${postfix}.${userCountry}`,
          i18nParams,
        )
      : t(`${prefix}.${formatedCheckName}.${postfix}`, i18nParams);
  };
};

export const useReviewSkipStatus = create<{
  skipped: boolean;
  setSkipped: (skipped: boolean) => void;
}>((set) => ({
  skipped: false,
  setSkipped: (skipped: boolean) => set({ skipped }),
}));

export default useTaxCheckUIComp;
