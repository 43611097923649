import { Menu as RootMenu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Fragment, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button as ButtonComp } from '../Button';
import { Loader } from '../Loader';
import type {
  MenuButtonProps,
  MenuGroupProps,
  MenuLinkProps,
  MenuProps,
} from './Menu.types';

const Group: FC<MenuGroupProps> = ({ children, ...props }) => (
  <div className="px-3 py-3" {...props}>
    {children}
  </div>
);

const Link: FC<MenuLinkProps> = ({
  children,
  to,
  activeClassName = 'bg-primary-800 text-white',
  inactiveClassName = 'text-gray-900',
  className = '',
}) => (
  <RootMenu.Item>
    {({ active }) => (
      <RouterLink
        to={to}
        className={classNames(
          `${
            active ? activeClassName : inactiveClassName
          } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary-800 hover:text-white font-semibold`,
          className,
        )}
      >
        {children}
      </RouterLink>
    )}
  </RootMenu.Item>
);

const Button: FC<MenuButtonProps> = ({
  children,
  loading,
  extendClassNames,
  baseTextColorClass = 'text-primary',
  ...props
}) => (
  <RootMenu.Item>
    {({ active }) => (
      <ButtonComp
        asDefault
        disabled={loading}
        className={classNames(
          active ? 'bg-primary-800 text-white' : baseTextColorClass,
          extendClassNames,
          'text-init group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary-800 hover:text-white font-semibold',
        )}
        {...props}
      >
        {loading ? (
          <span className="mr-1">
            <Loader />
          </span>
        ) : null}
        {children}
      </ButtonComp>
    )}
  </RootMenu.Item>
);

const MenuComponent: FC<MenuProps> = ({
  Button,
  children,
  position = 'left',
  buttonProps = {},
  trigger,
  contentWrapperClassName,
  rootClassName,
  onCloseAnimationEnd,
  onOpenAnimationStart,
  unmount = true,
}) => {
  return (
    <RootMenu as="div" className={classNames('relative', rootClassName)}>
      {(api) => (
        <>
          <RootMenu.Button {...buttonProps}>
            {trigger ? trigger(api) : Button}
          </RootMenu.Button>
          <Transition
            unmount={unmount}
            beforeEnter={onOpenAnimationStart}
            afterLeave={onCloseAnimationEnd}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <RootMenu.Items
              unmount={unmount}
              className={classNames(
                'absolute z-20 w-64 divide-y divide-gray-100 break-words rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-assistant',
                {
                  'left-0': position === 'left',
                  'right-0': position === 'right',
                  'bottom-0': position === 'top',
                  'top-0': position === 'bottom',
                  'bottom-0 right-0': position === 'topLeft',
                  'left-0 right-0 ml-auto mr-auto': position === 'center',
                },
                contentWrapperClassName,
              )}
            >
              {children}
            </RootMenu.Items>
          </Transition>
        </>
      )}
    </RootMenu>
  );
};

type SubMenuProps = MenuButtonProps &
  PropsWithChildren<{
    renderLabel: (isMenuOpen: boolean) => ReactNode;
    getClassName?: (isMenuOpen: boolean) => string;
  }>;

const SubMenu: FC<SubMenuProps> = ({
  renderLabel,
  children,
  getClassName,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        {...rest}
        className={classNames(rest.className, getClassName?.(isOpen))}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen((bool) => !bool);
        }}
      >
        {renderLabel(isOpen)}
      </Button>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform -translate-y-1"
      >
        <div className="ml-2">{children}</div>
      </Transition>
    </>
  );
};

export default Object.assign(MenuComponent, {
  Link,
  Group,
  Button,
  SubMenu,
});
