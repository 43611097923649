import type { ReactNode } from 'react';
import parse from './parse';

const parseBold = (str: string): ReactNode =>
  str
    .split(/\*\*([\s\S]*?)\*\*/g) // **bold Text**
    .map((part, index) =>
      index % 2 ? <strong key={part + index}>{part}</strong> : part,
    );

const boldFormatter = (element: ReactNode): ReactNode | string =>
  parse(element, parseBold);

export default boldFormatter;
