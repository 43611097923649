import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAiUsageConsentEnum } from 'types/users.types';

const AIConsentCheckbox = ({ isSelected }: { isSelected: boolean }) => (
  <div className="absolute right-4 top-4 flex items-center">
    {isSelected ? (
      <div className="shadow-md w-5 h-5 bg-taxes rounded-full flex items-center justify-center shadow-[#E6DEF4]">
        <div className="shadow-md w-2 h-2 bg-white rounded-full animate-fadeIn"></div>
      </div>
    ) : (
      <div className="shadow-md w-5 h-5 bg-white rounded-full border-[#EBE8ED] border" />
    )}
  </div>
);

type AIConsentSwitchProps = {
  requiresFullAccess?: boolean;
  value: UserAiUsageConsentEnum;
  onChange: (value: UserAiUsageConsentEnum) => void;
};

const AIConsentSwitch: FC<AIConsentSwitchProps> = ({
  requiresFullAccess = false,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col border border-[#EBE8ED] rounded-lg mt-4">
      <button
        className="pl-6 pr-20 py-4 cursor-pointer text-left relative hover:bg-primary-25"
        onClick={() => onChange(UserAiUsageConsentEnum.allow)}
      >
        <p className="font-bold text-sm">
          {t('ai_bot.data_share.alert.options.allow.title')}
        </p>
        <p className="text-sm">
          {t('ai_bot.data_share.alert.options.allow.description')}
        </p>

        <AIConsentCheckbox
          isSelected={value === UserAiUsageConsentEnum.allow}
        />
      </button>
      <div className="border-b border-[#EBE8ED]" />
      <button
        className="pl-6 pr-20 py-4 cursor-pointer text-left relative hover:bg-primary-25"
        onClick={() => onChange(UserAiUsageConsentEnum.limited)}
      >
        <p className="font-bold text-sm">
          {t('ai_bot.data_share.alert.options.limit.title')}
        </p>
        <p className="text-sm">
          {t('ai_bot.data_share.alert.options.limit.description')}
        </p>

        {requiresFullAccess && value === UserAiUsageConsentEnum.limited && (
          <p className="font-bold text-red-500 text-sm">
            {t('ai_bot.data_share.alert.options.limited_use_case')}
          </p>
        )}

        <AIConsentCheckbox
          isSelected={value === UserAiUsageConsentEnum.limited}
        />
      </button>
    </div>
  );
};

export default AIConsentSwitch;
