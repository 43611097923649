import { getProfitSettings } from 'api/v2/taxes';
import { useQuery } from 'react-query';

const useProfitMeta = () => {
  return useQuery('PROFIT_CALCULATIONS', getProfitSettings, {
    cacheTime: 0,
  });
};

export default useProfitMeta;
