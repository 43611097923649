/* eslint-disable @typescript-eslint/ban-types */
import { accountableAPI } from 'config';
import type {
  AuthenticationDataType,
  IAPIRefreshAccessTokenResponse,
} from 'types/auth.types';
import { isWeb } from './constants';
import { deparallelize } from './promise';

export function getStoredAuthData(): AuthenticationDataType | {};

export function getStoredAuthData<K extends keyof AuthenticationDataType>(
  key: K,
): AuthenticationDataType[K] | undefined;

export function getStoredAuthData(
  key?: string,
): AuthenticationDataType | {} | string | undefined {
  const authData =
    window.self != window.top // If in an iframe, keep logged out.
      ? {}
      : JSON.parse(localStorage.getItem('auth') || '{}');

  return key ? authData[key] : authData;
}

export const getUpToDateAuthData = deparallelize(
  async (): Promise<AuthenticationDataType | null> => {
    const authData = getStoredAuthData();

    if (!('access_token' in authData)) return null;

    const timeToExpiry = authData.exp - Date.now() / 1000;

    // If less than 10 minutes remaining until expiry
    if (timeToExpiry < 600) {
      // Update access token in the background
      const newAuthDataPromise = forceUpdateAuthData();

      // If less than 5 seconds remaining until expiry
      if (timeToExpiry < 5) {
        // Wait for the updated auth data instead
        return newAuthDataPromise;
      }
    }

    return authData;
  },
);

const baseUrl = accountableAPI.baseUrl;

export const forceUpdateAuthData = deparallelize(
  async (): Promise<AuthenticationDataType | null> => {
    let authData = getStoredAuthData();

    if (!('refresh_token' in authData)) return null;

    // If refresh token is already expired, don't bother trying to refresh access token
    if (new Date(authData.refresh_token_expires_at).getTime() < Date.now())
      return null;

    const { refresh_token } = authData;

    const response = await fetch(
      `${baseUrl}/v2/${isWeb ? 'users' : 'experts'}/refresh-access-token`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh_token }),
      },
    );

    if (!response.ok) return null;

    const { access_token, finalPayload }: IAPIRefreshAccessTokenResponse =
      await response.json();

    authData = { ...authData, access_token, ...finalPayload };

    if ('exp' in authData) {
      authData.exp = authData.exp - authData.iat + Date.now() / 1000;
    }

    localStorage.setItem('auth', JSON.stringify(authData));

    return authData as AuthenticationDataType;
  },
);
