import type { ChangeEvent, KeyboardEvent } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Currency } from 'types/global.types';
import type { AvailableLanguages } from 'types/users.types';
import formatMoney, {
  convertUserInputMoneyToNumber,
  fixCommaDotInUserInput,
  hasNotNumChars,
  intlFormat,
} from 'utils/formatMoney';

export type FormatOptions = {
  asMoney?: boolean;
  currency?: Currency;
  lang?: AvailableLanguages;
  prefix?: string;
  postfix?: string;
};

export type Value = number | string | null | undefined;

export type NumberFieldProps = {
  value: Value;
  onChange?: (value: number | null) => void;
  format?: FormatOptions;
  immedialtyCommitOnChange?: boolean;
};

const getDisplayStr = (
  value: Value,
  options: FormatOptions | undefined,
): string => {
  if (typeof value !== 'number') return '';
  const maybeMoneyFormat =
    options?.asMoney || options?.currency
      ? formatMoney(value, options.currency, options.lang)
      : intlFormat({
          amount: value,
          lang: options?.lang,
        });
  return `${options?.postfix || ''}${maybeMoneyFormat}${options?.prefix || ''}`;
};

export const getBlurNumber = (value: Value): number | null => {
  if (typeof value !== 'string') return NaN;

  value = value.trim();

  if (value.length === 0) return null;

  const result = convertUserInputMoneyToNumber(value);
  return result;
};

export const getFocusStr = (
  value: Value,
  lang?: AvailableLanguages,
): string => {
  if (typeof value !== 'number' || value === 0) return '';
  return intlFormat({
    amount: value,
    lang,
    useGrouping: false,
  });
};

export const numberOrNull = (value: any) => {
  if (typeof value === 'number' && !Number.isNaN(value)) return value;
  return null;
};

type KeyDownEvent =
  | KeyboardEvent<HTMLTextAreaElement>
  | KeyboardEvent<HTMLInputElement>;
type InputChangeEvent =
  | ChangeEvent<HTMLInputElement>
  | ChangeEvent<HTMLTextAreaElement>;

const useNumberField = (options: NumberFieldProps) => {
  const appLang = useTranslation().i18n.language as AvailableLanguages;
  const language = options.format?.lang || appLang;
  const [isBlured, setIsBlured] = useState(true);
  const [inner, setInner] = useState('');

  const formatRef = useRef(options.format);
  formatRef.current = options.format;

  const commitFromString = () => {
    const _value = numberOrNull(getBlurNumber(inner));
    options.onChange?.(_value);
    if (_value === null) setInner('');
  };

  return {
    autoComplete: 'off',
    type: 'text',
    value: isBlured ? getDisplayStr(options.value, formatRef.current) : inner,
    onFocus: () => {
      setInner(getFocusStr(options.value, language));
      setIsBlured(false);
    },
    onKeyDown: (e: KeyDownEvent) => {
      if (e.key === 'Enter') {
        e.currentTarget.blur();
      }
    },
    onChange: (e: InputChangeEvent) => {
      const _value = e.target.value.trim();
      if (!_value) {
        setInner('');
        if (options.immedialtyCommitOnChange) options.onChange?.(null);
        return;
      }

      if (hasNotNumChars(_value)) return;
      const adapted = fixCommaDotInUserInput(_value, language);
      setInner(adapted);

      if (options.immedialtyCommitOnChange) {
        const maybeNumber = numberOrNull(getBlurNumber(adapted));
        if (maybeNumber == null) return;
        options.onChange?.(maybeNumber);
      }
    },
    onBlur: () => {
      if (!options.immedialtyCommitOnChange) commitFromString();
      setInner('');
      setIsBlured(true);
    },
  };
};

export default useNumberField;
