import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Button } from 'components/atoms/Button';
import { Helper } from 'components/molecules/Helper';
import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useProfitMeta from 'hooks/Taxes/useProfitMeta';
import useTaxHeaderMeta from 'hooks/Taxes/useTaxHeaderMeta';
import { round } from 'lodash';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import type { IExpense } from 'types/expenses.types';
import type { IRevenue } from 'types/revenues.types';
import type { TaxImpactItem } from 'types/taxes.types';
import { TaxImpactDocumentType } from 'types/taxes.types';
import type { AccountType } from 'types/users.types';
import formatMoney, { formatEurosNoDecimals } from 'utils/formatMoney';
import isOnePersonCompany from 'utils/isOnePersonCompany';
import { divideBy1000 } from 'utils/math';
import { getIncomeTaxHeaderValue } from './helpers';

const currentYear = dayjs().year();
const trigger = (open: (e: any) => void) => (
  <Button
    structure="text"
    onClick={(e) => {
      open(e);
    }}
  >
    <QuestionMarkCircleIcon className="!h-5 !w-5 cursor-pointer text-primary-300 hover:text-primary-400 focus:text-primary-400" />
  </Button>
);

export const TaxImpactExpenseHelper: FC<{
  details: TaxImpactItem;
  year: number;
}> = ({ details, year }) => {
  const { t } = useTranslation();
  const { data: profitMeta } = useProfitMeta();
  const { data: taxHeaderMeta } = useTaxHeaderMeta(year);
  const { data: user } = useGetCurrentCustomerData();
  const profit = formatEurosNoDecimals(taxHeaderMeta?.taxableIncome || 0);
  const projectedProfit = formatEurosNoDecimals(
    taxHeaderMeta?.projectedTaxableIncome || 0,
  );
  const taxRate = round(taxHeaderMeta?.projectedTaxRate || 0, 2);
  const currentTaxRate = round(taxHeaderMeta?.currentTaxRate || 0, 2);
  const profitProjectionMethod = user?.settings?.profit?.option;

  const manualProfit = formatEurosNoDecimals(profitMeta?.manualEstimation || 0);
  const taxSaving = getIncomeTaxHeaderValue(
    TaxImpactDocumentType.EXPENSE,
    details?.incomeTax,
  );
  const deductibleAmount = formatMoney(details?.incomeTax?.deductibleAmount);
  const isCompany = isOnePersonCompany(user?.accountType as AccountType);
  const amount = formatMoney(details?.incomeTax?.amount);

  const flatFeeContent = (
    <Helper trigger={trigger}>
      <Helper.Title>
        {t('tax_impact.action_sheet.income_tax.flat_fee.title_1')}
      </Helper.Title>
      {t('tax_impact.action_sheet.income_tax.flat_fee.info_1', { year })
        .split('\n')
        .map((paragraph) => (
          <Helper.Paragraph className="py-1" key={paragraph}>
            {paragraph}
          </Helper.Paragraph>
        ))}
    </Helper>
  );

  const pastYearContent = (
    <Helper trigger={trigger}>
      <Helper.Title>{t('expenses.tax_impact.helper.title')}</Helper.Title>
      {t(
        `expenses.tax_impact.past.${
          isCompany ? 'corporate' : 'income'
        }_tax_saving.desc`,
        {
          taxSaving: formatMoney(taxSaving || 0),
        },
      )
        .split('\n')
        .map((paragraph) => (
          <Helper.Paragraph className="py-1" key={paragraph}>
            {paragraph}
          </Helper.Paragraph>
        ))}
      <Helper.Title>
        {t('cash_available.income_tax.helper.how_computed')}
      </Helper.Title>
      {t('expenses.tax_impact.past.how_it_is_computed.desc', {
        taxSaving: formatMoney(taxSaving),
        profit,
        year,
        taxRate: currentTaxRate,
        deductibleAmount: amount,
      })
        .split('\n')
        .map((paragraph) => (
          <Helper.Paragraph className="py-1" key={paragraph}>
            {paragraph}
          </Helper.Paragraph>
        ))}
    </Helper>
  );

  const sharedContent = (
    <>
      <Helper.Title>{t('expenses.tax_impact.helper.title')}</Helper.Title>

      {t(
        `expenses.tax_impact.helper.description${
          isCompany ? '.corporate' : ''
        }`,
        {
          taxSaving: formatMoney(taxSaving || 0),
        },
      )
        .split('\n')
        .map((paragraph) => (
          <Helper.Paragraph className="py-1" key={paragraph}>
            {paragraph}
          </Helper.Paragraph>
        ))}
      <Helper.Title>
        {t('cash_available.income_tax.helper.how_computed')}
      </Helper.Title>
      {t(`expenses.tax_impact.helper.description.${profitProjectionMethod}`, {
        taxSaving: formatMoney(taxSaving || 0),
        taxRate,
        year,
        manualProfit,
        projectedProfit,
        profit,
        deductibleAmount,
      })
        .split('\n')
        .map((paragraph) => (
          <Helper.Paragraph className="py-1" key={paragraph}>
            <ReactMarkdown>{paragraph}</ReactMarkdown>
          </Helper.Paragraph>
        ))}
    </>
  );

  const profitProjectionMap = {
    persona: (
      <Helper trigger={trigger}>
        {sharedContent}
        {t(
          (taxHeaderMeta?.currentYearDaysBetweenFirstLastRevenue as any) < 90
            ? 'expenses.tax_impact.helper.description.persona.less_than_90'
            : 'expenses.tax_impact.helper.description.persona.more_than_90',
          {
            year,
            profit: projectedProfit,
            taxRate,
            personaProfit: formatEurosNoDecimals(
              profitMeta?.personaTaxableIncome || 0,
            ),
            taxSaving: formatMoney(taxSaving || 0),
          },
        )
          .split('\n')
          .map((paragraph) => (
            <Helper.Paragraph className="py-1" key={paragraph}>
              <ReactMarkdown>{paragraph}</ReactMarkdown>
            </Helper.Paragraph>
          ))}
      </Helper>
    ),
    extrapolated: (
      <Helper trigger={trigger}>
        {sharedContent}
        {t(
          (taxSaving as number) <= 0
            ? 'expenses.tax_impact.helper.description.extrapolated.less_than_zero'
            : 'expenses.tax_impact.helper.description.extrapolated.more_than_zero',
          {
            year,
            profit,
            taxRate,
            projectedProfit,
            taxSaving: formatMoney(taxSaving || 0),
          },
        )
          .split('\n')
          .map((paragraph) => (
            <Helper.Paragraph className="py-1" key={paragraph}>
              <ReactMarkdown>{paragraph}</ReactMarkdown>
            </Helper.Paragraph>
          ))}
      </Helper>
    ),
    manual: <Helper trigger={trigger}>{sharedContent}</Helper>,
    previous_year: <Helper trigger={trigger}>{sharedContent}</Helper>,
    default: null,
  };
  if (details?.incomeTax?.flatFeeUsed) return flatFeeContent;
  if (year !== currentYear) return pastYearContent;
  return profitProjectionMap[user?.settings?.profit?.option || 'default'];
};

export const TaxImpactRevenueHelper: FC<{
  details: TaxImpactItem;
  year: number;
  documentData: IRevenue;
  includeCopyrightInfo?: boolean;
}> = ({ details, year, documentData, includeCopyrightInfo }) => {
  const { t } = useTranslation();
  const { data: profitMeta } = useProfitMeta();
  const { data: taxHeaderMeta } = useTaxHeaderMeta(year);
  const { data: user } = useGetCurrentCustomerData();
  const amount = formatMoney(
    (documentData.items || []).reduce((acc: number, item) => {
      const sum =
        divideBy1000(item.unitAmountExclVAT) * divideBy1000(item.quantity);
      return acc + (sum - sum * divideBy1000(item.discountPercentage));
    }, 0),
  );
  const profit = formatEurosNoDecimals(taxHeaderMeta?.taxableIncome || 0);
  const projectedProfit = formatEurosNoDecimals(
    taxHeaderMeta?.projectedTaxableIncome || 0,
  );
  const taxRate = round(details?.incomeTax?.taxRate * 100 || 0, 2);
  const projectedTaxRate = round(taxHeaderMeta?.projectedTaxRate || 0, 2);
  const currentTaxRate = round(taxHeaderMeta?.currentTaxRate || 0, 2);
  // const profitProjectionMethod = user?.settings?.profit?.option;

  const manualProfit = formatEurosNoDecimals(profitMeta?.manualEstimation || 0);

  const taxImpact = getIncomeTaxHeaderValue(
    TaxImpactDocumentType.REVENUE,
    details?.incomeTax,
  );
  const taxSaving = formatMoney(taxImpact || 0);
  const deductibleAmount = formatMoney(details?.incomeTax?.deductibleAmount);
  const isCompany = isOnePersonCompany(user?.accountType as AccountType);

  const vars = {
    year,
    profit,
    taxRate,
    amount,
    projectedProfit,
    personaProfit: projectedProfit,
    taxImpact: taxSaving,
    taxSaving,
    deductibleAmount,
    projectedTaxRate,
    amountExclVAT: amount,
    manualProfit,
  };

  const pastYearContent = (
    <Helper trigger={trigger}>
      <Helper.Title>
        {t(`revenues.tax_impact.helper.title${isCompany ? '.corporate' : ''}`)}
      </Helper.Title>
      {t(
        `revenues.tax_impact.past.${
          isCompany ? 'corporate' : 'income'
        }_tax_impact.desc`,
        vars,
      )
        .split('\n')
        .map((paragraph) => (
          <Helper.Paragraph className="py-1" key={paragraph}>
            {paragraph}
          </Helper.Paragraph>
        ))}
      <Helper.Title>
        {t('cash_available.income_tax.helper.how_computed')}
      </Helper.Title>
      {t('revenues.tax_impact.past.how_it_is_computed.desc', {
        ...vars,
        taxRate: currentTaxRate,
      })
        .split('\n')
        .map((paragraph) => (
          <Helper.Paragraph className="py-1" key={paragraph}>
            {paragraph}
          </Helper.Paragraph>
        ))}
    </Helper>
  );

  const sharedContent = (
    <>
      <Helper.Title>
        {t(`revenues.tax_impact.helper.title${isCompany ? '.corporate' : ''}`)}
      </Helper.Title>

      {t(
        `revenues.tax_impact.helper.description${
          isCompany ? '.corporate' : ''
        }`,
        vars,
      )
        .split('\n')
        .map((paragraph) => (
          <Helper.Paragraph className="py-1" key={paragraph}>
            {paragraph}
          </Helper.Paragraph>
        ))}
      <Helper.Title>
        {t('cash_available.income_tax.helper.how_computed')}
      </Helper.Title>
    </>
  );
  const flatFeeContent = (
    <Helper trigger={trigger}>
      <Helper.Title>
        {t('tax_impact.action_sheet.income_tax.flat_fee.title_1')}
      </Helper.Title>
      {t('tax_impact.action_sheet.income_tax.flat_fee.info_1', vars)
        .split('\n')
        .map((paragraph) => (
          <Helper.Paragraph className="py-1" key={paragraph}>
            {paragraph}
          </Helper.Paragraph>
        ))}
    </Helper>
  );

  const profitProjectionMap = {
    persona: (
      <Helper trigger={trigger}>
        {sharedContent}
        <Helper.Paragraph className="py-1">
          {t(
            `revenues.tax_impact.helper.description${
              isCompany ? '.corporate' : ''
            }.persona`,
            vars,
          )
            .split('\n')
            .map((paragraph) => (
              <Helper.Paragraph className="py-1" key={paragraph}>
                <ReactMarkdown>{paragraph}</ReactMarkdown>
              </Helper.Paragraph>
            ))}
        </Helper.Paragraph>

        {(taxHeaderMeta?.currentYearDaysBetweenFirstLastRevenue as any) < 90 ? (
          isCompany ? (
            t(
              `revenues.tax_impact.helper.description.corporate.persona.less_than_90`,
              vars,
            )
              .split('\n')
              .map((paragraph) => (
                <Helper.Paragraph className="py-1" key={paragraph}>
                  <ReactMarkdown>{paragraph}</ReactMarkdown>
                </Helper.Paragraph>
              ))
          ) : (
            <>
              <Helper.Paragraph className="py-1">
                {t(
                  `revenues.tax_impact.helper.description.persona.less_than_90.text1${
                    includeCopyrightInfo ? '.with_copy_right' : ''
                  }`,
                  vars,
                )}
              </Helper.Paragraph>
              <Helper.Paragraph className="py-1">
                {t(
                  `revenues.tax_impact.helper.description.persona.less_than_90.text2`,
                  vars,
                )}
              </Helper.Paragraph>
            </>
          )
        ) : (
          t(
            `revenues.tax_impact.helper.description${
              isCompany ? '.corporate' : ''
            }.persona.more_than_90`,
            vars,
          )
            .split('\n')
            .map((paragraph) => (
              <Helper.Paragraph className="py-1" key={paragraph}>
                <ReactMarkdown>{paragraph}</ReactMarkdown>
              </Helper.Paragraph>
            ))
        )}
      </Helper>
    ),
    extrapolated: (
      <Helper trigger={trigger}>
        {sharedContent}
        <Helper.Paragraph className="py-1">
          {t(
            `revenues.tax_impact.helper.description${
              isCompany ? '.corporate' : ''
            }.extrapolated`,
            vars,
          )
            .split('\n')
            .map((paragraph) => (
              <Helper.Paragraph className="py-1" key={paragraph}>
                <ReactMarkdown>{paragraph}</ReactMarkdown>
              </Helper.Paragraph>
            ))}
        </Helper.Paragraph>

        {(() => {
          if (taxImpact <= 0) {
            return t(
              `revenues.tax_impact.helper.description${
                isCompany ? '.corporate' : ''
              }.extrapolated.less_than_zero`,
              vars,
            )
              .split('\n')
              .map((paragraph) => (
                <Helper.Paragraph className="py-1" key={paragraph}>
                  <ReactMarkdown>{paragraph}</ReactMarkdown>
                </Helper.Paragraph>
              ));
          }
          if (isCompany) {
            return t(
              `revenues.tax_impact.helper.description.corporate.extrapolated.more_than_zero`,
              vars,
            )
              .split('\n')
              .map((paragraph) => (
                <Helper.Paragraph className="py-1" key={paragraph}>
                  <ReactMarkdown>{paragraph}</ReactMarkdown>
                </Helper.Paragraph>
              ));
          }
          return (
            <>
              <Helper.Paragraph className="py-1">
                {t(
                  `revenues.tax_impact.helper.description.extrapolated.more_than_zero.text1`,
                  vars,
                )}
              </Helper.Paragraph>
              <Helper.Paragraph className="py-1">
                {t(
                  `revenues.tax_impact.helper.description.extrapolated.more_than_zero.text2${
                    includeCopyrightInfo ? '.with_copy_right' : ''
                  }`,
                  vars,
                )}
              </Helper.Paragraph>
              <Helper.Paragraph className="py-1">
                {t(
                  `revenues.tax_impact.helper.description.extrapolated.more_than_zero.text3`,
                  vars,
                )}
              </Helper.Paragraph>
            </>
          );
        })()}
      </Helper>
    ),
    manual: (
      <Helper trigger={trigger}>
        {sharedContent}
        {(() => {
          if (isCompany) {
            return t(
              `revenues.tax_impact.helper.description.corporate.manual`,
              vars,
            )
              .split('\n')
              .map((paragraph) => (
                <Helper.Paragraph className="py-1" key={paragraph}>
                  <ReactMarkdown>{paragraph}</ReactMarkdown>
                </Helper.Paragraph>
              ));
          }
          return (
            <>
              <Helper.Paragraph className="py-1">
                {t(`revenues.tax_impact.helper.description.manual.text1`, vars)}
              </Helper.Paragraph>
              <Helper.Paragraph className="py-1">
                {t(`revenues.tax_impact.helper.description.manual.text2`, vars)}
              </Helper.Paragraph>
              <Helper.Paragraph className="py-1">
                {t(
                  `revenues.tax_impact.helper.description.manual.text3${
                    includeCopyrightInfo ? '.with_copy_right' : ''
                  }`,
                  vars,
                )}
              </Helper.Paragraph>
              <Helper.Paragraph className="py-1">
                {t(`revenues.tax_impact.helper.description.manual.text4`, vars)}
              </Helper.Paragraph>
            </>
          );
        })()}
      </Helper>
    ),
    previous_year: (
      <Helper trigger={trigger}>
        {sharedContent}
        {(() => {
          if (isCompany) {
            return t(
              `revenues.tax_impact.helper.description.corporate.previous_year`,
              vars,
            )
              .split('\n')
              .map((paragraph) => (
                <Helper.Paragraph className="py-1" key={paragraph}>
                  <ReactMarkdown>{paragraph}</ReactMarkdown>
                </Helper.Paragraph>
              ));
          }
          return (
            <>
              <Helper.Paragraph className="py-1">
                {t(
                  `revenues.tax_impact.helper.description.previous_year.text1`,
                  vars,
                )}
              </Helper.Paragraph>
              <Helper.Paragraph className="py-1">
                {t(
                  `revenues.tax_impact.helper.description.previous_year.text2${
                    includeCopyrightInfo ? '.with_copy_right' : ''
                  }`,
                  vars,
                )}
              </Helper.Paragraph>
              <Helper.Paragraph className="py-1">
                {t(
                  `revenues.tax_impact.helper.description.previous_year.text3`,
                  vars,
                )}
              </Helper.Paragraph>
            </>
          );
        })()}
      </Helper>
    ),
    default: null,
  };
  if (details?.incomeTax?.flatFeeUsed) return flatFeeContent;
  if (year !== currentYear) return pastYearContent;
  return profitProjectionMap[user?.settings?.profit?.option || 'default'];
};

const TaxImpactHelper: FC<{
  documentType: TaxImpactDocumentType;
  details: TaxImpactItem;
  year: number;
  documentData: IExpense | IRevenue;
}> = ({ documentType, details, year, documentData }) => {
  if (documentType === TaxImpactDocumentType.EXPENSE)
    return <TaxImpactExpenseHelper details={details} year={year} />;
  return (
    <TaxImpactRevenueHelper
      details={details}
      year={year}
      documentData={documentData as IRevenue}
    />
  );
};

export default TaxImpactHelper;
