import dayjs from 'dayjs';
import type { Dictionary } from 'lodash';
import { get, groupBy, keyBy, uniqBy } from 'lodash';
import type { Location } from 'react-router';
import type { IExpense } from 'types/expenses.types';
import type {
  FilterV2,
  LockStatusType,
  LockStatusTypeCatgoriesed,
  OptionType,
  QP,
} from 'types/global.types';
import type { IncomeTaxDE } from 'types/incomeTax.types';
import { InvoiceTypeEnum, type Invoice } from 'types/invoice.types';
import type { ITax, TaxesByYear } from 'types/taxes.types';
import { CivilStatusEnum, TaxCheckThemeEnum } from 'types/taxes.types';
import type { IUser } from 'types/users.types';
import { isExpert, isWeb } from './constants';

const chiftACL = [
  'rca@mycatalyst.eu',
  'Mike@fiskcouncil.be',
  'giraud@efficiens.be',
  'david@my-cfo.be',
  'Wannes@2ug.be',
  'charlene@kantoordewulf.be',
  'christophe@meesters.be',
  'koen@makofisc.be',
  'demaconsultancy@outlook.com',
  'info@kantoordewulf.be',
  'itservices@bakertilly.be',
  'wannes@2ug.be',
];

const shouldSeeChiftIntegrations = (email: string) =>
  isExpert &&
  (email.includes('@accountable.eu') ||
    email.includes('@mycatalist.eu') ||
    email.includes('@fiskcouncil.be') ||
    chiftACL.includes(email));

const appendSearchParamsToURL = (
  location: Location,
  params: Record<string, any>,
): string => {
  const { pathname, search } = location;
  const url = new URL(window.location.origin + pathname + search);

  Object.entries(params).forEach(([key, value]: [key: string, value: any]) => {
    url.searchParams.set(key, value);
  });

  return url.pathname + url.search;
};

const parseJwtToken = (token: string) => {
  const base64Url = token.split('.')[1];

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const hasCurelyBracesText = (str: string): boolean => /{(.*?)}/.test(str);

const removeSoftHyphens = (text: string): string =>
  text.replace(/[\u00AD\u002D\u2011]+/g, '');

const getLocationOrigin = (updateToHttps = false): string =>
  updateToHttps
    ? location.origin.replace(/^http:\/\//i, 'https://')
    : location.origin;

const caseInsensitiveEqual = (
  a: string | undefined,
  b: string | undefined,
): boolean => a?.toLowerCase() === b?.toLowerCase();

const showPeriodAmount = (returnFrequency: string): boolean =>
  returnFrequency !== 'yearly';

const excludeNumbers = (str?: string): string =>
  str?.replace(/[0-9]/g, '') || '';

export const sanitizeSteuernummer = (steuernummer: string) =>
  (steuernummer || '')
    .replace(/\s/g, '')
    .replace(/[^a-zA-Z0-9]/g, '')
    .trim();

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const getCustomerIdFromPath = (pathname?: string): string | null => {
  const customerId = (pathname || window.location.pathname)?.split('/')?.[2];

  return customerId?.match(/^[0-9a-fA-F]{24}$/) ? customerId : null;
};

const copy = async (text: string) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};

const eraseWordsFromString = (text: string, words: string[]): string => {
  let _text = text;

  words.forEach((word: string) => {
    _text = _text.replace(word, '');
  });

  return _text;
};

const getShortAccountType = (accountType: string) =>
  eraseWordsFromString(accountType as string, [
    '_no_vat',
    '_vat',
    '_principal',
    '_student',
    '_complementary',
  ]);

const getTurnoverStatus = (tax: ITax) => {
  if ((tax?.currentTurnOver as number) > (tax.threshold as number)) {
    return TaxCheckThemeEnum.error;
  }
  if (tax?.projectedTurnOver > tax.threshold) {
    return TaxCheckThemeEnum.warning;
  }
  return TaxCheckThemeEnum.success;
};

const getSelectOptionValue = (
  options: OptionType[],
  value: string | number,
): OptionType | undefined =>
  options?.find(({ value: _value }: OptionType) => _value === value);

export const isJointDeclarationEligible = (
  tax: IncomeTaxDE,
  status: CivilStatusEnum,
  civilStatusDate?: Date | null,
): boolean =>
  [CivilStatusEnum.civilPartnership, CivilStatusEnum.separated].includes(
    status,
  ) ||
  (CivilStatusEnum.divorced === status &&
    civilStatusDate &&
    dayjs(civilStatusDate) > dayjs('01/01/2021')) ||
  (CivilStatusEnum.married === status &&
    dayjs(civilStatusDate).year() < tax?.period?.year + 1);

const cleanEmptyValues_stringified = (value: any, keep: string[] = []) => {
  return JSON.stringify(value, (k, v) =>
    v == null && !keep.includes(k) ? undefined : v,
  );
};

const cleanEmptyValues = (value: any, keep: string[] = []) => {
  try {
    return JSON.parse(cleanEmptyValues_stringified(value, keep));
  } catch (e) {
    return value;
  }
};

const createSentryCustomErrorMsg = (msg: string) => `CUSTOM:${msg}`;

const openInNewTab = (href: string) => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.href = href;
  a.click();
};

type Options = {
  lowerCaseKeys?: boolean;
};
const resolveImgsContext = (
  imgsContext: __WebpackModuleApi.RequireContext,
  options?: Options,
) => {
  return imgsContext
    .keys()
    .reduce((acc: Dictionary<string>, imgShortPath: string) => {
      const imgName = imgShortPath.replace(/.\/|.png|.svg|.jpg|.jpeg/g, '');
      acc[options?.lowerCaseKeys ? imgName.toLowerCase() : imgName] =
        imgsContext(imgShortPath);
      return acc;
    }, {});
};

const appendQP = (qs: string, qp?: QP) => {
  const ins = new URLSearchParams(qs);
  if (!qp) return ins.toString();
  Object.entries(qp).forEach(([key, val]) => {
    if (val == null) return;
    if (ins.has(key)) ins.delete(key);
    ins.append(key, val + '');
  });
  return ins.toString();
};

const isTester = (user?: IUser) => !!user?.email.includes('@accountable.eu');

const guessIsUserBE = () => {
  try {
    return (
      new Intl.DateTimeFormat().resolvedOptions().timeZone === 'Europe/Brussels'
    );
  } catch (e) {
    return false;
  }
};

export const getIsPastDueDate = (
  taxesByYear: TaxesByYear,
  tab: string,
): boolean =>
  get(taxesByYear, `additional.dueItems.${tab}`)?.some(
    (dueItem: any) =>
      dayjs(get(dueItem, 'dueDate')).diff(dayjs(), 'days') + 1 <= 0,
  );

export const sanitizeIBAN = (iban: string): string =>
  iban
    ?.replace(/\s/g, '')
    ?.replace(/[^a-zA-Z0-9]/g, '')
    ?.trim();

const mergeFilters = (
  filters: FilterV2[] | undefined,
  newFilters: FilterV2[],
): FilterV2[] => {
  if (!filters || !filters.length) return newFilters;
  const newFiltersMap = keyBy(newFilters, 'key');
  return uniqBy(filters.concat(newFilters), 'key').map((f) => {
    if (!newFiltersMap[f.key]) return f;
    return {
      ...f,
      options: uniqBy(f.options.concat(newFiltersMap[f.key].options), 'value'),
    };
  });
};

const formatLockStatus = (
  taxLock?: LockStatusType,
): LockStatusTypeCatgoriesed => {
  if (!taxLock || !taxLock.length) return null;
  return groupBy(taxLock, 'field') as LockStatusTypeCatgoriesed;
};

const isDocumentLockedByTaxes = (
  document: Partial<Invoice> | Partial<IExpense>,
) => {
  return !!document.taxLock?.length;
};

const isDocumentLockedByAccountant = (
  document: Partial<Invoice> | Partial<IExpense>,
) => {
  const isLockedByAccountant =
    isWeb && document.accountantReview?.reviewStatus === 'reviewed';
  return isLockedByAccountant;
};

const isDocumentLockedByTaxesOrAccountant = (
  document: Partial<Invoice> | Partial<IExpense>,
) => {
  const isLockedByAccountant = isDocumentLockedByAccountant(document);
  const isLockedByTaxes = isDocumentLockedByTaxes(document);
  return isLockedByAccountant || isLockedByTaxes;
};
const isEndOrBeginingOfAYear =
  dayjs().month() === 11 || (dayjs().month() === 0 && dayjs().date() <= 31);

const getExpenseReferSlugs = (data: {
  isCreditNote?: boolean;
  country: string;
}) => {
  const { isCreditNote, country } = data;

  return {
    thisType: isCreditNote ? 'credit_note.' + country : 'expense',
    thisTypeAndOtherType:
      (isCreditNote ? 'credit_note_expense.' : 'expense_credit_note.') +
      country,
  };
};

const getRevenuesReferSlugs = (data: { type: string; country: string }) => {
  const { type, country } = data;

  const isCreditNote = type === InvoiceTypeEnum.CREDIT_NOTE;
  const isTicket = type === InvoiceTypeEnum.OTHER_REVENUE;

  return {
    thisType: isCreditNote
      ? 'credit_note.' + country
      : isTicket
      ? 'ticket'
      : 'invoice',
    thisTypeAndOtherType: isCreditNote
      ? 'credit_note_invoice.' + country
      : isTicket
      ? 'ticket_credit_note.' + country
      : 'invoice_credit_note.' + country,
  };
};

const getPromiseHandles = () => {
  let resolve = (value?: unknown) => {};
  let reject = (value?: unknown) => {};

  const promise = new Promise((res, rej) => {
    resolve = res as any;
    reject = rej as any;
  });

  return { promise, resolve, reject };
};

export {
  getLocationOrigin,
  caseInsensitiveEqual,
  showPeriodAmount,
  emailRegex,
  getCustomerIdFromPath,
  getSelectOptionValue,
  cleanEmptyValues,
  createSentryCustomErrorMsg,
  openInNewTab,
  resolveImgsContext,
  appendQP,
  copy,
  isTester,
  eraseWordsFromString,
  getTurnoverStatus,
  excludeNumbers,
  guessIsUserBE,
  appendSearchParamsToURL,
  removeSoftHyphens,
  mergeFilters,
  hasCurelyBracesText,
  formatLockStatus,
  getShortAccountType,
  isDocumentLockedByTaxesOrAccountant,
  parseJwtToken,
  isEndOrBeginingOfAYear,
  getExpenseReferSlugs,
  getRevenuesReferSlugs,
  isDocumentLockedByTaxes,
  isDocumentLockedByAccountant,
  cleanEmptyValues_stringified,
  getPromiseHandles,
  shouldSeeChiftIntegrations,
};
