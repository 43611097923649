// Personal Details Settings
export const WEBAPP_PERSONALDETAILSSETTINGS_OPENEDPICTUREUPLOADER =
  'WEBAPP_PERSONALDETAILSSETTINGS_OPENEDPICTUREUPLOADER';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDFIRSTNAMEFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDFIRSTNAMEFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDLASTNAMEFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDLASTNAMEFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDEMAILFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDEMAILFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDPHONENUMBERFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDPHONENUMBERFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDPROFESSIONFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDPROFESSIONFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDNATIONALREGISTRYNUMFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDNATIONALREGISTRYNUMFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDCOMPANYNAMEFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDCOMPANYNAMEFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDVATNUMBERFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDVATNUMBERFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDSTEUERNUMMERFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDSTEUERNUMMERFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDIBANFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDIBANFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDBICFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDBICFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDSTREETFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDSTREETFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDCITYFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDCITYFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDPOSTALCODEFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDPOSTALCODEFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENSTARTACTIVITYCALENDAR =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENSTARTACTIVITYCALENDAR';
export const WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDSTARTOFACTIVITYDATE =
  'WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDSTARTOFACTIVITYDATE';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENACTIVITYTYPEMENU =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENACTIVITYTYPEMENU';
export const WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDACTIVITYTYPE =
  'WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDACTIVITYTYPE';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENCOMPLEMENTARYDAYSMENU =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENCOMPLEMENTARYDAYSMENU';
export const WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDCOMPLEMETARYDAYS =
  'WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDCOMPLEMETARYDAYS';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENSTATESMENU =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENSTATESMENU';
export const WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDSTATE =
  'WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDSTATE';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENFIRSTCOMPUTATIONYEARMENU =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENFIRSTCOMPUTATIONYEARMENU';
export const WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDFIRSTCOMPUTATIONYEAR =
  'WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDFIRSTCOMPUTATIONYEAR';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDEMAILALIASFIELD =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDEMAILALIASFIELD';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDSAVE =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDSAVE';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENDELETEACCOUNTMODAL =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENDELETEACCOUNTMODAL';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDCLOSEDELETEACCOUNTMODAL =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDCLOSEDELETEACCOUNTMODAL';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDDELETEACCOUNT =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDDELETEACCOUNT';
export const WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENLANGUAGESMENU =
  'WEBAPP_PERSONALDETAILSSETTINGS_CLICKEDOPENLANGUAGESMENU';
export const WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDLANGUAGE =
  'WEBAPP_PERSONALDETAILSSETTINGS_UPDATEDLANGUAGE';
export const WEBAPP_PERSONALDETAILSSETTINGS_UPDATEAICONSENT =
  'WEBAPP_PERSONALDETAILSSETTINGS_UPDATEAICONSENT';

// Change Password Settings
export const WEBAPP_CHANGEPASSWORDSETTINGS_CLICKEDOLDPASSWORDFIELD =
  'WEBAPP_CHANGEPASSWORDSETTINGS_CLICKEDOLDPASSWORDFIELD';
export const WEBAPP_CHANGEPASSWORDSETTINGS_CLICKEDNEWPASSWORDFIELD =
  'WEBAPP_CHANGEPASSWORDSETTINGS_CLICKEDNEWPASSWORDFIELD';
export const WEBAPP_SETPASSWORDSETTINGS_CLICKEDNEWPASSWORDFIELD =
  'WEBAPP_SETPASSWORDSETTINGS_CLICKEDNEWPASSWORDFIELD';
export const WEBAPP_CHANGEPASSWORDSETTINGS_CLICKEDCONFIRMPASSWORDFIELD =
  'WEBAPP_CHANGEPASSWORDSETTINGS_CLICKEDCONFIRMPASSWORDFIELD';
export const WEBAPP_SETPASSWORDSETTINGS_CLICKEDCONFIRMPASSWORDFIELD =
  'WEBAPP_SETPASSWORDSETTINGS_CLICKEDCONFIRMPASSWORDFIELD';
export const WEBAPP_CHANGEPASSWORDSETTINGS_CLICKEDCHANGE =
  'WEBAPP_CHANGEPASSWORDSETTINGS_CLICKEDCHANGE';
export const WEBAPP_SETPASSWORDSETTINGS_CLICKEDSETUP =
  'WEBAPP_SETPASSWORDSETTINGS_CLICKEDSETUP';

export const accountTypeEvents = {
  gewerbetreibender: 'WEB_TAX_SETTINGS_ACCOUNTTYPETOTRADETAX',
  freiberufler: 'WEB_TAX_SETTINGS_ACCOUNTTYPETOFREELANCE',
  one_person_company: 'WEB_TAX_SETTINGS_ACCOUNTTYPETOONEPERSONCOMPANY',
  natural_person: 'WEB_TAX_SETTINGS_ACCOUNTTYPETONATURALPERSON',
};

// Tax Settings
export const WEBAPP_TAXSETTINGS_CLICKEDOPENVATTYPEMENU =
  'WEBAPP_TAXSETTINGS_CLICKEDOPENVATTYPEMENU';
export const WEBAPP_TAXSETTINGS_UPDATEDVATTYPE =
  'WEBAPP_TAXSETTINGS_UPDATEDVATTYPE';
export const WEBAPP_TAXSETTINGS_CLICKEDOPENVATFREQUENCYMENU =
  'WEBAPP_TAXSETTINGS_CLICKEDOPENVATFREQUENCYMENU';
export const WEBAPP_TAXSETTINGS_UPDATEDVATFREQUENCY =
  'WEBAPP_TAXSETTINGS_UPDATEDVATFREQUENCY';
export const WEBAPP_TAXSETTINGS_CANCELLEDVATFREQUENCYMODAL =
  'WEBAPP_TAXSETTINGS_CANCELLEDVATFREQUENCYMODAL';
export const WEBAPP_TAXSETTINGS_TOGGLEDTRADETAXYEARACCORDION =
  'WEBAPP_TAXSETTINGS_TOGGLEDTRADETAXYEARACCORDION';
export const WEBAPP_TAXSETTINGS_UPDATEDSUBJECTTOTRADETAX =
  'WEBAPP_TAXSETTINGS_UPDATEDSUBJECTTOTRADETAX';
export const WEBAPP_TAXSETTINGS_CLICKEDTRADETAXPREPAYMENTFIELD =
  'WEBAPP_TAXSETTINGS_CLICKEDTRADETAXPREPAYMENTFIELD';
export const WEBAPP_TAXSETTINGS_CLICKEDOPENTRADETAXHELP =
  'WEBAPP_TAXSETTINGS_CLICKEDOPENTRADETAXHELP';
export const WEBAPP_TAXSETTINGS_CLICKEDSAVE = 'WEBAPP_TAXSETTINGS_CLICKEDSAVE';
export const WEBAPP_TAXSETTINGS_CHANGEDCORPORATETAXRATE =
  'WEBAPP_TAXSETTINGS_CHANGEDCORPORATETAXRATE';
export const WEBAPP_TAXSETTINGS_CHANGEDPROFITPROJECTIONMETHOD =
  'WEBAPP_TAXSETTINGS_CHANGEDPROFITPROJECTIONMETHOD';
export const WEBAPP_TAXSETTINGS_CHANGEDPROJECTEDINCOMETAXDISPLAY =
  'WEBAPP_TAXSETTINGS_CHANGEDPROJECTEDINCOMETAXDISPLAY';
export const WEBAPP_TAXSETTINGS_CHANGEDMANUALINCOMEESTIMATE =
  'WEBAPP_TAXSETTINGS_CHANGEDMANUALINCOMEESTIMATE';

// Bank Settings
export const WEBAPP_BANKSSETTINGS_SWITCHEDBANKACCOUNT =
  'WEBAPP_BANKSSETTINGS_SWITCHEDBANKACCOUNT';
export const WEBAPP_BANKSSETTINGS_CLICKEDADDANOTHERBANKACCOUNT =
  'WEBAPP_BANKSSETTINGS_CLICKEDADDANOTHERBANKACCOUNT';
export const WEBAPP_BANKSSETTINGS_CLICKEDEDITBANKACCOUNT =
  'WEBAPP_BANKSSETTINGS_CLICKEDEDITBANKACCOUNT';
export const WEBAPP_BANKSSETTINGS_CLICKEDACCOUNTNAMEFIELD =
  'WEBAPP_BANKSSETTINGS_CLICKEDACCOUNTNAMEFIELD';
export const WEBAPP_BANKSSETTINGS_CLICKEDCANCELEDIT =
  'WEBAPP_BANKSSETTINGS_CLICKEDCANCELEDIT';
export const WEBAPP_BANKSSETTINGS_CLICKEDSAVE =
  'WEBAPP_BANKSSETTINGS_CLICKEDSAVE';
export const WEBAPP_BANKSSETTINGS_CLICKEDRENEWCONSENT =
  'WEBAPP_BANKSSETTINGS_CLICKEDRENEWCONSENT';
export const WEBAPP_BANKSSETTINGS_CLICKEDREMOVEACCOUNT =
  'WEBAPP_BANKSSETTINGS_CLICKEDREMOVEACCOUNT';
export const WEBAPP_BANKSSETTINGS_CLICKEDCANCELREMOVEACCOUNTMODAL =
  'WEBAPP_BANKSSETTINGS_CLICKEDCANCELREMOVEACCOUNTMODAL';
export const WEBAPP_BANKSSETTINGS_CLICKEDCONFIRMREMOVEACCOUNT =
  'WEBAPP_BANKSSETTINGS_CLICKEDCONFIRMREMOVEACCOUNT';

// Peppol Settings
export const WEBAPP_PEPPOL_PRESSEDSTARTREGISTRATION =
  'WEBAPP_PEPPOL_PRESSEDSTARTREGISTRATION';
export const WEBAPP_PEPPOL_SUBMITTEDREGISTRATIONFORM =
  'WEBAPP_PEPPOL_SUBMITTEDREGISTRATIONFORM';
export const WEBAPP_PEPPOL_REGISTRATIONFORMERROR =
  'WEBAPP_PEPPOL_REGISTRATIONFORMERROR';
export const WEBAPP_PEPPOL_PRESSEDLEARNMORE = 'WEBAPP_PEPPOL_PRESSEDLEARNMORE';
export const WEBAPP_PEPPOL_PRESSEDOPENLINK = 'WEBAPP_PEPPOL_PRESSEDOPENLINK';
export const WEBAPP_PEPPOL_PRESSEDDELETE = 'WEBAPP_PEPPOL_PRESSEDDELETE';
export const WEBAPP_PEPPOL_CONFIRMEDDELETE = 'WEBAPP_PEPPOL_CONFIRMEDDELETE';
export const WEBAPP_PEPPOL_CANCELEDDELETE = 'WEBAPP_PEPPOL_CANCELEDDELETE';
// Invoice reminder settings

export const WEBAPP_INVOICEREMINDERSETTINGS_SUBMITAUTOSENDON =
  'WEBAPP_INVOICEREMINDERSETTINGS_SUBMITAUTOSENDON';
export const WEBAPP_INVOICEREMINDERSETTINGS_SUBMITAUTOSENDOFF =
  'WEBAPP_INVOICEREMINDERSETTINGS_SUBMITAUTOSENDOFF';
