export const JsonPrefix = 'JSON#';

export const tempMessagesTranslations = {
  en: {
    'any.only': 'Incorrect value for {label}',
    'any.empty': '{label} is required',
    'any.required': '{label} is required',
    'any.invalid': '{label} is invalid',
    // add more ref translations
    'any.ref': '{label} required when {ref} is set', //ref is the other field label if Joi.ref render is false for that ref

    'string.base': '{label} is required',
    'string.empty': '{label} is required',
    'string.email': 'Email is invalid',
    'string.min': '{label} should contain at least {limit} characters',
    'string.max': '{label} should contain {limit} character/s max',
    'string.pattern.base': '{label} is incorrect',
    'string.pattern.empty': '{label} is incorrect',
    'string.length': 'Postal code should be {limit} digits',

    'number.type': '{label} should be a number',
    'number.base': '{label} is required',
    'number.empty': '{label} is required',
    'number.max': "{label} can't be greater then {limit}",
    'number.min': "{label} can't be less then {limit}",

    'array.base': '{label} is required',
    'array.min': '{label} should contain at least {limit} items',
    'array.max': '{label} should contain {limit} item/s max',
    'array.length': 'Must contain {limit} item/s',

    'object.required': '{label} is required',
    'object.base': '{label} is required',

    'date.base': '{label} is required',
    'date.min': "{label} can't be less then {limit}",
    'date.max': "{label} can't be greater then {limit}",
    'date.less': "{label} can't be greater then {limit}",
    'date.format': '{label} format should follow {format}',
  },
};
