import { get } from 'lodash';
import { type FC } from 'react';
import { TaxCheckEnum, type CheckComponentProps } from 'types/taxes.types';
import CheckRevenuesList from '../CheckRevenuesList';
import CheckUITemplate from '../CheckUITemplate';

const SubmissionRevenueIncludedInAnotherSubmission: FC<CheckComponentProps> = (
  props,
) => {
  const { checkData, checkName } = props;

  return (
    <CheckUITemplate
      {...props}
      renderContent={() => (
        <div className="my-4">
          <CheckRevenuesList
            {...{
              list: get(checkData, 'data'),
              ...(checkName ===
                TaxCheckEnum.submissionRevenueIncludedInAnotherSubmission && {
                extraCols: [{ head: 'Other submissions', key: 'taxItems' }],
              }),
              ...props,
            }}
          />
        </div>
      )}
    />
  );
};

export default SubmissionRevenueIncludedInAnotherSubmission;
