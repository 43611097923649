import api from 'api/axiosInstance';

const requestDeleteAccount = (
  {
    email,
    password,
  }: {
    email: string;
    password?: string;
  },
  params?: Record<string, any>,
): Promise<void> =>
  api.post(
    '/v2/users/delete-user-request',
    {
      email,
      password,
    },
    params,
  );

export default requestDeleteAccount;
