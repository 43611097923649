import type {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import axios from 'axios';
import { accountableAPI } from 'config';
import logout from 'hooks/Authentication/useLogout';
import _get from 'lodash/get';
import {
  forceUpdateAuthData,
  getStoredAuthData,
  getUpToDateAuthData,
} from 'utils/auth';
import { isExpert, isWeb } from 'utils/constants';
import { getCustomerIdFromPath } from 'utils/helpers';
import { resolveServerErrors } from './serverErrorsResolver';
import showErrorToast from './showErrorToast';

const generateId = (): string => Math.random().toString(32).slice(2, 10);

export type IPaginationRequest = {
  perPage: number;
  page: number;
};

export type IPaginationResponse = {
  paging: {
    perPage: number;
    page: number;
    pagesCount: number;
    totalCount: number;
  };
};

export type IAuthResponse = {
  refresh_token: string;
  access_token: string;
  token_type: 'Bearer';
  iss: string;
  iat: number;
  exp: number;
  aud: string;
  sub: string;
  role: string;
};

export interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  noOnBehalf?: boolean;
  noHeaders?: boolean;
}

const axiosInstance = axios.create({
  baseURL: accountableAPI.baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-bundleversion': process.env.REACT_APP_VERSION || '',
    'x-client': `accountable-${isWeb ? 'web' : 'ozark'}`,
    'x-client-session': generateId(),
  },
  timeout: 60000,
});

axiosInstance.interceptors.request.use(
  async (
    request: ExtendedAxiosRequestConfig,
  ): Promise<ExtendedAxiosRequestConfig> => {
    if (request.noHeaders) {
      request.headers = {};
      return request;
    }

    const authData = await getUpToDateAuthData();

    if (authData) {
      (request.headers || {}).Authorization = `Bearer ${authData.access_token}`;

      if (isExpert) {
        const customerId = getCustomerIdFromPath();

        if (!request.noOnBehalf && customerId) {
          (request.headers || {})['x-on-behalf-of'] = customerId;
        }
      }
    } else {
      const storedAuthData = getStoredAuthData();

      // If user was logged in, but session expired, force logout
      if ('access_token' in storedAuthData) return logout(true);
    }

    return request;
  },
  (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
);
axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosRequestConfig => response.data,
  async (error: AxiosError): Promise<AxiosPromise> => {
    const { config, response } = error;
    const serverErrorCode = _get(error, 'response.data.errors.[0].code');

    if (serverErrorCode?.includes('jwt-issuer-invalid-expected')) {
      return logout(true);
    }

    // If access token is expired
    if (
      serverErrorCode === 'jwt-expired' ||
      serverErrorCode == 'no-auth-token' ||
      serverErrorCode == 'invalid-token'
    ) {
      // Retrieve user's current refresh token
      const storedAuthData = getStoredAuthData();

      // If user was logged in
      if ('refresh_token' in storedAuthData) {
        // Try to update access token, and if it fails to, just force logout
        if (!(await forceUpdateAuthData())) return logout(true);

        // Retry request
        return axiosInstance(config);
      }

      // Session expired and error could not be handled. Just force logout
      return logout(true);
    }

    if (!_get(response, 'config.ignoreToast')) {
      resolveServerErrors(error).forEach((message) => {
        showErrorToast(message);
      });
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
