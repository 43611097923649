import SVGIcon from 'assets/svg/icons/revenues/SVGIconRenderer';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import dayjs from 'dayjs';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useCustomNavigate from 'hooks/useCustomeNavigate';
import i18n from 'i18n';
import { isEmpty, omit, pick } from 'lodash';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClientLocationEnum,
  ClientTypeEnum,
  IClient,
} from 'types/clients.types';
import { ReviewStatusEnum } from 'types/expenses.types';
import { Nullable, Period } from 'types/global.types';
import type {
  Invoice,
  InvoiceFormValues,
  InvoiceStatus,
} from 'types/invoice.types';
import {
  InvoiceStatusEnum,
  InvoiceTypeEnum,
  WhyZeroVATEnum,
} from 'types/invoice.types';
import type { TransactionAsOption } from 'types/transactions.types';
import {
  IUser,
  VATReturnFrequency,
  VATType,
  VATTypeEnum,
} from 'types/users.types';
import { isExpert } from 'utils/constants';
import { calculateVATAmount } from 'utils/expenses/helper';
import {
  cleanEmptyValues,
  cleanEmptyValues_stringified,
  isDocumentLockedByTaxesOrAccountant,
} from 'utils/helpers';
import { divideBy1000 } from 'utils/math';
import tryCatch from 'utils/tryCatch';

export const itemDefaults = {
  quantity: 1000,
  unit: 'items',
  qty: 1,
  VATRate: null,
  category: null,
  categoryId: null,
  name: null,
  unitAmountExclVAT: 0,
  unitAmountInclVAT: 0,
  whyZeroVAT: null,
  discountPercentage: null,
  assetId: null,
  _id: null,
};

const filterIrrelevantCategories = (
  categories: any[],
  docType: string,
  accountType: string,
) => {
  return categories.reduce(function reducer(acc: any[], category: any): any[] {
    const isIrrelevant =
      !category.revenueType.includes(docType) ||
      !!category.disabledFor?.includes(accountType);
    if (isIrrelevant) return acc;
    if (category.children) {
      const _children = category.children.reduce(reducer, []);
      if (_children.length) {
        return acc.concat({ ...category, children: _children });
      }
      return acc;
    }
    return acc.concat(category);
  }, []);
};

export const adaptCategories = (
  categories: any[],
  deductibleTaxCategoriesMap: any,
  docType: string,
  accountType: string,
) => {
  // revenues categories either all of them are categories of categories or just flat list of final categories
  const hasChildren = !!categories[0].children;
  if (hasChildren) {
    const list = filterIrrelevantCategories(
      categories,
      docType,
      accountType,
    ).map((category) => ({
      _category: category,
      title: i18n.t(category.label),
      displayName: i18n.t(category.label),
      description: i18n.t(`${category.label}.description`),
      icon: <SVGIcon icon={category.icon} className="w-6 h-6" fill="#351A4D" />,
      list: category.children.map((innerCategory: any) => {
        const { id, icon } = innerCategory;
        const category = {
          _category: innerCategory,
          id,
          title: i18n.t(id),
          displayName: i18n.t(id),
          description: i18n.t(`${id}.description`),
          icon: <SVGIcon icon={icon} className="w-6 h-6" fill="#351A4D" />,
        };
        if (deductibleTaxCategoriesMap[id]) {
          return { ...category, ...deductibleTaxCategoriesMap[id] };
        }
        return category;
      }),
    }));

    return [
      {
        title: i18n.t('invoice_categories.all.title'),
        list,
        icon: <SVGIcon icon="mixed" className="w-6 h-6" fill="#351A4D" />,
        isAllCategories: true,
      },
      ...list,
    ];
  }

  return [
    {
      title: i18n.t('invoice_categories.all.title'),
      list: filterIrrelevantCategories(categories, docType, accountType).map(
        (innerCategory: any) => {
          const { id, icon } = innerCategory;
          const category = {
            _category: innerCategory,
            id,
            title: i18n.t(id),
            displayName: i18n.t(id),
            description: i18n.t(`${id}.description`),
            icon: <SVGIcon icon={icon} className="w-6 h-6" fill="#351A4D" />,
          };
          if (deductibleTaxCategoriesMap[id]) {
            return { ...category, ...deductibleTaxCategoriesMap[id] };
          }
          return category;
        },
      ),
    },
  ];
};

export const generatedInvoiceOrCreditNoteKeys = [
  'currency',
  'period',
  'status',
  'transactions',
  'paymentType',
];

export const defaultValuesKeys = [
  'client',
  'fileType',
  'filePath',
  'invoiceDate',
  'isValidated',
  'dueDate',
  'deliveryDate',
  'deliveryPeriod',
  'comments',
  'notes',
  'accountantReview',
  'items',
  'paymentDate',
  'paymentAmount',
  'period',
  'taxPeriod',
  'revenueNumber',
  'status',
  'transactions',
  'paymentType',
  'type',
  'user',
  'createdFrom',
  'created',
  'currency',
  // 'recurringData',
  // 'recurringId',
  'customType',
  'customData',
  '_id',
];

export const itemDefaultValuesKeys = [
  'VATRate',
  'category',
  'categoryId',
  'name',
  'quantity',
  'unit',
  'unitAmountExclVAT',
  'doesUnitPriceIncludeVAT',
  'unitAmountInclVAT',
  'whyZeroVAT',
  'discountPercentage',
  'assetId',
  '_id',
];

export const otherRevenueEmptyClient = { location: 'local' };

export const removeInvalidVATRateGroups = (groups: {
  [key: string]: any[];
}) => {
  return omit(groups, ['0', 'undefined', 'null']);
};

export const getDefaultValues = (
  values: Partial<Invoice>,
  VATType: VATType,
  VATReturnFrequency: VATReturnFrequency,
) => {
  if (values?._id)
    return {
      ...pick(values, defaultValuesKeys),
      status:
        values.status === InvoiceStatusEnum.draft
          ? InvoiceStatusEnum.notSent
          : values.status,
      paymentType: values.paymentType || null,
      showDeliveryPeriod: values.deliveryPeriod != null,
      revenueNumber: values.revenueNumber || values.guessedInvoiceNumber,
      deliveryDate:
        (values.isValidated
          ? values.deliveryDate
          : values.deliveryPeriod
          ? undefined
          : values.deliveryDate || values.guessedDeliveryDate) || undefined,
      comments: values.comments || undefined,
      notes: values.notes || undefined,
      clientId: values.client?._id,
      accountantReview: isExpert
        ? {
            ...values.accountantReview,
            reviewStatus:
              values.accountantReview?.reviewStatus ||
              ReviewStatusEnum.notReviewd,
            comments: values.accountantReview?.comments || undefined,
          }
        : undefined,
      items:
        values.items?.map((item: any) => ({
          ...pick(item, itemDefaultValuesKeys),
          categoryId: item.category?.id,
          discountPercentage: item?.discountPercentage || 0,
        })) || [],
      user: {
        VATType: values.userSnapshot?.VATType || VATType,
        VATReturnFrequency,
      },
    } as InvoiceFormValues;

  const today = dayjs().format('YYYY-MM-DD');
  const isOtherRevenue = values.type === InvoiceTypeEnum.OTHER_REVENUE;

  return {
    invoiceDate: today,
    paymentDate: isOtherRevenue ? today : undefined,
    paymentType:
      isEmpty(values?.transactions) && values?.paymentType == null
        ? 'cash'
        : values?.paymentType || null,
    status: 'paid',
    client: isOtherRevenue ? otherRevenueEmptyClient : undefined,
    items: [{ quantity: 1000, unit: 'items', qty: 1 }],
    user: {
      VATType,
      VATReturnFrequency,
    },
    fileType: isOtherRevenue ? 'generated' : 'imported',
    createdFrom: 'web',
    accountantReview: isExpert
      ? {
          reviewStatus: ReviewStatusEnum.notReviewd,
        }
      : undefined,
    ...values,
    showDeliveryPeriod: values.deliveryPeriod != null,
    deliveryDate: values.deliveryPeriod
      ? undefined
      : values.deliveryDate || values.guessedDeliveryDate || undefined,
  } as InvoiceFormValues;
};

export const adaptDocForDuplication = (doc: Partial<Invoice>) => {
  return omit(doc, [
    '_id',
    'revenueNumber',
    'invoiceDate',
    'dueDate',
    'deliveryDate',
    'paymentDate',
    'deliveryPeriod',
    'paymentQrCode',
    'deleted',
    'dateSubmitted',
    'accountantReview',
    'created',
    'transactions',
    'paymentType',
    'period',
    'status',
    'taxLock',
    'quoteId',
  ]);
};
//

export const GeneratedDocumentEditWarning: FC<{
  document: Invoice;
  onCancel: () => void;
}> = (props) => {
  const { t } = useTranslation();

  const country = useCustomerCountry();
  const navigate = useCustomNavigate();

  const isInvoice = props.document.type === InvoiceTypeEnum.INVOICE;

  const isLocked = isDocumentLockedByTaxesOrAccountant(props.document);

  return (
    <>
      <div className="bg-[#f6f6fd] px-8 py-6 rounded-t-2xl">
        <p className="font-bold leading-tight text-primary-500 text-xl uppercase">
          {t('warning')}
        </p>
      </div>
      <div className="font-semibold px-8 py-6 text-lg">
        <p className="text-primary-900">
          {isInvoice
            ? t('warning_you_are_about' + (isLocked ? '.locked' : ''), {
                credit_note: t(`invoices.credit_note.${country}`),
                status: t(
                  `invoice.status_${props.document.status}`,
                ).toLowerCase(),
              })
            : t(
                'warning_you_are_about_credit_note' +
                  (isLocked ? '.locked' : ''),
                {
                  credit_note: t(`invoices.credit_note.${country}`),
                  status: t(
                    `invoice.status_${props.document.status}`,
                  ).toLowerCase(),
                },
              )}
        </p>
        <Button
          structure="secondary"
          size="lg"
          className="mt-6 w-full"
          onClick={() => {
            navigate('/revenues/create', {
              state: {
                guessedDocument: {
                  ...adaptDocForDuplication(props.document),
                  type: isInvoice
                    ? InvoiceTypeEnum.CREDIT_NOTE
                    : InvoiceTypeEnum.INVOICE,
                },
              },
            });
          }}
        >
          {isInvoice
            ? t('invoices.create_credit_note', {
                type: t(`invoices.credit_note.${country}`),
              })
            : t('invoices.create_invoice')}
        </Button>
        {!isLocked && (
          <Button
            size="lg"
            className="mt-3 w-full"
            onClick={() => navigate(`/revenues/${props.document._id}`)}
          >
            {t('edit')}
          </Button>
        )}
        {isLocked && (
          <Button
            size="lg"
            structure="text"
            className="mt-3 w-full"
            onClick={() => props.onCancel()}
          >
            {t('invoice.form.cancel')}
          </Button>
        )}
      </div>
    </>
  );
};

const warningKey = 'hideOtherRevenueIncodeWarning';
export const OtherRevenueIncodeWarning = () => {
  const { t } = useTranslation();
  const shouldHide = localStorage.getItem(warningKey);
  if (shouldHide) return null;
  return (
    <Alert
      type="info"
      dismissable
      onDismiss={() => localStorage.setItem(warningKey, 'true')}
      description={t('other_revenue.init_incoding.warning')}
    />
  );
};

export const getLockStatusBody = (invoice: {
  invoiceDate?: string;
  paymentDate?: string;
  period?: Period;
  status?: InvoiceStatus;
  user?: Partial<Pick<IUser, 'VATType' | 'VATReturnFrequency'>>;
}) => {
  const invoiceDate = invoice.invoiceDate
    ? dayjs(invoice.invoiceDate).format('YYYY-MM-DD')
    : undefined;

  const paymentDate = invoice.paymentDate
    ? dayjs(invoice.paymentDate).format('YYYY-MM-DD')
    : undefined;

  return {
    invoiceDate,
    paymentDate,
    period: invoice.period || undefined,
    status: invoice.status || undefined,
    user: {
      VATType: invoice.user?.VATType || undefined,
      VATReturnFrequency: invoice.user?.VATReturnFrequency || undefined,
    },
  };
};

export const cleanInvoiceValues = (values: Partial<Invoice>) => {
  return cleanEmptyValues(values, [
    'VATRate',
    'deliveryDate',
    'deliveryPeriod',
  ]);
};

export const formatInvoiceValues = (values: Partial<Invoice>) => {
  return {
    ...values,
    accountantReview: isExpert ? values.accountantReview : undefined,
    items: values.items?.map((item) => {
      return {
        ...item,
        name: item.name?.trim() || undefined,
      };
    }),
  };
};

export const cleanInvoiceForTaxImpact = (values: Partial<Invoice>) => {
  return cleanInvoiceValues(formatInvoiceValues(values) as any);
};

export const getRevenueLinkedPaymentsOppositeDirectionTotal = (
  payments?: any[],
  isCreditNote?: boolean,
): number =>
  payments
    ?.filter((option: TransactionAsOption) => {
      if (isCreditNote) return option.transaction.baseCurrencyAmount > 0;

      return option.transaction.baseCurrencyAmount < 0;
    })
    .reduce(
      (agg: number, current: TransactionAsOption) =>
        agg + Math.abs(current.transaction.baseCurrencyAmount || 0),
      0,
    );

export const getRevenueLinkedPaymentsAmount = (
  payments?: any[],
  isCreditNote?: boolean,
): number =>
  payments
    ?.filter((option: TransactionAsOption) => {
      if (isCreditNote) return option.transaction.baseCurrencyAmount < 0;

      return option.transaction.baseCurrencyAmount > 0;
    })
    .reduce(
      (agg: number, current: TransactionAsOption) =>
        agg + Math.abs(current.transaction.baseCurrencyAmount || 0),
      0,
    );

export const germanCarsCustomDataFieldsArray = [
  'customType',
  'customData.customCategor',
  'customData.customCategoryId',
  'customData.usageStartDate',
  'customData.usageEndDate',
  'customData.vehicle',
  'customData.vehicleId',
  'customData.isVehicleUsedForOfficeCommute',
  'customData.distanceBetweenHomeAndOffice',
  'user.VATType',
  'user.VATReturnFrequency',
];

export const formatGermanCarsData = (data: any): any => {
  const extractedData = pick(data, germanCarsCustomDataFieldsArray);

  const distance = extractedData.customData?.distanceBetweenHomeAndOffice;
  const isUsedForCommute =
    extractedData.customData?.isVehicleUsedForOfficeCommute;
  if (!distance && isUsedForCommute) {
    extractedData.customData.isVehicleUsedForOfficeCommute = false;
    extractedData.customData.distanceBetweenHomeAndOffice = undefined;
  }
  return extractedData;
};

export const encodeGermanCarsData = (data: any): string | null => {
  return tryCatch(
    () => cleanEmptyValues_stringified(formatGermanCarsData(data)),
    null,
  );
};

export type GetValidWhyZeroVATValuesData = {
  clientType: Nullable<IClient['type']>;
  clientLocation: Nullable<IClient['location']>;
  categoryId: Nullable<string>;
  revenueType: InvoiceTypeEnum;
  VATType: VATType;
};

export const getValidWhyZeroVATValues = (
  data: GetValidWhyZeroVATValuesData,
) => {
  if (data.VATType === VATTypeEnum.exempt) {
    return [WhyZeroVATEnum.exempt_item];
  }

  if (data.VATType !== VATTypeEnum.subjectToVAT) {
    return [WhyZeroVATEnum.user_franchisee];
  }

  const valids: string[] = [WhyZeroVATEnum.exempt_item];

  if (data.revenueType === InvoiceTypeEnum.OTHER_REVENUE) return valids;

  const isClientExtraEU = data.clientLocation === ClientLocationEnum.extra_eu;
  const isClientIntraEU = data.clientLocation === ClientLocationEnum.intra_eu;
  const isClientTypeBusiness = data.clientType === ClientTypeEnum.business;
  const isClientTypeDiplomatic = data.clientType === ClientTypeEnum.diplomatic;
  const isClientLocal = data.clientLocation === ClientLocationEnum.local;

  if (isClientIntraEU && isClientTypeBusiness)
    valids.push(WhyZeroVATEnum.intra_eu_reverse_charge);

  if (isClientTypeDiplomatic) valids.push(WhyZeroVATEnum.diplomatic);

  if (isClientLocal && isClientTypeBusiness)
    valids.push(WhyZeroVATEnum.local_reverse_charge);

  if (isClientExtraEU && data.categoryId) {
    const allowedCategories = [
      'be.revenue.sales_goods',
      'be.revenue.sales_services',
      'de.revenue.sales_goods',
      'de.revenue.sales_services',
      'be.revenue.copyright',
      'de.revenue.royalties_licensing',
    ];
    if (allowedCategories.includes(data.categoryId))
      valids.push(WhyZeroVATEnum.extra_eu_export);
  }

  return valids;
};

type PartialItem = Partial<{
  quantity: number;
  unitAmountExclVAT: number;
  VATRate: number;
  discountPercentage: number;
  doesUnitPriceIncludeVAT: boolean;
  unitAmountInclVAT: number;
}>;

export const getInvoiceItemInclVAT = (item: PartialItem) => {
  const _quantity = divideBy1000(item.quantity || 0);
  const _unitAmountExclVAT = divideBy1000(item.unitAmountExclVAT || 0);
  const _discountPercentage = divideBy1000(item.discountPercentage || 0);

  if (item.doesUnitPriceIncludeVAT) {
    const sum = divideBy1000(item.unitAmountInclVAT || 0) * _quantity;
    return sum - sum * _discountPercentage;
  }
  const sum =
    (_unitAmountExclVAT +
      _unitAmountExclVAT * divideBy1000(item.VATRate || 0)) *
    _quantity;
  return sum - sum * _discountPercentage;
};

export const getInvoiceItemExclVAT = (item: PartialItem) => {
  const _quantity = divideBy1000(item.quantity || 0);
  const _unitAmountInclVAT = divideBy1000(item.unitAmountInclVAT || 0);
  const _discountPercentage = divideBy1000(item.discountPercentage || 0);

  if (item.doesUnitPriceIncludeVAT) {
    const VATAmount = calculateVATAmount(
      _unitAmountInclVAT,
      divideBy1000(item.VATRate || 0),
    );
    const sum = (_unitAmountInclVAT - VATAmount) * _quantity;
    return sum - sum * _discountPercentage;
  }

  const sum = divideBy1000(item.unitAmountExclVAT || 0) * _quantity;
  return sum - sum * _discountPercentage;
};

export const getInvoiceItemsTotalExclVAT = (items: PartialItem[]) => {
  if (isEmpty(items)) return 0;
  return items.reduce((acc: number, item) => {
    return acc + getInvoiceItemExclVAT(item);
  }, 0);
};

export const getInvoiceItemsTotalInclVAT = (items: PartialItem[]) => {
  if (isEmpty(items)) return 0;
  return items.reduce((acc: number, item) => {
    return acc + getInvoiceItemInclVAT(item);
  }, 0);
};
