import classNames from 'classnames';
import { Tooltip } from 'components/atoms/Tooltip';
import type { FC, MutableRefObject } from 'react';
import { useRef, type ReactElement } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import type { MenuListProps, Props } from 'react-select';
import { components } from 'react-select';
import colors from 'theme/colors';
import {
  ChevronDownIconOutline,
  ExclamationCircleIcon,
  XIconOutline,
} from 'utils/icons';
import type { ThemeOptions } from './select.types';

const getBorderColor = ({
  isFocused,
  error,
}: {
  isFocused: boolean;
  isDisabled: boolean;
  error?: string;
}) => {
  if (error) return colors.red[500];
  if (isFocused) return colors.primary[500];
  // if (isDisabled) return colors.primary[100];

  return colors.primary[100];
};

const CustomMenuList: FC<MenuListProps & { onMenuScrollToEnd: () => void }> = (
  props,
) => {
  const callbackRef = useRef(props.onMenuScrollToEnd);
  const ref = useBottomScrollListener(callbackRef.current, {
    offset: 100,
  }) as MutableRefObject<HTMLDivElement | null>;

  const fnRef = useRef((elm: HTMLDivElement | null) => {
    props.innerRef(elm);
    ref.current = elm;
  });

  return <components.MenuList {...props} innerRef={fnRef.current} />;
};

const getCustomUI = (
  options?: ThemeOptions,
  error?: string,
): Partial<Props> => ({
  components: {
    Option: ({ children, isSelected, ...rest }) => {
      isSelected = !!options?.shouldOptionsShowSelectedIndicator && isSelected;
      const withTooltip = (rest.data as any)?.tooltipText;
      const maybeContentWithTooltip = (content: ReactElement): ReactElement =>
        withTooltip ? (
          <Tooltip content={withTooltip}>
            <div>{content}</div>
          </Tooltip>
        ) : (
          content
        );
      return maybeContentWithTooltip(
        <components.Option {...rest} isSelected={isSelected}>
          {children}
        </components.Option>,
      );
    },
    DropdownIndicator: (props) => {
      if (error) {
        return (
          <components.DropdownIndicator {...props}>
            <Tooltip content={error}>
              <ExclamationCircleIcon
                className={classNames('w-5 h-5 text-red-500', {
                  'opacity-40': props.isDisabled,
                })}
              />
            </Tooltip>
          </components.DropdownIndicator>
        );
      }

      if (options?.hideDropdownIndicator) return null;

      return (
        <components.DropdownIndicator {...props}>
          <ChevronDownIconOutline className="w-6 h-6 text-primary-400" />
        </components.DropdownIndicator>
      );
    },
    IndicatorSeparator: (props) => {
      if (options?.hideDropdownIndicator) return null;
      return (
        <components.IndicatorSeparator {...props} className="!bg-primary-100" />
      );
    },
    LoadingIndicator: (props) => (
      <components.LoadingIndicator {...props} isFocused={false} />
    ),
    ClearIndicator: (props) =>
      options?.hideClearButton ? null : (
        <components.ClearIndicator {...props}>
          <XIconOutline className="w-4 h-4 text-primary-400" />
        </components.ClearIndicator>
      ),
    Control: (props) => (
      <components.Control
        {...props}
        className={classNames(props.className, options?.controlClassName)}
      />
    ),
    MenuList: (props) => {
      const onMenuScrollToEnd = (props.selectProps as any)?.onMenuScrollToEnd;
      if (onMenuScrollToEnd) {
        return (
          <CustomMenuList {...props} onMenuScrollToEnd={onMenuScrollToEnd} />
        );
      }
      return <components.MenuList {...props} />;
    },
  },
  theme: (theme) =>
    ({
      ...theme,
      spacing: {
        ...theme.spacing,
        controlHeight: '3rem',
      },
    } as any),
  styles: {
    container: (provided) => ({
      ...provided,
      pointerEvents: 'auto',
    }),
    input: (provided) => ({
      ...provided,
      color: colors.main.DEFAULT,
      fontWeight: 600,
      display: 'flex',
      flex: '1',
      '&>input': { cursor: 'inherit' },
      '&::after': { display: 'none' },
    }),
    singleValue: (provided, { isDisabled }) => {
      return {
        ...provided,
        display: 'inline-block',
        color: isDisabled ? colors.primary[400] : colors.main.DEFAULT,
        // opacity: isDisabled ? 0.5 : 1,
        fontWeight: 600,
      };
    },
    dropdownIndicator: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? colors.primary[400] : colors.primary.DEFAULT,
      // opacity: isDisabled ? 0.5 : 1,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colors.primary[400],
      fontWeight: 600,
      position: 'absolute',
    }),
    control: (provided, { isFocused, isDisabled }) => {
      return {
        ...provided,
        '&:hover': {},
        boxShadow: isFocused
          ? error
            ? 'rgba(185, 28, 28, 0.25) 0px 0px 0px 0.125rem'
            : 'rgba(73, 49, 94, 0.25) 0px 0px 0px 0.125rem'
          : undefined,
        margin: undefined,
        borderRadius: '0.5rem',
        borderColor: getBorderColor({ isFocused, isDisabled, error }),
        backgroundColor: isDisabled ? colors.primary[25] : 'white',
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem',
        height: options?.flexibleControlHight ? undefined : '3rem',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        display: 'flex',
        flexWrap: 'nowrap',
        position: 'relative',
      };
    },
    menu: (provided) => ({
      ...provided,
      borderRadius: '0.375rem',
      marginTop: '0.25rem',
      boxShadow: `0 0 0 0.0625rem ${colors.primary[100]}, 0 0.25rem 0.68rem hsla(0, 0%, 0%, 0.1)`,
      right: '0',
      left: '0',
      overflow: 'hidden',
    }),
    option: (provided, { isFocused, isSelected, isDisabled }) => {
      isSelected = !!options?.shouldOptionsShowSelectedIndicator && isSelected;

      return {
        ...provided,
        ':active': undefined,
        backgroundColor:
          isFocused || isSelected ? colors.primary[50] : 'transparent',
        color: isDisabled
          ? colors.primary[500]
          : isSelected
          ? colors.primary.DEFAULT
          : colors.main.DEFAULT,
        padding: '0.5rem 1rem',
        cursor: 'pointer',
      };
    },
  },
});

export default getCustomUI;
