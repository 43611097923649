import { Button } from 'components/atoms/Button';
import { Title } from 'components/atoms/Title';
import { SizesEnum } from 'components/atoms/Title/Title.enum';
import getIntercom from 'hooks/intercom.hook';
import type { FC } from 'react';

type IPageProps = {
  error: Error;
  componentStack: string | null;
  resetError: () => void;
  eventId: string | null;
};

const ErrorPage: FC<IPageProps> = ({ resetError, eventId }) => {
  const { showIntercom } = getIntercom();
  return (
    <div className="flex justify-center flex-col items-center h-screen">
      <Title
        type={SizesEnum.h1}
      >{`Oops, looks like something went wrong . . .`}</Title>
      <div className="my-5 font-semibold text-xl">
        {`We apologize for the issue. Our team has been notified and we'll work on a fix soon.`}
      </div>

      <div className="bg-gray-100 p-2 text-sm">
        <code>Error Id: {eventId}</code>
      </div>
      <div className="mt-1 text-sm">{`Report this id to our customer service to help us solve the issue faster`}</div>
      <div className="mt-8 flex space-x-5">
        <Button color="taxes" size={'xl'} onClick={resetError}>
          {'Go back'}
        </Button>
        <Button color="primary" size={'xl'} onClick={showIntercom}>
          {'Contact support'}
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
