import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useFeatures from 'hooks/useFeatures';
import { useCallback } from 'react';
import type { IClient } from 'types/clients.types';
import { ClientTypeEnum } from 'types/clients.types';
import type { CountryType } from 'types/countries';
import { AvailableCountries, type IUser } from 'types/users.types';
import { caseInsensitiveEqual } from 'utils/helpers';

const isClientVATNumberRequired = (
  country: 'be' | 'de',
  client_country: CountryType | undefined,
  isBusiness: boolean,
) => {
  // Logic for Belgium
  if (country === 'be') {
    // if client is local or intra-eu and business, vat number is required, otherwise not required
    return client_country?.EEA && isBusiness;
  }

  if (country === 'de') {
    // if client is german, not required
    if (client_country?.alpha2Code === 'DE') return false;

    // if client is intra-eu and business, vat number is required otherwise not required
    return client_country?.EEA && isBusiness;
  }

  throw new Error(
    'Country must be "be" or "de" in order to validate the client VATNumber',
  );
};

const useFormMeta = (countries?: CountryType[]) => {
  const { data: customer } = useGetCurrentCustomerData();

  const { country: customerCountry } = customer as IUser;
  const getShouldPrefillVATPrefix = useFeatures(
    'clients.shouldPrefillVATPrefix',
  );
  const getIsSearchableCountry = useFeatures('clients.getIsSearchableCountry');

  const getFormMeta = useCallback(
    (formValues: Partial<IClient>) => {
      const countryCode = formValues?.address?.country as string;
      const isUserDE = customerCountry === AvailableCountries.germany;
      const isClientLocal = caseInsensitiveEqual(customerCountry, countryCode);

      const country = countries?.find((option) =>
        caseInsensitiveEqual(option.alpha2Code, countryCode),
      );

      const isEEA = !!country?.EEA;

      const isClientTypeBusiness = formValues.type === ClientTypeEnum.business;
      const isClientIntraEU = isEEA && !isClientLocal;
      const shouldShowSteuernummer =
        isUserDE &&
        caseInsensitiveEqual(AvailableCountries.germany, countryCode);

      const shouldShowVATNumber = isClientTypeBusiness;
      const isVATNumberRequired = isClientVATNumberRequired(
        customerCountry,
        country,
        isClientTypeBusiness,
      );

      const shouldShowVATStatus = isClientLocal && isClientTypeBusiness;

      const shouldPrefillVATPrefix =
        getShouldPrefillVATPrefix(countryCode) && formValues.VATNumber == null;

      const isCountrySearchable = getIsSearchableCountry(countryCode);

      const shouldShowEuVATWarning =
        formValues.VATNumber?.slice(0, 2).toUpperCase() === 'EU';

      return {
        isClientLocal,
        isEEA,
        isClientIntraEU,
        isClientTypeBusiness,
        isCountrySearchable,
        isVATNumberRequired,
        shouldShowVATNumber,
        shouldShowVATStatus,
        shouldPrefillVATPrefix,
        shouldShowSteuernummer,
        shouldShowEuVATWarning,
      };
    },
    [countries, customerCountry],
  );

  return { getFormMeta };
};

export default useFormMeta;
