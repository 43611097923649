import { getUserData } from 'api/v2/users/authentication';
import _get from 'lodash/get';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { UserCacheKeys } from 'types/cacheKeys.types';
import type { IUser } from 'types/users.types';
import { isExpert } from 'utils/constants';
import { getCustomerIdFromPath } from 'utils/helpers';
import useGetLoggedInUserData from './useGetLoggedInUserData';

const useGetCurrentCustomerData = (
  afterFetch?: () => void,
): UseQueryResult<IUser, unknown> => {
  const webCustomer = useGetLoggedInUserData(afterFetch);
  const navigate = useNavigate();
  const expertSelectedCustomer = useQuery<IUser>(
    [UserCacheKeys.CustomerData],
    getUserData,
    {
      enabled: !!isExpert && !!getCustomerIdFromPath(),
      onSuccess: () => {
        afterFetch?.();
      },
      onError: (error) => {
        const status = _get(error, 'response.status');

        if (status === 401 && isExpert) {
          navigate('/');
        }
      },
    },
  );

  return isExpert ? expertSelectedCustomer : webCustomer;
};

export default useGetCurrentCustomerData;
