import { VariantButton } from 'components/atoms/Form/VariantButton';
import type { FC } from 'react';
import { getTracingEvents } from 'utils/amplitude';
import type { TracingConfig } from 'utils/amplitude/amplitude.types';

type Value = string | number | boolean;

export type VariantSelectionProps = {
  options: {
    label: any;
    value: Value;
    iconSrc?: string;
    isDisabled?: boolean;
  }[];
  value?: Value;
  onChange: (value: Value) => void;
  isDisabled?: boolean;
  tracing?: TracingConfig;
  buttonClassName?: string;
  iconClassName?: string;
};

const VariantSelection: FC<VariantSelectionProps> = ({
  options,
  value: selected,
  onChange,
  isDisabled: disabled,
  tracing,
  ...rest
}) => {
  const tracingEvents = getTracingEvents(tracing, { onChange });

  return (
    <>
      {options.map(({ value, label, iconSrc, isDisabled }) => (
        <VariantButton
          key={String(value)}
          disabled={disabled || isDisabled}
          selected={value === selected}
          iconSrc={iconSrc}
          onClick={() => {
            if (value !== selected) tracingEvents.onChange(value);
          }}
          {...rest}
        >
          <>{label}</>
        </VariantButton>
      ))}
    </>
  );
};

export default VariantSelection;
