import dayjs from 'dayjs';
import useShouldRecommendSMALLBusinessPlan from 'hooks/Growth/useShouldRecommendSMALLBusinessPlan';
import { useSubscriptionPlan } from 'hooks/Subscriptions';
import { get } from 'lodash';
import {
  createContext,
  useCallback,
  useReducer,
  type FC,
  type PropsWithChildren,
} from 'react';
import { type ISubscriptionPlan } from 'types/users.types';
import {
  isMonthlySubscription,
  isSubscriptionAmbassadorPlan,
  isSubscriptionTrialPlan,
} from 'utils/subscriptions.utils';

export enum BANNERS_STORAGE_KEYS {
  SMALL_PLAN_BANNER_DISMISS_KEY = 'dismissedSMALLPlanBanner',
  TRIAL_BANNER_DISMISS_KEY = 'dismissedTrialBanner',
  UPGRADE_YEARLY_DISMISS_KEY = 'dismissedUpgradeYearlyBanner',
  DOWNGRADE_BANNER_DISMISS_KEY = 'dismissedDowngradeBanner',
}

const banners = Object.keys(BANNERS_STORAGE_KEYS) as BANNERS_STORAGE_KEYS[];

export const AppBannersContext = createContext<{
  displaySMALLBanner: boolean;
  displayTRIALBanner: boolean;
  displayDowngradeBanner: boolean;
  displayUpgradeYearlyBanner: boolean;
  dismissBanner: (dismissKey: BANNERS_STORAGE_KEYS) => void;
}>({} as any);

const AppBannersProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const {
    SMALL_PLAN_BANNER_DISMISS_KEY,
    UPGRADE_YEARLY_DISMISS_KEY,
    TRIAL_BANNER_DISMISS_KEY,
    DOWNGRADE_BANNER_DISMISS_KEY,
  } = BANNERS_STORAGE_KEYS;
  const [state, dispatch] = useReducer(
    (prev: Record<string, any>, next: Record<string, any>) => ({
      ...prev,
      ...next,
    }),
    banners.reduce((all, key) => {
      const keyName = get(BANNERS_STORAGE_KEYS, [key]);
      return {
        ...all,
        [keyName]: !!localStorage.getItem(keyName),
      };
    }, {}),
  );
  const plan = useSubscriptionPlan();

  const daysUntilPlanExpires = dayjs(plan?.end).diff(dayjs(), 'd');

  const displayDowngradeBanner =
    !isSubscriptionAmbassadorPlan(plan?.planInternalName) &&
    daysUntilPlanExpires > 0 &&
    daysUntilPlanExpires <= 90 &&
    plan?.status?.autoRenew === false &&
    !state[DOWNGRADE_BANNER_DISMISS_KEY];

  const displayUpgradeYearlyBanner =
    isMonthlySubscription(plan as ISubscriptionPlan) &&
    !state[UPGRADE_YEARLY_DISMISS_KEY];

  const shouldRecommendSMALLBusinessPlan =
    useShouldRecommendSMALLBusinessPlan();

  const shouldShowTrialBanner = isSubscriptionTrialPlan(plan?.planInternalName);

  const dismissBanner = useCallback((dismissKey: BANNERS_STORAGE_KEYS) => {
    dispatch({ [dismissKey]: true });
    localStorage.setItem(dismissKey, 'true');
  }, []);

  return (
    <AppBannersContext.Provider
      value={{
        displayDowngradeBanner,
        displayUpgradeYearlyBanner,
        displaySMALLBanner:
          shouldRecommendSMALLBusinessPlan &&
          !state[SMALL_PLAN_BANNER_DISMISS_KEY],
        displayTRIALBanner:
          shouldShowTrialBanner && !state[TRIAL_BANNER_DISMISS_KEY],
        dismissBanner,
      }}
    >
      {children}
    </AppBannersContext.Provider>
  );
};

export default AppBannersProvider;
