import dayjs from 'dayjs';
import { CellsModifiers } from 'types/CellsModifiers.types';
import { ClientVATStatusEnum } from 'types/clients.types';
import type { Currency } from 'types/global.types';
import { CurrenciesEnum } from 'types/global.types';
import type { Invoice, InvoiceItem } from 'types/invoice.types';
import { InvoiceStatusEnum, InvoiceTypeEnum } from 'types/invoice.types';
import { CATEGORY_TAXES_AND_INSURANCE_VAT_REIMBURSEMENT } from 'utils/categories';

const invoice = {
  use_bank_reference: true,
  reversedHeader: true,
  withSteuernummer: true,
  defaultDueDate: dayjs().add(1, 'w').format('YYYY-MM-DD'),
  dateFormat: 'd MMM yyyy',
  displayDateFormat: 'D. MMM YYYY',
  VATStatusOverrides: {
    [ClientVATStatusEnum.franchisee]: {
      label: 'client.field.vat_franchisee.title.de',
      description: 'client.field.vat_franchisee.description.de',
    },
  },
  zero_vat_reasons: {
    de: ['noVAT', 'intraEU', 'extraEU', 'other'],
    eu: ['intraEU', 'noVAT', 'extraEU', 'other'],
    other: ['extraEU', 'noVAT', 'intraEU', 'other'],
  },
  vat_application_status: 'paid',
  vat_not_applicable_status: 'not_paid',
  required_customer_data_for_subscription: [
    'steuernummer',
    'address.street',
    'address.city',
    'address.zip',
  ],
  required_customer_data_for_invoice_creation: [
    'companyName',
    'steuernummer',
    'address.street',
    'address.city',
    'address.zip',
    'address.state',
    'IBAN',
  ],
  show_payment_amount: (
    currency: Currency,
    type: string,
    items: InvoiceItem[] = [],
  ): boolean =>
    type !== InvoiceTypeEnum.OTHER_REVENUE &&
    currency !== CurrenciesEnum.EUR &&
    !!items.length,
  show_period_field: (status: string): boolean => status === 'paid',
  is_applicable: (invoice: Invoice): boolean =>
    invoice.isValidated && invoice.status === 'paid',
  applicable_date_key: 'paymentDate',
  default_vat_rate: 0.19,
  default_reduced_vat_rate: 0.16,
  // reduced_vat_rates is only the unique rates that is not part of the normal rates
  // if you want to change it to include other rates you will need to take care about the places that uses this values
  reduced_vat_rates: [0.05, 0.16],
  blocked: {
    web: false,
    experts: false,
  },
  has_delivery_date: true,
  has_payment_date: true,
  has_streuernummer: true,
  due_date_days_after_invoice_date: 7,
  vat_rates: [0, 0.07, 0.19],
  list: {
    vat_inclusion_key: 'excl_vat',
    group_invoices: {
      web: 'isValidated',
      experts: 'reviewed',
    },
    cells: {
      _modifiers: [{ name: CellsModifiers.invoices }],
      first_group: {
        web: [
          {
            cellName: 'CLIENT',
            headerName: 'invoices.cells.invoices_and_credit_notes',
            headerNameVariation: 'invoices.cells.invoices_in_order',
          },
          'TYPE',
          'NUMBER',
          'DATE',
          'STATUS',
          'AMOUNT',
        ],
        experts: [
          {
            cellName: 'CLIENT',
            headerName: 'invoices.cells.invoices_and_credit_notes',
            headerNameVariation: 'invoices.cells.invoices_in_order',
          },
          'TYPE',
          'NUMBER',
          'DATE',
          'STATUS',
          'AMOUNT',
        ],
      },
      second_group: {
        web: [
          {
            cellName: 'CLIENT',
            headerName: 'invoices.cells.invoices_to_review',
          },
          'IMPORTED_DATE',
          'DATE',
          'STATUS',
          'AMOUNT',
        ],
        experts: [
          {
            cellName: 'CLIENT',
            headerName: 'invoices.cells.invoices_to_review',
          },
          'IMPORTED_DATE',
          'DATE',
          'STATUS',
          'AMOUNT',
        ],
      },
      default_sort: {
        web: 'invoiceDate_desc',
        experts: 'invoiceDate_desc',
      },
    },
  },
  deductible_tax_categories: [CATEGORY_TAXES_AND_INSURANCE_VAT_REIMBURSEMENT],
  shouldShowWarningEditingGeneratedDocument: (status: InvoiceStatusEnum) => {
    return InvoiceStatusEnum.paid === status;
  },
  showUBLOptions: false,
  getStatusDisabledOptions: (isLocked?: boolean) => {
    if (isLocked) {
      return {
        [InvoiceStatusEnum.notSent]: true,
        [InvoiceStatusEnum.sent]: true,
        [InvoiceStatusEnum.paid]: true,
      };
    }
    return {};
  },
};

export default invoice;
