import type { AvailableLanguages } from 'types/users.types';
import de from './de.json';
import en from './en.json';
import fr from './fr.json';
import nl from './nl.json';

export default {
  en,
  fr,
  nl,
  de,
} as Record<AvailableLanguages, Record<string, string>>;
