import { joiResolver } from '@hookform/resolvers/joi';
import { hasPayableTax } from 'api/v1/taxes';
import {
  createRevenue,
  deleteRevenue,
  getRevenueById,
  getRevenueDuplicates,
  patchLockedInvoice,
  patchRevenue,
  updateRevenue,
} from 'api/v2/revenues';
import classNames from 'classnames';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { FieldConnector } from 'components/atoms/Form/FieldConnector';
import { Modal } from 'components/atoms/Modal';
import { Tooltip } from 'components/atoms/Tooltip';
import Amount from 'components/molecules/CommonCells/Amount.component';
import CustomLoader from 'components/molecules/CustomLoader.component';
import { InvoiceLockedByAccountant } from 'components/molecules/DocumentLockByAccountant.compopnent';
import { InvoiceLockByTaxes } from 'components/molecules/DocumentLockByTaxes.compopnent';
import { Helper } from 'components/molecules/Helper';
import PassedVATThreshold from 'components/molecules/PassedVATThreshold.component';
import SimpleError from 'components/molecules/SimpleError.component';
import type { SlideOver2Props } from 'components/molecules/SlideOver2.component';
import SlideOver2 from 'components/molecules/SlideOver2.component';
import { DocumentDuplicateFinder } from 'components/organisms/DocumentDuplicateFinder';
import { maybeAskForTrustpilotReview } from 'components/organisms/TrustpilotReview';
import AttachmentController from 'components/pages/Expenses/Form/AttachmentController';
import FileViewer from 'components/pages/Expenses/Form/FlieViewer';
import dayjs from 'dayjs';
import { AnimatePresence } from 'framer-motion';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useVATReturnFrequency from 'hooks/shared/useVATReturnFrequency';
import { worldThemeContext } from 'hooks/shared/useWorldTheme';
import useConfirm from 'hooks/useConfirm';
import useCustomNavigate from 'hooks/useCustomeNavigate';
import useFeatures from 'hooks/useFeatures';
import type {
  CloseMeta,
  FormSlideOverManagerOptions,
} from 'hooks/useFormSlideOverManager';
import {
  forceClose,
  naturalClose,
  useFormSlideOverManager,
} from 'hooks/useFormSlideOverManager';
import { HighlightProvider } from 'hooks/useHighlight';
import useModal from 'hooks/useModal';
import useUpdateSidebarDocCounts from 'hooks/useUpdateSidebarDocCounts';
import { isEmpty } from 'lodash';
import type { FC } from 'react';
import { useId, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { SidebarDocCountsKeysEnum } from 'store/sidebarState';
import { RevenuesCacheKeys } from 'types/cacheKeys.types';
import { IClient } from 'types/clients.types';
import type { LockStatusType, Period } from 'types/global.types';
import type {
  Invoice,
  InvoiceFormValues,
  InvoiceItem,
} from 'types/invoice.types';
import { InvoiceTypeEnum } from 'types/invoice.types';
import { InvoiceFileType, RevenueStatus } from 'types/revenues.types';
import type { IUser } from 'types/users.types';
import * as RevenuesEvents from 'utils/amplitude/events/revenues.amplitude';
import { applySuggestedChanges } from 'utils/applySuggestedChanges';
import { isWeb } from 'utils/constants';
import formatMoney from 'utils/formatMoney';
import { isDocumentLockedByTaxesOrAccountant } from 'utils/helpers';
import {
  ClockIconOutline,
  CopyOutlineIcon,
  EditOutlineIcon,
  PlusIconOuline,
  RefreshIconOutline,
  TrashIcon,
} from 'utils/icons';
import { divideBy1000 } from 'utils/math';
import { ONE_SECOND } from 'utils/time';
import { prepareRecurringInvoiceFromSavedInvoice } from '../GeneratedRevenueForm/RecurringInvoiceForm/helpers';
import Form from './Form';
import RevenueLogs from './RevenueLogs.component';
import useFormMeta from './useFormMeta';
import {
  adaptDocForDuplication,
  cleanInvoiceValues,
  formatInvoiceValues,
  GeneratedDocumentEditWarning,
  getDefaultValues,
  getInvoiceItemsTotalInclVAT,
  OtherRevenueIncodeWarning,
} from './utils';
import { getValidationSchema } from './validationSchema';

export type InvoiceInput = string | Partial<Invoice>;

type ExtraFormWrapperProps = {
  afterSubmit?: (result: any, originalDoc: any) => void;
  afterDelete?: () => void;
  disabledFields?: string[];
  shouldUnitPriceIncludeVATByDefault?: boolean;
  onClose: (meta: CloseMeta) => void;
  onEditVehicles?: () => void;
};

export type InvoiceFormWrapperProps = {
  revenue: InvoiceInput;
  onChangeRevenue: (_id: string) => void;
  isFormDirtyRef: any;
} & ExtraFormWrapperProps &
  Partial<Omit<SlideOver2Props, 'onClose'>>;

const unSavedChangesDefaultSettings = {
  className: 'text-center font-semibold',
  buttonsWrapperClassName: 'justify-center',
  buttonClassName: 'min-w-[90px]',
};

const FormWrapper: FC<InvoiceFormWrapperProps> = ({
  revenue,
  onClose,
  onChangeRevenue,
  isFormDirtyRef,
  afterSubmit,
  afterDelete,
  disabledFields,
  shouldUnitPriceIncludeVATByDefault,
  onEditVehicles,
}) => {
  const { t } = useTranslation();

  const navigate = useCustomNavigate();

  const globalVATFrequency = useVATReturnFrequency();

  const { data } = useGetCurrentCustomerData();
  const customer = data as IUser;
  const { VATType: globalVATTyype, country: customerCountry } = customer;

  const deductibleTaxCategoriesSlugs: string[] = useFeatures(
    'revenue.deductible_tax_categories',
  );

  const shouldShowWarningOnEdit = useFeatures(
    'revenue.shouldShowWarningEditingGeneratedDocument',
  );

  const [confirmContent, confirmApi] = useConfirm();
  const [modal, modalApi] = useModal();

  const metaAPI = useFormMeta();
  const { getSuggestedChanges } = metaAPI;

  const formAPI = useForm({
    mode: 'onChange',
    defaultValues: {} as any, // we depend on it to be empty object on the start so we know its not ready yet
    resolver: joiResolver(getValidationSchema(metaAPI), {
      allowUnknown: true,
      abortEarly: false,
    }),
  });

  const [showAttachment, setShowAttachment] = useState(false);

  const [generatedDefaultValues, setGeneratedDefaultValues] =
    useState<InvoiceFormValues>();

  const revenueAPI = useQuery(
    [RevenuesCacheKeys.RevenueDetails, useId()],
    () => {
      if (typeof revenue !== 'string') return revenue;
      return getRevenueById(revenue);
    },
    {
      cacheTime: 0,
      onSuccess: (data) => {
        const _initValues = getDefaultValues(
          data,
          globalVATTyype,
          globalVATFrequency,
        );

        setGeneratedDefaultValues(_initValues);

        formAPI.reset(
          applySuggestedChanges(_initValues, getSuggestedChanges(_initValues)),
        );

        setShowAttachment(!!formAPI.getValues('filePath'));
      },
    },
  );

  const isLoading = revenueAPI.isLoading;
  const isError = revenueAPI.isError;
  const retry = () => {
    if (revenueAPI.isError) revenueAPI.refetch();
  };

  const isDirty = !isEmpty(formAPI.formState.dirtyFields);

  const isEdit = !!revenueAPI.data?._id;

  const [filePath, fileType, revenueNumber, items, currency] = formAPI.watch([
    'filePath',
    'fileType',
    'revenueNumber',
    'items',
    'currency',
  ]);

  const reviewStatus = revenueAPI.data?.accountantReview?.reviewStatus;

  const updateSidebarCounts = useUpdateSidebarDocCounts();

  const isCreditNote = revenueAPI.data?.type === InvoiceTypeEnum.CREDIT_NOTE;
  const isInvoice = revenueAPI.data?.type === InvoiceTypeEnum.INVOICE;
  const isOtherRevenue =
    revenueAPI.data?.type === InvoiceTypeEnum.OTHER_REVENUE;
  const isGenerated = fileType === InvoiceFileType.generated;

  const isLockedByAccountant = isWeb && reviewStatus === 'reviewed';
  const isLockedByTaxes = !!revenueAPI.data?.taxLock?.length;

  const isLocked = isLockedByAccountant || isLockedByTaxes;

  const allItems = (items || []) as InvoiceItem[];

  const totalAmountInclVAT = getInvoiceItemsTotalInclVAT(allItems);

  useImperativeHandle(isFormDirtyRef, () => isDirty, [isDirty]);

  const isGeneratedInvoiceOrCreditNote =
    isEdit && (isCreditNote || isInvoice) && isGenerated;

  const onSubmit = async () => {
    const formValues = cleanInvoiceValues(formAPI.getValues()) as Invoice;

    const hasStatusChanged = revenueAPI.data?.status !== formValues.status;
    const markedAsPaid =
      hasStatusChanged && formValues.status === RevenueStatus.paid;

    let values = formatInvoiceValues(formValues) as Invoice;

    // editing an invoice that is recurring

    // if locked, skip all checks, since we are only updating simple fields like the notes or the transactions
    if (!isLocked) {
      const withDeductibleTaxCategoryItem = values.items.find(
        (item) => !!deductibleTaxCategoriesSlugs.includes(item.categoryId),
      );
      if (withDeductibleTaxCategoryItem) {
        const { isPayable } = await hasPayableTax(
          withDeductibleTaxCategoryItem.categoryId,
          values.taxPeriod as Period,
          customer.country,
        );
        if (isPayable) {
          const shouldContinue = await new Promise((resolve) => {
            confirmApi.open({
              message: t(
                'transactions.mark_tax_done.VATReimbursement.confirmation',
              ),
              okText: t('yes'),
              cancelText: t('no'),
              buttonsWrapperClassName: 'justify-end',
              modalOptions: {
                width: 'xl',
                name: 'update revenue category should mark tax item as done',
                onClose: () => resolve(false),
              },
              onConfirm: () => {
                values = { ...values, shouldUpdateTaxStatus: true };
                resolve(true);
              },
              onDecline: () => {
                values = { ...values, shouldUpdateTaxStatus: false };
                resolve(true);
              },
            });
          });
          if (!shouldContinue) return;
        }
      }
    }

    return Promise.resolve()
      .then(() => {
        if (isEdit) {
          if (isLocked) {
            return patchLockedInvoice(values._id as string, values);
          }
          if (isGeneratedInvoiceOrCreditNote) {
            return patchRevenue(values._id as string, {
              ...values,
              paymentType: values.paymentType || null,
            });
          }
          return updateRevenue(values._id as string, values);
        }
        return createRevenue(values).then((data) => {
          updateSidebarCounts(SidebarDocCountsKeysEnum.NewRevenuesCount);

          return data;
        });
      })
      .then((result) => {
        if (markedAsPaid) maybeAskForTrustpilotReview();
        return Promise.resolve(
          afterSubmit?.(result as unknown as Invoice, revenueAPI.data),
        )
          .catch()
          .finally(() => {
            onClose?.(forceClose);
          });
      });
  };

  const onDelete = (id: string): Promise<boolean> => {
    return new Promise((resolve) => {
      confirmApi.open({
        ...unSavedChangesDefaultSettings,
        message: t('invoices.confirm_delete'),
        onConfirm: () => deleteRevenue(id).then(() => resolve(true)),
        onDecline: () => {
          confirmApi.close();
          resolve(false);
        },
      });
    });
  };

  const onAttachmentUploaded = ({ key }: any) => {
    formAPI.setValue('fileName', null);
    formAPI.setValue('filePath', key, { shouldValidate: true });
    formAPI.setValue('fileType', 'imported');
    setShowAttachment(true);
  };

  const onEdit = () => {
    if (!shouldShowWarningOnEdit(revenueAPI.data?.status)) {
      return navigate(`/revenues/${revenueAPI.data?._id}`);
    }
    modalApi.open(
      () => (
        <GeneratedDocumentEditWarning
          document={revenueAPI.data as Invoice}
          onCancel={modalApi.close}
        />
      ),
      {
        dialogPanalClassName: '!p-0',
        name: `editing a generated ${revenueAPI.data?.type}`,
      },
    );
  };

  const onDuplicate = () => {
    navigate('/revenues/create', {
      state: {
        guessedDocument: adaptDocForDuplication(revenueAPI.data as Invoice),
      },
    });
  };

  return (
    <>
      {modal}
      {confirmContent}
      <SlideOver2
        showLeftPanelUI={showAttachment}
        rightPanelCoverContent={
          <p className="absolute bottom-[20%] text-xl font-avenir font-bold text-center px-10">
            {t('invoices.already_validated_by_accountant')}
          </p>
        }
        onClose={() => onClose?.(naturalClose)}
        renderLeftPanel={() => {
          if (!filePath) return null;
          return (
            <FileViewer
              floatingItemsMargin="var(--rightPaneWidth)"
              tracing={{
                onDownload: RevenuesEvents.WEBAPP_REVENUE_CLICKEDDOWNLOAD,
                onReplace: RevenuesEvents.WEBAPP_REVENUE_CLICKEDREPLACE,
                onDelete: RevenuesEvents.WEBAPP_REVENUE_CLICKEDDELETEATTACHMENT,
                onEdit: RevenuesEvents.WEBAPP_REVENUE_CLICKEDEDIT,
                onDuplicate: RevenuesEvents.WEBAPP_REVENUE_CLICKEDDUPLICATE,
              }}
              filePath={filePath}
              onReplace={onAttachmentUploaded}
              onDelete={() => {
                formAPI.setValue('fileName', null);
                formAPI.setValue('filePath', null, {
                  shouldValidate: true,
                });

                if (revenueAPI.data?.type === InvoiceTypeEnum.OTHER_REVENUE) {
                  formAPI.setValue('fileType', 'generated');
                }

                setShowAttachment(false);
              }}
              onEdit={
                // !isLocked &&
                isGeneratedInvoiceOrCreditNote ? onEdit : null
              }
              onDuplicate={isGeneratedInvoiceOrCreditNote ? onDuplicate : null}
              disabled={isLocked || (isEdit && isGeneratedInvoiceOrCreditNote)}
            />
          );
        }}
        renderRightPanel={() => {
          if (isLoading) {
            return <CustomLoader className="h-full" />;
          }
          if (isError) {
            return <SimpleError messageClassName="!text-xl" onRetry={retry} />;
          }
          if (
            !revenueAPI.data ||
            isEmpty(formAPI.getValues()) ||
            !generatedDefaultValues
          ) {
            return null;
          }
          return (
            <div>
              <Form
                formAPI={formAPI}
                revenue={revenueAPI.data}
                generatedDefaultValues={generatedDefaultValues}
                isFormDirty={isDirty}
                onSubmit={onSubmit}
                disabledFields={isLocked ? '*' : disabledFields}
                notDisabledFields={
                  isLocked
                    ? [
                        'transactions',
                        'notes',
                        'comments',
                        'status' /* status is handled in a more complex way using a hook in th form */,
                      ]
                    : []
                }
                isLocked={isLocked}
                onEditVehicles={onEditVehicles}
                shouldUnitPriceIncludeVATByDefault={
                  shouldUnitPriceIncludeVATByDefault
                }
                headerMeta={
                  <>
                    <div className="relative mt-8">
                      <PassedVATThreshold />
                      {isLockedByTaxes && (
                        <InvoiceLockByTaxes
                          taxLock={revenueAPI.data.taxLock as LockStatusType}
                          type={revenueAPI.data.type as InvoiceTypeEnum}
                        />
                      )}
                      {isLockedByAccountant && !isLockedByTaxes && (
                        <InvoiceLockedByAccountant
                          type={revenueAPI.data.type as InvoiceTypeEnum}
                        />
                      )}
                      {isOtherRevenue && <OtherRevenueIncodeWarning />}
                      {!!revenueAPI.data._id &&
                        revenueAPI.data.userSnapshot?.VATType !==
                          customer.VATType && (
                          <Alert
                            type="warning"
                            name="diff vat type warning"
                            showIntercomBtn
                            description={
                              <div className="flex gap-4">
                                <span>
                                  {t(
                                    'document.warning_bubble.diff_vattype.title',
                                    {
                                      snapshotVATType: t(
                                        'VATType.' +
                                          revenueAPI.data.userSnapshot?.VATType,
                                      ),
                                      currentVATType: t(
                                        'VATType.' + customer.VATType,
                                      ),
                                    },
                                  )}
                                </span>
                                <Helper>
                                  <Helper.Paragraph>
                                    {t(
                                      'document.warning_bubble.diff_vattype.helper',
                                    )}
                                  </Helper.Paragraph>
                                </Helper>
                              </div>
                            }
                          />
                        )}
                      <h1 className="font-avenir text-5xl text-primary-700 font-bold first-letter:capitalize tracking-tight leading-none truncate mt-5 mb-5">
                        {(() => {
                          if (revenueAPI.data.type === InvoiceTypeEnum.INVOICE)
                            return t('invoice.field.title', {
                              number: revenueNumber,
                            });
                          if (
                            revenueAPI.data.type === InvoiceTypeEnum.CREDIT_NOTE
                          )
                            return t(`invoices.credit_note.${customerCountry}`);
                          return t('other_revenue.field.title', {
                            number: revenueNumber,
                          });
                        })()}
                      </h1>
                      <div className="flex items-center justify-between">
                        <FieldConnector name="filePath">
                          {() => (
                            <div className="flex items-center gap-2">
                              {
                                <>
                                  <AttachmentController
                                    onHide={() => setShowAttachment(false)}
                                    onShow={() => setShowAttachment(true)}
                                    onUploaded={onAttachmentUploaded}
                                    isShow={showAttachment}
                                    hasFile={!!filePath}
                                    disabled={isLocked}
                                  />

                                  <span className="text-primary-400">
                                    {'/'}
                                  </span>
                                </>
                              }

                              <span className="flex gap-1 text-primary-700">
                                <Amount
                                  amount={
                                    isCreditNote
                                      ? totalAmountInclVAT * -1
                                      : totalAmountInclVAT
                                  }
                                  currency={currency}
                                />
                                {t('incl_vat')}
                              </span>
                            </div>
                          )}
                        </FieldConnector>

                        <div className="flex gap-2">
                          {
                            // !isLocked &&
                            isGeneratedInvoiceOrCreditNote && (
                              <Tooltip content={t('edit')}>
                                <Button
                                  highlightID="edit-invoice"
                                  structure="text"
                                  tracingEvent={
                                    RevenuesEvents.WEBAPP_REVENUE_CLICKEDEDIT
                                  }
                                  onClick={onEdit}
                                >
                                  <EditOutlineIcon className="w-6" />
                                </Button>
                              </Tooltip>
                            )
                          }
                          {isGeneratedInvoiceOrCreditNote &&
                            globalVATTyype ===
                              revenueAPI.data?.userSnapshot?.VATType && (
                              <Tooltip
                                content={t('create_recurrence_tooltip')}
                                placement="topRight"
                                hoverable={false}
                              >
                                <Button
                                  structure="text"
                                  tracingEvent={
                                    RevenuesEvents.WEBAPP_REVENUE_MAKERECURRINGFORMPANEL
                                  }
                                  onClick={(e) => {
                                    const partialDoc =
                                      prepareRecurringInvoiceFromSavedInvoice(
                                        revenueAPI.data as Invoice<IClient>,
                                      );
                                    navigate('/recurring-revenues/create', {
                                      state: {
                                        guessedDocument: partialDoc,
                                      },
                                    });
                                  }}
                                >
                                  <RefreshIconOutline className="w-5" />
                                </Button>
                              </Tooltip>
                            )}
                          {isGeneratedInvoiceOrCreditNote && (
                            <Tooltip
                              hoverable={false}
                              content={t('duplicate')}
                              placement="topRight"
                            >
                              <Button
                                structure="text"
                                tracingEvent={
                                  RevenuesEvents.WEBAPP_REVENUE_CLICKEDDUPLICATE
                                }
                                onClick={onDuplicate}
                              >
                                <CopyOutlineIcon className="w-6" />
                              </Button>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                    {isEdit && (
                      <div className="relative z-10 pointer-events-auto">
                        <DocumentDuplicateFinder
                          onFetch={() =>
                            getRevenueDuplicates(revenueAPI.data._id as string)
                          }
                          renderLabel={(duplicate) =>
                            `${duplicate.revenueNumber || t('unknown')}: ${
                              duplicate.client?.name || t('unknown')
                            }, ${formatMoney(
                              divideBy1000(duplicate.totalAmountInclVAT || 0),
                            )} ${
                              dayjs(duplicate.invoiceDate).format(
                                'DD/MM/YYYY',
                              ) || t('unknown')
                            }`
                          }
                          viewText={t('invoice.view')}
                          deleteText={t('invoice.delete')}
                          onSelect={(_id) => onChangeRevenue(_id)}
                          getShouldShowDeleteButton={(dup) =>
                            !isDocumentLockedByTaxesOrAccountant(dup)
                          }
                          onDelete={(duplicate) =>
                            onDelete(duplicate._id).then((isDeleted) => {
                              if (isDeleted) afterDelete?.();
                              return isDeleted;
                            })
                          }
                        />
                      </div>
                    )}
                    <div className="border-b border-primary-100" />
                  </>
                }
                footerMeta={
                  <div
                    className={classNames(
                      'flex flex-col gap-3 border-primary-100 text-primary-700 text-sm leading-tight',
                      {
                        'border-t pt-4 mt-3': !isGenerated,
                      },
                    )}
                  >
                    {/* {!!revenueAPI.data.emailSeenAt && (
                      <div className="flex items-center gap-2">
                        <div className="p-1 rounded-full bg-primary-50">
                          <img src={mail} className="w-4 h-4" />
                        </div>
                        <p className="first-letter:capitalize text-sm">
                          {t('document_email_open_date', {
                            docType: isCreditNote
                              ? t('invoices.credit_note.' + customerCountry)
                              : t('invoice.invoice'),
                            date: dayjs(revenueAPI.data.emailSeenAt).format(
                              'MMM D, YYYY',
                            ),
                          })}
                        </p>
                      </div>
                    )} */}
                    {/* {!!revenueAPI.data.emailSentAt && (
                      <div className="flex items-center gap-2">
                        <div className="p-1 rounded-full bg-primary-50">
                          <img src={send} className="w-4 h-4" />
                        </div>
                        <p className="first-letter:capitalize text-sm">
                          {t('document_email_sent_date', {
                            docType: isCreditNote
                              ? t('invoices.credit_note.' + customerCountry)
                              : t('invoice.invoice'),
                            date: dayjs(revenueAPI.data.emailSentAt).format(
                              'MMM D, YYYY',
                            ),
                          })}
                        </p>
                      </div>
                    )} */}
                    {!isGeneratedInvoiceOrCreditNote && (
                      <div className="flex items-center gap-2">
                        <div className="p-1 rounded-full bg-primary-50">
                          <PlusIconOuline className="w-4 h-4" />
                        </div>
                        <p className="first-letter:capitalize text-sm">
                          {t(
                            revenueAPI.data.createdFrom
                              ? 'creation_method_and_date'
                              : 'creation_date',
                            {
                              type: t(
                                revenueAPI.data.fileType === 'imported'
                                  ? 'imported'
                                  : 'created',
                              ),
                              createdFrom: t(
                                revenueAPI.data.createdFrom as string,
                              ),
                              date: dayjs(revenueAPI.data.created).format('ll'),
                            },
                          )}
                        </p>
                      </div>
                    )}

                    {!!revenueAPI.data._id && isGeneratedInvoiceOrCreditNote && (
                      <Modal
                        name="revenueLogsModal"
                        width="4xl"
                        trigger={(api) => (
                          <Button
                            structure="text"
                            className="self-start mt-6"
                            onClick={api.openModal}
                            tracingEvent={
                              RevenuesEvents.WEBAPP_REVENUE_CLICKEDVIEWLOGS
                            }
                          >
                            <ClockIconOutline className="w-5 mr-2" />
                            <span className="font-semibold">
                              {t('invoice_logs.view')}
                            </span>
                          </Button>
                        )}
                        render={() => (
                          <RevenueLogs
                            id={revenueAPI.data._id!}
                            invoiceNumber={revenueAPI.data.revenueNumber!}
                          />
                        )}
                      />
                    )}

                    {!isLocked && isEdit && (
                      <Button
                        structure="text"
                        className="self-start mt-6"
                        tracingEvent={
                          RevenuesEvents.WEBAPP_REVENUE_CLICKEDDELETE
                        }
                        onClick={() =>
                          onDelete(revenueAPI.data._id as string).then(
                            (isDeleted) => {
                              if (isDeleted) {
                                onClose?.(naturalClose);
                                afterDelete?.();
                              }
                            },
                          )
                        }
                      >
                        <TrashIcon className="w-5 mr-2" />
                        <span className="font-semibold">{t('delete')}</span>
                      </Button>
                    )}
                  </div>
                }
              />
            </div>
          );
        }}
      />
    </>
  );
};

type RevenueFormSlideOverProps = FormSlideOverManagerOptions<InvoiceInput> &
  Omit<ExtraFormWrapperProps, 'onClose'>;

const RevenueFormSlideOver: FC<RevenueFormSlideOverProps> = ({
  apiRef,
  onClose,
  onOpen,
  disabledFields,
  afterSubmit,
  afterDelete,
  onEditVehicles,
  enableNavigationBlockingIfDirty,
}) => {
  const { doc, api, isFormDirtyRef, confirmContent, options, formKey } =
    useFormSlideOverManager<InvoiceInput>({
      enableNavigationBlockingIfDirty,
      apiRef,
      onClose,
      onOpen,
    });
  const _options = options as Partial<InvoiceFormWrapperProps> | undefined;

  const _afterSubmit = _options?.afterSubmit || afterSubmit;

  return (
    <worldThemeContext.Provider value="revenues">
      {confirmContent}
      <AnimatePresence mode="wait">
        {!!doc && (
          <HighlightProvider
            key={formKey}
            unHighlightTimer={2 * ONE_SECOND}
            className="animate-taxesPulse rounded-lg"
          >
            <FormWrapper
              key={formKey}
              revenue={doc}
              onClose={api.close}
              onChangeRevenue={api.open}
              isFormDirtyRef={isFormDirtyRef as any}
              afterDelete={afterDelete}
              disabledFields={disabledFields}
              onEditVehicles={onEditVehicles}
              {..._options}
              afterSubmit={(...args) => {
                if (!_afterSubmit) return;
                api.pauseDirtyTracking();
                api.pauseNavigationBlocking();
                return Promise.resolve(_afterSubmit(...args)).finally(() => {
                  api.resumeDirtyTracking();
                  api.resumeNavigationBlocking();
                });
              }}
            />
          </HighlightProvider>
        )}
      </AnimatePresence>
    </worldThemeContext.Provider>
  );
};
export default RevenueFormSlideOver;
