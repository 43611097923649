import type SVGType from 'types/svg.types';

const HelpIcon: SVGType = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.303 13.9997C25.1404 15.028 25.6439 16.41 25.6439 18.173C25.6439 19.8212 25.2038 21.1365 24.4623 22.141C24.1933 22.5054 24.25 23.3548 24.465 23.7534C24.9794 24.7075 24.0074 25.753 22.9509 25.5108C22.16 25.3295 21.3305 25.0842 20.6046 24.7551C20.3388 24.6347 20.0453 24.5861 19.7554 24.6189C19.3951 24.6596 19.0275 24.6798 18.6552 24.6798C16.9275 24.6798 15.2997 24.2457 14.0505 23.333M20.3104 12.8397C20.3104 17.3271 17.0483 19.3464 13.3217 19.3464C12.9494 19.3464 12.5818 19.3263 12.2215 19.2855C11.9316 19.2527 11.6381 19.3013 11.3723 19.4218C10.6464 19.7509 9.81687 19.9962 9.02604 20.1774C7.96951 20.4196 6.99749 19.3741 7.51195 18.4201C7.72692 18.0214 7.78359 17.1721 7.51461 16.8077C6.77308 15.8032 6.33301 14.4879 6.33301 12.8397C6.33301 8.35233 9.59504 6.33301 13.3217 6.33301C17.0483 6.33301 20.3104 8.35233 20.3104 12.8397Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export default HelpIcon;
