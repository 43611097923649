import RCTooltip from 'rc-tooltip';
import type { FC } from 'react';
import { useFocusVisible } from 'react-aria';
import './tooltip.scss';
import type { TooltipProps } from './Tooltip.types';

// new tooltip component we might use it instead of the current one.
import classNames from 'classnames';
import type { ITooltip } from 'react-tooltip';
import { Tooltip as Tooltip2 } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  style,
  overlayClassName,
  hoverable = true,
  ...rest
}) => {
  const { isFocusVisible } = useFocusVisible();

  if (!content) return <>{children}</>;
  return (
    <RCTooltip
      overlay={content}
      placement="top"
      trigger={isFocusVisible ? ['hover', 'focus'] : ['hover']}
      overlayClassName={classNames(
        'font-semibold text-sm max-w-[250px]',
        style === 'light'
          ? 'rc-tooltip-light'
          : style === 'white'
          ? 'rc-tooltip-white'
          : 'rc-tooltip-dark',
        hoverable ? '' : '!pointer-events-none',
        overlayClassName,
      )}
      destroyTooltipOnHide
      {...rest}
    >
      {children}
    </RCTooltip>
  );
};

export const renderTooltip = (
  props: ITooltip & { overrideClassName?: string },
) => {
  return (
    <Tooltip2
      place="top"
      {...props}
      className={
        props.overrideClassName ||
        classNames(
          '!text-sm !font-semibold !p-2 !bg-[#374151] max-w-[300px] rounded-lg',
          props.className,
        )
      }
    />
  );
};

export default Tooltip;
