import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useFeatures from 'hooks/useFeatures';
import { useTranslation } from 'react-i18next';
import {
  ExpenseCategoryType,
  SupplierCountriesEnum,
} from 'types/expense.types';
import type { IExpense, IExpenseItem } from 'types/expenses.types';
import {
  ExpenseWhyZeroVAT,
  TripType,
  TripTypeIds,
  TripVehicleType,
} from 'types/expenses.types';
import type { IUser } from 'types/users.types';
import {
  AccountTypeEnum,
  AvailableCountries,
  VATTypeEnum,
} from 'types/users.types';
import {
  CATEGORY_BANK_CHARGES,
  CATEGORY_DIRECTORS_COMPENSATION,
  CATEGORY_HEALTH_INSURANCE,
  CATEGORY_INTEREST_AND_BANK_CHARGES,
  CATEGORY_IT_SERVICE,
  CATEGORY_LUNCH_VOUCHERS,
  CATEGORY_LUNCH_VOUCHERS_ADMIN,
  CATEGORY_OTHER_PERSONNEL_FEES,
  CATEGORY_TRAVELS_FLIGHTS,
  CATEGORY_TRAVEL_FOR_EMPLOYEE_FLIGHTS,
  CATEGORY_TRAVEL_FOR_SELF_EMPLOYED_FLIGHTS,
  CATEGORY_WORKERS_COMPENSATION,
  CATEGORY_WORKPLACE_RENT_HOMELUMPSUM,
  CATEGORY_WORKPLACE_RENT_HOME_OFFICE_SECONDARY,
  SELF_EMPLOYED_CAR_PRIVATE_USE,
  workFromHomeCategories,
} from 'utils/categories';
import { isExpert } from 'utils/constants';
import { calculateVATAmount } from 'utils/expenses/helper';
import getFieldState from 'utils/getFieldState';
import { getShortAccountType } from 'utils/helpers';
import { isMultipleOf2, round } from 'utils/math';
import {
  doesTenDayRuleApplies,
  isBusinessCar,
  isUtilityVehicle,
  isVehicleFineCategory,
  utilityVehicleWarning,
} from './utils';

const flightsCategories = [
  CATEGORY_TRAVEL_FOR_SELF_EMPLOYED_FLIGHTS,
  CATEGORY_TRAVEL_FOR_EMPLOYEE_FLIGHTS,
  CATEGORY_TRAVELS_FLIGHTS,
];

const today = dayjs();

const useFormMeta = () => {
  const customer = useGetCurrentCustomerData().data as IUser;

  const { t } = useTranslation();

  const {
    country: customerCountry,
    accountType,
    VATNumber: userVATNumber,
  } = customer as IUser;

  const isCustomerBE = customerCountry === AvailableCountries.belgium;
  const isCustomerDE = customerCountry === AvailableCountries.germany;

  const isCustomerOnePersonCompany = accountType === 'one_person_company';

  const deductibleTaxCategories = useFeatures(
    'expenses.deductible_tax_categories',
  );
  const vehicleCategoryIds = useFeatures('expenses.vehicleCategoryIds');
  const isAmortizationFn = useFeatures('expenses.is_amortization');

  const maxVATRate = useFeatures('expenses.max_vat_rate') as number;

  const isCustomerNaturalPerson =
    isCustomerDE ||
    getShortAccountType(customer.accountType) ===
      AccountTypeEnum.natural_person;

  const getFormMeta = (formValues: Partial<IExpense>) => {
    // test private cars
    const germanPrivateCarItem = (formValues.items || []).find(
      (item) => item.categoryId === SELF_EMPLOYED_CAR_PRIVATE_USE,
    );

    const isGermanPrivateCar = !!germanPrivateCarItem;

    const VATType = formValues.user?.VATType;

    const isCustomerSubjectToVAT = VATType === VATTypeEnum.subjectToVAT;

    const isDeductible = !!formValues.items?.some((item) =>
      deductibleTaxCategories.includes(item.categoryId),
    );

    const supplierCountry = formValues.supplier?.country as any;
    const isSupplierLocal = supplierCountry === customerCountry;
    const isSupplierEU = supplierCountry === SupplierCountriesEnum.eu;
    const isSupplierOther = supplierCountry === SupplierCountriesEnum.other;

    const hasAnyServiceItems = !!formValues.items?.some(
      (item) => item.category?.type === ExpenseCategoryType.SERVICE,
    );

    const hasEveryItemWithVATAmount0 = !!formValues.items?.every(
      ({ VATAmount }) => VATAmount === 0,
    );

    const hasEveryItemWithZeroVATReverseCharge = !!formValues.items?.every(
      ({ whyZeroVAT }) => whyZeroVAT === ExpenseWhyZeroVAT.reverseCharge,
    );

    const hasEveryItemWithVATAmount1 = !!formValues.items?.every(
      ({ VATAmount }) => VATAmount === 1,
    );

    const hasEveryItemWithNoZeroVAT = !!formValues.items?.every(
      ({ whyZeroVAT }) => whyZeroVAT == null,
    );

    const hasAnyZeroVatReveseChargeItems = !!formValues.items?.some(
      ({ whyZeroVAT }: any) => whyZeroVAT === ExpenseWhyZeroVAT.reverseCharge,
    );

    // const hasEveryItemWithDisallowedVATCategory = !!formValues.items?.every(({ category }) => category?.allowsVAT === false);

    const isInvoiceField = getFieldState({
      name: 'isInvoice',
      value: () => !!formValues.isInvoice,
      isHidden:
        isCustomerDE ||
        isDeductible ||
        !isCustomerSubjectToVAT ||
        !isSupplierLocal,
      suggestedChanges: ({ name, isHidden }) => {
        if (isHidden) {
          if (isCustomerDE) return [{ name, value: isCustomerSubjectToVAT }];
          return [{ name, value: false }];
        }
      },
    });

    const supplierField = getFieldState({
      name: 'supplier',
      value: () => formValues.supplier,
      isHidden: isGermanPrivateCar,
      suggestedChanges: ({ name }) => {
        if (isGermanPrivateCar) {
          return [
            {
              name,
              value: {
                country: customerCountry,
                name:
                  germanPrivateCarItem.tripType &&
                  t(
                    `expense.form.destination.${
                      TripTypeIds[germanPrivateCarItem.tripType]
                    }`,
                  ),
              },
            },
          ];
        }
      },
    });

    // virtual
    const isThereVATOnInvoiceField = getFieldState<boolean | null>({
      name: 'isThereVATOnInvoice',
      value: (self) => {
        if (!self.isApplicable) return null;
        const fieldValue = formValues.isThereVATOnInvoice;
        if (typeof fieldValue === 'boolean') return fieldValue;
        // if no value found on the form we infer it from the data or just force a default one
        if (
          hasEveryItemWithVATAmount0 &&
          hasEveryItemWithZeroVATReverseCharge
        ) {
          return false;
        }

        if (
          isSupplierEU &&
          hasEveryItemWithVATAmount1 &&
          hasEveryItemWithNoZeroVAT
        ) {
          return true;
        }

        if (isSupplierOther && hasEveryItemWithNoZeroVAT) {
          return true;
        }

        return false;
      },
      isApplicable:
        !isDeductible &&
        (isSupplierEU || (isSupplierOther && hasAnyServiceItems)),
    });

    const useIsThereVATEU =
      isThereVATOnInvoiceField.isApplicable && isSupplierEU;
    const transactionsField = getFieldState({
      name: 'transactions',
      value: () => formValues.transactions,
      isApplicable: !isGermanPrivateCar,
      suggestedChanges: ({ isApplicable, name }) => {
        if (!isApplicable) return [{ name, value: [] }];
      },
    });

    const periodField = getFieldState({
      name: 'period',
      value: () => formValues.period,
      isApplicable: isCustomerSubjectToVAT,
    });

    const taxPeriodField = getFieldState({
      name: 'taxPeriod',
      value: () => formValues.taxPeriod,
      isHidden: isCustomerDE,
      isApplicable: isDeductible,
    });

    const isAL1guess = !!formValues.guessedFrom?.includes('AL1');

    const shouldShowVATInformative =
      !isDeductible &&
      isCustomerSubjectToVAT &&
      hasAnyServiceItems &&
      isSupplierOther &&
      hasEveryItemWithVATAmount0;

    const shouldShowSpecialVATDeclarationWarning =
      isCustomerDE &&
      isThereVATOnInvoiceField.value === false &&
      !isCustomerSubjectToVAT &&
      (isSupplierEU || (isSupplierOther && hasAnyServiceItems));

    const shouldShowReverseChargeSmallBusinessInformative =
      isCustomerBE &&
      !isCustomerSubjectToVAT &&
      ((isSupplierLocal && hasAnyZeroVatReveseChargeItems) ||
        ((isSupplierEU ||
          (isSupplierOther && isCustomerSubjectToVAT && hasAnyServiceItems)) &&
          !isThereVATOnInvoiceField.value));

    const shouldShowNoVATReturnInformative =
      isThereVATOnInvoiceField.value &&
      ((isSupplierEU && isCustomerSubjectToVAT) ||
        (isSupplierOther && hasAnyServiceItems));

    const shouldShowTenDayRuleWarning = doesTenDayRuleApplies(
      formValues.expenseDate,
      formValues.items?.map((item) => item.categoryId),
    );

    // user classify the expense as invoice while ocr didn't get the user vat number from the file
    const shouldShowIsInvoiceWarning =
      !isAL1guess &&
      !isInvoiceField.isHidden &&
      isCustomerBE &&
      !!userVATNumber &&
      isInvoiceField.value &&
      (formValues.guessedIsInvoice === null ||
        formValues.guessedIsInvoice === false);

    // user classify the expense as ticket while ocr got the user vat number from the file
    const shouShowIsTicketWarning =
      !isAL1guess &&
      !isInvoiceField.isHidden &&
      isCustomerBE &&
      !!userVATNumber &&
      !isInvoiceField.value &&
      !!formValues.guessedIsInvoice;

    const shouldShowCalcRows =
      !isGermanPrivateCar &&
      !isDeductible &&
      isInvoiceField.value &&
      !!formValues.items?.length;

    const shouldShowNewItemBtn = !isGermanPrivateCar && !isDeductible;
    const shouldShowExpenseDateWarning =
      !!formValues.expenseDate &&
      dayjs(formValues.expenseDate).isAfter(today, 'day');

    const shouldShowExpenseDateHelper = isCustomerDE;

    // const shouldShowReverseChargeInformative =
    //   isCustomerSubjectToVAT &&
    //   isSupplierEU &&
    //   isThereVATOnInvoiceField.value === false;

    return {
      fields: {
        isInvoice: isInvoiceField,
        isThereVATOnInvoice: isThereVATOnInvoiceField,
        period: periodField,
        taxPeriod: taxPeriodField,
        supplier: supplierField,
        transactions: transactionsField,
      },
      helpers: {
        isCustomerSubjectToVAT,
        isDeductible,
        isSupplierLocal,
        isSupplierEU,
        isSupplierOther,
        hasAnyServiceItems,
        hasEveryItemWithVATAmount0,
        hasEveryItemWithZeroVATReverseCharge,
        hasEveryItemWithVATAmount1,
        hasEveryItemWithNoZeroVAT,
        hasAnyZeroVatReveseChargeItems,
        isCustomerBE,
        isCustomerDE,
        isCustomerOnePersonCompany,
        useIsThereVATEU,
        isGermanPrivateCar,
        shouldShowCalcRows,
        shouldShowNewItemBtn,
        // warnings
        shouldShowVATInformative,
        // shouldShowReverseChargeInformative,
        shouldShowSpecialVATDeclarationWarning,
        shouldShowReverseChargeSmallBusinessInformative,
        shouldShowNoVATReturnInformative,
        shouldShowTenDayRuleWarning,
        shouldShowIsInvoiceWarning,
        shouShowIsTicketWarning,
        shouldShowExpenseDateWarning,
        shouldShowExpenseDateHelper,
        isCustomerNaturalPerson,
        // hasEveryItemWithDisallowedVATCategory,
      },
    };
  };

  const getItemMeta = (
    formValues: Partial<IExpense>,
    item: Partial<IExpenseItem>,
    prefix = '',
  ) => {
    const {
      fields: { isInvoice, isThereVATOnInvoice },
      helpers: {
        isCustomerSubjectToVAT,
        isDeductible,
        isSupplierLocal,
        isSupplierEU,
        isSupplierOther,
        hasAnyServiceItems,
        // hasEveryItemWithDisallowedVATCategory,
      },
    } = getFormMeta(formValues);

    const isGermanPrivateCar =
      item.categoryId === SELF_EMPLOYED_CAR_PRIVATE_USE;

    const itemType = item.category?.type || null;
    const isItemService = itemType === ExpenseCategoryType.SERVICE;
    const isItemGood = itemType === ExpenseCategoryType.GOOD;

    const isVATPaidAtCustoms =
      isSupplierOther && isCustomerSubjectToVAT && isItemGood;

    const isVATAmountCreatable = isCustomerBE || isSupplierOther;

    // virtual
    const isReverseChargeField = getFieldState({
      name: prefix + 'isReverseCharge',
      value: (self) => {
        if (!self.isApplicable) return null;
        const fieldValue = item.isReverseCharge;
        if (typeof fieldValue === 'boolean') return fieldValue;
        // if no value found on the form we infer it from the data or just force a default one
        if (item.whyZeroVAT === ExpenseWhyZeroVAT.reverseCharge) return true;
        if (item.whyZeroVAT === ExpenseWhyZeroVAT.other) return false;
        return false;
      },
      isApplicable:
        // !isDeductible &&
        // !isGermanPrivateCar &&
        isInvoice.value &&
        isCustomerSubjectToVAT &&
        isSupplierLocal &&
        item.VATAmount === 0,
    });

    const localVATRateField = getFieldState({
      name: prefix + 'localVATRate',
      value: () => item.localVATRate,
      isApplicable: (() => {
        if (isDeductible) return false;
        if (
          isSupplierLocal &&
          item.whyZeroVAT === ExpenseWhyZeroVAT.reverseCharge
        ) {
          return true;
        }
        if (isThereVATOnInvoice.value) return false;
        if (isSupplierEU) return true;
        if (isSupplierOther && hasAnyServiceItems) {
          return true;
        }
        return false;
      })(),
    });

    const amortizationPeriodField = getFieldState({
      name: prefix + 'amortizationPeriod',
      value: () => item.amortizationPeriod,
      isApplicable: isAmortizationFn(item),
    });

    const isAssetField = getFieldState({
      name: prefix + 'isAsset',
      value: () => item.isAsset,
      isApplicable: !!amortizationPeriodField.value,
      suggestedChanges: ({ isApplicable, name }) => {
        if (!isApplicable) return [{ name, value: false }];
        return [{ name, value: true }];
      },
    });

    const nameField = getFieldState({
      name: prefix + 'name',
      value: () => item.name,
      isApplicable: amortizationPeriodField.isApplicable,
    });

    const qtyField = getFieldState({
      name: prefix + 'qty',
      value: () => item.qty,
      suggestedChanges: ({ name }) => [{ name, value: 1 }],
    });

    const vehicleIdField = getFieldState({
      name: prefix + 'vehicleId',
      value: () => item.vehicleId,
      isApplicable:
        isCustomerBE && vehicleCategoryIds.includes(item.categoryId),
      suggestedChanges: ({ isApplicable, name }) => {
        if (!isApplicable) {
          return [
            { name, value: undefined },
            { name: prefix + 'vehicle', value: undefined },
          ];
        }
      },
    });

    const VATAmountField = getFieldState({
      name: prefix + 'VATAmount',
      value: () => item.VATAmount,
      isHidden:
        // isGermanPrivateCar ||
        // isDeductible ||
        isThereVATOnInvoice.value != null,
      isApplicable: (() => {
        if (item.category?.allowsVAT === false && isSupplierLocal) return false;
        // if (isDeductible) {
        //   return isCustomerDE && isCustomerSubjectToVAT;
        // }
        if (isThereVATOnInvoice.value != null) {
          return true;
        }
        if (isCustomerSubjectToVAT) {
          if (isSupplierLocal) return isInvoice.value;
          return isSupplierOther && isItemGood;
        }
        return false;
      })(),
      suggestedChanges: ({ isApplicable, name }) => {
        if (!isApplicable) return;
        // if (isGermanPrivateCar) return [{ name, value: 0 }];
        // if (isDeductible) return [{ name, value: 0 }];
        if (isThereVATOnInvoice.value) return [{ name, value: 1 }];
        if (isThereVATOnInvoice.value === false) return [{ name, value: 0 }];
      },
    });

    const isExclVATField = getFieldState({
      name: prefix + 'isExclVAT',
      value: () => item.isExclVAT,
      isHidden: VATAmountField.isHidden || isVATPaidAtCustoms,
      isApplicable: (() => {
        // if (
        // isDeductible
        // || isGermanPrivateCar
        // )
        // return false;
        if (isVATPaidAtCustoms) return true;
        return (
          !!VATAmountField.value &&
          isCustomerSubjectToVAT &&
          isSupplierLocal &&
          isInvoice.value
        );
      })(),
      suggestedChanges: ({ name, isApplicable }) => {
        if (!isApplicable) return;
        if (isVATPaidAtCustoms) return [{ name, value: true }];
      },
    });

    const maxDeductibleVATField = getFieldState({
      name: prefix + 'maxDeductibleVAT',
      value: () => item.maxDeductibleVAT,
      isHidden: (() => {
        if (!isExpert) return true;
        if (isSupplierLocal && isInvoice.value) return false;
        if (isSupplierEU && item.VATAmount === 0) return false;
        if (isSupplierOther) {
          if (isItemGood) return false;
          if (isItemService && item.VATAmount === 0) return false;
        }
        return true;
      })(),
    });

    const professionalPartField = getFieldState({
      name: prefix + 'professionalPart',
      value: () => item.professionalPart,
      isHidden: isDeductible || isGermanPrivateCar,
      suggestedChanges: ({ name }) => {
        if (
          isDeductible ||
          isGermanPrivateCar ||
          (isCustomerOnePersonCompany && item.professionalPart == null)
        ) {
          return [{ name, value: 1 }];
        }
      },
    });

    const whyZeroVATField = getFieldState({
      name: prefix + 'whyZeroVAT',
      value: () => item.whyZeroVAT,
      isApplicable: (() => {
        return (
          // isDeductible ||
          // isGermanPrivateCar ||
          isThereVATOnInvoice.value === false ||
          isReverseChargeField.value != null
        );
      })(),
      suggestedChanges: ({ name, isApplicable }) => {
        if (!isApplicable) return;
        if (isThereVATOnInvoice.value === false || isReverseChargeField.value) {
          return [{ name, value: ExpenseWhyZeroVAT.reverseCharge }];
        }
        if (
          // isGermanPrivateCar ||
          // isDeductible ||
          isReverseChargeField.value === false
        ) {
          return [{ name, value: ExpenseWhyZeroVAT.other }];
        }
      },
    });

    const tripTypeField = getFieldState({
      name: prefix + 'tripType',
      value: () => item.tripType,
      isApplicable: isGermanPrivateCar,
    });

    const tripVehicleTypeField = getFieldState({
      name: prefix + 'tripVehicleType',
      value: () => item.tripVehicleType,
      isApplicable: tripTypeField.value === TripType.businessTrip,
    });

    const tripTotalKmField = getFieldState({
      name: prefix + 'tripTotalKm',
      value: () => item.tripTotalKm,
      isApplicable: tripTypeField.value != null,
    });

    const tripWorkingDaysField = getFieldState({
      name: prefix + 'tripWorkingDays',
      value: () => item.tripWorkingDays,
      isApplicable: tripTypeField.value === TripType.driveToWork,
    });

    const deductionField = getFieldState({
      name: prefix + 'deduction',
      value: () => item.deduction,
      isApplicable: isGermanPrivateCar,
      suggestedChanges: ({ isApplicable, name }) => {
        if (!isApplicable) return;

        if (tripTotalKmField.value != null) {
          const tripTotalKmValue = Math.floor(tripTotalKmField.value);
          if (tripTypeField.value === TripType.businessTrip) {
            if (tripVehicleTypeField.value === TripVehicleType.car) {
              return [{ name, value: round(tripTotalKmValue * 0.3) }];
            }
            if (tripVehicleTypeField.value === TripVehicleType.motorbike) {
              return [{ name, value: round(tripTotalKmValue * 0.2) }];
            }
          }

          if (tripTypeField.value === TripType.driveToWork) {
            if (tripWorkingDaysField.value != null) {
              return [
                {
                  name,
                  value: round(
                    (tripTotalKmField.value > 20
                      ? (tripTotalKmValue - 20) * 0.38 + 20 * 0.3
                      : tripTotalKmValue * 0.3) *
                      Math.floor(tripWorkingDaysField.value),
                  ),
                },
              ];
            }
          }
        }

        return [{ name, value: undefined }];
      },
    });

    const amountField = getFieldState({
      name: prefix + 'amount',
      value: () => item.amount,
      isHidden: isGermanPrivateCar,
      suggestedChanges: ({ name }) => {
        if (isGermanPrivateCar) return [{ name, value: deductionField.value }];
      },
    });

    const isExceedingMaxVATRate = (() => {
      if (VATAmountField.isHidden || isVATPaidAtCustoms || !item.amount) {
        return false;
      }

      const VATAmount = VATAmountField.value || 0;

      const maxVATAmount = isExclVATField.value
        ? round((item.amount - VATAmount) * maxVATRate)
        : calculateVATAmount(item.amount, maxVATRate);

      return VATAmount > maxVATAmount;
    })();

    const shouldShowLunchWarning =
      item.categoryId === CATEGORY_LUNCH_VOUCHERS ||
      item.categoryId === CATEGORY_LUNCH_VOUCHERS_ADMIN;

    const shouldShowUtilityVehicleWarning =
      !utilityVehicleWarning.isSeen() && isUtilityVehicle(item.categoryId);

    // used on form submit
    const shouldMarkUtilityVehicleWarningAsSeen =
      shouldShowUtilityVehicleWarning;

    const shouldShowVehicleFineWarning = isVehicleFineCategory(item.categoryId);
    const shouldShowHealthWarning =
      item.categoryId === CATEGORY_HEALTH_INSURANCE;

    const allowSetVatAmountManually = isCustomerBE || isSupplierOther;

    const shouldShowreverseChargeZeroRateWarning =
      item.whyZeroVAT === ExpenseWhyZeroVAT.reverseCharge &&
      item.localVATRate === 0 &&
      (!item.categoryId || !flightsCategories.includes(item.categoryId));

    const shouldShowProfessionalDeductibilityWarning =
      item.professionalPart != null &&
      item.incomeTaxDeductibility != null &&
      item.professionalPart < 1 &&
      item.professionalPart === item.incomeTaxDeductibility;

    const shouldShowGermanyReverseChargeWarning =
      isCustomerDE && !!isReverseChargeField.value;

    const shouldShowAmortizationStartDateWarning =
      !!formValues.expenseDate &&
      !!item.amortizationPeriod &&
      !!customer?.startDate &&
      dayjs(formValues.expenseDate).isBefore(dayjs(customer.startDate));

    const shouldShowProfessionalPartDifferenceWarning =
      !professionalPartField.isHidden &&
      isCustomerNaturalPerson &&
      item.professionalPart != null &&
      item.category?.recommendedProfessionalPart != null &&
      item.professionalPart !== item.category.recommendedProfessionalPart;

    const shouldShowBankChargesProfessionalPartWarning =
      !professionalPartField.isHidden &&
      isCustomerNaturalPerson &&
      (item.categoryId === CATEGORY_BANK_CHARGES ||
        item.categoryId === CATEGORY_INTEREST_AND_BANK_CHARGES) &&
      item.professionalPart != null &&
      item.professionalPart !== 1;

    const shouldShowWorkFromHomeProfessionalPartWarning =
      !professionalPartField.isHidden &&
      isCustomerNaturalPerson &&
      !!item.categoryId &&
      workFromHomeCategories.includes(item.categoryId);

    const shouldShowCompanyProfessionalPartWarning =
      !professionalPartField.isHidden &&
      isCustomerOnePersonCompany &&
      item.professionalPart != null &&
      item.professionalPart !== 1;

    const shouldShowDeductionBusinessTripHelper =
      tripTypeField.value === TripType.businessTrip;

    const shouldShowDeductionDriveToWorkHelper =
      tripTypeField.value === TripType.driveToWork;

    const shouldShowDeductionDestinationHelpers =
      deductionField.isApplicable && tripTypeField.value == null;

    const shouldShowBusinessCarInfo = isBusinessCar(item.categoryId);

    const shouldShowLumpsumWarning =
      item.categoryId === CATEGORY_WORKPLACE_RENT_HOMELUMPSUM;

    const shouldShowCompanyMealVoucherWarning =
      isCustomerOnePersonCompany &&
      item.categoryId === CATEGORY_LUNCH_VOUCHERS &&
      item.amount != null &&
      !isMultipleOf2(item.amount);

    const shouldShowSoftwareInvoiceWarning =
      item.categoryId === CATEGORY_IT_SERVICE;

    const shouldShowSelfEmployedCompensationWarning =
      isCustomerOnePersonCompany &&
      (item.categoryId === CATEGORY_DIRECTORS_COMPENSATION ||
        item.categoryId === CATEGORY_WORKERS_COMPENSATION ||
        item.categoryId === CATEGORY_OTHER_PERSONNEL_FEES);

    const shouldShowProfPartRentSecWorkplaceWarning =
      item.categoryId === CATEGORY_WORKPLACE_RENT_HOME_OFFICE_SECONDARY;

    return {
      fields: {
        isReverseCharge: isReverseChargeField,
        localVATRate: localVATRateField,
        amortizationPeriod: amortizationPeriodField,
        isAsset: isAssetField,
        name: nameField,
        qty: qtyField,
        vehicleId: vehicleIdField,
        amount: amountField,
        VATAmount: VATAmountField,
        isExclVAT: isExclVATField,
        maxDeductibleVAT: maxDeductibleVATField,
        professionalPart: professionalPartField,
        whyZeroVAT: whyZeroVATField,
        deduction: deductionField,
        tripType: tripTypeField,
        tripVehicleType: tripVehicleTypeField,
        tripTotalKm: tripTotalKmField,
        tripWorkingDays: tripWorkingDaysField,
      },
      helpers: {
        isExceedingMaxVATRate,
        isItemService,
        isItemGood,
        isVATPaidAtCustoms,
        isVATAmountCreatable,
        allowSetVatAmountManually,
        // warnings
        shouldShowHealthWarning,
        shouldShowreverseChargeZeroRateWarning,
        shouldShowProfessionalDeductibilityWarning,
        shouldShowLunchWarning,
        shouldShowUtilityVehicleWarning,
        shouldMarkUtilityVehicleWarningAsSeen,
        shouldShowVehicleFineWarning,
        shouldShowGermanyReverseChargeWarning,
        shouldShowAmortizationStartDateWarning,
        shouldShowProfessionalPartDifferenceWarning,
        shouldShowBankChargesProfessionalPartWarning,
        shouldShowWorkFromHomeProfessionalPartWarning,
        shouldShowCompanyProfessionalPartWarning,
        shouldShowDeductionBusinessTripHelper,
        shouldShowDeductionDriveToWorkHelper,
        shouldShowDeductionDestinationHelpers,
        shouldShowBusinessCarInfo,
        shouldShowLumpsumWarning,
        shouldShowCompanyMealVoucherWarning,
        shouldShowSoftwareInvoiceWarning,
        shouldShowSelfEmployedCompensationWarning,
        shouldShowProfPartRentSecWorkplaceWarning,
      },
    };
  };

  const getSuggestedChanges = (formValues: Partial<IExpense>) => {
    return [
      ...Object.values(getFormMeta(formValues).fields),
      ...(formValues.items || []).flatMap((item, i) =>
        Object.values(getItemMeta(formValues, item, `items.${i}.`).fields),
      ),
    ].flatMap((field) => field.suggestedChanges);
  };

  return {
    getFormMeta,
    getItemMeta,
    getSuggestedChanges,
  };
};

export default useFormMeta;
//
