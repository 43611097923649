import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import formatMoney from 'utils/formatMoney';
import { round } from 'utils/math';

const classnamesMap = {
  bank: {
    fillTrackClassName: 'bg-bank',
    trackClassName: 'bg-bank-100',
    textClassname: 'text-bank',
  },
  revenues: {
    fillTrackClassName: 'bg-revenues',
    trackClassName: 'bg-revenues-100',
    textClassname: 'text-revenues',
  },
  expenses: {
    fillTrackClassName: 'bg-expenses',
    trackClassName: 'bg-expenses-100',
    textClassname: 'text-expenses',
  },
  taxes: {
    fillTrackClassName: 'bg-taxes',
    trackClassName: 'bg-taxes-100',
    textClassname: 'text-taxes',
  },
  primary: {
    fillTrackClassName: 'bg-primary',
    trackClassName: 'bg-primary-100',
    textClassname: 'text-primary',
  },
};

const ProgressLabel: FC<{ Icon?: any; text: string }> = ({ text, Icon }) => (
  <div className="flex items-center justify-end mt-1">
    {!Icon ? (
      <ExclamationCircleIcon className="w-5 mr-2 opacity-0" />
    ) : (
      <Icon className="w-5 mr-2" />
    )}

    <span>{text}</span>
  </div>
);

const LinkedItemsProgress: FC<{
  matchedAmount: number;
  total: number;
  type: 'bank' | 'revenues' | 'expenses' | 'taxes' | 'primary';
}> = ({ matchedAmount, total, type }) => {
  const { t } = useTranslation();
  const _matchedAmount = round(matchedAmount);

  const _total = round(Math.abs(total));

  const percentage = (matchedAmount * 100) / _total;

  const label = (() => {
    if (_total === 0)
      return (
        <ProgressLabel
          text={`${formatMoney(0)} ${t('matched_items.left_to_link.text')}`}
        />
      );

    if (_matchedAmount === _total)
      return (
        <ProgressLabel
          text={t('matched_items.left_to_link.fully_matched')}
          Icon={CheckIcon}
        />
      );

    if (_matchedAmount > _total)
      return (
        <ProgressLabel
          text={`${formatMoney(_matchedAmount - _total)} ${t(
            'matched_items.left_to_link.too_much',
          )}`}
          Icon={ExclamationCircleIcon}
        />
      );

    return (
      <ProgressLabel
        text={`${formatMoney(_total - _matchedAmount)} ${t(
          'matched_items.left_to_link.text',
        )}`}
      />
    );
  })();

  const classes = classnamesMap[type];

  return (
    <div className="mb-5">
      <div className="h-4 flex rounded-md overflow-hidden">
        <div
          className={classNames(
            'transition-all duration-500',
            classes.fillTrackClassName,
          )}
          style={{
            width: `${_total === 0 || percentage > 100 ? 100 : percentage}%`,
          }}
        />

        <div className={classNames('flex-1', classes.trackClassName)} />
      </div>

      <p
        className={classNames(
          'text-right font-semibold',
          classes.textClassname,
        )}
      >
        {label}
      </p>
    </div>
  );
};

export default LinkedItemsProgress;
