import hasTransactions from 'api/v1/transactions/hasTransactions';
import { useQuery } from 'react-query';
import { BankTransactionsCacheKeys } from 'types/cacheKeys.types';

const useHasTransactions = () => {
  return useQuery(BankTransactionsCacheKeys.HasTransactions, hasTransactions, {
    staleTime: 0,
  });
};

export default useHasTransactions;
