import { getClientById } from 'api/v2/clients';
import { calculateItems, getQuoteById } from 'api/v2/revenues';
import { Loader } from 'components/atoms/Loader';
import SimpleError from 'components/molecules/SimpleError.component';
import dayjs from 'dayjs';
import useCutRouterState from 'hooks/useCutRouterState';
import { lazy, useId, useMemo, type FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { RevenuesCacheKeys } from 'types/cacheKeys.types';
import { CurrenciesEnum } from 'types/global.types';
import { InvoiceTypeEnum, Quote } from 'types/invoice.types';
import { cleanInvoiceItems } from '../SharedControls/ItemsForm/helpers';
import '../styles.scss';
const Form = lazy(() => import('./Form'));

const PrepareQuote: FC<{ data: Partial<Quote> }> = ({ data: quote }) => {
  const clientQuery = useQuery(
    [RevenuesCacheKeys.ClientById, useId()],
    () => {
      const clientId = quote.client?._id;
      if (!clientId) {
        return {
          client: undefined,
          clientId: undefined,
        };
      }
      return getClientById(clientId, { ignoreToast: true })
        .then(() => ({
          client: quote!.client,
          clientId: clientId,
        }))
        .catch((e) => {
          if (e.response?.status === 404) {
            return {
              client: undefined,
              clientId: undefined,
            };
          }

          throw e;
        });
    },
    {
      cacheTime: 0,
    },
  );

  const calculationsQueryEnabled = !!clientQuery.data;
  const calculationsQuery = useQuery(
    [RevenuesCacheKeys.ItemsCalculations, useId(), calculationsQueryEnabled],
    () => {
      const client = clientQuery.data!;
      return calculateItems({
        type: InvoiceTypeEnum.QUOTE,
        items: cleanInvoiceItems(quote.items || []),
        clientId: client.clientId,
        invoiceDate: quote.quoteDate || dayjs().format('YYYY-MM-DD'),
        options: {
          currency: quote.currency || CurrenciesEnum.EUR,
        },
      });
    },
    { cacheTime: 0, enabled: calculationsQueryEnabled },
  );

  const document = useMemo(() => {
    return {
      ...quote,
      ...clientQuery.data,
      itemsCalculations: calculationsQuery.data,
    };
  }, [quote, clientQuery.data, calculationsQuery.data]);

  const isLoading = clientQuery.isLoading || calculationsQuery.isLoading;
  const isError = clientQuery.isError || calculationsQuery.isError;

  if (isLoading) return <Loader />;

  if (isError)
    return (
      <SimpleError
        onRetry={() => {
          if (clientQuery.isError) clientQuery.refetch();
          if (calculationsQuery.isError) calculationsQuery.refetch();
        }}
      />
    );

  return <Form defaultValues={document as any} />;
};

export const EditQuote = () => {
  const quoteId = useParams().quoteId as string;

  const QuoteQuery = useQuery(
    [RevenuesCacheKeys.RevenueById, quoteId],
    () => getQuoteById(quoteId),
    { cacheTime: 0 },
  );

  if (QuoteQuery.isLoading) return <Loader />;

  if (QuoteQuery.isError) {
    return (
      <SimpleError
        onRetry={() => {
          QuoteQuery.refetch();
        }}
      />
    );
  }

  if (!QuoteQuery.data) return null;
  return <PrepareQuote data={QuoteQuery.data} />;
};

export const CreateQuote = () => {
  const guessedDocument = useCutRouterState(
    'guessedDocument',
  ) as Partial<Quote> | null;
  if (!guessedDocument) return <Form />;

  return <PrepareQuote data={{ ...guessedDocument, _id: undefined }} />;
};
