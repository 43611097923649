import { tempMessagesTranslations } from 'customJoi/data';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import type { InitOptions } from 'i18next';
import { type i18n } from 'i18next';
import type { BackendOptions, RequestResponse } from 'i18next-http-backend';
import get from 'lodash/get';
import { AvailableLanguages } from 'types/users.types';
import reportOnSlack, { SlackWebHooks } from 'utils/slack/reportOnSlack';
import AYCD from './AYCD';
import occupations from './occupations';

dayjs.extend(localizedFormat);
export const DEFAULT_LANGUAGE = AvailableLanguages.en;

const langCodes: string[] = Object.values(AvailableLanguages);

const loadDynamicBundle = async (locale: string) => {
  if (langCodes.includes(locale)) {
    const res = await import(`./langs/${locale}.json`);
    return res.default;
  }
};

const backend: BackendOptions = {
  loadPath: '{lng}',
  request: (...args) => {
    const [, url, , callback] = args;
    try {
      const lng = url.split('|')[0] as AvailableLanguages;
      // this mocks the HTTP fetch plugin behavior so it works with the backend AJAX pattern in this XHR library
      loadDynamicBundle(lng).then((data) => {
        callback(null, {
          data: JSON.stringify({
            ...AYCD[lng],
            ...tempMessagesTranslations.en,
            // maybe move these 2 to the main sheet
            ...occupations[lng as keyof typeof occupations],
            //
            ...data,
          }),
          status: 200, // status code is required by XHR plugin to determine success or failure
        });
      });
    } catch (e) {
      console.error(e, args);
      callback(null, {
        status: 500,
      } as RequestResponse);
    }
  },
};

const countriesLangsMap = {
  en: 'EN 🇬🇧',
  nl: 'NL 🇳🇱',
  de: 'DE 🇩🇪',
  fr: 'FR 🇫🇷',
};

export const parseMissingKeyHandler =
  (i18n: i18n) =>
  (key: string): string => {
    if (key) {
      reportOnSlack(
        SlackWebHooks.translation,
        `${get(countriesLangsMap, i18n.language)} missing: ${key}`,
      );
    }
    return key;
  };

export const sharedConfig: InitOptions = {
  fallbackLng: DEFAULT_LANGUAGE,
  supportedLngs: Object.values(AvailableLanguages),
  backend,
  detection: {
    lookupQuerystring: 'locale',
    lookupLocalStorage: 'i18nextLng',
  },
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    prefix: '{',
    suffix: '}',
  },
  keySeparator: false,
};
