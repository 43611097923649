import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { Helper } from 'components/molecules/Helper';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { InvoiceTypeEnum } from 'types/invoice.types';
import type { AvailableCountries } from 'types/users.types';
import { getExpenseReferSlugs, getRevenuesReferSlugs } from 'utils/helpers';
import { LockClosedIconOutline } from 'utils/icons';

const DocumentLockByAccountant: FC<{
  thisType: string;
  name?: string;
}> = ({ thisType, name }) => {
  const { t } = useTranslation();

  return (
    <Alert
      type="default"
      name={name}
      icon={LockClosedIconOutline}
      title={t('document.info_bubble.locked_by_accountant.' + thisType)}
      renderButtons={() => (
        <div className="flex justify-end mt-3">
          <Helper
            trigger={(openModal) => (
              <Button structure="text" color="primary" onClick={openModal}>
                {t('learn_more')}
              </Button>
            )}
          >
            <Helper.Title>
              {t('document.info_bubble.locked_by_accountant.title')}
            </Helper.Title>
            <Helper.Paragraph className="whitespace-pre-line">
              {t('document.info_bubble.locked_by_accountant.text1')}{' '}
              {t('document.info_bubble.locked_by_accountant.text2.' + thisType)}
              {t('document.info_bubble.locked_by_accountant.text3')}
            </Helper.Paragraph>
          </Helper>
        </div>
      )}
    />
  );
};

export const ExpenseLockedByAccountant: FC<{ isCreditNote: boolean }> = ({
  isCreditNote,
}) => {
  const country = useCustomerCountry() as AvailableCountries;

  const { thisType } = getExpenseReferSlugs({ isCreditNote, country });

  const docType = isCreditNote ? 'credit-note' : 'expense';

  return (
    <DocumentLockByAccountant
      thisType={thisType}
      name={'expense:' + docType + ' accountant locked'}
    />
  );
};

export const InvoiceLockedByAccountant: FC<{
  type: InvoiceTypeEnum;
}> = ({ type }) => {
  const country = useCustomerCountry() as AvailableCountries;

  const { thisType } = getRevenuesReferSlugs({ type, country });

  return (
    <DocumentLockByAccountant
      thisType={thisType}
      name={'invoice:' + type + ' accountant locked'}
    />
  );
};
