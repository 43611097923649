import classNames from 'classnames';
import type { FC } from 'react';
import type { TableHeadProps } from './table.types';

export const TableHead: FC<TableHeadProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <thead
      className={classNames(
        'text-md font-assistant font-medium text-primary-500 bg-white border-b border-primary-50',
        className,
      )}
      {...props}
    >
      <tr>{children}</tr>
    </thead>
  );
};
