import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useFeatures from 'hooks/useFeatures';
import type { VATReturnFrequency } from 'types/users.types';

const useVATReturnFrequency = (): VATReturnFrequency => {
  const { data: user } = useGetCurrentCustomerData();
  const defaultVatFreq = useFeatures('taxes.default_vat_return_frequency');
  return (user?.settings?.taxes?.VATReturnFrequency ||
    defaultVatFreq) as VATReturnFrequency;
};
export default useVATReturnFrequency;
