import { SubscriptionPlanNameEnum } from 'types/users.types';

export type SubscriptionFreePlan = typeof subscriptionFreePlans[number];
export const subscriptionFreePlans = ['eu.accountable.free'] as const;

export type SubscriptionTrialPlan = typeof subscriptionTrialPlans[number];
export const subscriptionTrialPlans = ['eu.accountable.trial1'] as const;

export type SubscriptionBasicPlan = typeof subscriptionBasicPlans[number];
export const subscriptionBasicPlans = [
  'eu.accountable.basic1',
  'eu.accountable.basic12',
] as const;

export type SubscriptionProPlan = typeof subscriptionProPlans[number];
export const subscriptionProPlans = [
  'eu.accountable.pro1',
  'eu.accountable.pro12',
] as const;
export const subscriptionSmallPlans = [
  'eu.accountable.small1',
  'eu.accountable.small12',
] as const;
export type SubscriptionSmallPlan = typeof subscriptionSmallPlans[number];

export type SubscriptionPaidPlan = typeof subscriptionPaidPlans[number];
export const subscriptionPaidPlans = [
  ...subscriptionBasicPlans,
  ...subscriptionProPlans,
  ...subscriptionSmallPlans,
] as const;

export type SubscriptionAmbassadorPlan =
  typeof subscriptionAmbassadorPlans[number];
export const subscriptionAmbassadorPlans = [
  'eu.accountable.ambassador',
] as const;

export type SubscriptionNoWebAccessPlan =
  typeof subscriptionNoWebAccessPlans[number];
export const subscriptionNoWebAccessPlans = [
  'eu.accountable.free',
  'eu.accountable.basic1',
  'eu.accountable.basic12',
] as const;

export type StripeSubscriptionPlan = typeof stripeSubscriptionPlans[number];
export const stripeSubscriptionPlans = [
  ...subscriptionFreePlans,
  ...subscriptionTrialPlans,
  ...subscriptionPaidPlans,
  ...subscriptionAmbassadorPlans,
] as const;

export type SubscriptionManualPlatform =
  typeof subscriptionManualPlatforms[number];
export const subscriptionManualPlatforms = ['manual'] as const;

export type SubscriptionMobilePlatform =
  typeof subscriptionMobilePlatforms[number];
export const subscriptionMobilePlatforms = [
  'apple' /* legacy */,
  'ios' /* legacy */,
  'android' /* legacy */,
  'qonversion',
] as const;

export type SubscriptionWebPlatform = typeof subscriptionWebPlatforms[number];
export const subscriptionWebPlatforms = ['stripe', 'stripe-otp'] as const;

export type SubscriptionPromoPlatform =
  typeof subscriptionPromoPlatforms[number];
export const subscriptionPromoPlatforms = ['ING'] as const;

export type SubscriptionPlatform = typeof subscriptionPlatforms[number];
export const subscriptionPlatforms = [
  ...subscriptionManualPlatforms,
  ...subscriptionMobilePlatforms,
  ...subscriptionWebPlatforms,
  ...subscriptionPromoPlatforms,
] as const;

export const plans_order = {
  [SubscriptionPlanNameEnum.free]: 0,
  [SubscriptionPlanNameEnum.trial1]: 0,
  [SubscriptionPlanNameEnum.ambassador]: 0,
  [SubscriptionPlanNameEnum.basic1]: 1,
  [SubscriptionPlanNameEnum.basic12]: 1,
  [SubscriptionPlanNameEnum.small1]: 3,
  [SubscriptionPlanNameEnum.small12]: 3,
  [SubscriptionPlanNameEnum.pro1]: 3,
  [SubscriptionPlanNameEnum.pro12]: 3,
} as const;
