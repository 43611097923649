import type { FC } from 'react';

const Mixed2: FC<any> = ({ fill, ...props }) => (
  <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill={fill}>
      <path d="M8 22a2 2 0 11-4.001-.001A2 2 0 018 22zM24 7.333c0 .368-.3.667-.667.667h-2.666A.668.668 0 0120 7.333V4.667c0-.368.3-.667.667-.667h2.666c.368 0 .667.3.667.667v2.666zM11.227 0H1.773C.796 0 0 .796 0 1.773v9.454C0 12.204.796 13 1.773 13h9.454c.977 0 1.773-.796 1.773-1.773V1.773C13 .796 12.204 0 11.227 0zm.591 11.227a.591.591 0 01-.59.591H1.772a.592.592 0 01-.591-.59V1.772c0-.326.265-.591.59-.591h9.455c.326 0 .591.265.591.59v9.455z" />
      <path d="M11.227 15H1.773C.796 15 0 15.796 0 16.773v9.454C0 27.204.796 28 1.773 28h9.454c.977 0 1.773-.796 1.773-1.773v-9.454c0-.977-.796-1.773-1.773-1.773zm.591 11.227a.591.591 0 01-.59.591H1.772a.592.592 0 01-.591-.59v-9.455c0-.326.265-.591.59-.591h9.455c.326 0 .591.265.591.59v9.455zM26.227 0h-9.454C15.796 0 15 .796 15 1.773v9.454c0 .977.796 1.773 1.773 1.773h9.454c.977 0 1.773-.796 1.773-1.773V1.773C28 .796 27.204 0 26.227 0zm.591 11.227a.591.591 0 01-.59.591h-9.455a.592.592 0 01-.591-.59V1.772c0-.326.265-.591.59-.591h9.455c.326 0 .591.265.591.59v9.455zM26.227 15h-9.454c-.977 0-1.773.796-1.773 1.773v9.454c0 .977.796 1.773 1.773 1.773h9.454c.977 0 1.773-.796 1.773-1.773v-9.454c0-.977-.796-1.773-1.773-1.773zm.591 11.227a.591.591 0 01-.59.591h-9.455a.592.592 0 01-.591-.59v-9.455c0-.326.265-.591.59-.591h9.455c.326 0 .591.265.591.59v9.455z" />
      <path d="M24.857 2h-5.714C18.513 2 18 2.513 18 3.143v5.714c0 .63.513 1.143 1.143 1.143h5.714C25.487 10 26 9.487 26 8.857V3.143C26 2.513 25.487 2 24.857 2zm0 6.857h-5.714V3.143h5.714v5.714zm0 9.143h-5.714c-.63 0-1.143.513-1.143 1.143v5.714c0 .63.513 1.143 1.143 1.143h5.714c.63 0 1.143-.513 1.143-1.143v-5.714c0-.63-.513-1.143-1.143-1.143zm0 1.143v1.143h-5.714v-1.143h5.714zm-5.714 5.714V21.43h5.714v3.428h-5.714zM8.857 18H3.143C2.513 18 2 18.513 2 19.143v5.714C2 25.487 2.513 26 3.143 26h5.714c.63 0 1.143-.513 1.143-1.143v-5.714C10 18.513 9.487 18 8.857 18zm0 6.857H3.143v-5.714h5.714v5.714zM8.857 2H3.143C2.513 2 2 2.513 2 3.143v5.714C2 9.487 2.513 10 3.143 10h5.714C9.487 10 10 9.487 10 8.857V3.143C10 2.513 9.487 2 8.857 2zm0 1.143v1.143H3.143V3.143h5.714zm0 2.286V6.57H3.143V5.43h5.714zM3.143 8.857V7.714h5.714v1.143H3.143z" />
    </g>
  </svg>
);

export default Mixed2;
