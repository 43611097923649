import { uploadFile } from 'api/v2/upload';
import { Button } from 'components/atoms/Button';
import useCustomDropzone from 'hooks/useCustomDropzone';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import type { color } from 'types/shared/worlds';
import { globalyAcceptedFileUploaderTypes, maxFileSize } from 'utils/constants';
import { EyeIcon, EyeOffIcon, Upload2OutlineIcon } from 'utils/icons';
import { renderLoader } from './utils';

type AttachmentControllerProps = {
  onShow: () => void;
  onHide: () => void;
  onUploaded: (data: any) => void;
  hasFile: boolean;
  isShow: boolean;
  disabled?: boolean;
  color?: color;
};

const AttachmentController: FC<AttachmentControllerProps> = (props) => {
  const { t } = useTranslation();
  const upload = useMutation((file: File) => uploadFile({ file }), {
    onSuccess: (data) => {
      props.onUploaded(data);
    },
  });
  const { getInputProps, open } = useCustomDropzone({
    onDropAccepted: ([file]) => upload.mutate(file),
    multiple: false,
    maxSize: maxFileSize,
    accept: globalyAcceptedFileUploaderTypes,
  });

  if (!props.hasFile) {
    return (
      <>
        <input {...getInputProps()} />
        <Button
          highlightID="attach-document"
          disabled={upload.isLoading || props.disabled}
          structure="text"
          size="xl"
          color={props.color}
          onClick={() => open()}
        >
          {upload.isLoading ? (
            renderLoader('w-5 mx-auto')
          ) : (
            <Upload2OutlineIcon className="w-5 mx-auto" />
          )}
          <span className="ml-2 font-semibold">{t('attach_document')}</span>
        </Button>
      </>
    );
  }

  if (props.isShow) {
    return (
      <Button
        structure="text"
        size="xl"
        onClick={() => props.onHide()}
        color={props.color}
      >
        <EyeOffIcon className="w-5 mx-auto" />
        <span className="ml-2 font-semibold">{t('sidepane.hide_preview')}</span>
      </Button>
    );
  }
  return (
    <Button
      structure="text"
      size="xl"
      onClick={() => props.onShow()}
      color={props.color}
    >
      <EyeIcon className="w-5 mx-auto" />
      <span className="ml-2 font-semibold">{t('sidepane.show_preview')}</span>
    </Button>
  );
};

export default AttachmentController;
