import { joiResolver } from '@hookform/resolvers/joi';
import { Button } from 'components/atoms/Button';
import GermanCarsForm from 'components/organisms/GermanCarsForm';
import germanCarValidationSchema from 'components/organisms/GermanCarsForm/validationSchema';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  defaultValues?: any;
  onSubmit: (values: any) => Promise<any>;
};

const GermanCarsFormControlled: FC<Props> = (props) => {
  const { t } = useTranslation();

  const formAPI = useForm({
    defaultValues: props.defaultValues || {},
    resolver: joiResolver(germanCarValidationSchema, {
      allowUnknown: true,
      abortEarly: false,
    }),
  });

  const isLoading = formAPI.formState.isSubmitting;

  return (
    <div>
      <div className="bg-[#f6f6fd] px-8 py-10 rounded-t-2xl">
        <p className="text-primary-900 text-lg leading-tight">
          {t(
            'expense.we_need_some_information_about_your_vehicle_to_compute_your_income_tax_properly',
          )}
        </p>
      </div>
      <div className="px-8 py-4">
        <GermanCarsForm formAPI={formAPI} />
        <Button
          onClick={(e) => formAPI.handleSubmit(props.onSubmit)(e)}
          loading={isLoading}
          size="xl"
          className="mt-8 mb-4 w-full"
        >
          {t('save')}
        </Button>
      </div>
    </div>
  );
};

export default GermanCarsFormControlled;
