import { getExpenseVATCells } from 'api/v2/expenses';
import { getRevenueVATCells } from 'api/v2/revenues';
import { cleanExpenseDataForTaxImpact } from 'components/pages/Expenses/Form/utils';
import { cleanInvoiceForTaxImpact } from 'components/pages/Revenues/OtherRevenueForm/utils';
import useDebounceQuery from 'hooks/useDebounceQuery';
import { ExpensesCacheKeys, RevenuesCacheKeys } from 'types/cacheKeys.types';
import type { IExpense } from 'types/expenses.types';
import type { Invoice } from 'types/invoice.types';
import { ONE_MINUTE } from 'utils/time';

export type UseExpenseVATCellsOptions = {
  expense: Partial<IExpense>;
  enabled: boolean;
};

export type UseInvoiceVATCellsOptions = {
  invoice: Partial<Invoice>;
  enabled: boolean;
};

type ResultType = {
  key: string;
  value: number;
}[];

export const useExpenseVATCells = (props: UseExpenseVATCellsOptions) => {
  return useDebounceQuery<ResultType>({
    queryKey: [ExpensesCacheKeys.ExpenseVATCells, props.expense, props.enabled],
    queryFn: () => {
      if (!props.enabled) {
        return [];
      }
      return getExpenseVATCells(cleanExpenseDataForTaxImpact(props.expense));
    },
    debounceTime: 500,
    cacheTime: ONE_MINUTE,
    keepPreviousData: true,
  });
};

export const useInvoiceVATCells = (props: UseInvoiceVATCellsOptions) => {
  return useDebounceQuery<ResultType>({
    queryKey: [
      RevenuesCacheKeys.RevenuesVATCells,
      props.invoice,
      props.enabled,
    ],
    queryFn: () => {
      if (!props.enabled) {
        return [];
      }
      return getRevenueVATCells(
        cleanInvoiceForTaxImpact(props.invoice) as Partial<Invoice>,
      );
    },
    debounceTime: 500,
    cacheTime: ONE_MINUTE,
    keepPreviousData: true,
  });
};
