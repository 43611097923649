import classNames from 'classnames';
import type { FC } from 'react';
import { getTracingEvents } from 'utils/amplitude';
import type { RadioGroupsProps } from './RadioGroups.types';
import { customRadioGroupsTypeMap } from './RadioGroups.types';

const RadioGroups: FC<RadioGroupsProps> = ({
  type,
  legendLabel = 'Radio Group Options',
  data,
  onChange,
  labelClassNames = 'text-sm text-gray-700',
  className,
  tracing,
}) => {
  const { inlineClasses } =
    customRadioGroupsTypeMap[type] || customRadioGroupsTypeMap.default;

  const tracingEvents = getTracingEvents(tracing, { onChange });
  return (
    <fieldset>
      <legend className="sr-only">{legendLabel}</legend>
      <div className={classNames('space-y-2', inlineClasses, className)}>
        {data.map(
          ({ id, title, description, InfoComponent = () => null, checked }) => (
            <div
              key={String(id)}
              className="flex items-center cursor-pointer py-1"
            >
              <div className="flex items-center">
                <input
                  id={String(id)}
                  name={String(id)}
                  type="radio"
                  checked={!!checked}
                  className="cursor-pointer h-3.5 w-3.5 text-primary-600 border-gray-300"
                  onChange={() => tracingEvents.onChange(id)}
                />
              </div>

              <label
                htmlFor={String(id)}
                className={classNames(
                  'pl-3 flex-1 block font-medium cursor-pointer',
                  labelClassNames,
                )}
              >
                <p
                  className={classNames('text-primary', {
                    'font-bold	': checked,
                  })}
                >
                  {title}
                </p>
                {description && (
                  <p className="text-sm font-medium text-primary">
                    {description}
                  </p>
                )}
              </label>

              <InfoComponent />
            </div>
          ),
        )}
      </div>
    </fieldset>
  );
};

export default RadioGroups;
