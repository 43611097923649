import type { Dictionary } from 'lodash';
import type { IExpense } from 'types/expenses.types';
import type { Invoice } from 'types/invoice.types';
import type { IUser } from 'types/users.types';

export enum GratificationTypes {
  joke = 'joke',
  appTip = 'appTip',
  taxTip = 'taxTip',
  taxImpact = 'taxImpact',
  pnlTip = 'pnlTip',
  analytics = 'analytics',
  comparison = 'comparison',
}

export type GratifColors = {
  primary: string;
  secondary: string;
  helper: string;
};

export type Gratification = {
  _id?: string;
  created?: Date;
  modified?: Date;
  gratifContent?: string;
  gratificationNumber: number; // airtable id
  text: string;
  icon: string;
  type: keyof typeof GratificationTypes;
  seen?: string | null;
  metadata?: Metadata; // these contain the data we have to render in the toasts/helpers
  useful?: boolean;
  activationText?: string; // when user scans his first expense or invoice
  activationActionText?: string;
  activationTextId?: number;
  helperContent?: HelperContent[];
  helperTitle?: string;
};

export type FormattedMetadata = Dictionary<string | number> & {
  expense?: IExpense;
  invoice?: Invoice;
};

export type Metadata = Dictionary<any>;

export type Gratifications = {
  [gratificationNumber: string]: Gratification;
};

export enum ContentType {
  title = 'title',
  text = 'text',
  button = 'button',
  link = 'link',
  textInput = 'textInput',
  container = 'container',
  table = 'table',
  subTitle = 'subTitle',
  subText = 'subText',
  tableRowTitle = 'tableRowTitle',
  tableRow = 'tableRow',
  tableLink = 'tableLink',
  floatingButton = 'redirectionButton',
  divider = 'divider',
  image = 'image',
  outlineButton = 'outlineButton',
  date = 'date',
}

type ShouldShowProps = { user: IUser };

export type HelperContent = {
  type: ContentType;
  id?: string; // translation file key, i.e. 'gratification_message_36_title_1'
  text?: string; // translated message
  value?: string;
  backgroundColor?: string;
  innerContent?: HelperContent[];
  onPressId?: number;
  center?: boolean;
  imgSrc?: any;
  onPress?: any;
  shouldShow?: (props: ShouldShowProps) => boolean;
  to?: string;
};
