import { parse } from 'query-string';

const markers = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'fbclid',
  'gclid',
  '_gl',
  'adset_id',
];

const getUTMs = (search: string) => {
  const params = parse(search || '');
  return Object.fromEntries(
    Object.entries(params).filter(([key]) => markers.includes(key)),
  );
};

export default getUTMs;
