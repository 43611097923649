import { useCallback, useEffect, useMemo } from 'react';
import ConcurrencyLimiter from 'utils/scheduling/concurrency-limiter';
import RateLimiter from 'utils/scheduling/rate-limiter.helper';

type Options = { onComplete?: () => void; keepAlive?: boolean };

const useParallelScheduler = <T>(
  options: Options,
): ((job: () => Promise<T>) => void) => {
  // Use rate and concurrency limiters in conjunction
  const rateLimiter = useMemo(
    () => new RateLimiter({ rateLimit: 2, bucketSize: 3 }),
    [],
  );

  const concurrencyLimiter = useMemo(
    () => new ConcurrencyLimiter<T>({ concurrentJobs: 5 }),
    [],
  );

  // If should not keep alive, clear current jobs from queue
  useEffect(() => {
    if (options.keepAlive) return;

    return () => {
      rateLimiter.clearQueue();
      concurrencyLimiter.clearQueue();
    };
  }, [options.keepAlive, rateLimiter, concurrencyLimiter]);

  // Call `onComplete` when the last job completes
  useEffect(() => {
    const { onComplete } = options;
    if (!onComplete) return;

    concurrencyLimiter.addEventListener('empty', onComplete);

    return () => {
      concurrencyLimiter.removeEventListener('empty', onComplete);
    };
  }, [concurrencyLimiter, options]);

  // Use both limiters in conjunction
  const schedule = useCallback(
    (job: () => Promise<T>) => {
      rateLimiter.schedule(() => concurrencyLimiter.schedule(job));
    },
    [rateLimiter, concurrencyLimiter],
  );

  return schedule;
};

export default useParallelScheduler;
