import Joi from 'joi';

import { settingsErrorMessagesFormatter } from 'utils/settings/settingsHelpers';

import { AccountSettingsFieldsNamesEnum } from 'types/settings.types';

const { deleteAccountReason, deleteAccountOtherReason } =
  AccountSettingsFieldsNamesEnum;

const deleteAccountValidationSchema = (t: (x: string) => string) =>
  Joi.object({
    [deleteAccountReason]: Joi.string()
      .required()
      .messages(settingsErrorMessagesFormatter(deleteAccountReason)),
    [deleteAccountOtherReason]: Joi.when(Joi.ref(deleteAccountReason), {
      is: t('settings.delete_account.option_other'),
      then: Joi.string()
        .required()
        .messages(settingsErrorMessagesFormatter(deleteAccountOtherReason)),
    }),
  });

export default deleteAccountValidationSchema;
