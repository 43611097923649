import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import type { FC } from 'react';
import type { TableHeadCellProps } from './table.types';
import { Sort } from './table.types';

export const TableHeadCell: FC<TableHeadCellProps> = ({
  children,
  className,
  contentClassName,
  sortable,
  active,
  sort,
  defaultSort,
  onSortToggle,
  sortableClassName = '',
  DescIcon,
  AscIcon,
  ...props
}) => {
  const textStyle = classNames(
    { 'text-primary': active, 'text-primary-500': !active },
    'w-full text-sm font-semibold',
    contentClassName,
  );
  const DescIconComp = DescIcon || ChevronDownIcon;
  const AscIconComp = AscIcon || ChevronUpIcon;
  return (
    <th
      className={classNames('p-3 rounded-tl-lg rounded-tr-lg', className)}
      {...props}
    >
      {sortable ? (
        <button
          onClick={onSortToggle}
          className={classNames(
            `${textStyle} hover:text-primary group flex items-center`,
            sortableClassName,
          )}
        >
          {children}
          <span
            className={classNames(
              'ml-1 flex-none rounded group-hover:visible  hover:text-primary relative top-px',
              {
                invisible: !active,
                visible: active,
              },
            )}
          >
            {sort === Sort.desc ? (
              <DescIconComp className="h-4 w-4" aria-hidden="true" />
            ) : (
              <AscIconComp className="h-4 w-4" aria-hidden="true" />
            )}
          </span>
        </button>
      ) : (
        <span className={textStyle}>{children}</span>
      )}
    </th>
  );
};
