import { ChevronRightIcon, UserIcon } from '@heroicons/react/outline';
import { CheckIcon, DocumentDuplicateIcon } from '@heroicons/react/solid';
import { createExpertRouteURL } from 'applications/expert/routing/navigationGenerator';
import GearIcon from 'assets/svg/icons/gearIcon';
import LogoutIcon from 'assets/svg/icons/LogoutIcon';
import classNames from 'classnames';
import { Avatar } from 'components/atoms/Avatar';
import { AvatarSize, AvatarType } from 'components/atoms/Avatar/Avatar.enum';
import { Loader } from 'components/atoms/Loader';
import { Menu } from 'components/atoms/Menu';
import ProfileIcon from 'components/atoms/SVG/sidebarIcons/ProfileIcon.component';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import logout from 'hooks/Authentication/useLogout';
import { useSubscriptionPlan } from 'hooks/Subscriptions';
import useResolveFileURL from 'hooks/useResolveFileURL';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionPlanNameEnum } from 'types/users.types';
import { isWeb } from 'utils/constants';
import { copy } from 'utils/helpers';
import {
  isSubscriptionAmbassadorPlan,
  isSubscriptionFreePlan,
  isSubscriptionTrialPlan,
} from 'utils/subscriptions.utils';
import NavigationItem from './NavigationItem.component';
import { SidebarNavigationItemTypesEnum } from './types';

const UserInfoItem: FC<{ label: string; value: string; canCopy?: boolean }> = ({
  label,
  value,
  canCopy,
}) => {
  const [copied, setCopied] = useState(false);

  return (
    <div className="flex items-center my-1">
      <span className="text-xs text-primary-400 font-semibold mr-1">
        {label}
      </span>
      <span className="text-xs text-primary-400 font-semibold">- {value}</span>

      {canCopy ? (
        <button
          className="ml-1"
          onClick={() => copy(value as string).then(() => setCopied(true))}
        >
          {copied ? (
            <CheckIcon className="w-[15px]" />
          ) : (
            <DocumentDuplicateIcon className="w-[15px] text-primary-400" />
          )}
        </button>
      ) : null}
    </div>
  );
};

const AccountMenu: FC = () => {
  const { t } = useTranslation();
  const subscriptionPlan = useSubscriptionPlan();
  const { data: user } = useGetCurrentCustomerData();

  const { data: picture, isLoading } = useResolveFileURL(
    user?.photoPath as string,
  );

  return (
    <Menu
      buttonProps={{ id: 'USER_MENU' }}
      position="top"
      trigger={(api) => (
        <NavigationItem
          active={api.open}
          titleContainerClassName="flex-1"
          item={{
            title: '',
            renderTitle: () =>
              isLoading ? (
                <div className="w-7 h-7 flex items-center justify-center">
                  <Loader />
                </div>
              ) : (
                <span
                  className={classNames(
                    'flex gap-1 flex-1 w-full items-center justify-between',
                    api.open ? '!text-main' : '',
                  )}
                >
                  <span className="flex-1">{t('avatarmenu.account')}</span>
                  <span>
                    <ChevronRightIcon className="w-4 h-4" />
                  </span>
                </span>
              ),
            Icon: UserIcon,
            type: SidebarNavigationItemTypesEnum.more,
          }}
        />
      )}
      contentWrapperClassName="left-[9.6rem] !ring-0 !rounded-xl !px-1"
      rootClassName="w-full"
    >
      <Menu.Group className="!px-2 py-1">
        <div className="flex gap-2">
          <div>
            {picture?.url ? (
              <div className="flex mt-1 items-center justify-center">
                <Avatar
                  type={AvatarType.circular}
                  key={picture?.url}
                  size={AvatarSize.sm}
                  src={picture?.url as string}
                />
              </div>
            ) : (
              <ProfileIcon />
            )}
          </div>
          <div>
            <div className="tex-sm font-semibold">{`${user?.firstName} ${user?.lastName}`}</div>

            <div className="text-xs text-primary-400">{user?.companyName}</div>

            {user?.VATNumber ? (
              <UserInfoItem
                label={t('settings.companyDetails.vatNumber')}
                value={user?.VATNumber}
                canCopy
              />
            ) : null}

            {user?.steuernummer ? (
              <UserInfoItem
                label={t('settings.companyDetails.steuernummer')}
                value={user?.steuernummer}
                canCopy
              />
            ) : null}
          </div>
        </div>
      </Menu.Group>
      <Menu.Group className="p-0 pt-3">
        <Menu.Link
          className="hover:!bg-primary-50  w-full"
          to={
            isWeb
              ? '/settings/personalDetails'
              : createExpertRouteURL(user?._id, '/settings/personalDetails')
          }
        >
          <span className="flex flex-1 items-center hover:!text-primary !text-main">
            <span className="inline-block mr-2">
              <GearIcon className="!w-5 !h-5" />
            </span>
            {isWeb
              ? t('avatarmenu.settings')
              : t('avatarmenu.settings.experts')}
          </span>
        </Menu.Link>
      </Menu.Group>
      {/* stripe / stripe-otp / qonversion / ios / android */}
      {subscriptionPlan &&
      (subscriptionPlan?.planInternalName === SubscriptionPlanNameEnum.trial1 ||
        subscriptionPlan?.planInternalName === SubscriptionPlanNameEnum.free ||
        ['stripe', 'stripe-otp', 'qonversion', 'ios', 'android'].includes(
          subscriptionPlan?.fromPlatform,
        )) &&
      isWeb &&
      !isSubscriptionAmbassadorPlan(subscriptionPlan.planInternalName) ? (
        <Menu.Link className="hover:!bg-primary-50 w-full" to="/subscriptions">
          <span className="flex items-center flex-1 hover:!text-primary text-main">
            <span className="inline-block mr-2">
              <UserIcon className="w-5" />
            </span>
            {t(
              isSubscriptionTrialPlan(subscriptionPlan?.planInternalName) ||
                isSubscriptionFreePlan(subscriptionPlan?.planInternalName)
                ? 'avatarmenu.upgrade_subscription'
                : 'avatarmenu.billing',
            )}
          </span>
        </Menu.Link>
      ) : null}
      {isWeb ? (
        <Menu.Button
          className="group mb-2 flex w-full !border-t-0 items-center rounded-md px-2 py-2 text-sm hover:!bg-primary-50 font-semibold"
          onClick={() => logout()}
        >
          <span className="flex flex-1 gap-2 items-center text-main hover:!text-primary">
            <LogoutIcon className="w-5 h-5" />
            <div id="USER_LOGOUT_BTN">{t('avatarmenu.logout')}</div>
          </span>
        </Menu.Button>
      ) : null}
    </Menu>
  );
};

export default AccountMenu;
