export const WEB_AIASSISTANT_USEROPENED = 'WEB_AIASSISTANT_USEROPENED';
export const WEB_AIASSISTANT_SELFTRIGGERED = 'WEB_AIASSISTANT_SELFTRIGGERED';
export const WEB_AIASSISTANT_USERSENTMESSAGE =
  'WEB_AIASSISTANT_USERSENTMESSAGE';
export const WEB_AIASSISTANT_TOGGLEDCONTEXT = 'WEB_AIASSISTANT_TOGGLEDCONTEXT';
export const WEB_AIASSISTANT_USERCLOSEDASSISTANT =
  'WEB_AIASSISTANT_USERCLOSEDASSISTANT';
export const WEB_AICATEGORYGUESSER_CATEGORYGUESSERTRIGGERED =
  'WEB_AICATEGORYGUESSER_CATEGORYGUESSERTRIGGERED';
export const WEB_AICATEGORYGUESSER_USERCHOSECATEGORYFROMCATEGORYGUESSER =
  'WEB_AICATEGORYGUESSER_USERCHOSECATEGORYFROMCATEGORYGUESSER';
export const WEB_AIEMAILREVIEW_EMAILREVIEWTRIGGERED =
  'WEB_AIEMAILREVIEW_EMAILREVIEWTRIGGERED';
export const WEB_AIEMAILREVIEW_EMAILREVIEWCONFIRMED =
  'WEB_AIEMAILREVIEW_EMAILREVIEWCONFIRMED';
