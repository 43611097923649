import getTransactionsOptions from 'api/v1/transactions/getTransactionsAsOptions';
import classNames from 'classnames';
import { Select } from 'components/atoms/Form/Select';
import type { SelectProps } from 'components/atoms/Form/Select/select.types';
import useDebounceQuery from 'hooks/useDebounceQuery';
import useResetableState from 'hooks/useResetableState';
import { isEmpty, uniqBy } from 'lodash';
import type { FC, ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BankTransactionsCacheKeys } from 'types/cacheKeys.types';
import type { Transaction } from 'types/transactions.types';
import { ONE_SECOND } from 'utils/time';
import transactionToOption from 'utils/transactionToOption';
import {
  SuggestedTransactionContainer,
  TransactionGroup,
  TransactionOption,
  TransactionOptionPreset,
} from './utils';

interface PaymentLinkProps extends SelectProps {
  value?: string[] | null;
  renderSelected: (selectedOptions?: any[]) => ReactNode;
  // onInitValuesLoadingSuccess?: (initSelected: TransactionAsOption[]) => void;
  suggestedTransactionOptions: {
    queryFn: () => Promise<Transaction | null>;
    queryKey: any[];
    enabled: boolean;
  };
  isDisabled?: boolean;
  onSuggestionActiveStatusChange?: (isActive: boolean) => void;
  suggestedTransactionHint?: string;
  suggestedTransactionHintClassName?: string;
}

const PaymentLink: FC<PaymentLinkProps> = (props) => {
  const { t } = useTranslation();

  const {
    data: suggestedTransaction,
    isLoading: isSuggestedTransactionLoading,
    isError: isSuggestedTransactionError,
    refetch: refetchSuggestedTransaction,
  } = useDebounceQuery({
    queryKey: props.suggestedTransactionOptions.queryKey,
    queryFn: () =>
      props.suggestedTransactionOptions
        .queryFn()
        .then((transaction) => transaction && transactionToOption(transaction)),
    enabled: props.suggestedTransactionOptions.enabled && !props.isDisabled,
    cacheTime: 0,
    debounceTime: ONE_SECOND / 2,
  });

  const [accumelatedSuggestedTransactions] = useResetableState({
    init: suggestedTransaction ? [suggestedTransaction] : [],
    resetFn: (prevSuggestions) => {
      if (!suggestedTransaction) return prevSuggestions;
      return uniqBy([suggestedTransaction, ...prevSuggestions], 'value');
    },
    deps: [suggestedTransaction],
  });

  const isValueEmpty = isEmpty(props.value);

  const [shouldShowSuggestedTransaction, setShouldShowSuggestedTransaction] =
    useResetableState({
      init: !!suggestedTransaction && isValueEmpty,
      deps: [suggestedTransaction, isValueEmpty],
    });

  useEffect(() => {
    props.onSuggestionActiveStatusChange?.(shouldShowSuggestedTransaction);
  }, [shouldShowSuggestedTransaction]);

  const paymentLinkFixedOptions = useMemo(() => {
    const suggestedGroup = {
      title: t('expense.field.link_payment.suggested_transactions'),
      subTitle: t('expense.field.link_payment.picked_up_scientifically'),
      options: accumelatedSuggestedTransactions,
    };
    return props.fixedOptions
      ? [].concat(props.fixedOptions, suggestedGroup as any)
      : [suggestedGroup];
  }, [accumelatedSuggestedTransactions, props.fixedOptions, t]);

  const isLoading = isSuggestedTransactionLoading;

  const isError = isSuggestedTransactionError;

  return (
    <>
      {shouldShowSuggestedTransaction && (
        <div className="mb-2">
          <SuggestedTransactionContainer
            className="my-2"
            transaction={(suggestedTransaction as any).transaction}
            onAccept={() => {
              props.onChange?.([suggestedTransaction], {
                action: 'select-option',
                option: suggestedTransaction,
              });
              setShouldShowSuggestedTransaction(false);
            }}
            onDecline={() => setShouldShowSuggestedTransaction(false)}
          />
          {props.suggestedTransactionHint && (
            <span
              className={classNames(
                props.suggestedTransactionHintClassName,
                'block leading-tight text-sm',
              )}
            >
              {props.suggestedTransactionHint}
            </span>
          )}
        </div>
      )}
      <Select
        cacheKey={BankTransactionsCacheKeys.PaymentLinkedList}
        key={props.name}
        mode="asyncOptionsPaginated"
        classNamePrefix="transactions"
        asyncSearch
        isMulti
        controlShouldRenderValue={false}
        isClearable={false}
        formatGroupLabel={(group: any) => (
          <TransactionGroup title={group.title} subTitle={group.subTitle} />
        )}
        formatOptionLabel={(option: any) => {
          if (option.preset) {
            return <TransactionOptionPreset label={option.label} />;
          }
          return <TransactionOption transaction={option.transaction} />;
        }}
        placeholder={t('expense.link_payment.type_to_search')}
        onFetch={getTransactionsOptions}
        {...props}
        fixedOptions={paymentLinkFixedOptions}
        mapOptions={({ fixedOptions, options }) => [
          ...fixedOptions,
          ...(accumelatedSuggestedTransactions.length
            ? [
                {
                  title: t('expense.field.link_payment.other_transactions'),
                  subTitle: t('sorted_by_date'),
                  options,
                },
              ]
            : options),
        ]}
        isLoading={isLoading}
        isError={isError}
        retry={() => {
          if (isSuggestedTransactionError) refetchSuggestedTransaction();
        }}
      />
    </>
  );
};

export default PaymentLink;
