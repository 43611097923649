import { getTaxesByYear } from 'api/v2/taxes';
import dayjs from 'dayjs';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useVATReturnFrequency from 'hooks/shared/useVATReturnFrequency';
import { set } from 'lodash';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { TaxesCacheKeys } from 'types/cacheKeys.types';
import type { VATReturnFrequency } from 'types/global.types';
import type { ITax } from 'types/taxes.types';
import { TaxTypeEnum } from 'types/taxes.types';
import type { UserCountry } from 'types/users.types';
import getTaxTitle from 'utils/taxes/getTaxTitle';
import create from 'zustand';

export const useSelectedTaxYear = create<{
  selectedYear: number;
  setSelectedYear: (year: number) => void;
}>((set) => ({
  selectedYear: 0,
  setSelectedYear: (selectedYear: number) => set({ selectedYear }),
}));

export const formatTaxItem =
  (
    vatReturnFrequency: VATReturnFrequency,
    customerCountry: UserCountry,
    titleFormatter?: (
      tax: ITax,
      vatReturnFrequency: VATReturnFrequency,
      customerCountry: UserCountry,
    ) => string | undefined,
  ) =>
  (tax: ITax) => {
    const _titleFormatter = titleFormatter || getTaxTitle;

    if (tax.type === TaxTypeEnum.specialVAT) {
      tax.isSpecial = true;

      set(tax, 'submission.type', TaxTypeEnum.vatSubmission);
      set(tax, 'payment.type', TaxTypeEnum.vatPayment);
    }

    if ([TaxTypeEnum.ustva, TaxTypeEnum.specialVAT].includes(tax.type)) {
      tax.type = TaxTypeEnum.vat;
    }

    tax.title = _titleFormatter(tax, vatReturnFrequency, customerCountry);

    return tax;
  };

const formatQuarter = (
  quarter: any,
  vatReturnFrequency: VATReturnFrequency,
  customerCountry: UserCountry,
) => ({
  ...quarter,
  items: quarter.items.map(formatTaxItem(vatReturnFrequency, customerCountry)),
});

const useGetTaxesByYear = ({
  year,
  afterSuccessCB,
}: {
  year: number;
  afterSuccessCB?: () => void;
}): UseQueryResult<ITax[]> => {
  const vatReturnFrequency = useVATReturnFrequency();
  const customerCountry = useCustomerCountry();
  return useQuery([TaxesCacheKeys.Taxes, year], () => getTaxesByYear(year), {
    onSuccess: () => {
      afterSuccessCB?.();
    },
    cacheTime: 0,
    enabled: !!year,
    select: (res) => ({
      ...res,
      data: {
        q1: formatQuarter(
          res?.data?.q1,
          vatReturnFrequency,
          customerCountry as UserCountry,
        ),
        q2: formatQuarter(
          res?.data?.q2,
          vatReturnFrequency,
          customerCountry as UserCountry,
        ),
        q3: formatQuarter(
          res?.data?.q3,
          vatReturnFrequency,
          customerCountry as UserCountry,
        ),
        q4: formatQuarter(
          res?.data?.q4,
          vatReturnFrequency,
          customerCountry as UserCountry,
        ),
        annual: formatQuarter(
          res.data?.annual,
          vatReturnFrequency,
          customerCountry as UserCountry,
        ),
      },
    }),
  });
};
const useGetSelectedYearTaxes = (
  taxYear?: number,
  afterOnSuccess?: () => void,
): UseQueryResult<any> => {
  const { selectedYear: selectedTabYear } = useSelectedTaxYear();

  return useGetTaxesByYear({
    year: taxYear || selectedTabYear || dayjs().year(),
    afterSuccessCB: afterOnSuccess,
  });
};

export default useGetSelectedYearTaxes;
