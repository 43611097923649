import { ShieldCheckIcon } from '@heroicons/react/outline';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useApplySMALL25Discount from 'hooks/Growth/useApplySMALL25Discount';
import { AvailableCountries, IUser } from 'types/users.types';

type ReturnType = {
  title: string;
  subtitle: string;
  description: string;
  name: string;
  discountBadgeText?: string;
  subscriptionText: string;
  badge?: { Icon: any; text: string };
};

const useSubscriptionPlanInfo = (planGroup: string): ReturnType => {
  const user = useGetCurrentCustomerData().data as IUser;
  const country = user.country;

  const isGermanUser = country === AvailableCountries.germany;
  const isBelgiumUser = country === AvailableCountries.belgium;

  const shouldShowSmall25Discount = useApplySMALL25Discount();

  const plansInformation = {
    free: {
      name: 'subscriptions_screen.plans.free',
      title: '',
      subtitle: 'subscriptions_screen.billed_free',
      description: '',
      subscriptionText: 'subscriptions_screen.plans.recommendation_title',
    },
    trial: {
      name: 'subscriptions_screen.plans.free',
      title: '',
      subtitle: 'subscriptions_screen.billed_free',
      description: '',
      subscriptionText: 'subscriptions_screen.plans.recommendation_title',
    },
    pro: {
      name: 'subscriptions_screen.plans.pro',
      title: 'subscriptions_screen.plans.pro.title',
      subtitle: '',
      description: 'subscriptions_screen.plans.pro.description',
      subscriptionText: 'subscriptions_screen.plans.subscribed_title',
      badge: isBelgiumUser
        ? {
            Icon: ShieldCheckIcon,
            text: 'subscriptions_screen.plans.tax_guarantee',
          }
        : undefined,
    },
    basic: {
      name: 'subscriptions_screen.plans.basic',
      title: 'subscriptions_screen.plans.basic.title',
      subtitle: '',
      description: 'subscriptions_screen.plans.basic.description',
      subscriptionText: 'subscriptions_screen.plans.recommendation_title',
    },
    small: {
      name: 'subscriptions_screen.plans.small',
      title: 'subscriptions_screen.plans.small.title',
      subtitle: '',
      description: '',
      discountBadgeText: shouldShowSmall25Discount
        ? 'subscriptions_screen.plans.small.AB_25_discount_badge_text_de'
        : isGermanUser
        ? 'subscriptions_screen.plans.small.discount_badge_text_de'
        : undefined,
      subscriptionText: 'subscriptions_screen.plans.subscribed_title',
      badge: isBelgiumUser
        ? {
            Icon: ShieldCheckIcon,
            text: 'subscriptions_screen.plans.tax_guarantee',
          }
        : undefined,
    },
  };

  return plansInformation[planGroup as keyof typeof plansInformation] || {};
};

export default useSubscriptionPlanInfo;
