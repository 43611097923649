import { Sort } from 'components/atoms/Table/table.types';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import zustandCleanup from 'utils/zustandCleanup';
import type { Get, InitData, Set, TableState } from './table.state.types';

const flipSortDirection = (direction: Sort) => {
  if (direction === Sort.asc) return Sort.desc;
  return Sort.asc;
};

const customIsEmpty = (data: any) => {
  if (typeof data === 'number') {
    return Number.isNaN(data);
  }
  if (typeof data === 'string') {
    return data.trim().length === 0;
  }
  return isEmpty(data);
};

const normalizeFiltersData = (filtersData: any) => {
  return Object.fromEntries(
    Object.entries(filtersData).filter(([, value]) => !customIsEmpty(value)),
  );
};

export const stateCreator = (initData: InitData) => (set: Set, get: Get) => {
  const { filtersData, tableSorter, registerCleanup } = initData;
  const normalizedFiltersData = normalizeFiltersData(filtersData);

  const meta = {
    page: initData.meta?.page || 1,
    selectedRows: [],
    filtersData: normalizedFiltersData,
    isCurrentFiltersDataEmpty: customIsEmpty(normalizedFiltersData),
    tableSorter,
    sort: tableSorter.cellId + '_' + tableSorter.direction,
  };

  const updateMeta = (update: Partial<TableState['meta']>) =>
    set((state) => ({ ...state, meta: { ...state.meta, ...update } }));

  const controllers = {
    setPageNumber: (page: number) => {
      updateMeta({ page });
      get().controllers.setSelectedRows([]);
    },
    setSelectedRows: (selectedRows: any[]) => updateMeta({ selectedRows }),
    setFiltersData: debounce((filtersData: any) => {
      const normalizedFiltersData = normalizeFiltersData(filtersData);
      updateMeta({
        filtersData: normalizedFiltersData,
        isCurrentFiltersDataEmpty: customIsEmpty(normalizedFiltersData),
      });
      get().controllers.setPageNumber(1);
      get().controllers.setSelectedRows([]);
    }, 350),

    setTableSort: (cellId: string) => {
      const prevSorter = get().meta.tableSorter;
      const direction =
        cellId === prevSorter?.cellId
          ? flipSortDirection(prevSorter.direction)
          : Sort.desc;

      // if the next direct is desc and the cellId is the same as the previous one
      // it means we should reset the sort to the default
      if (
        direction === Sort.desc &&
        cellId === prevSorter.cellId &&
        cellId !== tableSorter.cellId
      ) {
        updateMeta({
          tableSorter,
          sort: tableSorter.cellId + '_' + tableSorter.direction,
        });
      } else {
        updateMeta({
          tableSorter: { cellId, direction },
          sort: cellId + '_' + direction,
        });
      }
      get().controllers.setPageNumber(1);
      get().controllers.setSelectedRows([]);
    },
  };

  if (registerCleanup)
    zustandCleanup.registerCleanup(() => set(() => ({ meta })));

  return {
    meta,
    controllers,
  };
};
