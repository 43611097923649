import PageTitle from 'components/atoms/PageTitle.component';
import { assignLayoutToRoutes } from 'components/organisms/Layout/Layout.component';
import PaymentFailure from 'components/pages/PaymentFailure';
/// ---------------
import {
  CreateInvoice,
  EditInvoice,
} from 'components/pages/Revenues/GeneratedRevenueForm/InvoiceForm';
import {
  CreateQuote,
  EditQuote,
} from 'components/pages/Revenues/GeneratedRevenueForm/QuoteForm';
import {
  CreateRecurringInvoice,
  EditRecurringInvoice,
} from 'components/pages/Revenues/GeneratedRevenueForm/RecurringInvoiceForm';
/// ---------------
import Amortization from 'components/organisms/SettingsViews/Amortization';
import Subscriptions from 'components/pages/Subscriptions';
import YearlyReview from 'components/pages/YearlyReview';
import { getReviewYears } from 'components/pages/YearlyReview/helpers';
import { lazy } from 'react';
import type { RoutesListType } from 'types/routing.types';
import navigationGenerator from './navigationGenerator';
const RecurringRevenues = lazy(
  () => import('components/pages/RecurringRevenues'),
);
const Home = lazy(() => import('components/pages/Home'));
const Banks = lazy(() => import('components/pages/Banks'));
const DataExport = lazy(() => import('components/pages/DataExport'));
const MobileApp = lazy(() => import('components/pages/MobileApp'));
const Expenses = lazy(() => import('components/pages/Expenses'));
const Revenues = lazy(() => import('components/pages/Revenues'));
const Taxes = lazy(() => import('components/pages/Taxes'));
const NotFoundPage = lazy(() => import('components/pages/404.component'));
const Clients = lazy(() => import('components/pages/Clients'));
const Quotes = lazy(() => import('components/pages/Quotes'));
const Tax = lazy(() => import('components/pages/Taxes/Tax/Tax'));
const Settings = lazy(() => import('../pages/Settings'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const UserLogin = lazy(() => import('../pages/UserLogin'));
const UserSignup = lazy(() => import('../pages/UserSignup'));
const Assistant = lazy(() => import('components/pages/AI'));
const ExpensesAnalytics = lazy(
  () => import('components/pages/Analytics/ExpensesAnalytics.component'),
);
const RevenuesAnalytics = lazy(
  () => import('components/pages/Analytics/RevenuesAnalytics.component'),
);
const ProfitAnalytics = lazy(
  () => import('components/pages/Analytics/ProfitAnalytics.component'),
);
const TOTP = lazy(() => import('../pages/TOTP'));
const RecoveryCode = lazy(() => import('../pages/RecoveryCode'));

export const routes: RoutesListType = [
  {
    path: '/',
    Component: (
      <PageTitle slug="home.title">
        <Home />
      </PageTitle>
    ),
    withLayout: true,
    private: true,
    ignoreAmplitude: true,
  },
  {
    path: '/banks',
    Component: (
      <PageTitle slug="transactions.title">
        <Banks />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/expenses',
    Component: (
      <PageTitle slug="expenses.title">
        <Expenses />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
    showBgImage: true,
  },
  {
    path: '/revenues',
    Component: (
      <PageTitle slug="invoices.title">
        <Revenues />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/quotes/create',
    Component: <CreateQuote />,
    private: true,
    withLayout: true,
    showBgImage: false,
    className: 'bg-primary-25',
  },
  {
    path: '/quotes/:quoteId',
    Component: <EditQuote />,
    private: true,
    withLayout: true,
    showBgImage: false,
    className: 'bg-primary-25',
  },
  {
    path: '/revenues/create',
    Component: <CreateInvoice />,
    private: true,
    withLayout: true,
    showBgImage: false,
    className: 'bg-primary-25',
  },
  {
    path: '/revenues/:revenueId',
    Component: <EditInvoice />,
    private: true,
    showBgImage: false,
    withLayout: true,
    className: 'bg-primary-25',
  },
  {
    path: '/recurring-revenues',
    Component: <RecurringRevenues />,
    private: true,
    withLayout: true,
    showBgImage: true,
  },
  {
    path: '/recurring-revenues/create',
    Component: <CreateRecurringInvoice />,
    private: true,
    showBgImage: false,
    withLayout: true,
    className: 'bg-primary-25',
  },
  {
    path: '/recurring-revenues/:revenueId',
    Component: <EditRecurringInvoice />,
    private: true,
    showBgImage: false,
    withLayout: true,
    className: 'bg-primary-25',
  },
  {
    path: '/taxes/:country/:taxSlug/:type/:periodSlug',
    Component: (
      <PageTitle slug="taxes.title">
        <Tax />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
    showBgImage: false,
    ignoreAmplitude: true,
  },
  {
    path: '/taxes/:country/:taxSlug/:periodSlug',
    Component: (
      <PageTitle slug="taxes.title">
        <Tax />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
    showBgImage: false,
    ignoreAmplitude: true,
  },
  {
    path: '/clients',
    Component: (
      <PageTitle slug="clients.title">
        <Clients />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/quotes',
    Component: (
      <PageTitle slug="quotes.title">
        <Quotes />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/taxes',
    Component: (
      <PageTitle slug="taxes.title">
        <Taxes />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/assistant',
    Component: <Assistant />,
    private: true,
    withLayout: true,
    className: '!pt-8 pb-2 px-14',
  },
  {
    path: '/export',
    className: '!px-8 !py-0',
    Component: (
      <PageTitle slug="menu.export_data">
        <DataExport />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/mobile',
    Component: (
      <PageTitle slug="mobile_app.title">
        <MobileApp />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/settings/*',
    Component: (
      <PageTitle slug="settings.title">
        <Settings />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
    showBgImage: false,
  },
  {
    path: '/amortization',
    Component: <Amortization />,
    private: true,
    withLayout: true,
  },
  {
    path: '/subscriptions',
    Component: <Subscriptions />,
    private: true,
    withLayout: false,
  },
  {
    path: '/paymentFailed',
    Component: <PaymentFailure />,
    private: true,
    withLayout: false,
  },
  {
    path: '/expenses/analytics',
    Component: (
      <PageTitle slug="expenses.analytics.title">
        <ExpensesAnalytics />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/revenues/analytics',
    Component: (
      <PageTitle slug="revenues.analytics.title">
        <RevenuesAnalytics />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/yearly-review',
    Component: (
      <PageTitle
        slug="yearly_review.title"
        params={{
          year: getReviewYears().year,
        }}
      >
        <YearlyReview />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/taxes/analytics',
    Component: (
      <PageTitle slug="profit.analytics.title">
        <ProfitAnalytics />
      </PageTitle>
    ),
    private: true,
    withLayout: true,
  },
  {
    path: '/signup',
    Component: <UserSignup />,
    onlyBeforeAuth: true,
    private: false,
    withLayout: false,
  },
  {
    path: '/login',
    Component: <UserLogin />,
    private: false,
    withLayout: false,
  },
  {
    path: '/login/totp',
    Component: <TOTP />,
    private: false,
    withLayout: false,
  },
  {
    path: '/login/recovery-code',
    Component: <RecoveryCode />,
    private: false,
    withLayout: false,
  },
  {
    path: '/reset',
    Component: <ResetPassword />,
    private: false,
    withLayout: false,
  },
  {
    path: '*',
    Component: <NotFoundPage />,
    private: false,
    withLayout: false,
  },
];

export default assignLayoutToRoutes(routes, navigationGenerator);
