import ContainerWithBorder from 'components/atoms/ContainerWithBorder';
import MoneyCounter from 'components/atoms/MoneyCounter';
import appI18nInstance from 'i18n';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import type { YearlyReviewData } from 'types/global.types';
import formatMoney from 'utils/formatMoney';
import { isNumeric, round } from 'utils/math';
import { getReviewYears, prependPositiveSign } from './helpers';

const getInvoicesSubtitle = (sent: number, unpaid: number) => {
  if (!sent)
    return appI18nInstance.t(
      'yearly_review.quarters_widget.invoices.no_invoices',
    );

  if (sent > 0 && unpaid > 0)
    return appI18nInstance.t(
      'yearly_review.quarters_widget.invoices.positive_sent_and_unpaid',
      {
        sent_invoices: sent,
        unpaid_invoices: unpaid,
      },
    );

  if (sent > 0 && !unpaid)
    return appI18nInstance.t(
      'yearly_review.quarters_widget.invoices.positive_sent_only',
      {
        sent_invoices: sent,
      },
    );

  return null;
};

const QuarterCard: FC<{
  abbrv: string;
  revenueAmount: number;
  deductibleExpensesAmount: number;
  numberOfSentInvoices: number;
  numberOfPaidInvoices: number;
  deductibleExpensesChangePercentile: number;
  highestExpense?: { amount?: number; supplier?: string };
}> = ({
  abbrv,
  revenueAmount,
  deductibleExpensesAmount,
  numberOfSentInvoices,
  numberOfPaidInvoices,
  deductibleExpensesChangePercentile,
  highestExpense,
}) => {
  const { t } = useTranslation();

  const { previousYear } = getReviewYears();

  return (
    <ContainerWithBorder containerClassName="w-[49%] mb-[2%] !h-auto">
      <div className="flex flex-col items-center py-7">
        <p className="w-16 h-16 rounded-full bg-taxes flex items-center justify-center text-white font-bold text-2xl border-4 border-taxes-50 mb-10">
          {abbrv}
        </p>

        <h3 className="text-primary-500 font-bold text-xl mb-4">
          {t('yearly_review.quarters_widget.total_revenues')}
        </h3>

        <div className="font-avenir text-4xl mb-3 font-semibold">
          <MoneyCounter
            amount={revenueAmount}
            currency="EUR"
            stateId={`yearlyReview${abbrv}RevenueAmount`}
            noFractions
          />
        </div>

        <span className="px-3 py-[3px] rounded-full border border-primary-100 font-semibold text-primary-700 text-sm">
          {getInvoicesSubtitle(numberOfSentInvoices, numberOfPaidInvoices)}
        </span>

        <h3 className="text-primary-500 font-bold text-xl mt-6 mb-4">
          {t('yearly_review.quarters_widget.expenses')}
        </h3>

        <div className="font-avenir text-4xl mb-3 font-semibold">
          <MoneyCounter
            amount={deductibleExpensesAmount}
            currency="EUR"
            stateId={`yearlyReview${abbrv}DeductibleExpensesAmount`}
            noFractions
          />
        </div>

        {isNumeric(deductibleExpensesChangePercentile) ? (
          <span className="px-3 py-[3px] rounded-full border border-primary-100 font-semibold text-primary-700 text-sm">
            {prependPositiveSign(deductibleExpensesChangePercentile)}% vs{' '}
            {abbrv} {previousYear}
          </span>
        ) : null}

        {highestExpense?.amount && highestExpense?.supplier ? (
          <div className="bg-taxes-200 text-center p-6 py-3 mt-7 rounded-lg w-[90%]">
            <ReactMarkdown
              components={{
                p: ({ children }) => (
                  <p className="text-taxes text-sm font-semibold">{children}</p>
                ),
                strong: ({ children }) => (
                  <p className="text-taxes !text-base !font-bold">{children}</p>
                ),
              }}
            >
              {t('yearly_review.quarters_widget.top_expense', {
                amount: formatMoney(highestExpense.amount),
                supplier: highestExpense.supplier,
              })}
            </ReactMarkdown>
          </div>
        ) : null}
      </div>
    </ContainerWithBorder>
  );
};

const QuartersSummarySection: FC<{ data?: YearlyReviewData }> = ({ data }) => {
  const { t } = useTranslation();

  const thisYearQuartersTurnover = data?.thisYear?.turnover?.perQuarter || [];

  const thisYearQuartersDeductibleExpenses =
    data?.thisYear?.deductibleExpenses?.perQuarter || [];

  const lastYearQuartersDeductibleExpenses =
    data?.lastYear?.deductibleExpenses?.perQuarter || [];

  const thisYearQuartersHighestAmountExpenses =
    data?.thisYear?.expenses?.highestAmount?.perQuarter || [];

  const getDeductibleExpensesChangePercentile = (quarter: 1 | 2 | 3 | 4) => {
    const thisYearAmount = thisYearQuartersDeductibleExpenses?.[quarter - 1];
    const lastYearAmount = lastYearQuartersDeductibleExpenses?.[quarter - 1];

    return ((thisYearAmount - lastYearAmount) / lastYearAmount) * 100;
  };

  return (
    <section className="mt-10 flex flex-wrap gap-[2%]">
      <QuarterCard
        abbrv={t('quarter_abr', { quarter: 1 })}
        revenueAmount={round(thisYearQuartersTurnover?.[0] || 0)}
        deductibleExpensesAmount={round(
          thisYearQuartersDeductibleExpenses?.[0] || 0,
        )}
        numberOfSentInvoices={
          data?.thisYear?.invoices?.totalCount?.perQuarter?.[0] || 0
        }
        numberOfPaidInvoices={
          data?.thisYear?.invoices?.unpaidCount?.perQuarter?.[0] || 0
        }
        deductibleExpensesChangePercentile={round(
          getDeductibleExpensesChangePercentile(1),
        )}
        highestExpense={thisYearQuartersHighestAmountExpenses?.[0]}
      />

      <QuarterCard
        abbrv={t('quarter_abr', { quarter: 2 })}
        revenueAmount={round(thisYearQuartersTurnover?.[1] || 0)}
        deductibleExpensesAmount={round(
          thisYearQuartersDeductibleExpenses?.[1] || 0,
        )}
        numberOfSentInvoices={
          data?.thisYear?.invoices?.totalCount?.perQuarter?.[1] || 0
        }
        numberOfPaidInvoices={
          data?.thisYear?.invoices?.unpaidCount?.perQuarter?.[1] || 0
        }
        deductibleExpensesChangePercentile={round(
          getDeductibleExpensesChangePercentile(2),
        )}
        highestExpense={thisYearQuartersHighestAmountExpenses?.[1]}
      />

      <QuarterCard
        abbrv={t('quarter_abr', { quarter: 3 })}
        revenueAmount={round(thisYearQuartersTurnover?.[2] || 0)}
        deductibleExpensesAmount={round(
          thisYearQuartersDeductibleExpenses?.[2] || 0,
        )}
        numberOfSentInvoices={
          data?.thisYear?.invoices?.totalCount?.perQuarter?.[2] || 0
        }
        numberOfPaidInvoices={
          data?.thisYear?.invoices?.unpaidCount?.perQuarter?.[2] || 0
        }
        deductibleExpensesChangePercentile={round(
          getDeductibleExpensesChangePercentile(3),
        )}
        highestExpense={thisYearQuartersHighestAmountExpenses?.[2]}
      />

      <QuarterCard
        abbrv={t('quarter_abr', { quarter: 4 })}
        revenueAmount={round(thisYearQuartersTurnover?.[3] || 0)}
        deductibleExpensesAmount={round(
          thisYearQuartersDeductibleExpenses?.[3] || 0,
        )}
        numberOfSentInvoices={
          data?.thisYear?.invoices?.totalCount?.perQuarter?.[3] || 0
        }
        numberOfPaidInvoices={
          data?.thisYear?.invoices?.unpaidCount?.perQuarter?.[3] || 0
        }
        deductibleExpensesChangePercentile={round(
          getDeductibleExpensesChangePercentile(4),
        )}
        highestExpense={thisYearQuartersHighestAmountExpenses?.[3]}
      />
    </section>
  );
};

export default QuartersSummarySection;
