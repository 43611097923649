import type { FC, MutableRefObject, ReactNode } from 'react';
import { useRef } from 'react';

type UseRefProps = {
  init?: any;
  render: (ref: MutableRefObject<any>) => ReactNode;
};

const UseRef: FC<UseRefProps> = (props) => {
  const ref = useRef(props.init);
  return <>{props.render(ref)}</>;
};

export default UseRef;
