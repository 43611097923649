import axiosInstance from 'api/axiosInstance';
import { AxiosRequestConfig } from 'axios';
import { stringify } from 'query-string';
import type { ClientTypeEnum, IClient } from 'types/clients.types';
import type {
  IPaginationRequest,
  IPaginationResponse,
  Paging,
} from 'types/pagination.types';
import { onCreateClientGTM } from 'utils/GTM';

const service = '/v2/clients';

type IAPIGetClientsParams = {
  locations?: string[];
  text?: string;
} & Partial<IPaginationRequest>;

type IAPIBulkUpdateExpensesResponse = {
  _id: string;
  ok: boolean;
  status: string;
  errors?: { message: string; code: string }[];
  type?: string;
}[];

type IAPIGetClientsResponse = IPaginationResponse & { data: IClient[] };

type IAPICreateClientBody = {
  name: string;
  email?: string;
  type: ClientTypeEnum;
  VATStatus: string;
  VATNumber?: string;
  address: {
    street?: string;
    city?: string;
    zip?: string;
    country: string;
  };
};

// TODO: return client type
export const getClients = (
  query: IAPIGetClientsParams,
): Promise<IAPIGetClientsResponse> =>
  axiosInstance.get(
    `${service}?${stringify(query, {
      arrayFormat: 'comma',
      skipEmptyString: true,
    })}`,
  );

export const getClientsOptions = (): Promise<
  { label: string; value: string; client: IClient }[]
> =>
  axiosInstance.get(service).then((data) =>
    data.data.map((client: IClient) => ({
      label: client.name,
      value: client._id,
      client,
    })),
  );

// TODO: return client type
export const getClientById = (
  id: string,
  config?: AxiosRequestConfig<any>,
): Promise<IClient> => axiosInstance.get(`${service}/${id}`, config);

export const deleteClient = (id: string): Promise<void> =>
  axiosInstance.delete(`${service}/${id}`);

// TODO: return client type
export const createClient = (body: IAPICreateClientBody): Promise<IClient> =>
  axiosInstance.post<any, IClient>(`${service}`, body).then((data) => {
    onCreateClientGTM();
    return data;
  });

export const updateClient = (
  id: string,
  body: IAPICreateClientBody,
): Promise<IClient> => axiosInstance.put(`${service}/${id}`, body);

export const bulkDeleteClients = (
  ids: string[],
): Promise<IAPIBulkUpdateExpensesResponse> =>
  axiosInstance.delete(
    `${service}/bulk?${stringify(
      { ids },
      { arrayFormat: 'comma', skipEmptyString: true },
    )}`,
  );

type SuggestedClientsParams = { name?: string } & Partial<IPaginationRequest>;
type SuggestedClientsResponse = {
  data: { name: string; _id: string }[];
  paging: Paging;
};

export const getSuggestedClients = (
  params?: SuggestedClientsParams,
): Promise<SuggestedClientsResponse> =>
  axiosInstance.get(
    `${service}-temp?${stringify(
      { page: 1, perPage: 25, ...params },
      {
        skipEmptyString: true,
      },
    )}`,
  );
