import dayjs from 'dayjs';
import { get, isNumber } from 'lodash';
import type { ITax } from 'types/taxes.types';
import { TaxTypeEnum } from 'types/taxes.types';
import { VATTypeEnum, type IUser } from 'types/users.types';
import isTaxDone from 'utils/taxes/isTaxDone';
import SpecialVATStatementTableData from './SpecialVATStatementTableData.json';
import VATStatementTableData from './VATStatementTableData.json';

const today = dayjs();
const currentYear = today.year();
const taxes = {
  firstClosingDate: dayjs(`01/21/${currentYear}`),
  canSkipPrepayment: true,
  incomeTaxSlug: 'be/income-tax-submission',
  getTaxAvailablityMessage: () =>
    'taxes.api.not_available_yet_from_authorities',
  incomeTaxPaymentSlug: 'be/income-tax-payment',
  getStatementTableData: (tax: ITax) => {
    switch (tax.type) {
      case TaxTypeEnum.vatSubmission: {
        if (tax.isSpecial) {
          return SpecialVATStatementTableData;
        }
        return VATStatementTableData;
      }
    }
  },
  vat_breakdown_get_path: 'be/vat',
  required_payment_summary_field: 'vat_number',
  eur_breakdown_get_path: '',
  startDateForIncomeTaxSubmission: dayjs(`01-05-${currentYear}`),
  download_vat_submission_xml: true,
  required_data_for_intraeu_export: ['VATNumber', 'Address', 'PhoneNumber'],
  required_data_for_vat_submission: ['VATNumber', 'Address'],
  hasCopyrightHelper: true,
  incomTaxStillToPay: (tax: ITax) => {
    const isDone = isTaxDone(tax);
    const isCurrentYear = currentYear === tax.period.year;

    const ofWhichAlreadyPaid = (() => {
      return (
        (get(tax, 'incomeTaxPrepaymentTotal') || 0) +
        (get(tax, 'estimatedIncomeTaxOnSalary') || 0)
      );
    })();

    if (isCurrentYear && !isDone)
      return (
        (get(tax, 'estimatedOtherTaxes') || 0) +
        (get(tax, 'estimatedIncomeTaxOnSalary') || 0) +
        (get(tax, 'projectedIncomeTax') || 0) -
        ofWhichAlreadyPaid
      );
    return (
      (get(tax, 'estimatedOtherTaxes') || 0) +
      (get(tax, 'estimatedIncomeTaxOnSalary') || 0) +
      (get(tax, 'estimatedIncomeTax') || 0) -
      ofWhichAlreadyPaid
    );
  },
  submission_description_suffix:
    'taxes.submission_history.alert.description.be',
  getIsReimbursement: (tax: ITax): boolean => {
    if (tax?.type === TaxTypeEnum.vatPayment) {
      return (
        isNumber(get(tax, 'statement.c72')) && get(tax, 'statement.c72') > 0
      );
    }
    if (tax?.type === TaxTypeEnum.incomeTaxPayment)
      return get(tax, 'suggestedPrepaymentAmount') < 0;
    return false;
  },
  shouldShowCompanyTaxRate: true,
  blocked: {
    web: false,
    experts: false,
  },
  fullTermsLink: 'https://finances.belgium.be/fr/entreprises/tva/amendes',
  can_make_vat_as_expense: false,
  vat: {
    frequency: ['quarterly'], // Could have more than one value, possibly 'yearly' and/or 'monthly' depending on the country
    submission_dates: {
      quarterly: [
        {
          day: 20,
          month: 4,
        },
        {
          day: 10,
          month: 8,
        },
        {
          day: 20,
          month: 10,
        },
        {
          day: 20,
          month: 1,
        },
      ],
    },
    due_dates: {
      quarterly: [
        {
          day: 20,
          month: 4,
        },
        {
          day: 20,
          month: 7,
        },
        {
          day: 20,
          month: 10,
        },
        {
          day: 20,
          month: 1,
        },
      ],
    },
  },
  social_contributions: {
    frequency: ['quarterly'],
    due_dates: {
      quarterly: [
        {
          day: 25,
          month: 3,
        },
        {
          day: 25,
          month: 6,
        },
        {
          day: 25,
          month: 9,
        },
        {
          day: 25,
          month: 12,
        },
      ],
    },
  },
  vat_return_frequency: ['quarterly'],
  default_vat_return_frequency: 'quarterly',
  hasProfitAndLossBreakdown: true,
  no_negative_vat_cells: ['c71', 'c72'],
  available_export_modules: [
    { key: 'excel', value: 'excel' },
    { key: 'original_documents', value: 'original_documents' },
    { key: 'profit_and_loss', value: 'profit_and_loss' },
    { key: 'ubl', value: 'ubl' },
    { key: 'pl', value: 'pl' },
    { key: 'coda', value: 'coda' },
    { key: 'pdf', value: 'pdf' },
    { key: 'amortisation_table', value: 'amortisation_table' },
  ],
  taxes_onboarding_modal_texts: {
    canUse: [
      'taxes.onboarding_modal.can_use_text_1.be',
      'taxes.onboarding_modal.can_use_text_2.be',
      'taxes.onboarding_modal.can_use_text_3.be',
      'taxes.onboarding_modal.can_use_text_4.be',
      'taxes.onboarding_modal.can_use_text_5.be',
    ],
    cannotUse: [
      'taxes.onboarding_modal.cannot_use_text_1.be',
      'taxes.onboarding_modal.cannot_use_text_2.be',
      'taxes.onboarding_modal.cannot_use_text_3.be',
      'taxes.onboarding_modal.cannot_use_text_4.be',
      'taxes.onboarding_modal.cannot_use_text_5.be',
      'taxes.onboarding_modal.cannot_use_text_6.be',
      'taxes.onboarding_modal.cannot_use_text_7.be',
      'taxes.onboarding_modal.cannot_use_text_8.be',
    ],
  },
  onboardingTaxObligations: (user: IUser) => {
    const mapper = {
      [VATTypeEnum.subjectToVAT]: [
        {
          title: 'taxes_onboarding.obligations.vat',
          frequency: 'quarterly',
          helper: 'taxes_onboarding.obligations.vat.helper.be',
        },
        {
          title: 'taxes_onboarding.obligations.income_tax',
          frequency: 'yearly',
          helper: 'taxes_onboarding.obligations.income_tax.helper.be',
        },
        {
          title: 'taxes_onboarding.obligations.client_listing',
          frequency: 'yearly',
          helper: 'taxes_onboarding.obligations.client_listing.helper.be',
        },
        {
          title: 'taxes_onboarding.obligations.social_contributions',
          frequency: 'quarterly',
          helper: 'taxes_onboarding.obligations.social_contributions.helper.be',
        },
      ],
      default: [
        {
          title: 'taxes_onboarding.obligations.income_tax',
          frequency: 'yearly',
          helper: 'taxes_onboarding.obligations.income_tax.helper.be',
        },
        {
          title: 'taxes_onboarding.obligations.client_listing',
          frequency: 'yearly',
          helper: 'taxes_onboarding.obligations.client_listing.helper.be',
        },
        {
          title: 'taxes_onboarding.obligations.social_contributions',
          frequency: 'quarterly',
          helper: 'taxes_onboarding.obligations.social_contributions.helper.be',
        },
      ],
    };

    return mapper[user?.VATType as keyof typeof mapper] || mapper.default;
  },
} as const;

export default taxes;
