import { renderBody } from 'applications/web/pages/UserSignup/Shared/helpers';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { Modal } from 'components/atoms/Modal';
import { Title } from 'components/atoms/Title';
import { SizesEnum } from 'components/atoms/Title/Title.enum';
import useHasPassedVATThreshold from 'hooks/Taxes/useHasPassedVATThreshold';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import formatMoney from 'utils/formatMoney';

const PassedVATThreshold: FC = () => {
  const { t } = useTranslation();
  const { hasPassedVATThreshold, country, period, threshold } =
    useHasPassedVATThreshold();
  if (!hasPassedVATThreshold) {
    return null;
  }

  return (
    <div className="mt-6 mb-4">
      <Alert
        dismissable
        type="warning"
        description={
          <Modal
            name="create revenue after passing threshold"
            width="4xl"
            trigger={(api) => (
              <ReactMarkdown
                components={{
                  a: ({ children }) => (
                    <Button
                      onClick={api.openModal}
                      className="underline !text-yellow-900 !hover:text-yellow-800 font-semibold"
                      structure="text"
                    >
                      {children}
                    </Button>
                  ),
                }}
              >
                {t(`taxes.vat_franchisee.create_revenue_helper_${country}`)}
              </ReactMarkdown>
            )}
            render={() => (
              <div className="px-4 py-4">
                <Title type={SizesEnum.h5} className="pb-6">
                  {t(`taxes.vat_franchisee.title.${country}`)}
                </Title>
                <div>
                  {t(
                    `taxes.vat_franchisee.passed_threshold_helper_${country}`,
                    {
                      amount: formatMoney(threshold || 0),
                      year: period?.year,
                    },
                  )
                    .split('\n')
                    .map((paragraph) => (
                      <div key={paragraph} className="mb-3">
                        {renderBody(paragraph)}
                      </div>
                    ))}
                </div>
              </div>
            )}
          />
        }
      />
    </div>
  );
};

export default PassedVATThreshold;
