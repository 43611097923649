import classNames from 'classnames';
import useWorldTheme from 'hooks/shared/useWorldTheme';
import { useHighlight } from 'hooks/useHighlight';
import type { FC } from 'react';
import { forwardRef } from 'react';
import { getTracingEvents } from 'utils/amplitude';
import { Loader } from '../Loader';
import type { ButtonComponentProps } from './Button.types';

const theme = {
  structure: {
    primary:
      'shadow-sm focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 border border-transparent',
    secondary:
      'bg-white border border-gray-300 shadow-sm focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2',
    text: 'focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 focus-visible:ring-primary',
  },
  colors: {
    primary: {
      bank: 'bg-bank hover:bg-bank-800 focus-visible:ring-bank text-white hover:text-white',
      expenses:
        'bg-expenses disabled:bg-gray-400 hover:bg-expenses-800 focus-visible:ring-expenses text-white hover:text-white',
      primary:
        'bg-primary hover:bg-primary-800 disabled:bg-primary-800 focus-visible:ring-primary text-white hover:text-white',
      taxes:
        'bg-taxes hover:bg-taxes-800 focus-visible:ring-taxes text-white hover:text-white',
      revenues:
        'bg-revenues disabled:bg-gray-400 hover:bg-revenues-800 focus-visible:ring-revenues text-white hover:text-white',
      red: 'bg-red-500 hover:bg-red-400 focus-visible:ring-red-500 text-white hover:text-white',
      yellow:
        'bg-yellow-500 hover:bg-yellow-400 focus-visible:ring-yellow-500 text-white hover:text-white',
      main: 'bg-primary-700 hover:bg-primary-500 focus-visible:ring-primary-700 text-white hover:text-white',
    },
    secondary: {
      expenses:
        'text-expenses border-expenses hover:bg-expenses-700 hover:border-expenses-700 hover:text-white disabled:text-expenses disabled:bg-transparent focus-visible:ring-expenses',
      bank: 'text-bank border-bank hover:bg-bank hover:text-white',
      primary:
        'text-primary border-primary hover:bg-primary hover:text-white disabled:text-primary-800 disabled:border-primary-800',
      taxes: 'text-taxes border-taxes hover:bg-taxes hover:text-white',
      revenues:
        'text-revenues border-revenues hover:bg-revenues hover:text-white disabled:text-revenues disabled:bg-transparent focus-visible:ring-revenues',
      red: 'text-red-500 border-red-500 hover:bg-red hover:text-white',
      yellow:
        'text-yellow-500 border-yellow-500 hover:bg-yellow hover:text-white',
      main: 'text-primary-700 border-primary-700 hover:bg-primary-500 hover:text-white',
    },
    text: {
      bank: 'text-bank hover:text-bank-800',
      expenses: 'text-expenses hover:text-expenses-800 disabled:text-gray-400',
      primary: 'text-primary hover:text-primary-800 disabled:text-primary-800',
      taxes: 'text-taxes hover:text-taxes-800',
      revenues: 'text-revenues hover:text-revenues-800',
      red: 'text-red-500 hover:text-red-400',
      yellow: 'text-yellow-500 hover:text-yellow-400',
      main: 'text-primary-700 hover:text-primary-500',
    },
  },
  size: {
    xs: 'px-2.5 py-1.5 text-xs',
    sm: 'px-3 py-2 text-sm leading-4',
    md: 'px-4 py-2 text-sm',
    lg: 'px-6 py-2 text-base',
    xl: 'px-6 py-3 text-base',
  },
  icon: {
    size: {
      xs: 'w-3.5 h-3.5',
      sm: 'w-4 h-4',
      md: 'w-4 h-4',
      lg: 'w-5 h-5',
      xl: 'w-5 h-5',
    },
  },
};

const Button: FC<ButtonComponentProps> = forwardRef(
  (props, ref): JSX.Element => {
    const {
      children,
      disabled,
      className: _className,
      onClick,
      asDefault,
      //--
      iconPlacement = 'left',
      loading,
      icon: Icon,
      structure = 'primary',
      color,
      size = 'md',
      tracingEvent,
      ariaDisabled,
      highlightID,
      ...rest
    } = props;
    const worldColor = useWorldTheme();

    const tracingEvents = getTracingEvents(
      tracingEvent ? { onClick: tracingEvent } : {},
      { onClick },
    );
    const highlightAPI = useHighlight();

    const className = classNames(
      _className,
      highlightAPI.shouldHighlight(highlightID) && highlightAPI.className,
    );
    if (asDefault) {
      return (
        <button
          disabled={disabled || loading}
          {...(disabled || ariaDisabled
            ? { 'aria-disabled': true, tabIndex: -1 }
            : null)}
          className={className}
          onClick={
            disabled || ariaDisabled || loading
              ? undefined
              : tracingEvents.onClick
          }
          type="button"
          ref={ref}
          {...rest}
        >
          {children}
        </button>
      );
    }
    return (
      <button
        data-highlight-id={highlightID}
        type="button"
        // keep the key, the translation browser extension can change the dom content of the button children
        // react has issues with that when it tries to update the button if its loading
        // so we workaround it by changing the button key to make react destroy the old one and build a new one
        key={loading ? '_loading' : '_'}
        // if loading or disabled, button is disabled
        disabled={disabled || loading}
        {...(disabled || ariaDisabled
          ? { 'aria-disabled': true, tabIndex: -1 }
          : null)}
        className={classNames(
          'disabled:cursor-not-allowed inline-flex items-center justify-center font-semibold rounded-full focus:outline-none',
          theme.structure[structure],
          theme.colors[structure][color || worldColor],
          theme.size[size],
          { 'px-0 !py-0': structure === 'text' },
          className,
        )}
        onClick={
          disabled || ariaDisabled || loading
            ? undefined
            : tracingEvents.onClick
        }
        ref={ref}
        {...rest}
      >
        {loading ? <Loader size={size} className={'mr-2'} /> : ''}
        {/* Show Icon on left if requested and not loading */}
        {Icon && !loading && iconPlacement === 'left' ? (
          <Icon
            aria-hidden
            className={classNames(theme.icon.size[size], {
              'mr-2': !!children,
            })}
          />
        ) : (
          ''
        )}
        {/* Show content */}
        {children}
        {/* Show Icon on right if requested and not loading */}
        {Icon && !loading && iconPlacement === 'right' ? (
          <Icon
            aria-hidden
            className={classNames('ml-2', theme.icon.size[size])}
          />
        ) : (
          ''
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
