import create from 'zustand';

interface IOpenAIState {
  didConsent: boolean;
  setConsent: (state: boolean) => void;
}

const openAIStoreCreator = () =>
  create<IOpenAIState>((set) => ({
    didConsent: localStorage.getItem('didOpenAIConsent') === 'true',
    setConsent: (didConsent: boolean) => {
      localStorage.setItem('didOpenAIConsent', String(didConsent));

      return set({ didConsent });
    },
  }));

export default openAIStoreCreator;
