import type { FC } from 'react';

const House: FC<any> = ({ fill, ...props }) => (
  <svg viewBox="0 0 35 29" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.498 0a.57.57 0 00-.216.04 1.089 1.089 0 00-.1.055c-.016.012-.04.016-.055.03L9.917 6.06V4.086a.58.58 0 00-.583-.58.58.58 0 00-.583.58v2.901c0 .01.004.018.007.03l-1.174.963V1.763c0-.32-.26-.58-.583-.58a.581.581 0 00-.584.58v6.965c0 .063.019.124.037.183L.21 14.05a.579.579 0 00.374 1.027.582.582 0 00.374-.134l1.96-1.614V27.84h-1.75a.581.581 0 100 1.16h32.666a.581.581 0 100-1.16h-1.75V13.328l1.96 1.614c.109.09.241.134.373.134a.58.58 0 00.371-1.027L17.873.125c-.016-.014-.036-.018-.055-.03a.46.46 0 00-.104-.054.768.768 0 00-.103-.032.602.602 0 00-.112-.009zm.003 1.324l13.416 11.043v9.67h-9.333v-4.64c0-.64-.524-1.161-1.167-1.161h-5.833c-.643 0-1.167.521-1.167 1.16v4.642H4.084v-9.67L17.501 1.323zm0 5.63a2.912 2.912 0 00-2.917 2.9c0 1.6 1.308 2.9 2.917 2.9a2.91 2.91 0 002.916-2.9c0-1.6-1.308-2.9-2.916-2.9zm0 1.04c1.032 0 1.87.833 1.87 1.86 0 1.027-.838 1.86-1.87 1.86a1.867 1.867 0 01-1.871-1.86c0-1.027.838-1.86 1.87-1.86zm-2.917 9.402h5.833V27.81h-5.833V17.396zm4.083 4.062a.581.581 0 00-.583.58v1.16c0 .32.26.58.583.58.324 0 .584-.26.584-.58v-1.16c0-.32-.26-.58-.584-.58zm-14.583 1.74h9.333v4.642H4.084v-4.642zm17.5 0h9.333v4.642h-9.333v-4.642zm-15.75 1.16a.581.581 0 00-.583.58V26.1c0 .32.26.58.583.58a.58.58 0 00.583-.58v-1.16c0-.32-.26-.58-.583-.58zm2.917 0a.581.581 0 00-.584.58V26.1c0 .32.26.58.584.58.323 0 .583-.26.583-.58v-1.16c0-.32-.26-.58-.583-.58zm2.916 0a.581.581 0 00-.583.58V26.1c0 .32.26.58.583.58.324 0 .584-.26.584-.58v-1.16c0-.32-.26-.58-.584-.58zm11.667 0a.581.581 0 00-.583.58V26.1c0 .32.26.58.583.58a.58.58 0 00.583-.58v-1.16c0-.32-.26-.58-.583-.58zm2.917 0a.581.581 0 00-.584.58V26.1c0 .32.26.58.584.58.323 0 .583-.26.583-.58v-1.16c0-.32-.26-.58-.583-.58zm2.916 0a.581.581 0 00-.583.58V26.1c0 .32.26.58.583.58.324 0 .584-.26.584-.58v-1.16c0-.32-.26-.58-.584-.58z"
      fill={fill}
    />
  </svg>
);

export default House;
