import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import useResetableState from 'hooks/useResetableState';
import type { FC } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import ResizeObserver from 'resizeObserver';
import { popoverTransition } from 'utils/constants';
import { ChevronRightIconOutline } from 'utils/icons';

const Crumb: FC<any> = ({ onClick, disabled, className, label }) => {
  return (
    <Button
      asDefault
      onClick={onClick}
      disabled={disabled}
      className="group text-left flex"
    >
      <span
        className={classNames(
          {
            'group-hover:underline group-focus:underline text-main': !disabled,
          },
          { 'text-primary-400': disabled },
          'font-semibold',
          className,
        )}
      >
        {label}
      </span>
    </Button>
  );
};

const Icon: FC<any> = ({ className }) => (
  <ChevronRightIconOutline
    className={classNames(
      'text-primary-400 flex-shrink-0 relative top-px',
      className,
    )}
  />
);

export type BreadCrumbsProps = {
  hideRoot?: boolean;
  disableLastBreadcrumb?: boolean;
  data: { label: string; value?: any }[];
  onClick: (value: any) => void;
  titleClassName?: string;
  iconClassName?: string;
  className?: string;
  shouldCompine?: boolean;
};

const BreadCrumbs: FC<BreadCrumbsProps> = ({
  hideRoot = true,
  disableLastBreadcrumb = true,
  data,
  onClick,
  titleClassName,
  iconClassName,
  className,
  shouldCompine: originalShouldCompine = false,
}) => {
  const pointerContainer = useRef<HTMLDivElement>(null);
  const endpointer = useRef<HTMLSpanElement>(null);

  const labels = data.map((d) => d.label).join('');

  const [shouldCompine, setShouldCompine] = useResetableState({
    init: false,
    deps: [labels],
  });

  const [pointerContainerWidth, setPointerContainerWidth] = useState(0);

  const shouldHideUI = data.length <= 1 && hideRoot;

  useLayoutEffect(() => {
    if (!originalShouldCompine || !pointerContainer.current || shouldHideUI)
      return;
    const obs = new ResizeObserver(([entry]) => {
      setPointerContainerWidth(entry.contentRect.width);
      setShouldCompine(false);
    });
    obs.observe(pointerContainer.current);
    return () => obs.disconnect();
  }, [originalShouldCompine, shouldHideUI]);

  useLayoutEffect(() => {
    const container = pointerContainer.current;
    const end = endpointer.current;
    if (
      !originalShouldCompine ||
      !container ||
      !end ||
      shouldHideUI ||
      shouldCompine
    )
      return;
    const containerDim = container.getBoundingClientRect();
    const containerEndX = Math.abs(containerDim.x + containerDim.width);
    const endX = Math.abs(end.getBoundingClientRect().x);
    setShouldCompine(endX > containerEndX); // if the "end" pointer position exceeds the container width
  }, [
    labels,
    pointerContainerWidth,
    originalShouldCompine,
    shouldHideUI,
    shouldCompine,
  ]);

  if (shouldHideUI) return null;

  return (
    <div ref={pointerContainer}>
      {(() => {
        if (shouldCompine && data.length >= 3) {
          const firstCrumb = data[0];
          const lastCrumb = data[data.length - 1];
          const inBetween = data.slice(1, -1).reverse();
          return (
            <div
              className={classNames(
                'flex items-center z-10 relative',
                className,
              )}
            >
              <Crumb
                onClick={() => onClick(firstCrumb.value)}
                className={classNames(titleClassName, 'pr-1')}
                label={firstCrumb.label}
              />
              <Icon className={iconClassName} />

              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button as="div">
                      <Crumb
                        className={classNames(
                          titleClassName,
                          'px-2 font-bold rounded',
                          {
                            'bg-primary-100': open,
                          },
                        )}
                        label={'...'}
                      />
                    </Popover.Button>
                    <Transition {...popoverTransition}>
                      <Popover.Panel
                        focus
                        className="bg-white mt-0.5 absolute w-max flex flex-col gap-2 bg-white px-4 py-2 rounded shadow-lg border"
                      >
                        {inBetween.map((layer: any, index: number) => {
                          return (
                            <>
                              <Crumb
                                onClick={() => onClick(layer.value)}
                                className={titleClassName}
                                label={layer.label}
                              />
                            </>
                          );
                        })}
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Icon className={iconClassName} />
              <Crumb
                onClick={() => onClick(lastCrumb.value)}
                className={classNames(titleClassName, 'px-1')}
                disabled={disableLastBreadcrumb}
                label={lastCrumb.label}
              />
            </div>
          );
        }

        return (
          <div
            className={classNames('flex items-center', className, {
              // important to force the ui to be displayed on one line
              // then the layout effect will do its work in case of we "should combine"
              // if we let it display on multilines it can make flickering issue with framer motion components (with layout animation) displayed around the BreadCrumbs
              'whitespace-nowrap overflow-hidden': originalShouldCompine,
            })}
          >
            {data.map((layer: any, index: number) => {
              const isFirst = index === 0;
              const isLast = data.length - 1 === index;
              const layerTitle = layer.label;
              return (
                <>
                  <Crumb
                    onClick={() => onClick(layer.value)}
                    disabled={isLast && disableLastBreadcrumb}
                    className={classNames(titleClassName, {
                      'pr-1': isFirst,
                      'px-1': !isFirst,
                    })}
                    label={layerTitle}
                  />
                  {!isLast && <Icon className={iconClassName} />}
                </>
              );
            })}
            <span ref={endpointer} />
          </div>
        );
      })()}
    </div>
  );
};

export default BreadCrumbs;
