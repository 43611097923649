import { dateToPeriod } from 'components/molecules/Fields/Period/utils';
import dayjs from 'dayjs';
import type { VATReturnFrequency } from 'types/global.types';

const periodBasedOnDate = (
  date: string | null,
  vatReturnFreq: VATReturnFrequency,
) => {
  if (!date) return null;
  return dateToPeriod(dayjs(date), vatReturnFreq);
};

export default periodBasedOnDate;
