import type { Category } from 'components/organisms/CategoriesPicker/categoriesPicker.types';
import i18n from 'i18n';
import get from 'lodash/get';
import {
  getPeriodAsFilterOptionValue,
  getPeriodLabel,
} from 'store/shared/filters/filterConstraints';
import type { FilterResponse, FilterV2 } from 'types/global.types';
import type { AvailableCountries } from 'types/users.types';
import { isExpert, isWeb } from 'utils/constants';
import adaptOldCategorySlug from 'utils/expenses/adaptOldCategorySlugs';

const allowedFilters = [
  'attachment',
  'payment',
  'isInvoice',
  'isValidated',
  'reviewed',
  'source',
  'categories',
  'dateRange',
  'period',
  'isCreditNote',
  isExpert ? 'exported' : null,
];
const keysMapper = { period: 'periods', categories: 'categoryIds' };

const slugs: Record<string, string | undefined> = {
  reviewed: isWeb ? 'filters.reviewed.web' : 'expenses.filters.reviewed',
  isValidated: isWeb ? 'filters.validated.web' : 'expenses.filters.isValidated',
};

const getCategoryLabel = (option: Category, country: AvailableCountries) => {
  const category = option;
  const titleSlug = adaptOldCategorySlug(country, category.title, 'title');
  if (!category.displayName) return i18n.t(titleSlug);
  const displayNameSlug = adaptOldCategorySlug(
    country,
    category.displayName,
    'displayName',
  );
  if (i18n.exists(displayNameSlug)) return i18n.t(displayNameSlug);
  return i18n.t(titleSlug);
};

export const adaptFilters = (
  data: FilterResponse[],
  country: AvailableCountries,
): FilterV2[] => {
  return data
    .filter((f) => allowedFilters.includes(f.key))
    .map((f) => {
      const key = get(keysMapper, f.key, f.key) as string;
      return {
        ...f,
        isV2: true,
        key,
        label: i18n.t(slugs[f.key] || `expenses.filters.${f.key}`),
        options: (() => {
          if (key === 'exported') {
            return f.values.map((option) => ({
              label: i18n.t(`expenses.filters.exported.${option}`) as string,
              value: option,
            }));
          }
          if (key === 'categoryIds') {
            return f.values.map((option: Category) => ({
              label: getCategoryLabel(option, country),
              value: option.id as string,
            }));
          }
          if (key === 'periods') {
            return f.values.map((period) => ({
              label: getPeriodLabel(period),
              value: getPeriodAsFilterOptionValue(period),
            }));
          }
          if (key === 'dateRange') {
            return [];
          }
          if (key === 'source') {
            return f.values.map((v: string) => {
              const suffix = v === 'peppol' ? `.${country}` : '';

              return {
                label: i18n.t(`expenses.choices.${v}${suffix}`),
                value: v,
              };
            });
          }
          return f.values.map((v: string) => ({
            label: i18n.t(`expenses.choices.${v}`),
            value: v,
          }));
        })(),
      };
    });
};
