export const ONE_DAY = 86400000; // 60 * 60 * 1000 * 24

export const ONE_MINUTE = 60000; // 60 * 1000;

export const TWO_MINUTES = 120000; // 60 * 2 * 1000

export const FIVE_MINUTES = 300000; // 60 * 5 * 1000

export const ONE_SECOND = 1000; // 1 * 1000

export const THREE_SECONDS = 3000; // 3 * 1000

export const FIVE_SECONDS = 5000; // 5 * 1000

export const TEN_SECONDS = 10000; // 10 * 1000

export const SIXTEEN_SECONDS = 16000; // 16 * 1000
