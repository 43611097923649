import bike from 'assets/icons/categories/bike-and-motorbike.svg';
import compensation from 'assets/icons/categories/compensation.svg';
import foodAndDrink from 'assets/icons/categories/food-and-drinks.svg';
import furnitureAndMaterial from 'assets/icons/categories/goods-for-resale.svg';
import landAndConstruction from 'assets/icons/categories/land-and-construction.svg';
import legalAndAccounting from 'assets/icons/categories/legal-and-accounting.svg';
import marketing from 'assets/icons/categories/marketing.svg';
import mixed from 'assets/icons/categories/mixed.svg';
import officeSrc from 'assets/icons/categories/office.svg';
import phoneAndInternet from 'assets/icons/categories/phone-and-internet.svg';
import subcontracting from 'assets/icons/categories/subcontracting.svg';
import technology from 'assets/icons/categories/technology.svg';
import trainingAndDocumentation from 'assets/icons/categories/training-and-doc.svg';
import travel from 'assets/icons/categories/travel.svg';
import vehicle from 'assets/icons/categories/vehicle.svg';
import workplace from 'assets/icons/categories/workplace.svg';

export const listIcons: { [key: string]: string } = {
  bike,
  compensation,
  foodAndDrink,
  furnitureAndMaterial,
  landAndConstruction,
  legalAndAccounting,
  marketing,
  officeSrc,
  officeAndAdministration: officeSrc,
  phoneAndInternet,
  subcontracting,
  technology,
  trainingAndDocumentation,
  travel,
  vehicle,
  workplace,
  mixed,
};

import picker_workplace from '../icons//workplace.svg';
import picker_bike from '../icons/bike-and-motorbike.svg';
import picker_compensation from '../icons/compensation.svg';
import picker_foodAndDrink from '../icons/foodAndDrink.svg';
import picker_furnitureAndMaterial from '../icons/furnitureAndMaterial.svg';
import picker_interestAndBankCharges from '../icons/interestAndBankCharges.svg';
import picker_landAndConstruction from '../icons/land-and-construction.svg';
import picker_legalAndAccounting from '../icons/legalAndAccounting.svg';
import picker_marketing from '../icons/marketing.svg';
import picker_mixed from '../icons/mixed.svg';
import picker_officeSrc from '../icons/office.svg';
import picker_phoneAndInternet from '../icons/phoneAndInternet.svg';
import picker_subcontracting from '../icons/subcontracting.svg';
import picker_technology from '../icons/technology.svg';
import picker_trainingAndDocumentation from '../icons/training-and-doc.svg';
import picker_travel from '../icons/travel.svg';
import picker_vehicle from '../icons/vehicle.svg';
// import picker_payment from '../icons/payment.svg';

export const pickerIcons: { [key: string]: string } = {
  vat_payment: picker_compensation,
  furnitureAndMaterial: picker_furnitureAndMaterial,
  subcontracting: picker_subcontracting,
  purchaseOther: picker_mixed, //need icon 'leasing'

  //------
  bike: picker_bike,
  legalAndAccounting: picker_legalAndAccounting,
  officeSrc: picker_officeSrc,
  officeAndAdministration: picker_officeSrc,
  marketing: picker_marketing, // Marketing
  workplace: picker_workplace, // Workplace
  travel: picker_travel, // Travel
  landAndConstruction: picker_landAndConstruction, // Investment
  technology: picker_technology, // Tech
  vehicle: picker_vehicle, // Vehicle
  interestAndBankCharges: picker_interestAndBankCharges, // Interest
  trainingAndDocumentation: picker_trainingAndDocumentation, // Training
  phoneAndInternet: picker_phoneAndInternet, // Phone
  compensation: picker_compensation, // Taxes
  foodAndDrink: picker_foodAndDrink, // Food
  mixed: picker_mixed, // Mixed
};
