import { XIcon } from '@heroicons/react/solid';
import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence, motion } from 'framer-motion';
import useWorldTheme from 'hooks/shared/useWorldTheme';
import type { FC } from 'react';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { logAmplitude } from 'utils/amplitude';
import { WEBAPP_GLOBAL_OPENMODAL } from 'utils/amplitude/events/global.amplitude';
import { globalModalClassNameSelector } from 'utils/constants';
import { IconButton } from '../IconButton';
import type { ModalApi, ModalProps } from './Modal.types';

const widths = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl',
  full: 'max-w-full',
};

const Modal: FC<ModalProps> = ({
  defaultOpen = false,
  handleInitFocus = true,
  onClose,
  onBlur,
  trigger,
  render,
  color,
  width = 'xl',
  className,
  dialogPanalClassName,
  apiRef,
  name,
  hideCloseIcon,
  overlayClassName,
  dialogClassName,
  isFocusTrapActive = true,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const closeModal = () => {
    setIsOpen(false);
    onClose?.();
  };

  const blurModal = () => {
    if (onBlur) onBlur({ closeModal } as ModalApi);
    else closeModal();
  };

  const worldColor = useWorldTheme();
  const openModal = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    // we had to do it in an effect to handle useModal case were the options can be changed within modalApi.open
    if (isOpen && name) {
      logAmplitude(WEBAPP_GLOBAL_OPENMODAL, { name });
    }
  }, [isOpen, name]);
  const api = {
    closeModal,
    openModal,
    blurModal,
  };

  useImperativeHandle(apiRef, () => api);
  const closeBtnRef = useRef<HTMLButtonElement>(null);

  const handleOnClickClose = () =>
    onClose ? api.closeModal() : api.blurModal();

  // usePreventScroll({ isDisabled: !isOpen });

  return (
    <Dialog.Root
      modal={false}
      open={isOpen}
      onOpenChange={(open) => {
        if (open) return api.openModal();
        api.blurModal();
      }}
    >
      {trigger?.(api)}
      <AnimatePresence mode="wait">
        {isOpen && (
          <Dialog.Portal forceMount>
            <motion.div
              className={
                'fixed inset-0 z-[100] ' + globalModalClassNameSelector
              }
              onClick={(e: any) => e.stopPropagation()}
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.3, ease: 'easeOut' },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.2, ease: 'easeIn' },
                }}
                className={classNames(
                  'absolute w-full h-full bg-primary bg-opacity-40',
                  overlayClassName,
                )}
              />
              <motion.div className="relative flex w-full h-full overflow-y-auto">
                <FocusTrap active={isFocusTrapActive}>
                  <Dialog.Content asChild>
                    <motion.div
                      className={classNames(
                        'm-auto pt-6 pb-4 px-6 text-center w-full',
                        widths[width],
                        className,
                      )}
                    >
                      <motion.div
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{
                          opacity: 1,
                          scale: 1,
                          transition: { duration: 0.3, ease: 'easeOut' },
                        }}
                        exit={{
                          opacity: 0,
                          scale: 0.9,
                          transition: { duration: 0.2, ease: 'easeIn' },
                        }}
                        className={classNames(
                          'relative h-full flex-1 overflow-visible p-6 rounded-2xl bg-white text-left align-middle shadow-xl',
                          dialogPanalClassName,
                        )}
                      >
                        {hideCloseIcon ? null : (
                          <IconButton
                            icon={() => (
                              <XIcon className="text-white w-4 h-4" />
                            )}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOnClickClose();
                            }}
                            className="absolute top-0 right-0 -translate-y-1/2 translate-x-1/2"
                            buttonType={color || worldColor}
                            ref={closeBtnRef}
                          />
                        )}
                        <button
                          type="button"
                          className="absolute top-0 right-0 opacity-0 w-0 h-0"
                        />
                        {render?.(api)}
                      </motion.div>
                    </motion.div>
                  </Dialog.Content>
                </FocusTrap>
              </motion.div>
            </motion.div>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
};

export default Modal;
