const yearlyReviewExpensesCategories = {
  phone_internet: {
    EN: 'Phone & internet ☎️',
    FR: 'Téléphone & internet ☎️',
    NL: 'Telephoon & internet ☎️',
    DE: 'Telefon & Internet ☎️',
  },
  restaurant_canteen: {
    EN: 'Bar, Canteen & Restaurant 🍝',
    FR: 'Bar, Cantine & Restaurant 🍝',
    NL: 'Bar, Kantine & Restaurant 🍝',
    DE: 'Bewirtung & Aufmerksamkeiten 🍝',
  },
  '613200a': {
    EN: 'Accounting fees 🔺',
    FR: 'Frais de comptabilité 🔺',
    NL: 'Boekhoudkundige kosten 🔺',
    DE: 'Buchhaltungskosten 🔺',
  },
  'de.legal_and_other_fees.accounting_fees': {
    EN: 'Accounting fees 🔺',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Buchhaltungskosten 🔺',
  },
  615400: {
    EN: 'Advertising 📣',
    FR: 'Publicité 📣',
    NL: 'Publiciteit 📣',
    DE: 'Werbung 📣',
  },
  'de.legal_and_other_fees.audit_and_closing_fees': {
    EN: 'Audit fees ⚖️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Rechts- und Prüfungskosten ⚖️',
  },
  659000: {
    EN: 'Bank & interests',
    FR: 'Frais bancaires et intérêts',
    NL: 'Bank & interesten',
    DE: 'Bankgebühren & Zinsen',
  },
  'de.interest_and_bank_charges.bank_charges': {
    EN: 'Bank & interests',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Bankgebühren & Zinsen',
  },
  'de.interest_and_bank_charges.interest': {
    EN: 'Bank & interests',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Bankgebühren & Zinsen',
  },
  613403: {
    EN: 'Bike 🚲   - Leasing or rental',
    FR: 'Vélo 🚲 - Location ou leasing',
    NL: 'Fiets 🚲  - Huur of leasing',
    DE: 'Fahrrad 🚲 - Leasing oder Miete',
  },
  613413: {
    EN: 'Bike 🚲   - Maintenance',
    FR: 'Bike 🚲 - Entretien',
    NL: 'Fiets 🚲  - Onderhoud',
    DE: 'Fahrrad 🚲 - Instandhaltung',
  },
  613702: {
    EN: 'Bike 🚲   - Purchase',
    FR: 'Bike 🚲 - Achat',
    NL: 'Fiets 🚲  - Aankoop',
    DE: 'Fahrrad 🚲 - Kauf',
  },
  613416: {
    EN: 'Bike 🚲   - Parts/accessories',
    FR: 'Bike 🚲   - Pièces/accessoires',
    NL: 'Fiets 🚲  - Onderdelen of accessoires',
    DE: '',
  },
  222000: {
    EN: 'Built land 🏔',
    FR: 'Terrain 🏔',
    NL: 'Terreinen 🏔',
    DE: 'Bebaute Grundstücke 🏔',
  },
  '615300a': {
    EN: 'Business gifts 🎁',
    FR: "Cadeaux d'affaires 🎁",
    NL: 'Relatiegeschenken 🎁',
    DE: 'Geschenke  🎁',
  },
  'de.marketing.personal_gifts_high.professional': {
    EN: 'Business gifts 🎁',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Geschenke 🎁',
  },
  'de.food_and_drinks.bar_and_restaurants': {
    EN: 'Canteen & restaurant 🍝',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Bewirtungen 🍝',
  },
  '6450a': {
    EN: 'Car 🚗  - Fines',
    FR: 'Voiture 🚗 - Amendes',
    NL: 'Auto 🚗  - Boetes',
    DE: 'Auto 🚗 - Bußgelder',
  },
  613420: {
    EN: 'Car 🚗  - Fuel & electricity',
    FR: 'Voiture 🚗 - Carburant',
    NL: 'Auto 🚗  - Brandstof & elektriciteit',
    DE: 'Auto 🚗 - Kraftstoff und Strom',
  },
  613530: {
    EN: 'Car 🚗  - Insurance',
    FR: 'Voiture 🚗 - Assurance',
    NL: 'Auto 🚗  - Verzekeringen',
    DE: 'Auto 🚗 - Versicherung',
  },
  'de.categories.leasing.car_lease': {
    EN: 'Car 🚗  - Leasing',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Auto 🚗 - Leasing',
  },
  613400: {
    EN: 'Car 🚗  - Leasing/rental',
    FR: 'Voiture 🚗 - Leasing / Location',
    NL: 'Auto 🚗  - Leasing/huur',
    DE: 'Auto 🚗 - Leasing/Miete',
  },
  613410: {
    EN: 'Car 🚗  - Maintenance',
    FR: 'Voiture 🚗 - Maintenance',
    NL: 'Auto 🚗  - Onderhoud',
    DE: 'Auto 🚗 - Wartung',
  },
  613430: {
    EN: 'Car 🚗  - Parking',
    FR: 'Voiture 🚗 - Parking',
    NL: 'Auto 🚗  - Parking',
    DE: 'Auto 🚗 - Parken',
  },
  '613430a': {
    EN: 'Car 🚗  - Parking',
    FR: 'Voiture 🚗 - Parking',
    NL: 'Auto 🚗  - Parking',
    DE: 'Auto 🚗 - Parken',
  },
  241000: {
    EN: 'Car 🚗  - Purchase',
    FR: 'Voiture 🚗 - Achat',
    NL: 'Auto 🚗  - Aankoop',
    DE: 'Auto 🚗 - Erwerb',
  },
  613425: {
    EN: 'Car 🚗  - Parts/accessories',
    FR: 'Voiture 🚗 - Pièces/accessoires',
    NL: 'Auto 🚗  - Onderdelen of accessoires',
    DE: '',
  },
  'de.investments.purchase.car': {
    EN: 'Car 🚗  - Purchase',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Auto 🚗 - Erwerb',
  },
  'de.vehicle.purchase.car': {
    EN: 'Car 🚗  - Purchase',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Auto 🚗 - Erwerb',
  },
  640000: {
    EN: 'Car 🚗  - Taxes',
    FR: 'Voiture 🚗 - Taxes',
    NL: 'Auto 🚗  - Belastingen',
    DE: 'Auto 🚗 - Steuern',
  },
  6130: {
    EN: 'Clubs & associations 🤝',
    FR: 'Clubs & Associations 🤝',
    NL: 'Clubs & verenigingen',
    DE: 'Vereine und Verbände 🤝',
  },
  'de.categories.leasing.leasing_computer': {
    EN: 'Computer - Leasing',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Computer - Leasing',
  },
  '240200a': {
    EN: 'Computer/phone purchase 🖥',
    FR: 'Achat informatique 🖥',
    NL: 'Aankoop computer/telefoon 🖥',
    DE: 'Ankauf Computers/Telefons 🖥',
  },
  '240200b': {
    EN: 'Computer/phone purchase 🖥',
    FR: 'Achat informatique 🖥',
    NL: 'Aankoop computer/telefoon 🖥',
    DE: 'Ankauf Computers/Telefons 🖥',
  },
  6140: {
    EN: 'Conferences 📚',
    FR: 'Conférences 📚',
    NL: 'Conferenties 📚',
    DE: 'Konferenzen 📚',
  },
  221000: {
    EN: 'Construction 🏔',
    FR: 'Construction 🏔',
    NL: 'Bouwontwikkeling 🏔',
    DE: 'Bauwesen 🏔',
  },
  'de.investments.construction': {
    EN: 'Construction 🏔',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Bauwesen 🏔',
  },
  612100: {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  612120: {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  612150: {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  '612150a': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  '612150b': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  '612150c': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  615100: {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  615200: {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.food_and_drinks.canteen_and_reception': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.goods_and_materials.goods_for_resell.nineteen_percent': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.goods_and_materials.goods_for_resell.seven_percent': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.goods_and_materials.goods_for_resell.zero_percent': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.goods_and_materials.raw_material.nineteen_percent': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.goods_and_materials.raw_material.seven_percent': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.goods_and_materials.raw_material.zero_percent': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.goods_and_materials.tools.above_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.goods_and_materials.tools.below_250': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.goods_and_materials.tools.between_250_and_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.hardware.above_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.hardware.below_250': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.hardware.between_250_and_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.machines.above_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.machines.below_250': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.machines.between_250_and_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.office_furniture.above_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.office_furniture.below_250': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.office_furniture.between_250_and_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.shop_furniture.above_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.shop_furniture.below_250': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.shop_furniture.between_250_and_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.tools.above_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.tools.below_250': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.investments.tools.between_250_and_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_employee.health_insurance': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_employee.minijobber_fee': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_employee.pension_plan': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_employee.wage_tax': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_employee.workplace_levy': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_self_employed.income_tax.church_tax': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_self_employed.income_tax.income_tax': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_self_employed.income_tax.solidarity_surcharge': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_self_employed.property_tax.inside_main': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_self_employed.property_tax.inside_secondary': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_self_employed.property_tax.outside': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.taxes_and_insurance.for_self_employed.vat.current_year_no_permantent_extension':
    {
      EN: 'Deprecated - should not appear',
      FR: 'Deprecated - should not appear',
      NL: 'Deprecated - should not appear',
      DE: 'Deprecated - should not appear',
    },
  'de.taxes_and_insurance.for_self_employed.vat.current_year_permantent_extension':
    {
      EN: 'Deprecated - should not appear',
      FR: 'Deprecated - should not appear',
      NL: 'Deprecated - should not appear',
      DE: 'Deprecated - should not appear',
    },
  'de.technology.hardware.above_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.technology.hardware.below_250': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.technology.hardware.between_250_and_800': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.workplace.maintenance.inside_main': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.workplace.maintenance.inside_secondary': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.workplace.maintenance.outside': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.workplace.rent.inside_main': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.workplace.rent.inside_secondary': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  'de.workplace.rent.outside': {
    EN: 'Deprecated - should not appear',
    FR: 'Deprecated - should not appear',
    NL: 'Deprecated - should not appear',
    DE: 'Deprecated - should not appear',
  },
  618000: {
    EN: 'Director compensation 💵',
    FR: 'Rémunération dirigeant 💵',
    NL: 'Vergoeding zaakvoerder 💵',
    DE: 'Geschäftsführergehälter 💵',
  },
  'de.compensation.directors_compensation.majority_shareholder': {
    EN: 'Director compensation 💵',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Geschäftsführergehälter 💵',
  },
  'de.compensation.directors_compensation.minority_shareholder': {
    EN: 'Director compensation 💵',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Geschäftsführergehälter 💵',
  },
  'de.training_and_documentation.documentation': {
    EN: 'Docs 📚',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Fortbildung 📚',
  },
  'de.travel.for_self_employed.drive_to_work': {
    EN: 'Drive to work 🚘',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Fahrt zur Arbeit 🚘',
  },
  613404: {
    EN: 'eBike 🚲   - Leasing or rental',
    FR: 'ebike 🚲 - Location ou leasing',
    NL: 'e-bike 🚲  - Leasing of huur',
    DE: 'eBike 🚲 - Leasing oder Miete',
  },
  613414: {
    EN: 'eBike 🚲   - Maintenance',
    FR: 'ebike 🚲 - Entretien',
    NL: 'e-bike 🚲  - Onderhoud',
    DE: 'eBike 🚲 - Instandhaltung',
  },
  613703: {
    EN: 'eBike 🚲   - Purchase',
    FR: 'ebike 🚲 - Achat',
    NL: 'e-bike 🚲  - Aankoop',
    DE: 'eBike 🚲 - Erwerb',
  },
  613417: {
    EN: 'eBike 🚲   - Parts/accessories',
    FR: 'ebike 🚲 - Pièces/accessoires',
    NL: 'e-bike 🚲  - Onderdelen of accessoires',
    DE: '',
  },
  'de.categories.leasing.leasing_electric_car_or_e_bike': {
    EN: 'eCarBike - leasing',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'eAuto/eBike - Leasing',
  },
  'de.travel.for_employee.car_private_use': {
    EN: 'Employee 🧑 - Car private',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Beschäftigte 🧑 - Auto Privat',
  },
  'de.travel.for_employee.flights': {
    EN: 'Employee 🧑 - Flights',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Beschäftigte 🧑 - Flüge',
  },
  'de.travel.for_employee.hotels': {
    EN: 'Employee 🧑 - Hotels',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Beschäftigte 🧑 - Hotels',
  },
  'de.travel.for_employee.per_diem': {
    EN: 'Employee 🧑 - Perdiem',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Beschäftigte 🧑 - Per diem',
  },
  'de.travel.for_employee.rental': {
    EN: 'Employee 🧑 - Rental',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Beschäftigte 🧑 - Anmietung',
  },
  'de.taxes_and_insurance': {
    EN: 'Employee 🧑 - Taxes',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Beschäftigte 🧑 - Steuern',
  },
  'de.travel.for_employee.taxi.long_distance': {
    EN: 'Employee 🧑 - Taxi',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Beschäftigte 🧑 - Taxi',
  },
  'de.travel.for_employee.taxi.short_distance': {
    EN: 'Employee 🧑 - Taxi',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Beschäftigte 🧑 - Taxi',
  },
  'de.travel.for_employee.public_transport': {
    EN: 'Employee 🧑 - Transport',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Beschäftigte 🧑 - Transport',
  },
  'de.travel.for_employee.other_travel_fees': {
    EN: 'Employee 🧑 - Travel',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Beschäftigte 🧑 - Reise',
  },
  'de.taxes_and_insurance.for_self_employed.fines_for_late_payment': {
    EN: 'Fines 💰',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Strafzahlungen 💰',
  },
  '613310a': {
    EN: 'Flights ✈️',
    FR: 'Vols ✈️',
    NL: 'Vluchten ✈️',
    DE: 'Flüge ✈️',
  },
  'de.travel.for_self_employed.flights': {
    EN: 'Flights ✈️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Flüge ✈️',
  },
  604000: {
    EN: 'Goods for resale 🔀',
    FR: 'Biens pour la revente 🔀',
    NL: 'Aankopen van handelsgoederen 🔀',
    DE: 'Waren zum Weiterverkauf 🔀',
  },
  goods_for_resell: {
    EN: 'Goods for resale 🔀',
    FR: 'Biens destinés à la revente 🔀',
    NL: 'Handelsgoederen 🔀',
    DE: 'Waren zum Weiterverkauf 🔀',
  },
  'de.technology.hardware': {
    EN: 'Hardware 🛠',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Hardware 💻',
  },
  investment_hardware: {
    EN: 'Hardware 🛠',
    FR: 'Matériel 🛠',
    NL: 'Materiaal 🛠',
    DE: 'Hardware 🛠',
  },
  'de.compensation.health_insurance': {
    EN: 'Heatlh insurance ☔️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Krankenversicherung ☔️',
  },
  'de.taxes_and_insurance.for_self_employed.health_insurance': {
    EN: 'Heatlh insurance ☔️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Krankenversicherung ☔️',
  },
  'de.technology.hosting': {
    EN: 'Hosting 🖥',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Hosting 🖥',
  },
  613320: {
    EN: 'Hotels 🏨',
    FR: 'Hôtels 🏨',
    NL: 'Hotels 🏨',
    DE: 'Hotels 🏨',
  },
  'de.travel.for_self_employed.hotels': {
    EN: 'Hotels 🏨',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Hotels 🏨',
  },
  'de.taxes_and_insurance.for_self_employed.income_tax': {
    EN: 'Income tax 💰',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Einkommenssteuer 💰',
  },
  230000: {
    EN: 'Installation 🏗',
    FR: 'Installation 🏗',
    NL: 'Voorzieningen 🏗',
    DE: 'Installationen 🏗',
  },
  'de.investments.installations': {
    EN: 'Installation 🏗',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Installationen 🏗',
  },
  'de.categories.leasing.installment_purchase': {
    EN: 'Installment purchase',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Ratenkauf',
  },
  613540: {
    EN: 'Insurance ☔️',
    FR: 'Assurance ☔️',
    NL: 'Verzekeringen ☔️',
    DE: 'Versicherung ☔️',
  },
  'de.taxes_and_insurance.for_self_employed.business_insurances': {
    EN: 'Insurance ☔️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Versicherung ☔️',
  },
  220000: {
    EN: 'Land 🏔',
    FR: 'Terrain 🏔',
    NL: 'Terrein 🏔',
    DE: 'Grund & Boden 🏔',
  },
  'de.investments.land': {
    EN: 'Land 🏔',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Grund & Boden 🏔',
  },
  '615300b': {
    EN: 'Large gifts 🎁',
    FR: 'Grands cadeaux 🎁',
    NL: 'Grote geschenken 🎁',
    DE: 'Große Geschenke 🎁',
  },
  '613200b': {
    EN: 'Legal fees ⚖️',
    FR: 'Frais de justice ⚖️',
    NL: 'Juridische kosten ⚖️',
    DE: 'Rechtskosten ⚖️',
  },
  'de.legal_and_other_fees.lawyer_fees_and_consulting': {
    EN: 'Legal fees ⚖️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Rechtskosten ⚖️',
  },
  618040: {
    EN: 'Lunch vouchers 🍝',
    FR: 'Lunch vouchers 🍝',
    NL: 'Maaltijdcheques 🍝',
    DE: 'Essensgutscheine 🍝',
  },
  231000: {
    EN: 'Machines 🏗',
    FR: 'Machines 🏗',
    NL: 'Machines 🏗',
    DE: 'Maschinen 🏗',
  },
  machines: {
    EN: 'Machines 🏗',
    FR: 'Machines 🏗',
    NL: 'Machines 🏗',
    DE: 'Maschinen 🏗',
  },
  'de.categories.mailing.commissions': {
    EN: 'Mailing commission ✉️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Verkaufsprovisionen 💸',
  },
  'de.categories.mailing.other_transport_fees': {
    EN: 'Mailing fees ✉️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Versandgebühren ✉️',
  },
  'de.categories.mailing.transport_insurance': {
    EN: 'Mailing insurance ✉️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Transportversicherung ✉️',
  },
  'de.categories.mailing.package': {
    EN: 'Mailing packages ✉️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Verpackung ✉️',
  },
  'de.categories.mailing.stamps_postage': {
    EN: 'Mailing stamps ✉️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Porto ✉️',
  },
  'de.technology.maintenance': {
    EN: 'Maintenance 🖥',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Wartung 🖥',
  },
  'de.marketing.marketing': {
    EN: 'Marketing 📢',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Marketing 📢',
  },
  'de.legal_and_other_fees.membership_fees': {
    EN: 'Membership fees ⚖️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Mitgliedsbeiträge ⚖️',
  },
  'de.compensation.minijobber_fee': {
    EN: 'Minijob',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Minijobber Abgabe',
  },
  '6450b': {
    EN: 'Moto/bike 🚲   - Fines',
    FR: 'Moto / vélo 🚲 - Amendes',
    NL: 'Motor/fiets 🚲   - Boetes',
    DE: 'Motorad 🚲 - Geldstrafen',
  },
  613401: {
    EN: 'Moto/bike 🚲   - Leasing/rental',
    FR: 'Moto / vélo 🚲 - Location',
    NL: 'Motor/fiets 🚲   - Leasing/huur',
    DE: 'Motorad 🚲 - Leasing/Verleih',
  },
  613411: {
    EN: 'Moto/bike 🚲   - Maintenance',
    FR: 'Moto / vélo 🚲 - Maintenance',
    NL: 'Motor/fiets 🚲   - Onderhoud',
    DE: 'Motorad 🚲 - Wartung',
  },
  241220: {
    EN: 'Moto/bike 🚲   - Purchase',
    FR: 'Moto / vélo 🚲 - Achat',
    NL: 'Motor/fiets 🚲   - Aankoop',
    DE: 'Motorad 🚲 - Erwerb',
  },
  613531: {
    EN: 'Moto/bike 🚲  - Insurance',
    FR: 'Moto / vélo 🚲 - Assurance',
    NL: 'Motor/fiets 🚲   - Verzekering',
    DE: 'Motorad 🚲 - Versicherung',
  },
  613431: {
    EN: 'Moto/bike 🚲  - Parking',
    FR: 'Moto / vélo 🚲 - Parking',
    NL: 'Motor/fiets 🚲   - Parking',
    DE: 'Motorad 🚲 - Parkplatz',
  },
  '613431a': {
    EN: 'Moto/bike 🚲  - Parking',
    FR: 'Moto / vélo 🚲 - Parking',
    NL: 'Motor/fiets 🚲   - Parking',
    DE: 'Motorad 🚲 - Parkplatz',
  },
  640001: {
    EN: 'Moto/bike 🚲  - Taxes',
    FR: 'Moto / vélo 🚲 - Taxes',
    NL: 'Motor/fiets 🚲   - Belastingen',
    DE: 'Motorad 🚲 - Steuern',
  },
  613435: {
    EN: 'Moto/bike 🚲  - Parts/accessories',
    FR: 'Moto/bike 🚲  -  Pièces/accessoires',
    NL: 'Moto/bike 🚲  - Onderdelen of accessoires',
    DE: '',
  },
  613421: {
    EN: 'Moto/bike 🚲 - Fuel & electricity',
    FR: 'Moto / vélo 🚲 - Carburant',
    NL: 'Motor/fiets 🚲   - Brandstof & elektriciteit',
    DE: 'Motorad 🚲 - Kraftstoff und Strom',
  },
  'de.categories.leasing.leasing_office_furniture': {
    EN: 'Office - leasing',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Büro - Leasing 📎',
  },
  612400: {
    EN: 'Office & admin fees 📎',
    FR: 'Frais de bureau 📎',
    NL: 'Kantoor- en administratiekosten 📎',
    DE: 'Büro- und Verwaltungskosten 📎',
  },
  'de.office_supplies': {
    EN: 'Office & admin fees 📎',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Bürobedarf 📎',
  },
  '610000a': {
    EN: 'Office & rent 💼',
    FR: 'Bureau & loyer 💼',
    NL: 'Kantoor & huur 💼',
    DE: 'Büro & Miete 💼',
  },
  '610000b': {
    EN: 'Office & rent 💼',
    FR: 'Bureau & loyer 💼',
    NL: 'Kantoor & huur 💼',
    DE: 'Büro & Miete 💼',
  },
  rent: {
    EN: 'Office & rent 💼',
    FR: 'Bureau & location 💼',
    NL: 'Kantoor & huur 💼',
    DE: 'Büro & Miete 💼',
  },
  shop_furniture: {
    EN: 'Office & shop furniture 🛋',
    FR: 'Mobilier de bureau et de magasin 🛋',
    NL: 'Kantoor- en Winkelmeubilair 🛋',
    DE: 'Büro- und Ladenmöbel 🛋',
  },
  'de.workplace.cleaning': {
    EN: 'Office cleaning 🛋',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Büroreinigung 🛋',
  },
  'de.workplace.decoration': {
    EN: 'Office decoration 🛋',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Bürodekoration 🛋',
  },
  240000: {
    EN: 'Office furniture 🛋',
    FR: 'Meubles 🛋',
    NL: 'Kantoormeubels 🛋',
    DE: 'Büromöbel 🛋',
  },
  'de.workplace.renovation': {
    EN: 'Office renovation 🛋',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Büro-Renovierung🛋',
  },
  'de.investments.purchase.other': {
    EN: 'Other 🚚  - Purchase',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Sonstiges 🚚 - Erwerb',
  },
  'de.vehicle.purchase.other': {
    EN: 'Other 🚚  - Purchase',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Sonstiges 🚚 - Erwerb',
  },
  'de.compensation.other_personal_expenses': {
    EN: 'Other expenses 💵',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Sonstige Personalausgaben 💵',
  },
  'de.legal_and_other_fees.other_fees': {
    EN: 'Other fees ⚖️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Sonstige Gebühren ⚖️',
  },
  '613200c': {
    EN: 'Other legal fees ⚖️',
    FR: 'Autres frais juridiques ⚖️',
    NL: 'Andere juridische kosten ⚖️',
    DE: 'Sonstige Anwaltskosten ⚖️',
  },
  623000: {
    EN: 'Other personnel cost 💵',
    FR: 'Autre frais de personnel 💵',
    NL: 'Andere personeelskosten 💵',
    DE: 'Sonstige Personalkosten 💵',
  },
  '613310b': {
    EN: 'Other travel fees 🌄',
    FR: 'Autres frais de voyage 🌄',
    NL: 'Andere reiskosten 🌄',
    DE: 'Sonstige Reisekosten 🌄',
  },
  'de.travel.for_self_employed.other_travel_fees': {
    EN: 'Other travel fees 🌄',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Sonstige Reisekosten 🌄',
  },
  618030: {
    EN: 'Pension 👵🏻',
    FR: 'Pension 👵🏻',
    NL: 'Pensioen 👵🏻',
    DE: 'Rente 👵🏻',
  },
  624000: {
    EN: 'Pension 👵🏻',
    FR: 'Pension 👵🏻',
    NL: 'Pensioen 👵🏻',
    DE: 'Rente 👵🏻',
  },
  'de.compensation.pension_plan': {
    EN: 'Pension 👵🏻',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Altersvorsorge 👵🏻',
  },
  'de.taxes_and_insurance.for_self_employed.pension_plan': {
    EN: 'Pension 👵🏻',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Altersvorsorge 👵🏻',
  },
  613330: {
    EN: 'Per diems 🍝',
    FR: 'Per diems 🍝',
    NL: 'Dagvergoedingen 🍝',
    DE: 'Per diems 🍝',
  },
  'de.travel.for_self_employed.per_diem': {
    EN: 'Perdiem',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Verpflegungsmehraufwand',
  },
  620500: {
    EN: 'Personnel cost 💵',
    FR: 'Coût du personnel 💵',
    NL: 'Personeelskosten 💵',
    DE: 'Personalkosten 💵',
  },
  'de.travel.for_self_employed.car_private_use': {
    EN: 'Private car 🚘',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Geschäftliche Nutzung Privatwagen 🚘',
  },
  'de.marketing.personal_gifts_high.private': {
    EN: 'Private gifts 🎁',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Firmengeschenke 🎁',
  },
  'de.taxes_and_insurance.for_self_employed.private_insurances': {
    EN: 'Private insurance ☔️',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Private Versicherung ☔️',
  },
  615500: {
    EN: 'Prof. contributions 💰',
    FR: 'Contributions prof. 💰',
    NL: 'Prof. bijdragen💰',
    DE: 'Beiträge 💰',
  },
  'de.marketing.promotional_gifts': {
    EN: 'Promo gifts 🎁',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Werbegeschenke 🎁',
  },
  223000: {
    EN: 'Property rights 🏔',
    FR: 'Droits de propriété 🏔',
    NL: 'Eigendomsrechten 🏔',
    DE: 'Eigentumsrechte 🏔',
  },
  'de.taxes_and_insurance.property_tax': {
    EN: 'Property tax 💰',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Grundsteuer 💰',
  },
  613350: {
    EN: 'Public transport 🚃',
    FR: 'Transport public 🚃',
    NL: 'Openbaar vervoer 🚃',
    DE: 'Öffentlicher Verkehr 🚃',
  },
  '613350a': {
    EN: 'Public transport 🚃',
    FR: 'Transport public 🚃',
    NL: 'Openbaar vervoer 🚃',
    DE: 'Öffentlicher Verkehr 🚃',
  },
  'de.travel.for_self_employed.public_transport': {
    EN: 'Public transport 🚃',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Öffentlicher Verkehr 🚃',
  },
  600000: {
    EN: 'Raw materials 🌾',
    FR: 'Matières premières 🌾',
    NL: 'Grondstoffen 🌾',
    DE: 'Rohmaterialien 🌾',
  },
  raw_material: {
    EN: 'Raw materials 🌾',
    FR: 'Matières premières 🌾',
    NL: 'Grondstoffen 🌾',
    DE: 'Rohmaterialien 🌾',
  },
  'de.travel.for_self_employed.rental': {
    EN: 'Rental 🚘',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Mietwagen 🚘',
  },
  'de.marketing.personal_gifts_low': {
    EN: 'Small gifts 🎁',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Firmengeschenke 🎁',
  },
  618020: {
    EN: 'Social contributions 💰',
    FR: 'Contributions sociales 💰',
    NL: 'Sociale bijdragen 💰',
    DE: 'Sozialbeiträge 💰',
  },
  'de.technology.software_license': {
    EN: 'Software 🖥',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Software 🖥',
  },
  'de.compensation.spousal_compensation': {
    EN: 'Spouse compensation 💵',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Ehegattengehalt 💵',
  },
  602000: {
    EN: 'Studies & works 📐',
    FR: 'Études et travaux 📐',
    NL: 'Studies & werken 📐',
    DE: 'Weiterbildung 📐',
  },
  603000: {
    EN: 'Subcontractors 😏',
    FR: 'Sous-traitants 😏',
    NL: 'Onderaanneming 😏',
    DE: 'Unterauftragnehmer 😏',
  },
  'de.subcontracting.displayName': {
    EN: 'Subcontractors 😏',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Unterauftragnehmer 😏',
  },
  613340: { EN: 'Taxi 🚕', FR: 'Taxi 🚕', NL: 'Taxi 🚕', DE: 'Taxi 🚕' },
  'de.travel.for_self_employed.taxi.long_distance': {
    EN: 'Taxi 🚕',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Taxi 🚕',
  },
  'de.travel.for_self_employed.taxi.short_distance': {
    EN: 'Taxi 🚕',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Taxi 🚕',
  },
  '240200c': {
    EN: 'Tech purchase 🖥',
    FR: 'Achat Tech 🖥',
    NL: 'Technologische aankopen  🖥',
    DE: 'IT 🖥',
  },
  technology: {
    EN: 'Technology  🖥',
    FR: 'Technologie  🖥',
    NL: 'Technologie  🖥',
    DE: 'Technik  🖥',
  },
  601000: {
    EN: 'Tools 🛠',
    FR: 'Outils 🛠',
    NL: 'Materiaal 🛠',
    DE: 'Werkzeug 🛠',
  },
  goods_materials_tools: {
    EN: 'Tools 🛠',
    FR: 'Outils 🛠',
    NL: 'Gereedschap 🛠',
    DE: 'Werkzeug 🛠',
  },
  tools: {
    EN: 'Tools 🛠',
    FR: 'Outils 🛠',
    NL: 'Gereedschap 🛠',
    DE: 'Werkzeug 🛠',
  },
  'de.taxes_and_insurance.for_self_employed.trade_tax': {
    EN: 'Trade tax 💰',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Gewerbesteuer 💰',
  },
  '613600a': {
    EN: 'Training food 📚',
    FR: 'Formations (nourriture)',
    NL: 'Opleidingen (met eten) 📚',
    DE: 'Verpflegung bei Schulungen 📚',
  },
  '613600b': {
    EN: 'Trainings 📚',
    FR: 'Formations 📚',
    NL: 'Opleidingen 📚',
    DE: 'Schulungen 📚',
  },
  'de.training_and_documentation.training': {
    EN: 'Trainings 📚',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Fortbildung 📚',
  },
  'de.investments.purchase.truck': {
    EN: 'Truck 🚚  - Purchase',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Lkw 🚚 - Kauf',
  },
  'de.vehicle.purchase.truck': {
    EN: 'Truck 🚚  - Purchase',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Lkw 🚚 - Kauf',
  },
  '6450c': {
    EN: 'Utility 🚚   - Fines',
    FR: 'Utilitaire 🚚 - Amendes',
    NL: 'Bedrijfswagen 🚚 - Boetes',
    DE: 'Berufswagen 🚚 - Geldbußen',
  },
  613402: {
    EN: 'Utility 🚚   - Leasing/rental',
    FR: 'Utilitaire 🚚 - Location / Leasing',
    NL: 'Bedrijfswagen 🚚 - Leasing/huur',
    DE: 'Berufswagen 🚚 - Leasing/Verleih',
  },
  613412: {
    EN: 'Utility 🚚   - Maintenance',
    FR: 'Utilitaire 🚚 - Maintenance',
    NL: 'Bedrijfswagen 🚚 - Onderhoud',
    DE: 'Berufswagen 🚚 - Wartung',
  },
  613422: {
    EN: 'Utility 🚚  - Fuel & electricity',
    FR: 'Utilitaire 🚚 - Carburant',
    NL: 'Bedrijfswagen 🚚 - Brandstof & elektriciteit',
    DE: 'Berufswagen 🚚 - Kraftstoff und Strom',
  },
  613532: {
    EN: 'Utility 🚚  - Insurance',
    FR: 'Utilitaire 🚚 - Assurance',
    NL: 'Bedrijfswagen 🚚 - Verzekering',
    DE: 'Berufswagen 🚚 - Versicherung',
  },
  613432: {
    EN: 'Utility 🚚  - Parking',
    FR: 'Utilitaire 🚚 - Parking',
    NL: 'Bedrijfswagen 🚚 - Parking',
    DE: 'Berufswagen 🚚 - Parkplatz',
  },
  '613432a': {
    EN: 'Utility 🚚  - Parking',
    FR: 'Utilitaire 🚚 - Parking',
    NL: 'Bedrijfswagen 🚚 - Parking',
    DE: 'Berufswagen 🚚 - Parkplatz',
  },
  241100: {
    EN: 'Utility 🚚  - Purchase',
    FR: 'Utilitaire 🚚 - Achat',
    NL: 'Bedrijfswagen 🚚 - Aankoop',
    DE: 'Berufswagen 🚚 - Erwerb',
  },
  640002: {
    EN: 'Utility 🚚  - Taxes',
    FR: 'Utilitaire 🚚 - Taxes',
    NL: 'Bedrijfswagen 🚚 - Belastingen',
    DE: 'Berufswagen 🚚 - Steuern',
  },
  613415: {
    EN: 'Utility 🚚  - Parts/accessories',
    FR: 'Utility 🚚  - Pièces/accessoires',
    NL: 'Utility 🚚  - Onderdelen of accessoires',
    DE: '',
  },
  VAT: {
    EN: 'VAT 💰',
    FR: 'TVA  💰',
    NL: 'btw 💰',
    DE: 'Umsatzsteuer 💰',
  },
  'de.taxes_and_insurance.for_self_employed.vat.previous_year': {
    EN: 'VAT previous year 💰',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Umsatzsteuer - Letztes Jahr💰',
  },
  'de.vehicle.fuel': {
    EN: 'Vehicle 🚗  - Fuel',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Fahrzeug 🚗  - Tanken',
  },
  'de.vehicle.insurance': {
    EN: 'Vehicle 🚗  - Insurance',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Fahrzeug 🚗  - Versicherung',
  },
  'de.vehicle.leasing': {
    EN: 'Vehicle 🚗  - Leasing',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Fahrzeug 🚗  - Leasing',
  },
  'de.vehicle.other': {
    EN: 'Vehicle 🚗  - Other',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Fahrzeug 🚗  - Sonstiges',
  },
  'de.vehicle.parking': {
    EN: 'Vehicle 🚗  - Parking',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Fahrzeug 🚗  - Parken',
  },
  'de.vehicle.rental': {
    EN: 'Vehicle 🚗  - Rental',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Fahrzeug 🚗  - Miete',
  },
  'de.vehicle.repair': {
    EN: 'Vehicle 🚗  - Repairs',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Fahrzeug 🚗  - Reparaturen',
  },
  'de.vehicle.road_and_registration_taxes': {
    EN: 'Vehicle 🚗  - Taxes',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Fahrzeug 🚗  - Steuern',
  },
  'de.compensation.wage_tax': {
    EN: 'Wage tax 💰',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Lohnsteuer 💰',
  },
  'de.compensation.wages': {
    EN: 'Wages 💵',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Löhne & Gehälter 💵',
  },
  '612200a': {
    EN: 'Workplace - decoration 🖼',
    FR: 'Bureau - Décoration 🖼',
    NL: 'Werkplek - Decoratie 🖼',
    DE: 'Arbeitsplatz - Dekoration 🖼',
  },
  '612200b': {
    EN: 'Workplace - decoration 🖼',
    FR: 'Bureau - Décoration 🖼',
    NL: 'Werkplek - Decoratie 🖼',
    DE: 'Arbeitsplatz - Dekoration 🖼',
  },
  '612200c': {
    EN: 'Workplace - maintenance 🧽',
    FR: 'Bureau - Entretien 🧽',
    NL: 'Werkplek - Onderhoud 🧽',
    DE: 'Arbeitsplatz - Wartung 🧽',
  },
  '612200d': {
    EN: 'Workplace - maintenance 🧽',
    FR: 'Bureau - Entretien 🧽',
    NL: 'Werkplek - Onderhoud 🧽',
    DE: 'Arbeitsplatz - Wartung 🧽',
  },
  '612200e': {
    EN: 'Workplace - maintenance 🧽',
    FR: 'Bureau - Entretien 🧽',
    NL: 'Werkplek - Onderhoud 🧽',
    DE: 'Arbeitsplatz - Wartung 🧽',
  },
  '612200f': {
    EN: 'Workplace - maintenance 🧽',
    FR: 'Bureau - Entretien 🧽',
    NL: 'Werkplek - Onderhoud 🧽',
    DE: 'Arbeitsplatz - Wartung 🧽',
  },
  workplace_maintenance: {
    EN: 'Workplace - maintenance 🧽',
    FR: 'Lieu de travail - entretien 🧽',
    NL: 'Onderhoud van werkplek 🧽',
    DE: 'Arbeitsplatz - Instandhaltung 🧽',
  },
  610100: {
    EN: 'Workplace - utilities 🔌',
    FR: 'Bureau - Elec & eau 🔌',
    NL: 'Werkplek - Electriciteit & water 🔌',
    DE: 'Arbeitsplatz - Versorgungsunternehmen 🔌',
  },
  610110: {
    EN: 'Workplace - utilities 🔌',
    FR: 'Bureau - Elec & eau 🔌',
    NL: 'Werkplek - Electriciteit & water 🔌',
    DE: 'Arbeitsplatz - Versorgungsunternehmen 🔌',
  },
  'de.compensation.workplace_levy': {
    EN: 'Workplace levy',
    FR: 'Not translated',
    NL: 'Not translated',
    DE: 'Berufsgenossenschaft',
  },
  None: { EN: '', FR: '', NL: '', DE: '' },
};

export default yearlyReviewExpensesCategories;
