const NewLinkOutline = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.32215 4.86011H7.71565C6.71596 4.86011 6.21611 4.86011 5.83428 5.05466C5.49841 5.22579 5.22534 5.49887 5.0542 5.83473C4.85965 6.21657 4.85965 6.71641 4.85965 7.71611V16.2841C4.85965 17.2838 4.85965 17.7836 5.0542 18.1655C5.22534 18.5014 5.49841 18.7744 5.83428 18.9456C6.21611 19.1401 6.71596 19.1401 7.71565 19.1401H16.2837C17.2833 19.1401 17.7832 19.1401 18.165 18.9456C18.5009 18.7744 18.774 18.5014 18.9451 18.1655C19.1397 17.7836 19.1397 17.2838 19.1397 16.2841V14.6776M13.7846 4.86011H19.1397M19.1397 4.86011V10.2151M19.1397 4.86011L11.1071 12.8926"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewLinkOutline;
