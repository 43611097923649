import * as jsvat from '@accountable/jsvat';
import type { ClientData } from 'api/v1/clients';
import {
  searchEntrepriseClientsByName,
  searchEntrepriseClientsByVATNumber,
} from 'api/v1/clients';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { FieldConnector } from 'components/atoms/Form/FieldConnector';
import FreeTextInput from 'components/atoms/Form/FreeTextInput';
import { Select } from 'components/atoms/Form/Select';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useGetCountries from 'hooks/shared/useGetCountries';
import { get } from 'lodash';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { flushSync } from 'react-dom';
import type { UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RevenuesCacheKeys } from 'types/cacheKeys.types';
import type { IClient } from 'types/clients.types';
import { ClientTypeEnum, ClientVATStatusEnum } from 'types/clients.types';
import { AvailableCountries } from 'types/users.types';
import * as ClientsEvents from 'utils/amplitude/events/clients.amplitude';
import { caseInsensitiveEqual } from 'utils/helpers';
import { VATFormatter } from 'utils/text';
import UserType from './Fields/UserType';
import VATType from './Fields/VatType';
import useFormMeta from './useFormMeta';
import { VATNumberHelper } from './utils';

type FormProps = {
  formAPI: UseFormReturn;
  client: Partial<IClient>;
  onSubmit: (values: Partial<IClient>) => any;
  headerMeta?: ReactNode;
  footerMeta?: ReactNode;
  disabled?: boolean;
};

const Form: FC<FormProps> = ({ headerMeta, footerMeta, onSubmit, formAPI }) => {
  const customerCountry = useCustomerCountry();
  const { isSubmitting, dirtyFields } = formAPI.formState;
  const formValues = formAPI.watch();

  const countryCode = formValues.address?.country?.toUpperCase();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const countries = useGetCountries();

  const countriesOptions = useMemo(() => {
    if (!countries.data) return [];
    return countries.data.map((c) => ({
      label: get(c, ['translations', language], c.name),
      value: c.alpha2Code,
      country: c,
    }));
  }, [countries.data, language]);

  const { getFormMeta } = useFormMeta(countries.data);
  const formMeta = getFormMeta(formValues);

  // const VATLabelSame = useFeatures('clients.vat_label.same_country');
  // const VATLabelOther = useFeatures('clients.vat_label.other_country');

  const country = useMemo(
    () =>
      countriesOptions.find((option) =>
        caseInsensitiveEqual(option.value, countryCode),
      )?.country,
    [countryCode],
  );

  const VATLabel = useMemo(() => {
    if (
      !formMeta.isVATNumberRequired &&
      customerCountry === AvailableCountries.germany
    ) {
      return t('clients.field.vat_number.de');
    }
    if (!country) return t('vat');
    const label1 = country.VATLabel || t('vat');
    const label2 = formMeta.isEEA
      ? t('client.field.vat_number.european_vat_number')
      : t('client.field.vat_number.country_company_number');
    return `${label1} (${label2})`;
  }, [country, formMeta.isEEA]);

  const updateIfNotDirty = (name: string, value: any) => {
    if (get(dirtyFields, name)) return;
    formAPI.setValue(name, value);
  };

  const onUpdateVATNumberOrName = (client: any) => {
    if (!client) return;
    updateIfNotDirty('name', client.name);
    if (client.VATNumber) {
      updateIfNotDirty(
        'VATNumber',
        VATFormatter(client.VATNumber.toUpperCase()),
      );
      const vat = jsvat.checkVAT(client.VATNumber, jsvat.countries);
      const country = get(vat, 'country.isoCode.short', '').toLowerCase();
      if (country) updateIfNotDirty('address.country', country);
    }
    updateIfNotDirty('address.street', client.street);
    updateIfNotDirty('address.zip', client.zipcode);
    updateIfNotDirty('address.city', client.city);
    updateIfNotDirty('email', client.email);
  };

  const populateFieldsTypeAndVATStatus = () => {
    if (formValues.type == null) {
      formAPI.setValue('type', ClientTypeEnum.business);
    }
    const { shouldShowVATStatus } = getFormMeta(formAPI.getValues());
    if (formValues.VATStatus == null && shouldShowVATStatus) {
      formAPI.setValue('VATStatus', ClientVATStatusEnum.subjectToVAT);
    }
  };

  return (
    <FormProvider {...formAPI}>
      <form className="relative pb-20">
        <div className="flex flex-col gap-4 px-6 pb-8">{headerMeta}</div>
        <div className="flex flex-col gap-4 px-6 pb-8">
          <FieldConnector
            name="address.country"
            label={t('client.field.country_code')}
          >
            {({ field: { onChange, value, ...rest } }) => (
              <Select
                tracing={{
                  onMenuOpen: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDCOUNTRY,
                  onChange: ClientsEvents.WEBAPP_CLIENTITEM_UPDATEDCOUNTRY,
                }}
                isSearchable
                placeholder=""
                options={countriesOptions}
                isLoading={countries.isLoading}
                isError={countries.isError}
                retry={() => countries.refetch()}
                value={value && value.toUpperCase()}
                onChange={(option: any) => {
                  const value = option.value.toLowerCase();
                  onChange(value);
                }}
                {...rest}
              />
            )}
          </FieldConnector>

          <FieldConnector name="name" label={t('client.field.name')}>
            {({ field: { onChange, ...rest } }) => (
              <Select
                mode="asyncOptions"
                tracing={{
                  onMenuOpen: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDNAME,
                  onChange: ClientsEvents.WEBAPP_CLIENTITEM_UPDATEDNAME,
                }}
                asyncSearch
                creatable
                themeOptions={{
                  shouldOptionsShowSelectedIndicator: false,
                }}
                fetchOnEmpty={false}
                cacheKey={[
                  RevenuesCacheKeys.RevenuesClientsSearch,
                  countryCode,
                ]}
                enabled={formMeta.isCountrySearchable}
                onFetch={(search) =>
                  searchEntrepriseClientsByName(search as string, countryCode)
                }
                afterFetch={(clients: ClientData[]) =>
                  clients.map((client) => ({
                    label: client.name,
                    value: client.name,
                    client,
                  }))
                }
                onChange={(option: any) => {
                  flushSync(() => {
                    onChange(option?.value || null);
                    onUpdateVATNumberOrName(option?.client);
                    if (option?.client?.VATNumber) {
                      populateFieldsTypeAndVATStatus();
                    }
                  });
                  formAPI.clearErrors();
                }}
                placeholder=""
                {...rest}
              />
            )}
          </FieldConnector>

          <FieldConnector
            name="contactName"
            label={`${t('client.field.contact_name')} (${t(
              'field.optional.tag',
            )})`}
          >
            {({ field }) => (
              <FreeTextInput
                tracing={{
                  onFocus: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDCONTACTNAME,
                }}
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          </FieldConnector>

          <FieldConnector name="type" label={t('client.field.type')}>
            {({ field }) => (
              <UserType
                tracing={{
                  onMenuOpen: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDTYPE,
                  onChange: ClientsEvents.WEBAPP_CLIENTITEM_UPDATEDTYPE,
                }}
                {...field}
                onChange={(value) => {
                  flushSync(() => {
                    field.onChange(value);
                  });
                  formAPI.clearErrors();
                }}
              />
            )}
          </FieldConnector>

          <FieldConnector
            name="steuernummer"
            label={t('invoice.field.steuernummer.label')}
            showField={formMeta.shouldShowSteuernummer}
          >
            {({ field }) => (
              <FreeTextInput
                {...field}
                onChange={(e) => field.onChange(e.target.value || null)}
              />
            )}
          </FieldConnector>

          <FieldConnector
            name="VATNumber"
            showField={formMeta.shouldShowVATNumber}
            label={VATLabel}
            hint={
              formValues._id ? null : (
                <div className="flex justify-between">
                  <span>
                    {t('client.field.vat_number.help_article.what_if')}
                  </span>
                  <VATNumberHelper />
                </div>
              )
            }
          >
            {({ field: { onChange, ...rest } }) => (
              <Select
                tracing={{
                  onMenuOpen: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDVATNUMBER,
                  onChange: ClientsEvents.WEBAPP_CLIENTITEM_UPDATEDVATNUMBER,
                }}
                mode="asyncOptions"
                asyncSearch
                creatable
                fetchOnEmpty={false}
                themeOptions={{ shouldOptionsShowSelectedIndicator: false }}
                inputValue={
                  formMeta.shouldPrefillVATPrefix
                    ? country?.VATPrefix || countryCode
                    : undefined
                }
                resetInputValueOnFocus
                cacheKey={[RevenuesCacheKeys.RevenuesClientsSearch]}
                enabled={formMeta.isCountrySearchable}
                onFetch={(search) => {
                  if (
                    !/\d/.test(search as string) ||
                    caseInsensitiveEqual(search as string, countryCode)
                  ) {
                    return Promise.resolve([]);
                  }
                  return searchEntrepriseClientsByVATNumber(
                    search as string,
                    countryCode,
                  );
                }}
                afterFetch={(clients: ClientData[]) =>
                  clients.map((client) => ({
                    label: client.name,
                    value: client.VATNumber,
                    client,
                  }))
                }
                formatOptionLabel={(option: any, meta) => {
                  if (meta.context === 'value') return option.value;
                  return (
                    <div className="leading-tight">
                      <div>{option.label}</div>
                      <div>{option.value}</div>
                    </div>
                  );
                }}
                onChange={(option: any) => {
                  const value = caseInsensitiveEqual(option?.value, countryCode)
                    ? null
                    : option?.value || null;
                  onChange(value);
                  onUpdateVATNumberOrName(option?.client);
                  if (value) populateFieldsTypeAndVATStatus();
                }}
                placeholder=""
                {...rest}
              />
            )}
          </FieldConnector>

          {formMeta.shouldShowEuVATWarning && (
            <Alert
              type="info"
              showIntercomBtn
              name="client form EU VAT"
              description={t('client.eu_vat.warning')}
            />
          )}

          <FieldConnector
            name="VATStatus"
            showField={formMeta.shouldShowVATStatus}
            label={t('client.field.subject_to_vat.label')}
          >
            {({ field }) => (
              <VATType
                tracing={{
                  onMenuOpen: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDVATSTATUS,
                  onChange: ClientsEvents.WEBAPP_CLIENTITEM_UPDATEDVATSTATUS,
                }}
                {...field}
              />
            )}
          </FieldConnector>

          <FieldConnector
            name="address.street"
            label={t('client.field.street')}
          >
            {({ field }) => (
              <FreeTextInput
                tracing={{
                  onFocus: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDSTREET,
                }}
                {...field}
                onChange={(e) => field.onChange(e.target.value || null)}
              />
            )}
          </FieldConnector>

          <FieldConnector name="address.zip" label={t('client.field.zipcode')}>
            {({ field }) => (
              <FreeTextInput
                {...field}
                tracing={{
                  onFocus: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDPOSTALCODE,
                }}
                onChange={(e) => field.onChange(e.target.value || null)}
              />
            )}
          </FieldConnector>

          <FieldConnector name="address.city" label={t('client.field.city')}>
            {({ field }) => (
              <FreeTextInput
                tracing={{
                  onFocus: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDCITY,
                }}
                {...field}
                onChange={(e) => field.onChange(e.target.value || null)}
              />
            )}
          </FieldConnector>

          <FieldConnector name="email" label={t('client.field.email')}>
            {({ field }) => (
              <FreeTextInput
                tracing={{
                  onFocus: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDEMAIL,
                }}
                {...field}
                onChange={(e) => field.onChange(e.target.value || null)}
              />
            )}
          </FieldConnector>
          <FieldConnector
            name="phoneNumber"
            label={`${t('phoneNumber')} (${t('field.optional.tag')})`}
          >
            {({ field }) => (
              <FreeTextInput
                tracing={{
                  onFocus: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDPHONENUMBER,
                }}
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          </FieldConnector>
          <FieldConnector
            name="notes"
            label={`${t('client.field.notes')} (${t('field.optional.tag')})`}
          >
            {({ field }) => (
              <FreeTextInput
                tracing={{
                  onFocus: ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDNOTES,
                }}
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          </FieldConnector>
          {footerMeta}
        </div>
        <Button
          loading={isSubmitting}
          className="sticky bottom-20 left-[50%] -translate-x-1/2 translate-y-[20px]"
          size="xl"
          color="revenues"
          tracingEvent={ClientsEvents.WEBAPP_CLIENTITEM_CLICKEDSAVE}
          onClick={(e) => {
            formAPI.trigger().then((valid) => {
              if (valid) {
                return formAPI.handleSubmit(onSubmit)(e);
              }
              const fieldWithError =
                document.querySelector('.field-with-error');
              if (fieldWithError)
                fieldWithError.scrollIntoView({ behavior: 'smooth' });
            });
          }}
        >
          {t('save')}
        </Button>
      </form>
    </FormProvider>
  );
};

export default Form;
