import classNames from 'classnames';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { AnchorLinkProps, LinkProps } from './Link.types';

const isExternalUrl = (url: string): boolean =>
  new RegExp('^http[s]?://(www.)?(.*)?/?(.)*').test(url);

const HTMLAnchorLink: FC<AnchorLinkProps> = ({ children, ...props }) => (
  <a {...props}>{children}</a>
);

const Link: FC<LinkProps> = ({ href = '', children, className, ...props }) => {
  const LinkComponent = isExternalUrl(href) ? HTMLAnchorLink : RouterLink;

  return (
    <LinkComponent
      href={href}
      to={href}
      {...props}
      className={classNames(
        'text-sm font-semibold text-primary border-b-2 border-dotted transition duration-300 border-b-transparent hover:border-b-taxes-500 pb-1',
        className,
      )}
    >
      {children}
    </LinkComponent>
  );
};

export default Link;
