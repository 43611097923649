import { getSuppliersOptions } from 'api/v1/expenses';
import { Select } from 'components/atoms/Form/Select';
import { SelectProps } from 'components/atoms/Form/Select/select.types';
import type { FC } from 'react';
import type { FieldProps } from './commonFieldProps.types';

const SupplierName: FC<FieldProps & SelectProps> = (props) => {
  return (
    <Select
      mode="asyncOptions"
      asyncSearch
      creatable
      fetchOnEmpty={false}
      themeOptions={{
        shouldOptionsShowSelectedIndicator: false,
        hideDropdownIndicator: true,
      }}
      placeholder=""
      onFetch={(value) => getSuppliersOptions(value as string)}
      styles={{
        container: (provided) => ({
          ...provided,
          position: 'static',
          pointerEvents: 'auto',
        }),
      }}
      isDisabled={props.disabled}
      {...props}
    />
  );
};

export default SupplierName;
