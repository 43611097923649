import i18n from 'i18n';
import type { Dictionary } from 'lodash';
import { isEmpty, pick, transform } from 'lodash';
import formatMoney from 'utils/formatMoney';
import type { FormattedMetadata, Metadata } from './types';

const moneyKeys = [
  'totalRevenue',
  'franchiseThreshold',
  'VATAmountDiff',
  // 'incomeTaxDeductibility',
  // 'VATDeductibility',
  'amountExclVAT',
  'professionalAmount',
  'benchmarkAmount',
  'userAmount',
  'previousAmount',
  'currentAmount',
  'expectedTaxableIncome',
  'thisYearMaxContribution',
  'thisYearExcessContribution',
  'totalAmountFSP',
  'totalTaxSavingFSP',
  'thisYearContributionFSP',
  'thisYearTaxSavingFSP',
  'taxSaving',
  'taxSavingRecurring',
  'netAfterTax',
  'amount',
];

const regularKeys = [
  'currentYear',
  'number',
  'vatRates',
  'vatPercentage',
  'guaranteedReturn',
  'yearsUntilRetirement',
  'EasyvestLink',
  'expense',
  'invoice',
  'incomePercentage',
  'vehicleName',
  'taxRate',
  'quarter',
  'percentile',
  'oneMinusPercentile',
  'profession',
  'isFirstTaxImpactTip',
  'previousQuarter',
  'benchmarkFrequency',
  'userFrequency',
  'APC1_plural',
  'APC2_singular',
];

const translatedKeys = ['country'];

const dynamicValues = {
  currentYear: new Date().getFullYear(),
};

export const formatMetadata = (
  metadata: Metadata | undefined,
): FormattedMetadata => {
  if (isEmpty(metadata)) return {};

  const moneyValues = transform(
    pick(metadata, moneyKeys),
    (acc: Dictionary<string>, value: number, key) => {
      if (value == null) return;
      acc[key] = formatMoney(value);
    },
  );

  const regularValues = transform(
    pick(metadata, regularKeys),
    (acc: Dictionary<string | number>, value, key) => {
      if (value == null) return;
      acc[key] = value;
    },
  );

  const translatedValues = transform(
    pick(metadata, translatedKeys),
    (acc: Dictionary<string>, value, key) => {
      if (typeof value !== 'string') return;
      acc[key] = i18n.t(value);
    },
  );

  return {
    ...moneyValues,
    ...regularValues,
    ...translatedValues,
    ...dynamicValues,
    expenseId: `/expenses?id=${
      metadata?.expense?.id || '6627d7a2f9a089c32afc9164'
    }`,
    revenueId: `/expenses?id=${metadata?.invoice?.id || metadata?.revenue?.id}`,
  };
};
