import type { fileState } from 'components/molecules/FileUploadedList/FileUploaded.types';
import type { fileUploaderMeta } from 'components/organisms/FileUploader/FileUploader.types';
import type { ReactNode } from 'react';
import type { AccountantReview } from './accountantReview.types';
import type { DocumentSubmission, Period } from './global.types';
import type { Price } from './price.types';
import type { BEVehicle, DEVehicle } from './vehicle.types';

export enum ReviewStatusEnum {
  reviewed = 'reviewed',
  notReviewed = 'not_reviewed',
}

export enum WhyZeroVAT {
  REVERSE_CHARGE = 'reverseCharge',
  OTHER = 'other',
  NO_VAT = 'noVAT',
  CO_CONTRACTOR = 'coContractor',
}

export enum ExpenseCategoryType {
  GOOD = 'good',
  SERVICE = 'service',
}

export enum SupplierCountriesEnum {
  be = 'be',
  de = 'de',
  eu = 'eu',
  other = 'other',
}

export type SupplierCountry = keyof typeof SupplierCountriesEnum;

export type Supplier = {
  supplierType: string;
  name: string;
  country: SupplierCountriesEnum;
};

export type GuessedExpense = {
  supplierName: string;
  categoryId: string;
  vehicle: BEVehicle | DEVehicle | null;
  professionalPart: number | null;
  category: any;
  isInvoice?: boolean;
  guessedFrom: string;
  supplierCountry: string;
};

export type ExpenseItem = {
  id: string;
  _id?: string;
  name: string;
  amountExclVAT: number;
  VATAmount: number;
  netPrice: number;
  professionalPart: number;
  categoryId: string;
  amount: number;
  qty: number;
  VATStatus: string;
  vat: number;
  unitPrice: number;
  whyZeroVAT: string;
  isAsset?: boolean;
  amortizationPeriod?: number;
  vehicleId?: string;
  vehicle?: BEVehicle | DEVehicle;
  localVATRate?: number;
  maxDeductibleVAT: number;
  incomeTaxDeductibility: number;
  category?: any;
};

type ocrDATA = {
  date: string | null;
  supplier: {
    name?: string | null;
  };
  totalAmount: number | null;
  VATAmount: number | null;
  VATNumber: number | null;
  communication: string | null;
  IBAN: string | null;
  expenseNumber: string | null;
};

type guessedDATA = {
  categoryId: string;
  date: string;
  from: string;
  isInvoice: boolean;
  supplier: {
    name: string;
    country: string;
  };
  totalAmount: number;
  VATAmount: number;
  VATNumber: number;
};

export type Expense = {
  _id: string;
  ocr: ocrDATA;
  guessed: guessedDATA;
  id: string;
  created: number;
  expenseDate: number | string;
  amount: number;
  supplier: Supplier | null;
  price: Price;
  accountantReview?: AccountantReview;
  notes?: string;
  items: ExpenseItem[];
  customFeesAmount?: number;
  filename: string;
  filePath: string;
  fileHash: string;
  userSnapshot: any;
  isCreditNote: boolean;
  cashRemainder: boolean;
  isValidated: boolean;
  isInvoice: boolean;
  VATStatus: string;
  matchCard: boolean;
  transactions?: string[];
  period: Period;
  imported: boolean;
  submissions: DocumentSubmission[];
  createdFrom: 'email' | 'mobile' | 'web' | 'dropzone' | 'experts' | 'bank';
  sufficientlyDocumented: boolean;
};

export type Cell = {
  cellName: string;
  headerName: string;
  renderCell: any;
  size?: 'small' | 'medium' | 'large';
  availableOrders: string[];
  headerNameVariation?: string;
};

export enum ExpenseHighlights {
  New = 'new',
}

type FilterKey = 'status' | 'type' | 'source' | 'period' | 'category';

type FilterValue =
  | 'paid'
  | 'overdue'
  | 'sent'
  | 'not-sent'
  | 'draft' // status
  | 'invoice'
  | 'credit-note'
  | 'other-revenue' // type
  | 'mobile'
  | 'web'
  | 'email'
  | 'accountant'; // source

export type ExpenseFilter = {
  key: FilterKey;
  values: FilterValue[];
};

export type HomeExpenseUploaderProps = {
  onUpload: (result: any) => void;
  onClickReviewNow?: (fileUploaderMeta: fileUploaderMeta) => void;
  onClickReviewLater?: (fileUploaderMeta: fileUploaderMeta) => void;
  renderUploadedFileItem?: (props: fileState, filesList: fileState[]) => any;
  hideDropzoneWhileUploading?: boolean;
  renderHeader?: ((meta: any) => ReactNode) | null;
  renderFooter?: ((meta: any) => ReactNode) | null;
};
