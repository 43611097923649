import * as Sentry from '@sentry/react';
import AccountableLogo from 'components/atoms/AccountableLogo';
import useAuthStatus from 'hooks/Authentication/useAuthStatus';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import { useInitializeIntercom } from 'hooks/intercom.hook';
import type { FC, ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import type { RouteType } from '../../types/routing.types';

import routes from './routing/routes';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App: FC = () => {
  const { isAuthenticated, isLoadingAuthData } = useAuthStatus();
  const { isLoading: isLoadingCustomerData } = useGetCurrentCustomerData();

  useInitializeIntercom();

  if (isLoadingAuthData || isLoadingCustomerData)
    return (
      <div className="flex flex-1 h-screen items-center justify-center bg-white">
        <AccountableLogo className="w-52" />
      </div>
    );

  return (
    <SentryRoutes>
      {routes.map(
        ({ path, Component, private: isPrivate }: RouteType): ReactNode =>
          isPrivate ? (
            <Route
              path={path}
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  {Component}
                </PrivateRoute>
              }
              key={path}
            />
          ) : (
            <Route path={path} element={Component} key={path} />
          ),
      )}
    </SentryRoutes>
  );
};

export default App;
