import useModal from 'hooks/useModal';
import { useMemo } from 'react';
import { CategoriesPickerProps } from './categoriesPicker.types';
import CategoriesPickerContent from './CategoriesPickerContent.component';
import { attachData, getCategoriesById } from './utils';

export type UseCategoriesPickerProps = Pick<
  CategoriesPickerProps,
  | 'categories'
  | 'searchBarPlaceholder'
  | 'header'
  | 'renderGridHeader'
  | 'modalWidth'
  | 'tooltips'
  | 'helpers'
  | 'disabledCategories'
  | 'renderHeader'
>;

export type UseCategoriesPickerOpenProps = Pick<
  CategoriesPickerProps,
  'value' | 'onChange'
>;

const useCategoriesPicker = (props: UseCategoriesPickerProps) => {
  const {
    categories = [],
    searchBarPlaceholder,
    header,
    renderGridHeader,
    modalWidth = '7xl',
    tooltips,
    helpers,
    disabledCategories,
    renderHeader,
  } = props;

  const data = useMemo(() => {
    const categoriesList = attachData(categories);
    const categoriesDict = getCategoriesById(categoriesList);
    return { categoriesList, categoriesDict };
  }, [categories]);

  const [content, api] = useModal({
    name: 'categories picker',
    width: modalWidth,
    className: 'h-full max-h-[800px]',
    dialogPanalClassName: '!p-0',
  });

  return {
    content,
    data,
    close: api.close,
    open: ({ value, onChange }: UseCategoriesPickerOpenProps) =>
      api.open(() => {
        return (
          <CategoriesPickerContent
            searchBarPlaceholder={searchBarPlaceholder}
            onChange={(category: any) => {
              onChange(category, data.categoriesDict);
              api.close();
            }}
            value={value}
            valueCategory={value ? data.categoriesDict[value] : null}
            categoriesList={data.categoriesList}
            categoriesDict={data.categoriesDict}
            header={header}
            renderGridHeader={renderGridHeader}
            tooltips={tooltips}
            helpers={helpers}
            disabledCategories={disabledCategories}
            renderHeader={renderHeader}
          />
        );
      }),
  };
};

export default useCategoriesPicker;
