import i18n from 'i18n';
import { TaxTypeEnum } from 'types/taxes.types';
import type { AvailableCountries } from 'types/users.types';

const getTaxName = (tax: any, customerCountry: AvailableCountries): string => {
  return i18n.t(
    `taxes.filters.types.${tax?.type}${
      [TaxTypeEnum.vat, TaxTypeEnum.intracomListing].includes(tax?.type)
        ? `.${customerCountry}`
        : ''
    }`,
  );
};

export default getTaxName;
