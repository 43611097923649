const DEFAULT_COUNTRY = 'be';

const germanStates = {
  'Baden-Württemberg': 28,
  Bayern: 9,
  Berlin: 11,
  Brandenburg: 3,
  Bremen: 24,
  Hamburg: 22,
  Hessen: 26,
  'Mecklenburg-Vorpommern': 4,
  Niedersachsen: 23,
  'Nordrhein-Westfalen': 5,
  'Rheinland-Pfalz': 27,
  Saarland: 1,
  Sachsen: 3,
  'Sachsen-Anhalt': 3,
  'Schleswig-Holstein': 21,
  Thüringen: 4,
};

const germanStatesArr = Object.entries(germanStates).map((el) => ({
  label: el[0], // name
  value: el[1], // code
}));

export { DEFAULT_COUNTRY, germanStates, germanStatesArr };
