export enum BankProviders {
  klarna = 'klarna',
  ibanity = 'ibanity',
  finAPI = 'finAPI',
  accountable = 'accountable',
  creditCard = 'credit-card',
}

export enum BankStatus {
  stable = 'stable',
  beta = 'beta',
  dev = 'dev',
}

export enum BankFieldType {
  iban = 'iban',
  email = 'email',
}

export type BankField = {
  type: BankFieldType;
  name: string;
  format?: string;
  hint?: string;
};

export type Bank = {
  id: string | number;
  name: string;
  provider: BankProviders;
  fields: BankField[];
  bic: string;
  status: BankStatus;
  logoUrl?: string; // only available for ibanity
  fullLogoUrl?: string;
  index?: number;
  minBuildNumber?: number;
  maintenance: boolean;
  city?: string; // only available for finAPI
  financialInstitutionSlug?: string;
};
