import useBlockNavigation from 'hooks/useBlockNavigation';
import useConfirm from 'hooks/useConfirm';
import { useImperativeHandle, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import type { IColors } from 'theme/colors';

export type CloseMeta = {
  action: 'natural' | 'navigation' | 'force';
};

export const naturalClose: CloseMeta = { action: 'natural' };
export const forceClose: CloseMeta = { action: 'force' };
export const navigationClose: CloseMeta = { action: 'navigation' };

type DefaultDynamicOptions = Record<string, any> | undefined;

export type FormSlideOverManagerApi<
  DocTypes,
  DynamicOptions = DefaultDynamicOptions,
> = {
  open: (doc: DocTypes, options?: DynamicOptions) => void;
  close: (meta?: CloseMeta) => void;
  isOpen: boolean;
  currentDoc: DocTypes | null;
  options: DynamicOptions | undefined;
  pauseNavigationBlocking: () => void;
  resumeNavigationBlocking: () => void;
  pauseDirtyTracking: () => void;
  resumeDirtyTracking: () => void;
  reset: () => void;
};

export type FormSlideOverManagerOptions<DocTypes> = {
  apiRef: any;
  onOpen?: (preloadedDoc: DocTypes) => void;
  enableNavigationBlockingIfDirty?: boolean;
  color?: IColors;
  onClose?: (meta: CloseMeta, preloadedDoc: DocTypes) => void;
};

export function useFormSlideOverManager<
  DocTypes,
  DynamicOptions = DefaultDynamicOptions,
>({
  apiRef,
  onClose,
  onOpen,
  enableNavigationBlockingIfDirty = true,
  color,
}: FormSlideOverManagerOptions<DocTypes>) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<DynamicOptions>();
  const [currentDoc, setCurrentDoc] = useState<DocTypes | null>(null);

  const [docNumber, setDocNumber] = useState(0);
  const formKey = `_${docNumber}`;

  const [confirmContent, confirmApi] = useConfirm();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isFormDirtyTrackingPaused, setFormDirtyTrackingStatus] =
    useState(false);
  const [isNavigationBlockingPaused, setNavigationBlockingStatus] =
    useState(false);

  const isFormDirtyRef = useRef(isFormDirty);
  isFormDirtyRef.current = isFormDirty;
  const isFormDirtyTrackingPausedRef = useRef(isFormDirtyTrackingPaused);
  isFormDirtyTrackingPausedRef.current = isFormDirtyTrackingPaused;
  const formKeyRef = useRef(formKey);
  formKeyRef.current = formKey;

  const pauseNavigationBlocking = () => {
    flushSync(() => setNavigationBlockingStatus(true));
  };

  const resumeNavigationBlocking = () => {
    flushSync(() => setNavigationBlockingStatus(false));
  };

  const pauseDirtyTracking = () => {
    flushSync(() => setFormDirtyTrackingStatus(true));
  };

  const resumeDirtyTracking = () => {
    flushSync(() => setFormDirtyTrackingStatus(false));
  };

  const unSavedChangesDefaultSettings = {
    className: 'text-center font-semibold',
    buttonsWrapperClassName: 'justify-center',
    buttonClassName: 'min-w-[90px]',
    okText: t('yes'),
    message: t('warning.un­saved_changes'),
  };

  useBlockNavigation({
    shouldBlock:
      enableNavigationBlockingIfDirty &&
      isFormDirty &&
      !isFormDirtyTrackingPaused &&
      open &&
      !isNavigationBlockingPaused,
    onBlockedNavigation: (api) => {
      confirmApi.open({
        ...unSavedChangesDefaultSettings,
        onConfirm: () => {
          api.next();
          onClose?.(navigationClose, currentDoc as DocTypes);
        },
      });
    },
  });

  const reset = () => {
    flushSync(() => {
      setOpen(false);
      setCurrentDoc(null);
      setOptions(undefined);
      setIsFormDirty(false);
    });
  };

  const api: FormSlideOverManagerApi<DocTypes, DynamicOptions> = {
    open: (doc, openOptions) => {
      if (!doc) return;
      const populate = () => {
        flushSync(() => {
          setOpen(true);
          setDocNumber((n) => n + 1);
          setCurrentDoc(doc);
          setOptions(openOptions);
          setIsFormDirty(false);
        });
        onOpen?.(doc);
      };

      if (isFormDirtyRef.current && !isFormDirtyTrackingPausedRef.current) {
        return confirmApi.open({
          color,
          ...unSavedChangesDefaultSettings,
          onConfirm: populate,
        });
      }
      populate();
    },
    close: (meta = naturalClose) => {
      if (formKeyRef.current !== formKey) return;
      if (
        !isFormDirtyRef.current ||
        isFormDirtyTrackingPausedRef.current ||
        meta.action === 'force'
      ) {
        reset();
        return onClose?.(meta, currentDoc as DocTypes);
      }
      confirmApi.open({
        ...unSavedChangesDefaultSettings,
        onConfirm: () => {
          reset();
          onClose?.(meta, currentDoc as DocTypes);
        },
      });
    },
    isOpen: open,
    currentDoc,
    options,
    pauseNavigationBlocking,
    resumeNavigationBlocking,
    pauseDirtyTracking,
    resumeDirtyTracking,
    reset,
  };

  useImperativeHandle(apiRef, () => api);

  return {
    confirmContent,
    doc: currentDoc,
    reset,
    open,
    api,
    isFormDirtyRef: setIsFormDirty,
    options,
    pauseNavigationBlocking,
    resumeNavigationBlocking,
    pauseDirtyTracking,
    resumeDirtyTracking,
    formKey,
  };
}
