import { getWorkingDaysAndDeductibleAmount } from 'api/v2/expenses';
import { useQuery } from 'react-query';
import { ExpensesCacheKeys } from 'types/cacheKeys.types';

const useGetWorkingDaysAndDeductibleAmount = (
  year: number | undefined,
  expenseId?: string,
  enabled = true,
) => {
  return useQuery(
    [ExpensesCacheKeys.ExpenseWorkingDaysAndDeductibleAmount, year, expenseId],
    () => getWorkingDaysAndDeductibleAmount(year as number, expenseId),
    {
      placeholderData: { days: 0, amount: 0 },
      enabled: enabled && !!year,
      cacheTime: 0,
    },
  );
};

export default useGetWorkingDaysAndDeductibleAmount;
