import { TrashIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Button, type ButtonComponentProps } from 'components/atoms/Button';
import { Modal, type ModalProps } from 'components/atoms/Modal';
import { Helper } from 'components/molecules/Helper';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

type TableDeleteBulkActionProps = {
  selected: string[];
  onDelete: (ids: string[]) => Promise<any>;
  onAfterDelete?: () => void;
  title: ReactNode;
  description: ReactNode;
  className?: string;
  structure?: string;
  triggerButtonLabel?: string;
  confirmButtonLabel?: string;
  buttonColor?: ButtonComponentProps['color'];
  ButtonIcon?: FC;
  color?: ModalProps['color'];
  titleClassName?: string;
  descriptionClassName?: string;
};

export const TableDeleteBulkAction: FC<TableDeleteBulkActionProps> = ({
  selected,
  onDelete,
  onAfterDelete,
  title,
  description,
  className = '',
  structure = 'primary',
  triggerButtonLabel,
  confirmButtonLabel,
  ButtonIcon,
  color,
  titleClassName,
  descriptionClassName,
  buttonColor,
}) => {
  const { mutateAsync, isLoading } = useMutation(onDelete, {
    onSuccess: onAfterDelete,
  });
  const { t } = useTranslation();
  return (
    <Modal
      width="md"
      name={`"bulk delete: ${title}`}
      color={color || 'primary'}
      trigger={(api) => (
        <Button
          size="lg"
          structure={structure as any}
          onClick={api.openModal}
          className={classNames(className, {
            hidden: !selected.length,
          })}
        >
          {triggerButtonLabel || t('delete')}
        </Button>
      )}
      render={(api) => (
        <div className="text-center">
          <Helper.Title className={titleClassName}>{title}</Helper.Title>
          <p
            className={classNames(
              'mt-3 first-letter:capitalize',
              descriptionClassName,
            )}
          >
            {description}
          </p>
          <div className="flex justify-center gap-2 mt-5">
            <Button
              className="capitalize min-w-[90px]"
              structure="secondary"
              color={buttonColor || 'primary'}
              onClick={api.closeModal}
            >
              {t('cancel')}
            </Button>
            <Button
              className="min-w-[90px]"
              loading={isLoading}
              color={buttonColor || 'red'}
              icon={ButtonIcon || TrashIcon}
              onClick={() => {
                mutateAsync(selected).then(() => api.closeModal());
              }}
            >
              {confirmButtonLabel || t('delete')}
            </Button>
          </div>
        </div>
      )}
    />
  );
};
