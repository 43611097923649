import classNames from 'classnames';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import colors from 'theme/colors';
import { CheckIcon, QuestionMarkCircleIcon, XIcon } from 'utils/icons';
import { Tooltip } from '../Tooltip';

type ItemType = {
  content: string;
  type?: 'positive' | 'negative';
  tooltip?: {
    title: string;
    description: string;
  };
};
type ItemProps = {
  itemClassName?: string;
  iconClassName?: string;
  itemTextClassName?: string;
};
export interface ListProps {
  type?: 'vertical' | 'horizontal';
  data: ItemType[];
  listClassName?: string;
  itemClassName?: string;
  iconClassName?: string;
  itemTextClassName?: string;
}

const ListItem: FC<ItemType & ItemProps> = ({
  content,
  type,
  itemClassName,
  iconClassName,
  itemTextClassName,
  tooltip,
}) => {
  const { t } = useTranslation();

  return (
    <div key={content} className={classNames('flex pb-6 pl-6', itemClassName)}>
      {type === 'positive' ? (
        <CheckIcon
          className={classNames('w-5 h-5 text-green-400', iconClassName)}
          aria-hidden="true"
        />
      ) : (
        <XIcon
          className={classNames('w-5 h-5 text-gray-400', iconClassName)}
          aria-hidden="true"
        />
      )}

      <span className={classNames('pl-6 text-primary-700', itemTextClassName)}>
        {t(content)}
      </span>

      {tooltip ? (
        <Tooltip
          overlayClassName="!min-w-[400px] !rounded-2xl"
          overlayInnerStyle={{
            backgroundColor: colors.primary[900],
            borderRadius: 16,
          }}
          content={
            <div className="p-8 text-white bg-primary-900 transition-opacity">
              <h3 className="font-bold text-xl mb-3">{t(tooltip.title)}</h3>

              <p className="text-base font-medium">
                <ReactMarkdown
                  components={{
                    a: ({ children, href }) => (
                      <a
                        href={href as string}
                        className="!text-white underline font-medium"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {children}
                      </a>
                    ),
                  }}
                >
                  {t(tooltip.description)}
                </ReactMarkdown>
              </p>
            </div>
          }
        >
          <QuestionMarkCircleIcon className="w-5 ml-2 text-primary-400" />
        </Tooltip>
      ) : null}
    </div>
  );
};

const List: FC<ListProps> = ({
  data,
  type = 'vertical',
  listClassName,
  itemClassName,
  iconClassName,
  itemTextClassName,
}) => {
  return (
    <div
      className={classNames(
        'flex',
        { 'flex-col': type === 'vertical' },
        listClassName,
      )}
    >
      {data.map(({ content, type = 'positive', tooltip }) => (
        <ListItem
          key={content}
          content={content}
          type={type}
          tooltip={tooltip}
          itemClassName={itemClassName}
          iconClassName={iconClassName}
          itemTextClassName={itemTextClassName}
        />
      ))}
    </div>
  );
};

export default List;
