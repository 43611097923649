import * as config from 'config';
import type { Channel } from 'pusher-js';
import Pusher from 'pusher-js';
import { getStoredAuthData } from 'utils/auth';

const getChannelName = (id: string) => `private-${id}`;

let channel: Channel | undefined;

export const disconnectUserChannel = () => {
  channel?.disconnect();
  channel = undefined;
  return channel;
};

export const getOrCreateChannel = (userId: string) => {
  const channelName = getChannelName(userId);

  if (channel && channel.name === channelName) {
    return channel;
  }
  disconnectUserChannel();

  const instance = new Pusher(config.pusher.key, {
    authEndpoint: `${config.accountableAPI.baseUrl}/v1/users/me/pusher/auth`,
    cluster: 'eu',
    auth: {
      params: {},
      headers: {
        Authorization: `Bearer ${getStoredAuthData('access_token')}`,
      },
    },
  });

  channel = instance.subscribe(channelName);

  return channel;
};

export const getUserChannel = () => channel;
