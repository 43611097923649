import { joiResolver } from '@hookform/resolvers/joi';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import DatePicker from 'components/atoms/DatePicker/DatePicker.component';
import { FieldConnector } from 'components/atoms/Form/FieldConnector';
import FreeTextInput from 'components/atoms/Form/FreeTextInput';
import { Select } from 'components/atoms/Form/Select';
import NumberField from 'components/molecules/Fields/Number/Number';
import { Helper } from 'components/molecules/Helper';
import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import Joi from 'joi';
import type { FC } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { AccountTypeEnum, type IUser } from 'types/users.types';
import { isNumeric } from 'utils/math';
import {
  currentYear,
  vehiclePurchaseDateMax,
  vehiclePurchaseDateMaxAsNumber,
  vehiclePurchaseDateMin,
  vehiclePurchaseDateMinAsNumber,
} from '../../utils';

const markdownComponents = {
  p: ({ children }: any) => <p className="whitespace-pre-line">{children}</p>,
  a: ({ children, href }: any) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="underline break-word"
    >
      {children}
    </a>
  ),
};

const fuelTypeOptions = [
  { value: 'diesel', label: 'expense.diesel' },
  { value: 'gas', label: 'expense.gas' },
  { value: 'LPG', label: 'expense.LPG' },
  { value: 'electric', label: 'expense.electric' },
  { value: 'hybrid', label: 'expense.hybrid' },
  { value: 'CNG', label: 'expense.CNG' },
];

const usageTypeOptions = [
  { value: 'professionalDriver', label: 'expense.professionalDriver' },
  { value: 'other', label: 'expense.other' },
];

const contractTypeOptions = [
  { value: 'userOwned', label: 'expense.userOwned' },
  { value: 'userLeased', label: 'expense.userLeased' },
  { value: 'userRented', label: 'expense.userRented' },
  { value: 'other', label: 'expense.other' },
];

export const VehicleFormValidationSchema = Joi.object({
  name: Joi.string()
    .min(2)
    .max(30)
    .required()
    .messages({ '*': 'expense.name_must_be_between_2_and_30_characters' }),

  fuelType: Joi.string()
    .required()
    .messages({ '*': 'expense.please_select_fuel_type' }),

  co2Emission: Joi.when('fuelType', {
    is: Joi.string().not('electric'),
    then: Joi.number().required().min(0).max(999),
  }).messages({ '*': 'expense.emission_must_be_between_0_and_999' }),
  weight: Joi.when('fuelType', {
    is: Joi.string().equal('hybrid'),
    then: Joi.number().required().min(10).max(5000),
  }).messages({ '*': 'expense.weight_must_be_between_10_and_5000' }),
  batteryCapacity: Joi.when('fuelType', {
    is: Joi.string().equal('hybrid'),
    then: Joi.number().required().min(0).max(500),
  }).messages({ '*': 'expense.battery_capacity_must_be_between_0_and_500' }),
  fiscalHorsepower: Joi.when('fuelType', {
    is: Joi.string().equal('CNG'),
    then: Joi.number().required().min(1).max(5000),
  }).messages({ '*': 'expense.horsepower_must_be_between_1_and_5000' }),

  registrationYear: Joi.number()
    .required()
    .min(1900)
    .max(currentYear)
    .messages({
      '*': 'expense.year_of_registration_must_be_1900_and_current_year',
    }),
  orderDate: Joi.number()
    .min(vehiclePurchaseDateMinAsNumber)
    .max(vehiclePurchaseDateMaxAsNumber)
    .required()
    .messages({
      'date.min': 'vehicle.orderDate.date.min',
      'date.max': 'vehicle.orderDate.date.max',
      '*': 'vehicle.orderDate.date.empty',
    }),
  usageType: Joi.string()
    .required()
    .messages({ '*': 'expense.please_select_usage_type' }),
  contractType: Joi.string()
    .required()
    .messages({ '*': 'expense.please_select_contract_type' }),
});

export const VehicleForm: FC<any> = ({ defaultValues, onSubmit }) => {
  const { t } = useTranslation();
  const formApi = useForm({
    defaultValues,
    resolver: joiResolver(VehicleFormValidationSchema, {
      allowUnknown: true,
      abortEarly: false,
    }),
  });
  //
  const isEdit = !!defaultValues?._id;

  const customer = useGetCurrentCustomerData().data as IUser;

  return (
    <FormProvider {...formApi}>
      <div className="bg-[#f6f6fd] px-8 py-10 rounded-t-2xl">
        <p className="text-primary-900 text-lg leading-tight">
          {t(
            'expense.we_need_some_information_about_your_vehicle_to_compute_your_income_tax_properly',
          )}
        </p>
        <p className="text-primary-700 leading-tight mt-4">
          {t('expense.be_cars_form.subtitle', {
            taxType: t(
              customer?.accountType === AccountTypeEnum.one_person_company
                ? 'expenses.helper.car_deductibility.corporate_tax'
                : 'expenses.helper.car_deductibility.Income_tax',
            ).toLowerCase(),
          })}
        </p>
      </div>
      <div className="px-8 py-4">
        {isEdit && (
          <Alert
            type="warning"
            description={t('vehicle.modifications_warning')}
          />
        )}
        <VehicleFormFields className="flex flex-col gap-4" />
        <Button
          loading={formApi.formState.isSubmitting}
          size="xl"
          className="mt-6 mb-4 w-full"
          onClick={formApi.handleSubmit(onSubmit)}
        >
          {t('save')}
        </Button>
      </div>
    </FormProvider>
  );
};

export const VehicleFormFields: FC<{
  className?: string;
  warningsClassName?: string;
}> = ({ className, warningsClassName }) => {
  const { t } = useTranslation();
  const formApi = useFormContext();

  const formValues = formApi.watch();

  const { fuelType, usageType, co2Emission, weight, batteryCapacity } =
    formValues;

  const customer = useGetCurrentCustomerData().data as IUser;

  const shouldShowProfessionWarning =
    customer.APC1 &&
    usageType === 'professionalDriver' &&
    customer.APC1 !== 'drivers_and_courriers';

  const shouldShowFalseHybridWarning =
    fuelType === 'hybrid' &&
    ((isNumeric(co2Emission) && co2Emission > 50) ||
      (isNumeric(weight) &&
        isNumeric(batteryCapacity) &&
        batteryCapacity / weight < 0.005));

  return (
    <form className={className}>
      <FieldConnector
        name="name"
        label={t('expense.let_s_give_this_vehicle_a_name')}
      >
        {({ field }) => <FreeTextInput {...field} />}
      </FieldConnector>

      <FieldConnector
        name="fuelType"
        label={t('expense.what_kind_of_fuel_does_it_use')}
      >
        {({ field: { onChange, ...rest } }) => (
          <Select
            options={fuelTypeOptions}
            formatOptionLabel={(option: any) => t(option.label)}
            onChange={(option: any) => onChange(option.value)}
            {...rest}
          />
        )}
      </FieldConnector>

      <FieldConnector
        showField={
          !!(formValues.fuelType && formValues.fuelType !== 'electric')
        }
        name="co2Emission"
        label={`${t('expense.what_are_the_vehicle_s_emissions')}\n${t(
          'expense.you_can_find_this_on_its_registration_documents',
        )}`}
      >
        {({ field }) => (
          <NumberField
            placeholder={`100 ${t('expense.g_km')}`}
            format={{ prefix: ` ${t('expense.g_km')}` }}
            {...field}
          />
        )}
      </FieldConnector>

      {shouldShowFalseHybridWarning && (
        <Alert
          type="warning"
          showIntercomBtn
          name="false hybrid vehicle"
          title={
            <div className="flex gap-4">
              <span>{t('vehicle.false_hybrid.warning.title')}</span>
              <span className="flex-shrink-0">
                <Helper>
                  <Helper.Paragraph>
                    <ReactMarkdown components={markdownComponents}>
                      {t('vehicle.false_hybrid.helper.text')}
                    </ReactMarkdown>
                  </Helper.Paragraph>
                </Helper>
              </span>
            </div>
          }
          description={t('vehicle.false_hybrid.warning.text')}
        />
      )}

      <FieldConnector
        showField={formValues.fuelType === 'hybrid'}
        name="weight"
        label={`${t('expense.weight_of_the_car')}\n${t(
          'expense.you_can_find_this_on_its_registration_documents',
        )}`}
      >
        {({ field }) => (
          <NumberField
            placeholder={`1000 ${t('expense.kg')}`}
            format={{ prefix: ` ${t('expense.kg')}` }}
            {...field}
          />
        )}
      </FieldConnector>

      <FieldConnector
        showField={formValues.fuelType === 'hybrid'}
        name="batteryCapacity"
        label={`${t('expense.capacity_of_battery')}\n${t(
          'expense.you_can_find_this_on_its_registration_documents',
        )}`}
      >
        {({ field }) => (
          <NumberField
            placeholder={`50 ${t('expense.kwh')}`}
            format={{ prefix: ` ${t('expense.kwh')}` }}
            {...field}
          />
        )}
      </FieldConnector>

      <FieldConnector
        showField={formValues.fuelType === 'CNG'}
        name="fiscalHorsepower"
        label={t('expense.what_is_the_vehicle_horsepower')}
      >
        {({ field }) => (
          <NumberField
            placeholder={`100 ${t('expense.horsepower')}`}
            format={{ prefix: ` ${t('expense.horsepower')}` }}
            {...field}
          />
        )}
      </FieldConnector>

      <FieldConnector
        name="registrationYear"
        ErrorContext={{ currentYear }}
        label={`${t(
          'expense.what_is_the_vehicle_s_first_year_of_registration',
        )}\n${t('expense.you_can_find_this_on_its_registration_documents')}`}
      >
        {({ field }) => (
          <FreeTextInput placeholder="2009" type="number" {...field} />
        )}
      </FieldConnector>

      <FieldConnector
        name="orderDate"
        label={t('expense.vehicle.order_date.label')}
      >
        {({ field: { value, onChange, ...field } }) => (
          <DatePicker
            {...field}
            filterDate={(date: Date) =>
              dayjs(date).isBetween(
                vehiclePurchaseDateMin,
                vehiclePurchaseDateMax,
                'day',
                '[]',
              )
            }
            selected={value ? dayjs(value + '').format('YYYY-MM-DD') : null}
            onChange={(date) => {
              const value = date ? +dayjs(date).format('YYYYMMDD') : null;
              onChange(value);
            }}
          />
        )}
      </FieldConnector>

      <FieldConnector
        name="usageType"
        label={t('expense.what_do_you_use_this_vehicle_for')}
      >
        {({ field: { onChange, ...rest } }) => (
          <Select
            options={usageTypeOptions}
            placeholder={t('expense.any_other_usage')}
            formatOptionLabel={(option: any) => t(option.label)}
            onChange={(option: any) => onChange(option.value)}
            {...rest}
          />
        )}
      </FieldConnector>

      {shouldShowProfessionWarning && (
        <Alert
          className={warningsClassName}
          type="warning"
          showIntercomBtn
          description={t('vehicle.driver_profession_warning', {
            APC2: t(`${customer.country}.APC2.${customer.APC2}`),
          })}
        />
      )}

      <FieldConnector
        name="contractType"
        label={t('expense.what_kind_of_contract_do_you_have_on_this_vehicle')}
      >
        {({ field: { onChange, ...rest } }) => (
          <Select
            options={contractTypeOptions}
            placeholder={t('expense.i_own_it')}
            formatOptionLabel={(option: any) => t(option.label)}
            onChange={(option: any) => onChange(option.value)}
            {...rest}
          />
        )}
      </FieldConnector>
    </form>
  );
};
