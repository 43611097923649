import classNames from 'classnames';
import { createElement, forwardRef, type FC } from 'react';
import type { TableRowProps } from './table.types';

export const TableRow: FC<TableRowProps & { as?: any }> = forwardRef(
  ({ children, className, as, ...props }, ref) => {
    const classes = classNames('border-primary-50 border-t', className, {
      'hover:bg-primary-50 cursor-pointer': !!props.onClick,
    });
    return createElement(
      as || 'tr',
      { className: classes, ref, ...props },
      children,
    );
  },
);

TableRow.displayName = 'Table.Row';
