import dayjs from 'dayjs';
import i18n from 'i18n';
import type { PeriodTimeFrameType } from 'types/global.types';
import type { PeriodFilterOption } from 'types/shared/filters';
import type { SubWorld } from 'types/shared/worlds';
import { World } from 'types/shared/worlds';
import { TaxTypeEnum } from 'types/taxes.types';
import type { AvailableCountries } from 'types/users.types';
import { isExpert } from 'utils/constants';
import { periodRange } from 'utils/date';
import adaptOldCategorySlug from 'utils/expenses/adaptOldCategorySlugs';

// filters data mapping --------------------------------------
export const allowedFilters: Record<World | SubWorld, string[]> = {
  expenses: [
    'attachment',
    'payment',
    'isInvoice',
    isExpert ? 'isValidated' : 'reviewed',
    'source',
    'period',
    'categories',
  ],
  revenues: [
    'status',
    'type',
    isExpert ? 'isValidated' : 'reviewed',
    'payment',
    'isInvoice',
    'source',
    'period',
    'categoryIds',
  ],
  bank: [
    'toClassify',
    'amountSign',
    'transactionType',
    'transactionCategory',
    'period',
    'statementPeriod',
  ],
  taxes: ['type', 'status'],
  settings: [],

  // sub worlds
  clients: [],
  quotes: ['categoryIds'],
};

export const resolveOptionTranslationBasedOnWorld = (
  world: string,
  option: string | Record<string, string | number>,
  country: AvailableCountries,
): string => {
  if (option === 'credit-note')
    return i18n.t(`invoices.filters.${country}.credit_note`);
  const mapper = {
    [World.REVENUES]: () => i18n.t(`invoices.choices.${String(option)}`),
    [World.BANK]: () => i18n.t(`transactions.choices.${String(option)}`),
    [World.TAXES]: () => i18n.t(`taxes.${String(option)}`),
    default: () => i18n.t(`${world}.choices.${String(option)}`),
  };

  return (mapper[world as keyof typeof mapper] || mapper.default)();
};

const commonKeysMapper = { period: () => 'periods' };
export const filetrsKeyMapper: {
  [key in World | SubWorld]?: { [key: string]: () => string };
} = {
  expenses: { ...commonKeysMapper, categories: () => 'categoryIds' },
  revenues: { ...commonKeysMapper, type: () => 'types' },
  bank: {
    ...commonKeysMapper,
    transactionCategory: () => 'transactionCategories',
    transactionType: () => 'transactionTypes',
  },
};

// filters data displaying and transalting ----------------------------
export const radioKeys: Record<World | SubWorld, string[]> = {
  expenses: ['isInvoice', 'reviewed', 'isValidated'],
  revenues: ['isInvoice', 'reviewed', 'isValidated'],
  bank: ['toClassify', 'amountSign'],
  taxes: ['status'],
  settings: [],
  clients: [],
  quotes: [],
};

export const translationPrefix: Record<World | SubWorld, string> = {
  expenses: 'expenses.filters.',
  revenues: 'invoices.filters.',
  bank: 'transactions.filters.',
  taxes: 'taxes.filters.',
  settings: 'settings.filter.',
  clients: '',
  quotes: 'invoices.filters.',
};

export const filtersIcons: Record<string, JSX.Element> = {};

export const isCategoryFilter = (filterKey: string): boolean =>
  ['category', 'categories', 'categoryIds', 'transactionCategory'].includes(
    filterKey,
  );

export const getPeriodAsFilterOptionValue = (
  period: PeriodFilterOption,
): string => {
  const periodChar = period.frequency?.split('')[0];
  if (periodChar === 'y') return String(period.year);
  return `${periodChar}${`_${period.value}`}${`_${period.year}`}`;
};

export const getPeriodLabel = (period: Record<string, string>) => {
  const periodChar = period.frequency.split('')[0];
  let periodUnit = '';
  switch (periodChar) {
    case 'm':
      periodUnit = dayjs()
        .month(Number(period.value) - 1)
        .format('MMMM');
      break;
    case 'q':
      periodUnit = `${i18n.t('quarter_abbr')}${period.value}`;
      break;
  }
  return `${periodUnit} ${period.year}`;
};

export const slashedPeriodFormat = (
  period: string | Record<string, string | number>,
  format?: string,
): string => {
  const range = periodRange(period as unknown as PeriodTimeFrameType);
  const startOfPeriod = range.start().format(format || 'YYYY-MM-DD');
  const endOfPeriod = range.end().format(format || 'YYYY-MM-DD');

  return `${startOfPeriod}/${endOfPeriod}`;
};

export const getOptionValue = (
  option: string | Record<string, string | number>,
  key: string,
  world: string,
): string => {
  if (isCategoryFilter(key)) {
    if (world === World.EXPENSES) return (option as any).id;
    return String(option);
  }
  if (key === 'period') {
    // The Bank page period filter requires custom parameter data structure to be sent to Backend:
    // periods: 2022-01-01/2022-04-01,2021-10-01/2022-01-01,2021-07-01/2021-10-01
    // Where each index is a period range in the format: start/end (2022-01-01/2022-04-01)
    if (world === World.BANK) {
      return slashedPeriodFormat(option);
    }

    return getPeriodAsFilterOptionValue(option as PeriodFilterOption);
  }
  return String(option);
};

// TODO: talk with the team about a refactor, maybe we should create a function for each world
export const getOptionLabel = (
  option: string | Record<string, string | number>,
  key: string,
  world: string,
  country: AvailableCountries,
  // dicts: { expensesCategoriesDict: ExpenseCategoriesDict },
): string => {
  if (isCategoryFilter(key)) {
    if (world === World.REVENUES) return i18n.t(String(option));
    if (world === World.BANK) {
      return i18n.t(`payments.transaction_category_short.${option}`);
    }
    if (world === World.EXPENSES) {
      const category = option as any;
      const titleSlug = adaptOldCategorySlug(country, category.title, 'title');
      if (!category.displayName) return i18n.t(titleSlug);
      const displayNameSlug = adaptOldCategorySlug(
        country,
        category.displayName,
        'displayName',
      );
      if (i18n.exists(displayNameSlug)) return i18n.t(displayNameSlug);
      return i18n.t(titleSlug);
    }
    return i18n.exists(String(option))
      ? i18n.t(String(option))
      : String(option);
  }
  if (world === World.TAXES && key === 'type') {
    return i18n.t(
      `taxes.filters.types.${String(option)}${
        [TaxTypeEnum.vat, TaxTypeEnum.intracomListing].includes(
          option as TaxTypeEnum,
        )
          ? `.${country}`
          : ''
      }`,
    );
  }
  if (world === World.TAXES && key === 'status') {
    return i18n.t(`taxes.${String(option)}`);
  }
  switch (key) {
    case 'period': {
      const period = option as Record<string, string>;
      const periodChar = period.frequency?.split('')[0];
      let periodUnit = '';
      switch (periodChar) {
        case 'm':
          periodUnit = dayjs()
            .month(Number(period.value) - 1)
            .format('MMMM');
          break;
        case 'q':
          periodUnit = `${i18n.t('quarter_abbr')}${period.value}`;
          break;
      }
      return `${periodUnit} ${period.year}`;
    }
  }

  return resolveOptionTranslationBasedOnWorld(world, option, country);
};
