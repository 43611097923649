import api, { IPaginationResponse } from 'api/axiosInstance';
import { RecurringInvoice } from 'types/invoice.types';
import { divideBy1000 } from 'utils/math';
const service = 'v2/revenues/recurrings';

export const createRecurringRevenue = (data: RecurringInvoice) => {
  return api.post(`${service}`, data);
};

export const updateRecurringRevenue = (id: string, data: RecurringInvoice) => {
  return api.put(`${service}/${id}`, data);
};

export const deleteRecurringRevenue = (id: string) => {
  return api.delete(`${service}/${id}`);
};

export const pauseRecurringRevenue = (id: string) => {
  return api.post(`${service}/${id}/pause`);
};

export const resumeRecurringRevenue = (id: string) => {
  return api.post(`${service}/${id}/resume`);
};

export const getRecurringRevenue = (id: string): Promise<RecurringInvoice> => {
  return api.get(`${service}/${id}`).then(({ data }) => data);
};

export const getRecurringRevenues = (): Promise<
  { data: RecurringInvoice[] } & IPaginationResponse
> => {
  return api
    .get<any, { data: RecurringInvoice[] }>(`${service}`)
    .then(({ data }) => {
      return {
        data: data.map((doc) => {
          return {
            ...doc,
            _amount: doc.revenueData.items.reduce((acc: number, item) => {
              if (item.doesUnitPriceIncludeVAT) {
                const sum =
                  divideBy1000(item.unitAmountInclVAT || 0) *
                  divideBy1000(item.quantity);
                return (
                  acc + (sum - sum * divideBy1000(item.discountPercentage || 0))
                );
              }

              const sum =
                (divideBy1000(item.unitAmountExclVAT) +
                  divideBy1000(item.unitAmountExclVAT) *
                    divideBy1000(+item.VATRate)) *
                divideBy1000(item.quantity);
              return (
                acc + (sum - sum * divideBy1000(item.discountPercentage || 0))
              );
            }, 0),
          };
        }),
        paging: {
          page: 1,
          pagesCount: 1,
          pageCount: 1,
          perPage: data.length,
          totalCount: data.length,
        },
      };
    });
};
