import { Pagination } from 'components/atoms';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import { get } from 'lodash';
import QueryString from 'qs';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { RevenueFileType, type IRevenue } from 'types/revenues.types';
import { ITaxSubmission } from 'types/tax-submissions.types';
import {
  TaxTypeEnum,
  type CheckComponentProps,
  type ITax,
} from 'types/taxes.types';
import type { AvailableCountries } from 'types/users.types';
import { logAmplitude } from 'utils/amplitude';
import { WEBAPP_TAXREVIEW_OPENCHECKEXPENSEORREVENUE } from 'utils/amplitude/events/taxes.amplitude';
import formatMoney from 'utils/formatMoney';
import formatPeriodLabel from 'utils/formatPeriodLabel';
import getTaxName from 'utils/getTaxName';
import { divideBy1000 } from 'utils/math';
import ZebraTable from '../ZebraTable';

const CheckRevenuesList: FC<
  {
    extraCols?: { head: string; key: string }[];
    onItemClick?: (item: IRevenue) => void;
    list: IRevenue[];
    selectedExpenses?: string[];
    setSelectedExpenses?: (ids?: string[]) => void;
  } & CheckComponentProps
> = ({
  list,
  revenueFormRef,
  extraCols = [],
  onItemClick,
  checkName,
  checkData,
  setPage,
}) => {
  const [_, setSearchParams] = useSearchParams();
  const { data, paging } = checkData;
  const { t } = useTranslation();
  const userCountry = useCustomerCountry();
  return (
    <div>
      <ZebraTable
        cells={[
          { head: t('client'), key: 'clientName' },
          { head: t('invoice.field.invoice_number'), key: 'revenueNumber' },
          { head: t('invoice.field.invoice_date'), key: 'invoiceDate' },
          {
            head: t('taxes.vat_submission.table.header.amount'),
            key: 'totalAmountInclVAT',
          },
        ].concat(extraCols)}
        onClick={(_, item) => {
          logAmplitude(WEBAPP_TAXREVIEW_OPENCHECKEXPENSEORREVENUE, {
            checkName,
            expenseId: get(item, '_id'),
          });
          if (get(item, 'fileType') === RevenueFileType.generated)
            setSearchParams(
              QueryString.stringify({ 'issue-field': 'edit-invoice' }),
            );
          onItemClick?.(item as unknown as IRevenue);
          revenueFormRef?.current?.open(get(item, '_id'));
        }}
        hideChevron
        cellClass="first:!flex-grow-0"
        withEditIcon
        items={list?.map((revenue: IRevenue) => ({
          ...revenue,
          key: revenue?._id,
          taxItems: (
            <span className="text-xs">
              {revenue?.submissions
                ?.map(
                  (sub) =>
                    `${getTaxName(
                      get(sub, 'type') === TaxTypeEnum.vatSubmission
                        ? { ...(sub as ITaxSubmission), type: TaxTypeEnum.vat }
                        : (sub as unknown as ITax),
                      userCountry as AvailableCountries,
                    )} ${formatPeriodLabel(get(sub, 'period'))}`,
                )
                .join(', ')}
            </span>
          ),
          revenueNumber: revenue?.revenueNumber || (
            <span className="!font-normal opacity-50">{t('data.missing')}</span>
          ),
          clientName: (
            <div className="flex items-center gap-2">
              <span className="font-semibold text-primary">
                {revenue.client.name || (
                  <span className="opacity-50">
                    {t('invoices.unknownClient')}
                  </span>
                )}
              </span>
            </div>
          ),
          invoiceDate: revenue?.invoiceDate || (
            <span className="opacity-50">{t('an_unknown_date')}</span>
          ),
          VATRate: `${get(revenue, 'items[0].VATRate')}%`,
          totalAmountInclVAT: (
            <span className="font-semibold text-primary">
              {formatMoney(
                divideBy1000(revenue.totalAmountInclVAT),
                revenue?.currency as 'EUR',
              )}
            </span>
          ),
        }))}
      />
      {paging?.pageCount > 1 ? (
        <Pagination
          className="mt-2"
          pagesCount={paging?.pageCount || 0}
          perPage={25}
          totalCount={paging?.totalCount || 0}
          page={paging?.page || 0}
          currentPageCount={data?.length}
          onPageChange={(pageNumber) => {
            setPage(pageNumber);
          }}
        />
      ) : null}
    </div>
  );
};

export default CheckRevenuesList;
