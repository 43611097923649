import Banner from 'components/molecules/Banner/Banner.component';
import { getMonthlyAmount } from 'components/pages/Subscriptions/Subscription.helper';
import {
  AppBannersContext,
  BANNERS_STORAGE_KEYS,
} from 'contexts/AppBannersProvider';
import { useGroupSubscriptionPlans } from 'hooks/Subscriptions';
import { useContext, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { SubscriptionPlan } from 'types/subscriptions.types';
import type { AvailableCountries } from 'types/users.types';
import { SubscriptionPlanNameEnum } from 'types/users.types';
import { DiscountIcon } from 'utils/icons';

const SmallBusinessPlanBanner: FC<{ country?: AvailableCountries }> = ({
  country,
}) => {
  const { t } = useTranslation();

  const { dismissBanner } = useContext(AppBannersContext);

  const subscriptionPlans = useGroupSubscriptionPlans();

  const small12 = subscriptionPlans.data?.find(
    (plan: SubscriptionPlan) =>
      plan.nickname === SubscriptionPlanNameEnum.small12,
  );

  if (!small12 || subscriptionPlans.isLoading) return null;

  const SMALLPlanPrice = Math.round(
    getMonthlyAmount('year', small12?.amount as number),
  );

  return (
    <Banner
      iconSrc={DiscountIcon}
      title={t(`subscriptions.banner.small.title_${country}`, {
        price: SMALLPlanPrice,
      })}
      href="/subscriptions"
      linkLabel={t('subscriptions.banner.small.cta')}
      extra={
        <p className="bg-primary-700 text-white p-2 px-5 font-semibold rounded-10">
          {t(`subscriptions.banner.small.disclaimer_${country}`)}
        </p>
      }
      onDismiss={() =>
        dismissBanner(BANNERS_STORAGE_KEYS.SMALL_PLAN_BANNER_DISMISS_KEY)
      }
    />
  );
};

export default SmallBusinessPlanBanner;
