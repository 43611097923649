import classNames from 'classnames';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import FreeTextInput from 'components/atoms/Form/FreeTextInput/FreeTextInput.component';
import denormalizeSteuernummer from 'denormalize-steuernummer';
import useValidateSteuernummer from 'hooks/useValidateSteuernummer';
import { get } from 'lodash';
import { useEffect, useMemo, useState, type FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { sanitizeSteuernummer } from 'utils/helpers';

export const placeholderMap = {
  'baden-württemberg': '1234/56789',
  bayern: '123/456/7890',
  berlin: '12/345/67890',
  brandenburg: '123/456/7890',
  bremen: '12 345 67890',
  hamburg: '12/345/67890',
  hessen: '012 456 6789',
  'mecklenburg-vorpommern': '123/456/67890',
  niedersachsen: '12/345/67890',
  'nordrhein-westfalen': '123/4567/890',
  'rheinland-pfalz': '12/345/67890',
  saarland: '123/456/67890',
  sachsen: '123/456/7890',
  'sachsen-anhalt': '123/456/7890',
  'schleswig-holstein': '12/345/67890',
  thüringen: '123/456/7890',
};

export const germanStatesCodesMap = {
  'baden-württemberg': 'DE-BW',
  bayern: 'DE-BY',
  berlin: 'DE-BE',
  brandenburg: 'DE-BB',
  bremen: 'DE-HB',
  hamburg: 'DE-HH',
  hessen: 'DE-HE',
  'mecklenburg-vorpommern': 'DE-MV',
  niedersachsen: 'DE-NI',
  'nordrhein-westfalen': 'DE-NW',
  'rheinland-pfalz': 'DE-RP',
  saarland: 'DE-SL',
  sachsen: 'DE-SN',
  'sachsen-anhalt': 'DE-ST',
  'schleswig-holstein': 'DE-SH',
  thüringen: 'DE-TH',
};

const SteuernummerField: FC<any> = ({
  name,
  value,
  error: _error,
  ...props
}) => {
  const { watch } = useFormContext();
  const [focused, setFocused] = useState(false);
  const [stateValue] = watch([props.stateFieldName]);
  const { isValidatingSteuernummer } = useValidateSteuernummer({
    name: props.name,
    stateValue,
    steuernummerValue: value,
    disabled: focused || sanitizeSteuernummer(value).length < 10,
  });
  const stateCode = get(germanStatesCodesMap, stateValue?.toLowerCase());
  const placeholder = get(placeholderMap, stateValue?.toLowerCase());

  const denormalizedSteuernummer = denormalizeSteuernummer(value);
  const isHessen = stateCode === 'DE-HE';
  useEffect(() => {
    if (isHessen && !value) props.onChange('0');
  }, [value]);
  const isTaxId = sanitizeSteuernummer(value)?.length > 11;
  const isFedralScheme = sanitizeSteuernummer(value)?.length > 12;
  const isNotStandardScheme =
    sanitizeSteuernummer(value)?.length > 13 && /^[0-9/]+$/?.test(value);
  const { t } = useTranslation();
  const error = useMemo(() => {
    if (!focused) {
      if (isNotStandardScheme)
        return {
          message: t('settings.steuernummer.validation.not_standard'),
          type: 'error',
        };
      if (isFedralScheme)
        return {
          message: t(
            'settings.steuernummer.validation.not_standard_remove_extra',
          ),
          type: 'error',
        };
      if (isTaxId)
        return {
          message: t('settings.steuernummer.validation.looks_like_tax_id'),
          type: 'error',
        };
      if (isHessen)
        return {
          message: t('settings.steuernummer.validation.hessen'),
          type: 'info',
        };
    }
  }, [value, focused]);
  return (
    <>
      <FreeTextInput
        // {...props}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        disabled={!stateValue}
        max={13}
        value={value}
        onChange={(e) => props.onChange(e.target.value)}
        loading={isValidatingSteuernummer}
      />

      {!error && _error?.trim() && <Alert type="warning" title={t(_error)} />}
      {error && (
        <Alert
          className="mt-2 py-1 px-2 !text-xs"
          type={error?.type as any}
          description={
            <ReactMarkdown
              components={{
                a: (props) => (
                  <Button
                    onClick={() =>
                      Intercom(
                        'showNewMessage',
                        t('settings.steuernummer.validation.prompt_message'),
                      )
                    }
                    structure="text"
                    className={classNames({
                      '!text-yellow-700 hover:text-yellow-800':
                        error?.type === 'warning',
                      '!text-red-800 hover:text-red-900':
                        error?.type === 'error',
                    })}
                  >
                    {props.children}
                  </Button>
                ),
              }}
            >
              {error?.message}
            </ReactMarkdown>
          }
        />
      )}
    </>
  );
};

export default SteuernummerField;
