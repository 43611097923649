import classNames from 'classnames';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { FreeLoader } from 'components/atoms/Loader/Loader.component';
import { Helper } from 'components/molecules/Helper';
import { includesPattern } from 'components/organisms/CategoriesPicker/utils';
import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useGetCountries from 'hooks/shared/useGetCountries';
import useFeatures from 'hooks/useFeatures';
import { pick } from 'lodash';
import type { FC, ReactNode } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import type { FieldValues, UseFieldArrayReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { IExpense, IExpenseItem } from 'types/expenses.types';
import { ReviewStatusEnum } from 'types/expenses.types';
import type { TransactionAsOption } from 'types/transactions.types';
import type { IUser, IUserSnapShot } from 'types/users.types';
import { AccountTypeEnum, AvailableCountries } from 'types/users.types';
import type { IVehicle } from 'types/vehicles.types';
import { applySuggestedChanges } from 'utils/applySuggestedChanges';
import * as cates from 'utils/categories';
import { formResetOptions, isWeb } from 'utils/constants';
import { cleanEmptyValues, getShortAccountType } from 'utils/helpers';
import { TrashIconOutline } from 'utils/icons';
import type useFormMeta from './useFormMeta';

export const isBusinessCar = (categoryId: string | undefined) => {
  if (!categoryId) return false;
  return cates.businessCarDE.includes(categoryId);
};

export const isCarCategory = (categoryId: string | undefined) => {
  if (!categoryId) return false;
  return cates.catesThatRequiresVehicleBE.includes(categoryId);
};

export const isUtilityVehicle = (categoryId: string | undefined) => {
  if (!categoryId) return false;
  return cates.utilityVehiclesBE.includes(categoryId);
};

export const isVehicleFineCategory = (categoryId: string | undefined) => {
  if (!categoryId) return false;
  return cates.vehiclesFinesBE.includes(categoryId);
};

export const utilityVehicleWarning = ((key) => ({
  isSeen: () => !!localStorage.getItem(key),
  markAsSeen: () => localStorage.setItem(key, 'true'),
}))('isUtilityVehicleWarningSeen');

// export const getTotalVATAmount = (items: IExpense['items']): number | null =>
//   items.reduce((acc: number | null, item) => {
//     if (acc == null) return item.VATAmount ?? null;
//     return acc + (item.VATAmount || 0);
//   }, null);

type FieldsArrayProps = {
  name: string;
  render: (api: { field: Record<'id', string>; index: number }) => ReactNode;
  className?: string;
  // canRemoveLast?: boolean;
  controller: UseFieldArrayReturn<FieldValues, any, 'id'>;
  isItemsRemovable?: boolean;
  baseindex?: number;
  showItemsNumber?: boolean;
};
export const FieldsArray: FC<FieldsArrayProps> = ({
  // name,
  render,
  className,
  // canRemoveLast,
  controller,
  isItemsRemovable = true,
  baseindex = 0,
  showItemsNumber = true,
}) => {
  const { t } = useTranslation();
  const { reset, getValues } = useFormContext();
  const { fields, remove } = controller;

  if (!fields.length) return null;

  // if (!canRemoveLast && fields.length === 1)
  //   return (
  //     <div key={fields[0].id} className={classNames(className)}>
  //       {render({ field: fields[0], index: 0 })}
  //     </div>
  //   );

  return (
    <div className={classNames('flex flex-col gap-8', className)}>
      {fields.map((field, index) => (
        <div key={field.id}>
          {(isItemsRemovable || showItemsNumber) && (
            <div className="flex items-center justify-between mb-4">
              {showItemsNumber && (
                <p className="font-bold text-main-900 text-xl tracking-tight font-avenir">
                  {t('item', { number: baseindex + index + 1 })}
                </p>
              )}
              {isItemsRemovable && (
                <Button
                  onClick={() => {
                    remove(index);
                    reset(getValues(), formResetOptions);
                  }}
                  structure="text"
                  color="primary"
                >
                  <TrashIconOutline className="w-5 text-primary-400" />
                </Button>
              )}
            </div>
          )}
          {render({ field, index })}
        </div>
      ))}
    </div>
  );
};

type UseVATRatesOptions = {
  expenseDate: string | undefined;
};

// const customerCountry = useCustomerCountry();
export const useVATRatesGetter = () => {
  const emptyRates = useRef([]);
  const VATRates = useFeatures('expenses.vat_rates') || emptyRates.current;
  const VATRatesDeductible2020 =
    useFeatures('expenses.vat_rates_deductible_2020') || emptyRates.current;

  return useCallback(
    (options: UseVATRatesOptions): number[] => {
      if (
        options.expenseDate &&
        new Date(options.expenseDate).getFullYear() === 2020
      ) {
        return VATRates.concat(VATRatesDeductible2020).sort();
      }
      return VATRates;
    },
    [VATRates, VATRatesDeductible2020],
  );
};

export const getUpdatedIncomeTaxDeductibility = (
  expenseDate: string | undefined,
  item: IExpenseItem,
  shouldUseVehicleData: boolean,
) => {
  if (shouldUseVehicleData) {
    let incomeTaxDeductibility = item.vehicle?.incomeTaxDeductibility;

    if (expenseDate && item.vehicle?.incomeTaxDeductibilityOverrides) {
      const expenseDateNumber = +expenseDate.replace(/-/g, '');
      item.vehicle.incomeTaxDeductibilityOverrides.forEach(
        ([date, deductibility]) => {
          if (expenseDateNumber >= +date)
            incomeTaxDeductibility = deductibility;
        },
      );
    }

    return incomeTaxDeductibility;
  }
  return item.category?.incomeTaxDeductibility ?? item.incomeTaxDeductibility;
};

export const getUpdatedMaxDeductibleVAT = (
  item: IExpenseItem,
  shouldUseVehicleData: boolean,
) => {
  if (shouldUseVehicleData) return item.vehicle?.VATDeductibility;
  return item.category?.maxDeductibleVAT ?? item.maxDeductibleVAT;
};

export const getVehicleIncomeTaxDeductibilityPerYear = (
  vehicle: IVehicle,
  year: number,
) => {
  const dateNumber = year * 1_00_00 + 1_01;
  let { incomeTaxDeductibility } = vehicle;

  if (!vehicle.incomeTaxDeductibilityOverrides) return incomeTaxDeductibility;
  for (const [date, deductibility] of vehicle.incomeTaxDeductibilityOverrides) {
    if (dateNumber >= date) {
      incomeTaxDeductibility = deductibility;
    }
  }
  return incomeTaxDeductibility;
};
//

export const expenseAlternativeTransactionsOptions = [
  {
    label: 'transaction.match_remainder_with_cash',
    value: 'cashRemainder',
    preset: true,
  },
  {
    label: 'transaction.match_with_next_credit_card_statement',
    value: 'matchCard',
    preset: true,
  },
];

export const initValuesKeys = [
  '_id',
  'filePath',
  'files',
  'expenseDate',
  'items',
  'supplier',
  'notes',
  'transactions',
  'isCreditNote',
  'cashRemainder',
  'matchCard',
  'isInvoice',
  'VATStatus',
  'period',
  'taxPeriod',
  'accountantReview',
  'sufficientlyDocumented',
  'isValidated',
  'guessedIsInvoice', // we need to read this easly inside the meta hook
  'guessedIsVATPresent',
  'isFake',
  'guessedMerchantName',
  'guessedMerchantCountry',
  'guessedCategoryId',
  'guessedCategory',
  'guessedVehicle',
  'guessedProfessionalPart',
  'guessedFrom',
];

export const itemDefault = {
  professionalPart: 1,
  qty: 1,
};

const getDefaultItems = (items: any) => {
  if (Array.isArray(items)) {
    const result = items.filter(Boolean);
    if (result.length) return result;
    return [itemDefault];
  }
  return [itemDefault];
};

const handleFiles = (filePath: any) => {
  if (typeof filePath !== 'string') {
    return {
      filePath: null,
      files: [],
    };
  }
  return {
    filePath,
    files: [{ path: filePath }],
  };
};

const removeSign = (n: any) => {
  if (typeof n === 'number') return Math.abs(n);
  return n;
};

export const getDefaultValues = (values: any, user: IUser) => {
  const isCreditNote = !!values?.isCreditNote || values?.amount < 0;

  return {
    ...pick(values, initValuesKeys),
    ...handleFiles(values?.filePath),
    cashRemainder: values?.cashRemainder || false,
    matchCard: values?.matchCard || false,
    accountantReview: isWeb
      ? values.accountantReview
      : {
          ...values.accountantReview,
          reviewStatus:
            values.accountantReview?.reviewStatus ||
            ReviewStatusEnum.notReviewd,
        },
    isInvoice: values.isFake && !values.isValidated ? true : !!values.isInvoice,
    isCreditNote,
    items: getDefaultItems(values?.items).map((item) => {
      const _item = {
        ...item,
        amount: removeSign(item.amount),
        VATAmount: !item.amount ? undefined : removeSign(item.VATAmount),
        categoryId:
          item.categoryId && !item.category ? undefined : item.categoryId,
        amortizationPeriod:
          item.amortizationPeriod === null ? 0 : item.amortizationPeriod,
      };
      return _item;
    }),
    user: {
      VATReturnFrequency: user.VATReturnFrequency,
      VATType: values.userSnapshot?.VATType || user.VATType,
    } as IUserSnapShot,
    _matchedTransactions: values._matchedTransactions,
    isSuggestedPeriod: values.isSuggestedPeriod,
  };
};

export const adaptExpenseWithMeta = (
  expense: Partial<IExpense>,
  metaAPI: ReturnType<typeof useFormMeta>,
): Partial<IExpense> => {
  const formMeta = metaAPI.getFormMeta(expense);
  return applySuggestedChanges(
    {
      ...expense,
      isThereVATOnInvoice:
        expense.isValidated ||
        !formMeta.fields.isThereVATOnInvoice.isApplicable ||
        expense.guessedIsVATPresent == null
          ? undefined
          : expense.guessedIsVATPresent,
    },
    metaAPI.getSuggestedChanges(expense),
  );
};

export const renderLoader = (className = '') => (
  <FreeLoader className={`text-primary-200 fill-primary-400 ${className}`} />
);

export const supplierNameToSearchValue = (name: string | undefined) => {
  if (!name) return;
  // try to get "first" word with 4 or more char otherwise get the second longest one.
  const result = name.split(' ').reduce((acc, str) => {
    if (acc.length >= 4) return acc;
    if (str.length > acc.length) return str;
    return acc;
  }, '');
  if (result) return result;
};

const emptyCountries: [] = [];

// conditions from old code
export const SupplierInformative: FC<any> = (props) => {
  const {
    expenseDate,
    guessedVATNumber,
    customerCountry,
    isSupplierEU,
    isSupplierLocal,
    isSupplierOther,
    VATRate,
  } = props;

  const { t } = useTranslation();

  const { data: countries = emptyCountries } = useGetCountries();

  const countryCodesEEA = useMemo(() => {
    return countries.filter((c) => c.EEA).map((c) => c.alpha2Code);
  }, [countries]);

  const VATRates = useVATRatesGetter()({ expenseDate });

  const meta = (() => {
    const data = {
      '1': false,
      '2': false,
      '3': false,
      '4': false,
      '5': false,
    };

    if (guessedVATNumber) {
      const VATNumber = guessedVATNumber;

      const hasLocalVATNumber = VATNumber.includes(
        customerCountry.toUpperCase(),
      );

      const hasEuGlobalVATNumberThatDoesntMatchCustomerCountry =
        isSupplierEU &&
        VATNumber.startsWith('EU') &&
        !VATNumber.includes(customerCountry.toUpperCase());

      const hasEuCountryVATNumberThatDoesntMatchCustomerCountry =
        countryCodesEEA.some(
          (cc) =>
            VATNumber.includes(cc) &&
            cc !== customerCountry.toLocaleUpperCase(),
        );

      if (isSupplierLocal) {
        if (
          hasEuGlobalVATNumberThatDoesntMatchCustomerCountry &&
          VATRate !== 0 &&
          VATRates.includes(VATRate)
        ) {
          return { ...data, '1': true };
        }
        if (hasEuCountryVATNumberThatDoesntMatchCustomerCountry)
          return { ...data, '1': true };
      }

      if (isSupplierEU) {
        if (hasLocalVATNumber) return { ...data, '2': true };
        if (
          hasEuGlobalVATNumberThatDoesntMatchCustomerCountry &&
          VATRates.includes(VATRate)
        ) {
          return { ...data, '3': true };
        }
        if (
          !hasEuGlobalVATNumberThatDoesntMatchCustomerCountry &&
          !hasEuCountryVATNumberThatDoesntMatchCustomerCountry
        ) {
          return { ...data, '4': true };
        }
      }

      if (isSupplierOther) {
        if (hasLocalVATNumber) return { ...data, '2': true };
        if (
          hasEuGlobalVATNumberThatDoesntMatchCustomerCountry &&
          VATRates.includes(VATRate)
        ) {
          return { ...data, '3': true };
        }
        if (hasEuCountryVATNumberThatDoesntMatchCustomerCountry)
          return { ...data, '5': true };
      }
    }

    return data;
  })();

  const messageNumber = Object.entries(meta).find(
    ([number, shouldShow]) => shouldShow,
  )?.[0];

  if (!messageNumber) return null;

  return (
    <Alert
      type="warning"
      showIntercomBtn
      name="wrong supplier country"
      title={t('expense.countryPicker.warning.title')}
      description={t(`expense.countryPicker.warning.message${messageNumber}`, {
        nationality: t(`nationality.${customerCountry}`),
        country: t(`country.${customerCountry}`),
      })}
    />
  );
};

type GetDefaultVATRateOptions = {
  isSupplierLocal: boolean;
  isCustomerBE: boolean;
  expenseDate: string | undefined | null;
  categorySuggestedVATRate: number | undefined | null;
};
export const getDefaultVATRate = (options: GetDefaultVATRateOptions) => {
  if (options.categorySuggestedVATRate === null) return null;
  if (options.isSupplierLocal) {
    // 21% for belgian users
    if (options.isCustomerBE) return options.categorySuggestedVATRate ?? 0.21;
    // 19% for German users (16% for users if expenses is in 4th quarter)
    const date = options.expenseDate ? dayjs(options.expenseDate) : null;
    if (date?.year() === 2020 && date?.quarter() === 4) {
      if (options.categorySuggestedVATRate === 0) return 0;
      if (options.categorySuggestedVATRate === 0.07) return 0.05;
      return 0.16;
    }
    return options.categorySuggestedVATRate ?? 0.19;
  }
  // 0% for intra-extra eu
  return 0;
};

export const currentYear = new Date().getFullYear();

export const vehiclePurchaseDateMin = dayjs(new Date('1900-01-01'))
  .startOf('day')
  .toDate();
export const vehiclePurchaseDateMinAsNumber = 19000101;
export const vehiclePurchaseDateMax = new Date();
export const vehiclePurchaseDateMaxAsNumber = +dayjs().format('YYYYMMDD');

const ambiguousSuppliers = [
  'Spotify',
  'Netflix',
  'Delhaize',
  'Luminus',
  'Engie',
  'Carrefour',
  'Lidl',
  'Aldi',
  'Aldi Süd',
  'Aldi Nord',
  'Netto Marken-Discount',
  'Spar',
  'Edeka',
  'Colruyt',
  'Rewe',
  'Penny Markt',
].map((s) => s.toLowerCase());

export const isAmbiguousSupplier = (name: string | undefined) => {
  if (!name) return false;
  return ambiguousSuppliers.includes(name.toLowerCase());
};

const scamySuppliers = ['SDI', 'SDZ', 'Unizo'].map((s) => s.toLowerCase());

export const isScamySupplier = (name: string | undefined) => {
  if (!name) return false;
  return scamySuppliers.includes(name.toLowerCase());
};

// TODO: move to categories file
const tenDaysRuleCategories = [
  'de.legal_and_other_fees.accounting_fees',
  'de.legal_and_other_fees.other_fees',
  'de.legal_and_other_fees.audit_and_closing_fees',
  'de.vehicle.leasing',
  'de.vehicle.insurance',
  'de.vehicle.taxes',
  'de.vehicle.road_and_registration_taxes',
  'de.technology.hosting',
  'de.technology.maintenance',
  'de.phone_and_internet.phone',
  'de.phone_and_internet.internet',
  'de.taxes_and_insurance.vat_payment',
  'de.taxes_and_insurance.for_self_employed.private_insurances',
  'de.taxes_and_insurance.for_self_employed.healthinsurance',
  'de.taxes_and_insurance.for_self_employed.pension_plan',
  'de.taxes_and_insurance.for_self_employed.trade_tax',
  'de.taxes_and_insurance.for_self_employed.import_fees',
  'de.taxes_and_insurance.for_self_employed.fines_for_late_payment',
  'de.taxes_and_insurance.for_self_employed.nd_fines_for_late_payment',
  'de.taxes_and_insurance.for_self_employed.income_tax',
  'de.taxes_and_insurance.for_self_employed.solidariy_charge',
  'de.taxes_and_insurance.for_self_employed.churchtax',
  'de.taxes_and_insurance.for_self_employed.property_tax.outside',
  'de.taxes_and_insurance.for_self_employed.property_tax.inside_main',
  'de.taxes_and_insurance.for_self_employed.property_tax.inside_secondary',
  'de.subcontracting.displayName', // remove later after backend deploys german categories changes
  'de.subcontracting',
  'de.workplace.rent.outside',
  'de.workplace.maintenance.outside',
  'de.workplace.maintenance.inside_main',
  'de.workplace.maintenance.inside_secondary',
  'de.workplace.rent.homelumpsum',
];

export const doesTenDayRuleApplies = (
  expenseDate: string | undefined,
  categoryIds: string[] | undefined,
) => {
  if (!expenseDate || !categoryIds) return false;
  const date = dayjs(expenseDate);
  if (!date.isValid()) return false;
  const dayOfTheMonth = date.get('D');
  return (
    date.month() === 0 &&
    dayOfTheMonth >= 1 &&
    dayOfTheMonth <= 10 &&
    categoryIds.some((id) => id && tenDaysRuleCategories.includes(id))
  );
};

export const getLockStatusBody = (expense: Partial<IExpense>) => {
  return {
    expenseDate: expense.expenseDate
      ? dayjs(expense.expenseDate).format('YYYY-MM-DD')
      : undefined,
    user: {
      VATType: expense.user?.VATType || undefined,
      VATReturnFrequency: expense.user?.VATReturnFrequency || undefined,
    },
    period: expense.period || undefined,
  };
};
export const TaxSearchWarning: FC<{ searchTerm?: string }> = ({
  searchTerm,
}) => {
  const user = useGetCurrentCustomerData().data as IUser;
  const { t } = useTranslation();

  if (!searchTerm?.trim()) return null;

  if (user.country === AvailableCountries.belgium) {
    const taxesOrVATMatch = includesPattern({
      searchValue: searchTerm,
      keywords: [t('vat'), t('tax')],
    });

    if (!taxesOrVATMatch) return null;

    const shortAccountType = getShortAccountType(user.accountType);
    if (shortAccountType === AccountTypeEnum.natural_person) {
      return (
        <Alert
          type="warning"
          className="mb-0"
          name="be natural person search for tax category in expense form"
          description={
            <div>
              {t(
                'expenses.warning_bubble.search_for_tax_category.natural.title.be',
              )}
              {'  '}
              <Helper className="!inline">
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_for_tax_category.natural.text.be',
                  )}
                </Helper.Paragraph>
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_for_tax_category.natural.text2.be',
                  )}
                </Helper.Paragraph>
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_for_tax_category.natural.text3.be',
                  )}
                </Helper.Paragraph>
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_for_tax_category.natural.text4.be',
                  )}
                </Helper.Paragraph>
              </Helper>
            </div>
          }
        />
      );
    }
    if (shortAccountType === AccountTypeEnum.one_person_company) {
      return (
        <Alert
          type="warning"
          className="mb-0"
          name="be one person company search for tax category in expense form"
          description={
            <div>
              {t(
                'expenses.warning_bubble.search_for_tax_category.company.title.be',
              )}
              {'  '}
              <Helper className="!inline">
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_for_tax_category.company.text.be',
                  )}
                </Helper.Paragraph>
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_for_tax_category.company.text2.be',
                  )}
                </Helper.Paragraph>
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_for_tax_category.company.text3.be',
                  )}
                </Helper.Paragraph>
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_for_tax_category.company.text4.be',
                  )}
                </Helper.Paragraph>
              </Helper>
            </div>
          }
        />
      );
    }
  }

  if (user.country === AvailableCountries.germany) {
    const taxKeywordsMatch = includesPattern({
      searchValue: searchTerm,
      keywords: [t('tax')],
    });
    if (taxKeywordsMatch) {
      return (
        <Alert
          type="warning"
          className="mb-0"
          name="de search for tax category in expense form"
          description={
            <div>
              {t('expenses.warning_bubble.search_for_tax_category.title.de')}
              {'  '}
              <Helper className="!inline">
                <Helper.Paragraph>
                  {t('expenses.warning_bubble.search_for_tax_category.text.de')}
                </Helper.Paragraph>
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_for_tax_category.text2.de',
                  )}
                </Helper.Paragraph>
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_for_tax_category.text3.de',
                  )}
                </Helper.Paragraph>
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_for_tax_category.text4.de',
                  )}
                </Helper.Paragraph>
              </Helper>
            </div>
          }
        />
      );
    }
    const healthOrPensionMatch = includesPattern({
      searchValue: searchTerm,
      keywords: [
        t('keywords.keyword_pension'),
        t('keywords.keyword_pension_plan'),
        t('keywords.keyword_health'),
      ],
    });
    if (healthOrPensionMatch) {
      return (
        <Alert
          type="warning"
          className="mb-0"
          name="de search for tax category in expense form"
          description={
            <div>
              {t('expenses.warning_bubble.search_personal_categories.title.de')}
              {'  '}
              <Helper className="!inline">
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_personal_categories.text1.de',
                  )}
                </Helper.Paragraph>
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_personal_categories.text2.de',
                  )}
                </Helper.Paragraph>
                <Helper.Paragraph>
                  {t(
                    'expenses.warning_bubble.search_personal_categories.text3.de',
                  )}
                </Helper.Paragraph>
              </Helper>
            </div>
          }
        />
      );
    }
  }

  return null;
};

const formatExpenseForSubmit = (expense: Partial<IExpense>) => {
  return {
    ...expense,
    items: expense.items?.map((item) => ({
      ...item,
      amortizationPeriod: item.amortizationPeriod || null, // if 0 or undefined we need to make it null
      name: item.name || undefined,
    })),
  };
};

export const cleanExpenseDataForSubmit = (expense: Partial<IExpense>) => {
  return cleanEmptyValues(formatExpenseForSubmit(expense), [
    'amortizationPeriod',
    'notes',
  ]);
};

export const cleanExpenseDataForTaxImpact = (expense: Partial<IExpense>) => {
  return cleanEmptyValues(formatExpenseForSubmit(expense));
};

export const getExpenseLinkedPaymentsOppositeDirectionTotal = (
  payments?: any[],
  isCreditNote?: boolean,
): number =>
  payments
    ?.filter((option: TransactionAsOption) => {
      if (isCreditNote) return option.transaction.baseCurrencyAmount < 0;

      return option.transaction.baseCurrencyAmount > 0;
    })
    .reduce(
      (agg: number, current: TransactionAsOption) =>
        agg + Math.abs(current.transaction.baseCurrencyAmount || 0),
      0,
    );

export const getExpenseLinkedPaymentsAmount = (
  payments?: any[],
  isCreditNote?: boolean,
): number =>
  payments
    ?.filter((option: TransactionAsOption) => {
      if (isCreditNote) return option.transaction.baseCurrencyAmount > 0;

      return option.transaction.baseCurrencyAmount < 0;
    })
    .reduce(
      (agg: number, current: TransactionAsOption) =>
        agg + Math.abs(current.transaction.baseCurrencyAmount || 0),
      0,
    );
