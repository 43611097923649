import {
  getGratificationById,
  triggerGratificationByNumber,
  updateGratification,
} from 'api/v1/gratifications';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import { FieldConnector } from 'components/atoms/Form/FieldConnector';
import FreeTextInput from 'components/atoms/Form/FreeTextInput';
import Link from 'components/atoms/Link';
import Loader from 'components/atoms/Loader/Loader.component';
import HelperComponent from 'components/molecules/Helper/Helper.component';
import { maybeAskForTrustpilotReview } from 'components/organisms/TrustpilotReview';
import dayjs from 'dayjs';
import { get } from 'lodash';
import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { Components } from 'react-markdown';
import ReactMarkdown from 'react-markdown';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import type { IUser } from 'types/users.types';
import { getTracingEvents } from 'utils/amplitude';
import * as GratifsEvents from 'utils/amplitude/events/gratifications';
import { formatMetadata } from 'utils/gratifications/getMetaData';
import { gratificationsData } from 'utils/gratifications/gratificationsData';
import type {
  Gratification,
  HelperContent,
  Metadata,
} from 'utils/gratifications/types';
import { GratificationTypes } from 'utils/gratifications/types';
import { resolveImgsContext } from 'utils/helpers';
import {
  QuestionMarkCircleIcon,
  ThumbDownIcon,
  ThumbUpIcon,
} from 'utils/icons';
import showToast from 'utils/toast';
import useGetCurrentCustomerData from './Authentication/useGetCurrentCustomerData';
import getIntercom from './intercom.hook';
import useWorldTheme, { worldThemeContext } from './shared/useWorldTheme';
import useCustomNavigate from './useCustomeNavigate';
import useModal from './useModal';
import usePusher from './usePusher';

const imgs = resolveImgsContext(
  require.context('../assets/images/gratification'),
);

const tracingEvents = getTracingEvents({
  onClick: {
    name: GratifsEvents.WEBAPP_GRATIFICATIONS_CLICKED,
    data: (gratifId: number) => ({ gratifId }),
  },
  onView: {
    name: GratifsEvents.WEBAPP_GRATIFICATIONS_VIEWED,
    data: (gratifId: number) => ({ gratifId }),
  },
  onUseful: {
    name: GratifsEvents.WEBAPP_GRATIFICATIONS_USEFUL,
    data: (gratifId: number) => ({ gratifId }),
  },
  onNotUseful: {
    name: GratifsEvents.WEBAPP_GRATIFICATIONS_NOTUSEFUL,
    data: (gratifId: number) => ({ gratifId }),
  },
});

const withT = (Comp: FC<any>) => {
  const _wrapper: FC<any> = (props) => {
    const { t } = useTranslation();
    const _t: typeof t = useCallback(
      (slug) => t(slug, props.metaData),
      [t, props.metaData],
    );
    return <Comp t={_t} {...props} />;
  };
  return _wrapper;
};

const shouldShowVote = (gratif: Gratification) => {
  const hasTheRightType = [
    GratificationTypes.taxTip,
    GratificationTypes.taxImpact,
    GratificationTypes.pnlTip,
    GratificationTypes.comparison,
  ].includes(gratif.type as GratificationTypes);

  return hasTheRightType;
};

const markdownComponents: Components = {
  ul: (props) => {
    return (
      <ul className="list-disc list-inside mb-[1rem]">{props.children}</ul>
    );
  },
  ol: (props) => {
    return (
      <ol className="list-decimal list-inside mb-[1rem]">{props.children}</ol>
    );
  },
  li: (props) => {
    return <li className="my-1 gratifs-nested-ul-ol">{props.children}</li>;
  },
  p: (props) => {
    return <div className="whitespace-pre-line">{props.children}</div>;
  },
  a: ({ children, href }) => (
    <Link
      href={href}
      rel="noopener noreferrer"
      className="underline italic font-semibold hover:opacity-80"
    >
      {children}
    </Link>
  ),
};

const components = {
  noop: () => null,
  title: withT(({ t, text }: any) => (
    <h2 className="text-primary-700 tracking-tight leading-tight font-bold text-lg mb-2 mt-6">
      {t(text)}
    </h2>
  )),
  text: withT(({ t, text }: any) => (
    <div className="text-primary-700 leading-snug">
      <ReactMarkdown components={markdownComponents}>{t(text)}</ReactMarkdown>
    </div>
  )),
  link: withT(({ t, text, href }: any) => {
    if (!href) return null;
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="underline text-blue-400"
      >
        {t(text)}
      </a>
    );
  }),
  container: withT(({ innerContent, renderContent }) => {
    return (
      <div className="p-4 bg-primary-50 rounded-md mt-4">
        {useMemo(() => renderContent(innerContent), [innerContent])}
      </div>
    );
  }),
  subTitle: withT(({ t, text }) => {
    return (
      <p className="text-primary-700 leading-snug text-base font-semibold mb-3">
        {t(text)}
      </p>
    );
  }),
  table: withT(({ innerContent, renderContent }) => {
    return (
      <div>{useMemo(() => renderContent(innerContent), [innerContent])}</div>
    );
  }),
  tableRowTitle: withT(({ t, text }) => {
    return <p className="text-sm text-primary-700">{t(text)}</p>;
  }),
  tableRow: withT(({ t, text, value }) => {
    return (
      <div className="flex items-center justify-between">
        <span className="text-sm py-1">{t(text)}</span>
        <span className="text-sm py-1">{t(value)}</span>
      </div>
    );
  }),
  divider: () => <div className="border-b py-1" />,
  button: withT(({ t, text, to, onClick, api }) => {
    const navigate = useCustomNavigate();
    return (
      <Button
        onClick={(e) => {
          if (to) {
            api.closeGratification();
            return navigate(to);
          }
          onClick?.(e);
        }}
        structure="primary"
        size="md"
        className="mt-4"
      >
        {t(text)}
      </Button>
    );
  }),
  // tableLink = 'tableLink',
  // floatingButton = 'redirectionButton',
};

function renderContent(
  contents: HelperContent[] | undefined,
  props: {
    user: IUser;
    metaData: Record<string, any>;
    api: { closeGratification: () => void };
  },
) {
  return contents?.map((content, index) => {
    const shouldShow = content.shouldShow ? content.shouldShow(props) : true;
    if (!shouldShow) return null;
    const Component = get(components, content.type, components.noop);
    return (
      <Component
        key={index}
        {...content}
        {...props}
        renderContent={(contents: HelperContent[] | undefined) =>
          renderContent(contents, props)
        }
      />
    );
  });
}

export const RenderGartification: FC<{
  id: string;
  gratification: Gratification;
  metaData: any;
  closeGratification: () => void;
  forceHideVote?: boolean;
  prependContent?: ReactNode;
  extraContent?: ReactNode;
}> = (props) => {
  const { data } = useQuery(props.id, () => getGratificationById(props.id));

  const qc = useQueryClient();

  const gratifUpdate = useMutation(
    (isUseful: boolean) =>
      updateGratification(props.id, {
        useful: isUseful,
      }),
    {
      onSuccess: (data) => {
        qc.setQueryData(props.id, (_data: any) => ({
          ..._data,
          useful: data.useful as boolean,
        }));
      },
    },
  );

  const { t } = useTranslation();
  const user = useGetCurrentCustomerData().data as IUser;
  const { showIntercom } = getIntercom();

  const btnClassName =
    'font-semibold text-sm flex items-center px-3 py-1 rounded-lg gap-2 border border-transparent';

  const isUseful = data?.useful === true;
  const isNotUseful = data?.useful === false;

  return (
    <div className="p-4 relative">
      {props.prependContent}
      <HelperComponent.Title className="leading-tight tracking-tight mb-6">
        {t(props.gratification.text, props.metaData)}
      </HelperComponent.Title>
      {renderContent(props.gratification.helperContent, {
        metaData: props.metaData || {},
        user,
        api: {
          closeGratification: props.closeGratification,
        },
      })}

      {props.extraContent}

      {!!data && shouldShowVote(data) && !props.forceHideVote && (
        <div className="mt-6 flex gap-10">
          <Button
            asDefault
            onClick={() => {
              gratifUpdate
                .mutateAsync(true)
                .then(() => maybeAskForTrustpilotReview());
              tracingEvents.onUseful(props.gratification.gratificationNumber);
            }}
            disabled={gratifUpdate.isLoading || isUseful}
            className={classNames(btnClassName, {
              'border-primary-100': isUseful,
            })}
            structure="text"
            color="primary"
          >
            {gratifUpdate.isLoading && gratifUpdate.variables === true ? (
              <Loader size="lg" className="mx-1.5" />
            ) : (
              <ThumbUpIcon className="w-7 text-taxes" />
            )}
            {t('useful')}
          </Button>
          <Button
            asDefault
            onClick={() => {
              gratifUpdate.mutate(false);
              tracingEvents.onNotUseful(
                props.gratification.gratificationNumber,
              );
            }}
            disabled={gratifUpdate.isLoading || isNotUseful}
            className={classNames(btnClassName, {
              'border-primary-100': isNotUseful,
            })}
            structure="text"
            color="primary"
          >
            {gratifUpdate.isLoading && gratifUpdate.variables === false ? (
              <Loader size="lg" className="mx-1.5" />
            ) : (
              <ThumbDownIcon className="w-7 text-taxes transform -scale-x-100" />
            )}
            <div>{t('not_useful')}</div>
          </Button>
        </div>
      )}
      <div className="mt-6">
        <HelperComponent.Contact onTalkToExpert={showIntercom} />
      </div>
    </div>
  );
};

const useGratificationsHook = () => {
  const { t } = useTranslation();
  const color = useWorldTheme();
  const markAsSeen = (id: string) =>
    updateGratification(id, { seen: dayjs().toISOString() });

  const markAsOpened = (id: string) =>
    updateGratification(id, { opened: true });

  const [modalContent, modalApi] = useModal({ width: '2xl', name: '' });

  const channel = usePusher();

  const onGratifClick = useCallback(
    (
        id: string,
        gratifNumber: number,
        gratif: Gratification,
        metaData: Metadata,
      ) =>
      (e: any) => {
        markAsOpened(id);
        tracingEvents.onClick(gratifNumber);
        modalApi.open(
          () => (
            <worldThemeContext.Provider value={color}>
              <RenderGartification
                key={id}
                id={id}
                gratification={gratif}
                metaData={metaData}
                closeGratification={modalApi.close}
              />
            </worldThemeContext.Provider>
          ),
          {
            name: 'gratification details',
          },
        );
      },
    [color, modalApi],
  );

  useEffect(() => {
    if (!channel) return;

    const handler = (data: { from: string; payload: Gratification }) => {
      if (!data || data.from !== 'accountable-web') return;

      const gratifNumber = data.payload?.gratificationNumber;
      const gratif = gratificationsData[gratifNumber];
      if (!gratif) return;

      const metaData = formatMetadata(data.payload.metadata);

      const icon = imgs[gratif.icon] || imgs.fallback;

      const id = data.payload._id as string;

      markAsSeen(id);
      tracingEvents.onView(gratifNumber);
      showToast(
        {
          title: t(gratif.text, metaData),
          icon: icon ? (
            <img
              alt=""
              src={icon}
              className="h-10 brightness-0 object-contain w-10"
            />
          ) : null,
          actionButtons: gratif.helperContent
            ? [
                {
                  title: (
                    <QuestionMarkCircleIcon
                      className={classNames(
                        'w-5 text-primary-300 hover:text-primary-400 focus:text-primary-400',
                      )}
                    />
                  ),
                  onClick: onGratifClick(id, gratifNumber, gratif, metaData),
                  asDefault: true,
                },
              ]
            : undefined,
        },
        {
          onClick: gratif.helperContent
            ? onGratifClick(id, gratifNumber, gratif, metaData)
            : undefined,
          position: toast.POSITION.BOTTOM_LEFT,
          delay: 2000,
        },
      );
    };

    const _c = channel.bind('gratification.showMessage', handler);

    return () => {
      _c.unbind('gratification.showMessage', handler);
    };
  }, [channel, color]);

  return modalContent;
};

const UseGratifications = () => {
  const content = useGratificationsHook();
  return <>{content}</>;
};

export const TestGratif: FC<{ className?: string }> = ({ className }) => {
  const form = useForm({ defaultValues: { n: '' } });

  return (
    <FormProvider {...form}>
      <form
        className={classNames('flex gap-4', className)}
        onSubmit={form.handleSubmit(({ n }) => {
          return triggerGratificationByNumber(+n);
        })}
      >
        <FieldConnector name="n" className="flex-1">
          {({ field }) => (
            <FreeTextInput
              {...field}
              type="number"
              required
              placeholder="Gratification number"
            />
          )}
        </FieldConnector>
        <Button loading={form.formState.isSubmitting} type="submit">
          Trigger gratification
        </Button>
      </form>
    </FormProvider>
  );
};

export default UseGratifications;
