import { SubscriptionPlanNameEnum } from 'types/users.types';

const paywall = {
  plans: {
    [SubscriptionPlanNameEnum.small1]: {
      netPrice: 17.5,
    },
    [SubscriptionPlanNameEnum.small12]: {
      netPrice: 15,
    },
  },
};

export default paywall;
