import i18n from 'i18n';
import { get } from 'lodash';
import {
  getPeriodAsFilterOptionValue,
  getPeriodLabel,
} from 'store/shared/filters/filterConstraints';
import type { FilterResponse, FilterV2 } from 'types/global.types';
import type { AvailableCountries } from 'types/users.types';
import { isExpert, isWeb } from 'utils/constants';

const allowedFilters = [
  'status',
  'type',
  'isValidated',
  'reviewed',
  'payment',
  'isInvoice',
  'source',
  'period',
  'categories',
  'dateRange',
  'clientIds',
  // 'withRecurring',
  isExpert ? 'exported' : null,
];
const keysMapper = {
  period: 'periods',
  type: 'types',
  categories: 'categoryIds',
};

const slugs: Record<string, string | undefined> = {
  isValidated: isWeb ? 'filters.validated.web' : 'invoices.filters.isValidated',
  reviewed: isWeb ? 'filters.reviewed.web' : 'invoices.filters.reviewed',
};

export const adaptFilters = (
  data: FilterResponse[],
  country: AvailableCountries,
): FilterV2[] => {
  return data
    .filter((f) => allowedFilters.includes(f.key))
    .map((f) => {
      const key = get(keysMapper, f.key, f.key) as string;
      return {
        ...f,
        isV2: true,
        key,
        label: i18n.t(slugs[f.key] || `invoices.filters.${f.key}`),
        options: (() => {
          if (key === 'exported') {
            return f.values.map((option) => ({
              label: i18n.t(`invoices.filters.exported.${option}`) as string,
              value: option,
            }));
          }
          if (key === 'categoryIds') {
            return f.values.map((option: { id: string }) => ({
              label: i18n.t(option.id),
              value: option.id,
            }));
          }
          if (key === 'periods') {
            return f.values.map((period) => ({
              label: getPeriodLabel(period),
              value: getPeriodAsFilterOptionValue(period),
            }));
          }
          if (key === 'clientIds') {
            return f.values.map((client) => ({
              label: client.name,
              value: client._id,
            }));
          }
          if (key === 'dateRange') {
            return [];
          }
          return f.values.map((v: string) => ({
            label:
              v === 'credit-note'
                ? i18n.t(`invoices.filters.${country}.credit_note`)
                : i18n.t(`invoices.choices.${v}`),
            value: v,
          }));
        })(),
      };
    });
};
