import { AccountSettingsFieldsNamesEnum } from 'types/settings.types';
import { AccountTypeEnum, ActivityTypesEnum } from 'types/users.types';
import { VATNumberMaskNormalizer } from 'utils/settingsHelpers';

const { one_person_company, natural_person } = AccountTypeEnum;
const { principal, complementary, student } = ActivityTypesEnum;

const settings = {
  isBetaFeaturesEligible: true,
  canChangeVATFrequency: false,
  vatNumberPlaceholder: 'BE0999999999',
  IBANPlaceholder: 'BE99 9999 9999 9999',
  countryBasedFormFields: [
    AccountSettingsFieldsNamesEnum.occupation,
    AccountSettingsFieldsNamesEnum.APC1,
    AccountSettingsFieldsNamesEnum.APC2,
    AccountSettingsFieldsNamesEnum.nationalRegistryNumber,
    AccountSettingsFieldsNamesEnum.accountType,
    AccountSettingsFieldsNamesEnum.activity,
    AccountSettingsFieldsNamesEnum.complementaryDays,
    AccountSettingsFieldsNamesEnum.VATType,
    AccountSettingsFieldsNamesEnum.VATNumber,
    AccountSettingsFieldsNamesEnum.professionType,
  ],
  accountTypeOptions: [
    {
      value: one_person_company,
      label: 'settings.companyDetails.accountType.company',
    },
    {
      value: natural_person,
      label: 'settings.companyDetails.accountType.natural_person',
    },
  ],
  activityTypeOptions: [
    {
      value: principal,
      label: 'settings.companyDetails.activity.primary_activity',
    },
    {
      value: complementary,
      label: 'settings.companyDetails.activity.secondary_activity',
    },
    {
      value: student,
      label: 'settings.companyDetails.accountType.natural_person_student_vat',
    },
  ],
  VATNumberMask: 'BE 9999999999',
  VATNumberMaskNormalizer: VATNumberMaskNormalizer('BE'),
  available_languages: [0, 1, 2], // english, french, dutch
  hasTaxesSettings: false,
  dateFormat: 'DD/MM/YYYY',
  requiredProfileData: [
    { field: AccountSettingsFieldsNamesEnum.VATNumber, weight: 25 },
    { field: AccountSettingsFieldsNamesEnum.city, weight: 7 },
    { field: AccountSettingsFieldsNamesEnum.street, weight: 9 },
    { field: AccountSettingsFieldsNamesEnum.zip, weight: 9 },
    { field: AccountSettingsFieldsNamesEnum.IBAN, weight: 25 },
  ],
};

export default settings;
