interface Options {
  concurrentJobs: number;
}

type Job<T> = () => Promise<T>;

// ConcurrencyLimiter limits the number of concurrent jobs to a specified
// maximum without taking into account how many are processed per second.
export default class ConcurrencyLimiter<T> extends EventTarget {
  private jobsQueue: Job<T>[] = [];
  private running = 0;

  constructor(private options: Options) {
    super();
  }

  schedule(job: Job<T>): void {
    this.jobsQueue.push(job);
    this.processNextJob();
  }

  clearQueue(): void {
    this.jobsQueue = [];
  }

  private processNextJob() {
    if (this.running < this.options.concurrentJobs) {
      const job = this.jobsQueue.shift();
      if (job) this.run(job);
      // Send an event that the queue is empty
      else if (!this.running) this.dispatchEvent(new Event('empty'));
    }
  }

  private run(job: Job<T>) {
    this.running += 1;

    job().finally(() => {
      this.running -= 1;
      this.processNextJob();
    });
  }
}
