import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { GuessedExpense } from 'types/expense.types';
import { CurrenciesEnum, type VATReturnFrequency } from 'types/global.types';
import {
  InvoiceStatusEnum,
  type Invoice,
  type InvoiceType,
} from 'types/invoice.types';
import { InvoiceFileType, RevenueType } from 'types/revenues.types';
import type { IMatchedItem, Transaction } from 'types/transactions.types';
import {
  AvailableCountries,
  VATTypeEnum,
  type VATType,
} from 'types/users.types';
import { multiplyBy1000 } from 'utils/math';

import type { GuessedRevenueType } from 'api/v1/transactions/getGuessedDocument';
import { getRevenueOCR } from 'api/v2/revenues';
import { uploadFile } from 'api/v2/upload';
import { getDefaultVATRate } from 'components/pages/Expenses/Form/utils';
import { useMemo } from 'react';
import { ClientLocationEnum } from 'types/clients.types';
import { getPeriodFromDate } from 'utils/date';
import { calculateVATAmount } from 'utils/expenses/helper';
import { resolveImgsContext } from 'utils/helpers';

const imgsContext = require.context('assets/svg/classification');
const sprinklesClassificationImgsContext = require.context(
  'assets/svg/one_click_classification',
);

const categoriesIcons = resolveImgsContext(imgsContext);
const sprinklesClassificationIcons = resolveImgsContext(
  sprinklesClassificationImgsContext,
);

const commonLinkingViewProps = {
  searchBarContainerClassName: 'px-6',
  listContainerClassName: 'px-6 overflow-y-auto',
};

export const getTransactionDate = (
  transaction: Transaction,
  format?: string,
): Dayjs | string => {
  const date = dayjs(
    transaction && typeof transaction === 'object'
      ? new Date(transaction.valueDate || transaction.executionDate)
      : new Date(),
  );

  return format ? date.format(format) : date;
};

// Expense creation helpers
const formatExpenseFromTransaction = (
  transaction: Transaction | Transaction[],
  OCRGuess: GuessedExpense,
  country: AvailableCountries,
  globalVATType: VATType,
  globalVATFrequency: VATReturnFrequency,
  category?: Record<string, any>,
  taxPeriod?: Record<string, any>,
  isCreditNote?: boolean,
): Record<string, any> => {
  const isBulk = Array.isArray(transaction);

  const singleTransaction = isBulk ? transaction[0] : transaction;

  const expenseDate = getTransactionDate(
    singleTransaction,
    'YYYY-MM-DD',
  ) as string;

  const supplier = {
    name: OCRGuess?.supplierName || singleTransaction.counterPartyName || '',
    country,
  };

  const amount = Math.abs(
    isBulk
      ? transaction.reduce(
          (acc, t) => acc + (t.baseCurrencyAmount ?? t.amount),
          0,
        )
      : singleTransaction.baseCurrencyAmount ?? singleTransaction.amount,
  );

  const _category = category || OCRGuess.category;

  const VATRate = getDefaultVATRate({
    isSupplierLocal: supplier.country === country,
    isCustomerBE: country === AvailableCountries.belgium,
    expenseDate,
    categorySuggestedVATRate:
      typeof _category?.suggestedVATRate === 'number'
        ? _category.suggestedVATRate
        : undefined,
  }) as number;

  const period = getPeriodFromDate(
    globalVATFrequency as VATReturnFrequency,
    expenseDate,
  );

  const itemCategoryProps = {
    categoryId: _category?.id,
    incomeTaxDeductibility: _category?.incomeTaxDeductibility,
    maxDeductibleVAT: _category?.maxDeductibleVAT,
    professionalPart: OCRGuess.professionalPart || 1,
    category: _category,
  };

  return {
    expenseDate,
    isCreditNote,
    items: [
      {
        VATAmount: calculateVATAmount(amount, VATRate),
        amount,
        qty: 1,
        vehicle: OCRGuess.vehicle,
        vehicleId: OCRGuess.vehicle?._id,
        ...itemCategoryProps,
      },
    ],
    supplier,
    transactions: isBulk ? transaction.map((t) => t._id) : [transaction._id],
    _matchedTransactions: isBulk ? transaction : [transaction],
    period,
    cashRemainder: false,
    matchCard: false,
    isValidated: true,
    isInvoice:
      OCRGuess.isInvoice ||
      (country === AvailableCountries.germany &&
        globalVATType === VATTypeEnum.subjectToVAT),
    taxPeriod,
  };
};

const getPaymentDateAndStatusFromTransaction = (transaction?: Transaction) => {
  const withTransactionDate = transaction?.valueDate;
  const paymentDate = withTransactionDate
    ? dayjs(withTransactionDate).format('YYYY-MM-DD')
    : undefined;
  const status = paymentDate ? InvoiceStatusEnum.paid : InvoiceStatusEnum.sent;

  return {
    paymentDate,
    status,
  };
};

const formatInvoiceFromTransaction = (
  transaction: Transaction | Transaction[],
  category: Record<string, any>,
  type: RevenueType,
  globalVATFrequency: VATReturnFrequency,
  taxPeriod?: any,
  OCRGuess?: GuessedRevenueType,
): Partial<Invoice> => {
  const isBulk = Array.isArray(transaction);

  const singleTransaction = isBulk ? transaction[0] : transaction;

  const unitAmountInclVAT = Math.abs(
    multiplyBy1000(
      isBulk
        ? transaction.reduce(
            (acc, t) => acc + (t.baseCurrencyAmount ?? t.amount),
            0,
          )
        : singleTransaction.baseCurrencyAmount ?? singleTransaction.amount,
    ),
  );

  const items: any[] = [
    {
      unitAmountInclVAT,
      unitAmountExclVAT: 0,
      quantity: 1000,
      name: singleTransaction.counterPartyName,
      unit: 'items',
      category: category || OCRGuess?.category,
      categoryId: category?.id || OCRGuess?.categoryId,
      VATRate: OCRGuess?.VATRate ? OCRGuess?.VATRate * 1000 : undefined,
      doesUnitPriceIncludeVAT: true,
    },
  ];

  const invoiceDate = getTransactionDate(
    singleTransaction,
    'YYYY-MM-DD',
  ) as string;

  const _matchedTransactions = isBulk ? transaction : [transaction];

  const transactions = isBulk
    ? transaction.map((t) => t._id)
    : [transaction._id];

  const period = getPeriodFromDate(
    globalVATFrequency as VATReturnFrequency,
    invoiceDate,
  );

  return {
    currency: OCRGuess?.currency || 'EUR',
    invoiceDate,
    paymentDate: invoiceDate,
    items,
    status: InvoiceStatusEnum.paid,
    transactions,
    _matchedTransactions,
    type,
    taxPeriod,
    period,
    client: OCRGuess?.client
      ? OCRGuess?.client
      : type === RevenueType.other_revenue
      ? ({
          location: ClientLocationEnum.local,
        } as any)
      : undefined,
    fileType: 'generated',
  };
};

const getBaseInvoiceData = (
  OCRGuess?: GuessedRevenueType,
): Partial<Invoice> => ({
  type: 'invoice',
  transactions: [],
  _matchedTransactions: [],
  status: 'sent',
  currency: OCRGuess?.currency || CurrenciesEnum.EUR,
});

const adaptRevenueOCRResult = (
  result: Partial<Invoice>,
  transactions?: Transaction[],
): Partial<Invoice> => {
  return {
    ...getBaseInvoiceData(),
    ...result,
    ...getPaymentDateAndStatusFromTransaction(transactions?.[0]),
    dueDate:
      result.dueDate || result.invoiceDate
        ? dayjs(result.invoiceDate).add(1, 'day').format('YYYY-MM-DD')
        : undefined,
    ...(transactions
      ? {
          transactions: transactions.map((t) => t._id),
          _matchedTransactions: transactions,
        }
      : null),
  };
};

const getRevenueOCRFromFile = (
  file: File,
  revenueType: InvoiceType,
  onProgress: (n: number) => void,
) => {
  return uploadFile({ file, onProgress }).then(({ key }: any) =>
    getRevenueOCR({ filename: key.split('/').pop(), type: revenueType }).then(
      (result) => ({
        filePath: key,
        files: [{ path: key }],
        fileType: InvoiceFileType.imported,
        ...result,
      }),
    ),
  );
};

export const useMapIdsWithTransactions = (
  ids: string[] = [],
  transactions: Transaction[] = [],
): Transaction[] => {
  return useMemo(() => {
    const list = (transactions || []) as Transaction[];
    return list.filter((t) => (ids || []).includes(t._id));
  }, [ids, transactions]);
};

type UseTransactionsTypeStateReturn = {
  isBulk: boolean;
  isPositive: boolean;
  isNegative: boolean;
  isMixed: boolean;
  isPostiveMoreThanNegative: boolean;
  isEmpty: boolean;
  isForeign: boolean;
};

export const useTransactionsTypeState = (
  transactions: Transaction | Transaction[],
): UseTransactionsTypeStateReturn => {
  return useMemo(() => {
    const isBulk = Array.isArray(transactions);
    if (!isBulk || (isBulk && transactions.length == 1)) {
      const _transaction = isBulk ? transactions[0] : transactions;
      const isPositive = _transaction.amount > 0;
      return {
        isEmpty: false,
        isBulk: false,
        isPositive,
        isNegative: !isPositive,
        isMixed: false,
        isPostiveMoreThanNegative: isPositive,
        isForeign: _transaction.currency !== 'EUR',
      };
    }
    if (!transactions.length) {
      return {
        isEmpty: true,
        isBulk: false,
        isPositive: false,
        isNegative: false,
        isMixed: false,
        isPostiveMoreThanNegative: false,
        isForeign: false,
      };
    }
    const positiveDocuments = transactions.filter((d) => d.amount > 0);
    const negativeDocuments = transactions.filter((d) => d.amount < 0);
    const isPositive = positiveDocuments.length === transactions.length;
    const isNegative = negativeDocuments.length === transactions.length;
    const isPostiveMoreThanNegative =
      positiveDocuments.length > negativeDocuments.length;
    return {
      isEmpty: false,
      isBulk: true,
      isPositive,
      isNegative,
      isMixed: !isPositive && !isNegative,
      isPostiveMoreThanNegative,
      isForeign: transactions.some((t) => t.currency !== 'EUR'),
    };
  }, [transactions]);
};

const isTypeExpense = (type: string) => {
  return type === 'expense';
};

const isTypeInvoice = (type: string) => {
  return ['invoice', 'credit-note', 'other-revenue'].includes(type);
};

const matchedItemSubmitKey = {
  expense: 'expenseId',
  invoice: 'revenueId',
};

const getBulkDocUpdateKey = (type: string) => {
  return isTypeExpense(type)
    ? matchedItemSubmitKey.expense
    : matchedItemSubmitKey.invoice;
};

const adaptTransactionToLink = (transaction: Transaction) => {
  // We have to include the "amount" to be part of the "matchedItems" to calculate the linking progress
  const matchedItemsWithAmounts = transaction.matchedItems?.map((item) => {
    const _item = transaction.matchedItemsDetails?.find(
      (_item) => _item._id === item.documentId,
    );

    const amount = _item?.baseCurrencyTotalAmountInclVAT
      ? _item?.baseCurrencyTotalAmountInclVAT / 1000
      : _item?.amount;

    return { ...item, amount };
  });

  return { ...transaction, matchedItems: matchedItemsWithAmounts };
};

const createExpenseMatchedItem = (
  _id: string,
  isCreditNote: boolean,
): IMatchedItem => {
  return {
    type: 'expense',
    documentId: _id,
    isCreditNote,
  };
};

const createInvoiceMatchedItem = (
  _id: string,
  isCreditNote: boolean,
): IMatchedItem => {
  return {
    type: 'invoice',
    documentId: _id,
    isCreditNote,
  };
};

export {
  formatExpenseFromTransaction,
  formatInvoiceFromTransaction,
  getBaseInvoiceData,
  categoriesIcons,
  sprinklesClassificationIcons,
  getRevenueOCRFromFile,
  adaptRevenueOCRResult,
  isTypeExpense,
  isTypeInvoice,
  getBulkDocUpdateKey,
  createExpenseMatchedItem,
  createInvoiceMatchedItem,
  adaptTransactionToLink,
  commonLinkingViewProps,
};
