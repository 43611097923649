import * as jsvat from '@accountable/jsvat';
import { getInvoiceItemsTotalInclVAT } from 'components/pages/Revenues/OtherRevenueForm/utils';
import type { ObjectSchema, Schema, StringSchema } from 'joi';
import Joi from 'joi';
import { get } from 'lodash';
import type { DictionaryType } from 'types/global.types';
import type { InvoiceItem } from 'types/invoice.types';

const checkVAT = (
  VATNumber: string,
  countries?: jsvat.CountryConfig[],
): jsvat.VatCheckResult =>
  jsvat.checkVAT(VATNumber, countries || jsvat.countries);

const validateBankInfo = (
  validate: (value: string) => boolean,
  messages: any,
): StringSchema =>
  Joi.string()
    .allow('', null)
    .replace(/ /g, '')
    .custom((value, helpers) => {
      if (!validate(value)) {
        return helpers.error('any.invalid');
      }
      return value;
    })
    .messages(messages);

// Since the 'fieldName' has a '.' (e.g. 'address.street', 'address.city' ..etc), and Joi has a certain way to
// handle the validation for such grouped form fields, this function adjusts the validation structure to meet
// the Joi's required data structure.
// INPUT: { 'address.street': Joi.string(), 'address.city': Joi.string, }
// OUTPUT: Joi.object({ street: Joi.string(), city: Joi.string() })
const groupValidation = (
  group: string,
  validationObject = {},
): { [x: string]: ObjectSchema<any> } => {
  const _validationObject = Object.entries(validationObject).reduce(
    (acc: any, [fieldName = '', value]) => {
      acc[fieldName.split('.')[1]] = value;

      return acc;
    },
    {},
  );

  return {
    [group]: Joi.object(_validationObject),
  };
};

const getCountryBasedValidation = (
  country: string,
  validationMap: { [x: string]: Schema<any> } = {},
): Schema<any> => validationMap[country as keyof typeof validationMap];

const formatErrorMessage = (prefix: string, label: string): DictionaryType => {
  const keys = [
    'any.only',
    'any.empty',
    'any.required',
    'any.invalid',
    'string.base',
    'string.empty',
    'string.email',
    'string.min',
    'string.max',
    'string.pattern.base',
    'string.length',
    'missing.number',
    'number.type',
    'number.base',
    'number.min',
    'number.max',
    'number.empty',
    'array.min',
    'array.max',
    'array.length',
    'object.required',
    'object.base',
    'date.min',
    'date.max',
    'date.less',
    'date.format',
    'date.base',
    'any.ref',
    'string.pattern.base',
    'date.invalid',
  ];

  return keys.reduce((acc, val) => {
    // we're returning the same key when .base and .empty
    // to reduce the number of translations needed since
    // for the enduser it's kinda the same problem
    // value is missing
    const parsedKeyValue = val.replace('.base', '.empty');

    return { ...acc, [val]: `${prefix}.${label}.${parsedKeyValue}` };
  }, {});
};

const formatGenericError = (rule?: string, prefix?: string) => {
  const keys = [
    'any.only',
    'any.empty',
    'any.required',
    'any.invalid',
    'string.base',
    'string.empty',
    'string.email',
    'string.min',
    'string.max',
    'string.length',
    'string.alphaNumeric',
    'missing.number',
    'number.type',
    'number.base',
    'number.min',
    'number.max',
    'number.empty',
    'array.min',
    'array.max',
    'object.required',
    'date.min',
    'date.max',
    'date.less',
  ];

  return keys.reduce((acc, key) => {
    const pref = rule === key ? `${prefix}.` : '';
    const parsedKeyValue = key.replace('.base', '.empty');

    return { ...acc, [key]: `${pref}validation.${parsedKeyValue}` };
  }, {});
};

const validatePeriod = Joi.object({
  quarter: Joi.number()
    .valid(1, 2, 3, 4)
    .when(Joi.ref('$user.country'), { is: 'be', then: Joi.required() })
    .messages(formatErrorMessage('invoice', 'periodQuarter')),
  year: Joi.number()
    .max(new Date().getFullYear() + 1)
    .min(new Date().getFullYear() - 10)
    .required()
    .messages(formatErrorMessage('invoice', 'periodYear')),
  month: Joi.number()
    .valid(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12)
    .messages(formatErrorMessage('invoice', 'periodMonth')),
});

const validatePaymentAmount: Joi.CustomValidator<any> = (value, helpers) => {
  const items = get(
    helpers.state,
    ['ancestors', 0, 'items'],
    [],
  ) as Partial<InvoiceItem>[];

  const total = getInvoiceItemsTotalInclVAT(items);

  if (total > 0) {
    if (value < 10) {
      return helpers.error('number.min');
    }
    return value;
  }

  if (total <= 0) {
    if (value < 0) {
      return helpers.error('number.min_0');
    }
    return value;
  }
};

export {
  checkVAT,
  groupValidation,
  getCountryBasedValidation,
  validateBankInfo,
  formatErrorMessage,
  formatGenericError,
  validatePeriod,
  validatePaymentAmount,
};
