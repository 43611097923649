import api from 'api/axiosInstance';
import { EmailData } from 'api/v2/revenues';

const integrationService = '/v1/integrations';
const revenueService = '/v2/revenues';
const clientService = '/v2/clients';

export enum IntegrationSlugEnum {
  horusChift = 'horus-chift',
  octopusChift = 'octopus-chift',
  exactChift = 'exact-chift',
  peppol = 'peppol',
  odooChift = 'odoo-chift',
}

export enum IntegrationsStatusEnum {
  available = 'available',
  active = 'active',
  inactive = 'inactive',
  waiting_callback = 'waiting_callback',
  pending = 'pending',
}
export enum IntegrationsDeactivationReasons {
  userUpdated = 'user-updated',
}

enum IntegrationTypeEnum {
  access = 'access',
  user = 'user',
}

export type CurrentIntegration = {
  _id: string;
  slug: IntegrationSlugEnum;
  status: IntegrationsStatusEnum;
  deactivationReason?: IntegrationsDeactivationReasons;
  connection_url: string;
  connectionId: string;
  logo_url: string;
  icon_url: string;
};

type PeppolCurrentIntegration = CurrentIntegration & {
  slug: IntegrationSlugEnum.peppol;
  metaData: PeppolIntegrationFormData;
};

export type AvailableIntegration = {
  type: IntegrationTypeEnum;
  slug: IntegrationSlugEnum;
  status: IntegrationsStatusEnum;
  logo_url: string;
  icon_url: string;
};

type Integrations = {
  currentIntegrations: CurrentIntegration[];
  availableIntegrations: AvailableIntegration[];
};

export const getIntegrations = (): Promise<{ data: Integrations }> => {
  return api.get(integrationService);
};

export const deleteIntegration = (id: string) => {
  return api.delete(`${integrationService}/${id}`);
};

export const findIntegration = (id: string) => {
  return api
    .get<any, { integration: PeppolCurrentIntegration }>(
      `${integrationService}/${id}`,
    )
    .then(({ integration }) => integration);
};

export type PeppolIntegration = {
  current:
    | (CurrentIntegration & { metaData: PeppolIntegrationFormData })
    | null;
  available: AvailableIntegration | null;
};

export const getPeppolIntegration = () => {
  return getIntegrations().then(({ data }) => {
    const current =
      data.currentIntegrations.find(
        (integration) => integration.slug === IntegrationSlugEnum.peppol,
      ) || null;
    const available =
      data.availableIntegrations.find(
        (integration) => integration.slug === IntegrationSlugEnum.peppol,
      ) || null;
    return {
      current,
      available,
    } as PeppolIntegration;
  });
};

export enum PeppolIntegrationScheme {
  IBAN = 'IBAN',
  BE_VAT = 'BE:VAT',
  DE_VAT = 'DE:VAT',
}

export type PeppolIntegrationFormData = {
  companyName: string;
  address: {
    street: string;
    zip: string;
    city: string;
  };
  identifier: string;
  scheme: PeppolIntegrationScheme;
};

export const createPeppolIntegration = (data: PeppolIntegrationFormData) => {
  return api.post(integrationService, {
    slug: IntegrationSlugEnum.peppol,
    metaData: data,
  });
};

export const updatePeppolIntegration = (
  id: string,
  data: PeppolIntegrationFormData,
) => {
  return api.put(`${integrationService}/${id}`, {
    slug: IntegrationSlugEnum.peppol,
    metaData: data,
  });
};

export const checkClientPeppolStatus = (id: string) => {
  return api
    .get<
      any,
      {
        exists: boolean;
      }
    >(`${clientService}/${id}/peppol-exists`)
    .then(({ exists }) => exists);
};

export enum BulkEmailsMethods {
  PEPPOL = 'PEPPOL',
  EMAIL = 'EMAIL',
}

type BulkEmails = (
  | { method: BulkEmailsMethods.PEPPOL }
  | { method: BulkEmailsMethods.EMAIL; data: EmailData }
)[];

export type BulkEmailErrorResponse = {
  ok: false;
  method: BulkEmailsMethods;
  reason: string;
  error: { message: string; code: string }[] | null;
};

export type BulkEmailSuccessResponse = { ok: true; method: BulkEmailsMethods };

type BulkEmailsResponse = {
  ops: (BulkEmailErrorResponse | BulkEmailSuccessResponse)[];
};

export const sendInvoiceEmailAndPeppol = (
  id: string,
  data: BulkEmails,
): Promise<BulkEmailsResponse> => {
  return api.post(`${revenueService}/${id}/send`, {
    ops: data,
  });
};
