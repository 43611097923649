import type { HelperProps } from 'components/molecules/Helper';
import { Helper } from 'components/molecules/Helper';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const PeriodHelper: FC<{
  className?: string;
  trigger?: (openModal: () => void) => ReactNode;
}> = ({ className, ...props }) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <Helper
        {...(props as HelperProps)}
        name={t('period.field.period_assignment.help_article.explanation')}
      >
        <Helper.Title>
          {t('period.field.period_assignment.help_article.explanation')}
        </Helper.Title>
        <Helper.Paragraph>
          {t('period.field.period_assignment.help_article.description_1')}
        </Helper.Paragraph>
        <Helper.Paragraph>
          {t('period.field.period_assignment.help_article.description_2')}
        </Helper.Paragraph>
        <Helper.Paragraph>
          {t('period.field.period_assignment.help_article.description_3')}
        </Helper.Paragraph>
      </Helper>
    </div>
  );
};

export default PeriodHelper;
