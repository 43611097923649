import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import WarningModalContent from './WarningModalContent';

type AmortizationEditModalContentProps = {
  onConfirm: () => Promise<any>;
  onCancel: () => void;
};

const AmortizationEditModalContent: FC<AmortizationEditModalContentProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { isLoading, mutate } = useMutation(() => onConfirm());

  return (
    <WarningModalContent
      onChoose={(confirmed) => (confirmed ? mutate() : onCancel())}
      description={t('expense.amortization.edit_warning')}
      options={[
        {
          value: true,
          isLoading,
          title: t('expense.amortization.edit.title'),
          description: t('expense.amortization.edit.subtitle'),
        },
        {
          value: false,
          title: t('expense.amortization.leave_as_is.title'),
          description: t('expense.amortization.leave_as_is.subtitle.edit'),
        },
      ]}
    />
  );
};

export default AmortizationEditModalContent;
