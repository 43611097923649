import type { KeyboardEvent } from 'react';
// we use left right events for some functionality, however if a field is focused we dont want
// any listerer to be notifed about the event
// so this function is usually used with fields
const eventKey = ['ArrowLeft', 'ArrowRight'];
export const captureLeftRightArrowEvents = (e: KeyboardEvent) => {
  if (eventKey.includes(e.key)) {
    e.stopPropagation();
  }
};
