import classNames from 'classnames';
import type { FC } from 'react';
import type { FileUploadedListProps } from './FileUploaded.types';
import FileUploadedItem from './FileUploadedItem.component';

const FileUploadedList: FC<FileUploadedListProps> = ({
  files,
  className,
  onRetry,
  color,
  renderUploadedFileItem,
}): JSX.Element => {
  return (
    <div className={classNames(className)}>
      {files.map((fileState) => {
        const { key, file, status, progress, options } = fileState;

        return renderUploadedFileItem ? (
          renderUploadedFileItem(fileState, files)
        ) : (
          <FileUploadedItem
            key={key}
            name={file.name}
            status={status}
            size={file.size}
            progress={progress}
            onRetry={() => onRetry(file, key, options)}
            color={color}
          />
        );
      })}
    </div>
  );
};

export default FileUploadedList;
