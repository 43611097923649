import { periodToValue } from 'components/molecules/Fields/Period/utils';
import type { Period, VATReturnFrequency } from 'types/global.types';
import type { Category } from './categoriesPicker.types';

export const attachData = (cates: Category[]): Category[] => {
  let uid = 0;
  return (function _attachData(cates: any[], path: string[]): any[] {
    return cates.map((cate) => {
      if (!cate.list) {
        return {
          ...cate,
          path,
          isFinal: true,
        };
      }
      const id = cate.id || `${uid++}`;
      const ownPath = path.concat(id);
      return {
        ...cate,
        isFinal: false,
        id,
        path: ownPath,
        list: _attachData(cate.list, ownPath),
      };
    });
  })(cates, []);
};

export const getCategoriesById = (cates: Category[]) => {
  const map: { [key: string]: Category } = {};
  cates.forEach((cate) => {
    if (cate.id) {
      map[cate.id] = cate;
    }
    if (cate.list) Object.assign(map, getCategoriesById(cate.list));
  });
  return map;
};

export const categoryIdWithTaxPeriodToValue = (
  id: string,
  frequency: VATReturnFrequency,
  period: Period,
) => {
  return `${id}#${frequency}#${periodToValue(period, frequency)}`;
};

export const includesPattern = ({
  title = '',
  displayName = '',
  description = '',
  keywords = [] as string[],
  searchValue = '',
}) => {
  const searchTerms = searchValue
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/[^\w\s]/g, '')
    .toLowerCase()
    .split(' ')
    .filter((term) => !!term.length);

  if (!searchTerms.length) return false;

  const str = [title, displayName, description, ...keywords]
    .join(' ')
    .replace(/\s+/g, ' ')
    .replace(/[^\w\s]/g, '')
    .toLowerCase();
  return searchTerms.some(
    (term) => str.startsWith(term) || str.includes(` ${term}`),
  );
};
