import colors from 'theme/colors';
import type { HelperContent } from './types';
import { ContentType } from './types';

export const helperContent36: HelperContent[] = [
  {
    type: ContentType.title,
    text: 'gratification_message_36_title_1',
  },
  {
    type: ContentType.text,
    text: 'gratification_message_36_text_1',
  },
  {
    type: ContentType.title,
    text: 'gratification_message_36_title_2',
  },
  {
    type: ContentType.text,
    text: 'gratification_message_36_text_2',
  },
];

export const helperContent33: HelperContent[] = [
  {
    type: ContentType.title,
    text: 'gratification_message_33_title_1',
  },
  {
    type: ContentType.text,
    text: 'gratification_message_33_text_1',
  },
  {
    type: ContentType.title,
    text: 'gratification_message_33_title_2',
  },
  {
    type: ContentType.text,
    text: 'gratification_message_33_text_2',
  },
  {
    type: ContentType.title,
    text: 'gratification_message_33_title_3',
  },
  {
    type: ContentType.text,
    text: 'gratification_message_33_text_3',
  },
];

export const helperContent18: HelperContent[] = [
  { type: ContentType.text, text: 'gratification_message_18_text_1_new' },
  {
    type: ContentType.container,
    backgroundColor: colors.expenses[100],
    innerContent: [
      {
        type: ContentType.subTitle,
        text: 'gratification_message_18_subtitle_1_new',
      },
      {
        type: ContentType.subText,
        text: 'gratification_message_18_subtext_1_new',
        onPressId: 1,
      },
      {
        type: ContentType.table,
        innerContent: [
          {
            type: ContentType.tableRowTitle,
            text: 'gratification_message_18_table_1_title_new',
          },
          {
            type: ContentType.tableRow,
            text: 'gratification_message_18_table_1_row_1_label_new',
            value: 'gratification_message_18_table_1_row_1_value_new',
          },
          {
            type: ContentType.tableRow,
            text: 'gratification_message_18_table_1_row_2_label_new',
            value: 'gratification_message_18_table_1_row_2_value_new',
          },
        ],
      },
      {
        type: ContentType.table,
        innerContent: [
          {
            type: ContentType.tableRowTitle,
            text: 'gratification_message_18_table_2_title_new',
            onPressId: 2,
          },
          {
            type: ContentType.tableRow,
            text: 'gratification_message_18_table_2_row_1_label_new',
            value: 'gratification_message_18_table_2_row_1_value_new',
          },
          {
            type: ContentType.tableRow,
            text: 'gratification_message_18_table_2_row_2_label_new',
            value: 'gratification_message_18_table_2_row_2_value_new',
          },
          {
            type: ContentType.tableRow,
            text: 'gratification_message_18_table_2_row_3_label_new',
            value: 'gratification_message_18_table_2_row_3_value_new',
          },
        ],
      },
      {
        type: ContentType.tableLink,
        text: 'gratification_message_18_table_link_new',
        onPressId: 3,
      },
    ],
  },
  {
    type: ContentType.text,
    text: 'gratification_message_18_text_2_new',
    onPressId: 4,
  },
  {
    type: ContentType.floatingButton,
    text: 'gratification_message_18_redirection_button_new',
    onPressId: 5,
  },
];

export const helperContent271: HelperContent[] = [
  { type: ContentType.text, text: 'gratification_message_271_text_1' },
  {
    type: ContentType.container,
    backgroundColor: colors.expenses[100],
    innerContent: [
      {
        type: ContentType.subTitle,
        text: 'gratification_message_271_container_title_1',
      },
      {
        type: ContentType.table,
        innerContent: [
          {
            type: ContentType.tableRowTitle,
            text: 'gratification_message_271_table_title_1',
          },
          {
            type: ContentType.tableRow,
            text: 'gratification_message_271_table_row_label_1',
            value: 'gratification_message_271_table_row_value_1',
          },
          {
            type: ContentType.tableRow,
            text: 'gratification_message_271_table_row_label_2',
            value: 'gratification_message_271_table_row_value_2',
          },
          {
            type: ContentType.tableRow,
            text: 'gratification_message_271_table_row_label_3',
            value: 'gratification_message_271_table_row_value_3',
          },
          {
            type: ContentType.divider,
          },
          {
            type: ContentType.tableRow,
            text: 'gratification_message_271_table_row_label_4',
            value: 'gratification_message_271_table_row_value_4',
          },
        ],
      },
    ],
  },
  { type: ContentType.text, text: 'gratification_message_271_text_2' },
];

export const helperContent270: HelperContent[] = [
  {
    type: ContentType.text,
    text: 'gratification_message_270_helper_text_1',
    onPressId: 1,
  },
  {
    type: ContentType.floatingButton,
    text: 'gratification_message_270_helper_button_1',
    onPressId: 2,
  },
];

// 329, 330, 331, 332, 333, 334, 336
export const professionHelperContent: HelperContent = {
  type: ContentType.container,
  shouldShow: ({ user }) => !!user.APC1 && !!user.APC2,
  innerContent: [
    {
      type: ContentType.subTitle,
      text: 'gratifications.about_profession.title',
    },
    {
      type: ContentType.text,
      text: 'gratifications.about_profession.message',
    },
    {
      type: ContentType.button,
      to: '/settings/personalDetails',
      text: 'gratifications.about_profession.change.label',
    },
  ],
};
