import create from 'zustand';

export type EffectsMap = Record<string, any>;

const UIEffectsCache = () =>
  create<{
    effectsCache: EffectsMap;
    setEffectsCache: (next: EffectsMap) => void;
  }>((set) => ({
    effectsCache: {} as EffectsMap,
    setEffectsCache: (next: EffectsMap) =>
      set((state) => Object.assign(state.effectsCache, next)),
  }));

export default UIEffectsCache;
