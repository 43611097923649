import { ExclamationCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import ContainerWithBorder from 'components/atoms/ContainerWithBorder';
import MoneyCounter from 'components/atoms/MoneyCounter';
import ExpensesIcon from 'components/atoms/SVG/sidebarIcons/ExpensesIcon.component';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useExecuteWhenElementInView from 'hooks/useExecuteWhenElementInView';
import useFeatures from 'hooks/useFeatures';
import appI18nInstance from 'i18n';
import { useState, type FC, type MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { CurrenciesEnum, type YearlyReviewData } from 'types/global.types';
import { AccountTypeEnum } from 'types/users.types';
import formatMoney from 'utils/formatMoney';
import { openInNewTab } from 'utils/helpers';
import { isNumeric } from 'utils/math';
import yearlyReviewExpensesCategories from './categories';
import { getReviewYears, prependPositiveSign } from './helpers';
import * as tipsAnimationData from './tipsIconAimation.json';

const getCategorySubtitle = (percentile: number) => {
  if (!percentile) return null;

  if (percentile >= 95) {
    return appI18nInstance.t(
      'yearly_review.deductible_expenses_widget.category_distribution.top_5',
      { percentile },
    );
  }

  if (percentile >= 65) {
    return appI18nInstance.t(
      'yearly_review.deductible_expenses_widget.category_distribution.more_than_65',
      { percentile },
    );
  }

  if (percentile <= 35) {
    return appI18nInstance.t(
      'yearly_review.deductible_expenses_widget.category_distribution.less_than_35',
      { percentile },
    );
  }

  return appI18nInstance.t(
    'yearly_review.deductible_expenses_widget.category_distribution.similar_to_others',
    { percentile },
  );
};

const DeductibleExpensesSection: FC<{ data?: YearlyReviewData }> = ({
  data,
}) => {
  const {
    t,
    i18n: { language, exists },
  } = useTranslation();

  const [runTipsAnimation, setRunTipsAnimation] = useState(false);

  const country = useCustomerCountry();

  const deductiblesUrl = useFeatures('expenses.all_you_can_deduct_link_origin')(
    language,
  );

  const thisYearDeductibleExpensesTotal =
    data?.thisYear?.deductibleExpenses?.total || 0;

  const lastYearDeductibleExpensesTotal =
    data?.lastYear?.deductibleExpenses?.total || 0;

  const deductibleExpenseChangeRate =
    ((thisYearDeductibleExpensesTotal - lastYearDeductibleExpensesTotal) /
      lastYearDeductibleExpensesTotal) *
    100;

  const topCategories = Object.entries(data?.thisYear?.categories?.top5 || {});

  const { year, previousYear } = getReviewYears();

  const { data: user } = useGetCurrentCustomerData();

  const { elementRef } = useExecuteWhenElementInView({
    execute: () => setRunTipsAnimation(true),
  });

  return (
    <ContainerWithBorder containerClassName="mt-10">
      <div className="p-6 flex">
        <div className="w-[55%] px-10 pt-6">
          <div className="flex items-center space-x-2 mb-10">
            <div
              className={classNames(
                'bg-expenses w-10 h-10 rounded-lg p-1 border-[3px] border-taxes-100',
              )}
            >
              <ExpensesIcon className="text-white stroke-white" />
            </div>

            <h1 className="font-bold font-gotham text-lg text-primary-700">
              {t('yearly_review.deductible_expenses_widget.title')}
            </h1>
          </div>

          <div className="w-full text-center">
            <div className="font-avenir text-4xl mb-3 font-semibold">
              <MoneyCounter
                amount={thisYearDeductibleExpensesTotal}
                currency="EUR"
                stateId="yearlyReviewDeductibleExpensesTotal"
                noFractions
              />
            </div>

            {isNumeric(deductibleExpenseChangeRate) ? (
              <span className="px-3 py-[3px] rounded-full border border-primary-100 font-semibold text-primary-700 text-sm">
                {prependPositiveSign(Math.floor(deductibleExpenseChangeRate))}%
                vs {previousYear}{' '}
                {deductibleExpenseChangeRate > 0 ? '💪' : null}
              </span>
            ) : null}
          </div>

          <div className="w-full mt-5 flex flex-col flex-1 justify-center">
            {topCategories.map(([key, value]) => (
              <div
                className="flex items-center justify-between border-b border-primary-50 py-3"
                key={key}
              >
                <div className="w-[75%]">
                  <p className="font-semibold text-lg text-primary-700">
                    {
                      (
                        yearlyReviewExpensesCategories[
                          key as keyof typeof yearlyReviewExpensesCategories
                        ] as any
                      )?.[language?.toUpperCase()]
                    }
                  </p>

                  <p className="text-primary-700">
                    {getCategorySubtitle(value.percentile || 0)}
                  </p>
                </div>

                <div className="font-semibold text-lg text-primary-900 w-[25%] text-right">
                  {formatMoney(
                    value?.amount || 0,
                    CurrenciesEnum.EUR,
                    undefined,
                    false,
                    true,
                  )}
                </div>
              </div>
            ))}

            <div className="w-full mt-5 flex flex-col flex-1 justify-center">
              <div className="flex items-start bg-primary-25 rounded-lg p-4">
                <ExclamationCircleIcon className="w-14 mr-4 text-primary-700 mt-2" />

                <p className="text-primary-700">
                  {t(
                    `yearly_review.deductible_expenses_widget.alert_text.${country}`,
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 bg-expenses-50 p-6 pl-10 rounded-lg relative pb-44">
          <h1 className="font-bold font-gotham text-xl text-primary-700 mb-7">
            {t(
              user?.accountType === AccountTypeEnum.one_person_company
                ? 'yearly_review.deductible_expenses_widget.to_be_deducted.corporate_title'
                : 'yearly_review.deductible_expenses_widget.to_be_deducted.title',
            )}
          </h1>

          {data?.tips?.length ? (
            data?.tips?.map((tip) => {
              const hasLink = exists(
                `yearly_review.deductible_expenses_widget.to_be_deducted.tips.${tip}.link.${country}`,
              );

              return (
                <div className="my-5" key={tip}>
                  {hasLink ? (
                    <a
                      href={t(
                        `yearly_review.deductible_expenses_widget.to_be_deducted.tips.${tip}.link.${country}`,
                      )}
                      className="text-lg font-semibold text-primary-700 hover:text-primary-500 mb-1 block"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(
                        `yearly_review.deductible_expenses_widget.to_be_deducted.tips.${tip}.title`,
                        { year },
                      )}
                    </a>
                  ) : (
                    <p className="text-lg font-semibold text-primary-700 mb-1 block">
                      {t(
                        `yearly_review.deductible_expenses_widget.to_be_deducted.tips.${tip}.title`,
                        { year },
                      )}
                    </p>
                  )}

                  <p className="text-expenses bg-white rounded-full px-4 py-1 font-semibold text-sm inline-block">
                    {t(
                      `yearly_review.deductible_expenses_widget.to_be_deducted.tips.${tip}.subtitle`,
                      { year },
                    )}
                  </p>
                </div>
              );
            })
          ) : (
            <div className="min-h-[30vh] flex flex-col items-center justify-center">
              <Lottie
                options={{
                  animationData: tipsAnimationData,
                  loop: false,
                  autoplay: runTipsAnimation,
                }}
                height={75}
                width={75}
              />

              <p className="font-semibold text-primary-700 text-sm mt-3 text-center">
                {t('yearly_review.deductible_expenses_widget.empty_tips_text')}
              </p>
            </div>
          )}

          <div ref={elementRef as MutableRefObject<HTMLDivElement>} />

          <Button
            color="expenses"
            className="absolute bottom-10 right-[20%] left-[20%]"
            onClick={() => openInNewTab(deductiblesUrl)}
          >
            {t('yearly_review.deductible_expenses_widget.to_be_deducted.cta')}
          </Button>
        </div>
      </div>
    </ContainerWithBorder>
  );
};

export default DeductibleExpensesSection;
