export type FieldState<Value = any> = {
  name: string;
  isDisabled: boolean;
  isHidden: boolean;
  isApplicable: boolean;
  shouldValidate: boolean;
  value: Value;
  suggestedChanges: { name: string; value: any }[];
};
type FieldConfigOptional<Value> = {
  name: string;
  isDisabled: boolean;
  isHidden: boolean;
  isApplicable: boolean;
  shouldValidate: boolean;
  suggestedChanges: (
    self: FieldConfigWithDefaults<Value>,
  ) => { name: string; value: any }[] | void;
};
type FieldConfig<Value> = {
  value: (self: FieldConfigWithDefaults<Value>) => Value;
};

type FieldConfigWithOptionals<Value> = FieldConfig<Value> &
  Partial<FieldConfigOptional<Value>>;
type FieldConfigWithDefaults<Value> = FieldConfig<Value> &
  FieldConfigOptional<Value>;

const fieldConfigDefaults = {
  name: '',
  isDisabled: false,
  isHidden: false,
  isApplicable: true,
  shouldValidate: true,
  suggestedChanges: () => undefined,
};

export default function getFieldState<Value = any>(
  config: FieldConfigWithOptionals<Value>,
): FieldState<Value> {
  const _config: FieldConfigWithDefaults<Value> = Object.assign(
    {},
    fieldConfigDefaults,
    config,
  );
  return {
    name: _config.name,
    value: _config.value(_config),
    isDisabled: _config.isDisabled || !_config.isApplicable,
    isHidden: _config.isHidden || !_config.isApplicable,
    isApplicable: _config.isApplicable,
    shouldValidate: _config.isApplicable && _config.shouldValidate,
    suggestedChanges:
      _config.suggestedChanges(_config) ||
      (_config.isApplicable ? [] : [{ name: _config.name, value: undefined }]),
  };
}
