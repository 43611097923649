import { NumberParser } from '@internationalized/number';
import i18n from 'i18n';
import { parseInt } from 'lodash';
import type { Currency } from 'types/global.types';
import { CurrenciesEnum } from 'types/global.types';
import { AvailableLanguages } from 'types/users.types';

type GetFormatConfig = {
  amount: number;
  currency?: Currency;
  lang?: string;
  noFractions?: boolean;
};

type IntlFormat = {
  amount: number;
  currency?: Currency;
  lang?: string;
  useGrouping?: boolean;
  noFractions?: boolean;
};

export const intlFormat = ({
  amount,
  currency,
  lang,
  useGrouping = true,
  noFractions,
}: IntlFormat): string => {
  return new Intl.NumberFormat(lang || i18n.language, {
    useGrouping,
    style: currency ? 'currency' : 'decimal',
    currency,
    minimumFractionDigits: noFractions ? 0 : currency ? 2 : undefined,
    maximumFractionDigits: noFractions ? 0 : currency ? 2 : undefined, // important for "JPY" to force it to show fractions, because japan formtting doesn't have fractions
  }).format(amount);
};

// TODO: simplify if possible by using any of Intl methods, we just added a polyfill for the api
// also support arabic numbers ?
export const getFormatConfig = ({
  amount: _amount,
  currency,
  lang,
  noFractions,
}: GetFormatConfig): any => {
  if (typeof _amount !== 'number') _amount = 0;
  const amount = Math.abs(_amount);
  const sample = intlFormat({
    lang,
    currency: currency || CurrenciesEnum.EUR,
    amount,
    noFractions,
  });
  const symbol = sample.replace(/\d.*\d/, '').trim();
  const isSymbolAtTheStart = sample.startsWith(symbol);
  const amountFormatted = sample.replace(symbol, '').trim();
  const separators = amountFormatted.replace(/\d/g, '').split('');
  const separator = separators[separators.length - 1];
  const hasMultiSeparator = separators.length > 1;
  return {
    separator,
    hasMultiSeparator,
    amountFormatted,
    symbol,
    isSymbolAtTheStart,
    isNegative: _amount < 0,
  };
};

const formatMoney = (
  amount: number,
  currency?: Currency,
  lang?: string,
  showPositiveSign?: boolean,
  noFractions?: boolean,
): string => {
  const config = getFormatConfig({ amount, currency, lang, noFractions });

  let result = `${config.isNegative ? '-' : showPositiveSign ? '+' : ''}${
    config.amountFormatted
  }`;
  if (config.isSymbolAtTheStart) result = `${config.symbol} ${result}`;
  else result = `${result} ${config.symbol}`;

  return result;
};

export const convertUserInputMoneyToNumber = (
  amount: string,
  // currency?: Currency,
): number => {
  const _amount = amount.replace(/,/g, '.');
  return new NumberParser(
    'en',
    // {
    //   style: 'currency',
    //   currency: currency || CurrenciesEnum.EUR,
    // }
  ).parse(_amount);
};

const notNumChars = /[^0-9.,\s-]/;

export const hasNotNumChars = (str: string): boolean => notNumChars.test(str);

export const fixCommaDotInUserInput = (
  input: string,
  lang: AvailableLanguages,
): string => {
  if (lang === AvailableLanguages.en) return input.replace(/,/g, '.');
  return input.replace(/\./g, ',');
};

export const formatEurosNoDecimals = (n: number): string => {
  const amountFormatter = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return amountFormatter.format(parseInt((n || 0) as unknown as string));
};

export default formatMoney;
