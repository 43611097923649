import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import { useSubscriptionPlan } from 'hooks/Subscriptions';
import { VATTypeEnum, type SubscriptionPlanNameEnum } from 'types/users.types';

import {
  isSubscriptionFreePlan,
  isSubscriptionTrialPlan,
} from 'utils/subscriptions.utils';

export const useShouldRecommendSMALLBusinessPlan = (
  extraAllowedPlans?: SubscriptionPlanNameEnum[],
): boolean => {
  const { data: user } = useGetCurrentCustomerData();
  const planInternalName = useSubscriptionPlan()?.planInternalName;

  if (user?.VATType !== VATTypeEnum.franchisee) return false;

  return (
    !!isSubscriptionFreePlan(planInternalName) ||
    !!isSubscriptionTrialPlan(planInternalName) ||
    !!extraAllowedPlans?.includes(planInternalName as SubscriptionPlanNameEnum)
  );
};

export default useShouldRecommendSMALLBusinessPlan;
