import classNames from 'classnames';
import type { FC } from 'react';
import { useId } from 'react';
import type { ProgressProps } from './Progress.types';

const theme = {
  base: 'w-full overflow-hidden rounded-full bg-gray-200',
  label: 'mb-1 flex justify-between font-medium',
  bar: 'flex items-center justify-center rounded-full text-center font-medium leading-none text-blue-100',
  color: {
    dark: 'bg-gray-600',
    blue: 'bg-[#6435c9]',
    red: 'bg-red-600',
    green: 'bg-green-600',
    yellow: 'bg-yellow-400',
    primary: 'bg-primary-600',
  },
  size: {
    sm: 'h-1.5',
    md: 'h-2.5',
    lg: 'h-4',
    xl: 'h-6',
  },
};

export const Progress: FC<ProgressProps> = ({
  color = 'blue',
  label = 'progressbar',
  labelPosition = 'none',
  labelProgress = false,
  progress,
  size = 'md',
}): JSX.Element => {
  const id = useId();
  return (
    <>
      <div
        id={id}
        aria-label={label}
        aria-valuenow={progress}
        role="progressbar"
      >
        {label && labelPosition === 'outside' && (
          <div className={theme.label}>
            <span>{label}</span>
            {labelProgress && <span>{progress}%</span>}
          </div>
        )}
        <div className={classNames(theme.base, theme.size[size])}>
          <div
            className={classNames(
              theme.bar,
              theme.color[color],
              theme.size[size],
            )}
            style={{ width: `${progress}%` }}
          >
            {label && labelPosition === 'inside' && label}
          </div>
        </div>
      </div>
    </>
  );
};

export default Progress;
