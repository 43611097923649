import classNames from 'classnames';
import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import type {
  CircularButtonProps,
  WrapperButtonProps,
  WrapperLinkProps,
} from './CircularButton.types';
import { ContainerClassesEnum, IconClassesEnum } from './CircularButton.types';

const WrapperLink: FC<WrapperLinkProps & { children: ReactNode }> = ({
  to = '/',
  children,
  onClick,
}) => (
  <Link to={to} onClick={onClick}>
    {children}
  </Link>
);

const WrapperButton: FC<WrapperButtonProps & { children: ReactNode }> = ({
  onClick = () => null,
  children,
}) => (
  <button onClick={onClick} type="button">
    {children}
  </button>
);

const CircularButton: FC<CircularButtonProps> = ({
  to,
  Icon,
  active,
  type,
  onClick,
  wrapperClassName,
  iconClassName,
  renderContent,
}) => {
  const Wrapper = to ? WrapperLink : WrapperButton;

  return (
    <Wrapper to={to} onClick={onClick}>
      {renderContent?.()}
      <div
        className={classNames(
          'h-12 w-12 rounded-full',
          'flex justify-center items-center fill-current',
          active ? 'bg-opacity-10' : 'opacity-40',
          active
            ? ContainerClassesEnum[type as keyof typeof ContainerClassesEnum]
            : 'bg-transparent',
          wrapperClassName,
        )}
      >
        <Icon
          className={classNames(
            'h-7',
            iconClassName,
            active
              ? IconClassesEnum[type as keyof typeof IconClassesEnum]
              : 'text-black stroke-black fill-black',
          )}
        />
      </div>
    </Wrapper>
  );
};

export default CircularButton;
