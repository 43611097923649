import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { get, isFunction } from 'lodash';
import { useState, type FC } from 'react';
import {
  CheckCircleIcon,
  ChevronRightIcon,
  PencilIconOutline,
} from 'utils/icons';

type Item = Record<string, unknown> | ArrayItem;
type ArrayItem = (string | Element | boolean)[];

export interface ZebraTableProps {
  items: Item[];
  widthClass?: string;
  noHeader?: boolean;
  basic?: boolean;
  headerClasses?: string;
  hideChevron?: boolean;
  cellClass?: string;
  withEditIcon?: boolean;
  cells: {
    head: string | (() => JSX.Element);
    key: string;
  }[];

  onClick?: (index: number, item: Item) => void;
}
const ZebraTable: FC<ZebraTableProps> = ({
  cells,
  items,
  onClick,
  noHeader,
  widthClass = 'w-36',
  basic,
  headerClasses = '',
  hideChevron,
  cellClass = 'first:!flex-none',
  withEditIcon,
}) => {
  const Row = ({ item, index }: any) => {
    const [visible, setVisible] = useState(false);
    return (
      <motion.div
        className={classNames('relative', {
          'odd:bg-primary-25 rounded-lg': !basic,
        })}
      >
        <AnimatePresence>
          {visible && (
            <motion.div
              {...{
                initial: { opacity: 0 },
                animate: { opacity: 1, transition: { delay: 0.5 } },
              }}
              className="absolute -top-2 -right-2"
            >
              <CheckCircleIcon className=" w-4 h-4 text-green-600" />
            </motion.div>
          )}
        </AnimatePresence>
        <motion.tr
          {...{
            exit: {
              border: '1px solid green',
              transition: { duration: 1 },
            },
          }}
          onAnimationStart={() => setVisible(true)}
          onClick={(e: any) => {
            if (get(e.target, 'type') === 'checkbox') return;
            onClick?.(index, item);
          }}
          className={classNames(
            {
              'cursor-pointer': !!onClick,
              'hover:border-taxes-700': !!onClick,
            },
            'flex group justify-between p-2.5 rounded-lg',
            'text-purple-darker font-assistant my-2 tracking-wide border border-transparent transition-all',
          )}
        >
          {cells.map(({ key }, index) => (
            <td
              className={classNames(
                'first:grow first:text-left last:text-right text-sm text-center',
                cellClass,
                widthClass,
              )}
              key={`${index} ${get(item, key)}`}
            >
              {get(item, key) || '-'}
            </td>
          ))}
          {onClick && !hideChevron && (
            <td className="w-20 flex justify-end">
              <ChevronRightIcon className="w-6 h-6 text-primary-400" />
            </td>
          )}
          {withEditIcon ? (
            <div className="absolute top-1.5 right-2">
              <PencilIconOutline className="hidden group-hover:inline-block w-4 !h-4 text-primary-400" />
            </div>
          ) : null}
        </motion.tr>
      </motion.div>
    );
  };
  return (
    <table className="w-full">
      {!noHeader ? (
        <thead>
          <tr
            className={classNames(
              'flex justify-between items-center px-2.5 pb-4',
              headerClasses,
            )}
          >
            {cells.map((cell, index) => (
              <td
                className={classNames(
                  'first-letter:capitalize first:grow text-sm',
                  'text-sm text-primary-500 font-semibold',
                  'first:!text-left text-center last:!text-right',
                  widthClass,
                  cellClass,
                )}
                key={`${index}`}
              >
                {isFunction(cell.head) ? cell.head() : cell.head}
              </td>
            ))}
            {onClick && !hideChevron && <td className="w-20" />}
            {withEditIcon && <td className="w-0" />}
          </tr>
        </thead>
      ) : null}
      <tbody>
        <AnimatePresence>
          {items.map((item, index) => (
            <Row
              item={item}
              index={index}
              key={get(item, 'key', String(index))}
            />
          ))}
        </AnimatePresence>
      </tbody>
    </table>
  );
};

export default ZebraTable;
