// BELGIUM
// =======
//// Food & Drinks
export const CATEGORY_BAR_AND_RESTAURANTS = '615100';
export const CATEGORY_CANTEEN = '615200';
//// Workplace
// export const CATEGORY_RENT_PROFESSIONAL = '610000a';
// export const CATEGORY_RENT_PRIVATE = '610000b';
// -> 610000c
export const CATEGORY_RENT = '610000c';

// export const CATEGORY_WATER_ELECTRICITY_HEATING_PRO = '610100';
// export const CATEGORY_WATER_ELECTRICITY_HEATING_MIXED = '610110';
// -> 610100b
export const CATEGORY_WATER_ELECTRICITY_HEATING = '610100b';

// export const CATEGORY_MAINTENANCE_AND_DECORATION_GOOD_PRO = '612200a';
// export const CATEGORY_MAINTENANCE_AND_DECORATION_GOOD_MIXED = '612200b';
// -> 612201
export const CATEGORY_MAINTENANCE_AND_DECORATION_GOOD = '612201';

// export const CATEGORY_MAINTENANCE_AND_DECORATION_SERVICE_PRO_MORE_10 =
//   '612200c';
// export const CATEGORY_MAINTENANCE_AND_DECORATION_SERVICE_MIXED_MORE_10 =
//   '612200d';
// export const CATEGORY_MAINTENANCE_AND_DECORATION_SERVICE_PRO_LESS_10 =
//   '612200e';
// export const CATEGORY_MAINTENANCE_AND_DECORATION_SERVICE_MIXED_LESS_10 =
//   '612200f';
// -> 612202
export const CATEGORY_MAINTENANCE_AND_DECORATION_SERVICE = '612202';

//// Taxes & Insurance
export const CATEGORY_SOCIAL_CONTRIBUTIONS = '618020';
export const CATEGORY_PENSION_PLAN = '618030';
export const CATEGORY_INSURANCE_PRO = '613540';
//// Compensation
export const CATEGORY_DIRECTORS_COMPENSATION = '618000';
export const CATEGORY_DIRECTOR_RETIREMENT_AND_SURVIVAL_PENSION = '624000';
// export const CATEGORY_LUNCH_VOUCHERS = '618040';
// -> 618050, 618060

export const CATEGORY_LUNCH_VOUCHERS = '618060';
export const CATEGORY_LUNCH_VOUCHERS_ADMIN = '618050';

export const CATEGORY_OTHER_PERSONNEL_FEES = '623000';
export const CATEGORY_WORKERS_COMPENSATION = '620500';
//// Interest & bank charges
export const CATEGORY_INTEREST_AND_BANK_CHARGES = '659000';
//// Technology
// export const CATEGORY_IT_GOOD_PROFESSIONNAL = '612150';
// export const CATEGORY_IT_GOOD_MIXED = '612150a';
// -> 612150d
export const CATEGORY_IT_GOOD = '612150d';

// export const CATEGORY_IT_SERVICE_PROFESSIONAL = '612150b';
// export const CATEGORY_IT_SERVICE_MIXED = '612150c';
// -> 612150e
export const CATEGORY_IT_SERVICE = '612150e';

//// Travels
export const CATEGORY_TRAVELS_PER_DIEM_ALLOWANCE = '613330';
export const CATEGORY_TRAVELS_FLIGHTS = '613310a';
export const CATEGORY_TRAVELS_HOTELS = '613320';
export const CATEGORY_TRAVEL_BELGIAN_PUBLIC_TRANSPORTATION = '613350a';
//// Vehicle
// export const CATEGORY_VEHICLE_AUTO_FOREIGN_PARKING = '613430';
// export const CATEGORY_VEHICLE_AUTO_BELGIAN_PARKING = '613430a';
// -> 613430b
export const CATEGORY_VEHICLE_AUTO_PARKING = '613430b';

export const CATEGORY_VEHICLE_AUTO_MAINTENANCE_REPAIRS = '613410';
export const CATEGORY_VEHICLE_AUTO_FUEL = '613420';
export const CATEGORY_VEHICLE_AUTO_ELECTRICITY = '613420b';
export const CATEGORY_VEHICLE_AUTO_INSURANCE = '613530';
export const CATEGORY_VEHICLE_AUTO_LEASING_RENTAL = '613400';
export const CATEGORY_VEHICLE_AUTO_ROAD_REGISTRATION_TAXES = '640000';
export const CATEGORY_VEHICLE_AUTO_FINE = '6450a';
export const CATEGORY_VEHICLE_AUTO_LEASING_OPERATIONAL = '613455';
export const CATEGORY_VEHICLE_AUTO_LEASING_FINANCIAL_CONTRACT = '252010';
export const CATEGORY_VEHICLE_AUTO_LEASING_FINANCIAL_MONTHLY_INVOICE = '613000';
// export const CATEGORY_VEHICLE_MOTO_FOREIGN_PARKING = '613431';
// export const CATEGORY_VEHICLE_MOTO_BELGIAN_PARKING = '613431a';
// -> 613431b
export const CATEGORY_VEHICLE_MOTO_PARKING = '613431b';

export const CATEGORY_VEHICLE_MOTO_MAINTENANCE_REPAIRS = '613411';
export const CATEGORY_VEHICLE_MOTO_FUEL = '613421';
export const CATEGORY_VEHICLE_MOTO_INSURANCE = '613531';
export const CATEGORY_VEHICLE_MOTO_LEASING_RENTAL = '613401';
export const CATEGORY_VEHICLE_MOTO_ROAD_REGISTRATION_TAXES = '640001';
export const CATEGORY_VEHICLE_MOTO_FINE = '6450b';

// export const CATEGORY_VEHICLE_UTILITY_FOREIGN_PARKING = '613432';
// export const CATEGORY_VEHICLE_UTILITY_BELGIAN_PARKING = '613432a';
// -> 613432b
export const CATEGORY_VEHICLE_UTILITY_PARKING = '613432b';

export const CATEGORY_VEHICLE_UTILITY_MAINTENANCE_REPAIRS = '613412';
export const CATEGORY_VEHICLE_UTILITY_FUEL = '613422';
export const CATEGORY_VEHICLE_UTILITY_FUEL_ELECTRICITY = '613422b';
export const CATEGORY_VEHICLE_CAR_PARTS_OR_ACCESSORIES = '613425';
export const CATEGORY_VEHICLE_UTILITY_INSURANCE = '613532';
export const CATEGORY_VEHICLE_UTILITY_LEASING_RENTAL = '613402';
export const CATEGORY_VEHICLE_UTILITY_LEASING_OPERATIONAL = '613459';
export const CATEGORY_VEHICLE_UTILITY_LEASING_FINANCIAL_CONTRACT = '252014';
export const CATEGORY_VEHICLE_UTILITY_LEASING_FINANCIAL_MONTHLY_INVOICE =
  '613004';
export const CATEGORY_VEHICLE_UTILITY_PARTS = '613415';

export const CATEGORY_VEHICLE_UTILITY_ROAD_REGISTRATION_TAXES = '640002';
export const CATEGORY_VEHICLE_UTILITY_FINE = '6450c';
//// Phone & internet
// export const CATEGORY_PHONE_INTERNET_PURELY_PRO = '612100';
// export const CATEGORY_PHONE_INTERNET_MIXED = '612120';
// -> 612100
export const CATEGORY_PHONE_INTERNET = '612100';

//// Marketing
export const CATEGORY_MARKETING_BUSINESS_GIFT_ABOVE_50 = '615300a';
export const CATEGORY_MARKETING_BUSINESS_GIFT_BELOW_50 = '615300b';
export const CATEGORY_MARKETING_ADVERTISING = '615400';
export const CATEGORY_MARKETING_CLUB_MEMBERSHIP = '6130';
//// Office & administration fees
export const CATEGORY_OFFICE_ADMIN_FEES = '612400';
//// Investment
export const CATEGORY_INVESTMENT_VEHICLE_CAR = '241000';

// GERMANY
// =======
//// Food & drinks
export const CATEGORY_FOOD_DRINKS_BAR_RESTAURANTS =
  'de.food_and_drinks.bar_and_restaurants';
export const CATEGORY_FOOD_DRINKS_CANTEEN_RECEPTION =
  'de.food_and_drinks.canteen_and_reception';
//// Travel
export const CATEGORY_TRAVEL_PER_DIEM_FOR_EMPLOYEE =
  'de.travel.for_employee.per_diem';
export const CATEGORY_TRAVEL_PER_DIEM_FOR_SELF_EMPLOYED =
  'de.travel.for_self_employed.per_diem';
export const CATEGORY_TRAVEL_FOR_EMPLOYEE_PUBLIC_TRANSPORT =
  'de.travel.for_employee.public_transport';
export const CATEGORY_TRAVEL_FOR_SELF_EMPLOYED_PUBLIC_TRANSPORT =
  'de.travel.for_self_employed.public_transport';
export const CATEGORY_TRAVEL_FOR_EMPLOYEE_FLIGHTS =
  'de.travel.for_employee.flights';
export const CATEGORY_TRAVEL_FOR_EMPLOYEE_TAXI_SHORT_DISTANCE =
  'de.travel.for_employee.taxi.short_distance';
export const CATEGORY_TRAVEL_FOR_EMPLOYEE_TAXI_LONG_DISTANCE =
  'de.travel.for_employee.taxi.long_distance';
export const CATEGORY_TRAVEL_FOR_EMPLOYEE_RENTAL =
  'de.travel.for_employee.rental';
export const CATEGORY_TRAVEL_FOR_EMPLOYEE_HOTELS =
  'de.travel.for_employee.hotels';
export const CATEGORY_TRAVEL_FOR_EMPLOYEE_CAR_PRIVATE_USE =
  'de.travel.for_employee.car_private_use';
export const CATEGORY_TRAVEL_FOR_EMPLOYEE_OTHER_TRAVEL_FEES =
  'de.travel.for_employee.other_travel_fees';
export const CATEGORY_TRAVEL_FOR_SELF_EMPLOYED_FLIGHTS =
  'de.travel.for_self_employed.flights';
export const CATEGORY_TRAVEL_FOR_SELF_EMPLOYED_TAXI_SHORT_DISTANCE =
  'de.travel.for_self_employed.taxi.short_distance';
export const CATEGORY_TRAVEL_FOR_SELF_EMPLOYED_TAXI_LONG_DISTANCE =
  'de.travel.for_self_employed.taxi.long_distance';
export const CATEGORY_TRAVEL_FOR_SELF_EMPLOYED_RENTAL =
  'de.travel.for_self_employed.rental';
export const CATEGORY_TRAVEL_FOR_SELF_EMPLOYED_HOTELS =
  'de.travel.for_self_employed.hotels';
export const CATEGORY_TRAVEL_FOR_SELF_EMPLOYED_CAR_PRIVATE_USE =
  'de.travel.for_self_employed.car_private_use';
export const CATEGORY_TRAVEL_FOR_SELF_EMPLOYED_OTHER_TRAVEL_FEES =
  'de.travel.for_self_employed.other_travel_fees';
export const CATEGORY_TRAVEL_FOR_SELF_EMPLOYED_DRIVE_TO_WORK =
  'de.travel.for_self_employed.drive_to_work';
//// Technology
export const CATEGORY_TECHNOLOGY_SOFTWARE_LICENSE =
  'de.technology.software_license';
export const CATEGORY_TECHNOLOGY_HOSTING = 'de.technology.hosting';
export const CATEGORY_TECHNOLOGY_HARDWARE_BELOW_250 =
  'de.technology.hardware.below_250';
export const CATEGORY_TECHNOLOGY_HARDWARE_BETWEEN_250_AND_800 =
  'de.technology.hardware.between_250_and_800';
export const CATEGORY_TECHNOLOGY_HARDWARE_ABOVE_800 =
  'de.technology.hardware.above_800';
export const CATEGORY_TECHNOLOGY_MAINTENANCE = 'de.technology.maintenance';
//// Investements
export const CATEGORY_INVESTMENTS_LAND = 'de.investments.land';
export const CATEGORY_INVESTMENTS_CONSTRUCTION = 'de.investments.construction';
export const CATEGORY_INVESTMENTS_INSTALLATIONS =
  'de.investments.installations';
export const CATEGORY_INVESTMENTS_MACHINES_BELOW_250 =
  'de.investments.machines.below_250';
export const CATEGORY_INVESTMENTS_MACHINES_BETWEEN_250_AND_800 =
  'de.investments.machines.between_250_and_800';
export const CATEGORY_INVESTMENTS_ABOVE_800 =
  'de.investments.machines.above_800';
export const CATEGORY_INVESTMENTS_TOOLS_BELOW_250 =
  'de.investments.tools.below_250';
export const CATEGORY_INVESTMENTS_TOOLS_BETWEEN_250_AND_800 =
  'de.investments.tools.between_250_and_800';
export const CATEGORY_INVESTMENTS_TOOLS_ABOVE_800 =
  'de.investments.tools.above_800';
export const CATEGORY_INVESTMENTS_OFFICE_FURNITURE_BELOW_250 =
  'de.investments.office_furniture.below_250';
export const CATEGORY_INVESTMENTS_OFFICE_FURNITURE_BETWEEN_250_AND_800 =
  'de.investments.office_furniture.between_250_and_800';
export const CATEGORY_INVESTMENTS_OFFICE_FURNITURE_ABOVE_800 =
  'de.investments.office_furniture.above_800';
export const CATEGORY_INVESTMENTS_SHOP_FURNITURE_BELOW_250 =
  'de.investments.shop_furniture.below_250';
export const CATEGORY_INVESTMENTS_SHOP_FURNITURE_BETWEEN_250_AND_800 =
  'de.investments.shop_furniture.between_250_and_800';
export const CATEGORY_INVESTMENTS_SHOP_FURNITURE_ABOVE_800 =
  'de.investments.shop_furniture.above_800';
export const CATEGORY_INVESTMENTS_HARDWARE_BELOW_250 =
  'de.investments.hardware.below_250';
export const CATEGORY_INVESTMENTS_HARDWARE_BETWEEN_250_AND_800 =
  'de.investments.hardware.between_250_and_800';
export const CATEGORY_INVESTMENTS_HARDWARE_ABOVE_800 =
  'de.investments.hardware.above_800';
export const CATEGORY_INVESTMENTS_PURCHASE_CAR = 'de.investments.purchase.car';
export const CATEGORY_INVESTMENTS_PURCHASE_TRUCK =
  'de.investments.purchase.truck';
export const CATEGORY_INVESTMENTS_PURCHASE_OTHER =
  'de.investments.purchase.other';
//// Compensation
export const CATEGORY_COMPENSATION_PENSION_PLAN =
  'de.compensation.pension_plan';
//// Workplace
export const CATEGORY_WORKPLACE_DECORATION = 'de.workplace.decoration';
export const CATEGORY_WORKPLACE_RENOVATION = 'de.workplace.renovation';
export const CATEGORY_WORKPLACE_CLEANING = 'de.workplace.cleaning';
export const CATEGORY_WORKPLACE_RENT_EXTERNAL_OFFICE =
  'de.workplace.rent.outside';
export const CATEGORY_WORKPLACE_RENT_HOME_OFFICE_MAIN =
  'de.workplace.rent.inside_main';
export const CATEGORY_WORKPLACE_RENT_HOME_OFFICE_SECONDARY =
  'de.workplace.rent.inside_secondary';
export const CATEGORY_WORKPLACE_MAINTENANCE_EXTERNAL_OFFICE =
  'de.workplace.maintenance.outside';
export const CATEGORY_WORKPLACE_MAINTENANCE_HOME_OFFICE_MAIN =
  'de.workplace.maintenance.inside_main';
export const CATEGORY_WORKPLACE_MAINTENANCE_HOME_OFFICE_SECONDARY =
  'de.workplace.maintenance.inside_secondary';
export const CATEGORY_WORKPLACE_RENT_HOMELUMPSUM =
  'de.workplace.rent.homelumpsum';
//// Vehicle
export const CATEGORY_VEHICLE_PARKING = 'de.vehicle.parking';
export const CATEGORY_VEHICLE_FUEL = 'de.vehicle.fuel';
export const CATEGORY_VEHICLE_OTHER = 'de.vehicle.other';
export const CATEGORY_VEHICLE_INSURANCE = 'de.vehicle.insurance';
export const CATEGORY_VEHICLE_LEASING = 'de.vehicle.leasing';
export const CATEGORY_VEHICLE_ROAD_REGISTRATION_TAXES =
  'de.vehicle.road_and_registration_taxes';
export const CATEGORY_VEHICLE_REPAIRS = 'de.vehicle.repair';
export const CATEGORY_VEHICLE_RENTAL = 'de.vehicle.rental';
export const CATEGORY_VEHICLE_PURCHASE_CAR = 'de.vehicle.purchase.car';
export const CATEGORY_VEHICLE_PURCHASE_TRUCK = 'de.vehicle.purchase.truck';
export const CATEGORY_VEHICLE_PURCHASE_OTHER = 'de.vehicle.purchase.other';
//// Taxes & Insurance
export const CATEGORY_TAXES_INSURANCE_BUSINESS =
  'de.taxes_and_insurance.for_self_employed.business_insurances';
export const CATEGORY_TAXES_AND_INSURANCE_VAT_PAYMENT =
  'de.taxes_and_insurance.vat_payment';
export const CATEGORY_TAXES_AND_INSURANCE_VAT_REIMBURSEMENT =
  'de.revenue.vat_reimbursement';
export const CATEGORY_TAXES_AND_INSURANCE_PRIVATE_INSURANCE =
  'de.taxes_and_insurance.for_self_employed.private_insurances';
//// Marketing
export const CATEGORY_MARKETING_MARKETING = 'de.marketing.marketing';
export const CATEGORY_MARKETING_PROMOTIONAL_GIFTS =
  'de.marketing.promotional_gifts';
export const CATEGORY_MARKETING_PERSONAL_GIFTS_BELOW_35 =
  'de.marketing.personal_gifts_low';
export const CATEGORY_MARKETING_PERSONAL_GIFTS_ABOVE_35_PROFESSIONAL_USE =
  'de.marketing.personal_gifts_high.professional';
export const CATEGORY_MARKETING_PERSONAL_GIFTS_ABOVE_35_PRIVATE_USE =
  'de.marketing.personal_gifts_high.private';
export const CATEGORY_HEALTH_INSURANCE = 'de.compensation.health_insurance';
/// bank charges
export const CATEGORY_BANK_CHARGES =
  'de.interest_and_bank_charges.bank_charges';

export const autoMobileBE = [
  // CATEGORY_VEHICLE_AUTO_FOREIGN_PARKING,
  // CATEGORY_VEHICLE_AUTO_BELGIAN_PARKING,
  CATEGORY_VEHICLE_AUTO_PARKING,
  CATEGORY_VEHICLE_AUTO_MAINTENANCE_REPAIRS,
  CATEGORY_VEHICLE_AUTO_FUEL,
  CATEGORY_VEHICLE_AUTO_INSURANCE,
  CATEGORY_VEHICLE_AUTO_LEASING_RENTAL,
  CATEGORY_VEHICLE_AUTO_ROAD_REGISTRATION_TAXES,
  CATEGORY_VEHICLE_AUTO_FINE,
  CATEGORY_VEHICLE_CAR_PARTS_OR_ACCESSORIES,
  CATEGORY_VEHICLE_AUTO_ELECTRICITY,
  CATEGORY_VEHICLE_AUTO_LEASING_OPERATIONAL,
  CATEGORY_VEHICLE_AUTO_LEASING_FINANCIAL_CONTRACT,
  CATEGORY_VEHICLE_AUTO_LEASING_FINANCIAL_MONTHLY_INVOICE,
];

export const catesThatRequiresVehicleBE = [
  CATEGORY_VEHICLE_AUTO_PARKING,
  CATEGORY_VEHICLE_AUTO_MAINTENANCE_REPAIRS,
  CATEGORY_VEHICLE_AUTO_FUEL,
  CATEGORY_VEHICLE_AUTO_INSURANCE,
  CATEGORY_VEHICLE_AUTO_LEASING_RENTAL,
  CATEGORY_VEHICLE_AUTO_ROAD_REGISTRATION_TAXES,
  CATEGORY_INVESTMENT_VEHICLE_CAR,
  CATEGORY_VEHICLE_CAR_PARTS_OR_ACCESSORIES,
  CATEGORY_VEHICLE_AUTO_ELECTRICITY,
  CATEGORY_VEHICLE_AUTO_LEASING_OPERATIONAL,
  CATEGORY_VEHICLE_AUTO_LEASING_FINANCIAL_CONTRACT,
  CATEGORY_VEHICLE_AUTO_LEASING_FINANCIAL_MONTHLY_INVOICE,
];

export const vehiclesFinesBE = [
  CATEGORY_VEHICLE_AUTO_FINE,
  CATEGORY_VEHICLE_MOTO_FINE,
  CATEGORY_VEHICLE_UTILITY_FINE,
];

export const utilityVehiclesBE = [
  // cates.CATEGORY_VEHICLE_UTILITY_FOREIGN_PARKING,
  // cates.CATEGORY_VEHICLE_UTILITY_BELGIAN_PARKING,
  CATEGORY_VEHICLE_UTILITY_PARKING,
  CATEGORY_VEHICLE_UTILITY_MAINTENANCE_REPAIRS,
  CATEGORY_VEHICLE_UTILITY_FUEL,
  CATEGORY_VEHICLE_UTILITY_FUEL_ELECTRICITY,
  CATEGORY_VEHICLE_UTILITY_INSURANCE,
  CATEGORY_VEHICLE_UTILITY_LEASING_RENTAL,
  CATEGORY_VEHICLE_UTILITY_ROAD_REGISTRATION_TAXES,
  CATEGORY_VEHICLE_UTILITY_FINE,
  CATEGORY_VEHICLE_UTILITY_LEASING_OPERATIONAL,
  CATEGORY_VEHICLE_UTILITY_LEASING_FINANCIAL_CONTRACT,
  CATEGORY_VEHICLE_UTILITY_LEASING_FINANCIAL_MONTHLY_INVOICE,
  CATEGORY_VEHICLE_UTILITY_PARTS,
];

export const workFromHomeCategories = [
  CATEGORY_WORKPLACE_RENT_HOME_OFFICE_MAIN,
  // CATEGORY_WORKPLACE_RENT_HOME_OFFICE_SECONDARY, // handled in a seperate warning check
  CATEGORY_WORKPLACE_DECORATION,
  CATEGORY_WORKPLACE_RENOVATION,
  CATEGORY_WORKPLACE_MAINTENANCE_EXTERNAL_OFFICE,
  CATEGORY_WORKPLACE_MAINTENANCE_HOME_OFFICE_MAIN,
  CATEGORY_WORKPLACE_MAINTENANCE_HOME_OFFICE_SECONDARY,
  // CATEGORY_TAXES_AND_INSURANCE_PRIVATE_INSURANCE,
  // CATEGORY_INSURANCE_PRO,
  // CATEGORY_RENT_PROFESSIONAL,
  // CATEGORY_RENT_PRIVATE,
  CATEGORY_RENT,
  // CATEGORY_WATER_ELECTRICITY_HEATING_PRO,
  // CATEGORY_WATER_ELECTRICITY_HEATING_MIXED,
  CATEGORY_WATER_ELECTRICITY_HEATING,
  // CATEGORY_MAINTENANCE_AND_DECORATION_GOOD_PRO,
  // CATEGORY_MAINTENANCE_AND_DECORATION_GOOD_MIXED,
  CATEGORY_MAINTENANCE_AND_DECORATION_GOOD,
  // CATEGORY_MAINTENANCE_AND_DECORATION_SERVICE_PRO_MORE_10,
  // CATEGORY_MAINTENANCE_AND_DECORATION_SERVICE_MIXED_MORE_10,
  // CATEGORY_MAINTENANCE_AND_DECORATION_SERVICE_PRO_LESS_10,
  // CATEGORY_MAINTENANCE_AND_DECORATION_SERVICE_MIXED_LESS_10,
  CATEGORY_MAINTENANCE_AND_DECORATION_SERVICE,
];

// vehicles

export const SELF_EMPLOYED_CAR_PRIVATE_USE =
  'de.travel.for_self_employed.car_private_use';

export const REVENUE_CAR_PRIVATE_USE = 'de.revenue.private_usage_business_car';
// business cars

export const BUSINESS_CAR_FUEL = 'de.vehicle.business_car.fuel';
export const BUSINESS_CAR_PARKING = 'de.vehicle.business_car.parking';
export const BUSINESS_CAR_OTHER = 'de.vehicle.business_car.other';
export const BUSINESS_CAR_REPAIR = 'de.vehicle.business_car.repair';
export const BUSINESS_CAR_LEASING = 'de.vehicle.business_car.leasing';
export const BUSINESS_CAR_INSURANCE = 'de.vehicle.business_car.insurance';
export const BUSINESS_CAR_TAXES = 'de.vehicle.business_car.taxes';
export const BUSINESS_CAR_ROAD_AND_REGISTRATION_TAXES =
  'de.vehicle.business_car.road_and_registration_taxes';
export const BUSINESS_CAR_PURCHASE = 'de.vehicle.business_car.purchase';
export const BUSINESS_CAR_PURCHASE_OTHER =
  'de.vehicle.business_car.purchase.other';

export const businessCarDE = [
  BUSINESS_CAR_FUEL,
  BUSINESS_CAR_INSURANCE,
  BUSINESS_CAR_LEASING,
  BUSINESS_CAR_OTHER,
  BUSINESS_CAR_PARKING,
  BUSINESS_CAR_PURCHASE,
  BUSINESS_CAR_PURCHASE_OTHER,
  BUSINESS_CAR_REPAIR,
  BUSINESS_CAR_ROAD_AND_REGISTRATION_TAXES,
  BUSINESS_CAR_TAXES,
];
