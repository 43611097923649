import { ClientLocationEnum, IClient } from 'types/clients.types';
import { InvoiceItem, WhyZeroVATEnum } from 'types/invoice.types';
import { cleanEmptyValues } from 'utils/helpers';

export const cleanInvoiceItems = (items: Partial<InvoiceItem>[]) => {
  return cleanEmptyValues(items, ['VATRate']);
};

export const cleanInvoiceItem = (item: Partial<InvoiceItem>) => {
  return cleanEmptyValues(item, ['VATRate']);
};

// suggests a value based on the client location only
// which may be wrong if used alone, there is other factors that can affect the VAT rate other than the client location
export const suggestVATRateBasedOnClientLocation = (
  clientLocation: IClient['location'] | undefined,
  defaultRate?: number | null,
) => {
  const isClientExtraEU = clientLocation === ClientLocationEnum.extra_eu;
  const isClientIntraEU = clientLocation === ClientLocationEnum.intra_eu;

  if (isClientExtraEU || isClientIntraEU) {
    return 0;
  }
  return defaultRate ?? null;
};

// suggests a value based on the client location only
// which may be wrong if used alone, there is other factors that can affect the WhyZeroVAT reason other than the client location
export const suggestWhyZeroVATBasedOnClientLocation = (
  clientLocation: IClient['location'] | undefined,
  defaultReason?: WhyZeroVATEnum,
) => {
  const isClientExtraEU = clientLocation === ClientLocationEnum.extra_eu;
  const isClientIntraEU = clientLocation === ClientLocationEnum.intra_eu;

  if (isClientExtraEU) {
    return WhyZeroVATEnum.extra_eu_export;
  }
  if (isClientIntraEU) {
    return WhyZeroVATEnum.intra_eu_reverse_charge;
  }

  return defaultReason ?? null;
};
