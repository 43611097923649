import type {
  ABTest,
  GrowthExperimentsEnum,
} from 'types/growthExperiments.types';
import create from 'zustand';

export type GrowthExperimentsWithABGroupsType = Record<
  GrowthExperimentsEnum,
  ABTest
>;

const ABExperimentsStoreCreator = () =>
  create<{
    experiments: GrowthExperimentsWithABGroupsType;
    setExperiments: (experiments: GrowthExperimentsWithABGroupsType) => void;
  }>((set) => ({
    experiments: {} as GrowthExperimentsWithABGroupsType,
    setExperiments: (experiments: GrowthExperimentsWithABGroupsType) =>
      set({ experiments }),
  }));

export default ABExperimentsStoreCreator;
