import { Button } from 'components/atoms/Button';
import useDebounceInfiniteQuery from 'hooks/useDebounceInfiniteQuery';
import type { FC } from 'react';
import { useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Props } from 'react-select';
import { SelectOptionsCacheKeys } from 'types/cacheKeys.types';
import { FIVE_MINUTES } from 'utils/time';
import { getOptionsDict, getSelectedOptionsFromDict } from '../helpers';
import type { SelectConfig, value } from '../select.types';

const withAsyncOptionsPaginated = (SelectComponent: FC<Props>) =>
  function WithAsyncPagination(props: SelectConfig) {
    const {
      onFetch,
      refetchOnSearchChange = true,
      fetchOnEmpty = true,
      value,
      onInputChange,
      renderSelected,
      renderLabelContent,
      render,
      optionsDict: extraOptionsDict,
      isLoading,
      fixedOptions,
      mapOptions,
      cacheKey = '',
      cacheTime,
      menuIsOpen,
      isError,
      retry,
      originalInputValue,
      createdOption,
      queryRef,
      ...rest
    } = props;

    const { t } = useTranslation();

    const { name, isMulti } = props;

    const queryConfig = refetchOnSearchChange
      ? {
          inputValue: (originalInputValue ?? '')?.trim().slice(0, 70),
        }
      : {};

    const query = useDebounceInfiniteQuery({
      queryKey: [
        SelectOptionsCacheKeys.AsyncPaginated,
        cacheKey,
        name,
        queryConfig,
      ],
      queryFn: ({ pageParam = 1 }) =>
        onFetch?.(queryConfig.inputValue, pageParam),
      cacheTime: cacheTime ?? FIVE_MINUTES,
      enabled: fetchOnEmpty || !!queryConfig.inputValue,
      debounceTime: 250,
      getNextPageParam: (lastPage: any) => {
        const { pagesCount, pageCount, page } = lastPage.paging;
        if (pagesCount > page || pageCount > page) return page + 1;
        return undefined; // -> hasNextPage === false
      },
    });

    useImperativeHandle(queryRef, () => query, [query]);

    const {
      fetchNextPage,
      hasNextPage,
      data,
      isFetching,
      refetch,
      isError: isInnerError,
    } = query;

    const { extraOptions, normalOptions } = useMemo(() => {
      const extraOptions = fixedOptions ? [].concat(fixedOptions) : [];
      const normalOptions = data
        ? data.pages.flatMap((page) => page.data || [])
        : [];
      return { extraOptions, normalOptions };
    }, [data, fixedOptions]);

    const optionsDict = useMemo(
      () => ({
        ...extraOptionsDict,
        ...getOptionsDict(normalOptions.concat(extraOptions)),
      }),
      [extraOptions, normalOptions, extraOptionsDict],
    );

    const selected = useMemo(
      () =>
        getSelectedOptionsFromDict({
          value: value as value,
          optionsDict,
          isMulti,
        }),
      [value, optionsDict],
    );

    const maybeMappedOptions = useMemo(() => {
      if (mapOptions) {
        return mapOptions({
          fixedOptions: extraOptions,
          options: normalOptions,
        });
      }
      return normalOptions.concat(extraOptions);
    }, [extraOptions, normalOptions]);

    // const optionsWithCreatedOption = useMemo(() => {
    //   if (!createdOption || isFetching || isLoading) return maybeMappedOptions;
    //   const hasSimilarOption = maybeMappedOptions.some(
    //     (option: any) => option.value === createdOption.value,
    //   );
    //   if (hasSimilarOption) return maybeMappedOptions;
    //   return [createdOption].concat(maybeMappedOptions);
    // }, [createdOption, maybeMappedOptions, isFetching, isLoading]);

    return (
      <>
        {renderLabelContent?.(selected)}
        <SelectComponent
          isLoading={isFetching || isLoading}
          options={maybeMappedOptions}
          value={selected}
          menuIsOpen={isError || isInnerError ? false : menuIsOpen}
          onMenuScrollToEnd={() => {
            if (isFetching || !hasNextPage) return;
            fetchNextPage();
          }}
          onInputChange={
            onInputChange
              ? (newValue, actionMeta) => {
                  // if (isError || isInnerError) return; // don't reset user search input
                  return onInputChange(newValue, actionMeta, selected);
                }
              : undefined
          }
          // controlShouldRenderValue={!renderSelected}
          {...rest}
        />
        {(isError || isInnerError) && (
          <div className="flex flex-wrap items-center gap-2">
            <span className="text-sm text-primary-400">
              {t('backend.error.something_went_wrong')}.
            </span>
            <Button
              structure="text"
              color="primary"
              onClick={() => (isInnerError ? refetch() : retry?.())}
            >
              <span className="text-sm">{t('retry')}</span>
            </Button>
          </div>
        )}
        {/* Todo: maybe send other functions like onChange  */}
        {renderSelected?.(selected)}
        {render?.(selected)}
      </>
    );
  };

export default withAsyncOptionsPaginated;
