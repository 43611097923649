import Joi from 'joi';
import { isEmpty } from 'lodash';
import { ReviewStatusEnum, SupplierCountriesEnum } from 'types/expense.types';
import { ExpenseWhyZeroVAT } from 'types/expenses.types';
import { VATReturnFrequencyEnum, VATTypeEnum } from 'types/users.types';
import { isExpert, maximumAmortizationPeriod } from 'utils/constants';
import { formatErrorMessage } from 'validation/commonValidators';
import type useFormMeta from './useFormMeta';

const datePattern = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

const itemValidationSchema = Joi.object({
  categoryId: Joi.string()
    .required()
    .messages(formatErrorMessage('expenseItem', 'categoryId')),
  professionalPart: Joi.number().min(0.01).max(1).required().messages({
    'number.max': 'expense.validator.professionalPart.higher',
    'number.min': 'expense.validator.professionalPart.lower',
    'number.base': 'expense.validator.professionalPart.missing',
  }),
  qty: Joi.number().integer().greater(0).default(1),

  amount: Joi.when('$amount.isApplicable', {
    is: true,
    then: Joi.number().min(0.01).required().messages({
      'number.min': 'expenseItem.amount.number.min',
      '*': 'expenseItem.amount.any.required',
    }),
  }),

  whyZeroVAT: Joi.when('$whyZeroVAT.isApplicable', {
    is: true,
    then: Joi.valid(...Object.values(ExpenseWhyZeroVAT)).required(),
  }),

  VATAmount: Joi.when('$VATAmount.isApplicable', {
    is: true,
    then: Joi.number()
      .min(0)
      .custom((value, helpers) => {
        if (helpers.prefs.context?.isExceedingMaxVATRate) {
          return helpers.error('bad_vat_rate');
        }
      })
      .required()
      .messages({
        ...formatErrorMessage('expenseItem', 'VATAmount'),
        bad_vat_rate: 'expense.validator.item.vat_amount.bad_vat_rate',
      }),
  }),

  localVATRate: Joi.when('$localVATRate.isApplicable', {
    is: true,
    then: Joi.number()
      .required()
      .messages(formatErrorMessage('expenseItem', 'localVATRate')),
  }),

  name: Joi.when('isAsset', {
    is: true,
    then: Joi.string()
      .required()
      .messages({ '*': 'expenseItem.name.any.required' }),
    otherwise: Joi.allow(null),
  }),

  isAsset: Joi.when('$isAsset.isApplicable', {
    is: true,
    then: Joi.allow(true).required(),
    otherwise: Joi.allow(false, null),
  }).default(false),

  amortizationPeriod: Joi.when('$amortizationPeriod.isApplicable', {
    is: true,
    then: Joi.alternatives([
      Joi.valid(null),
      Joi.number()
        .integer()
        .custom((value, helpers) => {
          const years = value / 12;
          if (years === 0) return value;
          if (years > maximumAmortizationPeriod || years < 1) {
            return helpers.error(
              'expense.validator.item.amortization.period.incorrect_temp',
            );
          }
          return value;
        })
        .required(),
    ]).messages({
      '*': 'expense.validator.item.amortization.period.incorrect_temp',
    }),
  }),

  vehicleId: Joi.when('$vehicleId.isApplicable', {
    is: true,
    then: Joi.string()
      .required()
      .messages({ '*': 'expenseItem.vehicleId.any.required' }),
    otherwise: Joi.allow(null),
  }),

  maxDeductibleVAT: isExpert
    ? Joi.number()
        .min(0)
        .max(1)
        .required()
        .messages(formatErrorMessage('expenseItem', 'maxDeductibleVAT'))
    : Joi.any(),
  incomeTaxDeductibility: isExpert
    ? Joi.number()
        .min(0)
        .max(1.2)
        .required()
        .messages(formatErrorMessage('expenseItem', 'incomeTaxDeductibility'))
    : Joi.any(),

  // -----------

  tripType: Joi.when('$tripType.isApplicable', {
    is: true,
    then: Joi.string().required(),
  }).messages({
    '*': 'expenseItem.tripType.string.empty',
  }),
  tripVehicleType: Joi.when('$tripVehicleType.isApplicable', {
    is: true,
    then: Joi.string().required(),
  }).messages({
    '*': 'expenseItem.tripVehicleType.string.empty',
  }),
  tripTotalKm: Joi.when('$tripTotalKm.isApplicable', {
    is: true,
    then: Joi.number().required().min(1).max(9999),
  }).messages({
    'number.min': 'expenseItem.tripTotalKm.number.min',
    'number.max': 'expenseItem.tripTotalKm.number.max',
    '*': 'expenseItem.tripTotalKm.number.empty',
  }),
  tripWorkingDays: Joi.when('$tripWorkingDays.isApplicable', {
    is: true,
    then: Joi.number().integer().required().min(1).max(365),
  }).messages({
    'number.min': 'expenseItem.tripWorkingDays.number.min',
    'number.max': 'expenseItem.tripWorkingDays.number.max',
    'number.integer': 'expenseItem.tripWorkingDays.number.integer',
    '*': 'expenseItem.tripWorkingDays.number.empty',
  }),
}).required();

export const generalValidationSchema = Joi.object({
  isInvoice: Joi.boolean(),
  isCreditNote: Joi.boolean().default(false),
  expenseDate: Joi.string().trim().pattern(datePattern).required().messages({
    '*': 'expenseItem.expenseDate.string.pattern.empty',
  }),
  supplier: Joi.when('$supplier.isApplicable', {
    is: true,
    then: Joi.object({
      name: Joi.string()
        .required()
        .messages({ '*': 'expense.supplier.name.string.empty' }),
      country: Joi.valid(...Object.values(SupplierCountriesEnum))
        .required()
        .messages({ '*': 'expense.supplier.country.string.empty' }),
    }).required(),
  }),
  items: Joi.array().min(1).required(),
  cashRemainder: Joi.boolean(),
  matchCard: Joi.boolean(),
  transactions: Joi.when('$transactions.isApplicable', {
    is: true,
    then: Joi.array().items(Joi.string()).default([]),
  }),
  period: Joi.when('$period.isApplicable', {
    is: true,
    then: Joi.object({
      year: Joi.number()
        .integer()
        .max(new Date().getFullYear() + 1)
        .required()
        .messages({ 'number.max': 'invoice.periodYear.number.max' }),
      quarter: Joi.number()
        .integer()
        .min(1)
        .max(4)
        .when('/user.VATReturnFrequency', {
          is: VATReturnFrequencyEnum.quarterly,
          then: Joi.required().messages({
            '*': 'invoice.periodQuarter.any.invalid',
          }),
        }),
      month: Joi.number()
        .integer()
        .min(1)
        .max(12)
        .when('/user.VATReturnFrequency', {
          is: VATReturnFrequencyEnum.monthly,
          then: Joi.required().messages({
            '*': 'invoice.periodMonth.any.invalid',
          }),
        }),
    })
      .required()
      .messages({ '*': 'expense.period.object.empty' }),
  }),
  user: Joi.object({
    VATType: Joi.valid(...Object.values(VATTypeEnum)).required(),
    VATReturnFrequency: Joi.valid(
      ...Object.values(VATReturnFrequencyEnum),
    ).required(),
  }).required(),
  customFeesAmount: Joi.number().min(0).default(0),
  accountantReview: Joi.object({
    reviewStatus: Joi.valid(...Object.values(ReviewStatusEnum)).default(
      'not_reviewed',
    ),
    comments: Joi.string().allow(null),
  }),
  fileHash: Joi.string().allow(null),
  filePath: Joi.string().allow(null),
  files: Joi.array()
    .items(
      Joi.object({
        path: Joi.string().required(),
        hash: Joi.string().allow(null),
      }),
    )
    .default([]),
});

export const getValidationSchema = ({
  getFormMeta,
  getItemMeta,
}: Pick<
  ReturnType<typeof useFormMeta>,
  'getFormMeta' | 'getItemMeta'
>): any => {
  return {
    validateAsync: (values: any, options: Joi.AsyncValidationOptions) => {
      const formMeta = getFormMeta(values);
      return Promise.allSettled([
        generalValidationSchema.validateAsync(values, {
          ...options,
          context: {
            ...options.context,
            ...formMeta.fields,
            ...formMeta.helpers,
          },
        }),
        Promise.allSettled(
          values.items?.map((item: any) => {
            const itemMeta = getItemMeta(values, item);
            return itemValidationSchema.validateAsync(item, {
              ...options,
              context: {
                ...options.context,
                ...formMeta.fields,
                ...formMeta.helpers,
                ...itemMeta.fields,
                ...itemMeta.helpers,
              },
            });
          }) || [],
        ),
      ]).then(([general, items]) => {
        let errors = { _original: values, details: [] };

        if (general.status === 'rejected') {
          errors = {
            ...errors,
            details: errors.details.concat(general.reason.details),
          };
        }

        (
          items as PromiseFulfilledResult<PromiseSettledResult<any>[]>
        ).value.map((item, index: number) => {
          if (item.status === 'rejected') {
            errors = {
              ...errors,
              details: errors.details.concat(
                item.reason.details.map((detail: any) => ({
                  ...detail,
                  path: ['items', index].concat(detail.path),
                })),
              ),
            };
          }
        });

        if (!isEmpty(errors.details)) return Promise.reject(errors);

        return {
          ...(general as PromiseFulfilledResult<any>).value,
          items: (
            items as PromiseFulfilledResult<PromiseFulfilledResult<any>[]>
          ).value.map((item) => item.value),
        };
      });
    },
  };
};
