import type { ProgressColor } from 'components/atoms/Progress/Progress.types';
import type { FC } from 'react';
import type { color } from 'types/shared/worlds';

export enum Status {
  done = 'done',
  error = 'error',
  waiting = 'waiting',
  uploading = 'uploading',
}

export type fileState = {
  key: string;
  file: File;
  status: Status;
  progress: number;
  result: any;
  options?: Record<string, any>;
};

export interface FileUploadedItemProps {
  color?: color;
  name: string;
  status: Status;
  size: number;
  progress: number;
  className?: string;
  onRetry: () => void;
}

export interface FileUploadedListProps {
  color?: color;
  className?: string;
  onRetry: (file: File, key: string, options?: Record<string, any>) => void;
  files: fileState[];
  renderUploadedFileItem?: (props: fileState, filesList: fileState[]) => any;
}

export type FileUploadedItemMap = Record<
  Status,
  {
    Icon: FC;
    progressColor: keyof ProgressColor;
  }
>;
