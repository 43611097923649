import { getExpensesFilters } from 'api/v2/expenses';
import { adaptFilters } from 'components/pages/Expenses/filtersConfig';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useFeatures from 'hooks/useFeatures';
import _debounce from 'lodash/debounce';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import filtersStoreCreatorV2 from 'store/shared/filters/filtersStoreCreatorV2';
import { ExpensesCacheKeys } from 'types/cacheKeys.types';
import type { AvailableCountries } from 'types/users.types';
import { getTracingEvents } from 'utils/amplitude';
import { WEB_EXPENSELIST_FILTEREDLIST } from 'utils/amplitude/events/expenses.amplitude';
import { mergeFilters } from 'utils/helpers';

export const useExpensesFiltersStore = filtersStoreCreatorV2();

const tracingEvents = getTracingEvents({
  onFiltersChange: WEB_EXPENSELIST_FILTEREDLIST,
});

const logFilterChanges = _debounce(tracingEvents.onFiltersChange, 500);

const useExpensesFilters = () => {
  const country = useCustomerCountry();
  const irrelevantFilters: string[] = useFeatures('expenses.irrelevantFilters');
  const filtersConfig = useExpensesFiltersStore();

  useEffect(() => {
    return useExpensesFiltersStore.subscribe((state, prev) => {
      if (state.filtersData === prev.filtersData) return;
      logFilterChanges();
    });
  }, []);

  const cacheKey = ExpensesCacheKeys.ExpensesFilters;

  const filtersQuery = useQuery(
    cacheKey,
    () =>
      getExpensesFilters().then((filters) => {
        const newFilters = mergeFilters(
          filtersConfig.getFilters(),
          adaptFilters(
            filters.data.filter((f) => !irrelevantFilters.includes(f.key)),
            country as AvailableCountries,
          ),
        );
        filtersConfig.setFilters(newFilters);
        return newFilters;
      }),
    {
      staleTime: 0,
    },
  );

  return {
    filtersConfig,
    filtersQuery,
  };
};

export default useExpensesFilters;
