import * as Sentry from '@sentry/react';
import getSuggestedTransaction from 'api/v1/transactions/getSuggestedTransaction';
import {
  createVehicle,
  deleteVehicle,
  getVehiclesOptions,
  updateVehicle,
} from 'api/v1/vehicles';
import { getClientsOptions, getSuggestedClients } from 'api/v2/clients';
import {
  getFormCustomData,
  getLockStatus,
  getRevenuesCurrencies,
} from 'api/v2/revenues';
import classNames from 'classnames';
import { Alert } from 'components/atoms/Alert';
import { Badge } from 'components/atoms/Badge';
import { Button } from 'components/atoms/Button';
import DatePicker from 'components/atoms/DatePicker/DatePicker.component';
import {
  FakeField,
  FieldConnector,
  FieldErrorManager,
} from 'components/atoms/Form/FieldConnector';
import FreeTextInput from 'components/atoms/Form/FreeTextInput';
import { Select } from 'components/atoms/Form/Select';
import { getOptionsDict } from 'components/atoms/Form/Select/helpers';
import { Toggle } from 'components/atoms/Form/Toggle';
import { Menu } from 'components/atoms/Menu';
import UseRef from 'components/atoms/UseRef';
import UseState from 'components/atoms/UseState';
import Amount from 'components/molecules/CommonCells/Amount.component';
import { textToDateRange } from 'components/molecules/Fields/DateRangerPicker/DateRangePicker';
import NumberField from 'components/molecules/Fields/Number/Number';
import PaymentAmount from 'components/molecules/Fields/PaymentAmount/PaymentAmount.component';
import PaymentAmountHelper from 'components/molecules/Fields/PaymentAmount/PaymentAmountHelper.component';
import { PaymentLinkSelect } from 'components/molecules/Fields/PaymentLink';
import {
  revenueAlternativeTransactionsOptions,
  SelectedOptionContainer,
  TransactionOption,
  TransactionOptionPreset,
} from 'components/molecules/Fields/PaymentLink/utils';
import PeriodHelper from 'components/molecules/Fields/Period/PeriodHelper.components';
import {
  createPeriodsOptions,
  periodToValue,
} from 'components/molecules/Fields/Period/utils';
import VariantSelection from 'components/molecules/Fields/VariantSelection';
import { Helper } from 'components/molecules/Helper';
import LinkedItemsProgress from 'components/molecules/LinkedItemsProgress';
import { InvoiceSubmitLockedByTaxes } from 'components/molecules/SubmitLockByTaxes.compopnent';
import CarsSelect from 'components/organisms/CarsSelect';
import TaxImpact from 'components/organisms/TaxImpact';
import ClientForm from 'components/pages/Clients/Form';
import { FieldsArray } from 'components/pages/Expenses/Form/utils';
import dayjs from 'dayjs';
import useHasTransactions from 'hooks/Bank/useHasTransactions';
import useGetRevenuesVATRates from 'hooks/Revenues/useGetRevenuesVATRates';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useDebounceQuery from 'hooks/useDebounceQuery';
import useFeatures from 'hooks/useFeatures';
import useHighlighTaxCheckField from 'hooks/useHighlightTaxCheckField';
import { groupBy, isEmpty, last, omit } from 'lodash';
import type { FC, MouseEventHandler, ReactNode } from 'react';
import { useEffect, useId, useMemo } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import { useQueryClient } from 'react-query';
import { AlertIdsEnum } from 'types/AlertIdsEnum';
import {
  RevenuesCacheKeys,
  SelectOptionsCacheKeys,
} from 'types/cacheKeys.types';
import { ReviewStatusEnum } from 'types/expense.types';
import type {
  LockStatusTypeCatgoriesed,
  VATReturnFrequency,
} from 'types/global.types';
import { CurrenciesEnum, Section } from 'types/global.types';
import {
  InvoiceItem,
  InvoiceStatusEnum,
  type Invoice,
} from 'types/invoice.types';
import type { IRevenue } from 'types/revenues.types';
import { RevenueStatus } from 'types/revenues.types';
import { TaxImpactDocumentType } from 'types/taxes.types';
import { AvailableCountries, VATTypeEnum } from 'types/users.types';
import {
  VehicleFuelTypeEnum,
  type DEVehicle,
  type VehiclesOption,
} from 'types/vehicle.types';
import { getTracingEvents } from 'utils/amplitude';
import * as expensesEvents from 'utils/amplitude/events/expenses.amplitude';
import * as RevenuesEvents from 'utils/amplitude/events/revenues.amplitude';
import { viewSuggestedChanges } from 'utils/applySuggestedChanges';
import { REVENUE_CAR_PRIVATE_USE } from 'utils/categories';
import { isExpert, isWeb } from 'utils/constants';
import { calculateVATAmount } from 'utils/expenses/helper';
import formatMoney from 'utils/formatMoney';
import { createSentryCustomErrorMsg } from 'utils/helpers';
import {
  ChevronDownIconOutline,
  ChevronUpIconOutline,
  LockClosedIconOutline,
  MinusIcon,
  PlusIcon,
} from 'utils/icons';
import {
  divideBy1000,
  evaluateExchangeRateFromAmounts,
  multiplyBy1000,
  round,
} from 'utils/math';
import { ONE_SECOND } from 'utils/time';
import transactionToOption from 'utils/transactionToOption';
import periodBasedOnDate from 'utils/updatePeriodBasedOnDate';
import { CategoriesPickerControlled } from './Fields/CategoriesPicker';
import CustomPaymentAmount from './Fields/CustomPaymentAmount';
import GermanCarsFormControlled from './Fields/GermanCarsFormControlled';
import RevenueNumber from './Fields/RevenueNumber';
import useRevenuesCategoriesPicker from './Fields/useRevenuesCategoriesPicker';
import WhyZeroVAT from './Fields/WhyZeroVAT';
import RevenueVATCells from './RevenueVATCells.component';
import useFormMeta from './useFormMeta';
import {
  encodeGermanCarsData,
  formatGermanCarsData,
  getInvoiceItemsTotalExclVAT,
  getInvoiceItemsTotalInclVAT,
  getLockStatusBody,
  getRevenueLinkedPaymentsAmount,
  getRevenueLinkedPaymentsOppositeDirectionTotal,
  itemDefaults,
  removeInvalidVATRateGroups,
} from './utils';
import {
  germanCarsCustomSchema,
  userDataValidationSchema,
} from './validationSchema';

type FormProps = {
  formAPI: UseFormReturn;
  revenue: Partial<Invoice>;
  generatedDefaultValues: Partial<Invoice>;
  isFormDirty?: boolean;
  onSubmit: (values: Partial<Invoice>) => any;
  headerMeta?: ReactNode;
  footerMeta?: ReactNode;
  disabled?: boolean;
  disabledFields?: string[] | '*';
  notDisabledFields?: string[];
  isLocked?: boolean;
  shouldUnitPriceIncludeVATByDefault?: boolean;
  onEditVehicles?: () => void;
};

const markdownComponents: ReactMarkdownOptions['components'] = {
  ul: ({ children }) => <ul className="list-disc list-inside">{children}</ul>,
};

const tracingEvents = getTracingEvents({
  onAddGermanCar: expensesEvents.WEBAPP_VEHICLE_ADDEDGERMANCAR,
  onEditGermanCar: expensesEvents.WEBAPP_VEHICLE_EDITEDGERMANCAR,
  onDeleteGermanCar: expensesEvents.WEBAPP_VEHICLE_DELETEDGERMANCAR,
  onChangeCategory: {
    name: RevenuesEvents.WEBAPP_REVENUE_UPDATEDCATEGORY,
    data: (id: string) => ({ id }),
  },
});

const Form: FC<FormProps> = ({
  formAPI,
  revenue,
  generatedDefaultValues,
  headerMeta,
  footerMeta,
  onSubmit,
  disabled,
  disabledFields,
  notDisabledFields,
  isLocked,
  isFormDirty,
  onEditVehicles,
  ...rest
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const customerCountry = useCustomerCountry() as AvailableCountries;

  const {
    errors: formErrors,
    dirtyFields,
    isSubmitting,
    isValid,
  } = formAPI.formState;
  const formValues = formAPI.watch() as any;

  console.log(formErrors);

  const hasErrors = !isValid;

  const itemsController = useFieldArray({
    name: 'items',
    control: formAPI.control,
  });

  const priorityDate = useFeatures('period.priority_date')({
    documentDate: formValues.invoiceDate,
    paymentDate: formValues.paymentDate,
    section: Section.revenues,
    type: formValues.type,
  });

  const priorityDateKey = useFeatures('period.priority_date_key')({
    section: Section.revenues,
    type: formValues.type,
  });

  const { getFormMeta, getItemMeta, getSuggestedChanges } = useFormMeta();

  const formMeta = getFormMeta(formValues);

  useEffect(() => {
    viewSuggestedChanges(
      formValues,
      getSuggestedChanges(formValues),
      formAPI.setValue,
      true,
    );
  }, [formValues]);

  const defaultVATRate = useFeatures('revenue.default_vat_rate');

  const VATRatesAPI = useGetRevenuesVATRates(formValues.invoiceDate);

  const VATRates = VATRatesAPI.rates.map((value: number) => ({
    label: `${(value * 100).toFixed()}%`,
    value,
  }));

  const documentVATReturnFrequency = formAPI.getValues(
    'user.VATReturnFrequency',
  ) as VATReturnFrequency;

  const allItems = (formValues.items || []) as InvoiceItem[];

  const totalAmountExclVAT = getInvoiceItemsTotalExclVAT(formValues.items);

  const totalAmountInclVAT = getInvoiceItemsTotalInclVAT(formValues.items);

  const presetsOptions = useMemo(
    () =>
      revenueAlternativeTransactionsOptions.map((option) => ({
        ...option,
        label: t(option.label),
      })),
    [t],
  );

  const periodOptions = useMemo(
    () => createPeriodsOptions(priorityDate, documentVATReturnFrequency),
    [priorityDate, documentVATReturnFrequency],
  );

  const updatePeriodBasedOnDate = (date: string | null) => {
    const result = periodBasedOnDate(date, documentVATReturnFrequency);

    formAPI.setValue('period', result);
  };

  const horizontalFieldLabelClass = formMeta.helpers.isGenerated
    ? ''
    : formMeta.fields.paymentAmount.isHidden
    ? 'w-[120px]'
    : 'w-[160px]';

  useHighlighTaxCheckField(revenue as Partial<IRevenue>);

  const isReviewed = formValues.accountantReview?.reviewStatus === 'reviewed';

  const hasTransactions = !!useHasTransactions().data;

  const { customType, customData, user } = formValues;
  const customTypeData = { customType, customData, user };

  const germanCarsQueryEnabled =
    !isLocked && formMeta.helpers.isGermanBusinessCar;

  const germanCarsEncodedFormData = germanCarsQueryEnabled
    ? encodeGermanCarsData(customTypeData)
    : null;

  const germanCarComputedItemsAPI = useDebounceQuery({
    queryKey: [
      RevenuesCacheKeys.GermanCarComputedItems,
      useId(),
      germanCarsEncodedFormData,
    ],
    queryFn: () => {
      const encodedDefaultData = encodeGermanCarsData(generatedDefaultValues);

      if (germanCarsEncodedFormData != null) {
        if (germanCarsEncodedFormData === encodedDefaultData) {
          return Promise.resolve(generatedDefaultValues.items || []);
        }
      }

      const formatedCustomData = formatGermanCarsData(customTypeData);

      const result = germanCarsCustomSchema
        .concat(userDataValidationSchema)
        .validate(
          { ...formatedCustomData.customData, ...formatedCustomData.user },
          {
            allowUnknown: true,
          },
        );

      if (result.error) return Promise.resolve([]);
      return getFormCustomData(formatedCustomData)
        .then(({ data }) => data.items)
        .catch(() => []);
    },
    debounceTime: ONE_SECOND * 0.5,
    enabled: germanCarsQueryEnabled,
    cacheTime: 0,
    onSuccess: (items) => {
      formAPI.setValue('items', items);
    },
  });

  const shouldUnitPriceIncludeVATByDefault =
    rest.shouldUnitPriceIncludeVATByDefault ||
    formMeta.helpers.shouldUnitPriceIncludeVATByDefault;

  const lockStatusParams = getLockStatusBody(formValues);
  const lockByTaxesStatusAPI = useDebounceQuery<LockStatusTypeCatgoriesed>({
    queryKey: [RevenuesCacheKeys.TaxLock, useId(), !isLocked, lockStatusParams],
    queryFn: () => getLockStatus(lockStatusParams, customerCountry),
    cacheTime: 0,
    staleTime: 0,
    debounceTime: ONE_SECOND * 0.5,
    enabled: !isLocked,
  });

  const isFieldDisabled = useMemo(() => {
    const disabled: Record<string, boolean> = {};
    const notDisabled: Record<string, boolean> = {};
    if (Array.isArray(disabledFields)) {
      disabledFields.forEach((key) => (disabled[key] = true));
    }
    notDisabledFields?.forEach((key) => (notDisabled[key] = true));

    return (key: string) => {
      if (notDisabled[key]) return false;
      if (disabled[key] || disabledFields === '*') return true;
      return false;
    };
  }, [disabledFields, notDisabledFields]);

  const statusDisabledOptions: Record<string, boolean | undefined> =
    useFeatures('revenue.getStatusDisabledOptions')(isLocked);

  const renderTransactions = () =>
    formMeta.fields.transactions.isHidden ? null : (
      <FieldConnector
        name="transactions"
        disabled={(name) => isFieldDisabled(name)}
        label={
          hasTransactions
            ? t('revenue.link_payment.with_transactions')
            : t('revenue.link_payment.without_transactions')
        }
      >
        {({ field: { onChange, ...field } }) => {
          const fieldOnChange = (selected: any) => {
            const options = selected as any[];
            const latestOption = last(options);
            if (latestOption?.preset) {
              formAPI.setValue('paymentType', latestOption.value, {
                shouldDirty: true,
              });
              formAPI.setValue('status', RevenueStatus.paid, {
                shouldDirty: true,
              });
              return onChange([]);
            }
            formAPI.setValue('paymentType', null, { shouldDirty: true });
            if (isEmpty(options)) return onChange([]);
            onChange(options.map(({ value }) => value));
            formAPI.setValue('status', RevenueStatus.paid, {
              shouldDirty: true,
            });
            const { paymentDate } = getFormMeta(
              formAPI.getValues() as any,
            ).fields;
            if (paymentDate.isApplicable && paymentDate.value == null) {
              if (latestOption?.transaction?.valueDate) {
                formAPI.setValue(
                  paymentDate.name,
                  dayjs(latestOption.transaction.valueDate).format(
                    'YYYY-MM-DD',
                  ),
                  { shouldDirty: true },
                );
              }
            }
          };

          const suggestedTransactionParams = {
            amount:
              totalAmountInclVAT * (formMeta.helpers.isCreditNote ? -1 : 1),
            date: formValues.invoiceDate,
            currency: formValues.currency,
            communication:
              revenue.communication || revenue.guessedCommunication,
            documentNumber: formValues.revenueNumber,
            name: formValues.client?.name,
          };
          return (
            <PaymentLinkSelect
              tracing={{
                onMenuOpen: RevenuesEvents.WEBAPP_REVENUE_CLICKEDLINKPAYMENT,
                onChange: RevenuesEvents.WEBAPP_REVENUE_UPDATEDLINKPAYMENT,
              }}
              {...field}
              optionsDict={getOptionsDict(
                revenue?._matchedTransactions?.map(transactionToOption),
              )}
              onChange={fieldOnChange}
              isDisabled={field.disabled}
              fixedOptions={presetsOptions}
              suggestedTransactionOptions={{
                queryFn: () =>
                  getSuggestedTransaction(suggestedTransactionParams),
                queryKey: [
                  RevenuesCacheKeys.RevenuesSuggestedTransaction,
                  suggestedTransactionParams,
                ],
                enabled: !!formValues.invoiceDate,
              }}
              renderLabelContent={(selected) => {
                if (!hasTransactions) return null;
                const selectedPreset = presetsOptions.find(
                  (option) => formValues.paymentType === option.value,
                );
                if (!selected && !selectedPreset) return null;
                return (
                  <div className="mt-3">
                    {selectedPreset?.value === 'otherBank' ||
                    selectedPreset?.value === 'cash' ? (
                      <LinkedItemsProgress
                        type="revenues"
                        total={100}
                        matchedAmount={100}
                      />
                    ) : (
                      <LinkedItemsProgress
                        type="revenues"
                        total={
                          (formValues.currency != null &&
                          formValues.currency !== CurrenciesEnum.EUR
                            ? (formValues.paymentAmount || 0) / 1000
                            : totalAmountInclVAT) +
                          getRevenueLinkedPaymentsOppositeDirectionTotal(
                            selected,
                            formMeta.helpers.isCreditNote,
                          )
                        }
                        matchedAmount={getRevenueLinkedPaymentsAmount(
                          selected,
                          formMeta.helpers.isCreditNote,
                        )}
                      />
                    )}
                  </div>
                );
              }}
              renderSelected={(selected) => {
                const selectedPreset = presetsOptions.find(
                  (option) => formValues.paymentType === option.value,
                );
                if (!selected && !selectedPreset) return null;
                return (
                  <div className="flex flex-col gap-2 mt-2">
                    {selectedPreset && (
                      <SelectedOptionContainer
                        className={formMeta.helpers.isGenerated ? 'border' : ''}
                        isDisabled={field.disabled}
                        onDelete={() => {
                          formAPI.setValue('paymentType', null, {
                            shouldDirty: true,
                          });
                        }}
                        selectedOptionUI={
                          <TransactionOptionPreset
                            className="flex-1"
                            label={selectedPreset.label}
                          />
                        }
                      />
                    )}
                    {selected?.map((option: any) => (
                      <SelectedOptionContainer
                        key={option.value}
                        className={formMeta.helpers.isGenerated ? 'border' : ''}
                        isDisabled={field.disabled}
                        selectedOptionUI={
                          <TransactionOption
                            className="flex-1"
                            transaction={option.transaction}
                          />
                        }
                        onDelete={() =>
                          fieldOnChange(
                            selected.filter(
                              (selectedOption: any) =>
                                selectedOption.value !== option.value,
                            ),
                          )
                        }
                      />
                    ))}
                  </div>
                );
              }}
            />
          );
        }}
      </FieldConnector>
    );

  const renderPeriod = () => (
    <>
      {!formMeta.fields.period.isHidden && (
        <>
          <FieldConnector
            name="period"
            disabled={isFieldDisabled}
            onShow={() => {
              if (formValues.period != null) return;
              updatePeriodBasedOnDate(priorityDate);
            }}
            errorKeys={[
              'period',
              'period.year',
              'period.month',
              'period.quarter',
            ]}
            label={t('fields.vat_declaration.label')}
            helper={<PeriodHelper />}
            loading={lockByTaxesStatusAPI.isLoading}
          >
            {({ field }) => (
              <Select
                tracing={{
                  onMenuOpen: RevenuesEvents.WEBAPP_REVENUE_CLICKEDPERIOD,
                  onChange: RevenuesEvents.WEBAPP_REVENUE_UPDATEDPERIOD,
                }}
                menuPlacement="top"
                options={periodOptions}
                formatOptionLabel={(option, meta) => {
                  if (meta.context !== 'menu') return option.label;
                  return (
                    <div className="flex items-center justify-between">
                      <span>{option.label}</span>

                      {option.isLocked && (
                        <LockClosedIconOutline className="w-5 h-5 text-primary-400" />
                      )}
                    </div>
                  );
                }}
                placeholder=""
                {...field}
                isDisabled={field.disabled}
                value={
                  field.value
                    ? periodToValue(field.value, documentVATReturnFrequency)
                    : null
                }
                onChange={(option: any) => field.onChange(option.period)}
              />
            )}
          </FieldConnector>
        </>
      )}
      {!!lockByTaxesStatusAPI.data?.period && (
        <InvoiceSubmitLockedByTaxes
          type={formValues.type!}
          fieldSlug={'period'}
          taxLockReasons={lockByTaxesStatusAPI.data.period}
        />
      )}
    </>
  );

  const isCarPrivateUseCategoryDisabled =
    !formMeta.helpers.isGermanBusinessCar && allItems.length > 1;

  const pickerController = useRevenuesCategoriesPicker({
    defaultTaxPeriod: revenue.taxPeriod,
    type: formValues.type,
    taxPeriod: formValues.taxPeriod,
    helpers: {
      [REVENUE_CAR_PRIVATE_USE]: (
        <Helper>
          <Helper.Title>
            {t('de.revenue.private_usage_business_car')}
          </Helper.Title>
          <Helper.Paragraph>
            {t('de.revenue.private_usage_business_car.helper')}
          </Helper.Paragraph>
        </Helper>
      ),
    },
    disabledCategories: {
      [REVENUE_CAR_PRIVATE_USE]: isCarPrivateUseCategoryDisabled,
    },
    tooltips: {
      [REVENUE_CAR_PRIVATE_USE]: isCarPrivateUseCategoryDisabled
        ? t('one_item_categories.tooltip')
        : null,
    },
    tracing: {
      onOpen: RevenuesEvents.WEBAPP_REVENUE_CLICKEDCATEGORY,
      // onChange: handled in itemsCategoryPickerOnChange
    },
  });

  const itemsCategoryPickerOnChange = (
    itemIndex: number,
    category: any,
    taxPeriod: any,
  ) => {
    if (taxPeriod) {
      formAPI.setValue('taxPeriod', taxPeriod);
    }

    if (category.customType) {
      formAPI.setValue('customType', category.customType);
      formAPI.setValue('customData.customCategory', category);
      formAPI.setValue('customData.customCategoryId', category.id, {
        shouldValidate: true,
      });
      itemsController.remove();
      return tracingEvents.onChangeCategory(category.id);
    }

    formAPI.setValue('items.' + itemIndex + '.categoryId', category.id, {
      shouldValidate: true,
      shouldDirty: true,
    });

    formAPI.setValue('items.' + itemIndex + '.category', category);
    tracingEvents.onChangeCategory(category.id);
  };

  const currencyRate = formValues.paymentAmount
    ? +evaluateExchangeRateFromAmounts(
        multiplyBy1000(totalAmountInclVAT),
        formValues.paymentAmount,
      )
    : 1;

  return (
    <FormProvider {...formAPI}>
      {pickerController.api.content}
      <form className="relative pb-20">
        <div className="flex flex-col gap-4 px-6 pb-8 bg-[#FCFBFE] border-b border-primary-100">
          {headerMeta}

          {!formMeta.fields.status.isHidden && (
            <FieldConnector
              name="status"
              disabled={isFieldDisabled}
              label={t('status')}
            >
              {({ field }) => (
                <div className="flex gap-2">
                  <VariantSelection
                    tracing={{
                      onChange: RevenuesEvents.WEBAPP_REVENUE_UPDATEDSTATUS,
                    }}
                    isDisabled={field.disabled}
                    value={formValues.status}
                    onChange={field.onChange}
                    options={[
                      {
                        label: t('invoice.field.not-sent'),
                        value: InvoiceStatusEnum.notSent,
                        isDisabled:
                          statusDisabledOptions[InvoiceStatusEnum.notSent],
                      },
                      {
                        label: t('invoice.field.sent'),
                        value: InvoiceStatusEnum.sent,
                        isDisabled:
                          statusDisabledOptions[InvoiceStatusEnum.sent],
                      },
                      {
                        label: t('invoice.field.paid'),
                        value: InvoiceStatusEnum.paid,
                        isDisabled:
                          statusDisabledOptions[InvoiceStatusEnum.paid],
                      },
                    ]}
                  />
                </div>
              )}
            </FieldConnector>
          )}

          {!formMeta.fields.currency.isHidden && (
            <FieldConnector
              horizontal={!formMeta.helpers.isGenerated}
              labelClassName={horizontalFieldLabelClass}
              name="currency"
              disabled={(name) =>
                isFieldDisabled(name) || formMeta.fields.currency.isDisabled
              }
              label={t('invoice.field.currency')}
            >
              {({ field: { onChange, ...rest } }) => (
                <Select
                  tracing={{
                    onMenuOpen: RevenuesEvents.WEBAPP_REVENUE_CLICKEDCURRENCY,
                    onChange: RevenuesEvents.WEBAPP_REVENUE_UPDATEDCURRENCY,
                  }}
                  mode="asyncOptions"
                  isDisabled={rest.disabled}
                  isSearchable
                  placeholder={t('invoice.field.select')}
                  onFetch={() => getRevenuesCurrencies()}
                  onChange={(option: any) => onChange(option.value)}
                  afterFetch={(currencies) =>
                    Object.keys(currencies).map((currency) => ({
                      label: t(`currency.${currency}`),
                      value: currency,
                    }))
                  }
                  {...rest}
                />
              )}
            </FieldConnector>
          )}

          {!formMeta.fields.paymentAmount.isHidden &&
            (formMeta.helpers.isGenerated ? (
              <FieldConnector
                labelClassName={horizontalFieldLabelClass}
                labelWrapperClassName={horizontalFieldLabelClass}
                name="paymentAmount"
                disabled
                helper={<PaymentAmountHelper />}
                label={`${t('invoice.field.paymentAmount')} (${t(
                  'invoice.form.exchange_rate',
                )} ${currencyRate})`}
              >
                {({ field: { value, onChange, ...field } }) => (
                  <PaymentAmount
                    disabled
                    value={value == null ? null : divideBy1000(value)}
                    onChange={() => null}
                  />
                )}
              </FieldConnector>
            ) : (
              <FieldConnector
                horizontal
                labelClassName={horizontalFieldLabelClass}
                labelWrapperClassName={horizontalFieldLabelClass}
                name="paymentAmount"
                disabled={(name) =>
                  isFieldDisabled(name) ||
                  formMeta.fields.paymentAmount.isDisabled
                }
                helper={<PaymentAmountHelper />}
                label={`${t('invoice.field.paymentAmount')}`}
                subLabel={`${
                  formValues.paymentAmount
                    ? `(${t(
                        'invoice.form.exchange_rate',
                      )} ${evaluateExchangeRateFromAmounts(
                        multiplyBy1000(totalAmountInclVAT),
                        formValues.paymentAmount,
                      )})`
                    : ''
                }`}
              >
                {({ field: { value, onChange, ...field } }) => (
                  <CustomPaymentAmount
                    tracing={{
                      onFocus:
                        RevenuesEvents.WEBAPP_REVENUE_CLICKEDPAYMENTAMOUNT,
                    }}
                    {...field}
                    value={value == null ? null : divideBy1000(value)}
                    onChange={(v) =>
                      onChange(v == null ? null : multiplyBy1000(v))
                    }
                    onNewCurrencyRate={(v) =>
                      formAPI.setValue(field.name, multiplyBy1000(v), {
                        shouldValidate: true,
                      })
                    }
                    params={{
                      clientId: formValues.clientId,
                      items:
                        totalAmountExclVAT != null
                          ? [
                              {
                                quantity: 1000,
                                unitAmountExclVAT:
                                  multiplyBy1000(totalAmountExclVAT),
                              },
                            ]
                          : [],
                      invoiceDate: formValues.invoiceDate,
                      type: formValues.type,
                      options: { currency: formValues.currency },
                    }}
                  />
                )}
              </FieldConnector>
            ))}

          {!formMeta.fields.revenueNumber.isHidden && (
            <FieldConnector
              horizontal={!formMeta.helpers.isGenerated}
              labelClassName={horizontalFieldLabelClass}
              name="revenueNumber"
              disabled={isFieldDisabled}
              label={t('invoices.cells.invoice_number')}
            >
              {({ field }) => (
                <RevenueNumber
                  tracing={{
                    onFocus: RevenuesEvents.WEBAPP_REVENUE_CLICKEDREVENUENUMBER,
                  }}
                  type={formValues.type}
                  disabled={field.disabled}
                  onNextNumber={(value: string) =>
                    formAPI.setValue('revenueNumber', value)
                  }
                  onValidNumber={() => {
                    if (formErrors['revenueNumber']?.type === 'custom-async')
                      formAPI.clearErrors(field.name);
                  }}
                  onInvalidNumber={() => {
                    if (!formErrors['revenueNumber'])
                      formAPI.setError(field.name, {
                        type: 'custom-async',
                        message: 'invoice.revenueNumber.already_exists',
                      });
                  }}
                  {...field}
                />
              )}
            </FieldConnector>
          )}

          {!formMeta.fields.invoiceDate.isHidden && (
            <>
              <FieldConnector
                horizontal={!formMeta.helpers.isGenerated}
                labelClassName={horizontalFieldLabelClass}
                name="invoiceDate"
                disabled={isFieldDisabled}
                label={t('invoice.field.issued')}
                loading={lockByTaxesStatusAPI.isLoading}
              >
                {({ field: { value, onChange, ...field } }) => (
                  <DatePicker
                    tracing={{
                      onCalendarOpen:
                        RevenuesEvents.WEBAPP_REVENUE_CLICKEDINVOICEDATE,
                      onChange:
                        RevenuesEvents.WEBAPP_REVENUE_UPDATEDINVOICEDATE,
                    }}
                    {...field}
                    selected={value}
                    onChange={(value) => {
                      const date = value
                        ? dayjs(value).format('YYYY-MM-DD')
                        : null;
                      onChange(date);
                      if (
                        priorityDateKey === field.name &&
                        !formMeta.fields.period.isHidden
                      ) {
                        updatePeriodBasedOnDate(date);
                      }
                      if (
                        !formMeta.fields.deliveryDate.isHidden &&
                        !dirtyFields['deliveryDate']
                      ) {
                        formAPI.setValue('deliveryDate', date);
                      }
                    }}
                  />
                )}
              </FieldConnector>
            </>
          )}

          {customerCountry === AvailableCountries.belgium &&
            !!lockByTaxesStatusAPI.data?.date && (
              <InvoiceSubmitLockedByTaxes
                type={formValues.type}
                fieldSlug={'invoice_date'}
                taxLockReasons={lockByTaxesStatusAPI.data.date}
              />
            )}

          {formMeta.helpers.shouldShowInvoiceDateWarning && (
            <Alert
              type="info"
              name="invoice date in the future"
              showIntercomBtn
              description={t('warning_bubble.document_date_in_the_future')}
            />
          )}

          {!formMeta.fields.dueDate.isHidden && (
            <FieldConnector
              horizontal={!formMeta.helpers.isGenerated}
              labelClassName={horizontalFieldLabelClass}
              name="dueDate"
              disabled={isFieldDisabled}
              label={t('invoice.field.due_date')}
            >
              {({ field: { value, onChange, ...field } }) => (
                <DatePicker
                  tracing={{
                    onCalendarOpen:
                      RevenuesEvents.WEBAPP_REVENUE_CLICKEDDUEDATE,
                    onChange: RevenuesEvents.WEBAPP_REVENUE_UPDATEDDUEDATE,
                  }}
                  {...field}
                  filterDate={(date: Date) =>
                    formValues.invoiceDate
                      ? !dayjs(date).isBefore(dayjs(formValues.invoiceDate))
                      : true
                  }
                  selected={value}
                  onChange={(value) => {
                    onChange(value ? dayjs(value).format('YYYY-MM-DD') : null);
                  }}
                />
              )}
            </FieldConnector>
          )}

          {(!formMeta.fields.deliveryDate.isHidden ||
            !formMeta.fields.deliveryPeriod.isHidden) && (
            <FakeField
              horizontal
              labelClassName={horizontalFieldLabelClass}
              label={
                <Menu
                  buttonProps={{
                    className: 'flex',
                    disabled:
                      isFieldDisabled('deliveryPeriod') &&
                      isFieldDisabled('deliveryDate'),
                  }}
                  contentWrapperClassName="!w-max"
                  trigger={(api) => (
                    <Button asDefault className="flex items-center gap-0.5">
                      {formValues.showDeliveryPeriod
                        ? t('invoice.field.delivery_period')
                        : t('invoice.field.delivery_date')}
                      {api.open ? (
                        <ChevronUpIconOutline className="w-4 h-4" />
                      ) : (
                        <ChevronDownIconOutline className="w-4 h-4" />
                      )}
                    </Button>
                  )}
                >
                  <Menu.Group>
                    <Menu.Button
                      onClick={() => {
                        if (!formAPI.getValues('showDeliveryPeriod')) return;
                        formAPI.setValue('showDeliveryPeriod', false);
                        formAPI.setValue(
                          'deliveryDate',
                          formValues['invoiceDate'] || null,
                        );
                        formAPI.setValue('deliveryPeriod', null);
                      }}
                    >
                      {t('invoice.field.delivery_date')}
                    </Menu.Button>
                    <Menu.Button
                      onClick={() => {
                        if (formAPI.getValues('showDeliveryPeriod')) return;
                        formAPI.setValue('showDeliveryPeriod', true);
                        formAPI.setValue('deliveryDate', null);
                        formAPI.setValue('deliveryPeriod', null);
                      }}
                    >
                      {t('invoice.field.delivery_period')}
                    </Menu.Button>
                  </Menu.Group>
                </Menu>
              }
            >
              {() => {
                if (!formMeta.fields.deliveryPeriod.isHidden) {
                  return (
                    <>
                      <FieldConnector
                        key="deliveryPeriod"
                        name="deliveryPeriod"
                        forceHideError
                        disabled={isFieldDisabled}
                      >
                        {({ field: { value, onChange, ...field } }) => (
                          <DatePicker
                            {...field}
                            selectsRange={true}
                            startDate={value?.start}
                            endDate={value?.end}
                            onChange={(value: any) => {
                              const range = value as [Date | null, Date | null];
                              if (!range[0] && !range[1]) return onChange(null);
                              onChange({
                                start: range[0]
                                  ? dayjs(range[0]).format('YYYY-MM-DD')
                                  : null,
                                end: range[1]
                                  ? dayjs(range[1]).format('YYYY-MM-DD')
                                  : null,
                              });
                            }}
                            onChangeRaw={(event) => {
                              const inputValue = event.currentTarget.value;
                              if (!inputValue) return;
                              const range = textToDateRange(inputValue);
                              if (!range) return onChange(null);
                              onChange({
                                start: dayjs(range[0]).format('YYYY-MM-DD'),
                                end: dayjs(range[1]).format('YYYY-MM-DD'),
                              });
                            }}
                          />
                        )}
                      </FieldConnector>
                      <FieldErrorManager
                        key="deliveryPeriod-error"
                        name="deliveryPeriod"
                        className="col-start-2 mt-1"
                      />
                    </>
                  );
                }
                return (
                  <>
                    <FieldConnector
                      key="deliveryDate"
                      name="deliveryDate"
                      forceHideError
                      disabled={isFieldDisabled}
                      onShow={(name) => {
                        if (!formValues['invoiceDate']) return;
                        if (formValues[name] == null) {
                          formAPI.setValue(name, formValues['invoiceDate'], {
                            shouldValidate: true,
                          });
                        }
                      }}
                    >
                      {({ field: { value, onChange, ...field } }) => (
                        <DatePicker
                          tracing={{
                            onCalendarOpen:
                              RevenuesEvents.WEBAPP_REVENUE_CLICKEDDELIVERYDATE,
                            onChange:
                              RevenuesEvents.WEBAPP_REVENUE_UPDATEDDELIVERYDATE,
                          }}
                          {...field}
                          selected={value}
                          onChange={(value) => {
                            onChange(
                              value ? dayjs(value).format('YYYY-MM-DD') : null,
                            );
                          }}
                        />
                      )}
                    </FieldConnector>
                    <FieldErrorManager
                      key="deliveryDate-error"
                      name="deliveryDate"
                      className="col-start-2 mt-1"
                    />
                  </>
                );
              }}
            </FakeField>
          )}

          {formMeta.helpers.isGenerated && renderTransactions()}

          {!formMeta.fields.paymentDate.isHidden && (
            <FieldConnector
              horizontal={!formMeta.helpers.isGenerated}
              labelClassName={horizontalFieldLabelClass}
              name="paymentDate"
              disabled={isFieldDisabled}
              label={t('invoice.field.payment_date')}
              loading={lockByTaxesStatusAPI.isLoading}
            >
              {({ field: { value, onChange, ...field } }) => (
                <DatePicker
                  tracing={{
                    onCalendarOpen:
                      RevenuesEvents.WEBAPP_REVENUE_CLICKEDPAYMENTDATE,
                    onChange: RevenuesEvents.WEBAPP_REVENUE_UPDATEDPAYMENTDATE,
                  }}
                  {...field}
                  filterDate={(date: Date) =>
                    formValues.invoiceDate
                      ? !dayjs(date).isBefore(dayjs(formValues.invoiceDate))
                      : true
                  }
                  selected={value}
                  onChange={(value) => {
                    const date = value
                      ? dayjs(value).format('YYYY-MM-DD')
                      : null;
                    onChange(date);
                    if (
                      priorityDateKey === field.name &&
                      !formMeta.fields.period.isHidden
                    ) {
                      updatePeriodBasedOnDate(date);
                    }
                  }}
                />
              )}
            </FieldConnector>
          )}
          {customerCountry === AvailableCountries.germany &&
            !!lockByTaxesStatusAPI.data?.date && (
              <InvoiceSubmitLockedByTaxes
                type={formValues.type}
                fieldSlug={'payment_date'}
                taxLockReasons={lockByTaxesStatusAPI.data.date}
              />
            )}

          {formMeta.helpers.isGenerated && renderPeriod()}

          {!formMeta.fields.otherRevenueClient.isHidden && (
            <FieldConnector
              horizontal={!formMeta.helpers.isGenerated}
              labelClassName={horizontalFieldLabelClass}
              name="client"
              disabled={isFieldDisabled}
              label={`${t('invoice.field.client')} (${t(
                'invoice.field.optional.label',
              ).toLowerCase()})`}
            >
              {({ field: { onChange, value, ...rest } }) => (
                <Select
                  tracing={{
                    onMenuOpen: RevenuesEvents.WEBAPP_REVENUE_CLICKEDCLIENT,
                    onChange: RevenuesEvents.WEBAPP_REVENUE_UPDATEDCLIENT,
                  }}
                  mode="asyncOptionsPaginated"
                  asyncSearch
                  creatable
                  isDisabled={rest.disabled}
                  themeOptions={{
                    shouldOptionsShowSelectedIndicator: false,
                    hideDropdownIndicator: true,
                  }}
                  placeholder={t('invoice.field.select')}
                  value={value?.name}
                  onChange={(option: any) => {
                    const client = {
                      name: option?.value || undefined,
                      location: 'local',
                    };
                    onChange(client);
                  }}
                  onFetch={(name, page) =>
                    getSuggestedClients({ name, page }).then(
                      ({ data, paging }) => ({
                        paging,
                        data: data.map((client: any) => ({
                          label: client.name,
                          value: client.name,
                          client,
                        })),
                      }),
                    )
                  }
                  {...rest}
                />
              )}
            </FieldConnector>
          )}

          {!formMeta.fields.invoiceClient.isHidden && (
            <FieldConnector
              horizontal={!formMeta.helpers.isGenerated}
              labelClassName={horizontalFieldLabelClass}
              name="clientId"
              disabled={isFieldDisabled}
              label={t('invoice.field.client')}
            >
              {({ field: { onChange, value, ...rest } }) => (
                <Select
                  tracing={{
                    onMenuOpen: RevenuesEvents.WEBAPP_REVENUE_CLICKEDCLIENT,
                    onChange: RevenuesEvents.WEBAPP_REVENUE_UPDATEDCLIENT,
                  }}
                  mode="asyncOptions"
                  isSearchable
                  isDisabled={rest.disabled}
                  cacheKey={RevenuesCacheKeys.RevenueClientFieldOptions}
                  placeholder={t('invoice.field.select')}
                  onChange={(option: any) => {
                    onChange(option.value);
                    formAPI.setValue('client', option.client);
                  }}
                  onFetch={() => getClientsOptions()}
                  value={value}
                  {...rest}
                />
              )}
            </FieldConnector>
          )}

          {!formMeta.fields.invoiceClient.isHidden && !isLocked && (
            <UseRef
              render={(ref) => {
                const currentClient = formValues.client;
                const shouldSuggest =
                  !currentClient?.id &&
                  !currentClient?._id &&
                  !!currentClient?.name;
                return (
                  <div>
                    <Button
                      size="xl"
                      structure="text"
                      tracingEvent={RevenuesEvents.WEBAPP_REVENUE_ADDCLIENT}
                      onClick={() => {
                        ref.current?.open(
                          shouldSuggest ? formValues.client : {},
                        );
                      }}
                    >
                      {t('invoice.field.add_new_client', {
                        name: shouldSuggest
                          ? `(${t('suggested')}: ${currentClient?.name})`
                          : '',
                      })}
                    </Button>
                    <ClientForm
                      apiRef={ref}
                      afterSubmit={(result) => {
                        queryClient.invalidateQueries([
                          RevenuesCacheKeys.RevenuesClients,
                        ]);
                        queryClient.invalidateQueries([
                          SelectOptionsCacheKeys.Async,
                          RevenuesCacheKeys.RevenueClientFieldOptions,
                        ]);
                        formAPI.setValue('clientId', result._id, {
                          shouldValidate: true,
                        });
                        formAPI.setValue('client', result);
                      }}
                    />
                  </div>
                );
              }}
            />
          )}
        </div>

        <div className="mt-6">
          {formMeta.helpers.isGermanBusinessCar && (
            <div className="flex flex-col gap-4 px-6 pt-4">
              {!formMeta.fields.customCategoryId.isHidden && (
                <FieldConnector
                  className="flex-1"
                  name={'customData.customCategoryId'}
                  label={t('expense.category')}
                  disabled={isFieldDisabled}
                >
                  {({ field: { value, onChange, ...rest } }) => (
                    <CategoriesPickerControlled
                      {...rest}
                      isDisabled={rest.disabled}
                      value={value}
                      pickerController={pickerController}
                      taxPeriod={formValues.taxPeriod}
                      onChange={(category: any, taxPeriod: any) => {
                        if (taxPeriod) formAPI.setValue('taxPeriod', taxPeriod);

                        if (!category.customType) {
                          onChange(null);
                          formAPI.setValue('customType', null);
                          formAPI.setValue('customData.customCategory', null);
                          return formAPI.setValue('items', [
                            {
                              ...itemDefaults,
                              category,
                              categoryId: category.id,
                            },
                          ]);
                        }

                        onChange(category.id);
                        formAPI.setValue('customData.customCategory', category);
                        formAPI.setValue('customType', category.customType);
                      }}
                    />
                  )}
                </FieldConnector>
              )}

              {!formMeta.fields.usageStartDate.isHidden &&
                !formMeta.fields.usageEndDate.isHidden && (
                  <div className="flex gap-2">
                    <FieldConnector
                      className="flex-1 min-w-0"
                      name={'customData.usageStartDate'}
                      label={t('invoice.field.usage_from')}
                      disabled={isFieldDisabled}
                    >
                      {({ field: { value, ...field } }) => {
                        return (
                          <DatePicker
                            {...field}
                            selected={value}
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                            maxDate={
                              formValues.customData?.usageEndDate ||
                              dayjs().endOf('year')
                            }
                            onChange={(value) => {
                              const date = value
                                ? dayjs(value).format('YYYY-MM-DD')
                                : null;
                              field.onChange(date);
                            }}
                            tracing={{
                              onCalendarOpen:
                                RevenuesEvents.WEBAPP_REVENUE_CLICKEDUSAGESTART,
                              onChange:
                                RevenuesEvents.WEBAPP_REVENUE_UPDATEDUSAGESTART,
                            }}
                          />
                        );
                      }}
                    </FieldConnector>
                    <FieldConnector
                      className="flex-1 min-w-0"
                      name={'customData.usageEndDate'}
                      label={t('invoice.field.usage_to')}
                      disabled={isFieldDisabled}
                    >
                      {({ field: { value, ...field } }) => (
                        <DatePicker
                          {...field}
                          selected={value}
                          showMonthYearPicker
                          dateFormat="MMMM yyyy"
                          maxDate={dayjs().endOf('year')}
                          minDate={formValues.customData?.usageStartDate}
                          onChange={(value) => {
                            const date = value
                              ? dayjs(value).format('YYYY-MM-DD')
                              : null;
                            field.onChange(date);
                          }}
                          tracing={{
                            onCalendarOpen:
                              RevenuesEvents.WEBAPP_REVENUE_CLICKEDUSAGEEND,
                            onChange:
                              RevenuesEvents.WEBAPP_REVENUE_UPDATEDUSAGEEND,
                          }}
                        />
                      )}
                    </FieldConnector>
                  </div>
                )}

              {formMeta.helpers.shouldShowUsagePeriodWarning && (
                <Alert
                  type="warning"
                  dismissId={AlertIdsEnum.otherRevenueGermanCarUsage}
                  dismissable
                  name="german car usage more than 1 period"
                  description={t(
                    `invoice.warning_bubble.car_usage_more_than_1_period.${formValues.user?.VATReturnFrequency}`,
                  )}
                />
              )}

              {!formMeta.fields.vehicleId.isHidden && (
                <FieldConnector
                  name={'customData.vehicleId'}
                  label={t('invoice.field.car')}
                  disabled={isFieldDisabled}
                >
                  {({ field: { onChange, ...rest } }) => {
                    const _onChange = (
                      option: VehiclesOption<DEVehicle> | null,
                    ) => {
                      onChange(option?.value);
                      formAPI.setValue('customData.vehicle', option?.vehicle);
                    };
                    return (
                      <CarsSelect
                        {...rest}
                        tracing={{
                          onMenuOpen:
                            RevenuesEvents.WEBAPP_REVENUE_CLICKEDVEHICLE,
                          onChange:
                            RevenuesEvents.WEBAPP_REVENUE_UPDATEDVEHICLE,
                        }}
                        // use the vehicle name as the value label, usefull when the vehicle is deleted
                        // because we still have the vehicle object on the document
                        valueLabel={formValues.customData?.vehicle?.name}
                        isDisabled={rest.disabled}
                        onChange={_onChange}
                        onAddVehicle={(vehicle) => {
                          _onChange(vehicle);
                          onEditVehicles?.();
                          tracingEvents.onAddGermanCar();
                        }}
                        onEditVehicle={(option) => {
                          onEditVehicles?.();
                          if (rest.value === option.value) {
                            _onChange(option);
                          }
                          tracingEvents.onEditGermanCar();
                        }}
                        onDeleteVehicle={(option) => {
                          onEditVehicles?.();
                          if (rest.value === option.value) {
                            _onChange(null);
                          }
                          tracingEvents.onDeleteGermanCar();
                        }}
                        getVehiclesOptions={() =>
                          getVehiclesOptions<DEVehicle>()
                        }
                        deleteVehicle={deleteVehicle}
                        deleteMessage={t('german_car.delete_warning')}
                        renderForm={(props) => (
                          <GermanCarsFormControlled
                            {...props}
                            onSubmit={(values) => {
                              if (props.context === 'add') {
                                return createVehicle(values).then(
                                  props.onSubmit,
                                );
                              }
                              return updateVehicle(values._id, values).then(
                                props.onSubmit,
                              );
                            }}
                          />
                        )}
                      />
                    );
                  }}
                </FieldConnector>
              )}

              {!formMeta.fields.isVehicleUsedForOfficeCommute.isHidden && (
                <FieldConnector
                  name={'customData.isVehicleUsedForOfficeCommute'}
                  className="flex flex-row-reverse gap-2 items-center justify-end"
                  labelClassName="mb-0"
                  label={t('invoice.field.car_commute_to_work')}
                  disabled={isFieldDisabled}
                >
                  {({ field: { value, onChange, disabled } }) => (
                    <Toggle
                      enabled={!!value}
                      onChange={onChange}
                      disabled={disabled}
                      tracing={{
                        onChange:
                          RevenuesEvents.WEBAPP_REVENUE_UPDATEDCARUSEDFOROFFICETOGGLE,
                      }}
                    />
                  )}
                </FieldConnector>
              )}

              {!formMeta.fields.distanceBetweenHomeAndOffice.isHidden && (
                <FieldConnector
                  name={'customData.distanceBetweenHomeAndOffice'}
                  label={t('invoice.field.km_to_work')}
                  disabled={isFieldDisabled}
                >
                  {({ field }) => (
                    <NumberField
                      {...field}
                      format={{ prefix: ' km' }}
                      tracing={{
                        onFocus:
                          RevenuesEvents.WEBAPP_REVENUE_CLICKEDDISTANCEHOMETOOFFICE,
                      }}
                    />
                  )}
                </FieldConnector>
              )}

              {formMeta.helpers.shouldShow1RuleInfo && (
                <Alert
                  type="info"
                  description={t('invoice.info_bubble.1_rule')}
                  renderButtons={(themeClassName) => (
                    <Helper
                      trigger={(openModal) => (
                        <Button
                          asDefault
                          onClick={openModal}
                          className={classNames(
                            'underline text-sm font-semibold',
                            themeClassName,
                          )}
                        >
                          {t('learn_more')}
                        </Button>
                      )}
                    >
                      <Helper.Paragraph className="whitespace-pre-line">
                        {t('german_car.encode_private_usage.helper')}
                      </Helper.Paragraph>
                    </Helper>
                  )}
                />
              )}
            </div>
          )}

          <div className="flex flex-col gap-4 px-6 pt-4">
            {!formMeta.fields.items.isHidden && (
              <FieldConnector name="items">
                {({ name: parentName }) => (
                  <FieldsArray
                    controller={itemsController}
                    name={parentName}
                    showItemsNumber={
                      formMeta.helpers.isItemsComputed ||
                      formValues.items?.length > 1
                    }
                    isItemsRemovable={
                      !isLocked &&
                      !formMeta.helpers.isItemsComputed &&
                      formValues.items?.length > 1
                    }
                    render={(props) => {
                      const { field, index } = props;
                      const getName = (name: string) =>
                        `${parentName}.${index}.${name}`;
                      const item = formValues.items[index];

                      const itemMeta = getItemMeta(formValues, item);

                      const onRateChange = (newRate: any) => {
                        const value = multiplyBy1000(newRate);
                        formAPI.setValue(getName('VATRate'), value);
                      };

                      const onToggleIncludeVAT = (
                        doesUnitPriceIncludeVAT: boolean,
                      ) => {
                        formAPI.setValue(
                          getName('doesUnitPriceIncludeVAT'),
                          doesUnitPriceIncludeVAT,
                        );

                        if (doesUnitPriceIncludeVAT) {
                          formAPI.setValue(
                            getName('unitAmountInclVAT'),
                            formAPI.getValues(getName('unitAmountExclVAT')) ||
                              0,
                            { shouldValidate: true },
                          );
                          return formAPI.setValue(
                            getName('unitAmountExclVAT'),
                            null,
                            { shouldValidate: true },
                          );
                        }
                        formAPI.setValue(
                          getName('unitAmountExclVAT'),
                          formAPI.getValues(getName('unitAmountInclVAT')) || 0,
                          { shouldValidate: true },
                        );
                        formAPI.setValue(getName('unitAmountInclVAT'), null, {
                          shouldValidate: true,
                        });
                      };

                      return (
                        <div
                          key={field.id}
                          className={classNames('flex flex-col gap-4')}
                        >
                          {!itemMeta.fields.name.isHidden && (
                            <FieldConnector
                              name={getName('name')}
                              disabled={(name) =>
                                isFieldDisabled(name) ||
                                itemMeta.fields.name.isDisabled
                              }
                              label={`${t(
                                'invoice.field.item_description',
                              )} (${t(
                                'invoice.field.optional.label',
                              ).toLowerCase()})`}
                            >
                              {({ field }) => (
                                <FreeTextInput
                                  tracing={{
                                    onFocus:
                                      RevenuesEvents.WEBAPP_REVENUE_CLICKEDITEMNAME,
                                  }}
                                  {...field}
                                  disabled={field.disabled}
                                />
                              )}
                            </FieldConnector>
                          )}

                          <div className="flex gap-2">
                            {!itemMeta.fields.quantity.isHidden && (
                              <FieldConnector
                                className="flex-1"
                                name={getName('quantity')}
                                disabled={(name) =>
                                  isFieldDisabled(name) ||
                                  itemMeta.fields.quantity.isDisabled
                                }
                                label={t('invoice.field.quantity')}
                              >
                                {({ field: { onChange, value, ...field } }) => {
                                  return (
                                    <NumberField
                                      tracing={{
                                        onFocus:
                                          RevenuesEvents.WEBAPP_REVENUE_CLICKEDQUANTITY,
                                      }}
                                      {...field}
                                      disabled={field.disabled}
                                      value={
                                        value == null
                                          ? null
                                          : divideBy1000(value)
                                      }
                                      onChange={(value) => {
                                        onChange(
                                          value == null
                                            ? null
                                            : round(multiplyBy1000(value)),
                                        );
                                      }}
                                    />
                                  );
                                }}
                              </FieldConnector>
                            )}

                            <FieldConnector
                              className="flex-1"
                              name={getName('categoryId')}
                              disabled={(name) =>
                                isFieldDisabled(name) ||
                                itemMeta.fields.categoryId.isDisabled
                              }
                              label={t('expense.category')}
                            >
                              {({ field: { value, onChange, ...rest } }) => (
                                <CategoriesPickerControlled
                                  {...rest}
                                  pickerController={pickerController}
                                  isDisabled={rest.disabled}
                                  value={value}
                                  taxPeriod={formValues.taxPeriod}
                                  onChange={(category, taxPeriod) =>
                                    itemsCategoryPickerOnChange(
                                      index,
                                      category,
                                      taxPeriod,
                                    )
                                  }
                                />
                              )}
                            </FieldConnector>
                          </div>

                          <div className="flex gap-2">
                            {(!itemMeta.fields.unitAmountExclVAT.isHidden ||
                              !itemMeta.fields.unitAmountInclVAT.isHidden) && (
                              <FieldConnector
                                className="flex-1"
                                name={
                                  item.doesUnitPriceIncludeVAT
                                    ? getName('unitAmountInclVAT')
                                    : getName('unitAmountExclVAT')
                                }
                                key={
                                  item.doesUnitPriceIncludeVAT
                                    ? 'unitAmountInclVAT'
                                    : 'unitAmountExclVAT'
                                }
                                disabled={isFieldDisabled}
                                label={
                                  formMeta.helpers.isOtherRevenue
                                    ? t('invoice.field.amount')
                                    : t('invoice.field.unit_price')
                                }
                                helper={
                                  formMeta.helpers.isGermanBusinessCar && (
                                    <Helper>
                                      <Helper.Title>
                                        {t(
                                          'invoice.business_car.amount_helper.title1',
                                        )}
                                      </Helper.Title>
                                      <Helper.Paragraph className="whitespace-pre-wrap">
                                        {t(
                                          'invoice.business_car.amount_helper.text1',
                                          {
                                            listPrice: formatMoney(
                                              formValues.customData?.vehicle
                                                ?.listPrice || 0,
                                            ),
                                            numberOfmonths: dayjs(
                                              formValues.customData
                                                ?.usageEndDate,
                                            ).diff(
                                              dayjs(
                                                formValues.customData
                                                  ?.usageStartDate,
                                              ),
                                              'month',
                                            ),
                                            totalAmountExclVAT:
                                              formatMoney(totalAmountExclVAT),
                                            factor:
                                              formValues.customData?.vehicle
                                                ?.fuelType ===
                                              VehicleFuelTypeEnum.hybrid
                                                ? 0.005
                                                : formValues.customData?.vehicle
                                                    ?.fuelType ===
                                                  VehicleFuelTypeEnum.electric
                                                ? 0.0025
                                                : 0.01,
                                          },
                                        )}
                                      </Helper.Paragraph>
                                      <Helper.Title>
                                        {t(
                                          'invoice.business_car.amount_helper.title2',
                                        )}
                                      </Helper.Title>
                                      <Helper.Paragraph className="whitespace-pre-wrap">
                                        {t(
                                          'invoice.business_car.amount_helper.text2',
                                        )}
                                      </Helper.Paragraph>
                                    </Helper>
                                  )
                                }
                              >
                                {({ field: { onChange, value, ...field } }) => {
                                  const _value = value;
                                  return (
                                    <PaymentAmount
                                      tracing={{
                                        onFocus:
                                          RevenuesEvents.WEBAPP_REVENUE_CLICKEDAMOUNT,
                                      }}
                                      currency={formValues.currency}
                                      {...field}
                                      disabled={
                                        field.disabled ||
                                        (item.doesUnitPriceIncludeVAT
                                          ? itemMeta.fields.unitAmountInclVAT
                                              .isDisabled
                                          : itemMeta.fields.unitAmountExclVAT
                                              .isDisabled)
                                      }
                                      value={
                                        _value == null
                                          ? null
                                          : divideBy1000(_value)
                                      }
                                      onChange={(value) => {
                                        value = value == null ? 0 : value;
                                        const amount = round(value);
                                        onChange(round(multiplyBy1000(amount)));
                                      }}
                                    />
                                  );
                                }}
                              </FieldConnector>
                            )}

                            {!itemMeta.fields.VATRate.isHidden && (
                              <FieldConnector
                                className="flex-1"
                                name={getName('VATRate')}
                                disabled={(name) =>
                                  isFieldDisabled(name) ||
                                  itemMeta.fields.VATRate.isDisabled
                                }
                                onShow={(name) => {
                                  if (formMeta.helpers.isItemsComputed) return;
                                  const current = formAPI.getValues(name);
                                  if (current != null) return;
                                  onRateChange(defaultVATRate);
                                }}
                                label={t('invoice.field.vat_rate')}
                              >
                                {({ field: { onChange, value, ...rest } }) => (
                                  <Select
                                    tracing={{
                                      onMenuOpen:
                                        RevenuesEvents.WEBAPP_REVENUE_CLICKEDVATRATE,
                                      onChange:
                                        RevenuesEvents.WEBAPP_REVENUE_UPDATEDVATRATE,
                                    }}
                                    options={VATRates}
                                    isDisabled={rest.disabled}
                                    isSearchable={false}
                                    value={
                                      value == null ? null : divideBy1000(value)
                                    }
                                    onChange={(option) =>
                                      onRateChange(option.value)
                                    }
                                    formatOptionLabel={(option, meta) => {
                                      if (meta.context === 'value')
                                        return option.label;
                                      return (
                                        <div className="flex items-center justify-between w-full">
                                          <span>{option.label}</span>
                                          {itemMeta.helpers
                                            .recommendedVATRate ===
                                            option.value && (
                                            <Badge
                                              color="purple"
                                              baseClassName="py-1 px-1.5 rounded-full"
                                            >
                                              {t('recommended')}
                                            </Badge>
                                          )}
                                        </div>
                                      );
                                    }}
                                    {...rest}
                                  />
                                )}
                              </FieldConnector>
                            )}

                            {!itemMeta.fields.doesUnitPriceIncludeVAT
                              .isHidden && (
                              <FieldConnector
                                name={getName('doesUnitPriceIncludeVAT')}
                                disabled={(name) =>
                                  isFieldDisabled(name) ||
                                  itemMeta.fields.doesUnitPriceIncludeVAT
                                    .isDisabled
                                }
                                className="flex flex-col items-center gap-2"
                                label={t('invoice.field.vat_toggle.amount')}
                              >
                                {({ field: { value, disabled } }) => (
                                  <Toggle
                                    enabled={!!value}
                                    onChange={onToggleIncludeVAT}
                                    disabled={disabled}
                                  />
                                )}
                              </FieldConnector>
                            )}
                          </div>
                          {itemMeta.helpers.shouldShowVATRateNotZeroWarning && (
                            <Alert
                              className="mb-0"
                              type="warning"
                              description={
                                <ReactMarkdown components={markdownComponents}>
                                  {t(
                                    'revenue.vatrate_intra_eu_not_zero_warning',
                                    {
                                      userNationality: t(
                                        'nationality.' + customerCountry,
                                      ),
                                      userCountry: t(
                                        'country.' + customerCountry,
                                      ),
                                    },
                                  )}
                                </ReactMarkdown>
                              }
                            />
                          )}
                          {!itemMeta.fields.whyZeroVAT.isHidden && (
                            <FieldConnector
                              name={getName('whyZeroVAT')}
                              disabled={(name) =>
                                isFieldDisabled(name) ||
                                itemMeta.fields.whyZeroVAT.isDisabled
                              }
                              label={t('invoice.field.why_zero_vat.label')}
                            >
                              {({ field }) => (
                                <WhyZeroVAT
                                  tracing={{
                                    onMenuOpen:
                                      RevenuesEvents.WEBAPP_REVENUE_CLICKEDWHYZEROVAT,
                                    onChange:
                                      RevenuesEvents.WEBAPP_REVENUE_UPDATEDWHYZEROVAT,
                                  }}
                                  {...field}
                                  clientLocation={formValues.client?.location}
                                  clientType={formValues.client?.type}
                                  categoryId={item.categoryId}
                                  revenueType={formValues.type}
                                  VATType={formValues.user.VATType}
                                />
                              )}
                            </FieldConnector>
                          )}
                        </div>
                      );
                    }}
                  />
                )}
              </FieldConnector>
            )}

            {!formMeta.helpers.shouldHideAddItemBtn && !isLocked && (
              <div>
                <Button
                  structure="text"
                  size="xl"
                  color="revenues"
                  onClick={() =>
                    itemsController.append({
                      ...itemDefaults,
                      doesUnitPriceIncludeVAT:
                        !!shouldUnitPriceIncludeVATByDefault,
                    })
                  }
                >
                  <PlusIcon className="w-5 mr-1" />
                  {t('invoice.field.item.add_item')}
                </Button>
              </div>
            )}

            {!formMeta.helpers.isCalcUIHidden && (
              <div>
                {(formMeta.helpers.isInvoice ||
                  formMeta.helpers.isCreditNote) &&
                  formMeta.helpers.isCustomerSubjectToVAT && (
                    <div className="bg-[#F9F7FC] flex items-center justify-between rounded-lg p-2 my-1">
                      <p className="text-primary-400">
                        {t('invoice.field.total_amount_excl_vat')}
                      </p>
                      <span className="font-semibold">
                        <Amount
                          amount={totalAmountExclVAT}
                          currency={formValues.currency}
                        />
                      </span>
                    </div>
                  )}

                {Object.entries(
                  removeInvalidVATRateGroups(groupBy(allItems, 'VATRate')),
                ).map(([VATGroupName, VATGroup]) => {
                  return (
                    <div
                      key={VATGroupName}
                      className="bg-[#F9F7FC] flex items-center justify-between rounded-lg p-2 my-1"
                    >
                      <p className="text-primary-400">
                        {t(
                          `invoice.field.total_amount_vat_${(
                            divideBy1000(+VATGroupName) * 100
                          ).toFixed()}`,
                        )}
                      </p>
                      <span className="font-semibold">
                        <Amount
                          currency={formValues.currency}
                          amount={VATGroup.reduce((acc, item) => {
                            if (item.doesUnitPriceIncludeVAT) {
                              const sum =
                                calculateVATAmount(
                                  divideBy1000(item.unitAmountInclVAT),
                                  divideBy1000(+VATGroupName),
                                ) * divideBy1000(item.quantity);
                              return (
                                acc +
                                (sum -
                                  sum * divideBy1000(item.discountPercentage))
                              );
                            }

                            const sum =
                              divideBy1000(item.unitAmountExclVAT) *
                              divideBy1000(+VATGroupName) *
                              divideBy1000(item.quantity);
                            return (
                              acc +
                              (sum -
                                sum * divideBy1000(item.discountPercentage))
                            );
                          }, 0)}
                        />
                      </span>
                    </div>
                  );
                })}
                <div className="bg-[#F9F7FC] flex items-center justify-between rounded-lg p-2 my-1">
                  <p className="text-primary-400">
                    {t('invoice.field.total_amount_incl_vat')}
                  </p>
                  <span className="font-semibold">
                    <Amount
                      amount={
                        formMeta.helpers.isCreditNote
                          ? totalAmountInclVAT * -1
                          : totalAmountInclVAT
                      }
                      currency={formValues.currency}
                    />
                  </span>
                </div>
              </div>
            )}

            {!germanCarComputedItemsAPI.isLoading && !!allItems.length && (
              <div className="flex flex-col items-end mt-4">
                <TaxImpact
                  currencyRate={currencyRate}
                  documentType={TaxImpactDocumentType.REVENUE}
                  isValidated={
                    !!((revenue?.isValidated as boolean) && revenue?._id)
                  }
                />
              </div>
            )}

            {!formMeta.helpers.isGenerated && (
              <div
                className={classNames('pt-7 flex flex-col gap-4', {
                  'border-t border-primary-100 mt-7': allItems.length,
                })}
              >
                <p className="font-bold text-main-900 text-xl leading-none tracking-tight font-avenir">
                  {t('expense.additional_information')}
                </p>
                {!formMeta.helpers.isGenerated && renderTransactions()}

                {!formMeta.fields.notes.isHidden && (
                  <FieldConnector name="notes" label={t('expense.notes')}>
                    {({ field: { onChange, ...field } }) => (
                      <FreeTextInput
                        type="textarea"
                        {...field}
                        onChange={({ target: { value } }) => {
                          onChange(value);
                        }}
                      />
                    )}
                  </FieldConnector>
                )}

                {!formMeta.helpers.isGenerated && renderPeriod()}

                {!formMeta.fields.VATType.isHidden && (
                  <UseState
                    init={false}
                    render={(isOpen, setIsOpen) => {
                      return (
                        <div className="mt-4">
                          <Button
                            structure="text"
                            size="xl"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            {isOpen ? (
                              <MinusIcon className="w-5 mr-1" />
                            ) : (
                              <PlusIcon className="w-5 mr-1" />
                            )}
                            {t('invoice.item_form.advanced_settings')}
                          </Button>
                          <FieldConnector
                            className="mt-2"
                            name={'user.VATType'}
                            disabled={isFieldDisabled}
                            showUI={isOpen}
                            label={t('customer.field.vattype.doctype', {
                              docType: t('invoice'),
                            })}
                          >
                            {({ field: { onChange, ...rest } }) => (
                              <Select
                                tracing={{
                                  onMenuOpen:
                                    RevenuesEvents.WEBAPP_REVENUE_CLICKEDVATTYPE,
                                  onChange:
                                    RevenuesEvents.WEBAPP_REVENUE_UPDATEDVATTYPE,
                                }}
                                menuPlacement="top"
                                isDisabled={rest.disabled}
                                options={[
                                  {
                                    label: t(
                                      'settings.generalTaxes.subjectToVAT',
                                    ),
                                    value: VATTypeEnum.subjectToVAT,
                                  },
                                  {
                                    label: t(
                                      `settings.generalTaxes.franchisee.${customerCountry}`,
                                    ),
                                    value: VATTypeEnum.franchisee,
                                  },
                                  {
                                    label: t('settings.generalTaxes.exempt'),
                                    value: VATTypeEnum.exempt,
                                  },
                                ]}
                                isSearchable={false}
                                onChange={(option: any) => {
                                  onChange(option.value);
                                }}
                                {...rest}
                              />
                            )}
                          </FieldConnector>
                        </div>
                      );
                    }}
                  />
                )}
              </div>
            )}

            {isExpert && (
              <div className="border-t border-primary-100 mt-3 pt-7 flex flex-col gap-4">
                <p className="font-bold text-main-900 text-xl leading-none tracking-tight font-avenir">
                  {t('expense.accountant_section')}
                </p>
                {!formMeta.fields.accountantComments.isHidden && (
                  <FieldConnector
                    name={'accountantReview.comments'}
                    disabled={isFieldDisabled}
                    label={t('accountantReview.comments')}
                  >
                    {({ field }) => (
                      <FreeTextInput type="textarea" {...field} />
                    )}
                  </FieldConnector>
                )}
                <RevenueVATCells invoice={formValues} enabled={!hasErrors} />
              </div>
            )}

            {footerMeta}
          </div>
        </div>
        {!disabled &&
          (!isLocked || (isLocked && isFormDirty)) &&
          (() => {
            const runValidationAndSubmit: MouseEventHandler<
              HTMLButtonElement
            > = (e) => {
              if (lockByTaxesStatusAPI.data) {
                const lockUI = document.querySelector('.TAX_LOCK_REASON');
                if (lockUI) {
                  lockUI.scrollIntoView({ behavior: 'smooth' });
                  return;
                }
              }
              // temporary
              if (formMeta.helpers.isDeductible && !formValues.taxPeriod) {
                const firstDeductibleItemIndex = formValues.items.findIndex(
                  (item: any) =>
                    getItemMeta(formValues, item).helpers.isDeductible,
                );
                return pickerController.api.open({
                  value: formValues.items[firstDeductibleItemIndex].categoryId,
                  onChange: (category, taxPeriod) => {
                    itemsCategoryPickerOnChange(
                      firstDeductibleItemIndex,
                      category,
                      taxPeriod,
                    );
                  },
                });
              }

              const revenueNumberHasCustomError =
                formErrors['revenueNumber']?.type === 'custom-async';

              const fieldsToValidate = revenueNumberHasCustomError
                ? Object.keys(omit(formAPI.control._fields, 'revenueNumber'))
                : Object.keys(formAPI.control._fields);

              formAPI.trigger(fieldsToValidate).then((valid) => {
                if (valid && !revenueNumberHasCustomError) {
                  return formAPI.handleSubmit(onSubmit)(e);
                }
                const fieldWithError =
                  document.querySelector('.field-with-error');
                if (fieldWithError) {
                  fieldWithError.scrollIntoView({ behavior: 'smooth' });
                } else {
                  // we have erros for hidden fields
                  // it means some important data is missing
                  Sentry.captureException(
                    {
                      message: createSentryCustomErrorMsg(
                        'revenues_errors_on_hidden_fields',
                      ),
                    },
                    {
                      extra: {
                        formErrors: JSON.stringify(formAPI.formState.errors),
                        formValues: JSON.stringify(formValues),
                      },
                    },
                  );
                }
              });
            };
            return (
              <div className="sticky bottom-20 translate-y-[20px] w-64 mx-auto flex flex-col gap-2 justify-center items-center">
                {isWeb && (
                  <Button
                    highlightID="submit-revenue"
                    loading={isSubmitting}
                    disabled={
                      formMeta.helpers.isGenerated &&
                      !isFormDirty &&
                      revenue.status !== InvoiceStatusEnum.draft
                    }
                    size="xl"
                    tracingEvent={RevenuesEvents.WEBAPP_REVENUE_CLICKEDSAVE}
                    onClick={runValidationAndSubmit}
                  >
                    {t('save')}
                  </Button>
                )}
                {isExpert && (
                  <>
                    <Button
                      loading={isSubmitting && isReviewed}
                      disabled={isSubmitting}
                      size="xl"
                      className="w-full"
                      tracingEvent={RevenuesEvents.WEBAPP_REVENUE_CLICKEDSAVE}
                      onClick={(e) => {
                        formAPI.setValue(
                          'accountantReview.reviewStatus',
                          ReviewStatusEnum.reviewed,
                        );
                        runValidationAndSubmit(e);
                      }}
                    >
                      {t('save_and_validate')}
                    </Button>
                    <Button
                      structure="secondary"
                      loading={isSubmitting && !isReviewed}
                      disabled={isSubmitting}
                      size="xl"
                      className="w-full"
                      tracingEvent={RevenuesEvents.WEBAPP_REVENUE_CLICKEDSAVE}
                      onClick={(e) => {
                        formAPI.setValue(
                          'accountantReview.reviewStatus',
                          ReviewStatusEnum.notReviewed,
                        );
                        runValidationAndSubmit(e);
                      }}
                    >
                      {t('save_and_dont_validate')}
                    </Button>
                  </>
                )}
              </div>
            );
          })()}
      </form>
    </FormProvider>
  );
};

export default Form;
