export enum AvatarType {
  circular = 'circular',
  rounded = 'rounded',
  circularWithTopNotification = 'circular-with-top-notification',
  roundedWithTopNotification = 'rounded-with-top-notification',
  circularWithBottomNotification = 'circular-with-bottom-notification',
  roundedWithBottomNotification = 'rounded-with-bottom-notification',
}

export enum AvatarSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
}

export enum AvatarStatus {
  online = 'online',
  offline = 'offline',
  busy = 'busy',
}
