const VATNumberMaskNormalizer =
  (prefix: string) =>
  (VATNumber: string): string => {
    if (!VATNumber || VATNumber.trim().length === 0) {
      return '';
    }

    const content = VATNumber.replace(/[^0-9]/g, '');

    return prefix + content;
  };

export { VATNumberMaskNormalizer };
