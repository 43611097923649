import { Table } from 'components/atoms/Table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

const AssetCols: FC<{ shouldHideStillToAmortizeCol?: boolean }> = (props) => {
  const { shouldHideStillToAmortizeCol = false } = props;
  const { t } = useTranslation();
  return (
    <>
      <Table.HeadCell className="rounded-tl-lg">
        {t('settings.amortization.table.asset')}
      </Table.HeadCell>
      <Table.HeadCell>{t('settings.amortization.table.date')}</Table.HeadCell>
      <Table.HeadCell>{t('settings.amortization.table.period')}</Table.HeadCell>
      <Table.HeadCell>
        {t('settings.amortization.table.purchase_price')}
      </Table.HeadCell>
      <Table.HeadCell
        className={shouldHideStillToAmortizeCol ? 'text-right' : ''}
      >
        {t('settings.amortization.table.pro_amount')}
      </Table.HeadCell>
      {!shouldHideStillToAmortizeCol && (
        <Table.HeadCell className="text-right">
          {t('settings.amortization.table.to_be_amortized')}
        </Table.HeadCell>
      )}
      <Table.HeadCell className="rounded-tr-lg"></Table.HeadCell>
    </>
  );
};

export default AssetCols;
