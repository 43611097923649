import { sortBy, uniqBy } from 'lodash';

export const getRecommendedAmortizationDurationInYears = (
  category: any,
): number => (category?.suggestedAmortizationPeriod || 0) / 12;

export const getAmortizationOptionLabel = (durationInYears: number) => {
  if (!durationInYears) return 'expense.field.amortization.no_amortization';
  if (durationInYears > 1) return 'expense.field.amortization.number_of_years';
  return 'expense.field.amortization.one_year';
};

const amortizationOptions = [
  {
    label: 0,
    value: 0,
  },
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 10,
    value: 10,
  },
];

const createAmortizationOption = (durationInYears: number) => {
  return {
    label: durationInYears,
    value: durationInYears,
    isRecommended: true,
  };
};

export const getAmortizationOptions = (category: any) => {
  const recommendedDuartion =
    getRecommendedAmortizationDurationInYears(category);
  if (!recommendedDuartion) return amortizationOptions;
  const recommendedOption = createAmortizationOption(recommendedDuartion);
  return sortBy(
    uniqBy([recommendedOption, ...amortizationOptions], 'value'),
    ({ value }) => (value ? value : 0),
  );
};
