import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import 'array.prototype.at/auto';
import 'array.prototype.flat/auto';
import 'array.prototype.flatmap/auto';
import 'object.fromentries/auto';
import 'promise.allsettled/auto';
import 'promise.withresolvers/auto';
// api
import '@formatjs/intl-numberformat/polyfill';
// langs
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/nl';

// api
import '@formatjs/intl-pluralrules/polyfill';
// langs
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/nl';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import '@stripe/stripe-js';
import '@ungap/global-this';
import * as config from 'config';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import i18n from 'i18n';
import { parse } from 'query-string';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import RelativeTimeFormat from 'relative-time-format';
import de from 'relative-time-format/locale/de';
import en from 'relative-time-format/locale/en';
import fr from 'relative-time-format/locale/fr';
import nl from 'relative-time-format/locale/nl';
import { initAmplitude } from 'utils/amplitude';

RelativeTimeFormat.addLocale(en);
RelativeTimeFormat.addLocale(fr);
RelativeTimeFormat.addLocale(de);
RelativeTimeFormat.addLocale(nl);

i18n.on('languageChanged', (lng) => {
  dayjs.locale(lng);
  document.getElementsByTagName('html')[0]?.setAttribute('lang', lng);
});

dayjs.extend(quarterOfYear);

dayjs.extend(relativeTime);

dayjs.extend(isBetween);

const tagManagerArgs = {
  gtmId: 'GTM-5KDV3ZV',
};

TagManager.initialize(tagManagerArgs);

initAmplitude();

if (config.environment != 'development') {
  Sentry.init({
    dsn: 'https://4b5091c3d6434100a96cf19d0d9a51bd@o1135119.ingest.sentry.io/6465173',
    environment: config.environment,
    release: config.version,
    ignoreErrors: ['ChunkLoadError'],
    integrations: [
      new BrowserTracing({
        tracingOrigins: [/.*\.accountable.eu/, /^\//],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: config.environment == 'production' ? 0.05 : 1,
  });
}

(window as any).shouldOpenIntercomInitially =
  parse(window.location.search).utm_medium === 'chat_opener';
