import { CellsModifiers } from 'types/CellsModifiers.types';
import { ClientTypeEnum } from 'types/clients.types';
import { countriesWithSearchableClients } from 'utils/constants';
const clients = {
  client_types: [
    ClientTypeEnum.business,
    ClientTypeEnum.private,
    ClientTypeEnum.diplomatic,
  ],
  has_VAT_field_for_same_country: true,
  getIsSearchableCountry: (countryCode: string): boolean => {
    return countriesWithSearchableClients.includes(countryCode?.toUpperCase());
  },
  shouldPrefillVATPrefix: () => true,
  vat_label: {
    same_country: 'client.field.vat_number.title.be',
    other_country: 'client.field.vat_number.title.be',
  },
  list: {
    cells: {
      _modifiers: [{ name: CellsModifiers.clients }],
      default_sort: {
        web: 'clientName_asc',
        experts: 'clientName_asc',
      },
      first_group: {
        web: ['NAME', 'CITY', 'VATNUMBER'],
        experts: ['NAME', 'CITY', 'VATNUMBER'],
      },
      second_group: {
        web: ['NAME', 'CITY', 'VATNUMBER'],
        experts: ['NAME', 'CITY', 'VATNUMBER'],
      },
    },
  },
};

export default clients;
