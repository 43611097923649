import { signupUser } from 'api/v1/signup';
import type { Dictionary } from 'lodash';
import { pick } from 'lodash';
import type { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import type { IUser } from 'types/users.types';
import {
  AvailableCountries,
  AvailableLanguages,
  AvailableLanguagesBE,
  AvailableLanguagesDE,
  VATTypeEnum,
} from 'types/users.types';

export enum StepsIds {
  Email = 'Email',
  FirstName = 'FirstName',
  LastName = 'LastName',
  SelectCountry = 'SelectCountry',
  AccountType = 'AccountType',
  VATType = 'VATType',
  VATFrequency = 'VATFrequency',
  Promocode = 'Promocode',
  Password = 'Password',
  Enterprise = 'Enterprise',
  VATNumber = 'VATNumber',
  NaturalType = 'naturalType',
  ComplementaryDays = 'ComplementaryDays',
  CompanyName = 'CompanyName',
  Tenure = 'Tenure',
  Profession = 'Profession',
  AreYouSelfEmployed = 'AreYouSelfEmployed',
  BecomingSelfEmployed = 'BecomingSelfEmployed',
  LostNonSelfEmployed = 'LostNonSelfEmployed',
  UnsureToBecomeSeleftEmployed = 'UnsureToBecomeSeleftEmployed',
  ToBeSEAccountType = 'ToBeSEAccountType',
  ToBeSEOccupation = 'ToBeSEOccupation',
  UnsureAccountType = 'UnsureAccountType',
  UnsureOccupation = 'UnsureOccupation',
  UnsureVATType = 'UnsureVATType',
  DefaultAccountType = 'DefaultAccountType',
  DefaultOccupation = 'DefaultOccupation',
  DefaultVATType = 'DefaultVATType',
  HowLongBeenSE = 'HowLongBeenSE',
}

export enum FieldsNames {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  type = 'accountType',
  VATType = 'VATType',
  VATFrequency = 'VATReturnFrequency',
  companyInfo = 'companyInfo',
  VATNumber = 'VATNumber',
  country = 'country',
  addressCountry = 'address.country',
  promocode = 'promocode',
  password = 'password',
  occupation = 'occupation',
  naturalType = 'naturalType',
  complementaryDays = 'complementaryDays',
  companyName = 'companyName',
  tenure = 'tenure',
  APC1 = 'APC1',
  APC2 = 'APC2',
  intercomLeadId = 'intercomLeadId',
  selfEmployed = 'selfEmployed',
  thinksOfBecomingSelfEmployed = 'thinksOfBecomingSelfEmployed',
  howLongAsSE = 'howLongAsSE',
  googleIdToken = 'googleIdToken',
  appleIdToken = 'appleIdToken',
}

export enum ActionsIds {
  // shared
  SubmitEmail = 'SubmitEmail',
  SubmitSSO = 'SubmitSSO',
  SubmitSSO_LastNameMissing = 'SubmitSSO_LastNameMissing',
  SubmitFirstName = 'SubmitFirstName',
  SubmitLastName = 'SubmitLastName',
  SelectedCountryDE = 'SelectedCountryDE',
  SelectedCountryBE = 'SelectedCountryBE',
  SubmitPromocode = 'SubmitPromocode',
  SubmitPassword = 'SubmitPassword',
  SubmitProfession = 'SubmitProfession',
  YesImSE = 'YesImSE',
  NotSE = 'NotSE',
  NotSureSE = 'NotSureSE',
  SelectWantToBecomeSE = 'SelectWantToBecomeSE',
  SelectDontWantToBecomeSE = 'SelectDontWantToBecomeSE',
  SelectNotSureToBecomeSE = 'SelectNotSureToBecomeSE',
  NotSESelectedAccountType = 'NotSESelectedAccountType',
  SelectedOccupation = 'SelectedOccupation',
  ContinueUnsureFlow = 'ContinueUnsureFlow',
  NotSureOfAccountType = 'NotSureOfAccountType',
  NotSureOfOccupation = 'NotSureOfOccupation',
  NotSureOfVATType = 'NotSureOfVATType',
  NotSureOfVATFrequency = 'NotSureOfVATFrequency',
  ClickFinishWithDefaultAccountType = 'ClickFinishWithDefaultAccountType',
  ClickFinishWithDefaultOccupation = 'ClickFinishWithDefaultOccupation',
  ClickFinishWithDefaultVATType = 'ClickFinishWithDefaultVATType',
  ClickFinishWithDefaultVATFrequency = 'ClickFinishWithDefaultVATFrequency',
  ClickContinueSignupProcess = 'ClickContinueSignupProcess',
  SelectHowLongAsSE = 'SelectHowLongAsSE',
  // DE
  SelectedAccountType = 'SelectedAccountType',
  SelectedVATTypeSubject = 'SelectedVATTypeSubject',
  SelectedVATTypeOther = 'SelectedVATTypeOther',
  SelectedVATFrequency = 'SelectedVATFrequency',
  SubmitTenure = 'SubmitTenure',

  // BE
  SelectedAccountTypeLegal = 'SelectedAccountTypeLegal',
  SelectedAccountTypeOther = 'SelectedAccountTypeOther',
  SubmitNaturalTypeAsPrincipal = 'SubmitOccupationAsPrincipal',
  SubmitNaturalTypeAsComplementary = 'SubmitOccupationAsComplementary',
  SelectedVATType = 'SelectedVATType',
  SubmitComplementaryDays = 'SubmitComplementaryDays',
  SubmitVATNumber = 'SubmitVATNumber',
  SelectEnterManualVATNumber = 'SubmitManualVATNumber',
  SubmitCompanyName = 'SubmitCompanyName',
  SelectCompanyInfo = 'SelectCompanyInfo',
}

export enum NaturalTypeEnum {
  principal = 'principal',
  complementary = 'complementary',
}

export enum AccountTypeShortEnum {
  one_person_company = 'one_person_company',
  natural_person = 'natural_person',
  natural_person_student = 'natural_person_student',
  freiberufler = 'freiberufler',
  gewerbetreibender = 'gewerbetreibender',
}

export enum TenureTypesEnum {
  less_than_a_year = 'less_than_a_year',
  more_than_a_year = 'more_than_a_year',
}

export type Data = Dictionary<any>;

export type ScreenUIProps = {
  data?: Data;
  allData?: Data;
  onAction: (actionId: ActionsIds, screenData?: Data) => Promise<any> | void;
};
export type ScreenUI = {
  MainComponent: FC<ScreenUIProps>;
  SideComponent: FC<ScreenUIProps>;
};

export type PreStep = {
  id: StepsIds;
  uid?: string;
  screen: ScreenUI;
  next?: {
    [key in ActionsIds]?: PreStep;
  };
  // data?: Data;
};

export type Step = Omit<PreStep, 'next' | 'uid'> & {
  uid: string;
  next?: {
    [key in ActionsIds]?: Step;
  };
  key?: string;
  prev?: Step;
};

type Route = { uid: string; id: string }[];

export type Flow = {
  entry: Step;
  routes: Route[];
  defaultValues?: Data;
};

export type StepData = {
  uid: string;
  id: StepsIds;
  data?: Data;
  key?: string;
};

export const mainScreenUIAnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const headerUIAnimationProps = mainScreenUIAnimationProps;

export const sideScreenUIAnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.25 } },
};

export const stepsBarAnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const compineStepsData = (
  stepsData: StepData[],
  init: Data = {},
  endAt?: string,
  includeEnd?: boolean,
) => {
  return stepsData.reduce(
    (acc, stepData) => ({ ...acc, ...stepData.data }),
    init as Data,
  );
};

export const adaptSteps = (
  (uid: number) =>
  (preStep: PreStep): Step => {
    const step = { ...preStep, uid: preStep.uid ?? `_${uid++}_` } as Step;
    if (!step.next) return step;
    step.next = Object.keys(step.next).reduce((acc, key) => {
      const child = adaptSteps(step.next![key as ActionsIds] as PreStep);
      child.prev = step;
      acc![key as ActionsIds] = child;
      return acc;
    }, {} as Step['next']);
    return step;
  }
)(1);

export const getRoutes = (step: Step, base: Route = []): Route[] => {
  const stepMeta = { uid: step.uid, id: step.id, key: step.key };
  if (!step.next) return [base.concat(stepMeta)];
  return Object.keys(step.next).flatMap((key) =>
    getRoutes(step.next![key as ActionsIds] as Step, base.concat(stepMeta)),
  );
};

const normalizeVATNumber = (str?: string) => {
  return str?.replace(/\s+/g, '').toUpperCase();
};
const normalizeEmail = (str: string) => {
  return str.toLowerCase();
};

const submitBE = (data: Data, language: number, intercomLeadId: string) => {
  const values = pick(data, Object.values(FieldsNames));
  const {
    companyInfo,
    companyName,
    VATNumber,
    email,
    password,
    firstName,
    lastName,
    promocode,
    country,
    complementaryDays,
    VATType,
    occupation,
    APC1,
    APC2,
    googleIdToken,
    appleIdToken,
    selfEmployed,
  } = values;

  const accountType = (() => {
    if (values.accountType === AccountTypeShortEnum.natural_person) {
      const _data = [values.accountType, values.naturalType];
      if (values.naturalType === NaturalTypeEnum.principal) {
        if (values.VATType === VATTypeEnum.subjectToVAT) {
          return _data.concat('vat').join('_');
        }
        return _data.concat('no_vat').join('_');
      }
      return _data.join('_');
    }

    if (values.accountType === AccountTypeShortEnum.natural_person_student) {
      const _data = [values.accountType];
      if (values.VATType === VATTypeEnum.subjectToVAT) {
        return _data.concat('vat').join('_');
      }
      return _data.concat('no_vat').join('_');
    }

    return values.accountType;
  })();

  const isPrincipalStudent =
    values.accountType === AccountTypeShortEnum.natural_person_student &&
    (!values.naturalType || values.naturalType === NaturalTypeEnum.principal);

  return signupUser({
    accountType,
    complementaryDays:
      isPrincipalStudent ||
      (values.naturalType === NaturalTypeEnum.complementary &&
        !complementaryDays)
        ? 5
        : complementaryDays,
    VATType:
      values.accountType === AccountTypeShortEnum.one_person_company
        ? VATTypeEnum.subjectToVAT
        : VATType,
    VATNumber: normalizeVATNumber(
      (companyInfo?.VATNumber || VATNumber) as string,
    ),
    email: normalizeEmail(email),
    password,
    firstName,
    lastName,
    promocode,
    country,
    address: {
      street: companyInfo?.street,
      city: companyInfo?.city,
      zip: companyInfo?.zipcode,
      country: 'Belgium',
    },
    NACECodes: companyInfo?.NACECodes,
    startDate: companyInfo?.startDate,
    companyName: companyInfo?.name || companyName,
    occupation,
    APC1,
    APC2,
    language,
    intercomLeadId,
    googleIdToken,
    appleIdToken,
    isNotYetSelfEmployed: selfEmployed !== 'yes',
  });
};

const submitDE = (data: Data, language: number, intercomLeadId: string) => {
  const values = pick(data, Object.values(FieldsNames));
  const {
    email,
    password,
    firstName,
    lastName,
    promocode,
    country,
    VATReturnFrequency,
    occupation,
    naturalType = NaturalTypeEnum.principal, // no screen for it, hardcoded for now
    APC1,
    APC2,
    VATType,
    googleIdToken,
    appleIdToken,
    selfEmployed,
  } = values;

  const accountType = (() => {
    const _data = [values.accountType, naturalType];
    if (values.accountType === AccountTypeShortEnum.gewerbetreibender) {
      return _data.join('_');
    }
    if (naturalType === NaturalTypeEnum.principal) {
      if (values.VATType === VATTypeEnum.subjectToVAT) {
        return _data.concat('vat').join('_');
      }
      return _data.concat('no_vat').join('_');
    }
    return _data.join('_');
  })();

  return signupUser({
    email: normalizeEmail(email),
    password,
    firstName,
    lastName,
    address: {
      country: 'Germany',
    },
    language,
    country,
    promocode,
    accountType,
    VATType,
    VATReturnFrequency,
    APC1,
    APC2,
    occupation,
    intercomLeadId,
    googleIdToken,
    appleIdToken,
    isNotYetSelfEmployed: selfEmployed !== 'yes',
  });
};

export const onSubmit = (
  data: Data,
  language: number,
  intercomLeadId: string,
) => {
  if (data.country === AvailableCountries.belgium) {
    return submitBE(data, language, intercomLeadId);
  }
  return submitDE(data, language, intercomLeadId);
};

export const formatCreatedUserForIntercom = (
  user: IUser,
  language: string,
  stepsData: Data,
) => ({
  name: `${user.firstName} ${user.lastName}`,
  phone: user.phoneNumber || undefined,
  VAT_type: user.VATType,
  account_type: user.accountType,
  language: language,
  language_override: language,
  user_country: user.country,
  VAT_number: user.VATNumber,
  APC1: user.APC1,
  APC2: user.APC2,
  is_already_self_employed: stepsData?.selfEmployed,
  how_long_as_self_employed: stepsData?.howLongAsSE,
  thinking_of_becoming_self_employed: stepsData?.thinksOfBecomingSelfEmployed,
  ...(Array.isArray(user.NACECodes?.main)
    ? {
        NACE_main: user.NACECodes?.main.toString(),
      }
    : null),
  ...(Array.isArray(user.NACECodes?.secondary)
    ? {
        NACE_secondary: user.NACECodes?.secondary.toString(),
      }
    : null),
  ...(user.startDate
    ? {
        KBO_start_date: new Date(user.startDate).getTime() / 1000,
      }
    : null),
  address: JSON.stringify({
    ...(user.address?.city && { city: user.address.city }),
    ...(user.address?.zipcode && { zip: user.address.zip }),
    ...(user.address?.street && { street: user.address.street }),
  }),
  finished_sign_up: true,
});

export const formatCreatedUserForAmplitude = (
  user: IUser,
  language: string,
  stepsData: Data,
) => ({
  user_country: user.country,
  VAT_type: user.VATType,
  account_type: user.accountType,
  phoneNumber: user.phoneNumber,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  language: language,
  VATNumber: user.VATNumber,
  companyName: user.companyName,
  APC1: user.APC1,
  APC2: user.APC2,
  is_registered_as_self_employed: stepsData?.howLongAsSE,
  thinking_of_becoming_self_employed: stepsData?.thinksOfBecomingSelfEmployed,
  ...(Array.isArray(user.NACECodes?.main)
    ? {
        NACE_main: user.NACECodes?.main.toString(),
      }
    : null),
  ...(Array.isArray(user.NACECodes?.secondary)
    ? {
        NACE_secondary: user.NACECodes?.secondary.toString(),
      }
    : null),
  regstration_date: user.created,
  ...(user.startDate
    ? {
        KBO_start_date: new Date(user.startDate).getTime() / 1000,
      }
    : null),
  ...user.address,
});
// export const clientId =
//   '20015120266-80cvhlhqkb4tk5hq16kb4h32ei79rhq9.apps.googleusercontent.com';

export const renderBody = (text: string, showIntercom?: () => void) => {
  return text.split(/\n\n+/).map((t, i) => (
    <ReactMarkdown
      key={i}
      components={{
        p: ({ children }) => <p className="whitespace-pre-wrap">{children}</p>,
        li: ({ children }) => (
          <li className="whitespace-pre-wrap">&bull; {children}</li>
        ),
        em: ({ children }) => (
          <button
            className="text-blue-500 underline font-semibold"
            onClick={showIntercom}
          >
            {children}
          </button>
        ),
        a: ({ children, href }) => (
          <a
            href={href}
            className="text-blue-500 underline"
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        ),
      }}
    >
      {t}
    </ReactMarkdown>
  ));
};

export const getAllowedLanguages = (
  _country?: AvailableCountries,
): string[] => {
  if (_country === AvailableCountries.belgium) {
    return Object.values(AvailableLanguagesBE);
  }
  if (_country === AvailableCountries.germany) {
    return Object.values(AvailableLanguagesDE);
  }
  return Object.values(AvailableLanguages);
};

export const getCountryFromLanguage = (_lang: AvailableLanguages) => {
  if ([AvailableLanguages.fr, AvailableLanguages.nl].includes(_lang)) {
    return AvailableCountries.belgium;
  }
  if ([AvailableLanguages.de].includes(_lang)) {
    return AvailableCountries.germany;
  }
};

export type StepSkipMeta = { data: Data; next: ActionsIds };

export const countryStepKey = StepsIds.SelectCountry;

export const getCountryFromData = (
  data?: Data,
): AvailableCountries | undefined => {
  return data?.[FieldsNames.country];
};

export const getCountryStepSkipMeta = (
  _lang: AvailableLanguages,
): StepSkipMeta | undefined => {
  if ([AvailableLanguages.fr, AvailableLanguages.nl].includes(_lang)) {
    return {
      data: { [FieldsNames.country]: AvailableCountries.belgium },
      next: ActionsIds.SelectedCountryBE,
    };
  }
  if (AvailableLanguages.de === _lang) {
    return {
      data: { [FieldsNames.country]: AvailableCountries.germany },
      next: ActionsIds.SelectedCountryDE,
    };
  }
};

export const shouldSkipCountryStep = (_lang: AvailableLanguages) => {
  return (
    [AvailableLanguages.fr, AvailableLanguages.nl].includes(_lang) ||
    AvailableLanguages.de === _lang
  );
};

export const isCountryStep = (key?: string) => key === countryStepKey;

export const findStepByUID = (uid: string, step: Step): Step | undefined => {
  if (step.uid === uid) return step;
  if (step.next) {
    const steps = Object.values(step.next);
    let i = 0;
    while (i <= steps.length - 1) {
      const result = findStepByUID(uid, steps[i]);
      if (result) return result;
      i += 1;
    }
  }
};
