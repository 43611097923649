import { getRevenuesCommunication, getRevenuesQRCode } from 'api/v2/revenues';
import { GeneratedInvoiceFormValues } from 'types/invoice.types';
import { divideBy1000, round } from 'utils/math';
import { getInvoiceItemsTotalInclVAT } from '../../OtherRevenueForm/utils';
import '../styles.scss';

export const getCommunicationDependencies = (
  values: GeneratedInvoiceFormValues,
) => {
  return {
    clientId: values.clientId,
    docNumber: values.revenueNumber,
    useCommunication: !!values.settings.useCommunication,
  };
};

export const getQRCodeDependencies = (values: GeneratedInvoiceFormValues) => {
  return {
    IBAN: values.user.IBAN,
    currency: values.currency!,
    amount: round(
      Math.max(
        getInvoiceItemsTotalInclVAT(values.items || []) -
          divideBy1000(values.downPayment || 0),
        0,
      ),
    ),
    name:
      values.user.companyName ||
      `${values.user.firstName} ${values.user.lastName}`,
    communication: values.settings.useCommunication
      ? values.communication
      : values.revenueNumber,
    usePaymentQrCode: !!values.settings.usePaymentQrCode,
  };
};

export const paymentQrCodeAndCommunicationGuard = async (
  values: GeneratedInvoiceFormValues,
) => {
  let communication = values.communication || undefined;
  let paymentQrCode = values.paymentQrCode || undefined;

  if (
    values.settings.useCommunication &&
    values.clientId &&
    values.revenueNumber &&
    !communication
  ) {
    communication = await getRevenuesCommunication(
      values.clientId,
      values.revenueNumber,
    );
  }

  const name =
    values.user.companyName ||
    `${values.user.firstName} ${values.user.lastName}`;

  const iban = values.user.IBAN;

  const currency = values.currency;

  const structuredReference = values.settings.useCommunication
    ? communication
    : values.revenueNumber;

  if (
    values.settings.usePaymentQrCode &&
    name &&
    iban &&
    currency &&
    structuredReference &&
    !paymentQrCode
  ) {
    paymentQrCode = await getRevenuesQRCode({
      name,
      iban,
      amount: round(
        Math.max(
          getInvoiceItemsTotalInclVAT(values.items || []) -
            divideBy1000(values.downPayment || 0),
          0,
        ),
      ),
      currency,
      structuredReference,
    }).then((res) => res.paymentQrCode);
  }

  return { communication, paymentQrCode };
};
