import { getCategories } from 'api/v1/expenses';
import { useTranslation } from 'react-i18next';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { ExpensesCacheKeys } from 'types/cacheKeys.types';
import type { UserCountry, UserLanguage } from 'types/users.types';
import useGetCurrentCustomerData from './Authentication/useGetCurrentCustomerData';

import { isCarCategory } from 'components/pages/Expenses/Form/utils';
import i18n from 'i18n';
import Keywords from 'i18n/keywords/expenseCategories.keywords.json';
import type {
  ExpenseCategoriesDict,
  ExpenseCategory,
} from 'types/expensesCategory.types';
import { AvailableCountries } from 'types/users.types';
import { pickerIcons as icons } from 'utils/expenses/categories/icons';
import { round } from 'utils/math';

const getIcon = (category: any): string => {
  const icon = icons[category.icon as any];
  if (icon) return icon;
  if (category.list && category.list.length > 0)
    return getIcon(category.list[0]);
  return icons.mixed;
};

const adaptSlugForKeywords = (slug: string | undefined, key: string) => {
  if (slug) {
    return slug.replace('expense.category.', '').replace(`.${key}`, '');
  }
};

function modifyEachCategory(
  categories: ExpenseCategory[],
  country: AvailableCountries,
  shouldUseDisplayNameAsTitle: boolean,
): ExpenseCategory[] {
  return categories.map((originalCategory) => {
    const _shouldUseDisplayNameAsTitle =
      originalCategory.title === 'expense.category.category_vehicle.title' ||
      shouldUseDisplayNameAsTitle;
    const title = i18n.t(`${country}.${originalCategory.title}`);
    const displayNameSlug = originalCategory.displayName;
    const displayName =
      originalCategory.displayName &&
      i18n.exists(`${country}.${displayNameSlug}`)
        ? i18n.t(`${country}.${displayNameSlug}`)
        : title;

    const descriptionSlug = (() => {
      if (originalCategory.description) {
        const slug = originalCategory.description;
        if (i18n.exists(`${country}.${slug}`)) return slug;
      }
      if (displayNameSlug) {
        const slug = displayNameSlug.replace('.displayName', '.description');
        if (i18n.exists(`${country}.${slug}`)) return slug;
      }
      const slug = originalCategory.title.replace('.title', '.description');
      if (i18n.exists(`${country}.${slug}`)) return slug;
      return null;
    })();

    const questionSlug = originalCategory.question;
    const modifiedCategory = Object.assign({}, originalCategory, {
      title: shouldUseDisplayNameAsTitle ? displayName : title,
      displayName,
      description: descriptionSlug
        ? i18n.t(`${country}.${descriptionSlug}`)
        : null,
      question: questionSlug ? i18n.t(`${country}.${questionSlug}`) : undefined,
      icon: getIcon(originalCategory),
      keywords: (
        (Keywords as any)[
          adaptSlugForKeywords(
            originalCategory.displayName,
            'displayName',
          ) as any
        ] ||
        (Keywords as any)[
          adaptSlugForKeywords(originalCategory.title, 'title') as any
        ] ||
        (Keywords as any)[originalCategory.id as any] ||
        []
      )
        .filter((keyword: string) => i18n.exists(`keywords.${keyword}`))
        .map((keyword: string) => i18n.t(`keywords.${keyword}`)),
    });
    if (modifiedCategory.list) {
      return Object.assign(modifiedCategory, {
        list: modifyEachCategory(
          modifiedCategory.list.map((child) => ({
            ...child,
            parentTitle: displayName,
          })),
          country,
          _shouldUseDisplayNameAsTitle,
        ),
      });
    }

    if (country === AvailableCountries.belgium) {
      Object.assign(modifiedCategory, {
        additionalData: [
          {
            label: i18n.t('expense.vat_deductibility'),
            value: isCarCategory(modifiedCategory.id as string)
              ? '35%-100%'
              : `${round(
                  (modifiedCategory.maxDeductibleVAT as number) * 100,
                )}%`,
          },
          {
            label: i18n.t('expense.item.income_tax_deductibility'),
            value: isCarCategory(modifiedCategory.id as string)
              ? i18n.t('category.taxes.depends_on_the_car')
              : `${round(
                  (1 - (modifiedCategory.dnaPercentage as number)) * 100,
                )}%`,
          },
        ],
      });
    }

    return modifiedCategory;
  });
}

const getCategoriesById = (cates: ExpenseCategory[]) => {
  const map: ExpenseCategoriesDict = {};
  cates.forEach((cate) => {
    if (cate.id) map[cate.id] = cate;
    if (cate.list) Object.assign(map, getCategoriesById(cate.list));
  });
  return map;
};

export type ExpenseCategoriesType = {
  list: ExpenseCategory[];
  dict: ExpenseCategoriesDict;
};

export const useExpensesCategories = (
  customerCountry?: AvailableCountries,
  customerId?: string,
): UseQueryResult<ExpenseCategoriesType, unknown> => {
  const { i18n } = useTranslation();
  const { data: user } = useGetCurrentCustomerData();

  const country = customerCountry || (user?.country as UserCountry);
  const lang = i18n.language as UserLanguage;

  const result = useQuery(
    [ExpensesCacheKeys.CategoriesPicker, country, lang, customerId],
    () =>
      getCategories(country, lang).then((categories) => {
        const list = modifyEachCategory(categories, country, false);
        return {
          list,
          dict: getCategoriesById(list),
        } as ExpenseCategoriesType;
      }),
    { cacheTime: 0 },
  );

  // console.log('useExpensesCategories', result.data);

  return result;
};

export default useExpensesCategories;
