import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import formatMoney from 'utils/formatMoney';

type Props = {
  data: { key: string; value: number }[];
};

const VATCells: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const country = useCustomerCountry();
  if (!data.length) return null;
  return (
    <div className="flex flex-col gap-2">
      {data.map((item) => (
        <div
          key={item.key}
          className="rounded-10 font-semibold bg-primary-50 px-4 py-2"
        >
          <div className="flex items-center justify-between">
            <span className="pr-1 whitespace-nowrap first-letter:uppercase">
              {`${t('vatCells.cell')} ${item.key.replace('c', '')}`}
            </span>
            <span className="whitespace-nowrap">{formatMoney(item.value)}</span>
          </div>
          <span className="text-primary-600">
            {t(`taxes.vat_submission.table.cells.${country}.${item.key}`)}
          </span>
        </div>
      ))}
    </div>
  );
};

export default VATCells;
