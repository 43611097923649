import { useTranslation } from 'react-i18next';
import type { OptionType } from 'types/global.types';

const useTranslateSelectOptions = (): ((
  options: OptionType[],
) => OptionType[]) => {
  const { t } = useTranslation();

  return (options: OptionType[]) =>
    options.map((option) => ({ ...option, label: t(option.label) }));
};

export default useTranslateSelectOptions;
