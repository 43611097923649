import getSubscriptionPlans from 'api/v1/subscriptions/getSubscriptionPlans';
import { parseSubscriptionPlan } from 'components/pages/Subscriptions/Subscription.helper';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useApplySMALL25Discount from 'hooks/Growth/useApplySMALL25Discount';
import useShouldRecommendSMALLBusinessPlan from 'hooks/Growth/useShouldRecommendSMALLBusinessPlan';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { SubscriptionsCacheKeys } from 'types/cacheKeys.types';
import type { SubscriptionPlan } from 'types/subscriptions.types';
import {
  AvailableCountries,
  SubscriptionPlanNameEnum,
  VATTypeEnum,
} from 'types/users.types';
import { getStoredAuthData } from 'utils/auth';
import {
  isWeb,
  SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT,
  SMALL_BUSINESS_PLAN_DISCOUNT,
} from 'utils/constants';
import {
  isSubscriptionFreePlan,
  isSubscriptionTrialPlan,
} from 'utils/subscriptions.utils';

const useGroupSubscriptionPlans = (
  currentSubscriptionPlan?: SubscriptionPlanNameEnum,
): UseQueryResult<SubscriptionPlan[]> => {
  const shouldRecommendSMALLBusinessPlan = useShouldRecommendSMALLBusinessPlan([
    SubscriptionPlanNameEnum.small1,
    SubscriptionPlanNameEnum.small12,
    SubscriptionPlanNameEnum.pro1,
    SubscriptionPlanNameEnum.pro12,
  ]);
  const { data: user } = useGetCurrentCustomerData();
  const { subscriptionPlan: { planInternalName } = { planInternalName: '' } } =
    user || {};
  const shouldAccessPPRO = !(
    user?.VATType === VATTypeEnum.franchisee &&
    (isSubscriptionFreePlan(planInternalName) ||
      isSubscriptionTrialPlan(planInternalName))
  );

  const shouldShowSmall25Discount = useApplySMALL25Discount();

  return useQuery(
    SubscriptionsCacheKeys.SubscriptionPlans,
    getSubscriptionPlans,
    {
      enabled: !!getStoredAuthData('access_token') && isWeb,
      select: (data) =>
        data
          // BASIC is temporarly removed only from client-side via 'filter' until it's decided to totally remove it
          // from the system
          .filter(
            ({ nickname }: SubscriptionPlan): boolean =>
              (nickname.includes('basic') &&
                currentSubscriptionPlan?.includes('basic')) ||
              !nickname.includes('basic'),
          )
          .filter(
            ({ nickname }: SubscriptionPlan): boolean =>
              !nickname.includes('pro') ||
              (nickname.includes('pro') && shouldAccessPPRO),
          )
          // SMALL shouldn't show up in the plans list if the user is not eligible to the SMALL plan
          .filter(
            ({ nickname }: SubscriptionPlan): boolean =>
              !nickname.includes('small') ||
              (nickname.includes('small') && shouldRecommendSMALLBusinessPlan),
          )
          .map((plan: SubscriptionPlan) => {
            const eligibleToSMALLBusinessDiscount =
              (plan.nickname === SubscriptionPlanNameEnum.small1 ||
                plan.nickname === SubscriptionPlanNameEnum.small12) &&
              user?.country === AvailableCountries.germany;

            return eligibleToSMALLBusinessDiscount
              ? {
                  ...plan,
                  amount: shouldShowSmall25Discount
                    ? plan.amount * SMALL_BUSINESS_PLAN_ABTEST_25_DISCOUNT
                    : plan.amount * SMALL_BUSINESS_PLAN_DISCOUNT,
                }
              : plan;
          })
          .map(parseSubscriptionPlan),
    },
  );
};

export default useGroupSubscriptionPlans;
