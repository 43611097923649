import api from 'api/axiosInstance';
import { stringify } from 'query-string';
import type { Transaction } from 'types/transactions.types';

const getTransaction = async (
  transactionId: string,
  params?: Record<string, any>,
): Promise<Transaction> =>
  api
    .get<any, Transaction>(
      `/v1/transactions/${transactionId}?${stringify(
        params as Record<string, any>,
        {
          skipEmptyString: true,
          arrayFormat: 'comma',
        },
      )}`,
    )
    .then((data) => {
      return {
        ...data,
        matchedItems: data.matchedItems?.filter(Boolean),
        rawMatchedItems: data.rawMatchedItems?.filter(Boolean),
      };
    });

export default getTransaction;
