import type { IExpenseItem } from './expenses.types';
import type { Invoice, InvoiceItem } from './invoice.types';

type Charge = {
  amount: number;
  residual_value: number;
  VATAmount: number;
  year: number;
};

export enum AssetStatus {
  STOLEN = 'stolen',
  BROKEN = 'broken',
  LOST = 'lost',
}

// Data required to link a invoice to an asset
export type AssetInvoiceLinkData = {
  _id: string;
  itemId: string;
};

export type AssetInvoiceData =
  | ({
      item: InvoiceItem;
      invoiceDate: string;
      invoiceNumber: string;
      revenueNumber: string;
      amountExclVAT: number;
      capitalGain: number;
    } & AssetInvoiceLinkData)
  | null;

type AssetExpenseData = {
  _id: string;
  itemId: string;
  expenseDate: string;
  item: IExpenseItem;
};

type AssetData = {
  charge: number;
  deductibleAmount: number;
  deductibleCharge: number;
  incomeTaxDeductibility: number;
  professionalAmount: number;
  professionalAmountExclRecoverableVAT: number;
  recoverableVAT: number;
  usedProfessionalPart: number;
};

export type Asset = {
  _id: string;
  residual_value: number;
  expense: AssetExpenseData;
  profit_and_loss_metrics?: any;
  charges: Charge[];
  amortizationPeriod: number;
  status?: AssetStatus | null;
  totalAmount: number;
  VATAmount: number;
  amountToAmortize: number;
  data: AssetData;
  date: string;
  userId: string;
  writeOffDate?: string | null;
  invoice?: AssetInvoiceData;
  invoices?: Invoice[];
  name: string;
};

export type GetAmortizationPeriodResponse = {
  amount: number;
  deductibleAmount: number;
  residualValueAtStart: number;
  amortizationStartDate: string;
};
