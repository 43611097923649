import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { flushSync } from 'react-dom';
import { useQuery, useQueryClient } from 'react-query';
import { createSentryCustomErrorMsg } from 'utils/helpers';
import { FIVE_MINUTES } from 'utils/time';
import tryCatch from 'utils/tryCatch';
import type { useTableQueryOptions } from './tableQuery.types';

/**
 *
 *  this hook is responsible for accepting table state and apply this state to a fetch query and keep it in sync
 * also extends the table state api with a function to delete all cached data and start like new
 */

const useTableQuery = ({
  tableState,
  cacheKey,
  onFetch,
  ...rest
}: useTableQueryOptions) => {
  const queryClient = useQueryClient();

  const { meta, controllers } = tableState;

  const { page, sort, filtersData } = meta;

  const queryInfo = {
    page,
    sort,
    ...filtersData,
  };

  const queryKey = Array.isArray(cacheKey)
    ? [...cacheKey, queryInfo]
    : [cacheKey, queryInfo];
  const query = useQuery(queryKey, () => onFetch(queryInfo), {
    keepPreviousData: true,
    cacheTime: FIVE_MINUTES,
    ...rest,
    onSuccess: (res) => {
      if (!res?.data || !res.paging) {
        Sentry.captureException(
          { message: createSentryCustomErrorMsg('res_no_data') },
          {
            extra: {
              res: tryCatch(() => JSON.stringify(res), res),
              queryKey,
            },
          },
        );
        return; // TODO ask juan how this could happen and test more
      }
      const { data, paging } = res;
      rest.onSuccess?.({ data, paging });
      if (data.length === 0 && paging.totalCount > 0) {
        // if (paging.page === paging.pagesCount) {
        //   // this should never happen, if happend we should return or it may cause infinite loop
        //   return;
        // }
        // this can happen when trying to reset or refetch the current query,
        // the response page might be empty, then we should remove any cache and set page number to 1
        flushSync(() => {
          controllers.setPageNumber(paging.pagesCount);
        });
        queryClient.removeQueries(cacheKey);
      }
    },
  });

  const reset = useCallback(() => {
    controllers.setSelectedRows([]);
    queryClient.removeQueries(cacheKey);
    return query.refetch();
  }, []);

  return {
    query,
    meta,
    controllers: { ...controllers, reset },
  };
};

export default useTableQuery;
