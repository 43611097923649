import { Table } from 'components/atoms/Table';
import CustomLoader from 'components/molecules/CustomLoader.component';
import SimpleError from 'components/molecules/SimpleError.component';
import Papa from 'papaparse';
import type { FC } from 'react';
import { useQuery } from 'react-query';
import { BankTransactionsCacheKeys } from 'types/cacheKeys.types';

const fetchCSVData = (url: string) =>
  new Promise((resolve, reject) => {
    Papa.parse(url, {
      header: true,
      download: true,
      complete: (response) => {
        resolve(response.data);
      },
      error: (error) => {
        reject(error);
      },
    });
  });

const CSVViewer: FC<{ url: string }> = ({ url }) => {
  const csvQuery = useQuery(
    [BankTransactionsCacheKeys.CreditCardStatementCSVFileData, url],
    () => fetchCSVData(url),
  );

  if (csvQuery.isLoading || csvQuery.isRefetching)
    return (
      <div className="h-screen flex items-center justify-center">
        <CustomLoader />
      </div>
    );

  if (csvQuery.isError || !Array.isArray(csvQuery.data))
    return (
      <SimpleError
        messageClassName="!text-white"
        onRetry={() => csvQuery.refetch()}
      />
    );

  const tableColumns = Object.keys(csvQuery.data?.[0]) || [];

  return (
    <Table className="overflow-scroll">
      <Table.Head>
        {tableColumns?.map((column) => (
          <Table.Cell
            key={column}
            className="min-w-[240px] border-l text-sm !px-3 !py-4 bg-primary-25 font-semibold"
          >
            {column}
          </Table.Cell>
        ))}
      </Table.Head>

      {csvQuery.data.map((entry, index) => (
        <Table.Row key={index} className="hover:bg-gray-50">
          {tableColumns?.map((column) => (
            <Table.Cell key={column} className="border-l text-xs !px-3 !py-2">
              {entry[column]?.trim() || '-'}
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table>
  );
};

export default CSVViewer;
