import api from 'api/axiosInstance';
import type { BankProviders } from 'types/banks.types';
import type { CreateConnectorConnectionResponse } from 'types/transactions.types';

export type CreateConnectorParams = {
  provider: BankProviders;
  bankCode?: string;
  bankId?: string | number;
  accountReference?: string;
  successRedirectUri: string;
  errorRedirectUri: string;
};

export const createConnectorConnection = (
  params: CreateConnectorParams,
): Promise<CreateConnectorConnectionResponse> =>
  api.post(`/v2/connectors/${params.provider}/start-flow`, params);
