import { TrashIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import List from 'components/atoms/List';
import { Modal } from 'components/atoms/Modal';
import SectionSeparator from 'components/atoms/SectionSeparator.component';
import { Title } from 'components/atoms/Title';
import { SizesEnum } from 'components/atoms/Title/Title.enum';
import CustomLoader from 'components/molecules/CustomLoader.component';
import AccountDeletion from 'components/organisms/SettingsViews/AccountDetailsView/AccountDeletion';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import logout from 'hooks/Authentication/useLogout';
import useShouldRecommendSMALLBusinessPlan from 'hooks/Growth/useShouldRecommendSMALLBusinessPlan';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import {
  useRedirectToCustomerBillingPortal,
  useSubscriptionPlan,
} from 'hooks/Subscriptions';
import useGetAccessibleSubscriptionPlans from 'hooks/Subscriptions/useGetAccessibleSubscriptionPlans';
import useStripeSubscription from 'hooks/Subscriptions/useStripeSubscription';
import useSubscriptionPlanInfo from 'hooks/Subscriptions/useSubscriptionPlanInfo';
import useFeatures from 'hooks/useFeatures';
import _get from 'lodash/get';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useLocation, useNavigate } from 'react-router';
import type { SubscriptionPlan } from 'types/subscriptions.types';
import type { IUser } from 'types/users.types';
import { SubscriptionPlanNameEnum } from 'types/users.types';
import { WEBAPP_PAYWALL_CLICKEDOPENDELETEACCOUNTMODAL } from 'utils/amplitude/events/global.amplitude';
import { excludeNumbers } from 'utils/helpers';
import { round } from 'utils/math';
import {
  isSubscriptionNoWebAccessPlan,
  isSubscriptionProPlan,
  isSubscriptionSmallPlan,
} from 'utils/subscriptions.utils';
import {
  getCustomerGroupSubscriptionPlan,
  getMonthlyAmount,
  isDowngradingSubscriptionPlan,
} from '../Subscription.helper';
import type { PlanFeatureType } from '../Subscription.PlanFeatures';
import { getPlanFeatures } from '../Subscription.PlanFeatures';

export const PlanWidget: FC<{
  plan: Partial<SubscriptionPlan>;
  currentPlan: SubscriptionPlanNameEnum;
  user?: IUser;
  installment: 1 | 12;
  customFeatures?: PlanFeatureType[];
  onClickSubscribe?: () => void;
}> = ({
  plan,
  currentPlan,
  user,
  installment,
  customFeatures,
  onClickSubscribe,
}) => {
  const { t, i18n } = useTranslation();

  const country = useCustomerCountry();

  const { shortNickname, nickname, interval, amount } = plan;

  const planGroup = excludeNumbers(shortNickname);

  const isDowngradingPlan = isDowngradingSubscriptionPlan(
    currentPlan,
    nickname as SubscriptionPlanNameEnum,
  );

  const isCurrentPlan = currentPlan === nickname;

  const {
    name: planName,
    discountBadgeText,
    subtitle,
    badge,
  } = useSubscriptionPlanInfo(planGroup);

  const planDisplayName = t(planName).toUpperCase();

  const _amount = round(
    getMonthlyAmount(interval as string, amount as number),
    100,
  );

  const { Icon: BadgeIcon, text: patchText } = badge || {};

  const { subscribe, isLoading } = useStripeSubscription();

  const hasDiscountBadge =
    discountBadgeText && i18n.exists(discountBadgeText as string);

  const planFeatures =
    customFeatures ||
    getPlanFeatures(country)[planGroup]?.features?.filter(Boolean);

  const shouldHideController =
    isDowngradingPlan ||
    (nickname?.includes('basic') && !isCurrentPlan) ||
    (nickname === SubscriptionPlanNameEnum.free &&
      currentPlan.includes('trial'));

  return (
    <div className="relative bg-white p-5 px-6 pb-32 flex-1 m-2 rounded-2xl hover:-translate-y-5 transition-all duration-500 border border-transparent hover:border-taxes">
      {hasDiscountBadge ? (
        <div className="absolute left-0 right-0 top-0 bottom-[65%] bg-transparent overflow-hidden">
          <div className="bg-expenses rotate-[30deg] absolute right-0 top-0 translate-x-52 translate-y-12 w-[460px] xl:w-[600px] text-center py-2">
            <span className="text-white font-bold text-base">
              {t(discountBadgeText as string)}
            </span>
          </div>
        </div>
      ) : null}
      <div className="flex items-start justify-between">
        <div>
          <h1 className="text-primary text-xl font-semibold">
            {planDisplayName}
          </h1>

          <Title type={SizesEnum.h5} className="my-2 text-taxes">
            € {_amount}{' '}
            <span className="text-xl font-medium">
              / {t('subscriptions_screen.plans.month_abbr')}
            </span>{' '}
            {_amount > 0 ? (
              <span className="text-xs text-primary-500 font-normal tracking-normal">
                ({t('excl_vat')})
              </span>
            ) : null}
          </Title>

          <h2 className="text-primary-400 text-sm font-semibold">
            {t(subtitle) || t(`subscriptions_screen.billed_${installment}`)}
          </h2>
        </div>

        {badge && !hasDiscountBadge ? (
          <div className="flex items-center space-x-1 bg-bank-100 py-[6px] px-3 rounded-full border border-bank-400">
            <BadgeIcon width={22} className="text-bank-900" />

            <span className="text-bank text-sm font-semibold">
              {t(patchText as string)}
            </span>
          </div>
        ) : null}
      </div>

      <SectionSeparator className="my-4" />

      <h2 className="text-primary-400 text-sm font-semibold">
        {t('subscriptions_screen.plans.includes', {
          planName: planDisplayName,
        })}
      </h2>

      <List
        data={(planFeatures || []) as PlanFeatureType[]}
        itemClassName="!pb-0 !pl-0 py-3 items-center"
        itemTextClassName="!pl-3 font-semibold"
        iconClassName="!text-primary !w-4 !h-4"
      />

      {shouldHideController ? null : (
        <div className="mt-8 absolute left-4 right-4 bottom-5 group">
          <Button
            color="taxes"
            size="xl"
            type="button"
            className={classNames('w-full', {
              'bg-primary-400 hover:bg-primary-500': isCurrentPlan,
            })}
            disabled={isCurrentPlan}
            onClick={() => {
              onClickSubscribe?.();
              subscribe(plan);
            }}
            loading={isLoading}
          >
            {isCurrentPlan
              ? t('subscriptions_screen.plans.current_plan')
              : t('subscriptions_screen.plans.get_plan_cta', {
                  planName: planDisplayName,
                })}
          </Button>
        </div>
      )}
    </div>
  );
};

const SubscriptionPlans: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: user } = useGetCurrentCustomerData();

  const planInternalName = useSubscriptionPlan()?.planInternalName;

  const [installment, setInstallment] = useState<1 | 12>(12);

  const shouldRecommendSMALLBusinessPlan = useShouldRecommendSMALLBusinessPlan([
    SubscriptionPlanNameEnum.small1,
    SubscriptionPlanNameEnum.small12,
  ]);

  const currentPlanGroup = getCustomerGroupSubscriptionPlan(
    planInternalName as SubscriptionPlanNameEnum,
  );

  const suggestedPlanName = shouldRecommendSMALLBusinessPlan
    ? t(`subscriptions_screen.plans.small_${user?.country}`)
    : t('subscriptions_screen.plans.pro');

  const { subscriptionText, name: currenPlanName } =
    useSubscriptionPlanInfo(currentPlanGroup);

  const { mutate, isLoading: isRedirectingToCustomerPortal } =
    useRedirectToCustomerBillingPortal();

  const { getAccessiblePlans, isLoading: isLoadingPlans } =
    useGetAccessibleSubscriptionPlans();

  const plansBasedOnInstallments = getAccessiblePlans(installment);

  const requiredCustomerFields = useFeatures(
    'revenue.required_customer_data_for_subscription',
  ) as string[];

  const isMissingCustomerData = requiredCustomerFields.some(
    (key) => !_get(user, key),
  );

  if (isLoadingPlans) return <CustomLoader className="h-screen" />;

  return (
    <Modal
      name="subscription plan"
      color="taxes"
      trigger={() => null}
      onBlur={() => null}
      render={() => (
        <div className="p-12">
          <div className="my-3 mx-2 mt-0 mb-6 flex items-start justify-between">
            <div className="max-w-[60%]">
              <div>
                <Title type={SizesEnum.h5} className="text-primary">
                  {t(subscriptionText, {
                    recommendedPlanName: suggestedPlanName.toUpperCase(),
                    planName: t(currenPlanName),
                  })}
                </Title>

                {isSubscriptionProPlan(planInternalName) ||
                isSubscriptionSmallPlan(planInternalName) ? (
                  <Button
                    size="xl"
                    color="taxes"
                    type="button"
                    onClick={() => mutate()}
                    className="mt-2"
                    loading={isRedirectingToCustomerPortal}
                  >
                    {t('avatarmenu.billing')}
                  </Button>
                ) : null}
              </div>

              {isMissingCustomerData ? (
                <ReactMarkdown
                  className="w-[85%] mt-3 text-sm"
                  components={{
                    a: ({ children, href }) => (
                      <Link
                        href={href}
                        className="text-orange-500 hover:border-b-orange-500 font-medium"
                      >
                        {children}
                      </Link>
                    ),
                  }}
                >
                  {t('subscriptions_screen.plans.missing_info_hint', {
                    link: '/settings/personalDetails#company',
                  })}
                </ReactMarkdown>
              ) : null}
            </div>

            <div>
              <div className="bg-white rounded-full flex p-1">
                <button
                  type="button"
                  onClick={() => setInstallment(1)}
                  className={classNames('py-2 px-5 font-semibold', {
                    'bg-primary rounded-full text-white': installment === 1,
                  })}
                >
                  {t('subscriptions_screen.plans.monthly')}
                </button>
                <button
                  type="button"
                  className={classNames('py-2 px-5 font-semibold', {
                    'bg-primary rounded-full text-white': installment === 12,
                  })}
                  onClick={() => setInstallment(12)}
                >
                  {t('subscriptions_screen.plans.yearly')}
                </button>
              </div>

              <p className="text-right text-sm mt-2 font-semibold">
                {t('subscriptions_screen.plans.save_text')}
              </p>
            </div>
          </div>
          <div
            className={classNames('flex', {
              'space-x-14': (plansBasedOnInstallments?.length as number) < 3,
            })}
          >
            {plansBasedOnInstallments?.map(
              (plan: Partial<SubscriptionPlan>) => (
                <PlanWidget
                  plan={plan}
                  key={plan.id}
                  currentPlan={planInternalName as SubscriptionPlanNameEnum}
                  user={user}
                  installment={installment}
                />
              ),
            )}
          </div>

          <AccountDeletion
            user={user as IUser}
            Trigger={({ api, isLoading }: any) => (
              <Button
                size="md"
                type="button"
                color="primary"
                structure="text"
                icon={TrashIcon}
                onClick={() => api.openModal()}
                tracingEvent={WEBAPP_PAYWALL_CLICKEDOPENDELETEACCOUNTMODAL}
                loading={isLoading}
                className="ml-3 mt-5 opacity-50"
              >
                {t('settings.controllers.deleteAccount')}
              </Button>
            )}
          />
        </div>
      )}
      defaultOpen
      isFocusTrapActive={false}
      onClose={() => {
        if (isSubscriptionNoWebAccessPlan(planInternalName)) {
          return logout();
        }
        if ((location.state as any)?.from === 'paymentFailed') {
          return navigate('/');
        }

        return navigate(-1);
      }}
      width={(plansBasedOnInstallments?.length as number) < 3 ? '6xl' : '7xl'}
      dialogPanalClassName="!p-0 !bg-taxes-100"
    />
  );
};

export default SubscriptionPlans;
