import getYearlyReviewWrapped from 'api/v2/shared/getYearlyReviewWrapped';

import ContainerWithShadow from 'components/atoms/ContainerWithShadow';
import CustomLoader from 'components/molecules/CustomLoader.component';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useQuery } from 'react-query';
import { YearlyReviewCacheKeys } from 'types/cacheKeys.types';
import CSQuotesSection from './CSQuotesSection.component';
import DeductibleExpensesSection from './DeductibleExpensesSection.component';
import EarningsSection from './EarningsSection.component';
import { getReviewYears } from './helpers';
import QuartersSummarySection from './QuartersSummarySection.component';
import WelcomeBanner from './WelcomeBanner.component';
import WelcomeText from './WelcomeText.component';

const YearlyReview: FC = () => {
  const { t } = useTranslation();

  const { data, isLoading, isRefetching } = useQuery(
    YearlyReviewCacheKeys.YearlyReviewData,
    getYearlyReviewWrapped,
    {
      staleTime: 0,
    },
  );

  const { year, newYear } = getReviewYears();

  if (isLoading || isRefetching)
    return (
      <div className="h-screen flex items-center justify-center">
        <CustomLoader />;
      </div>
    );

  return (
    <div>
      <ContainerWithShadow>
        <WelcomeBanner />

        <WelcomeText />

        <div className="px-32 pb-14">
          <EarningsSection data={data} />

          <CSQuotesSection data={data} />

          <DeductibleExpensesSection data={data} />

          <p className="mt-10 text-primary-700 font-semibold text-lg text-center">
            {t('yearly_review.quarters_widget.title')}
          </p>

          <QuartersSummarySection data={data} />

          <ReactMarkdown
            className="mt-10"
            components={{
              p: ({ children }) => (
                <p className="mb-4 font-semibold text-center text-primary-700">
                  {children}
                </p>
              ),
              em: ({ children }) => (
                <span className="mb-4 text-center font-light text-primary-700 italic">
                  {children}
                </span>
              ),
            }}
          >
            {t('yearly_review.footer_text', {
              year,
              newYear,
            })}
          </ReactMarkdown>
        </div>
      </ContainerWithShadow>
    </div>
  );
};

export default YearlyReview;
