import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

const useQueryParams = (): { [x: string]: unknown } => {
  const { search } = useLocation();

  return useMemo(() => queryString.parse(search), [search]);
};

export default useQueryParams;
