/// list ///
export const WEBAPP_CLIENTSLIST_CLICKEDADDCLIENT =
  'WEBAPP_CLIENTSLIST_CLICKEDADDCLIENT';
export const WEB_CLIENTSLIST_FILTEREDLIST = 'WEB_CLIENTSLIST_FILTEREDLIST';
export const WEBAPP_CLIENTSLIST_CLICKEDCLIENT =
  'WEBAPP_CLIENTSLIST_CLICKEDCLIENT';
export const WEBAPP_CLIENTSLIST_BULKSELECT = 'WEBAPP_CLIENTSLIST_BULKSELECT';
export const WEBAPP_CLIENTSLIST_CHANGETABLEPAGE =
  'WEBAPP_CLIENTSLIST_CHANGETABLEPAGE';
export const WEBAPP_CLIENTSLIST_BULKDELETE = 'WEBAPP_CLIENTSLIST_BULKDELETE';

/// form ///

export const WEBAPP_CLIENTITEM_CLICKEDCOUNTRY =
  'WEBAPP_CLIENTITEM_CLICKEDCOUNTRY';
export const WEBAPP_CLIENTITEM_UPDATEDCOUNTRY =
  'WEBAPP_CLIENTITEM_UPDATEDCOUNTRY';
export const WEBAPP_CLIENTITEM_CLICKEDNAME = 'WEBAPP_CLIENTITEM_CLICKEDNAME';
export const WEBAPP_CLIENTITEM_UPDATEDNAME = 'WEBAPP_CLIENTITEM_UPDATEDNAME';
export const WEBAPP_CLIENTITEM_CLICKEDTYPE = 'WEBAPP_CLIENTITEM_CLICKEDTYPE';
export const WEBAPP_CLIENTITEM_UPDATEDTYPE = 'WEBAPP_CLIENTITEM_UPDATEDTYPE';
export const WEBAPP_CLIENTITEM_CLICKEDVATNUMBER =
  'WEBAPP_CLIENTITEM_CLICKEDVATNUMBER';
export const WEBAPP_CLIENTITEM_UPDATEDVATNUMBER =
  'WEBAPP_CLIENTITEM_UPDATEDVATNUMBER';
export const WEBAPP_CLIENTITEM_CLICKEDVATSTATUS =
  'WEBAPP_CLIENTITEM_CLICKEDVATSTATUS';
export const WEBAPP_CLIENTITEM_UPDATEDVATSTATUS =
  'WEBAPP_CLIENTITEM_UPDATEDVATSTATUS';
export const WEBAPP_CLIENTITEM_CLICKEDSTREET =
  'WEBAPP_CLIENTITEM_CLICKEDSTREET';
export const WEBAPP_CLIENTITEM_CLICKEDPOSTALCODE =
  'WEBAPP_CLIENTITEM_CLICKEDPOSTALCODE';
export const WEBAPP_CLIENTITEM_CLICKEDCITY = 'WEBAPP_CLIENTITEM_CLICKEDCITY';
export const WEBAPP_CLIENTITEM_CLICKEDEMAIL = 'WEBAPP_CLIENTITEM_CLICKEDEMAIL';
export const WEBAPP_CLIENTITEM_CLICKEDPHONENUMBER =
  'WEBAPP_CLIENTITEM_CLICKEDPHONENUMBER';
export const WEBAPP_CLIENTITEM_CLICKEDCONTACTNAME =
  'WEBAPP_CLIENTITEM_CLICKEDCONTACTNAME';
export const WEBAPP_CLIENTITEM_CLICKEDNOTES = 'WEBAPP_CLIENTITEM_CLICKEDNOTES';
export const WEBAPP_CLIENTITEM_CLICKEDSAVE = 'WEBAPP_CLIENTITEM_CLICKEDSAVE';
export const WEBAPP_CLIENTITEM_CLICKEDDELETE =
  'WEBAPP_CLIENTITEM_CLICKEDDELETE';

export const WEBAPP_CLIENTITEM_CLICKEDELIGIBLEFORREVERSECHARGE =
  'WEBAPP_CLIENTITEM_CLICKEDELIGIBLEFORREVERSECHARGE';
export const WEBAPP_CLIENTITEM_UPDATEDELIGIBLEFORREVERSECHARGE =
  'WEBAPP_CLIENTITEM_UPDATEDELIGIBLEFORREVERSECHARGE';
