import Joi from 'joi';
import { checkVAT, formatErrorMessage } from 'validation/commonValidators';

const validationSchema = Joi.object({
  name: Joi.string()
    .min(1)
    .max(300)
    .required()
    .messages(formatErrorMessage('client', 'name')),
  type: Joi.string()
    .valid('business', 'private', 'diplomatic')
    .required()
    .messages(formatErrorMessage('client', 'type')),
  address: Joi.when('type', {
    is: 'business',
    then: Joi.object({
      city: Joi.string()
        .min(1)
        .max(200)
        .required()
        .messages(formatErrorMessage('client', 'city')),
      street: Joi.string()
        .min(1)
        .max(200)
        .required()
        .messages(formatErrorMessage('client', 'street')),
      zip: Joi.string()
        .min(1)
        .max(200)
        .required()
        .messages(formatErrorMessage('client', 'zipcode')),
    }),
    otherwise: Joi.object({
      city: Joi.string()
        .max(200)
        .allow('', null)
        .messages(formatErrorMessage('client', 'city')),
      street: Joi.string()
        .max(300)
        .allow('', null)
        .messages(formatErrorMessage('client', 'street')),
      zip: Joi.string()
        .max(200)
        .allow('', null)
        .messages(formatErrorMessage('client', 'zipcode')),
    }),
  }),

  VATStatus: Joi.when('$shouldShowVATStatus', {
    is: true,
    then: Joi.string()
      .required()
      .messages({ '*': 'client.VATStatus.any.required' }),
  }),

  VATNumber: Joi.string()
    .trim()
    .custom((value, helpers) => {
      const isEEA = helpers?.prefs?.context?.isEEA;
      // We do not validate the schema if no value or it's outside of EU.
      if (!value || !isEEA) return value;

      const { isValid } = checkVAT(value);
      if (!isValid) return helpers.error('any.invalid');
      return value;
    })
    .when('$isVATNumberRequired', {
      is: true,
      then: Joi.required(),
      otherwise: Joi.allow(null).optional(),
    })
    .messages({
      'any.invalid': 'settings.VATNumber.any.invalid',
      '*': 'settings.VATNumber.string.empty',
    }),

  email: Joi.string()
    .trim()
    .allow('', null)
    .email({ tlds: { allow: false } })
    .messages(formatErrorMessage('client', 'email')),

  phoneNumber: Joi.string()
    .trim()
    .max(20)
    .allow('', null)
    .messages({ '*': 'client.phone_number.string.max' }),

  notes: Joi.string()
    .trim()
    .max(500)
    .allow('', null)
    .messages({ '*': 'client.notes.string.max' }),

  contactName: Joi.string()
    .trim()
    .max(100)
    .allow('', null)
    .messages({ '*': 'client.contact_name.string.max' }),
});

export default validationSchema;
