import api from 'api/axiosInstance';
import type {
  IAPIChangePasswordResponse,
  IAPILoginBody,
} from 'types/auth.types';
import type { IUser } from 'types/users.types';
import { Role } from 'types/users.types';
import {
  overrideAmplDefaultProps,
  setAmplitudeUser,
  setAmplitudeUserProperties,
} from 'utils/amplitude';
import {
  customerEmailAmplProp,
  isWeb,
  signupDateStorageKey,
} from 'utils/constants';

const service = '/v2/users';

export const setWebAmplitudeUser = (email: string): void => {
  setAmplitudeUser(email);
  setAmplitudeUserProperties({
    role: Role.user,
  });
};

export const getAuthMethods = (): Promise<any> =>
  api.get(`${service}/auth-methods`);

export const addAuthMethod = (
  method: 'google' | 'apple' | 'password',
  body: { googleIdToken?: string; appleIdToken?: string; password?: string },
): Promise<any> => api.post(`${service}/auth-methods/${method}`, body);

export const deleteAuthMethod = (
  method: 'google' | 'apple' | 'password',
): Promise<any> => api.delete(`${service}/auth-methods/${method}`);

export const login = (
  body: IAPILoginBody,
  params?: Record<string, any>,
): Promise<any> =>
  api.post(`${service}/login`, body, params).then((data: any): IUser => {
    setWebAmplitudeUser(data?.data?.email);

    return data;
  });

export const logout = (
  clientId: string | undefined = undefined,
): Promise<void> => api.post(`${service}/logout`, { clientId });

export const changePassword = (
  old: string,
  newPassword: string,
): Promise<IAPIChangePasswordResponse> =>
  api.post(`${service}/change-password`, {
    oldPassword: old,
    newPassword: newPassword,
  });

export const resetPasswordRequest = (email: string): Promise<void> =>
  api.post(`${service}/reset-password-request`, { email });

export const getUserHash = (): Promise<string> =>
  api
    .get(`${service}/intercom-user-hash/web`)
    .then(({ userHash }: any) => userHash);

export const resetPassword = (
  token: string,
  newPassword: string,
): Promise<void> =>
  api.post(`${service}/reset-password`, {
    reset_password_token: token,
    newPassword,
  });

export const getUserData = (): Promise<IUser> =>
  api.get(service).then((data: any): IUser => {
    if (isWeb) {
      setWebAmplitudeUser(data.email);
      if (data.created) {
        localStorage.setItem(signupDateStorageKey, data.created);
      }
    } else {
      overrideAmplDefaultProps({ [customerEmailAmplProp]: data.email });
    }

    return data;
  });
