import classNames from 'classnames';
import type { FC } from 'react';
import type { TableCellProps } from './table.types';

export const TableCell: FC<TableCellProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <td
      className={classNames('text-md font-assistant py-4 px-3', className)}
      {...props}
    >
      {children}
    </td>
  );
};
