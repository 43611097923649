import classNames from 'classnames';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useVATReturnFrequency from 'hooks/shared/useVATReturnFrequency';
import useGetCustomeNavigationLink from 'hooks/useGetCustomNavigationLink';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import { Link } from 'react-router-dom';
import type { LockReasonType } from 'types/global.types';
import type { AvailableCountries } from 'types/users.types';
import getPeriodSlug from 'utils/taxes/getPeriodSlug';
import getTaxTitle from 'utils/taxes/getTaxTitle';
import { Helper } from './Helper';

const markdownComponents: ReactMarkdownOptions['components'] = {
  ul: ({ children }) => (
    <ul className="list-disc list-inside mt-1">{children}</ul>
  ),
};

export type LockedByTaxesWarningProps = {
  taxLockReasons: LockReasonType[];
  fieldSlug: 'invoice_date' | 'payment_date' | 'expense_date' | 'period';
  className?: string;
  name?: string;
  dataTypeSlug: 'period' | 'date';
};

type DetailsProps = {
  taxLockReasons?: LockReasonType[];
  dataTypeSlug: LockedByTaxesWarningProps['dataTypeSlug'];
  className?: string;
};
const Details: FC<DetailsProps> = ({
  taxLockReasons,
  className,
  dataTypeSlug,
}) => {
  const { t } = useTranslation();
  const VATFrequency = useVATReturnFrequency();
  const country = useCustomerCountry() as AvailableCountries;
  const getLink = useGetCustomeNavigationLink();
  return (
    <div className={classNames('whitespace-pre-line', className)}>
      <p className="py-2">
        {t('document.info_bubble.locked_by_taxes.list_title')}
        <ul className="list-disc list-inside mt-1">
          {taxLockReasons?.map((reason, i) => {
            return (
              <li key={i}>
                <Link
                  className="underline"
                  to={getLink(
                    `/taxes/${reason.readableType}/${getPeriodSlug({
                      period: reason.period,
                    })}`,
                  )}
                >
                  {getTaxTitle(
                    {
                      type: reason.type,
                      period: reason.period,
                    },
                    VATFrequency,
                    country,
                  )}
                </Link>
              </li>
            );
          })}
        </ul>
      </p>
      <p className="py-2">
        {t('document.info_bubble.locked_by_taxes.period_list_footer')}
        {dataTypeSlug === 'period' && (
          <div className="whitespace-normal">
            <ReactMarkdown components={markdownComponents}>
              {t(
                'document.info_bubble.locked_by_taxes.unlock_document_process',
              )}
            </ReactMarkdown>
          </div>
        )}
      </p>
    </div>
  );
};

export const SubmitLockByTaxesToastMessage: FC<
  Pick<LockedByTaxesWarningProps, 'fieldSlug'>
> = ({ fieldSlug }) => {
  const { t } = useTranslation();

  const dataTypeSlug = fieldSlug === 'period' ? 'period' : 'date';

  return (
    <>
      {t(
        'document.error_bubble.locked_by_tax.error_toast.require_changes.' +
          fieldSlug,
      ) +
        ' ' +
        t(
          'document.error_bubble.locked_by_tax.require_changes.text2.' +
            dataTypeSlug,
        )}
    </>
  );
};

const SubmitLockByTaxes: FC<LockedByTaxesWarningProps> = ({
  taxLockReasons,
  fieldSlug,
  className,
  name,
  dataTypeSlug,
}) => {
  const { t } = useTranslation();
  const VATFrequency = useVATReturnFrequency();
  const country = useCustomerCountry() as AvailableCountries;
  const getLink = useGetCustomeNavigationLink();

  return (
    <Alert
      name={name}
      type="error"
      className={classNames('TAX_LOCK_REASON', className)}
      description={
        <>
          <span className="block">
            {t(
              'document.error_bubble.locked_by_tax.require_changes.text1.' +
                fieldSlug,
            ) +
              ' ' +
              t(
                'document.error_bubble.locked_by_tax.require_changes.with_links.text2.' +
                  dataTypeSlug,
              )}
          </span>
          <ul className="list-disc list-inside mt-1">
            {taxLockReasons.map((reason, i) => (
              <li key={i}>
                <Link
                  className="underline"
                  to={getLink(
                    `/taxes/${reason.readableType}/${getPeriodSlug({
                      period: reason.period,
                    })}`,
                  )}
                >
                  {getTaxTitle(
                    {
                      type: reason.type,
                      period: reason.period,
                    },
                    VATFrequency,
                    country,
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </>
      }
      renderButtons={() => (
        <Helper
          trigger={(openModal) => (
            <Button
              structure="text"
              className="underline"
              color="primary"
              onClick={openModal}
            >
              {t('learn_more')}
            </Button>
          )}
        >
          <Details
            taxLockReasons={taxLockReasons}
            dataTypeSlug={dataTypeSlug}
          />
        </Helper>
      )}
    />
  );
};

export const ExpenseSubmitLockedByTaxes: FC<
  Omit<LockedByTaxesWarningProps, 'dataTypeSlug'> & {
    isCreditNote: boolean;
  }
> = (props) => {
  const dataTypeSlug = props.fieldSlug === 'period' ? 'period' : 'date';

  const docType = props.isCreditNote ? 'credit-note' : 'expense';

  return (
    <SubmitLockByTaxes
      name={`expense:${docType} ${dataTypeSlug} locked`}
      {...props}
      dataTypeSlug={dataTypeSlug}
    />
  );
};

export const InvoiceSubmitLockedByTaxes: FC<
  Omit<LockedByTaxesWarningProps, 'dataTypeSlug'> & {
    type: string;
  }
> = (props) => {
  const dataTypeSlug = props.fieldSlug === 'period' ? 'period' : 'date';
  return (
    <SubmitLockByTaxes
      name={`invoice:${props.type} ${dataTypeSlug} locked`}
      {...props}
      dataTypeSlug={dataTypeSlug}
    />
  );
};

export const InvoiceSubmitLockedByTaxesToastDetails: FC<DetailsProps> = (
  props,
) => (
  <Helper.Inline>
    <Details {...props} />
  </Helper.Inline>
);
