export const WEBAPP_HOMEPAGE_TODO_CLICKEDCONNECTBANKACCOUNT =
  'WEBAPP_HOMEPAGE_TODO_CLICKEDCONNECTBANKACCOUNT';
export const WEBAPP_HOMEPAGE_TODO_CLICKEDTODOITEM =
  'WEBAPP_HOMEPAGE_TODO_CLICKEDTODOITEM';
export const WEBAPP_HOMEPAGE_PROFITANALYTICS_CLICKEDSEEMORE =
  'WEBAPP_HOMEPAGE_PROFITANALYTICS_CLICKEDSEEMORE';
export const WEBAPP_HOMEPAGE_TAXDEADLINES_CLICKEDSEEMORE =
  'WEBAPP_HOMEPAGE_TAXDEADLINES_CLICKEDSEEMORE';
export const WEBAPP_HOMEPAGE_TAXDEADLINES_CLICKEDTAXDEADLINEITEM =
  'WEBAPP_HOMEPAGE_TAXDEADLINES_CLICKEDTAXDEADLINEITEM';
export const WEBAPP_HOMEPAGE_TAXDEADLINES_CLICKEDTAXDEADLINECALENDARMARK =
  'WEBAPP_HOMEPAGE_TAXDEADLINES_CLICKEDTAXDEADLINECALENDARMARK';
export const WEBAPP_HOMEPAGE_REVENUESIMULATOR_CLICKEDIMPROVESIMULATION =
  'WEBAPP_HOMEPAGE_REVENUESIMULATOR_CLICKEDIMPROVESIMULATION';
export const WEBAPP_HOMEPAGE_GETHELP_CLICKEDGETHELPBUTTON =
  'WEBAPP_HOMEPAGE_GETHELP_CLICKEDGETHELPBUTTON';
export const WEBAPP_HOMEPAGE_MUSTREADLINKS_CLICKEDMUSTREADLINK =
  'WEBAPP_HOMEPAGE_MUSTREADLINKS_CLICKEDMUSTREADLINK';
export const WEBAPP_HOMEPAGE_TODO_MARKEDITEMASDONE =
  'WEBAPP_HOMEPAGE_TODO_MARKEDITEMASDONE';
export const WEBAPP_HOMEPAGE_TODO_MARKEDITEMASUNDONE =
  'WEBAPP_HOMEPAGE_TODO_MARKEDITEMASUNDONE';
export const WEB_HOME_SCREEN_IMPROVETAXESTIMATION =
  'WEB_HOME_SCREEN_IMPROVETAXESTIMATION';
