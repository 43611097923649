import type { FC } from 'react';
import type { Currency } from 'types/global.types';
import type { AvailableLanguages } from 'types/users.types';
import type { NumberFieldProps } from '../Number/Number';
import NumberField from '../Number/Number';

export type PaymentAmountProps = NumberFieldProps & {
  currency?: Currency;
  formatLang?: AvailableLanguages;
  inputRef?: any;
};

const PaymentAmount: FC<PaymentAmountProps> = ({
  currency,
  formatLang,
  ...rest
}) => {
  return (
    <NumberField
      format={{ asMoney: true, currency, lang: formatLang }}
      {...rest}
    />
  );
};

export default PaymentAmount;
