import api from 'api/axiosInstance';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import qs from 'query-string';
import { UploadTypesEnum } from 'types/uploadEnums.types';

type UploadURLResponse = { url: { url: string; fields: object } };

const TIMEOUT_IN_MILLISECONDS = 20000;

let n = 1; // very important,
// it looks like safari can auto cache a response and re use it if a an identical request made again in very short time
// but we never need a cached response in this case
// so we are differentiating between thes requests with a new query param

export const getUploadURL = (params: {
  category: string;
  contentType: string;
  config?: AxiosRequestConfig;
}): Promise<UploadURLResponse> =>
  api.get(`/v2/users/upload-url?${qs.stringify({ ...params, n: n++ })}`, {
    timeout: TIMEOUT_IN_MILLISECONDS,
    ...params.config,
  });

export const uploadFileToS3 = (
  { url, file, fields }: { url: string; file: Blob; fields: object },
  config?: AxiosRequestConfig,
): Promise<any> => {
  const formData = new FormData();
  Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
  formData.append('file', file);
  return axios.post(url, formData, config).then((res) => {
    if (res.status === 204) {
      return fields;
    }
  });
};

type UploadFileOptions = {
  file: File;
  onProgress?: (n: number) => void;
  category?: UploadTypesEnum;
};
export const uploadFile = async ({
  file,
  onProgress,
  category,
}: UploadFileOptions) => {
  const {
    url: { url, fields },
  } = await getUploadURL({
    category: category || UploadTypesEnum.document,
    contentType: file.type,
  });
  return await uploadFileToS3(
    { url, fields, file },
    {
      onUploadProgress: (e) => onProgress?.((e.loaded / e.total) * 100),
    },
  ).then(() => fields);
};
