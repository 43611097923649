import type { DictionaryType } from 'types/global.types';
import { AccountSettingsFieldsNamesEnum } from 'types/settings.types';
import type { IUser } from 'types/users.types';
import { AccountTypeEnum, ActivityTypesEnum } from 'types/users.types';
import { eraseWordsFromString, getShortAccountType } from 'utils/helpers';
import { formatErrorMessage } from 'validation/commonValidators';

export const removeComplementaryDays = (customer: Partial<IUser>): boolean => {
  return !!(
    customer.accountType &&
    (customer.accountType === AccountTypeEnum.one_person_company ||
      customer.accountType.includes('principal'))
  );
};

const constructAccountDetailsFormDefaultValues = (customer: IUser): any => {
  const { accountType, settings, complementaryDays } = customer || {};
  const _accountType = getShortAccountType(accountType);
  const _activityType = eraseWordsFromString(accountType, [
    '_no_vat',
    '_vat',
    'freiberufler_',
    'gewerbetreibender_',
    'one_person_company',
    'natural_person_',
  ]);

  const emailAliasesDefaultValue = settings?.emailAliases?.map(
    (email: any) => ({
      email,
    }),
  );

  return {
    ...customer,
    [AccountSettingsFieldsNamesEnum.accountType]: _accountType,
    [AccountSettingsFieldsNamesEnum.settings]: {
      ...settings,
      emailAliases: emailAliasesDefaultValue,
    },
    [AccountSettingsFieldsNamesEnum.complementaryDays]: removeComplementaryDays(
      customer,
    )
      ? undefined
      : complementaryDays,
    [AccountSettingsFieldsNamesEnum.language]: undefined, // Language should be changed only by the language dropdown to avoid conflicts
    [AccountSettingsFieldsNamesEnum.activity]:
      _activityType || ActivityTypesEnum.principal,
  };
};

const normalizeMaskedInputs = (value: string): string =>
  value.replace(/[^a-zA-Z0-9]/g, '');

const VATNumberMaskNormalizer =
  (prefix: string) =>
  (VATNumber: string): string => {
    if (!VATNumber || VATNumber.trim().length === 0) {
      return '';
    }

    const content = VATNumber.replace(/[^0-9]/g, '');

    return prefix + content;
  };

const SWIFTMask = 'aaaa aa ** ***';

const nationalRegistryNumberMask = '999999-999.99';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const settingsErrorMessagesFormatter = (field: string): DictionaryType =>
  formatErrorMessage('settings', field);

export {
  constructAccountDetailsFormDefaultValues,
  normalizeMaskedInputs,
  SWIFTMask,
  nationalRegistryNumberMask,
  settingsErrorMessagesFormatter,
  VATNumberMaskNormalizer,
  emailRegex,
};
