import { Pagination } from 'components/atoms';
import { get } from 'lodash';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { IExpense } from 'types/expenses.types';
import { type CheckComponentProps } from 'types/taxes.types';
import { logAmplitude } from 'utils/amplitude';
import { WEBAPP_TAXREVIEW_OPENCHECKEXPENSEORREVENUE } from 'utils/amplitude/events/taxes.amplitude';
import formatMoney from 'utils/formatMoney';
import ZebraTable from '../ZebraTable';

const CommutersList: FC<
  {
    onItemClick?: (item: IExpense) => void;
    extraCols?: { head: string; key: string }[];
    list: IExpense[];
  } & CheckComponentProps
> = ({ list, expenseFormRef, onItemClick, checkName, checkData, setPage }) => {
  const { data, paging } = checkData;
  const { t } = useTranslation();
  return (
    <div>
      <ZebraTable
        cells={[
          {
            head: t('taxes.tax_checks.commuters_list.booking_date'),
            key: 'expenseDate',
          },
          {
            head: t('taxes.tax_checks.commuters_list.distance'),
            key: 'tripTotalKm',
          },
          {
            head: t('taxes.tax_checks.commuters_list.commuting_days'),
            key: 'tripWorkingDays',
          },

          {
            head: t('taxes.vat_submission.table.header.amount'),
            key: 'deductibleAmount',
          },
        ]}
        onClick={(_, item) => {
          logAmplitude(WEBAPP_TAXREVIEW_OPENCHECKEXPENSEORREVENUE, {
            checkName,
            expenseId: get(item, '_id'),
          });
          onItemClick?.(item as unknown as IExpense);
          setTimeout(() => expenseFormRef.current.open(get(item, '_id')), 0);
        }}
        cellClass="first:!grow-0 last:text-right"
        hideChevron
        items={list
          .filter((expense) => expense.items.length)
          .map((expense: IExpense) => ({
            ...expense,
            deductibleAmount: (
              <span className="font-semibold text-primary">
                {formatMoney(expense.items[0].deductibleAmount)}
              </span>
            ),
            tripTotalKm: expense.items[0].tripTotalKm,
            tripWorkingDays: expense.items[0].tripWorkingDays,
            key: expense?._id,
          }))}
      />
      {paging?.pageCount > 1 ? (
        <Pagination
          className="mt-2"
          pagesCount={paging?.pageCount || 0}
          perPage={25}
          totalCount={paging?.totalCount || 0}
          page={paging?.page || 0}
          currentPageCount={data?.length || 0}
          onPageChange={(pageNumber) => {
            setPage(pageNumber);
          }}
        />
      ) : null}
    </div>
  );
};

export default CommutersList;
