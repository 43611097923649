import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import type { IColors } from 'theme/colors';
import type { ModalOptions } from './useModal';
import useModal from './useModal';

type OptionalConfirmProps = {
  onDecline?: () => Promise<any> | void;
  color?: IColors;
  onDone?: () => void;
  okText?: ReactNode;
  cancelText?: ReactNode;
  className?: string;
  okTracingEvent?: string;
  buttonsWrapperClassName?: string;
  cancelTracingEvent?: string;
  buttonClassName?: string;
  width?: string;
  modalOptions?: ModalOptions;
  okButtonClassName?: string;
};

type ConfirmProps = OptionalConfirmProps & {
  message: ReactNode;
  onConfirm: () => Promise<any> | void;
};

export type UseConfirmReturn = [
  ReactNode,
  { open: (options: ConfirmProps) => void; close: () => void },
];

export const unSavedChangesDefaultSettings = {
  className: 'text-center font-semibold',
  buttonsWrapperClassName: 'justify-center',
  buttonClassName: 'min-w-[90px]',
};

const Confirm: FC<ConfirmProps> = (props) => {
  const {
    onConfirm,
    onDecline,
    message,
    okText,
    cancelText,
    okTracingEvent,
    cancelTracingEvent,
    onDone,
    className,
    buttonsWrapperClassName,
    buttonClassName,
    color,
    okButtonClassName,
  } = props;
  const { t } = useTranslation();
  const onConfirmResult = useMutation(() => Promise.resolve().then(onConfirm), {
    onSuccess: onDone,
  });
  const onDeclineResult = useMutation(() => Promise.resolve().then(onDecline), {
    onSuccess: onDone,
  });

  return (
    <div className={classNames(className)}>
      <div className="whitespace-pre-line mb-6 first-letter:capitalize">
        {message}
      </div>
      <div className={classNames('flex gap-2', buttonsWrapperClassName)}>
        <Button
          structure="secondary"
          loading={onDeclineResult.isLoading}
          onClick={() => onDeclineResult.mutate()}
          className={classNames('min-w-[100px]', buttonClassName)}
          color={color}
          tracingEvent={cancelTracingEvent}
        >
          {cancelText || <span className="capitalize">{t('cancel')}</span>}
        </Button>
        <Button
          loading={onConfirmResult.isLoading}
          onClick={() => onConfirmResult.mutate()}
          className={classNames(
            'min-w-[100px]',
            buttonClassName,
            okButtonClassName,
          )}
          color={color}
          tracingEvent={okTracingEvent}
        >
          {okText || <span className="capitalize">{t('continue')}</span>}
        </Button>
      </div>
    </div>
  );
};

const useConfirm = (
  generalOptions?: OptionalConfirmProps,
): UseConfirmReturn => {
  const [content, _api] = useModal();

  const api = {
    open: (options: ConfirmProps) => {
      _api.open(
        () => <Confirm onDone={_api.close} {...generalOptions} {...options} />,
        {
          width: 'md',
          color: options.color,
          name: 'action confirmation modal',
          ...options.modalOptions,
        },
      );
    },
    close: _api.close,
  };

  return [content, api];
};

export default useConfirm;
