import { ChevronRightIcon } from '@heroicons/react/outline';
import ChartIcon from 'assets/svg/icons/ChartIcon';
import Spreadsheet from 'assets/svg/icons/Spreadsheet';
import classNames from 'classnames';
import { Menu } from 'components/atoms/Menu';
import ExportIcon from 'components/atoms/SVG/sidebarIcons/ExportIcon.component';
import MoreIcon from 'components/atoms/SVG/sidebarIcons/MoreIcon.component';
import useFeatures from 'hooks/useFeatures';
import useGetCustomNavigationLink from 'hooks/useGetCustomNavigationLink';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import NavigationItem from './NavigationItem.component';
import { SidebarNavigationItemTypesEnum } from './types';

const MoreMenu: FC = () => {
  const { t } = useTranslation();
  const getLink = useGetCustomNavigationLink();
  const { pathname } = useLocation();
  const showProfitAndLossInMoreMenu = useFeatures(
    'showProfitAndLossInMoreMenu',
  );
  return (
    <Menu
      buttonProps={{ id: 'MORE_MENU', className: 'w-full' }}
      position="top"
      trigger={(api: any) => {
        const isActive = api.open; //||
        // ['/amortization', '/taxes/analytics', '/export'].some((item) =>
        //   pathname.includes(item),
        // );
        return (
          <NavigationItem
            active={isActive}
            titleContainerClassName="flex-1"
            item={{
              title: '',
              renderTitle: () => (
                <span
                  className={classNames(
                    'flex flex-1 w-full items-center justify-between',
                    isActive ? '!text-primary' : '',
                  )}
                >
                  <span className="flex-1">{t('sidebar.more')}</span>
                  <span>
                    <ChevronRightIcon className="w-4 h-4" />
                  </span>
                </span>
              ),
              Icon: MoreIcon,
              type: SidebarNavigationItemTypesEnum.more,
            }}
          />
        );
      }}
      rootClassName="w-full"
      contentWrapperClassName="left-[9.6rem] !ring-0 !px-1 !py-2 !rounded-xl !px-1"
    >
      <Menu.Group className="!p-0">
        <Menu.Link
          className="hover:!bg-primary-50 "
          activeClassName="bg-primary-50"
          inactiveClassName=""
          to={getLink('/amortization')}
        >
          <span className="flex gap-2 items-center text-main hover:text-primary">
            <span className="w-6">
              <Spreadsheet />
            </span>
            <span>{t('settings.amortization.title')}</span>
          </span>
        </Menu.Link>
      </Menu.Group>
      <Menu.Group className="!p-0">
        <Menu.Link
          className="hover:!bg-primary-50"
          activeClassName="bg-primary-50"
          inactiveClassName=""
          to={getLink('/export')}
        >
          <span className="flex gap-2 items-center  !text-main hover:!text-primary">
            <span className="w-6">
              <ExportIcon className="w-6" />
            </span>
            <span>{t('export.title_web')}</span>
          </span>
        </Menu.Link>
      </Menu.Group>
      {showProfitAndLossInMoreMenu ? (
        <Menu.Group className="!p-0">
          <Menu.Link
            className="hover:!bg-primary-50 "
            activeClassName="bg-primary-50"
            inactiveClassName=""
            to={getLink('/taxes/analytics')}
          >
            <span className="flex gap-2 text-main hover:text-primary items-center">
              <span>
                <ChartIcon />
              </span>
              <span>{t('profit_and_loss.title')}</span>
            </span>
          </Menu.Link>
        </Menu.Group>
      ) : null}
    </Menu>
  );
};

export default MoreMenu;
