import { getRevenuesFilters } from 'api/v2/revenues';
import { adaptFilters } from 'components/pages/Revenues/filtersConfig';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import _debounce from 'lodash/debounce';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import filtersStoreCreatorV2 from 'store/shared/filters/filtersStoreCreatorV2';
import { RevenuesCacheKeys } from 'types/cacheKeys.types';
import type { AvailableCountries } from 'types/users.types';
import { getTracingEvents } from 'utils/amplitude';
import { WEB_REVENUESLIST_FILTEREDLIST } from 'utils/amplitude/events/revenues.amplitude';
import { mergeFilters } from 'utils/helpers';

export const useRevenuesFiltersStore = filtersStoreCreatorV2();

const tracingEvents = getTracingEvents({
  onFiltersChange: WEB_REVENUESLIST_FILTEREDLIST,
});

const logFilterChanges = _debounce(tracingEvents.onFiltersChange, 500);

const useRevenuesFilters = () => {
  const country = useCustomerCountry();
  const filtersConfig = useRevenuesFiltersStore();

  useEffect(() => {
    return useRevenuesFiltersStore.subscribe((state, prev) => {
      if (state.filtersData === prev.filtersData) return;
      logFilterChanges();
    });
  }, []);

  const cacheKey = RevenuesCacheKeys.RevenuesFilters;

  const filtersQuery = useQuery(
    cacheKey,
    () =>
      getRevenuesFilters().then((filters) => {
        const newFilters = mergeFilters(
          filtersConfig.getFilters(),
          adaptFilters(filters, country as AvailableCountries),
        );
        filtersConfig.setFilters(newFilters);
        return newFilters;
      }),
    {
      staleTime: 0,
    },
  );

  return {
    filtersConfig,
    filtersQuery,
  };
};

export default useRevenuesFilters;
