enum SidebarNavigationItemTypesEnum {
  revenues = 'revenues',
  clients = 'clients',
  taxes = 'taxes',
  expenses = 'expenses',
  banks = 'banks',
  export = 'export',
  primary = 'primary',
  AI = 'AI',
  help = 'help',
  more = 'more',
  yearlyReview = 'yearlyReview',
}

enum SidebarNavigationItemTextStyles {
  revenues = 'text-revenues',
  expenses = 'text-expenses',
  banks = 'text-bank',
  taxes = 'text-taxes',
  clients = 'text-revenues',
  export = 'text-primary-700',
  AI = 'text-taxes',
  primary = 'text-primary',
  help = 'text-primary',
  yearlyReview = 'text-white',
  more = 'text-primary',
}

enum SidebarNavigationItemBGStyles {
  revenues = 'bg-revenues-100',
  expenses = 'bg-expenses-100',
  banks = 'bg-bank-100',
  taxes = 'bg-taxes-100',
  clients = 'bg-revenues-100',
  export = 'bg-primary-50',
  AI = 'bg-taxes-100',
  primary = 'bg-primary-100',
  help = 'bg-primary-100',
  more = 'bg-primary-50',
  yearlyReview = 'bg-primary-900',
}

enum SideBarNavigationItemIconStyles {
  revenues = 'text-revenues-900',
  expenses = 'text-expenses-900',
  banks = 'text-bank-900',
  taxes = 'text-taxes-900',
  clients = 'text-revenues-900',
  export = 'text-primary-700',
  AI = 'text-taxes',
  help = 'text-primary',
  primary = 'text-primary-900',
  yearlyReview = 'text-white',
  more = 'text-primary-900',
}

enum SidebarNavigationItemSelectedBGStyles {
  yearlyReview = 'bg-primary-900',
}

enum SidebarNavigationItemSelectedTextStyles {
  yearlyReview = 'text-white',
}

enum SideBarNavigationItemSelectedIconStyles {
  yearlyReview = 'text-white',
}

export {
  SidebarNavigationItemTypesEnum,
  SidebarNavigationItemTextStyles,
  SidebarNavigationItemBGStyles,
  SideBarNavigationItemIconStyles,
  SidebarNavigationItemSelectedBGStyles,
  SidebarNavigationItemSelectedTextStyles,
  SideBarNavigationItemSelectedIconStyles,
};
