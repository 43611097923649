import { Button } from 'components/atoms/Button';
import { CircularButtonTypesEnum } from 'components/atoms/CircularButton/CircularButton.types';
import { FeedbackMessage } from 'components/templates/FeedbackMessage';
import logout from 'hooks/Authentication/useLogout';
import useSubscriptionPlan from 'hooks/Subscriptions/useSubscriptionPlan';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  isSubscriptionAmbassadorPlan,
  isSubscriptionMobilePlatform,
  isSubscriptionNoWebAccessPlan,
} from '../../../utils/subscriptions.utils';

import IntercomController from 'components/organisms/IntercomController.component';
import SubscriptionPlans from './SubscriptionsViews/SubscriptionPlans';

const Subscriptions: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const subscriptionPlan = useSubscriptionPlan();

  if (!subscriptionPlan) return null;

  const { planInternalName, fromPlatform } = subscriptionPlan;

  // * User Subscribed From Mobile Platform
  if (isSubscriptionMobilePlatform(fromPlatform)) {
    return (
      <FeedbackMessage
        title={t('subscription.feedback.qonversion.title')}
        message={t('subscription.feedback.qonversion.message')}
        feedbackMessageControllers={
          <Button
            color="taxes"
            size={'xl'}
            onClick={() => {
              if (isSubscriptionNoWebAccessPlan(planInternalName)) {
                return logout();
              }
              return navigate(-1);
            }}
          >
            {t('landing.go_back')}
          </Button>
        }
      />
    );
  }

  // * Users With Ambassador Plan
  if (isSubscriptionAmbassadorPlan(planInternalName)) {
    return (
      <FeedbackMessage
        title={t('subscription.feedback.ambassador.title')}
        message={t('subscription.feedback.ambassador.message')}
        feedbackMessageControllers={
          <Button
            color="taxes"
            size={'xl'}
            onClick={() => {
              if (isSubscriptionNoWebAccessPlan(planInternalName)) {
                return logout();
              }
              return navigate(-1);
            }}
          >
            {t('landing.go_back')}
          </Button>
        }
      />
    );
  }

  return (
    <>
      <SubscriptionPlans />

      <div className="fixed bottom-10 right-10 z-[200] bg-white rounded-full">
        <IntercomController
          type={CircularButtonTypesEnum.taxes}
          active
          showIntercomLabel={false}
        />
      </div>
    </>
  );
};

export default Subscriptions;
