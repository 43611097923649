import type SVGType from 'types/svg.types';

const RevenuesIcon: SVGType = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.66634 25.6667H6.33301V17H9.66634V19.6667M9.66634 25.6667V19.6667M9.66634 25.6667C18.6663 25.6667 25.6663 21.3333 25.6663 21.3333V18.3333H17.6663M9.66634 19.6667L14.3722 17.1569C14.5653 17.0539 14.7808 17 14.9997 17H16.333C17.0694 17 17.6663 17.597 17.6663 18.3333M14.333 21.6667L17.6663 20V18.3333"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
      stroke="currentColor"
    />
    <path
      d="M21.9257 8.12263C21.5929 6.89997 20.4747 6.00098 19.1466 6.00098C17.556 6.00098 16.2666 7.29038 16.2666 8.88093C16.2666 10.4715 17.556 11.7609 19.1466 11.7609C19.5212 11.7609 19.8792 11.6894 20.2075 11.5592M21.9257 8.12263C22.254 7.99248 22.612 7.92095 22.9866 7.92095C24.5772 7.92095 25.8666 9.21035 25.8666 10.8009C25.8666 12.3915 24.5772 13.6809 22.9866 13.6809C21.6585 13.6809 20.5403 12.7819 20.2075 11.5592M21.9257 8.12263C20.5786 8.65665 19.8296 10.1712 20.2075 11.5592"
      strokeWidth="2"
      stroke="currentColor"
    />
  </svg>
);

export default RevenuesIcon;
