import { useEffect, useRef } from 'react';

const useCutRouterState = (keyToCut: string) => {
  // .usr is react-router v6 key, used to store user created state
  const stateRef = useRef(window.history.state?.usr?.[keyToCut]);
  useEffect(() => {
    const usrState = window.history.state?.usr;
    if (usrState) {
      const { [keyToCut]: unwanted, ...restUsr } = usrState;
      window.history.replaceState(
        { ...window.history.state, usr: restUsr },
        '',
      );
    }
  }, []);
  return stateRef.current;
};

export default useCutRouterState;
