import type { IPaginationResponse } from 'api/axiosInstance';
import api from 'api/axiosInstance';
import { stringify } from 'query-string';
import type {
  IExpense,
  IExpenseCategory,
  VATPeriod,
} from 'types/expenses.types';
import type {
  FilterResponse,
  LockStatusType,
  LockStatusTypeCatgoriesed,
} from 'types/global.types';
import type { TaxImpact } from 'types/taxes.types';
import type { Transaction } from 'types/transactions.types';
import { VATTypeEnum } from 'types/users.types';
import { BEVehicle, DEVehicle } from 'types/vehicle.types';
import { isExpert } from 'utils/constants';
import { onCreateExpenseGTM } from 'utils/GTM';
import { formatLockStatus } from 'utils/helpers';

const service = '/v2/expenses';

type IPagination = {
  perPage: number;
  page: number;
};

export type getExpensesParams = IPagination & {
  periods?: string[];
  text?: string;
  source?: 'web' | 'mobile';
  reviewed?: boolean;
  isInvoice?: boolean;
  categoryIds?: string[];
  attachment?: 'document-attached' | 'document-missing';
  payment?: 'no-payment-linked' | 'paid-cash';
  sufficientlyDocumented?: boolean;
  filename?: string;
  expand?: ['transactions'];
  isValidated?: boolean;
  fields?: string;
  isCreditNote?: boolean;
  sort?: string;
  dateRange?: string[];
};

type IAPIBulkUpdateExpensesBody = {
  resourceId: string;
  body: { reviewed: boolean };
}[];

type IAPIBulkUpdateExpensesResponse = {
  _id: string;
  ok: boolean;
  status: string;
  errors?: { message: string; code: string }[];
  type?: string;
}[];

// TODO: return expense type
export const getExpenses = (
  query: getExpensesParams,
): Promise<{ data: IExpense[] } & IPaginationResponse> =>
  api.get(
    `${service}?${stringify(query, {
      skipEmptyString: true,
      arrayFormat: 'comma',
    })}`,
  );

// TODO: return expense type
export const getExpenseById = (expenseId: string): Promise<IExpense> =>
  api
    .get(`${service}/${expenseId}`, {
      params: {
        expand: 'transactions,taxLock',
      },
    })
    .then((doc: any) => {
      const transactions = doc?.transactions?.filter((t: any) => !!t);
      return {
        ...doc,
        transactions: transactions?.map((t: Transaction) => t._id),
        _matchedTransactions: transactions,
      };
    });

// TODO: type
export const updateExpenseById = (
  expenseId: string,
  body: Partial<IExpense>,
): Promise<IExpense> => api.put(`${service}/${expenseId}`, body);

export const addExpense = (body: Partial<IExpense>) =>
  api.post<any, Promise<IExpense>>(`${service}`, body).then((data) => {
    onCreateExpenseGTM();
    return data;
  });

// TODO: return expense type
export const deleteExpenseById = (expenseId: string): Promise<void> =>
  api.delete(`${service}/${expenseId}`);

export const getExpensesFilters = (): Promise<{
  data: FilterResponse[];
}> => api.get(`${service}/filters`);

export const bulkUpdateExpenses = (
  ops: IAPIBulkUpdateExpensesBody,
): Promise<IAPIBulkUpdateExpensesResponse> =>
  api.patch(`${service}/bulk`, { ops });

export const bulkDeleteExpenses = (
  ids: string[],
): Promise<IAPIBulkUpdateExpensesResponse> =>
  api.delete(`${service}/bulk?${stringify({ ids }, { arrayFormat: 'comma' })}`);

export const bulkValidateExpenses = (
  ids: string[],
): Promise<IAPIBulkUpdateExpensesResponse> => {
  return api.patch(`${service}/bulk`, {
    ops: ids.map((id) => ({
      resourceId: id,
      body: {
        reviewed: true,
      },
    })),
  });
};

// TODO: type
export const getExpenseTaxImpact = (
  expense: Partial<IExpense>,
): Promise<any> => {
  return api
    .post(`${service}/tax-impact`, expense, {
      params: { taxImpactRequested: true },
    })
    .then(({ data }) => data);
};

export const getExpenseVATCells = (
  expense: Partial<IExpense>,
): Promise<{ key: string; value: number }[]> => {
  return api
    .post<
      any,
      { data: { total: { vat: { statement: Record<string, number> } } } }
    >(`${service}/tax-impact`, expense, {
      ignoreToast: true,
    } as any)
    .then(({ data }) => {
      return Object.entries(data.total.vat.statement)
        .filter(([key, value]) => !!value)
        .map(([key, value]) => ({ key, value }));
    });
};

export const getSavedExpenseTaxImpact = (
  expense: IExpense,
): Promise<TaxImpact> => {
  return api
    .get(`${service}/${expense?._id}/tax-impact`, {
      params: { taxImpactRequested: true },
    })
    .then(({ data }) => data);
};

// TODO: type
// export const getExpenseTaxImpact = (expenseId: string): Promise<void> =>
//   api.get(`${service}/${expenseId}/tax-impact`);

type LockStatusBody = Partial<{
  expenseDate: string;
  user: Partial<{
    VATType: string;
    VATReturnFrequency: string;
  }>;
  period: VATPeriod;
}>;
type LockStatusResponse = { taxLock: LockStatusType };

export const getLockStatus = (
  body: LockStatusBody,
): Promise<LockStatusTypeCatgoriesed> => {
  if (
    !body.expenseDate ||
    !body.user?.VATType ||
    !body.user?.VATReturnFrequency
  ) {
    return Promise.resolve(null);
  }
  if (body.user?.VATType === VATTypeEnum.subjectToVAT && !body.period) {
    return Promise.resolve(null);
  }
  return api
    .post<any, LockStatusResponse>(`${service}/tax-lock`, body, {
      ignoreToast: true,
    })
    .then((data) => formatLockStatus(data.taxLock))
    .catch(() => null);
};

export const patchLockedExpense = (
  expenseId: string,
  body: Pick<
    IExpense,
    | 'transactions'
    | 'notes'
    | 'cashRemainder'
    | 'matchCard'
    | 'accountantReview'
  >,
): Promise<IExpense> => {
  return api.patch(`${service}/${expenseId}/locked`, {
    transactions: body.transactions,
    notes: body.notes || null,
    cashRemainder: !!body.cashRemainder,
    matchCard: !!body.matchCard,
    accountantReview: isExpert ? body.accountantReview : undefined,
  });
};

export const getWorkingDaysAndDeductibleAmount = (
  year: number,
  expenseId?: string,
) =>
  api
    .get<any, { days?: number; amount?: number } | undefined>(
      `${service}/drive-to-work/working-days/${year}${
        expenseId ? `?expenseId=${expenseId}` : ''
      }`,
    )
    .then((data) => ({ days: data?.days || 0, amount: data?.amount || 0 }))
    .catch(() => ({ days: 0, amount: 0 }));

export type GetExpenseCategoriesGuessedParams = {
  filename?: string | null;
  supplierName?: string;
  transactions?: string[];
};

export type GetExpenseCategoriesGuessedResponse = {
  category: IExpenseCategory;
  vehicle?: BEVehicle | DEVehicle;
  guessedCategoryFrom: string;
};

export const getExpenseCategoriesGuessed = (
  params: GetExpenseCategoriesGuessedParams,
): Promise<GetExpenseCategoriesGuessedResponse[]> =>
  api.get(`${service}/guess/categories`, { params });
