import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { IconButton } from 'components/atoms';
import { motion } from 'framer-motion';
import { type FC } from 'react';
import type { IColors } from 'theme/colors';
import { XIcon } from 'utils/icons';
export type SlideOver2Props = {
  showLeftPanelUI?: boolean;
  coverRightPanel?: boolean;
  rightPanelCoverContent?: any;
  renderLeftPanel?: () => any;
  renderRightPanel: () => any;
  rightPaneClassName?: string;
  rightPaneWrapperClassName?: string;
  rightPaneWidth?: string;
  onClose?: () => void;
  color?: IColors;
  name?: string;
};

const SlideOver2: FC<SlideOver2Props> = ({
  showLeftPanelUI,
  coverRightPanel,
  rightPanelCoverContent,
  renderLeftPanel,
  renderRightPanel,
  rightPaneClassName = 'h-[95vh] mt-[2.5vh] bg-white shadow-xl overflow-y-auto rounded-xl',
  rightPaneWrapperClassName,
  rightPaneWidth = '32rem',
  onClose,
}) => {
  const leftPanelContent = renderLeftPanel?.();

  return (
    <Dialog.Root open modal={false}>
      <Dialog.Portal forceMount>
        <Dialog.Content
          asChild
          onEscapeKeyDown={(event) => {
            if (event.defaultPrevented) return;
            onClose?.();
          }}
        >
          <motion.div
            className="fixed top-0 right-0 z-50"
            style={{
              '--rightPaneWidth': rightPaneWidth,
            }}
          >
            <motion.div
              className={classNames(
                { 'w-screen': showLeftPanelUI },
                'flex absolute top-0 right-0',
              )}
            >
              {showLeftPanelUI && (
                <motion.div
                  transition={{ type: 'keyframes' }}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 0.7,
                    transition: { ease: 'easeOut', delay: 0.1 },
                  }}
                  exit={{ opacity: 0, transition: { ease: 'easeIn' } }}
                  className={classNames('bg-primary absolute z-1 inset-0')}
                />
              )}
              {leftPanelContent && showLeftPanelUI ? (
                <motion.div
                  transition={{ type: 'keyframes' }}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: { ease: 'easeOut', delay: 0.1 },
                  }}
                  exit={{ opacity: 0, transition: { ease: 'easeIn' } }}
                  className={classNames(
                    'flex-1 relative h-screen overflow-y-auto overflow-x-hidden force-scroll-style scrollbar-none py-10',
                  )}
                >
                  {leftPanelContent}
                </motion.div>
              ) : null}
              <motion.div
                transition={{ type: 'keyframes' }}
                initial={{ x: '100%' }}
                animate={{ x: 0, transition: { ease: 'easeOut' } }}
                exit={{ x: '100%', transition: { ease: 'easeIn' } }}
                className={classNames(
                  'relative w-[var(--rightPaneWidth)] ml-auto pr-[1rem]',
                  rightPaneWrapperClassName,
                )}
              >
                {coverRightPanel && (
                  <div className="bg-white bg-opacity-[65%] absolute inset-0 z-10 pointer-events-none">
                    {rightPanelCoverContent ? rightPanelCoverContent : null}
                  </div>
                )}
                {onClose ? (
                  <div className="absolute top-[4vh] z-[51] right-[2vh] -translate-x-1/2">
                    <IconButton
                      icon={() => (
                        <XIcon className="text-primary-700 w-4 h-4" />
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                      }}
                      className="!bg-primary-25"
                    />
                  </div>
                ) : null}
                <div
                  className={classNames(rightPaneClassName)}
                  id="expense-right-panel"
                >
                  {renderRightPanel()}
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SlideOver2;
