import classNames from 'classnames';
import useItemsCategoriesSummary from 'hooks/Expenses/useExpenseItemsCategoriesSummary';
import type { FC } from 'react';
import type { IExpenseItem } from 'types/expenses.types';

export const Category: FC<{
  items: IExpenseItem[];
  hideIcon?: boolean;
  className?: string;
  labelClassName?: string;
}> = ({ items, hideIcon, className = '', labelClassName = '' }) => {
  const getSummary = useItemsCategoriesSummary();
  const summary = getSummary(items);

  const icon = (() => {
    if (hideIcon || !summary.icon) return null;
    if (typeof summary.icon === 'string')
      return <img src={summary.icon} className="mr-2 w-6 h-6" />;
    return (
      <summary.icon
        className="w-6 h-6 mr-2 relative top-px"
        strokeWidth="1.25"
      />
    );
  })();

  if (summary.isMixed) {
    return (
      <div className={classNames('flex items-center', className)}>
        {icon}
        <p
          className={classNames(
            'truncate-wrap text-primary-700 w-56',
            labelClassName,
          )}
        >
          {summary.text}
        </p>
      </div>
    );
  }

  if (summary.isEmpty) {
    return (
      <div className={classNames('flex items-center', className)}>
        {icon && <span className="text-primary-500">{icon}</span>}
        <p
          className={classNames(
            'truncate-wrap text-primary-700 w-56',
            labelClassName,
          )}
        >
          {summary.text}
        </p>
      </div>
    );
  }

  return (
    <div className={classNames('flex items-center', className)}>
      {icon}
      <p
        className={classNames(
          'truncate-wrap text-primary-700 w-56',
          labelClassName,
        )}
      >
        {summary.text}
      </p>
    </div>
  );
};
