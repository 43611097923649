import { getFileUrl } from 'api/v2/users';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { FilesCacheKeys } from 'types/cacheKeys.types';

const useResolveFileURL = (path: string): UseQueryResult<{ url: string }> =>
  useQuery([FilesCacheKeys.FileURL, { path }], () => getFileUrl(path), {
    enabled: !!path,
  });

export default useResolveFileURL;
