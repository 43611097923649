import classNames from 'classnames';
import type { FC } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from 'utils/icons';
import type { PaginationProps } from './Pagination.types';
import { paginatedFirstItem, paginatedLastItem } from './Pagination.utils';

const Pagination: FC<PaginationProps> = ({
  onPageChange,
  page,
  pagesCount,
  totalCount,
  perPage,
  currentPageCount,
  className,
}) => {
  const firstItem = paginatedFirstItem(page, currentPageCount, perPage);
  const lastItem = paginatedLastItem(
    page,
    currentPageCount,
    totalCount,
    perPage,
  );

  const prevPageDisabled = page === 1;
  const nextPageDisabled = pagesCount === 0 || page === pagesCount;

  return (
    <div className={classNames('flex items-center justify-end', className)}>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-end">
        <div className="mr-1">
          <p className="text-sm text-primary">
            <span className="font-semibold">{firstItem}</span>
            {' - '}
            <span className="font-semibold">{lastItem}</span>
            {' of '}
            <span className="font-semibold">{totalCount}</span>
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md -space-x-px">
            <button
              disabled={prevPageDisabled}
              type="button"
              onClick={() => !prevPageDisabled && onPageChange(page - 1)}
              className={classNames(
                'relative inline-flex items-center px-2 py-2 rounded-l-md  text-sm font-semibold',
                {
                  'text-primary hover:bg-gray-100 cursor-pointer':
                    !prevPageDisabled,
                  'text-gray-200': prevPageDisabled,
                },
              )}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              disabled={nextPageDisabled}
              type="button"
              onClick={() => !nextPageDisabled && onPageChange(page + 1)}
              className={classNames(
                'relative inline-flex items-center px-2 py-2 rounded-r-md  text-sm font-semibold',
                {
                  'text-primary hover:bg-gray-100 cursor-pointer':
                    !nextPageDisabled,
                  'text-gray-200 cursor-forbidden': nextPageDisabled,
                },
              )}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
