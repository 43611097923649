import { createContext } from 'react';

const layoutContext = createContext({
  shouldShowBgImage: true,
  setShouldShowBgImage: (shouldShow: boolean) => {},
  layoutBGColor: 'transparent',
  setLayoutBGColor: (color: string) => {},
  sidebarArea: 176,
});

export default layoutContext;
