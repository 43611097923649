import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import WarningModalContent from './WarningModalContent';

type AmortizationPeriodModalContentProps = {
  currentValue: number;
  recomendedValue: number;
  onChange: (value: number) => void;
};

const AmortizationPeriodModalContent: FC<
  AmortizationPeriodModalContentProps
> = ({ currentValue, recomendedValue, onChange }) => {
  const { t } = useTranslation();

  return (
    <WarningModalContent
      onChoose={onChange}
      description={t('expense.amortization.unrecommended_duration')}
      options={[
        {
          value: currentValue,
          title: t('expense.amortization.keep_deprecation.title', {
            years: currentValue,
            yearOrYears: t('years'),
          }),
          description: t('expense.amortization.keep_deprecation.subtitle', {
            years: currentValue,
            yearOrYears: t('years'),
          }),
        },
        {
          value: recomendedValue,
          title: t('expense.amortization.change_to.title', {
            years: recomendedValue,
            yearOrYears: t('years'),
          }),
          description: t('expense.amortization.change_to.subtitle', {
            years: recomendedValue,
            yearOrYears: t('years'),
          }),
        },
      ]}
    />
  );
};

export default AmortizationPeriodModalContent;
