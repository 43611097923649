import useResetableState from 'hooks/useResetableState';
import { useState, type FC } from 'react';
import type { SelectConfig } from '../select.types';

const withControlledInputValue = (SelectComponent: FC<SelectConfig>) =>
  function WithAsyncSearch(props: SelectConfig) {
    const {
      onInputChange,
      inputValue,
      onFocus,
      isCreatable,
      resetInputValueOnFocus,
      // emptyInputValueOnFocus,
      controlShouldRenderValue,
      value,
      // formatInputAsNumberOnFocus,
      // parseInputAsInt,
      formatOnInputChange,
      formatOnInputFocus,
    } = props;

    const suggestInputValue = () => {
      if (controlShouldRenderValue) return;

      if (resetInputValueOnFocus && inputValue != null) {
        return inputValue + '';
      }

      if (isCreatable && value != null) {
        const _value = formatOnInputFocus?.(value) ?? value;
        return _value == null ? '' : _value + '';
      }
    };

    const [suggestedInputValue] = useState(suggestInputValue);

    const [search, setSearch] = useResetableState({
      init: inputValue || suggestedInputValue || '',
      deps: [inputValue],
    });

    const newProps: Partial<SelectConfig> = {
      inputValue: search,
      onInputChange: (value, actionMeta, selected) => {
        onInputChange?.(value, actionMeta, selected);
        if (actionMeta.action !== 'input-change') return;
        if (!value) return setSearch('');
        if (formatOnInputChange) {
          const formatted = formatOnInputChange(value);
          if (formatted === null) return;
          return setSearch(formatted);
        }
        setSearch(value);
      },
      onFocus: (e) => {
        onFocus?.(e);

        const suggestedInputValue = suggestInputValue();
        if (typeof suggestedInputValue === 'string') {
          setSearch(suggestedInputValue);
        }
      },
      emptyInputValueOnFocus: undefined,
      resetInputValueOnFocus: undefined,
      isCreatable: undefined,
    };

    return <SelectComponent {...props} {...newProps} />;
  };

export default withControlledInputValue;
