import type SVGType from 'types/svg.types';

const AIIcon: SVGType = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="currentColor"
      d="M22.667 6.33301C22.667 7.86241 21.1965 9.33301 19.667 9.33301C21.1965 9.33301 22.667 10.8036 22.667 12.333C22.667 10.8036 24.1375 9.33301 25.667 9.33301C24.1375 9.33301 22.667 7.86241 22.667 6.33301Z"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="currentColor"
      d="M22.667 19.667C22.667 21.1965 21.1965 22.667 19.667 22.667C21.1965 22.667 22.667 24.1375 22.667 25.667C22.667 24.1375 24.1375 22.667 25.667 22.667C24.1375 22.667 22.667 21.1965 22.667 19.667Z"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="currentColor"
      d="M11.9997 10.333C11.9997 13.2219 9.22189 15.9997 6.33301 15.9997C9.22189 15.9997 11.9997 18.7774 11.9997 21.6663C11.9997 18.7774 14.7774 15.9997 17.6663 15.9997C14.7774 15.9997 11.9997 13.2219 11.9997 10.333Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AIIcon;
