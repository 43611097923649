import type { ReactNode } from 'react';
import type { TracingConfig } from 'utils/amplitude/amplitude.types';

export interface RadioGroupsProps {
  type: 'default' | 'inline' | 'block';
  legendLabel?: string;
  data: {
    id: string | boolean;
    title: string | ReactNode;
    description?: string;
    InfoComponent?: React.FC;
    checked?: boolean;
  }[];
  onChange: (val: string) => void | undefined;
  labelClassNames?: string;
  className?: string;
  tracing?: TracingConfig;
}

type CustomRadioGroupsTypeMap = Record<
  RadioGroupsProps['type'],
  {
    inlineClasses: string;
  }
>;

export const customRadioGroupsTypeMap: CustomRadioGroupsTypeMap = {
  default: {
    inlineClasses: '',
  },
  inline: {
    inlineClasses: 'sm:flex sm:items-center sm:space-y-0 sm:space-x-10',
  },
  block: {
    inlineClasses: '',
  },
};
