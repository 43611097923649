export enum VehicleFuelTypeEnum {
  diesel = 'diesel',
  gas = 'gas',
  LPG = 'LPG',
  electric = 'electric',
  hybrid = 'hybrid',
  CNG = 'CNG',
}
export type VehicleFuelType =
  | 'diesel'
  | 'gas'
  | 'LPG'
  | 'electric'
  | 'hybrid'
  | 'CNG';
export type VehicleUsageType = 'professionalDriver' | 'other';
export type VehicleContractType =
  | 'userOwned'
  | 'userLeased'
  | 'userRented'
  | 'other';

export type BEVehicle = {
  _id: string;
  userId: string;
  name: string; // 2-30 length
  fuelType: VehicleFuelType;
  co2Emission: number; // 0-999 g/km
  registrationYear: number; // >=1900
  usageType: VehicleUsageType;
  contractType: VehicleContractType;
  weight?: number; // 0-5000 kg
  batteryCapacity?: number; // 0-500 kWh
  created: Date;
  modified: Date;
  isDeleted: boolean;
  default?: boolean;
  incomeTaxDeductibility: number;
  VATDeductibility: number;
  orderDate?: number;
};

export type DEVehicle = {
  _id: string;
  name: string;
  fuelType: VehicleFuelType;
  incomeTaxDeductibility: number;
  incomeTaxDeductibilityOverrides: number;
  VATDeductibility: number;
  listPrice: number;
  enginePower: number;
  electricRange: number;
};

export type EmptyVehicle = {
  batteryCapacity: null;
  co2Emission: null;
  contractType: null;
  fuelType: null;
  name: null;
  registrationYear: null;
  usageType: null;
  weight: null;
  _id: null;
};

export type VehiclesOption<Vehicle = BEVehicle> = {
  label: string;
  value: string;
  vehicle: Vehicle;
};
