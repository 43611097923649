import { sha256 } from 'js-sha256';
import _pick from 'lodash/pick';
import { useEffect, useMemo } from 'react';
import type { GrowthExperimentsWithABGroupsType } from 'store/ABExperiments.store';
import ABExperimentsStoreCreator from 'store/ABExperiments.store';
import type { GrowthExperimentDefinition } from 'types/growthExperiments.types';
import { ABTest, GrowthExperimentType } from 'types/growthExperiments.types';
import type { IUser } from 'types/users.types';
import { setAmplitudeUserProperties } from 'utils/amplitude';
import useFeatures from './useFeatures';
import useResetableState from './useResetableState';

export const useGrowthABExperimentsState = ABExperimentsStoreCreator();

const unauthenticatedGrowthExperiments: GrowthExperimentDefinition[] = [];

const groups = [ABTest.A, ABTest.B, ABTest.C, ABTest.D, ABTest.E, ABTest.F];

const getExperimentsIdsByType = (
  rawGrowthExperiements: GrowthExperimentDefinition[],
  type: GrowthExperimentType,
): string[] =>
  rawGrowthExperiements
    .filter(
      (experiment: GrowthExperimentDefinition) => experiment.type === type,
    )
    .map((experiment: GrowthExperimentDefinition) => experiment.id);

const transformExperimentsToAmplitudeObject = (
  experimentsWithGroups: GrowthExperimentsWithABGroupsType,
  rawGrowthExperiements: GrowthExperimentDefinition[],
) => {
  const experimentNameIDDictionary = rawGrowthExperiements.reduce(
    (agg: Record<string, any>, current: GrowthExperimentDefinition) =>
      Object.assign(agg, { [current.name]: current.id }),
    {},
  );

  return Object.entries(experimentsWithGroups).reduce(
    (agg: Record<string, any>, [key, value]: [key: string, value: string]) => {
      agg[experimentNameIDDictionary[key]] = value;

      return agg;
    },
    {},
  );
};

const getABTestGroupForExperiment = (
  experiment: GrowthExperimentDefinition,
  firstVisitDate: string,
  userId?: string,
): ABTest => {
  const { type, id, numOfGroups } = experiment;

  switch (type) {
    case GrowthExperimentType.SIGNUP: {
      const stringToHash = `${firstVisitDate}-${id}`;

      return determineABTestGroup(stringToHash, numOfGroups);
    }
    case GrowthExperimentType.IN_APP:
    default: {
      const stringToHash = `${userId}-${id}`;

      return determineABTestGroup(stringToHash, numOfGroups);
    }
  }
};

const determineABTestGroup = (
  stringToHash: string,
  numOfGroups?: number,
): ABTest => {
  const hashedString = sha256(stringToHash);
  const lastChar = hashedString.substring(hashedString.length - 1);
  const decimalValue = parseInt(`0x0${lastChar}`, 16);

  if (numOfGroups) {
    return groups[decimalValue % numOfGroups];
  }

  return decimalValue < 8 ? ABTest.A : ABTest.B;
};

export const getGrowthExperimentsWithGroups = (
  experiments: GrowthExperimentDefinition[],
  growthFirstVisitDate: string | null,
  userId?: string,
): GrowthExperimentsWithABGroupsType =>
  experiments.reduce(
    (
      agg: GrowthExperimentsWithABGroupsType,
      current: GrowthExperimentDefinition,
    ) => {
      agg[current.name] = getABTestGroupForExperiment(
        current,
        growthFirstVisitDate as string,
        userId,
      ) as ABTest;

      return agg;
    },
    <GrowthExperimentsWithABGroupsType>{},
  );

const useAssignGrowthABGroups = (
  user?: IUser,
): { experiments: GrowthExperimentsWithABGroupsType; isReady: boolean } => {
  const { setExperiments, experiments } = useGrowthABExperimentsState();

  const [isReady, setIsReady] = useResetableState({
    init: false,
    deps: [user?._id],
  });

  const rawGrowthExperiements = useFeatures(
    'growthExperiments.experiments',
    unauthenticatedGrowthExperiments,
  );

  const growthFirstVisitDate = useMemo(() => {
    if (!localStorage.getItem('growth_first_visit_date')) {
      localStorage.setItem('growth_first_visit_date', new Date().toISOString());
    }

    return localStorage.getItem('growth_first_visit_date');
  }, []);

  useEffect(() => {
    const experimentsWithGroups = getGrowthExperimentsWithGroups(
      rawGrowthExperiements,
      growthFirstVisitDate,
      user?._id,
    );

    const amplitudeData = transformExperimentsToAmplitudeObject(
      experimentsWithGroups,
      rawGrowthExperiements,
    );

    setAmplitudeUserProperties({
      experimentGroups: _pick(
        amplitudeData,
        getExperimentsIdsByType(
          rawGrowthExperiements,
          GrowthExperimentType.IN_APP,
        ),
      ),
      webSignupExperimentGroups: _pick(
        amplitudeData,
        getExperimentsIdsByType(
          rawGrowthExperiements,
          GrowthExperimentType.SIGNUP,
        ),
      ),
    });

    // DON't REMOVE: NEEDED FOR PRODUCTION DEBUGGING
    console.log({
      experiments: experimentsWithGroups,
    });

    setIsReady(true);

    setExperiments(experimentsWithGroups);
  }, [
    rawGrowthExperiements,
    growthFirstVisitDate,
    user?._id,
    setExperiments,
    setIsReady,
  ]);

  return { experiments, isReady };
};

export default useAssignGrowthABGroups;
