import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { Helper } from 'components/molecules/Helper';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useVATReturnFrequency from 'hooks/shared/useVATReturnFrequency';
import useGetCustomeNavigationLink from 'hooks/useGetCustomNavigationLink';
import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import { Link } from 'react-router-dom';
import type { LockStatusType } from 'types/global.types';
import type { InvoiceTypeEnum } from 'types/invoice.types';
import type { AvailableCountries } from 'types/users.types';
import { getExpenseReferSlugs, getRevenuesReferSlugs } from 'utils/helpers';
import { LockClosedIconOutline } from 'utils/icons';
import getPeriodSlug from 'utils/taxes/getPeriodSlug';
import getTaxTitle from 'utils/taxes/getTaxTitle';

const markdownComponents: ReactMarkdownOptions['components'] = {
  ul: ({ children }) => (
    <ul className="list-disc list-inside mt-1">{children}</ul>
  ),
};

export const DocumentLockByTaxes: FC<{
  taxLock: LockStatusType;
  thisType: string;
  thisTypeAndOtherType: string;
  name?: string;
}> = ({ taxLock, thisType, thisTypeAndOtherType, name }) => {
  const { t } = useTranslation();
  const VATFrequency = useVATReturnFrequency();
  const country = useCustomerCountry() as AvailableCountries;
  const getLink = useGetCustomeNavigationLink();

  const taxesData = useMemo(() => {
    return (taxLock || []).map((reason) => {
      return {
        label: getTaxTitle(
          {
            type: reason.type,
            period: reason.period,
          },
          VATFrequency,
          country,
        ),
        link: getLink(
          `/taxes/${reason.readableType}/${getPeriodSlug({
            period: reason.period,
          })}`,
        ),
      };
    });
  }, []);

  return (
    <Alert
      type="default"
      name={name}
      icon={LockClosedIconOutline}
      title={
        <>
          <span className="block">
            {t('document.info_bubble.locked_by_taxes.with_links.' + thisType)}
          </span>
          <ul className="list-disc list-inside mt-1">
            {taxesData.map((data, i) => (
              <li key={i}>
                <Link className="underline" to={data.link}>
                  {data.label}
                </Link>
              </li>
            ))}
          </ul>
        </>
      }
      renderButtons={() => (
        <div className="flex justify-end mt-3">
          <Helper
            trigger={(openModal) => (
              <Button structure="text" color="primary" onClick={openModal}>
                {t('learn_more')}
              </Button>
            )}
          >
            <Helper.Title>
              {t('document.info_bubble.locked_by_taxes.title')}
            </Helper.Title>
            <Helper.Paragraph className="whitespace-pre-line">
              {t('document.info_bubble.locked_by_taxes.heading1')}{' '}
              {t(
                'document.info_bubble.locked_by_taxes.heading2.' +
                  thisTypeAndOtherType,
              )}
            </Helper.Paragraph>
            <Helper.Paragraph className="whitespace-pre-line">
              {t('document.info_bubble.locked_by_taxes.list_title')}
              <ul className="list-disc list-inside mt-1">
                {taxesData.map((data, i) => (
                  <li key={i}>
                    <Link className="underline" to={data.link}>
                      {data.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </Helper.Paragraph>
            <Helper.Paragraph className="whitespace-pre-line">
              {t('document.info_bubble.locked_by_taxes.footer1.' + thisType)}{' '}
              {t('document.info_bubble.locked_by_taxes.footer2')}
              <div className="whitespace-normal">
                <ReactMarkdown components={markdownComponents}>
                  {t(
                    'document.info_bubble.locked_by_taxes.unlock_document_process',
                  )}
                </ReactMarkdown>
              </div>
            </Helper.Paragraph>
          </Helper>
        </div>
      )}
    />
  );
};

export const ExpenseLockByTaxes: FC<{
  taxLock: LockStatusType;
  isCreditNote: boolean;
}> = ({ taxLock, isCreditNote }) => {
  const country = useCustomerCountry() as AvailableCountries;

  const { thisType, thisTypeAndOtherType } = getExpenseReferSlugs({
    isCreditNote,
    country,
  });

  const docType = isCreditNote ? 'credit-note' : 'expense';

  return (
    <DocumentLockByTaxes
      name={'expense:' + docType + ' tax locked'}
      taxLock={taxLock}
      thisType={thisType}
      thisTypeAndOtherType={thisTypeAndOtherType}
    />
  );
};

export const InvoiceLockByTaxes: FC<{
  taxLock: LockStatusType;
  type: InvoiceTypeEnum;
}> = ({ taxLock, type }) => {
  const country = useCustomerCountry() as AvailableCountries;

  const { thisType, thisTypeAndOtherType } = getRevenuesReferSlugs({
    type,
    country,
  });

  return (
    <DocumentLockByTaxes
      name={'invoice:' + type + ' tax locked'}
      taxLock={taxLock}
      thisType={thisType}
      thisTypeAndOtherType={thisTypeAndOtherType}
    />
  );
};
