import Joi from 'joi';
import isEmpty from 'lodash/isEmpty';
import { InvoiceStatusEnum } from 'types/invoice.types';
import { VATReturnFrequencyEnum } from 'types/users.types';
import {
  formatErrorMessage,
  validatePaymentAmount,
} from 'validation/commonValidators';
import type useFormMeta from './useFormMeta';

export const userDataValidationSchema = Joi.object({
  VATReturnFrequency: Joi.string().required(),
  VATType: Joi.string().required(),
});

const itemValidationSchema = Joi.object({
  unitAmountExclVAT: Joi.when('$unitAmountExclVAT.shouldValidate', {
    is: true,
    then: Joi.number()
      .min(0)
      .required()
      .messages({
        ...formatErrorMessage('invoiceItem', 'unitAmountExclVAT'),
        'number.min': 'invoiceItem.unitAmountExclVAT.number.min_0',
      }),
  }),
  unitAmountInclVAT: Joi.when('$unitAmountInclVAT.shouldValidate', {
    is: true,
    then: Joi.number()
      .min(0)
      .required()
      .messages({
        ...formatErrorMessage('invoiceItem', 'unitAmountExclVAT'),
        'number.min': 'invoiceItem.unitAmountExclVAT.number.min_0',
      }),
  }),
  VATRate: Joi.when('$VATRate.isApplicable', {
    is: true,
    then: Joi.number()
      .min(0)
      .required()
      .messages({ '*': 'invoiceItem.VATRate.any.required' }),
  }),
  quantity: Joi.number()
    .min(0.01)
    .required()
    .messages({
      ...formatErrorMessage('invoiceItem', 'quantity'),
      '*': 'invoiceItem.quantity.any.required',
    }),
  whyZeroVAT: Joi.when('$whyZeroVAT.shouldValidate', {
    is: true,
    then: Joi.string()
      .required()
      .messages({ '*': 'invoiceItem.whyZeroVAT.any.required' }),
  }),
  name: Joi.string()
    .max(900)
    .allow('')
    .messages(formatErrorMessage('invoiceItem', 'name'))
    .allow(null),
  categoryId: Joi.string()
    .required()
    .messages({ '*': 'invoiceItem.categoryId.any.required' }),
});

export const generalValidationSchema = Joi.object({
  status: Joi.string()
    .valid(...Object.values(InvoiceStatusEnum))
    .messages(formatErrorMessage('invoice', 'status')),

  paymentAmount: Joi.when('$paymentAmount.shouldValidate', {
    is: true,
    then: Joi.number().required().custom(validatePaymentAmount).messages({
      'number.min': 'invoice.paymentAmount.number.min',
      'number.min_0': 'invoice.paymentAmount.number.min_0',
      '*': 'invoice.paymentAmount.any.required',
    }),
  }),

  filePath: Joi.when('fileType', {
    is: 'imported',
    then: Joi.string().required().messages({
      '*': 'invoice.document.any.required',
    }),
  }),

  revenueNumber: Joi.string()
    .min(1)
    .custom((val, helpers) => {
      if (!/\d+/g.test(val)) {
        return helpers.error('missing.number');
      }
      return val;
    })
    .messages({ '*': 'invoice.revenueNumber.missing.number' }),
  invoiceDate: Joi.date()
    .iso()
    .required()
    .messages({ '*': 'invoice.invoiceDate.date.format' }),

  //----
  dueDate: Joi.when('$dueDate.shouldValidate', {
    is: true,
    then: Joi.date().iso().min(Joi.ref('invoiceDate')).required().messages({
      'date.min': 'invoice.dueDate.date.min',
      '*': 'invoice.dueDate.date.format',
    }),
  }),
  paymentDate: Joi.when('$paymentDate.shouldValidate', {
    is: true,
    then: Joi.date().iso().min(Joi.ref('invoiceDate')).required().messages({
      'date.min': 'invoice.paymentDate.date.min',
      '*': 'invoice.paymentDate.date.required',
    }),
  }),
  deliveryDate: Joi.when('$isGenerated', {
    is: false,
    then: Joi.when('$deliveryDate.shouldValidate', {
      is: true,
      then: Joi.date()
        .iso()
        .required()
        .messages({ '*': 'invoice.deliveryDate.any.required' }),
    }),
  }),
  deliveryPeriod: Joi.when('$isGenerated', {
    is: false,
    then: Joi.when('$deliveryPeriod.shouldValidate', {
      is: true,
      then: Joi.object()
        .required()
        .custom((value, helpers) => {
          if (!value.start || !value.end) {
            return helpers.error('any.required');
          }
          return value;
        })
        .messages({ '*': 'invoice.deliveryPeriod.any.required' }),
    }),
  }),
  //----

  clientId: Joi.when('$isOtherRevenue', {
    is: false,
    then: Joi.string()
      .required()
      .messages(formatErrorMessage('invoice', 'clientId')),
  }),

  items: Joi.when('$items.shouldValidate', {
    is: true,
    then: Joi.array()
      .min(1)
      .required()
      .messages(formatErrorMessage('invoice', 'items')),
  }),

  period: Joi.when('$period.shouldValidate', {
    is: true,
    then: Joi.object({
      year: Joi.number()
        .integer()
        .max(new Date().getFullYear() + 1)
        .required()
        .messages({ '*': 'invoice.periodYear.number.max' }),
      quarter: Joi.number()
        .integer()
        .min(1)
        .max(4)
        .when('/user.VATReturnFrequency', {
          is: VATReturnFrequencyEnum.quarterly,
          then: Joi.required().messages({
            '*': 'invoice.periodQuarter.any.invalid',
          }),
        }),
      month: Joi.number()
        .integer()
        .min(1)
        .max(12)
        .when('/user.VATReturnFrequency', {
          is: VATReturnFrequencyEnum.monthly,
          then: Joi.required().messages({
            '*': 'invoice.periodMonth.any.invalid',
          }),
        }),
    })
      .required()
      .messages({ '*': 'expense.period.object.empty' }),
  }),
  customData: Joi.object({
    customCategoryId: Joi.when('$customCategoryId.shouldValidate', {
      is: true,
      then: Joi.string()
        .required()
        .messages(formatErrorMessage('invoiceItem', 'categoryId')),
    }),
    vehicleId: Joi.when('$vehicleId.shouldValidate', {
      is: true,
      then: Joi.string().required().messages({
        '*': 'invoiceItem.vehicleId.required',
      }),
    }),
    usageStartDate: Joi.when('$usageStartDate.shouldValidate', {
      is: true,
      then: Joi.date()
        .required()
        .messages({ '*': 'invoiceItem.usageStartDate.required' }),
    }),
    usageEndDate: Joi.when('$usageEndDate.shouldValidate', {
      is: true,
      then: Joi.date()
        .required()
        .messages({ '*': 'invoiceItem.usageEndDate.required' }),
    }),
    distanceBetweenHomeAndOffice: Joi.when(
      '$distanceBetweenHomeAndOffice.shouldValidate',
      {
        is: true,
        then: Joi.number().required().min(1).messages({
          'number.min': 'invoiceItem.distanceBetweenHomeAndOffice.min',
          '*': 'invoiceItem.distanceBetweenHomeAndOffice.required',
        }),
      },
    ),
  }).allow(null),
});

export const getValidationSchema = ({
  getFormMeta,
  getItemMeta,
}: Pick<
  ReturnType<typeof useFormMeta>,
  'getFormMeta' | 'getItemMeta'
>): any => {
  return {
    validateAsync: (values: any, options: Joi.AsyncValidationOptions) => {
      const formMeta = getFormMeta(values);
      return Promise.allSettled([
        generalValidationSchema.validateAsync(values, {
          ...options,
          context: {
            ...options.context,
            ...formMeta.fields,
            ...formMeta.helpers,
          },
        }),
        Promise.allSettled(
          values.items?.map((item: any) => {
            if (formMeta.helpers.isItemsComputed) {
              return Promise.resolve({ status: 'fulfilled', value: item });
            }
            const itemMeta = getItemMeta(values, item);
            return itemValidationSchema.validateAsync(item, {
              ...options,
              context: {
                ...options.context,
                ...formMeta.fields,
                ...formMeta.helpers,
                ...itemMeta.fields,
                ...itemMeta.helpers,
              },
            });
          }) || [],
        ),
      ]).then(([general, items]) => {
        let errors = { _original: values, details: [] };

        if (general.status === 'rejected') {
          errors = {
            ...errors,
            details: errors.details.concat(general.reason.details),
          };
        }

        (
          items as PromiseFulfilledResult<PromiseSettledResult<any>[]>
        ).value.map((item, index: number) => {
          if (item.status === 'rejected') {
            errors = {
              ...errors,
              details: errors.details.concat(
                item.reason.details.map((detail: any) => ({
                  ...detail,
                  path: ['items', index].concat(detail.path),
                })),
              ),
            };
          }
        });

        if (!isEmpty(errors.details)) return Promise.reject(errors);

        return {
          ...(general as PromiseFulfilledResult<any>).value,
          items: (
            items as PromiseFulfilledResult<PromiseFulfilledResult<any>[]>
          ).value.map((item) => item.value),
        };
      });
    },
  };
};

export const germanCarsCustomSchema = Joi.object({
  customCategoryId: Joi.string()
    .required()
    .messages(formatErrorMessage('invoiceItem', 'categoryId')),
  vehicleId: Joi.string().required().messages({
    '*': 'invoiceItem.vehicleId.required',
  }),
  usageStartDate: Joi.date()
    .required()
    .messages({ '*': 'invoiceItem.usageStartDate.required' }),
  usageEndDate: Joi.date()
    .required()
    .messages({ '*': 'invoiceItem.usageEndDate.required' }),
  distanceBetweenHomeAndOffice: Joi.when('isVehicleUsedForOfficeCommute', {
    is: true,
    then: Joi.number().required().min(1).messages({
      'number.min': 'invoiceItem.distanceBetweenHomeAndOffice.min',
      '*': 'invoiceItem.distanceBetweenHomeAndOffice.required',
    }),
  }),
});
