import * as config from '../../config';

export enum SlackWebHooks {
  translation = 'translation',
  chift = 'chift',
}

const reportedKeys: Record<string, boolean> = {};

const reportOnSlack = (webHook: SlackWebHooks, text: string) => {
  // report a missing translation only once
  if (reportedKeys[text]) {
    return;
  }

  reportedKeys[text] = true;
  const body = JSON.stringify({ text });
  fetch(config.slackHooks[webHook] as RequestInfo, {
    method: 'POST',
    body,
  }).catch((err) => console.error(err));
};

const reportOnSlackMock = (_: SlackWebHooks, text: string) => null;
// console.error(`TRANSLATION FAILED: ${text}`);

const report =
  config.environment !== 'development' ? reportOnSlack : reportOnSlackMock;

export default report;
