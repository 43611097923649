import classNames from 'classnames';
import type { FC } from 'react';
import type { TableProps } from './table.types';
import { TableBody } from './TableBody';
import { TableCell } from './TableCell';
import { TableFooter } from './TableFooter';
import { TableHead } from './TableHead';
import { TableHeadCell } from './TableHeadCell';
import { TableRow } from './TableRow';

const TableComponent: FC<TableProps> = ({
  children,
  className,
  tableClassName,
  innerWrapperClassName,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'relative rounded-xl p-1.5 bg-primary-25 border border-primary-50',
        className,
      )}
    >
      <div
        className={classNames(
          'rounded-lg border border-primary-100',
          innerWrapperClassName,
        )}
      >
        <table
          className={classNames(
            'w-full text-left text-sm bg-white rounded-lg',
            tableClassName,
          )}
          {...props}
        >
          {children}
        </table>
      </div>
    </div>
  );
};

TableComponent.displayName = 'Table';
TableHead.displayName = 'Table.Head';
TableBody.displayName = 'Table.Body';
TableRow.displayName = 'Table.Row';
TableCell.displayName = 'Table.Cell';
TableHeadCell.displayName = 'Table.HeadCell';
TableFooter.displayName = 'Table.TableFooter';

export const Table = Object.assign(TableComponent, {
  Head: TableHead,
  Body: TableBody,
  Row: TableRow,
  Cell: TableCell,
  Footer: TableFooter,
  HeadCell: TableHeadCell,
});
