import { BankProviders } from 'types/banks.types';
import type { Connector } from 'types/transactions.types';
import {
  doesConnectorHasValidExpiryDate,
  getConnectorDaysLeft,
  isConnectorBankError,
} from 'utils/banks/banks.helpers';
import { isWeb } from 'utils/constants';

export default {
  provider: BankProviders.klarna,
  canRenewConsent: (connector: Connector) =>
    isWeb &&
    (isConnectorBankError(connector) ||
      (doesConnectorHasValidExpiryDate(connector) &&
        getConnectorDaysLeft(connector) < 15)),

  canSyncConnector: (connector: Connector) =>
    isWeb &&
    !isConnectorBankError(connector) &&
    doesConnectorHasValidExpiryDate(connector) &&
    getConnectorDaysLeft(connector) > 15 &&
    connector.provider !== 'accountable' &&
    !connector.manualSyncRequired,

  canAddConnector: () => isWeb,
  canSeeBankSettingPage: () => isWeb,
};
