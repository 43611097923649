import classNames from 'classnames';
import { Loader } from 'components/atoms/Loader';
import useWorldTheme from 'hooks/shared/useWorldTheme';
import type { FC } from 'react';

const themeColors: Record<string, string> = {
  revenues: '!text-revenues-200 !fill-revenues-600',
  expenses: '!text-expenses-200 !fill-expenses-600',
  taxes: '!text-taxes-200 !fill-taxes-600',
  bank: '!text-bank-200 !fill-bank-600',
  primary: '!text-primary-200 !fill-primary-600',
};

const CustomLoader: FC<{
  className?: string;
  message?: string;
  color?: string;
}> = ({ className, message, color }) => {
  const _color = useWorldTheme();

  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center w-full',
        className,
      )}
    >
      <Loader className={`${themeColors[color || _color]} !w-10 !h-10`} />
      {!!message && <h3 className="mt-3">{message}</h3>}
    </div>
  );
};
export default CustomLoader;
