import { CalendarIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import dayjs from 'dayjs';
import useWorldTheme from 'hooks/shared/useWorldTheme';
import useFeatures from 'hooks/useFeatures';
import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import DatePicker_ from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import type { IColors } from 'theme/colors';
import { getTracingEvents } from 'utils/amplitude';
import { captureLeftRightArrowEvents } from 'utils/captureLeftRightArrowEvents';
import { dateLocales } from 'utils/constants';
import './DatePicker.style.scss';
import type { DatePickerProps } from './DatePicker.types';

const colorMap: Record<IColors, string> = {
  expenses: 'date-picker-expenses',
  revenues: 'date-picker-revenues',
  taxes: 'date-picker-taxes',
  primary: 'date-picker-primary',
  bank: 'date-picker-bank',
};

const DatePicker: FC<DatePickerProps> = ({
  placeholder,
  iconStyle = {},
  hideIcon,
  iconWrapperClasses = '',
  wrapperClassName = '',
  containerClassName = '',
  color: propsColor,
  tracing,
  onCalendarOpen,
  onChange,
  compact,
  selected,
  minDate,
  maxDate,
  startDate,
  endDate,
  removeDefaultDateClasses,
  // it's important not to delete this key "value", as we don't want to pass it to the DatePicker_ component by mistake
  value,
  ...props
}) => {
  const color = useWorldTheme();

  const colorClass = colorMap[propsColor || color];

  const { i18n } = useTranslation();

  const MyContainer = useCallback(
    ({ className, children }: { className: string; children: ReactNode }) => (
      <div className={className}>{children}</div>
    ),
    [],
  );

  const tracingEvents = getTracingEvents(tracing, { onCalendarOpen, onChange });

  const dateFormat = useFeatures('formats.datePicker');

  const getDateValue = useCallback((date: DatePickerProps['selected']) => {
    if (date == null) return date;
    if (dayjs(date).isValid()) {
      // fix for timezone issue
      // https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
      // when we pass a string like this "2011-09-24" to the date constructor it thinks that this date is in UTC,
      // and then it tries to apply some kind of offset in order to make the date in the user local timezone
      // but apparently dayjs can handle this by default
      return dayjs(date).toDate();
    }
    return null;
  }, []);

  const selectedValue = useMemo(
    () => getDateValue(selected),
    [selected, getDateValue],
  );
  const minDateValue = useMemo(
    () => getDateValue(minDate),
    [minDate, getDateValue],
  );
  const maxDateValue = useMemo(
    () => getDateValue(maxDate),
    [maxDate, getDateValue],
  );
  const startDateValue = useMemo(
    () => getDateValue(startDate),
    [startDate, getDateValue],
  );
  const endDateValue = useMemo(
    () => getDateValue(endDate),
    [endDate, getDateValue],
  );

  return (
    <div
      className={classNames(
        'relative flex items-center flex-1',
        containerClassName,
        {
          'date-picker-atom-compact': compact,
          [`date-picker-atom ${colorClass}`]: !compact,
          'date-picker-error': !!props.error,
        },
      )}
    >
      <DatePicker_
        locale={dateLocales[i18n.language as keyof typeof dateLocales]}
        dateFormat={dateFormat}
        calendarContainer={MyContainer}
        renderDayContents={(dayNumber: number, dayDate: Date) => {
          return (
            <div className="react-datepicker__day-value w-full h-full relative p-0.5">
              <div className="abs-center">{dayNumber}</div>
            </div>
          );
        }}
        {...props}
        autoComplete="off"
        selected={selectedValue}
        minDate={minDateValue}
        maxDate={maxDateValue}
        startDate={startDateValue}
        endDate={endDateValue}
        onKeyDown={captureLeftRightArrowEvents}
        yearDropdownItemNumber={props.scrollableYearDropdown ? 100 : 20}
        wrapperClassName={wrapperClassName}
        placeholderText={placeholder}
        className={
          removeDefaultDateClasses
            ? undefined
            : classNames('!border-primary-100 outline-none min-w-0', {
                'text-primary-400 bg-primary-25 cursor-not-allowed':
                  props.disabled,
                'text-main bg-transparent': !props.disabled,
              })
        }
        onCalendarOpen={tracingEvents.onCalendarOpen}
        onChange={tracingEvents.onChange}
      />
      {!hideIcon ? (
        <div
          className={classNames(
            'pointer-events-none absolute flex items-center right-5 flex-1 h-5 border-l border-primary-100 text-primary-400 pl-4 z-5',
            {
              iconWrapperClasses,
              '!text-primary-400/50': props.disabled && !props.error,
              '!text-red-500': !!props.error,
            },
          )}
        >
          <CalendarIcon className={classNames('h-4 w-4')} style={iconStyle} />
        </div>
      ) : null}
    </div>
  );
};

export default DatePicker;
