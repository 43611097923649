import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import { Tooltip } from 'components/atoms/Tooltip';
import VariantSelection from 'components/molecules/Fields/VariantSelection';
import type { FC } from 'react';
import { useState } from 'react';
import type { CategoriesPickerSharedProps } from './categoriesPicker.types';

type ListQuestionsProps = CategoriesPickerSharedProps & {
  category: any;
  value?: string;
  onClick: (cate: any) => void;
};

const ListQuestions: FC<ListQuestionsProps> = ({
  category,
  value,
  onClick,
  tooltips,
  disabledCategories,
}) => {
  const [tabValue, setTabValue] = useState(() => {
    // if the value exists in a tab list, we need to open this tab for the user by default.
    const withSelectedCate = category.list.find(
      (cate: any) => cate.id === value,
    );
    if (withSelectedCate) return withSelectedCate.tab;
    return category.defaultTab;
  });
  return (
    <>
      <div className="mt-5 mb-4">
        <p className="text-primary-700 leading-tight font-bold text-lg">
          {category.title}
        </p>
        {category.question && (
          <p className="text-primary-700 font-bold text-lg mt-4">
            {category.question}
          </p>
        )}
        {category.questionDescription && (
          <p className="text-primary-400 font-semibold">
            {category.questionDescription}
          </p>
        )}
      </div>
      {category.tabs && category.tabs.length > 1 && (
        <div className="flex gap-4 pb-6 pt-2">
          <VariantSelection
            value={tabValue}
            onChange={(value) => setTabValue(value)}
            options={category.tabs}
          />
        </div>
      )}
      <div className="flex flex-col gap-4">
        {(category.tabs
          ? category.list.filter((item: any) => item.tab === tabValue)
          : category.list
        ).map((item: any) => {
          const isDisabled = item.disabled || disabledCategories?.[item.id];
          return (
            <Tooltip
              key={item.id}
              content={item.tooltip || tooltips?.[item.id]}
            >
              <Button
                asDefault
                onClick={() => !isDisabled && onClick(item)}
                className={classNames(
                  'transition-all border rounded-2xl p-4 text-left disabled:opacity-75 disabled:cursor-not-allowed',
                  'hover:shadow-lg focus-visible:shadow-lg',
                  {
                    'border-taxes-600 shadow-lg': item.id === value,
                    'opacity-75 bg-gray-100 cursor-not-allowed': isDisabled, // dont make the button it self disabled, the tooltip wont work
                  },
                )}
              >
                <p className="text-primary-700 font-semibold first-letter:capitalize">
                  {item.title}
                </p>
                <p className="text-primary-700 text-sm leading-tight">
                  {item.description}
                </p>
              </Button>
            </Tooltip>
          );
        })}
      </div>
    </>
  );
};

export default ListQuestions;
