import { default as api, default as axiosInstance } from 'api/axiosInstance';
import type { AxiosRequestConfig } from 'axios';
import { paymentQrCodeAndCommunicationGuard } from 'components/pages/Revenues/GeneratedRevenueForm/InvoiceForm/banking.helpers';
import dayjs from 'dayjs';
import { stringify } from 'query-string';
import { IClient } from 'types/clients.types';
import {
  CurrenciesEnum,
  type Currency,
  type FilterResponse,
  type LockStatusType,
  type LockStatusTypeCatgoriesed,
} from 'types/global.types';
import type {
  GeneratedInvoiceFormValues,
  Invoice,
  InvoiceCategory,
  InvoiceItem,
  InvoiceItemsCalculations,
  InvoiceLog,
  InvoiceType,
  Quote,
  UpdateInvoiceStatusBody,
} from 'types/invoice.types';
import { InvoiceStatusEnum, InvoiceTypeEnum } from 'types/invoice.types';
import type {
  IPaginationRequest,
  IPaginationResponse,
  Paging,
} from 'types/pagination.types';
import type { IRevenue, VATPeriod } from 'types/revenues.types';
import type { TaxImpact } from 'types/taxes.types';
import type { Transaction } from 'types/transactions.types';
import { AvailableCountries, VATTypeEnum } from 'types/users.types';
import { invoiceItemsEmptyCalculations, isExpert } from 'utils/constants';
import { onCreateInvoiceGTM, onCreateQuoteGTM } from 'utils/GTM';
import { formatLockStatus } from 'utils/helpers';
import { FIVE_MINUTES, TWO_MINUTES } from 'utils/time';

const service = '/v2/revenues';
const quotesService = '/v2/quotes';

export type getRevenuesParams = IPaginationRequest & {
  periods?: string[];
  text?: string;
  source?: string;
  reviewed?: boolean;
  isInvoice?: boolean;
  categoryIds?: string[];
  clientIds?: string[];
  isValidated?: boolean;
  fields?: string[];
  sort?: string;
  dateRange?: string[];
  taxPeriod?: any;
};

type IAPIGetRevenueQRCodeParams = {
  name: string;
  iban: string;
  amount: number;
  currency: string;
  purposeCode?: string;
  structuredReference?: string;
  unstructuredReference?: string;
  information?: string;
};

type IAPIBulkUpdateRevenuesBody = {
  resourceId: string;
  body: { reviewed: boolean };
}[];

type IAPIBulkUpdateRevenuesResponse = {
  _id: string;
  ok: boolean;
  status: string;
  errors?: { message: string; code: string }[];
  type?: string;
}[];

type IAPIGetSuggestedItemsResponse = {
  items: InvoiceItem[];
};

type IAPIGetCustomUnitsResponse = {
  customUnits: string[];
};

export type IAPIGetCalculateDataBody = {
  clientId: string;
  invoiceDate: string;
  items: any[];
  type: InvoiceType;
  options: {
    currency: string;
  };
};

type IAPIGetCalculateDataResponse = {
  calculations: {
    totalAmountExclVAT: number;
    totalVATAmount: number;
    totalAmountInclVAT: number;
    baseCurrencyTotalAmountInclVAT: number;
    items: [
      {
        totalAmountExclVAT: number;
        totalVATAmount: number;
        totalAmountInclVAT: number;
      },
    ];
    totalVATAmount_6: number;
    totalVATAmount_12: number;
    totalVATAmount_21: number;
    totalVATAmount_5: number;
    totalVATAmount_7: number;
    totalVATAmount_16: number;
    totalVATAmount_19: number;
  };
  currencyRate: number;
  copyRight: {
    copyrightRevenueAmountExclTax: number;
    copyrightRevenueTaxAmount: number;
  };
  legalNotes: {
    en: string;
    fr: string;
    nl: string;
    de: string;
  };
};

export const getFilePath = (params: {
  filePath: string;
}): Promise<{ url: string }> => {
  return axiosInstance.get(`/v2/users/file-url`, { params });
};
export const getQuoteById = (id: string): Promise<Quote> =>
  api
    .get(`${quotesService}/${id}`, {
      params: {
        expand: 'transactions',
      },
    })
    .then((doc: any) => {
      const transactions = doc?.transactions?.filter((t: any) => !!t);
      return {
        ...doc,
        transactions: transactions?.map((t: Transaction) => t._id),
        _matchedTransactions: transactions,
      };
    });

export const getRevenueById = (
  id: string,
  isQuote?: boolean,
): Promise<Invoice> =>
  api
    .get(`${isQuote ? quotesService : service}/${id}`, {
      params: {
        expand: 'transactions,taxLock',
      },
    })
    .then((doc: any) => {
      const transactions = doc?.transactions?.filter((t: any) => !!t);
      return {
        ...doc,
        transactions: transactions?.map((t: Transaction) => t._id),
        _matchedTransactions: transactions,
      };
    });

export const createRevenue = (body: any) => {
  return api.post<any, Invoice<IClient>>(`${service}`, body).then((data) => {
    onCreateInvoiceGTM();

    return data;
  });
};

export const updateRevenue = (
  id: string,
  body: any,
): Promise<Invoice<IClient>> => {
  return api.put(`${service}/${id}`, body);
};

export const createGeneratedRevenue = async (
  body: GeneratedInvoiceFormValues,
) => {
  const data = await paymentQrCodeAndCommunicationGuard(body);
  return createRevenue({ ...body, ...data });
};

export const updateGeneratedRevenue = async (
  id: string,
  body: GeneratedInvoiceFormValues,
) => {
  const data = await paymentQrCodeAndCommunicationGuard(body);
  return updateRevenue(id, { ...body, ...data });
};

export const createQuote = (body: any) => {
  return api.post<any, Quote>(quotesService, body).then((data) => {
    onCreateQuoteGTM();
    return data;
  });
};
export const updateQuote = (id: string, body: any): Promise<Quote> => {
  return api.put(`${quotesService}/${id}`, body);
};

export const patchRevenue = (id: string, body: any): Promise<any> =>
  api.patch(`${service}/${id}`, body).then(({ data }) => data);

export const saveInvoiceSettings = ({ isQuote, ...body }: any): Promise<any> =>
  api.patch(`/v2/users/settings/${isQuote ? 'quotes' : 'invoices'}`, body);

export const saveQuoteSettings = (body: any): Promise<any> =>
  api.patch(`/v2/users/settings/quotes`, body);

type CalculationsData = {
  type?: string;
  items: Partial<InvoiceItem>[];
  invoiceDate: string;
  language?: string;
  clientId?: string;
  options?: {
    currency?: Currency;
  };
};

export const calculateItems = (calculationsData: CalculationsData) =>
  api
    .post<any, InvoiceItemsCalculations>(
      `${service}/calculated-data`,
      calculationsData,
    )
    .then((data) => ({
      ...invoiceItemsEmptyCalculations,
      ...data,
      calculations: {
        ...invoiceItemsEmptyCalculations.calculations,
        ...data?.calculations,
      },
      copyRight: {
        ...invoiceItemsEmptyCalculations.copyRight,
        ...data?.copyRight,
      },
    }));

export const getCurrencyRate = (
  unitAmountInclVAT: number,
  currency: Currency = CurrenciesEnum.EUR,
) => {
  if (currency === CurrenciesEnum.EUR) {
    return Promise.resolve(unitAmountInclVAT);
  }
  if (!unitAmountInclVAT) {
    return Promise.resolve(0);
  }
  return api
    .post<any, InvoiceItemsCalculations>(`${service}/calculated-data`, {
      type: 'invoice',
      items: [
        {
          quantity: 1000,
          unitAmountInclVAT,
          discountPercentage: 0,
          doesUnitPriceIncludeVAT: true,
        },
      ],
      invoiceDate: dayjs().format('YYYY-MM-DD'),
      options: {
        currency,
      },
    })
    .then((data) => data.calculations.baseCurrencyTotalAmountInclVAT);
};

export const getRevenuesCommunication = (
  clientId: string,
  revenueNumber: string,
): Promise<string> =>
  api
    .get<any, { communication: string }>(
      `${service}/communication?${stringify({ clientId, revenueNumber })}`,
    )
    .then(({ communication }) => communication);

export const getRevenuesQRCode = (
  params: IAPIGetRevenueQRCodeParams,
): Promise<{ paymentQrCode: string }> => {
  return api.get(`${service}/payment-qr-code?${stringify(params)}`);
};

export const getRevenuesLegalNotes = (
  body: { whyZeroVAT: string; categoryId: string }[],
): Promise<{
  legalNotes: { en: string; fr: string; nl: string; de: string };
}> => api.post(`${service}/legal-notes`, { items: body });

export const bulkUpdateRevenues = (
  ops: IAPIBulkUpdateRevenuesBody,
): Promise<IAPIBulkUpdateRevenuesResponse> =>
  api.patch(`${service}/bulk`, { ops });

export const bulkDeleteRevenues = (
  ids: string[],
): Promise<IAPIBulkUpdateRevenuesResponse> =>
  api.delete(`${service}/bulk?${stringify({ ids }, { arrayFormat: 'comma' })}`);

export const bulkValidateRevenues = (
  ids: string[],
): Promise<IAPIBulkUpdateRevenuesResponse> => {
  return api.patch(`${service}/bulk`, {
    ops: ids.map((id) => ({
      resourceId: id,
      body: {
        reviewed: true,
      },
    })),
  });
};

export const deleteRevenue = (
  id: string,
  params?: Record<string, string>,
): Promise<void> => api.delete(`${service}/${id}`, { params });

export const getRevenuesCurrencies = (): Promise<{ [key: string]: string }> =>
  api.get(`${service}/currencies`).then((data: any) => data.currencies);

export const getExchangeRates = (
  date: string,
): Promise<{ exchangeRates: { [key: string]: string }[] }> =>
  api.get(`${service}/exchange-rates?documentDate=${date}`);

export const getSuggestedItems = (
  params?: { name?: string } & Partial<IPaginationRequest>,
) =>
  api
    .get<any, IAPIGetSuggestedItemsResponse & IPaginationResponse>(
      `${service}/suggested-items`,
      {
        params: { page: 1, perPage: 100, ...params },
      },
    )
    .then(({ items, paging }) => ({ data: items, paging }));

export const getCustomUnits = () =>
  api
    .get<any, IAPIGetCustomUnitsResponse>(
      `${service}/suggested-items?perPage=1`,
    )
    .then(({ customUnits }) => customUnits);

export const deleteSuggestedItems = (name: string) =>
  api.delete(`${service}/suggested-items/${encodeURIComponent(name)}`);

export const deleteCustomUnit = (unit: string) => {
  return api.delete(`${service}/custom-units/${encodeURIComponent(unit)}`);
};

export const getCalculatedData = (
  body: IAPIGetCalculateDataBody,
  config?: any,
): Promise<IAPIGetCalculateDataResponse> =>
  api.post(`${service}/calculated-data`, body, config);

export const getRevenuesFilters = (): Promise<FilterResponse[]> =>
  api.get(`${service}/filters`);

export const getRevenues = (
  query: getRevenuesParams,
): Promise<{ data: Invoice[] } & IPaginationResponse> =>
  api.get(
    `${service}?${stringify(query, {
      skipEmptyString: true,
      arrayFormat: 'comma',
    })}`,
  );

export const updateInvoiceStatus = ({
  id,
  params,
}: {
  id: string;
  params: UpdateInvoiceStatusBody;
}): Promise<any> => {
  return api.put(`${service}/${id}/status`, params);
};

export const getNextRevenueNumber = async (params: {
  type: InvoiceType;
  date: string;
}): Promise<string | null> => {
  const isQuote = params.type === InvoiceTypeEnum.QUOTE;
  return axiosInstance
    .get(
      `${isQuote ? quotesService : service}/next-number?${stringify({
        type: params.type,
        [isQuote ? 'quoteDate' : 'invoiceDate']: params.date,
      })}`,
    )
    .then((data: any) =>
      isQuote ? data.nextQuoteNumber : data.nextRevenueNumber,
    );
};

export const checkIfRevenueNumberExists = ({
  type,
  revenueNumber,
}: {
  type: InvoiceType;
  revenueNumber: string;
}): Promise<boolean> => {
  const isQuote = type === InvoiceTypeEnum.QUOTE;
  return axiosInstance
    .get(
      `${
        type === InvoiceTypeEnum.QUOTE ? quotesService : service
      }/number-exists?${stringify({
        type,
        [isQuote ? 'quoteNumber' : 'revenueNumber']: revenueNumber,
      })}`,
    )
    .then((data: any) => data.exists);
};

export const getRevenuesCategories = (params?: any): Promise<any> =>
  api.get(`${service}/categories`, params);

export const getRevenueTaxImpact = (
  revenue: Partial<Invoice>,
): Promise<any> => {
  return api
    .post(`${service}/tax-impact`, revenue, {
      params: { taxImpactRequested: true },
    })
    .then(({ data }) => data);
};

export const getRevenueVATCells = (
  revenue: Partial<Invoice>,
): Promise<{ key: string; value: number }[]> => {
  return api
    .post<
      any,
      { data: { total: { vat: { statement: Record<string, number> } } } }
    >(`${service}/tax-impact`, revenue, {
      ignoreToast: true,
    } as any)
    .then(({ data }) => {
      return Object.entries(data.total.vat.statement)
        .filter(([key, value]) => !!value)
        .map(([key, value]) => ({ key, value }));
    });
};

export const getSavedRevenueTaxImpact = (
  revenue: IRevenue,
): Promise<TaxImpact> => {
  return api
    .get(`${service}/${revenue._id}/tax-impact`, {
      params: { taxImpactRequested: true },
    })
    .then(({ data }) => data);
};

export const getRevenueDuplicates = (
  revenueId: string,
): Promise<(Invoice & { duplicateReason?: string })[]> => {
  return axiosInstance
    .get<
      any,
      {
        duplicates: Invoice[];
        types: { revenueId: string; type: string }[];
      }
    >(
      `${service}/${revenueId}/duplicates?${stringify({
        expand: ['taxLock'],
        perPage: 10,
        page: 1,
      })}`,
      {
        timeout: TWO_MINUTES,
      },
    )
    .then((data) => {
      const reasons = {} as Record<string, string>;
      data.types.forEach((type) => {
        reasons[type.revenueId] = type.type;
      });
      return data.duplicates.map((dup) => ({
        ...dup,
        duplicateReason: reasons[dup._id],
      }));
    });
};

export const getRevenueOCR = (params: {
  filename: string;
  type?: InvoiceType;
}): Promise<Partial<Invoice>> => {
  return axiosInstance.get(`${service}/ocr`, {
    params,
    timeout: FIVE_MINUTES,
  });
};

type GenerateRevenueParams = {
  generateUbl21?: boolean;
  generateUblBe?: boolean;
};
export const generateRevenue = (
  revenueId: string,
  params?: GenerateRevenueParams,
): Promise<{ filePath: string }> =>
  axiosInstance.get(`${service}/${revenueId}/generate-invoice`, { params });

export type EmailData = {
  to: string[];
  cc?: string[];
  bcc?: string[];
  subject: string;
  htmlTemplate: string;
  attachInvoicePdf?: boolean;
  attachInvoiceUbl21?: boolean;
  attachInvoiceUblBe?: boolean;
  attachQuotePdf?: boolean;
  saveTemplate?: boolean;
  enableTracking?: boolean;
};
export const sendRevenueEmail = (revenueId: string, data: EmailData) =>
  axiosInstance.post(`${service}/${revenueId}/email`, data);

type LockStatusBody = {
  status?: string;
  invoiceDate?: string;
  paymentDate?: string;
  period?: VATPeriod;
  user?: {
    VATType?: string;
    VATReturnFrequency?: string;
  };
};

type LockStatusResponse = { taxLock: LockStatusType };

export const getLockStatus = (
  body: LockStatusBody,
  country: AvailableCountries,
): Promise<LockStatusTypeCatgoriesed> => {
  if (!body.user?.VATReturnFrequency || !body.user?.VATType) {
    return Promise.resolve(null);
  }
  if (body.user?.VATType === VATTypeEnum.subjectToVAT && !body.period) {
    return Promise.resolve(null);
  }

  if (country === AvailableCountries.belgium) {
    if (
      !body.invoiceDate ||
      (body.status !== InvoiceStatusEnum.sent &&
        body.status !== InvoiceStatusEnum.paid)
    ) {
      return Promise.resolve(null);
    }
  }
  if (country === AvailableCountries.germany) {
    if (!body.paymentDate || body.status !== InvoiceStatusEnum.paid) {
      return Promise.resolve(null);
    }
  }

  const _body = {
    ...body,
    invoiceDate: undefined,
    paymentDate: undefined,
    taxDate:
      country === AvailableCountries.germany
        ? body.paymentDate
        : body.invoiceDate,
  };

  return api
    .post<any, LockStatusResponse>(`${service}/tax-lock`, _body, {
      ignoreToast: true,
    })
    .then((data) => formatLockStatus(data.taxLock))
    .catch(() => null);
};

export const patchLockedInvoice = (
  invoiceId: string,
  body: Pick<
    Invoice<any>,
    'transactions' | 'notes' | 'accountantReview' | 'paymentType' | 'status'
  >,
) => {
  return api.patch(`${service}/${invoiceId}/locked`, {
    transactions: body.transactions,
    notes: body.notes || null,
    accountantReview: isExpert ? body.accountantReview : undefined,
    paymentType: body.paymentType || null,
    status: body.status,
  });
};

export const patchLockedInvoiceStatus = (
  invoiceId: string,
  status: InvoiceStatusEnum,
) => {
  return api.patch(`${service}/${invoiceId}/locked`, { status });
};

export const sendReminderEmail = (revenueId: string, data: EmailData) => {
  return axiosInstance.post(`${service}/${revenueId}/reminders`, data);
};

export const getRevenueLogs = (
  revenueId: string,
  page?: number,
): Promise<{
  data: InvoiceLog[];
  pagination: Pick<Paging, 'page' | 'perPage' | 'totalCount'>;
}> => {
  return api.get(`${service}/${revenueId}/logs`, { params: { page } });
};
export type FormCustomDataBody = {
  customType: string;
  customData: Record<string, any>;
  user: {
    VATType: string;
    VATReturnFrequency: string;
  };
};

export const getFormCustomData = (
  body: FormCustomDataBody,
  config?: AxiosRequestConfig,
): Promise<{ data: { items: InvoiceItem[] } }> => {
  return axiosInstance.post(`${service}/custom-type-data`, body, config);
};

export const getRevenuesCategoryById = (
  categoryId: string,
  country: AvailableCountries,
  config?: AxiosRequestConfig,
): Promise<InvoiceCategory> =>
  api
    .get(`${service}/categories/${country}/${categoryId}`, config)
    .then(({ data }) => data);
