import dayjs from 'dayjs';
import { CURRENT_YEAR } from 'utils/constants';

const prependPositiveSign = (num: number): string | number =>
  num > 0 ? `+${num}` : num;

const getReviewYears = () => {
  const isJan = dayjs().month() === 0; // Meaning the page is being viewed during January of the new year

  if (isJan)
    return {
      year: CURRENT_YEAR - 1,
      newYear: CURRENT_YEAR,
      previousYear: CURRENT_YEAR - 2,
    };

  return {
    year: CURRENT_YEAR,
    newYear: CURRENT_YEAR + 1,
    previousYear: CURRENT_YEAR - 1,
  };
};

export { prependPositiveSign, getReviewYears };
