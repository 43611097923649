import { CellsModifiers } from 'types/CellsModifiers.types';
import type { ExpenseItem } from 'types/expense.types';
import { AvailableLanguages } from 'types/users.types';

const expenses = {
  header: {
    helper: {
      title: 'de.expenses.deductible_costs',
      description: [
        'de.expenses.deductible_costs_helper_1',
        'de.expenses.deductible_costs_helper_2',
        'de.expenses.deductible_costs_helper_3',
        'de.expenses.deductible_costs_helper_4',
        'de.expenses.deductible_costs_helper_5',
      ],
      ctaDesc: 'question_on_topic',
      ctaText: 'talk_to_an_expert',
      onClick: (): void => {},
    },
  },
  hasDeductibilityField: false,
  filters: {
    is_invoice: false,
  },
  can_link_vehicle: false,
  can_pay_with_qr_code: false,
  hasTranslatedCategories: false,
  is_customer_company: (): boolean => false,
  is_start_date_required: (): boolean => false,
  is_amortization: (item: ExpenseItem): boolean =>
    Boolean(item.amount >= 250 && item.category?.suggestedAmortizationPeriod),
  has_type: false,
  reverse_charge_helper: {
    title: 'expenses.helpers.reverse_charge_expense.title',
    description: [
      'expenses.helpers.reverse_charge_expense.text1',
      'expenses.helpers.reverse_charge_expense.text2',
    ],
  },
  vat_rates: [0, 0.07, 0.19],
  vat_rates_deductible_2020: [0.05, 0.16],
  default_vat_rate: 0.19,
  max_vat_rate: 0.19,
  list: {
    vat_inclusion_key: '',
    group_expenses: {
      web: 'isValidated',
      experts: 'reviewed',
    },
    cells: {
      _modifiers: [{ name: CellsModifiers.expenses }],
      // when a expense is highlighed we show only these columns
      cells_when_highlighted: ['SUPPLIER', 'CATEGORY', 'DATE', 'AMOUNT'],
      default_sort: {
        web: 'expenseDate_desc',
        experts: 'expenseDate_desc',
      },
      first_group: {
        web: [
          {
            cellName: 'SUPPLIER',
            headerName: 'expenses.cells.expenses_reviewed',
          },
          'DATE',
          'CATEGORY',
          'ATTACHMENT',
          'AMOUNT',
        ],
        experts: [
          {
            cellName: 'SUPPLIER',
            headerName: 'expenses.cells.expenses_validated',
          },
          'DATE',
          'CATEGORY',
          'ATTACHMENT',
          'AMOUNT',
          'REVIEWED',
        ],
      },
      second_group: {
        web: [
          {
            cellName: 'SUPPLIER',
            headerName: 'expenses.cells.expenses_to_review',
          },
          'DATE',
          {
            cellName: 'CATEGORY',
            headerName: 'expenses.cells.suggested_category',
          },
          'ATTACHMENT',
          'AMOUNT',
        ],
        experts: [
          {
            cellName: 'SUPPLIER',
            headerName: 'expenses.cells.expenses_to_validate',
          },
          'DATE',
          'CATEGORY',
          'ATTACHMENT',
          'AMOUNT',
        ],
      },
    },
  },

  blocked: {
    web: false,
    experts: false,
  },
  deductible_tax_categories: ['de.taxes_and_insurance.vat_payment'],
  all_you_can_deduct_link_origin: (lang: AvailableLanguages) => {
    const link = 'https://kannichdasabsetzen.accountable.de';
    if (lang === AvailableLanguages.de) return link;
    return link + '/' + lang;
  },
  includeVATAmountIfDeductible: true,
  irrelevantFilters: ['isInvoice'],
};

export default expenses;
