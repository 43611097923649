import { Button } from 'components/atoms/Button';
import { FeedbackMessage } from 'components/templates/FeedbackMessage';
import getIntercom from 'hooks/intercom.hook';
import type { FC } from 'react';
import { useNavigate } from 'react-router';

const NotFoundPage: FC<{ goBack?: () => void }> = ({ goBack }) => {
  const navigate = useNavigate();
  const { showIntercom } = getIntercom();
  return (
    <FeedbackMessage
      title={`Oops, looks like you're lost . . .`}
      message={`The page you're looking for, doesn't exist`}
      feedbackMessageControllers={
        <>
          <Button
            color="taxes"
            size={'xl'}
            onClick={() => (goBack ? goBack() : navigate(-1))}
          >
            {'Go back'}
          </Button>
          <Button color="primary" size={'xl'} onClick={showIntercom}>
            {'Contact support'}
          </Button>
        </>
      }
    />
  );
};

export default NotFoundPage;
