import type { Invoice } from 'types/invoice.types';
import type { IAddress, IUser } from 'types/users.types';

const defaultMissingValues = (obj?: IAddress) =>
  Object.keys(obj || {}).reduce(
    (acc, key) => ({ ...acc, [key]: obj?.[key as keyof typeof obj] || null }),
    {},
  );

const formatCustomer = (
  customer: Partial<IUser>,
  invoice?: Invoice,
): IUser => ({
  ...(customer._raw && customer._raw),
  firstName: customer.firstName || undefined,
  lastName: customer.lastName || undefined,
  companyName: customer.companyName || null,
  startDate: customer.startDate,
  IBAN: customer.IBAN || undefined,
  SWIFT: customer.SWIFT || null,
  email: customer.email || undefined,
  VATNumber: customer.VATNumber || null,
  VATType: customer.VATType || undefined,
  language: customer.language,
  address: defaultMissingValues(customer.address),
  steuernummer: customer.steuernummer || null,
  phoneNumber:
    invoice?.userSnapshot?.phoneNumber || customer.phoneNumber || null,
  bufaNumber: customer.bufaNumber,
  occupation: customer.occupation || null,
  APC1: customer.APC1 || null,
  APC2: customer.APC2 || null,
  activity: customer.activity || undefined,
  complementaryDays: customer.complementaryDays || undefined,
  photoPath: customer.photoPath || undefined,
  nationalRegistryNumber: customer.nationalRegistryNumber || null,
  accountType: customer.accountType || undefined,
  firstTaxComputationYear: customer.firstTaxComputationYear || undefined,
  VATReturnFrequency: customer.VATReturnFrequency || undefined,
  settings: customer.settings || undefined,
  showProjectedIncomeTax: customer.showProjectedIncomeTax,
  professionType: customer.professionType,
  aiUsageConsent: customer.aiUsageConsent,
});

export default formatCustomer;
