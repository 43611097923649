import { useFormContext } from 'react-hook-form';

import { validateSteuernummer } from 'api/v1/users/validateSteuernummber';
import { getUserFinanzamt } from 'api/v2/users/getUserFinanzamt';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { UserCacheKeys } from 'types/cacheKeys.types';
import { AccountSettingsFieldsNamesEnum } from 'types/settings.types';
import useDebounce from './useDebounce';

// Validates the Steuernummer (Tax ID) for German accounts based on the German State
// and the actual Tax ID
const useValidateSteuernummer = ({
  name,
  stateValue,
  steuernummerValue,
  disabled,
}: {
  name?: string;
  stateValue: string;
  steuernummerValue: string;
  disabled?: boolean;
}): { isValidatingSteuernummer: boolean; finanzamtOffice: string } => {
  const { setError, setValue, clearErrors } = useFormContext();
  const fieldName = name || AccountSettingsFieldsNamesEnum.steuernummer;
  const _steuernummerValue = useDebounce(1000, steuernummerValue);

  const steuernummerQuery = useQuery(
    [
      UserCacheKeys.ValidateSteuernummer,
      { stateValue, _steuernummerValue, fieldName },
    ],
    () => {
      clearErrors();
      return validateSteuernummer(stateValue, _steuernummerValue);
    },
    {
      cacheTime: 0,
      enabled: !!(stateValue && _steuernummerValue?.length >= 10),
      onSuccess: (data) => {
        if (!data?.valid) {
          setValue('steuernummerValid', false);
          return setError(fieldName, {
            type: 'custom',
            message: 'settings.steuernummer.any.invalidCombination',
          });
        }
        setValue('steuernummerValid', true);
      },
      onError: () => {
        setValue('steuernummerValid', false);
        setError(fieldName, {
          type: 'custom',
          message: ' ',
        });
      },
    },
  );

  useEffect(() => {
    if (steuernummerValue?.length < 10) {
      setValue('steuernummerValid', false);
      setError(fieldName, { type: 'custom', message: '' });
    }
  }, [steuernummerValue]);

  const finanzamtQuery = useQuery(
    [UserCacheKeys.UserFinanzamt, { _steuernummerValue, stateValue }],
    () => getUserFinanzamt(_steuernummerValue, stateValue),
    {
      enabled: !!(
        _steuernummerValue &&
        stateValue &&
        steuernummerQuery.data?.valid
      ),
      cacheTime: 0,
    },
  );

  return {
    isValidatingSteuernummer:
      steuernummerQuery.isLoading || finanzamtQuery.isLoading,
    finanzamtOffice: finanzamtQuery.data?.taxOffice as string,
  };
};

export default useValidateSteuernummer;
