import api from 'api/axiosInstance';
import type { Asset } from 'types/assets';
import { AssetStatus } from 'types/assets';

export const getAsset = (assetId: string): Promise<Asset> => {
  return api.get(`/v2/assets/${assetId}`).then((response) => response.data);
};

export const getAssets = (): Promise<Asset[]> => {
  return api.get(`/v1/users/me/assets`);
};

export const getCategorisedAssets = () => {
  return getAssets().then((assets) => {
    const lost: Asset[] = [];
    const sold: Asset[] = [];
    const fullyAmortized: Asset[] = [];
    const underAmortization: Asset[] = [];

    assets.forEach((asset) => {
      const { status, invoice, amountToAmortize } = asset;
      if (
        status === AssetStatus.BROKEN ||
        status === AssetStatus.LOST ||
        status === AssetStatus.STOLEN
      ) {
        return lost.push(asset);
      }

      if (invoice) {
        return sold.push(asset);
      }

      if (amountToAmortize === 0) {
        return fullyAmortized.push(asset);
      }

      return underAmortization.push(asset);
    });

    return {
      lost,
      sold,
      fullyAmortized,
      underAmortization,
    };
  });
};

export const updateAsset = (asset: Asset): Promise<Asset> => {
  return api.put(`/v1/users/me/assets/${asset._id}`, asset);
};
