import { AccountTypeShortEnum } from 'applications/web/pages/UserSignup/Shared/helpers';
import firstClientUrlDE from 'assets/images/Andreas_Reichert.jpeg';
import secondClientUrlDE from 'assets/images/Markus_Thomas_Boldt.png';
import appstoreQRCode from 'assets/images/QR_apple_DE.png';
import googlePlayQRCode from 'assets/images/QR_google_DE.png';
import { HOME_PAGE_STATE } from 'contexts/HomeProvider';
import dayjs from 'dayjs';
import appI18nInstance from 'i18n';
import _isEmpty from 'lodash/isEmpty';
import type { HomeUtils, StarterTodoItemsList } from 'types/home.types';
import { AvailableLanguages, type IUser } from 'types/users.types';
import { openInNewTab } from 'utils/helpers';
import banks from './banks';
import clients from './clients';
import customers from './customers';
import expenses from './expenses';
import growthExperiments from './growthExperiments';
import paywall from './paywall';
import period from './period';
import revenue from './revenue';
import revenues from './revenues';
import settings from './settings';
import taxes from './taxes';
import transactions from './transactions';

export default {
  clients,
  customers,
  expenses,
  revenue,
  revenues,
  period,
  settings,
  transactions,
  growthExperiments,
  taxes,
  banks,
  home: {
    blocked: {
      web: true,
      experts: true,
    },
  },
  showProfitAndLossInMoreMenu: false,
  testimony_img_urls: {
    firstClientUrl: firstClientUrlDE,
    secondClientUrl: secondClientUrlDE,
  },
  formats: {
    calc_phone_prefix: '+49',
    date: 'DD.MM.YYYY',
    datePicker: 'dd.MM.yyyy',
  },
  paywall,
  mobileAppData: {
    appStoreURL:
      'https://apps.apple.com/eg/app/accountable-self-employed/id1384729810',
    googlePlayURL:
      'https://play.google.com/store/apps/details?id=com.hivearts.accountable&gl=DE&pli=1',
    appstoreQRCode,
    googlePlayQRCode,
  },
  canAccessPeppol: false,
  getTypeformLink: (lang: AvailableLanguages) => {
    if (lang === AvailableLanguages.de) {
      return 'https://getaccountable.typeform.com/to/pwX4WsmI';
    }
    return 'https://getaccountable.typeform.com/to/UYUf4IWU';
  },
  getTrustpilotLink: (lang: AvailableLanguages) => {
    if (lang === AvailableLanguages.de) {
      return 'https://de.trustpilot.com/evaluate/accountable.de';
    }
    return 'https://www.trustpilot.com/evaluate/accountable.de';
  },
  getStarterTodoList: (
    state: Record<string, boolean>,
    utils: HomeUtils & {
      isRegisteredThroughRASO: boolean;
    },
    user?: IUser,
  ): StarterTodoItemsList => [
    {
      key: HOME_PAGE_STATE.CONNECT_BANK_ACCOUNT_KEY,
      title: appI18nInstance.t('bank.onboarding.connect_your_bank_account'),
      onClick: () => utils.createBankConnection(),
      isDone:
        !!state[HOME_PAGE_STATE.CONNECT_BANK_ACCOUNT_KEY] ||
        !_isEmpty(utils.connectors),
    },
    {
      key: HOME_PAGE_STATE.GEWRBEAMT_KEY,
      title: appI18nInstance.t(
        'home.starter_todo_list.register_trade.title.de',
      ),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.register_trade.helper_content.de',
      ),
      isDone: !!state[HOME_PAGE_STATE.GEWRBEAMT_KEY],
      isNotEligible: !user?.accountType.includes(
        AccountTypeShortEnum.gewerbetreibender,
      ),
    },
    {
      key: HOME_PAGE_STATE.FRAGEBOGEN_KEY,
      title: appI18nInstance.t(
        'home.starter_todo_list.register_as_selfemployed.title.de',
      ),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.register_as_selfemployed.helper_text.de',
      ),
      onClick: () => openInNewTab(appI18nInstance.t('home.raso_link')),
      isDone:
        !!state[HOME_PAGE_STATE.FRAGEBOGEN_KEY] ||
        !!utils.isRegisteredThroughRASO,
      shouldHighlight: true,
    },
    {
      key: HOME_PAGE_STATE.ALL_BUSINESS_EXPENSES_KEY,
      title: appI18nInstance.t(
        'home.starter_todo_list.business_expenses.title.de',
      ),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.business_expenses.helper_content.de',
      ),
      isDone: !!state[HOME_PAGE_STATE.ALL_BUSINESS_EXPENSES_KEY],
      onClick: () =>
        utils.openExpenseUploader({
          onUpload: () => null,
          onClickReviewNow: (result) =>
            utils.openExpenseDetails(result?.done?.[0].result),
        }),
    },
    {
      key: HOME_PAGE_STATE.ENTER_VAT_NUMBER_KEY,
      title: appI18nInstance.t('home.starter_todo_list.tax_number.title.de'),
      helperContent: appI18nInstance.t(
        'home.starter_todo_list.tax_number.helper_content.de',
      ),
      isDone:
        !!state[HOME_PAGE_STATE.ENTER_VAT_NUMBER_KEY] || !!user?.steuernummer,
      isHidden:
        !utils.isRegisteredThroughRASO &&
        !state[HOME_PAGE_STATE.FRAGEBOGEN_KEY],
      shouldHighlight: true,
      hideCheckbox: true,
      onClick: () =>
        utils.openVATNumberForm(() =>
          utils.toggleStateByKey(HOME_PAGE_STATE.ENTER_VAT_NUMBER_KEY),
        ),
    },
  ],
  getRASORequestTimeline: (user?: IUser) => [
    {
      title: appI18nInstance.t('raso_request_status.timeline.event1.title.de'),
      description: appI18nInstance.t(
        'raso_request_status.timeline.event1.description.de',
        {
          requestDate: dayjs(user?.created).format('MMM DD YYYY h:mm a'),
        },
      ),
      status: 'done',
    },
    {
      title: appI18nInstance.t('raso_request_status.timeline.event2.title.de'),
      description: appI18nInstance.t(
        'raso_request_status.timeline.event2.description.de',
      ),
      status: 'pending',
    },
    {
      title: appI18nInstance.t('raso_request_status.timeline.event3.title.de'),
      description: appI18nInstance.t(
        'raso_request_status.timeline.event3.description.de',
        {
          address:
            user?.raso?.homeAddress?.street && user?.raso?.homeAddress?.city
              ? `${user?.raso?.homeAddress?.street} - ${user?.raso?.homeAddress?.city}`
              : '',
        },
      ),
      status: 'not-reached',
    },
  ],
  homeMustReadLinks: () => [
    {
      title: appI18nInstance.t('home.must_read.calculate_net_income.title.de'),
      description: appI18nInstance.t(
        'home.must_read.calculate_net_income.description.de',
      ),
      link: appI18nInstance.t('home.must_read.calculate_net_income.link.de'),
    },
    {
      title: appI18nInstance.t('home.must_read.first_steps.title.de'),
      description: appI18nInstance.t(
        'home.must_read.first_steps.description.de',
      ),
      link: appI18nInstance.t('home.must_read.first_steps.link.de'),
    },
    {
      title: appI18nInstance.t('home.must_read.funding.title.de'),
      description: appI18nInstance.t('home.must_read.funding.description.de'),
      link: appI18nInstance.t('home.must_read.funding.link.de'),
    },
    {
      title: appI18nInstance.t('home.must_read.taxes.title.de'),
      description: appI18nInstance.t('home.must_read.taxes.description.de'),
      link: appI18nInstance.t('home.must_read.taxes.link.de'),
    },
    {
      title: appI18nInstance.t('home.must_read.expat.title.de'),
      description: appI18nInstance.t('home.must_read.expat.description.de'),
      link: appI18nInstance.t('home.must_read.expat.link.de'),
    },
  ],
  getRevenueSimulatorLink: () => null,
};
