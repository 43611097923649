import { useClickAwayListener } from 'hooks';
import type { FC } from 'react';
import type { ClickAwayListenerProps } from './ClickAwayListener.types';

const ClickAwayListener: FC<ClickAwayListenerProps> = ({
  handler,
  className = '',
  children,
  excludedId,
}) => {
  const wrapperRef = useClickAwayListener(handler, excludedId);
  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  );
};

export default ClickAwayListener;
