import { getAsset } from 'api/v1/Assets';
import classNames from 'classnames';
import { Loader } from 'components/atoms/Loader';
import { Table } from 'components/atoms/Table';
import { getAmortizationOptionLabel } from 'components/pages/Expenses/Form/Fields/AmortizationPeriod/helpers';
import dayjs from 'dayjs';
import useFeatures from 'hooks/useFeatures';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { type Asset } from 'types/assets';
import { AmortizationCacheKeys } from 'types/cacheKeys.types';
import formatMoney from 'utils/formatMoney';
import {
  CheckIconOutline,
  ChevronDownIconOutline,
  ChevronUpIconOutline,
} from 'utils/icons';

type AmortizationYearProps = {
  yearNumber: number;
  year: number;
  residualValue: number;
  amortizationAmount: number;
  className?: string;
};

type AmortizationInvoiceProps = {
  date: string;
  amount: number;
  gain: number;
  itemName: string;
  invoiceNumber?: string;
  className?: string;
};

export type AssetRowProps = {
  data: Asset;
  defaultExpanded?: boolean;
  hideDefaultControllers?: boolean;
  renderControllers?: () => ReactNode;
  shouldHideStillToAmortizeCol?: boolean;
};

const thisYear = dayjs().year();

const AmortizationYear: FC<AmortizationYearProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        'flex justify-between leading-tight p-3 rounded-lg',
        props.className,
      )}
    >
      <div>
        <p className="font-semibold mb-2.5 flex items-center gap-2">
          <span>
            {t('settings.amortization.details.year')} {props.yearNumber}
          </span>
          {props.year < thisYear && <CheckIconOutline className="w-5 h-5" />}
        </p>
        <p className="text-primary-400">
          {dayjs().year(props.year).endOf('year').format('MMM. YYYY')}
        </p>
      </div>
      <div className="text-right">
        <p className="font-semibold mb-2.5">
          {formatMoney(props.amortizationAmount * -1)}
        </p>
        <p className="text-primary-400">
          {t('settings.amortization.details.residual_value')}:{' '}
          {formatMoney(Math.abs(props.residualValue))}
        </p>
      </div>
    </div>
  );
};

const AmortizationInvoice: FC<AmortizationInvoiceProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames('leading-tight p-3 rounded-lg', props.className)}
    >
      <div className="flex justify-between">
        <p className="font-semibold mb-2.5">
          {t('settings.amortization.details.invoice')}
        </p>
        <p className="font-semibold mb-2.5">{formatMoney(props.amount)}</p>
      </div>
      <div className="flex justify-between">
        <p className="font-semibold mb-2.5">
          {t('settings.amortization.details.capital_gain')}
        </p>
        <p className="font-semibold mb-2.5">{formatMoney(props.gain)}</p>
      </div>
      <div className="flex justify-between border-t border-primary-50 pt-2.5">
        <p className="text-primary-400">
          {dayjs(props.date).format('MMM. YYYY')}
        </p>
        <p className="text-primary-400">
          {props.invoiceNumber
            ? `${t('settings.amortization.details.invoice_number')}: ${
                props.invoiceNumber
              } / `
            : ''}
          {t('settings.amortization.details.invoice_item')}: {props.itemName}
        </p>
      </div>
    </div>
  );
};

const AssetWrittenOff: FC<{ date: string; className?: string }> = (props) => {
  const { t } = useTranslation();
  const dateFormat = useFeatures('formats.date');
  return (
    <div
      className={classNames('leading-tight p-3 rounded-lg', props.className)}
    >
      <p className="font-semibold text-center">
        {t('settings.amortization.details.marked_as_lost_on', {
          date: dayjs(props.date).format(dateFormat),
        })}
      </p>
    </div>
  );
};

const COLSPAN = 7;

export const SumRow: FC<{ assets: Asset[] }> = ({ assets }) => {
  const { t } = useTranslation();

  const sum = assets.reduce((acc, asset) => {
    return acc + asset.amountToAmortize;
  }, 0);

  return (
    <Table.Row>
      <Table.Cell colSpan={COLSPAN - 1}>
        <div className="flex justify-end gap-2">
          <span className="text-primary-400">{t('total')}:</span>
          <span className="font-semibold text-primary">{formatMoney(sum)}</span>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

const AssetRow: FC<AssetRowProps> = (props) => {
  const { data: asset, shouldHideStillToAmortizeCol = false } = props;
  const { t } = useTranslation();
  const dateFormat = useFeatures('formats.date');
  const [shouldExpand, setShouldExpand] = useState(!!props.defaultExpanded);

  const numberOfYears = asset.amortizationPeriod / 12;

  const { data, isLoading, isSuccess, refetch } = useQuery(
    [AmortizationCacheKeys.Asset, asset._id],
    () => getAsset(asset._id),
    {
      enabled: shouldExpand,
      cacheTime: 0,
      onError: () => {
        setShouldExpand(false);
      },
    },
  );

  const isExpanded = shouldExpand && data;

  const withInvoiceDetails = asset.invoices?.find(
    (invoice) => invoice._id === asset.invoice?._id,
  );

  return (
    <>
      <Table.Row
        key={asset._id}
        onClick={() => {
          setShouldExpand((state) => !state);
          if (!isSuccess) refetch();
        }}
      >
        <Table.Cell className="font-semibold">{asset.name}</Table.Cell>
        <Table.Cell className="text-primary-700">
          {dayjs(asset?.expense?.expenseDate).format(dateFormat)}
        </Table.Cell>
        <Table.Cell className="text-primary-700">
          {t(getAmortizationOptionLabel(numberOfYears), {
            years: numberOfYears,
          })}
        </Table.Cell>
        <Table.Cell className="text-primary-700">
          {formatMoney(asset.data.professionalAmount)}
        </Table.Cell>
        <Table.Cell
          className={classNames(
            'text-primary-700',
            shouldHideStillToAmortizeCol ? 'text-right' : '',
          )}
        >
          {formatMoney(asset.data.professionalAmountExclRecoverableVAT)}
        </Table.Cell>
        {!shouldHideStillToAmortizeCol && (
          <Table.Cell className="font-semibold text-right">
            {formatMoney(asset.amountToAmortize)}
          </Table.Cell>
        )}
        <Table.Cell>
          <div className="flex gap-2 w-full justify-end">
            {isLoading ? (
              <Loader size="lg" />
            ) : isExpanded ? (
              <ChevronUpIconOutline className="w-5 h-5" />
            ) : (
              <ChevronDownIconOutline className="w-5 h-5" />
            )}
          </div>
        </Table.Cell>
      </Table.Row>
      {isExpanded && (
        <Table.Row
          key={asset._id + '_expanded'}
          className="!border-t-transparent"
        >
          <Table.Cell
            colSpan={shouldHideStillToAmortizeCol ? COLSPAN - 1 : COLSPAN}
          >
            <div className="flex flex-col gap-4">
              {(!!data.charges.length ||
                !!data.invoice ||
                !!data.writeOffDate) && (
                <div>
                  {data.charges.map((charge, i) => {
                    return (
                      <AmortizationYear
                        key={i}
                        yearNumber={i + 1}
                        year={charge.year}
                        residualValue={charge.residual_value}
                        amortizationAmount={charge.amount}
                        className={classNames(
                          i % 2 === 0 ? 'bg-primary-25' : 'bg-white',
                        )}
                      />
                    );
                  })}
                  {data.invoice && (
                    <AmortizationInvoice
                      date={data.invoice.invoiceDate}
                      amount={data.invoice.amountExclVAT}
                      gain={data.invoice.capitalGain}
                      itemName={data.invoice.item.name}
                      invoiceNumber={withInvoiceDetails?.revenueNumber}
                      className={classNames(
                        data.charges.length % 2 === 0
                          ? 'bg-primary-25'
                          : 'bg-white',
                      )}
                    />
                  )}
                  {data.writeOffDate && (
                    <AssetWrittenOff
                      date={data.writeOffDate}
                      className={classNames(
                        (data.charges.length + (data.invoice ? 1 : 0)) % 2 === 0
                          ? 'bg-primary-25'
                          : 'bg-white',
                      )}
                    />
                  )}
                </div>
              )}
              {props.renderControllers?.()}
            </div>
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

export default AssetRow;
