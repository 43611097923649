import { get } from 'lodash';
import QueryString from 'qs';
import { type FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { CheckComponentProps } from 'types/taxes.types';
import CheckExpensesList from '../CheckExpensesList';
import CheckUITemplate from '../CheckUITemplate';

const ExpenseReverseCharge: FC<CheckComponentProps> = (props) => {
  const { checkData } = props;
  const [_, setSearchParams] = useSearchParams();

  return (
    <CheckUITemplate
      {...props}
      renderContent={() => (
        <div className="my-4">
          <CheckExpensesList
            {...{ list: get(checkData, 'data'), ...props }}
            onItemClick={(item) => {
              setSearchParams(
                QueryString.stringify({
                  'issue-field': `localVATRate.${String(
                    item?.items?.[0]?.amount,
                  )}`,
                }),
              );
            }}
          />
        </div>
      )}
    />
  );
};

export default ExpenseReverseCharge;
