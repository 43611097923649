import type { Category } from 'components/organisms/CategoriesPicker/categoriesPicker.types';
import { categoriesIcons } from 'components/pages/Banks/Classification/helpers';
import { createPeriodCategory } from 'components/pages/Banks/Classification/useClassificationOptions';
import i18n from 'i18n';
import React from 'react';
import { CellsModifiers } from 'types/CellsModifiers.types';
import { TransactionCategoryEnum } from 'types/transactions.types';
import { VATReturnFrequencyEnum, type IUser } from 'types/users.types';
import {
  CATEGORY_TAXES_AND_INSURANCE_VAT_PAYMENT,
  CATEGORY_TAXES_AND_INSURANCE_VAT_REIMBURSEMENT,
} from 'utils/categories';

const transactions = {
  iban_mask: 'DE 99 99999999 9999999999',
  anotherProfessionalPaymentExpenseOptions: (user?: IUser): Category[] => [
    {
      icon: React.createElement('img', {
        src: categoriesIcons.incomeTaxPrePayment,
        alt: '',
      }),
      ...createPeriodCategory(
        TransactionCategoryEnum.tradeTaxPrePayment,
        [user?.VATReturnFrequency || VATReturnFrequencyEnum.quarterly],
        i18n.t('payments.transaction_category_short.tradeTaxPrePayment'),
        i18n.t('transaction.tax_period_title.tradeTaxPrePayment'),
      ),
    },
  ],
  anotherProfessionalPaymentRevenueOptions: (): Category[] => [],
  transactionsTaxCategoriesMarkAsPaid: [
    TransactionCategoryEnum.incomeTaxPayment,
    TransactionCategoryEnum.incomeTaxPrePayment,
    TransactionCategoryEnum.incomeTaxReimbursement,
  ],
  paymentKeys: {
    incomingPayment:
      'payments.payment_classification.credit_note_on_purchase.de',
    outgoingPayment: 'payments.payment_classification.credit_note_on_sales.de',
    linkToCreditNoteOnPurchase:
      'payments.payment_classification.link_to_credit_note_on_purchase.de',
    addToCreditNoteOnPurchase:
      'payments.payment_classification.create_a_new_credit_note_on_purchase.de',
    linkToCreditNoteOnSales:
      'payments.payment_classification.link_to_an_existing_credit_note_on_sales.de',
    addToCreditNoteOnSales:
      'payments.payment_classification.create_a_new_credit_note_on_sales.de',
  },
  transactionsTaxExpenseCategories: [TransactionCategoryEnum.VATPayment],
  transactionCategoryToCategoryIDMap: {
    [TransactionCategoryEnum.VATPayment]:
      CATEGORY_TAXES_AND_INSURANCE_VAT_PAYMENT,
    [TransactionCategoryEnum.VATReimbursement]:
      CATEGORY_TAXES_AND_INSURANCE_VAT_REIMBURSEMENT,
  },
  transactionsTaxReimbursementCategories: [
    TransactionCategoryEnum.VATReimbursement,
  ],
  blocked: {
    web: false,
    experts: false,
  },
  list: {
    cells: {
      _modifiers: [{ name: CellsModifiers.transactions }],
      default_sort: {
        web: 'valueDate_desc',
        experts: 'valueDate_desc',
      },
      first_group: {
        web: [
          'COUNTERPARTY',
          'COMMUNICATION',
          'DATE',
          'CLASSIFICATION',
          'TOTAL_AMOUNT',
        ],
        experts: [
          'COUNTERPARTY',
          'COMMUNICATION',
          'DATE',
          'CLASSIFICATION',
          'TOTAL_AMOUNT',
        ],
      },
      second_group: {
        web: [
          'COUNTERPARTY',
          'COMMUNICATION',
          'DATE',
          'CLASSIFICATION',
          'TOTAL_AMOUNT',
        ],
        experts: [
          'COUNTERPARTY',
          'COMMUNICATION',
          'DATE',
          'CLASSIFICATION',
          'TOTAL_AMOUNT',
        ],
      },
    },
  },
};

export default transactions;
