import classNames from 'classnames';
import type { FC } from 'react';
import type { VariantButtonProps } from './VariantButton.types';

const VariantButton: FC<VariantButtonProps> = ({
  selected = false,
  children,
  iconSrc,
  buttonClassName,
  iconClassName,
  ...props
}) => (
  <button
    type="button"
    className={classNames(
      buttonClassName,
      'text-sm text-center flex-1 rounded-[10px] py-2 px-1.5 cursor-pointer font-assistant font-semibold',
      {
        'bg-neutral-100 text-primary': !selected,
        'bg-primary-700 text-neutral-100': selected,
        'cursor-not-allowed opacity-60': props.disabled,
      },
    )}
    {...props}
  >
    {iconSrc ? (
      <img
        className={classNames('mx-auto mb-1', iconClassName)}
        src={iconSrc}
      />
    ) : null}
    {children}
  </button>
);

export default VariantButton;
