import classNames from 'classnames';
import { Button, ButtonComponentProps } from 'components/atoms/Button';
import { Modal, ModalApi } from 'components/atoms/Modal';
import AIIcon from 'components/atoms/SVG/sidebarIcons/AIIcon.component';
import useAIUsageConsent from 'hooks/shared/useAIUsageConsent';
import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAiUsageConsentEnum } from 'types/users.types';
import AIConsentHelperContent from './AIConsentHelperContent';
import AIConsentSwitch from './AIConsentSwitch';

type AIButtonConsentCheckProps = ButtonComponentProps & {
  onClick: (...args: any[]) => any;
  tooltip?: string;
  requiresFullAccess?: boolean;
};

const AIDefaultButtonIcon: FC<{ isLoading?: boolean }> = ({ isLoading }) => (
  <span className="flex items-center justify-center w-6 h-6 rounded-full bg-taxes-200 cursor-pointer">
    <AIIcon
      className={classNames('w-5 h-5 text-taxes', {
        'animate-spin': isLoading,
      })}
    />
  </span>
);

const AIButtonConsentCheck: FC<AIButtonConsentCheckProps> = ({
  onClick,
  requiresFullAccess = false,
  className,
  children,
  loading,
  ...props
}) => {
  const { t } = useTranslation();
  const apiRef = useRef<ModalApi>(null);

  const onConsentUpdate = useCallback(
    (newConsent: UserAiUsageConsentEnum) => {
      apiRef.current?.closeModal();
      if (
        newConsent === UserAiUsageConsentEnum.allow ||
        (newConsent === UserAiUsageConsentEnum.limited && !requiresFullAccess)
      ) {
        onClick();
      }
    },
    [apiRef, requiresFullAccess, onClick],
  );

  const { consent, patchConsent, isLoading } = useAIUsageConsent({
    onUpdate: onConsentUpdate,
  });

  const consentGiven = requiresFullAccess
    ? consent === UserAiUsageConsentEnum.allow
    : consent && consent !== UserAiUsageConsentEnum.deny;

  const [selectedConsent, setSelectedConsent] =
    useState<UserAiUsageConsentEnum>(consent || UserAiUsageConsentEnum.allow);

  if (consentGiven) {
    return (
      <Button
        structure="text"
        onClick={onClick}
        className={className}
        {...props}
        loading={children ? loading : undefined}
      >
        {children || <AIDefaultButtonIcon isLoading={isLoading || loading} />}
      </Button>
    );
  }

  return (
    <Modal
      name="AI Usage Consent Modal"
      width="2xl"
      apiRef={apiRef}
      color="primary"
      trigger={(api) => (
        <Button
          structure="text"
          onClick={api.openModal}
          className={className}
          {...props}
          loading={children ? loading : undefined}
        >
          {children || <AIDefaultButtonIcon isLoading={isLoading || loading} />}
        </Button>
      )}
      render={() => (
        <div className="p-6 flex flex-col">
          <Button
            asDefault
            type="button"
            className="flex items-center justify-center w-10 h-10 rounded-full bg-taxes-200 self-center mb-3"
          >
            <AIIcon className={'w-9 h-9 text-taxes'} />
          </Button>

          <AIConsentHelperContent />

          <AIConsentSwitch
            value={selectedConsent}
            onChange={setSelectedConsent}
            requiresFullAccess={requiresFullAccess}
          />

          <div className="space-x-5 flex mt-10 flex-1">
            <Button
              structure="secondary"
              color="primary"
              className="flex-1 text-[#725F82] border-[#725F82]"
              size="lg"
              onClick={() => patchConsent(UserAiUsageConsentEnum.deny)}
              loading={
                isLoading && selectedConsent === UserAiUsageConsentEnum.deny
              }
            >
              {t('ai_bot.data_share.alert.buttons.no')}
            </Button>
            <Button
              color="taxes"
              size="lg"
              className="flex-1"
              onClick={() => patchConsent(selectedConsent)}
              disabled={selectedConsent === UserAiUsageConsentEnum.deny}
              loading={
                isLoading && selectedConsent !== UserAiUsageConsentEnum.deny
              }
            >
              {t('ai_bot.data_share.alert.buttons.yes')}
            </Button>
          </div>
        </div>
      )}
    ></Modal>
  );
};

export default AIButtonConsentCheck;
