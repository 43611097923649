import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import CustomLink from 'components/atoms/Link';
import layoutContext from 'components/organisms/Layout/layoutContext';
import { useContext, type FC, type ReactNode } from 'react';
import { Link } from 'react-router-dom';

const Banner: FC<{
  iconSrc?: string;
  title: string;
  description?: string;
  href?: string;
  linkLabel?: string;
  onDismiss?: () => void;
  onBannerClick?: () => void;
  extra?: ReactNode;
  bannerWrapperClassName?: string;
}> = ({
  iconSrc,
  title,
  description,
  href,
  linkLabel,
  onDismiss,
  onBannerClick,
  extra,
  bannerWrapperClassName,
}) => {
  const layout = useContext(layoutContext);

  return (
    <div
      className={classNames(
        'fixed z-50 bg-gray-100 right-4 top-4 px-10 py-4 flex justify-between items-center hover:bg-gray-200 transition-colors shadow-filters rounded-xl',
        bannerWrapperClassName,
      )}
      style={{ left: layout.sidebarArea }}
    >
      <div className="flex items-center justify-between flex-1">
        {href ? (
          <Link
            to={href}
            className="flex items-center flex-1"
            onClick={onBannerClick}
          >
            {iconSrc ? <img src={iconSrc} className="w-12" /> : null}

            <div
              className={classNames('pl-5 font-semibold', {
                '!pl-0': !iconSrc,
              })}
            >
              <p>{title}</p>

              <p className="text-sm font-semibold text-primary">
                {description}

                {linkLabel ? (
                  <CustomLink
                    href={href}
                    className="cursor-pointer border-b-taxes-500"
                  >
                    {linkLabel}
                  </CustomLink>
                ) : null}
              </p>
            </div>
          </Link>
        ) : (
          <div className="flex items-center flex-1" onClick={onBannerClick}>
            {iconSrc ? <img src={iconSrc} className="w-12" /> : null}

            <div
              className={classNames('pl-5 font-semibold', {
                '!pl-0': !iconSrc,
              })}
            >
              <p>{title}</p>

              <p className="text-sm font-semibold text-primary">
                {description}

                {linkLabel ? (
                  <CustomLink
                    href={href}
                    className="cursor-pointer border-b-taxes-500"
                  >
                    {linkLabel}
                  </CustomLink>
                ) : null}
              </p>
            </div>
          </div>
        )}

        {extra}
      </div>

      {onDismiss ? (
        <button type="button" className="ml-10" onClick={onDismiss}>
          <XIcon className="w-6 h-6 text-primary-400" />
        </button>
      ) : null}
    </div>
  );
};

export default Banner;
