import classNames from 'classnames';

import type { FC } from 'react';
import { Link } from 'react-router-dom';
import type { BadgeProps } from './Badge.types';

const theme = {
  base: 'rounded-[6.25rem] flex h-fit items-center gap-1 py-1 px-3',
  color: {
    info: 'bg-blue-100 text-blue-800 group-hover:bg-blue-200 border-blue-200',
    gray: 'bg-gray-100 text-gray-800 group-hover:bg-gray-200 border-grey-200',
    failure: 'bg-red-50 text-red-500 group-hover:bg-red-200 border-red-200',
    success:
      'bg-[#ECFDF5] text-[#10B981] group-hover:bg-green-200 border-green-200',
    darkSuccess:
      'bg-green-300 text-green-900 group-hover:bg-green-400 border-green-200',
    warning:
      'bg-[#FFFBEB] text-[#F59E0B] group-hover:bg-yellow-200 border-yellow-200',
    indigo:
      'bg-indigo-100 text-indigo-800 group-hover:bg-indigo-200 border-indigo-200',
    purple:
      'bg-purple-100 text-purple-800 group-hover:bg-purple-200 border-purple-200',
    pink: 'bg-pink-100 text-pink-800 group-hover:bg-pink-200 border-pink-200',
    expenses:
      'bg-expenses-100 text-expenses-800 group-hover:bg-expenses-200 border-expenses-200',
    revenues:
      'bg-revenues-100 text-revenues-800 group-hover:bg-revenues-200 border-revenues-200',
    taxes:
      'bg-taxes-100 text-taxes-800 group-hover:bg-taxes-200 border-taxes-200',
    bank: 'bg-bank-100 text-bank-800 group-hover:bg-bank-200 border-bank-200',
    fuchsia: 'bg-taxes text-white group-hover:bg-taxes border-white',
  },
  href: 'group',
  icon: {
    off: 'rounded px-2 py-0.5',
    on: 'rounded p-1.5',
    size: {
      xs: 'w-3 h-3',
      sm: 'w-3.5 h-3.5',
      md: 'w-4 h-4',
      lg: 'w-4.5 h-4.5',
      xl: 'w-5 h-5',
    },
  },
  size: {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-md',
    lg: 'text-md',
    xl: 'text-md',
  },
};

const Content: FC<BadgeProps> = ({
  children,
  color = 'info',
  size = 'xs',
  icon: Icon,
  border,
  baseClassName,
}): JSX.Element => {
  const {
    icon: { on, off, size: iconSize },
    base,
    color: themeColor,
    size: themeSize,
  } = theme;
  return (
    <span
      className={classNames(
        'font-avenir font-medium',
        // Icon ? on : off,
        baseClassName || base,
        themeColor[color],
        themeSize[size],
        { border: !!border },
      )}
    >
      {Icon && <Icon aria-hidden className={iconSize[size]} />}
      {children && <span className="text-xs">{children}</span>}
    </span>
  );
};

const Badge: FC<BadgeProps> = ({
  children,
  color = 'info',
  href,
  icon: Icon,
  size = 'xs',
  border,
  baseClassName,
}): JSX.Element => {
  return href ? (
    <Link className={theme.href} to={href}>
      <Content
        color={color}
        size={size}
        icon={Icon}
        border={border}
        baseClassName={baseClassName}
      >
        {children}
      </Content>
    </Link>
  ) : (
    <Content
      color={color}
      size={size}
      icon={Icon}
      border={border}
      baseClassName={baseClassName}
    >
      {children}
    </Content>
  );
};

export default Badge;
