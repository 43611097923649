import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import i18n from 'i18n';
import orderBy from 'lodash/orderBy';
import type { Period, VATReturnFrequency } from 'types/global.types';
import { VATReturnFrequencyEnum } from 'types/users.types';
import { dayjsRange } from 'utils/date';

export type PeriodOption = {
  value: string;
  label: string;
  period: Period;
  isPriority?: boolean;
};

const MONTHS = (dayjs().year() - 2019) * 12; // to get the months from the start of 2020 till the end of the current year

export const periodLabelMap = {
  yearly: 'invoice.field.period_assignment.vat_yearly',
  quarterly: 'invoice.field.period_assignment.vat_quarterly',
  monthly: 'invoice.field.period_assignment.vat_monthly',
};

export const orderPeriodsOptions = (options: any) => {
  return orderBy(
    options,
    ['period.year', 'period.quarter', 'period.month'],
    ['desc', 'desc', 'desc'],
  );
};

export const freqsDict = {
  [VATReturnFrequencyEnum.monthly]: 'month',
  [VATReturnFrequencyEnum.quarterly]: 'quarter',
  [VATReturnFrequencyEnum.yearly]: 'year',
};

export const periodToFrequency = (
  period: Period,
  currentFreq?: VATReturnFrequency,
): VATReturnFrequency => {
  if (currentFreq) {
    if ((period as any)[freqsDict[currentFreq]]) return currentFreq;
  }
  if (period.month) return 'monthly';
  if (period.quarter) return 'quarterly';
  return 'yearly';
};

export const formatLabel = (
  period: Period,
  VATReturnFreq: VATReturnFrequency,
) => {
  if (VATReturnFreq === 'yearly') return `${period.year}`;
  if (VATReturnFreq === 'monthly') {
    return `${dayjs()
      .month((period.month as number) - 1)
      .format('MMMM')} ${period.year}`;
  }
  return `${i18n.t('taxes.periods.quarter_abbr')}${period.quarter} ${
    period.year
  }`;
};

export const periodToValue = (
  period: Period,
  VATReturnFreq: VATReturnFrequency,
): string => {
  const freq = periodToFrequency(period, VATReturnFreq);
  if (freq !== VATReturnFreq) return formatLabel(period, freq);

  if (VATReturnFreq === 'yearly') return `y-${period.year}`;
  if (VATReturnFreq === 'monthly') return `m-${period.month}-${period.year}`;
  return `q-${period.quarter}-${period.year}`;
};

export const periodToDate = (
  period: Period,
  VATReturnFreq: VATReturnFrequency,
): Dayjs => {
  const date = dayjs();
  if (VATReturnFreq === 'yearly')
    return date.set('year', period.year).startOf('year');
  if (VATReturnFreq === 'monthly')
    return date
      .set('year', period.year)
      .set('month', (period.month as number) - 1)
      .startOf('month');
  return date
    .set('year', period.year)
    .startOf('year')
    .quarter(period.quarter as number);
};

export const dateToPeriod = (
  date: Dayjs,
  VATReturnFreq: VATReturnFrequency,
): Period => {
  if (VATReturnFreq === 'yearly') return { year: date.year() };
  if (VATReturnFreq === 'monthly')
    return {
      month: (date.month() + 1) as Period['month'],
      year: date.year(),
    };
  return {
    quarter: date.quarter() as Period['quarter'],
    year: date.year(),
  };
};

export const getPeriodIndex = (
  period: Period,
  periods: Period[],
  VATReturnFreq: VATReturnFrequency,
): number | undefined => {
  if (VATReturnFreq === 'yearly')
    return periods.findIndex(({ year }) => year === period.year);

  if (VATReturnFreq === 'monthly')
    return periods.findIndex(
      ({ year, month }) => year === period.year && month === period.month,
    );
  return periods.findIndex(
    ({ year, quarter }) => year === period.year && quarter === period.quarter,
  );
};

export const periodExists = (
  period: Period,
  periods: Period[],
  VATReturnFreq: VATReturnFrequency,
): boolean => {
  const periodIndex = getPeriodIndex(period, periods, VATReturnFreq);

  return periodIndex !== -1;
};

export const getPeriodOption = (
  period: Period,
  options: PeriodOption[],
  VATReturnFreq: VATReturnFrequency,
): PeriodOption | undefined => {
  if (VATReturnFreq === 'yearly')
    return options.find(({ period: { year } }) => year === period.year);

  if (VATReturnFreq === 'monthly')
    return options.find(
      ({ period: { year, month } }) =>
        year === period.year && month === period.month,
    );

  return options.find(
    ({ period: { year, quarter } }) =>
      year === period.year && quarter === period.quarter,
  );
};

export const generatePeriods = (
  VATReturnFreq: VATReturnFrequency,
  endDate?: Dayjs,
  startYear?: number,
): Period[] => {
  endDate = endDate || dayjs();

  const startDate = dayjs()
    .set('year', startYear || 2020)
    .startOf('year');

  if (VATReturnFreq === 'yearly') {
    return dayjsRange(startDate, endDate, [1, 'year'])
      .map((date) => dateToPeriod(date, 'yearly'))
      .reverse();
  }

  if (VATReturnFreq === 'monthly') {
    return dayjsRange(startDate, endDate, [1, 'month'])
      .map((date) => dateToPeriod(date, 'monthly'))
      .reverse();
  }

  return dayjsRange(startDate, endDate, [1, 'quarter' as any])
    .map((date) => dateToPeriod(date, 'quarterly'))
    .reverse();
};

export const getNextPeriod = (
  VATReturnFreq: VATReturnFrequency,
  date?: Dayjs,
) => {
  date = date || dayjs();
  if (VATReturnFreq === 'yearly') {
    return dateToPeriod(date.add(1, 'year'), 'yearly');
  }
  if (VATReturnFreq === 'monthly') {
    return dateToPeriod(date.add(1, 'month'), 'monthly');
  }
  return dateToPeriod(date.add(1, 'quarter'), 'quarterly');
};

export const createPeriodsOptions = (
  priorityDate: string | undefined,
  VATReturnFreq: VATReturnFrequency,
): PeriodOption[] => {
  const periods = [
    getNextPeriod(VATReturnFreq),
    ...generatePeriods(VATReturnFreq),
  ];
  const options: PeriodOption[] = periods.map((period) => {
    return {
      value: periodToValue(period, VATReturnFreq),
      label: formatLabel(period, VATReturnFreq),
      period,
    };
  });

  if (!priorityDate) return options;
  const priorityPeriod = dateToPeriod(dayjs(priorityDate), VATReturnFreq);

  const withOption = getPeriodOption(priorityPeriod, options, VATReturnFreq);
  if (withOption) return options;

  return orderPeriodsOptions(
    options.concat({
      value: periodToValue(priorityPeriod, VATReturnFreq),
      label: formatLabel(priorityPeriod, VATReturnFreq),
      period: priorityPeriod,
      isPriority: true,
    }),
  );
};

// export const getClosedPeriods = (
//   VATType: VATType,
//   VATFreq: VATReturnFrequency,
//   country: AvailableCountries,
// ): Promise<Period[]> => {
//   if (VATType !== VATTypeEnum.subjectToVAT) return Promise.resolve([]);
//   return getClosedVATPeriods(VATFreq, country);
// };
