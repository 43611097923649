import { getRevenueLogs } from 'api/v2/revenues';
import PeppolIcon from 'assets/svg/icons/PeppolIcon';
import classNames from 'classnames';
import { Pagination } from 'components/atoms';
import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import { Table } from 'components/atoms/Table';
import SimpleError from 'components/molecules/SimpleError.component';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import getIntercom from 'hooks/intercom.hook';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import { FC, SVGProps, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RevenuesCacheKeys } from 'types/cacheKeys.types';
import { InvoiceLog, InvoiceLogSlugs } from 'types/invoice.types';
import { AvailableCountries } from 'types/users.types';
import {
  DocumentAddOutline,
  EmailInboxOutline,
  EmailOpenOutline,
  EmailSentOutline,
  ExclamationIconOutline2,
} from 'utils/icons';

type RevenueLogsProps = {
  id: string;
  invoiceNumber: string;
  isCreditNote?: boolean;
};

type FormatedLog = {
  tSlug: string;
  tVars: Record<string, string>;
  icon: FC<SVGProps<SVGSVGElement>>;
  iconClassName: string;
  date: string;
  showSupportBtn?: boolean;
};

const formatLog = (
  log: InvoiceLog,
  context: { country: AvailableCountries },
): FormatedLog | null => {
  switch (log.slug) {
    case InvoiceLogSlugs.created:
      return {
        tSlug: `invoice_logs.${log.slug}.${log.data.source}`,
        tVars: { source: log.data.source, date: log.data.createdAt },
        icon: DocumentAddOutline,
        iconClassName: 'text-primary w-6',
        date: log.data.createdAt,
      };
    case InvoiceLogSlugs.emailSent:
    case InvoiceLogSlugs.reminderSent:
      return {
        tSlug: log.data.to?.length
          ? `invoice_logs.${log.slug}`
          : `invoice_logs.${log.slug}.no_recipients`,
        tVars: { to: log.data.to?.join(', ') || '' },
        icon: EmailSentOutline,
        iconClassName: 'text-primary w-6',
        date: log.created,
      };
    case InvoiceLogSlugs.emailOpened:
    case InvoiceLogSlugs.reminderOpened:
      return {
        tSlug: log.data.Recipient
          ? `invoice_logs.${log.slug}`
          : `invoice_logs.${log.slug}.no_recipients`,
        tVars: { Recipient: log.data.Recipient || '' },
        icon: EmailOpenOutline,
        iconClassName: 'text-primary w-6',
        date: log.created,
      };
    case InvoiceLogSlugs.emailReceived:
    case InvoiceLogSlugs.reminderReceived:
      return {
        tSlug: `invoice_logs.${log.slug}`,
        tVars: { Recipient: log.data.Recipient },
        icon: EmailInboxOutline,
        iconClassName: 'text-primary w-6',
        date: log.data.DeliveredAt,
      };
    case InvoiceLogSlugs.emailBounced:
    case InvoiceLogSlugs.reminderBounced:
      return {
        tSlug: `invoice_logs.${log.slug}`,
        tVars: { email: log.data.Email },
        icon: ExclamationIconOutline2,
        iconClassName: 'text-yellow-500 w-6',
        date: log.data.BouncedAt,
        showSupportBtn: true,
      };
    case InvoiceLogSlugs.peppolSent:
      return {
        tSlug: `invoice_logs.${log.slug}.${context.country}`,
        tVars: {},
        icon: PeppolIcon,
        iconClassName: 'text-primary w-[1.5rem] p-[0.2rem]',
        date: log.data?.actionAt || log.created,
      };
    case InvoiceLogSlugs.peppolFailed:
      return {
        tSlug: `invoice_logs.${log.slug}.${context.country}`,
        tVars: {},
        icon: ExclamationIconOutline2,
        iconClassName: 'text-yellow-500 w-6',
        date: log.data?.actionAt || log.created,
        showSupportBtn: true,
      };
    default:
      return null;
  }
};

const RevenueLogs: FC<RevenueLogsProps> = ({
  id,
  invoiceNumber,
  isCreditNote,
}) => {
  const { t } = useTranslation();
  const { showIntercom } = getIntercom();
  const country = useCustomerCountry() as AvailableCountries;

  const [page, setPage] = useState(1);

  const {
    data: result,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    [RevenuesCacheKeys.RevenueLogs, id, page],
    () => getRevenueLogs(id, page),
    {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
    },
  );

  return (
    <motion.div
      className="flex flex-col"
      initial={false}
      animate={{
        height: isLoading || isError ? '16rem' : 'auto',
      }}
      transition={{ duration: 0.1, ease: 'linear' }}
    >
      <p className="text-primary-300 font-bold text-lg leading-tight mb-4">
        {t(
          isCreditNote
            ? 'invoice.logs.title.credit-note'
            : 'invoice.logs.title.invoice',
          { revenueNumber: invoiceNumber },
        )}
      </p>

      <AnimatePresence mode="popLayout">
        {(() => {
          if (isLoading) {
            return (
              <motion.div
                key="1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex-1 flex items-center justify-center"
              >
                <Loader size="3xl" />
              </motion.div>
            );
          }

          if (isError) {
            return (
              <motion.div
                key="2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex-1 flex items-center justify-center"
              >
                <SimpleError messageClassName="!text-xl" onRetry={refetch} />
              </motion.div>
            );
          }

          if (!result) return null;

          const { data, pagination } = result;

          const pagesCount = +Math.max(
            pagination.totalCount / pagination.perPage,
            1,
          ).toFixed();

          return (
            <motion.div
              key="3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <Table className="w-full table-layout-fixed">
                <Table.Head>
                  <Table.HeadCell>{t('invoice_logs.log_name')}</Table.HeadCell>
                  <Table.HeadCell>{t('invoice_logs.log_date')}</Table.HeadCell>
                  {/* <Table.HeadCell className="text-right">
                    Actions
                  </Table.HeadCell> */}
                </Table.Head>
                <Table.Body>
                  {!data.length && (
                    <Table.Row>
                      <Table.Cell colSpan={2} className="text-primary-400">
                        {t('list_no_result')}
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {data.map((log) => {
                    const data = formatLog(log, { country });
                    if (!data) return null;
                    return (
                      <Table.Row key={log._id}>
                        <Table.Cell className="text-sm flex items-center">
                          <data.icon
                            className={classNames(
                              data.iconClassName,
                              'inline-block mr-2',
                            )}
                          />
                          <div>
                            <p className="text-primary font-semibold">
                              {t(data.tSlug, data.tVars)}
                            </p>
                            {data.showSupportBtn && (
                              <Button
                                asDefault
                                className="underline text-primary-700 hover:text-primary"
                                onClick={() => showIntercom()}
                              >
                                {t('contact_support_short2')}
                              </Button>
                            )}
                          </div>
                        </Table.Cell>
                        <Table.Cell className="text-sm text-primary-700">
                          {dayjs(data.date).format('lll')}
                        </Table.Cell>
                        {/* <Table.Cell className="text-right" /> */}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>

              {pagesCount > 1 && (
                <Pagination
                  pagesCount={pagesCount}
                  perPage={pagination.perPage}
                  totalCount={pagination.totalCount}
                  page={pagination.page}
                  currentPageCount={data.length}
                  onPageChange={(pageNumber) => {
                    setPage(pageNumber);
                  }}
                />
              )}
            </motion.div>
          );
        })()}
      </AnimatePresence>
    </motion.div>
  );
};

export default RevenueLogs;
