import getCreditCardStatementsOCR from 'api/v2/transactions/getCreditCardStatementsOCR';
import { uploadFile } from 'api/v2/upload';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import type {
  Connector,
  IMatchedItem,
  Transaction,
} from 'types/transactions.types';
import {
  TransactionCategoryEnum,
  TransactionCategoryWithNextStepsEnum,
  TransactionCategoryWithOutNextStepsEnum,
} from 'types/transactions.types';

export const isCategorizedWithNextSteps = (
  transactionCategory: string | null,
): boolean =>
  !!(
    transactionCategory &&
    transactionCategory in TransactionCategoryWithNextStepsEnum
  );

export const isCategorizedWithoutNextSteps = (
  transactionCategory: string | null,
): boolean =>
  !!(
    transactionCategory &&
    transactionCategory in TransactionCategoryWithOutNextStepsEnum
  );

const isCategorized = (transactionCategory: string | null): boolean =>
  isCategorizedWithNextSteps(transactionCategory) ||
  isCategorizedWithoutNextSteps(transactionCategory);

const isMatched = (transaction: Transaction): boolean =>
  transaction?.matchedItems && transaction?.matchedItems?.length > 0;

const isClassified = (transaction: Transaction): boolean =>
  isCategorized(transaction.transactionCategory) || isMatched(transaction);

const hasMixedItems = (items: IMatchedItem[]): boolean =>
  items.some((item) => item.type === 'expense') &&
  items.some((item) =>
    ['invoice', 'credit-note', 'other-revenue'].includes(item.type),
  );

export const getDocumentTransactionCategory = (document: any) => {
  const { type, isCreditNote } = document || {};

  if (type === 'expense') {
    if (isCreditNote) return TransactionCategoryEnum.creditNoteOnPurchase;

    return TransactionCategoryEnum.professionalExpense;
  }

  if (['invoice', 'credit-note', 'other-revenue'].includes(type)) {
    if (type === 'credit-note' || isCreditNote)
      return TransactionCategoryEnum.creditNoteOnSales;

    return TransactionCategoryEnum.professionalIncome;
  }
};

const getMatchedTransactionCategory = (
  transaction: Transaction,
): string | undefined => {
  if (
    hasMixedItems(transaction.matchedItemsDetails || transaction.matchedItems)
  ) {
    return 'multiple';
  }

  return getDocumentTransactionCategory(
    _get(transaction, 'matchedItemsDetails.0') ||
      _get(transaction, 'matchedItems.0'),
  );
};

const getPaymentClassificationValue = (
  transaction: Transaction,
): string | undefined => {
  if (isCategorized(transaction.transactionCategory)) {
    return transaction.transactionCategory as string;
  }

  if (isMatched(transaction)) {
    return getMatchedTransactionCategory(transaction);
  }
};

const getConnectorDaysLeft = (connector: Connector) => {
  return dayjs(connector.expiresAt).diff(new Date().toString(), 'day');
};

const doesConnectorHasValidExpiryDate = (connector: Connector) => {
  return connector.expiresAt != null;
};

const bankErrors = ['technicalFailure', 'authorizationFailed', 'unknownError'];

const didConnectorExpire = (connector: Connector) =>
  doesConnectorHasValidExpiryDate(connector) &&
  getConnectorDaysLeft(connector) < 1;

const isConnectorBankError = (connector: Connector) =>
  bankErrors.includes(connector.status);

const isConnectorSyncing = (connector: Connector) =>
  connector.status === 'ready' &&
  (connector.lastSynchronizationDate === null || connector.syncing);

const getCreditCardStatementOCRFromFile = (
  connectorId: string,
  file: File,
  accountNumber: string,
  onProgress: (n: number) => void,
) =>
  uploadFile({ file, onProgress }).then(({ key }: any) =>
    getCreditCardStatementsOCR(connectorId, {
      filename: key.split('/').pop(),
      accountNumber,
      originalFilename: file.name,
    }),
  );

export {
  isCategorized,
  isMatched,
  isClassified,
  hasMixedItems,
  getMatchedTransactionCategory,
  getPaymentClassificationValue,
  // isConnectorExpired,
  doesConnectorHasValidExpiryDate,
  isConnectorSyncing,
  isConnectorBankError,
  getConnectorDaysLeft,
  didConnectorExpire,
  getCreditCardStatementOCRFromFile,
};
