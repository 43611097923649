import { Button } from 'components/atoms/Button';
import { FieldConnector } from 'components/atoms/Form/FieldConnector';
import FreeTextInput from 'components/atoms/Form/FreeTextInput';
import { Select } from 'components/atoms/Form/Select';
import { Modal } from 'components/atoms/Modal';
import PaymentAmount from 'components/molecules/Fields/PaymentAmount/PaymentAmount.component';
import getIntercom from 'hooks/intercom.hook';
import usePayWithQRCode from 'hooks/usePayWithQRCode';
import { friendlyFormatIBAN } from 'ibantools';
import type { FC } from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import * as ExpensesEvents from 'utils/amplitude/events/expenses.amplitude';
import { ExclamationIcon, QrcodeIconOutline } from 'utils/icons';
import { banksAvailable, bankSteps, generateQRCodePayload } from './helpers';

type FormProps = {
  onSubmit: (values: any) => any;
  defaultValues: {
    supplier: string;
    iban: string;
    amount: number;
    communication: string;
  };
};

const Form: FC<FormProps> = ({ defaultValues, onSubmit }) => {
  const { t } = useTranslation();
  const { showIntercom } = getIntercom();
  const [shouldShowIns, setShouldShowIns] = useState(false);

  const form = useForm({
    defaultValues: { ...defaultValues, bank: banksAvailable[0].value },
  });
  const formValues = form.watch();

  if (shouldShowIns) {
    return (
      <div>
        <p className="font-avenir text-main text-xl font-semibold mb-2">
          {t('expense.field.link_payment.pay_with_qr_code.how_to_scan')}
        </p>
        <p className="text-primary-700 inline">
          {t('expense.field.link_payment.pay_with_qr_code.description')}
        </p>
        <div className="flex gap-8 mt-8">
          <div className="flex-1">
            <FormProvider {...form}>
              <FieldConnector
                name="bank"
                label={t(
                  'expense.field.link_payment.pay_with_qr_code.select_your_bank',
                )}
              >
                {({ field: { onChange, ...field } }) => (
                  <Select
                    {...field}
                    onChange={(option: any) => onChange(option.value)}
                    options={banksAvailable}
                  />
                )}
              </FieldConnector>
              <ul className="list-disc list-inside">
                {(bankSteps as any)[formValues.bank].map(
                  (text: string, index: number) => (
                    <li key={index} className="text-primary-700">
                      {t(text)}
                    </li>
                  ),
                )}
              </ul>
            </FormProvider>
          </div>
          <div className="w-[256px]">
            <QRCode
              value={generateQRCodePayload({
                name: formValues.supplier,
                iban: formValues.iban,
                amount: formValues.amount,
                reference: formValues.communication,
              })}
            />
          </div>
        </div>
        <div className="mt-8">
          <Button size="xl" onClick={() => setShouldShowIns(false)}>
            {t('expense.field.link_payment.pay_with_qr_code.back')}
          </Button>
          <div className="mt-4">
            <p className="inline text-primary-700 font-semibold">
              {t('need_help')}
            </p>
            &nbsp;
            <Button size="xl" structure="text" onClick={() => showIntercom()}>
              {t('get_in_touch_with_our_team')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <FormProvider {...form}>
      <p className="font-avenir text-main text-xl font-semibold mb-2">
        {t('expense.field.link_payment.pay_with_qr_code.headline')}
      </p>
      <div>
        <p className="text-primary-700 inline">
          {t('expense.field.link_payment.pay_with_qr_code.description')}
        </p>
        &nbsp;
        <Button
          structure="text"
          size="xl"
          onClick={() => setShouldShowIns(true)}
        >
          {t('expense.field.link_payment.pay_with_qr_code.show_me_how')}
        </Button>
      </div>
      <p className="text-primary-400 text-sm font-semibold">
        - {t('expense.field.link_payment.pay_with_qr_code.note')}
      </p>
      <p className="text-primary-700 mt-4">
        <ExclamationIcon className="inline w-5 text-yellow-400" />
        &nbsp;
        {t('expense.field.link_payment.pay_with_qr_code.warning')}
      </p>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex gap-8 mt-8">
          <div className="flex-1">
            <FieldConnector
              name="supplier"
              label={t(
                'expense.field.link_payment.pay_with_qr_code.recipient_name',
              )}
            >
              {({ field }) => <FreeTextInput {...field} />}
            </FieldConnector>

            <FieldConnector
              name="iban"
              label={t(
                'expense.field.link_payment.pay_with_qr_code.recipient_iban',
              )}
            >
              {({ name }) => (
                <FreeTextInput
                  name={name}
                  disabled
                  value={friendlyFormatIBAN(formValues.iban) || ''}
                />
              )}
            </FieldConnector>

            <FieldConnector
              name="communication"
              label={t(
                'expense.field.link_payment.pay_with_qr_code.communication',
              )}
            >
              {({ field }) => <FreeTextInput {...field} />}
            </FieldConnector>

            <FieldConnector
              name="amount"
              label={t('expense.field.link_payment.pay_with_qr_code.amount')}
            >
              {({ field }) => <PaymentAmount {...field} />}
            </FieldConnector>
          </div>
          <div className="w-[256px]">
            <QRCode
              value={generateQRCodePayload({
                name: formValues.supplier,
                iban: formValues.iban,
                amount: formValues.amount,
                reference: formValues.communication,
              })}
            />
          </div>
        </div>
        <div className="text-center">
          <Button
            tracingEvent={
              ExpensesEvents.WEBAPP_EXPENSE_CLICKEDMARKASPAIDINQRCODEFORM
            }
            size="xl"
            className="mt-8"
            type="submit"
          >
            {t('expense.field.link_payment.pay_with_qr_code.mark_as_paid')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

type PayWithQRCodeProps = {
  expenseId: string | undefined;
  data: FormProps['defaultValues'];
};

const PayWithQRCode: FC<PayWithQRCodeProps> = ({ data, expenseId }) => {
  const { t } = useTranslation();
  const QRCodeApi = usePayWithQRCode();

  return (
    <Modal
      name="pay for an expense with QR"
      width="4xl"
      trigger={(api) => (
        <Button
          onClick={api.openModal}
          structure="text"
          size="xl"
          className="text-left leading-tight items-baseline"
          tracingEvent={ExpensesEvents.WEBAPP_EXPENSE_CLICKEDPAYWITHQRCODE}
        >
          <span className="text-base leading-tight flex-shrink-0">
            <QrcodeIconOutline className="w-5 mr-1 inline" />
          </span>
          {QRCodeApi.isPaid(expenseId)
            ? t('expense.field.link_payment.being_paid_qr_code')
            : t('expense.field.link_payment.pay_with_qr_code')}
        </Button>
      )}
      render={(api) => (
        <div className="px-8 py-4">
          <Form
            defaultValues={data}
            onSubmit={() => {
              QRCodeApi.markAsPaid(expenseId);
              api.closeModal();
            }}
          />
        </div>
      )}
    />
  );
};

export default PayWithQRCode;
