import type { IClient } from './clients.types';
import type { LockStatusType } from './global.types';
import type { ITaxSubmission } from './tax-submissions.types';
import type { Transaction } from './transactions.types';
import type { IUser } from './users.types';

export interface IRevenue extends Document {
  _id: string;
  userId: string;
  userSnapshot: Partial<IUser>;
  type: RevenueType;
  isValidated: boolean;
  revenueNumber: string;
  client: IClient;
  amount?: number;
  totalVATAmount: number;
  totalAmountExclVAT: number;
  totalAmountInclVAT: number;
  baseCurrencyTotalVATAmount: number;
  baseCurrencyTotalAmountExclVAT: number;
  baseCurrencyTotalAmountInclVAT: number;
  transactions: string[]; // TODO ITransaction[] | string[];
  _matchedTransactions: Transaction[];
  automatch_transaction_id: string;
  isSuggestedPeriod?: boolean;
  deleted?: boolean;
  dueDate: string;
  dueDateNumber: number;
  invoiceDate: string;
  quoteNumber?: string;
  expiryDate?: string;
  quoteDate?: string;
  invoiceDateNumber: number;
  invoiceDateTime: Date;
  deliveryDate?: Date;
  deliveryPeriod?: {
    start: string;
    end: string;
  };
  showDeliveryPeriod?: boolean;
  paymentDate?: Date;
  filePath: string;
  fileType: RevenueFileType;
  filePathUbl21?: string;
  filePathUblBe?: string;
  status: RevenueStatus;
  created?: Date;
  modified?: Date;
  comments?: string;
  accountantReview: AccountantReview;
  paymentAmount?: number;
  paymentDateNumber?: number;
  paymentType?: PaymentType;
  period?: VATPeriod;
  submissions?: string[] | ITaxSubmission[] | string[];
  periodUserAction?: PeriodUserAction | null;
  currency: string;
  currencyRate: number;
  items: IRevenueItem[];
  baseCurrency: 'EUR';
  paymentQrCode?: string;
  communication?: string;
  downPayment?: number;
  fileHash?: string;
  recurringId?: string;
  gmailMessageId?: string;
  quoteId?: string;
  din5008?: boolean;
  taxLock?: LockStatusType;
  notes?: string;
}

export interface IRevenueItem {
  _id: string;
  unitAmountExclVAT: number;
  unitAmountInclVAT: number;
  totalAmountExclVAT: number;
  totalVATAmount: number;
  totalAmountInclVAT: number;
  baseCurrencyTotalAmountExclVAT: number;
  baseCurrencyTotalVATAmount: number;
  baseCurrencyUnitAmountExclVAT: number;
  baseCurrencyTotalAmountInclVAT: number;
  quantity: number;
  VATRate: number;
  name: string;
  description?: string;
  assetId: string; // ObjectId
  unit: RevenueItemUnit;
  whyZeroVAT?: RevenueWhyZeroVATEnum;
  exemptParagraph?: string;
  categoryId?: string;
  category: {
    id: RevenueItemCategory;
    title: string;
    accountingCode: number;
    separatelyTaxable: boolean;
    taxRate: string;
    admitsVAT: boolean;
    icon: string;
  };
  discountAmount: number;
  baseCurrencyDiscountAmount: number;
  discountPercentage: number;
  doesUnitPriceIncludeVAT?: boolean;
}

export enum RevenueWhyZeroVATEnum {
  user_franchisee = 'user-franchisee',
  local_reverse_charge = 'local-reverse-charge',
  intra_eu_reverse_charge = 'intra-eu-reverse-charge',
  extra_eu_export = 'extra-eu-export',
  exempt_item = 'exempt-item',
  diplomatic = 'diplomatic',
}

export enum RevenueItemUnit {
  items = 'items',
  hours = 'hours',
  days = 'days',
}

export enum RevenueStatus {
  sent = 'sent',
  paid = 'paid',
  not_sent = 'not-sent',
  draft = 'draft',
}

export enum RevenueActionType {
  create_invoice = 'create_invoice',
  import_invoice = 'import_invoice',
  create_credit_note = 'create_credit_note',
  import_credit_note = 'import_credit_note',
  create_other_revenue = 'create_other_revenue',
  create_quote = 'create_quote',
  create_recurrence = 'create_recurrence',
}

export enum RevenueType {
  invoice = 'invoice',
  other_revenue = 'other-revenue',
  credit_note = 'credit-note',
  quote = 'quote',
}

export enum RevenueFileType {
  imported = 'imported',
  generated = 'generated',
}

export enum RevenueItemCategory {
  'be.revenue.sales_goods' = 'be.revenue.sales_goods',
  'be.revenue.sales_services' = 'be.revenue.sales_services',
  'de.revenue.sales_services' = 'de.revenue.sales_services',
  'de.revenue.sales_goods' = 'de.revenue.sales_goods',
  'be.revenue.passerelleCrise' = 'be.revenue.passerelleCrise',
  'be.revenue.copyright' = 'be.revenue.copyright',
  'be.revenue.passerelleRelance' = 'be.revenue.passerelleRelance',
  'de.revenue.corona_grants' = 'de.revenue.corona_grants',
  'de.revenue.commission' = 'de.revenue.commission',
  'de.revenue.royalties_licensing' = 'de.revenue.royalties_licensing',
  'de.revenue.rent' = 'de.revenue.rent',
  'be.revenue.subsidyNotTaxable' = 'be.revenue.subsidyNotTaxable',
  'be.revenue.insurancePayoutForLostAsset' = 'be.revenue.insurancePayoutForLostAsset',
  'be.revenue.indemnity165' = 'be.revenue.indemnity165',
  'de.revenue.gifts' = 'de.revenue.gifts',
  'de.revenue.interest_income' = 'de.revenue.interest_income',
  'de.revenue.refunds' = 'de.revenue.refunds',
}
export enum PaymentType {
  cash = 'cash',
  otherBank = 'otherBank',
  // linkedTransaction = 'linkedTransaction',
}

export enum InvoiceFileType {
  generated = 'generated',
  imported = 'imported',
}

export enum InvoiceCreatedBy {
  user = 'user',
  email = 'email',
  bank = 'bank',
}

export enum EClientType {
  subjectToVAT = 'subjectToVAT',
  intraEU = 'intra-EU',
  extraEU = 'extra-EU',
  exonerated = 'exonerated', // de
  privateIndividual = 'privateIndividual',
}

export type AccountantReview = {
  reviewStatus: 'reviewed' | 'not_reviewed';
  comments: string;
};

export type VATQuarter = 1 | 2 | 3 | 4;

export interface VATPeriod {
  year: number;
  quarter?: VATQuarter;
  month?: number;
  frequency?: string;
}

export enum PeriodUserAction {
  setToNextOpenQuarter = 'setToNextOpenQuarter',
  addedToClosedQuarter = 'addedToClosedQuarter',
}

export const specialCurrencySymbolMap = {
  EUR: '€',
  GBP: '£',
  PLN: 'zł',
};

export enum templateTypeEnum {
  basic = 'basic',
  straight = 'straight',
  rounded = 'rounded',
  circle = 'circle',
}
