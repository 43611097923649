import classNames from 'classnames';
import WorkAroundLegacyFieldDestroy from 'contexts/WorkAroundLegacyFieldDestroy';
import { useHighlight } from 'hooks/useHighlight';
import _get from 'lodash/get';
import type { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { useContext, useEffect, useId, useRef } from 'react';
import type {
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { TracingConfig } from 'utils/amplitude/amplitude.types';
import { formResetOptions } from 'utils/constants';
import { QuestionMarkCircleIconOutline } from 'utils/icons';
import { Loader } from '../Loader';
import type { TooltipProps } from '../Tooltip';
import { Tooltip } from '../Tooltip';

export type FieldConnectorRenderProps = {
  error: string;
  name: string;
  label?: string;
  field: Omit<ControllerRenderProps<FieldValues, string>, 'ref'> & {
    error?: string;
    autoComplete: string;
    disabled?: boolean;
  };
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
};

type FieldConnectorProps = {
  label?: string | (() => string);
  subLabel?: string | (() => string);
  labelClassName?: string;
  mainLabelClassName?: string;
  helper?: ReactNode;
  className?: string;
  hint?: ReactNode;
  showField?: boolean;
  destroyOnHide?: boolean;
  destroyValue?: any;
  onShow?: (name: string, isUIHidden: boolean, value: any) => void;
  showUI?: boolean;
  name: string | string[];
  errors?: any;
  ErrorContext?: { [key: string]: string | number };
  children?: (renderProps: FieldConnectorRenderProps) => ReactElement;
  errorKeys?: string[];
  tooltip?: TooltipProps['content'];
  horizontal?: boolean;
  PlaceholderNode?: FC;
  labelWrapperClassName?: string;
  tracing?: TracingConfig;
  displayErrorsOnFirstSubmit?: boolean;
  executeOnChange?: () => void;
  highlightID?: string;
  forceHideError?: boolean;
  disabled?: boolean | ((name: string) => boolean);
  loading?: boolean;
};

const runOnShowEffect = (fn: any) => {
  const id = setTimeout(fn, 32);
  return () => clearTimeout(id);
};

const runOnDestroyEffect = (fn: any) => {
  const id = setTimeout(fn, 16);
  return () => clearTimeout(id);
};

export const FieldTooltip: FC<{ content?: string }> = ({ content }) => (
  <div className="mb-1">
    <Tooltip content={content}>
      <QuestionMarkCircleIconOutline className="w-4 text-primary-400" />
    </Tooltip>
  </div>
);

export const FieldErrorUI: FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children,
}) => {
  return (
    <p className={classNames('text-red-500 text-sm leading-tight', className)}>
      {children}
    </p>
  );
};

export const FieldErrorManager: FC<{
  className?: string;
  name: string;
  context?: Record<string, string | number>;
}> = ({ name, className, context }) => {
  const { t } = useTranslation();
  const fieldAPI = useController({ name });

  const {
    fieldState: { error },
  } = fieldAPI;

  if (!error?.message) return null;

  return (
    <FieldErrorUI className={className}>
      {t(error.message, context)}
    </FieldErrorUI>
  );
};

export const FieldConnector: FC<FieldConnectorProps> = ({
  children,
  label,
  subLabel,
  labelClassName,
  labelWrapperClassName,
  mainLabelClassName,
  helper,
  hint,
  showField = true,
  destroyOnHide = true,
  showUI = true,
  destroyValue,
  name: _name,
  errors,
  className: _className,
  ErrorContext,
  onShow,
  errorKeys,
  tooltip,
  PlaceholderNode,
  horizontal,
  displayErrorsOnFirstSubmit,
  executeOnChange,
  highlightID,
  forceHideError,
  disabled: propsDisabled,
  loading,
}) => {
  const name = Array.isArray(_name) ? _name.join('_') : _name;

  const disabled =
    typeof propsDisabled === 'function' ? propsDisabled(name) : propsDisabled;

  const onShowRef = useRef(onShow);
  onShowRef.current = onShow;

  const destroyValueRef = useRef(destroyValue);
  destroyValueRef.current = destroyValue;

  const { t } = useTranslation();
  const {
    setValue,
    reset,
    unregister,
    getValues,
    formState: { errors: formErrors, isSubmitted },
  } = useFormContext();

  const shouldUseNullAsDestroyValue = useContext(WorkAroundLegacyFieldDestroy);

  useEffect(() => {
    if (!name) return;

    if (showField && onShowRef.current) {
      return runOnShowEffect(() =>
        onShowRef.current?.(name, !showUI, getValues(name)),
      );
    }

    if (!showField && destroyOnHide) {
      return runOnDestroyEffect(() => {
        unregister(name);
        if (destroyValueRef.current !== undefined) {
          setValue(name, destroyValueRef.current);
        } else {
          if (shouldUseNullAsDestroyValue) {
            setValue(name, null);
            return;
          }
          setValue(name, undefined);
          reset(getValues(), formResetOptions);
        }
      });
    }
  }, [showField, name, unregister, setValue, destroyOnHide]);

  const highlightAPI = useHighlight();

  const className = classNames(
    _className,
    highlightAPI.shouldHighlight(highlightID || name) && highlightAPI.className,
  );

  if (!showField || !showUI || !children) {
    return PlaceholderNode ? <PlaceholderNode /> : null;
  }

  const errorKey = (errorKeys || ([] as string[]).concat(_name)).find((key) =>
    _get(errors || formErrors, `${key}.message`),
  );

  const { message: fieldError, context } = errorKey
    ? _get(errors || formErrors, errorKey)
    : ({} as any);

  const containerHorizontalClasses = classNames({
    'field-grid justify-between items-center': horizontal,
  });
  const labelClasses = classNames(
    'first-letter:capitalize whitespace-pre-line text-sm',
    labelClassName,
    'text-primary-700',
    {
      // 'text-primary-400': disabled,
      // 'text-primary-700': !disabled,
      'mb-1': !horizontal,
    },
  );

  const labelContent = typeof label === 'function' ? label() : label;
  const subLabelContent =
    typeof subLabel === 'function' ? subLabel() : subLabel;

  // const tracingEvents = getTracingEvents(tracing, { onFocus });

  const translatedError = t(fieldError, { ...context, ...ErrorContext, label });

  return (
    <Tooltip content={tooltip}>
      <div
        data-highlight-id={highlightID || name}
        className={classNames(className, containerHorizontalClasses, {
          'field-with-error': !!fieldError,
        })}
        id={name}
      >
        <div
          className={classNames(
            'flex gap-2 items-center',
            labelWrapperClassName,
          )}
        >
          {labelContent || subLabelContent ? (
            <label htmlFor={name} className={labelClasses}>
              {labelContent && (
                <div
                  className={classNames(
                    mainLabelClassName,
                    'flex gap-2 items-center',
                  )}
                >
                  {labelContent}
                  {loading ? <Loader /> : helper}
                </div>
              )}
              {subLabelContent && <div>{subLabelContent}</div>}
            </label>
          ) : null}
        </div>
        <Controller
          name={name}
          render={({ field: { ref, ...fieldRest }, fieldState, formState }) =>
            children({
              error: translatedError,
              name,
              label: labelContent,
              fieldState,
              formState,
              field: {
                ...(disabled == null ? {} : { disabled }),
                ...fieldRest,
                onChange: (event: any) => {
                  executeOnChange?.();

                  return fieldRest.onChange(event);
                },
                error: translatedError,
                autoComplete: fieldRest.value ? 'new-password' : 'on',
              },
            })
          }
        />
        {(fieldError && !forceHideError) || hint ? (
          <div
            className={classNames({ 'grid-start-col-2-to-end': horizontal })}
          >
            {fieldError &&
              !forceHideError &&
              ((displayErrorsOnFirstSubmit && isSubmitted) ||
                !displayErrorsOnFirstSubmit) && (
                <FieldErrorUI className="mt-1">{translatedError}</FieldErrorUI>
              )}

            {hint ? (
              <p className="mt-1 text-sm text-primary-500 leading-tight">
                {hint}
              </p>
            ) : null}
          </div>
        ) : null}
      </div>
    </Tooltip>
  );
};

type FakeFieldProps = {
  label?: ReactNode;
  horizontal?: boolean;
  labelClassName?: string;
  labelWrapperClassName?: string;
  helper?: ReactNode;
  className?: string;
  hint?: string;
  showField?: boolean;
  onShow?: () => void;
  children?: () => ReactElement;
  disabled?: boolean;
};

// same structure as FormField but without the connect
// used for ui
export const FakeField: FC<FakeFieldProps> = ({
  children,
  label,
  labelClassName,
  helper,
  hint,
  showField = true,
  className,
  onShow,
  disabled,
  horizontal,
  labelWrapperClassName,
}) => {
  const onShowRef = useRef(onShow);
  onShowRef.current = onShow;

  const name = useId();

  useEffect(() => {
    if (showField && onShowRef.current) {
      return runOnShowEffect(() => onShowRef.current?.());
    }
    //  Promise.resolve().then().then(onShowRef.current);
  }, [showField]);

  if (!showField || !children?.()) {
    return null;
  }

  const containerHorizontalClasses = classNames({
    'field-grid justify-between items-center': horizontal,
  });

  return (
    <div className={classNames(className, containerHorizontalClasses)}>
      <div
        className={classNames('flex gap-2 items-center', labelWrapperClassName)}
      >
        {label ? (
          <label
            htmlFor={name}
            className={classNames(
              'first-letter:capitalize whitespace-pre-line text-sm mb-1',
              {
                'text-primary-400': disabled,
                'text-primary-700': !disabled,
              },
              labelClassName,
            )}
          >
            {label}
          </label>
        ) : null}
        {helper}
      </div>

      {children()}

      {hint ? <p className="text-xs text-primary-500">{hint}</p> : null}
    </div>
  );
};
