import { routes } from 'applications/web/routing/routes';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import type { RouteType } from 'types/routing.types';
import { getTracingEvents } from 'utils/amplitude';
import { WEBAPP_GLOBAL_VISITEDPAGE } from 'utils/amplitude/events/global.amplitude';

const tracingEvents = getTracingEvents({
  onRouteChange: {
    name: WEBAPP_GLOBAL_VISITEDPAGE,
    data: (data: { url: string }) => data,
  },
});

const ignoredAmplitudeRoutes = routes.filter(
  (route: RouteType) => route.ignoreAmplitude === true,
);

const shouldIgnoreAmplitudeLogging = (pathname: string): boolean =>
  !!ignoredAmplitudeRoutes.find(
    (route: RouteType) => matchPath(route.path, pathname) !== null,
  );

const useLogAmplitudeOnPageVisits = (): void => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (shouldIgnoreAmplitudeLogging(pathname)) return;

    return tracingEvents.onRouteChange({
      url: pathname + search,
    });
  }, [pathname, search]);
};

export default useLogAmplitudeOnPageVisits;
