import { type FC } from 'react';

import getIntercom from 'hooks/intercom.hook';
import type {
  NavbarItemType,
  NavbarNavigationListType,
} from 'types/navbar.types';

import HelpIcon from 'components/atoms/SVG/sidebarIcons/Help.component';
import { HighlightProvider } from 'hooks/useHighlight';
import AccountMenu from './AccountMenu.component';
import IntercomUnreadCount from './CountBadges/IntercomUnreadCount.component';
import MoreMenu from './MoreMenu';
import NavigationItem from './NavigationItem.component';
import { SidebarNavigationItemTypesEnum } from './types';

const Sidebar: FC<{
  navigationList: NavbarNavigationListType;
  sidebarRef?: any;
}> = ({ navigationList = [], sidebarRef }) => {
  const { showIntercom } = getIntercom();

  const allowedItems = navigationList.filter(
    ({ condition = true }) => !!condition,
  );

  return (
    <HighlightProvider className="animate-highlightTaxes">
      <div
        className="h-screen fixed w-max z-50 bg-transparent p-4 pr-0"
        ref={sidebarRef}
      >
        <div className="bg-white h-full flex flex-col justify-between flex-grow pt-2 pb-4 shadow-lg rounded-xl">
          <div className="flex items-center justify-start flex-col overflow-y-auto">
            {allowedItems
              .filter(({ placement }) => placement !== 'bottom')
              .map(
                ({ Component, ...item }: NavbarItemType) =>
                  Component || <NavigationItem key={item.title} item={item} />,
              )}
          </div>

          <div className="flex items-center justify-start flex-col">
            {allowedItems
              .filter(({ placement }) => placement === 'bottom')
              .map(
                ({ Component, ...item }: NavbarItemType) =>
                  Component || <NavigationItem key={item.title} item={item} />,
              )}

            <NavigationItem
              item={{
                title: 'sidebar.help_title',
                Icon: HelpIcon,
                type: SidebarNavigationItemTypesEnum.help,
                onClick: showIntercom,
                BadgeComponent: IntercomUnreadCount,
              }}
            />

            <MoreMenu />

            <AccountMenu />
          </div>
        </div>
      </div>
    </HighlightProvider>
  );
};

export default Sidebar;
