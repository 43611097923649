import { Button } from 'components/atoms/Button';
import useGermanPrivateUsageCategory from 'components/organisms/SettingsViews/GermanCars/useGermanPrivateUsageCategory';
import type { FC } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RevenueType } from 'types/revenues.types';
import type { CheckComponentProps } from 'types/taxes.types';
import { CurrentTaxContext } from '../../Tax';
import CheckUITemplate from '../CheckUITemplate';
import { useReviewTaxCheck } from '../hooks';

const OnePercentRuleExpenseNoUsage: FC<CheckComponentProps> = (props) => {
  const { data: tax } = useContext(CurrentTaxContext);
  const { revenueFormRef, checkName } = props;
  const { t } = useTranslation();
  const privateUseCategoryAPI = useGermanPrivateUsageCategory();
  const { mutate: markAsReviewed } = useReviewTaxCheck(checkName, tax, 500);
  return (
    <CheckUITemplate
      {...props}
      renderButtons={() => {
        return (
          <Button
            structure="secondary"
            onClick={() => {
              if (!privateUseCategoryAPI.data) {
                return revenueFormRef.current?.open({
                  type: RevenueType.other_revenue,
                });
              }

              const category = privateUseCategoryAPI.data;
              revenueFormRef.current?.open(
                {
                  type: RevenueType.other_revenue,
                  customType: category.customType,
                  customData: {
                    customCategory: category,
                    customCategoryId: category.id,
                  },
                },
                {
                  afterSubmit: () => {
                    markAsReviewed();
                  },
                },
              );
            }}
          >
            {t('taxes.tax_checks.encode_car_revenue')}
          </Button>
        );
      }}
    />
  );
};

export default OnePercentRuleExpenseNoUsage;
