import type { UserCountry } from 'types/users.types';

export const version = process.env.REACT_APP_VERSION;
export const environment = process.env.REACT_APP_ENV || 'development';
export const isProduction = environment === 'production';
export const product = process.env.REACT_APP_PRODUCT || 'web';
export const amplitudeKey = process.env.REACT_APP_AMPLITUDE_KEY;
export const tapFiliateId = process.env.REACT_APP_TAPFILIATEID;
export const pdfWorkerName = process.env.REACT_APP_PDF_WORKER_NAME;

export const slackHooks = {
  translation: process.env.REACT_APP_SLACK_TRANSLATION_HOOK,
  chift: process.env.REACT_APP_SLACK_CHIFT_HOOK,
};

export const recaptcha = {
  key: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
};

export const stripe: { key: Record<UserCountry, string> } = {
  key: {
    de: process.env.REACT_APP_STRIPE_GERMANY_PUBLIC_KEY as string,
    be: process.env.REACT_APP_STRIPE_BELGIUM_PUBLIC_KEY as string,
  },
};

const customENV =
  localStorage.getItem('customENV') &&
  JSON.parse(localStorage.getItem('customENV') || '{}');

export const accountableAPI =
  customENV && environment !== 'production'
    ? customENV
    : {
        baseUrl: process.env.REACT_APP_ACCOUNTABLE_API_BASE_URL,
        filesUrl: process.env.REACT_APP_ACCOUNTABLE_API_FILES_URL,
        redirectUrl: process.env.REACT_APP_REDIRECT_URL,
      };

export const pusher = {
  key: process.env.REACT_APP_PUSHER_KEY as string,
};
