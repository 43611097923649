import { IntegrationSlugEnum } from 'api/v1/integrations';
import { Button } from 'components/atoms/Button';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationIconOutline } from 'utils/icons';
import useCustomerCountry from './shared/useCustomerCountry';
import { worldThemeContext } from './shared/useWorldTheme';
import useCustomNavigate from './useCustomeNavigate';
import useModal from './useModal';
import usePusher from './usePusher';

type Event = null | {
  data: { slug: IntegrationSlugEnum.peppol };
};

const usePeppolInactiveEvent = () => {
  const { t } = useTranslation();
  const country = useCustomerCountry();
  const navigate = useCustomNavigate();
  const channel = usePusher();

  const [content, api] = useModal({
    name: 'peppol became inactive event modal',
    onBlur: () => null,
    onClose: () => null,
  });

  useEffect(() => {
    if (!channel) return;

    const handler = (event: Event) => {
      console.log(event);
      if (!event) return;
      if (event.data.slug !== IntegrationSlugEnum.peppol) return;
      api.open(() => (
        <div className="p-2 text-center">
          <span className="inline-block p-2 bg-yellow-50 rounded-full">
            <ExclamationIconOutline className="w-8 h-8 text-yellow-500" />
          </span>
          <p className="text-lg font-semibold mt-2 leading-tight">
            {t('peppol.inactive.warning.' + country)}
          </p>
          <div className="mt-6 flex flex-col gap-2">
            <Button
              onClick={() => {
                navigate('/settings/peppol');
                api.close();
              }}
            >
              {t('peppol.inactive.warning.update_now')}
            </Button>
            <Button structure="secondary" onClick={() => api.close()}>
              {t('peppol.inactive.warning.update_later')}
            </Button>
          </div>
        </div>
      ));
    };

    channel.bind('user.integration.deactivated', handler);
    return () => {
      channel.unbind('user.integration.deactivated', handler);
    };
  }, [channel]);

  return content;
};

const UsePeppolInactiveEvent = () => {
  return (
    <worldThemeContext.Provider value={'taxes'}>
      {usePeppolInactiveEvent()}
    </worldThemeContext.Provider>
  );
};

export default UsePeppolInactiveEvent;
