import type { SVGProps } from 'react';

export default function EuroCoins(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="64"
      height="88"
      viewBox="0 0 64 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.6651 53.514C20.7104 50.9287 15.5524 43.3931 7.15811 36.6786C-1.26877 29.9683 -9.75358 26.6094 -11.8032 29.1621C-13.8485 31.7473 -8.69055 39.2829 -0.296217 45.9975C8.13067 52.7078 16.6155 56.0667 18.6651 53.514Z"
        fill="#A68BD9"
      />
      <path
        d="M21.79 49.5649L18.6611 53.4838L-11.8396 29.1364L-8.71079 25.2173"
        fill="#A68BD9"
      />
      <path
        d="M21.7947 49.5978C23.84 47.0125 18.6821 39.4772 10.2877 32.7627C1.86087 26.0524 -6.62394 22.6935 -8.67353 25.2462C-10.7189 27.8314 -5.56095 35.3667 2.83338 42.0813C11.2603 48.7916 19.7451 52.1505 21.7947 49.5978Z"
        fill="#E6DEF4"
      />
      <path
        d="M21.2575 48.9653C23.2233 46.4998 18.279 39.3237 10.2271 32.9328C2.14403 26.546 -5.99779 23.353 -7.96765 25.7875C-9.93346 28.253 -4.98916 35.4291 3.06268 41.82C11.1458 48.2068 19.2876 51.3999 21.2575 48.9653Z"
        fill="#F2EEFA"
      />
      <path
        opacity="0.7"
        d="M3.06268 41.82C-4.98916 35.4291 -9.93346 28.253 -7.96765 25.7875C-7.49547 25.2039 -6.66867 24.9437 -5.57306 24.9733L19.6854 49.74C16.4339 50.1818 9.72834 47.0868 3.06268 41.82Z"
        fill="url(#paint0_linear_2391_16212)"
      />
      <path
        d="M12.6526 37.9773C13.15 38.9448 13.1301 40.0065 12.5611 40.7165C11.604 41.9009 9.82456 41.6572 7.37328 40.1246L6.84951 40.7758L5.70229 39.496L5.9772 39.1689C5.88888 39.101 5.80055 39.033 5.71223 38.9651L5.44386 38.7355C5.20494 38.5284 4.96943 38.3476 4.73051 38.1405L4.29722 38.6738L3.14321 37.3422L3.45994 36.9299C1.4906 34.8311 0.991735 33.0429 2.01666 31.7702C2.31419 31.4135 3.0146 30.8981 3.43452 30.8696L5.06081 32.775C4.74502 32.7898 4.29349 32.9815 3.99596 33.3382C3.56268 33.8715 3.63547 34.6298 4.45884 35.6606L5.9623 33.7958L7.14234 35.124L5.71016 36.9265C5.91965 37.1109 6.12915 37.2954 6.36467 37.4764L6.66247 37.7286C6.78023 37.8191 6.86854 37.887 6.95686 37.9549L8.25328 36.3288L9.40051 37.6086L8.37557 38.8813C9.46022 39.4807 10.2979 39.6095 10.7312 39.0761C11.0705 38.6345 10.9695 38.0652 10.6501 37.445L12.6526 37.9773Z"
        fill="#BFACE3"
      />
      <path
        d="M59.5369 20.2399C60.2659 17.0251 52.3605 12.454 41.8901 10.0206C31.3922 7.60512 22.2887 8.23769 21.5418 11.425C20.8127 14.6399 28.7182 19.2109 39.1885 21.6443C49.6864 24.0598 58.7899 23.4273 59.5369 20.2399Z"
        fill="#A68BD9"
      />
      <path
        d="M60.6497 15.3293L59.5189 20.2148L21.4964 11.4181L22.627 6.53227"
        fill="#A68BD9"
      />
      <path
        d="M60.6673 15.3582C61.3964 12.1433 53.4911 7.57257 43.0208 5.13916C32.5229 2.72365 23.4193 3.35622 22.6724 6.54357C21.9433 9.75845 29.8486 14.3292 40.319 16.7626C50.8169 19.1781 59.9204 18.5455 60.6673 15.3582Z"
        fill="#E6DEF4"
      />
      <path
        d="M59.911 15.0198C60.62 11.9472 53.0625 7.60831 43.0408 5.31901C32.9926 3.04689 24.2701 3.68105 23.5441 6.72739C22.8351 9.79996 30.3926 14.1389 40.4143 16.4282C50.4625 18.7003 59.185 18.0661 59.911 15.0198Z"
        fill="#F2EEFA"
      />
      <path
        opacity="0.7"
        d="M40.4143 16.4282C30.3926 14.1389 22.8351 9.79996 23.5441 6.72739C23.7181 5.99718 24.3516 5.40556 25.3527 4.95935L58.8274 16.3972C56.0851 18.1992 48.7005 18.3018 40.4143 16.4282Z"
        fill="url(#paint1_linear_2391_16212)"
      />
      <path
        d="M47.408 8.82082C48.2742 9.47891 48.7146 10.4452 48.5077 11.3312C48.1557 12.8127 46.4454 13.3611 43.5727 13.0367L43.3813 13.8501L41.794 13.1909L41.9008 12.7771C41.7918 12.754 41.6828 12.7308 41.5738 12.7077L41.2326 12.6164C40.9277 12.5328 40.6372 12.4713 40.3323 12.3877L40.1717 13.0557L38.5559 12.3527L38.6637 11.8441C35.9813 10.801 34.7594 9.40323 35.1346 7.81276C35.249 7.36259 35.6583 6.59536 36.0248 6.3884L38.3143 7.40515C38.0358 7.55487 37.7112 7.92265 37.5968 8.37282C37.4362 9.04093 37.8292 9.69357 39.0168 10.268L39.568 7.93681L41.2058 8.62555L40.692 10.8697C40.9606 10.9456 41.2292 11.0216 41.5198 11.0832L41.8972 11.1822C42.0425 11.213 42.1515 11.2361 42.2605 11.2592L42.728 9.23286L44.3153 9.89206L43.9401 11.4825C45.1772 11.555 45.9884 11.3096 46.149 10.6415C46.2646 10.0966 45.9277 9.62664 45.3718 9.20516L47.408 8.82082Z"
        fill="#BFACE3"
      />
      <path
        d="M59.7017 73.0107C58.5877 69.9081 49.4646 70.3049 39.3281 73.883C29.1782 77.4911 21.8428 82.9194 22.9268 86.0084C24.0407 89.111 33.1639 88.7143 43.3003 85.1361C53.4503 81.528 60.7857 76.0997 59.7017 73.0107Z"
        fill="#A68BD9"
      />
      <path
        d="M57.9987 68.2717L59.6722 72.9989L22.8841 86.0266L21.2103 81.2992"
        fill="#A68BD9"
      />
      <path
        d="M58.0283 68.2836C56.9144 65.181 47.7915 65.5779 37.6551 69.156C27.5051 72.7641 20.1697 78.1924 21.2537 81.2814C22.3677 84.384 31.4906 83.9871 41.627 80.4089C51.7769 76.8009 59.1123 71.3726 58.0283 68.2836Z"
        fill="#E6DEF4"
      />
      <path
        d="M57.2096 68.4049C56.1552 65.4331 47.4502 65.8384 37.7696 69.2969C28.0759 72.784 21.0625 78.0088 22.0884 80.9677C23.1427 83.9395 31.8478 83.5341 41.5284 80.0757C51.2221 76.5885 58.2354 71.3638 57.2096 68.4049Z"
        fill="#F2EEFA"
      />
      <path
        opacity="0.7"
        d="M41.5284 80.0757C31.8478 83.5341 23.1427 83.9395 22.0884 80.9677C21.8425 80.2584 22.0585 79.419 22.6626 78.5045L57.0365 70.149C55.6933 73.1428 49.5223 77.2 41.5284 80.0757Z"
        fill="url(#paint2_linear_2391_16212)"
      />
      <path
        d="M43.3352 69.9054C44.4194 69.9945 45.3102 70.5724 45.6123 71.4307C46.1121 72.8691 44.9649 74.2511 42.3684 75.5222L42.6444 76.311L40.9516 76.6086L40.8192 76.2023C40.7149 76.2415 40.6105 76.2805 40.5062 76.3196L40.1694 76.4261C39.8674 76.5195 39.5894 76.6239 39.2873 76.7173L39.5111 77.367L37.7708 77.643L37.5881 77.1562C34.7656 77.719 32.9839 77.1976 32.445 75.6549C32.2994 75.2138 32.232 74.3468 32.4297 73.9752L34.9067 73.6015C34.7525 73.8774 34.6766 74.362 34.8221 74.8031C35.046 75.4528 35.7282 75.7917 37.0384 75.6375L36.2497 73.3756L38.0009 73.0757L38.7744 75.2441C39.0416 75.1637 39.309 75.0833 39.5871 74.979L39.9586 74.8595C40.0976 74.8073 40.2019 74.7682 40.3062 74.7291L39.6109 72.7692L41.3036 72.4716L41.8425 74.0142C42.9245 73.4102 43.4765 72.7671 43.2527 72.1174C43.0571 71.5959 42.5204 71.3808 41.8251 71.3243L43.3352 69.9054Z"
        fill="#BFACE3"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2391_16212"
          x1="-7.62004"
          y1="24.5109"
          x2="23.029"
          y2="57.5793"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9CDEF" />
          <stop offset="1" stopColor="#D9CDEF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2391_16212"
          x1="23.3066"
          y1="5.42577"
          x2="65.2273"
          y2="22.0252"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9CDEF" />
          <stop offset="1" stopColor="#D9CDEF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2391_16212"
          x1="21.1883"
          y1="79.9979"
          x2="65.4587"
          y2="71.4529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9CDEF" />
          <stop offset="1" stopColor="#D9CDEF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
