import type { FC, MouseEventHandler } from 'react';
import type { EventConfig } from 'utils/amplitude/amplitude.types';

export interface IconButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon: FC;
  buttonType?:
    | 'default'
    | 'taxes'
    | 'expenses'
    | 'revenues'
    | 'bank'
    | 'primary';
  style?: React.CSSProperties;
  className?: string;
  heightClass?: string;
  disabled?: boolean;
  tracingEvent?: EventConfig;
}

type CustomButtonBgColorTypeMap = Record<
  Exclude<IconButtonProps['buttonType'], undefined>,
  { backgroundColor: string }
>;

export const customButtonBgColorTypeMap: CustomButtonBgColorTypeMap = {
  default: {
    backgroundColor: '#fff',
  },
  taxes: {
    backgroundColor: 'bg-taxes',
  },
  expenses: {
    backgroundColor: 'bg-expenses',
  },
  revenues: {
    backgroundColor: 'bg-revenues',
  },
  bank: {
    backgroundColor: 'bg-bank',
  },
  primary: {
    backgroundColor: 'bg-primary',
  },
};
