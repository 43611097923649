import { createContext, useContext } from 'react';
import { useLocation } from 'react-router';
import type { color } from 'types/shared/worlds';

const worlds = {
  expenses: ['expenses'],
  revenues: ['revenues', 'clients', 'quotes'],
  bank: ['bank', 'banks'],
  taxes: ['taxes'],
};

export const worldThemeContext = createContext('primary' as color);

export const useGetWorldThemeFromPath = (): color => {
  const location = useLocation();
  if (location.pathname === '/') return 'bank';
  const [theme] =
    Object.entries(worlds).find(([, pages]) =>
      pages.some((page) => location.pathname.includes(page)),
    ) || [];
  return (theme || 'primary') as color;
};

const useWorldTheme = (): color => {
  return useContext(worldThemeContext);
};

export default useWorldTheme;
