import { useTranslation } from 'react-i18next';
import { Helper } from '../Helper';

const AIConsentHelperContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helper.Title className="text-center !text-primary-800">
        {t('ai_bot.data_share.alert.title')}
      </Helper.Title>
      <Helper.Paragraph className="whitespace-pre-line text-sm">
        {t('ai_bot.data_share.alert.text', {
          buttonText: t('ai_bot.data_share.helper.title'),
        })}
      </Helper.Paragraph>
    </>
  );
};

export default AIConsentHelperContent;
