import annePic from 'assets/images/Anne.png';
import cassiePic from 'assets/images/Cassie.png';
import eniolaPic from 'assets/images/Eniola.png';
import hannaPic from 'assets/images/Hanna.png';
import romainPic from 'assets/images/Romain.png';
import lightCirclesCornerArtImage from 'assets/svg/light_circles_corner_art.svg';
import { Button } from 'components/atoms/Button';
import ContainerWithBorder from 'components/atoms/ContainerWithBorder';
import { motion } from 'framer-motion';
import getIntercom from 'hooks/intercom.hook';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import type { YearlyReviewData } from 'types/global.types';
import { removeSoftHyphens } from 'utils/helpers';
import { getReviewYears } from './helpers';

const csTeamPicturesMap = {
  Romain: romainPic,
  Cassie: cassiePic,
  Eniola: eniolaPic,
  Hanna: hannaPic,
  Anne: annePic,
};

const CSQuotesSection: FC<{ data?: YearlyReviewData }> = ({ data }) => {
  const { t } = useTranslation();

  const country = useCustomerCountry();

  const { showIntercom } = getIntercom();

  const taxCoachName = t(`cs_tax_coach.${country}`);

  const { year, newYear, previousYear } = getReviewYears();

  return (
    <motion.div
      initial={{ opacity: 0, translateY: 100 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ ease: 'easeOut', delay: 0.3, duration: 0.6 }}
    >
      <ContainerWithBorder containerClassName="mt-10 relative">
        <div className="flex items-center p-10 py-20">
          <div className="flex flex-col items-center w-[30%]">
            <img
              src={
                csTeamPicturesMap[
                  removeSoftHyphens(
                    taxCoachName,
                  ) as keyof typeof csTeamPicturesMap
                ]
              }
              alt="Quote person picture"
              className="w-32 h-32 rounded-full"
            />

            <div className="text-center mt-3">
              <p className="text-primary-700 font-semibold">{taxCoachName}</p>

              <p className="text-primary-700">
                {t('yearly_review.cs_quotes_widget.position_title')}
              </p>
            </div>
          </div>

          <div className="w-[70%] pl-4">
            {data?.quotes?.quote1 ? (
              <p className="text-primary italic font-semibold mb-3">
                <ReactMarkdown
                  components={{
                    a: ({ children, href }) => (
                      <a
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        className="underline text-blue-500"
                      >
                        {children}
                      </a>
                    ),
                    em: ({ children }) => (
                      <Button
                        asDefault
                        onClick={showIntercom}
                        className="underline text-blue-500 !italic"
                      >
                        {children}
                      </Button>
                    ),
                  }}
                >
                  {t(
                    `yearly_review.cs_quotes_widget.quote1.${data?.quotes.quote1}`,
                    {
                      year,
                      newYear,
                      previousYear,
                      deductionLink: t(`al­ly­ou­can­d­educt_link.${country}`),
                    },
                  )}
                </ReactMarkdown>
              </p>
            ) : null}

            {data?.quotes?.quote2 ? (
              <p className="text-primary italic font-semibold">
                <ReactMarkdown
                  components={{
                    a: ({ children, href }) => (
                      <a
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        className="underline text-blue-500"
                      >
                        {children}
                      </a>
                    ),
                    em: ({ children }) => (
                      <Button
                        asDefault
                        onClick={showIntercom}
                        className="underline text-blue-500 !italic"
                      >
                        {children}
                      </Button>
                    ),
                  }}
                >
                  {t(
                    `yearly_review.cs_quotes_widget.quote2.${data?.quotes.quote2}`,
                    {
                      year,
                      newYear,
                      previousYear,
                      deductionLink: t(`al­ly­ou­can­d­educt_link.${country}`),
                    },
                  )}
                </ReactMarkdown>
              </p>
            ) : null}
          </div>
        </div>

        <img
          src={lightCirclesCornerArtImage}
          className="absolute top-0 right-0"
        />
      </ContainerWithBorder>
    </motion.div>
  );
};

export default CSQuotesSection;
