import type SVGType from 'types/svg.types';

const MoreIcon: SVGType = (props) => (
  <svg
    viewBox="0 0 32 32"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.11811 20.1785L4.4395 13.8723C3.92098 12.9835 4.56215 11.8672 5.5912 11.8672H12.9484C13.9775 11.8672 14.6187 12.9835 14.1001 13.8723L10.4215 20.1786C9.90702 21.0606 8.63262 21.0606 8.11811 20.1785Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M19.4027 5H27.4027C27.5868 5 27.736 5.14924 27.736 5.33333V13.3333C27.736 13.5174 27.5868 13.6667 27.4027 13.6667H19.4027C19.2186 13.6667 19.0693 13.5174 19.0693 13.3333V5.33333C19.0693 5.14924 19.2186 5 19.4027 5Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M24.2692 22.6663C24.2692 25.0596 22.3291 26.9997 19.9359 26.9997C17.5426 26.9997 15.6025 25.0596 15.6025 22.6663C15.6025 20.2731 17.5426 18.333 19.9359 18.333C22.3291 18.333 24.2692 20.2731 24.2692 22.6663Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default MoreIcon;
