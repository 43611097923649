import APC1_BE_en from './APC1_BE/en.json';
import APC1_BE_en_single from './APC1_BE/en_single.json';
import APC1_BE_fr from './APC1_BE/fr.json';
import APC1_BE_fr_single from './APC1_BE/fr_single.json';
import APC1_BE_nl from './APC1_BE/nl.json';
import APC1_BE_nl_single from './APC1_BE/nl_single.json';
///
import APC2_BE_en from './APC2_BE/en.json';
import APC2_BE_fr from './APC2_BE/fr.json';
import APC2_BE_nl from './APC2_BE/nl.json';
///
import APC1_DE_de from './APC1_DE/de.json';
import APC1_DE_de_single from './APC1_DE/de_single.json';
import {
  default as APC1_DE_en,
  default as APC1_DE_en_single,
} from './APC1_DE/en.json';
///
import APC2_DE_de from './APC2_DE/de.json';
import APC2_DE_en from './APC2_DE/en.json';

const appendSuffix = (obj, prefix) =>
  Object.entries(obj).reduce((all, [key, value]) => {
    all[`${key}${prefix}`] = value;
    return all;
  }, {});

// when using single APC1 alsways add prefix _single upon using the key in the GSheet
const occupations = {
  en: {
    ...APC1_BE_en,
    ...APC2_BE_en,
    ...APC1_DE_en,
    ...APC2_DE_en,
    ...appendSuffix(APC1_BE_en_single, '_single'),
    ...appendSuffix(APC1_DE_en_single, '_single'),
  },
  fr: {
    ...APC1_BE_fr,
    ...APC2_BE_fr,
    ...appendSuffix(APC1_BE_fr_single, '_single'),
  },
  nl: {
    ...APC1_BE_nl,
    ...APC2_BE_nl,
    ...appendSuffix(APC1_BE_nl_single, '_single'),
  },
  de: {
    ...APC1_DE_de,
    ...APC2_DE_de,
    ...appendSuffix(APC1_DE_de_single, '_single'),
  },
};

export default occupations;
