import getCustomerBillingPortal from 'api/v1/subscriptions/getCustomerBillingPortal';
import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

const useRedirectToCustomerBillingPortal = (): UseMutationResult<
  {
    url: string;
  },
  unknown,
  void,
  unknown
> => {
  return useMutation(getCustomerBillingPortal, {
    onSuccess: ({ url }) => {
      window.location.href = url;
    },
  });
};

export default useRedirectToCustomerBillingPortal;
