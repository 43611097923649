import { useGrowthABExperimentsState } from 'hooks/useAssignGrowthABGroups';
import useIsNewUserRelativeToDate from 'hooks/useIsNewUserRelativeToDate';
import { ABTest } from 'types/growthExperiments.types';

export const STARTER_HOME_RELEASE_DATE = '2024-04-02T12:17:42.211Z';

const useShouldSeeStarterHomePage = (): boolean => {
  const { experiments } = useGrowthABExperimentsState();

  const isNewUser = useIsNewUserRelativeToDate()(STARTER_HOME_RELEASE_DATE);

  return experiments.STARTER_HOME_PAGE === ABTest.B && isNewUser;
};

export default useShouldSeeStarterHomePage;
