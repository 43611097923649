import type SVGType from 'types/svg.types';

const BankIcon: SVGType = (props) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9997 6.45104L25.6663 11.2844V11.533H6.33301V11.2844L15.9997 6.45104Z M8 14V23.3333 M5.33301 25.7334H26.6663 M13.333 14V23.3333M18.667 14V23.3333 M24 14V23.3333"
      strokeWidth="2"
      stroke="currentColor"
    />
  </svg>
);

export default BankIcon;
