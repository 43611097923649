import { Button } from 'components/atoms/Button';
import { Select } from 'components/atoms/Form/Select';
import type { SelectProps } from 'components/atoms/Form/Select/select.types';
import { Tooltip } from 'components/atoms/Tooltip';
import useConfirm from 'hooks/useConfirm';
import useModal from 'hooks/useModal';
import type { FC, ReactNode } from 'react';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import type { DEVehicle, VehiclesOption } from 'types/vehicle.types';
import { PencilIcon, TrashIcon } from 'utils/icons';

type RenderFormProps = {
  defaultValues: any;
  onSubmit: (values: any) => any;
  context: 'add' | 'edit';
};

interface VehicleFieldProps extends SelectProps {
  onAddVehicle?: (vehicle: VehiclesOption<DEVehicle>) => void;
  onEditVehicle?: (vehicle: VehiclesOption<DEVehicle>) => void;
  onDeleteVehicle?: (vehicle: VehiclesOption<DEVehicle>) => void;
  getVehiclesOptions: () => Promise<
    { label: string; value: string; vehicle: any }[]
  >;
  renderForm: (renderProps: RenderFormProps) => ReactNode;
  deleteVehicle: (id: string) => Promise<void>;
  deleteMessage: string;
  valueLabel?: string;
}

const CarsSelect: FC<VehicleFieldProps> = (props) => {
  const { t } = useTranslation();
  const [vehicleFormModal, vehicleFormModalApi] = useModal({
    dialogPanalClassName: '!p-0',
    name: '',
  });

  const [confirmationContent, confirmationApi] = useConfirm();
  const queryClient = useQueryClient();
  const cacheKey = useId();

  const {
    data: options = [],
    isLoading,
    isError,
    refetch,
  } = useQuery(cacheKey, () => props.getVehiclesOptions(), { cacheTime: 0 });

  const addNewVehicle = (vehicle: any) => {
    const newVehcileOption = {
      label: vehicle.name,
      value: vehicle._id,
      vehicle,
    };
    queryClient.setQueryData(cacheKey, [newVehcileOption].concat(options));
    props.onAddVehicle?.(newVehcileOption);
    return vehicle;
  };

  const editVehicle = (vehicle: any) => {
    const updatedVehcileOption = {
      label: vehicle.name,
      value: vehicle._id,
      vehicle,
    };
    queryClient.setQueryData(
      cacheKey,
      options.map((option) =>
        option.value === updatedVehcileOption.value
          ? updatedVehcileOption
          : option,
      ),
    );
    props.onEditVehicle?.(updatedVehcileOption);
    return vehicle;
  };

  const deleteVehicleOption = (vehicle: any) => {
    const deletedVehcileOption = {
      label: vehicle.name,
      value: vehicle._id,
      vehicle,
    };
    queryClient.setQueryData(
      cacheKey,
      options.filter((option) => option.value !== vehicle._id),
    );
    props.onDeleteVehicle?.(deletedVehcileOption);
  };

  const ADD_VEHICLE = '__ADD_VEHICLE__';

  if (isLoading || isError || !!options.length) {
    return (
      <>
        <Select
          {...props}
          options={[{ label: t('vehicle.add'), value: ADD_VEHICLE }].concat(
            options,
          )}
          onChange={(option: any, actionMeta) => {
            if (option.value === ADD_VEHICLE) {
              return vehicleFormModalApi.open(
                (modalApi) =>
                  props.renderForm({
                    defaultValues: {},
                    context: 'add',
                    onSubmit: (values) => {
                      addNewVehicle(values);
                      modalApi.closeModal();
                    },
                  }),
                { name: 'add vehicle' },
              );
            }
            props.onChange?.(option, actionMeta);
          }}
          isLoading={isLoading}
          isDisabled={isLoading || props.isDisabled}
          isError={isError}
          retry={() => refetch()}
          placeholder=""
          formatOptionLabel={(option: any, meta) => {
            if (meta.context === 'value') {
              return props.valueLabel ?? option.label;
            }
            if (option.value === ADD_VEHICLE) {
              return (
                <Button structure="text" size="xl" className="w-full">
                  {option.label}
                </Button>
              );
            }
            return (
              <div className="flex flex-1 justify-between item-center">
                <span>{option.label}</span>
                <div className="flex gap-2">
                  <Tooltip content={t('vehicle.edit')}>
                    <Button
                      structure="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        vehicleFormModalApi.open(
                          (modalApi) =>
                            props.renderForm({
                              defaultValues: option.vehicle,
                              context: 'edit',
                              onSubmit: (values) => {
                                editVehicle(values);
                                modalApi.closeModal();
                              },
                            }),
                          {
                            name: 'edit vehicle',
                          },
                        );
                      }}
                    >
                      <PencilIcon className="w-5" />
                    </Button>
                  </Tooltip>
                  <Tooltip content={t('vehicle.delete')} placement="topRight">
                    <Button
                      structure="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        return confirmationApi.open({
                          message: (
                            <>
                              <p className="font-semibold">
                                {props.deleteMessage}
                              </p>
                              <p className="text-primary-700 mb-6">
                                - {option.label}
                              </p>
                            </>
                          ),
                          onConfirm: () =>
                            props.deleteVehicle(option.value).then(() => {
                              deleteVehicleOption(option.vehicle);
                              confirmationApi.close();
                            }),
                        });
                      }}
                    >
                      <TrashIcon className="w-5" />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            );
          }}
        />
        {vehicleFormModal}
        {confirmationContent}
      </>
    );
  }

  return (
    <>
      <Button
        size="xl"
        structure="text"
        onClick={() =>
          vehicleFormModalApi.open(
            (modalApi) =>
              props.renderForm({
                defaultValues: {},
                context: 'add',
                onSubmit: (values) => {
                  addNewVehicle(values);
                  modalApi.closeModal();
                },
              }),
            {
              name: 'add vehicle',
            },
          )
        }
      >
        {t('vehicle.add')}
      </Button>
      {vehicleFormModal}
      {confirmationContent}
    </>
  );
};

export default CarsSelect;
