import {
  getExpenseCategoriesGuessed,
  GetExpenseCategoriesGuessedResponse,
} from 'api/v2/expenses';
import { useGrowthABExperimentsState } from 'hooks/useAssignGrowthABGroups';
import { useMemo, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { ExpensesCacheKeys } from 'types/cacheKeys.types';
import { IExpense } from 'types/expenses.types';
import { ABTest } from 'types/growthExperiments.types';
import { FIVE_MINUTES } from 'utils/time';

type UseGuessExpenseCategoryWithAIProps = Partial<
  Pick<IExpense, 'filePath' | 'transactions' | 'supplier'>
>;

export type UseGuessExpenseCategoryWithAIReturn = {
  showGuessedCategories: boolean;
  setShowGuessedCategories: (value: boolean) => void;
  enabled: boolean;
  query: UseQueryResult<GetExpenseCategoriesGuessedResponse[]>;
};

const useGuessExpenseCategoryWithAI = ({
  filePath,
  supplier,
  transactions,
}: UseGuessExpenseCategoryWithAIProps): UseGuessExpenseCategoryWithAIReturn => {
  const filename = useMemo(() => filePath?.split('/').pop(), [filePath]);
  const [showGuessedCategories, setShowGuessedCategories] = useState(false);
  const { experiments } = useGrowthABExperimentsState();

  const supplierName = supplier?.name;

  const query = useQuery(
    [
      ExpensesCacheKeys.ExpensesCategoriesGuessed,
      filename,
      supplierName,
      ...(transactions || []),
    ],
    () =>
      getExpenseCategoriesGuessed({
        filename,
        supplierName,
        transactions,
      }),
    {
      enabled: showGuessedCategories,
      cacheTime: FIVE_MINUTES,
      staleTime: FIVE_MINUTES,
    },
  );

  return {
    query,
    enabled: experiments.AI_CATEGORY_SUGGESTION !== ABTest.A && !!filename,
    showGuessedCategories,
    setShowGuessedCategories,
  };
};

export default useGuessExpenseCategoryWithAI;
