import i18n, { type InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { parseMissingKeyHandler, sharedConfig } from './config';

/**
 * this config is inspired but this solution
 * https://codesandbox.io/s/react-i18n-dynamic-import-kk542?file=/src/i18n.js:581-712
 * the idea is to lazyload the translation file using i18next-http-backend
 */

const appI18nInstance = i18n.createInstance();
appI18nInstance
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ...sharedConfig,
    parseMissingKeyHandler: parseMissingKeyHandler(appI18nInstance),
  } as InitOptions);
export default appI18nInstance;

/**
 * ✅ integrate with googleapi to get translations files
 * ✅ detect initial user language
 * ✅ load langs dynamically/lazy
 * ✅ multiple translation instances (for invoice pdf )
 * ✅ slack integration for missing keys
 * ⬜️ tool for notifying devs and QA for missing keys
 *    and copy them to clipboard
 */
