import { ArrowLeftIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { Button } from 'components/atoms/Button';
import { AnimatePresence, motion } from 'framer-motion';
import getIntercom from 'hooks/intercom.hook';
import { useContext, type FC, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import type { TaxCheckEnum } from 'types/taxes.types';
import formatMoney from 'utils/formatMoney';
import { CurrentTaxContext } from '../Tax';
import { useGetTaxCheckTranslation } from './hooks';

const CheckUITemplate: FC<{
  title?: string | ReactNode;
  checkName: TaxCheckEnum;
  description?: string;
  actionPoint?: string | ReactNode;
  renderContent?: () => JSX.Element;
  renderButtons?: () => JSX.Element;
  MarkAsReviewedButton?: JSX.Element;
  hideMarkAsReviewedButton?: boolean;
  checkData?: any;
}> = ({
  checkName,
  checkData,
  renderContent,
  renderButtons,
  hideMarkAsReviewedButton,
  MarkAsReviewedButton = null,
}) => {
  const { t } = useTranslation();
  const { showIntercom } = getIntercom();
  const { setCurrentCheck } = useContext(CurrentTaxContext);
  const getTaxCheckTitle = useGetTaxCheckTranslation('taxes.checks', 'label', {
    number: checkData?.metaData?.vatPaymentExpenses,
  });
  const getTaxCheckDescription = useGetTaxCheckTranslation(
    'taxes.checks',
    'description',
    {
      days: checkData?.metaData?.tripWorkingDays,
      amount: formatMoney(checkData?.metaData?.deductibleAmount || 0),
      number: checkData?.metaData?.vatPaymentExpenses,
    },
  );
  return (
    <AnimatePresence mode="wait">
      <motion.div
        {...{
          initial: { opacity: 0, marginTop: 50 },
          animate: {
            opacity: 1,
            marginTop: 0,
            transition: { delay: 0.1, duration: 0.4 },
          },
          exit: { opacity: 0, transition: { duration: 0.2 } },
        }}
        className={classNames('mb-14')}
      >
        <div className="rounded-10 bg-white shadow-topShadow mt-4 p-8">
          <div>
            <Button structure="text" onClick={() => setCurrentCheck(null)}>
              <ArrowLeftIcon className="w-6 h-6 text-primary-400" />
            </Button>
          </div>
          <div className="p-1 rounded-10 bg-primary-50">
            <div className="rounded-10 p-4 bg-white border border-primary-200">
              <div className="mb-2 font-bold text-lg">
                {getTaxCheckTitle(checkName)}
              </div>
              <div className="text-primary-700 text-lg">
                {getTaxCheckDescription(checkName)
                  .split('\n')
                  .map((p: string) => (
                    <p className="mb-3" key={p}>
                      <ReactMarkdown>{p}</ReactMarkdown>
                    </p>
                  ))}
              </div>
              {renderContent?.()}
            </div>
          </div>
          <div className="flex w-full mt-8 justify-between items-center">
            <Button structure="text" onClick={() => showIntercom()}>
              <span className="text-primary-700 text-lg">
                {t('taxes.checks.ask_for_help')}
              </span>
            </Button>
            <div className="flex gap-2">
              <div>{renderButtons?.()}</div>
              {!hideMarkAsReviewedButton && MarkAsReviewedButton}
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CheckUITemplate;
