import type SVGType from '../../../types/svg.types';

enum ContainerClassesEnum {
  revenues = 'bg-revenues',
  clients = 'bg-revenues',
  taxes = 'bg-taxes',
  expenses = 'bg-expenses',
  banks = 'bg-bank',
  export = 'bg-black',
  primary = 'bg-primary-800',
}

enum IconClassesEnum {
  revenues = 'text-revenues stroke-revenues',
  clients = 'text-revenues stroke-revenues',
  taxes = 'text-taxes stroke-taxes fill-taxes',
  expenses = 'text-expenses stroke-expenses',
  banks = 'text-bank stroke-bank',
  export = 'text-black stroke-black',
  primary = 'text-primary stroke-primary',
}

enum CircularButtonTypesEnum {
  revenues = 'revenues',
  clients = 'clients',
  taxes = 'taxes',
  expenses = 'expenses',
  banks = 'banks',
  export = 'export',
  primary = 'primary',
}

type WrapperLinkProps = {
  to?: string;
  onClick?: (e: any) => void;
};

type WrapperButtonProps = {
  onClick?: (e: any) => void;
  wrapperClassName?: string;
  iconClassName?: string;
};

type CircularButtonProps = WrapperLinkProps &
  WrapperButtonProps & {
    Icon: SVGType;
    active: boolean;
    type: CircularButtonTypesEnum;
    renderContent?: () => JSX.Element | null;
  };

export {
  WrapperLinkProps,
  WrapperButtonProps,
  CircularButtonProps,
  IconClassesEnum,
  ContainerClassesEnum,
  CircularButtonTypesEnum,
};
