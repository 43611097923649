import type { Category } from 'components/organisms/CategoriesPicker/categoriesPicker.types';
import i18n from 'i18n';
import type { ExpenseCategory } from 'types/expensesCategory.types';
import { pickerIcons as icons } from 'utils/expenses/categories/icons';

type CustomCategory = ExpenseCategory & { fakeWrapper?: boolean };

export const adaptCategories = (
  categories: Category[],
  deductibleTaxCategories: any,
  accountType: string,
) => {
  const irrelevantCategoriesRemoved = categories.reduce(
    function filterIrrelevantCategories(
      acc: Category[],
      category: Category,
    ): Category[] {
      const isIrrelevant = (category.disabledFor || []).includes(accountType);
      if (isIrrelevant) return acc;
      if (category.list) {
        const _list = category.list.reduce(filterIrrelevantCategories, []);
        if (_list.length) return acc.concat({ ...category, list: _list });
        return acc;
      }
      return acc.concat(category);
    },
    [],
  );
  const withTopLevelParents = irrelevantCategoriesRemoved.map((cate) => {
    if (!cate.list || cate.question) {
      return {
        fakeWrapper: true,
        title: cate.title,
        displayName: cate.displayName,
        icon: cate.icon,
        list: [cate],
      };
    }
    return cate;
  });
  const adapted = withTopLevelParents.map(function modifyEachCategory(
    category: CustomCategory,
  ): CustomCategory {
    category = { ...category };

    if (category.list) {
      return Object.assign(category, {
        list: category.list.map(modifyEachCategory),
      });
    }

    const update = deductibleTaxCategories[category.id as string];
    if (update) {
      return Object.assign(category, update);
    }
    return category;
  });

  const allCates = {
    title: i18n.t('invoice_categories.all.title'),
    icon: icons.mixed,
    isAllCategories: true,
    list: adapted,
  };

  return [allCates, ...adapted];
};
