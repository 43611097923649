const Downgrade = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      {...props}
      viewBox="0 0 70 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-26"
        y="-15"
        width="96"
        height="96"
        rx="48"
        fill="white"
        fillOpacity="0.15"
      />
      <rect
        x="-25.5"
        y="-14.5"
        width="95"
        height="95"
        rx="47.5"
        stroke="url(#paint0_radial_3143_13398)"
        strokeOpacity="0.2"
      />
      <rect
        x="-21"
        y="-10"
        width="86"
        height="86"
        rx="43"
        fill="white"
        fillOpacity="0.15"
      />
      <rect
        x="-20.6821"
        y="-9.68212"
        width="85.3642"
        height="85.3642"
        rx="42.6821"
        stroke="url(#paint1_radial_3143_13398)"
        strokeOpacity="0.3"
        strokeWidth="0.635758"
      />
      <rect x="-16" y="-5" width="76" height="76" rx="38" fill="white" />
      <path
        d="M32.5 36.5L22 47.5L11.5 36.5"
        stroke="#8059C8"
        strokeWidth="3"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 45.5C20.5 46.3284 21.1716 47 22 47C22.8284 47 23.5 46.3284 23.5 45.5H20.5ZM23.5 18.5V17H20.5V18.5H23.5ZM23.5 45.5V18.5H20.5V45.5H23.5Z"
        fill="#8059C8"
      />
      <defs>
        <radialGradient
          id="paint0_radial_3143_13398"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.3556 -15) rotate(90.2122) scale(96.0007)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.13" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_3143_13398"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.3185 -10) rotate(90.2122) scale(86.0006)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.13" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default Downgrade;
