import classNames from 'classnames';
import useGetSelectedYearTaxes from 'hooks/Taxes/useGetSelectedYearTaxes';
import _get from 'lodash/get';
import _sum from 'lodash/sum';
import type { FC } from 'react';
import type { ITax } from 'types/taxes.types';

const TaxesCount: FC<{ isActive?: boolean }> = ({ isActive }) => {
  const { data: taxesData } = useGetSelectedYearTaxes();
  const totalActionsRequired = _sum(
    Object.values(taxesData?.meta?.actionsRequired ?? {}),
  );
  const hasAnyPassedDueDate = (
    Object.values(_get(taxesData, 'meta.dueItems') || {}) as ITax[][]
  )
    ?.flat()
    ?.some((item) => _get(item, 'hasDueDatePassed'));

  if (isActive || !totalActionsRequired) return null;

  return (
    <div
      className={classNames(
        'text-[9px] animate-countBadge !opacity-100 w-5 h-5 z-50 !font-bold ',
        'border-white border-2 text-white rounded-full',
        'flex  items-center justify-center',
        hasAnyPassedDueDate ? 'bg-red-500' : 'bg-yellow-500',
      )}
    >
      {totalActionsRequired}
    </div>
  );
};

export default TaxesCount;
