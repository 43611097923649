import classNames from 'classnames';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Button';
import { FieldConnector } from 'components/atoms/Form/FieldConnector';
import { Loader } from 'components/atoms/Loader';
import { Title } from 'components/atoms/Title';
import { SizesEnum } from 'components/atoms/Title/Title.enum';
import NumberField from 'components/molecules/Fields/Number/Number';
import SimpleError from 'components/molecules/SimpleError.component';
import SlideOver2 from 'components/molecules/SlideOver2.component';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import useTaxImpact from 'hooks/Taxes/useTaxImpact';
import { get, isEmpty } from 'lodash';
import type { FC } from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { IExpense } from 'types/expenses.types';
import type { IRevenue } from 'types/revenues.types';
import type { TaxImpactItem } from 'types/taxes.types';
import { TaxImpactDocumentType } from 'types/taxes.types';
import type { AccountType, UserCountry } from 'types/users.types';
import { VATTypeEnum } from 'types/users.types';
import { isExpert } from 'utils/constants';
import formatMoney from 'utils/formatMoney';
import {
  ChevronLeftIcon,
  ChevronRightIconOutline,
  MinusIconOuline,
  PlusIconOuline,
} from 'utils/icons';
import isOnePersonCompany from 'utils/isOnePersonCompany';
import {
  getIncomeTaxHeaderTitle,
  getIncomeTaxHeaderValue,
  getIsCreditNote,
  getItemsFromTaxImpactIncomeTax,
  getItemsFromTaxImpactVAT,
  getTotal,
  getVATHeaderValue,
} from './helpers';
import {
  TaxImpactExpenseHelper,
  TaxImpactRevenueHelper,
} from './TaxImpactHelper';

const ImpactItem: FC<{
  isFlatFeeUsed: boolean;
  taxRate: number;
  documentType: TaxImpactDocumentType;
  details: TaxImpactItem;
  isCreditNote: boolean;
  documentData: IExpense | IRevenue;
  isTotal?: boolean;
  index?: number;
  currencyRate?: number;
}> = ({
  isFlatFeeUsed,
  taxRate,
  documentType,
  isCreditNote,
  isTotal,
  details,
  index: itemIndex,
  documentData,
  currencyRate = 1,
}) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const total =
    getTotal(
      documentType,
      details,
      documentData,
      isTotal ? undefined : itemIndex,
    ) / currencyRate;
  const customerCountry = useCustomerCountry();
  const vat = getVATHeaderValue(documentType, details?.vat, isCreditNote);
  const vatItems = getItemsFromTaxImpactVAT(
    details?.vat,
    customerCountry as UserCountry,
  );

  const incomeTax = getIncomeTaxHeaderValue(
    documentType,
    details.incomeTax,
    isCreditNote,
  );
  const incomeTaxItems = getItemsFromTaxImpactIncomeTax(
    customerCountry as UserCountry,
    details.incomeTax,
    documentType,
    get(details, 'items[0].amortizationPeriod', 0),
  );
  const { data: user } = useGetCurrentCustomerData();
  const isSubjectToVAT = user?.VATType === VATTypeEnum.subjectToVAT;
  const { t } = useTranslation();
  const isCompany = isOnePersonCompany(user?.accountType as AccountType);

  return (
    <div className="text-main">
      <Title type={SizesEnum.h6} className="my-4">
        {isTotal ? 'Total' : `Item #${(itemIndex as number) + 1}`}
      </Title>
      <div className="grid grid-cols-2 grid-rows-2 gap-4 text-center">
        <div className="border rounded-10 p-4 flex flex-col justify-center items-center">
          <span className="text-xl font-bold">{formatMoney(total)}</span>
          <span className="text-sm text-center">
            {documentType === TaxImpactDocumentType.EXPENSE
              ? t('expense.item_professional_amount')
              : t('transactions.cells.total_amount')}
          </span>
        </div>
        <div className="border rounded-10 p-4 flex flex-col justify-center items-center">
          <span className="text-xl font-bold">{formatMoney(vat)}</span>
          <span className="text-sm">
            {(documentType === TaxImpactDocumentType.REVENUE &&
              !isCreditNote) ||
            (documentType === TaxImpactDocumentType.EXPENSE && isCreditNote)
              ? t('tax_impact.vat_to_pay_on_purchase')
              : t('tax_impact.vat_to_recover_on_purchase')}
          </span>
        </div>
        <div className="border rounded-10 p-4 flex flex-col justify-center items-center">
          <span className="text-xl font-bold">
            {formatMoney(details?.incomeTax?.deductibleAmount)}
          </span>
          <span className="text-sm">
            {t(
              `tax_impact.action_sheet.deductible${
                isCompany ? '.corporate' : ''
              }`,
            )}
          </span>
        </div>
        <div className="border rounded-10 p-4 flex flex-col justify-center items-center">
          <span className="text-xl font-bold">{formatMoney(incomeTax)}</span>
          <div className="text-sm flex first-letter:!capitalize">
            {getIncomeTaxHeaderTitle(
              isFlatFeeUsed,
              documentType,
              taxRate * 100,
            )}
            {isTotal && (
              <>
                {documentType === TaxImpactDocumentType.EXPENSE ? (
                  <TaxImpactExpenseHelper
                    details={details}
                    year={dayjs(get(documentData, 'expenseDate')).year()}
                  />
                ) : (
                  <TaxImpactRevenueHelper
                    details={details}
                    year={dayjs(get(documentData, 'invoiceDate')).year()}
                    documentData={documentData as IRevenue}
                    includeCopyrightInfo={!!details.incomeTax.copyright}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {!isTotal ? (
        <Button
          structure="text"
          className="my-6 font-semibold text-lg"
          onClick={() => setDetailsVisible(!detailsVisible)}
          icon={detailsVisible ? MinusIconOuline : PlusIconOuline}
        >
          {t(
            detailsVisible
              ? 'tax_impact.less_details'
              : 'tax_impact.more_details',
          )}
        </Button>
      ) : null}
      <AnimatePresence mode="wait">
        {detailsVisible || isTotal ? (
          <motion.div
            {...{
              initial: { opacity: 0 },
              animate: { opacity: 1, transition: { delay: 0.1 } },
              exit: { opacity: 0, transition: { duration: 0.1 } },
            }}
          >
            <div>
              {isSubjectToVAT && vatItems?.length ? (
                <div>
                  <div className="my-6">{t('tax_impact.vat_cells')}</div>
                  <div>
                    {vatItems.map((item) => (
                      <div
                        key={item.label}
                        className="rounded-10 font-semibold bg-primary-50 px-4 py-2 my-2"
                      >
                        <div className="flex items-center justify-between">
                          <span className="pr-1 whitespace-nowrap first-letter:uppercase">
                            {`${t('vatCells.cell')} ${item.title?.replace(
                              'c',
                              '',
                            )}`}
                          </span>
                          <div className="flex items-center">
                            <FieldConnector
                              showUI={!!(item?.title === 'c59')}
                              className={classNames(
                                'mx-2',
                                isExpert ? 'w-[70px]' : '',
                              )}
                              name={`items.${itemIndex}.maxDeductibleVAT`}
                            >
                              {({ field: { value, onChange } }) =>
                                isExpert ? (
                                  <NumberField
                                    format={{ prefix: '%' }}
                                    value={
                                      value == null
                                        ? null
                                        : Math.round(value * 100)
                                    }
                                    onChange={(value) =>
                                      onChange(value == null ? 0 : value / 100)
                                    }
                                    placeholder="0 %"
                                  />
                                ) : documentType ===
                                  TaxImpactDocumentType.EXPENSE ? (
                                  <>{null}</>
                                ) : (
                                  <span className="text-primary-400">{`(${
                                    value * 100
                                  }%)`}</span>
                                )
                              }
                            </FieldConnector>
                            <span className="whitespace-nowrap">
                              {item.value}
                            </span>
                          </div>
                        </div>
                        <span className="text-primary-600">{item.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              {!details?.incomeTax?.flatFeeUsed && incomeTaxItems?.length ? (
                <div>
                  <div className="my-6">
                    {t(
                      `tax_impact.income_tax_details${
                        isCompany ? '.corporate' : ''
                      }`,
                    )}
                  </div>
                  <div>
                    {incomeTaxItems.map((item) => (
                      <div
                        key={item.label}
                        className="rounded-10 font-semibold bg-primary-50 px-4 py-2 my-2 flex flex-wrap items-center justify-between"
                      >
                        <span>{item.label}</span>
                        <div className="flex items-center">
                          <FieldConnector
                            showUI={!!item?.isDeductibility}
                            name={`items.${itemIndex}.incomeTaxDeductibility`}
                            className={classNames(
                              'mx-2',
                              isExpert ? 'w-[70px]' : '',
                            )}
                          >
                            {({ field: { value, onChange } }) =>
                              isExpert ? (
                                <NumberField
                                  format={{ prefix: '%' }}
                                  value={
                                    value == null
                                      ? null
                                      : Math.round(value * 100)
                                  }
                                  onChange={(value) =>
                                    onChange(value == null ? 0 : value / 100)
                                  }
                                  placeholder="0 %"
                                />
                              ) : documentType ===
                                TaxImpactDocumentType.EXPENSE ? (
                                <>{null}</>
                              ) : (
                                <span className="text-primary-400">{`(${
                                  value * 100
                                }%)`}</span>
                              )
                            }
                          </FieldConnector>
                          <span className="whitespace-nowrap">
                            {item.value}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

const TaxImpactRightPanel: FC<{
  onClose: () => void;
  currencyRate: number;
  documentType: TaxImpactDocumentType;
}> = ({ onClose, currencyRate, documentType }) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const documentData = getValues() as IExpense | IRevenue;
  const {
    data: taxImpact,
    isLoading,
    isError,
    refetch,
  } = useTaxImpact({
    documentData,
    documentType,
  });

  const isCreditNote = getIsCreditNote(documentType, documentData);
  const isFlatFeeUsed = taxImpact?.total?.incomeTax?.flatFeeUsed;
  const taxRate = taxImpact?.total?.incomeTax?.taxRate;

  return (
    <div className="h-full flex flex-col">
      <div className="p-10 bg-taxes-25 border-b border-b-taxes-100">
        <Button
          structure="text"
          icon={ChevronLeftIcon}
          onClick={() => onClose()}
          className="!text-primary-300"
        >
          {t('taxes.income_submission_tax.go_back')}
        </Button>
        <Title className="my-6" type={SizesEnum.h3}>
          {t(
            documentType === TaxImpactDocumentType.EXPENSE
              ? 'tax_impact.expense.label'
              : 'tax_impact.revenue.label',
          )}
        </Title>
      </div>
      <div className="flex-1">
        {(() => {
          if (isLoading) {
            return (
              <div className="h-full flex items-center justify-center">
                <Loader size="3xl" />
              </div>
            );
          }

          if (isError) {
            return (
              <SimpleError
                messageClassName="!text-xl"
                onRetry={() => refetch()}
              />
            );
          }

          if (!taxImpact) return null;

          return (
            <div className="px-10 pb-20">
              <ImpactItem
                isTotal
                {...{ isFlatFeeUsed, isCreditNote, documentType, taxRate }}
                details={
                  {
                    ...taxImpact?.total,
                    professionalAmount: taxImpact.professionalAmount,
                  } as TaxImpactItem
                }
                index={0}
                currencyRate={currencyRate}
                documentData={documentData}
              />
              {taxImpact?.items?.flat()?.length > 1
                ? taxImpact.items
                    .flat()
                    .map((taxImpactItem: any, index: number) => (
                      <ImpactItem
                        {...{
                          isFlatFeeUsed,
                          isCreditNote,
                          documentType,
                          taxRate,
                        }}
                        currencyRate={currencyRate}
                        key={String(index)}
                        details={taxImpactItem}
                        index={index}
                        documentData={documentData}
                      />
                    ))
                : null}
            </div>
          );
        })()}
      </div>
    </div>
  );
};

const TaxImpact: FC<{
  isValidated: boolean;
  currencyRate?: number;
  documentType: TaxImpactDocumentType;
}> = ({ isValidated, currencyRate = 1, documentType }) => {
  const [showTaxImpact, setShowTaxImpact] = useState(false);
  const {
    formState: { errors },
    trigger,
  } = useFormContext();
  const hasErrors = !isEmpty(errors);

  const { t } = useTranslation();

  return (
    <>
      {hasErrors ? (
        <Alert
          type="warning"
          title={t('tax_impact.action_sheet.error', {
            documentType: t(documentType),
          })}
        />
      ) : null}
      <Button
        icon={ChevronRightIconOutline}
        iconPlacement="right"
        structure="text"
        onClick={() => {
          trigger().then((isValid) => {
            if (isValid) {
              setShowTaxImpact(true);
            }
          });
        }}
      >
        <span>{t('tax_impact.label')}</span>
      </Button>
      <AnimatePresence mode="wait">
        {showTaxImpact ? (
          <SlideOver2
            renderRightPanel={() => (
              <TaxImpactRightPanel
                currencyRate={currencyRate}
                documentType={documentType}
                onClose={() => setShowTaxImpact(false)}
              />
            )}
          />
        ) : null}
      </AnimatePresence>
    </>
  );
};
export default TaxImpact;
