import useModal from 'hooks/useModal';
import type { HomeExpenseUploaderProps } from 'types/expense.types';
import ExpenseUploader from './HomeExpenseUploader.component';

const useExpenseUploader = () => {
  const [expenseUploaderModalContent, expenseUploaderModalAPI] = useModal({
    width: '4xl',
    name: 'add expense',
    color: 'taxes',
    className: '!h-[60vh]',
  });

  const openExpenseUploader = ({
    onUpload,
    onClickReviewNow,
    onClickReviewLater,
  }: HomeExpenseUploaderProps) =>
    expenseUploaderModalAPI.open(() => (
      <ExpenseUploader
        onUpload={onUpload}
        onClickReviewLater={(fileUploaderMeta) => {
          expenseUploaderModalAPI.close();

          return onClickReviewLater?.(fileUploaderMeta);
        }}
        onClickReviewNow={(fileUploaderMeta) => {
          expenseUploaderModalAPI.close();

          return onClickReviewNow?.(fileUploaderMeta);
        }}
      />
    ));

  return { expenseUploaderModalContent, openExpenseUploader };
};

export default useExpenseUploader;
