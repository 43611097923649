import { getUserCountries } from 'api/v1/users/countries';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import { CountriesCacheKeys } from 'types/cacheKeys.types';
import type { CountryType } from 'types/countries';

const useGetCountries = (): UseQueryResult<CountryType[]> => {
  return useQuery(CountriesCacheKeys.CountriesList, getUserCountries);
};

export default useGetCountries;
