import dayjs from 'dayjs';
import useGetCurrentCustomerData from 'hooks/Authentication/useGetCurrentCustomerData';
import { VATTypeEnum } from 'types/users.types';
import useGetTaxBySlug from './useGetTaxBySlug';

const useHasPassedVATThreshold = () => {
  const { data: user } = useGetCurrentCustomerData();
  const isFranchisee = user?.VATType === VATTypeEnum.franchisee;
  const VATThresholdItemQuery = useGetTaxBySlug(
    `${user?.country}/vat-franchisee/${dayjs().year()}`,
    isFranchisee,
  );

  const { data: VATFranchiseeItem } = VATThresholdItemQuery;

  return {
    VATThresholdItemQuery,
    country: user?.country,
    threshold: VATFranchiseeItem?.threshold,
    period: VATFranchiseeItem?.period,
    hasPassedVATThreshold:
      isFranchisee &&
      VATFranchiseeItem &&
      VATFranchiseeItem?.currentTurnOver > VATFranchiseeItem?.threshold,
  };
};
export default useHasPassedVATThreshold;
