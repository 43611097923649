import useSubscriptionPlan from 'hooks/Subscriptions/useSubscriptionPlan';
import type { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import type { ISubscriptionPlan } from 'types/users.types';
import { isSubscriptionNoWebAccessPlan } from 'utils/subscriptions.utils';

// TODO: MOVE THIS ARRAY LIST TO CONSTANT FILE (constant.ts)
const allowedNoWebAccessRoutes = [
  '/settings/personalDetails',
  '/settings/loginMethods',
  '/settings/changePassword',
  '/settings/taxesSettings',
  '/mobile',
  '/subscriptions',
  '/paymentFailed',
  '/experiments',
];

const PrivateRoute: FC<{ isAuthenticated: boolean; children: any }> = ({
  isAuthenticated,
  children,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const subscriptionPlan = useSubscriptionPlan();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  if (
    !allowedNoWebAccessRoutes.includes(pathname) &&
    isSubscriptionNoWebAccessPlan(
      (subscriptionPlan as ISubscriptionPlan)?.planInternalName,
    )
  ) {
    return <Navigate to="/subscriptions" />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
