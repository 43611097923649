import { useContext, useEffect, useRef } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

type Api = {
  next: () => void;
  [key: string]: any;
};

type UseStopNavigationOptions = {
  shouldBlock: boolean;
  onBlockedNavigation: (api: Api) => void;
};

const useBlockNavigation = (options: UseStopNavigationOptions) => {
  const { navigator } = useContext(NavigationContext);
  const actionRef = useRef(options.onBlockedNavigation);
  actionRef.current = options.onBlockedNavigation;

  useEffect(() => {
    if (!options.shouldBlock) return;

    const unBlock = (navigator as any).block((context: any) => {
      const next = () => {
        unBlock();
        context.retry();
      };
      options.onBlockedNavigation({ ...context, next });
    });

    return unBlock;
  }, [options.shouldBlock]);
};

export default useBlockNavigation;
