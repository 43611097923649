import dayjs from 'dayjs';
import { GROWTH_ALLOWED_EMAILS } from 'utils/constants';
import useGetCurrentCustomerData from './Authentication/useGetCurrentCustomerData';

const isAllowedProductionEmail = (email?: string) =>
  GROWTH_ALLOWED_EMAILS.includes(email as string) ||
  email?.includes('@accountable.eu');

export const isNewUserRelativeToDate = (
  userDate: string,
  date: string,
  email?: string,
): boolean => isAllowedProductionEmail(email) || dayjs(userDate).isAfter(date);

const useIsNewUserRelativeToDate = (): ((date: string) => boolean) => {
  const { data: user } = useGetCurrentCustomerData();
  const signupDate = dayjs(user?.created);

  return (date: string) =>
    isAllowedProductionEmail(user?.email) || signupDate.isAfter(date);
};

export default useIsNewUserRelativeToDate;
