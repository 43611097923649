import { Helper } from 'components/molecules/Helper';
import useCustomerCountry from 'hooks/shared/useCustomerCountry';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

const PaymentAmountHelper: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();
  const customerCountry = useCustomerCountry();
  return (
    <div className={className}>
      <Helper color="revenues" name={t('invoice.helpers.euro_amount.title')}>
        <Helper.Title>{t('invoice.helpers.euro_amount.title')}</Helper.Title>
        <Helper.Paragraph>
          {t(`invoice.helpers.euro_amount.text1.${customerCountry}`)}
        </Helper.Paragraph>
        <Helper.Paragraph>
          {t('invoice.helpers.euro_amount.text2')}
        </Helper.Paragraph>
        <Helper.Paragraph>
          {t('invoice.helpers.euro_amount.text3')}
        </Helper.Paragraph>
      </Helper>
    </div>
  );
};

export default PaymentAmountHelper;
