import classNames from 'classnames';
import { Dropzone } from 'components/molecules/Dropzone';
import { FileUploadedList } from 'components/molecules/FileUploadedList';
import { Status } from 'components/molecules/FileUploadedList/FileUploaded.types';
import useKeyValueState from 'hooks/useKeyValueState';
import useParallelScheduler from 'hooks/useParallelScheduler';
import { isEmpty } from 'lodash';
import groupBy from 'lodash/groupBy';
import type { FC, ReactNode } from 'react';
import { createContext, useContext, useMemo, useRef } from 'react';
import { flushSync } from 'react-dom';
import { globalyAcceptedFileUploaderTypes, maxFileSize } from 'utils/constants';
import type { FileUploaderProps } from './FileUploader.types';

export const DefaultUploaderContentext = createContext<
  ReturnType<typeof useKeyValueState>
>({
  set: () => {},
  update: () => {},
  values: [],
  clear: () => {},
});

export const ExpensesUploaderContext = createContext<
  ReturnType<typeof useKeyValueState>
>({
  set: () => {},
  update: () => {},
  values: [],
  clear: () => {},
});

const DefaultFileUploaderProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const filesStateApi = useKeyValueState();

  return (
    <DefaultUploaderContentext.Provider value={filesStateApi}>
      {children}
    </DefaultUploaderContentext.Provider>
  );
};

export const ExpensesUploaderProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const filesStateApi = useKeyValueState();

  return (
    <ExpensesUploaderContext.Provider value={filesStateApi}>
      {children}
    </ExpensesUploaderContext.Provider>
  );
};

export const FileUploader: FC<FileUploaderProps> = ({
  onUpload,
  dropzoneProps,
  filesListProps,
  className,
  color,
  afterPatchUpload,
  keepUploadingAfterDestroy,
  onAfterDropAccepted,
  renderUploadedFileItem,
  filesStateApi,
  hideDropzoneWhileUploading,
  filesListContainerClassName,
}) => {
  const fileUploaderMeta = useMemo(
    () => ({
      ...groupBy(filesStateApi.values, 'status'),
      isEmpty: isEmpty(filesStateApi.values),
    }),
    [filesStateApi.values],
  );

  // TODO: rename
  const metaRef = useRef(fileUploaderMeta);
  metaRef.current = fileUploaderMeta;

  const schedule = useParallelScheduler({
    onComplete: () => afterPatchUpload?.(metaRef.current),
    keepAlive: keepUploadingAfterDestroy,
  });
  const uidRef = useRef(0);

  const processFile = (
    file: File,
    key: string,
    options?: Record<string, any>,
  ) => {
    const fileState = {
      key,
      file,
      progress: 5,
      status: Status.waiting,
      result: null,
      options,
    };
    filesStateApi.set(key, fileState);
    schedule(() => {
      filesStateApi.update(key, { status: Status.uploading });
      return onUpload(
        file,
        (progress) => filesStateApi.update(key, { progress }),
        options,
      )
        .then((result) =>
          // TODO: only flush sync if afterPatchUpload is provided
          flushSync(() =>
            filesStateApi.update(key, {
              status: Status.done,
              progress: 100,
              result,
            }),
          ),
        )
        .catch(() =>
          flushSync(() => filesStateApi.update(key, { status: Status.error })),
        );
    });
  };

  const onDropAccepted = (files: File[]) => {
    const processFiles = () =>
      files.forEach((file) => {
        const uid = uidRef.current++;
        processFile(file, `_${uid}`);
      });

    if (onAfterDropAccepted) {
      return onAfterDropAccepted(processFiles);
    }

    return processFiles();
  };

  return (
    <div className={classNames('flex gap-6 h-full', className)}>
      {hideDropzoneWhileUploading && filesStateApi.values.length > 0 ? null : (
        <Dropzone
          color={color || dropzoneProps.color}
          render={(dropzoneMeta) =>
            dropzoneProps.render(dropzoneMeta, fileUploaderMeta, processFile)
          }
          className={classNames('flex-1', dropzoneProps.className)}
          dropOptions={{
            onDropAccepted,
            accept: globalyAcceptedFileUploaderTypes,
            maxSize: maxFileSize,
            ...dropzoneProps.dropOptions,
            ...dropzoneProps.getDropOptions?.(fileUploaderMeta),
          }}
        />
      )}
      {filesStateApi.values.length > 0 && (
        <div
          className={classNames(
            'flex flex-1 flex-col px-4 overflow-y-auto',
            filesListContainerClassName,
          )}
        >
          <div className="flex flex-1 flex-col">
            {filesListProps?.renderHeader?.(fileUploaderMeta)}
            <div className="flex-1">
              <FileUploadedList
                color={color || filesListProps.color}
                className="flex flex-col gap-5"
                onRetry={processFile}
                files={filesStateApi.values}
                renderUploadedFileItem={renderUploadedFileItem}
              />
            </div>
          </div>
          {filesListProps?.renderFooter?.(fileUploaderMeta)}
        </div>
      )}
    </div>
  );
};

const RenderUploader: FC<{
  render: (result: ReturnType<typeof useKeyValueState>) => ReactNode;
}> = ({ render }) => {
  const filesStateApi = useContext(DefaultUploaderContentext);

  return <>{render(filesStateApi)}</>;
};

export const GlobalExpensesUploader: FC<
  Omit<FileUploaderProps, 'filesStateApi'>
> = (props) => {
  const filesStateApi = useContext(ExpensesUploaderContext);

  return <FileUploader {...props} filesStateApi={filesStateApi} />;
};

const FileUploaderWrapper: FC<Omit<FileUploaderProps, 'filesStateApi'>> = (
  props,
) => (
  <DefaultFileUploaderProvider>
    <RenderUploader
      render={(result) => <FileUploader {...props} filesStateApi={result} />}
    />
  </DefaultFileUploaderProvider>
);

export default FileUploaderWrapper;
