import type { IAPIGetCalculateDataBody } from 'api/v2/revenues';
import { getCalculatedData } from 'api/v2/revenues';
import { Button } from 'components/atoms/Button';
import type { PaymentAmountProps } from 'components/molecules/Fields/PaymentAmount/PaymentAmount.component';
import OriginalPaymentAmount from 'components/molecules/Fields/PaymentAmount/PaymentAmount.component';
import type { FieldProps } from 'components/pages/Expenses/Form/Fields/commonFieldProps.types';
import useDebounce from 'hooks/useDebounce';
import type { FC } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RevenuesCacheKeys } from 'types/cacheKeys.types';

type Params = IAPIGetCalculateDataBody;

export type CustomPaymentAmountProps = FieldProps &
  PaymentAmountProps & {
    onNewCurrencyRate: (num: number) => void;
    params: Params;
  };

const CustomPaymentAmount: FC<CustomPaymentAmountProps> = ({
  onNewCurrencyRate,
  params,
  ...rest
}) => {
  const { t } = useTranslation();
  const _params = useDebounce(500, JSON.stringify(params));

  const lastConfig = useRef(_params);

  const result = useQuery(
    [RevenuesCacheKeys.CurrencyRate, _params],
    () => getCalculatedData(JSON.parse(_params), { ignoreToast: true }),
    {
      cacheTime: 0,
      staleTime: 0,
      onSuccess: (data) => {
        lastConfig.current = _params;
        onNewCurrencyRate(data.calculations.baseCurrencyTotalAmountInclVAT);
      },
      enabled: rest.value == null || lastConfig.current !== _params,
    },
  );

  return (
    <div>
      <OriginalPaymentAmount {...rest} loading={result.isLoading} />
      {result.isError && (
        <div className="flex flex-wrap items-center gap-2">
          <span className="text-sm text-primary-400">
            {t('backend.error.something_went_wrong')}.
          </span>
          <Button
            structure="text"
            color="primary"
            onClick={() => result.refetch()}
          >
            <span className="text-sm">{t('retry')}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default CustomPaymentAmount;
