import { useGrowthABExperimentsState } from 'hooks/useAssignGrowthABGroups';
import { ABTest } from 'types/growthExperiments.types';

const useApplySMALL25Discount = () => {
  return (
    useGrowthABExperimentsState().experiments
      .SMALL_BUSINESS_PLAN_25DISCOUNT_GERMAN === ABTest.B
  );
};
export default useApplySMALL25Discount;
