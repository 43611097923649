import create from 'zustand';

interface IIntercomSlice {
  unreadCount: number;
}

const useIntercomStore = create<IIntercomSlice>((set, get) => ({
  unreadCount: 0,
  setUnreadCount: (unreadCount: number) => set({ unreadCount }),
}));

export default useIntercomStore;
